/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Page from "../../../../artibulles-cis/Page/Page";

import _ from "lodash";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, addHours} from "date-fns";

import TaskCreateEditForm from "./TaskCreateEditForm";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "10px",
    },
}));

const Component = (props) => {
    const {Id, HandleCloseViewCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const LocationParams = useParams();
    const Location = useLocation();
    const PathName = Location.pathname;
    let PreviousPath;
    if (Location && Location.state) {
        PreviousPath = Location.state.from;
    }

    const History = useHistory();
    var FinalId, FinalAPIUrlRelative, InitialCall;
    var OpenInModal = true;
    if (Id) {
        FinalId = Id;
        OpenInModal = false;
    } else {
        FinalId = LocationParams.id;
        OpenInModal = true;
    }
    if (FinalId === "new") {
        InitialCall = {type: "New"};
    } else {
        InitialCall = {type: "Edit", ID: FinalId};
    }
    FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/tasks/task`,
        DELETE: "",
        PUT: "",
        POST: "new", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("redirect", args);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            if (OpenInModal === false) {
                HandleCloseViewCard(true);
                // History.push(`/tasktimemanagement/tasks/alltasks`);
            } else {
                if (PreviousPath) {
                    History.push(PreviousPath);
                } else {
                    History.push(`/tasks`);
                }
            }
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;

        var task = null;
        if (data.Task) {
            task = data.Task;
        }
        var Categories = [];
        if (data.Categories && data.Categories.length > 0) {
            Categories = data.Categories.map((elem) => {
                return {value: elem._id, label: elem.category_name};
            });
        }
        var Priorities = [];
        if (data.Priorities && data.Priorities.length > 0) {
            Priorities = data.Priorities.map((elem) => {
                return {value: elem._id, label: elem.piority_name, order: elem.priority_order};
            });
        }
        var Companies = [];
        if (data.Companies && data.Companies.length > 0) {
            Companies = data.Companies.map((elem) => {
                return {value: elem._id, label: elem.company};
            });
        }
        var ProgramsSubOptions = [];
        if (data.Programs && data.Programs.length > 0) {
            ProgramsSubOptions = data.Programs;
        }
        var MilestonesSubOptions = [];
        if (data.Milestones && data.Milestones.length > 0) {
            MilestonesSubOptions = data.Milestones;
        }

        let ProgramSubOptionsInit = [];
        if (task && task.ref_company) {
            //We initialize the suboptions

            ProgramsSubOptions.forEach((elem) => {
                if (elem.ref_company === task.ref_company) {
                    let newsub = {value: elem.id, label: elem.program_name};
                    ProgramSubOptionsInit.push(newsub);
                }
            });
        }
        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }

        var DurationSecondsInit;
        if (task && task.estimated_duration_seconds) {
            let TimeFromSeconds = convertSecondsToTime(task.estimated_duration_seconds).split(":");

            let TimeNow = setMinutes(setHours(Date.now(), TimeFromSeconds[0]), TimeFromSeconds[1]);
            DurationSecondsInit = formatISO(TimeNow);
        } else {
            let TimeNow = setMinutes(setHours(Date.now(), 1), 0);
            DurationSecondsInit = formatISO(TimeNow);
        }
        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: task ? task._id : "new",
                task_name: task ? task.task_name : null,
                ref_taskCategory: task ? task.ref_taskCategory : null,
                ref_taskPriority: task ? task.ref_taskPriority : null,
                due_date_Iso: task ? task.due_date_Iso : formatISO(Date.now()),
                task_details: task ? task.task_details : null,
                estimated_duration_seconds: DurationSecondsInit,
                is_completed: task ? task.is_completed : false,
                completed_date_Iso: task ? task.completed_date_Iso : formatISO(Date.now()),
                is_personal: task ? (task.is_personal === true ? true : false) : false,
                is_billable: task ? (task.is_billable === true ? true : false) : false,
                ref_company: task ? task.ref_company : null,
                ref_program: task ? task.ref_program : null,
                ref_milestone: task ? task.ref_milestone : null,
            },
            SelectOptions: {
                ref_taskPriority: Priorities,
                ref_taskCategory: Categories,
                ref_company: Companies,
                ref_program: ProgramSubOptionsInit,
                ref_milestone: [],
            },
            SubOptionsFull: {
                ref_company: ProgramsSubOptions,
                ref_program: MilestonesSubOptions,
            },
            FilesUploaders: {
                // fileuploaderName: data.tutorial.Files,
            },
            InitiaFormErrors: {},
            InitialFormErrorMain: FormErrorMain,
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            var EstimatedDurationSeconds = 3600;
            if (values.estimated_duration_seconds) {
                let hours = getHours(parseISO(values.estimated_duration_seconds));
                let minutes = getMinutes(parseISO(values.estimated_duration_seconds));
                EstimatedDurationSeconds = hours * 3600 + minutes * 60;
            }

            var Newcategory = null;
            if (FullSubmission.NewCreatedOptions) {
                Newcategory = values.ref_taskCategory;
                //new options for categories
            }

            let FinalCompletedDate = null;
            if (values.is_completed === true) {
                FinalCompletedDate = values.completed_date_Iso;
            }
            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Newcategory: Newcategory,
                    Task: {
                        Updatable: {
                            estimated_duration_seconds: EstimatedDurationSeconds,
                            ref_taskCategory: values.ref_taskCategory,
                            ref_taskPriority: values.ref_taskPriority,
                            task_details: values.task_details,
                            task_name: values.task_name,
                            due_date_Iso: values.due_date_Iso ? values.due_date_Iso : formatISO(new Date(Date.now())),
                            is_completed: values.is_completed,
                            completed_date_Iso: FinalCompletedDate,
                            is_personal: values.is_personal,
                            is_billable: values.is_billable,
                            ref_company: values.ref_company,
                            ref_program: values.ref_program,
                            ref_milestone: values.ref_milestone,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <Page
            // TabRef={Ref_TabNavContent}
            // ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            WithPerfectScrollBar={true}
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                WithCardModal={OpenInModal}
                CardModalOptions={{
                    // ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    WithPerfectScrollbar:true,
                    // CardMaxWidth: "800px",
                    // CardHeight: "700px",
                    // CardWindowPaddingVertical: "0px",
                    // CardWindowPaddingHorizontal: "0px",
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                OpenInViewMode={false}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={TaskCreateEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "Reset"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
};

export default Component;
