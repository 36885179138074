import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import FileUploader from "../../../../../../../../artibulles-cis/FileUploader/FileUploader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    UploaderWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "10px",
        maxWidth: "800px",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    Uploader_Instruction: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 25px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        padding: "8px",
        background: "grey",
        color: "white",
        borderRadius: "5px",
        "& p": {
            margin: "2px 0px",
        },
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    Uploader_Button: {
        display: "flex",
        position: "relative",
        marginTop: "10px",
        justifyContent: "center",
    },
}));

const Component = (props) => {
    const {CloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [InitialFormValues, setInitialFormValues] = React.useState({
        current_date: Date.now(),
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FilesUploaders, setFilesUploaders] = React.useState({});

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data) {
                    console.log("Data", ApiRes.data);
                    CloseCard("refresh");
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleConvertCSV = () => {
        const ApiCall = {url: `${ProdURL}/api/bankaccounts/converscvfiles`, type: "post", data : FilesUploaders};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
	const UpdateFormFiles = (fieldName, filesPaths, originalNames, fileNames, fileExtensions, fileTypes, fileSizes, keywords) => {
        if (fieldName) {
            let FullFilesUploader = [];
            for (var i = 0; i < filesPaths.length; i++) {
                FullFilesUploader.push({
                    serverPath: filesPaths[i],
                    originalName: originalNames[i],
                    fileName: fileNames ? fileNames[i] : null,
                    fileExtension: fileExtensions ? fileExtensions[i] : null,
                    fileType: fileTypes ? fileTypes[i] : null,
                    fileSize: fileSizes ? fileSizes[i] : null,
                    keywords: keywords ? keywords[i] : null,
                });
            }
            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);
        }
    };
    // const UpdateFormFiles = (filesPath, fieldName) => {
    //     if (fieldName) {
    //         FilesUploaders[fieldName] = filesPath;
    //         setFilesUploaders(FilesUploaders);
    //     }
		
    // };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div className={classes.UploaderWrapper}>
            <div className={classes.Uploader_Instruction}>
                <p>The CSV files exported from your bank must be in English Language</p>
                <p>You can upload bank accounts statements and credit cards statements</p>
                <p>You can upload multiple csv files</p>
            </div>
            <div className={classes.FormMultilineFlex}>
                <div className={classes.FormField} style={{flex: "1 1 350px"}}>
                    <FileUploader
                        name="csv_files"
                        label="csv bank transactions"
                        APIUploadPath="/api/bankaccounts/uploadcsvtransactions/multiple"
                        APIDeletePath="/api/bankaccounts/deleteuploadedtransactions"
                        UpdateFormFunction={UpdateFormFiles}
                        InitialFiles={FilesUploaders ? FilesUploaders.csv_files : []}
                        UploadButton={{
                            Message: "Upload CSV Files",
                        }}
                        AcceptedFileTypes={["csv"]}
                        MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                        BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                        SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                        WithDragAndDrop={true}
                        WithProgressBar={true}
                        ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                    />

                    
                </div>
            </div>
            <div className={classes.Uploader_Button}>
                <Button Width="350px" onClick={HandleConvertCSV}>
                    Upload transaction files
                </Button>
            </div>
        </div>
    );

    /***************** RENDER ******************/
};

export default Component;
