/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import Page from "../../../../../artibulles-cis/Page/Page";

import _ from "lodash";
const {format, parseISO, formatISO} = require("date-fns");

import FormDemosForm from "./FormDemosFormDatePickers";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "0px",
    },
}));

const Component = (props) => {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal = "test";
    const InitialCall = {type: "Edit", ID: IdFinal};

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/zdemos/form/edit`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Customer._id;
            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Data", data);

        // var CompanySiteTypes;
        // if (data.CompanySiteTypes && data.CompanySiteTypes.length > 0) {
        //     CompanySiteTypes = data.CompanySiteTypes.map((elem) => {
        //         return {value: elem._id, label: elem.name_EN};
        //     });
        // }

        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }

        let Account = data.Account ? data.Account : null;

		let SimulatedServerDateIso = "2020-11-12T00:00:00+01:00";
		let SimulatedServerDateIsoV2 = "2015-10-13T00:00:00+01:00";
        var InitialFormValues = {
            InitiaFormValues: {
				ApiID: Account ? Account._id : "new",
				date_calendar_Keyboard :SimulatedServerDateIso.toString(),
				// date_calendar_KeyboardV2 : SimulatedServerDateIsoV2
            },
            SelectOptions: {},
            SubOptionsFull: {},
            FilesUploaders: {
                // fileuploaderName: data.tutorial.Files,
            },
            InitiaFormErrors: {},
            InitialFormErrorMain: null,
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Account: {
                        Updatable: {
                            account_number: values.account_number,
                            bic: values.bic,
                            account_owner: values.account_owner,
                            bank_name: values.bank_name,
                            isDefaultAccount: values.isDefaultAccount === true ? true : false,
                            status: values.status,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page
            // TabRef={Ref_TabNavContent}
            // ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            WithPerfectScrollBar={true}
            // ScrollY="auto"
		>
		
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                OpenInViewMode={false}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={FormDemosForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: true, Text: "Save"}}
                ButtonSaveAndQuit={{Active: false, Text: "Save"}}
                ButtonQuitNoSave={{Active: false, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: true, Text: "Reset"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
};

export default Component;
