/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Route, Switch, Redirect} from "react-router-dom";

import CustomersDashboard from "./Dashboard/CustomersDashboard";
import CustomersTable from "./CustomerTable/CustomerTable";


import CustomerRouter from "./Customer/CustomerRouter";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const CustomersRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/crm/customers">
                    <Redirect to="/crm/customers/dashboard" />
                </Route>
                <Route exact path="/crm/customers/dashboard">
                    <CustomersDashboard />
                </Route>
                <Route exact path="/crm/customers/table">
                    <CustomersTable />
                </Route>
                <Route path="/crm/customers/customer/:id">
                    <CustomerRouter />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default CustomersRouter;
