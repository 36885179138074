import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../artibulles-cis/FormComponent/FormCompontent";
import Button from "@artibulles-cis/react/Button";
import {toast} from "react-toastify";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import Page from "../../../../../artibulles-cis/Page/Page";
import Table from "../../../../../artibulles-cis/TableNew/Table";
import CardModal from "@artibulles-cis/react/CardModal";
import TabNav from "../../../../../artibulles-cis/TabNav/TabNav";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import ProjectSpendingDetails from "./ProjectSpendingDetails"
import ProjectSpendingSummary from "./ProjectSpendingSummary"


const {isValid, format, parseISO, formatISO, add, endOfMonth, getDate, getYear} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    
    TableToolbar: {
        display: "flex",
        flexDirection: "column",
    },
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        // flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
}));

const ProjectSpendingMain = React.memo(function ProjectSpendingMain(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    
    const Ref_LocaleData = useRef();

    /***************** REFS ******************/

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const Ref_URLAbsolute = useRef({
        MAIN: `${ProdURL}/api/tasks/query`,
        DELETE: `${ProdURL}/api/tasks/task/`,
        PUT: "",
        POST: "",
    });

   
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [isIntialized, setisIntialized] = useState(true);
    const [ViewCardId, setViewCardId] = useState(null);
    const [UpdatedTableData, setUpdatedTableData] = useState(null);
    const [TableComponent, setTableComponent] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState("InitSelect");
    const [InitialFormValues, setInitialFormValues] = React.useState({
        ref_company: "All",
        ref_program: [],
        ref_milestone: [],
        year: null,
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({
        ref_company: "All",
        ref_program: [],
        ref_milestone: [],
    }); //FINAL FORM VALUES

    const [FieldOptions, setFieldOptions] = useState({
        ref_company: [{label: "All", value: "All"}],
        year: [],
        quarter: [
            {label: "Q1", value: 1},
            {label: "Q2", value: 2},
            {label: "Q3", value: 3},
            {label: "Q4", value: 4},
        ],
        month: [
            {label: "January", value: 0},
            {label: "February", value: 1},
            {label: "March", value: 2},
            {label: "April", value: 3},
            {label: "May", value: 4},
            {label: "June", value: 5},
            {label: "July", value: 6},
            {label: "August", value: 7},
            {label: "September", value: 8},
            {label: "October", value: 9},
            {label: "November", value: 10},
            {label: "December", value: 11},
        ],
    });
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({
        ref_company: [],
        ref_program: [],
    }); //FORM SUBOPTIONS FOR SELECT
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
   
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                console.log("ApiRes", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    let data = ApiRes.data.data;
                    console.log("data", data);
                    if (APICallType === "InitSelect") {
                        //We initiate the select
                        console.log("select data");
                        var Companies = [];

                        if (data.Companies && data.Companies.length > 0) {
                            Companies = data.Companies.map((elem) => {
                                return {value: elem._id, label: elem.company, internal: false};
                            });
                        }

                        Companies.push({value: "All", label: "All"});

                        var Categories = [];
                        if (data.Categories && data.Categories.length > 0) {
                            Categories = data.Categories.map((elem) => {
                                return {value: elem._id, label: elem.category_name};
                            });
                        }

                        Categories.push({value: "All", label: "All"});
                        var Priorities = [];
                        if (data.Priorities && data.Priorities.length > 0) {
                            Priorities = data.Priorities.map((elem) => {
                                return {value: elem._id, label: elem.piority_name, order: elem.priority_order};
                            });
                        }

                        var ProgramsSubOptions = [];
                        if (data.Programs && data.Programs.length > 0) {
                            ProgramsSubOptions = data.Programs;
                        }
                        console.log("ProgramsSubOptions", ProgramsSubOptions);
                        var MilestonesSubOptions = [];
                        if (data.Milestones && data.Milestones.length > 0) {
                            MilestonesSubOptions = data.Milestones;
                        }

                        // let ProgramSubOptionsInit = [];
                        // if (task && task.ref_company) {
                        //     //We initialize the suboptions

                        //     ProgramsSubOptions.forEach((elem) => {
                        //         if (elem.ref_company === task.ref_company) {
                        //             let newsub = {value: elem.id, label: elem.program_name};
                        //             ProgramSubOptionsInit.push(newsub);
                        //         }
                        //     });
                        // }

                        setFieldOptions({
                            ...FieldOptions,
                            ...{
                                ref_company: Companies,
                                ref_taskPriority: Priorities,
                                ref_taskCategory: Categories,
                                ref_program: [],
                                ref_milestone: [],
                            },
                        });

                        setFieldSubOptionsFull({
                            ...FieldOptions,
                            ...{
                                ref_company: ProgramsSubOptions,
                                ref_program: MilestonesSubOptions,
                            },
                        });

                        setAPICallType(null);
                    } else {
                        console.log("Entries", data);
                        let TimeEntries = data.TimeEntries;
                        if (TimeEntries && Array.isArray(TimeEntries) && TimeEntries.length > 0) {
                            Ref_LocaleData.current = data;
                        } else {
                            Ref_LocaleData.current = null;
                        }

                        setisIntialized(true);
                    }
                }

                if (APICallType === "deleteone") {
                    toast.success(`Entry deleteted`, {autoClose: true});
                    setisIntialized(true);
                    setAPICallType(null);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (APICallType !== "InitSelect") {
            //Calling the Api whenever the selects are changing to create the query
            // console.log("FormValues", FormValues);
            // let CompanyName = FormValues.ref_company;
            // let ApiCall = {url: `${ProdURL}/api/tasks/time/entriesv2/?is_work=true&exclude_running=true`, type: "get"};
            // if (CompanyName !== "All") {
            //     ApiCall = {url: `${ProdURL}/api/tasks/time/entriesv2/?is_work=true&exclude_running=true`, type: "get"};
            // }
            // // let URLBase = `${ProdURL}/api/tasks/query/?`;
            // // let Query = {
            // // 	dueonly: null,
            // // 	is_personal: null,
            // // 	is_late: null
            // // }
            // // if (FormValues.company_name) {
            // // } else {
            // //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/programs/programsqueries`;
            // // }
            // // if (FormValues.is_completed === "Due") {
            // //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/?dueonly=true`;
            // // } else if (FormValues.is_completed === "Completed") {
            // //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/?dueonly=false`;
            // // } else if (FormValues.is_completed === "All") {
            // //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/`;
            // // }
            // setLoading(true);
            // setisIntialized(false);
            // async function APIInternal() {
            //     const res = await APICallExternal(ApiCall);
            //     setApiRes(res);
            //     setLoading(false);
            // }
            // APIInternal();
        }
    }, [FormValues, APICallType]);

    useEffect(() => {
        //Called once to get the company list and initiate the selects
        setLoading(true);

        const ApiCall = {url: `${ProdURL}/api/tasks/time/entriesinit`, type: "get"};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        //Reload the table
        setisIntialized(false);
        setTimeout(() => {
            setisIntialized(true);
        }, 50);
    };

    const HandleUpdateFormValues = (field, values) => {
        //Note!!! with Date Pickers it's mandatory to use the Ref and not the state ....impossible to understand why but that is it
        // console.log("FORMDEMOS - HANDLE UPDATE", field, values);
        // console.log("FormValues", Ref_FormValues.current);

        let value = values.Value;

        setFormValues({...FormValues, ...{[field]: value}});
    };

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        if (field === "ref_company") {
            //We update the Options of the ConditionalSelect2
            let SubOptions = [];
            if (Array.isArray(SelectedValues) && SelectedValues.length > 0) {
                console.log("customer", SelectedValues);
                //We need to select the subprograms for each customers
                if (SelectedValues.includes("All")) {
                    FieldSubOptionsFull.ref_company.forEach((elem) => {
                        let newsub = {value: elem.id, label: elem.program_name};
                        SubOptions.push(newsub);
                    });
                } else {
                    SelectedValues.forEach((cust) => {
                        FieldSubOptionsFull.ref_company.forEach((elem) => {
                            if (elem.ref_company === cust) {
                                let newsub = {value: elem.id, label: elem.program_name};
                                SubOptions.push(newsub);
                            }
                        });
                    });
                }
            } else {
                FieldSubOptionsFull.ref_company.forEach((elem) => {
                    if (elem.ref_company === SelectedValues) {
                        let newsub = {value: elem.id, label: elem.program_name};
                        SubOptions.push(newsub);
                    }
                });
            }
            setFieldOptions({...FieldOptions, ...{ref_program: SubOptions}});
            //We also need to reset the Conditional 2;
            setFormValues({...UpdatedFormValues, ...{ref_program: null}});
        }
        if (field === "ref_program") {
            let SubOptions = [];
            if (Array.isArray(SelectedValues) && SelectedValues.length > 0) {
                //We need to select the submilestones for each program
                SelectedValues.forEach((prog) => {
                    FieldSubOptionsFull.ref_program.forEach((elem) => {
                        if (elem.ref_program === prog) {
                            let newsub = {value: elem.id, label: elem.milestone_name};
                            SubOptions.push(newsub);
                        }
                    });
                });
            } else {
                FieldSubOptionsFull.ref_program.forEach((elem) => {
                    if (elem.ref_program === SelectedValues) {
                        let newsub = {value: elem.id, label: elem.milestone_name};
                        SubOptions.push(newsub);
                    }
                });
            }

            setFieldOptions({...FieldOptions, ...{ref_milestone: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ref_milestone: null}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleSearchDB = () => {
        console.log("SearchDB");
        console.log("FormValues", FormValues);
        let Query = null;

        if (FormValues.ref_milestone && FormValues.ref_milestone.length > 0) {
            //It means we only search milestones
            Query = Query ? Query + "&milestones=" + FormValues.ref_milestone : "milestones=" + FormValues.ref_milestone;
        } else if (FormValues.ref_program && FormValues.ref_program.length > 0) {
            //It means we only search programs
            Query = Query ? Query + "&programs=" + FormValues.ref_program : "programs=" + FormValues.ref_program;
        } else if (FormValues.ref_company && FormValues.ref_company.length > 0) {
            if (FormValues.ref_company.includes("All")) {
            } else {
                //It means we only search programs
                Query = Query ? Query + "&customers=" + FormValues.ref_company : "customers=" + FormValues.ref_company;
            }
        }

        let Year = FormValues.year ? getYear(parseISO(FormValues.year)) : null;
        let Quarter = FormValues.quarter;
        let Month = FormValues.month;

        let QueryStartDate, QueryEndDate;

        if (Month) {
            console.log("Year", Year, "Month : ", Month);
            QueryStartDate = new Date(Year, Month, 1, 0, 0, 1);
            let EndofMonth = getDate(endOfMonth(QueryStartDate));
            QueryEndDate = new Date(Year, Month, EndofMonth, 23, 59, 59);
            Query = Query ? Query + "&datestart=" + QueryStartDate : "datestart=" + QueryStartDate;
            Query = Query ? Query + "&dateend=" + QueryEndDate : "dateend=" + QueryEndDate;
        } else if (Quarter) {
            if (Quarter === "Q1") {
            } else if (Quarter === "Q2") {
            } else if (Quarter === "Q3") {
            } else if (Quarter === "Q4") {
            }
        } else if (Year) {
        }

        let ApiCall;

        if (Query) {
            ApiCall = {url: `${ProdURL}/api/tasks/time/entriesv2/?${Query}`, type: "get"};
        } else {
            ApiCall = {url: `${ProdURL}/api/tasks/time/entriesv2/`, type: "get"};
        }

        // if (CompanyName !== "All") {
        //     ApiCall = {url: `${ProdURL}/api/tasks/time/entriesv2/?is_work=true&exclude_running=true`, type: "get"};
        // }

        // let URLBase = `${ProdURL}/api/tasks/query/?`;
        // let Query = {
        // 	dueonly: null,
        // 	is_personal: null,
        // 	is_late: null
        // }
        // if (FormValues.company_name) {
        // } else {
        //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/programs/programsqueries`;
        // }
        // if (FormValues.is_completed === "Due") {
        //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/?dueonly=true`;
        // } else if (FormValues.is_completed === "Completed") {
        //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/?dueonly=false`;
        // } else if (FormValues.is_completed === "All") {
        //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/`;
        // }

        setLoading(true);

        setisIntialized(false);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    let FinalTableComponent;
    if (isIntialized === true) {
        FinalTableComponent = TableComponent;
    } else {
        FinalTableComponent = null;
    }
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <div>Details</div>
                </div>
            </CardModal>
        );
    }

    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPage={classes.PageContentExtends}>
            {ModalCardView}
            <div className={classes.TableToolbar}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 450px"}}>
                        <FormCompontent
                            Name="ref_company"
                            InitialValue={InitialFormValues.ref_company ? InitialFormValues.ref_company : null}
                            FormValue={FormValues.ref_company ? FormValues.ref_company : null}
                            OutputSelectedValues={(values) => handleSelectChange("ref_company", values)}
                            SelectOptions={FieldOptions.ref_company}
                            Component="Select"
                            Variant="Outlined"
                            Label="Company"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                            MultiSelect={true}
                            SortSelectOptions="Asc"
                            // SortField="order"
                            SortFirstItemName={{Exact: false, Name: "All"}}
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="100px"
                            LabelAlign="Left"
                            Required={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 1 400px"}}>
                        <FormCompontent
                            Name="ref_program"
                            InitialValue={InitialFormValues.ref_program ? InitialFormValues.ref_program : null}
                            FormValue={FormValues.ref_program ? FormValues.ref_program : null}
                            OutputSelectedValues={(values) => handleSelectChange("ref_program", values)}
                            SelectOptions={FieldOptions.ref_program}
                            Component="Select"
                            Variant="Outlined"
                            Label="Program"
                            NoMessage={false}
                            MultiSelect={true}
                            SortSelectOptions="Asc"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            LabelMaxWidth="100px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 1 400px"}}>
                        <FormCompontent
                            Name="ref_milestone"
                            InitialValue={InitialFormValues.ref_milestone ? InitialFormValues.ref_milestone : null}
                            FormValue={FormValues.ref_milestone ? FormValues.ref_milestone : null}
                            OutputSelectedValues={(values) => handleSelectChange("ref_milestone", values)}
                            SelectOptions={FieldOptions.ref_milestone}
                            Component="Select"
                            Variant="Outlined"
                            Label="Milestone"
                            NoMessage={false}
                            MultiSelect={true}
                            SortSelectOptions="Asc"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            LabelMaxWidth="100px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{maxWidth: "1000px"}}>
                    <div className={classes.FormField} style={{flex: "0 1 60px", width: "60px", maxWidth: "60px"}}>
                        <FormCompontent
                            Name="year"
                            InitialValue={InitialFormValues.year ? InitialFormValues.year : null}
                            FormValue={FormValues.year ? FormValues.year : null}
                            OutputValue={(values) => HandleUpdateFormValues("year", values)}
                            Component="DatePicker"
                            Variant="OutlinedLabel"
                            Label="Year"
                            NoMessage={false}
                            // meta={{error: FormErrors.month_year_picker, invalid: FormErrors.month_year_picker ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("month_year_picker", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            PickerDateType="Year"
                            PickerOnly={false}
                            InputFormat="yyyy"
                            FieldMaxWidth="60px"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                        <FormCompontent
                            Name="quarter"
                            InitialValue={InitialFormValues.quarter ? InitialFormValues.quarter : null}
                            FormValue={FormValues.quarter ? FormValues.quarter : null}
                            OutputSelectedValues={(values) => handleSelectChange("quarter", values)}
                            SelectOptions={FieldOptions.quarter}
                            Component="Select"
                            Variant="OutlinedLabel"
                            Label="Quarter"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            // SortField="order"
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            Required={false}
                            Clearable={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                        <FormCompontent
                            Name="month"
                            InitialValue={InitialFormValues.month ? InitialFormValues.month : null}
                            FormValue={FormValues.year ? FormValues.year : null}
                            OutputSelectedValues={(values) => handleSelectChange("month", values)}
                            SelectOptions={FieldOptions.month}
                            Component="Select"
                            Variant="OutlinedLabel"
                            Label="Month"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            // SortField="order"
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            Required={false}
                        />
                    </div>
                </div>
                <Button onClick={HandleSearchDB}> Search</Button>
            </div>
            <TabNav
                NoLink={true}
                ActiveIndicator={{
                    WithIndicator: true,
                    Position: "Bottom",
                }}
                TabMenuContent={[
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Summary</div>
                            </div>
                        ),

                        TabContent: <ProjectSpendingSummary TableData={Ref_LocaleData.current} Companies={FormValues.ref_company} Programs={FormValues.ref_program} Milestones = {FormValues.ref_milestone} />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Details</div>
                            </div>
                        ),

                        TabContent: <ProjectSpendingDetails TableData={Ref_LocaleData.current}/>,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Update VAT</div>
                            </div>
                        ),

                        TabContent: <div>VAT</div>,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Check VAT</div>
                            </div>
                        ),

                        TabContent: <div>Check</div>,
                    },
                ]}
            />
            
        </Page>
    );

    /***************** RENDER ******************/
});

export default ProjectSpendingMain;
