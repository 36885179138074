import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Rnd} from "react-rnd";
import PerfectScrollBarWrapper from "../PerfectScrollBarWrapper/PerfectScrollBarWrapper";
import useDetectNavigateAway from "../utils/useDetectNavigateAway";

import {CloseIcon} from "@artibulles-cis/react-icons";

const LockedstylesWithProps = (props) => {
    return {
        cardWindowCentered: {
            padding: `${props.CardWindowPaddingVertical} ${props.CardWindowPaddingHorizontal} ${props.CardWindowPaddingVertical} ${props.CardWindowPaddingHorizontal}`,
            zIndex: props.ZIndex ? props.ZIndex : null,
        },
    };
};

const Lockedstyles = {
    cardWindowCentered: {
        position: "fixed",
        display: "flex",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "2000",

        boxSizing: "border-box",
    },
    cardWindowWithParent: {
        position: "fixed",
        display: "flex",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        zIndex: "2000",
    },
    cardCentered: {
        display: "flex",
        position: "relative",
        marginTop: 0,
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        boxSizing: "border-box",
    },

    cardWithParent: {
        flex: "1 1 auto",
        display: "flex",
        position: "relative",
        marginTop: 0,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflow: "hidden",
        boxSizing: "border-box",
    },
};
const styles = createUseStyles((theme) => ({
    CardWindow: theme.CardWindow,
    Card: theme.Card,
    CloseCard_Button: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "0px",
        right: "0px",
        height: "35px",
        width: "35px",
        zIndex: "100",
    },
    CardTopNavbar: {
        width: "100%",
        flex: "0 0 50px",
        display: "flex",
        alignItems: "center",
    },
    CardRndStyle: {
        display: "flex",
    },
    CardContent: {
        flex: "1 1 auto",
        position: "relative",
        width: "100%",
        // overflowY: "auto",
        overflowY: "hidden",
		overflowX: "hidden",
		
        zIndex: "10",
    },
    CardContentWithPerfectScrollBar: {
        position: "relative",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
    },
}));

/**
 * Create a Modal Window With card and multiple Options.
 * The Card can be centered on the screen :
 *  -It can have a maxWidth and Height properties
 *  -Or it can take up the full space with a WindowMaxWidth prop and PaddingVertical and PaddingHorizontal to insert gaps
 * The Card can also be displayed inside a parent element (simulated position), in that case the parent id needs to be specified.
 * The parent css should at least have : width :"100%", position :"relative"
 *
 */

const CardModal = React.memo(function CardModal(props) {
    const {
        CardID,
        ShowCard,
        CloseCard,
        ParentRefCompID,
        ParentCompRef,
        CardMaxWidth,
        CloseOnClickOutside,
        WithCloseButton,
        CloseButtonStyle,
        CustomCloseButton,
        TopBarContent,
        CardHeight,
        WindowMaxWidth,
        CardWindowPaddingVertical,
        CardWindowPaddingHorizontal,
        AlertUserWhenLeavingPage,
        IsDraggable,
        IsResizable,
        WithPerfectScrollbar,
        WindowBackgroundColor,
        CardBackgroundColor,
        CardBackground,
        //eslint-disable-next-line
        ZIndex,
        ClassOverrideCardWindow,
        ClassExtendCardWindow,
        ClassOverrideCard,
        ClassExtendCard,
        ClassOverrideTopToolBar,
        ClassExtendTopToolBar,
        children,
    } = props;

    /***************** CONST ******************/
    const theme = useTheme();
    const classes = styles({...props, theme});
    
    /***************** CONST ******************/

    /***************** REF ******************/
    const CardRef = React.useRef();
    const Ref_FinalCloseOnClickOutside = React.useRef();
    /***************** REF ******************/

    /***************** STATE ******************/
    const [ShowCardInt, setShowCardInt] = React.useState(ShowCard);
    const [FitParentComponent, setFitParentComponent] = React.useState(false);
    const [RnDPosition, setRnDPosition] = React.useState({x: 100, y: 200});
    const [Position, setPosition] = React.useState({});
    const [CardDimension, setCardDimension] = React.useState({});
    const [Init, setInit] = React.useState(true);
    /***************** STATE ******************/

    /***************** CALLBACKS ******************/
    //Function to calculate the card Element Position for RnD Element
    const CalculateRnDCardPosition = React.useCallback(() => {
        var RndWidth, RndHeight, top, left;
        if (CardRef.current) {
            if (CardMaxWidth) {
                RndWidth = parseInt(CardMaxWidth);
                top = CardRef.current.getBoundingClientRect().top;
                left = CardRef.current.getBoundingClientRect().left;
            } else {
                top = parseInt(CardWindowPaddingVertical);
                left = parseInt(CardWindowPaddingHorizontal);
                if (WindowMaxWidth) {
                    if (window.innerWidth > parseInt(WindowMaxWidth)) {
                        RndWidth = parseInt(WindowMaxWidth) - 2 * parseInt(CardWindowPaddingHorizontal);
                    } else {
                        RndWidth = window.innerWidth - 2 * parseInt(CardWindowPaddingHorizontal);
                    }
                } else {
                    RndWidth = window.innerWidth - 2 * parseInt(CardWindowPaddingHorizontal);
                }
            }
            if (CardHeight) {
                RndHeight = parseInt(CardHeight);
            } else {
                RndHeight = window.innerHeight - 2 * parseInt(CardWindowPaddingVertical);
            }
            setRnDPosition({x: left, y: top, width: RndWidth, height: RndHeight});
            setInit(false);
        }
    }, [CardRef, CardHeight, CardMaxWidth, CardWindowPaddingHorizontal, CardWindowPaddingVertical, WindowMaxWidth]);

    //Function to calculate the position of the card element when ParentId is specified
    const CalculateCardPositionWithParentID = React.useCallback(() => {
        //Get the Parent Eement From the ID
        if (ParentRefCompID && document.getElementById(ParentRefCompID)) {
            let top = document.getElementById(ParentRefCompID).getBoundingClientRect().top;
            let left = document.getElementById(ParentRefCompID).getBoundingClientRect().left;
            //We then need to calculate the accecptable Height for the component to fit inside the window (it's a card so it shouldn't be overflowing)
            // let CardHeight = window.innerHeight - top;

            var PaddingV = CardWindowPaddingVertical ? 2 * parseFloat(CardWindowPaddingVertical) : 0;
            var PaddingH = CardWindowPaddingHorizontal ? 2 * parseFloat(CardWindowPaddingHorizontal) : 0;

            let CardHeight = document.getElementById(ParentRefCompID).getBoundingClientRect().height - PaddingV;
            let CardWidth = document.getElementById(ParentRefCompID).getBoundingClientRect().width - PaddingH;
            setPosition({x: left, y: top, height: CardHeight, width: CardWidth});
            setCardDimension({width: CardWidth + "px", height: CardHeight + "px"});
        } else if (ParentCompRef && ParentCompRef.current) {
            let top = ParentCompRef.current.getBoundingClientRect().top;
            let left = ParentCompRef.current.getBoundingClientRect().left;
            //We then need to calculate the accecptable Height for the component to fit inside the window (it's a card so it shouldn't be overflowing)
            // let CardHeight = window.innerHeight - top;
            PaddingV = CardWindowPaddingVertical ? 2 * parseFloat(CardWindowPaddingVertical) : 0;
            PaddingH = CardWindowPaddingHorizontal ? 2 * parseFloat(CardWindowPaddingHorizontal) : 0;

            let CardHeight = ParentCompRef.current.getBoundingClientRect().height - PaddingV;
            let CardWidth = ParentCompRef.current.getBoundingClientRect().width - PaddingH;

            var FinalCardHeight, FinalCardWidth;
            if (CardHeight > 0) {
                FinalCardHeight = CardHeight + "px";
            }
            if (CardWidth > 0) {
                FinalCardWidth = CardWidth + "px";
            }

            setPosition({x: left, y: top, height: CardHeight, width: CardWidth});
            setCardDimension({width: FinalCardWidth, height: FinalCardHeight});
        }
    }, [ParentRefCompID, ParentCompRef, CardWindowPaddingHorizontal, CardWindowPaddingVertical]);

    //Function to calculate the Dimensions of the Card Element when MaxWidth and/or MaxHeight are not specified
    const CalculateCardDimensions = React.useCallback(() => {
        var FinalCardWidth, FinalCardHeight;

        if (CardMaxWidth) {
            if (parseInt(CardMaxWidth) > window.innerWidth) {
                FinalCardWidth = window.innerWidth;
            } else {
                FinalCardWidth = parseInt(CardMaxWidth);
            }
        } else {
            if (WindowMaxWidth) {
                if (window.innerWidth > parseInt(WindowMaxWidth)) {
                    FinalCardWidth = parseInt(WindowMaxWidth) - 2 * parseInt(CardWindowPaddingHorizontal);
                } else {
                    FinalCardWidth = window.innerWidth - 2 * parseInt(CardWindowPaddingHorizontal);
                }
            } else {
                FinalCardWidth = window.innerWidth - 2 * parseInt(CardWindowPaddingHorizontal);
            }
        }
        if (CardHeight) {
            if (parseInt(CardHeight) > window.innerHeight) {
                FinalCardHeight = window.innerHeight;
            } else {
                FinalCardHeight = parseInt(CardHeight);
            }
        } else {
            FinalCardHeight = window.innerHeight - 2 * parseInt(CardWindowPaddingVertical);
        }
        setCardDimension({width: FinalCardWidth + "px", height: FinalCardHeight + "px"});
    }, [CardHeight, CardMaxWidth, CardWindowPaddingHorizontal, CardWindowPaddingVertical, WindowMaxWidth]);

    //Handle Click outside
    const HandleClickOutside = React.useCallback(
        (e) => {
            if (CardRef.current && CardRef.current.contains(e.target)) {
            } else {
                if (CloseCard) {
                    CloseCard();
                } else {
                    CloseWindow();
                }
            }
        },
        [CardRef, CloseCard]
    );

    /***************** CALLBACKS ******************/

    /***************** EFFECTS ******************/
    React.useEffect(() => {
        if (ParentCompRef || ParentRefCompID) {
            setFitParentComponent(true);
        } else {
            setFitParentComponent(false);
        }
    }, [ParentCompRef, ParentRefCompID]);

    React.useEffect(() => {
        if (ShowCard === true) {
            setShowCardInt(true);
        } else {
            setShowCardInt(false);
        }
    }, [ShowCard]);
    //Calculating Card position when componenent loads
    React.useEffect(() => {
        if (CardRef) {
            if (IsDraggable) {
                CalculateRnDCardPosition();
            } else {
                CalculateCardDimensions();
            }
            if (FitParentComponent) {
                CalculateCardPositionWithParentID();
            }
        }
    }, [IsDraggable, FitParentComponent, CalculateRnDCardPosition, CardRef, ShowCardInt, CalculateCardDimensions, CalculateCardPositionWithParentID]);

    // Card Position - Resize Event Listener
    React.useEffect(() => {
        if (FitParentComponent) {
            window.addEventListener("resize", CalculateCardPositionWithParentID);
            return function cleanup() {
                window.removeEventListener("resize", CalculateCardPositionWithParentID);
            };
        } else {
            window.addEventListener("resize", CalculateCardDimensions);
            return function cleanup() {
                window.removeEventListener("resize", CalculateCardDimensions);
            };
        }
    }, [FitParentComponent, CalculateCardDimensions, CalculateCardPositionWithParentID]);

    //Adding Click Outisde Listener
    React.useEffect(() => {
        if (IsDraggable) {
            Ref_FinalCloseOnClickOutside.current = false;
        } else {
            Ref_FinalCloseOnClickOutside.current = CloseOnClickOutside;
        }
    }, [IsDraggable, CloseOnClickOutside]);

    React.useEffect(() => {
        if (Ref_FinalCloseOnClickOutside.current === true) {
            document.addEventListener("mousedown", HandleClickOutside);

            return () => {
                document.removeEventListener("mousedown", HandleClickOutside);
            };
        }
    }, [Ref_FinalCloseOnClickOutside, HandleClickOutside]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const CloseWindow = () => {
        setShowCardInt(false);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    /***************** RENDER ******************/

    const DefaultCloseButtonStyle = {
        IconSize: "25px",
        Frame: "circle",
        SVGStrokeColor: "black",
        SVGStrokeWidth: "10px",
        FrameFillColor: "white",
        InnerPadding: "35px",
        SVGStrokeHoverColor: "black",
        FrameFillColorHover: "grey",
    };
    var FinalCloseButtonStyle = {...DefaultCloseButtonStyle, ...CloseButtonStyle};

    function CloseButtonComponent() {
        if (WithCloseButton) {
            return (
                <div className={classes.CloseCard_Button} onClick={CloseCard ? CloseCard : CloseWindow}>
                    <CloseIcon
                        IconSize={FinalCloseButtonStyle.IconSize}
                        Frame={FinalCloseButtonStyle.Frame}
                        SVGStrokeColor={FinalCloseButtonStyle.SVGStrokeColor}
                        SVGStrokeWidth={FinalCloseButtonStyle.SVGStrokeWidth}
                        FrameFillColor={FinalCloseButtonStyle.FrameFillColor}
                        InnerPadding={FinalCloseButtonStyle.InnerPadding}
                        SVGStrokeHoverColor={FinalCloseButtonStyle.SVGStrokeHoverColor}
                        FrameFillColorHover={FinalCloseButtonStyle.FrameFillColorHover}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    var CustomCloseButtonComponent = null;
    if (CustomCloseButton) {
        CustomCloseButtonComponent = React.cloneElement(CustomCloseButton, {
            onClick: CloseCard ? CloseCard : CloseWindow,
        });
    }

    var TopToolbar = null;

    if (TopBarContent) {
        TopToolbar = <div className={ClassOverrideTopToolBar ? ClassOverrideTopToolBar : clsx(ClassExtendTopToolBar, classes.CardTopNavbar)}>{TopBarContent}</div>;
    }

    var FinalComponent;

    const DefaultReziableCard = {bottom: false, bottomLeft: false, bottomRight: false, left: false, right: false, top: false, topLeft: false, topRight: false};
    var ReziableCard;
    if (IsResizable === true) {
        ReziableCard = {bottom: true, bottomLeft: true, bottomRight: true, left: true, right: true, top: true, topLeft: true, topRight: true};
    } else if (IsResizable === false) {
        ReziableCard = false;
    } else {
        ReziableCard = {...DefaultReziableCard, ...IsResizable};
    }

    //Adding leave page hook
    useDetectNavigateAway(AlertUserWhenLeavingPage);

    var CardConditionalStyle;
    if (FitParentComponent) {
        CardConditionalStyle = {
            left: Position.x + "px",
            top: Position.y + "px",
        };
    }
    var LockedCardStyle;
    if (FitParentComponent) {
        LockedCardStyle = "cardWithParent";
    } else {
        LockedCardStyle = "cardCentered";
    }

    if (IsDraggable) {
        if (Init) {
            FinalComponent = (
                <div
                    id={CardID}
                    ref={CardRef}
                    style={{
                        ...Lockedstyles[LockedCardStyle],
                        flex: `0 1 ${CardMaxWidth}`,
                        height: CardHeight ? CardHeight : "100%",
                        background: CardBackground,
                        backgroundColor: CardBackgroundColor,
                        opacity: "1",
                    }}
                    className={ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)}
                >
                    {TopToolbar}
                    <CloseButtonComponent />
                    {children}
                </div>
            );
        } else {
            if (WithPerfectScrollbar) {
                FinalComponent = (
                    <Rnd default={RnDPosition} bounds="window" enableResizing={ReziableCard} className={classes.CardRndStyle}>
                        <div
                            id={CardID}
                            ref={CardRef}
                            style={{
                                ...Lockedstyles[LockedCardStyle],
                                ...CardConditionalStyle,
                                flex: "1 1 auto",
                                height: "100%",
                                background: CardBackground,
                                backgroundColor: CardBackgroundColor,
                            }}
                            className={FitParentComponent === false ? (ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)) : null}
                        >
                            {TopToolbar}
                            <CloseButtonComponent />
                            <div className={classes.CardContentWithPerfectScrollBar}>
                                <PerfectScrollBarWrapper Active={true} ScrollX={false} ScrollY={true} DisableDocumentSroll={true}>
                                    {children}
                                </PerfectScrollBarWrapper>
                            </div>
                        </div>
                    </Rnd>
                );
            } else {
                FinalComponent = (
                    <Rnd default={RnDPosition} bounds="window" enableResizing={ReziableCard} className={classes.CardRndStyle}>
                        <div
                            id={CardID}
                            ref={CardRef}
                            style={{
                                ...Lockedstyles[LockedCardStyle],
                                ...CardConditionalStyle,
                                flex: "1 1 auto",
                                height: "100%",
                                background: CardBackground,
                                backgroundColor: CardBackgroundColor,
                            }}
                            className={FitParentComponent === false ? (ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)) : null}
                        >
                            {TopToolbar}
                            <CloseButtonComponent />

                            <div className={classes.CardContent}>{children}</div>
                        </div>
                    </Rnd>
                );
            }
        }
    } else {
        if (WithPerfectScrollbar) {
            FinalComponent = (
                <div
                    id={CardID}
                    ref={CardRef}
                    style={{
                        ...Lockedstyles[LockedCardStyle],
                        ...CardConditionalStyle,
                        flex: FitParentComponent
                            ? `0 0 ${Position.width}px`
                            : CardMaxWidth
                            ? `0 1 ${CardDimension.width}`
                            : `0 1 ${parseInt(WindowMaxWidth) - 2 * parseInt(CardWindowPaddingHorizontal)}px`,
                        height: CardHeight ? CardDimension.height : FitParentComponent ? CardDimension.height : "100%",
                        background: CardBackground,
                        backgroundColor: CardBackgroundColor,
                    }}
                    className={FitParentComponent === false ? (ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)) : null}
                >
                    {TopToolbar}
                    <CloseButtonComponent />
                    <div
                        className={classes.CardContentWithPerfectScrollBar}
                        style={{
                            height: CardHeight ? CardDimension.height : FitParentComponent ? CardDimension.height : "100%",
                        }}
                    >
                        <PerfectScrollBarWrapper Active={true} ScrollX={false} ScrollY={true} DisableDocumentSroll={true}>
                            {children}
                        </PerfectScrollBarWrapper>
                    </div>
                </div>
            );
        } else {
            FinalComponent = (
                <div
                    id={CardID}
                    ref={CardRef}
                    style={{
                        ...Lockedstyles[LockedCardStyle],
                        ...CardConditionalStyle,
                        flex: FitParentComponent
                            ? `0 0 ${Position.width}px`
                            : CardMaxWidth
                            ? `0 1 ${CardDimension.width}`
                            : `0 1 ${parseInt(WindowMaxWidth) - 2 * parseInt(CardWindowPaddingHorizontal)}px`,
                        // height: CardHeight ? CardDimension.height : "100%",
                        height: FitParentComponent ? CardDimension.height : CardHeight ? CardDimension.height : "100%",
                        background: CardBackground,
                        backgroundColor: CardBackgroundColor,
                    }}
                    className={FitParentComponent === false ? (ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)) : null}
                >
                    {CustomCloseButtonComponent}
                    {TopToolbar}

                    <CloseButtonComponent />
                    <div className={classes.CardContent}>{children}</div>
                </div>
            );
        }
    }

    var LockedCardWindowStyle;
    if (FitParentComponent) {
        LockedCardWindowStyle = "cardWindowWithParent";
    } else {
        LockedCardWindowStyle = "cardWindowCentered";
    }

    var FinalFinalComponent = null;

    let RootElement = document.getElementById("root");
    // let ModalPlaceHolder = document.getElementById("modal-root");

    if (ShowCardInt === true) {
        FinalFinalComponent = (
            <div
                style={{
                    ...Lockedstyles[LockedCardWindowStyle],
                    ...LockedstylesWithProps(props).cardWindowCentered,
                    background: WindowBackgroundColor,
                }}
                className={ClassOverrideCardWindow ? ClassOverrideCardWindow : clsx(ClassExtendCardWindow, classes.CardWindow)}
            >
                {FinalComponent}
            </div>
        );
    }

    return <React.Fragment>{ReactDOM.createPortal(FinalFinalComponent, RootElement)}</React.Fragment>;
});

CardModal.defaultProps = {
    CardID: null,

    WindowMaxWidth: "2500px",
    ParentRefCompID: null,
    ParentCompRef: null,
    CardMaxWidth: null,
    CardHeight: null,
    CardWindowPaddingVertical: "0px",
    CardWindowPaddingHorizontal: "0px",
    AlertUserWhenLeavingPage: false,
    ShowCard: false,
    CloseCard: null,
    CloseOnClickOutside: false,
    WithCloseButton: false,
    CloseButtonStyle: null,
    CustomCloseButton: null,
    TopBarContent: null,
    IsDraggable: false,
    IsResizable: false,
    WithPerfectScrollbar: false,
    WindowBackgroundColor: null,
    CardBackgroundColor: null,
    CardBackground: null,
    ZIndex: null,
    ClassOverrideCardWindow: null,
    ClassExtendCardWindow: null,
    ClassOverrideCard: null,
    ClassExtendCard: null,
    ClassOverrideTopToolBar: null,
    ClassExtendTopToolBar: null,
};

CardModal.propTypes = {
    /**
     * ID of the Card element if needed
     */
    CardID: PropTypes.any,
    /**
     * Window Max Width (only used to calculate the width of the card if no MaxWidth is specified)
     */
    WindowMaxWidth: PropTypes.any,
    /**
     * If you want the card window to appear at a certain position, you can specify a parentID (ref to the component)
     * The Card Window will take that exact position.a1
     * This can be useful if you want to prevent the user from clicking anything in your app but still want to show the card as if it was part of the app
     */
    ParentRefCompID: PropTypes.any,
    /**
     * If you want the card window to appear at a certain position, you can specify a parentID (ref to the component)
     * The Card Window will take that exact position.a1
     * This can be useful if you want to prevent the user from clicking anything in your app but still want to show the card as if it was part of the app
     */
    ParentCompRef: PropTypes.any,
    /**
     * MaxWidth of the Card Window
     */
    CardMaxWidth: PropTypes.any,
    /**
     * Height of the Card Window
     */
    CardHeight: PropTypes.any,
    /**
     * Vertical spacing between the browser window and the card when no Height is specified
     */
    CardWindowPaddingVertical: PropTypes.any,
    /**
     * Horizontal spacing between the browser window and the card when no width is specified
     */
    CardWindowPaddingHorizontal: PropTypes.any,
    /**
     * Add an event listener to warn the user if he leaves the page or close the browser
     * Note : doesn't work with back and next browser button
     */
    AlertUserWhenLeavingPage: PropTypes.bool,
    /**
     * Show or Hide the Card
     */
    ShowCard: PropTypes.bool,
    /**
     * function used to close the card from outside
     */
    CloseCard: PropTypes.any,

    /**
     * Close the card on Click Outside
     */
    CloseOnClickOutside: PropTypes.bool,
    /**
     * Add a close Button
     */
    WithCloseButton: PropTypes.bool,
    /**
     * Props of the CloseIcon (refer to Artiweb-icons props) : use it to pass an object with props for instance : CloseButtonStyle={{IconSize:"15px"}}
     */
    CloseButtonStyle: PropTypes.any,
    /**
     * Pass a custom close button Dom or ReactComponent. OnClick will be added automatically
     */
    CustomCloseButton: PropTypes.any,
    /**
     * ID of the Card element if needed
     */
    TopBarContent: PropTypes.any,

    /**
     * Specify If the Card is Draggable.
     */
    IsDraggable: PropTypes.bool,
    /**
     * Specify If the Card Window is Resizable and what handle to be included
     * if true => all handles will be shown,
     * if false => not resizable (default)
     * if {Object} => activate specified handles : {bottom: true, bottomLeft: true, bottomRight: true, left: true, right: true, top: true, topLeft: true, topRight: true}
     */
    IsResizable: PropTypes.any,
    /**
     * Specify If a perfectScrollbar should be added or not inside the card window
     */
    WithPerfectScrollbar: PropTypes.bool,
    /**
     * Color of the window background
     */
    WindowBackgroundColor: PropTypes.any,
    /**
     * Color of the card
     */
    CardBackgroundColor: PropTypes.any,
    /**
     * Background of the card
     */
    CardBackground: PropTypes.any,
    /**
     * ZIndex for the card Window
     */
    ZIndex: PropTypes.any,
    /**
     * Override the class
     */
    ClassOverrideCardWindow: PropTypes.any,
    /**
     * Extend the class
     */
    ClassExtendCardWindow: PropTypes.any,
    /**
     * Override the class
     */
    ClassOverrideCard: PropTypes.any,
    /**
     * Extend the class
     */
    ClassExtendCard: PropTypes.any,
    /**
     * Override the class
     */
    ClassOverrideTopToolBar: PropTypes.any,
    /**
     * Extend the class
     */
    ClassExtendTopToolBar: PropTypes.any,
};

export default CardModal;
