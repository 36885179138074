import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "10px 5px 10px 5px",
        boxSizing: "border-box",
        // maxWidth: "1200px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormFieldGroup: {
        margin: "2px 5px 2px 5px",
    },
    FormField: {
        flex: "0 1 600px",
        flexDirection: "row",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {InitalFormValuesFromParent, FieldOptions, HandleFormFieldChange, HandleSubmitForm} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (InitalFormValuesFromParent) {
            setInitialFormValues(InitalFormValuesFromParent);
            setFormValues(InitalFormValuesFromParent);
        }
    }, [InitalFormValuesFromParent]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});

        if (InitialFormValues[field] !== value) {
            HandleFormFieldChange(field, value);
        }
    };
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        setFormValues(UpdatedFormValues);
        if (InitialFormValues[field] !== SelectedValues) {
            HandleFormFieldChange(field, SelectedValues);
        }

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormDetailsComponent;
    if (FormValues) {
        FormDetailsComponent = (
            <React.Fragment>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                        <FormCompontent
                            Name="Language"
                            InitialValue={InitialFormValues.Language ? InitialFormValues.Language : null}
                            FormValue={FormValues.Language ? FormValues.Language : null}
                            OutputSelectedValues={(values) => handleSelectChange("Language", values)}
                            SelectOptions={FieldOptions.Language}
                            Component="Select"
                            Variant="Outlined"
                            Label="Language"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="150px"
                            FieldMaxWidth="120px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                        <FormCompontent
                            Name="GeneralConditions"
                            InitialValue={InitialFormValues.GeneralConditions ? InitialFormValues.GeneralConditions : null}
                            FormValue={FormValues.GeneralConditions ? FormValues.GeneralConditions : null}
                            OutputSelectedValues={(values) => handleSelectChange("GeneralConditions", values)}
                            SelectOptions={FieldOptions.GeneralConditions}
                            Component="Select"
                            Variant="Outlined"
                            Label="General Conditions"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="150px"
                            FieldMaxWidth="200px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="Template"
                            InitialValue={InitialFormValues.Template ? InitialFormValues.Template : null}
                            FormValue={FormValues.Template ? FormValues.Template : null}
                            OutputSelectedValues={(values) => handleSelectChange("Template", values)}
                            SelectOptions={FieldOptions.Template}
                            Component="Select"
                            Variant="Outlined"
                            Label="Template"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="150px"
                            FieldMaxWidth="200px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
    return <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent}>{FormDetailsComponent}</TabNavContent>;

    /***************** RENDER ******************/
});

export default Component;
