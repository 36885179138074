import React, {useEffect, useState, useRef} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
 
//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const CustomerProjects = React.memo(function CustomerProjects (props) {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
/***************** REFS ******************/
 
/***************** REFS ******************/
 
 
/***************** CONST ******************/
 
/***************** CONST ******************/
 
 
/***************** STATE ******************/
 
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
 
/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<div>
	  Projects
	</div>
  )
 
/***************** RENDER ******************/
 
});
 
export default CustomerProjects;