import {LOGIN_USER, LOGGEDIN_USER, SIGN_OUT_USER, INREGISTRATIONFALSE} from "./authConstants";
import {createReducer} from "../reduxStore/reduxReducerUtil";
import _ from "lodash";
// import axios from "axios";

const initialState = {
    authenticated: false,
    User: {
        id: null,
        PermissionGroups: "user",
        isAdmin: false,
        inRegistration: true,

        userProfile: {
            id: null,
            username: null,
            firstName: null,
            lastName: null,
            avatar: null,
            email: null,
        },
    },
};

export const loginUser = (state, payload) => {
    let data = payload;
    var isAdmin;
    console.log("data", data);
    if (data) {
        if (_.includes(data.PermissionGroups, "admin")) {
            isAdmin = true;
        } else {
            isAdmin = false;
        }
        let User = {
            id: data.id,
            PermissionGroups: data.PermissionGroups,
            isAdmin: isAdmin,
            inRegistration: data.inRegistration === true ? true : false,
            userProfile: {
                id: data.userProfile.id ? data.userProfile.id : null,
                username: data.userProfile.username ? data.userProfile.username : null,
                firstName: data.userProfile.firstName ? data.userProfile.firstName : null,
                lastName: data.userProfile.lastName ? data.userProfile.lastName : null,
                avatar: data.userProfile.avatar ? data.userProfile.avatar : null,
                email: data.userProfile.email ? data.userProfile.email : null,
            },
        };
        return {
            ...state,
            authenticated: true,
            User: {...state.User, ...User},
        };
    }
};

export const loggedinUser = (state, payload) => {
    return {
        ...state,
        authenticated: true,
        User: {...state.User, ...payload},
    };
};

export const InRegistration = (state) => {
    return {
        ...state,
        User: {...state.User, ...{inRegistration: false}},
    };
};

export const signOutUser = (state, payload) => {
    return {
        ...state,
        authenticated: false,
        User: {
            id: null,
            PermissionGroup: "user",
            isAdmin: false,
            inRegistration: true,
            userProfile: {
                id: null,
                username: null,
                avatar: null,
                email: null,
            },
        },
    };
};

export default createReducer(initialState, {
    [LOGIN_USER]: loginUser,
    [LOGGEDIN_USER]: loggedinUser,
    [SIGN_OUT_USER]: signOutUser,
    [INREGISTRATIONFALSE]: InRegistration,
});
