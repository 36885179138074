import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {IMaskInput} from "react-imask";
import {format, parse as parseFNS, isValid, add, parseISO, formatISO} from "date-fns";
import Calendar from "../Calendar/Calendar";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Input: {
        alignSelf: "stretch",
        flex: "1 1 auto",
    },
}));

/**
 * Component Description
 */
const FieldDatePicker = React.memo(function FieldDatePicker(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputValue,
        OutputError,
        Name, //Important for AutoFill
        ShareFocusState,
        ShareHasValue,
        Clearable,
        Searchable,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Disabled,
        Error,
        LocalValidation,
        Type, //Important for AutoFill
        Required,
        FinalBorderRadius,
        FieldMaxWidth,
        ShareCalendarIsOpened,
        IsCalendarOpened,
        Mask,
        PickerOnly,
        PickerDateType,
        CalendarOptions,
        RollerOptions,
        //Dont Fold
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_LocalInput = useRef(null);
    const Ref_Imask = useRef(null);
    const Ref_CalendarPicker = useRef(null);

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/

    const [LocalInputValue, setLocalInputValue] = useState(null);
    const [LocalInputValueUnformatted, setLocalInputValueUnformatted] = useState(null);
    const [MaskDefinitionReady, setMaskDefinitionReady] = useState(false); //This is used to ensure we don't render the instance without a mask
    const [MaskInstanceReady, setMaskInstanceReady] = useState(false); //This is used to ensure we don't pass the initial value unless the instance is ready
    const [LazyPattern, setLazyPattern] = useState(true); //we hide the pattern by default;

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    //Handle Click outside
    const HandleClickOutside = useCallback(
        (e) => {
            if (Ref_CalendarPicker.current && Ref_CalendarPicker.current.contains(e.target)) {
            } else {
                ShareCalendarIsOpened(false);
                ShareFocusState(false);
            }
        },
        [Ref_CalendarPicker]
    );
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Ensuring MaskDefinition is existing Before rendering anything
    useEffect(() => {
        if (Mask) {
            setMaskDefinitionReady(true);
        }
    }, [Mask]);

    //Passing the Component Ref to the parent - only when it has been rendered once
    useEffect(() => {
        if (MaskDefinitionReady === true) {
            if (Ref_Imask.current) {
                if (Ref_Imask.current.element) {
                    if (Ref_LocalInput.current !== Ref_Imask.current.element) {
                        Ref_LocalInput.current = Ref_Imask.current.element;
                        setMaskInstanceReady(true);
                        HandleUpdateRefInput(Ref_LocalInput.current);
                    }
                }
            }
        }
    }, [Ref_Imask, MaskDefinitionReady]);

    //Initializing the value from InitalValue
    useEffect(() => {
        if (PickerOnly === false) {
            // console.log("InputMask Init", "InitialValue :", InitialValue, "FormValue :", FormValue, "LocalInputValue :", LocalInputValue, "LocalInputValue Unformatted :", LocalInputValueUnformatted);
            if (MaskInstanceReady === true) {
                if (!Error) {
                    if (InitialValue === FormValue) {
                        //Init or Reset
                        if (InitialValue) {
                            let IsoDate = parseISO(InitialValue);
                            let FormattedValue = Mask.format(IsoDate);

                            setLocalInputValue(FormattedValue);
                        } else {
                            setLocalInputValue(InitialValue);
                        }
                    } else {
                        if (FormValue !== LocalInputValueUnformatted) {
                            //Update from outside
                            if (FormValue) {
                                let IsoDate = parseISO(FormValue);
                                let FormattedValue = Mask.format(IsoDate);

                                setLocalInputValue(FormattedValue);
                            } else {
                                setLocalInputValue(FormValue);
                            }
                        }
                    }
                }
            }
        }
    }, [InitialValue, Mask, MaskInstanceReady, FormValue, PickerOnly]);

    // Setting the Inital Value for no Keyboard Input
    useEffect(() => {
        if (PickerOnly === true) {
            if (InitialValue === FormValue) {
                //Init or Reset
                if (InitialValue) {
                    var FormattedValue;
                    if (Mask) {
                        FormattedValue = format(parseISO(InitialValue), Mask.pattern);
                    }
                    setLocalInputValue(FormattedValue);
                }
            } else {
                if (FormValue !== LocalInputValueUnformatted) {
                    if (FormValue) {
                        let IsoDate = parseISO(FormValue);
                        let FormattedValue = Mask.format(IsoDate);
                        setLocalInputValue(FormattedValue);
                    } else {
                        setLocalInputValue(FormValue);
                    }
                }
            }
        }
    }, [InitialValue, FormValue, PickerOnly]);

    //Passing the Component Ref to the parent
    useEffect(() => {
        if (Ref_LocalInput.current) {
            HandleUpdateRefInput(Ref_LocalInput.current);
        }
    }, [Ref_LocalInput.current]);

    //Adding Calendar Click Outside Listener
    //Adding Click Outside
    useEffect(() => {
        if (IsCalendarOpened === true) {
            if (CalendarOptions && CalendarOptions.SaveButton === true) {
            } else {
                document.addEventListener("mousedown", HandleClickOutside);

                return () => {
                    document.removeEventListener("mousedown", HandleClickOutside);
                };
            }
        } else {
            return () => {
                document.removeEventListener("mousedown", HandleClickOutside);
            };
        }
    }, [HandleClickOutside, IsCalendarOpened, CalendarOptions]);

    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/
    const HandleInputFocus = () => {
        ShareFocusState(true);
        setLazyPattern(false);
    };

    const HandleOnAcceptMask = (Value, mask) => {
        //We always return a server type formatted value to the server!!!!!!!!

        var FinalReturnValue;
        var Invalid = false;

        if (Mask.type === "Date" || Mask.type === "Time" || Mask.type === "DateTime") {
            var testFns;
            if (Mask.pattern.includes("MM") && Mask.pattern.includes("yy") && !Mask.pattern.includes("yyyy")) {
                if (Mask.pattern.includes("dd")) {
                    //We need to addd one month to the date
                    let temptestFns = parseFNS(Value, Mask.pattern, new Date());

                    if (isValid(temptestFns)) {
                        testFns = add(temptestFns, {
                            months: 1,
                        });
                    }
                } else {
                    //MonthYear Picker - no dd
                    let Separator = Mask.pattern[2];
                    let RealDate = `01${Separator}${Value}`;
                    let RealMaskPattern = `dd${Separator}${Mask.pattern}`;
                    let temptestFns = parseFNS(RealDate, RealMaskPattern, new Date());
                    if (isValid(temptestFns)) {
                        testFns = temptestFns;
                    }
                }
            } else if (Mask.pattern.includes("MMM")) {
                testFns = parseFNS(Value, Mask.pattern, new Date());
            } else {
                testFns = parseFNS(Value, Mask.pattern, new Date());
            }

            if (isValid(testFns)) {
                FinalReturnValue = formatISO(testFns);
            } else {
                Invalid = true;
            }
        } else {
            FinalReturnValue = mask._unmaskedValue;
        }

        //Update the input value
        setLocalInputValue(Value);
        setLocalInputValueUnformatted(FinalReturnValue);

        // console.log("setLocalInputValueUnformatted", FinalReturnValue);
        if (OutputValue && typeof OutputValue === "function") {
            if (Invalid === false) {
                let FinalPristine = InitialValue === FinalReturnValue ? true : false;
                OutputValue({Value: FinalReturnValue, FormattedValue: Value, Pristine: FinalPristine});
                if (OutputError && typeof OutputError === "function") {
                    OutputError(null);
                }
            } else {
                if (mask._unmaskedValue === "") {
                    let FinalPristine = InitialValue === null ? true : false;
                    OutputValue({Value: null, FormattedValue: null, Pristine: FinalPristine});
                } else {
                    if (OutputError && typeof OutputError === "function") {
                        OutputError("Invalid Date");
                    }
                }
            }
        }

        if (Value) {
            ShareHasValue(true);
            //We need to show the pattern (Lazy = false means show the pattern (we don't want it if there is no value))
            setLazyPattern(false);
        } else {
            ShareHasValue(false);
        }
    };

    const HandleInputBlur = () => {
        //Validating the field on Leaving if FinalValidateWhen === "Leaving"
        ShareFocusState(false);
        setLazyPattern(true);
    };

    const HandleOpenCalendar = () => {
        if (IsCalendarOpened === false) {
            ShareCalendarIsOpened(true);
            ShareFocusState(true);
        }
    };
    const UpdateInputFromCalendar = (calendarDate, CloseCalendar) => {
        if (PickerOnly === true) {
            var FormattedValue = format(calendarDate, Mask.pattern);

            setLocalInputValue(FormattedValue);
            setLocalInputValueUnformatted(formatISO(calendarDate));

            ShareHasValue(true);
            // ShareFocusState(true);
            if (CloseCalendar === true) {
                ShareCalendarIsOpened(false);
            }

            if (OutputValue && typeof OutputValue === "function") {
                // OutputValue({Value: FinalReturnValue, FormattedValue: Value, Pristine: FinalPristine});
                OutputValue({Value: formatISO(calendarDate), FormattedValue: FormattedValue, Pristine: false});
                if (OutputError && typeof OutputError === "function") {
                    OutputError(null);
                }
            }
        } else {
            let FormattedValue = Mask.format(calendarDate);
            setLocalInputValue(FormattedValue);
            if (CloseCalendar === true) {
                ShareCalendarIsOpened(false);
            }
        }
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var FormElement;
    if (PickerOnly === true) {
        if (MaskDefinitionReady === true) {
            FormElement = (
                <React.Fragment>
                    <div ref={Ref_CalendarPicker}>
                        <Calendar
                            //Dont fold
                            InputCalendarDate={LocalInputValueUnformatted}
                            IsOpenCalendar={IsCalendarOpened}
                            ShareCalendarIsOpened={ShareCalendarIsOpened}
                            UpdateInputFromCalendar={UpdateInputFromCalendar}
                            PickerDateType={PickerDateType}
                            CalendarOptions={CalendarOptions}
                            RollerOptions={RollerOptions}
                            Name={Name}
                            ReadOnly={ReadOnly}
                        />
                    </div>
                    <input
                        readOnly={true}
                        ref={Ref_LocalInput}
                        style={{
                            flex: "1 1 auto",
                            width: FieldMaxWidth ? FieldMaxWidth : null,
                            ...Style,
                        }}
                        //
                        className={classes.Input}
                        onClick={HandleOpenCalendar}
                        value={LocalInputValue === null || LocalInputValue === undefined ? "" : LocalInputValue}
                        onBlur={HandleInputBlur}
                    />
                </React.Fragment>
            );
        }
    } else {
        if (MaskDefinitionReady === true) {
            FormElement = (
                <React.Fragment>
                    <div ref={Ref_CalendarPicker}>
                        <Calendar
                            //Dont fold
                            InputCalendarDate={LocalInputValueUnformatted}
                            IsOpenCalendar={IsCalendarOpened}
                            Ref_Anchor={Ref_LocalInput}
                            ShareCalendarIsOpened={ShareCalendarIsOpened}
                            ShareHasValue={ShareHasValue}
                            ShareFocusState={ShareFocusState}
                            UpdateInputFromCalendar={UpdateInputFromCalendar}
                            PickerDateType={PickerDateType}
                            CalendarOptions={CalendarOptions}
                            RollerOptions={RollerOptions}
                            Name={Name}
                            ReadOnly={ReadOnly}
                            FieldMaxWidth={FieldMaxWidth}
                        />
                    </div>
                    <IMaskInput
                        mask={Mask.mask}
                        //The Following is used for blocks
                        blocks={Mask.blocks ? Mask.blocks : null}
                        pattern={Mask.pattern ? Mask.pattern : null}
                        format={Mask.format ? Mask.format : null}
                        parse={Mask.parse ? Mask.parse : null}
                        //This is used for no blocks
                        thousandsSeparator={Mask.thousandsSeparator ? Mask.thousandsSeparator : ""}
                        radix={Mask.radix ? Mask.radix : null}
                        scale={Mask.scale ? Mask.scale : null}
                        signed={Mask.signed ? Mask.signed : null}
                        mapToRadix={Mask.mapToRadix ? Mask.mapToRadix : null}
                        min={Mask.min ? Mask.min : null}
                        max={Mask.max ? Mask.max : null}
                        maxLength={Mask.maxLength ? Mask.maxLength : null}
                        //This is standard
                        lazy={LazyPattern}
                        // overwrite={Mask.overwrite === true ? true : false}
                        // autofix={Mask.autofix === true ? true : false}
                        unmask={Mask.unmask === "typed" ? "typed" : Mask.unmask === true ? true : false}
                        ref={Ref_Imask}
                        inputRef={() => Ref_LocalInput} // access to nested input
                        onAccept={(value, mask) => HandleOnAcceptMask(value, mask)}
                        value={LocalInputValue}
                        onFocus={HandleInputFocus}
                        onBlur={HandleInputBlur}
                        readOnly={ReadOnly}
                        style={{
                            flex: "1 1 auto",
                            width: FieldMaxWidth ? FieldMaxWidth : null,
                            ...Style,
                        }}
                        className={classes.Input}
                        name={Name}
                    />
                </React.Fragment>
            );
        } else {
            FormElement = null;
        }
    }

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldDatePicker.defaultProps = {
    InitialValue: null,
    FormValue: null,
    OutputValue: null,
    OutputError: null,
    Name: null, //Important for AutoFill
    ShareFocusState: null,
    ShareHasValue: null,
    Clearable: null,
    Searchable: null,
    Style: null,
    HandleUpdateRefInput: null,
    ReadOnly: null,
    Disabled: null,
    Error: null,
    LocalValidation: null,
    Type: null, //Important for AutoFill
    Required: null,
    FinalBorderRadius: null,
    FieldMaxWidth: null,
    ShareCalendarIsOpened: null,
    IsCalendarOpened: null,
    Mask: null,
    PickerOnly: null,
    PickerDateType: null,
    CalendarOptions: null,
    RollerOptions: null,
};

FieldDatePicker.propTypes = {
    /**
     * NoMessage :
     */
    InitialValue: PropTypes.any,
    /**
     * NoMessage :
     */
    FormValue: PropTypes.any,
    /**
     * NoMessage :
     */
    OutputValue: PropTypes.any,
    /**
     * NoMessage :
     */
    OutputError: PropTypes.any,
    /**
     * NoMessage :
     */
    Name: PropTypes.any,
    /**
     * NoMessage :
     */
    ShareFocusState: PropTypes.any,

    /**
     * NoMessage :
     */
    ShareHasValue: PropTypes.any,
    /**
     * NoMessage :
     */
    Clearable: PropTypes.any,
    /**
     * NoMessage :
     */
    Searchable: PropTypes.any,
    /**
     * NoMessage :
     */
    Style: PropTypes.any,
    /**
     * NoMessage :
     */
    HandleUpdateRefInput: PropTypes.any,
    /**
     * NoMessage :
     */
    ReadOnly: PropTypes.any,
    /**
     * NoMessage :
     */
    Disabled: PropTypes.any,

    /**
     * NoMessage :
     */
    Error: PropTypes.any,

    /**
     * NoMessage :
     */
    LocalValidation: PropTypes.any,

    /**
     * NoMessage :
     */
    Type: PropTypes.any,

    /**
     * NoMessage :
     */
    Required: PropTypes.any,

    /**
     * NoMessage :
     */
    FinalBorderRadius: PropTypes.any,
    /**
     * NoMessage :
     */
    FieldMaxWidth: PropTypes.any,
    /**
     * NoMessage :
     */
    ShareCalendarIsOpened: PropTypes.any,
    /**
     * NoMessage :
     */
    IsCalendarOpened: PropTypes.any,
    /**
     * NoMessage :
     */
    Mask: PropTypes.any,
    /**
     * NoMessage :
     */
    PickerOnly: PropTypes.any,
    /**
     * NoMessage :
     */
    PickerDateType: PropTypes.any,
    /**
     * NoMessage :
     */
    CalendarOptions: PropTypes.any,
    /**
     * NoMessage :
     */
    RollerOptions: PropTypes.any,
};

export default FieldDatePicker;
