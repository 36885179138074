import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import Select, {components} from "react-select";
import CreatableSelect from "react-select/creatable";


import FormAnimatedWrapper from "../FormAnimatedWrapper/FormAnimatedWrapper";
import {ArtiWeb} from "@artibulles-cis/react-icons";

var _ = require("lodash");

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

const Lockedstyles = {
    FormElement: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: "1 1 auto",
    },
    FormInput: {
        flex: "1 1 auto",
        display: "flex",

        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
    },
};

const styles = createUseStyles((theme) => ({
    FormElement: theme.FormTextInput.FormElement,
    FormInput: theme.FormTextInput.FormInput,

    Input: {
        display: "block",
        // borderRadius: "3px",
        border: "0px !important",
        backgroundColor: "transparent",
        boxSizing: "border-box",

        pointerEvents: "all",
        //Keep it here to set the full component height to harmonize with other inputs
        // minHeight: "25px",
        // lineHeight: "25px",
        // height: "30px"
        webkitAppearance: "none !important",
        outline: "none !important",
        background: "none",

        "&:error": {
            webkitAppearance: "none !important",
            outline: "none !important",
            border: "none !important",
            boxShadow: "none",
        },
        "&:invalid": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important",
        },
        "&:focus": {
            webkitAppearance: "none !important",
            outline: "none !important",
            border: "none !important",
            boxShadow: "none",
        },
        "&:active": {
            webkitAppearance: "none !important",
            outline: "none !important",
            border: "none !important",
            boxShadow: "none",
        },
    },

    BottomMessageContent: theme.FormTextInput.BottomMessageContent,

    BottomMessageContentError: (props) => {
        let ErrorStyleFinal = props.ErrorStyle ? (props.ErrorStyle.color ? props.ErrorStyle.color : null) : null;
        let ColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;

        if (ErrorStyleFinal) {
            return {color: ErrorStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentError;
        }
    },
    BottomMessageContentHelper: (props) => {
        let ColorFinal = props.HelperStyle ? (props.HelperStyle.color ? props.HelperStyle.color : null) : null;

        if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentHelper;
        }
    },
    BottomMessageContentHidden: {
        visibility: "hidden",
    },
    CustomSelectContainer: {
        position: "relative",
        boxSizing: "border-box",
    },
    CustomSelectController: {
        display: "flex",
        alignItems: "center",
    },
    CustomSelectValueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
        // padding: "10px"
        // padding: theme.SelectSingleAnimated.MenuItems.outlinedPadding
    },

    CustomSelectSingleValue: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Filled ? props.InputStyle.Filled : null) : null;
        let BasicStyle = {
            display: "flex",
            alignItems: "center",
        };
        if (InputStyleFinal) {
            return {...BasicStyle, color: InputStyleFinal};
        } else if (ColorFinal) {
            return {...BasicStyle, color: ColorFinal};
        } else {
            return {...BasicStyle, color: theme.FormTextInput.InputColor.color};
        }
    },
}));

/**
 * Create a NavTopBar Wrapper that goes into a NavHorizontalBar Component
 */
const SelectComponent = React.forwardRef(function SelectComponent(props, ref) {
    const {
        variant,
        NoMessage,
        curvalue,
        initialvalue,
        options,
        name,
        onSelectChange,
        label,
        MultiSelect,
        AllowCreateOption,
        sortSelectOptions,
        sortLastItemName,
        UpdateOptionsFromOutside,
        RemoveOptionIfUnselected,
        Clearable,
        isSearchable,
        placeHolder,
        HelperMessage,
        required,
        readOnly,
        disabled,
        minMenuHeight,
        maxMenuHeight,
        menuPlacement,
        AlternateRowStyle,
        LabelScale,
        StyleBorderErrorFocusTrue,
        InputColor,
        Colors,
        InputFontProp,
        LabelStyle,
        InputStyle,
        BorderStyle,
        HelperStyle,
        ErrorStyle,
		inputVerticalPadding,
		ControlledEditMode,
        ControlledEditModeActive,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /*need to delete*/

    /*need to delete*/

    const [SelectOptions, setSelectOptions] = React.useState(null);
    const [Single, setSingle] = React.useState(null);
    const [Multi, setMulti] = React.useState(null);

    //Synching the form with Select
    React.useEffect(() => {
        var initialOption = [];
        if (MultiSelect) {
            if (initialvalue && options) {
                if (options.length >= 1) {
                    if (curvalue === initialvalue) {
                        if (Array.isArray(initialvalue)) {
                            initialvalue.forEach((initval) => {
                                options.forEach((elem) => {
                                    if (elem.value === initval) {
                                        initialOption.push({value: elem.value, label: elem.label});
                                    }
                                });
                            });
                        } else {
                            options.forEach((elem) => {
                                if (elem.value === initialvalue) {
                                    initialOption = {value: elem.value, label: elem.label};
                                }
                            });
                        }

                        setMulti(initialOption);
                    }
                }
            }
            if (curvalue == null) {
                setMulti(null);
            }
        } else {
            if (initialvalue && options) {
                if (initialvalue === curvalue) {
                    if (options.length > 0) {
                        options.forEach((elem) => {
                            if (elem.value === initialvalue) {
                                initialOption = {value: elem.value, label: elem.label};
                            }
                        });
                        //It is potentially a form reset -> Need to update the field to reflect that change
                        setSingle(initialOption);
                    }
                } else {
                    if (options.length > 0) {
                        options.forEach((elem) => {
                            if (elem.value === curvalue) {
                                initialOption = {value: elem.value, label: elem.label};
                            }
                        });
                        //It is potentially a form reset -> Need to update the field to reflect that change

                        setSingle(initialOption);
                    }
                }
            } else {
                if (curvalue === null) {
                    setSingle(null);
                }
            }
        }
    }, [curvalue, MultiSelect, initialvalue, options]);

    //Managing the internal customized Options
    React.useEffect(() => {
        if (options) {
            var SortedOptions, SortedOptionsFinal;
            //If sort is required ->
            if (sortSelectOptions) {
                let sortDir = sortSelectOptions === "desc" ? "desc" : "asc";
                SortedOptions = _.orderBy(
                    options,
                    function (lowCase) {
                        return lowCase.label.toLowerCase();
                    },
                    sortDir
                );

                if (sortLastItemName.name) {
                    var LastIem;
                    //eslint-disable-next-line
                    let OptionSortedTemp = [];

                    SortedOptions.forEach((elem) => {
                        if (sortLastItemName.exact) {
                            if (elem.label === sortLastItemName.name) {
                                //Store it
                                LastIem = elem;
                            } else {
                                OptionSortedTemp.push(elem);
                            }
                        } else {
                            if (elem.label.includes(sortLastItemName.name)) {
                                //Store it
                                LastIem = elem;
                            } else {
                                OptionSortedTemp.push(elem);
                            }
                        }
                    });

                    OptionSortedTemp.push(LastIem);

                    SortedOptionsFinal = OptionSortedTemp;
                } else {
                    SortedOptionsFinal = SortedOptions;
                }
            } else {
                SortedOptionsFinal = options;
            }
            //Adding custom tags for customizing the style
            var i = 0;

            var customOptions = SortedOptionsFinal.map((elem) => {
                let isAlternate = i % 2 === 1 ? true : false;
                i = i + 1;
                return {...elem, alternateRow: isAlternate};
                //do something to customize them
            });

            setSelectOptions(customOptions);
        }
    }, [options, sortSelectOptions, sortLastItemName]);

    function HandleSelectChange(value, action) {
        if (MultiSelect) {
            setMulti(value);
            if (value) {
                let finallist = value.map((val) => val.value);
                if (onSelectChange) {
                    onSelectChange(finallist);
                }
            } else {
                if (onSelectChange) {
                    onSelectChange(null);
                }
            }
        } else {
            //This is where we transfer the value to the select.
            setSingle(value);
            //Deppending on server api host
            if (value) {
                if (onSelectChange) {
                    onSelectChange(value.value);
                }
            } else {
                if (onSelectChange) {
                    onSelectChange(null);
                }
            }
        }
    }
    function handleCreateOption(inputValue) {
		console.log("create the option", inputValue)
        let FinalNewLabel = inputValue;
        let newOption = {value: FinalNewLabel, label: FinalNewLabel};
		let FinalOptionsUnsorted = [...SelectOptions, newOption];
		console.log("FinalOptionsUnsorted",FinalOptionsUnsorted)
        //sort the new array if required
        var OptionsSorted, FinalOptions;
        if (sortSelectOptions) {
            let sortDir = sortSelectOptions === "desc" ? "desc" : "asc";

            OptionsSorted = _.orderBy(
                FinalOptionsUnsorted,
                function (lowCase) {
                    return lowCase.label.toLowerCase();
                },
                sortDir
            );
            if (sortLastItemName) {
                var LastIem;
                //eslint-disable-next-line
                let OptionSortedTemp = OptionsSorted.map((elem) => {
                    if (elem.label === sortLastItemName) {
                        //Store it
                        LastIem = elem;
                    } else {
                        return elem;
                    }
                });

                OptionSortedTemp.push(LastIem);

                FinalOptions = OptionSortedTemp;
            }

            FinalOptions = OptionsSorted;
        } else {
            FinalOptions = FinalOptionsUnsorted;
		}
		console.log("FinalOptions",FinalOptions)

        if (MultiSelect) {
            var updatedSelect;

            var CurrentMulti = [];
            if (Multi) {
                if (Array.isArray(Multi) === false) {
                    CurrentMulti.push(Multi);
                } else {
                    CurrentMulti = Multi;
                }

                setMulti([...CurrentMulti, newOption]);
                let FullupdatedSelect = [...CurrentMulti, newOption];
                updatedSelect = FullupdatedSelect.map((val) => val.value);
                if (RemoveOptionIfUnselected === false) {
                    setSelectOptions(FinalOptions);
                }
                //Updating the content of the form values
                props.input.onChange(updatedSelect);
            } else {
                setMulti([newOption]);
                if (RemoveOptionIfUnselected === false) {
                    setSelectOptions(FinalOptions);
                }

                let FullupdatedSelect = Multi;
                updatedSelect = FullupdatedSelect.map((val) => val.value);
                //Updating the content of the form values
                props.input.onChange(updatedSelect);
            }
            if (UpdateOptionsFromOutside) {
                UpdateOptionsFromOutside(FinalOptions, updatedSelect);
            }
        } else {
            if (RemoveOptionIfUnselected === false) {
                setSelectOptions(FinalOptions);
            }
			console.log("newOption",newOption)
            setSingle(newOption);

            //Updating the content of the form values
            if (UpdateOptionsFromOutside) {
                UpdateOptionsFromOutside(FinalOptions, newOption);
			}
			if (onSelectChange) {
				onSelectChange(newOption.value);
			}
        }
    }
    //eslint-disable-next-line
    const InputOnBlur = (event) => {};
    //eslint-disable-next-line
    const InputOntouchStart = () => {
        // console.log("InputOntouchStart");
    };
    //eslint-disable-next-line
    const InputOnTouchEnd = () => {
        // console.log("InputOnTouchEnd");
    };

    var BottomMessageClass = classes.BottomMessageContent;

    BottomMessageClass = clsx(BottomMessageClass, classes.BottomMessageContentHidden);

    const BottomMessageContent = () => {
        if (HelperMessage) {
            return HelperMessage;
        } else {
            return "No Message";
        }
    };

    const SelectRef = React.useRef(null);
    const FinalSelectComponent = (FieldSetProps) => {
        const {InputMarginBottom, FinalInputPadding} = FieldSetProps;

        const SelectCustomStyles = {
            container: (provided) => ({
                ...provided,
                marginBottom: variant ? (variant === "naked" ? null : MultiSelect ? `${parseInt(InputMarginBottom) - 4}px` : InputMarginBottom) : null,

                fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
                position: "relative",
                boxSizing: "border-box",
                flex: "1 0 auto",
                width: "100%",
            }),

            menu: (provided) => ({
                ...provided,
                marginTop: variant ? (variant === "naked" ? theme.SelectComponent.Menu.marginTop : theme.FormSelect.Menu.marginTop) : theme.SelectComponent.Menu.marginTop,
                marginBottom: variant ? (variant === "naked" ? theme.SelectComponent.Menu.marginBottom : theme.FormSelect.Menu.marginBottom) : theme.SelectComponent.Menu.marginBottom,
                boxShadow: variant ? (variant === "naked" ? theme.SelectComponent.Menu.boxShadow : theme.FormSelect.Menu.boxShadow) : theme.SelectComponent.Menu.boxShadow,
                background: variant ? (variant === "naked" ? theme.SelectComponent.Menu.background : theme.FormSelect.Menu.background) : theme.SelectComponent.Menu.background,
                fontFamily: variant ? (variant === "naked" ? theme.SelectComponent.Menu.fontFamily : theme.FormSelect.Menu.fontFamily) : theme.SelectComponent.Menu.fontFamily,
                fontSize: variant ? (variant === "naked" ? theme.SelectComponent.Menu.fontSize : theme.FormSelect.Menu.fontSize) : theme.SelectComponent.Menu.fontSize,
                fontWeight: variant ? (variant === "naked" ? theme.SelectComponent.Menu.fontWeight : theme.FormSelect.Menu.fontWeight) : theme.SelectComponent.Menu.fontWeight,
            }),
            option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                let isAlternate = data.alternateRow;

                if (AlternateRowStyle) {
                    return {
                        ...styles,
                        padding: theme.SelectComponent.Options.padding,
                        backgroundColor: isSelected
                            ? theme.SelectComponent.Options.BackgroundColorSelected
                            : isFocused
                            ? theme.SelectComponent.Options.BackgroundColorHovered
                            : isAlternate === true
                            ? theme.SelectComponent.Options.BackgroundAlternate
                            : theme.SelectComponent.Options.BackgroundColor,
                        color: isSelected ? theme.SelectComponent.Options.ColorSelected : isFocused ? theme.SelectComponent.Options.ColorHovered : theme.SelectComponent.Options.Color,
                        fontWeight: isSelected ? theme.SelectComponent.Options.FontWeightSelected : theme.SelectComponent.Menu.fontWeight,
                        ":active": {
                            ...styles[":active"],
                            backgroundColor: isSelected ? theme.SelectComponent.Options.BackgroundColorSelected : theme.SelectComponent.Options.BackgroundColorHovered,
                        },
                    };
                } else {
                    return {
                        ...styles,
                        padding: theme.SelectComponent.Options.padding,
                        backgroundColor: isSelected
                            ? theme.SelectComponent.Options.BackgroundColorSelected
                            : isFocused
                            ? theme.SelectComponent.Options.BackgroundColorHovered
                            : theme.SelectComponent.Options.BackgroundColor,
                        color: isSelected ? theme.SelectComponent.Options.ColorSelected : isFocused ? theme.SelectComponent.Options.ColorHovered : theme.SelectComponent.Options.Color,
                        fontWeight: isSelected ? theme.SelectComponent.Options.FontWeightSelected : theme.SelectComponent.Menu.fontWeight,
                        ":active": {
                            ...styles[":active"],
                            backgroundColor: isSelected ? theme.SelectComponent.Options.BackgroundColorSelected : theme.SelectComponent.Options.BackgroundColorHovered,
                        },
                    };
                }
            },
            dropdownIndicator: (provided) => ({
                ...provided,
                padding: "2px 8px 2px 8px",
            }),
            clearIndicator: (provided) => ({
                ...provided,
                padding: "2px 8px 2px 8px",
            }),
            input: (provided) => ({
                ...provided,
                padding: "0px 0px 0px 0px",
                margin: "0px 2px 0px 2px",
            }),
            indicatorSeparator: (provided) => ({
                ...provided,
                marginBottom: variant ? (variant === "naked" ? "2px" : "0px") : "2px",
                marginTop: variant ? (variant === "naked" ? "2px" : "0px") : "2px",
            }),
            valueContainer: (provided) => ({
                ...provided,
                padding: variant ? (variant === "naked" ? null : FinalInputPadding) : null,
                display: "flex",
                flexWrap: "wrap",
                flex: 1,
                alignItems: "center",
                overflow: "hidden",
            }),
            placeholder: () => ({
                position: "relative",
                // padding: MultiSelect ? "2px 0px 2px 0px" : "0px 2px 0px 2px",
                color: Colors.Empty ? Colors.Empty : theme.FormTextInput.LabelEmpty.color,
            }),
            multiValueLabel: (provided) => ({
                ...provided,
                paddingTop: "1px",
                paddingBottom: "1px",
            }),
            singleValue: (provided) => ({
                ...provided,
                color: InputColor,
            }),

            control: () => ({
                padding: "0px",
                background: "none",

                position: "relative",
                boxSizing: "border-box",
                margin: "0px",
                transition: "all 100ms",
                display: "flex",

                alignItems: "center",
                justifyContent: "space-between",
                outline: "0!important",
                border: "0 none!important",
                // minHeight : "38px"
            }),
        };
        //eslint-disable-next-line
        const SelectContainerComponent = ({innerRef, innerProps, children}) => {
            return (
                <div
                    ref={innerRef}
                    {...innerProps}
                    className={classes.CustomSelectContainer}
                    style={{
                        marginBottom: InputMarginBottom,
                        fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                        fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                        fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
                    }}
                >
                    {children}
                </div>
            );
        };
        //eslint-disable-next-line
        const SelectValueComponent = (props) => {
            //eslint-disable-next-line
            const {innerRef, innerProps, clearValue, getStyles, getValue, data, hasValue, isMulti, isRtl, options, selectOption, setValue, selectProps} = props;
            return (
                <div className={classes.CustomSelectValueContainer} style={{padding: FinalInputPadding}}>
                    <components.ValueContainer ref={innerRef} {...innerProps} {...props}>
                        {props.children}
                    </components.ValueContainer>
                </div>
            );
        };
        //eslint-disable-next-line
        const SelectMenuComponent = (props) => {
            return (
                <React.Fragment>
                    <components.Menu {...props} className={classes.SelectMyMenu}>
                        {props.children}
                    </components.Menu>
                </React.Fragment>
            );
        };

        //Customizing the Single Value component
        //eslint-disable-next-line
        const SelectSingleValueComponent = (props) => {
            //eslint-disable-next-line
            const {innerRef, innerProps, clearValue, getStyles, getValue, data, hasValue, isMulti, isRtl, options, selectOption, setValue, selectProps} = props;

            let currentval = data.label;
            //eslint-disable-next-line
            var CustomIcon;
            if (currentval === "Express") {
                CustomIcon = <ArtiWeb />;
            } else {
                CustomIcon = null;
            }

            return (
                <div ref={innerRef} {...innerProps} className={classes.CustomSelectSingleValue}>
                    {props.children}
                </div>
            );
        };
        const SelectComponents = {
            // Menu: SelectMenuComponent,
            // Option,
            // SelectContainer: SelectContainerComponent,
            // Control: SelectControlComponent,
            // ValueContainer: SelectValueComponent
            // SingleValue: SelectSingleValueComponent
        };

        const FinalMenuOptions = {
            minMenuHeight: minMenuHeight ? parseInt(minMenuHeight) : null,
            maxMenuHeight: maxMenuHeight ? parseInt(maxMenuHeight) : null,
            menuPlacement: menuPlacement ? menuPlacement : "auto",
        };
		var FinalDisabled = false;
		if (disabled === true) {
			FinalDisabled = true;
		} else {
			if (ControlledEditMode === true) {
				FinalDisabled = true;
			}
		}
        if (AllowCreateOption) {
            return (
                <CreatableSelect
					ref={SelectRef}
					name={name}
                    isDisabled={FinalDisabled}
                    styles={SelectCustomStyles}
                    isClearable={required === true ? false : Clearable === false ? false : true}
                    components={SelectComponents}
                    value={MultiSelect ? Multi : Single}
                    options={SelectOptions}
                    onChange={HandleSelectChange}
                    readOnly={readOnly}
                    // onBlur={InputOnBlur}
                    // onFocus={InputOnFocus}
                    // onMenuClose={SelectonMenuClose}
                    // autoFocus={false}
                    isMulti={MultiSelect === true ? true : false}
                    closeMenuOnSelect={MultiSelect === true ? false : true}
                    onCreateOption={handleCreateOption}
                    isSearchable={isSearchable ? true : false}
                    minMenuHeight={FinalMenuOptions.minMenuHeight}
                    maxMenuHeight={FinalMenuOptions.maxMenuHeight}
                    menuPlacement={FinalMenuOptions.menuPlacement}
                />
            );
        } else {
            return (
                <Select
                    ref={SelectRef}
                    name={name}
                    isDisabled={FinalDisabled}
                    styles={SelectCustomStyles}
                    isClearable={required === true ? false : Clearable === false ? false : true}
                    value={MultiSelect ? Multi : Single}
                    options={SelectOptions}
                    onChange={HandleSelectChange}
                    readOnly={readOnly}
                    // onBlur={InputOnBlur}
                    // onFocus={InputOnFocus}
                    // onMenuClose={SelectonMenuClose}
                    // autoFocus={false}
                    isMulti={MultiSelect === true ? true : false}
                    closeMenuOnSelect={MultiSelect === true ? false : true}
                    isSearchable={isSearchable ? true : false}
                    minMenuHeight={FinalMenuOptions.minMenuHeight}
                    maxMenuHeight={FinalMenuOptions.maxMenuHeight}
                    menuPlacement={FinalMenuOptions.menuPlacement}
                />
            );
        }
    };
    var BottomMessageComponent;
    if (NoMessage === false) {
        BottomMessageComponent = (
            <div className={BottomMessageClass} style={HelperStyle ? HelperStyle : null}>
                {BottomMessageContent()}
            </div>
        );
    }
    var FinalSelectWithWrapper;
    if (variant === "outlined" || variant === "filled" || variant === "standard" || variant === "outlinedSimple") {
        FinalSelectWithWrapper = (
            <div className={classes.FormElement} style={{...Lockedstyles.FormElement}}>
                <div className={classes.FormInput} style={{...Lockedstyles.FormInput}}>
                    <FormAnimatedWrapper
                        InputType="Select"
                        name={name}
                        label={label}
                        InputRef={null}
                        LabelScale={LabelScale}
                        variant={variant}
                        placeHolder={placeHolder}
                        disabled={disabled}
                        required={required}
                        StyleBorderErrorFocusTrue={StyleBorderErrorFocusTrue}
                        Colors={Colors}
                        InputFontProp={InputFontProp}
                        LabelStyle={LabelStyle}
                        BorderStyle={BorderStyle}
                        inputVerticalPadding={inputVerticalPadding}
                        FormInputComponent={FinalSelectComponent}
                        readOnly={readOnly}
                        ErrorStyle={ErrorStyle}
						InputStyle={InputStyle}
						ControlledEditMode={ControlledEditMode}
                    ControlledEditModeActive={ControlledEditModeActive}
                        // {...other}
                    />
                </div>

                {BottomMessageComponent}
            </div>
        );
    } else {
        //Naked Select
        FinalSelectWithWrapper = (
            <div className={classes.FormElement} style={{...Lockedstyles.FormElement}}>
                <FinalSelectComponent />
            </div>
        );
    }

    return <React.Fragment>{FinalSelectWithWrapper}</React.Fragment>;
});

SelectComponent.defaultProps = {
    variant: "outlined",
    NoMessage: false,
    curvalue: null,
    initialvalue: null,
    options: null,
    name: "FieldName",
    onSelectChange: null,
    label: "Field Label",
    MultiSelect: false,
    AllowCreateOption: false,
    sortSelectOptions: null,
    sortLastItemName: {name: null, exact: true},
    UpdateOptionsFromOutside: null,
    RemoveOptionIfUnselected: true,
    Clearable: true,
    isSearchable: true,
    placeHolder: null,
    HelperMessage: null,
    required: false,
    readOnly: false,
    disabled: false,
    minMenuHeight: null,
    maxMenuHeight: null,
    menuPlacement: "auto",
    AlternateRowStyle: false,
    LabelScale: "0.75", //Range is 0.75 - 0.85 otherwise fonts will crack
    StyleBorderErrorFocusTrue: true,
    InputColor: "black",
    Colors: {Empty: null, Filled: null, Error: null, Focused: null, Disabled: null},
    InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
    LabelStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
    InputStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
    BorderStyle: {Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null},
    HelperStyle: {fontFamily: null, fontSize: null, marginTop: null, color: null},
    ErrorStyle: {color: null},
	inputVerticalPadding: null,
	ControlledEditMode: false,
    ControlledEditModeActive: null,
};

SelectComponent.propTypes = {
    /**
     * the type of input variant="outlined", "standard"
     */
    variant: PropTypes.any,
    /**
     * The current values of the select -> passed by the form element
     */
    curvalue: PropTypes.any,
    /**
     * The initial values of the select -> passed by the form element
     */
    initialvalue: PropTypes.any,
    /**
     * The options of the select -> passed by the form element
     */
    options: PropTypes.any,
    /**
     * input name
     */
    name: PropTypes.any,
    /**
     * Used to pass back the value to the parent component
     * onSelectChange(value)=>{console.log(value)}
     */
    onSelectChange: PropTypes.any,

    /**
     * input label
     */
    label: PropTypes.any,
    /**
     * MultiSelect : true/false : allow to select multiple values
     */
    MultiSelect: PropTypes.bool,
    /**
     * AllowCreateOption : true/false : allow to create values
     */
    AllowCreateOption: PropTypes.bool,
    /**
     * sortSelectOptions : asc/desc -> Sort the options
     */
    sortSelectOptions: PropTypes.any,
    /**
     * sortLastItemName : Place the item in last position
     */
    sortLastItemName: PropTypes.any,

    /**
     * Function to update the select content back to the form
     */
    UpdateOptionsFromOutside: PropTypes.any,
    /**
     * RemoveOptionIfUnselected: true/false. If true the option is removed from the list if the user is deselecting it.
     * This should be the default case to avoid creating options on the server API if the user made a mistake.
     * If set to false, all created options will be passed back to the server
     */
    RemoveOptionIfUnselected: PropTypes.bool,
    /**
     * Clearable : true/false : allow to clear all values. Not active if required is true
     */
    Clearable: PropTypes.bool,
    /**
     * isSearchable : true/false : allow to search existing options
     */
    isSearchable: PropTypes.bool,
    /**
     * placeHolder value
     */
    placeHolder: PropTypes.any,
    /**
     * HelperMessage : Text to be displayed below the input
     */
    HelperMessage: PropTypes.any,
    /**
     * required
     */
    required: PropTypes.bool,

    /**
     * readOnly
     */
    readOnly: PropTypes.bool,
    /**
     * disabled
     */
    disabled: PropTypes.bool,
    /**
     * minMenuHeight: null,
     */

    minMenuHeight: PropTypes.any,
    /**
     * maxMenuHeight: null,
     */

    maxMenuHeight: PropTypes.any,
    /**
     * menuPlacement: "auto",
     */

    menuPlacement: PropTypes.any,

    /**
     * AlternateRowStyle : true/false -> add alternate row style to the option menu.
     * Not necessary good visual
     */

    AlternateRowStyle: PropTypes.bool,
    /**
     * LabelScale : 0,75. Range is between 0.75 and 0.85, otherwise the text effect will not work
     */

    LabelScale: PropTypes.any,

    /**
     * StyleBorderErrorFocusTrue : default is true
     * If true then the color of the input is focused when focused otherwise the input color is error color
     */
    StyleBorderErrorFocusTrue: PropTypes.bool,
    /**
     *
     */
    InputColor: PropTypes.any,

    Colors: PropTypes.any,
    /**
     * InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
     * Fonts for the label and the input
     */
    InputFontProp: PropTypes.any,
    /**
     * LabelStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
     * Customize the label (will override Colors)
     */
    LabelStyle: PropTypes.any,
    /**
     * InputStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}
     * Customize the Input (will override Colors)
     */
    InputStyle: PropTypes.any,
    /**
     * BorderStyle: {Empty: "1px solid grey", Focused: "1px solid grey", Error: "1px solid grey", Disabled: null, BoderRadius: "2px"},
     */
    BorderStyle: PropTypes.any,
    /**
     * HelperStyle: {fontFamily: null, FontSize: null, marginTop: null, color: null},
     */
    HelperStyle: PropTypes.any,
    /**
     * ErrorStyle: {fontFamily: "roboto", fontSize: "14px", marginTop: "2px", color: "red"},
     */
    ErrorStyle: PropTypes.any,
    /**
     * Change the vertical padding of the input
     * inputVerticalPadding: "2px"
     */
	inputVerticalPadding: PropTypes.any,
	/**
     * ErrorStyle: {fontFamily: "roboto", fontSize: "14px", marginTop: "2px", color: "red"},
     */
    ControlledEditMode: PropTypes.any,
    /**
     * Change the vertical padding of the input
     * inputVerticalPadding: "2px"
     */
    ControlledEditModeActive: PropTypes.any,
};

export default SelectComponent;
