import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "10px 5px 10px 5px",
        boxSizing: "border-box",
        // maxWidth: "1200px",
    },
    MainForm: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    TitleGroup: {
        display: "flex",
        flexDirection: "column",
        fontSize: "1rem",
        fontWeight: "500",
        // textDecoration : "underline"
        // borderBottom : "1px solid grey"
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormFieldGroup: {
        margin: "2px 5px 2px 5px",
    },
    FormField: {
        flex: "0 1 600px",
        flexDirection: "row",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    Separator: {
        borderTop: "2px solid grey",
        width: "100%",
        margin: "5px 0px 5px 0px",
        height: "5px",
    },
}));

const Component = React.memo(function Component(props) {
    const {InitalFormValuesFromParent, FieldOptions, HandleFormFieldChange, HandleSubmitForm} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (InitalFormValuesFromParent) {
            setInitialFormValues(InitalFormValuesFromParent);
            setFormValues(InitalFormValuesFromParent);
        }
    }, [InitalFormValuesFromParent]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
        // console.log("field: ", field, "Value: ", values);
        // console.log("InitialFormValues[field]",InitialFormValues[field])
        if (InitialFormValues[field] !== value) {
            HandleFormFieldChange(field, value);
        }
    };
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        setFormValues(UpdatedFormValues);
        if (InitialFormValues[field] !== SelectedValues) {
            HandleFormFieldChange(field, SelectedValues);
        }

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormDetailsComponent;
    if (FormValues) {
        FormDetailsComponent = (
            <div className={classes.MainForm}>
                <div className={classes.TitleGroup}>Summary</div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 872px"}}>
                        <FormCompontent
                            Name="CustomerReference"
                            InitialValue={InitialFormValues.CustomerReference ? InitialFormValues.CustomerReference : null}
                            FormValue={FormValues.CustomerReference ? FormValues.CustomerReference : null}
                            OutputValue={(values) => HandleUpdateFormValues("CustomerReference", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Customer Reference"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="160px"
                            FieldMaxWidth="700px"
                            LabelAlign="Left"
                            // Required={false}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 872px"}}>
                        <FormCompontent
                            Name="SummaryNote1"
                            InitialValue={InitialFormValues.SummaryNote1 ? InitialFormValues.SummaryNote1 : null}
                            FormValue={FormValues.SummaryNote1 ? FormValues.SummaryNote1 : null}
                            OutputValue={(values) => HandleUpdateFormValues("SummaryNote1", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Information Line 1"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="160px"
                            FieldMaxWidth="700px"
                            LabelAlign="Left"
                            // Required={false}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 872px"}}>
                        <FormCompontent
                            Name="SummaryNote2"
                            InitialValue={InitialFormValues.SummaryNote2 ? InitialFormValues.SummaryNote2 : null}
                            FormValue={FormValues.SummaryNote2 ? FormValues.SummaryNote2 : null}
                            OutputValue={(values) => HandleUpdateFormValues("SummaryNote2", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Information Line 2"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="160px"
                            FieldMaxWidth="700px"
                            LabelAlign="Left"
                            // Required={false}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 872px"}}>
                        <FormCompontent
                            Name="SummaryNote3"
                            InitialValue={InitialFormValues.SummaryNote3 ? InitialFormValues.SummaryNote3 : null}
                            FormValue={FormValues.SummaryNote3 ? FormValues.SummaryNote3 : null}
                            OutputValue={(values) => HandleUpdateFormValues("SummaryNote3", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Information Line 3"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="160px"
                            FieldMaxWidth="700px"
                            LabelAlign="Left"
                            // Required={false}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.Separator}></div>
                <div className={classes.TitleGroup}>VAT Information</div>
            </div>
        );
    }
    return <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent}>{FormDetailsComponent}</TabNavContent>;

    /***************** RENDER ******************/
});

export default Component;
