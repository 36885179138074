/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "40px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 50px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 15px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
        color: "white",
    },
}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const MainRoute = "/demos";
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/form/input`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>TextInput</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/form/select`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Select</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/form/datepicker`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>DatePicker</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/form/checkswitch`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>CheckBox And Switch</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/form/fileuploader`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>File Uploader</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/dragablelist`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Dragable list</p>
                    </div>
                </div>
            </NavSideBarLink>

            <NavSideBarLinkAccordion
                Slug={`${MainRoute}/table`}
                SlugList={[`${MainRoute}/table/source`, `${MainRoute}/table/group2`, `${MainRoute}/table/group3`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                ScrollToLink={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Table demos</p>
                        </div>
                    </div>
                }
            >
                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}/table/source`}
                    SlugList={[`${MainRoute}/table/source/local`, `${MainRoute}/table/source/urlrelative`, `${MainRoute}/table/source/urlabsolute`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ScrollToLink={true}
                    exact
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Table Source</p>
                            </div>
                        </div>
                    }
                >
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/source/local`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Local</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>

                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/source/urlrelative`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Relative URL</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>

                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/source/urlabsolute`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Absolute URL</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </NavSideBarLinkAccordion>
                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}/table/grouping`}
                    SlugList={[`${MainRoute}/table/grouping/demo1`, `${MainRoute}/table/grouping/urlrelative`, `${MainRoute}/table/grouping/urlabsolute`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ScrollToLink={true}
                    exact
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Grouping</p>
                            </div>
                        </div>
                    }
                >
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/grouping/demo1`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Demo1</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>

                    
                </NavSideBarLinkAccordion>
				<NavSideBarLinkAccordion
                    Slug={`${MainRoute}/table/aggrid`}
                    SlugList={[`${MainRoute}/table/aggrid/demo1`, `${MainRoute}/table/aggrid/urlrelative`, `${MainRoute}/table/aggrid/urlabsolute`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ScrollToLink={true}
                    exact
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>AG Grid trials</p>
                            </div>
                        </div>
                    }
                >
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/aggrid/demo1`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Basic With Local data</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/aggrid/demo2`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>With source and export</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/aggrid/demo3`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Data grouping 1</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/aggrid/demo3b`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Data grouping Custom</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/aggrid/demo4`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Total Row</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/table/aggrid/devcomponents`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>DevComponents</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>

                    
                </NavSideBarLinkAccordion>
            </NavSideBarLinkAccordion>
			<NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}/reactpdf`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>ReactPDF</p>
                    </div>
                </div>
            </NavSideBarLink>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default Component;
