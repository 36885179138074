import React from "react";
import PropTypes from "prop-types";

import {createUseStyles, useTheme} from "react-jss";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Artibulles_Cis_Gage: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
    },
    GageIndicatorText: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
    },
    GageFixed: {
        imageRendering: "crisp-edges",
    },
    GageIndicator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageTicks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageTicksText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
        fontSize: "18px",
        fontFamily: "roboto",
        fontWeight: "300",
    },
    GageNeedle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
}));

/**
 * Component Description
 */

const CircularGageFourQuarters = React.forwardRef(function CircularGageFourQuarters(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        ExternalDiameter,
        Thickness,
        Value,
        MaxValue,
        GageTriggers,
        WithOuterText,
        WithInnerText,
        GageTickTextOuterfontSize,
        GageTickTextOuterPaddingInner,
        GageTickTextInnerfontSize,
        GageTickTextInnerPaddingOuter,
        GageCentralText,
        GageCustomCentralText,
        EmptyColor,
        GageTickThickness,
        GageTickOverflow,
        GageTickColor,
        NeedleIndicator,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** CONST *********************/

    const SVGRatioReal_VBox = parseFloat(ExternalDiameter) / 100;
    var SVGDimensions = parseFloat(SVGRatioReal_VBox) * 100;
    var RealThickness = Thickness / SVGRatioReal_VBox;

    var RadiusInternal = (100 - RealThickness) / 2;

    const CircleCirconf = 2 * Math.PI * RadiusInternal;

    var ValuePercentage;
    /******** MERGING THE INPUTS TO PREVENT ERRORS *******/

    var GageTriggersInitial = {
        Quarter1: {text: null, TextColor: "black", GageColor: "white", FontSize: "10px", FontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
        Quarter2: {text: null, TextColor: "black", GageColor: "yellow", FontSize: "10px", FfontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
        Quarter3: {text: null, TextColor: "black", GageColor: "green", FontSize: "10px", FontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
        Quarter4: {text: null, TextColor: "black", GageColor: "red", FontSize: "10px", FontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
    };

    var GageTriggersFinal = _.merge(GageTriggersInitial, GageTriggers);
    GageTriggersFinal.Quarter1.FontSize = parseFloat(GageTriggersFinal.Quarter1.FontSize) / SVGRatioReal_VBox;
    GageTriggersFinal.Quarter2.FontSize = parseFloat(GageTriggersFinal.Quarter2.FontSize) / SVGRatioReal_VBox;
    GageTriggersFinal.Quarter3.FontSize = parseFloat(GageTriggersFinal.Quarter3.FontSize) / SVGRatioReal_VBox;
    GageTriggersFinal.Quarter4.FontSize = parseFloat(GageTriggersFinal.Quarter4.FontSize) / SVGRatioReal_VBox;

    GageTriggersFinal.Quarter1 = {...GageTriggersFinal.Quarter1, ...{Radius: RadiusInternal - GageTriggersFinal.Quarter1.FontSize / 1.2 / 2}};
    GageTriggersFinal.Quarter2 = {...GageTriggersFinal.Quarter2, ...{Radius: RadiusInternal + GageTriggersFinal.Quarter2.FontSize / 1.2 / 2}};
    GageTriggersFinal.Quarter3 = {...GageTriggersFinal.Quarter3, ...{Radius: RadiusInternal + GageTriggersFinal.Quarter3.FontSize / 1.2 / 2}};
    GageTriggersFinal.Quarter4 = {...GageTriggersFinal.Quarter4, ...{Radius: RadiusInternal - GageTriggersFinal.Quarter4.FontSize / 1.2 / 2}};

    /******** MERGING THE INPUTS TO PREVENT ERRORS *******/

    /******** GAGE TICKS ELEMENTS *******/

    const RealGageTickOverflow = parseFloat(GageTickOverflow) / SVGRatioReal_VBox;
    const GageTicksSVGVBoxDimensions = 100 + 2 * RealGageTickOverflow;
    const GageTicksSVGDimensions = GageTicksSVGVBoxDimensions * SVGRatioReal_VBox;

    const GageTickWidth = RealThickness + 2 * RealGageTickOverflow;
    const GageTickX = GageTicksSVGVBoxDimensions - RealThickness - 2 * RealGageTickOverflow;
    const GageTickY = GageTicksSVGVBoxDimensions / 2 - GageTickThickness / 2;
    /******** GAGE TICKS ELEMENTS *******/

    /******** GAGE TICKS TEXT ELEMENTS *******/
    const GageTickTextOuterfontSizeReal = parseFloat(GageTickTextOuterfontSize) / SVGRatioReal_VBox;
    const GageTickTextOuterPaddingInnerReal = parseFloat(GageTickTextOuterPaddingInner) / SVGRatioReal_VBox;
    const GageTickTextInnerfontSizeReal = parseFloat(GageTickTextInnerfontSize) / SVGRatioReal_VBox;
    const TextWithCoef = 0.75;

    const GageTickTextOuterWidth = (parseFloat(GageTickTextOuterfontSize) * 5 * TextWithCoef) / SVGRatioReal_VBox;

    const GageTickTextSVGBoxDimensions = GageTicksSVGVBoxDimensions + 2 * (GageTickTextOuterWidth + GageTickTextOuterPaddingInnerReal);
    const GageTickTextSVGDimensions = GageTickTextSVGBoxDimensions * SVGRatioReal_VBox;

    const GageTickTextInnerWidth = (parseFloat(GageTickTextInnerfontSize) * 5 * TextWithCoef) / SVGRatioReal_VBox;

    /******** GAGE TICKS TEXT ELEMENTS *******/

    /******** CALCULATING THE VALUE AND COLOR OF THE GAGE*******/
    var FilledColorFinal;

    //Detecting if linear progression or not
    if (GageTriggersFinal.Quarter2.Trigger) {
        //NON LINEAR GAGE
        if (parseFloat(Value) <= parseFloat(GageTriggersFinal.Quarter2.Trigger)) {
            FilledColorFinal = GageTriggersFinal.Quarter1.GageColor;
            ValuePercentage = (Value / parseFloat(GageTriggersFinal.Quarter2.Trigger)) * 25;
        } else if (parseFloat(Value) > parseFloat(GageTriggersFinal.Quarter2.Trigger) && parseFloat(Value) <= parseFloat(GageTriggersFinal.Quarter3.Trigger)) {
            FilledColorFinal = GageTriggersFinal.Quarter2.GageColor;
            ValuePercentage = (Value / parseFloat(GageTriggersFinal.Quarter3.Trigger)) * 50;
        } else if (parseFloat(Value) > parseFloat(GageTriggersFinal.Quarter3.Trigger) && parseFloat(Value) <= parseFloat(GageTriggersFinal.Quarter4.Trigger)) {
            FilledColorFinal = GageTriggersFinal.Quarter3.GageColor;
            ValuePercentage = (Value / parseFloat(GageTriggersFinal.Quarter4.Trigger)) * 75;
        } else if (parseFloat(Value) > parseFloat(GageTriggersFinal.Quarter4.Trigger)) {
            FilledColorFinal = GageTriggersFinal.Quarter4.GageColor;
            ValuePercentage = (Value / parseFloat(MaxValue)) * 100;
        }
    } else {
        //LINEAR GAGE
        if (parseFloat(Value) <= parseFloat(MaxValue) / 4) {
            FilledColorFinal = GageTriggersFinal.Quarter1.GageColor;
        } else if (parseFloat(Value) > parseFloat(MaxValue) / 4 && parseFloat(Value) <= (2 * parseFloat(MaxValue)) / 4) {
            FilledColorFinal = GageTriggersFinal.Quarter2.GageColor;
        } else if (parseFloat(Value) > (2 * MaxValue) / 4 && parseFloat(Value) <= (3 * parseFloat(MaxValue)) / 4) {
            FilledColorFinal = GageTriggersFinal.Quarter3.GageColor;
        } else if (parseFloat(Value) > (3 * parseFloat(MaxValue)) / 4) {
            FilledColorFinal = GageTriggersFinal.Quarter4.GageColor;
        }
        ValuePercentage = (Value / MaxValue) * 100;
    }

    var ValPercent = ((Value / parseFloat(MaxValue)) * 100).toFixed(0);

    /******** CALCULATING THE VALUE AND COLOR OF THE GAGE*******/

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    //const [val, setVal] = useState();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    }

    function GenerateArcPath(id, reverted, x, y, radius, startAngle, endAngle) {
        var start, end;
        if (reverted === "reverted") {
            end = polarToCartesian(x, y, radius, endAngle);
            start = polarToCartesian(x, y, radius, startAngle);
        } else {
            start = polarToCartesian(x, y, radius, endAngle);
            end = polarToCartesian(x, y, radius, startAngle);
        }

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");

        return <path id={id} fill="none" stroke="green" strokeWidth="1" d={d} />;
    }
    function GenerateArcD(reverted, x, y, radius, startAngle, endAngle) {
        var start, end;

        if (reverted === "reverted") {
            end = polarToCartesian(x, y, radius, endAngle);
            start = polarToCartesian(x, y, radius, startAngle);
        } else {
            start = polarToCartesian(x, y, radius, endAngle);
            end = polarToCartesian(x, y, radius, startAngle);
        }

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(" ");
        var startF = {x: start.x, y: start.y};
        var endF = {x: end.x, y: end.y};

        return {d: d, start: startF, end: endF};
    }

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var MajorTicksComponents = [];
    /****************************** TICKS *********************/

    for (var i = 0; i < 4; i++) {
        let TickElem = (
            <rect
                id={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${i * 90} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            ></rect>
        );
        MajorTicksComponents.push(TickElem);
    }

    /****************************** TICKS *********************/

    /****************************** TICKS EXTERNAL GAUGE TEXT *********************/

    var MajorTicksText = [];

    if (WithOuterText === true) {
        if (GageTriggersFinal.Quarter2.OuterText) {
            let XTextOuterZero =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 0);
            let YTextOuterZero =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 0);
            let TextOuterZero = (
                <text x={XTextOuterZero} y={YTextOuterZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {GageTriggersFinal.Quarter2.OuterText}
                </text>
            );
            MajorTicksText.push(TextOuterZero);
        }
        if (GageTriggersFinal.Quarter3.OuterText) {
            let XTextOuterZero =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 90);
            let YTextOuterZero =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 90);
            let TextOuterZero = (
                <text x={XTextOuterZero} y={YTextOuterZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {GageTriggersFinal.Quarter3.OuterText}
                </text>
            );
            MajorTicksText.push(TextOuterZero);
        }
        if (GageTriggersFinal.Quarter4.OuterText) {
            let XTextOuterZero =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 180);
            let YTextOuterZero =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 180);
            let TextOuterZero = (
                <text x={XTextOuterZero} y={YTextOuterZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {GageTriggersFinal.Quarter4.OuterText}
                </text>
            );
            MajorTicksText.push(TextOuterZero);
        }
    }
    /****************************** TICKS EXTERNAL GAUGE TEXT *********************/

    /****************************** TICKS INTERNAL GAUGE TEXT *********************/
    if (WithInnerText === true) {
        if (GageTriggersFinal.Quarter2.InnerText) {
            let XTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 0);
            let YTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 0);
            let TextInnerZero = (
                <text x={XTextInnerZero} y={YTextInnerZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {GageTriggersFinal.Quarter2.InnerText}
                </text>
            );
            MajorTicksText.push(TextInnerZero);
        }
        if (GageTriggersFinal.Quarter3.InnerText) {
            let XTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 90);
            let YTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 90);
            let TextInnerZero = (
                <text x={XTextInnerZero} y={YTextInnerZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {GageTriggersFinal.Quarter3.InnerText}
                </text>
            );
            MajorTicksText.push(TextInnerZero);
        }
        if (GageTriggersFinal.Quarter4.InnerText) {
            let XTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 180);
            let YTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 180);
            let TextInnerZero = (
                <text x={XTextInnerZero} y={YTextInnerZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {GageTriggersFinal.Quarter4.InnerText}
                </text>
            );
            MajorTicksText.push(TextInnerZero);
        }
    }

    /****************************** TICKS INTERNAL GAUGE TEXT *********************/

    /****************************** CENTRAL GAUGE TEXT *********************/
    var GageCentraltextMain, GageCentraltextSub, GageCustomCentralTextComp;
    if (GageCustomCentralText) {
        GageCustomCentralTextComp = GageCustomCentralText;
    } else {
        if (GageCentralText.MainText === "Percent") {
            GageCentraltextMain = ValPercent + "%";
        } else if (GageCentralText.MainText === "Value") {
            GageCentraltextMain = Value;
        } else {
            GageCentraltextMain = GageCentralText.MainText;
        }
        if (GageCentralText.SubText === "Value") {
            GageCentraltextSub = Value;
        } else {
            GageCentraltextSub = GageCentralText.SubText;
        }
    }

    /****************************** CENTRAL GAUGE TEXT *********************/

    /****************************** NEEDLE INDICATOR *********************/
    var NeedleIndicatorComp;
    var NeedleIndictatorInitial = {Active: false, WithKnob: true, Color: "rgba(150,255,255,0.5)", KnobDiameter: 15, StartAngle: 20, EndAngle: 340};
    var NeedleIndictatorFinal = _.merge(NeedleIndictatorInitial, NeedleIndicator);
    if (NeedleIndictatorFinal.Active === true) {
        let Rotation = (360 * ValuePercentage) / 100;
        var ArcD;
        if (NeedleIndictatorFinal.WithKnob === true) {
            ArcD = GenerateArcD("standard", 50, 50, NeedleIndictatorFinal.KnobDiameter, NeedleIndictatorFinal.StartAngle, NeedleIndictatorFinal.EndAngle);
            let D = ArcD.d;
            D = D + `M${ArcD.start.x} ${ArcD.start.y} L50 ${RealThickness / 2} L${ArcD.end.x} ${ArcD.end.y} Z`;
            NeedleIndicatorComp = (
                <g transform={`rotate(${Rotation} 50 50)`}>
                    <path d={D} fill={NeedleIndictatorFinal.Color} sroke="none" />
                </g>
            );
        } else {
            ArcD = GenerateArcD("standard", 50, 50, 30, 10, 350);

            let D = ArcD.d;
            let DFinal = `M${ArcD.start.x} ${ArcD.start.y} L50 ${RealThickness / 2}` + D + `L${ArcD.end.x} ${ArcD.end.y} `;

            NeedleIndicatorComp = (
                <g>
                    <path d={DFinal} fill="yellow" sroke="none" />
                </g>
            );
        }
    }
    /****************************** NEEDLE INDICATOR *********************/
    return (
        <div
            className={classes.Artibulles_Cis_Gage}
            ref={ref}
            style={{
                position: "relative",
                width: `${WithOuterText || WithInnerText ? GageTickTextSVGDimensions : SVGDimensions}px`,
                height: `${WithOuterText || WithInnerText ? GageTickTextSVGDimensions : SVGDimensions}px`,
            }}
        >
            <div className={classes.GageFixed} style={{zIndex: 1}}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r={`${RadiusInternal}`} strokeWidth={RealThickness} fill="none" stroke={EmptyColor} shapeRendering="geometricPrecision"></circle>
                </svg>
            </div>
            <div className={classes.GageIndicator} style={{zIndex: 10}}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle
                        shapeRendering="geometricPrecision"
                        cx="50"
                        cy="50"
                        r={`${RadiusInternal}`}
                        strokeWidth={RealThickness}
                        fill="none"
                        stroke={FilledColorFinal}
                        // strokeDasharray={`${SDashArray}, ${CircleCirconf}`}
                        // strokeDashoffset={SDashOffset}
                        strokeDasharray={`${(CircleCirconf * ValuePercentage) / 100}, ${(CircleCirconf * (100 - ValuePercentage)) / 100}`}
                        strokeDashoffset={`${(CircleCirconf * 25) / 100}`}
                    ></circle>
                </svg>
            </div>
            <div className={classes.GageNeedle} style={{zIndex: 50}}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    {NeedleIndicatorComp}
                </svg>
            </div>
            <div className={classes.GageTicks} style={{zIndex: 20}}>
                <svg
                    style={{display: "block", imageRendering: "optimizequality"}}
                    width={`${GageTicksSVGDimensions}`}
                    height={`${GageTicksSVGDimensions}`}
                    viewBox={`0 0 ${GageTicksSVGVBoxDimensions} ${GageTicksSVGVBoxDimensions}`}
                >
                    <defs>
                        {GenerateArcPath("Arc1", "standard", 50, 50, GageTriggersFinal.Quarter1.Radius, 20, 90)}
                        {GenerateArcPath("Arc2", "standard", 50, 50, GageTriggersFinal.Quarter2.Radius, 90, 180)}
                        {GenerateArcPath("Arc3", "standard", 50, 50, GageTriggersFinal.Quarter3.Radius, 180, 270)}
                        {GenerateArcPath("Arc4", "standard", 50, 50, GageTriggersFinal.Quarter4.Radius, 270, 360)}
                    </defs>

                    <text style={{fontSize: GageTriggersFinal.Quarter1.FontSize, fill: GageTriggersFinal.Quarter1.TextColor}}>
                        <textPath href="#Arc1" side="right" startOffset="40%" textAnchor="middle">
                            {GageTriggersFinal.Quarter1.Text}
                        </textPath>
                    </text>

                    <text style={{fontSize: GageTriggersFinal.Quarter2.FontSize, fill: GageTriggersFinal.Quarter2.TextColor}}>
                        <textPath href="#Arc2" side="left" startOffset="50%" textAnchor="middle">
                            {GageTriggers.Quarter2.Text}
                        </textPath>
                    </text>
                    <text style={{fontSize: GageTriggersFinal.Quarter3.FontSize, fill: GageTriggersFinal.Quarter3.TextColor}}>
                        <textPath href="#Arc3" side="left" startOffset="50%" textAnchor="middle">
                            {GageTriggers.Quarter3.Text}
                        </textPath>
                    </text>
                    <text style={{fontSize: GageTriggersFinal.Quarter4.FontSize, fill: GageTriggersFinal.Quarter4.TextColor}}>
                        <textPath href="#Arc4" side="right" startOffset="50%" textAnchor="middle">
                            {GageTriggers.Quarter4.Text}
                        </textPath>
                    </text>
                    {MajorTicksComponents}
                </svg>
            </div>
            <div className={classes.GageTicksText}>
                <svg
                    style={{display: "block", imageRendering: "optimizequality"}}
                    width={`${GageTickTextSVGDimensions}`}
                    height={`${GageTickTextSVGDimensions}`}
                    viewBox={`0 0 ${GageTickTextSVGBoxDimensions} ${GageTickTextSVGBoxDimensions}`}
                >
                    {MajorTicksText}
                </svg>
            </div>

            <div className={classes.GageIndicatorText} style={{width: `${SVGDimensions}px`, height: `${SVGDimensions}px`, padding: `${2 * RealThickness}px`, zIndex: 60}}>
                <div className={classes.GageIndicatorText_Main} style={{fontSize: GageCentralText.MainFontSize, display: GageCustomCentralText ? "none" : "flex"}}>
                    {GageCentraltextMain}
                </div>
                <div className={classes.GageIndicatorText_Sub} style={{fontSize: GageCentralText.SubFontSize, display: GageCustomCentralText ? "none" : "flex"}}>
                    {GageCentraltextSub}
                </div>
                {GageCustomCentralTextComp ? GageCustomCentralTextComp : null}
            </div>
        </div>
    );
    /****************************** RENDER *********************/
});

CircularGageFourQuarters.defaultProps = {
    ExternalDiameter: 100,
    Thickness: 10,
    Value: 10,
    MaxValue: 100,
    GageTriggers: {
        Quarter1: {text: null, TextColor: "black", GageColor: "white", FontSize: "8px", FontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
        Quarter2: {text: null, TextColor: "black", GageColor: "yellow", FontSize: "8px", FfontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
        Quarter3: {text: null, TextColor: "black", GageColor: "green", FontSize: "8px", FontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
        Quarter4: {text: null, TextColor: "black", GageColor: "red", FontSize: "8px", FontWeight: "300", FontFamily: "Roboto", OffsetRadius: 1},
    },
    WithOuterText: false,
    WithInnerText: false,
    GageTickTextOuterfontSize: "8px",
    GageTickTextOuterPaddingInner: 0,
    GageTickTextInnerfontSize: "8px",
    GageTickTextInnerPaddingOuter: 2,
    GageCentralText: {
        MainText: null,
        MainFontSize: "18px",
        SubText: null,
        SubFontSize: "16px",
    },
    GageCustomCentralText: null,
    EmptyColor: "white",
    GageTickThickness: 1,
    GageTickOverflow: 5,
    GageTickColor: "black",
    NeedleIndicator: {Active: false, Color: "black", KnobDiameter: 20},
};

CircularGageFourQuarters.propTypes = {
    /**
     * ExternalDiameter :
     */
    ExternalDiameter: PropTypes.any,
    /**
     * Thickness :
     */
    Thickness: PropTypes.any,
    /**
     * Value :
     */
    Value: PropTypes.any,
    /**
     * MaxValue :
     */
    MaxValue: PropTypes.any,
    /**
     * GageTriggers :
     */
    GageTriggers: PropTypes.any,
    /**
     * WithOuterText :
     */
    WithOuterText: PropTypes.any,
    /**
     * WithInnerText :
     */
    WithInnerText: PropTypes.any,
    /**
     * GageTickTextOuterfontSize :
     */
    GageTickTextOuterfontSize: PropTypes.any,
    /**
     * GageTickTextOuterPaddingInner :
     */
    GageTickTextOuterPaddingInner: PropTypes.any,
    /**
     * GageTickTextInnerfontSize :
     */
    GageTickTextInnerfontSize: PropTypes.any,
    /**
     * GageTickTextInnerPaddingOuter :
     */
    GageTickTextInnerPaddingOuter: PropTypes.any,
    /**
     * GageCentralText :
     */
    GageCentralText: PropTypes.any,
    /**
     * GageCustomCentralText :
     */
    GageCustomCentralText: PropTypes.any,
    /**
     * EmptyColor :
     */
    EmptyColor: PropTypes.any,
    /**
     * GageTickThickness :
     */
    GageTickThickness: PropTypes.any,
    /**
     * GageTickOverflow :
     */
    GageTickOverflow: PropTypes.any,
    /**
     * GageTickColor :
     */
    GageTickColor: PropTypes.any,
    /**
     * NeedleIndicator :
     */
    NeedleIndicator: PropTypes.any,
};

export default CircularGageFourQuarters;
