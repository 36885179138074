import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

// import MyCompanyEdit from "./MyCompanyEdit";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const SettingsRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div>Transactions Router</div>
    );

    /***************** RENDER ******************/
};

export default SettingsRouter;
