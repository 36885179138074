import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    const Data = ApiRes.data.data;
                    console.log("Data", Data);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        
        const ApiCall = {url: `${ProdURL}/api/tasks/getdaycalendar`, type: "get"};
        setLoading(true);
        // setLoginPhase("OTP code entered");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, [ProdURL]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return <div>Hello</div>;

    /***************** RENDER ******************/
};

export default Component;
