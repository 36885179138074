import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import {WarningTriangleRed, RotateRight} from "@artibulles-cis/react-icons";
import {useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import Button from "@artibulles-cis/react/Button";
import FormCompontent from "../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../../../../artibulles-cis/CheckBox/CheckBox";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CardTitle: {
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "flex-start",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    Title: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-start",
        padding: "0px 5px",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
        fontSize: "0.9rem",
    },

    WarningMessage: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "left",
    },
    FormWrapper: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        color: "black",
        textAlign: "left",
        margin: "0px 0px 20px 0px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        display: "flex",
        flex: "0 1 600px",
        // margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
    },
    ButtonBarExtend: {
        margin: "0px 5px",
        padding: "0px 5px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },
    ButtonIcon: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.9rem",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/
    const {isAdmin} = useSelector((state) => ({
        isAdmin: state.auth.User.isAdmin,
    }));
    /***************** CONST ******************/
    const {InvoiceId, GenerationMethod, HanldeConfirmPostCardWarnning, PassedFormValues} = props;
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = useState({
        InvoiceIssueDateIso: PassedFormValues.InvoiceIssueDateIso,
        InvoicePaymentExpectedCommunication: PassedFormValues.InvoicePaymentExpectedCommunication,
        AdminDevTestMode: PassedFormValues.AdminDevTestMode,
    });
    const [FormValues, setFormValues] = useState({
        InvoiceIssueDateIso: PassedFormValues.InvoiceIssueDateIso,
        InvoicePaymentExpectedCommunication: PassedFormValues.InvoicePaymentExpectedCommunication,
        AdminDevTestMode: PassedFormValues.AdminDevTestMode,
    });
    const [FieldOptions, setFieldOptions] = useState({});
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [Invalid, setInvalid] = useState(true); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [TempFormErrors, setTempFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;
        let FieldPristine = values.Pristine;
        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);
    };
    const HandleUseInvoiceNumberForCommunication = () => {
        let UpdateFormValues = {InvoicePaymentExpectedCommunication: PassedFormValues.Calc_InvoiceLegaReference};
        setFormValues({...FormValues, ...UpdateFormValues});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let TopBarContent = (
        <div className={classes.Title}>
            <div style={{flex: "1 1 auto", justifyContent: "center"}}>Create Sale Invoice</div>
        </div>
    );

    return (
        <CardModal
            CardID="ConfirmPostInvoice"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={TopBarContent}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={() => HanldeConfirmPostCardWarnning({Type: "Cancel"})}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            CardMaxWidth="600px"
            CardHeight="400px"
            // CardWindowPaddingVertical="10px"
            // CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="50px"

            ShowCard={true}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
        >
            <div className={classes.CardWindow}>
                <div className={classes.CardMain} style={{flex: "1 1 auto"}}>
                    <div className={classes.WarningMessage}>
                        <WarningTriangleRed IconSize="65px" />
                        <div style={{padding: "0px 5px "}}>By creatting this invoice, the Legal Number will be generated and you won't be able to delete this invoice</div>
                    </div>
                    <div style={{display: "flex", padding: "10px 15px"}}>Please Carefully review the Invoice Date and Communication</div>
                    <div className={classes.FormWrapper} style={{padding: "5px 15px"}}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "0 0 280px"}}>
                                <FormCompontent
                                    Name="InvoiceIssueDateIso"
                                    InitialValue={InitialFormValues.InvoiceIssueDateIso ? InitialFormValues.InvoiceIssueDateIso : null}
                                    FormValue={FormValues.InvoiceIssueDateIso ? FormValues.InvoiceIssueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoiceIssueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Date"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.InvoiceIssueDateIso, invalid: FormErrors.InvoiceIssueDateIso ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("InvoiceIssueDateIso", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    FieldMaxWidth="110px"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    CalendarOptions={{
                                        Min: PassedFormValues.MinIssueDateIso ? PassedFormValues.MinIssueDateIso : null,
                                        Max: PassedFormValues.MaxIssueDateIso ? PassedFormValues.MaxIssueDateIso : null,
                                    }}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="120px"
                                    LabelAlign="Left"
                                    // ReadOnly={FormViewMode === "View" ? true : false}
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "0 0 360px"}} data-tip data-for="InvoicePaymentExpectedCommunication">
                                <FormCompontent
                                    Name="InvoicePaymentExpectedCommunication"
                                    InitialValue={InitialFormValues.InvoicePaymentExpectedCommunication ? InitialFormValues.InvoicePaymentExpectedCommunication : null}
                                    FormValue={FormValues.InvoicePaymentExpectedCommunication ? FormValues.InvoicePaymentExpectedCommunication : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentExpectedCommunication", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Communication"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.InvoicePaymentExpectedCommunication, invalid: FormErrors.InvoicePaymentExpectedCommunication ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("InvoicePaymentExpectedCommunication", error)}
                                    DisplayMessageIn="Popup"
                                    FieldMaxWidth="240px"
                                    // Required={true}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    LabelMaxWidth="120px"
                                    LabelAlign="Left"
                                    // ReadOnly={FormViewMode === "View" ? true : false}
                                />
                            </div>

                            <ReactTooltip id="InvoicePaymentExpectedCommunication" place="top" type="dark" effect="solid">
                                <p className={classes.tooltipP}>Will be updated with Invoice Number when posted</p>
                            </ReactTooltip>
                            <div className={classes.FormField} style={{flex: "0 0 30px"}} data-tip data-for="ResetInvoiceCommunication">
                                <Button Height="20px" Width="20px" ClassExtendButton={classes.ButtonIcon} onClick={HandleUseInvoiceNumberForCommunication}>
                                    <RotateRight IconSize="18px" SVGStrokeWidth="18px" SVGFillColor="white" SVGFillHoverColor="white" />
                                </Button>
                            </div>
                            <ReactTooltip id="ResetInvoiceCommunication" place="right" type="dark" effect="solid">
                                <p className={classes.tooltipP}>Use Invoice Number</p>
                            </ReactTooltip>
                        </div>

                        <div className={classes.FormMultilineFlex}>
                            <div
                                className={classes.FormField}
                                style={{
                                    flex: "0 0 115px",
                                    marginTop: "20px",
                                    display: isAdmin === true ? "flex" : "none",
                                }}
                            >
                                <CheckBox
                                    Inputname="AdminDevTestMode"
                                    label="Developper Mode"
                                    initialvalue={InitialFormValues.AdminDevTestMode === true ? true : false}
                                    curvalue={FormValues.AdminDevTestMode}
                                    ShareInputValue={(value) => HandleUpdateFormValues("AdminDevTestMode", value)}
                                    // disabled={FormViewMode === "View" ? true : false}
                                    LabelMaxWidth="120px"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.ButtonBar}>
                        <Button
                            // Background="red"
                            Height="22px"
                            Width="60px"
                            ClassExtendButton={classes.Button}
                            onClick={() => HanldeConfirmPostCardWarnning({Type: "PostConfirm", GenerationMethod: GenerationMethod, Data: FormValues})}
                        >
                            Create
                        </Button>
                        <Button Background="orange" Height="22px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HanldeConfirmPostCardWarnning({Type: "Cancel"})}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default Component;
