import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";
import FormCompontent from "../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {PDF, Contract} from "@artibulles-cis/react-icons";
import Button from "@artibulles-cis/react/Button";
import PdfViewer from "../PdfViewer";
import GenerateInvoiceTabNav from "./GenerateInvoiceTabNav/GenerateInvoiceTabNav";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CardContentWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        textAlign: "left",
    },
    ContentFlex: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    ContentLeft: {
        flex: "1 1 auto",
        minWidth: "1200px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
        height: "100%",
        overflow: "hidden",
    },
    Scroll: {
        boxSizing: "border-box",
        height: "100%",
        overflow: "hidden",
    },

    ContentRight: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
    },
    FormGroup: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "row",
    },
    FormGroupCol: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },

    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormFieldGroup: {
        margin: "2px 5px 2px 5px",
    },
    FormField: {
        flex: "0 1 600px",
        flexDirection: "row",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    SupplierAddress: {
        display: "flex",
        flexDirection: "column",
    },
    SupplierAddressLine: {
        display: "flex",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 0px 2px 0px",
    },
    FormFlex: {
        display: "flex",
        padding: "0px 15px 0px 0px",
    },
    FormLeft: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
    },
    FormRight: {
        flex: "1 1 auto",

        display: "flex",
        flexDirection: "column",
    },
    PDFViewerIcon: {
        margin: "0px",
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "flex-end",
    },
    InvoiceSummaryBox: {
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "column",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        margin: "5px 0px 0px 10px",
        padding: "5px 5px",

        borderRadius: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    InvoiceSummary: {
        display: "flex",
        // padding: "0px 0px 0px 5px",
        width: "210px",
        alignSelf: "flex-end",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "0 0 120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    SummaryInvoiceError: {
        color: "orange",
        fontWeight: "600",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    FormPDFViewer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
    },
    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        flex: "0 0 35px",
        // height: "45px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "0 0 1400px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightInfo: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 45px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },
    ItemsTable: {
        padding: "0px 5px 0px 0px",
    },
    Details: {
        minHeight: "150px",
    },
}));

const GenerateInvoicePDF = React.memo(function GenerateInvoicePDF(props) {
    const {SaleInvoiceID, FormValuesFromParent, FieldOptionsParent, FormViewMode, CustomerAccountingPreference} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_FormValues = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValuesParent, setInitialFormValuesParent] = React.useState({}); //INITIAL FORM VALUES
    const [FormValuesParent, setFormValuesParent] = React.useState({}); //FINAL FORM VALUES
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FINAL FORM VALUES
    const [SupplierAddressComponent, setSupplierAddressComponent] = React.useState(null); //FINAL FORM VALUES
    const [SummaryFormComponent, setSummaryFormComponent] = React.useState(null); //FINAL FORM VALUES
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [ShowViewPDF, setShowViewPDF] = useState(false); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [PDFFileURL, setPDFFileURL] = useState(null);
    const [TotalComponent, setTotalComponent] = useState(null);
    const [FormToSave, setFormToSave] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateFormComponent = useCallback(() => {
        let FormSummaryComponent;
        if (FormValuesParent) {
            FormSummaryComponent = (
                <React.Fragment>
                    <div className={classes.FormGroup}>
                        <div className={classes.FormGroupCol} style={{flex: "0 0 535px"}}>
                            <div className={classes.FormFieldGroup} style={{width: "500px"}}>
                                <FormCompontent
                                    Name="ref_Customer"
                                    InitialValue={FormValuesParent.ref_Customer ? FormValuesParent.ref_Customer : null}
                                    FormValue={FormValuesParent.ref_Customer ? FormValuesParent.ref_Customer : null}
                                    OutputSelectedValues={(values) => handleSelectChangeParent("ref_Customer", values)}
                                    SelectOptions={FieldOptionsParent.ref_Customer}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Customer"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortIcons={false}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="110px"
                                    LabelAlign="Left"
                                    Required={true}
                                    // Disabled={true}
                                    ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormFieldGroup} style={{width: "500px", fontSize: "0.7rem"}}>
                                {SupplierAddressComponent}
                            </div>
                        </div>
                        <div className={classes.FormGroupCol} style={{flex: "0 0 390px"}}>
                            <div className={classes.FormFieldGroup} style={{width: "390px", marginLeft: "5px"}}>
                                <FormCompontent
                                    Name="InvoiceIssueDateIso"
                                    InitialValue={FormValuesParent.InvoiceIssueDateIso ? FormValuesParent.InvoiceIssueDateIso : null}
                                    FormValue={FormValuesParent.InvoiceIssueDateIso ? FormValuesParent.InvoiceIssueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValuesParent("InvoiceIssueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Date"
                                    NoMessage={false}
                                    FieldMaxWidth="210px"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="120px"
                                    LabelAlign="Left"
                                    ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormFieldGroup} style={{width: "380px", margin: "0px 0px 0px 0px", display: `${FormValuesParent.ref_PaymentTerms ? "none" : "flex"}`}}>
                                <FormCompontent
                                    Name="ManualInvoicePaymentDueDateIso"
                                    InitialValue={FormValuesParent.ManualInvoicePaymentDueDateIso ? FormValuesParent.ManualInvoicePaymentDueDateIso : null}
                                    FormValue={FormValuesParent.ManualInvoicePaymentDueDateIso ? FormValuesParent.ManualInvoicePaymentDueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValuesParent("ManualInvoicePaymentDueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Due Date"
                                    NoMessage={false}
                                    DisplayMessageIn="Popup"
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="120px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="210px"
                                    ReadOnly={true}
                                />
                            </div>
                            <div
                                className={classes.FormFieldGroup}
                                style={{
                                    width: "400px",
                                    // width: "380px",
                                    margin: "0px 0px 0px 5px",
                                    display: `${FormValuesParent.ref_PaymentTerms && FormValuesParent.InvoicePaymentDueDateIso ? "flex" : "none"}`,
                                }}
                            >
                                <FormCompontent
                                    Name="InvoicePaymentDueDateIso"
                                    InitialValue={FormValuesParent.InvoicePaymentDueDateIso ? FormValuesParent.InvoicePaymentDueDateIso : null}
                                    FormValue={FormValuesParent.InvoicePaymentDueDateIso ? FormValuesParent.InvoicePaymentDueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValuesParent("InvoicePaymentDueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Due Date"
                                    NoMessage={false}
                                    DisplayMessageIn="Popup"
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    // FieldMaxWidth="350px"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="120px"
                                    FieldMaxWidth="210px"
                                    LabelAlign="Left"
                                    ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormFieldGroup} style={{width: "395px", display: `${FormValuesParent.ManualInvoicePaymentDueDateIso ? "none" : "flex"}`}}>
                                <FormCompontent
                                    Name="ref_PaymentTerms"
                                    InitialValue={FormValuesParent.ref_PaymentTerms ? FormValuesParent.ref_PaymentTerms : null}
                                    FormValue={FormValuesParent.ref_PaymentTerms ? FormValuesParent.ref_PaymentTerms : null}
                                    OutputSelectedValues={(values) => handleSelectChangeParent("ref_PaymentTerms", values)}
                                    SelectOptions={FieldOptionsParent.ref_PaymentTerms}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Payment Terms"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortField="value"
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    Clearable={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="120px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="240px"
                                    ReadOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "0 0 500px"}}>
                            <FormCompontent
                                Name="vat_number"
                                InitialValue={FormValuesParent.vat_number ? FormValuesParent.vat_number : null}
                                FormValue={FormValuesParent.vat_number ? FormValuesParent.vat_number : null}
                                OutputValue={(values) => HandleUpdateFormValuesParent("vat_number", values)}
                                Component="Input"
                                Variant="Outlined"
                                Label="VAT number"
                                NoMessage={false}
                                ReadOnly={true}
                                // LegendOffsetHeight="0"
                                // LegendOffsetWidth="0"

                                LabelMaxWidth="110px"
                                LabelAlign="Left"
                            />
                        </div>
                    </div>

                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "0 0 525px"}}>
                            <FormCompontent
                                Name="ref_MyBankAccount"
                                InitialValue={FormValuesParent.ref_MyBankAccount ? FormValuesParent.ref_MyBankAccount : null}
                                FormValue={FormValuesParent.ref_MyBankAccount ? FormValuesParent.ref_MyBankAccount : null}
                                OutputSelectedValues={(values) => handleSelectChangeParent("ref_MyBankAccount", values)}
                                SelectOptions={FieldOptionsParent.ref_MyBankAccount}
                                Component="Select"
                                Variant="Outlined"
                                Label="Bank Account"
                                NoMessage={false}
                                MultiSelect={false}
                                SortSelectOptions="Asc"
                                SortIcons={false}
                                AllowCreateOption={false}
                                // CreateDeleteIfUnselected={}
                                // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                LabelMaxWidth="110px"
                                FieldMaxWidth="387px"
                                LabelAlign="Left"
                                Required={true}
                                // Disabled={true}
                                ReadOnly={true}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "1 1 auto", justifyContent: "left"}}>
                            <FormCompontent
                                Name="InvoicePaymentExpectedCommunication"
                                InitialValue={FormValuesParent.InvoicePaymentExpectedCommunication ? FormValuesParent.InvoicePaymentExpectedCommunication : null}
                                FormValue={FormValuesParent.InvoicePaymentExpectedCommunication ? FormValuesParent.InvoicePaymentExpectedCommunication : null}
                                OutputValue={(values) => HandleUpdateFormValuesParent("InvoicePaymentExpectedCommunication", values)}
                                Component="Input"
                                Variant="Outlined"
                                Label="Communication"
                                NoMessage={false}
                                DisplayMessageIn="Popup"
                                FieldMaxWidth="240px"
                                // Required={true}
                                // LegendOffsetHeight="0"
                                // LegendOffsetWidth="0"
                                LabelMaxWidth="120px"
                                LabelAlign="Left"
                                ReadOnly={true}
                            />
                        </div>
                    </div>

                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}></div>
                </React.Fragment>
            );
        }
        setSummaryFormComponent(FormSummaryComponent);
    }, [FormValuesParent]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                // toast.error(`Payment not created`, {autoClose: true});
            } else {
                if (!ApiCallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data", Data);

                        let InvoiceGenerationDetail = Data.InvoiceGenerationDetail;
                        let InitialValues = {
                            Language: InvoiceGenerationDetail ? (InvoiceGenerationDetail.Language ? InvoiceGenerationDetail.Language : "English") : "English",
                            GeneralConditions: InvoiceGenerationDetail ? InvoiceGenerationDetail.GeneralConditions : null,
                            Template: InvoiceGenerationDetail ? InvoiceGenerationDetail.Template : null,
                            IncludeVATColumn: InvoiceGenerationDetail ? InvoiceGenerationDetail.IncludeVATColumn : null,
                            SummaryNote1: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote1 : null,
                            SummaryNote2: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote2 : null,
                            SummaryNote3: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote3 : null,
                            WithReferenceCol: InvoiceGenerationDetail ? InvoiceGenerationDetail.WithReferenceCol : null,
                            WithVATColumn: InvoiceGenerationDetail ? InvoiceGenerationDetail.WithVATColumn : null,
                            CustomerReference: InvoiceGenerationDetail ? InvoiceGenerationDetail.CustomerReference : null,
                            VerticalSpaceDetailSummaryTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceDetailSummaryTable : null,
                            VerticalSpaceContactSummaryTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceContactSummaryTable : null,
                            VerticalSpaceSummaryDetailTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceSummaryDetailTable : null,
                        };
                        setFormValues(InitialValues);
                        setInitialFormValues(InitialValues);
                        let LanguageOptions = [
                            {value: "English", label: "English"},
                            {value: "French", label: "French"},
                        ];

                        let ContactOpions = [];
                        if (Data.Contacts && Array.isArray(Data.Contacts) && Data.Contacts.length > 0) {
                            Data.Contacts.forEach((elem) => {
                                ContactOpions.push({value: elem._id, label: `${elem.Email} - ${elem.Contact.FirstName}`});
                            });
                        }
                        setFieldOptions({Contacts: ContactOpions, Language: LanguageOptions, GeneralConditions: [], Template: []});

                        let Invoice = Data.Invoice;
                        if (Invoice) {
                            if (Invoice.NeedsToRegeneratePDF === false) {
                                if (Invoice.Invoices_File_Path && Invoice.Invoices_File_Path[0]) {
                                    let FileUrl = Invoice.Invoices_File_Path[0].serverPath;
                                    setPDFFileURL(FileUrl);
                                    setShowViewPDF(true);
                                } else {
                                    setPDFFileURL(null);
                                    setShowViewPDF(false);
                                }
                            }
                        }
                        Ref_FormValues.current = InitialValues;
                    }
                } else {
                    if (ApiCallType === "GeneratePDF") {
                        if (ApiRes && ApiRes.data && ApiRes.data.data) {
                            let Data = ApiRes.data.data;
                            let InvoiceGenerationDetail = Data.InvoiceGenerationDetail;

                            let InitialValues = {
                                Language: InvoiceGenerationDetail ? (InvoiceGenerationDetail.Language ? InvoiceGenerationDetail.Language : "English") : "English",
                                GeneralConditions: InvoiceGenerationDetail ? InvoiceGenerationDetail.GeneralConditions : null,
                                Template: InvoiceGenerationDetail ? InvoiceGenerationDetail.Template : null,
                                IncludeVATColumn: InvoiceGenerationDetail ? InvoiceGenerationDetail.IncludeVATColumn : null,
                                SummaryNote1: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote1 : null,
                                SummaryNote2: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote2 : null,
                                SummaryNote3: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote3 : null,
                                WithReferenceCol: InvoiceGenerationDetail ? InvoiceGenerationDetail.WithReferenceCol : null,
                                WithVATColumn: InvoiceGenerationDetail ? InvoiceGenerationDetail.WithVATColumn : null,
                                CustomerReference: InvoiceGenerationDetail ? InvoiceGenerationDetail.CustomerReference : null,
                                VerticalSpaceDetailSummaryTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceDetailSummaryTable : null,
                                VerticalSpaceContactSummaryTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceContactSummaryTable : null,
                                VerticalSpaceSummaryDetailTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceSummaryDetailTable : null,
                            };
                            setFormValues(InitialValues);
                            setInitialFormValues(InitialValues);
                            let LanguageOptions = [
                                {value: "English", label: "English"},
                                {value: "French", label: "French"},
                            ];
                            setFieldOptions({Contacts: Data.Contacts, Language: LanguageOptions, GeneralConditions: [], Template: []});
                            let Invoice = Data.Invoice;
                            if (Invoice) {
                                if (Invoice.NeedsToRegeneratePDF === false) {
                                    if (Invoice.Invoices_File_Path && Invoice.Invoices_File_Path[0]) {
                                        let FileUrl = Invoice.Invoices_File_Path[0].serverPath;
                                        setPDFFileURL(FileUrl);
                                        setShowViewPDF(true);
                                    }
                                } else {
                                    setPDFFileURL(null);
                                    setShowViewPDF(false);
                                }
                            }
                            Ref_FormValues.current = InitialValues;
                        }
                    } else if (ApiCallType === "UpdateDetails") {
                        if (ApiRes && ApiRes.data && ApiRes.data.data) {
                            let Data = ApiRes.data.data;
                            let InvoiceGenerationDetail = Data.InvoiceGenerationDetail;
                            console.log("InvoiceGenerationDetail", InvoiceGenerationDetail);
                            setApiCallType(null);
                            let InitialValues = {
                                Language: InvoiceGenerationDetail ? (InvoiceGenerationDetail.Language ? InvoiceGenerationDetail.Language : "English") : "English",
                                GeneralConditions: InvoiceGenerationDetail ? InvoiceGenerationDetail.GeneralConditions : null,
                                Template: InvoiceGenerationDetail ? InvoiceGenerationDetail.Template : null,
                                IncludeVATColumn: InvoiceGenerationDetail ? InvoiceGenerationDetail.IncludeVATColumn : null,
                                SummaryNote1: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote1 : null,
                                SummaryNote2: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote2 : null,
                                SummaryNote3: InvoiceGenerationDetail ? InvoiceGenerationDetail.SummaryNote3 : null,
                                WithReferenceCol: InvoiceGenerationDetail ? InvoiceGenerationDetail.WithReferenceCol : null,
                                WithVATColumn: InvoiceGenerationDetail ? InvoiceGenerationDetail.WithVATColumn : null,
                                CustomerReference: InvoiceGenerationDetail ? InvoiceGenerationDetail.CustomerReference : null,
                                VerticalSpaceDetailSummaryTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceDetailSummaryTable : null,
                                VerticalSpaceContactSummaryTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceContactSummaryTable : null,
                                VerticalSpaceSummaryDetailTable: InvoiceGenerationDetail ? InvoiceGenerationDetail.VerticalSpaceSummaryDetailTable : null,
                            };
                            setFormValues(InitialValues);
                            setInitialFormValues(InitialValues);
                            let LanguageOptions = [
                                {value: "English", label: "English"},
                                {value: "French", label: "French"},
                            ];
                            setFieldOptions({Contacts: Data.Contacts, Language: LanguageOptions, GeneralConditions: [], Template: []});
                            Ref_FormValues.current = InitialValues;
                            setFormToSave(false);
                            let Invoice = Data.Invoice;
                            if (Invoice) {
                                if (Invoice.NeedsToRegeneratePDF === false) {
                                    if (Invoice.Invoices_File_Path && Invoice.Invoices_File_Path[0]) {
                                        let FileUrl = Invoice.Invoices_File_Path[0].serverPath;
                                        setPDFFileURL(FileUrl);
                                        setShowViewPDF(true);
                                    }
                                } else {
                                    setPDFFileURL(null);
                                    setShowViewPDF(false);
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (FormValuesFromParent) {
            setInitialFormValuesParent(FormValuesFromParent);
            setFormValuesParent(FormValuesFromParent);
            if (FormValuesFromParent && FormValuesFromParent.InitialAddress) {
                let SupplierAddressComp = (
                    <div className={classes.SupplierAddress} style={{marginLeft: "110px"}}>
                        <div className={classes.SupplierAddressLine}>{FormValuesFromParent.InitialAddress ? FormValuesFromParent.InitialAddress.Line1 : ""}</div>
                        <div className={classes.SupplierAddressLine} style={{display: `${FormValuesFromParent.InitialAddress ? (FormValuesFromParent.InitialAddress.Line2 ? null : "none") : "none"}`}}>
                            {FormValuesFromParent.InitialAddress ? FormValuesFromParent.InitialAddress.Line2 : ""}
                        </div>
                        <div className={classes.SupplierAddressLine}>{FormValuesFromParent.InitialAddress ? FormValuesFromParent.InitialAddress.ZipCity : ""}</div>
                        <div className={classes.SupplierAddressLine}>{FormValuesFromParent.InitialAddress ? FormValuesFromParent.InitialAddress.Country : ""}</div>
                    </div>
                );
                setSupplierAddressComponent(SupplierAddressComp);
            }
            GenerateFormComponent();
        }
    }, [FormValuesFromParent, GenerateFormComponent]);

    useEffect(() => {
        //Getting the Invoice Generation Details
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoicegeneration/${SaleInvoiceID}`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const handleSelectChangeParent = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        var UpdatedFormValues = {...FormValuesParent, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValuesParent(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
    };
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValuesParent = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValuesParent({...FormValuesParent, ...UpdateFormValues});
    };

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };

    const HandleUpdateTableLine = (Data) => {
        let TotalsComponentTemp;
        if (Data) {
            let SaleInvoiceItems = Data.SaleInvoiceItems;
            console.log("SaleInvoiceItems", SaleInvoiceItems);
            if (SaleInvoiceItems && Array.isArray(SaleInvoiceItems)) {
                let TotalVATExc = 0,
                    TotalVATInc = 0,
                    TotalVAT = 0,
                    VATLines = [];

                SaleInvoiceItems.forEach((elem) => {
                    TotalVATExc = TotalVATExc + elem.CalcTotalVATExcluded;
                    TotalVATInc = TotalVATInc + elem.CalcTotalVATIncluded;
                    TotalVAT = TotalVAT + elem.CalcVATAmount;
                    let VATCode = elem.TaxCode ? elem.TaxCode.InvoiceLabel_EN : 0;
                    let Index = _.findIndex(VATLines, {VatCode: VATCode});
                    if (Index < 0) {
                        VATLines.push({VatCode: VATCode, Amount: elem.CalcVATAmount});
                    } else {
                        VATLines[Index].Amount = VATLines[Index].Amount + elem.CalcVATAmount;
                    }
                });

                let VATDetailsLines = [];
                if (VATLines && Array.isArray(VATLines)) {
                    VATLines.forEach((elem) => {
                        let VatEntry = (
                            <div className={classes.InvoiceSummary_Line}>
                                <div className={classes.InvoiceSummary_Line_Title}>{elem.VatCode}:</div>
                                <div className={classes.InvoiceSummary_Line_Value}>
                                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(elem.Amount)}
                                </div>
                            </div>
                        );
                        VATDetailsLines.push(VatEntry);
                    });
                }
                TotalsComponentTemp = (
                    <div className={classes.InvoiceSummary}>
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title} style={{fontWeight: "600"}}>
                                Total VAT exc.:
                            </div>
                            <div className={classes.InvoiceSummary_Line_Value} style={{fontWeight: "600"}}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(TotalVATExc)}
                            </div>
                        </div>
                        {VATDetailsLines}
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title} style={{fontSize: "1rem", fontWeight: "600"}}>
                                Total:
                            </div>
                            <div className={classes.InvoiceSummary_Line_Value} style={{fontSize: "1rem", fontWeight: "600", borderTop: "1px solid grey"}}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(TotalVATInc)}
                            </div>
                        </div>
                    </div>
                );
            } else {
                TotalsComponentTemp = <div className={classes.SummaryInvoiceError}>Please add Invoice Items</div>;
            }
        } else {
            TotalsComponentTemp = <div className={classes.SummaryInvoiceError}>Please add Invoice Items</div>;
        }
        setTotalComponent(TotalsComponentTemp);
    };

    const HandleReloadPDF = () => {
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoice/${SaleInvoiceID}`, type: "get"};
        setLoading(true);
        setApiCallType("ReloadPDF");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleViewPDF = () => {
        // console.log("HandleViewPDF");
        setShowViewPDF(!ShowViewPDF);
    };

    const HandleSaveDraft = () => {
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoicegeneration/${SaleInvoiceID}`, type: "put", data: Ref_FormValues.current};
        setLoading(true);
        setApiCallType("UpdateDetails");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        // console.log("FormValues", FormValues);
        APIInternal();
    };
    const HandleGenerateInvoice = () => {
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoicegeneration/generatepdf/${SaleInvoiceID}`, type: "post", data: Ref_FormValues.current};
        setLoading(true);
        setShowViewPDF(false);
        setApiCallType("GeneratePDF");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleFormFieldChange = (field, value) => {
        // console.log("field", field);
        // console.log("value", value);
        let UpdateFormValues = {[field]: value};
        Ref_FormValues.current = {...Ref_FormValues.current, ...UpdateFormValues};
        //We should compare with Initial Values...
        if (InitialFormValues === Ref_FormValues.current) {
            console.log("Some fileds are identical");
        } else {
            console.log("fileds have changed");
        }
        setFormToSave(true);
    };

    const HandleSubmitForm = () => {
        // console.log("Ref_FormValues.current", Ref_FormValues.current);
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoicegeneration/${SaleInvoiceID}`, type: "put", data: Ref_FormValues.current};
        setLoading(true);
        setApiCallType("UpdateDetails");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormTitle = (
        <div className={classes.FormTitle_Wrapper}>
            <div className={classes.FormTitleMain}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Logo_Wrapper}>
                        <Contract IconSize="30px" SVGFillColor="white" />
                    </div>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>{FormValuesFromParent.Calc_InvoiceLegaReference}</h1>
                        <div className={classes.ActionButton}>
                            <Button Height="30px" Width="150px" style={{margin: "0px 0px", padding: "0px 5px 0px 5px"}} onClick={HandleSaveDraft} disabled={FormToSave === true ? false : true}>
                                Save Draft
                            </Button>
                        </div>
                        <div className={classes.ActionButton}>
                            <Button
                                Height="30px"
                                Width="150px"
                                style={{margin: "0px 0px", padding: "0px 5px 0px 5px", justifyContent: "space-around", display: `${FormViewMode === "View" ? "none" : null}`}}
                                onClick={HandleGenerateInvoice}
                            >
                                <PDF SVGFillColor="white" IconSize="25px" />
                                Generate PDF
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    let PdfViewerComponent;
    if (ShowViewPDF === true) {
        PdfViewerComponent = <PdfViewer FileUrl={PDFFileURL} />;
    } else {
        PdfViewerComponent = null;
    }
    return (
        <div className={classes.CardContentWrapper}>
            {FormTitle}
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            <div className={classes.ContentFlex}>
                <div className={classes.ContentLeft}>
                    <div className={classes.Scroll}>
                        <PerfectScrollBarWrapper Active={true}>
                            <div className={classes.FormFlex}>
                                <div className={classes.FormLeft}>{SummaryFormComponent}</div>
                                <div className={classes.FormRight}>
                                    <div className={classes.PDFViewerIcon} onClick={HandleViewPDF} style={{display: `${PDFFileURL ? "flex" : "none"}`}}>
                                        <div style={{display: `${PDFFileURL ? "flex" : "none"}`}}>
                                            <PDF />
                                        </div>
                                    </div>

                                    <div className={classes.InvoiceSummaryBox}>
                                        <div className={classes.InvoiceSummary_Line} style={{borderBottom: "1px solid grey", fontSize: "0.85rem"}}>
                                            Invoice Summary
                                        </div>
                                        {TotalComponent}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.Details}>
                                <GenerateInvoiceTabNav
                                    SaleInvoiceID={SaleInvoiceID}
                                    FieldOptions={FieldOptions}
                                    FormViewMode={FormViewMode}
                                    FormValues={FormValues}
                                    PDFUploadedUpdate={HandleReloadPDF}
                                    HandleUpdateTableLine={HandleUpdateTableLine}
                                    HandleReloadPDF={HandleReloadPDF}
                                    HandleFormFieldChange={HandleFormFieldChange}
                                    HandleSubmitForm={HandleSubmitForm}
                                    CustomerAccountingPreference={CustomerAccountingPreference}
                                />
                            </div>
                        </PerfectScrollBarWrapper>
                    </div>
                </div>
                <div className={classes.ContentRight}>
                    <div className={classes.FormPDFViewer} style={{display: `${ShowViewPDF === true ? "flex" : "none"}`}}>
                        {PdfViewerComponent}
                    </div>

                    <div className={classes.FormPDFViewer} style={{display: `${ShowViewPDF === true ? "none" : "flex"}`}}>
                        <div style={{color: "orange"}}>Inputs Have Changed, Please Regenerate The PDF</div>
                    </div>
                </div>
            </div>
        </div>
    );

    /***************** RENDER ******************/
});

export default GenerateInvoicePDF;
