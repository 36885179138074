import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import CardModal from "../../../../../../../artibulles-cis/CardModal/CardModal";
import AccountingTransactionEntryEdit from "./TransactionEdit/AccountingTransactionEntryEdit"
import {PDF} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "5px",
        justifyContent: "flex-start",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        if (value && Array.isArray(value) && value.length > 0) {
            let CellRenderComp = [];
            value.forEach((elem) => {
                let IconElem = (
                    <div onClick={() => HandleViewPdf(elem.serverPath)}>
                        <PDF SVGFillColor="white" />
                    </div>
                );
                CellRenderComp.push(IconElem);
            });

            return CellRenderComp;
        } else {
            return null;
        }
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const StatusRendering = (params) => {
        let RowData = params.data;

        let Status = RowData.Status;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Posted") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                            Posted
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Reconciled") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "green"}}>
                            Reconciled
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    };
    const AccountNumberRendering = (params) => {
        let RowData = params.data;
        let counterparty_bankAccount_number = RowData.counterparty_bankAccount_number;
        let location = RowData.location;
        let Output;
        if (counterparty_bankAccount_number) {
            Output = counterparty_bankAccount_number;
        } else {
            Output = location;
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };
    const AccountOwnerRendering = (params) => {
        let RowData = params.data;
        let counterparty_bankAccount_name = RowData.counterparty_bankAccount_name;
        let merchant = RowData.merchant;
        let Output;
        if (counterparty_bankAccount_name) {
            Output = counterparty_bankAccount_name;
        } else {
            Output = merchant;
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };

    const ColumnButtonStyle = {
        Width: 40,
        padding: "0px 0px 0px 0px",
    };
    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };
    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];
    const TableColumns = [
        {
            field: "transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
        },
        {
            field: "calc_RefTransaction",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 2,
            sort: "desc",
            // Type: "Date",
        },
        {
            field: "Status",
            //
            headerName: "Status",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "banking_amount_transaction",
            headerName: "Amount Statement",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "communication",
            //
            headerName: "Communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            sortingOrder: ["asc", "desc"],
            // cellRenderering: StatusRendering,
            // cellStyle: PaymentCell,
        },

        {
            field: "counterparty_bankAccount_number",
            //
            headerName: "Account Number",
            headerClass: "ag-CenterHeader",
            width: 210,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountNumberRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "counterparty_bankAccount_name",
            //
            headerName: "Account Owner",
            headerClass: "ag-CenterHeader",
            width: 250,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountOwnerRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "transaction_source",
            headerName: "Source",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: true,
            // Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
		StatusBar : true
    };

    let URLAbsolute = {
        ALL: `${ProdURL}/api/companyaccount/transactions`,
        QUERY: "",
        DELETEONE: "",
        DELETEMANY: "",
        PUT: "",
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [ReloadTableData, setReloadTableData] = useState(true);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        // console.log("Handle Close Edit Card");
        setShowEditCard(false);
        setEditCardId(null);
        setTimeout(() => {
            setReloadTableData(true);
        }, 300);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;

    if (ShowEditCard === true) {
        ModalCard = (
            <CardModal
                CardID="EditInvoiceCard"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"
                ZIndex="1000"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
			<AccountingTransactionEntryEdit Id={EditCardId} HandleCloseViewCard={HandleCloseEditCard} />
			</CardModal>
        );
    }
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCard}
            <div className={classes.TableWrapper}>
                <AGGridTable
                    URLAbsolute={URLAbsolute}
                    TableColumns={TableColumns}
                    MainDataSource="Transactions"
                    RowActionButtons={TableRowButtons}
                    ColumnButtonStyle={ColumnButtonStyle}
                    TableOptions={TableOptions}
                    ReloadData={ReloadTableData}
                    SyncReloadDataStateFunction={setReloadTableData}
                    // TotalRow={TotalRow}
                />
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
