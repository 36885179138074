import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';
import { ArrowSimpleLeft, ArrowSimpleRight } from '@artibulles-cis/react-icons';

const LockedstylesWithProps = props => {
  return {
    TabNavMenu: {
      zIndex: props.ZIndex ? props.ZIndex : null,
      flex : `0 0 ${props.TabNavMenuHeight}`
    },
    TabNavMenuScrollable: {
      flex: props.MenuFullWidth ? '1 1 auto' : '0 1 auto',
    },
  };
};

const Lockedstyles = {
  TabNavMenuScrollable: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
  },
};

const styles = createUseStyles(theme => ({
  TabNavMenuScrollIcon: theme.TabNavMenuScrollIcon,
  TabNavMenu: theme.TabNavMenu,
  TabNavMenuSeparationShadow: theme.TabNavMenuSeparationShadow,
  TabNavMenuSeparationLine: theme.TabNavMenuSeparationLine,
  TabNavMenuScrollableController: theme.TabNavMenuScrollableController,
  TabNavMenuScrollableControllerFixed: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TabNavMenuScrollableControllerEvent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));



//Frequency at wich to update the window size (prevent rerunning the function too many times)
function debounce(fn, ms) {
    let timer;
    return (_) => {
        clearTimeout(timer);
        timer = setTimeout((_) => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);
    };
}



/**
 * Create a TabMenuBar Containing the TabNavLink Elements
 * Can be scrollable or not
 */
const TabNavMenu = React.forwardRef(function TabNavMenu(props, ref) {
  const {
    Scrollable,
    ZIndex,
    ClassOverrideTabNavMenu,
    MenuFullWidth,
    TabNavMenuHeight,
    TabNavMenuSeparation,
    ClassExtendTabNavMenu,
    ClassExtendTabNavMenuScrollableController,
    ScrollIconStyle,
    className,
    children,
    ...other
  } = props;
  const theme = useTheme();
  const classes = styles({ ...props, theme });
  const [ScrollableInfo, setScrollableInfo] = React.useState(null);
  const [ArrayOfNavElemPos, setArrayOfNavElemPos] = React.useState([]);
  const [HideLeftScrollButton, setHideLeftScrollButton] = React.useState(true);
  const [HideRightScrollButton, setHideRightScrollButton] = React.useState(false);
  const [NeedsScrollable, setNeedsScrollable] = React.useState(false);

  const ScrollableMenu = React.useRef();
  var LeftScrollButton, RightScrollButton;

  //Calculate NavLinks Position and Storing the Info in State
  const UpdatePosition = array => {
    let NavLinksArrayWidth = array.map(elem => {
      return {
        Left: elem.getBoundingClientRect().left,
        Right: elem.getBoundingClientRect().right,
        Width: elem.getBoundingClientRect().width,
      };
    });
    setArrayOfNavElemPos(NavLinksArrayWidth);
  };

  //Getting all elements at first render
  React.useEffect(() => {
    if (Scrollable === true || Scrollable === 'Auto') {
      if (ScrollableMenu.current) {
        let ScrollableElemRect = ScrollableMenu.current.getBoundingClientRect();
        setScrollableInfo({
          Left: ScrollableElemRect.left,
          Right: ScrollableElemRect.right,
          Width: ScrollableElemRect.width,
          Scroll: 0,
        });
        let NavLinksElem = ScrollableMenu.current.getElementsByClassName('TabNavLink');
        let NavLinksArray = [...NavLinksElem];

        if (Scrollable === true) {
          //Checking if the Right Scrollable Button needs to be hidden (everyting is fitting)
          if (
            NavLinksArray[NavLinksArray.length - 1].getBoundingClientRect().right <=
            ScrollableElemRect.right
          ) {
            setHideRightScrollButton(true);
          } else {
            UpdatePosition(NavLinksArray);
          }
        }
        if (Scrollable === 'Auto') {
          //Fully Hide the Controllers if it fits
          if (
            NavLinksArray[NavLinksArray.length - 1].getBoundingClientRect().right <=
            ScrollableElemRect.right
          ) {
            setNeedsScrollable(false);
          } else {
            setNeedsScrollable(true);
            UpdatePosition(NavLinksArray);
          }
        }
      }
    }
  }, [Scrollable]);
  const UpdateScroll = React.useCallback(() => {
    if (Scrollable === true || Scrollable === 'Auto') {
      if (ScrollableMenu.current) {
        let ScrollableElemRect = ScrollableMenu.current.getBoundingClientRect();
        setScrollableInfo({
          Left: ScrollableElemRect.left,
          Right: ScrollableElemRect.right,
          Width: ScrollableElemRect.width,
          Scroll: 0,
        });
        let NavLinksElem = ScrollableMenu.current.getElementsByClassName('TabNavLink');
        let NavLinksArray = [...NavLinksElem];

        if (Scrollable === true) {
          //Checking if the Right Scrollable Button needs to be hidden (everyting is fitting)
          if (
            NavLinksArray[NavLinksArray.length - 1].getBoundingClientRect().right <=
            ScrollableElemRect.right
          ) {
            setHideRightScrollButton(true);
          } else {
            setHideRightScrollButton(false);
            UpdatePosition(NavLinksArray);
          }
        }
        if (Scrollable === 'Auto') {
          //Fully Hide the Controllers if it fits
          if (
            NavLinksArray[NavLinksArray.length - 1].getBoundingClientRect().right <=
            ScrollableElemRect.right
          ) {
            setNeedsScrollable(false);
          } else {
            setNeedsScrollable(true);
            UpdatePosition(NavLinksArray);
          }
        }
      }
    }
  },[Scrollable]);
  //Updating the Scrolling Components based on Window Resize
  React.useEffect(() => {
    if (Scrollable === true || Scrollable === 'Auto') {
		
        const debounceUpdateScroll = debounce(function () {
            UpdateScroll();
        }, 100);
        window.addEventListener("resize", debounceUpdateScroll);
        return (_) => {
            window.removeEventListener("resize", debounceUpdateScroll);
        };
		
		
		
		
	// 	window.addEventListener('resize', UpdateScroll);
    //   return function cleanup() {
    //     window.removeEventListener('resize', UpdateScroll);
    //   };
    }
  }, [Scrollable,UpdateScroll]);

  const GetElemArray = () => {
    let NavLinksElem = ScrollableMenu.current.getElementsByClassName('TabNavLink');
    let NavLinksArray = [...NavLinksElem];
    return NavLinksArray;
  };

  const ScrollLeft = () => {
    //Get Next Element to Scroll
    //Find the first Element where Left Position is <= ScrollLeft
    var Index = 0;
    for (var i = 0; i < ArrayOfNavElemPos.length; i++) {
      if (ArrayOfNavElemPos[i].Right <= ScrollableInfo.Left) {
        if (i < Index) {
        } else {
          if (ArrayOfNavElemPos[i].Width > 0) Index = i;
        }
      }
    }
    //If Index <= Length => Stop Scrolling and Hide the Right ScrollButton

    if (Index < 1) {
      setHideLeftScrollButton(true);
    }
    //Add the Scrollable Right if Hidden
    setHideRightScrollButton(false);
    let Scroll = ArrayOfNavElemPos[Index].Left - ScrollableInfo.Left;

    ScrollableMenu.current.scrollLeft = ScrollableInfo.Scroll + Scroll;
    //Update the current Scroll
    setScrollableInfo({ ...ScrollableInfo, ...{ Scroll: ScrollableInfo.Scroll + Scroll } });
    //Update the Position of the Elem Array
    UpdatePosition(GetElemArray());
  };
  const ScrollRight = () => {
    //Get Next Element to Scroll
    //Find the first Element where Left Position is >= ScrollRight
    var Index = ArrayOfNavElemPos.length - 1;
    for (var i = 0; i < ArrayOfNavElemPos.length; i++) {
      if (ArrayOfNavElemPos[i].Left >= ScrollableInfo.Right) {
        if (i > Index) {
        } else {
          if (ArrayOfNavElemPos[i].Width > 0) Index = i;
        }
      }
    }
    //If Index >= Length => Stop Scrolling and Hide the Right ScrollButton
    if (Index >= ArrayOfNavElemPos.length - 1) {
      setHideRightScrollButton(true);
    }
    //Add the Scrollable Left if Hidden
    setHideLeftScrollButton(false);
    let Scroll = ArrayOfNavElemPos[Index].Right - ScrollableInfo.Right;
    ScrollableMenu.current.scrollLeft = ScrollableInfo.Scroll + Scroll;
    //Update the current Scroll
    setScrollableInfo({ ...ScrollableInfo, ...{ Scroll: ScrollableInfo.Scroll + Scroll } });
    //Update the Position of the Elem Array
    UpdatePosition(GetElemArray());
  };

  //Adding Scrollable Content Buttons if Options = true
  const BackupDefaultIconStyle = {
    IconSize: '30px',
    SVGStrokeColor: '#6E6E6E',
    SVGStrokeWidth: '10px',
    SVGStrokeHoverColor: '#0FB8E6',
    FrameFillColorHover: 'grey',
    Frame: 'none',
    FrameStrokeWidth: '12px',
    FrameStrokeColor: 'none',
    FrameFillColor: 'grey',
    InnerPadding: '25px',
  };
  const DefaultIconStyle = theme.TabNavMenuScrollIcon
    ? theme.TabNavMenuScrollIcon
    : BackupDefaultIconStyle;

  var FinalIconStyle = { ...DefaultIconStyle, ...ScrollIconStyle };
  if (Scrollable === true) {
    LeftScrollButton = (
      <div
        className={clsx(
          classes.TabNavMenuScrollableController,
          ClassExtendTabNavMenuScrollableController,
          classes.TabNavMenuScrollableControllerFixed,
        )}
      >
        <div
          className={clsx(classes.TabNavMenuScrollableControllerEvent)}
          onClick={ScrollLeft}
          style={HideLeftScrollButton ? { visibility: 'hidden' } : null}
        >
          <ArrowSimpleLeft
            IconSize={FinalIconStyle.IconSize}
            SVGStrokeColor={FinalIconStyle.SVGStrokeColor}
            SVGStrokeWidth={FinalIconStyle.SVGStrokeWidth}
            SVGStrokeHoverColor={FinalIconStyle.SVGStrokeHoverColor}
            FrameFillColorHover={FinalIconStyle.FrameFillColorHover}
            Frame={FinalIconStyle.Frame}
            FrameStrokeWidth={FinalIconStyle.FrameStrokeWidth}
            FrameStrokeColor={FinalIconStyle.FrameStrokeColor}
            FrameFillColor={FinalIconStyle.FrameFillColor}
            InnerPadding={FinalIconStyle.InnerPadding}
          />
        </div>
      </div>
    );
    RightScrollButton = (
      <div
        className={clsx(
          classes.TabNavMenuScrollableController,
          ClassExtendTabNavMenuScrollableController,
          classes.TabNavMenuScrollableControllerFixed,
        )}
      >
        <div
          className={clsx(classes.TabNavMenuScrollableControllerEvent)}
          onClick={ScrollRight}
          style={HideRightScrollButton ? { visibility: 'hidden' } : null}
        >
          <ArrowSimpleRight
            IconSize={FinalIconStyle.IconSize}
            SVGStrokeColor={FinalIconStyle.SVGStrokeColor}
            SVGStrokeWidth={FinalIconStyle.SVGStrokeWidth}
            SVGStrokeHoverColor={FinalIconStyle.SVGStrokeHoverColor}
            FrameFillColorHover={FinalIconStyle.FrameFillColorHover}
            Frame={FinalIconStyle.Frame}
            FrameStrokeWidth={FinalIconStyle.FrameStrokeWidth}
            FrameStrokeColor={FinalIconStyle.FrameStrokeColor}
            FrameFillColor={FinalIconStyle.FrameFillColor}
            InnerPadding={FinalIconStyle.InnerPadding}
          />
        </div>
      </div>
    );
  } else if (Scrollable === 'Auto' && NeedsScrollable) {
    LeftScrollButton = (
      <div
        className={clsx(
          classes.TabNavMenuScrollableController,
          ClassExtendTabNavMenuScrollableController,
          classes.TabNavMenuScrollableControllerFixed,
        )}
      >
        <div
          className={clsx(classes.TabNavMenuScrollableControllerEvent)}
          onClick={ScrollLeft}
          style={HideLeftScrollButton ? { visibility: 'hidden' } : null}
        >
          <ArrowSimpleLeft
            IconSize={FinalIconStyle.IconSize}
            SVGStrokeColor={FinalIconStyle.SVGStrokeColor}
            SVGStrokeWidth={FinalIconStyle.SVGStrokeWidth}
            SVGStrokeHoverColor={FinalIconStyle.SVGStrokeHoverColor}
            FrameFillColorHover={FinalIconStyle.FrameFillColorHover}
            Frame={FinalIconStyle.Frame}
            FrameStrokeWidth={FinalIconStyle.FrameStrokeWidth}
            FrameStrokeColor={FinalIconStyle.FrameStrokeColor}
            FrameFillColor={FinalIconStyle.FrameFillColor}
            InnerPadding={FinalIconStyle.InnerPadding}
          />
        </div>
      </div>
    );
    RightScrollButton = (
      <div
        className={clsx(
          classes.TabNavMenuScrollableController,
          ClassExtendTabNavMenuScrollableController,
          classes.TabNavMenuScrollableControllerFixed,
        )}
      >
        <div
          className={clsx(classes.TabNavMenuScrollableControllerEvent)}
          onClick={ScrollRight}
          style={HideRightScrollButton ? { visibility: 'hidden' } : null}
        >
          <ArrowSimpleRight
            IconSize={FinalIconStyle.IconSize}
            SVGStrokeColor={FinalIconStyle.SVGStrokeColor}
            SVGStrokeWidth={FinalIconStyle.SVGStrokeWidth}
            SVGStrokeHoverColor={FinalIconStyle.SVGStrokeHoverColor}
            FrameFillColorHover={FinalIconStyle.FrameFillColorHover}
            Frame={FinalIconStyle.Frame}
            FrameStrokeWidth={FinalIconStyle.FrameStrokeWidth}
            FrameStrokeColor={FinalIconStyle.FrameStrokeColor}
            FrameFillColor={FinalIconStyle.FrameFillColor}
            InnerPadding={FinalIconStyle.InnerPadding}
          />
        </div>
      </div>
    );
  }

  var FakeFullWidthComp;
  if (MenuFullWidth) {
    FakeFullWidthComp = <div style={{ flex: '1 1 auto' }}></div>;
  }
  return (
    <div
      ref={ref}
      {...other}
      className={clsx(
        'TabNavMenu',
        ClassOverrideTabNavMenu ? ClassOverrideTabNavMenu : classes.TabNavMenu,
        ClassExtendTabNavMenu,
        TabNavMenuSeparation === 'Line'
          ? classes.TabNavMenuSeparationLine
          : TabNavMenuSeparation === 'Shadow'
          ? classes.TabNavMenuSeparationShadow
          : null,
      )}
      style={{ ...LockedstylesWithProps(props).TabNavMenu }}
    >
      {LeftScrollButton}
      <div
        className="TabNavMenuScrollable"
        ref={ScrollableMenu}
        style={{
          ...LockedstylesWithProps(props).TabNavMenuScrollable,
          ...Lockedstyles.TabNavMenuScrollable,
        }}
      >
        {children}
      </div>
      {FakeFullWidthComp}
      {RightScrollButton}
    </div>
  );
});

TabNavMenu.defaultProps = {
  Scrollable: true,
  MenuFullWidth: true,
  ZIndex: null,
  TabNavMenuSeparation: 'Shadow',
  ClassOverrideTabNavMenu: null,
  ClassExtendTabNavMenu: null,
  ClassExtendTabNavMenuScrollableController: null,
  ScrollIconStyle: null,
  TabNavMenuHeight: '50px',
};

TabNavMenu.propTypes = {
  /**
   * Defines if the MenuBar is scrollable. If true it will add buttons to scroll from the width of the hidden Element.
   */
  Scrollable: PropTypes.bool,
  /**
   * If true the Menu Bar will take all the space based on parent size, if not, it will shrink to the content of the Nav Items
   */
  MenuFullWidth: PropTypes.bool,
  /**
   * Defines the height of the TabNavMenu Toolbar : default ="50px"
   */
  TabNavMenuHeight: PropTypes.string,
  /**
   * The z-index prop
   */
  ZIndex: PropTypes.any,
  /**
   * Style of the separator between the menu and the content of the tab.
   * Default = Shadow, options : (Shadow, Line, null)
   */
  TabNavMenuSeparation: PropTypes.string,

  /**
   * Override the class
   */
  ClassOverrideTabNavMenu: PropTypes.string,
  /**
   * Extend the class
   */
  ClassExtendTabNavMenu: PropTypes.string,
  /**
   *Extends the class of the div containing the scroll Arrow
   */
  ClassExtendTabNavMenuScrollableController: PropTypes.string,
  /**
   *Style properties for the Controller Arrows for scrolling
   */
  ScrollIconStyle: PropTypes.object,
};

export default TabNavMenu;
