import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    UploaderWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "10px",
        maxWidth: "1000px",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    Uploader_Instruction: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        margin: "4px 0px 25px 0px",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "8px",
        background: "grey",
        color: "white",
        borderRadius: "5px",
        "& p": {
            margin: "2px 0px",
        },
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    Uploader_Button: {
        display: "flex",
        position: "relative",
        marginTop: "10px",
        justifyContent: "center",
    },
    FileNameType: {
        margin: "10px 0px",
        "& span": {
            fontWeight: "500",
            fontSize: "1.1em",
        },
    },
    ConvResults: {
        margin: "0px 10px",
    },
}));

const AccountingOverviewSyncAccount = React.memo(function AccountingOverviewSyncAccount(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [IsSynced, setIsSynced] = useState(false);
    const [SyncResults, setSyncResults] = useState([]);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data) {
                    if (ApiRes.data.data) {
                        let Response = ApiRes.data.data;
                        console.log("Response", Response);

                        setSyncResults(Response);

                        setIsSynced(true);
                        // CloseCard("refresh");
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/companyaccount/syncaccount`, type: "get", timeout: 20000};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalComponent = null;
    if (IsSynced === true) {
        let DisplayResults = (
            <div className={classes.FileResult}>
                <div className={classes.ConvResults}>
                    <div>
                        <span>Total new entries : </span>
                        <span>{SyncResults.NewTotalTransactions}</span>
                    </div>
                    <div>
                        <span>Total skipped entries : </span>
                        <span>{SyncResults.SkippedTotalTransactions}</span>
                    </div>
                    <div>
                        <span>New bank account entries : </span>
                        <span>{SyncResults.NewBankTransactions}</span>
                    </div>
                    <div>
                        <span>New credit card entries : </span>
                        <span>{SyncResults.NewCreditCardTransactions}</span>
                    </div>
                </div>
            </div>
        );

        FinalComponent = <React.Fragment>{DisplayResults}</React.Fragment>;
    } else {
        FinalComponent = null;
    }

    return (
        <div className={classes.UploaderWrapper}>
            <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
            {FinalComponent}
        </div>
    );

    /***************** RENDER ******************/
});

export default AccountingOverviewSyncAccount;
