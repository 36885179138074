import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import ComaniesRolesTable from "./CompaniesRoles/ComaniesRolesTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",

    },
    TabMenuDiv: {
        fontSize: "0.8rem",
		cursor : "pointer"
    },
}));

const Component = React.memo(function Component(props) {
    const {FormViewMode, ContactID, HandleContactDetailsChanged} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            NoLink={true}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Job Titles</div>
                        </div>
                    ),

                    TabContent: <ComaniesRolesTable FormViewMode={FormViewMode} ContactID={ContactID} SubmitParentForm={HandleContactDetailsChanged} />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Emails</div>
                        </div>
                    ),

                    TabContent: <div>Emails</div>,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Phone</div>
                        </div>
                    ),

                    TabContent: <div>Phone</div>,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Addresses</div>
                        </div>
                    ),

                    TabContent: <div>Addresses</div>,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
