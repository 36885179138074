import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {Datepicker, Eventcalendar, localeFr, formatDate} from "@mobiscroll/react";
import Page from "../../../../../artibulles-cis/Page/Page";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import {parseISO, getDay, formatISO, sub, add} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    AgendaWrapper: {
        maxWidth: "600px",
        flex: "1 1 auto",
        maxHeight: "100%",
    },
    CustomEvent: {
        fontSize: "12px",
        fontWeight: "600",
        height: "100%",
        background: "#fff",
        position: "relative",
        boxSizing: "border-box",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
        // borderRadius: "0 10px 10px 0",
        borderRadius: "4px 8px 8px 4px",
        overflow: "hidden",
    },
    EventWrapper: {
        position: "relative",
        background: "rgba(255, 255, 255, 0.5)",
        height: "100%",
        minHeight: "135px",
        boxSizing: "border-box",
        borderRadius: "0 8px 8px 0",
        // borderRadius: "4px",
        transition: "background 0.15s ease-in-out",
    },
    EventDetails: {
        padding: "0 10px",
    },
    EventTitle: {
        color: "black",
    },
    EventTime: {
        fontSize: "10px",
        color: "#666",
    },
    EventType: {
        fontSize: "10px",
        fontWeight: "500",
        color: "black",
    },
    EventOrganizer: {
        fontSize: "10px",
        fontWeight: "400",
        color: "black",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const InvalidTimes = [
        {
            recurring: {
                repeat: "weekly",
                weekDays: "SA,SU",
            },
        },

        {
            start: "12:00",
            end: "13:00",
            title: "Lunch break",
            recurring: {
                repeat: "weekly",
                weekDays: "MO,TU,WE,TH,FR",
            },
        },
        {
            start: "00:00",
            end: "07:00",
            recurring: {
                repeat: "weekly",
                weekDays: "MO,TU,WE,TH,FR",
            },
        },
        {
            start: "18:00",
            end: "23:59",
            recurring: {
                repeat: "weekly",
                weekDays: "MO,TU,WE,TH,FR",
            },
        },
    ];
    let DefaultDate = new Date(Date.now());
    DefaultDate = add(DefaultDate, {days: 1});
    const DayOfWeek = getDay(DefaultDate);
    // console.log("DayOfWeek", DayOfWeek);
    const ShowTimeInEvent = false;
    const View = {
        schedule: {
            type: "day",
            startDay: DayOfWeek,
            endDay: DayOfWeek,
            startTime: "07:00",
            endTime: "23:00",
            // timezones : [{
            // 	timezone : "America/Chicago",
            // 	label : "CHI"
            // }]
        },
    };
    const Responsive = {
        xlarge: {
            view: {
                schedule: {type: "day", size: 1},
            },
        },
        custom: {
            // Custom breakpoint
            breakpoint: 2000,
            view: {
                schedule: {type: "day", size: 1},
            },
        },
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [IsInitialized, setIsInitialized] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [CalendarDate, setCalendarDate] = useState(DefaultDate);

    // const [Events, setEvents] = useState([
    //     {
    //         start: new Date(2022, 5, 20, 10, 0),
    //         end: new Date(2022, 5, 20, 12, 0),
    //         title: "My First Event",
    //         color: "blue",
    //         description: "Qq chose",
    //     },
    // ]);
    const [Events, setEvents] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            // console.log("API RES", ApiRes);
            if (ApiRes.error) {
                //It could mean the outlook server is down
                console.log("API error", ApiRes);
                setIsInitialized(true);
                // console.log("Is initialized");
                toast.error(`Microsoft API is down`, {autoClose: true});
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    const Data = ApiRes.data.data.data;
                    // console.log("Data", Data);
                    if (Data && Array.isArray(Data)) {
                        let DataEvents = [];
                        Data.forEach((elem) => {
                            let DataEvent = {
                                start: parseISO(elem.start),
                                end: parseISO(elem.end),
                                title: elem.subject,
                                color: elem.color ? elem.color : null,
                                description: elem.subject,
                                editable: false,
                                organizer: elem.organizer,
                                location: elem.location,
                            };
                            DataEvents.push(DataEvent);
                        });

                        setIsInitialized(true);
                        setTimeout(() => {
                            setEvents(DataEvents);
                        }, 500);
                    } else {
                        // setIsInitialized(true);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        let DateIso = formatISO(DefaultDate);
        const ApiCall = {url: `${ProdURL}/api/tasks/getdaylicalendar/?date=${DateIso}`, type: "get"};
        setLoading(true);
        // setLoginPhase("OTP code entered");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, [ProdURL]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const onEventClick = React.useCallback(
        (event) => {
            // console.log("event", Events);
            const newEventList = [...Events];
            let NewEvent = {
                start: new Date(2022, 5, 10, 8, 0),
                end: new Date(2022, 5, 21, 9, 0),
                title: "Test",
            };
            newEventList.push(NewEvent);
            setEvents(newEventList);
        },
        [Events]
    );

    const onEventUpdate = (args) => {
        // console.log("args", args);
    };
    const HandleSelectedDateChange = (event, inst) => {
        // console.log("event", event, inst);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    const HandleRenderHeader = () => {
        return null;
        // return <div>Hello</div>;
    };
    const HandleRenderDay = (args) => {
        // console.log("args", args);
        const date = args.date;
        return formatDate("DDDD", date) + "," + formatDate("MMMM DD", date);
        // return (
        //     <div className="header-template-container">
        //         <div className="header-template-date">
        //             <div className="header-template-day-name">{formatDate("DDDD", date)}</div>
        //             <div className="header-template-day">{formatDate("MMMM DD", date)}</div>
        //         </div>
        //     </div>
        // );
    };
    const HandleRenderLabel = () => {
        return <div>Hello</div>;
    };

    const HandleRenderScheduleEvent = (data) => {
        let OriginalData = data.original;
        let DefaultColor = "#0078d7";
        let FinalColor;
        if (OriginalData.color) {
            FinalColor = OriginalData.color;
        } else {
            FinalColor = DefaultColor;
        }
        FinalColor = DefaultColor;
        console.log("OriginalData", OriginalData);
        // console.log("FinalColor", FinalColor);

        let TimeInfo;
        if (ShowTimeInEvent) {
            TimeInfo = (
                <div className={classes.EventTime}>
                    {data.start} - {data.end}
                </div>
            );
        }
        if (data.allDay) {
            return (
                <div style={{background: "red"}} className="md-custom-event-allday-title">
                    {data.title}
                </div>
            );
        } else {
            return (
                <div className={classes.CustomEvent} style={{borderLeft: "5px solid" + FinalColor, background: FinalColor}}>
                    <div className={classes.EventWrapper}>
                        <div className={classes.EventDetails}>
                            <div className={classes.EventTitle}>{OriginalData.title}</div>
                            {TimeInfo}
                            <div className={classes.EventType}>{OriginalData.locaton}</div>
                            <div className={classes.EventOrganizer}>{OriginalData.organizer}</div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    let CalendarComponent;
    if (IsInitialized === true) {
        CalendarComponent = (
            <div className={classes.AgendaWrapper}>
                <Eventcalendar
                    theme="windows"
                    themeVariant="dark"
                    view={View}
                    // invalidateEvent="strict"
                    // invalid={InvalidTimes}
                    // allDayText="caca"
                    // locale={localeFr}
                    timeFormat="HH:mm"
                    defaultSelectDate={CalendarDate}
                    // selectedDate={CalendarDate}
                    // onSelectedDateChange={HandleSelectedDateChange}
                    renderDay={HandleRenderDay}
                    renderScheduleEvent={HandleRenderScheduleEvent}
                    // renderHeader={HandleRenderHeader}
                    // renderLabel={HandleRenderLabel}
                    // renderScheduleEvent = {HandleRenderScheduleEvent}
                    // responsive={Responsive}
                    data={Events}
                    clickToCreate={true}
                    eventDelete={true}
                    dragToCreate={true}
                    dragToMove={true}
                    dragToResize={true}
                    onEventClick={onEventClick}
                    onEventUpdate={onEventUpdate}
                    // onEventUpdated={onEventUpdated}
                    showControls={false}
                    //
                />
            </div>
        );
    } else {
        CalendarComponent = null;
    }
    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPage={classes.PageContentExtends}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            <div className={classes.Main}>{CalendarComponent}</div>
        </Page>
    );

    /***************** RENDER ******************/
});

export default Component;
