import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

import CardDetailsTabRouter from "./CardDetails/CardDetailsTabRouter";
import CardList from "./CardsList/CardList";


//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
	const HandleCreateCreditCard =() =>{
		//Create card
	}
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/companyaccount/creditcards">
                    <Redirect to="/financials/companyaccount/creditcards/list/overview" />
                </Route>
                <Route exact path="/financials/companyaccount/creditcards/card">
                    <Redirect to="/financials/companyaccount/creditcards/card/default/overview" />
                </Route>
                <Route path="/financials/companyaccount/creditcards/card/:id">
                    <CardDetailsTabRouter />
                </Route>
                <Route path="/financials/companyaccount/creditcards/list">
                    <CardList />
                </Route>
                <Route path="/financials/companyaccount/creditcards/create">
                    {HandleCreateCreditCard}
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default Component;
