/* eslint-disable */
import React, {useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import FormDev from "../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";

import ExpandableBox from "../../../../../artibulles-cis/ExpandableBox/ExpandableBox";

/************** NEW COMPONENTS **************/

import FormCompontent from "../../../../../artibulles-cis/FormComponent/FormCompontent";
import {ArrowFilledLeft, ArrowFilledRight, AvatarV2, Login} from "@artibulles-cis/react-icons";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "60px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 50px",
        boxSizing: "border-box",
        borderRadius: "3px",
        padding: "4px",
        border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 10px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "22px",
        margin: "0px",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 10px 10px",
    },
    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 10px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
                //Setting the Inital FormValues in the Form Fields but removing the null ones

                // if (FormData.InitiaFormValues) {
                //     let InitFormKeys = _.keys(FormData.InitiaFormValues);

                //     var FormValInit = {};
                //     if (InitFormKeys && InitFormKeys.length > 0) {
                //         InitFormKeys.forEach((key) => {
                //             if ((FormData.InitiaFormValues[key] && FormData.InitiaFormValues[key].length > 0) || FormData.InitiaFormValues[key] === false || FormData.InitiaFormValues[key] === true) {
                //                 if (typeof FormData.InitiaFormValues[key] === "object") {
                //                     let Subkeys = _.keys(FormData.InitiaFormValues[key]);
                //                     if (Subkeys && Subkeys.length > 0) {
                //                         Subkeys.forEach((subkey) => {
                //                             if ((FormData.InitiaFormValues[key][subkey] && FormData.InitiaFormValues[key][subkey].length > 0) || FormData.InitiaFormValues[key][subkey] === false) {
                //                                 let SubKeyElem = {};
                //                                 if (FormValInit[key]) {
                //                                     FormValInit[key][subkey] = FormData.InitiaFormValues[key][subkey];
                //                                 } else {
                //                                     FormValInit = {...FormValInit, ...{[key]: {}}};
                //                                     FormValInit[key][subkey] = FormData.InitiaFormValues[key][subkey];
                //                                 }
                //                             }
                //                         });
                //                     }
                //                 } else {
                //                     FormValInit = {...FormValInit, ...{[key]: FormData.InitiaFormValues[key]}};
                //                 }
                //             }
                //         });
                //     }
                // }

                // setFormValues(FormValInit);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }

            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, value) => {
        setPristine(false);
        //Hanlding the select change
        setFormValues({...FormValues, ...{[field]: value}});

        //Handling the subOptions
        // if (FieldSubOptionsFull[field]) {
        //     //There is a sub-Option for the field in question
        //     //1. Update the options based on that selected value
        //     FieldSubOptionsFull[field].forEach((elem) => {
        //         if (elem.ref_food_major_group === value) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        // }
        HandleFiedsChange(field, value);
        // var SubOptions = [];
        // if (field === "majorGroup") {
        //     SubOptions = [];

        //     SelectSubOptions.group.forEach((elem) => {
        //         if (elem.ref_food_major_group === value) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });

        //     setSelectOptions({...SelectOptions, ...{group: SubOptions}});
        //     setFormValues({...FormValues, ...{majorGroup: value, group: null, ref_food_category: null}});
        // } else if (field === "group") {
        //     SubOptions = [];

        //     SelectSubOptions.ref_food_category.forEach((elem) => {
        //         if (elem.ref_food_group === value) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });

        //     setSelectOptions({...SelectOptions, ...{ref_food_category: SubOptions}});
        //     setFormValues({...FormValues, ...{group: value, ref_food_category: null}});
        // } else {
        //     setFormValues({...FormValues, ...{[field]: value}});
        // }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (Options, selected, type) => {
        if (type) {
            FieldOptions[type] = Options;
            setFieldOptions(FieldOptions);
        }
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY
    const UpdateFormFiles = (filesPath, fieldName) => {
        if (fieldName) {
            FilesUploaders[fieldName] = filesPath;
            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function") {
            //If there are creatable options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    //Get the IntialValue
                    let InitialValue = InitialFormValues[key];
                    let Value = FormValues[key];
                    if (InitialValue !== Value) {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
                // FullOptionsWithCreated: values.submitype ? values : null,
            };

            OnSubmitExternal(FullSubmission);
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    var FinalFormComponent;
    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.Artiweb_form_Wrapper_Flex}>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Text Input</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="standard_input"
                                    InitialValue={InitialFormValues.standard_input ? InitialFormValues.standard_input : null}
                                    FormValue={FormValues.standard_input ? FormValues.standard_input : null}
                                    OutputValue={(values) => HandleUpdateFormValues("standard_input", values)}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Normal Outlined"
                                    NoMessage={false}
                                    meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("normal", error)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="email"
                                    InitialValue={InitialFormValues.email ? InitialFormValues.email : null}
                                    FormValue={FormValues.email ? FormValues.email : null}
                                    OutputValue={(values) => HandleUpdateFormValues("email", values)}
                                    OutputError={(error) => HandleUpdateErrors("email", error)}
                                    meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Email"
                                    Type="email"
                                    NoMessage={false}
                                    LocalValidation={{When: "Typing"}}
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="password"
                                    InitialValue={InitialFormValues.password ? InitialFormValues.password : null}
                                    FormValue={FormValues.password ? FormValues.password : null}
                                    OutputValue={(values) => HandleUpdateFormValues("password", values)}
                                    OutputError={(error) => HandleUpdateErrors("password", error)}
                                    meta={{error: FormErrors.password, invalid: FormErrors.password ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Password"
                                    Type="password"
                                    NoMessage={false}
                                    LocalValidation={{When: "Leaving"}}
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="textarea"
                                    InitialValue={InitialFormValues.textarea ? InitialFormValues.textarea : null}
                                    FormValue={FormValues.textarea ? FormValues.textarea : null}
                                    OutputValue={(values) => HandleUpdateFormValues("textarea", values)}
                                    OutputError={(error) => HandleUpdateErrors("textarea", error)}
                                    meta={{error: FormErrors.textarea, invalid: FormErrors.textarea ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="textarea"
                                    Type="text"
                                    multiline={true}
                                    RowsToDisplay="3"
                                    MaxRows="3"
                                    NoMessage={false}
                                    LocalValidation={{When: "Typing"}}
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>

                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Masked Inputs</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="masked_currency"
                                    InitialValue={InitialFormValues.masked_currency ? InitialFormValues.masked_currency : null}
                                    FormValue={FormValues.masked_currency ? FormValues.masked_currency : null}
                                    OutputValue={(values) => HandleUpdateFormValues("masked_currency", values)}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Imask={true}
                                    Mask={{
                                        Type: "Currency",
                                        Options: {
                                            Currency: "USD",
                                            // SymbolPosition: "Post",
                                            Scale: 2,
                                        },
                                    }}
                                    Label="Mask currency"
                                    // meta={{error: FormErrors.mask_currency_usd, invalid: FormErrors.mask_currency_usd ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("mask_currency_usd", error)}
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="mask_date"
                                    InitialValue={InitialFormValues.mask_date ? InitialFormValues.mask_date : null}
                                    FormValue={FormValues.mask_date ? FormValues.mask_date : null}
                                    OutputValue={(values) => HandleUpdateFormValues("mask_date", values)}
                                    OutputError={(error) => HandleUpdateErrors("mask_date", error)}
                                    meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Imask={true}
                                    NoMessage={false}
                                    Mask={{
                                        Type: "Date",
                                        Options: {
                                            Format: "yyyy/MM/dd",
                                        },
                                    }}
                                    Label="date and something large"
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>With Toolbars</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="search_input"
                                    InitialValue={InitialFormValues.search_input ? InitialFormValues.search_input : null}
                                    FormValue={FormValues.search_input ? FormValues.search_input : null}
                                    OutputValue={(values) => HandleUpdateFormValues("search_input", values)}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Search"
                                    NoMessage={false}
                                    meta={{error: FormErrors.search_input, invalid: FormErrors.search_input ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("search_input", error)}
                                    Clearable={true}
                                    Searchable={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="external_toolbar"
                                    InitialValue={InitialFormValues.external_toolbar ? InitialFormValues.external_toolbar : null}
                                    FormValue={FormValues.external_toolbar ? FormValues.external_toolbar : null}
                                    OutputValue={(values) => HandleUpdateFormValues("external_toolbar", values)}
                                    OutputError={(error) => HandleUpdateErrors("external_toolbar", error)}
                                    meta={{error: FormErrors.external_toolbar, invalid: FormErrors.external_toolbar ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="External toolbar"
                                    // Type="text"
                                    NoMessage={false}
                                    // LocalValidation={{When: "Typing"}}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    ExternalToolbarLeft={<ArrowFilledLeft />}
                                    ExternalToolbarRight={<ArrowFilledRight />}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="internal_toolbar"
                                    InitialValue={InitialFormValues.internal_toolbar ? InitialFormValues.internal_toolbar : null}
                                    FormValue={FormValues.internal_toolbar ? FormValues.internal_toolbar : null}
                                    OutputValue={(values) => HandleUpdateFormValues("internal_toolbar", values)}
                                    OutputError={(error) => HandleUpdateErrors("internal_toolbar", error)}
                                    meta={{error: FormErrors.internal_toolbar, invalid: FormErrors.internal_toolbar ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Internal toolbar"
                                    Type="text"
                                    NoMessage={false}
                                    // LocalValidation={{When: "Leaving"}}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                                    AnimateToolBarLeft={true}
                                    AnimateToolbarLeftColor={true}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Controlled Edit Mode with Overlay</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="search_input2"
                                    InitialValue={InitialFormValues.search_input2 ? InitialFormValues.search_input2 : null}
                                    FormValue={FormValues.search_input2 ? FormValues.search_input2 : null}
                                    OutputValue={(values) => HandleUpdateFormValues("search_input2", values)}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Search"
                                    NoMessage={false}
                                    meta={{error: FormErrors.search_input2, invalid: FormErrors.search_input2 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("search_input2", error)}
                                    Clearable={true}
                                    Searchable={true}
                                    ControlledEditViewMode={true}
                                    EditModeActive={FormViewMode === "Edit" ? true : false}
                                    SetFormEditMode={HandleEnableEditMode}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="external_toolbar2"
                                    InitialValue={InitialFormValues.external_toolbar2 ? InitialFormValues.external_toolbar2 : null}
                                    FormValue={FormValues.external_toolbar2 ? FormValues.external_toolbar2 : null}
                                    OutputValue={(values) => HandleUpdateFormValues("external_toolbar2", values)}
                                    OutputError={(error) => HandleUpdateErrors("external_toolbar2", error)}
                                    meta={{error: FormErrors.external_toolbar2, invalid: FormErrors.external_toolbar2 ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="External toolbar"
                                    // Type="text"
                                    NoMessage={false}
                                    // LocalValidation={{When: "Typing"}}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    ExternalToolbarLeft={<ArrowFilledLeft />}
                                    ExternalToolbarRight={<ArrowFilledRight />}
                                    ControlledEditViewMode={true}
                                    EditModeActive={FormViewMode === "Edit" ? true : false}
                                    SetFormEditMode={HandleEnableEditMode}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="internal_toolbar2"
                                    InitialValue={InitialFormValues.internal_toolbar2 ? InitialFormValues.internal_toolbar2 : null}
                                    FormValue={FormValues.internal_toolbar2 ? FormValues.internal_toolbar2 : null}
                                    OutputValue={(values) => HandleUpdateFormValues("internal_toolbar2", values)}
                                    OutputError={(error) => HandleUpdateErrors("internal_toolbar2", error)}
                                    meta={{error: FormErrors.internal_toolbar2, invalid: FormErrors.internal_toolbar2 ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Internal toolbar"
                                    Type="text"
                                    NoMessage={false}
                                    // LocalValidation={{When: "Leaving"}}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                                    AnimateToolBarLeft={true}
                                    AnimateToolbarLeftColor={true}
                                    ControlledEditViewMode={true}
                                    EditModeActive={FormViewMode === "Edit" ? true : false}
                                    SetFormEditMode={HandleEnableEditMode}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Variants Side Label</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 500px"}}>
                                <FormCompontent
                                    Name="email"
                                    InitialValue={InitialFormValues.email ? InitialFormValues.email : null}
                                    FormValue={FormValues.email ? FormValues.email : null}
                                    OutputValue={(values) => HandleUpdateFormValues("email", values)}
                                    OutputError={(error) => HandleUpdateErrors("email", error)}
                                    meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Email"
                                    Type="email"
                                    NoMessage={false}
                                    LocalValidation={{When: "Typing"}}
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                    ShowLabel={true}
                                    LabelMaxWidth="200px"
                                    FieldMaxWidth="350px"
                                    // LabelAlign="Left"
                                    // LabelPosition="Left"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 500px"}}>
                                <FormCompontent
                                    Name="password"
                                    InitialValue={InitialFormValues.password ? InitialFormValues.password : null}
                                    FormValue={FormValues.password ? FormValues.password : null}
                                    OutputValue={(values) => HandleUpdateFormValues("password", values)}
                                    OutputError={(error) => HandleUpdateErrors("password", error)}
                                    meta={{error: FormErrors.password, invalid: FormErrors.password ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Password"
                                    Type="password"
                                    NoMessage={false}
                                    LocalValidation={{When: "Leaving"}}
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                    LabelMaxWidth="200px"
                                    FieldMaxWidth="350px"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 500px"}}>
                                <FormCompontent
                                    Name="masked_currency"
                                    InitialValue={InitialFormValues.masked_currency ? InitialFormValues.masked_currency : null}
                                    FormValue={FormValues.masked_currency ? FormValues.masked_currency : null}
                                    OutputValue={(values) => HandleUpdateFormValues("masked_currency", values)}
                                    meta={{error: FormErrors.mask_currency_usd, invalid: FormErrors.mask_currency_usd ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Imask={true}
                                    Mask={{
                                        Type: "Currency",
                                        Options: {
                                            Currency: "EURO",
                                            // SymbolPosition: "Post",
                                            Scale: 2,
                                        },
                                    }}
                                    Label="Mask currency"
                                    LabelMaxWidth="200px"
                                    FieldMaxWidth="350px"
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 500px"}}>
                                <FormCompontent
                                    Name="mask_date"
                                    InitialValue={InitialFormValues.mask_date ? InitialFormValues.mask_date : null}
                                    FormValue={FormValues.mask_date ? FormValues.mask_date : null}
                                    OutputValue={(values) => HandleUpdateFormValues("mask_date", values)}
                                    OutputError={(error) => HandleUpdateErrors("mask_date", error)}
                                    meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Imask={true}
                                    NoMessage={false}
                                    Mask={{
                                        Type: "Date",
                                        Options: {
                                            Format: "yyyy/MM/dd",
                                        },
                                    }}
                                    Label="Date"
                                    DisplayMessageIn="Popup"
                                    Required={true}
                                    LabelMaxWidth="200px"
                                    FieldMaxWidth="350px"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 400px"}}>
                                <FormCompontent
                                    Name="internal_toolbar3"
                                    InitialValue={InitialFormValues.internal_toolbar3 ? InitialFormValues.internal_toolbar3 : null}
                                    FormValue={FormValues.internal_toolbar3 ? FormValues.internal_toolbar3 : null}
                                    OutputValue={(values) => HandleUpdateFormValues("internal_toolbar3", values)}
                                    OutputError={(error) => HandleUpdateErrors("internal_toolbar3", error)}
                                    meta={{error: FormErrors.internal_toolbar3, invalid: FormErrors.internal_toolbar3 ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Internal toolbar"
                                    Type="text"
                                    NoMessage={false}
                                    // LocalValidation={{When: "Leaving"}}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                                    AnimateToolBarLeft={true}
                                    AnimateToolbarLeftColor={true}
                                    ControlledEditViewMode={true}
                                    EditModeActive={FormViewMode === "Edit" ? true : false}
                                    SetFormEditMode={HandleEnableEditMode}
                                    LabelMaxWidth="200px"
                                    FieldMaxWidth="350px"
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Customize style</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 270px"}}>
                                <FormCompontent
                                    Name="email"
                                    InitialValue={InitialFormValues.email ? InitialFormValues.email : null}
                                    FormValue={FormValues.email ? FormValues.email : null}
                                    OutputValue={(values) => HandleUpdateFormValues("email", values)}
                                    OutputError={(error) => HandleUpdateErrors("email", error)}
                                    meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Email"
                                    Type="text"
                                    NoMessage={false}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    ShowLabel={true}
                                    LabelAlign="Left"
                                    LabelMaxWidth="60px"
                                    FieldMaxWidth="250px"
                                    // DetachedLabelStyle={{fontWeight: 400, fontSize: "14px"}}
                                    // InputTextStyle={{fontWeight: 400, fontSize: "14px"}}
                                    // Colors=""
                                    // Borders=""
                                    // BorderRadius=""
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                <FormCompontent
                                    Name="email"
                                    InitialValue={InitialFormValues.email ? InitialFormValues.email : null}
                                    FormValue={FormValues.email ? FormValues.email : null}
                                    OutputValue={(values) => HandleUpdateFormValues("email", values)}
                                    OutputError={(error) => HandleUpdateErrors("email", error)}
                                    meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Email"
                                    Type="text"
                                    NoMessage={false}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    ShowLabel={true}
                                    LabelAlign="Left"
                                    LabelMaxWidth="45px"
                                    FieldMaxWidth="200px"
                                    DetachedLabelStyle={{fontWeight: 400, fontSize: "16px"}}
                                    InputTextStyle={{fontWeight: 400, fontSize: "16px"}}
                                    // Colors=""
                                    // Borders=""
                                    // BorderRadius=""
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 270px"}}>
                                <FormCompontent
                                    Name="ztestNormal"
                                    InitialValue={InitialFormValues.email ? InitialFormValues.email : null}
                                    FormValue={FormValues.email ? FormValues.email : null}
                                    OutputValue={(values) => HandleUpdateFormValues("email", values)}
                                    OutputError={(error) => HandleUpdateErrors("email", error)}
                                    meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Email"
                                    Type="text"
                                    NoMessage={false}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    ShowLabel={true}
                                    LabelAlign="Left"
                                    // LabelMaxWidth="60px"
                                    FieldMaxWidth="200px"
                                    // DetachedLabelStyle={{fontWeight: 400, fontSize: "14px"}}
                                    // InputTextStyle={{fontWeight: 400, fontSize: "14px"}}
                                    // Colors=""
                                    // Borders=""
                                    // BorderRadius=""
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                                <FormCompontent
                                    Name="ztestCustom"
                                    InitialValue={InitialFormValues.email ? InitialFormValues.email : null}
                                    FormValue={FormValues.email ? FormValues.email : null}
                                    OutputValue={(values) => HandleUpdateFormValues("email", values)}
                                    OutputError={(error) => HandleUpdateErrors("email", error)}
                                    meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="what"
                                    Type="text"
                                    NoMessage={false}
                                    DisplayMessageIn="Popup"
                                    Required={false}
                                    ShowLabel={true}
                                    
                                    // LabelMaxWidth="60px"
                                    FieldMaxWidth="200px"
                                    // DetachedLabelStyle={{fontWeight: 400, fontSize: "14px"}}
                                    InputTextStyle={{fontWeight: 400, fontSize: "0.75rem"}}
                                    // Colors=""
                                    // Borders=""
                                    // BorderRadius=""
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <div style={{height: "50px"}}></div>
                <FormDev
                    DevMode={DevMode}
                    FormValues={FormValues}
                    InitialFormValues={InitialFormValues}
                    FieldOptions={FieldOptions}
                    FieldSubOptionsFull={FieldSubOptionsFull}
                    FilesUploaders={FilesUploaders}
                    FormErrors={FormErrors}
                />
                {/* Button Bar */}
                <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                    {`${FormErrorMain ? FormErrorMain : null}`}
                </div>
                <FormSubmitButtons
                    ButtonSaveAsDraft={ButtonSaveAsDraft}
                    ButtonSaveAndQuit={ButtonSaveAndQuit}
                    ButtonQuitNoSave={ButtonQuitNoSave}
                    ButtonDelete={ButtonDelete}
                    ButtonPublish={ButtonPublish}
                    ButtonReset={ButtonReset}
                    ButtonNext={ButtonNext}
                    ButtonPrevious={ButtonPrevious}
                    HandleSubmitForm={HandleSubmitForm}
                    Invalid={Invalid}
                    Pristine={Pristine}
                    DisableToolTip={DisableToolTip}
                    FormViewMode={FormViewMode}
                />

                {/* Button Bar */}
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <div className={classes.FormWrapper}>{FinalFormComponent}</div>;
});
export default Component;
