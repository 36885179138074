import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import useTableContext from "../utils/context/tableContext/useTableContext";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FooterWrapper: {
        position: "sticky",
        display: "table",
        width: "100%",
        bottom: "0px",
        // height: "40px",
        // width: "1200px",
        background: "grey",
        zIndex: "10",
    },
}));

/**
 * Component Description
 */

const TableFooter = React.memo(
    function TableFooter(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TotalRow} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** STATE *********************/
        const {TableRowsInputs} = useTableContext();
        const [FooterComponent, setFooterComponent] = useState(null);

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/

        //WHEN PROPS ARE CHANGING
        useEffect(() => {
            if (TotalRow && TotalRow.Render) {
                let FooterComponent = TotalRow.Render(TableRowsInputs);
                setFooterComponent(FooterComponent);
            }
        }, [TableRowsInputs]);
        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        //ADD YOUR FUNCTIONS HERE

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        return (
            <div className={classes.FooterWrapper}>
                <div>{FooterComponent}</div>
            </div>
        );
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableFooter.defaultProps = {
    MyProp: null,
};

TableFooter.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableFooter;
