/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Route, Switch, Redirect} from "react-router-dom";
import AccountingAndTransactionsDashboardTabNavRouter from "./01Dashboard/AccountingAndTransactionsDashboardTabNavRouter";
import StatementsTabNavRouter from "./02Statements/StatementsTabNavRouter";
import ReconciliationTabNavRouter from "./03Reconciliation/ReconciliationTabNavRouter";
import TransactionsTabNavRouter from "./04Transactions/TransactionsTabNavRouter";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path="/financials/accounting/accountsandtransactions">
                <Redirect to="/financials/accounting/accountsandtransactions/accountsdahboard" />
            </Route>

            <Route path="/financials/accounting/accountsandtransactions/accountsdahboard">
                <AccountingAndTransactionsDashboardTabNavRouter />
            </Route>
            <Route path="/financials/accounting/accountsandtransactions/statements">
                <StatementsTabNavRouter />
            </Route>
            <Route path="/financials/accounting/accountsandtransactions/reconciliation">
                <ReconciliationTabNavRouter />
            </Route>
            <Route path="/financials/accounting/accountsandtransactions/transactions">
                <TransactionsTabNavRouter />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
});

export default Component;
