import React, {useEffect, useState, useRef} from "react";

import {createUseStyles, useTheme} from "react-jss";
import {ZoomIn, ZoomOut} from "@artibulles-cis/react-icons";
import Page from "@artibulles-cis/react/Page";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PageWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // width: "100%",
    },
    Toolbar: {
        display: "flex",
    },
    PDFWrapper: {
		widht :"auto",
        flex: "1 1 auto",
        display: "flex",
        padding: "10px",
        boxSizing: "border-box",
        border: "1px solid grey",
        overflow: "hidden",
    },
}));

const DemoReactPDF = React.memo(function DemoReactPDF(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [Scale, setScale] = useState(1);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }
    const HandleZoom = (Direction) => {
        if (Direction === "In") {
            setScale(Scale + 0.5);
        } else {
            setScale(Scale - 0.5);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page PagePadding="10px">
           
        </Page>
    );

    /***************** RENDER ******************/
});

export default DemoReactPDF;

//
