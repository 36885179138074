import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormWithApi from "../../../../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import InvoiceItemsEditForm from "./InvoiceItemsEditForm";

import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {SaleInvoiceID, InvoiceItemID, CloseCard, ViewEditMode, CustomerAccountingPreference} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const InitialCall = {type: "Edit", ID: InvoiceItemID};

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/saleinvoices/invoicegeneration/invoiceitems/invoiceitem`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the initial options if needed
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            CloseCard();
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;

        console.log("CustomerAccountingPreference", CustomerAccountingPreference);

        let SaleInvoiceItem = data.SaleInvoiceItem;
        let SaleItemTypeSelectOptions = data.SaleItemTypeSelectOptions;

        let SaleItemTypeInitial = SaleInvoiceItem ? SaleInvoiceItem.TypeOfPurchase : null;
        if (SaleItemTypeInitial) {
            SaleItemTypeInitial = _.find(SaleItemTypeSelectOptions, {label: SaleItemTypeInitial}).value;
        }

        let AccountingTaxRatesOptions = [],
            ref_TaxesCodeIntial;
        if (data.AccoutingTaxesRates && Array.isArray(data.AccoutingTaxesRates)) {
            data.AccoutingTaxesRates.forEach((elem) => {
                AccountingTaxRatesOptions.push({
                    value: elem._id,
                    label: elem.TaxName,
                    Sequence: elem.Sequence,
                    VATPercentage: elem.VATPercentage,
                    TaxType: elem.TaxType,
                    TaxScope: elem.TaxScope,
                    InvoiceLabel_EN: elem.InvoiceLabel_EN,
                    InvoiceLabel_FR: elem.InvoiceLabel_FR,
                    RealPercentageInvoicePurchase: elem.RealPercentageInvoicePurchase,
                });
                if (SaleInvoiceItem && SaleInvoiceItem.ref_TaxesCode && elem._id === SaleInvoiceItem.ref_TaxesCode) {
                    ref_TaxesCodeIntial = elem._id;
                } else {
                    //Use the Customer Preference
                    if (CustomerAccountingPreference && CustomerAccountingPreference.DefaultItemTaxRateId) {
                        ref_TaxesCodeIntial = CustomerAccountingPreference.DefaultItemTaxRateId;
                    }
                }
            });
        }

        let AccoutingBelgianCodes = [];

        if (data.AccoutingBelgianCodes && Array.isArray(data.AccoutingBelgianCodes)) {
            data.AccoutingBelgianCodes.forEach((elem) => {
                AccoutingBelgianCodes.push({value: elem._id, label: `${elem.AccountNumber} - ${elem.Name_FR}`});
            });
        }

        // console.log("data", data);
        //SET THE INITIAL FORM VALUES
        let CalcVATAmountInit, CalcTotalVATExcludedInit, CalcTotalVATIncludedInit;
        if (SaleInvoiceItem && SaleInvoiceItem.CalcVATAmount) {
            CalcVATAmountInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(SaleInvoiceItem.CalcVATAmount);
        } else {
            CalcVATAmountInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(0);
        }
        if (SaleInvoiceItem && SaleInvoiceItem.CalcTotalVATExcluded) {
            CalcTotalVATExcludedInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(SaleInvoiceItem.CalcTotalVATExcluded);
        } else {
            CalcTotalVATExcludedInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(0);
        }
        if (SaleInvoiceItem && SaleInvoiceItem.CalcTotalVATIncluded) {
            if (SaleInvoiceItem.Rounding === true) {
                CalcTotalVATIncludedInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 0}).format(SaleInvoiceItem.CalcTotalVATIncluded);
            } else {
                CalcTotalVATIncludedInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(SaleInvoiceItem.CalcTotalVATIncluded);
            }
        } else {
            CalcTotalVATIncludedInit = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(0);
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: SaleInvoiceItem ? SaleInvoiceItem._id : "new",
                InvoiceLineIndex: SaleInvoiceItem ? SaleInvoiceItem.InvoiceLineIndex : null,
                ArticleName: SaleInvoiceItem ? SaleInvoiceItem.ArticleName : null,
                ToBill: SaleInvoiceItem ? SaleInvoiceItem.ToBill : null,
                AccountingArticleName: SaleInvoiceItem ? SaleInvoiceItem.AccountingArticleName : null,
                CustomerRefCode: SaleInvoiceItem ? SaleInvoiceItem.CustomerRefCode : null,
                CustomerDescription: SaleInvoiceItem ? SaleInvoiceItem.CustomerDescription : null,
                InternalDescription: SaleInvoiceItem ? SaleInvoiceItem.InternalDescription : null,
                TypeOfPurchase: SaleItemTypeInitial,
                ref_TaxesCode: ref_TaxesCodeIntial,
                ref_AccoutingBelgianCode: SaleInvoiceItem ? SaleInvoiceItem.ref_AccoutingBelgianCode : null,
                keywords: SaleInvoiceItem ? SaleInvoiceItem.keywords : null,
                UnitPriceVATExcluded: SaleInvoiceItem ? SaleInvoiceItem.UnitPriceVATExcluded : null,
                Quantity: SaleInvoiceItem ? SaleInvoiceItem.Quantity : null,
                Currency: SaleInvoiceItem ? SaleInvoiceItem.Currency : null,
                CurrencyExchangeRate: SaleInvoiceItem ? SaleInvoiceItem.CurrencyExchangeRate : null,
                CalcVATAmount: CalcVATAmountInit,
                CalcTotalVATExcluded: CalcTotalVATExcludedInit,
                CalcTotalVATIncluded: CalcTotalVATIncludedInit,
                ExpectedDeliveryDateIso: SaleInvoiceItem ? SaleInvoiceItem.ExpectedDeliveryDateIso : null,
                ref_SaleInvoice: SaleInvoiceItem ? SaleInvoiceItem.ref_SaleInvoice : null,
                Rounding: SaleInvoiceItem ? SaleInvoiceItem.Rounding : null,
            },
            SelectOptions: {
                TypeOfPurchase: SaleItemTypeSelectOptions,
                ref_TaxesCode: AccountingTaxRatesOptions,
                ref_AccoutingBelgianCode: AccoutingBelgianCodes,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };

        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    SaleInvoiceItem: {
                        Updatable: {
                            ref_SaleInvoice: SaleInvoiceID,
                            ArticleName: values.ArticleName,
                            InvoiceLineIndex: values.InvoiceLineIndex,
                            AccountingArticleName: values.AccountingArticleName,
                            CustomerRefCode: values.CustomerRefCode,
                            CustomerDescription: values.CustomerDescription,
                            InternalDescription: values.InternalDescription,
                            TypeOfPurchase: values.TypeOfPurchase,
                            keywords: values.keywords,
                            UnitPriceVATExcluded: values.UnitPriceVATExcluded,
                            Quantity: values.Quantity,
                            Currency: values.Currency,
                            CurrencyExchangeRate: values.CurrencyExchangeRate,
                            ref_TaxesCode: values.ref_TaxesCode,
                            ref_AccoutingBelgianCode: values.ref_AccoutingBelgianCode,
                            Rounding: values.Rounding,
                            ToBill: values.ToBill,
                        },
                    },
                };
                console.log("ServerData", ServerData);
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div>
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={true}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    ShowCard: false,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    // ClassExtendCard: classes.ClassExtendCard,
                }}
                OpenInViewMode={ViewEditMode === "View" ? true : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                // DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={InvoiceItemsEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
