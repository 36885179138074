/* eslint-disable */
//Disabled because of useCallback not included in use effect but if included -> create loop effect...
import React, {useState} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../utils/APICallExternal";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};
//eslint-disable-next-line
const Lockedstyles = {};
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * This Fake Component performs on demands API Calls and provides the server Answers when received from the server
 * It sends back the Answer to the parent component through theApiResponse prop
 * It is designed to perfrom CRUD operation for a form :
 * -Post = create a record and send back initial form values for select and other form fields
 * -Put = Update a record based on the ID
 * -Delete = Delete a record based on the ID
 * -Get = Edit an Record -> Provides the initial form values for the record to edit through the ID
 * - CRUDOption defines the type of API Calls to perform including the ID of the record
 */
const FormApi = React.forwardRef(function FormApi(props, ref) {
    const {APIUrlRelative, APIUrlAbsolute, ApiResponse, CRUDOption} = props;

    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const [ApiRes, setApiRes] = useState(null); //Collecting API answers after async calls
    const [Loading, setLoading] = useState(false); //Loader and ensuring async is done for API response

    //Checking the URL Inputs and sending an error if incorrect

    //Once the API has answered
    const EndofAPI = React.useCallback(() => {
        var Output;
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            //Checking that the server has answered
            if (ApiRes.data) {
                if (ApiRes.data.status !== "success") {
                    console.log("Server Error", ApiRes.data.message, ApiRes.data.status);
                    ApiResponse(ApiRes.data);
                    return;
                }
                if (CRUDOption.type === "Delete") {
                    Output = {status: ApiRes.data.status, data: null, CrudType: "Deleted"};
                    ApiResponse(Output);
                } else {
                    //Sending back the server response
                    let Data = ApiRes.data.data;
                    
                    if (Data) {
                        if (CRUDOption.type === "Publish") {
                            Output = {status: ApiRes.data.status, data: Data, CrudType: "Published"};
                        } else if (CRUDOption.type === "CustomEdit"){
							Output = {status: ApiRes.data.status, data: Data, CrudType: "Edit"};
						}else{
                            Output = {status: ApiRes.data.status, data: Data, CrudType: CRUDOption.type};
                        }
                    } else {
                        Output = {status: "Error", data: "The server didn't send back any data, your component will crash. Check your server response", CrudType: CRUDOption.type};
                    }
                    ApiResponse(Output);
                }
            } else {
                //Wrong server address or rejected
                ApiResponse({status: "Error", data: ApiRes.erroDetails, CrudType: CRUDOption.type});
                // console.log("Server Internal Error", ApiRes);
            }
        }
    }, [ApiRes, ApiResponse, CRUDOption, Loading]);

    //Initial API Call
    React.useEffect(() => {
        var FinalURL;
        if (APIUrlRelative) {
            FinalURL = {
                GETONE: APIUrlRelative.GETONE ? `${APIUrlRelative.MAIN}/${APIUrlRelative.GETONE}` : `${APIUrlRelative.MAIN}`,
                DELETE: APIUrlRelative.DELETE ? `${APIUrlRelative.MAIN}/${APIUrlRelative.DELETE}` : `${APIUrlRelative.MAIN}`,
                PUT: APIUrlRelative.PUT ? `${APIUrlRelative.MAIN}/${APIUrlRelative.PUT}` : `${APIUrlRelative.MAIN}`,
                POST: APIUrlRelative.POST ? `${APIUrlRelative.MAIN}/${APIUrlRelative.POST}` : `${APIUrlRelative.MAIN}`,
            };
        } else if (APIUrlAbsolute) {
            FinalURL = {
                GETONE: APIUrlAbsolute.GETONE ? `${APIUrlAbsolute.GETONE}` : "",
                DELETE: APIUrlAbsolute.DELETE ? APIUrlAbsolute.DELETE : "",
                PUT: APIUrlAbsolute.PUT ? APIUrlAbsolute.PUT : "",
                POST: APIUrlAbsolute.POST ? APIUrlAbsolute.POST : "",
            };
        } else {
            console.log("No API SERVER INPUTS WERE DEFINED - COMPONENT WILL CRASH - Check the APIUrlRelative or the APIUrlAbsolute objects");
        }

        if (CRUDOption) {
            var ApiCall;
            if (CRUDOption.type === "Edit") {
                ApiCall = {
                    url: `${FinalURL.GETONE}/${CRUDOption.ID}`,
                    type: "get",
                    data: null,
                    timeout: 10000,
                };
            } else if (CRUDOption.type === "CustomEdit") {
                ApiCall = {
                    url: `${FinalURL.GETONE}/${CRUDOption.ID}`,
                    type: "post",
                    data: CRUDOption.data,
                    timeout: 10000,
                };
            } else if (CRUDOption.type === "New") {
                ApiCall = {
                    url: FinalURL.POST,
                    type: "post",
                    data: null,
                      timeout : 10000,
                };
            } else if (CRUDOption.type === "SaveDraft" || CRUDOption.type === "SaveAndQuit" || CRUDOption.type === "Publish" || CRUDOption.type === "NextSave" || CRUDOption.type === "PreviousSave") {
                ApiCall = {
                    url: `${FinalURL.PUT}/${CRUDOption.ID}`,
                    type: "put",
                    data: CRUDOption.data,
                    timeout: 10000,
                };
            } else if (CRUDOption.type === "RefreshForm") {
				
                ApiCall = {
                    url: `${FinalURL.GETONE}/${CRUDOption.ID}`,
                    type: "get",
                    data: null,
                    timeout: 10000,
                };
            } else if (CRUDOption.type === "Delete") {
                ApiCall = {
                    url: `${FinalURL.DELETE}/${CRUDOption.ID}`,
                    type: "delete",
                    data: null,
                    timeout: 10000,
                };
            }else {
                console.log("FORMAPI : Incorrect CRUDOption.type provided");
                return;
            }
            setLoading(true);
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        } else {
            console.log("FORMAPI : no CRUDOption provided");
            return;
        }
    }, [CRUDOption, APIUrlRelative, APIUrlAbsolute]);

    //Checking that the API is ready with the answer and executing the function to use the API Data
    React.useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    return <React.Fragment />;
});

FormApi.defaultProps = {
    APIUrlRelative: null,
    APIUrlAbsolute: null,
    ApiResponse: null,
    CRUDOption: null,
};

FormApi.propTypes = {
    /**
     * Override the class
     */
    APIUrlRelative: PropTypes.object,
    /**
     * Override the class
     */
    APIUrlAbsolute: PropTypes.object,
    /**
     * Override the class
     */
    ApiResponse: PropTypes.func,
    /**
     * Override the class
     */
    CRUDOption: PropTypes.object,
};

export default FormApi;
