import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

import AccountDetailsTabRouter from "./AccountDetails/AccountDetailsTabRouter";
import Dashboard from "./Dashboard/Dashboard";
import AccountsBank from "./AccountsBank/AccountsBank";
import SpendingAnalysis from "./SpendingAnalysis/SpendingAnalysis";
import RevenueAnalysis from "./RevenueAnalysis/RevenueAnalysis";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const SettingsRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/companyaccount/account">
                    <Redirect to="/financials/companyaccount/account/dashboard" />
                </Route>
                <Route path="/financials/companyaccount/account/dashboard">
                    <Dashboard />
                </Route>
                <Route path="/financials/companyaccount/account/accountsbank">
                    <AccountsBank />
                </Route>
                <Route path="/financials/companyaccount/account/revenue">
                    <RevenueAnalysis />
                </Route>
                <Route path="/financials/companyaccount/account/spending">
                    <SpendingAnalysis />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SettingsRouter;
// <Route path="/financials/companyaccount/account/:id">
//                     <AccountDetailsTabRouter />
//                 </Route>
//                 <Route path="/financials/companyaccount/account/:id">
//                     <AccountDetailsTabRouter />
//                 </Route>
