import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";

import {useHistory} from "react-router-dom";
import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";

import Table from "../../../../../../artibulles-cis/TableNew/Table";
import {isValid, format, parseISO} from "date-fns";
import InvoiceEdit from "./InvoiceEdit/InvoiceEdit";
import {PDF} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        // height: "100%",
        padding: "10px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
	ClassExtendTabContent : {
		padding : "0px"
	}
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Page = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
	const Ref_TabNavContent = useRef(null);
	const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
	const HandleViewPdf = (arg) => {
        
        window.open(arg);
    };
    const ColomnsInput = [
        {
            width: "130px",
            caption: "Invoice Date",
            datafield: "InvoiceSupplierDateIso",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "dd-MMM-yyyy");
                } else {
                    return value;
                }
            },

            fixedCol: false,
            searchable: false,
            sortable: true,
            initialSort: {order: 1, sortDirection: "Dsc"},
            datatype: "date",
        },
		{
            width: "300px",
			datafield: "ref_Supplier",
			lookup: {data: "Suppliers", searchField: "_id", returnField: "company"},
            caption: "Supplier",
            searchable: true,
            datatype: "date",
            sortable: true,
            // initialSort: {order: 1, sortDirection: "dsc"},
        },

        {
            width: "300px",
            caption: "Description",
            datafield: "Description",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },
		{
            width: "300px",
            caption: "Communication",
            datafield: "InvoicePaymentExpectedCommunication",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },
		{
            width: "130px",
            caption: "Documents",
            datafield: "Invoices_File_Path",
            cellCustomRender: (value) => {
                if (value && Array.isArray(value) && value.length > 0) {
                    let CellRenderComp = [];
                    value.forEach((elem) => {
                        let IconElem = (
                            <div onClick={() => HandleViewPdf(elem.serverPath)}>
                                <PDF SVGFillColor="white" />
                            </div>
                        );
                        CellRenderComp.push(IconElem);
                    });

                    return CellRenderComp;
                } else {
                    return null;
                }
            },

            fixedCol: false,
            searchable: true,
            datatype: "text",
            sortable: true,
        },
        {
            width: "150px",
            caption: "Type",
            datafield: "TypeOfPurchase",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },

        
        
        {
            width: "100px",
            caption: "Tot VAT Exc.",
            datafield: "CalcTotalVATExcluded",
            cellFormatFunction: (value, rowData) => {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Tot VAT",
            datafield: "CalcTotalVATAmount",
            cellFormatFunction: (value, rowData) => {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Tot VAT Inc.",
            datafield: "CalcTotalVATIncluded",
            cellFormatFunction: (value, rowData) => {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
    ];

    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };
    const TotalRow = {
        Show: false,
    };

    const HandlePreviewRow = (arg) => {
        if (arg) {
            let id = arg._id;

            setShowViewCard(true);
            setViewCardId(id);
        }
    };
    const HandleEditRow = (arg) => {
        let Id = arg._id;
        History.push(`/financials/purchaseordersandinvoices/invoices/invoice/${Id}/edit`);
    };

    const HandleDeleteRow = (arg) => {
        console.log("delete");
    };
    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 15,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: true,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "View",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandlePreviewRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
            {
                Standard: true,
                Name: "Edit",
                ActiveCondition: null,
                Position: 2,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
            {
                Standard: true,
                Name: "Delete",
                ActiveCondition: null,
                Position: 3,
                // OnClick: HandleDeleteRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
    };

    const FinalAPIURLAbsolute = {
        MAIN: `${ProdURL}/api/purchaseinvoices/invoices/queries`,
        DELETE: `${ProdURL}/api/purchaseinvoices/invoice/`,
        PUT: "",
        POST: "",
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);

    const [TableComponent, setTableComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <Table
                // LocalData={TableData}
                URLAbsolute={FinalAPIURLAbsolute}
                // URLRelative="arnaud"
                MainDataSource="Invoices"
                ColomnsInput={ColomnsInput}
                TableDimensions={TableDimensions}
                TableOptions={TableOptions}
                TotalRow={TotalRow}
                DevMode={false}
            />
        );
        setTableComponent(FinalTableComponent);
    }, []);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        GenerateTableComponent();
    }, [GenerateTableComponent]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceEdit Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            {TableComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
