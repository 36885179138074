import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {CloseIcon, Calendar} from "@artibulles-cis/react-icons";
import FieldDatePicker from "../FieldDatePicker/FieldDatePicker";

import {ImaskCurrency, ImaskDate, ImaskNumber, ImaskEmail} from "../utils/ImaskPattern";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    InputWrapper: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        flex: "0 1 auto",
        alignSelf: "stretch",
        // width : "100%"
    },
    ToolbarLeft: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
        borderRight: "1px solid grey",
        overflow: "hidden",
        position: "relative",
        justifyContent: "center",
    },
    ToolbarIconLeft: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        top: "0px",
    },
    ToolbarIconLeft_Hide: {
        top: "-50px",
    },
    ToolbarIconLeftAnimated: {
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        top: "50%",
        transform: "translate(0,-50%)",
    },
    ToolbarIconLeftAnimated_Hide: {
        // display: "none",
        top: "100px",
        transform: "translate(0,0)",
    },
    ToolbarRight: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
    },
    ToolBarIconWrapper_Right: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: "1px solid grey",
        alignSelf: "stretch",
    },
    ToolBarIcon: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "0px 5px",
        cursor: "pointer",
    },
}));

/**
 * Component Description
 */
const FieldDatePickerMain = React.memo(function FieldDatePickerMain(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputValue,
        OutputError,
        Error,
        Name, //Important for AutoFill
        InternalToolbarLeft,
        AnimateToolBarLeft,
        InternalToolbarLeftIcon,
        AnimateToolbarLeftColor,
        FinalLabelColor,
        InternalToolbarRight,
        ShareFocusState,
        ShareHasValue,
        IsFocused,
        HasValue,
        Clearable,
        Searchable,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Disabled,
        Mask,
        LocalValidation,
        Type,
        Required,
        FinalBorderRadius,
        FieldMaxWidth,
        PickerOnly,
        PickerDateType,
        CalendarOptions,
        RollerOptions,
        InputFormat,

        //Dont Fold
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [FinalInputComponent, setFinalInputComponent] = useState(null);
    const [MaskedDefinition, setMaskedDefinition] = useState(null);
    const [IsCalendarOpened, setIsCalendarOpened] = useState(false);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
        if (InputFormat && PickerDateType) {
            let MaskProps;

            if (PickerDateType === "Calendar" || PickerDateType === "CalendarRoller") {
                let Min = CalendarOptions ? CalendarOptions.Min : null;
                
                MaskProps = ImaskDate({
                    Type: "Date",
                    Options: {
                        Format: InputFormat,
                        Min: CalendarOptions ? CalendarOptions.Min : null,
                        Max: CalendarOptions ? CalendarOptions.Max : null,
                    },
                });
            } else if (PickerDateType === "TimeHM" || PickerDateType === "TimeHMS") {
                MaskProps = ImaskDate({
                    Type: "Time",
                    Options: {
                        Format: InputFormat,
                    },
                });
            } else if (PickerDateType === "MonthYear") {
                MaskProps = ImaskDate({
                    Type: "MonthYear",
                    Options: {
                        Format: InputFormat,
                    },
                });
            } else if (PickerDateType === "Year") {
                MaskProps = ImaskDate({
                    Type: "Year",
                    Options: {
                        Format: InputFormat,
                    },
                });
            } else if (PickerDateType === "Month") {
                MaskProps = ImaskDate({
                    Type: "Month",
                    Options: {
                        Format: InputFormat,
                    },
                });
            }

            setMaskedDefinition(MaskProps);
            // setFinalInputComponent("MaskedInput");
        } else {
            let MaskProps = ImaskDate({
                Type: "Date",
                Options: {
                    Format: "dd/MM/yyyy",
                },
            });

            setMaskedDefinition(MaskProps);
        }
    }, [InputFormat, PickerDateType]);
    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/
    const HandleClear = () => {
        //To clear the Value, we pass null to the formValue to reset the input
        // OutputValue({value: null, formattedValue: null});
        // ShareHasValue(false);
    };
    const HandleToggleCalendar = () => {
        if (ReadOnly === false) {
            if (IsCalendarOpened === false) {
                setIsCalendarOpened(true);
            }
        }
    };
    const HandleToggleCalendarFromOutside = (statusOpened) => {
        if (ReadOnly === false) {
            setIsCalendarOpened(statusOpened);
        }
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var ToolbarComponentLeft, ToolbarComponentRight;
    if (InternalToolbarLeft) {
        if (AnimateToolBarLeft === true) {
            //When using any type of component
            var FinalClassTopIcon, FinalClassBottomIcon;
            if (IsFocused === true || HasValue === true) {
                FinalClassTopIcon = clsx(classes.ToolbarIconLeft, classes.ToolbarIconLeft_Hide);
                FinalClassBottomIcon = classes.ToolbarIconLeftAnimated;
            } else {
                FinalClassTopIcon = classes.ToolbarIconLeft;
                FinalClassBottomIcon = clsx(classes.ToolbarIconLeftAnimated, classes.ToolbarIconLeftAnimated_Hide);
            }

            ToolbarComponentLeft = (
                <div className={classes.ToolbarLeft}>
                    <div className={FinalClassTopIcon}>{InternalToolbarLeft}</div>
                    <div className={FinalClassBottomIcon}>{InternalToolbarLeft}</div>
                </div>
            );
        }
    } else if (InternalToolbarLeftIcon) {
        //When using Icons

        if (AnimateToolBarLeft === true) {
            var FinalClassTopIcon, FinalClassBottomIcon;
            if (IsFocused === true || HasValue === true) {
                FinalClassTopIcon = clsx(classes.ToolbarIconLeft, classes.ToolbarIconLeft_Hide);
                FinalClassBottomIcon = classes.ToolbarIconLeftAnimated;
            } else {
                FinalClassTopIcon = classes.ToolbarIconLeft;
                FinalClassBottomIcon = clsx(classes.ToolbarIconLeftAnimated, classes.ToolbarIconLeftAnimated_Hide);
            }
            if (AnimateToolbarLeftColor === true) {
                //We use the same color as for the label itself
                //We need to clone first the Icon
                let ClonedIcon = React.cloneElement(InternalToolbarLeftIcon, {
                    SVGFillColor: FinalLabelColor,
                    SVGStrokeColor: FinalLabelColor,
                    SVGStrokeHoverColor: FinalLabelColor,
                    SVGFillHoverColor: FinalLabelColor,
                });

                ToolbarComponentLeft = (
                    <div className={classes.ToolbarLeft}>
                        <div className={FinalClassTopIcon} style={{padding: "0px 5px"}}>
                            {ClonedIcon}
                        </div>
                        <div className={FinalClassBottomIcon} style={{padding: "0px 5px"}}>
                            {ClonedIcon}
                        </div>
                    </div>
                );
            } else {
                ToolbarComponentLeft = (
                    <div className={classes.ToolbarLeft}>
                        <div className={FinalClassTopIcon} style={{padding: "0px 5px"}}>
                            {InternalToolbarLeftIcon}
                        </div>
                        <div className={FinalClassBottomIcon} style={{padding: "0px 5px"}}>
                            {InternalToolbarLeftIcon}
                        </div>
                    </div>
                );
            }
        }
    } else {
        ToolbarComponentLeft = null;
    }

    /********************************* DEFINING THE RIGHT TOOLBAR WITH CALENDAR BUTTON *************************/
    var ClearIconComponent;
    if (Clearable === true) {
        if (Clearable === true) {
            ClearIconComponent = (
                <div className={classes.ToolBarIconWrapper_Right}>
                    <div className={classes.ToolBarIcon} onClick={HandleClear}>
                        <CloseIcon IconSize="18px" FrameFillColor="" FrameStrokeColor="" InnerPadding="35" FrameStrokeWidth="10" SVGStrokeWidth="10" SVGStrokeHoverColor="black" />
                    </div>
                </div>
            );
        }
    }
    let CalendarIconComponent = (
        <div className={classes.ToolBarIconWrapper_Right}>
            <div className={classes.ToolBarIcon} onClick={HandleToggleCalendar}>
                <Calendar IconSize="18px" SVGFillColor="black" SVGStrokeWidth="10" SVGFillHoverColor="black" SVGStrokeHoverColor="black" />
            </div>
        </div>
    );

    ToolbarComponentRight = (
        <div className={classes.ToolbarRight}>
            {ClearIconComponent}
            {CalendarIconComponent}
        </div>
    );

    /********************************* DEFINING THE RIGHT TOOLBAR WITH CALENDAR BUTTON *************************/

    var FormElement;
    if (MaskedDefinition) {
        var FinalPickerOnly;
        if (PickerDateType === "Calendar" || PickerDateType === "TimeHM" || PickerDateType === "TimeHMS" || PickerDateType === "MonthYear" || PickerDateType === "Year" || PickerDateType === "Month") {
            if (PickerOnly === true) {
                FinalPickerOnly = true;
            } else {
                FinalPickerOnly = false;
            }
        } else {
            FinalPickerOnly = true;
        }
        FormElement = (
            <div className={classes.InputWrapper}>
                {ToolbarComponentLeft}
                <FieldDatePicker
                    Mask={MaskedDefinition}
                    HandleUpdateRefInput={HandleUpdateRefInput}
                    Style={Style}
                    InitialValue={InitialValue}
                    FormValue={FormValue}
                    OutputValue={OutputValue}
                    Error={Error}
                    ShareFocusState={ShareFocusState}
                    ShareHasValue={ShareHasValue}
                    OutputError={OutputError}
                    ReadOnly={ReadOnly}
                    Disabled={Disabled}
                    Name={Name}
                    LocalValidation={LocalValidation}
                    Type={Type}
                    Required={Required}
                    FinalBorderRadius={FinalBorderRadius}
                    Clearable={Clearable}
                    Searchable={Searchable}
                    FieldMaxWidth={FieldMaxWidth}
                    ShareCalendarIsOpened={HandleToggleCalendarFromOutside}
                    IsCalendarOpened={IsCalendarOpened}
                    PickerOnly={FinalPickerOnly}
                    PickerDateType={PickerDateType}
                    CalendarOptions={CalendarOptions}
                    RollerOptions={RollerOptions}
                    InputFormat={InputFormat}
                />
                {ToolbarComponentRight}
            </div>
        );
    } else {
        FormElement = null;
    }

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldDatePickerMain.defaultProps = {
    InitialValue: null,
    FormValue: null,
    OutputValue: null,
    Error: null,
    Variant: "Outlined",
    Name: null,
    Label: null,
    ShowLabel: true,
    LabelPosition: "Left",
    LabelMaxWidth: "150px",
    LabelAlign: "Right",
    NoMessage: true,
    HelperMessage: null,
    ShareFocusState: null,
    ShareHasValue: null,
    PickerOnly: false,
    PickerDateType: "Calendar",
    CalendarOptions: null,
    RollerOptions: {MonthFormat: "", YearFormat: "", MinuteInterval: 1, SecondInterval: 1},
    InputFormat: null,
};

FieldDatePickerMain.propTypes = {
    /**
     * NoMessage :
     */
    NoMessage: PropTypes.boolean,
};

export default FieldDatePickerMain;
