/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import Button from "@artibulles-cis/react/Button";
import FormDev from "../../../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import CardModal from "../../../../../../../artibulles-cis/CardModal/CardModal";
import clsx from "clsx";
/************** NEW COMPONENTS **************/
import PdfViewer from "./InvoiceEditFormParts/PdfViewer";
import {PDF} from "@artibulles-cis/react-icons";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";

import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../../artibulles-cis/CheckBox/CheckBox";
import FileUploader from "../../../../../../../artibulles-cis/FileUploader/FileUploader";
import {AddCircle, Contract, AvatarV2, Login} from "@artibulles-cis/react-icons";
import {formatISO, parseISO, add, lastDayOfMonth, setDate, getDate} from "date-fns";

import InvoiceEditDetailsTabNav from "./InvoiceEditFormParts/InvoiceEditDetailsTabNav";
import InvoicePayReview from "./InvoiceEditFormParts/InvoicePayReview";
import InvoicePaymentInfoPart from "./InvoiceEditFormParts/InvoicePaymentInfoPart";
import AccountantStatusInformation from "./InvoiceEditFormParts/Accountant/AccountantStatusInformation";
import InvoiceReconcileEdit from "./Reconciliation/InvoiceReconcileEdit";
import Unreconcile from "./Reconciliation/Unreconcile";
import useWebsiteAppInitContext from "../../../../../../../artibulles-cis/utils/context/websiteAppInitContext/useWebsiteAppInitContext";
// import CustomerAddresses from "./CustomerAddresses";
// import CustomerBankAccountForm from "./CustomerBankAccountForm";

/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        overflow: "hidden",
        textAlign: "left",
    },
    FormFlexHorizontal: {
        display: "flex",
        height: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
    },
    FormPDFViewer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
    },
    FormMainScroll: {
        flex: "0 0 1200px",
        height: "100%",
        // overflowY: "auto",
        boxSizing: "border-box",
        // padding: "0px 10px",
        border: "1px solid grey",
        // maxWidth: "1400px",
        display: "flex",
        flexDirection: "column",
    },
    FormMainScroll_ScrollFlex: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },

    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        height: "35px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "1 1 1200px",
        // width: "1200px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 0px 0px 0px",
    },
    FormTitleRightInfo: {
        flex: "0 0 260px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 40px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    InvoiceDetailsSection: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // maxWidth: "1400px",
        // padding: "10px 10px 0px 10px",
    },
    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormTopFlex: {
        flex: "0 1 auto",
        display: "flex",
        width: "100%",

        boxSizing: "border-box",
        position: "relative",
        // padding: "0px 10px 0px 10px",
    },
    FormTopLeft: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        borderRight: "1px solid grey",
        borderBottom: "1px solid grey",
        padding: "0px 0px 0px 10px",
    },
    FormTopRight: {
        flex: "0 0 350px",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        // justifyContent: "flex-end",
        borderBottom: "1px solid grey",
        padding: "0px 10px 0px 0px",
    },
    PDFViewerIcon: {
        // margin: "0px",
        // display: "flex",
        // flex: "0 0 40px",
        // justifyContent: "flex-end",
    },
    InvoiceSummaryBox: {
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "column",
        // justifyContent: "flex-end",
        boxSizing: "border-box",
        margin: "0px 0px 5px 10px",
        padding: "5px 5px",

        borderRadius: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
    ClassExtendsContentOpened: {
        padding: "0px 0px 0px 10px",
    },

    ButtonArrowStart: {
        background: "none",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowStart_BeforeActive: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
    },
    ButtonArrowStart_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
    },
    ButtonArrowMiddle: {
        background: "none",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "16px solid transparent",
            borderBottom: "16px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowMiddle_BeforeActive: {
        background: "rgb(150, 201, 242)",
        "&:after": {
            borderLeft: "10px solid rgb(150, 201, 242)",
        },
        "&:before": {
            borderLeft: "10px solid rgb(150, 201, 242)",
        },
    },
    ButtonArrowMiddle_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },

    ButtonArrowEnd: {
        background: "transparent",
        padding: "0px 16px",

        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowEnd_Active: {
        background: "rgb(66, 165, 245)",
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },
    //ARROWS
    SupplierAddress: {
        fontSize: "0.8rem",
        flex: "1 1 auto",
        alignSelf: "flex-end",
        display: "flex",
        flexDirection: "column",
        marginLeft: "115px",
    },

    SupplierAddressLine: {
        display: "flex",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 0px 2px 0px",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
    },
    InvoiceSummary: {
        display: "flex",
        // padding: "0px 0px 0px 5px",
        width: "210px",
        alignSelf: "flex-end",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "0 0 120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    UploadButton: {
        fontSize: "0.75rem",
        padding: "0px",
        margin: "0px",
        height: "30px",
    },
    TitleButtons: {
        fontSize: "0.9rem",
        padding: "0px",
        margin: "0px",
    },
    SummaryInvoiceError: {
        color: "orange",
        fontWeight: "600",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    GroupedFields: {
        flex: "0 1 auto",
        // flex : "0 1 auto",
        // display : "flex",
        // flexDirection : "column"
    },
    AddressGroup: {
        display: "flex",
    },
    NoAddressFieldGroup: {
        marginLeft: "5px",
        fontSize: "0.9rem",
        padding: "10px",
        margin: "0px",
        boxSizing: "border-box",
        border: "1px solid red",
        display: "flex",
        borderRadius: "8px",
        background: "red",
        color: "white",
    },
    HorizontalSeparator: {
        padding: "0px",
        margin: "5px 8px 0px 0px",
        borderTop: "2px solid grey",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;
    const {websiteAppInitContextState} = useWebsiteAppInitContext();
    let ClientWindowWidth = websiteAppInitContextState.clientWindowDimensions.windowWidth;

    const theme = useTheme();
    const classes = styles({...props, theme});
    const Ref_InvoiceStatus = useRef(null);
    const Ref_ManualPaymentConfirmation = useRef(null);
    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [PristineDenyCreateInvoice, setPristineDenyCreateInvoice] = useState(true);
    const [PristineDenyPostInvoice, setPristineDenyPostInvoice] = useState(true);
    const [FormViewMode, setFormViewMode] = useState();
    const [AdminMode, setAdminMode] = useState(false);
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [ShowViewPDF, setShowViewPDF] = useState(false); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [SupplierAddressComponent, setSupplierAddressComponent] = useState(null);
    const [ShowPaymentReview, setShowPaymentReview] = useState(false);
    const [ShowInfoPart, setShowInfoPart] = useState(false);
    const [InvoiceGenerationMethod, setInvoiceGenerationMethod] = useState(null);
    const [EditInvoiceGenerate, setEditInvoiceGenerate] = useState(false);
    const [ShowCustomerCreateEditComponent, setShowCustomerCreateEditComponent] = useState(false);
    const [ShowDetailsTabNav, setShowDetailsTabNav] = useState(true);
    const [PDFFileURL, setPDFFileURL] = useState(null);
    const [ShowInvoiceReconciliation, setShowInvoiceReconciliation] = useState(null);
    const [ShowUnreconcile, setShowUnreconcile] = useState(false);
    const [ShowPDFSideViewer, setShowPDFSideViewer] = useState(true);
    const [ShowPDFViewerCardEditCard, setShowPDFViewerCardEditCard] = useState(false);

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);

    useEffect(() => {
        if (InitialFormValues && InitialFormValues.status_invoice) {
            Ref_InvoiceStatus.current = InitialFormValues.status_invoice;
            Ref_ManualPaymentConfirmation.current = {
                ManualPaymentDone: InitialFormValues.ManualPaymentDone,
                ManualPaymentDateIso: InitialFormValues.ManualPaymentDateIso,
            };
            if (InitialFormValues.status_invoice === "Draft") {
                setFormViewMode("Edit");
            }
            setShowInfoPart(true);
        }
        if (InitialFormValues && InitialFormValues.InitialAddress) {
            let SupplierAddressComp = (
                <div className={classes.SupplierAddress}>
                    <div className={classes.SupplierAddressLine}>{InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.Line1 : ""}</div>
                    <div className={classes.SupplierAddressLine} style={{display: `${InitialFormValues.InitialAddress ? (InitialFormValues.InitialAddress.Line2 ? null : "none") : "none"}`}}>
                        {InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.Line2 : ""}
                    </div>
                    <div className={classes.SupplierAddressLine}>{InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.ZipCity : ""}</div>
                    <div className={classes.SupplierAddressLine}>{InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.Country : ""}</div>
                </div>
            );
            setSupplierAddressComponent(SupplierAddressComp);
        }
    }, [InitialFormValues]);

    useEffect(() => {
        if (FilesUploaders && FilesUploaders.Invoices_File_Path && FilesUploaders.Invoices_File_Path.length > 0) {
            let FileUrl;
            if (FilesUploaders.Invoices_File_Path && FilesUploaders.Invoices_File_Path.length > 0) {
                FileUrl = FilesUploaders.Invoices_File_Path[0].serverPath;
            }
            if (FileUrl) {
                setPDFFileURL(FileUrl);
                setShowViewPDF(true);
            } else {
                setPDFFileURL(null);
                setShowViewPDF(false);
            }
        } else {
            setPDFFileURL(null);
            setShowViewPDF(false);
        }
    }, [FilesUploaders]);

    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (ClientWindowWidth < 1800) {
            setShowPDFSideViewer(false);
        } else {
            setShowPDFSideViewer(true);
        }
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "PaymentMethod") {
            if (SelectedValues === "01" || SelectedValues === "02") {
                //BankAccount
            } else {
                //Others
                //We reset the Communication and the BankAccount
                UpdatedFormValues.CounterpartyBankAccount_Id = null;
                UpdatedFormValues.InvoicePaymentExpectedCommunication = null;
            }
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }

        //When the ref_Supplier changes, we update the vat_number and the options for the bank account
        if (field === "ref_Supplier") {
            let SelectedId = SelectedDetails.Value;
            let SelectedInfo = _.find(FieldOptions.ref_Supplier, {value: SelectedId});
            let vat_number = SelectedInfo.vat_number;

            let SuppliersOptions = FieldOptions.ref_Supplier;
            if (SuppliersOptions && Array.isArray(SuppliersOptions)) {
                let SelectedSupplier = _.find(SuppliersOptions, {value: SelectedId});
                if (SelectedSupplier) {
                    //Updating the Bank Account and VAT Number
                    let BankAccountOptions = SelectedSupplier.BankAccounts;
                    if (BankAccountOptions && Array.isArray(BankAccountOptions)) {
                        let FinalBankAccountOptions = [];
                        BankAccountOptions.forEach((elem) => {
                            FinalBankAccountOptions.push({value: elem._id, label: elem.account_number});
                        });
                        //We change the options for the CounterpartyBankAccount_Id field
                        setFieldOptions({...FieldOptions, ...{CounterpartyBankAccount_Id: FinalBankAccountOptions}});
                        //And we reset the formValue
                        setFormValues({...UpdatedFormValues, ...{CounterpartyBankAccount_Id: null}});
                    }
                    //Updating the Address Infos

                    if (SelectedSupplier.Addresses && SelectedSupplier.Addresses.length > 0) {
                        //We need to find the Billing Address or the General one if the billing doesn't exist
                        let InitialAddressFull,
                            FinalAddress,
                            Addresses = [];
                        if (SelectedSupplier.Addresses.length === 1) {
                            InitialAddressFull = SelectedSupplier.Addresses[0];
                        } else {
                            InitialSupplier.Addresses.forEach((elem) => {
                                if (elem.AddressType.name_EN === "Billing") {
                                    Addresses.push({Type: "Billing", Address: elem});
                                } else if (elem.AddressType.name_EN === "General") {
                                    Addresses.push({Type: "General", Address: elem});
                                }
                            });
                            InitialAddressFull = _.find(Addresses, {Type: "Billing"});
                            if (!InitialAddressFull) {
                                InitialAddressFull = _.find(Addresses, {Type: "General"});
                            }
                        }

                        if (InitialAddressFull) {
                            FinalAddress = {
                                Line1: InitialAddressFull.street_line1,
                                Line2: InitialAddressFull.street_line2,
                                ZipCity: InitialAddressFull.zip_code + ", " + InitialAddressFull.city,
                                Country: InitialAddressFull.Country.country_name_EN,
                            };
                        }

                        let SupplierAddressComp = (
                            <div className={classes.SupplierAddress}>
                                <div className={classes.SupplierAddressLine}>{FinalAddress ? FinalAddress.Line1 : ""}</div>
                                <div className={classes.SupplierAddressLine} style={{display: `${FinalAddress ? (FinalAddress.Line2 ? null : "none") : "none"}`}}>
                                    {FinalAddress ? FinalAddress.Line2 : ""}
                                </div>
                                <div className={classes.SupplierAddressLine}>{FinalAddress ? FinalAddress.ZipCity : ""}</div>
                                <div className={classes.SupplierAddressLine}>{FinalAddress ? FinalAddress.Country : ""}</div>
                            </div>
                        );
                        setSupplierAddressComponent(SupplierAddressComp);
                    } else {
                        setSupplierAddressComponent(null);
                    }
                }
            }

            setFormValues({...UpdatedFormValues, ...{vat_number: vat_number}});
        }
        if (field === "ref_PaymentTerms") {
            // InvoicePaymentDueDateIso
            // ManualInvoicePaymentDueDateIso
            let DuedateFinalIso;
            let SelectedId = SelectedDetails.Value;
            let InvoiceDate = FormValues.InvoiceSupplierDateIso;

            if (InvoiceDate) {
                let SelectedInfo = _.find(FieldOptions.ref_PaymentTerms, {value: SelectedId});

                if (SelectedInfo) {
                    let DueDaysAfterInvoice = SelectedInfo.DueDaysAfterInvoice;
                    let DueDayOfNextMonth = SelectedInfo.DueDayOfNextMonth;
                    if (DueDaysAfterInvoice || DueDaysAfterInvoice === 0) {
                        let InvoiceDateObj = parseISO(InvoiceDate);
                        let DueDate = add(InvoiceDateObj, {days: DueDaysAfterInvoice});
                        DuedateFinalIso = formatISO(DueDate);
                    } else if (DueDayOfNextMonth) {
                        //We need to get next month;
                        let InvoiceDateObj = parseISO(InvoiceDate);
                        let InvoiceDateFirstDayOfMonth = setDate(InvoiceDateObj, 1);
                        let NextMonthDateFirstDayOfMonth = add(InvoiceDateFirstDayOfMonth, {months: 1});
                        let LastDayOfNextMonthDate = lastDayOfMonth(NextMonthDateFirstDayOfMonth);
                        let LastDayOfNextMonth = getDate(LastDayOfNextMonthDate);
                        if (DueDayOfNextMonth > LastDayOfNextMonth) {
                            DuedateFinalIso = formatISO(LastDayOfNextMonthDate);
                        } else {
                            DuedateFinalIso = formatISO(setDate(NextMonthDateFirstDayOfMonth, DueDayOfNextMonth));
                        }
                    }
                }
            }

            setFormValues({...UpdatedFormValues, ...{InvoicePaymentDueDateIso: DuedateFinalIso}});
        }

        ValidateForm(field, SelectedValues);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        if (field === "accounting_Justification_needed") {
            if (value === false) {
                //We clear the justification provided
                //we clear the justification date

                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = null;
            } else {
                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = formatISO(new Date(Date.now()));
            }
        }

        if (field === "ManualInvoicePaymentDueDateIso") {
            if (value) {
                UpdateFormValues.InvoicePaymentDueDateIso = value;
                UpdateFormValues.ref_PaymentTerms = null;
            } else {
                UpdateFormValues.InvoicePaymentDueDateIso = null;
            }
        }

        if (field === "InvoiceSupplierDateIso") {
            //We recalculate everything
            if (value) {
                let ManualInvoicePaymentDueDateIso = FormValues.ManualInvoicePaymentDueDateIso;
                let PaymentTerms = FormValues.ref_PaymentTerms;
                let DuedateFinalIso;
                let SelectedId = FormValues.ref_PaymentTerms;
                let InvoiceDate = value;
                let SelectedInfo = _.find(FieldOptions.ref_PaymentTerms, {value: SelectedId});

                if (SelectedInfo) {
                    let DueDaysAfterInvoice = SelectedInfo.DueDaysAfterInvoice;
                    let DueDayOfNextMonth = SelectedInfo.DueDayOfNextMonth;
                    if (DueDaysAfterInvoice || DueDaysAfterInvoice === 0) {
                        let InvoiceDateObj = parseISO(InvoiceDate);
                        let DueDate = add(InvoiceDateObj, {days: DueDaysAfterInvoice});
                        DuedateFinalIso = formatISO(DueDate);
                        UpdateFormValues.InvoicePaymentDueDateIso = DuedateFinalIso;
                        UpdateFormValues.ManualInvoicePaymentDueDateIso = null;
                    } else if (DueDayOfNextMonth) {
                    }
                }
                // console.log("DuedateFinalIso", DuedateFinalIso);

                // CACA
            }
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);
        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (fieldName, filedetails) => {
        if (fieldName && filedetails) {
            // console.log("UpdateFormFiles", filedetails);
            let FullFilesUploader = [];
            for (var i = 0; i < filedetails.length; i++) {
                FullFilesUploader.push({
                    serverPath: filedetails[i].serverPath,
                    originalName: filedetails[i].originalName,
                    fileName: filedetails[i].fileName,
                    fileExtension: filedetails[i].fileExtension,
                    fileType: filedetails[i].fileType,
                    fileSize: filedetails[i].fileSize,
                    keywords: filedetails[i].keywords,
                });
            }

            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);

            if (FullFilesUploader && FullFilesUploader.length > 0) {
                let FileUrl;
                if (FullFilesUploader && FullFilesUploader.length > 0) {
                    FileUrl = FullFilesUploader[0].serverPath;
                }
                if (FileUrl) {
                    setPDFFileURL(FileUrl);
                    setShowViewPDF(true);
                } else {
                    setPDFFileURL(null);
                    setShowViewPDF(false);
                }
            } else {
                setPDFFileURL(null);
                setShowViewPDF(false);
            }
        }
    };

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function" || submitType === "SaveDraft") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES EXCEPTED FOR THE CREATE SELECT MULTI
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    if (key === "status_invoice") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{status_invoice: Ref_InvoiceStatus.current}};
                    } else if (key === "PaymentCondition") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.PaymentCondition, {value: FormValues.PaymentCondition});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{PaymentCondition: Entry}};
                    } else if (key === "PaymentMethod") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.PaymentMethod, {value: FormValues.PaymentMethod});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{PaymentMethod: Entry}};
                    } else {
                        if (Ref_ManualPaymentConfirmation.current) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{ManualPaymentDateIso: Ref_ManualPaymentConfirmation.current.ManualPaymentDateIso}};
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{ManualPaymentDone: Ref_ManualPaymentConfirmation.current.ManualPaymentDone}};
                        }
                        //Get the IntialValue
                        let InitialValue = InitialFormValues[key];
                        let Value = FormValues[key];
                        if (InitialValue !== Value && key) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                        }
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        if (field === "ref_Supplier") {
            if (value) {
                delete UpdatedFormErrors.ref_Supplier;
            }
        }
        if (field === "Description") {
            if (value) {
                delete UpdatedFormErrors.Description;
            }
        }
        if (field === "InvoicePaymentExpectedCommunication") {
            if (value) {
                delete UpdatedFormErrors.InvoicePaymentExpectedCommunication;
            } else {
                UpdatedFormErrors.InvoicePaymentExpectedCommunication = "Cannot be empty";
            }
        }
        if (field === "PaymentMethod") {
            if (value) {
                let Entry = _.find(FieldOptions.PaymentMethod, {value: value});
                Entry = Entry.label;
                if (Entry === "Bank Account Manual" || Entry === "Bank Account Automatic")
                    if (!FormValues.CounterpartyBankAccount_Id) {
                        UpdatedFormErrors.CounterpartyBankAccount_Id = "Bank Account Required";
                    } else {
                        delete UpdatedFormErrors.CounterpartyBankAccount_Id;
                    }
            }
        }
        if (field === "CounterpartyBankAccount_Id") {
            if (FormValues.PaymentMethod) {
                let Entry = _.find(FieldOptions.PaymentMethod, {value: FormValues.PaymentMethod});
                Entry = Entry.label;
                if (Entry === "Bank Account Manual" || Entry === "Bank Account Automatic")
                    if (!value) {
                        UpdatedFormErrors.CounterpartyBankAccount_Id = "Bank Account Required";
                    } else {
                        delete UpdatedFormErrors.CounterpartyBankAccount_Id;
                    }
            }
        }
        if (field === "InvoicePaymentDueDateIso") {
            if (value) {
                delete UpdatedFormErrors.ManualInvoicePaymentDueDateIso;
                delete UpdatedFormErrors.ref_PaymentTerms;
            }
        }
        if (field === "ref_PaymentTerms") {
            if (value) {
                delete UpdatedFormErrors.ManualInvoicePaymentDueDateIso;
                delete UpdatedFormErrors.ref_PaymentTerms;
            } else {
                if (FormValues.ManualInvoicePaymentDueDateIso) {
                    delete UpdatedFormErrors.ManualInvoicePaymentDueDateIso;
                    delete UpdatedFormErrors.ref_PaymentTerms;
                } else {
                    UpdatedFormErrors.ManualInvoicePaymentDueDateIso = "Chose Due Date or Payment Terms";
                    UpdatedFormErrors.ref_PaymentTerms = "Chose Due Date or Payment Terms";
                }
            }
        }

        if (field === "CounterpartyBankAccount_Id") {
            // console.log("CounterpartyBankAccount_Id", value);
            if (value) {
                delete UpdatedFormErrors.CounterpartyBankAccount_Id;
            } else {
                UpdatedFormErrors.CounterpartyBankAccount_Id = "Cannot be empty";
            }
        }

        if (field === "Invoices_File_Path") {
            console.log("Invoices_File_Path", value);
            // if (value) {
            //     delete UpdatedFormErrors.CounterpartyBankAccount_Id;
            // } else {
            //     UpdatedFormErrors.CounterpartyBankAccount_Id = "Cannot be empty";
            // }
        }

        if (Object.keys(UpdatedFormErrors).length > 0) {
            setFormErrors(UpdatedFormErrors);
            setInvalid(true);
        } else {
            setFormErrors({});
            setInvalid(false);
        }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/

    const HandleSaveDraft = () => {
        HandleSubmitForm("SaveDraft");
    };
    const HandlePost = () => {
        // Caca;
        //First we check if all fields are correct
        let ref_Supplier = FormValues.ref_Supplier;
        let InvoicePaymentExpectedCommunication = FormValues.InvoicePaymentExpectedCommunication;
        let PaymentCondition = FormValues.PaymentCondition;
        let PaymentMethod = FormValues.PaymentMethod;
        let InvoiceSupplierDateIso = FormValues.InvoiceSupplierDateIso;

        let InvoicePaymentDueDateIso = FormValues.InvoicePaymentDueDateIso;
        let CounterpartyBankAccount_Id = FormValues.CounterpartyBankAccount_Id;
        let CalcTotalVATIncluded = FormValues.CalcTotalVATIncluded;

        let InvoiceFile = FilesUploaders.Invoices_File_Path;

        let FinalErrors = {};

        if (InvoiceFile.length === 0) {
            console.log("InvoiceFile", InvoiceFile);
            FinalErrors.Invoices_File_Path = "FilesRequired";
        }

        if (!ref_Supplier) {
            FinalErrors.ref_Supplier = "Supplier required";
        }
        if (!InvoicePaymentExpectedCommunication) {
            FinalErrors.InvoicePaymentExpectedCommunication = "Communication required";
        }
        if (!PaymentCondition) {
            FinalErrors.PaymentCondition = "Cannot be empty";
        }
        if (!PaymentMethod) {
            FinalErrors.PaymentMethod = "Cannot be empty";
        } else {
            let Entry = _.find(FieldOptions.PaymentMethod, {value: PaymentMethod});
            Entry = Entry.label;
            if (Entry === "Bank Account Manual" || Entry === "Bank Account Automatic")
                if (!CounterpartyBankAccount_Id) {
                    FinalErrors.CounterpartyBankAccount_Id = "Bank Account Required";
                }
        }
        if (!InvoiceSupplierDateIso) {
            FinalErrors.InvoiceSupplierDateIso = "Invoice date required";
        }

        if (!InvoicePaymentDueDateIso) {
            FinalErrors.ManualInvoicePaymentDueDateIso = "Chose Due Date or Payment Terms";
            FinalErrors.ref_PaymentTerms = "Chose Due Date or Payment Terms";
        }

        if (!CalcTotalVATIncluded) {
            FinalErrors.CalcTotalVATIncluded = "Cannot be empty";
        }

        let ErrorsKeys = _.keys(FinalErrors);

        if (ErrorsKeys && ErrorsKeys.length > 0) {
            setFormErrors(FinalErrors);
        } else {
            setFormErrors({});
            //We need to change the Invoice status to Invoiced
            let UpdateFormValues = {status_invoice: "Invoiced"};
            setFormValues({...FormValues, ...UpdateFormValues});
            Ref_InvoiceStatus.current = "Invoiced";
            HandleSubmitForm("SaveDraft");
        }
        // console.log("FinalErrors", FinalErrors);
    };
    const HandleRevertToDraft = () => {
        //We need to change the Invoice status to posted
        setFormViewMode("Edit");
        let UpdateFormValues = {status_invoice: "Draft"};
        setFormValues({...FormValues, ...UpdateFormValues});
        Ref_InvoiceStatus.current = "Draft";
        HandleSubmitForm("SaveDraft");
        //We need to change the view mode to edit
    };

    const HandleAdminEdit = () => {
        //We need to change the Invoice status to posted
        setFormViewMode("Edit");
        setAdminMode(true);
        //We need to change the view mode to edit
    };
    const HandleGeneratePayment = () => {
        setShowPaymentReview(true);
    };
    const HandlePurchaseItemChanged = () => {
        HandleSubmitForm("SaveDraft");
    };

    const HandleCreateInitialInvoice = () => {
        HandleSubmitForm("SaveDraft");
    };
    const HandleSaveRefreshForm = () => {
        setShowInfoPart(false);
        HandleSubmitForm("SaveDraft");
    };
    const HandleViewPDF = () => {
        // console.log("HandleViewPDF");
        setShowViewPDF(!ShowViewPDF);
        setShowPDFViewerCardEditCard(true);

        // setShowViewPDF(!ShowViewPDF);
    };
    const HandleClosePaymentCard = (Args) => {
        if (Args) {
            let FinalInputs = {
                ManualPaymentDone: Args.ManualPaymentDone,
                ManualPaymentDateIso: Args.ManualPaymentDateIso,
            };
            Ref_ManualPaymentConfirmation.current = FinalInputs;
        }

        //We need to update the status of the Invoice to recalculate the different fields
        setShowPaymentReview(false);
        setShowInfoPart(false);
        HandleSubmitForm("SaveDraft");
    };
    const HandleReconcileInvoice = () => {
        setShowInvoiceReconciliation(true);
        console.log("Reconcile Invoice");
    };
    const HandleCloseInvoiceReconcilerCard = () => {
        setShowInvoiceReconciliation(false);
        HandleSubmitForm("RefreshForm");
    };

    const HandleUnreconcile = () => {
        setShowUnreconcile(true);
        HandleSubmitForm("SaveDraft");
    };
    const HandleUnreconcileCloseCard = () => {
        setShowUnreconcile(false);
    };
    const HandleRefreshFormAfterUpdate = () => {
        HandleSubmitForm("SaveDraft");
    };
    const HandleClosePDFViewerCard = () => {
        // console.log("Handle Close Edit Card");
        setShowPDFViewerCardEditCard(false);
    };
    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let FormTitle, PurchaseItemsTableComponent, InvoiceSummaryComponent, InvoiceFirstSaveComponent;

    if (InitialFormValues.ApiID === "new") {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitleMain}>
                    <div className={classes.FormTitle_Box}>
                        <div className={classes.FormTitle_Logo_Wrapper}>
                            <Contract IconSize="30px" SVGFillColor="white" />
                        </div>
                        <div className={classes.FormTitle_Title_Wrapper}>
                            <h1 className={classes.FormTitle_Title}>Purchase Invoice</h1>
                            <div className={classes.ActionButton}>
                                <Button Height="30px" onClick={HandleCreateInitialInvoice} disabled={Invalid || Pristine}>
                                    Create Invoice
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.FormTitleRightStatus}>
                        <div className={clsx(classes.ButtonArrowStart, classes.ButtonArrowStart_Active)}>Draft</div>
                        <div className={clsx(classes.ButtonArrowMiddle)}>Invoiced</div>
                        <div className={clsx(classes.ButtonArrowMiddle)}>Paid</div>
                        <div className={clsx(classes.ButtonArrowMiddle)}>Reconciled</div>
                        <div className={clsx(classes.ButtonArrowEnd)}>Accounted</div>
                    </div>
                </div>
            </div>
        );
        InvoiceFirstSaveComponent = <div>Click Create Invoice to continue</div>;
    } else {
        InvoiceFirstSaveComponent = null;
        let DraftClass, InvoicedClass, PaidClass, ReconciledClass, AccountedClass;
        let ActionButton1Component, ActionButton2Component, ActionButton3Component;
        if (InitialFormValues.status_invoice === "Draft") {
            //Draft
            DraftClass = clsx(classes.ButtonArrowStart, classes.ButtonArrowStart_Active);
            InvoicedClass = clsx(classes.ButtonArrowMiddle);
            PaidClass = clsx(classes.ButtonArrowMiddle);
            ReconciledClass = clsx(classes.ButtonArrowMiddle);
            AccountedClass = clsx(classes.ButtonArrowEnd);
            ActionButton1Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" disabled={Pristine} onClick={HandleSaveDraft}>
                    Save Draft
                </Button>
            );
            ActionButton2Component = (
                <Button
                    ClassExtendButton={classes.TitleButtons}
                    Width="90px"
                    Height="30px"
                    // disabled={Invalid || Pristine}
                    onClick={HandlePost}
                    // disabled={DisablePost}
                >
                    Post
                </Button>
            );
        } else if (InitialFormValues.status_invoice === "Invoiced") {
            //Invoiced
            DraftClass = clsx(classes.ButtonArrowStart);
            InvoicedClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
            PaidClass = clsx(classes.ButtonArrowMiddle);
            ReconciledClass = clsx(classes.ButtonArrowMiddle);
            AccountedClass = clsx(classes.ButtonArrowEnd);

            if (InitialFormValues.PaymentMethod === "01") {
                if (InitialFormValues.ManualBankPaymentActionsStatus === "Create Payment") {
                    ActionButton1Component = (
                        <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleRevertToDraft}>
                            Revert to Draft
                        </Button>
                    );
                    ActionButton2Component = (
                        <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleGeneratePayment}>
                            Pay
                        </Button>
                    );
                }
            } else {
                ActionButton1Component = (
                    <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleRevertToDraft}>
                        Revert to Draft
                    </Button>
                );
                ActionButton2Component = (
                    <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleReconcileInvoice}>
                        Reconcile
                    </Button>
                );
            }
        } else if (InitialFormValues.status_invoice === "Paid") {
            //Paid
            DraftClass = clsx(classes.ButtonArrowStart);
            InvoicedClass = clsx(classes.ButtonArrowMiddle);
            PaidClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
            ReconciledClass = clsx(classes.ButtonArrowMiddle);
            AccountedClass = clsx(classes.ButtonArrowEnd);
            ActionButton1Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="80px" Height="30px" onClick={HandleAdminEdit}>
                    Admin
                </Button>
            );
            ActionButton2Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="80px" Height="30px" onClick={HandleReconcileInvoice}>
                    Reconcile
                </Button>
            );
            if (AdminMode === true) {
                ActionButton3Component = (
                    <Button ClassExtendButton={classes.TitleButtons} Width="100px" Height="30px" onClick={HandleSaveDraft}>
                        Admin Save
                    </Button>
                );
            }
        } else if (InitialFormValues.status_invoice === "Reconciled") {
            //Reconciled
            DraftClass = clsx(classes.ButtonArrowStart);
            InvoicedClass = clsx(classes.ButtonArrowMiddle);
            PaidClass = clsx(classes.ButtonArrowMiddle);
            ReconciledClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
            AccountedClass = clsx(classes.ButtonArrowEnd);
            ActionButton1Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleUnreconcile}>
                    Unreconcile
                </Button>
            );
            // ActionButton2Component = (
            //     <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px">
            //         Send to Accountant
            //     </Button>
            // );
        } else if (InitialFormValues.status_invoice === "Accounted") {
            //Accounted
            DraftClass = clsx(classes.ButtonArrowStart);
            InvoicedClass = clsx(classes.ButtonArrowMiddle);
            PaidClass = clsx(classes.ButtonArrowMiddle);
            ReconciledClass = clsx(classes.ButtonArrowMiddle);
            AccountedClass = clsx(classes.ButtonArrowEnd, classes.ButtonArrowEnd_Active);
            ActionButton1Component = <Button Height="30px">Change Accounting Status</Button>;
        }
        let PDFIcon;
        if (PDFFileURL) {
            PDFIcon = (
                <div className={classes.PDFViewerIcon} onClick={HandleViewPDF} style={{display: `${PDFFileURL ? "flex" : "none"}`}}>
                    <div style={{display: `${PDFFileURL ? "flex" : "none"}`}}>
                        <PDF IconSize="30px" SVGFillColor="white" />
                    </div>
                </div>
            );
        } else {
            PDFIcon = <Contract IconSize="30px" SVGFillColor="white" />;
        }
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitleMain}>
                    <div className={classes.FormTitle_Box}>
                        <div className={classes.FormTitle_Logo_Wrapper}>{PDFIcon}</div>
                        <div className={classes.FormTitle_Title_Wrapper}>
                            <h1 className={classes.FormTitle_Title}>{InitialFormValues.InvoiceShortId}</h1>
                            <div className={classes.ActionButton}>{ActionButton1Component}</div>
                            <div className={classes.ActionButton}>{ActionButton2Component}</div>
                            <div className={classes.ActionButton}>{ActionButton3Component}</div>
                        </div>
                    </div>
                    <div className={classes.FormTitleRightStatus}>
                        <div className={DraftClass}>Draft</div>
                        <div className={InvoicedClass}>Invoiced</div>
                        <div className={PaidClass}>Paid</div>
                        <div className={ReconciledClass}>Reconciled</div>
                        <div className={AccountedClass}>Accounted</div>
                    </div>
                </div>
                <div className={classes.FormTitleRightInfo}>
                    <div className={classes.FormTitleRightStatus_RecordInfo}>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Line}>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Title}>CreatedAt :</div>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Value}>{InitialFormValues.createdAt} </div>
                        </div>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Line}>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Title}>LastUpdated :</div>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Value}>{InitialFormValues.updatedAt} </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        if (InitialFormValues.CalcTotalVATIncluded > 0) {
            let VATLines = InitialFormValues.TaxesDetails;

            let VATDetailsLines = [];
            if (VATLines && Array.isArray(VATLines)) {
                VATLines.forEach((elem) => {
                    let VatEntry = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>{elem.VATInvoiceLabel}:</div>
                            <div className={classes.InvoiceSummary_Line_Value}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(elem.Amount)}
                            </div>
                        </div>
                    );
                    VATDetailsLines.push(VatEntry);
                });
            }
            InvoiceSummaryComponent = (
                <div className={classes.InvoiceSummary}>
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title} style={{fontWeight: "600"}}>
                            Total VAT exc.:
                        </div>
                        <div className={classes.InvoiceSummary_Line_Value} style={{fontWeight: "600"}}>
                            {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(InitialFormValues.CalcTotalVATExcluded)}
                        </div>
                    </div>
                    {VATDetailsLines}
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title} style={{fontSize: "1rem", fontWeight: "600"}}>
                            Total:
                        </div>
                        <div className={classes.InvoiceSummary_Line_Value} style={{fontSize: "1rem", fontWeight: "600", borderTop: "1px solid grey"}}>
                            {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(InitialFormValues.CalcTotalVATIncluded)}
                        </div>
                    </div>
                </div>
            );
        } else {
            InvoiceSummaryComponent = <div className={classes.SummaryInvoiceError}>Please add Invoice Items</div>;
        }
        PurchaseItemsTableComponent = (
            <div className={classes.InvoiceDetailsSection}>
                <div className={classes.InvoiceDetailsTabNavWrapper}>
                    <InvoiceEditDetailsTabNav
                        InvoiceStatus={FormValues.status_invoice}
                        PurchaseInvoiceID={InitialFormValues.ApiID}
                        HandlePurchaseItemChanged={HandlePurchaseItemChanged}
                        FormViewMode={FormViewMode}
                        IsReconciled={InitialFormValues.IsReconciled}
						FormValuesParent={FormValues}
                    />
                </div>
            </div>
        );
    }

    let PaymentReviewComponent;
    if (ShowPaymentReview === true) {
        PaymentReviewComponent = (
            <InvoicePayReview
                InvoiceId={InitialFormValues.ApiID}
                PassedFormValues={FormValues}
                BankAccounts={FieldOptions.CounterpartyBankAccount_Id}
                Suppliers={FieldOptions.ref_Supplier}
                HandleClosePaymentCard={HandleClosePaymentCard}
            />
        );
    } else {
        PaymentReviewComponent = null;
    }

    let InvoiceReconciliationComponent;
    if (ShowInvoiceReconciliation === true) {
        InvoiceReconciliationComponent = (
            <InvoiceReconcileEdit
                InvoiceId={InitialFormValues.ApiID}
                PassedFormValues={FormValues}
                HandleCloseInvoiceReconcilerCard={HandleCloseInvoiceReconcilerCard}
                SupplierOptions={FieldOptions.ref_Supplier}
                PaymentMethodOptions={FieldOptions.PaymentMethod}
                FieldOptionsFromParent={FieldOptions}
                HandleRefreshFormAfterUpdate={HandleRefreshFormAfterUpdate}
            />
        );
    } else {
        InvoiceReconciliationComponent = null;
    }

    let UnreconcileComponent;

    if (ShowUnreconcile === true) {
        UnreconcileComponent = (
            <Unreconcile
                InvoiceId={InitialFormValues.ApiID}
                PassedFormValues={FormValues}
                HandleUnreconcileCloseCard={HandleUnreconcileCloseCard}
                HandleRefreshFormAfterUpdate={HandleRefreshFormAfterUpdate}
            />
        );
    } else {
        UnreconcileComponent = null;
    }
    let FinalFormComponent;

    let InvoiceInforPartComponent;
    if (FormDataInitReady === true && ShowInfoPart === true) {
        InvoiceInforPartComponent = (
            <InvoicePaymentInfoPart
                InvoiceStatus={InitialFormValues.status_invoice}
                InvoiceId={InitialFormValues.ApiID}
                CalcIsPaid={InitialFormValues.CalcIsPaid}
                CalcPaymentDateIso={InitialFormValues.CalcPaymentDateIso}
                ManualBankPaymentActionsStatus={InitialFormValues.ManualBankPaymentActionsStatus}
                PaymentMethod={InitialFormValues.PaymentMethod}
                InvoiceInitialFormData={InitialFormValues}
                HandleSaveRefreshForm={HandleSaveRefreshForm}
                BankAccounts={FieldOptions.CounterpartyBankAccount_Id}
                Suppliers={FieldOptions.ref_Supplier}
            />
        );
    } else {
        InvoiceInforPartComponent = null;
    }

    let AccountantStatusInformationComponent;
    if (FormDataInitReady === true && ShowInfoPart === true) {
        AccountantStatusInformationComponent = (
            <AccountantStatusInformation
                Invoices_File_Path={FilesUploaders.Invoices_File_Path}
                InvoiceStatus={InitialFormValues.status_invoice}
                InvoiceId={InitialFormValues.ApiID}
                Suppliers={FieldOptions.ref_Supplier}
                PassedFormValues={FormValues}
                HandleSaveRefreshForm={HandleSaveRefreshForm}
            />
        );
    } else {
        AccountantStatusInformationComponent = null;
    }

    let FileUploaderComponent;
    if (InitialFormValues.ApiID === "new") {
        // , display: `${InitialFormValues.ApiID === "new" ? "none" : null}`
        FileUploaderComponent = null;
    } else {
        FileUploaderComponent = (
            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                <div className={classes.FormField} style={{flex: "0 0 500px", maxWidth: "500px"}}>
                    <FileUploader
                        name="Invoices_File_Path"
                        label="Purchase Invoices"
                        APIUploadPath="/api/purchaseinvoices/uploadpurchaseinvoices"
                        APIDeletePath="/api/purchaseinvoices/deleteuploadedpurchaseinvoice"
                        UpdateFormFunction={UpdateFormFiles}
                        MaxFiles={10}
                        MaxSizeMB={10}
                        InitialFiles={FilesUploaders ? FilesUploaders.Invoices_File_Path : []}
                        UploadButton={{
                            IconStyle: {
                                IconSize: "30px",
                            },
                            Message: "Upload Invoices",
                            ClassExtendButton: classes.UploadButton,
                            Width: "140px",
                            Height: "40px",
                        }}
                        // AcceptedFileTypes={["*"]}
                        AcceptedFileTypes={["pdf"]}
                        MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                        BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                        SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                        WithDragAndDrop={true}
                        WithProgressBar={true}
                        ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                        WithDeleteConfirmation={true}
                        ReadOnly={FormViewMode === "View" ? true : false}
                        ParentErrors={FormErrors.Invoices_File_Path}
                    />
                </div>
            </div>
        );
    }
    let PDFSideViewerComponent;
    if (ShowPDFSideViewer === true) {
        PDFSideViewerComponent = (
            <div className={classes.FormPDFViewer} style={{display: `${ShowPDFSideViewer === true ? (ShowViewPDF === true ? "flex" : "none") : "none"}`}}>
                <PdfViewer FileUrl={PDFFileURL} />
            </div>
        );
    } else {
        if (ShowPDFViewerCardEditCard === true) {
            PDFSideViewerComponent = (
                <CardModal
                    CardID="ViewPDFModal"
                    // ParentCompRef={Ref_TabNavContent}
                    // TopBarContent={CardTitle}
                    CloseCard={HandleClosePDFViewerCard}
                    CloseOnClickOutside={true}
                    WithCloseButton={true}
                    // CardMaxWidth={CardMaxWidth}
                    // CardHeight="700px"
                    CardWindowPaddingVertical="10px"
                    CardWindowPaddingHorizontal="10px"
                    WithPerfectScrollbar={false}
                    // CardWindowPaddingVertical="50px"

                    ShowCard={ShowPDFViewerCardEditCard}
                    WindowBackgroundColor="rgba(0,0,0,0.3)"
                    CardBackgroundColor="white"
                    CardBackground="white"
                    ClassExtendCard={classes.ClassExtendCard}
                >
                    <PdfViewer FileUrl={PDFFileURL} />
                </CardModal>
            );
        } else {
            PDFSideViewerComponent = null;
        }
    }

    if (InitialFormValues)
        if (FormDataInitReady === true) {
            FinalFormComponent = (
                <div className={classes.FormWrapper}>
                    {FormTitle}
                    <div className={classes.FormFlexHorizontal}>
                        <div className={classes.FormMainScroll} style={{flex: `${ShowPDFSideViewer === true ? "0 0 1200px" : "1 0 auto"}`}}>
                            <PerfectScrollBarWrapper Active={true}>
                                <div className={classes.FormMainScroll_ScrollFlex}>
                                    <div className={classes.FormTopFlex}>
                                        <div className={classes.FormTopLeft}>
                                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                                <div className={classes.FormField} style={{flex: "0 0 500px"}}>
                                                    <FormCompontent
                                                        Name="ref_Supplier"
                                                        InitialValue={InitialFormValues.ref_Supplier ? InitialFormValues.ref_Supplier : null}
                                                        FormValue={FormValues.ref_Supplier ? FormValues.ref_Supplier : null}
                                                        OutputSelectedValues={(values) => handleSelectChange("ref_Supplier", values)}
                                                        SelectOptions={FieldOptions.ref_Supplier}
                                                        Component="Select"
                                                        Variant="Outlined"
                                                        Label="Supplier"
                                                        meta={{error: FormErrors.ref_Supplier, invalid: FormErrors.ref_Supplier ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("ref_Supplier", error)}
                                                        NoMessage={false}
                                                        MultiSelect={false}
                                                        SortSelectOptions="Asc"
                                                        SortIcons={false}
                                                        AllowCreateOption={false}
                                                        // CreateDeleteIfUnselected={}
                                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                                        LabelMaxWidth="110px"
                                                        LabelAlign="Left"
                                                        Required={true}
                                                        // Disabled={true}
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>

                                                <div className={classes.FormField} style={{flex: "0 0 280px", marginLeft: `${FormViewMode === "View" ? "40px" : "40px"}`}}>
                                                    <FormCompontent
                                                        Name="InvoiceSupplierDateIso"
                                                        InitialValue={InitialFormValues.InvoiceSupplierDateIso ? InitialFormValues.InvoiceSupplierDateIso : null}
                                                        FormValue={FormValues.InvoiceSupplierDateIso ? FormValues.InvoiceSupplierDateIso : null}
                                                        OutputValue={(values) => HandleUpdateFormValues("InvoiceSupplierDateIso", values)}
                                                        Component="DatePicker"
                                                        Variant="Outlined"
                                                        Label="Invoice Date"
                                                        NoMessage={false}
                                                        meta={{error: FormErrors.InvoiceSupplierDateIso, invalid: FormErrors.InvoiceSupplierDateIso ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("InvoiceSupplierDateIso", error)}
                                                        // LegendOffsetHeight="0"
                                                        // LegendOffsetWidth="0"
                                                        FieldMaxWidth="110px"
                                                        PickerDateType="Calendar"
                                                        PickerOnly={false}
                                                        InputFormat="dd/MM/yyyy"
                                                        LabelMaxWidth="120px"
                                                        LabelAlign="Left"
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                            </div>

                                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                                <div className={classes.AddressGroup} style={{flex: "0 0 510px"}}>
                                                    {SupplierAddressComponent}
                                                </div>
                                                <div className={classes.GroupedFields}>
                                                    <div
                                                        className={classes.FormField}
                                                        style={{flex: "0 0 380px", width: "280px", margin: "0px 0px 0px 40px", display: `${FormValues.ref_PaymentTerms ? "none" : "flex"}`}}
                                                    >
                                                        <FormCompontent
                                                            Name="ManualInvoicePaymentDueDateIso"
                                                            InitialValue={InitialFormValues.ManualInvoicePaymentDueDateIso ? InitialFormValues.ManualInvoicePaymentDueDateIso : null}
                                                            FormValue={FormValues.ManualInvoicePaymentDueDateIso ? FormValues.ManualInvoicePaymentDueDateIso : null}
                                                            OutputValue={(values) => HandleUpdateFormValues("ManualInvoicePaymentDueDateIso", values)}
                                                            Component="DatePicker"
                                                            Variant="Outlined"
                                                            Label="Invoice Due Date"
                                                            NoMessage={false}
                                                            meta={{error: FormErrors.ManualInvoicePaymentDueDateIso, invalid: FormErrors.ManualInvoicePaymentDueDateIso ? true : false}}
                                                            OutputError={(error) => HandleUpdateErrors("ManualInvoicePaymentDueDateIso", error)}
                                                            DisplayMessageIn="Popup"
                                                            // LegendOffsetHeight="0"
                                                            // LegendOffsetWidth="0"
                                                            PickerDateType="Calendar"
                                                            PickerOnly={false}
                                                            InputFormat="dd/MM/yyyy"
                                                            LabelMaxWidth="120px"
                                                            LabelAlign="Left"
                                                            FieldMaxWidth="110px"
                                                            ReadOnly={FormViewMode === "View" ? true : false}
                                                        />
                                                    </div>
                                                    <div
                                                        className={classes.FormField}
                                                        style={{
                                                            flex: "0 0 500px",
                                                            width: "280px",
                                                            margin: "0px 0px 0px 40px",
                                                            display: `${FormValues.ref_PaymentTerms && FormValues.InvoicePaymentDueDateIso ? "flex" : "none"}`,
                                                        }}
                                                    >
                                                        <FormCompontent
                                                            Name="InvoicePaymentDueDateIso"
                                                            InitialValue={InitialFormValues.InvoicePaymentDueDateIso ? InitialFormValues.InvoicePaymentDueDateIso : null}
                                                            FormValue={FormValues.InvoicePaymentDueDateIso ? FormValues.InvoicePaymentDueDateIso : null}
                                                            OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentDueDateIso", values)}
                                                            Component="DatePicker"
                                                            Variant="Outlined"
                                                            Label="Invoice Due Date"
                                                            NoMessage={false}
                                                            meta={{error: FormErrors.InvoicePaymentDueDateIso, invalid: FormErrors.InvoicePaymentDueDateIso ? true : false}}
                                                            OutputError={(error) => HandleUpdateErrors("InvoicePaymentDueDateIso", error)}
                                                            DisplayMessageIn="Popup"
                                                            // LegendOffsetHeight="0"
                                                            // LegendOffsetWidth="0"
                                                            // FieldMaxWidth="350px"
                                                            PickerDateType="Calendar"
                                                            PickerOnly={false}
                                                            InputFormat="dd/MM/yyyy"
                                                            LabelMaxWidth="120px"
                                                            FieldMaxWidth="110px"
                                                            LabelAlign="Left"
                                                            ReadOnly={true}
                                                        />
                                                    </div>
                                                    <div
                                                        className={classes.FormField}
                                                        style={{flex: "1 0 280px", margin: "7px 0px 7px 40px", display: `${FormValues.ManualInvoicePaymentDueDateIso ? "none" : "flex"}`}}
                                                    >
                                                        <FormCompontent
                                                            Name="ref_PaymentTerms"
                                                            InitialValue={InitialFormValues.ref_PaymentTerms ? InitialFormValues.ref_PaymentTerms : null}
                                                            FormValue={FormValues.ref_PaymentTerms ? FormValues.ref_PaymentTerms : null}
                                                            OutputSelectedValues={(values) => handleSelectChange("ref_PaymentTerms", values)}
                                                            SelectOptions={FieldOptions.ref_PaymentTerms}
                                                            meta={{error: FormErrors.ref_PaymentTerms, invalid: FormErrors.ref_PaymentTerms ? true : false}}
                                                            OutputError={(error) => HandleUpdateErrors("ref_PaymentTerms", error)}
                                                            Component="Select"
                                                            Variant="Outlined"
                                                            Label="Payment Terms"
                                                            NoMessage={false}
                                                            MultiSelect={false}
                                                            SortSelectOptions="Asc"
                                                            SortField="value"
                                                            // SortIcons={true}
                                                            AllowCreateOption={false}
                                                            Clearable={true}
                                                            // CreateDeleteIfUnselected={}
                                                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                                            LabelMaxWidth="120px"
                                                            LabelAlign="Left"
                                                            FieldMaxWidth="140px"
                                                            // Required={true}
                                                            ReadOnly={FormViewMode === "View" ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start", display: `${FormValues.ref_Supplier ? "flex" : "none"}`}}>
                                                <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                                    <FormCompontent
                                                        Name="vat_number"
                                                        InitialValue={InitialFormValues.vat_number ? InitialFormValues.vat_number : null}
                                                        FormValue={FormValues.vat_number ? FormValues.vat_number : null}
                                                        OutputValue={(values) => HandleUpdateFormValues("vat_number", values)}
                                                        Component="Input"
                                                        Variant="Outlined"
                                                        Label="VAT number"
                                                        NoMessage={false}
                                                        meta={{error: FormErrors.vat_number, invalid: FormErrors.vat_number ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("vat_number", error)}
                                                        ReadOnly={true}
                                                        FieldMaxWidth="240px"
                                                        LabelMaxWidth="110px"
                                                        LabelAlign="Left"
                                                    />
                                                </div>
                                                <div className={classes.FormField} style={{flex: "0 0 370px", margin: "0px 0px 0px 20px"}}>
                                                    <FormCompontent
                                                        Name="PaymentMethod"
                                                        InitialValue={InitialFormValues.PaymentMethod ? InitialFormValues.PaymentMethod : null}
                                                        FormValue={FormValues.PaymentMethod ? FormValues.PaymentMethod : null}
                                                        OutputSelectedValues={(values) => handleSelectChange("PaymentMethod", values)}
                                                        SelectOptions={FieldOptions.PaymentMethod}
                                                        Component="Select"
                                                        Variant="Outlined"
                                                        Label="Payment Method"
                                                        Clearable={false}
                                                        NoMessage={false}
                                                        MultiSelect={false}
                                                        SortSelectOptions="Asc"
                                                        SortField="value"
                                                        FieldMaxWidth="250px"
                                                        // SortIcons={true}
                                                        AllowCreateOption={false}
                                                        // CreateDeleteIfUnselected={}
                                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                                        LabelMaxWidth="130px"
                                                        LabelAlign="Left"
                                                        // Required={true}
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                            </div>

                                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                                <div className={classes.FormField} style={{flex: "0 0 370px"}}>
                                                    <FormCompontent
                                                        Name="PaymentCondition"
                                                        InitialValue={InitialFormValues.PaymentCondition ? InitialFormValues.PaymentCondition : null}
                                                        FormValue={FormValues.PaymentCondition ? FormValues.PaymentCondition : null}
                                                        OutputSelectedValues={(values) => handleSelectChange("PaymentCondition", values)}
                                                        SelectOptions={FieldOptions.PaymentCondition}
                                                        Component="Select"
                                                        Variant="Outlined"
                                                        Label="Payment"
                                                        NoMessage={false}
                                                        MultiSelect={false}
                                                        SortSelectOptions="Asc"
                                                        SortField="value"
                                                        // SortIcons={true}
                                                        AllowCreateOption={false}
                                                        // CreateDeleteIfUnselected={}
                                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                                        LabelMaxWidth="110px"
                                                        LabelAlign="Left"
                                                        FieldMaxWidth="240px"
                                                        // Required={true}
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                                <div className={classes.FormField} style={{flex: "0 0 350px", margin: "0px 0px 0px 50px"}}>
                                                    <FormCompontent
                                                        Name="InvoicePaymentExpectedCommunication"
                                                        InitialValue={InitialFormValues.InvoicePaymentExpectedCommunication ? InitialFormValues.InvoicePaymentExpectedCommunication : null}
                                                        FormValue={FormValues.InvoicePaymentExpectedCommunication ? FormValues.InvoicePaymentExpectedCommunication : null}
                                                        OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentExpectedCommunication", values)}
                                                        Component="Input"
                                                        Variant="Outlined"
                                                        Label="Communication"
                                                        NoMessage={false}
                                                        meta={{error: FormErrors.InvoicePaymentExpectedCommunication, invalid: FormErrors.InvoicePaymentExpectedCommunication ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("InvoicePaymentExpectedCommunication", error)}
                                                        DisplayMessageIn="Popup"
                                                        FieldMaxWidth="250px"
                                                        // Required={true}
                                                        // LegendOffsetHeight="0"
                                                        // LegendOffsetWidth="0"
                                                        LabelMaxWidth="130px"
                                                        LabelAlign="Left"
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                                <div
                                                    className={classes.FormField}
                                                    style={{flex: "0 0 400px", display: `${FormValues.PaymentMethod === "01" || FormValues.PaymentMethod === "02" ? "flex" : "none"}`}}
                                                >
                                                    <FormCompontent
                                                        Name="CounterpartyBankAccount_Id"
                                                        InitialValue={InitialFormValues.CounterpartyBankAccount_Id ? InitialFormValues.CounterpartyBankAccount_Id : null}
                                                        FormValue={FormValues.CounterpartyBankAccount_Id ? FormValues.CounterpartyBankAccount_Id : null}
                                                        OutputSelectedValues={(values) => handleSelectChange("CounterpartyBankAccount_Id", values)}
                                                        SelectOptions={FieldOptions.CounterpartyBankAccount_Id}
                                                        meta={{error: FormErrors.CounterpartyBankAccount_Id, invalid: FormErrors.CounterpartyBankAccount_Id ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("CounterpartyBankAccount_Id", error)}
                                                        DisplayMessageIn="Popup"
                                                        Component="Select"
                                                        Variant="Outlined"
                                                        Label="Bank Account"
                                                        NoMessage={false}
                                                        MultiSelect={false}
                                                        SortSelectOptions="Asc"
                                                        SortField="value"
                                                        Clearable={false}
                                                        // SortIcons={true}
                                                        AllowCreateOption={false}
                                                        // CreateDeleteIfUnselected={}
                                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                                        LabelMaxWidth="110px"
                                                        FieldMaxWidth="240px"
                                                        LabelAlign="Left"
                                                        // Required={true}
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                                <div
                                                    className={classes.FormField}
                                                    style={{flex: "0 0 500px", display: `${FormValues.PaymentMethod === "03" || FormValues.PaymentMethod === "04" ? "flex" : "none"}`}}
                                                >
                                                    <FormCompontent
                                                        Name="CreditCard_Id"
                                                        InitialValue={InitialFormValues.CreditCard_Id ? InitialFormValues.CreditCard_Id : null}
                                                        FormValue={FormValues.CreditCard_Id ? FormValues.CreditCard_Id : null}
                                                        OutputSelectedValues={(values) => handleSelectChange("CreditCard_Id", values)}
                                                        SelectOptions={FieldOptions.CreditCard_Id}
                                                        meta={{error: FormErrors.CreditCard_Id, invalid: FormErrors.CreditCard_Id ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("CreditCard_Id", error)}
                                                        DisplayMessageIn="Popup"
                                                        Component="Select"
                                                        Variant="Outlined"
                                                        Label="Credit Card"
                                                        NoMessage={false}
                                                        MultiSelect={false}
                                                        SortSelectOptions="Asc"
                                                        SortField="value"
                                                        Clearable={false}
                                                        // SortIcons={true}
                                                        AllowCreateOption={false}
                                                        // CreateDeleteIfUnselected={}
                                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                                        LabelMaxWidth="160px"
                                                        LabelAlign="Left"
                                                        // Required={true}
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                                <div
                                                    className={classes.FormField}
                                                    style={{
                                                        flex: "0 0 350px",
                                                        marginLeft: `${
                                                            FormValues.PaymentMethod === "01" ||
                                                            FormValues.PaymentMethod === "02" ||
                                                            FormValues.PaymentMethod === "03" ||
                                                            FormValues.PaymentMethod === "04"
                                                                ? "50px"
                                                                : "560px"
                                                        }`,
                                                    }}
                                                >
                                                    <CheckBox
                                                        Inputname="IsInvoicePaymentExpectedCommunicationStructured"
                                                        label="Structured"
                                                        initialvalue={InitialFormValues.IsInvoicePaymentExpectedCommunicationStructured === true ? true : false}
                                                        curvalue={FormValues.IsInvoicePaymentExpectedCommunicationStructured}
                                                        ShareInputValue={(value) => HandleUpdateFormValues("IsInvoicePaymentExpectedCommunicationStructured", value)}
                                                        disabled={FormViewMode === "View" ? true : false}
                                                        LabelMaxWidth="90px"
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.FormMultilineFlex}>
                                                <div className={classes.FormField} style={{flex: "0 0 805px"}}>
                                                    <FormCompontent
                                                        Name="description"
                                                        InitialValue={InitialFormValues.Description ? InitialFormValues.Description : null}
                                                        FormValue={FormValues.Description ? FormValues.Description : null}
                                                        OutputValue={(values) => HandleUpdateFormValues("Description", values)}
                                                        Component="Input"
                                                        Variant="Outlined"
                                                        Label="Description"
                                                        NoMessage={false}
                                                        meta={{error: FormErrors.Description, invalid: FormErrors.Description ? true : false}}
                                                        OutputError={(error) => HandleUpdateErrors("Description", error)}
                                                        // LegendOffsetHeight="0"
                                                        // LegendOffsetWidth="0"
                                                        LabelMaxWidth="110px"
                                                        multiline={true}
                                                        RowsToDisplay="2"
                                                        MaxRows="4"
                                                        LabelAlign="Left"
                                                        // Required={false}
                                                        ReadOnly={FormViewMode === "View" ? true : false}
                                                    />
                                                </div>
                                            </div>
                                           

                                            {FileUploaderComponent}
                                        </div>
                                        <div className={classes.FormTopRight}>
                                            <div className={classes.InvoiceSummaryBox} style={{display: FormValues.ApiID === "new" ? "none" : "flex"}}>
                                                <div className={classes.InvoiceSummary_Line} style={{borderBottom: "1px solid grey", fontSize: "0.85rem", flex: "0 0 auto"}}>
                                                    Invoice Summary
                                                </div>
                                                {InvoiceSummaryComponent}
                                            </div>
                                            {InvoiceInforPartComponent}
                                            {AccountantStatusInformationComponent}
                                        </div>
                                    </div>
                                    {PurchaseItemsTableComponent}
                                    {InvoiceFirstSaveComponent}

                                    <FormDev
                                        DevMode={DevMode}
                                        FormValues={FormValues}
                                        InitialFormValues={InitialFormValues}
                                        FieldOptions={FieldOptions}
                                        FieldSubOptionsFull={FieldSubOptionsFull}
                                        FilesUploaders={FilesUploaders}
                                        FormErrors={FormErrors}
                                        NewFieldOptions={NewFieldOptions}
                                        PristineDetails={PristineDetails}
                                    />
                                    {/* Button Bar */}
                                    <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                                        {`${FormErrorMain ? FormErrorMain : null}`}
                                    </div>
                                </div>

                                {/* Button Bar */}
                            </PerfectScrollBarWrapper>
                        </div>
                        {PDFSideViewerComponent}

                        {PaymentReviewComponent}
                        {InvoiceReconciliationComponent}
                        {UnreconcileComponent}
                    </div>
                </div>
            );
        } else {
            FinalFormComponent = null;
        }
    return <React.Fragment>{FinalFormComponent}</React.Fragment>;
});
export default Component;
