import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "5px",
        justifyContent: "flex-start",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };
    const GroupCellRenderer = (params) => {
        let RowIndex = params.rowIndex;
        let RowData = params.api.getRowNode(RowIndex).data;

        if (RowData) {
            let BankStatement = RowData.ref_BankStatement;
            console.log("BankStatement", BankStatement);
            let StatementRef, TotalAmount;
            if (BankStatement) {
                if (BankStatement.calc_RefBankStatement) {
                    StatementRef = BankStatement.calc_RefBankStatement;
                }
                if (BankStatement.Calc_TotalAmount) {
                    TotalAmount = BankStatement.Calc_TotalAmount;
                }
            }

            return <div>{`${StatementRef} - Unreconciled - Total Amount : ${TotalAmount}`}</div>;
        } else {
            return <div>Coucou</div>;
        }
    };

    const AccountInfoCellRender = (params) => {
        if (params) {
            let RowData = params.data;
            if (RowData) {
                let myCompany_bankAccount_number = RowData.myCompany_bankAccount_number;
                let credit_card_number = RowData.credit_card_number;
                if (myCompany_bankAccount_number) {
                    return myCompany_bankAccount_number;
                } else {
                    return credit_card_number;
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    };
    const TableColumns = [
        {
            field: "ref_BankStatement.calc_RefBankStatement",
            headerName: "BankStatement",
            headerClass: "ag-CenterHeader",
            width: 160,
            filter: false,
            // sort: "asc",
            // sortIndex: 2,
            // sortingOrder: ["asc", "desc"],
            rowGroup: true,
            // showRowGroup : true,
            hide: true,
        },
        {
            field: "transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
        },
        {
            field: "calc_transaction_date_MMM",
            headerName: "Month",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            // sortIndex: 2,
            // sort: "desc",
            // Type: "Date",
            aggFunc: "first",
        },
        {
            field: "calc_transaction_date_yyyy",
            headerName: "Year",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            // Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
            // sort: "asc",
            // sortIndex: 1,
            // sort: "desc",
            sortingOrder: ["asc", "desc"],
            aggFunc: "first",
        },
        {
            field: "banking_amount_transaction",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            aggFunc: "sum",
        },
        {
            field: "calc_RefTransaction",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 160,
            filter: true,
            // Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "communication",
            headerName: "communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: "transaction_source",
            headerName: "Source",
            headerClass: "ag-CenterHeader",
            width: 170,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // Type: "Currency",
            aggFunc: "first",
        },
        {
            field: "myCompany_bankAccount_number",
            headerName: "Account Number",
            headerClass: "ag-CenterHeader",
            cellRenderering: AccountInfoCellRender,
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // Type: "Currency",
			aggFunc: "first",
        },
    ];
    const HandleRowSelectChange = (SelectedRows) => {
        console.log("SelectedRows", SelectedRows);
        // if (SelectedRows.length === 0) {
        //     setSelectedRows(null);
        // } else {
        //     setSelectedRows(SelectedRows);
        // }
    };
    const TableOptions = {
        Selection: {
            Multiple: true,
            GroupOnly: true,
            // WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },
        Grouping: {
            Type: "GroupRows",
            HeaderName: "Statement",
            MinWidth: 250,
        },
		SideBar :{

		},
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
    };

    let URLAbsolute = {
        ALL: `${ProdURL}/api/companyaccount/transactionswithbankstatement`,
        QUERY: "",
        DELETEONE: "",
        DELETEMANY: "",
        PUT: "",
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <div className={classes.TableWrapper}>
                <AGGridTable
                    URLAbsolute={URLAbsolute}
                    TableColumns={TableColumns}
                    MainDataSource="Transactions"
                    // RowActionButtons={TableRowButtons}
                    ColumnButtonStyle={ColumnButtonStyle}
                    TableOptions={TableOptions}
                    // ReloadData={ReloadTableData}
                    // SyncReloadDataStateFunction={setReloadTableData}
                    // TotalRow={TotalRow}
                />
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
