import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import CardModal from "@artibulles-cis/react/CardModal";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import CustomerEditAddress from "./CustomerEditAddress";
import {AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "250px",
        margin: "0 auto",
        justifyContent: "center",
    },
    ClassExtendTabContent: {
        padding: "0px 5px 0px 5px",
        boxSizing: "border-box",
        // maxWidth: "1200px",
    },
    ClassExtendButton: {
        fontSize: "0.8rem",
        margin: "0px 0px",
        padding: "0px 5px",
		justifyContent : "flex-start"
    },
}));

const CustomerEditBankAccountTable = React.memo(function CustomerEditBankAccountTable(props) {
    const {CustomerID} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const HandleEditRow = (Id, RowData) => {
		console.log("Id",Id)
        setShowViewCard(true);
        setViewCardId(Id);
    };

    const DeleteCondition = (args) => {
        return false
    };
    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };
    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },

        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            // OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];

    const TableColumns = [
        {
            field: "company",
            headerName: "Address Type",
            Lookup: {LookupField: "ref_address_type", Source: "CompanyAddressTypes", ReturnField: "name_EN"},
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "company",
            headerName: "Country",
            Lookup: {LookupField: "ref_country", Source: "Countries", ReturnField: "country_name_EN"},
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "region_state",
            headerName: "Region",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "city",
            headerName: "City",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "zip_code",
            headerName: "Zip",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "street_line1",
            headerName: "Address Line 1",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "is_defaultAddress",
            headerName: "Default",
            headerClass: "ag-CenterHeader",
            flex: 1,
            Type: "Boolean",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
    ];
    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        SupressMenu: true,
        WrapText: true,
        WrapHeader: false,
        HeaderHeight: 30,
        RowHeight: 24,
        RowFontSize: "13px",
        HeaderFontSize: "14px",
        StatusBar: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [CreateNewOnly, setCreateNewOnly] = useState(false);

    const [APICallType, setAPICallType] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);

                    if (Init === false) {
                        if (Data.CompanyAddresses && Array.isArray(Data.CompanyAddresses) && Data.CompanyAddresses.length > 0) {
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        } else {
                            //There is no bank account, we display a add button

                            setCreateNewOnly(true);
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "deleteone") {
                        toast.success(`Entry deleted`, {autoClose: true});
                        setInit(false);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="CompanyAddresses"
                Data={Ref_LocaleData.current}
                TableColumns={TableColumns}
                MainDataSource="CompanyAddresses"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current, Init]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (Init === false) {
            const ApiCall = {url: `${ProdURL}/api/mycompany/company/companyaddresses/${CustomerID}`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true && CreateNewOnly === false) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        setCreateNewOnly(false);
        setTimeout(() => {
            setInit(false);
        }, 50);
    };

    const HanldeCreateNewAddress = () => {
        setShowViewCard(true);
        setViewCardId("new");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1200px"
                CardHeight="800px"
                // CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <CustomerEditAddress Id={ViewCardId}  CompanyId={CustomerID} CloseCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    var FinalTableComponent;

    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = (
                <div>
                    <Button
                        Width="120px"
                        Height="22px"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{fontSize: "0.8rem", margin: "0px 0px", justifyContent: "space-between"}}
                        onClick={HanldeCreateNewAddress}
                    >
                        <AddCircle SVGFillColor="white" IconSize="18" />
                        Add Address
                    </Button>

                    <div className={classes.TableWrapper}>{TableComponent}</div>
                </div>
            );
        } else {
            FinalTableComponent = (
                <Button
                        Width="120px"
                        Height="22px"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{fontSize: "0.8rem", margin: "0px 0px", justifyContent: "space-between"}}
                        onClick={HanldeCreateNewAddress}
                    >
                        <AddCircle SVGFillColor="white" IconSize="18" />
                        Add Address
                    </Button>
            );
        }
    } else {
        FinalTableComponent = "There is no data";
    }

    return (
        <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent}>
            {ModalCardView}
            <div className={classes.DailytButtonBar}>
                <div className={classes.FormField} style={{flex: "1 1 250px", maxWidth: "250px", width: "250px"}}></div>
            </div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default CustomerEditBankAccountTable;
