import React, {useEffect, useState, useRef, useCallback} from "react";
import {CheckBoxCircularFilled, RotateRight, WarningTriangleYellow, WarningTriangleRed} from "@artibulles-cis/react-icons";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";

import InvoicePaymentInfoPartConfirmManualDeletion from "./InvoicePaymentInfoPartConfirmManualDeletion";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import {format, parseISO, differenceInCalendarDays, startOfDay} from "date-fns";
import {createUseStyles, useTheme} from "react-jss";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PaymentBox: {
        flex: "0 1 auto",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        margin: "10px 0px 10px 10px",

        borderRadius: "5px",
        fontSize: "0.9rem",
        background: "rgba(66, 165, 245,0.3)",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    PaymentBox_TopBar: {
        flex: "0 0 30px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid rgba(200,200,200,0.8)",
        // borderBottom: "1px solid rgba(66, 165, 245,0.2)",
    },
    PaymentBox_TopBarLeft: {
        flex: "1 1 auto",
        display: "flex",
        padding: "0px 5px",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "0.8rem",
    },
    PaymentBox_TopBarActions: {
        flex: "1 1 auto",
        display: "flex",
        padding: "0px 5px",
        justifyContent: "center",
        alignItems: "center",
    },
    PaymentBox_TopBarRight: {
        flex: "0 0 30px",
        display: "flex",
        padding: "0px 5px",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    PaymentBox_Content: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        padding: "5px 5px",
    },
    InvoiceSummary: {
        display: "flex",
        width: "300px",
        alignSelf: "flex-start",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "0 0 90px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
}));

const Component = React.memo(function Component(props) {
    const {InvoiceStatus, InvoiceInitialFormData, InvoiceId, CalcIsPaid, CalcPaymentDateIso, ManualBankPaymentActionsStatus, PaymentMethod, HandleSaveRefreshForm, BankAccounts, Suppliers} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCall, setApiCall] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [PaymentInfo, setPaymentInfo] = useState(null);
    const [ShowCardDeleteManualFromBank, setShowCardDeleteManualFromBank] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (!ApiCall) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        let PontoInvoiceTransaction = Data.PontoInvoiceTransaction;
                        let PontoTransaction = PontoInvoiceTransaction.PontoTransaction;
                        console.log("PontoInvoiceTransaction", PontoInvoiceTransaction);
                        console.log("PontoTransaction", PontoTransaction);
                        console.log("ManualBankPaymentActionsStatus", ManualBankPaymentActionsStatus);

                        let PontoTransactionId;
                        let SignUrl;
                        if (PontoTransaction) {
                            PontoTransactionId = PontoTransaction.PontoTransactionId;
                            if (!PontoTransactionId) {
                                PontoTransactionId = null;
                            }
                            console.log("PontoTransactionId", PontoTransactionId);
                            SignUrl = PontoTransaction.PontoSignUrl;
                            if (!SignUrl) {
                                SignUrl = null;
                            }
                            console.log("SignUrl", SignUrl);
                        }
                        let IsBulk = PontoInvoiceTransaction.IsBulk;

                        let TopBarLeftTitle,
                            TopBarActions = [],
                            TopBarIcon,
                            Lines = [];

                        if (ManualBankPaymentActionsStatus === "Bulk To Create") {
                            //TransactionIonvoice was created but not yet posted as bulk to Ponto
                            TopBarLeftTitle = "Post Bulk Transactions";
                            let InvalidTransactionDate = false;
                            if (differenceInCalendarDays(parseISO(PontoInvoiceTransaction.RequestedExecutionDateIso), Date.now()) < 1) {
                                InvalidTransactionDate = true;
                            }
                            if (InvalidTransactionDate) {
                                TopBarIcon = <WarningTriangleRed IconSize="25px" />;
                                let ButtonAction = (
                                    <Button Background="red" Height="22px" Width="60px" ClassExtendButton={classes.Button}>
                                        Delete
                                    </Button>
                                );
                                TopBarActions.push(ButtonAction);
                            } else {
                                TopBarIcon = <WarningTriangleYellow IconSize="25px" />;
                                let ButtonAction = (
                                    <Button Background="green" Height="20px" Width="50px" ClassExtendButton={classes.Button}>
                                        Edit
                                    </Button>
                                );
                                TopBarActions.push(ButtonAction);
                                ButtonAction = (
                                    <Button Background="red" Height="20px" Width="50px" ClassExtendButton={classes.Button}>
                                        Delete
                                    </Button>
                                );
                                TopBarActions.push(ButtonAction);
                            }

                            let Line = (
                                <div className={classes.InvoiceSummary_Line}>
                                    <div
                                        className={classes.InvoiceSummary_Line_Title}
                                        //  style={{fontWeight: "600"}}
                                    >
                                        Creation Date :
                                    </div>
                                    <div
                                        className={classes.InvoiceSummary_Line_Value}
                                        // style={{fontWeight: "600"}}
                                    >
                                        {format(parseISO(PontoInvoiceTransaction.DateCreatedIso), "dd-MMM-yyyy")}
                                    </div>
                                </div>
                            );
                            Lines.push(Line);
                            Line = (
                                <div className={classes.InvoiceSummary_Line}>
                                    <div
                                        className={classes.InvoiceSummary_Line_Title}
                                        //  style={{fontWeight: "600"}}
                                    >
                                        Payment Date :
                                    </div>
                                    <div
                                        className={classes.InvoiceSummary_Line_Value}
                                        // style={{fontWeight: "600"}}
                                    >
                                        {format(parseISO(PontoInvoiceTransaction.RequestedExecutionDateIso), "dd-MMM-yyyy")}
                                    </div>
                                </div>
                            );
                            Lines.push(Line);
                            Line = (
                                <div className={classes.InvoiceSummary_Line}>
                                    <div
                                        className={classes.InvoiceSummary_Line_Title}
                                        //  style={{fontWeight: "600"}}
                                    >
                                        Days to sign :
                                    </div>
                                    <div
                                        className={classes.InvoiceSummary_Line_Value}
                                        // style={{fontWeight: "600"}}
                                    >
                                        {differenceInCalendarDays(parseISO(PontoInvoiceTransaction.RequestedExecutionDateIso), Date.now())}
                                    </div>
                                </div>
                            );
                            Lines.push(Line);
                            Line = (
                                <div className={classes.InvoiceSummary_Line}>
                                    <div
                                        className={classes.InvoiceSummary_Line_Title}
                                        //  style={{fontWeight: "600"}}
                                    >
                                        Amount :
                                    </div>
                                    <div
                                        className={classes.InvoiceSummary_Line_Value}
                                        // style={{fontWeight: "600"}}
                                    >
                                        {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoInvoiceTransaction.Amount)}
                                    </div>
                                </div>
                            );
                            Lines.push(Line);
                        } else if (ManualBankPaymentActionsStatus === "Sign") {
                            TopBarLeftTitle = "Sign Payment";

                            if (IsBulk === true) {
                            } else {
                                //SINGLE PONTO TRANSACTION
                                let InvalidTransactionDate = false;

                                let DiffCalendarDays = differenceInCalendarDays(parseISO(PontoTransaction.RequestedExecutionDateIso), Date.now());

                                if (DiffCalendarDays < 0) {
                                    InvalidTransactionDate = true;
                                }
                                if (InvalidTransactionDate) {
                                    TopBarIcon = <WarningTriangleRed IconSize="25px" />;
                                    let ButtonAction = (
                                        <Button Background="red" Height="22px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HandleDeleteSingleTransaction(PontoTransactionId)}>
                                            Delete
                                        </Button>
                                    );
                                    TopBarActions.push(ButtonAction);
                                } else {
                                    TopBarIcon = (
                                        <Button Height="25px" Width="25px" ClassExtendButton={classes.Button} onClick={() => HandleRefreshSingleTransaction(PontoTransactionId)}>
                                            <RotateRight
                                                IconSize="22px"
                                                SVGStrokeWidth="20px"
                                                // Frame="rounded"
                                                SVGFillColor="white"
                                                SVGFillHoverColor="white"
                                                // FrameStrokeWidth="3px"
                                                // FrameStrokeColor="rgb(66, 165, 245)"
                                                // FrameFillColor="rgb(66, 165, 245)"
                                                // FrameFillColorHover="rgb(66, 165, 245)"
                                                // FrameStrokeColorHover="rgb(66, 165, 245)"
                                                // InnerPadding="20px"
                                            />
                                        </Button>
                                    );
                                    let ButtonAction = (
                                        <Button Background="green" Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HandleSignSingleTransaction(SignUrl)}>
                                            Sign
                                        </Button>
                                    );
                                    TopBarActions.push(ButtonAction);
                                    ButtonAction = (
                                        <Button Background="red" Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HandleDeleteSingleTransaction(PontoTransactionId)}>
                                            Delete
                                        </Button>
                                    );
                                    TopBarActions.push(ButtonAction);
                                }

                                let Line = (
                                    <div className={classes.InvoiceSummary_Line}>
                                        <div
                                            className={classes.InvoiceSummary_Line_Title}
                                            //  style={{fontWeight: "600"}}
                                        >
                                            Creation Date :
                                        </div>
                                        <div
                                            className={classes.InvoiceSummary_Line_Value}
                                            // style={{fontWeight: "600"}}
                                        >
                                            {format(parseISO(PontoTransaction.DateCreatedIso), "dd-MMM-yyyy")}
                                        </div>
                                    </div>
                                );
                                Lines.push(Line);
                                Line = (
                                    <div className={classes.InvoiceSummary_Line}>
                                        <div
                                            className={classes.InvoiceSummary_Line_Title}
                                            //  style={{fontWeight: "600"}}
                                        >
                                            Payment Date :
                                        </div>
                                        <div
                                            className={classes.InvoiceSummary_Line_Value}
                                            // style={{fontWeight: "600"}}
                                        >
                                            {format(parseISO(PontoTransaction.RequestedExecutionDateIso), "dd-MMM-yyyy")}
                                        </div>
                                    </div>
                                );
                                Lines.push(Line);
                                Line = (
                                    <div className={classes.InvoiceSummary_Line}>
                                        <div
                                            className={classes.InvoiceSummary_Line_Title}
                                            //  style={{fontWeight: "600"}}
                                        >
                                            Days to sign :
                                        </div>
                                        <div
                                            className={classes.InvoiceSummary_Line_Value}
                                            // style={{fontWeight: "600"}}
                                        >
                                            {differenceInCalendarDays(parseISO(PontoTransaction.RequestedExecutionDateIso), Date.now())}
                                        </div>
                                    </div>
                                );
                                Lines.push(Line);
                                Line = (
                                    <div className={classes.InvoiceSummary_Line}>
                                        <div
                                            className={classes.InvoiceSummary_Line_Title}
                                            //  style={{fontWeight: "600"}}
                                        >
                                            Amount :
                                        </div>
                                        <div
                                            className={classes.InvoiceSummary_Line_Value}
                                            // style={{fontWeight: "600"}}
                                        >
                                            {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoTransaction.PontoAmount)}
                                        </div>
                                    </div>
                                );
                                Lines.push(Line);
                            }
                        }

                        setPaymentInfo({
                            TopBarLeftTitle: TopBarLeftTitle,
                            TopBarActions: TopBarActions,
                            TopBarIcon: TopBarIcon,
                            Lines: Lines,
                        });
                    } else {
                        console.log("error");
                    }
                } else if (ApiCall === "Refresh Ponto Status") {
                    let Data = ApiRes.data.data;
                    console.log("Refresh Ponto Status", Data);
                    HandleSaveRefreshForm();
                } else if (ApiCall === "Delete Single Ponto Transaction") {
                    let Data = ApiRes.data.data;
                    console.log("Delete Single Ponto Transaction", Data);
                    HandleSaveRefreshForm();
                } else if (ApiCall === "Recreate Single Transaction") {
                    let Data = ApiRes.data.data;
                    console.log("Recreate Single Transaction", Data);
                    HandleSaveRefreshForm();
                }
            }
        }
    }, [ApiRes, Loading]);

    const GeneratePaymentInformation = useCallback(() => {
        let TopBarPaymentTitle,
            TopBarLeftTitle,
            TopBarActions = [],
            TopBarIcon,
            Lines = [];

        //This is only used for status = Invoiced or Paid

        if (PaymentMethod === "01") {
            TopBarPaymentTitle = "Payment Status";
            let PontoInvoiceTransaction = InvoiceInitialFormData.PontoDetails;

            if (ManualBankPaymentActionsStatus === "Bulk To Create") {
                let PontoInvoiceAmount = PontoInvoiceTransaction.Amount;
                let Today = new Date(Date.now());
                let RequestedExecutionDateIso = PontoInvoiceTransaction.RequestedExecutionDateIso;
                let RemainingDaysToSign = differenceInCalendarDays(parseISO(RequestedExecutionDateIso), Today);
                //Bulk to create -> no PontoTransaction yet
                TopBarLeftTitle = "Bulk (Create)";

                let ButtonAction = (
                    <Button Background="red" Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={HandleDeleteBulkInvoice}>
                        Delete
                    </Button>
                );
                TopBarActions.push(ButtonAction);
                let Line = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Invoice due date :</div>
                        <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(InvoiceInitialFormData.InvoicePaymentDueDateIso), "dd-MMM-yyyy")}</div>
                    </div>
                );
                Lines.push(Line);
                Line = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Payment execution date :</div>
                        <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(RequestedExecutionDateIso), "dd-MMM-yyyy")}</div>
                    </div>
                );
                Lines.push(Line);
                Line = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Remaining days to sign :</div>
                        <div className={classes.InvoiceSummary_Line_Value}>{RemainingDaysToSign}</div>
                    </div>
                );
                Lines.push(Line);

                Line = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Amount :</div>
                        <div className={classes.InvoiceSummary_Line_Value}>
                            {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoInvoiceAmount)}
                        </div>
                    </div>
                );
                Lines.push(Line);
            } else {
                let PontoTransaction = PontoInvoiceTransaction.PontoTransaction;
                let PontoSignUrl = PontoTransaction.PontoSignUrl;
                let PontoTransactionId = PontoTransaction.PontoTransactionId;

                let PontoInvoiceAmount = PontoInvoiceTransaction.Amount;

                if (ManualBankPaymentActionsStatus === "PaidExecuted") {
                    //Valid for Both Single and Group Transactions
                    TopBarLeftTitle = "Payment";
                    //No toolbar action
                } else if (ManualBankPaymentActionsStatus === "PaidPendingExecution") {
                    //We just propose a recall after the user deleted at the bank
                    let Today = new Date(Date.now());
                    let RequestedExecutionDateIso = PontoTransaction.RequestedExecutionDateIso;
                    let RemainingDaysToSign = differenceInCalendarDays(parseISO(RequestedExecutionDateIso), Today);
                    TopBarLeftTitle = "Payment pending";
                    let ButtonAction = (
                        <Button Background="rgb(66, 165, 245)" Height="25px" Width="120px" ClassExtendButton={classes.Button} onClick={HandleDeleteSingleFromBankUserConfirmation}>
                            Delete from bank
                        </Button>
                    );
                    TopBarActions.push(ButtonAction);

                    let Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Invoice due date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(InvoiceInitialFormData.InvoicePaymentDueDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Payment execution date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(RequestedExecutionDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Remaining days to cancel :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{RemainingDaysToSign}</div>
                        </div>
                    );
                    Lines.push(Line);

                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Amount :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoInvoiceAmount)}
                            </div>
                        </div>
                    );
                    Lines.push(Line);
                } else if (ManualBankPaymentActionsStatus === "FailedSingle") {
                    //We propose the user to recreate the transaction -> we need to delete it, than recreate it and ask him to reapprove the full transaction
                    let Today = new Date(Date.now());
                    let RequestedExecutionDateIso = PontoTransaction.RequestedExecutionDateIso;
                    let RemainingDaysToSign = differenceInCalendarDays(parseISO(RequestedExecutionDateIso), Today);
                    TopBarLeftTitle = "Sign Failed";
                    TopBarIcon = <WarningTriangleRed IconSize="25px" />;
                    let ButtonAction = (
                        <Button Background="green" Height="25px" Width="70px" ClassExtendButton={classes.Button} onClick={() => HandleRecreateSingleTransaction(PontoTransactionId)}>
                            Recreate
                        </Button>
                    );
                    TopBarActions.push(ButtonAction);
                    ButtonAction = (
                        <Button Background="red" Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HandleDeleteSingleTransaction(PontoTransactionId)}>
                            Delete
                        </Button>
                    );
                    TopBarActions.push(ButtonAction);

                    let Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Invoice due date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(InvoiceInitialFormData.InvoicePaymentDueDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Payment execution date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(RequestedExecutionDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Remaining days to sign :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{RemainingDaysToSign}</div>
                        </div>
                    );
                    Lines.push(Line);

                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Amount :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoInvoiceAmount)}
                            </div>
                        </div>
                    );
                    Lines.push(Line);
                } else if (ManualBankPaymentActionsStatus === "FailedGroup") {
                    //We propose the user to recreate the transaction -> we need to delete it, than recreate it and ask him to reapprove the full transaction
                    TopBarLeftTitle = "Payment (Bank Manual)";
                } else if (ManualBankPaymentActionsStatus === "ToSignSingleExpired") {
                    //We propose the user to recreate the transaction -> we need to delete it, than recreate it and ask him to reapprove the full transaction
                    TopBarLeftTitle = "Expired (Single)";
                } else if (ManualBankPaymentActionsStatus === "ToSignGroupExpired") {
                    //We propose the user to recreate the transaction -> we need to delete it, than recreate it and ask him to reapprove the full transaction
                    TopBarLeftTitle = "Payment (Bank Manual)";
                } else if (ManualBankPaymentActionsStatus === "ToSignSingle") {
                    let Today = new Date(Date.now());
                    let RequestedExecutionDateIso = PontoTransaction.RequestedExecutionDateIso;
                    let RemainingDaysToSign = differenceInCalendarDays(parseISO(RequestedExecutionDateIso), Today);
                    TopBarLeftTitle = "To Sign (Single)";
                    //We Propose 3 actions : Sign - Delete - Refresh
                    let ButtonAction = (
                        <Button Background="green" Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HandleSignSingleTransaction(PontoSignUrl)}>
                            Sign
                        </Button>
                    );
                    TopBarActions.push(ButtonAction);
                    ButtonAction = (
                        <Button Background="red" Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={() => HandleDeleteSingleTransaction(PontoTransactionId)}>
                            Delete
                        </Button>
                    );
                    TopBarActions.push(ButtonAction);
                    TopBarIcon = (
                        <Button Height="25px" Width="25px" ClassExtendButton={classes.Button} onClick={() => HandleRefreshSingleTransaction(PontoTransactionId)}>
                            <RotateRight IconSize="22px" SVGStrokeWidth="20px" SVGFillColor="white" SVGFillHoverColor="white" />
                        </Button>
                    );
                    let Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Invoice due date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(InvoiceInitialFormData.InvoicePaymentDueDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Payment execution date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(RequestedExecutionDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Remaining days to sign :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{RemainingDaysToSign}</div>
                        </div>
                    );
                    Lines.push(Line);

                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Amount :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoInvoiceAmount)}
                            </div>
                        </div>
                    );
                    Lines.push(Line);
                } else if (ManualBankPaymentActionsStatus === "ToSignGroup") {
                    //We propose the user to recreate the transaction -> we need to delete it, than recreate it and ask him to reapprove the full transaction
                    let Today = new Date(Date.now());
                    let RequestedExecutionDateIso = PontoTransaction.RequestedExecutionDateIso;
                    let RemainingDaysToSign = differenceInCalendarDays(parseISO(RequestedExecutionDateIso), Today);
                    TopBarLeftTitle = "To Sign (Group)";
                    //We Propose 3 actions : Sign - Delete - Refresh
                    let ButtonAction = <div style={{fontSize:"0.65rem"}}>Sign in Manage Payments</div>;

                    TopBarActions.push(ButtonAction);

                    TopBarIcon = (
                        <Button Height="25px" Width="25px" ClassExtendButton={classes.Button} onClick={() => HandleRefreshSingleTransaction(PontoTransactionId)}>
                            <RotateRight IconSize="22px" SVGStrokeWidth="20px" SVGFillColor="white" SVGFillHoverColor="white" />
                        </Button>
                    );
                    let Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Invoice due date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(InvoiceInitialFormData.InvoicePaymentDueDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Payment execution date :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(RequestedExecutionDateIso), "dd-MMM-yyyy")}</div>
                        </div>
                    );
                    Lines.push(Line);
                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Remaining days to sign :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>{RemainingDaysToSign}</div>
                        </div>
                    );
                    Lines.push(Line);

                    Line = (
                        <div className={classes.InvoiceSummary_Line}>
                            <div className={classes.InvoiceSummary_Line_Title}>Amount :</div>
                            <div className={classes.InvoiceSummary_Line_Value}>
                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoInvoiceAmount)}
                            </div>
                        </div>
                    );
                    Lines.push(Line);
                } else if (ManualBankPaymentActionsStatus === "Bulk To Create") {
                    //We propose the user to recreate the transaction -> we need to delete it, than recreate it and ask him to reapprove the full transaction
                    TopBarLeftTitle = "Payment (Bank Manual)";
                }

                // let ExecutionDateIso = InvoiceInitialFormData.PontoDetails.PontoTransaction.RequestedExecutionDateIso;
                // let PontoAmount = InvoiceInitialFormData.PontoDetails.Amount;
                // let Line = (
                //     <div className={classes.InvoiceSummary_Line}>
                //         <div
                //             className={classes.InvoiceSummary_Line_Title}
                //             //  style={{fontWeight: "600"}}
                //         >
                //             Signed Date :
                //         </div>
                //         <div
                //             className={classes.InvoiceSummary_Line_Value}
                //             // style={{fontWeight: "600"}}
                //         >
                //             {format(parseISO(InvoiceInitialFormData.CalcPaymentDateIso), "dd-MMM-yyyy")}
                //         </div>
                //     </div>
                // );
                // Lines.push(Line);
                // Line = (
                //     <div className={classes.InvoiceSummary_Line}>
                //         <div
                //             className={classes.InvoiceSummary_Line_Title}
                //             //  style={{fontWeight: "600"}}
                //         >
                //             Execution Date :
                //         </div>
                //         <div
                //             className={classes.InvoiceSummary_Line_Value}
                //             // style={{fontWeight: "600"}}
                //         >
                //             {format(parseISO(ExecutionDateIso), "dd-MMM-yyyy")}
                //         </div>
                //     </div>
                // );
                // Lines.push(Line);

                // Lines.push(Line);
                // Line = (
                //     <div className={classes.InvoiceSummary_Line}>
                //         <div
                //             className={classes.InvoiceSummary_Line_Title}
                //             //  style={{fontWeight: "600"}}
                //         >
                //             Amount :
                //         </div>
                //         <div
                //             className={classes.InvoiceSummary_Line_Value}
                //             // style={{fontWeight: "600"}}
                //         >
                //             {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PontoAmount)}
                //         </div>
                //     </div>
                // );
                // Lines.push(Line);
            }
        } else if (PaymentMethod === "02") {
            //Bank Auto
            TopBarLeftTitle = "Payment (Bank Auto)";
        } else if (PaymentMethod === "03" || PaymentMethod === "04") {
            //Credit Card Manual
        } else if (PaymentMethod === "04") {
            //Credit Card Auto
        }

        setPaymentInfo({
            TopBarPaymentTitle: TopBarPaymentTitle,
            TopBarLeftTitle: TopBarLeftTitle,
            TopBarActions: TopBarActions,
            TopBarIcon: TopBarIcon,
            Lines: Lines,
        });
    }, []);

    const GenerateSimplePaymentConfirmation = useCallback(() => {
        let TopBarPaymentTitle,
            TopBarLeftTitle,
            TopBarActions = [],
            TopBarIcon,
            Lines = [];

        TopBarPaymentTitle = "Payment Status";
        TopBarLeftTitle = "Paid (manually)";
        let Line = (
            <div className={classes.InvoiceSummary_Line}>
                <div className={classes.InvoiceSummary_Line_Title}>Paid Date :</div>
                <div className={classes.InvoiceSummary_Line_Value}>{format(parseISO(InvoiceInitialFormData.CalcPaymentDateIso), "dd-MMM-yyyy")}</div>
            </div>
        );
        Lines.push(Line);

        Line = (
            <div className={classes.InvoiceSummary_Line}>
                <div className={classes.InvoiceSummary_Line_Title}>Amount :</div>
                <div className={classes.InvoiceSummary_Line_Value}>
                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(InvoiceInitialFormData.CalcTotalVATIncluded)}
                </div>
            </div>
        );
        Lines.push(Line);
        setPaymentInfo({
            TopBarPaymentTitle: TopBarPaymentTitle,
            TopBarLeftTitle: TopBarLeftTitle,
            TopBarActions: TopBarActions,
            TopBarIcon: TopBarIcon,
            Lines: Lines,
        });
    }, []);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        console.log("Reload Info Part", InvoiceStatus);
        if (InvoiceStatus === "Draft") {
            //We don't generate Payment Information
        } else if (InvoiceStatus === "Reconciled" || InvoiceStatus === "Accounted") {
            //We just need to link to the Bank transaction
        } else if (ManualBankPaymentActionsStatus === "Create Payment") {
        } else {
            if (ManualBankPaymentActionsStatus === "PaidExecutedNotPonto") {
                GenerateSimplePaymentConfirmation();
            } else {
                //Status can be Invoiced or Paid

                GeneratePaymentInformation();
            }
        }
    }, [InvoiceStatus, ProdURL, InvoiceId, PaymentMethod, ManualBankPaymentActionsStatus, GeneratePaymentInformation, GenerateSimplePaymentConfirmation]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleRefreshSingleTransaction = (PontoTransactionId) => {
        setApiCall("Refresh Ponto Status");
        const ApiCall = {url: `${ProdURL}/api/securebanking/dbpontotransactions/getinfobyid/${PontoTransactionId}`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleDeleteSingleTransaction = (PontoTransactionId) => {
        setApiCall("Delete Single Ponto Transaction");
        const ApiCall = {url: `${ProdURL}/api/securebanking/deletesingleponto/${PontoTransactionId}`, type: "delete"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleDeleteBulkTransaction = (PontoTransactionId) => {
        setApiCall("Delete Single Ponto Transaction");
        // const ApiCall = {url: `${ProdURL}/api/securebanking/deletesingleponto/${PontoTransactionId}`, type: "delete"};
        // setLoading(true);

        // async function APIInternal() {
        //     const res = await APICallExternal(ApiCall);

        //     setApiRes(res);
        //     setLoading(false);
        // }

        // APIInternal();
    };

    const HandleSignSingleTransaction = (SignUrl) => {
        if (SignUrl) {
            window.open(SignUrl);
        } else {
            window.open("https://dashboard.myponto.com/organizations/e175cbd5-d4e1-41ef-81e0-d456d76c6823/live/accounts/cb585984-aafe-4658-a5ad-0c8715063d37/payments/signable");
        }
    };

    const HandleSignBulkTransaction = (SignUrl) => {
        //We need to open a modal to show all the invoices that needs to be signed together
        // if (SignUrl) {
        //     window.open(SignUrl);
        // } else {
        //     window.open("https://dashboard.myponto.com/organizations/e175cbd5-d4e1-41ef-81e0-d456d76c6823/live/accounts/cb585984-aafe-4658-a5ad-0c8715063d37/payments/signable");
        // }
    };

    const HandleRecreateSingleTransaction = (PontoTransactionId) => {
        console.log("Recreate Transaction");
        setApiCall("Recreate Single Transaction");
        const FormData = {
            TransactionID: PontoTransactionId,
            InvoiceId: InvoiceId,
        };
        const ApiCall = {url: `${ProdURL}/api/securebanking/recreatesinglepayment`, type: "post", data: FormData};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleDeleteSingleFromBankUserConfirmation = () => {
        console.log("Delete FromInvoiceId", InvoiceId);
        setShowCardDeleteManualFromBank(true);
    };

    const HandleCloseCardDeleteFromBank = (UpdateRequired) => {
        setShowCardDeleteManualFromBank(false);
    };

    const HandleDeleteBulkInvoice = () => {
        console.log("Delete InvoiceTransaction", InvoiceId);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let InvoicePaymentInfoPartConfirmManualDeletionComponent;
    if (ShowCardDeleteManualFromBank === true) {
        InvoicePaymentInfoPartConfirmManualDeletionComponent = (
            <InvoicePaymentInfoPartConfirmManualDeletion PassedFormValues={InvoiceInitialFormData} InvoiceId={InvoiceId} Suppliers={Suppliers} HandleUpdateShowStatus={HandleCloseCardDeleteFromBank} />
        );
    } else {
        InvoicePaymentInfoPartConfirmManualDeletionComponent = null;
    }

    let FinalComponent;
    if (InvoiceStatus === "Draft") {
        FinalComponent = null;
    } else {
        if (PaymentInfo) {
            FinalComponent = (
                <div className={classes.PaymentBox}>
                    <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                    {InvoicePaymentInfoPartConfirmManualDeletionComponent}
                    <div className={classes.PaymentBox_TopBar}>
                        <div className={classes.PaymentBox_TopBarLeft}>{PaymentInfo.TopBarPaymentTitle}</div>
                    </div>

                    <div className={classes.PaymentBox_TopBar}>
                        <div className={classes.PaymentBox_TopBarLeft}>{PaymentInfo.TopBarLeftTitle}</div>
                        <div className={classes.PaymentBox_TopBarActions}>{PaymentInfo.TopBarActions}</div>
                        <div className={classes.PaymentBox_TopBarRight}>{PaymentInfo.TopBarIcon}</div>
                    </div>
                    <div className={classes.PaymentBox_Content}>
                        <div className={classes.InvoiceSummary}>{PaymentInfo.Lines}</div>
                    </div>
                </div>
            );
        }
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
