/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import _ from "lodash";
import Select, {components} from "react-select";
import CreatableSelect from "react-select/creatable";
import {SortBarsDesc, SortBarsAsc} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Input: {
        // alignSelf: "stretch",
        flex: "1 1 auto",
    },
    TopMenuListToolbarWrapper: {
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",

        backgroundColor: "rgba(44, 44, 44, 0.1)",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        // borderBottom: "1px solid grey",
        boxShadow: "rgba(0, 0, 0, 0.3) 0 10px 16px -6px, rgba(0, 0, 0, 0.117647) 0 4px 3px 0, rgba(0, 0, 0, 0.2) 0 8px 10px -5px",
    },
    TopMenuListSearchInput: {
        flex: "1 1 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "5px 5px",
    },
    ToolBarIconWrapper_Right: {
        flex: "0 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: "1px solid grey",
        alignSelf: "stretch",
    },
    ToolBarIcon: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "0px 5px",
        cursor: "pointer",
    },
    MultiValueContainer: {
        display: "flex",
        flex: "1 1 auto",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "space-between",
        overflow: "hidden",
        zIndex: 10000,
    },
}));

/**
 * Component Description
 */
const FieldSelect = React.memo(function FieldSelect(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputSelectedValues,
        SelectOptions,
        Name, //Important for AutoFill
        ShareFocusState,
        ShareHasValue,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Disabled,
        Required,
        MultiSelect,
        AllowCreateOption,
        SortSelectOptions,
        SortLastItemName,
        SortFirstItemName,
        SortField,
        Clearable,
        isSearchable,
        minMenuHeight,
        maxMenuHeight,
        menuPlacement,
        AlternateRowStyle,
        Variant,
        FinalLabelColor,
        InputFontProp,
        SortIcons,
        CreateOnServerWhenCreate,
        CreateDeleteIfUnselected,
        CreateCheckServerAccept,
        CreateOnServerURLS,
        UpdateCreatableOptions,
        FieldMaxWidth,

        //Dont Fold
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_LocalInput = useRef(null);
    const Ref_Controller = useRef(null);
    const Ref_MenuSearchInput = useRef(null);

    const Ref_ResizeObserver = useRef(
        new ResizeObserver((entries) => {
            let ValueContainer = entries[0];
            let ValueContainerH = ValueContainer.contentRect.height;
            setValueContainerHeight(ValueContainerH);
        })
    );

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [OrginalSortedValues, setOrginalSortedValues] = React.useState(null); //Storing the original values with custom tags to restore it of required
    const [FinalSelectOptions, setFinalSelectOptions] = React.useState(null); //The final option list, sorted with addition items
    const [NewCreatedSelectOptions, setNewCreatedSelectOptions] = React.useState(null); //Uses to store the new added select options and keep it here when the comp refreshes
    const [Single, setSingle] = React.useState(null); //Current selection for Single Select
    const [Multi, setMulti] = React.useState(null); //Current Selection for Multi Select
    const [SortedDirection, setSortedDirection] = useState(null); //Sort Direction if Sorted
    const [IsMenuOpen, setIsMenuOpen] = useState(false); //Controllig the menu state when using a custom toolbar in the menu
    const [ValueContainerHeight, setValueContainerHeight] = useState(null); //the Real ValueContainer height using observer
    const [ReleaseMultineHeight, setReleaseMultineHeight] = useState(false); //set the Multi height when only 1 line or releasing it for multi lines

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    const HandleClickOutside = React.useCallback(
        (e) => {
            if (Ref_LocalInput.current.select.menuListRef && Ref_LocalInput.current.select.menuListRef.contains(e.target)) {
            } else {
                setIsMenuOpen(false);
            }
        },
        [Ref_LocalInput]
    );
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    //Initializing the component
    React.useEffect(() => {
        var initialOption = [];
        if (MultiSelect === true) {
            if (InitialValue && SelectOptions) {
                if (SelectOptions.length >= 1) {
                    if (FormValue === InitialValue) {
                        if (Array.isArray(InitialValue)) {
                            InitialValue.forEach((initval) => {
                                SelectOptions.forEach((elem) => {
                                    if (elem.value === initval) {
                                        initialOption.push({value: elem.value, label: elem.label});
                                    }
                                });
                            });
                        } else {
                            SelectOptions.forEach((elem) => {
                                if (elem.value === InitialValue) {
                                    initialOption = {value: elem.value, label: elem.label};
                                }
                            });
                        }

                        setMulti(initialOption);
                        ShareHasValue(true);
                    }
                }
            }
            if (FormValue == null) {
                setMulti(null);
                ShareHasValue(false);
            }
        } else {
            if (InitialValue && SelectOptions) {
                if (InitialValue === FormValue) {
                    if (SelectOptions.length > 0) {
                        SelectOptions.forEach((elem) => {
                            if (elem.value === InitialValue) {
                                initialOption = {value: elem.value, label: elem.label};
                            }
                        });
                        //It is potentially a form reset -> Need to update the field to reflect that change
                        setSingle(initialOption);
                        ShareHasValue(true);
                    }
                } else {
                    if (SelectOptions.length > 0) {
                        SelectOptions.forEach((elem) => {
                            if (elem.value === FormValue) {
                                initialOption = {value: elem.value, label: elem.label};
                            }
                        });

                        setSingle(initialOption);
                        ShareHasValue(true);
                    }
                }
            } else {
                if (FormValue === null) {
                    //Reset
                    setSingle(null);
                    ShareHasValue(false);
                } else {
                    if (SelectOptions.length > 0) {
                        SelectOptions.forEach((elem) => {
                            if (elem.value === FormValue) {
                                initialOption = {value: elem.value, label: elem.label};
                            }
                        });

                        setSingle(initialOption);
                        ShareHasValue(true);
                    }
                }
            }
        }
    }, [FormValue, MultiSelect, InitialValue, SelectOptions]);

    //Initiating the sort if required
    useEffect(() => {
        if (SortSelectOptions) {
            if (!SortedDirection) {
                setSortedDirection(SortSelectOptions);
            }
        }
    }, [SortSelectOptions]);

    //Sorting the select options and adding custom tags to pass alternate rows to the menu
    React.useEffect(() => {
        if (SelectOptions) {
            var SortedOptions, SortedOptionsFinal;
            //If sort is required ->
            if (SortedDirection) {
                let sortDir = SortedDirection === "Dsc" ? "desc" : "asc";
                if (SortField) {
                    SortedOptions = _.orderBy(SelectOptions, SortField, sortDir);
                } else {
                    SortedOptions = _.orderBy(
                        SelectOptions,
                        function (lowCase) {
                            if (lowCase.label) {
                                return lowCase.label.toLowerCase();
                            } else {
                                return lowCase.label;
                            }
                        },
                        sortDir
                    );
                }

                if (SortLastItemName.Name) {
                    var LastIem;
                    //eslint-disable-next-line
                    let OptionSortedTemp = [];

                    SortedOptions.forEach((elem) => {
                        if (SortLastItemName.Exact) {
                            if (elem.label === SortLastItemName.Name) {
                                //Store it
                                LastIem = elem;
                            } else {
                                OptionSortedTemp.push(elem);
                            }
                        } else {
                            if (elem.label.includes(SortLastItemName.Name)) {
                                //Store it
                                LastIem = elem;
                            } else {
                                OptionSortedTemp.push(elem);
                            }
                        }
                    });

                    OptionSortedTemp.push(LastIem);

                    SortedOptionsFinal = OptionSortedTemp;
                } else if (SortFirstItemName.Name) {
                    var FirstItem;
                    //eslint-disable-next-line
                    let OptionSortedTemp = [];

                    SortedOptions.forEach((elem) => {
                        if (SortFirstItemName.Exact) {
                            if (elem.label === SortFirstItemName.Name) {
                                //Store it
                                FirstItem = elem;
                            } else {
                                OptionSortedTemp.push(elem);
                            }
                        } else {
                            if (elem.label && elem.label.includes(SortFirstItemName.Name)) {
                                //Store it
                                FirstItem = elem;
                            } else {
                                OptionSortedTemp.push(elem);
                            }
                        }
                    });

                    OptionSortedTemp.unshift(FirstItem);

                    SortedOptionsFinal = OptionSortedTemp;
                } else {
                    SortedOptionsFinal = SortedOptions;
                }
            } else {
                SortedOptionsFinal = SelectOptions;
            }
            //Adding custom tags for customizing the style
            var i = 0;

            var customOptions = SortedOptionsFinal.map((elem) => {
                let isAlternate = i % 2 === 1 ? true : false;
                i = i + 1;
                return {...elem, alternateRow: isAlternate};
                //do something to customize them
            });

            setFinalSelectOptions(customOptions);
            setOrginalSortedValues(customOptions);
        }
    }, [SelectOptions, SortSelectOptions, SortLastItemName, SortFirstItemName, SortedDirection]);

    //Passing the Component Ref to the parent on creation
    useEffect(() => {
        if (Ref_LocalInput.current) {
            var ControllerRef;
            if (AllowCreateOption === true) {
                ControllerRef = Ref_LocalInput.current.select.select.controlRef;
            } else {
                ControllerRef = Ref_LocalInput.current.select.controlRef;
            }

            Ref_Controller.current = ControllerRef;
            HandleUpdateRefInput(ControllerRef);
        }
    }, [Ref_LocalInput]);

    //Adding Event Listener on the control to open the menu when using a custom toolbar
    useEffect(() => {
        if (SortIcons === true) {
            if (Ref_LocalInput.current) {
                if (Ref_LocalInput.current.select) {
                    if (Ref_LocalInput.current.select.controlRef) {
                        Ref_LocalInput.current.select.controlRef.addEventListener("click", HandleControllerClick);
                    }
                }

                return function cleanup() {
                    if (Ref_LocalInput.current) {
                        if (Ref_LocalInput.current.select) {
                            if (Ref_LocalInput.current.select.controlRef) {
                                Ref_LocalInput.current.select.controlRef.removeEventListener("click", HandleControllerClick);
                            }
                        }
                    }
                };
            }
        }
    }, [Ref_LocalInput, SortIcons]);

    //Adding ClickOutside of menu event listener only of the menu is opened for the toolbar component
    useEffect(() => {
        if (SortIcons === true && IsMenuOpen === true) {
            document.addEventListener("mousedown", HandleClickOutside);
            return function cleanup() {
                document.removeEventListener("mousedown", HandleClickOutside);
            };
        } else {
            document.removeEventListener("mousedown", HandleClickOutside);
        }
    }, [SortIcons, IsMenuOpen]);

    //Adding an observer Listener on value container to handle the height of multiline containers
    useEffect(() => {
        if (MultiSelect === true) {
            if (Ref_Controller.current) {
                let ChildrenNodes = Ref_Controller.current.querySelectorAll("*");
                let ValueContainer = ChildrenNodes[0];

                Ref_ResizeObserver.current.observe(ValueContainer);
                return () => {
                    Ref_ResizeObserver.current.unobserve(ValueContainer);
                };
            }
        }
    }, [Ref_Controller, Ref_ResizeObserver]);

    //Updating the height of the multiline container when the required
    useEffect(() => {
        if (Style.height && Style.paddingTop) {
            let CurrentStyleH = parseFloat(Style.height);
            let RealHeight = parseFloat(ValueContainerHeight) + parseFloat(Style.paddingTop) * 2;
            if (RealHeight >= 1.5 * CurrentStyleH) {
                setReleaseMultineHeight(true);
            } else {
                setReleaseMultineHeight(false);
            }
        }
    }, [ValueContainerHeight]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /*************   Handling the select change and passing back the value to the form ***********/
    const HandleSelectChange = (SelectedOptions, action) => {
        if (MultiSelect) {
            //Handling Multi select
            //Set the local value
            setMulti(SelectedOptions);
            if (AllowCreateOption === true && CreateDeleteIfUnselected === true) {
                //Creatable -> We need to check if the selected value is the created one, otherwise we delete it from the list
                //1.Check if there is a created value
                if (NewCreatedSelectOptions && NewCreatedSelectOptions.length > 0) {
                    var UpdatedNewCreatedOptions = [],
                        UpdatedFinalOptions = [];
                    //Update the local created list
                    if (SelectedOptions) {
                        //Remove the created values that are not in the selection
                        NewCreatedSelectOptions.forEach((elem) => {
                            //Check if the created exist in the selection
                            let ExistInSelection = _.find(SelectedOptions, {value: elem.value});
                            if (ExistInSelection) {
                                UpdatedNewCreatedOptions.push(elem);
                            }
                        });
                    }

                    //Update the FinalSelectOptions
                    FinalSelectOptions.forEach((elem) => {
                        if (elem.new === true) {
                            //Check if in the selected Options
                            let ExistInSelection = _.find(SelectedOptions, {value: elem.value});
                            if (ExistInSelection) {
                                UpdatedFinalOptions.push(elem);
                            }
                        } else {
                            UpdatedFinalOptions.push(elem);
                        }
                    });

                    if (CreateOnServerWhenCreate === true && CreateOnServerURLS.delete) {
                        //Push on the server directly - to be implemented later
                    } else {
                        //Update the local state
                        setNewCreatedSelectOptions(UpdatedNewCreatedOptions);
                        setFinalSelectOptions(UpdatedFinalOptions);
                        //Update the option list and the created ones
                        if (UpdateCreatableOptions && typeof UpdateCreatableOptions === "function") {
                            UpdateCreatableOptions({
                                FullOptions: UpdatedFinalOptions,
                                NewOptions: UpdatedNewCreatedOptions,
                            });
                        }
                    }
                }
            }
            //Pass the selected value to the Form
            if (OutputSelectedValues && typeof OutputSelectedValues === "function") {
                let FinalPristine = false;
                if (SelectedOptions) {
                    let finallist = SelectedOptions.map((val) => val.value);

                    //Let's check if the selected values are the same as initial
                    if (_.isEqual(InitialValue, finallist) === true) {
                        FinalPristine = true;
                    }
                    OutputSelectedValues({Value: finallist, Pristine: FinalPristine});
                    ShareHasValue(true);
                } else {
                    if (InitialValue === null) {
                        FinalPristine = true;
                    }
                    OutputSelectedValues({Value: null, Pristine: FinalPristine});
                    ShareHasValue(false);
                }
            }
        } else {
            // Handling Single Select
            //Set the local value
            setSingle(SelectedOptions);
            if (AllowCreateOption === true && CreateDeleteIfUnselected === true) {
                //Creatable -> We need to check if the selected value is the created one, otherwise we delete it from the list
                //1.Check if there is a created value
                if (NewCreatedSelectOptions && NewCreatedSelectOptions.length > 0) {
                    var UpdatedNewCreatedOptions = [],
                        UpdatedFinalOptions = [];
                    //Update the local created list
                    if (SelectedOptions) {
                        //Find the Currently selected value in the created list
                        NewCreatedSelectOptions.forEach((elem) => {
                            if (elem.value === SelectedOptions.value) {
                                UpdatedNewCreatedOptions.push(elem);
                            }
                        });
                    }

                    //Update the FinalSelectOptions
                    if (UpdatedNewCreatedOptions.length === 0) {
                        //We need to remove the new ones if any
                        FinalSelectOptions.forEach((elem) => {
                            if (elem.new === true) {
                                //Do Nothing
                            } else {
                                UpdatedFinalOptions.push(elem);
                            }
                        });
                    } else {
                        UpdatedFinalOptions = FinalSelectOptions;
                    }

                    if (CreateOnServerWhenCreate === true && CreateOnServerURLS.delete) {
                        //Push on the server directly - to be implemented later
                    } else {
                        //Update the local state
                        setNewCreatedSelectOptions(UpdatedNewCreatedOptions);
                        setFinalSelectOptions(UpdatedFinalOptions);

                        //Update the option list and the created ones
                        if (UpdateCreatableOptions && typeof UpdateCreatableOptions === "function") {
                            UpdateCreatableOptions({
                                FullOptions: UpdatedFinalOptions,
                                NewOptions: UpdatedNewCreatedOptions,
                            });
                        }
                    }
                }
            }
            //Pass the selected value to the Form
            if (OutputSelectedValues && typeof OutputSelectedValues === "function") {
                let FinalPristine = false;
                if (SelectedOptions) {
                    if (SelectedOptions.value === InitialValue) {
                        FinalPristine = true;
                    }
                    OutputSelectedValues({Value: SelectedOptions.value, Pristine: FinalPristine});
                    ShareHasValue(true);
                } else {
                    if (InitialValue === null) {
                        FinalPristine = true;
                    }
                    OutputSelectedValues({Value: null, Pristine: FinalPristine});
                    ShareHasValue(false);
                }
            }
        }
        if (SortIcons === true && IsMenuOpen === true) {
            if (MultiSelect === true) {
            } else {
                setIsMenuOpen(false);
            }
        }
    };
    /*************   Handling the select change and passing back the value to the form ***********/

    /*************   Handling Creating a new option ***********/
    const HandleCreateOption = (createdOption) => {
        let newOption = {value: createdOption, label: createdOption, new: true};
        //Adding the New option to the exisiting array
        let FinalOptionsUnsorted = [...FinalSelectOptions, newOption];

        //sort the new array if required
        var SortedOptions, SortedOptionsFinal;
        if (SortedDirection) {
            let sortDir = SortedDirection === "Dsc" ? "desc" : "asc";
            SortedOptions = _.orderBy(
                FinalOptionsUnsorted,
                function (lowCase) {
                    if (lowCase.label) {
                        return lowCase.label.toLowerCase();
                    } else {
                        return lowCase.label;
                    }
                },
                sortDir
            );

            if (SortLastItemName.Name) {
                var LastIem;
                //eslint-disable-next-line
                let OptionSortedTemp = [];

                FinalOptionsUnsorted.forEach((elem) => {
                    if (SortLastItemName.Exact) {
                        if (elem.label === SortLastItemName.Name) {
                            //Store it
                            LastIem = elem;
                        } else {
                            OptionSortedTemp.push(elem);
                        }
                    } else {
                        if (elem.label.includes(SortLastItemName.Name)) {
                            //Store it
                            LastIem = elem;
                        } else {
                            OptionSortedTemp.push(elem);
                        }
                    }
                });

                OptionSortedTemp.push(LastIem);

                SortedOptionsFinal = OptionSortedTemp;
            } else {
                SortedOptionsFinal = SortedOptions;
            }
        } else {
            SortedOptionsFinal = FinalOptionsUnsorted;
        }

        if (MultiSelect) {
            //Updating the SelectOptions based on the user inputs
            //Store the new option in the local state
            var UpdatedNewOptions;
            if (NewCreatedSelectOptions) {
                UpdatedNewOptions = [...NewCreatedSelectOptions, newOption];
            } else {
                UpdatedNewOptions = [newOption];
            }
            setNewCreatedSelectOptions(UpdatedNewOptions);
            if (CreateOnServerWhenCreate === true && CreateOnServerURLS.post) {
                //Push on the server directly - to be implemented later
            } else {
                if (CreateCheckServerAccept === true && CreateOnServerURLS.get) {
                    //We call the server to check if the proposed value is accecptable -> to be implemented later
                } else {
                    //No Server check or creation -> Pass the full new options and the new option to the FormComponent
                    if (UpdateCreatableOptions && typeof UpdateCreatableOptions === "function") {
                        UpdateCreatableOptions({
                            FullOptions: SortedOptionsFinal,
                            NewOptions: UpdatedNewOptions,
                        });
                    }
                }
            }
            //Update the current selection
            var UpdatedMulti = [];
            if (Multi && Multi.length > 0) {
                UpdatedMulti = [...Multi, newOption];
            } else {
                UpdatedMulti = [newOption];
            }

            setMulti(UpdatedMulti);
            //Update the select list
            setFinalSelectOptions(SortedOptionsFinal);
            //Pass the value to the form

            let FinalMultiSelection = UpdatedMulti.map((val) => val.value);

            OutputSelectedValues({Value: FinalMultiSelection, Pristine: false});
            ShareHasValue(true);
        } else {
            //Updating the SelectOptions based on the user inputs
            //Store the new option in the local state
            var UpdatedNewOptions;
            if (NewCreatedSelectOptions) {
                UpdatedNewOptions = [...NewCreatedSelectOptions, newOption];
            } else {
                UpdatedNewOptions = [newOption];
            }
            setNewCreatedSelectOptions(UpdatedNewOptions);

            if (CreateOnServerWhenCreate === true && CreateOnServerURLS.post) {
                //Push on the server directly - to be implemented later
            } else {
                if (CreateCheckServerAccept === true && CreateOnServerURLS.get) {
                    //We call the server to check if the proposed value is accecptable -> to be implemented later
                } else {
                    //No Server check or creation -> Pass the full new options and the new option to the FormComponent
                    if (UpdateCreatableOptions && typeof UpdateCreatableOptions === "function") {
                        UpdateCreatableOptions({
                            FullOptions: SortedOptionsFinal,
                            NewOptions: UpdatedNewOptions,
                        });
                    }
                }
            }
            //Update the current selection
            setSingle(newOption);
            //Update the select list
            setFinalSelectOptions(SortedOptionsFinal);
            //Pass the value to the form

            OutputSelectedValues({Value: newOption.value, Pristine: false});
            ShareHasValue(true);
        }
    };
    /*************   Handling Creating a new option ***********/

    /*************   Click on sort Icon ***********/
    const HandleToggleSort = () => {
        if (SortedDirection === "Asc") {
            setSortedDirection("Dsc");
        } else {
            setSortedDirection("Asc");
        }
    };

    /*************   Click on sort Icon ***********/

    /*************   Searching in the toolbar menu ***********/
    const HandleMenuToolbarSearch = (event) => {
        let Value = event.target.value ? event.target.value : null;

        //Applying our filter and updating the options
        if (Value) {
            var FilteredOptions = [];
            FinalSelectOptions.forEach((elem) => {
                if (elem.label.toLowerCase().includes(Value)) {
                    FilteredOptions.push(elem);
                }
            });
            setFinalSelectOptions(FilteredOptions);
            //We need to refocus on the input to prevent losing the focus
            setTimeout(() => {
                Ref_MenuSearchInput.current.value = Value;
                Ref_MenuSearchInput.current.focus();
            }, 150);
        } else {
            setFinalSelectOptions(OrginalSortedValues);
            setTimeout(() => {
                Ref_MenuSearchInput.current.focus();
            }, 150);
        }
        // setMenuToolbarInputSearchValue(Value);
    };
    /*************   Searching in the toolbar menu ***********/

    /*************   Setting the Focust on the Search Input when clicking the input ***********/
    const HanleInputClick = () => {
        //Set the focus to the input
        Ref_MenuSearchInput.current.focus();
    };
    /*************   Setting the Focust on the Search Input when clicking the input ***********/
    /*************   Searching in the toolbar menu ***********/

    const HandleControllerClick = () => {
        setIsMenuOpen(true);
    };

    const HandleOnFocus = () => {
        //Change the Zindex to ensure it is going over any other fields

        //Share the focus with the parent component
        ShareFocusState(true);
    };
    const HandleOnBlur = () => {
        ShareFocusState(false);
    };

    /*************   Searching in the toolbar menu ***********/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    /********************* Styling the Select components ******************/
    const SelectCustomStyles = {
        container: (provided) => ({
            ...provided,
            // fontSize: InputFontProp.fontSize,
            // fontFamily: InputFontProp.fontFamily,
            // fontWeight: InputFontProp.fontWeight,
            position: "relative",
            boxSizing: "border-box",
            flex: "1 0 auto",
            // width: "100%",
            width: FieldMaxWidth ? FieldMaxWidth : "100%",
        }),
        control: () => ({
            // fontSize: InputFontProp.fontSize,
            // fontFamily: InputFontProp.fontFamily,
            // fontWeight: InputFontProp.fontWeight,
            background: "none",
            position: "relative",
            boxSizing: "border-box",
            margin: "0px",
            transition: "all 100ms",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            outline: "0!important",
            border: "0 none!important",
            overflow: "hidden",
            height: ReleaseMultineHeight === false ? Style.height : null,
        }),
        valueContainer: (provided) => ({
            ...provided,
            display: "flex",
            flexWrap: "wrap",
            flex: 1,
            alignItems: "center",
            minHeight: Style.height,
            paddingTop: MultiSelect === true && Variant === "Outlined" ? `${parseFloat(Style.paddingTop) - 1}px` : Style.paddingTop,
            paddingBottom: MultiSelect === true && Variant === "Outlined" ? `${parseFloat(Style.paddingBottom) - 1}px` : Style.paddingBottom,
            paddingLeft: Style.paddingLeft,
            paddingRight: Style.paddingRight,
        }),
        placeholder: (provided) => ({
            // ...provided,
            fontSize: InputFontProp.fontSize,
            fontFamily: InputFontProp.fontFamily,
            fontWeight: InputFontProp.fontWeight,
            color: FinalLabelColor.Empty ? FinalLabelColor.Empty : theme.FormTextInput.LabelEmpty.color,
            marginTop: MultiSelect === true && Variant === "Outlined" ? "1.5px" : null,
            marginBottom: MultiSelect === true && Variant === "Outlined" ? "1.5px" : null,
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            paddingTop: "1px",
            paddingBottom: "1px",
            fontSize: theme.SelectComponent.MultiValue.fontSize,
            fontFamily: theme.SelectComponent.MultiValue.fontFamily,
            fontWeight: theme.SelectComponent.MultiValue.fontWeight,
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: InputFontProp.fontSize,
            fontFamily: InputFontProp.fontFamily,
            fontWeight: InputFontProp.fontWeight,
            color: Style.color,
        }),
        input: (styles) => ({
            ...styles,
            fontSize: InputFontProp.fontSize,
            fontFamily: InputFontProp.fontFamily,
            fontWeight: InputFontProp.fontWeight,

            color: Style.color,
            margin: "0px",
            padding: "0px",
        }),

        menu: (provided) => ({
            ...provided,
            //   marginTop : "0px",
            marginTop: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.marginTop : theme.SelectComponent.Menu.marginTop) : theme.SelectComponent.Menu.marginTop,
            marginBottom: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.marginBottom : theme.FormSelect.Menu.marginBottom) : theme.SelectComponent.Menu.marginBottom,
            boxShadow: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.boxShadow : theme.SelectComponent.Menu.boxShadow) : theme.SelectComponent.Menu.boxShadow,
            background: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.background : theme.SelectComponent.Menu.background) : theme.SelectComponent.Menu.background,
            fontFamily: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.fontFamily : theme.SelectComponent.Menu.fontFamily) : theme.SelectComponent.Menu.fontFamily,
            fontSize: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.fontSize : theme.SelectComponent.Menu.fontSize) : theme.SelectComponent.Menu.fontSize,
            fontWeight: Variant ? (Variant === "naked" ? theme.SelectComponent.Menu.fontWeight : theme.SelectComponent.Menu.fontWeight) : theme.SelectComponent.Menu.fontWeight,
            zIndex: "10000",
        }),
        menuList: (provided) => ({
            ...provided,
            paddingTop: SortIcons === true ? "0px" : "2px",
            zIndex: "100000",
        }),

        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
            let isAlternate = data.alternateRow;

            if (AlternateRowStyle) {
                return {
                    ...styles,
                    padding: theme.SelectComponent.Options.padding,
                    backgroundColor: isSelected
                        ? theme.SelectComponent.Options.BackgroundColorSelected
                        : isFocused
                        ? theme.SelectComponent.Options.BackgroundColorHovered
                        : isAlternate === true
                        ? theme.SelectComponent.Options.BackgroundAlternate
                        : theme.SelectComponent.Options.BackgroundColor,
                    color: isSelected ? theme.SelectComponent.Options.ColorSelected : isFocused ? theme.SelectComponent.Options.ColorHovered : theme.SelectComponent.Options.Color,
                    fontWeight: isSelected ? theme.SelectComponent.Options.FontWeightSelected : theme.SelectComponent.Menu.fontWeight,
                    ":active": {
                        ...styles[":active"],
                        backgroundColor: isSelected ? theme.SelectComponent.Options.BackgroundColorSelected : theme.SelectComponent.Options.BackgroundColorHovered,
                    },
                };
            } else {
                return {
                    ...styles,
                    padding: theme.SelectComponent.Options.padding,
                    backgroundColor: isSelected
                        ? theme.SelectComponent.Options.BackgroundColorSelected
                        : isFocused
                        ? theme.SelectComponent.Options.BackgroundColorHovered
                        : theme.SelectComponent.Options.BackgroundColor,
                    color: isSelected ? theme.SelectComponent.Options.ColorSelected : isFocused ? theme.SelectComponent.Options.ColorHovered : theme.SelectComponent.Options.Color,
                    fontWeight: isSelected ? theme.SelectComponent.Options.FontWeightSelected : theme.SelectComponent.Menu.fontWeight,
                    ":active": {
                        ...styles[":active"],
                        backgroundColor: isSelected ? theme.SelectComponent.Options.BackgroundColorSelected : theme.SelectComponent.Options.BackgroundColorHovered,
                    },
                };
            }
        },
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "2px 8px 2px 8px",
        }),
        clearIndicator: (provided) => ({
            ...provided,
            padding: "2px 8px 2px 8px",
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            marginBottom: Variant ? (Variant === "naked" ? "2px" : "0px") : "2px",
            marginTop: Variant ? (Variant === "naked" ? "2px" : "0px") : "2px",
        }),
    };
    const FinalMenuOptions = {
        minMenuHeight: minMenuHeight ? parseInt(minMenuHeight) : null,
        maxMenuHeight: maxMenuHeight ? parseInt(maxMenuHeight) : null,
        menuPlacement: menuPlacement ? menuPlacement : "auto",
    };
    /********************* Styling the Select components ******************/

    /********************* Custom toolbar in the select menu with sort and other custom elements ******************/

    var FinalDisabled = false;
    if (Disabled === true) {
        FinalDisabled = true;
    } else {
        if (ReadOnly === true) {
            FinalDisabled = true;
        }
    }
    /********************* Custom toolbar in the select menu with sort and other custom elements ******************/

    const MenuList = (props) => {
        if (SortIcons === true && MultiSelect === false) {
            var IconAscDsc;
            if (SortedDirection === "Asc") {
                IconAscDsc = <SortBarsAsc IconSize="18px" FrameFillColor="" FrameStrokeColor="" InnerPadding="35" FrameStrokeWidth="10" SVGStrokeWidth="10" SVGStrokeHoverColor="black" />;
            } else {
                IconAscDsc = <SortBarsDesc IconSize="18px" FrameFillColor="" FrameStrokeColor="" InnerPadding="35" FrameStrokeWidth="10" SVGStrokeWidth="10" SVGStrokeHoverColor="black" />;
            }

            return (
                <components.MenuList {...props}>
                    <div className={classes.TopMenuListToolbarWrapper}>
                        <div
                            className={classes.TopMenuListSearchInput}
                            style={{
                                fontSize: InputFontProp.fontSize,
                                fontFamily: InputFontProp.fontFamily,
                                fontWeight: InputFontProp.fontWeight,
                            }}
                        >
                            <input
                                ref={Ref_MenuSearchInput}
                                style={{
                                    fontSize: "16px",
                                    fontFamily: InputFontProp.fontFamily,
                                    fontWeight: InputFontProp.fontWeight,
                                    zIndex: "1000000",
                                    // // backgroundColor: "cyan",
                                    // borderRadius: FinalBorderRadius ? FinalBorderRadius : null,
                                    // flex: "1 1 auto",
                                    // ...Style,
                                }}
                                className={classes.Input}
                                name="selectSearch"
                                placeHolder="Search..."
                                onClick={HanleInputClick}
                                onChange={HandleMenuToolbarSearch}
                                type="text"
                                autocomplete="false"
                            />
                        </div>
                        <div className={classes.ToolBarIconWrapper_Right}>
                            <div className={classes.ToolBarIcon} onClick={HandleToggleSort}>
                                {IconAscDsc}
                            </div>
                        </div>
                    </div>
                    {props.children}
                </components.MenuList>
            );
        } else {
            return <components.MenuList {...props}>{props.children}</components.MenuList>;
        }
    };

    /********************* Custom toolbar in the select menu with sort and other custom elements ******************/

    /******************* Select or Creatable select **********/
    var FormElement;
    if (AllowCreateOption) {
        FormElement = (
            <CreatableSelect
                ref={Ref_LocalInput}
                name={Name}
                isDisabled={FinalDisabled}
                styles={SelectCustomStyles}
                isClearable={Required === true ? false : Clearable === false ? false : true}
                value={MultiSelect ? Multi : Single}
                options={FinalSelectOptions}
                onChange={HandleSelectChange}
                // components={SelectComponents}
                isMulti={MultiSelect === true ? true : false}
                closeMenuOnSelect={MultiSelect === true ? false : true}
                onCreateOption={HandleCreateOption}
                isSearchable={isSearchable === false ? false : true}
                minMenuHeight={FinalMenuOptions.minMenuHeight}
                maxMenuHeight={FinalMenuOptions.maxMenuHeight}
                menuPlacement={FinalMenuOptions.menuPlacement}
                onBlur={HandleOnBlur}
                onFocus={HandleOnFocus}
                onMenuClose={HandleOnBlur}
                onMenuOpen={HandleOnFocus}
            />
        );
    } else {
        if (SortIcons === true && MultiSelect === false) {
            FormElement = (
                <Select
                    ref={Ref_LocalInput}
                    name={Name}
                    isDisabled={FinalDisabled}
                    styles={SelectCustomStyles}
                    isClearable={Required === true ? false : Clearable === false ? false : true}
                    value={MultiSelect ? Multi : Single}
                    options={FinalSelectOptions}
                    onChange={HandleSelectChange}
                    components={{MenuList}}
                    isMulti={MultiSelect === true ? true : false}
                    closeMenuOnSelect={MultiSelect === true ? false : true}
                    minMenuHeight={FinalMenuOptions.minMenuHeight}
                    maxMenuHeight={FinalMenuOptions.maxMenuHeight}
                    menuPlacement={FinalMenuOptions.menuPlacement}
                    isSearchable={false}
                    menuIsOpen={IsMenuOpen}
                    autoFocus={false}
                    backspaceRemovesValue={false}
                />
            );
        } else {
            FormElement = (
                <Select
                    ref={Ref_LocalInput}
                    name={Name}
                    isDisabled={FinalDisabled}
                    styles={SelectCustomStyles}
                    isClearable={Required === true ? false : Clearable === false ? false : true}
                    value={MultiSelect ? Multi : Single}
                    options={FinalSelectOptions}
                    onChange={HandleSelectChange}
                    isMulti={MultiSelect === true ? true : false}
                    closeMenuOnSelect={MultiSelect === true ? false : true}
                    minMenuHeight={FinalMenuOptions.minMenuHeight}
                    maxMenuHeight={FinalMenuOptions.maxMenuHeight}
                    menuPlacement={FinalMenuOptions.menuPlacement}
                    isSearchable={isSearchable === false ? false : true}
                    onBlur={HandleOnBlur}
                    onFocus={HandleOnFocus}
                    onMenuClose={HandleOnBlur}
                    onMenuOpen={HandleOnFocus}
                />
            );
        }
    }
    /******************* Select or Creatable select **********/

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldSelect.defaultProps = {
    InitialValue: null,
    FormValue: null,
    OutputSelectedValues: null,
    SelectOptions: null,
    Variant: "Outlined",
    Name: null,
    ShareFocusState: null,
    ShareHasValue: null,
    SortField: null,
    FieldMaxWidth: null,
};

FieldSelect.propTypes = {
    /**
     * InitialValue :
     */
    InitialValue: PropTypes.any,

    /**
     * FormValue :
     */
    FormValue: PropTypes.any,

    /**
     * OutputSelectedValues :
     */
    OutputSelectedValues: PropTypes.any,
    /**
     * SelectOptions :
     */
    SelectOptions: PropTypes.any,

    /**
     * Variant :
     */
    Variant: PropTypes.any,
    /**
     * Name :
     */
    Name: PropTypes.any,

    /**
     * ShareFocusState :
     */
    ShareFocusState: PropTypes.any,

    /**
     * ShareHasValue :
     */
    ShareHasValue: PropTypes.any,
    /**
     * SortField :
     */
    SortField: PropTypes.any,
    /**
     * FieldMaxWidth :
     */
    FieldMaxWidth: PropTypes.any,
};

export default FieldSelect;
