/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Page from "../../../../../../artibulles-cis/Page/Page";

import _ from "lodash";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
const {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, addHours} = require("date-fns");

import AccountingTransactionEntryEditForm from "./AccountingTransactionEntryEditForm";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "10px",
    },
}));

const Component = (props) => {
    const {Id, HandleCloseViewCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const LocationParams = useParams();
    const Location = useLocation();
    const PathName = Location.pathname;
    const History = useHistory();

    let FinalId = Id;
    let OpenInModal = false;
    let InitialCall = {type: "Edit", ID: FinalId};

    let FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companyaccount/transaction`,
        DELETE: "",
        PUT: "",
        POST: "new", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("redirect", args);
        // if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
        //     if (OpenInModal === false) {
        //         HandleCloseViewCard(true);
        //         // History.push(`/tasktimemanagement/tasks/alltasks`);
        //     } else {
        //         History.push(`/tasktimemanagement/tasks`);
        //     }
        // }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Data", data);
        var Transaction = null;
        if (data.Transaction) {
            Transaction = data.Transaction;
        }
        var Categories = [];
        if (data.Categories && data.Categories.length > 0) {
            Categories = data.Categories.map((elem) => {
                return {value: elem._id, label: elem.category_name};
            });
        }
        var Priorities = [];
        if (data.Priorities && data.Priorities.length > 0) {
            Priorities = data.Priorities.map((elem) => {
                return {value: elem._id, label: elem.piority_name, order: elem.priority_order};
            });
        }

        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }

        var DurationSecondsInit;
        if (Transaction && Transaction.estimated_duration_seconds) {
            let TimeFromSeconds = convertSecondsToTime(Transaction.estimated_duration_seconds).split(":");

            console.log("TimeFromSeconds", TimeFromSeconds);
            let TimeNow = setMinutes(setHours(Date.now(), TimeFromSeconds[0]), TimeFromSeconds[1]);
            DurationSecondsInit = formatISO(TimeNow);
        } else {
            let TimeNow = setMinutes(setHours(Date.now(), 1), 0);
            DurationSecondsInit = formatISO(TimeNow);
        }

        let VATJustificationFiles = [];
        
        if (Transaction.accounting_Justification_File_Path && Array.isArray(Transaction.accounting_Justification_File_Path) && Transaction.accounting_Justification_File_Path.length > 0) {
            Transaction.accounting_Justification_File_Path.forEach((elem) => {
                VATJustificationFiles.push(elem);
            });
        }
		
        
        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Transaction ? Transaction._id : "new",
                transaction_date_Iso: Transaction ? Transaction.transaction_date_Iso : null,
                banking_effective_date_Iso: Transaction ? Transaction.banking_effective_date_Iso : null,
                banking_amount_transaction: Transaction
                    ? new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(Transaction.banking_amount_transaction)
                    : null,
                original_amount_transaction: Transaction ? Transaction.original_amount_transaction : null,
                type_expense_income: Transaction ? Transaction.type_expense_income : null,
                transaction_source: Transaction ? Transaction.transaction_source : null,
                myCompany_bankAccount_number: Transaction ? Transaction.myCompany_bankAccount_number : null,
                credit_card_number: Transaction ? Transaction.credit_card_number : null,
                counterparty_bankAccount_number: Transaction ? Transaction.counterparty_bankAccount_number : null,
                counterparty_bankAccount_bic: Transaction ? Transaction.counterparty_bankAccount_bic : null,
                counterparty_bankAccount_name: Transaction ? Transaction.counterparty_bankAccount_name : null,
                counterparty_bankAccount_address: Transaction ? Transaction.counterparty_bankAccount_address : null,
                communication: Transaction ? Transaction.communication : null,
                location: Transaction ? Transaction.location : null,
                location: Transaction ? Transaction.location : null,
                merchant: Transaction ? Transaction.merchant : null,
                accounting_Justification_needed: Transaction ? Transaction.accounting_Justification_needed : null,
                accounting_Justification_Provided: Transaction ? Transaction.accounting_Justification_Provided : null,
                accounting_Justification_Provided_Date: Transaction
                    ? Transaction.accounting_Justification_Provided_Date
                        ? Transaction.accounting_Justification_Provided_Date
                        : formatISO(new Date(Date.now()))
                    : formatISO(new Date(Date.now())),

                customer_name: Transaction ? Transaction.customer_name : null,
                vat_amount: Transaction ? (Transaction.vat_amount ? Transaction.vat_amount.toString().replace(".", ",") : null) : null,
                amount_with_vat: Transaction ? (Transaction.amount_with_vat ? Transaction.amount_with_vat.toString().replace(".", ",") : null) : null,
                amount_without_vat: Transaction ? (Transaction.amount_without_vat ? Transaction.amount_without_vat.toString().replace(".", ",") : null) : null,
                is_VAT_Intracomm: Transaction ? (Transaction.is_VAT_Intracomm === true ? true : false) : false,
                accounting_note: Transaction ? Transaction.accounting_note : null,
                accouting_communication: Transaction ? Transaction.accouting_communication : null,
            },
            SelectOptions: {
                ref_taskPriority: Priorities,
                ref_taskCategory: Categories,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                accounting_Justification_File_Path: VATJustificationFiles,
            },
            InitiaFormErrors: {},
            InitialFormErrorMain: FormErrorMain,
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            let FilesUpload = FullSubmission.FilesUploader;
			console.log("FilesUpload",FilesUpload)
            let FilepathFinal;
            if (FilesUpload && FilesUpload.accounting_Justification_File_Path && Array.isArray(FilesUpload.accounting_Justification_File_Path) && FilesUpload.accounting_Justification_File_Path.length > 0) {
                FilepathFinal = FilesUpload.accounting_Justification_File_Path;
            } else {
                FilepathFinal = null;
            }
			

            var EstimatedDurationSeconds = 3600;
            if (values.estimated_duration_seconds) {
                let hours = getHours(parseISO(values.estimated_duration_seconds));
                let minutes = getMinutes(parseISO(values.estimated_duration_seconds));
                EstimatedDurationSeconds = hours * 3600 + minutes * 60;
            }

            var Newcategory = null;
            if (FullSubmission.NewCreatedOptions) {
                Newcategory = values.ref_taskCategory;
                //new options for categories
            }

            let FinalCompletedDate = null;
            if (values.is_completed === true) {
                FinalCompletedDate = values.completed_date_Iso;
            }
            

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Newcategory: Newcategory,
                    Transaction: {
                        Updatable: {
                            accounting_Justification_needed: values.accounting_Justification_needed,
                            accounting_Justification_Provided: values.accounting_Justification_Provided,
                            accounting_Justification_Provided_Date: values.accounting_Justification_Provided_Date,
                            vat_amount: values.vat_amount,
                            amount_with_vat: values.amount_with_vat,
                            amount_without_vat: values.amount_without_vat,
                            is_VAT_Intracomm: values.is_VAT_Intracomm,
                            accounting_note: values.accounting_note,
                            accouting_communication: values.accouting_communication,
                            customer_name: values.customer_name,
                            accounting_Justification_File_Path: FilepathFinal,
							Invoices_File_Path: FilepathFinal,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <Page
            // TabRef={Ref_TabNavContent}
            // ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            WithPerfectScrollBar={true}
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                WithCardModal={OpenInModal}
                CardModalOptions={{
                    // ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    CardMaxWidth: "800px",
                    CardHeight: "700px",
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                OpenInViewMode={false}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={AccountingTransactionEntryEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "Reset"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
};

export default Component;
