import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import {useHistory} from "react-router-dom";
import Table from "../../../../../../artibulles-cis/TableNew/Table";
import CardModal from "@artibulles-cis/react/CardModal";
import {isValid, format, parseISO} from "date-fns";
import {PDF, DuplicateDocument} from "@artibulles-cis/react-icons";

import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";
import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import _ from "lodash";

import InvoiceEdit from "./InvoiceEdit/InvoiceEdit";
import InvoiceDuplicate from "./InvoiceEdit/InvoiceDuplicate";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PageContentExtends: {
        // background: "black",
        // backgroundImage: "url('/images/HomePageBackground.jpg')",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "50% 50%",
    },
    MainWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    ToolBar: {
        display: "flex",
        flexDirection: "column",
        flex: "0 0 auto",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    TableWrapper: {
        flex: "1 0 auto",
        display: "flex",

        width: "100%",
        maxWidth: "100%",
        // height: "100%",
        padding: "10px 0px",
        position: "relative",
        boxSizing: "border-box",

        margin: "0 auto",
        justifyContent: "center",
    },
}));

const TransactionsAll = React.memo(function TransactionsAll(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_FormValues = useRef(null);
	const Ref_FieldOptions = useRef(null);
	
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const History = useHistory();
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const ColomnsInput = [
        {
            width: "130px",
            caption: "Invoice Date",
            datafield: "InvoiceSupplierDateIso",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "dd-MMM-yyyy");
                } else {
                    return value;
                }
            },

            fixedCol: false,
            searchable: false,
            sortable: true,
            initialSort: {order: 1, sortDirection: "Dsc"},
            datatype: "date",
        },
        {
            width: "80px",
            caption: "Status",
            datafield: "status_invoice",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },
        {
            width: "300px",
            datafield: "ref_Customer",
            lookup: {data: "Customers", searchField: "_id", returnField: "company"},
            caption: "Customer",
            searchable: false,
            datatype: "date",
            sortable: true,
            // initialSort: {order: 1, sortDirection: "dsc"},
        },

        {
            width: "auto",
            caption: "Description",
            datafield: "Description",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },
        {
            width: "300px",
            caption: "Communication",
            datafield: "InvoicePaymentExpectedCommunication",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },
        {
            width: "130px",
            caption: "Documents",
            datafield: "Invoices_File_Path",
            cellCustomRender: (value) => {
                if (value && Array.isArray(value) && value.length > 0) {
                    let CellRenderComp = [];
                    value.forEach((elem) => {
                        let IconElem = (
                            <div onClick={() => HandleViewPdf(elem.serverPath)}>
                                <PDF SVGFillColor="white" />
                            </div>
                        );
                        CellRenderComp.push(IconElem);
                    });

                    return CellRenderComp;
                } else {
                    return null;
                }
            },

            fixedCol: false,
            searchable: false,
            datatype: "text",
            sortable: true,
        },
        {
            width: "150px",
            caption: "Type",
            datafield: "TypeOfPurchase",
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapColHeader: true,
        },

        {
            width: "100px",
            caption: "Tot VAT Exc.",
            datafield: "CalcTotalVATExcluded",
            cellFormatFunction: (value, rowData) => {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Tot VAT",
            datafield: "CalcTotalVATAmount",
            cellFormatFunction: (value, rowData) => {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Tot VAT Inc.",
            datafield: "CalcTotalVATIncluded",
            cellFormatFunction: (value, rowData) => {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
    ];

    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };
    const TotalRow = {
        Show: false,
    };
    const HandleEditRow = (arg) => {
        let Id = arg._id;
        History.push(`/financials/saleordersandinvoices/invoices/invoice/${Id}/edit`);
    };
    const DuplicateInvoice = (arg) => {
        // console.log("Duplicate Document", arg);
        let Id = arg._id;
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);

        // History.push(`/financials/purchaseordersandinvoices/invoices/invoice/${Id}/edit`);
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: true,
            DefaultPageSize: 15,
            AllowedPageSizes: [15, 30, 45],
        },
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
        Searchable: true,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "Edit",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
                Tooltip: {Show: true, Position: "right", Theme: "light"},
            },
            {
                Standard: true,
                Name: "Delete",
                ActiveCondition: null,
                Position: 2,
                // OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
                Tooltip: {Show: true, Position: "right", Theme: "light"},
            },
            {
                Standard: false,
                ActiveCondition: null,
                Position: 2,
                IconActive: <DuplicateDocument IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
                IconInactive: <DuplicateDocument IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
                OnClick: DuplicateInvoice,
                Tooltip: {Show: true, Position: "right", Theme: "light", Content: "Duplicate"},
            },
        ],
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [IsInitialized, setIsInitialized] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [RefreshApi, setRefreshApi] = useState(true);
    const [IsInitalized, setIsInitalized] = useState(false);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);

    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);

    const [TableComponent, setTableComponent] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        // console.log("FormValues", Ref_FormValues.current);
        let RealQuery = "";
        let Query = Ref_FormValues.current;
        let Keys = _.keysIn(Query);
        if (Keys) {
            Keys.forEach((elem) => {
                let Value = Query[elem];
                if (Value) {
                    if (elem === "company") {
                        RealQuery = RealQuery ? RealQuery + `&companyid=${Value}` : `?companyid=${Value}`;
                    }
                    if (elem === "status_invoice") {
                        console.log("Value", Value);

                        //We need to return the label
                        if (Value === "00") {
                            //It means all -> we don't add it
                        } else {
                            console.log("FieldOptions", Ref_FieldOptions.current);
                            let Label = _.find(Ref_FieldOptions.current.status_invoice, {value: Value}).label;

                            RealQuery = RealQuery ? RealQuery + `&status_invoice=${Label}` : `?status_invoice=${Label}`;
                        }
                    }
                }
            });
        }

        // if (Ref_FormValues && Ref_FormValues.current) {
        // 	let Keys = _.keysIn(Query);
        //     let SupplierId = Ref_FormValues.current.company;
        //     let status_invoice = Ref_FormValues.current.;
        //     Query = `?companyid=${SupplierId}`;
        // }

        let FinalTableComponent = (
            <Table
                // LocalData={TableData}
                URLAbsolute={{
                    MAIN: `${ProdURL}/api/saleinvoices/invoices/queries/${RealQuery}`,
                    // MAIN: `${ProdURL}/api/purchaseinvoices/invoices/queries${RealQuery}`,
                    //
                    DELETE: `${ProdURL}/api/saleinvoices/invoice/`,
                    PUT: "",
                    POST: "",
                }}
                // URLRelative="arnaud"
                MainDataSource="Invoices"
                ColomnsInput={ColomnsInput}
                TableDimensions={TableDimensions}
                TableOptions={TableOptions}
                TotalRow={TotalRow}
                DevMode={false}
            />
        );

        setTableComponent(FinalTableComponent);
    }, []);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);
                    //We just load the cards Id into the select

                    if (Data) {
                        let SaleInvoiceStatusSelectOptions = [{label: "ALL", value: "00"}];

                        Data.SaleInvoiceStatusSelectOptions.forEach((elem) => {
                            SaleInvoiceStatusSelectOptions.push(elem);
                        });
                        setInitialFormValues({status_invoice: "00"});
                        setFormValues({status_invoice: "00"});

                        setRefreshApi(false);
                        setIsInitalized(true);
                        let SuppliersSelectOptions = Data.Customers.map((elem) => {
                            return {value: elem._id, label: elem.company};
                        });

                        setFieldOptions({company: SuppliersSelectOptions, status_invoice: SaleInvoiceStatusSelectOptions});
						Ref_FieldOptions.current = {company: SuppliersSelectOptions, status_invoice: SaleInvoiceStatusSelectOptions}
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (RefreshApi === true) {
            const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoices/initializequeries`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, RefreshApi]);

    useEffect(() => {
        if (IsInitalized === true) {
            GenerateTableComponent();
        }
    }, [IsInitalized, FormValues, ViewCardId]);

    // useEffect(() => {
    //     if (ViewCardId === null) {
    //         GenerateTableComponent();
    //     }
    // }, [FormValues, ViewCardId]);
    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleUpdateFormValues = (field, values) => {
        //Note!!! with Date Pickers it's mandatory to use the Ref and not the state ....impossible to understand why but that is it
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        setFormValues({...Ref_FormValues.current, ...{[field]: value}});
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: value}};
        // let FinalQuery = Query;

        // if (field === "SourceType") {
        // }
        // if (field === "IncomeExpense") {
        // }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        // if (field === "ConditionalSelect1") {
        //     //We update the Options of the ConditionalSelect2
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect1 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
        //     //We also need to reset the Conditional 2;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        // }
        // if (field === "ConditionalSelect2") {
        //     //We update the Options of the ConditionalSelect3
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect2 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
        //     //We also need to reset the Conditional 3;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        // }
        setTableComponent(null);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
    };

    const HandleCloseDuplicateCard = (Id) => {
        // console.log("Should close the card")
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setViewCardId(Id);
        setShowViewCard(true);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;

    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceEdit Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    if (ShowDuplicateCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceDuplicate DuplicateId={DuplicateCardId} HandleCloseViewCard={HandleCloseDuplicateCard} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <div className={classes.MainWrapper}>
                <div className={classes.ToolBar}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                            <FormCompontent
                                Name="company"
                                InitialValue={InitialFormValues.company ? InitialFormValues.company : null}
                                FormValue={FormValues.company ? FormValues.company : null}
                                OutputSelectedValues={(values) => handleSelectChange("company", values)}
                                SelectOptions={FieldOptions.company}
                                Component="Select"
                                Variant="Outlined"
                                Label="Company"
                                NoMessage={false}
                                MultiSelect={false}
                                SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                Required={true}
                                // CreateDeleteIfUnselected={}

                                LabelMaxWidth="100px"
                                LabelAlign="Left"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                            <FormCompontent
                                Name="status_invoice"
                                InitialValue={InitialFormValues.status_invoice ? InitialFormValues.status_invoice : null}
                                FormValue={FormValues.status_invoice ? FormValues.status_invoice : null}
                                OutputSelectedValues={(values) => handleSelectChange("status_invoice", values)}
                                SelectOptions={FieldOptions.status_invoice}
                                Component="Select"
                                Variant="Outlined"
                                Label="Status"
                                NoMessage={false}
                                MultiSelect={false}
                                // SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                Required={true}
                                // CreateDeleteIfUnselected={}

                                LabelMaxWidth="100px"
                                LabelAlign="Left"
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.TableWrapper}>
                    {ModalCardView}
                    {TableComponent}
                </div>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default TransactionsAll;
