import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import PickerRoller from "../PickerRoller/PickerRoller";
import {format, getMonth, getDate, getYear, getDaysInMonth, add, sub, getHours, getMinutes, getSeconds} from "date-fns";
import {GenerateHoursDay, GetAllMonthsNameLocal, GenerateMinutesInterval, FindClosestIndexToArrayWithValue} from "../utils/CalendarFunctions";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    RollerWrapper: {
        display: "flex",
        width: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

/**
 * Customizable Roller Pickers for Year - Month - Day - Hour - Minute - Second
 * The Month and year formats can be customized - Month With Locale Names and year with yy or yyyy
 * The time format can be customized by using intervals : 5min, 10s...
 * Desktop : Scrollable with mouse, swipable with mouse
 * Mobile : Scrollable with touch
 * Styles can be fully customized by theme or using custom classes or styles
 */

const CalendarRollerPickers = React.memo(function CalendarRollerPickers(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        CustomType,
        QuickType,
        InitialDate,
        OutputDate,
        Locale,
        Rows,
        //
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [IsInitialized, setIsInitialized] = useState(false);
    const [InitialValues, setInitialValues] = useState({dayIndex: null, monthIndex: null, hourIndex: null, minuteIndex: null, secondIndex: null});
    const [SelectedDate, setSelectedDate] = useState({date: null, monthNumber: null, day: null, year: null, hour: null, minute: null, second: null});
    const [Months, setMonths] = useState(null);
    const [MonthLength, setMonthLength] = useState(null);
    const [Hours, setHours] = useState(null);
    const [Minutes, setMinutes] = useState(null);
    const [Seconds, setSeconds] = useState(null);

    const [DaysRollerComponent, setDaysRollerComponent] = useState(null);
    const [MonthsRollerComponent, setMonthsRollerComponent] = useState(null);
    const [YearsRollerComponent, setYearsRollerComponent] = useState(null);
    const [HoursRollerComponent, setHoursRollerComponent] = useState(null);
    const [MinutesRollerComponent, setMinutesRollerComponent] = useState(null);
    const [SecondsRollerComponent, setSecondsRollerComponent] = useState(null);

    const [InitialRollerComponents, setInitialRollerComponents] = useState([]);
    const [RollerComponents, setRollerComponents] = useState([]);
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const GenerateFinalComponents = useCallback(() => {
		
        let FinalComps = [];
        InitialRollerComponents.forEach((elem) => {
            var UpdateSelected, InitialVal, InputArray, RollerType;
            if (elem.Roller === "Year") {
                UpdateSelected = "Year";
                RollerType = "Years";
            }
            if (elem.Roller === "Month") {
                UpdateSelected = "Month";
                InitialVal = "monthIndex";
                InputArray = Months;
            }
            
            if (elem.Roller === "Day") {
                UpdateSelected = "Day";
                RollerType = "Days";
                InitialVal = "dayIndex";
            }

            if (elem.Roller === "Hour") {
                UpdateSelected = "Hour";
                InitialVal = "hourIndex";
                InputArray = Hours;
            }
            if (elem.Roller === "Minute") {
                UpdateSelected = "Minute";
                InitialVal = "minuteIndex";
                InputArray = Minutes;
            }
            if (elem.Roller === "Second") {
                UpdateSelected = "Second";
                InitialVal = "secondIndex";
                InputArray = Seconds;
            }

            let RollerElem = (
                <PickerRoller
                    InputArray={InputArray}
                    Width={elem.props.Width}
                    AutoWidth={elem.props.AutoWidth}
                    SlidesToShow={elem.props.SlidesToShow}
                    Alignement={elem.props.Alignement}
                    UpdateSelected={(selected) => HandleUpdatedSelected(UpdateSelected, selected)}
                    InitialIndex={InitialValues[InitialVal]}
                    RollerName={elem.props.RollerName}
                    RollerType={RollerType}
                    InitialYear={InitialValues.yearValue}
                    RollerMonthLength={MonthLength}
                />
            );
            FinalComps.push(RollerElem);
        });

        setRollerComponents(FinalComps);
    }, [Months, MonthLength, Hours, Minutes, Seconds]);

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Called When Mounting - based on props options
    useEffect(() => {
        //We only call it when it has not been initialized yet to prevent re-rendering
        if (IsInitialized === false) {
            var DateObj = InitialDate ? InitialDate : new Date(Date.now());
            //Generating the initial inputs for the state
            let DateInit = {
                date: DateObj,
                monthNumber: getMonth(DateObj),
                day: getDate(DateObj),
                year: getYear(DateObj),
                hour: getHours(DateObj),
                minute: getMinutes(DateObj),
                second: getSeconds(DateObj),
            };

            var RenderingRollers = [];
            var YearFormat = "yyyy",
                MonthFormat = "LLL",
                MinuteInterval = 1,
                SecondInterval = 1;

            //Creating the structure of the Rollers
            if (CustomType) {
                if (Array.isArray(CustomType) && CustomType.length > 0) {
                    CustomType.forEach((elem) => {
                        let RollerAutoWidth = elem.Width ? false : true;
                        if (elem.Roller === "Day") {
                            let RollerElem = {
                                Roller: "Day",
                                props: {
                                    Width: elem.Width,
                                    AutoWidth: RollerAutoWidth,
                                    SlidesToShow: Rows,
                                    Alignement: elem.Alignement ? elem.Alignement : "center",
                                    RollerName: "Day",
                                },
                            };

                            RenderingRollers.push(RollerElem);
                        }
                        if (elem.Roller === "Month") {
                            if (elem.Format) {
                                if (elem.Format === "L" || elem.Format === "LL" || elem.Format === "LLL" || elem.Format === "LLLL") {
                                    MonthFormat = elem.Format;
                                }
                            }
                            let RollerElem = {
                                Roller: "Month",
                                props: {
                                    Width: elem.Width,
                                    AutoWidth: RollerAutoWidth,
                                    SlidesToShow: Rows,
                                    Alignement: elem.Alignement ? elem.Alignement : "center",
                                    RollerName: "Month",
                                },
                            };

                            RenderingRollers.push(RollerElem);
                        }
                        if (elem.Roller === "Year") {
                            if (elem.Format) {
                                if (elem.Format === "yy" || elem.Format === "yyyy") {
                                    YearFormat = elem.Format;
                                }
                            }
                            let RollerElem = {
                                Roller: "Year",
                                props: {
                                    Width: elem.Width,
                                    AutoWidth: RollerAutoWidth,
                                    SlidesToShow: Rows,
                                    Alignement: elem.Alignement ? elem.Alignement : "center",
                                    RollerName: "Year",
                                    RollerType: "Years",
                                },
                            };

                            RenderingRollers.push(RollerElem);
                        }
                        if (elem.Roller === "Hour") {
                            let RollerElem = {
                                Roller: "Hour",
                                props: {
                                    Width: elem.Width,
                                    AutoWidth: RollerAutoWidth,
                                    SlidesToShow: Rows,
                                    Alignement: elem.Alignement ? elem.Alignement : "center",
                                    RollerName: "Hour",
                                },
                            };

                            RenderingRollers.push(RollerElem);
                        }
                        if (elem.Roller === "Minute") {
                            if (elem.Interval) {
                                MinuteInterval = parseInt(elem.Interval);
                            }
                            let RollerElem = {
                                Roller: "Minute",
                                props: {
                                    Width: elem.Width,
                                    AutoWidth: RollerAutoWidth,
                                    SlidesToShow: Rows,
                                    Alignement: elem.Alignement ? elem.Alignement : "center",
                                    RollerName: "Minute",
                                },
                            };

                            RenderingRollers.push(RollerElem);
                        }
                        if (elem.Roller === "Second") {
                            if (elem.Interval) {
                                SecondInterval = parseInt(elem.Interval);
                            }
                            let RollerElem = {
                                Roller: "Second",
                                props: {
                                    Width: elem.Width,
                                    AutoWidth: RollerAutoWidth,
                                    SlidesToShow: Rows,
                                    Alignement: elem.Alignement ? elem.Alignement : "center",
                                    RollerName: "Second",
                                },
                            };
                            RenderingRollers.push(RollerElem);
                        }
                    });
                }
            } else if (QuickType && !CustomType) {
                if (QuickType.Roller === "Calendar") {
                    let DayElem = {
                        Roller: "Day",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Day",
                        },
                    };

                    RenderingRollers.push(DayElem);

                    if (QuickType.MonthFormat) {
                        if (QuickType.MonthFormat === "L" || QuickType.MonthFormat === "LL" || QuickType.MonthFormat === "LLL" || QuickType.MonthFormat === "LLLL") {
                            MonthFormat = QuickType.MonthFormat;
                        }
                    }
                    let MonthElem = {
                        Roller: "Month",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",

                            RollerName: "Month",
                        },
                    };

                    RenderingRollers.push(MonthElem);

                    if (QuickType.YearFormat) {
                        if (QuickType.YearFormat === "yy" || QuickType.YearFormat === "yyyy") {
                            YearFormat = QuickType.YearFormat;
                        }
                    }
                    let YearElem = {
                        Roller: "Year",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Year",
                            RollerType: "Years",
                        },
                    };

                    RenderingRollers.push(YearElem);
                }
				if (QuickType.Roller === "Year") {
					if (QuickType.YearFormat) {
                        if (QuickType.YearFormat === "yy" || QuickType.YearFormat === "yyyy") {
                            YearFormat = QuickType.YearFormat;
                        }
                    }
                    let YearElem = {
                        Roller: "Year",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Year",
                            RollerType: "Years",
                        },
                    };

                    RenderingRollers.push(YearElem);

				}
				if (QuickType.Roller === "Month") {
					if (QuickType.MonthFormat) {
                        if (QuickType.MonthFormat === "L" || QuickType.MonthFormat === "LL" || QuickType.MonthFormat === "LLL" || QuickType.MonthFormat === "LLLL") {
                            MonthFormat = QuickType.MonthFormat;
                        }
                    }
                    let MonthElem = {
                        Roller: "Month",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Month",
                        },
                    };

                    RenderingRollers.push(MonthElem);

				}
                if (QuickType.Roller === "MonthYear") {
                    if (QuickType.MonthFormat) {
                        if (QuickType.MonthFormat === "L" || QuickType.MonthFormat === "LL" || QuickType.MonthFormat === "LLL" || QuickType.MonthFormat === "LLLL") {
                            MonthFormat = QuickType.MonthFormat;
                        }
                    }
                    let MonthElem = {
                        Roller: "Month",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",

                            RollerName: "Month",
                        },
                    };

                    RenderingRollers.push(MonthElem);

                    if (QuickType.YearFormat) {
                        if (QuickType.YearFormat === "yy" || QuickType.YearFormat === "yyyy") {
                            YearFormat = QuickType.YearFormat;
                        }
                    }
                    let YearElem = {
                        Roller: "Year",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Year",
                            RollerType: "Years",
                        },
                    };

                    RenderingRollers.push(YearElem);
                }
                if (QuickType.Roller === "TimeHMS") {
                    let HourRollerElem = {
                        Roller: "Hour",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Hour",
                        },
                    };

                    RenderingRollers.push(HourRollerElem);

                    if (QuickType.MinuteInterval) {
                        MinuteInterval = parseInt(QuickType.MinuteInterval);
                    }
                    let MinuteRollerElem = {
                        Roller: "Minute",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Minute",
                        },
                    };

                    RenderingRollers.push(MinuteRollerElem);
                    if (QuickType.SecondInterval) {
                        SecondInterval = parseInt(QuickType.SecondInterval);
                    }

                    let SecondRollerElem = {
                        Roller: "Second",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Second",
                        },
                    };
                    RenderingRollers.push(SecondRollerElem);
                }
                if (QuickType.Roller === "TimeHM") {
                    let HourRollerElem = {
                        Roller: "Hour",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Hour",
                        },
                    };

                    RenderingRollers.push(HourRollerElem);

                    if (QuickType.MinuteInterval) {
                        MinuteInterval = parseInt(QuickType.MinuteInterval);
                    }
                    let MinuteRollerElem = {
                        Roller: "Minute",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Minute",
                        },
                    };
                    RenderingRollers.push(MinuteRollerElem);
                }
                if (QuickType.Roller === "TimeMS") {
                    if (QuickType.MinuteInterval) {
                        MinuteInterval = parseInt(QuickType.MinuteInterval);
                    }
                    let MinuteRollerElem = {
                        Roller: "Minute",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Minute",
                        },
                    };

                    RenderingRollers.push(MinuteRollerElem);
                    if (QuickType.SecondInterval) {
                        SecondInterval = parseInt(QuickType.SecondInterval);
                    }

                    let SecondRollerElem = {
                        Roller: "Second",
                        props: {
                            AutoWidth: true,
                            SlidesToShow: Rows,
                            Alignement: "center",
                            RollerName: "Second",
                        },
                    };
                    RenderingRollers.push(SecondRollerElem);
                }
            } else {
                //The user forgot to pass something
            }

            //Initiating the Arrays and formats for the rollers

            //Generating the Months array
            const MonthsInit = GetAllMonthsNameLocal({locale: Locale, monthFormat: MonthFormat});
            const MonthLengthInit = getDaysInMonth(DateObj);

            //Generating the hours array
            const HoursInit = GenerateHoursDay({});
            //Finding the closest Index for the Hours
            const CurrentHour = getHours(DateObj);

            var HourInitIndex = FindClosestIndexToArrayWithValue({InputArray: HoursInit, Value: CurrentHour});

            //Generating the minutes array
            const MinutesInit = GenerateMinutesInterval({interval: MinuteInterval});
            //Finding the closest Index for the minutes
            const CurrentMin = getMinutes(DateObj);
            var MinInitIndex = FindClosestIndexToArrayWithValue({InputArray: MinutesInit, Value: CurrentMin});
            MinInitIndex = !MinInitIndex ? 0 : MinInitIndex;

            //Generating the Seconds array
            const SecondsInit = GenerateMinutesInterval({interval: SecondInterval});
            //Finding the closest Index for the seconds
            const CurrentSec = getSeconds(DateObj);
            var SecInitIndex = FindClosestIndexToArrayWithValue({InputArray: SecondsInit, Value: CurrentSec});
            SecInitIndex = !SecInitIndex ? 0 : SecInitIndex;

            setMonthLength(MonthLengthInit);
            setMonths(MonthsInit);
            setHours(HoursInit);
            setMinutes(MinutesInit);
            setSeconds(SecondsInit);
            setSelectedDate(DateInit);
            setInitialValues({
                dayIndex: getDate(DateObj) - 1,
                monthIndex: getMonth(DateObj),
                yearValue: getYear(DateObj),
                hourIndex: HourInitIndex.Index,
                minuteIndex: MinInitIndex.Index,
                secondIndex: SecInitIndex.Index,
            });

            setIsInitialized(true);
            setInitialRollerComponents(RenderingRollers);
            if (OutputDate && typeof OutputDate === "function") {
                OutputDate(DateObj);
            }
        }
    }, [IsInitialized]);

    useEffect(() => {
        if (IsInitialized === true) {
            GenerateFinalComponents();
        }
    }, [IsInitialized, Hours, Minutes, Seconds, Months, MonthLength, GenerateFinalComponents]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const HandleUpdatedSelected = (roller, selected) => {
        var UpdatedDate = SelectedDate;
        
        if (selected !==null) {
			
            var UpdateState = false;
            if (roller === "Month") {
                if (SelectedDate.monthNumber !== selected) {
                    UpdateState = true;
                    //First we need to find the equivalent day by adding or substracting a month to ensure we don't screw up months with less days
                    let NewDayOfNewMonth;
                    if (selected > SelectedDate.monthNumber) {
                        NewDayOfNewMonth = getDate(add(SelectedDate.date, {months: 1}));
                    } else {
                        NewDayOfNewMonth = getDate(sub(SelectedDate.date, {months: 1}));
                    }
                    let NewDate = new Date(SelectedDate.year, selected, NewDayOfNewMonth, SelectedDate.hour, SelectedDate.minute, SelectedDate.second);
                    UpdatedDate.date = NewDate;
                    UpdatedDate.monthNumber = selected;
                    UpdatedDate.day = NewDayOfNewMonth - 1;
                    const NewMonthLength = getDaysInMonth(NewDate);

                    setMonthLength(NewMonthLength);
                    setInitialValues({...InitialValues, ...{dayIndex: NewDayOfNewMonth - 1, monthIndex: getMonth(NewDate)}});
                }
            }
            if (roller === "Day") {
                if (SelectedDate.day !== selected) {
                    UpdateState = true;
                    let NewDate = new Date(SelectedDate.year, SelectedDate.monthNumber, selected + 1, SelectedDate.hour, SelectedDate.minute, SelectedDate.second);
                    UpdatedDate.date = NewDate;
                    UpdatedDate.day = selected;
                }
            }
            if (roller === "Year") {
                if (SelectedDate.year !== selected) {
                    UpdateState = true;
                    //First we need to find the equivalent day by adding or substracting a month to ensure we don't screw up months with less days
                    let NewDayOfNewMonth;
                    if (selected > SelectedDate.year) {
                        NewDayOfNewMonth = getDate(add(SelectedDate.date, {years: 1}));
                    } else {
                        NewDayOfNewMonth = getDate(sub(SelectedDate.date, {years: 1}));
                    }
                    let NewDate = new Date(selected, SelectedDate.monthNumber, NewDayOfNewMonth, SelectedDate.hour, SelectedDate.minute, SelectedDate.second);

                    UpdatedDate.date = NewDate;
                    UpdatedDate.day = NewDayOfNewMonth - 1;
                    UpdatedDate.year = selected;

                    const NewMonthLength = getDaysInMonth(NewDate);
                    setMonthLength(NewMonthLength);
                    setInitialValues({...InitialValues, ...{dayIndex: NewDayOfNewMonth - 1, monthIndex: getMonth(NewDate)}});
                }
            }

            if (roller === "Hour") {
                let Val = Hours[selected];
                
                if (SelectedDate.hour !== Val) {
                    UpdateState = true;

                    let NewDate = new Date(SelectedDate.year, SelectedDate.monthNumber, SelectedDate.day, Val, SelectedDate.minute, SelectedDate.second);
                    UpdatedDate.date = NewDate;
                    UpdatedDate.hour = Val;
                }
            }
            if (roller === "Minute") {
                let Val = Minutes[selected];
                if (SelectedDate.minute !== Val) {
                    UpdateState = true;

                    let NewDate = new Date(SelectedDate.year, SelectedDate.monthNumber, SelectedDate.day, SelectedDate.hour, Val, SelectedDate.second);
                    UpdatedDate.date = NewDate;
                    UpdatedDate.minute = Val;
                }
            }
            if (roller === "Second") {
                let Val = Seconds[selected];
                if (SelectedDate.second !== Val) {
                    UpdateState = true;

                    let NewDate = new Date(SelectedDate.year, SelectedDate.monthNumber, SelectedDate.day, SelectedDate.hour, SelectedDate.minute, Val);
                    UpdatedDate.date = NewDate;
                    UpdatedDate.second = Val;
                }
            }

            //Reset the go to month
            if (UpdateState === true) {
                setSelectedDate(UpdatedDate);
                if (OutputDate && typeof OutputDate === "function") {
                    OutputDate(UpdatedDate.date);
                }
            }
        }
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var FinalComponent;

    if (IsInitialized === true) {
        FinalComponent = RollerComponents;
    } else {
        FinalComponent = null;
    }
    return <div className={classes.RollerWrapper}>{FinalComponent}</div>;

    /****************************** RENDER *********************/
});
// {DaysRollerComponent}
// {MonthsRollerComponent}

CalendarRollerPickers.defaultProps = {
    CustomType: null,
    QuickType: null,
    InitialDate: null,
    OutputDate: null,
    Locale: null,
    Rows: 5,
};

CalendarRollerPickers.propTypes = {
    /**
     * CustomType : "Use this to gain granular control on the picker Roller."
     * You need to pass an array, the display order is the array order
     * Structure :
     * CustomType = [
     * {Roller : "Day", Width : "150px", Alignement:"left-right-center"},
     * {Roller : "Month", Format : "L-LL-LLL-LLLL", Locale : datefnsLocaleObj, Width : "30px"},
     * {Roller : "Year", Format : "yy-yyyy", MinYear : "2015", MaxYear:"2025", Width : "40px"},
     * {Roller : "Hour", Width : "150px"},
     * {Roller : "Minute", Interval : 5, Width : "25px},
     * {Roller : "Second", Interval : 5, Width : "200px"},
     * ]
     */
    CustomType: PropTypes.array,
    /**
     * QuickType : "Use this for quick pickers with less options."
     * Structure :
     * QuickType = {
     * Roller : "Calendar-TimeHMS - TimeHM- TimeMS - MonthYear", YearFormat:"yy-yyyy", MonthFormat:"L-LL-LLL-LLLL", HourInterval :5, MinuteInterval :5, SecondInterval : 5, Locale: datefnsLocaleObj},
     * }
     */
    QuickType: PropTypes.object,
    /**
     * InitialDate : Javascript Date Object to set the initial date
     */
    InitialDate: PropTypes.any,
    /**
     * OutputDate : a function that you use to manipulate the return date - a Javascript date object is returned
     * In the parent Component :  OutpuDate={HandleOutputDate}
     * HandleOutptutDate = (date) =>{
     * Whatever you want
     * }
     */
    OutputDate: PropTypes.any,
    /**
     * Locale : a date-fns locale object that you need to import yourself. Default in en-US
     * you need to import them yourself from the date-fns/locale folder
     * import frLocale from "date-fns/locale/fr"
     */
    Locale: PropTypes.any,
    /**
     * Rows : The number of rows to show in the roller : must be an odd number to be able to center them. Default is 7
     */
    Rows: PropTypes.any,
};

export default CalendarRollerPickers;
