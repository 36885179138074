import React, {useEffect, useState} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
 
//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const Component = props => {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
/***************** CONST ******************/
 
/***************** CONST ******************/
 
 
/***************** STATE ******************/
 
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
 
/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<div>
	  	Card Overview
	</div>
  )
 
/***************** RENDER ******************/
 
};
 
export default Component;