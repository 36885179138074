import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import CardModal from "@artibulles-cis/react/CardModal";
import CardModal from "@artibulles-cis/react/CardModal";
import {useHistory} from "react-router-dom";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Button from "@artibulles-cis/react/Button";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";

import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import InvoiceEdit from "../InvoiceEdit/InvoiceEdit";
import ReviewAndSignBulk from "./ReviewAndSignBulk";
import {PDF, CheckBoxCircularFilled, WarningTriangleRed, WarningTriangleYellow, RotateLeft} from "@artibulles-cis/react-icons";
import {parseISO, differenceInDays} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "10px",
    },

    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },

    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },

    PageWrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
    },
    ButtonBar: {
        display: "flex",
    },

    Button: {
        margin: "0px 0px 10px 0px",
        padding: "0px",
        fontSize: "0.8rem",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    const Ref_TableData = useRef(null);
    const Ref_FormValues = useRef(null);
    const Ref_FieldOptions = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/

    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    let URLAbsolute = {
        ALL: `${ProdURL}/api/securebanking/transactionstosign`,
        QUERY: null,
        DELETEONE: null,
        DELETEMANY: null,
        PUT: null,
    };

    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        if (value && Array.isArray(value) && value.length > 0) {
            let CellRenderComp = [];
            value.forEach((elem) => {
                let IconElem = (
                    <div onClick={() => HandleViewPdf(elem.serverPath)}>
                        <PDF SVGFillColor="white" />
                    </div>
                );
                CellRenderComp.push(IconElem);
            });

            return CellRenderComp;
        } else {
            return null;
        }
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };

    const HandleEditRow = (Id, RowData) => {
        let InvoiceId = RowData.Invoice._id;
        setShowEditCard(true);
        setEditCardId(InvoiceId);
    };

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 17px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];

    const TableColumns = [
        {
            field: "DateCreatedIso",
            headerName: "Created",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "Invoice.InvoicePaymentDueDateIso",
            headerName: "Due Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "RequestedExecutionDateIso",
            headerName: "Payment Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },

        {
            field: "Amount",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "Communication",
            //
            headerName: "Communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "CreditorName",
            //
            headerName: "Creditor",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "CreditorAccount",
            headerName: "Account",
            headerClass: "ag-CenterHeader",
            width: 200,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "CreditorAccountBIC",
            headerName: "bic",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "Invoice.Invoices_File_Path",
            headerName: "Docs",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: false,
            cellRenderering: PDFRendering,
            cellStyle: PDFCell,
        },
        {
            field: "PontoTransaction.BulkShortId",
            // ref_PontoTransaction
            headerName: "Bulk",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: false,
            // Type: "Boolean",
            rowGroup: true,
            // showRowGroup : true,
            hide: true,
            // cellRenderer: "agGroupCellRenderer",
            // cellRendererParams: {
            //     suppressCount: true,
            //     checkbox: false,

            //     // suppressDoubleClickExpand: true,
            //     // suppressEnterExpand: true,
            // },
        },
    ];

    const HandleRowSelectChange = (SelectedRows) => {
        console.log("SelectedRows", SelectedRows);
        if (SelectedRows.length === 0) {
            setSelectedRows(null);
        } else {
            setSelectedRows(SelectedRows);
        }
    };
    const ConditionalSelect = (args) => {
        let RowData = args.data;

        let Today = new Date(Date.now());
        let PaymentDate = parseISO(RowData.RequestedExecutionDateIso);

        if (differenceInDays(PaymentDate, Today) > 0) {
            return true;
        } else {
            return false;
        }
    };

    const TableOptions = {
        Selection: {
            Multiple: true,
            GroupOnly: true,
            // WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },
        Resizable: false,
        Sortable: false,
        Filterable: false,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [TableData, setTableData] = useState(null);
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FormValues, setFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [ApiCallType, setApiCallType] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [QueryAPi, setQueryAPi] = useState(false);
    const [IsInitalized, setIsInitalized] = useState(false);
    const [ReloadTableData, setReloadTableData] = useState(false);
    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [ShowCreateBulkCard, setShowCreateBulkCard] = useState(false);

    const [TableComponent, setTableComponent] = useState(null);
    const [SelectedRows, setSelectedRows] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                Data={Ref_TableData.current}
                // URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                // MainDataSource="PontoInvoiceTransactions"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                PaginationOptions={{
                    Pagination: true,
                    PageSize: 50,
                }}
            />
        );

        setTableComponent(FinalTableComponent);
    }, []);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!ApiCallType) {
                    if (ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Select Data", Data);
                        //We just load the cards Id into the select

                        setQueryAPi(true);
                    }
                } else if (ApiCallType === "TableQuery") {
                    if (ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        let PontoInvoiceTransactions = Data.PontoInvoiceTransactions;
                        console.log("PontoInvoiceTransactions", PontoInvoiceTransactions);
                        if (PontoInvoiceTransactions) {
                            setTableData(PontoInvoiceTransactions);
                            Ref_TableData.current = PontoInvoiceTransactions;
                            setIsInitalized(true);
                        } else {
                            //No Data
                        }

                        //We just load the cards Id into the select
                        setQueryAPi(false);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/securebanking/dbinvoicetransactions/initselects`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, [ProdURL]);

    useEffect(() => {
        if (QueryAPi === true) {
            setApiCallType("TableQuery");
            const ApiCall = {url: `${ProdURL}/api/securebanking/dbinvoicetransactions/queries/?isbulk=true`, type: "get"};

            // const ApiCall = {url: `${ProdURL}/api/securebanking/dbinvoicetransactions/queries/?isbulk=true`, type: "get"};
            // const ApiCall = {url: `${ProdURL}/api/securebanking/dbinvoicetransactions/queries/`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, QueryAPi]);

    useEffect(() => {
        if (IsInitalized === true) {
            GenerateTableComponent();
        }
    }, [IsInitalized, GenerateTableComponent]);

    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCloseEditCard = () => {
        setShowEditCard(false);
        setEditCardId(null);
        setShowCreateBulkCard(false);

        setTimeout(() => {
            setReloadTableData(true);
        }, 300);
    };

    const HandleCloseCreateBulkCard = (Id) => {
        // console.log("Should close the card")
        setShowCreateBulkCard(false);
    };
    const HandleSignBulk = () => {
        // console.log("Sign Bulk Payment", SelectedRows);
        // if (SelectedRows && Array.isArray(SelectedRows)) {
        //     let SignUrl = SelectedRows[0].PontoTransaction.PontoSignUrl;
        //     if (SignUrl) {
        //         window.open(SignUrl);
        //     }
        // }
        setShowCreateBulkCard(true);

        // let SignLink = "https://dashboard.myponto.com/organizations/e175cbd5-d4e1-41ef-81e0-d456d76c6823/live/accounts/cb585984-aafe-4658-a5ad-0c8715063d37/payments/signable";
        // window.open(SignLink);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;

    if (ShowEditCard === true) {
        ModalCard = (
            <CardModal
                CardID="EditInvoiceCard"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <InvoiceEdit Id={EditCardId} HandleCloseCard={HandleCloseEditCard} />
            </CardModal>
        );
    }
    if (ShowCreateBulkCard === true) {
        ModalCard = (
            <CardModal
                CardID="DuplicateInvoiceCard"
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Sign Bulk</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseCreateBulkCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="1100px"
                // CardHeight="600px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                // ClassExtendCard={classes.ClassExtendCard}
            >
                <ReviewAndSignBulk TableData={SelectedRows} HandleCloseCard={HandleCloseCreateBulkCard} />
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {ModalCard}
            <div className={classes.PageWrapper}>
                <div className={classes.ButtonBar} style={{display: `${SelectedRows ? "flex" : "none"}`}}>
                    <Button Width="150px" Height="30px" ClassExtendButton={classes.Button} onClick={HandleSignBulk}>
                        Review & Sign Bulk
                    </Button>
                </div>
                <div className={classes.TableWrapper}>{TableComponent}</div>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;

//
