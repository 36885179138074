import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import InvoiceItemsTable from "../InvoiceItems/InvoiceItemsTable";
import ReconciliationTable from "../Reconciliation/ReconciliationTable";
import ReccurenceDetails from "../Recurrences/ReccurenceDetails";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNav: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
}));

const Component = React.memo(function Component(props) {
    const {InvoiceStatus, FormViewMode, PurchaseInvoiceID, HandlePurchaseItemChanged, IsReconciled, FormValuesParent} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ReconciliationTab = {};
    if (IsReconciled === true) {
        ReconciliationTab = {
            TabMenu: (
                <div className={classes.TabMenuDiv}>
                    <div className={classes.TabTitle}>Reconciliation</div>
                </div>
            ),

            TabContent: <ReconciliationTable FormViewMode={FormViewMode} PurchaseInvoiceID={PurchaseInvoiceID} SubmitParentForm={HandlePurchaseItemChanged} />,
        };
    } else {
        ReconciliationTab = {};
    }
    let RecurrenceTab = {};
    console.log("InvoiceStatus", InvoiceStatus);
    if (InvoiceStatus === "Draft") {
        RecurrenceTab = {};
    } else {
        RecurrenceTab = {
            TabMenu: (
                <div className={classes.TabMenuDiv}>
                    <div className={classes.TabTitle}>Recurrences</div>
                </div>
            ),

            TabContent: <ReccurenceDetails FormViewMode={FormViewMode} PurchaseInvoiceID={PurchaseInvoiceID} FormValuesParent={FormValuesParent} SubmitParentForm={HandlePurchaseItemChanged} />,
        };
    }
    return (
        <TabNav
            NoLink={true}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Invoice Items</div>
                        </div>
                    ),

                    TabContent: <InvoiceItemsTable FormViewMode={FormViewMode} PurchaseInvoiceID={PurchaseInvoiceID} SubmitParentForm={HandlePurchaseItemChanged} />,
                },
                ReconciliationTab,
                RecurrenceTab,
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>History</div>
                        </div>
                    ),

                    TabContent: <div>VAT</div>,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
