import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import Page from "../../../../../../../artibulles-cis/Page/Page";
import InvoiceEditForm from "./InvoiceEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerEdit = React.memo(function CustomerEdit(props) {
    const {Id, RedirectToSourceOnClosing} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/crm/customers/customer/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/saleinvoices/invoice`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Company._id;
            History.push(RedirectToSourceOnClosing);
            if (IdFinal === "new") {
                //We redirect to edit to reset the form and get the right buttons and editing
                History.push(`/financials/saleordersandinvoices/suppliers/supplier/${Id}/edit`);
            }

            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        let Invoice = data.Invoice ? data.Invoice : null;

        let CustomerList;
        if (data.Customers && data.Customers.length > 0) {
            CustomerList = data.Customers.map((elem) => {
                return {value: elem._id, label: elem.company, vat_number: elem.vat_number, BankAccounts: elem.BankAccounts};
            });
        }
        let SaleInvoiceStatusSelectOptions = data.SaleInvoiceStatusSelectOptions;
        let PaymentConditionsSelectOptions = data.PaymentConditionsSelectOptions;
        let PaymentMethodSelectOptions = data.PaymentMethodSelectOptions;

        let InvoiceStatusInitial = Invoice ? Invoice.status_invoice : null;

        if (!InvoiceStatusInitial) {
            InvoiceStatusInitial = "01";
        } else {
            InvoiceStatusInitial = _.find(SaleInvoiceStatusSelectOptions, {label: InvoiceStatusInitial}).value;
        }

        let PaymentConditionsInitial = Invoice ? Invoice.PaymentCondition : null;
        if (!PaymentConditionsInitial) {
            PaymentConditionsInitial = "01";
        } else {
            PaymentConditionsInitial = _.find(PaymentConditionsSelectOptions, {label: PaymentConditionsInitial}).value;
        }

        let PaymentMethodInitial = Invoice ? Invoice.PaymentMethod : null;

        if (!PaymentMethodInitial) {
            PaymentMethodInitial = "01";
        } else {
            PaymentMethodInitial = _.find(PaymentMethodSelectOptions, {label: PaymentMethodInitial}).value;
        }

        let SupplierInitialId = Invoice ? Invoice.ref_Customer : null;
        let InitialCustomer;
        if (SupplierInitialId) {
            InitialCustomer = _.find(data.Customers, {_id: SupplierInitialId});
        }

        let VatNumber;
        if (InitialCustomer) {
            VatNumber = InitialCustomer.vat_number;
        }

        let InvoicesFilePaths = [];

        if (Invoice && Invoice.Invoices_File_Path && Array.isArray(Invoice.Invoices_File_Path) && Invoice.Invoices_File_Path.length > 0) {
            Invoice.Invoices_File_Path.forEach((elem) => {
                InvoicesFilePaths.push(elem);
            });
        }

        let BankAccountsOptions = [];
		
        if (InitialCustomer) {
            let BankAccounts = InitialCustomer.BankAccounts;
            
            if (BankAccounts && Array.isArray(BankAccounts)) {
                BankAccounts.forEach((elem) => {
                    BankAccountsOptions.push({label: elem.account_number, value: elem._id});
                });
            }
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Invoice ? Invoice._id : "new",
                status_invoice: InvoiceStatusInitial,
                InvoicePaymentExpectedCommunication: Invoice ? Invoice.InvoicePaymentExpectedCommunication : null,
                Description: Invoice ? Invoice.Description : null,
                CalcTotalVATIncluded: Invoice ? Invoice.CalcTotalVATIncluded : null,
                CalcTotalVATExcluded: Invoice ? Invoice.CalcTotalVATExcluded : null,
                CalcTotalVATAmount: Invoice ? Invoice.CalcTotalVATAmount : null,
                TotalVATExcludedPurchaseCurrency: Invoice ? Invoice.TotalVATExcludedPurchaseCurrency : null,
                TotlVATAmountPurchaseCurrency: Invoice ? Invoice.TotlVATAmountPurchaseCurrency : null,
                TotalVATIncludedPurchaseCurrency: Invoice ? Invoice.TotalVATIncludedPurchaseCurrency : null,
                Currency: Invoice ? Invoice.Currency : null,
                CurrencyExchangeRate: Invoice ? Invoice.CurrencyExchangeRate : null,
                PaymentCondition: PaymentConditionsInitial,
                PaymentMethod: PaymentMethodInitial,
                CalcTotalItems: Invoice ? Invoice.CalcTotalItems : null,
                OrderPlacedDateIso: Invoice ? Invoice.OrderPlacedDateIso : null,
                ExpectedDeliveryDateIso: Invoice ? Invoice.ExpectedDeliveryDateIso : null,
                DeliveryDateIso: Invoice ? Invoice.DeliveryDateIso : null,
                InvoiceSupplierDateIso: Invoice ? Invoice.InvoiceSupplierDateIso : null,
                InvoicePaymentDueDateIso: Invoice ? Invoice.InvoicePaymentDueDateIso : null,
                InvoicePaidDateIso: Invoice ? Invoice.InvoicePaidDateIso : null,
                Calc_Delivery_Expected_Days: Invoice ? Invoice.Calc_Delivery_Expected_Days : null,
                Calc_IsLateDelivery: Invoice && Invoice.Calc_IsLateDelivery === true ? true : false,
                Calc_DeliveryDelayDays: Invoice ? Invoice.Calc_DeliveryDelayDays : null,
                Calc_Real_Delivery_Days: Invoice ? Invoice.Calc_Real_Delivery_Days : null,
                Calc_IsLatePayment: Invoice && Invoice.Calc_IsLatePayment === true ? true : false,
                ref_Customer: SupplierInitialId ? SupplierInitialId : null,
                vat_number: VatNumber,
                CounterpartyBankAccount_Id: Invoice ? Invoice.CounterpartyBankAccount_Id : null,
                // db_status: Company ? Company.db_status : null,
                // db_locked: Company ? Company.db_locked : null,
                // db_locked_by: Company ? Company.ref_db_locked_by : null,
                // db_locked_at: Company ? Company.db_locked_at : null,
                // owner: Company ? Company.ref_owner : null,
                // createdAt: Company ? (Company.createdAt ? format(parseISO(Company.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                // updatedAt: Company ? (Company.updatedAt ? format(parseISO(Company.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                ref_Customer: CustomerList,
                status_invoice: SaleInvoiceStatusSelectOptions,
                PaymentCondition: PaymentConditionsSelectOptions,
                PaymentMethod: PaymentMethodSelectOptions,
                CounterpartyBankAccount_Id: BankAccountsOptions,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                Invoices_File_Path: InvoicesFilePaths,
            },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormDataToServer")
        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            let FilesUpload = FullSubmission.FilesUploader;

            let InvoicesFilepathFinal;
            if (FilesUpload && FilesUpload.Invoices_File_Path && Array.isArray(FilesUpload.Invoices_File_Path) && FilesUpload.Invoices_File_Path.length > 0) {
                InvoicesFilepathFinal = FilesUpload.Invoices_File_Path;
            } else {
                InvoicesFilepathFinal = null;
            }

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Invoice: {
                        Updatable: {
                            status_invoice: values.status_invoice,
                            InvoicePaymentExpectedCommunication: values.InvoicePaymentExpectedCommunication,
                            CalcTotalVATIncluded: values.CalcTotalVATIncluded,
                            CalcTotalVATExcluded: values.CalcTotalVATExcluded,
                            CalcTotalVATAmount: values.CalcTotalVATAmount,
                            Description: values.Description,
                            TotalVATExcludedPurchaseCurrency: values.TotalVATExcludedPurchaseCurrency,
                            TotlVATAmountPurchaseCurrency: values.TotlVATAmountPurchaseCurrency,
                            TotalVATIncludedPurchaseCurrency: values.TotalVATIncludedPurchaseCurrency,
                            Currency: values.Currency,
                            CurrencyExchangeRate: values.CurrencyExchangeRate,
                            PaymentCondition: values.PaymentCondition,
                            PaymentMethod: values.PaymentMethod,
                            CalcTotalItems: values.CalcTotalItems,

                            OrderPlacedDateIso: values.OrderPlacedDateIso,
                            ExpectedDeliveryDateIso: values.ExpectedDeliveryDateIso,
                            DeliveryDateIso: values.DeliveryDateIso,
                            InvoiceSupplierDateIso: values.InvoiceSupplierDateIso,
                            InvoicePaymentDueDateIso: values.InvoicePaymentDueDateIso,
                            InvoicePaidDateIso: values.InvoicePaidDateIso,

                            Calc_Delivery_Expected_Days: values.Calc_Delivery_Expected_Days,
                            Calc_IsLateDelivery: values.Calc_IsLateDelivery,
                            Calc_DeliveryDelayDays: values.Calc_DeliveryDelayDays,
                            Calc_Real_Delivery_Days: values.Calc_Real_Delivery_Days,
                            ref_Customer: values.ref_Customer,
                            Invoices_File_Path: InvoicesFilepathFinal,
                            CounterpartyBankAccount_Id: values.CounterpartyBankAccount_Id,
                        },
                    },
                    CompanyAddresses: values.CompanyAddresses,
                };
                //this is what will be sent back to the server
                // console.log("ServerData", ServerData);
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ButtonSaveAsDraftComp, ButtonSaveAndQuitComp, ButtonQuitNoSaveComp;

    if (IdFinal === "new") {
        ButtonSaveAsDraftComp = {Active: false, Text: "Create Order", Width: "180px"};
        ButtonSaveAndQuitComp = {Active: true, Text: "Create Initial Order", Width: "200px"};
        ButtonQuitNoSaveComp = {Active: true, Text: "Exit"};
    } else {
        ButtonSaveAsDraftComp = {Active: true, Text: "Save draft"};
        ButtonSaveAndQuitComp = {Active: true, Text: "Save And Quit"};
        ButtonQuitNoSaveComp = {Active: true, Text: "Exit"};
    }

    return (
        <Page WithPerfectScrollBar={true} PagePadding="0px">
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={true}
                CardModalOptions={{
                    // ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={InvoiceEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={ButtonSaveAsDraftComp}
                ButtonSaveAndQuit={ButtonSaveAndQuitComp}
                ButtonQuitNoSave={ButtonQuitNoSaveComp}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
});

export default CustomerEdit;
