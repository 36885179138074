import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "../../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import _ from "lodash";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import Table from "../../../../../../../../artibulles-cis/TableNew/Table.js";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
const {isValid, format, parseISO} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        // height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const ColomnsInput = [
        {
            width: "100px",
            caption: "Transaction date",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "dd-MMM-yyyy");
                } else {
                    return value;
                }
            },
            datafield: "transaction_date_Iso",
            searchable: true,
            datatype: "date",
            sortable: true,
            wrapColHeader: true,
            initialSort: {order: 1, sortDirection: "Dsc"},
        },
        {
            width: "100px",
            caption: "Charge date",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "dd-MMM-yyyy");
                } else {
                    return value;
                }
            },
            datafield: "card_charge_date_Iso",
            searchable: true,
            datatype: "date",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Card Amount",
            datafield: "amount",
            cellCustomRender: (value) => {
                if (value) {
                    let formattedOutput = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
                    if (value < 0) {
                        return <div style={{color: "red"}}>{formattedOutput}</div>;
                    } else {
                        return formattedOutput;
                    }
                }
            },

            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Original Amount",
            datafield: "original_amount",

            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },

        {width: "auto", caption: "Communication", datafield: "communication", searchable: true, datatype: "text", wrapCell: true},
        {width: "300px", caption: "Merchant", datafield: "merchant", searchable: true, datatype: "text"},
    ];

    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };
    const TotalRow = {
        Show: false,
    };

    const HandleEditRow = (arg) => {
        console.log("arg", arg);
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 15,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: true,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "View",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitiaFormValues, setInitiaFormValues] = useState({
        CardId: null,
    });
    const [FormValues, setFormValues] = useState({
        CardId: null,
    });

    const [FinalAPIUrlRelative, setFinalAPIUrlRelative] = useState({
        MAIN: `${ProdURL}/api/companyaccount/creditcards/gettransactions`,
        DELETE: "",
        PUT: "",
        POST: "",
    });

    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [RefreshApi, setRefreshApi] = useState(true);
    const [IsInitalized, setIsInitalized] = useState(false);
    const [InitialFormValues, setInitialFormValues] = React.useState({
        current_date: Date.now(),
    }); //INITIAL FORM VALUES

    const [AccountData, setAccountData] = useState(null);
    const [FieldOptions, setFieldOptions] = useState({
        CardId: [],
    }); //FORM OPTIONS FOR SELECT
    const [ShowCardUploadTransactions, setShowCardUploadTransactions] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);
                    //We just load the cards Id into the select

                    let SelectOptions;
                    if (Data && Data.length > 0) {
                        SelectOptions = Data.map((elem) => {
                            return {value: elem._id, label: elem.card_number + " - " + elem.card_holder_Name};
                        });
                    }
                    setFieldOptions({CardId: SelectOptions});
                    setRefreshApi(false);
                    setIsInitalized(true);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (RefreshApi === true) {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/creditcards/cards`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, RefreshApi]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        let SelectedValues = SelectedDetails.Value;

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};

        setFormValues(UpdatedFormValues);
        setIsInitalized(false);
        setFinalAPIUrlRelative({
            MAIN: `${ProdURL}/api/companyaccount/creditcards/gettransactions/?creditCardId=${SelectedDetails.Value}`,
            DELETE: "",
            PUT: "",
            POST: "",
        });
        setTimeout(() => {
            setIsInitalized(true);
        }, 50);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let TableComponent = null;
    if (IsInitalized === true) {
        TableComponent = (
            <Table
                // LocalData={TableData}
                URLRelative={FinalAPIUrlRelative}
                // URLRelative="arnaud"
                MainDataSource="Transactions"
                ColomnsInput={ColomnsInput}
                TableDimensions={TableDimensions}
                TableOptions={TableOptions}
                TotalRow={TotalRow}
            />
        );
    } else {
        TableComponent = null;
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <div className={classes.OverviewWrapper}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{width: "600px"}}>
                        <FormCompontent
                            Name="CardId"
                            InitialValue={InitialFormValues.CardId ? InitialFormValues.CardId : null}
                            FormValue={FormValues.CardId ? FormValues.CardId : null}
                            OutputSelectedValues={(values) => handleSelectChange("CardId", values)}
                            SelectOptions={FieldOptions.CardId}
                            Component="Select"
                            Variant="Outlined"
                            Label="Credit Card"
                            NoMessage={false}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskCategory", error)}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_taskCategory", options)}
                            LabelMaxWidth="100px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.TableWrapper}>{TableComponent}</div>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
