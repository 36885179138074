/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory, useLocation} from "react-router-dom";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";
import {useSelector} from "react-redux";

import {Calendar, Company, AddressBook, Contract, Offer, TableView, Dashboard, AddCircle, CheckBoxNotchedFilled} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "40px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 50px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 15px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
        color: "white",
    },
    SideBarLink_Content_Text_SubTree: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
        color: "white",
    },
    Separator: {
        width: "100%",
        borderBottom: "1px solid rgba(255,255,255,0.4)",
    },
}));

const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const {timeEntryExist} = useSelector((state) => ({
        timeEntryExist: state.timeEntryTracking.Exist,
    }));

    const MainRoute = "/tasktimemanagement/tasks/";
    const History = useHistory();
	const Location = useLocation();
	const PathName = Location.pathname;
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCreateTask = () => {
        History.push(`/tasktimemanagement/tasks/task/edit/new`, {from : PathName});
    };
    
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <React.Fragment>
            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}dashboard`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Dashboard IconSize="30" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Dashboard</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} onClick={HandleCreateTask}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <AddCircle IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Add Task</p>
                    </div>
                </div>
            </NavSideBarLink>

            
            <div className={classes.Separator}></div>

            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}tables`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <CheckBoxNotchedFilled IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Tasks</p>
                    </div>
                </div>
            </NavSideBarLink>

            <NavSideBarLink Exact={false} ActiveLinkIndicator={true} Slug={`${MainRoute}dailyorganizer`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <TableView IconSize="30" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Daily Organizer</p>
                    </div>
                </div>
            </NavSideBarLink>
			<NavSideBarLink Exact={false} ActiveLinkIndicator={true} Slug={`${MainRoute}testcalendar`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <TableView IconSize="30" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Calendar Test</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}today`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <TableView IconSize="30" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Today's tasks</p>
                    </div>
                </div>
            </NavSideBarLink>
           
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
