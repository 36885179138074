import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import CardModal from "@artibulles-cis/react/CardModal";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import InvoiceItemsEdit from "./InvoiceItemsEdit";
import InvoiceDuplicate from "./InvoiceDuplicate";

import {AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    ClassExtendTabContent: {
        padding: "0px 5px 0px 5px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    DailytButtonBar: {
        flex: "0 1 auto",
    },
    TableWithToolbar: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
		flex : "1 1 200px",
        // display: "flex",
        width: "100%",
        minHeight: "200px",
        // height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px",
    },
    ButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        paddingTop: "5px",
    },
    ButtonLeft: {
        flex: " 1 1 auto",
        display: "flex",
    },
    ButtonMiddle: {
        flex: " 1 1 auto",
        display: "flex",
    },
    ButtonRight: {
        flex: " 1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },
    CardContent: {
        color: "black",
        height: "100%",
        overflow: "hidden",
    },
    ClassExtendCardNoScroll: {
        overflow: "hidden",
    },
}));

const CustomerEditBankAccountTable = React.memo(function CustomerEditBankAccountTable(props) {
    const {FormViewMode, PurchaseInvoiceID, SubmitParentForm} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const HandleDeleteRow = (Id) => {
        // console.log("HandleDeleteRow", Id);
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/invoiceitems/invoiceitem/${Id}`, type: "delete"};
            setLoading(true);
            setAPICallType("deleteone");

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };
    const HandleEditRow = (Id, RowData) => {
        setShowEditPuchaseItemCard(true);
        setViewCartPurchaseItemId(Id);
        setPurchaseItemViewMode("Edit");
    };

    const HandleViewRow = (Id, RowData) => {
        setPurchaseItemViewMode("View");

        setShowEditPuchaseItemCard(true);
        setViewCartPurchaseItemId(Id);
    };
    const HandleDuplicateInvoiceItem = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };

    const TableColumns = [
        {
            field: "ArticleName",
            headerName: "Article Name",
            headerClass: "ag-CenterHeader",
            flex: 1,
			minWidth: 200,
            autoHeight: true,
            wrapText: true,
            // width: 150,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        // {
        //     field: "AccountingArticleName",
        //     headerName: "Accounting Name",
        //     headerClass: "ag-CenterHeader",
        //     flex: 1,
        //     // width: 150,
        //     // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // sort: "desc",
        //     // sortIndex: 1,
        //     // sortingOrder: ["asc", "desc"],
        //     // Type: "Date",
        // },
        // {
        //     field: "AccountingBelgianAccount.AccountDescription",
        //     headerName: "Description",
        //     headerClass: "ag-CenterHeader",
        //     flex: 1,
        //     // lookup: {data: "Suppliers", searchField: "_id", returnField: "company"},
        //     // filter: "agNumberColumnFilter",
        //     // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // Type: "Currency",
        //     // TotalRow: {
        //     //     Show: true,
        //     //     // Rendering: (params) => {
        //     //     //     return <div>Coucou</div>;
        //     //     // },
        //     // },
        // },
        // {
        //     field: "TypeOfPurchase",
        //     //
        //     headerName: "Type",
        //     headerClass: "ag-CenterHeader",
        //     width: 95,
        //     // filter: true,
        //     // sortingOrder: ["asc", "desc"],
        // },
        {
            field: "UnitPriceVATExcluded",
            //
            headerName: "Price",
            headerClass: "ag-CenterHeader",
            width: 105,
            Type: "Currency",
            // filter: true,
            // sortingOrder: ["asc", "desc"],
            // Type: "Boolean",
        },
        {
            field: "Quantity",
            //
            headerName: "Quantity",
            headerClass: "ag-CenterHeader",
            width: 90,
            // filter: true,
            // sortingOrder: ["asc", "desc"],
            // cellRenderering: PaymentActionRendering,
            // cellStyle: PaymentCell,
        },
        // {
        //     field: "TaxCode.TaxName",
        //     headerName: "Taxes",
        //     headerClass: "ag-CenterHeader",
        //     width: 85,
        //     // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // sortingOrder: ["asc", "desc"],
        //     // Type: "Date",
        // },

        {
            field: "CalcTotalVATExcluded",
            headerName: "Total",
            headerClass: "ag-CenterHeader",
            width: 105,
            Type: "Currency",
            // filter: "agNumberColumnFilter",
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            //
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "CalcVATAmount",
            headerName: "VAT",
            headerClass: "ag-CenterHeader",
            width: 95,
            Type: "Currency",
            // filter: "agNumberColumnFilter",
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},

            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "CalcTotalVATIncluded",
            headerName: "Tot VAT",
            headerClass: "ag-CenterHeader",
            width: 105,
            Type: "Currency",
            // filter: "agNumberColumnFilter",
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},

            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        SupressMenu: true,
        WrapText: true,
        WrapHeader: false,
        HeaderHeight: 30,
        RowHeight: 24,
        RowFontSize: "13px",
        HeaderFontSize: "14px",
        StatusBar: true,
    };
    // const TotalRow = {
    //     Position: "Bottom",
    //     Style: null,
    // };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [CreateNewOnly, setCreateNewOnly] = useState(false);

    const [APICallType, setAPICallType] = useState(null);
    const [ShowEditPuchaseItemCard, setShowEditPuchaseItemCard] = useState(false);
    const [ViewCartPurchaseItemId, setViewCartPurchaseItemId] = useState(null);
    const [PurchaseItemViewMode, setPurchaseItemViewMode] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);
    const [ReloadTableData, setReloadTableData] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    // console.log("PurchaseInvoiceItems", Data);

                    if (Init === false) {
                        // console.log("not init");

                        if (Data.PurchaseInvoiceItems && Array.isArray(Data.PurchaseInvoiceItems) && Data.PurchaseInvoiceItems.length > 0) {
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        } else {
                            Ref_LocaleData.current = null;
                            // console.log("New ONly");
                            setCreateNewOnly(true);
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "deleteone") {
                        toast.success(`Entry deleted`, {autoClose: true});

                        Ref_LocaleData.current = null;
                        setAPICallType(null);
                        setTimeout(() => {
                            setInit(false);
                        }, 50);
                        setTimeout(() => {
                            SubmitParentForm();
                        }, 200);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;

        if (Ref_LocaleData.current) {
            let TableRowButtons;
            if (FormViewMode === "View") {
                let ColumnButtonStyle = {
                    Width: 45,
                    padding: "0px 0px 0px 0px",
                };
                TableRowButtons = [
                    {
                        Standard: true,
                        Name: "View",
                        ActiveCondition: null,
                        Index: 1,
                        OnClick: HandleViewRow,
                        // IconSize: "10px",
                        // ActiveColor: "red",
                        // HoverColor: "green",
                        // InactiveColor: "blue",
                        Tooltip: {Show: true, Position: "right", Theme: "light"},
                    },
                ];
                FinalTableComponent = (
                    <AGGridTable
                        TableName="InvoiceitemsTable_ViewMode"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="PurchaseInvoiceItems"
                        RowActionButtons={TableRowButtons}
                        ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            } else {
                let ColumnButtonStyle = {
                    Width: 95,
                    padding: "0px 0px 0px 0px",
                };
                TableRowButtons = [
                    {
                        Standard: true,
                        Name: "Edit",
                        ActiveCondition: null,
                        Index: 1,
                        OnClick: HandleEditRow,
                        // IconSize: "10px",
                        // ActiveColor: "red",
                        // HoverColor: "green",
                        // InactiveColor: "blue",
                        Tooltip: {Show: true, Position: "right", Theme: "light"},
                    },
                    {
                        Standard: true,
                        Name: "Duplicate",
                        ActiveCondition: null,
                        Index: 2,
                        OnClick: HandleDuplicateInvoiceItem,
                        Tooltip: {Show: true, Position: "right", Theme: "light"},
                    },
                    {
                        Standard: true,
                        Name: "Delete",
                        // ActiveCondition: DeleteCondition,
                        Index: 3,
                        OnClick: HandleDeleteRow,
                        ConfirmationMessage: true,
                        // IconSize: "10px",
                        // ActiveColor: "red",
                        // HoverColor: "green",
                        // InactiveColor: "blue",
                        Tooltip: {Show: true, Position: "right", Theme: "light"},
                    },
                ];
                FinalTableComponent = (
                    <AGGridTable
                        TableName="InvoiceitemsTable_EditMode"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="PurchaseInvoiceItems"
                        RowActionButtons={TableRowButtons}
                        ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            }
        }

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
            setReloadTableData(false);
        }, 200);
    }, [Ref_LocaleData.current, Init, FormViewMode]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (Init === false || ReloadTableData === true) {
            console.log("API RELOAD Table");
            const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/invoiceitems/invoiceitems/${PurchaseInvoiceID}`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init, ReloadTableData]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true && CreateNewOnly === false) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init, FormViewMode, ReloadTableData]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditItemCard = () => {
        setShowEditPuchaseItemCard(false);
        setViewCartPurchaseItemId(null);
        if (PurchaseItemViewMode === "Edit") {
            //We refresh the parent form to recalculate the invoice amount

            setCreateNewOnly(false);
            setTimeout(() => {
                setInit(false);
            }, 50);

            setTimeout(() => {
                SubmitParentForm();
            }, 200);
        }
    };

    const HanldeCreateNewPurcahseItem = () => {
        setShowEditPuchaseItemCard(true);
        setViewCartPurchaseItemId("new");
    };
    const HandleCloseDuplicateCard = (Id) => {
        console.log("Should close the card");
        setShowDuplicateCard(false);
        setDuplicateCardId(null);

        setTimeout(() => {
            setInit(false);
        }, 50);
        setTimeout(() => {
            SubmitParentForm();
        }, 100);
        // setTableComponent(null);
        // setTimeout(() => {
        //     setReloadTableData(true);
        // }, 300);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowEditPuchaseItemCard === true) {
        ModalCardView = (
            <CardModal
                CardID="PurchaseItemCard"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Purchase Invoice Item</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseEditItemCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1000px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceItemsEdit PurchaseInvoiceID={PurchaseInvoiceID} InvoiceItemID={ViewCartPurchaseItemId} CloseCard={HandleCloseEditItemCard} ViewEditMode={PurchaseItemViewMode} />
                </div>
            </CardModal>
        );
    }
    if (ShowDuplicateCard === true) {
        ModalCardView = (
            <CardModal
                CardID="DuplicateInvoiceCard"
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseDuplicateCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCardNoScroll}
            >
                <div className={classes.CardContent}>
                    <InvoiceDuplicate DuplicateId={DuplicateCardId} HandleCloseViewCard={HandleCloseDuplicateCard} />
                </div>
            </CardModal>
        );
    }
    var FinalTableComponent;

    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = (
                <div className={classes.TableWithToolbar}>
                    <Button
                        Width="170px"
                        Height="25px"
                        style={{margin: "0px 0px", padding: "0px 5px 0px 5px", justifyContent: "space-between", display: `${FormViewMode === "View" ? "none" : null}`}}
                        onClick={HanldeCreateNewPurcahseItem}
                    >
                        <AddCircle SVGFillColor="white" IconSize="20px" />
                        Add Purchase Item
                    </Button>

                    <div className={classes.TableWrapper}>{TableComponent}</div>
                </div>
            );
        } else {
            FinalTableComponent = (
                <Button
                    Width="170px"
                    Height="25px"
                    style={{margin: "0px 0px", padding: "0px 5px 0px 5px", justifyContent: "space-between", display: `${FormViewMode === "View" ? "none" : null}`}}
                    onClick={HanldeCreateNewPurcahseItem}
                >
                    <AddCircle SVGFillColor="white" IconSize="20px" />
                    Add Purchase Item
                </Button>
            );
        }
    } else {
        FinalTableComponent = "There is no data";
    }

    return (
        <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
            {ModalCardView}
            <div className={classes.DailytButtonBar}>
                <div className={classes.FormField} style={{flex: "1 1 250px", maxWidth: "250px", width: "250px"}}></div>
            </div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default CustomerEditBankAccountTable;
