/* eslint-disable */
import React, {useEffect, useState} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {createUseStyles, useTheme} from "react-jss";
import {ToastContainer, toast, Slide} from "react-toastify";
import {useLocation, useHistory} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {useSelector, useDispatch} from "react-redux";
import {LOGIN_USER} from "../auth/authConstants";
import {useCookies} from "react-cookie";

import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";

import AppWrapper from "@artibulles-cis/react/AppWrapper";
import AppMainContainer from "@artibulles-cis/react/AppMainContainer";
import NavHorizontalBar from "@artibulles-cis/react/NavHorizontalBar";
// import NavSideBar from "@artibulles-cis/react/NavSideBar";
// import NavSideBarController from "@artibulles-cis/react/NavSideBarController";

import NavSideBar from "../../artibulles-cis/NavSideBar/NavSideBar";
import NavSideBarController from "../../artibulles-cis/NavSideBarController/NavSideBarController";


import {authserverurl} from "../common/util/APIServerAddresses";
import {Company, Calendar, CheckBoxNotchedFilled} from "@artibulles-cis/react-icons";
import TopNavBar from "./TopNavBar/TopNavBar";
import HomePage from "../Pages/Desktop/HomePage";

import CRMRouter from "../Pages/Desktop/CRM/CRMRouter";
import SideBarPanelCustomers from "./SideBars/CRM/SideBarPanelCustomers";
import SideBarPanelContacts from "./SideBars/CRM/SideBarPanelContacts";
import SideBarPanelProspects from "./SideBars/CRM/SideBarPanelProspects";
import MyCompanyRouter from "../Pages/Desktop/myCompany/MyCompanyRouter";
import SideBarPanelMyCompanySetting from "./SideBars/MyCompany/SideBarPanelMyCompanySetting";
import FinancialsRouter from "../Pages/Desktop/Financials/FinancialsRouter";
import SideBarPanelCompanyAccount from "./SideBars/Financials/SideBarPanelCompanyAccount";
import SideBarPanelSalesInvoices from "./SideBars/Financials/SideBarPanelSalesInvoices";
import SideBarPanelPurchaseInvoices from "./SideBars/Financials/SideBarPanelPurchaseInvoices";
import SideBarPanelAccounting from "./SideBars/Financials/SideBarPanelAccounting";
import SideBarPanelTaskManagement from "./SideBars/TasksTimeManagement/SideBarPanelTaskManagement";
import SideBarPanelTimeManagement from "./SideBars/TasksTimeManagement/SideBarPanelTimeManagement";
import SideBarPanelsPrograms from "./SideBars/Programs/SideBarPanelsPrograms";

import SideBarPanelComponentsDemos from "../Pages/Desktop/ZComponentsDemos/SideBarPanelComponentsDemos";
import ComponentsDemosRouter from "../Pages/Desktop/ZComponentsDemos/ComponentsDemosRouter";


import TaskManagementRouter from "../Pages/Desktop/TaskManagement/TaskManagementRouter";
import TimeManagementRouter from "../Pages/Desktop/TimeManagement/TimeManagementRouter";
import ProgramsRouter from "../Pages/Desktop/Programs/ProgramsRouter";

import Login from "../Pages/Desktop/LoginAndProfile/Login";
import Logout from "../Pages/Desktop/LoginAndProfile/Logout";
import ProfileViewEdit from "../Pages/Desktop/LoginAndProfile/ProfileViewEdit";
import AppUpdater from "./AppUpdater";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendNavSideBarController: {
        height: "30px",
        color: "white",
    },
    SidebarControllerContent_Left: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarController_Icon: {
        flex: "0 0 40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 0px 0px 0px",
    },
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "30px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 7px",
        margin: "0px",
        fontSize: "1.1rem",
    },
}));
toast.configure();
const AppDesktop = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const AppConfig = {
        maxWidth: "2500px",
    };
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const AuthServerAddress = authserverurl(DevMode);

    const Dispatch = useDispatch();
    const Location = useLocation();
    const History = useHistory();

    const IntervalAuthCheckMilliseconds = 1000 * 60 * 60 * 24;
    const SideBarWidthExpanded = "280";
    const SideBarWidthMinimized = "45";
    /***************** CONST ******************/

    /***************** STATE ******************/
    //Extracting Desired Redux State Variables
    const {authenticated} = useSelector((state) => ({
        authenticated: state.auth.authenticated,
    }));

    const [UserIsAuthenticated, setUserIsAuthenticated] = useState(false);
    const [Initialized, setInitialized] = useState(false);
    const [LeftSideBarMinimized, setLeftSideBarMinimized] = React.useState(false);
    const [ApiRes, setApiRes] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("errors", ApiRes);
                setUserIsAuthenticated(false);
            } else {
                if (ApiRes.data) {
                    let data = ApiRes.data.data;
                    if (data) {
                        setUserIsAuthenticated(true);
                        setInitialized(true);
                        Dispatch({type: LOGIN_USER, payload: data});
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //Check If authenticated
    useEffect(() => {
        // console.log("authenticated check")
        if (authenticated === true) {
            // console.log("authenticated")
            //No need to do anything
            setUserIsAuthenticated(true);
        } else {
            if (DevMode === "development") {
                //there are no cookies
                const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post", data: null};
                setLoading(true);
                async function APIInternal() {
                    const res = await APICallExternal(ApiCall);
                    setApiRes(res);
                    setLoading(false);
                }
                APIInternal();
            } else {
                if (Object.keys(cookies).length === 0) {
                    //No cookies at all
                    setUserIsAuthenticated(false);
                    setInitialized(true);
                } else {
                    //We check that the right cookie is there (not any stupid cookie)

                    if (cookies["artibulles-bms_Session_P"]) {
                        //we need to call again the server to get the user information in case the user refreshed the page and the store was lost
                        const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post", data: null};
                        setLoading(true);
                        async function APIInternal() {
                            const res = await APICallExternal(ApiCall);
                            setApiRes(res);
                            setLoading(false);
                        }
                        APIInternal();
                    } else {
                        setInitialized(true);
                        setUserIsAuthenticated(false);
                    }
                }
            }
        }
    }, [authenticated]);

    //Check browser cookies to confirm that the user is loged in
    useEffect(() => {
        setInterval(() => {
            if (Object.keys(cookies).length === 0) {
                //No cookies at all
                setUserIsAuthenticated(false);
            } else {
                //We check that the right cookie is there (not any stupid cookie)

                if (cookies["artibulles-bms_Session_P"]) {
                    setUserIsAuthenticated(true);
                } else {
                    setUserIsAuthenticated(false);
                    Dispatch({type: SIGN_OUT_USER, payload: null});
                }
            }
        }, IntervalAuthCheckMilliseconds);
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleLoginCallBack = (callBack) => {
        if (callBack.offline === true) {
            console.log("offline");
        } else {
            Dispatch({type: LOGIN_USER, payload: callBack.User});
            History.push("/");
        }
    };

    const ToggleSideBar = () => {
        setLeftSideBarMinimized(!LeftSideBarMinimized);
    };
    const UpdateWidthDraggedDimensions = ({WidthMinimized, widthExpanded}) => {
        // console.log("draggign");
    };
    var SideBarBankAccountShow = false;
    if (Location.pathname.includes("/financials/companyaccount")) {
        SideBarBankAccountShow = true;
    } else {
        SideBarBankAccountShow = false;
    }
    var SideBarBankAccount = (
        <NavSideBar
            Show={SideBarBankAccountShow}
            // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
            // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
            Resizable={false}
            ResizableMaxWidth={SideBarWidthExpanded}
            ResizableMinWidth="0"
            ExpandOnHover={false}
            CollapseOnMinimize={false}
            WidthExpanded={SideBarWidthExpanded}
            WidthMinimized={SideBarWidthMinimized}
            ResizeMinAllowed={true}
            id="LeftSideBar1"
            ZIndex="100"
            TopRefComponentID="TopFixedNavBar"
            // WithScrollBar={{X: false, Y: true}}
            WithPerfectScrollbar={true}
            SideBarController={
                <NavSideBarController
                    IconControllerPosition="left"
                    WithCollapseAllController={true}
                    ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                    // CustomCollapseAllController={false}
                    WithExpandAllController={true}
                    // CustomExpandAllController={false}

                    // IconController={SideBarIconController_Left}
                >
                    <div className={classes.SidebarControllerContent_Left}>
                        <div className={classes.SideBarController_Icon}>
                            <Company IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarController_Hidable_Wrapper}>
                            <h1 className={classes.SideBarController_Title_Left}>Company Account</h1>
                        </div>
                    </div>
                </NavSideBarController>
            }
        >
            <SideBarPanelCompanyAccount />
        </NavSideBar>
    );

    var FinalComponent;
    if (Initialized === false) {
        FinalComponent = <React.Fragment>Loading...</React.Fragment>;
    } else {
        if (UserIsAuthenticated === true) {
            FinalComponent = (
                <React.Fragment>
                    <NavHorizontalBar Show={true} Height="40px" id="TopFixedNavBar" MaxWidth={AppConfig.maxWidth} ZIndex="300">
                        <TopNavBar />
                    </NavHorizontalBar>
                    <Switch>
                        <Route path="/crm/customers">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar1"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Customers</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelCustomers />
                            </NavSideBar>
                        </Route>

                        <Route path="/crm/contacts">
                            <NavSideBar
                                Show={true}
                                DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={true}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={true}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar2"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Contacts</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelContacts />
                            </NavSideBar>
                        </Route>
                        <Route path="/crm/prospects">
                            <NavSideBar
                                Show={true}
                                DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={true}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={true}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar3"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Propsects</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelProspects />
                            </NavSideBar>
                        </Route>

                        <Route path="/mycompany/settings">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar1"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Company Settings</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelMyCompanySetting />
                            </NavSideBar>
                        </Route>

                        <Route path="/tasktimemanagement/tasks">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar1"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <CheckBoxNotchedFilled SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Tasks</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelTaskManagement />
                            </NavSideBar>
                        </Route>
                        <Route path="/tasktimemanagement/times">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar1"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Calendar SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Time</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelTimeManagement />
                            </NavSideBar>
                        </Route>

                        <Route path="/programs">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar1"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Programs</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelsPrograms />
                            </NavSideBar>
                        </Route>

                        <Route path="/financials/saleordersandinvoices">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth="400"
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded="400"
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar5"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Sales Invoices</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelSalesInvoices />
                            </NavSideBar>
                        </Route>

                        <Route path="/financials/purchaseordersandinvoices">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar5"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Purchase Invoices</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelPurchaseInvoices />
                            </NavSideBar>
                        </Route>

                        <Route path="/financials/accounting">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={true}
                                ResizableMaxWidth={SideBarWidthExpanded}
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded={SideBarWidthExpanded}
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar5"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Accounting</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelAccounting />
                            </NavSideBar>
                        </Route>

                        <Route path="/demos">
                            <NavSideBar
                                Show={true}
                                // DelegateMinimizeStatus={{StatusMinimized: LeftSideBarMinimized, ChangeStatusMinimized: ToggleSideBar}}
                                // UpdateWidthDraggedDimensions={UpdateWidthDraggedDimensions}
                                Resizable={false}
                                ResizableMaxWidth="400"
                                ResizableMinWidth="0"
                                ExpandOnHover={false}
                                CollapseOnMinimize={false}
                                WidthExpanded="400"
                                WidthMinimized={SideBarWidthMinimized}
                                ResizeMinAllowed={true}
                                id="LeftSideBar5"
                                ZIndex="100"
                                TopRefComponentID="TopFixedNavBar"
                                // WithScrollBar={{X: false, Y: true}}
                                WithPerfectScrollbar={true}
                                SideBarController={
                                    <NavSideBarController
                                        IconControllerPosition="left"
                                        WithCollapseAllController={true}
                                        ClassExtendNavSideBarController={classes.ClassExtendNavSideBarController}
                                        // CustomCollapseAllController={false}
                                        WithExpandAllController={true}
                                        // CustomExpandAllController={false}

                                        // IconController={SideBarIconController_Left}
                                    >
                                        <div className={classes.SidebarControllerContent_Left}>
                                            <div className={classes.SideBarController_Icon}>
                                                <Company IconSize="24" SVGFillColor="white" />
                                            </div>
                                            <div className={classes.SideBarController_Hidable_Wrapper}>
                                                <h1 className={classes.SideBarController_Title_Left}>Component Demos</h1>
                                            </div>
                                        </div>
                                    </NavSideBarController>
                                }
                            >
                                <SideBarPanelComponentsDemos />
                            </NavSideBar>
                        </Route>
                    </Switch>
                    {SideBarBankAccount}
                    <AppMainContainer
                        WithPerfectScrollbar={false}
                        WithScrollBar={false}
                        // classExtendMainContainer={classes.classExtendMainContainer}
                        DisableBodyScroll={true}
                        TopRefComponentID="TopFixedNavBar"
                        // BottomRefComponentID="BottomFixedNavBar"
                    >
                        <Switch>
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                            <Route path="/crm">
                                <CRMRouter />
                            </Route>
                            <Route path="/mycompany">
                                <MyCompanyRouter />
                            </Route>
                            <Route path="/financials">
                                <FinancialsRouter />
                            </Route>
                            <Route path="/demos">
                                <ComponentsDemosRouter />
                            </Route>
                            <Route path="/tasktimemanagement/tasks">
                                <TaskManagementRouter />
                            </Route>
                            <Route path="/tasktimemanagement/times">
                                <TimeManagementRouter />
                            </Route>

                            <Route path="/programs">
                                <ProgramsRouter />
                            </Route>
                        </Switch>
                    </AppMainContainer>
                </React.Fragment>
            );
        } else {
            FinalComponent = <Login HandleLoginCallBack={HandleLoginCallBack} />;
        }
    }
    return (
        <AppWrapper AppMaxWidth={AppConfig.maxWidth} style={{height: "100%"}}>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                // style={{minWidth : "500px"}}
                transition={Slide}
                closeOnClick={false}
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable={false}
                pauseOnHover={false}
            />
            {FinalComponent}
            <AppUpdater />
        </AppWrapper>
    );
};

export default AppDesktop;
