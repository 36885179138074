/* eslint-disable */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import LocalCircularLoader from "../LocalCircularLoader/LocalCircularLoader";
import FormCardModal from "../CardModal/CardModal";

import Button from "../Button/Button";
import FormAPI from "../FormApi/FormApi";
import FormToastr from "../FormToastr/FormToastr";
import {useHistory} from "react-router-dom";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    DevMode: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        border: "2px solid rgb(211, 78, 100)",
        borderRadius: "5px",
        padding: "10px 10px 10px 10px",
        margin: "10px 0px",
    },
    DevModeButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 0 auto",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "0px 10px 0px 10px",
    },
    DevModeData: {
        flex: "0 1 auto",
        maxHeight: "300px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "10px 10px 10px 10px",
        boxSizing: "border-box",
    },
    DevModeDataIndividual: {
        flex: "0 1 auto",
        maxHeight: "150px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "0px",

        boxSizing: "border-box",
    },
}));

/**
 * Master Component to create help managing Forms.
 * It only works with the FormTemplate component and does not create a form in intself
 * What it does is provide the API management to get, post, update, delete the form
 * It also provides toastr messages that will ask confirmations before performing the requested actions.
 * Data from the server to the form and from the form to the server formatting are managed in the parent calling component
 * so that it is easy to dissociate the Form and the server logic
 *
 */
const FormWithApi = React.memo(function FormWithApi(props, ref) {
    const {
        WithCardModal,
        toast,
        CardModalOptions,
        ShowAPIAnswerOnComponent,
        InitialCall,
        APIUrlRelative,
        APIUrlAbsolute,
        RedirectUrlOnQuit,
        HandleRedirection,
        FormComponent,
        FormatDataServerToForm,
        FormatDataFormToServer,
        WithToasts,
        LocaleToastMessage,
        ToastDeleteConfirmation,
        ToastPublishConfirmation,
        ToastQuitWihtouSaveConfirmation,
        ToastSavedMessage,
        ToastDeletedMessage,
        ToastPublishedMessage,
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        DevMode,
        OpenInViewMode,
        SwitchEditMode,
    } = props;
    const NodeDevMode = process.env.NODE_ENV === "development" ? true : false;
    const theme = useTheme();
    const classes = styles({...props, theme});
    let history = useHistory();
    const [FormValues, setFormValues] = useState(null);
    const [TypeOfSubmit, setTypeOfSubmit] = useState(null);
    const [Loading, setLoading] = React.useState(true);
    const [ToasterInput, setToasterInput] = React.useState(null);
    const [CompErrors, setCompErrors] = React.useState([]);
    const [DevCheckValues, setDevCheckValues] = React.useState({content: "", Show: false}); //ONLY USED FOR DEVELOPMENT CHECKS
    const [ServerAnswerForShowAPIAnswerOnComponent, setServerAnswerForShowAPIAnswerOnComponent] = React.useState({content: "", Show: false});

    var FinalFormComponent;

    /*********CHECKING INPUTS AND INITIATING THE COMPONENT ***********/
    //Assigning the Final URL
    useEffect(() => {
        if (!APIUrlRelative && !APIUrlAbsolute) {
            let newError = "You didn't provide an appropriate APIUrlRelative or APIUrlAbsolute";
            setCompErrors((previous) => [...previous, newError]);
        }
    }, [APIUrlRelative, APIUrlAbsolute]);

    //Checking that the Form Component is valid
    useEffect(() => {
        if (!FormComponent) {
            let newError = "You didn't pass a valid FormComponent";
            setCompErrors((previous) => [...previous, newError]);
        }
    }, [FormComponent]);

    //Performing the initial call if provided
    useEffect(() => {
        if (InitialCall) {
            let Submit = {type: InitialCall.type, ID: InitialCall.ID, data: InitialCall.Data};
            setTypeOfSubmit(Submit);
        } else {
            let newError = "You didn't provide an InitialCall option";
            setCompErrors((previous) => [...previous, newError]);
        }
    }, []);
    /*********CHECKING INPUTS AND INITIATING THE COMPONENT ***********/

    /*********MANAGING THE API RESPONSE ***********/
    //FINAL API ANSWER MODIFIED WITH THE TOAST CONFIRMATION MESSAGES TO DETECT WHAT ACTION NEEDS TO BE PERFORMED IF PROVIDED
    const ServerAnswer = (response) => {
        if (DevMode && NodeDevMode) {
            console.log("FormWithAPI Dev Mode : ", response);
            if (response) {
                setDevCheckValues((previous) => {
                    let updatedAnswer = {content: response, Show: previous.Show};
                    return updatedAnswer;
                });
            }
        }
        setLoading(false);

        if (response) {
            if (response.status === "success") {
                if (response.CrudType === "New" || response.CrudType === "Edit" || response.CrudType === "RefreshForm") {
                    //LOAD INITIAL API VALUES INTO THE FORM
                    if (FormatDataServerToForm && typeof FormatDataServerToForm === "function") {
                        let InitialFormValues = FormatDataServerToForm(response);
                        if (InitialFormValues) {
                            setFormValues(InitialFormValues);
                        }
                    } else {
                        CompErrors.push("FormWithAPI : you didn't pass a FormatDataServerToForm function. The component will not work as expected");
                        setCompErrors(CompErrors);
                    }
                }
                if (response.CrudType === "SaveDraft") {
                    //SAVING DRAFT -> NO REDIRECTION
                    if (WithToasts) {
                        setToasterInput({typeOfSubmit: "Saved"});
                    }

                    //Format the server data for the form inputs and reload the form
                    if (FormatDataServerToForm && typeof FormatDataServerToForm === "function") {
                        let InitialFormValues = FormatDataServerToForm(response);
                        if (InitialFormValues) {
                            setFormValues(InitialFormValues);
                        }
                    } else {
                        CompErrors.push("FormWithAPI : you didn't pass a FormatDataServerToForm function. The component will not work as expected");
                        setCompErrors(CompErrors);
                    }
                }
                if (response.CrudType === "SaveAndQuit" || response.CrudType === "PreviousSave" || response.CrudType === "NextSave") {
                    //SAVE AND QUIT --> WITH REDIRECTION

                    if (RedirectUrlOnQuit || HandleRedirection) {
                        if (WithToasts) {
                            if (ShowAPIAnswerOnComponent) {
                                //Pass the Data to the Component
                                setServerAnswerForShowAPIAnswerOnComponent({content: response, Show: true});
                            } else {
                                setToasterInput({typeOfSubmit: "Saved"});
                                setTimeout(() => {
                                    if (HandleRedirection) {
                                        HandleRedirection({typeOfSubmit: response.CrudType, data: response});
                                    } else {
                                        history.push(RedirectUrlOnQuit);
                                    }
                                }, 30);
                            }
                        } else {
                            setTimeout(() => {
                                if (HandleRedirection) {
                                    HandleRedirection({typeOfSubmit: response.CrudType, data: response});
                                } else {
                                    history.push(RedirectUrlOnQuit);
                                }
                            }, 30);
                        }
                    } else {
                        CompErrors.push("FormWithAPI : you didn't pass a RedirectUrlOnQuit");
                        setCompErrors(CompErrors);
                    }
                }
                if (response.CrudType === "Published") {
                    //PUBLISHED -> WITH REDIRECTION
                    if (WithToasts) {
                        setToasterInput({typeOfSubmit: "Published"});
                    }

                    if (RedirectUrlOnQuit || HandleRedirection) {
                        setTimeout(() => {
                            if (HandleRedirection) {
                                HandleRedirection({typeOfSubmit: "Published", data: response});
                            } else {
                                history.push(RedirectUrlOnQuit);
                            }
                        }, 30);
                    } else {
                        CompErrors.push("FormWithAPI : you didn't pass a RedirectUrlOnQuit");
                        setCompErrors(CompErrors);
                    }
                }
                if (response.CrudType === "Deleted") {
                    //DELETE -->WITH REDIRECTION
                    if (WithToasts) {
                        setToasterInput({typeOfSubmit: "Deleted"});
                    }
                    if (RedirectUrlOnQuit || HandleRedirection) {
                        setTimeout(() => {
                            if (HandleRedirection) {
                                HandleRedirection({typeOfSubmit: "Deleted", data: response});
                            } else {
                                history.push(RedirectUrlOnQuit);
                            }
                        }, 30);
                    } else {
                        CompErrors.push("FormWithAPI : you didn't pass a RedirectUrlOnQuit");
                        setCompErrors(CompErrors);
                    }
                }
            } else {
                CompErrors.push(`API Error: ${JSON.stringify(response.data)}, type of request : ${response.CrudType}`);
                setCompErrors(CompErrors);
            }
        } else {
            CompErrors.push("Server Internal Error");
            setCompErrors(CompErrors);
        }
    };
    /*********MANAGING THE API RESPONSE ***********/

    /*************FORM SUBMITTING HANDLING WITH TOASTR MANAGEMENT ********************/
    //WHEN THE FORM IS SUBMITED, ASK CONFIRMATION WITH TOASTR IF ACTIVE AND CALL THE API TO GET THE ANSWER
    //IF YOU NEED TO REDIRECT TO ANOTHER COMPONENT -> IT PASSES THE COMPONENT TO BE RENDERED BEFORE CALLING THE API AND THAT COMPONENT WILL GET THE ANSWER WHEN THE API IS READY
    const OnFormSubmit = (FullSubmission) => {
        //When the Form is submitting

        //check if the form is sending the data -> Avoid bugs
        if (FullSubmission) {
            let typeofsubmit = FullSubmission.typeOfSubmit;

            if (typeofsubmit === "Delete") {
                //Delete the Entry -> Button Delete
                if (WithToasts) {
                    //Ask confirmation via toastr
                    setToasterInput(FullSubmission);
                } else {
                    let ID = FullSubmission.values.ApiID;
                    if (ID) {
                        setLoading(true);
                        setTypeOfSubmit({type: "Delete", ID: ID, data: null});
                    } else {
                        CompErrors.push("No ID provided for a Delete Form submission - request canceled");
                        setCompErrors(CompErrors);
                    }
                }
            } else if (typeofsubmit === "SaveAndQuit" || typeofsubmit === "PreviousSave" || typeofsubmit === "NextSave") {
                if (FormatDataFormToServer && typeof FormatDataFormToServer === "function") {
                    //Update the record then quit
                    let ID = FullSubmission.values.ApiID;
                    let ServerData = FormatDataFormToServer(FullSubmission);
                    if (ShowAPIAnswerOnComponent) {
                        //Show the Component (setting the state to ShowAPIAnswerComp = true)
                        setLoading(true);
                        setServerAnswerForShowAPIAnswerOnComponent({content: "", Show: true});
                        //Submit the API Answer
                        if (ID && ServerData) {
                            setTypeOfSubmit({type: "SaveAndQuit", ID: ID, data: ServerData});
                        } else {
                            CompErrors.push("No ID or Data provided for a SaveAnQuit Form submission - request canceled");
                            setCompErrors(CompErrors);
                        }
                    } else {
                        setLoading(true);
                        if (ID && ServerData) {
                            setTypeOfSubmit({type: typeofsubmit, ID: ID, data: ServerData});
                        } else {
                            CompErrors.push("No ID or Data provided for a SaveAnQuit Form submission - request canceled");
                            setCompErrors(CompErrors);
                        }
                    }
                } else {
                    CompErrors.push("FormWithAPI : you didn't pass a FormatDataFormToServer function. The component will not work as expected");
                    setCompErrors(CompErrors);
                }
            } else if (typeofsubmit === "SaveDraft") {
                if (FormatDataFormToServer && typeof FormatDataFormToServer === "function") {
                    //Update the record and reinitialize the form with the new data
                    let ID = FullSubmission.values.ApiID;
                    let ServerData = FormatDataFormToServer(FullSubmission);
                    setLoading(true);
                    if (ID && ServerData) {
                        setTypeOfSubmit({type: "SaveDraft", ID: ID, data: ServerData});
                    } else {
                        CompErrors.push("No ID or Data provided for a SaveDraft Form submission - request canceled");
                        setCompErrors(CompErrors);
                    }
                } else {
                    console.log("FormWithAPI : you didn't pass a FormatDataFormToServer function. The component will not work as expected");
                    CompErrors.push("FormWithAPI : you didn't pass a FormatDataFormToServer function. The component will not work as expected");
                    setCompErrors(CompErrors);
                }
            } else if (typeofsubmit === "RefreshForm") {
                let ID = FullSubmission.values.ApiID;
                setLoading(true);
                setTypeOfSubmit({type: "RefreshForm", ID: ID, data: null});
            } else if (typeofsubmit === "QuitNoSave" || typeofsubmit === "PreviousQuit" || typeofsubmit === "NextQuit") {
                //Ask confirmation via toastr

                if (WithToasts) {
                    setToasterInput(FullSubmission);
                } else {
                    //Just Leave the form
                    if (RedirectUrlOnQuit || HandleRedirection) {
                        setToasterInput({typeOfSubmit: "Saved"});
                        setTimeout(() => {
                            if (HandleRedirection) {
                                HandleRedirection({typeOfSubmit: "QuitNoSave"});
                            } else {
                                history.push(RedirectUrlOnQuit);
                            }
                        }, 30);
                    } else {
                        CompErrors.push("FormWithAPI : you didn't pass a RedirectUrlOnQuit");
                        setCompErrors(CompErrors);
                    }
                }
            } else if (typeofsubmit === "Publish") {
                if (WithToasts) {
                    //Ask confirmation via toastr
                    setToasterInput(FullSubmission);
                }
            } else if (typeofsubmit === "Reset") {
                //Reload the Form
            }
        }
    };

    /**************** RENDERING ********************** */

    const ShowAPIAnswerOnComponentRender = () => {
        if (ServerAnswerForShowAPIAnswerOnComponent.Show === true) {
            return ShowAPIAnswerOnComponent(ServerAnswerForShowAPIAnswerOnComponent);
        } else {
            return null;
        }
    };

    //Toastr Component : when Actions are confirmed via the toast -> Asking the API to send the data
    const OnToastrConfirmation = (FullSubmission) => {
        if (FullSubmission) {
            let action = FullSubmission.typeOfSubmit.action;
            let origin = FullSubmission.typeOfSubmit.origin;

            if (action === "Save") {
                //Save before redirecting
                let ID = FullSubmission.values.ApiID;
                let ServerData = FormatDataFormToServer(FullSubmission);
                setLoading(true);
                if (ID && ServerData) {
                    setTypeOfSubmit({type: "SaveAndQuit", ID: ID, data: ServerData});
                } else {
                    CompErrors.push("No ID or Data provided for a SaveAnQuit Form submission - request canceled");
                    setCompErrors(CompErrors);
                }
            }
            if (action === "Quit") {
                //close the
                //redirect
                if (HandleRedirection) {
                    HandleRedirection({typeOfSubmit: origin});
                } else {
                    history.push(RedirectUrlOnQuit);
                }
            }

            if (action === "Publish") {
                //Save before redirecting
                let ID = FullSubmission.values.ApiID;
                let ServerData = FormatDataFormToServer(FullSubmission);
                setLoading(true);
                if (ID && ServerData) {
                    setTypeOfSubmit({type: "Publish", ID: ID, data: ServerData});
                } else {
                    CompErrors.push("No ID or Data provided for a SaveAnQuit Form submission - request canceled");
                    setCompErrors(CompErrors);
                }
            }

            if (action === "Delete") {
                let ID = FullSubmission.values.ApiID;
                if (ID) {
                    setLoading(true);
                    setTypeOfSubmit({type: "Delete", ID: ID, data: null});
                } else {
                    CompErrors.push("No ID provided for a Delete Form submission - request canceled");
                    setCompErrors(CompErrors);
                }
            }
        }
    };

    const DevModeComponent = () => {
        if (DevMode === true && NodeDevMode === true) {
            if (DevCheckValues.content) {
                // if (DevCheckValues) {
                return (
                    <div className={classes.DevMode}>
                        <div className={classes.DevModeButtonBar}>
                            <Button
                                type="button"
                                Width="200px"
                                Height="50px"
                                Background="rgb(211, 78, 100)"
                                ClassExtendButton={classes.ClassExtendButton}
                                style={{margin: "0px 10px 0px 10px"}}
                                onClick={() => {
                                    setDevCheckValues((prev) => {
                                        return {...prev, ...{Show: true}};
                                    });
                                }}
                            >
                                DEV MODE API SHOW RESPONSE
                            </Button>
                            <Button
                                type="button"
                                Width="200px"
                                Height="50px"
                                Background="rgb(211, 78, 100)"
                                ClassExtendButton={classes.ClassExtendButton}
                                style={{margin: "0px 10px 0px 10px"}}
                                onClick={() => {
                                    setDevCheckValues((prev) => {
                                        return {...prev, ...{Show: false}};
                                    });
                                }}
                            >
                                DEV MODE API HIDE RESPONSE
                            </Button>
                            <Button
                                type="button"
                                Width="200px"
                                Height="50px"
                                Background="rgb(211, 78, 100)"
                                ClassExtendButton={classes.ClassExtendButton}
                                style={{margin: "0px 10px 0px 10px"}}
                                onClick={() => {
                                    console.log("API Response :", DevCheckValues.content.data);
                                }}
                            >
                                DEV MODE LOG RESP IN CONSOLE
                            </Button>
                        </div>
                        <div style={{display: DevCheckValues.Show === true ? "block" : "none"}}>
                            <h2>API SERVER RESPONSE</h2>

                            <pre className={classes.DevModeData}>{JSON.stringify(DevCheckValues.content.data, 0, 3)}</pre>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    if (FormComponent) {
        if (WithCardModal === true) {
            FinalFormComponent = (
                <FormCardModal {...CardModalOptions}>
                    {ShowAPIAnswerOnComponentRender()}
                    {DevModeComponent()}
                    <FormComponent
                        {...props}
                        ButtonSaveAsDraft={ButtonSaveAsDraft}
                        ButtonSaveAndQuit={ButtonSaveAndQuit}
                        ButtonQuitNoSave={ButtonQuitNoSave}
                        ButtonDelete={ButtonDelete}
                        ButtonPublish={ButtonPublish}
                        ButtonReset={ButtonReset}
                        ButtonNext={ButtonNext}
                        ButtonPrevious={ButtonPrevious}
                        FormData={FormValues}
                        OnSubmitExternal={OnFormSubmit}
                        DevMode={DevMode}
                        OpenInViewMode={OpenInViewMode}
                        SwitchEditMode={SwitchEditMode}
                    />
                </FormCardModal>
            );
        } else {
            FinalFormComponent = (
                <FormComponent
                    {...props}
                    ButtonSaveAsDraft={ButtonSaveAsDraft}
                    ButtonSaveAndQuit={ButtonSaveAndQuit}
                    ButtonQuitNoSave={ButtonQuitNoSave}
                    ButtonDelete={ButtonDelete}
                    ButtonPublish={ButtonPublish}
                    ButtonReset={ButtonReset}
                    ButtonNext={ButtonNext}
                    ButtonPrevious={ButtonPrevious}
                    FormData={FormValues}
                    OnSubmitExternal={OnFormSubmit}
                    DevMode={DevMode}
                    OpenInViewMode={OpenInViewMode}
                    SwitchEditMode={SwitchEditMode}
                />
            );
        }
    } else {
        FinalFormComponent = null;
    }
    var FinalComponent;
    if (FormValues) {
        if (CompErrors.length > 0) {
            let finalError = CompErrors.map((elem, index) => {
                return <div key={index}>{elem}</div>;
            });
            FinalComponent = (
                <div style={{color: "red"}}>
                    <h2>FormWithAPI Error</h2>
                    {finalError}
                </div>
            );
        } else {
            if (TypeOfSubmit) {
                if (!APIUrlRelative && !APIUrlAbsolute) {
                    return null;
                } else {
                    FinalComponent = (
                        <React.Fragment>
                            <FormAPI CRUDOption={TypeOfSubmit} APIUrlRelative={APIUrlRelative} APIUrlAbsolute={APIUrlAbsolute} ApiResponse={ServerAnswer} />
                            <LocalCircularLoader Loading={Loading} FullHeight={false} />
                            {DevModeComponent()}
                            {FinalFormComponent}

                            <FormToastr
                                toast={toast}
                                ToastInput={ToasterInput}
                                OnToastrConfirmation={OnToastrConfirmation}
                                ToastDeleteConfirmation={ToastDeleteConfirmation}
                                ToastPublishConfirmation={ToastPublishConfirmation}
                                ToastQuitWihtouSaveConfirmation={ToastQuitWihtouSaveConfirmation}
                                ToastSavedMessage={ToastSavedMessage}
                                ToastDeletedMessage={ToastDeletedMessage}
                                ToastPublishedMessage={ToastPublishedMessage}
                                LocaleToastMessage={LocaleToastMessage}
                            />
                        </React.Fragment>
                    );
                }
            } else {
                FinalComponent = null;
            }
        }
    } else {
        if (CompErrors.length > 0) {
            let finalError = CompErrors.map((elem, index) => {
                return <div key={index}>{elem}</div>;
            });
            FinalComponent = (
                <div style={{color: "red"}}>
                    <h2>FormWithAPI Error</h2>
                    {finalError}
                </div>
            );
        } else {
            if (TypeOfSubmit) {
                if (!APIUrlRelative && !APIUrlAbsolute) {
                    return null;
                } else {
                    FinalComponent = (
                        <React.Fragment>
                            <FormAPI CRUDOption={TypeOfSubmit} APIUrlRelative={APIUrlRelative} APIUrlAbsolute={APIUrlAbsolute} ApiResponse={ServerAnswer} />
                            <LocalCircularLoader Loading={Loading} FullHeight={false} />
                        </React.Fragment>
                    );
                }
            } else {
                FinalComponent = null;
            }
        }
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;
});

FormWithApi.defaultProps = {
    WithCardModal: false,

    CardModalOptions: {ShowCard: true},

    ShowAPIAnswerOnComponent: null,
    InitialCall: null,
    RedirectUrlOnQuit: null,
    HandleRedirection: null,
    APIUrlRelative: null,
    APIUrlAbsolute: null,
    FormatDataServerToForm: null,
    FormatDataFormToServer: null,
    FormComponent: null,
    WithToasts: true,
    LocaleToastMessage: "en",
    ToastDeleteConfirmation: null,
    ToastPublishConfirmation: null,
    ToastQuitWihtouSaveConfirmation: null,
    ToastSavedMessage: null,
    ToastDeletedMessage: null,
    ToastPublishedMessage: null,
    ButtonSaveAsDraft: {Active: true, Text: "Save draft"},
    ButtonSaveAndQuit: {Active: true, Text: "Save and quit"},
    ButtonQuitNoSave: {Active: true, Text: "Quit"},
    ButtonDelete: {Active: true, Text: "Delete"},
    ButtonPublish: {Active: true, Text: "Publish"},
    ButtonReset: {Active: true, Text: "Reset"},
    ButtonNext: {Active: true, Text: "Next", Save: true, FormConditions: {invalid: false, pristine: false}},
    ButtonPrevious: {Active: true, Text: "Previous", Save: true, FormConditions: {invalid: false, pristine: false}},
    DevMode: false,
    OpenInViewMode: false,
    SwitchEditMode: "Edit",
};

FormWithApi.propTypes = {
    /**
     * if true -> Create a modal form that prevents the user to do anything outside the form
     */
    WithCardModal: PropTypes.any,

    /**
     * The FormCardModal options
     */
    CardModalOptions: PropTypes.any,

    /**
     * if true -> Before sending the answer to the API, it will :
     * 1) show the Component ShowAPIAnswerOnComponent
     * 2) Send the API Request with the formDATA
     * 3) Pass the server Answer to the Component
     * Usage : const ShowAPIAnswerOnComponent = (data)=> {
     * return (<div> {data}</div>)}
     */
    ShowAPIAnswerOnComponent: PropTypes.any,

    /**
     * Defines what CRUD Operation needs to be perfomed when the Component mounts
     * {type: "Edit", ID: "5e5646488e77db4618dba127"} or {type: "New"}
     * It will use the APIURLRelative to build the post url or the edit url with the /:id
     */
    InitialCall: PropTypes.any,
    /**
     * Redirecting the user to another url when the form is closed
     * RedirectUrlOnQuit ="/testtutorialslibrary/tableview"
     */
    RedirectUrlOnQuit: PropTypes.any,
    /**
     * Managing the redirection from the parent Component
     * const HandleRedirection = (args)=>{
     * //do somethings
     * }
     * the args will contain the button that was pressed
     */
    HandleRedirection: PropTypes.any,

    /**
     *
     * const FinalAPIUrlRelative = {
     *  MAIN: "http://localhost:3653/api/tutorials",
     *  DELETE: "",
     *  PUT: "",
     *  POST: "addtutorial"
     * };
     */
    APIUrlRelative: PropTypes.any,
    /**
     * const APIUrlAbsolute = {
     *  MAIN: "http://localhost:3653/api/tutorials",
     *  DELETE: "http://localhost:3653/api/tutorials",
     *  PUT: "http://localhost:3653/api/tutorials",
     *  POST: "http://localhost:3653/api/tutorials"/addtutorial"
     * };
     */
    APIUrlAbsolute: PropTypes.any,
    /**
     * Function to format the data received from the API to a format readable by the form component
     * const FormatDataServerToForm = response => {
     * let data = response.data;
     * let categoryOptions = data.categories.map(elem => {
     *    return {value: elem._id, label: elem.category};
     *   });
     *   var InitialFormValues = {
     *       InitiaFormValues: {
     *           ApiID: data.tutorial._id,
     *           title: data.tutorial.titleDocument,
     *           category: data.tutorial.ref_TutorialsCategory,
     *           published: data.tutorial.statusPublish
     *       },
     *       SelectOptions: {author: authorOptions, category: categoryOptions, keyword: keywordsOptions}
     *   };
     *   return InitialFormValues;
     * };
     */
    FormatDataServerToForm: PropTypes.any,
    /**
     * Function to format the data received from the form to a format readable by API
     * const FormatDataFormToServer = FullSubmission => {
     *   let values = FullSubmission.values;
     *   if (values) {
     *       let ServerData = {
     *           API_id: values.ApiID,
     *           titleDocument: values.title,
     *           summaryDocument: values.summary,
     *           statusPublish: FullSubmission.typeOfSubmit === "Publish" ? true : false,
     *           statusTemp: false,
     *           statusArchived: false,
     *           ref_TutorialsCategory: values.category,
     *           ref_Author: values.author,
     *           ref_TutorialsKeyword: values.keywords
     *       };
     *       return ServerData;
     *   }
     * };
     */
    FormatDataFormToServer: PropTypes.any,

    /**
     * A Form Component using the FormComponentTemplate
     * The way the Form is built and the buttons are required to work with those components
     * see FormComponentTemplate info to learn more
     */
    FormComponent: PropTypes.any,
    /**
     * WithToasts : true/false. If False, not toats will be provided
     */
    WithToasts: PropTypes.any,

    /**
     * The Language of the Toasters
     * LocaleToastMessage="fr" options : fr/en
     */
    LocaleToastMessage: PropTypes.any,
    /**
     * ToastDeleteConfirmation ={Message : "Are you sure you want to delete ?" ,CancelButton :"Cancel" , DeleteButton: "Delete"}
     */
    ToastDeleteConfirmation: PropTypes.any,
    /**
     * ToastPublishConfirmation ={Message : "Are you sure you want to publish this records ?" ,CancelButton :"Cancel" , CancelButton: "Publish"} :
     */
    ToastPublishConfirmation: PropTypes.any,
    /**
     * ToastQuitWihtouSaveConfirmation ={Message : "Are you sure you want to quit without saving ?" ,QuitNoSaveButton  :"Quit" , SaveAndQuitButton: "Save"} :
     */
    ToastQuitWihtouSaveConfirmation: PropTypes.any,
    /**
     * ToastSavedMessage = "Entry Saved"
     */
    ToastSavedMessage: PropTypes.any,
    /**
     * ToastSavedMessage = "Entry Deleted"
     */
    ToastDeletedMessage: PropTypes.any,
    /**
     * ToastSavedMessage = "Entry Published"
     */
    ToastPublishedMessage: PropTypes.any,
    /**
     * ButtonSaveAsDraft: {Active: true, Text: "Save draft"},
     */
    ButtonSaveAsDraft: PropTypes.any,

    /**
     * ButtonSaveAndQuit: {Active: true, Text: "Save and quit"},
     */
    ButtonSaveAndQuit: PropTypes.any,

    /**
     * ButtonDelete: {Active: true, Text: "Delete"},
     */
    ButtonDelete: PropTypes.any,

    /**
     *  ButtonPublish: {Active: true, Text: "Publish"},
     */
    ButtonPublish: PropTypes.any,

    /**
     * ButtonReset: {Active: true, Text: "Reset"},
     */
    ButtonReset: PropTypes.any,
    /**
     * ButtonQuitNoSave: {Active: true, Text: "Quit"},
     */
    ButtonQuitNoSave: PropTypes.any,
    /**
     * ButtonNext:{Active: true, Text: "Next", Save: true, FormConditions: {invalid: false, pristine: false}},
     */
    ButtonNext: PropTypes.any,
    /**
     * ButtonPrevious:{Active: true, Text: "Previous", Save: true, FormConditions: {invalid: false, pristine: false}},
     */
    ButtonPrevious: PropTypes.any,

    /**
     * DevMode : true/false display dev mode buttons to check the form content
     */
    DevMode: PropTypes.any,

    /**
     * OpenInViewMode : true/false Open the form in view mode and only activate the edit mode when a field is clicked
     */
    OpenInViewMode: PropTypes.any,

    /**
     * SwitchEditMode : true/false display dev mode buttons to check the form content
     */
    SwitchEditMode: PropTypes.any,
};

export default FormWithApi;
