import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import AGGridAPICall from "../AGGridAPICall/AGGridAPICall";
import AGGridTableMain from "../AGGridTableMain/AGGridTableMain";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const AgGridTable = React.memo(function AgGridTable(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        TableName,
        URLAbsolute,
        Data,
        MainDataSource,
        TableColumns,
        RowActionButtons,
        ColumnButtonStyle,
        TableOptions,
        ReloadData,
        SyncReloadDataStateFunction,
        TotalRow,
        PaginationOptions,
		ShareGridApi,
		ExcelStyles,

        //
        // Dimensions,
        // Theme,
        // HeaderStyle,
        // FooterStyle,
        // BodyStyle,
    } = props;

    const theme = useTheme();
	//eslint-disable-next-line
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [TableData, setTableData] = useState(null);
    const [FullTableData, setFullTableData] = useState(null);
    const [CallApi, setCallApi] = useState(false);
    const [CrudAction, setCrudAction] = useState(null);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    //Checking all the tables options to ensure we pass clean data to the TableMain Component
    let FinalTotalRow = {};
    if (TotalRow) {
        let ColsToShow = [];
        if (TableColumns && Array.isArray(TableColumns)) {
            TableColumns.forEach((elem) => {
                if (elem.TotalRow) {
                    ColsToShow.push(elem.field);
                }
            });
        }

        FinalTotalRow.ColsToShow = ColsToShow;
        FinalTotalRow.Position = TotalRow.Position ? TotalRow.Position : "Bottom";
    }

    //Defining how to load the data for the table
    useEffect(() => {
        if (ReloadData == null) {
            if (Data) {
                //We don't use the APICall
                if (MainDataSource) {
                    setFullTableData(Data);
                    let RealData = _.get(Data, MainDataSource);

                    setTableData(RealData);
                } else {
                    setFullTableData(Data);
                    setTableData(Data);
                }
            } else {
                //We initiate the apicall
                setCallApi(true);
            }
        } else {
            if (ReloadData === true) {
                if (Data) {
                    //We don't use the APICall
                    if (MainDataSource) {
                        setFullTableData(Data);
                        let RealData = _.get(Data, MainDataSource);
                        setTableData(RealData);
                    } else {
                        setFullTableData(Data);
                        setTableData(Data);
                    }
                } else {
                    //We initiate the apicall
                    setCallApi(true);
                }
            }
        }

        //Execute your code here
    }, [URLAbsolute, Data, MainDataSource, ReloadData, TableName]);

    // useEffect(() => {
    //     console.log("Table UseEffect ReloadData");
    //     // console.log("Reloading the Table Data", ReloadData);
    //     if (ReloadData && ReloadData === true) {
    //         setCallApi(true);
    //     }
    // }, [ReloadData]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const APICallback = (args) => {
        if (args.Error === true) {
        } else {
            if (MainDataSource) {
                setFullTableData(args.Data);
                let RealData = _.get(args.Data, MainDataSource);
                setTableData(RealData);

                if (SyncReloadDataStateFunction && typeof SyncReloadDataStateFunction === "function") {
                    SyncReloadDataStateFunction(false);
                }
            } else {
                setTableData(args.Data);
                setFullTableData(args.Data);
                if (SyncReloadDataStateFunction && typeof SyncReloadDataStateFunction === "function") {
                    SyncReloadDataStateFunction(false);
                }
            }
            setCallApi(false);
            // console.log("TableData", args.Data);
        }
    };
    const HandleCrudAPIUpdate = (params) => {
        setTableData(null);
        setFullTableData(null);
        setCrudAction({Action: "DeleteOne", Id: params.Id});
        setCallApi(true);

        //We update the CrudAction
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    let ApICallComponent;
    if (CallApi === true) {
        ApICallComponent = <AGGridAPICall URLAbsolute={URLAbsolute} CrudAction={CrudAction} APICallback={APICallback} />;
    } else {
        ApICallComponent = null;
    }
    let AGTableComponent;
    if (TableData) {
        AGTableComponent = (
            <AGGridTableMain
                TableColumns={TableColumns}
                TableData={TableData}
                FullTableData={FullTableData}
                RowActionButtons={RowActionButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                CrudAPIUpdate={HandleCrudAPIUpdate}
                TableOptions={TableOptions}
                TotalRow={FinalTotalRow}
                PaginationOptions={PaginationOptions}
				ShareGridApi={ShareGridApi}
				ExcelStyles={ExcelStyles}
            />
        );
    } else {
        AGTableComponent = null;
    }

    return (
        <React.Fragment>
            {ApICallComponent}
            {AGTableComponent}
        </React.Fragment>
    );

    /****************************** RENDER *********************/
});

AgGridTable.defaultProps = {
    TableColumns: null,
};

AgGridTable.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default AgGridTable;
