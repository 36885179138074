/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Page from "../../../../../artibulles-cis/Page/Page";

import _ from "lodash";
const {format, parseISO, formatISO} = require("date-fns");

import FormDemosForm from "./FormDemosFormSelect";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "0px",
    },
}));

const Component = (props) => {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal = "test";
    const InitialCall = {type: "Edit", ID: IdFinal};

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/zdemos/form/edit`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Customer._id;
            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Data", data);

        // var CompanySiteTypes;
        // if (data.CompanySiteTypes && data.CompanySiteTypes.length > 0) {
        //     CompanySiteTypes = data.CompanySiteTypes.map((elem) => {
        //         return {value: elem._id, label: elem.name_EN};
        //     });
        // }

        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }

        let Account = data.Account ? data.Account : null;

        let SimpleSelectOptions = [
            {label: "Arnaud", value: "01"},
            {label: "Nathan", value: "02"},
            {label: "Tilia", value: "04"},
            {label: "Maman", value: "05"},
            {label: "Papa", value: "06"},
            {label: "Spiderman", value: "07"},
            {label: "Superman", value: "08"},
            {label: "Cacaman", value: "09"},
            {label: "Pipiman", value: "10"},
        ];
        let MultiSelectOptions = [
            {label: "Arnaud", value: "01"},
            {label: "Nathan", value: "02"},
            {label: "Tilia", value: "04"},
            {label: "Maman", value: "05"},
            {label: "Papa", value: "06"},
            {label: "Spiderman", value: "07"},
            {label: "Superman", value: "08"},
            {label: "Cacaman", value: "09"},
            {label: "Pipiman", value: "10"},
        ];

        let ConditionalSelect1 = [
            {label: "Breakfast", value: "01"},
            {label: "Lunch", value: "02"},
            {label: "Snack", value: "03"},
            {label: "Dinner", value: "04"},
        ];
        let ConditionalSelect2 = [
            {label: "Light", value: "01"},
            {label: "Full", value: "02"},
            {label: "Light", value: "03"},
            {label: "N/a", value: "04"},
            {label: "Light", value: "05"},
            {label: "Full", value: "06"},
        ];
        let ConditionalSelect3 = [
            {label: "Yogurt", value: "01"},
            {label: "Cereal", value: "02"},
            {label: "Eggs", value: "03"},
            {label: "Sandwich", value: "04"},
            {label: "Salad", value: "05"},
            {label: "Soup", value: "06"},
            {label: "Chips", value: "07"},
            {label: "Choclate", value: "08"},
            {label: "Nuts", value: "09"},
            {label: "Steak", value: "10"},
            {label: "Pasta", value: "11"},
            {label: "Ribs", value: "12"},
        ];

        let FullOptions = [];

        let SimulatedServerDateIso = "2020-11-12T00:00:00+01:00";
        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Account ? Account._id : "new",
                simple_select: "02",
                multi_select: ["04", "07"],
            },
            SelectOptions: {
                simple_select: SimpleSelectOptions,
                multi_select: MultiSelectOptions,
                create_single: MultiSelectOptions,
                create_multiple: MultiSelectOptions,
                ConditionalSelect1: ConditionalSelect1,
                ConditionalSelect2: [],
                ConditionalSelect3: [],
            },
            SubOptionsFull: {
                ConditionalSelect1: [
                    {ref_ConditionalSelect1: "01", name: "Light Breakfast", id: "01"},
                    {ref_ConditionalSelect1: "01", name: "Full Breakfast", id: "02"},
                    {ref_ConditionalSelect1: "02", name: "Light Lunch", id: "03"},
                    {ref_ConditionalSelect1: "03", name: "N/A", id: "04"},
                    {ref_ConditionalSelect1: "04", name: "Light Dinner", id: "05"},
                    {ref_ConditionalSelect1: "04", name: "Full Dinner", id: "06"},
                ],
                ConditionalSelect2: [
                    {ref_ConditionalSelect2: "01", name: "Yogurt", id: "01"},
                    {ref_ConditionalSelect2: "01", name: "Cereal", id: "02"},
                    {ref_ConditionalSelect2: "02", name: "Eggs", id: "03"},
                    {ref_ConditionalSelect2: "03", name: "Sandwich", id: "04"},
                    {ref_ConditionalSelect2: "03", name: "Salad", id: "05"},
                    {ref_ConditionalSelect2: "04", name: "Soup", id: "06"},
                    {ref_ConditionalSelect2: "04", name: "Chips", id: "07"},
                    {ref_ConditionalSelect2: "04", name: "Choclate", id: "08"},
                    {ref_ConditionalSelect2: "04", name: "Nuts", id: "09"},
                    {ref_ConditionalSelect2: "06", name: "Steak", id: "10"},
                    {ref_ConditionalSelect2: "05", name: "Pasta", id: "11"},
                    {ref_ConditionalSelect2: "06", name: "Ribs", id: "12"},
                ],
            },
            FilesUploaders: {
                // fileuploaderName: data.tutorial.Files,
            },
            InitiaFormErrors: {},
            InitialFormErrorMain: FormErrorMain,
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Account: {
                        Updatable: {
                            account_number: values.account_number,
                            bic: values.bic,
                            account_owner: values.account_owner,
                            bank_name: values.bank_name,
                            isDefaultAccount: values.isDefaultAccount === true ? true : false,
                            status: values.status,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page
            // TabRef={Ref_TabNavContent}
            // ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            WithPerfectScrollBar={true}
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                OpenInViewMode={false}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={FormDemosForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: true, Text: "Save"}}
                ButtonSaveAndQuit={{Active: false, Text: "Save"}}
                ButtonQuitNoSave={{Active: false, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: true, Text: "Reset"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
};

export default Component;
