import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Link, useRouteMatch, useLocation} from "react-router-dom";
import ScrollToRef from "../utils/ScrollToRef";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    NavSideBarLink: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink,
    NavSideBarLink_WithTree: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink_WithTree,
    NavSideBarLinkActive: (props) =>
        props.WithTree === true
            ? theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink_WithTree.NavSideBarLink_Active
            : theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink.NavSideBarLink_Active,
    NavSideBarLink_ActiveIndicator_Inactive: (props) =>
        props.WithTree === true
            ? theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink_WithTree.ActiveIndicator_Inactive
            : theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink.ActiveIndicator_Inactive,
    NavSideBarLink_ActiveIndicator_Active: (props) =>
        props.WithTree === true
            ? theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink_WithTree.ActiveIndicator_Active
            : theme.NavSideBarLinkAccordion.NavSideBarLinkAccordionSubLink.ActiveIndicator_Active,
}));
/**
 * A NavLink Element to be placed inside the NavSideBarLinkAccordion that manages the Active Status with different styles Options.
 * Props from the NavLink elements are transfered (exact and strict for instance, refer to the NavLink element for more information)
 * Indicator : Box to the Left of the SideBar that is highlighted when the link is Active.
 */

const NavSideBarLinkAccordionSubLink = React.forwardRef(function NavSideBarLinkAccordionSubLink(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Slug,
        ActiveLinkIndicator,
        WithTree,
        ScrollToLink,
        onClick,
        ClassOverrideNavSideBarLink,
        ClassExtendNavSideBarLink,
        ClassOverrideNavSideBarLink_Active,
        ClassExtendNavSideBarLink_Active,
        ClassOverrideActiveLink_Indicator_Inactive,
        ClassOverrideActiveLink_Indicator_Active,
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/
    const match = useRouteMatch(Slug);
    const Location = useLocation();
    /****************************** REFS *********************/
    const RefLink = React.useRef(null);
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [IsActiveLink, setIsActiveLink] = React.useState(false);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Updating the Active Link Based on route Changed
    React.useEffect(() => {
        if (Slug) {
            if (match && match.isExact) {
                setIsActiveLink(true);
                if (ScrollToLink === true && RefLink && RefLink.current) {
                    if (Location && Location.state && Location.state.ClickedLink === true) {
                    } else {
                        // ScrollToRef(RefLink);
                    }
                }
            } else {
				//Adding Options to have non exact sublinks
                var Active = false;
                let CurrentPath = Location.pathname;
                    if (CurrentPath.includes(Slug)) {
                        Active = true;
                    }
                
                if (Active === true) {
                    setIsActiveLink(true);
                } else {
                    setIsActiveLink(false);
                }
                
            }
        }
    }, [Slug, match, ScrollToLink, Location]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var ActiveIndicator;
    if (ActiveLinkIndicator) {
        ActiveIndicator = (
            <div
                className={
                    IsActiveLink
                        ? ClassOverrideActiveLink_Indicator_Active
                            ? ClassOverrideActiveLink_Indicator_Active
                            : classes.NavSideBarLink_ActiveIndicator_Active
                        : ClassOverrideActiveLink_Indicator_Inactive
                        ? ClassOverrideActiveLink_Indicator_Inactive
                        : classes.NavSideBarLink_ActiveIndicator_Inactive
                }
            ></div>
        );
    } else {
        ActiveIndicator = null;
    }

    var FinalLinkClass;
    if (ClassOverrideNavSideBarLink) {
        FinalLinkClass = ClassOverrideNavSideBarLink;
    } else {
        if (WithTree) {
            FinalLinkClass = clsx(classes.NavSideBarLink_WithTree, ClassExtendNavSideBarLink);
        } else {
            FinalLinkClass = clsx(classes.NavSideBarLink, ClassExtendNavSideBarLink);
        }
    }

    if (IsActiveLink) {
        if (ClassOverrideNavSideBarLink_Active) {
            FinalLinkClass = clsx(FinalLinkClass, ClassOverrideNavSideBarLink_Active);
        } else {
            FinalLinkClass = clsx(FinalLinkClass, classes.NavSideBarLinkActive, ClassExtendNavSideBarLink_Active);
        }
    }

    var FinalComponent;
    if (Slug) {
        FinalComponent = (
            <Link
                ref={RefLink}
                to={{
                    pathname: `${Slug}`,
                    state: {ClickedLink: false},
                }}
                className={FinalLinkClass}
            >
                {ActiveIndicator}
                {children}
            </Link>
        );
    } else {
        FinalComponent = (
            <div ref={RefLink} onClick={onClick} className={FinalLinkClass}>
                {ActiveIndicator}
                {children}
            </div>
        );
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;
    /****************************** RENDER *********************/
});

NavSideBarLinkAccordionSubLink.defaultProps = {
    Slug: null,
    ActiveLinkIndicator: false,
    WithTree: false,
    ScrollToLink: true,
    onClick: null,
    ClassOverrideNavSideBarLink: null,
    ClassExtendNavSideBarLink: null,
    ClassOverrideNavSideBarLink_Active: null,
    ClassExtendNavSideBarLink_Active: null,
    ClassOverrideActiveLink_Indicator_Inactive: null,
    ClassOverrideActiveLink_Indicator_Active: null,
};

NavSideBarLinkAccordionSubLink.propTypes = {
    /**
     * The relative link to the page to display
     */
    Slug: PropTypes.string,
    /**
     * Add an Indicator Box to the let of the SideBar that is Highlighted when the link is active.boolean
     */
    ActiveLinkIndicator: PropTypes.bool,
    /**
     * Specify the theme to be used tree view
     */
    WithTree: PropTypes.bool,
    /**
     * If true, when the route matches from the oustide it will scroll to the element (useful for search or other programmatic navigation)
     */
    ScrollToLink: PropTypes.bool,
    /**
     * Only used for non link elements (button type)
     */
    onClick: PropTypes.func,
    /**
     * Overriding the default class of the NavSideBarLink.
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideNavSideBarLink: PropTypes.string,
    /**
     * Overriding the default class of the Controller Icon Wrapper
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassExtendNavSideBarLink: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideNavSideBarLink_Active: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassExtendNavSideBarLink_Active: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideActiveLink_Indicator_Inactive: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideActiveLink_Indicator_Active: PropTypes.string,
};

export default NavSideBarLinkAccordionSubLink;
