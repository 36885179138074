import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import CardModal from "@artibulles-cis/react/CardModal";
import Page from "../../../../artibulles-cis/Page/Page";
import Table from "../../../../artibulles-cis/TableNew/Table";
import Button from "@artibulles-cis/react/Button";

import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import _ from "lodash";
import {toast} from "react-toastify";
import APICallExternal from "../../../../artibulles-cis/utils/APICallExternal";
import FormCompontent from "../../../../artibulles-cis/FormComponent/FormCompontent";

import {isValid, format, parseISO} from "date-fns";

import ProgramEditDetails from "./Program/ProgramEdit/ProgramEditDetails";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 15px 10px 15px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "100%",
        maxHeight: "700px",
        margin: "0 auto",
        justifyContent: "center",
    },
    // TableWrapper: {
    //     position: "relative",
    //     boxSizing: "border-box",
    //     padding: "10px 10px",
    //     display: "flex",
    //     width: "100%",
    //     // maxWidth: "1200px",
    //     // maxHeight: "800px",
    //     height: "500px",
    //     margin: "0 auto",
    //     justifyContent: "center",
    // },
    TableToolbar: {
        display: "flex",
    },
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        // flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const Ref_CurrentTableData = useRef();
    const Ref_LocaleData = useRef();

    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const Ref_URLAbsolute = useRef({
        MAIN: `${ProdURL}/api/tasks/query`,
        DELETE: `${ProdURL}/api/tasks/task/`,
        PUT: "",
        POST: "",
    });

    const ColomnsInput = [
        {width: "auto", caption: "Program", datafield: "program_name", fixedCol: false, searchable: true, sortable: true, datatype: "text", wrapCell: true},
        {width: "350px", caption: "Description", datafield: "description", fixedCol: false, searchable: false, sortable: true, wrapColHeader: true, datatype: "text", wrapCell: true},

        {
            width: "250px",
            caption: "Company",
            datafield: "ref_company",
            lookup: {data: "Companies", searchField: "_id", returnField: "company"},
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            // style: {fontSize: "20px", justifyContent: "center"},
        },
    ];

    const deletecondition = (args) => {
        // console.log("args",args.rowData)
        return true;
        // if (args.rowData.company) {
        //     return true;
        // } else {
        //     return false;
        // }
    };
    const HandlePreviewRow = (rowData) => {
        if (rowData && rowData.rowData) {
            let id = rowData.rowData._id;
            console.log("id", id);
            setShowViewCard(true);
            setViewCardId(id);
        }
    };
    const TotalRow = {
        Show: false,
    };

    const HandleEditRow = (arg) => {
        let Id = arg._id;
        History.push(`/programs/program/${Id}/view`);
    };

    const HandleDeleteRow = (arg) => {
        let Id = arg._id;
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/programs/program/${Id}`, type: "delete"};
            setLoading(true);
            setAPICallType("deleteone");
            setisIntialized(false);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 30,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: false,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "Edit",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
            {
                Standard: true,
                Name: "Delete",
                ActiveCondition: null,
                Position: 2,
                OnClick: HandleDeleteRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
    };
    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [isIntialized, setisIntialized] = useState(true);
    const [ViewCardId, setViewCardId] = useState(null);
    const [UpdatedTableData, setUpdatedTableData] = useState(null);
    const [TableComponent, setTableComponent] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState("InitSelect");
    const [InitialFormValues, setInitialFormValues] = React.useState({
        company_name: "All",
        is_completed: "Due",
        late_status: "All",
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({
        company_name: "All",
        is_completed: "Due",
        late_status: "All",
    }); //FINAL FORM VALUES

    const [FieldOptions, setFieldOptions] = useState({
        company_name: [{label: "All", value: "All"}],
        is_personal: [
            {label: "Work", value: "Work"},
            {label: "All", value: "All"},
            {label: "Personal", value: "Personal"},
        ],
        late_status: [
            {label: "Late", value: "Late"},
            {label: "All", value: "All"},
            {label: "Upcoming", value: "Upcoming"},
        ],
    });

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        if (Ref_LocaleData.current && isIntialized === true) {
            let FinalTableComponent = (
                <Table
                    LocalData={Ref_LocaleData.current}
                    // URLAbsolute={Ref_URLAbsolute.current}
                    MainDataSource="Programs"
                    ColomnsInput={ColomnsInput}
                    TableDimensions={TableDimensions}
                    TableOptions={TableOptions}
                    TotalRow={TotalRow}
                    DevMode={false}
                />
            );
            setTableComponent(FinalTableComponent);
        } else {
            setTableComponent("There is no program");
        }
    }, [Ref_LocaleData, isIntialized]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                console.log("ApiRes", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    let data = ApiRes.data.data;
                    console.log("data", data);
                    if (APICallType === "InitSelect") {
                        //We initiate the select
                        console.log("select data");
                        var Companies = [];

                        if (data.Companies && data.Companies.length > 0) {
                            Companies = data.Companies.map((elem) => {
                                return {value: elem._id, label: elem.company, internal: false};
                            });
                        }

                        Companies.push({value: "All", label: "All"});
                        console.log("Companies", Companies);
                        setFieldOptions({...FieldOptions, ...{company_name: Companies}});

                        setAPICallType(null);
                    } else {
                        if (data.Programs && Array.isArray(data.Programs) && data.Programs.length > 0) {
                            Ref_LocaleData.current = data;
                        } else {
                            Ref_LocaleData.current = null;
                        }

                        setisIntialized(true);
                    }
                }

                if (APICallType === "deleteone") {
                    toast.success(`Entry deleteted`, {autoClose: true});
                    setisIntialized(true);
                    setAPICallType(null);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (isIntialized === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, isIntialized]);
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (APICallType !== "InitSelect") {
            //Calling the Api whenever the selects are changing to create the query
            console.log("FormValues", FormValues);
            let CompanyName = FormValues.company_name;

            let ApiCall = {url: `${ProdURL}/api/programs/programsqueries`, type: "get"};
            if (CompanyName !== "All") {
                ApiCall = {url: `${ProdURL}/api/programs/programsqueries/?companyid=${CompanyName}`, type: "get"};
            }

            // let URLBase = `${ProdURL}/api/tasks/query/?`;
            // let Query = {
            // 	dueonly: null,
            // 	is_personal: null,
            // 	is_late: null
            // }
            // if (FormValues.company_name) {
            // } else {
            //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/programs/programsqueries`;
            // }
            // if (FormValues.is_completed === "Due") {
            //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/?dueonly=true`;
            // } else if (FormValues.is_completed === "Completed") {
            //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/?dueonly=false`;
            // } else if (FormValues.is_completed === "All") {
            //     Ref_URLAbsolute.current.MAIN = `${ProdURL}/api/tasks/query/`;
            // }

            setLoading(true);

            setisIntialized(false);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [FormValues, APICallType]);

    useEffect(() => {
        //Called once to get the company list and initiate the selects
        setLoading(true);

        const ApiCall = {url: `${ProdURL}/api/programs/programsqueriesgetcompanies`, type: "get"};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        //Reload the table
        setisIntialized(false);
        setTimeout(() => {
            setisIntialized(true);
        }, 50);
    };

    const HandleShareTableData = (Data) => {
        Ref_CurrentTableData.current = Data;
        // setCurrentTableData(Data);
    };

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        // if (field === "ConditionalSelect1") {
        //     //We update the Options of the ConditionalSelect2
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect1 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
        //     //We also need to reset the Conditional 2;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        // }
        // if (field === "ConditionalSelect2") {
        //     //We update the Options of the ConditionalSelect3
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect2 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
        //     //We also need to reset the Conditional 3;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        // }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    let FinalTableComponent;
    if (isIntialized === true) {
        FinalTableComponent = TableComponent;
    } else {
        FinalTableComponent = null;
    }
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <ProgramEditDetails Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPage={classes.PageContentExtends}>
            {ModalCardView}
            <div className={classes.TableToolbar}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 500px"}}>
                        <FormCompontent
                            Name="company_name"
                            InitialValue={InitialFormValues.company_name ? InitialFormValues.company_name : null}
                            FormValue={FormValues.company_name ? FormValues.company_name : null}
                            OutputSelectedValues={(values) => handleSelectChange("company_name", values)}
                            SelectOptions={FieldOptions.company_name}
                            Component="Select"
                            Variant="OutlinedLabel"
                            Label="Company"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            // SortField="order"
                            SortFirstItemName={{Exact: false, Name: "All"}}
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            Required={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                        <FormCompontent
                            Name="is_personal"
                            InitialValue={InitialFormValues.is_personal ? InitialFormValues.is_personal : null}
                            FormValue={FormValues.is_completed ? FormValues.is_personal : null}
                            OutputSelectedValues={(values) => handleSelectChange("is_personal", values)}
                            SelectOptions={FieldOptions.is_personal}
                            Component="Select"
                            Variant="OutlinedLabel"
                            Label="Personal"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            // SortField="order"
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            Required={true}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.TableWrapper}>{FinalTableComponent}</div>
        </Page>
    );

    /***************** RENDER ******************/
});

export default Component;
// <div className={classes.FormField} style={{flex: "0 0 150px"}}>
//                         <FormCompontent
//                             Name="late_status"
//                             InitialValue={InitialFormValues.late_status ? InitialFormValues.late_status : null}
//                             FormValue={FormValues.late_status ? FormValues.late_status : null}
//                             OutputSelectedValues={(values) => handleSelectChange("late_status", values)}
//                             SelectOptions={FieldOptions.late_status}
//                             Component="Select"
//                             Variant="OutlinedLabel"
//                             Label="Status Late"
//                             NoMessage={false}
//                             // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
//                             // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
//                             MultiSelect={false}
//                             // SortSelectOptions="Asc"
//                             // SortField="order"
//                             SortIcons={false}
//                             AllowCreateOption={false}
//                             LabelMaxWidth="200px"
//                             LabelAlign="Left"
//                             Required={true}
//                         />
//                     </div>
