import React, {useState, useEffect, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import clsx from "clsx";
import anime from "animejs";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import AxiosAsyncAPICallsInit from "@artibulles-cis/react/utils/AxiosAsyncAPICallsInit";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {useSelector} from "react-redux";
import NavTopBarLink from "@artibulles-cis/react/NavTopBarLink";
import {ArrowSimpleLeft, ArrowSimpleRight} from "@artibulles-cis/react-icons";
import {Link} from "react-router-dom";
// import NavTopBarSubMenu from "@artibulles-cis/react/NavTopBarSubMenu";
// import NavTopBarSubLink from "@artibulles-cis/react/NavTopBarSubLink";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CompanyLink: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        "&:hover": {
            background: "rgba(35, 35, 35, 1)",
        },
    },
    CompanyLinkContent: {
        justifyContent: "flex-start",
        fontSize: "30px",
        "&:hover": {
            background: "rgba(35, 35, 35, 1)",
        },
    },
    CompanyLinkActive: {
        background: "rgba(35, 35, 35, 1)",
        // opacity : 0.4
    },
    TopNavIconCompany: {
        width: "55px",
        height: "55px",
    },

    MobileMenuButton_Wrapper: {
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        background: "rgba(35, 35, 35, 1)",
    },
    MobileMenuButton: {
        flex: "0 1 40px",
        display: "block",
        cursor: "pointer",
        margin: "0px 8px 0px 8px",
        //padding-left: 1.25em;
        position: "relative",
        width: "36px",
        height: "30px",
        top: "0px",
    },

    MobileMenuButton_Hide: {
        display: "none",
    },
    MobileMenuButtontop: {
        top: "0",
    },
    MobileMenuGlobal: {
        backfaceVisibility: "hidden",
        position: "absolute",
        left: "0",
        borderTop: "4px solid white",
        width: "100%",
        // transition: "0.40s",
    },
    MobileMenuButtonMiddle: {
        top: "13px",
    },
    MobileMenuButtonBottom: {
        top: "26px",
    },
    MobileMenuDropDown: {
        position: "fixed",
        top: "60px",
        display: "none",
        width: "100%",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 0px",
        backgroundColor: "rgba(47, 47, 47, 0.98)",
        height: "0%",
        // animation: "$slidedown_MobileDropDown 800ms 0s",
    },

    Mobile_MainNavMenu: {
        display: "block",
        width: "100%",
        height: "100%",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 0px",
        backgroundColor: "rgba(47, 47, 47, 0.98)",
        transform: "translate(0, 0)",
        // transition: "all 800ms 0ms",
    },
    Mobile_Artib_Nav_MainMenu_UL: {
        display: "block",
        alignItems: "center",
        listStyle: "none",
        flexWrap: "nowrap",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 0px",
        opacity: 0,
        transform: "scale(0)",
        color: "white",
        transformOrigin: "top left",
    },
    Mobile_Artib_Nav_MainMenu_LI: {
        display: "flex",
        border: "none",
        position: "relative",
        flex: "1 1 auto",
        textAlign: "left",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 40px",
        borderBottom: "solid 1px rgba(255, 255, 255, 0.2)",
        alignContent: "center",
        height: "45px",
    },
    MobileMenu_Link: {
        display: "flex",
        flex: "1 0 50%",
        fontSize: "20px",
        fontWeight: "400",
        padding: "0px 0px 0px 0px",
        margin: "0px 0px 0px 0px",
        textDecoration: "none",
        textTransform: "none",
        color: "white",
        position: "relative",
        zIindex: 1,
        alignItems: "center",
    },

    MobileMenu_Link_Text: {
        display: "block",
        whiteSpace: "nowrap",
        position: "relative",
    },
    MobileMenu_Link_ArrowSubMenu: {
        flex: "1 0 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 40px 0px 0px",
    },
    MobileMenu_SubMenuWrapper: {
        display: "block",
        width: "0%",
        backgroundColor: "rgba(87, 87, 87, 0.98)",
    },
    MobileMenu_SubMenuWrapper_TitleWrapper: {
        display: "flex",
        alignItems: "center",
        borderBottom: "solid 1px rgba(255,255,255,0.2)",
        height: "50px",
        color: "white",
    },
    MobileMenu_SubMenuWrapper_TitleWrapper_Button: {
        display: "flex",
        alignItems: "center",
    },
    MobileMenu_SubMenuWrapper_TitleWrapper_Content: {
        display: "block",
        flex: "1 0 auto",
        padding: "0px 60px 0px 0px",
        textAlign: "center",
        zIndex: 1,
        margin: "0 auto",
    },

    MobileMenu_SubMenuWrapper_ContentWrapper: {
        display: "block",
        width: "100%",
        height: "100%",
    },
    MobileSubMenu_UL: {
        display: "block",
        flex: "0 1 auto",
        alignItems: "center",
        listStyle: "none",
        flexWrap: "nowrap",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 0px",
        color: "white",
        zIndex: -1,
        border: "none",
    },
    MobileSubMenu_LI: {
        display: "flex",
        border: "none",
        position: "relative",
        flex: "1 1 auto",
        textAlign: "left",
        margin: "0px 0px 0px 0px",
        padding: "0px 0px 0px 40px",
        borderBottom: "solid 1px rgba(255, 255, 255, 0.2)",
        alignItems: "center",
        height: "35px",
    },
    MobileSubMenu_Link: {
        display: "block",
        flex: "1 0 auto",
        fontWeight: "300",
        fontSize: "1em",
        letterSpacing: "0.04em",
        padding: "0px",
        margin: "0px",
        textDecoration: "none",
        textTransform: "none",
        color: "white",
        textAlign: "left",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
}));

const TopNavBarMobile = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [IsMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const {UserEmail, Username, User} = useSelector((state) => ({
        User: state.auth.User.userProfile,
        UserEmail: state.auth.User.userProfile.email ? state.auth.User.userProfile.email : null,
        Username: state.auth.User.userProfile.username ? state.auth.User.userProfile.username : null,
    }));
    const [Loading, setLoading] = useState(false);
    const [APIServerResponse, setAPIServerResponse] = useState(AxiosAsyncAPICallsInit); //Contains the Server Response
    const [ApiRes, setApiRes] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    //Listening to API Answers
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                // console.log("Loged out", ApiRes);
                window.location.reload(true);
                if (ApiRes.data && ApiRes.data.data) {
                }
            }
        }
    }, [ApiRes, Loading]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    function MobileMenuIconOpen() {
        //LOGO ANIMATION
        var animelogoMobileTop = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animelogoMobileTop
            .add({
                targets: "#Mobile_menu_Button_top",
                translateY: {
                    value: 6,
                    duration: 500,
                    delay: 0,
                    easing: "easeInOutCubic",
                },
            })
            .add({
                targets: "#Mobile_menu_Button_top",
                translateX: {
                    value: 6,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                translateY: {
                    value: 14,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                rotate: {
                    value: 90,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
            })
            .add({
                targets: "#Mobile_menu_Button_top",
                translateX: {
                    value: 0,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                translateY: {
                    value: 14,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                rotate: {
                    value: 45,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
            });

        var animelogoMobileBottom = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animelogoMobileBottom
            .add({
                targets: "#Mobile_menu_Button_bottom",
                translateY: {
                    value: -6,
                    duration: 500,
                    delay: 0,
                    easing: "easeInOutCubic",
                },
            })
            .add({
                targets: "#Mobile_menu_Button_bottom",
                translateX: {
                    value: -6,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                translateY: {
                    value: -12,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                rotate: {
                    value: 90,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
            })
            .add({
                targets: "#Mobile_menu_Button_bottom",
                translateX: {
                    value: 0,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                translateY: {
                    value: -12,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                rotate: {
                    value: 135,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
            });

        anime({
            targets: "#Mobile_menu_Button_middle",
            opacity: {
                value: 0,
                duration: 300,
                easing: "easeInOutCubic",
            },
        });

        //MENU ANIMATION
        document.getElementById("DropDownMobileMenu").style.display = "flex";
        var animelogoMenu = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animelogoMenu
            .add(
                {
                    targets: "#DropDownMobileMenu",
                    height: {
                        value: ["0%", "100%"],
                        duration: 600,
                        delay: 0,
                        easing: "easeInOutCubic",
                    },
                },
                0
            )
            .add(
                {
                    targets: "#Artib_Nav_MainMenu_UL",
                    opacity: {
                        value: [0, 1],
                        duration: 600,
                        easing: "easeInOutCubic",
                    },
                    scale: {
                        value: [0, 1],
                        duration: 600,
                        easing: "easeInOutCubic",
                    },
                },
                0
            );
    }

    function MobileMenuIconClose() {
        var animelogoMobileTop = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animelogoMobileTop
            .add({
                targets: "#Mobile_menu_Button_top",
                rotate: {
                    value: [360, 0],
                    duration: 500,
                    delay: 0,
                    loop: 2,
                    easing: "easeOutCubic",
                },
            })
            .add({
                targets: "#Mobile_menu_Button_top",
                translateX: {
                    value: 0,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                translateY: {
                    value: 0,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
            });

        var animelogoMobileBottom = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animelogoMobileBottom
            .add({
                targets: "#Mobile_menu_Button_bottom",
                rotate: {
                    value: [-360, 0],
                    duration: 500,
                    delay: 0,
                    loop: 2,
                    easing: "easeOutCubic",
                },
            })
            .add({
                targets: "#Mobile_menu_Button_bottom",
                translateX: {
                    value: 0,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
                translateY: {
                    value: 0,
                    duration: 300,
                    delay: 0,
                    easing: "easeOutCubic",
                },
            });

        anime({
            targets: "#Mobile_menu_Button_middle",
            opacity: 1,
            duration: 300,
            easing: "easeInOutCubic",
        });
        //MENU ANIMATION
        var animelogoMenu = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animelogoMenu
            .add(
                {
                    targets: "#DropDownMobileMenu",
                    height: {
                        value: ["100%", "0%"],
                        duration: 600,
                        delay: 0,
                        easing: "easeInOutCubic",
                    },
                },
                0
            )
            .add(
                {
                    targets: "#Artib_Nav_MainMenu_UL",
                    opacity: {
                        value: [1, 0],
                        duration: 600,
                        easing: "easeInOutCubic",
                    },
                    // scale: {
                    //     value: [1, 0],
                    //     duration: 800,
                    //     easing: "easeInOutCubic",
                    // },
                },
                0
            );
        animelogoMenu.finished.then(() => {
            document.getElementById("DropDownMobileMenu").style.display = "none";
        });
    }

    const HandleMobileMenuOpenClose = () => {
        if (IsMobileMenuOpened === false) {
            MobileMenuIconOpen();
        } else {
            MobileMenuIconClose();
            setTimeout(() => {
                MobileSubMenuClose();
            }, 900);
        }

        setIsMobileMenuOpened(!IsMobileMenuOpened);
    };
    const HandleHomeButtonClick = () => {
        if (IsMobileMenuOpened === true) {
            MobileMenuIconClose();
            setTimeout(() => {
                MobileSubMenuClose();
            }, 900);
            setIsMobileMenuOpened(false);
        }
    };
    function MobileSubMenuOpen() {
        var animeSubMenuOpen = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animeSubMenuOpen
            .add(
                {
                    targets: "#Mobile_MainNavMenu",
                    width: {
                        value: ["100%", "0%"],
                        duration: 800,
                        easing: "easeOutCubic",
                    },
                },
                0
            )
            .add(
                {
                    targets: "#Mobile_MainNavMenu",
                    translateX: {
                        value: ["0px", "-500px"],
                        duration: 800,
                        easing: "easeOutCubic",
                    },
                },
                0
            )
            .add(
                {
                    targets: "#MobileMenu_SubMenuWrapper",
                    width: {
                        value: ["0%", "100%"],
                        duration: 800,
                        easing: "easeOutCubic",
                    },
                },
                0
            );
    }
    function MobileSubMenuClose() {
        var animeSubMenuClose = anime.timeline({
            direction: "normal",
            loop: false,
        });

        animeSubMenuClose
            .add(
                {
                    targets: "#Mobile_MainNavMenu",
                    width: {
                        value: ["0%", "100%"],
                        duration: 800,
                        easing: "easeOutCubic",
                    },
                },
                0
            )
            .add(
                {
                    targets: "#Mobile_MainNavMenu",
                    translateX: {
                        value: ["-500px", "0px"],
                        duration: 800,
                        easing: "easeOutCubic",
                    },
                },
                0
            )
            .add(
                {
                    targets: "#MobileMenu_SubMenuWrapper",
                    width: {
                        value: ["100%", "0%"],
                        duration: 800,
                        easing: "easeOutCubic",
                    },
                },
                0
            );
    }
    const HandleSubMenuOpen = () => {
        MobileSubMenuOpen();
    };
    const HandleSubMenuClose = () => {
        MobileSubMenuClose();
    };
    const HandleLogout = () => {
        const ApiCall = {url: `${ProdURL}/api/auth/logout`, type: "post", data: null};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();

        console.log("Logout");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <React.Fragment>
            <NavTopBarLink
                exact
                Slug="/"
                ClassExtendTopNavLink={classes.CompanyLink}
                ClassExtendNavTopBarLinkContent={classes.CompanyLinkContent}
                ClassExtendTopNavLinkActive={classes.CompanyLinkActive}
                WithActiveIndicator={false}
            >
                <img onClick={HandleHomeButtonClick} className={classes.TopNavIconCompany} src="/images/icons/ArtibullesLogo.svg" alt="Engineering Solutions" />
                <p style={{flex: "1 1 auto", textAlign: "center", justifyContent: "center", padding: "10px"}}>ArTiBulles-BMS</p>
            </NavTopBarLink>
            <div className={classes.MobileMenuButton_Wrapper}>
                <div id="MobileMenuNavigationButton" className={classes.MobileMenuButton} onClick={HandleMobileMenuOpenClose}>
                    <span id="Mobile_menu_Button_top" className={clsx(classes.MobileMenuGlobal, classes.MobileMenuButtonTop)}></span>
                    <span id="Mobile_menu_Button_middle" className={clsx(classes.MobileMenuGlobal, classes.MobileMenuButtonMiddle)}></span>
                    <span id="Mobile_menu_Button_bottom" className={clsx(classes.MobileMenuGlobal, classes.MobileMenuButtonBottom)}></span>
                </div>
            </div>
            <nav id="DropDownMobileMenu" className={classes.MobileMenuDropDown}>
                <div id="Mobile_MainNavMenu" className={classes.Mobile_MainNavMenu}>
                    <ul id="Artib_Nav_MainMenu_UL" className={classes.Mobile_Artib_Nav_MainMenu_UL}>
                        <li className={classes.Mobile_Artib_Nav_MainMenu_LI}>
                            <Link onClick={HandleMobileMenuOpenClose} to="/tasks" className={classes.MobileMenu_Link}>
                                <span className={classes.MobileMenu_Link_Text}>Tasks</span>
                            </Link>
                        </li>
                        <li className={classes.Mobile_Artib_Nav_MainMenu_LI}>
                            <Link onClick={HandleMobileMenuOpenClose} to="/time" className={classes.MobileMenu_Link}>
                                <span className={classes.MobileMenu_Link_Text}>Time Management</span>
                            </Link>
                        </li>
						<li className={classes.Mobile_Artib_Nav_MainMenu_LI}>
                            <Link onClick={HandleLogout} to="/" className={classes.MobileMenu_Link}>
                                <span className={classes.MobileMenu_Link_Text}>LogOut</span>
                            </Link>
                        </li>
						
                        <li className={classes.Mobile_Artib_Nav_MainMenu_LI} onClick={HandleSubMenuOpen}>
                            <div className={classes.MobileMenu_Link}>
                                <span className={classes.MobileMenu_Link_Text}>SubMenuTest</span>
                                <div className={classes.MobileMenu_Link_ArrowSubMenu}>
                                    <ArrowSimpleRight IconSize="20px" SVGStrokeColor="white" SVGStrokeHoverColor="white" SVGStrokeWidth="15px" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div id="MobileMenu_SubMenuWrapper" className={classes.MobileMenu_SubMenuWrapper}>
                    <div className={classes.MobileMenu_SubMenuWrapper_TitleWrapper}>
                        <div className={classes.MobileMenu_SubMenuWrapper_TitleWrapper_Button}>
                            <ArrowSimpleLeft onClick={HandleSubMenuClose} IconSize="20px" SVGStrokeColor="white" SVGStrokeHoverColor="white" SVGStrokeWidth="15px" />
                        </div>
                        <div className={classes.MobileMenu_SubMenuWrapper_TitleWrapper_Content}>SubMenuTest</div>
                    </div>
                    <div className={classes.MobileMenu_SubMenuWrapper_ContentWrapper}>
                        <ul className={classes.MobileSubMenu_UL}>
                            <li className={classes.MobileSubMenu_LI}>
                                <Link onClick={HandleMobileMenuOpenClose} to="/contact" className={classes.MobileMenu_Link}>
                                    Sub1
                                </Link>
                            </li>
                            <li className={classes.MobileSubMenu_LI}>
                                <Link onClick={HandleMobileMenuOpenClose} to="/contact" className={classes.MobileMenu_Link}>
                                    Sub2
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default TopNavBarMobile;
