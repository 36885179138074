import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import CardModal from "@artibulles-cis/react/CardModal";
import {PDF} from "@artibulles-cis/react-icons";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import TabNavContent from "../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import _ from "lodash";

import InvoiceEdit from "../01InvoiceEdit/InvoiceEdit";
import InvoiceDuplicate from "../02InvoiceDuplicate/InvoiceDuplicate";
import {differenceInDays, parseISO, format} from "date-fns";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PageContentExtends: {
        // background: "black",
        // backgroundImage: "url('/images/HomePageBackground.jpg')",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "50% 50%",
    },
    MainWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    ToolBar: {
        display: "flex",
        flexDirection: "column",
        flex: "0 0 auto",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    TableWrapperMain: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
    },
    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    TableToolbar: {
        display: "flex",
        flexDirection: "column",
    },
    SearchButtons: {
        padding: "0px 0px 8px 0px",
        flex: "0 1 auto",
        display: "flex",
    },
    ButtonExtend: {
        margin: "0px 0px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_FormValues = useRef(null);
    const Ref_FieldOptions = useRef(null);
    const Ref_TabNavContent = useRef(null);
    const Ref_LocaleData = useRef(null);
    const Ref_AgGridApi = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        if (value && Array.isArray(value) && value.length > 0) {
            let CellRenderComp = [];
            value.forEach((elem) => {
                let IconElem = (
                    <div onClick={() => HandleViewPdf(elem.serverPath)}>
                        <PDF SVGFillColor="white" />
                    </div>
                );
                CellRenderComp.push(IconElem);
            });

            return CellRenderComp;
        } else {
            return null;
        }
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const StatusRendering = (params) => {
        let RowData = params.data;

        let Status = RowData.status_invoice;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Invoiced") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                            Invoiced
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Paid") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(108,145,0)"}}>
                            Paid
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Reconciled") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                            Reconciled
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment>{Status}</React.Fragment>;
        }
    };
    const PaymentStatusRendering = (params) => {
        let RowData = params.data;

        let CalcIsPaid = RowData.CalcIsPaid;
        let ManualBankPaymentActionsStatus = RowData.ManualBankPaymentActionsStatus;
        let status_invoice = RowData.status_invoice;
        let PaymentMethod = RowData.PaymentMethod;
        if (status_invoice === "Draft") {
            return <React.Fragment />;
        } else {
            if (CalcIsPaid === true) {
                //We check the details of the Manual Actions
                if (ManualBankPaymentActionsStatus === "PaidExecuted") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Executed
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (ManualBankPaymentActionsStatus === "PaidPendingExecution") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(108,145,0)"}}>
                                    Paid Pending
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                }
            } else {
                if (PaymentMethod === "Bank Account Automatic") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Auto Bank
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (PaymentMethod === "Bank Account Manual") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Man Bank
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (PaymentMethod === "Credit Card Automatic") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Auto CC
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (PaymentMethod === "Credit Card Manual") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Man CC
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "orange"}}>
                                    {PaymentMethod}
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                }
            }
        }
    };

    const AccountantStatusRendering = (params) => {
        let RowData = params.data;
        let AccountantStatus = RowData.AccountantStatus;

        if (AccountantStatus) {
            if (AccountantStatus.Uploaded === true) {
                return (
                    <div className={classes.PaymentStatus}>
                        <div className={classes.PaymentStatus_BadgeWrapper}>
                            <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                Uploaded
                            </div>
                        </div>
                        <div className={classes.PaymentStatus_WarningWrapper}></div>
                    </div>
                );
            } else if (AccountantStatus.UploadedMethod === "Email To Confirm") {
                return (
                    <div className={classes.PaymentStatus}>
                        <div className={classes.PaymentStatus_BadgeWrapper}>
                            <div className={classes.PaymentStatus_Badge} style={{background: "orange", color: "black"}}>
                                Skwarel Upload
                            </div>
                        </div>
                        <div className={classes.PaymentStatus_WarningWrapper}></div>
                    </div>
                );
            } else {
                return (
                    <div className={classes.PaymentStatus}>
                        <div className={classes.PaymentStatus_BadgeWrapper}>
                            <div className={classes.PaymentStatus_Badge} style={{background: "red", color: "black"}}>
                                Not sent
                            </div>
                        </div>
                        <div className={classes.PaymentStatus_WarningWrapper}></div>
                    </div>
                );
            }
        } else {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "red"}}>
                            Not sent
                        </div>
                    </div>
                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                </div>
            );
        }
    };

    const DaysRemainingRendering = (params) => {
        let RowData = params.data;
        let InvoicePaymentDueDateIso = parseISO(RowData.InvoicePaymentDueDateIso);
        let Today = new Date(Date.now());
        let DaysRemaining = differenceInDays(InvoicePaymentDueDateIso, Today) + 1;
        if (DaysRemaining) {
            return DaysRemaining;
        } else {
            return null;
        }
    };

    const SentDateDateIsoRendering = (params) => {
        let RowData = params.data;
        let SentDateDateIso;
        console.log("sent", RowData.EmailToCustomerStatus);
        if (RowData.EmailToCustomerStatus && RowData.EmailToCustomerStatus.SentDateDateIso) {
            SentDateDateIso = format(parseISO(RowData.EmailToCustomerStatus.SentDateDateIso), "dd-MMM-yyyy");
        }
        // EmailToCustomerStatus: {
        // 	Sent: {
        // 	  type: Boolean,
        // 	  default: false,
        // 	},
        // 	SentDateDateIso: {
        // 	  type: Date,
        // 	  default: null,
        // 	},
        // 	SentMethod: {
        // 	  //Direct Email / Email To Confirm / Upload
        // 	  type: String,
        // 	  default: null,
        // 	},
        //   },

        if (SentDateDateIso) {
            return SentDateDateIso;
        } else {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "red"}}>
                            Not sent
                        </div>
                    </div>
                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                </div>
            );
        }
    };

    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };

    const HandleDuplicateInvoice = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };

    const DeleteCondition = (args) => {
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else {
            return true; //DEVONLY!! Should be false
        }
    };

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Duplicate",
            ActiveCondition: null,
            Index: 2,
            OnClick: HandleDuplicateInvoice,
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            // OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];

    const TableColumns = [
        {
            field: "InvoiceIssueDateIso",
            headerName: "Invoice Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "Calc_RefInvoice",
            headerName: "Ref",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "CalcTotalVATIncluded",
            headerName: "Amount (VAT)",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "status_invoice",
            //
            headerName: "Invoice Status",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
        },

        {
            field: "InvoicePaymentDueDateIso",
            headerName: "Due Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "InvoicePaymentDueDateIso",
            //
            headerName: "Due Days",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: DaysRemainingRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "SentDateDateIso",
            headerName: "Sent Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            cellRenderering: SentDateDateIsoRendering,
            cellStyle: PaymentCell,
        },

        {
            field: "AccountantStatus",
            //
            headerName: "Accountant Status",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountantStatusRendering,
            cellStyle: PaymentCell,
        },

        {
            headerName: "Supplier",
            headerClass: "ag-CenterHeader",
            width: 300,
            filter: true,
            Lookup: {LookupField: "ref_Customer", Source: "Customers", ReturnField: "company"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "Description",
            headerName: "Description",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            autoHeight: true,
            wrapText: true,
            minWidth: 200,
        },
        // {
        //     field: "InvoicePaymentExpectedCommunication",
        //     headerName: "Communication",
        //     headerClass: "ag-CenterHeader",
        //     width: 300,
        //     filter: true,
        // },

        {
            field: "Invoices_File_Path",
            headerName: "Docs",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: false,
            cellRenderering: PDFRendering,
            cellStyle: PDFCell,
        },

        {
            field: "CalcTotalVATExcluded",
            headerName: "Tot VAT Exc.",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "CalcTotalVATAmount",
            headerName: "Tot VAT",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "updatedAt",
            headerName: "Last updated",
            headerClass: "ag-CenterHeader",
            width: 130,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Date",
            sortingOrder: ["asc", "desc"],
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
    };
    const HandleShareGridApi = (GridApi) => {
        Ref_AgGridApi.current = GridApi;
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [IsQueryiesInitialized, setIsQueryiesInitialized] = useState(false);
    const [RefreshApi, setRefreshApi] = useState(true);
    const [IsInitalized, setIsInitalized] = useState(false);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);

    const [ReloadTable, setReloadTable] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="InvoicesPaymentsDue"
                Data={Ref_LocaleData.current}
                TableColumns={TableColumns}
                MainDataSource="Invoices"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTable}
                SyncReloadDataStateFunction={setReloadTable}
                ShareGridApi={HandleShareGridApi}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!APICallType) {
                    if (ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data Initialize Query", Data);
                        //We just load the cards Id into the select
                        if (IsQueryiesInitialized === false) {
                            if (Data) {
                                let SaleInvoiceStatusSelectOptions = [{label: "ALL", value: "00"}];
                                Data.SaleInvoiceStatusSelectOptions.forEach((elem) => {
                                    SaleInvoiceStatusSelectOptions.push(elem);
                                });
                                let InitFormValues = {
                                    status_invoice: "00",
                                };
                                setInitialFormValues(InitFormValues);
                                setFormValues(InitFormValues);
                                setRefreshApi(false);
                                let SuppliersSelectOptions = Data.Customers.map((elem) => {
                                    return {value: elem._id, label: elem.company};
                                });
                                setFieldOptions({company: SuppliersSelectOptions, status_invoice: SaleInvoiceStatusSelectOptions});
                                Ref_FieldOptions.current = {company: SuppliersSelectOptions, status_invoice: SaleInvoiceStatusSelectOptions};
                                Ref_FormValues.current = InitFormValues;
                                setIsQueryiesInitialized(true);
                            }
                        }
                    }
                } else if (APICallType === "InitalizeTable") {
                    console.log("Data Query");
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data Query");
                        Ref_LocaleData.current = Data;
                        setReloadTable(true);
                    }
                } else if (APICallType === "Filter") {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        Ref_LocaleData.current = Data;
                        setReloadTable(true);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Initial Call or Refresh
        if (RefreshApi === true) {
            const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoices/payments/initializequeries`, type: "get"};
            setLoading(true);
            // console.log("ApiCall", ApiCall);
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, []);

    useEffect(() => {
        //Once Initialization has been done and default fields have been loaded
        if (IsQueryiesInitialized === true) {
            let RealQuery = "?paymentstatus=upcoming";
            let Query = Ref_FormValues.current;
            let Keys = _.keysIn(Query);
            if (Keys) {
                Keys.forEach((elem) => {
                    let Value = Query[elem];
                    if (Value) {
                        if (elem === "company") {
                            RealQuery = RealQuery ? RealQuery + `&companyid=${Value}` : `?companyid=${Value}`;
                        }
                        if (elem === "status_invoice") {
                            console.log("Value", Value);

                            //We need to return the label
                            if (Value === "00") {
                                //It means all -> we don't add it
                            } else {
                                console.log("FieldOptions", Ref_FieldOptions.current);
                                let Label = _.find(Ref_FieldOptions.current.status_invoice, {value: Value}).label;

                                RealQuery = RealQuery ? RealQuery + `&status_invoice=${Label}` : `?status_invoice=${Label}`;
                            }
                        }
                    }
                });
            }
            const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoices/payments/queries/${RealQuery}`, type: "get"};

            setLoading(true);
            setAPICallType("InitalizeTable");
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [IsQueryiesInitialized]);

    useEffect(() => {
        if (ReloadTable === true) {
            GenerateTableComponent();
            setReloadTable(false);
        }
    }, [ReloadTable, GenerateTableComponent]);
    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleUpdateFormValues = (field, values) => {
        //Note!!! with Date Pickers it's mandatory to use the Ref and not the state ....impossible to understand why but that is it
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        setFormValues({...Ref_FormValues.current, ...{[field]: value}});
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: value}};
        // let FinalQuery = Query;

        // if (field === "SourceType") {
        // }
        // if (field === "IncomeExpense") {
        // }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        // if (field === "ConditionalSelect1") {
        //     //We update the Options of the ConditionalSelect2
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect1 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
        //     //We also need to reset the Conditional 2;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        // }
        // if (field === "ConditionalSelect2") {
        //     //We update the Options of the ConditionalSelect3
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect2 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
        //     //We also need to reset the Conditional 3;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        // }
        setTableComponent(null);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleCloseEditCard = () => {
        setShowEditCard(false);
        setEditCardId(null);
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setTimeout(() => {
            setReloadTable(true);
        }, 300);
    };

    const HandleCloseDuplicateCard = (Id) => {
        // console.log("Should close the card")
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setViewCardId(Id);
        setShowViewCard(true);
        setTimeout(() => {
            setReloadTable(true);
        }, 300);
    };
    const HandleFilter = () => {
        let QueryTable = "";
        let FormVal = Ref_FormValues.current;
        if (FormVal) {
            if (FormVal.is_personal === "Work") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&is_personal=false";
                } else {
                    QueryTable = QueryTable + "?is_personal=false";
                }
            } else if (FormVal.is_personal === "Personal") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&is_personal=true";
                } else {
                    QueryTable = QueryTable + "?is_personal=true";
                }
            }
            if (FormVal.is_completed === "Due") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&dueonly=true";
                } else {
                    QueryTable = QueryTable + "?dueonly=true";
                }
            } else if (FormVal.is_completed === "Completed") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&dueonly=false";
                } else {
                    QueryTable = QueryTable + "?dueonly=false";
                }
            }
        }
        console.log("QueryTable", QueryTable);

        const ApiCall = {url: `${ProdURL}/api/tasks/query/${QueryTable}`, type: "get"};
        setLoading(true);
        setAPICallType("Filter");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    const HandleResetFilter = () => {
        // setInit(false);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;

    if (ShowEditCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceEdit Id={EditCardId} HandleCloseCard={HandleCloseEditCard} />
                </div>
            </CardModal>
        );
    }
    if (ShowDuplicateCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseDuplicateCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceDuplicate DuplicateId={DuplicateCardId} HandleCloseViewCard={HandleCloseDuplicateCard} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <div className={classes.MainWrapper}>
                <div className={classes.ToolBar}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                            <FormCompontent
                                Name="company"
                                InitialValue={InitialFormValues.company ? InitialFormValues.company : null}
                                FormValue={FormValues.company ? FormValues.company : null}
                                OutputSelectedValues={(values) => handleSelectChange("company", values)}
                                SelectOptions={FieldOptions.company}
                                Component="Select"
                                Variant="Outlined"
                                Label="Company"
                                NoMessage={false}
                                MultiSelect={false}
                                SortSelectOptions="Asc"
                                AlternateRowStyle={true}
                                // SortIcons={true}
                                AllowCreateOption={false}
                                Required={true}
                                // CreateDeleteIfUnselected={}

                                LabelMaxWidth="100px"
                                LabelAlign="Left"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                            <FormCompontent
                                Name="status_invoice"
                                InitialValue={InitialFormValues.status_invoice ? InitialFormValues.status_invoice : null}
                                FormValue={FormValues.status_invoice ? FormValues.status_invoice : null}
                                OutputSelectedValues={(values) => handleSelectChange("status_invoice", values)}
                                SelectOptions={FieldOptions.status_invoice}
                                Component="Select"
                                Variant="Outlined"
                                Label="Status"
                                NoMessage={false}
                                MultiSelect={false}
                                // SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                Required={true}
                                // CreateDeleteIfUnselected={}

                                LabelMaxWidth="100px"
                                LabelAlign="Left"
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.TableWrapper}>
                    {ModalCardView}
                    <div className={classes.TableToolbar}>
                        <div className={classes.SearchButtons}>
                            <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleFilter}>
                                Apply Filters
                            </Button>
                            <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleResetFilter} style={{marginLeft: "10px"}}>
                                Reset Filters
                            </Button>
                        </div>
                    </div>
                    <div className={classes.TableWrapperMain}>
                        <div className={classes.TableWrapper}>{TableComponent}</div>
                    </div>
                </div>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
