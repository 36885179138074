import {createStore, applyMiddleware} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";

export const configureStore = (preloadedState) => {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const storeEnhancers = [middlewareEnhancer];

    const composedEnhancer = composeWithDevTools(...storeEnhancers);

    let store;

    if (process.env.NODE_ENV == "production") {
        //For production we remove the devtool
        store = createStore(rootReducer, preloadedState, middlewareEnhancer);
    } else {
        store = createStore(rootReducer, preloadedState, composedEnhancer);
    }

    //Only for dev mode : Use the hotReloader to make it faster
    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./rootReducer.js", () => {
                const newRootReducer = require("./rootReducer").default;
                store.replaceReducer(newRootReducer);
            });
        } else {
            const newRootReducer = require("./rootReducer").default;
            store.replaceReducer(newRootReducer);
        }
    }
    return store;
};
