import React from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CardExtended: {
        color: "black",
    },

    TopBar: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    CardContent: {
        height: "100%",

        flexDirection: "column",
        display: "flex",
    },
    Content: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    BottomToolbar: {
        flex: "0 0 60px",
        display: "flex",
    },
}));

/**
 * Component Description
 */

const TableMessages = React.forwardRef(function TableMessages(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {ShowCard, Action, Output, HandleCloseCard} = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** CONST *********************/
    const ActionList = {
        Delete: {
            Title: "Delete Confirmation",
            Message: "Are you sure you want to delete this entry ?",
        },
    };
    /****************************** CONST *********************/

    /****************************** STATE *********************/

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const HandleDelete = (Id) => {
        Output({Action: "Delete", Id: Id});
    };
    const HandleDeleteMessageOnly = (Id, onClick, RowData) => {
        Output({Action: "DeleteMessageOnly", Id: Id, onClick: onClick, RowData: RowData});
    };
    const HandleCancel = () => {
        HandleCloseCard();
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var CardTitle, CardContent, BottomToolbar;
    if (Action) {
        if (Action.Action === "Delete") {
            CardTitle = <div className={classes.TopBar}>{ActionList.Delete.Title}</div>;
            CardContent = <div className={classes.Content}>{ActionList.Delete.Message}</div>;
            BottomToolbar = (
                <div className={classes.BottomToolbar}>
                    <Button onClick={() => HandleDelete(Action.Id)}>Delete</Button>
                    <Button onClick={HandleCancel}>Cancel</Button>
                </div>
            );
        } else if (Action.Action === "DeleteMessageOnly") {
            CardTitle = <div className={classes.TopBar}>{ActionList.Delete.Title}</div>;
            CardContent = <div className={classes.Content}>{ActionList.Delete.Message}</div>;
            BottomToolbar = (
                <div className={classes.BottomToolbar}>
                    <Button onClick={() => HandleDeleteMessageOnly(Action.Id, Action.onClick, Action.RowData)}>Delete</Button>
                    <Button onClick={HandleCancel}>Cancel</Button>
                </div>
            );
        }
    }

    return (
        <CardModal
            // CardID="HealFit_CreateDailyIntake"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={CardTitle}
            CloseCard={HandleCloseCard}
            CloseOnClickOutside={true}
            WithCloseButton={true}
            CardMaxWidth="400px"
            CardHeight="300px"
            // WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="10px"
            // CardWindowPaddingHorizontal="10px"
            ShowCard={ShowCard}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.CardExtended}
        >
            <div className={classes.CardContent}>
                {CardContent}
                {BottomToolbar}
            </div>
        </CardModal>
    );
    /****************************** RENDER *********************/
});

TableMessages.defaultProps = {
    MyProp: null,
};

TableMessages.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableMessages;
