import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import {useHistory, useLocation} from "react-router-dom";
import CustomerEdit from "../../../../03_Customers/CustomerEditDetails/CustomerEdit";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {ApiId, CustomerId, HandleCloseExternalCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();
    console.log("ApiId", ApiId);
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(true);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        HandleCloseExternalCard();
        if (ApiId === "new") {
            setTimeout(() => {
                History.push("/financials/saleordersandinvoices/invoices/tables/all");
            }, 100);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <CustomerEdit Id={CustomerId} HandleCloseViewCard={HandleCloseViewCard} />
            </CardModal>
        );
    }

    return <React.Fragment>{ModalCardView}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
