import React from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    DevMode: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        border: "2px solid rgb(211, 78, 100)",
        borderRadius: "5px",
        padding: "10px 10px 10px 10px",
    },
    DevModeButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 0 auto",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "0px 10px 0px 10px",
    },
    DevModeData: {
        flex: "0 1 auto",
        maxHeight: "300px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "10px 10px 10px 10px",
        boxSizing: "border-box",
    },
    DevModeDataIndividual: {
        flex: "0 1 auto",
        maxHeight: "150px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "0px",

        boxSizing: "border-box",
    },
}));

/**
 * Component Description
 */

const FormDev = React.forwardRef(function FormDev(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {DevMode, FormValues, InitialFormValues, FieldOptions, FieldSubOptionsFull, FilesUploaders, FormErrors, NewFieldOptions,PristineDetails} = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [DevCheckFormValues, setDevCheckFormValues] = React.useState({title: null, type: null});
    const [FilteredFormValues, setFilteredFormValues] = React.useState(null);
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const HandleDevModeSubmit = (type) => {
        if (type === "Submit") {
            //We compare the FormValues to the InitalFormValues

            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    //Get the IntialValue
                    let InitialValue = InitialFormValues[key];
                    let Value = FormValues[key];
                    if (InitialValue !== Value) {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                });
            }
            let FinalAnswer = {
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
                NewCreatedOptions: NewFieldOptions,
				FileUploaders : FilesUploaders
            };

            setFilteredFormValues(FinalAnswer);
            setDevCheckFormValues({title: "Form submit FormValues filtered", type: "Submit"});
        }
        if (type === "FormValues") {
            setDevCheckFormValues({title: "FormValues unfiltered", type: "FormValues"});
        }

        if (type === "Initial Value") {
            setDevCheckFormValues({title: "Form submit - Form Values", content: null, type: "Initial Value"});
        }
        if (type === "Reset") {
            setDevCheckFormValues({title: null, content: null, type: "Reset"});
        }
        if (type === "FormErrors") {
            setDevCheckFormValues({title: "Form Errors", content: null, type: "FormErrors"});
		}
		if (type === "PristineDetails") {
            setDevCheckFormValues({title: "Form pristine details", content: null, type: "PristineDetails"});
		}
		
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var DevModeComponent;

    if (DevMode) {
        var DevInitiaFormValues, DevSelectOptions, DevSubOptionsFull, DevFilesUploaders, DevSubmitVal, DevFormErrors;
        if (DevCheckFormValues.type === "Submit") {
            if (FormValues) {
                DevSubmitVal = <div>{JSON.stringify(FilteredFormValues, 0, 2)}</div>;
            }
        }
        if (DevCheckFormValues.type === "FormValues") {
            if (FormValues) {
                DevSubmitVal = <div>{JSON.stringify(FormValues, 0, 2)}</div>;
            }
        }
        if (DevCheckFormValues.type === "PristineDetails") {
            if (FormErrors) {
                DevFormErrors = <div>{JSON.stringify(PristineDetails, 0, 2)}</div>;
            }
		}
		if (DevCheckFormValues.type === "FormErrors") {
            if (FormErrors) {
                DevFormErrors = <div>{JSON.stringify(FormErrors, 0, 2)}</div>;
            }
        }
        if (DevCheckFormValues.type === "Initial Value") {
            if (InitialFormValues) {
                DevInitiaFormValues = (
                    <div>
                        <h3 style={{margin: "0px"}}>InitiaFormValues</h3>
                        <pre className={classes.DevModeDataIndividual}>{JSON.stringify(InitialFormValues, 0, 2)}</pre>
                    </div>
                );
            }

            if (FieldOptions) {
                DevSelectOptions = (
                    <div>
                        <h3 style={{margin: "2px 0px 2px 0px"}}>SelectOptions</h3>
                        <pre className={classes.DevModeDataIndividual}>{JSON.stringify(FieldOptions, 0, 2)}</pre>
                    </div>
                );
            }
            if (FieldSubOptionsFull) {
                DevSubOptionsFull = (
                    <div>
                        <h3 style={{margin: "0px"}}>SubOptionsFull</h3>
                        <pre className={classes.DevModeDataIndividual}>{JSON.stringify(FieldSubOptionsFull, 0, 2)}</pre>
                    </div>
                );
            }
            if (FilesUploaders) {
                DevFilesUploaders = (
                    <div>
                        <h3 style={{margin: "0px"}}>FilesUploaders</h3>
                        <pre className={classes.DevModeDataIndividual}>{JSON.stringify(FilesUploaders, 0, 2)}</pre>
                    </div>
                );
            }
        }
        DevModeComponent = (
            <div className={classes.DevMode}>
                <div className={classes.DevModeButtonBar}>
                    <Button
                        Width="160px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px"}}
                        onClick={() => HandleDevModeSubmit("Submit")}
                    >
                        FORM SUBMIT
                    </Button>
                    <Button
                        Width="160px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => HandleDevModeSubmit("FormValues")}
                    >
                        FormValues UNFILTERED
					</Button>
					
					<Button
                        Width="160px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => HandleDevModeSubmit("PristineDetails")}
                    >
                        PRISTINE DETAILS
                    </Button>
					<Button
                        Width="160px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => HandleDevModeSubmit("FormErrors")}
                    >
                        FORM ERRORS
                    </Button>
                    <Button
                        Width="160px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => HandleDevModeSubmit("Initial Value")}
                    >
                        DEV MODE CHECK INITIAL VALUES
                    </Button>
                    <Button
                        Width="160px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => HandleDevModeSubmit("Reset")}
                    >
                        DEV MODE CLEAR
                    </Button>
                </div>
                <h2>{DevCheckFormValues.title}</h2>
                <pre className={classes.DevModeData}>
                    {DevInitiaFormValues}
                    {DevSelectOptions}
                    {DevSubOptionsFull}
                    {DevFilesUploaders}
                    {DevSubmitVal}
                    {DevFormErrors}
                </pre>
            </div>
        );
    }

    return <React.Fragment>{DevModeComponent}</React.Fragment>;
    /****************************** RENDER *********************/
});

FormDev.defaultProps = {
    DevMode: false,
};

FormDev.propTypes = {
    /**
     * DevMode :
     */
    DevMode: PropTypes.any,
};

export default FormDev;
