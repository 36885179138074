import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import AGGridTable from "../../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {PDF} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "160px",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const Component = React.memo(function Component(props) {
    const {TableData} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        return (
            <div onClick={() => HandleViewPdf(value)}>
                <PDF SVGFillColor="white" />
            </div>
        );
    };
    const TableColumns = [
        {
            field: "Uploaded",
            headerName: "Uploaded",
            headerClass: "ag-CenterHeader",
            width: 110,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "UploadedDateIso",
            headerName: "Uploaded on",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "UploadedMethod",
            headerName: "Upload Method",
            headerClass: "ag-CenterHeader",
            width: 150,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "EmailSentDateIso",
            headerName: "Email sent",
            headerClass: "ag-CenterHeader",
            width: 120,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            Type: "Date",
        },

        {
            field: "Note",
            headerName: "Note",
            headerClass: "ag-CenterHeader",
            width: 150,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
		{
            field: "ReasonForChange",
            headerName: "Reason For Change",
            headerClass: "ag-CenterHeader",
            width: 150,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "Doc_Reference",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 170,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "Doc_URL",
            headerName: "Docs",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: false,
            cellRenderering: PDFRendering,
            cellStyle: PDFCell,
        },
		{
            field: "SentToEmailAddress",
            headerName: "Eamil to",
            headerClass: "ag-CenterHeader",
            flex :1
            // filter: false,
            
            
        },
		{
            field: "SentFromEmailAddress",
            headerName: "Eamil from",
            headerClass: "ag-CenterHeader",
            flex :1
            // filter: false,
            
        },

      
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: false,
        Filterable: false,
        SupressMenu: true,
        WrapText: true,
        WrapHeader: false,
        HeaderHeight: 30,
        RowHeight: 24,
        RowFontSize: "13px",
        HeaderFontSize: "14px",
    };
    // const TotalRow = {
    //     Position: "Bottom",
    //     Style: null,
    // };

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div className={classes.TableWrapper}>
            <AGGridTable
                TableName="AccoutantUploadHistory"
                Data={TableData}
                // URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                // MainDataSource=""
                // RowActionButtons={TableRowButtons}
                // ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
            />
        </div>
    );
    /***************** RENDER ******************/
});

export default Component;
