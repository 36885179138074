import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import Select, {components, MultiValueRemoveProps, ClearIndicatorProps} from "react-select";
import ReactTooltip from "react-tooltip";
import {PDF, DocImage, DocVideo, DocGeneral, CloseIcon} from "@artibulles-cis/react-icons";
import CardModal from "../CardModal/CardModal";
import Button from "../Button/Button";
import {v4 as uuidv4} from "uuid";
var _ = require("lodash");
const LockedstylesWithProps = (props) => {
    return {};
};

const Lockedstyles = {
    FormElement: {
        display: "flex",
        flexDirection: "column",
    },
    FormInput: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
    },
};

const styles = createUseStyles((theme) => ({
    FormElement: theme.FormTextInput.FormElement,
    FormInput: theme.FormTextInput.FormInput,

    Input: {
        display: "block",
        // borderRadius: "3px",
        border: "0px !important",
        backgroundColor: "transparent",
        boxSizing: "border-box",

        pointerEvents: "all",
        //Keep it here to set the full component height to harmonize with other inputs
        // minHeight: "25px",
        // lineHeight: "25px",
        // height: "30px"
        webkitAppearance: "none !important",
        outline: "none !important",
        background: "none",

        "&:error": {
            webkitAppearance: "none !important",
            outline: "none !important",
            border: "none !important",
            boxShadow: "none",
        },
        "&:invalid": {
            outline: "none !important",
            border: "none !important",
            boxShadow: "none !important",
        },
        "&:focus": {
            webkitAppearance: "none !important",
            outline: "none !important",
            border: "none !important",
            boxShadow: "none",
        },
        "&:active": {
            webkitAppearance: "none !important",
            outline: "none !important",
            border: "none !important",
            boxShadow: "none",
        },
    },

    BottomMessageContent: theme.FormTextInput.BottomMessageContent,

    BottomMessageContentError: (props) => {
        let ErrorStyleFinal = props.ErrorStyle ? (props.ErrorStyle.color ? props.ErrorStyle.color : null) : null;
        let ColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;

        if (ErrorStyleFinal) {
            return {color: ErrorStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentError;
        }
    },
    BottomMessageContentHelper: (props) => {
        let ColorFinal = props.HelperStyle ? (props.HelperStyle.color ? props.HelperStyle.color : null) : null;

        if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentHelper;
        }
    },
    BottomMessageContentHidden: {
        visibility: "hidden",
    },
    CustomSelectContainer: {
        position: "relative",
        boxSizing: "border-box",
    },
    CustomSelectController: {
        display: "flex",
        alignItems: "center",
    },
    CustomSelectValueContainer: {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        overflow: "hidden",
        // padding: "10px"
        // padding: theme.SelectSingleAnimated.MenuItems.outlinedPadding
    },

    CustomSelectSingleValue: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Filled ? props.InputStyle.Filled : null) : null;
        let BasicStyle = {
            display: "flex",
            alignItems: "center",
        };
        if (InputStyleFinal) {
            return {...BasicStyle, color: InputStyleFinal};
        } else if (ColorFinal) {
            return {...BasicStyle, color: ColorFinal};
        } else {
            return {...BasicStyle, color: theme.FormTextInput.InputColor.color};
        }
    },
    MultiValueLabel: (props) => {
        return {
            bordeRadius: "2px",
            color: "hsl(0,0%,20%)",
            overflow: "hidden",
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            paddingTop: "1px",
            paddingBottom: "1px",
            // fontSize: "85%",
            fontSize: theme.FileUploader.MultiValue.fontSize,
            fontFamily: theme.FileUploader.MultiValue.fontFamily,
            fontWeight: theme.FileUploader.MultiValue.fontWeight,
        };
    },
    CardExtended: {
        color: "black",
    },

    TopBar: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    CardContent: {
        height: "100%",

        flexDirection: "column",
        display: "flex",
    },
    Content: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    BottomToolbar: {
        flex: "0 0 60px",
        display: "flex",
    },
    RemoveItem: {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        justifyContent: "center",
        "&:hover": {
            background: "rgb(255, 189, 173)",
        },
    },
    RemoveAllItems: {
        display: "flex",
        alignItems: "center",
        padding: "0px 7px",
        justifyContent: "center",
    },
}));

/**
 * Create a NavTopBar Wrapper that goes into a NavHorizontalBar Component
 */
const FileUploaderSelect = React.forwardRef(function FileUploaderSelect(props, ref) {
    const {name, MultiSelect, sortSelectOptions, placeHolder, ReadOnly, AlternateRowStyle, Colors, SelectStyle, InputFontProp, WithDeleteConfirmation} = props;

    const {curvalue, initialvalue, options, zizi, UpdateOptionsFromOutside} = props.FieldSetProps;

    const theme = useTheme();
    const classes = styles({...props, theme});

    const Ref_RemoveSelectedFunction = React.useRef(null);
    const [SelectOptions, setSelectOptions] = React.useState(null);

    const [Multi, setMulti] = React.useState(null);
    const [ShowCard, setShowCard] = React.useState(false);

    //Synching the form with Select
    React.useEffect(() => {
        if (initialvalue && options) {
            if (options.length >= 1) {
                if (curvalue === initialvalue) {
                    var initialOption = [];
                    if (Array.isArray(initialvalue)) {
                        initialvalue.forEach((initval) => {
                            options.forEach((elem) => {
                                if (elem.value === initval) {
                                    initialOption.push(elem);
                                }
                            });
                        });
                    } else {
                        options.forEach((elem) => {
                            if (elem.value === initialvalue) {
                                initialOption.push(elem);
                            }
                        });
                    }

                    setMulti(initialOption);
                }
            }
        }
        if (curvalue == null) {
            setMulti(null);
        }
    }, [curvalue, initialvalue, options]);

    //Managing the internal customized Options
    React.useEffect(() => {
        if (options) {
            var SortedOptions;
            //If sort is required ->
            if (sortSelectOptions) {
                let sortDir = sortSelectOptions === "desc" ? "desc" : "asc";
                SortedOptions = _.orderBy(
                    options,
                    function (lowCase) {
                        return lowCase.label.toLowerCase();
                    },
                    sortDir
                );
            } else {
                SortedOptions = options;
            }
            //Adding custom tags for customizing the style
            var i = 0;
            var customOptions = SortedOptions.map((elem) => {
                let isAlternate = i % 2 === 1 ? true : false;
                i = i + 1;
                return {...elem, alternateRow: isAlternate};
                //do something to customize them
            });
            setSelectOptions(customOptions);
        }
    }, [options]);

    //Setting the focus status to move the label
    const InputOnFocus = (event) => {
        // console.log("onfocus cas called")
    };

    const SelectonMenuClose = (args) => {
        // console.log("Menu Close was closed");
    };

    const HandleCloseCardDelete = () => {
        // console.log("Close Card");
        setShowCard(false);
    };

    const HandleDelete = () => {
        console.log("delete element");
        if (Ref_RemoveSelectedFunction.current) {
            Ref_RemoveSelectedFunction.current();
        }
        setShowCard(false);
    };

    function HandleSelectChange(value, action) {
        console.log("FileUploader SELECT", action);
        setMulti(value);
        if (value) {
            let finallist = value.map((val) => val.value);
            UpdateOptionsFromOutside("", finallist);
            // props.input.onChange(finallist);
        } else {
            UpdateOptionsFromOutside("", null);
            // props.input.onChange(null);
        }
    }

    const InputOnBlur = (event) => {};

    const InputOntouchStart = () => {
        // console.log("InputOntouchStart");
    };
    const InputOnTouchEnd = () => {
        // console.log("InputOnTouchEnd");
    };

    const SelectRef = React.useRef(null);
    const FinalSelectComponent = (FieldSetProps) => {
        const {InputClass, InputMarginBottom, FinalInputPadding} = FieldSetProps.FieldSetProps;

        // const InputMarginBottom = FieldSetProps.InputMarginBottom
        // console.log(FieldSetProps.FieldSetProps)

        const SelectCustomStyles = {
            container: (provided) => ({
                ...provided,
                marginBottom: MultiSelect ? `${parseInt(InputMarginBottom) - 4}px` : InputMarginBottom,
                fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
                position: "relative",
                boxSizing: "border-box",
            }),

            menu: (provided) => ({
                ...provided,
                marginTop: "12px",
                marginBottom: "12px",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
                background: "white none repeat scroll 0% 0%",
                // backgroundColor: "none",
                fontFamily: "roboto",
                fontSize: "18px",
                fontWeight: "400",
            }),
            option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                let isAlternate = data.alternateRow;

                if (AlternateRowStyle) {
                    return {
                        ...styles,
                        padding: "5px 10px 5px 10px",
                        backgroundColor: isSelected ? "rgb(66, 165, 245)" : isFocused ? "rgb(240, 240, 240)" : isAlternate == true ? "rgb(220, 220, 220)" : "white",
                        color: isSelected ? "white" : isFocused ? "black" : "black",
                        fontWeight: isSelected ? "800" : "400",
                        ":active": {
                            ...styles[":active"],
                            backgroundColor: isSelected ? "rgb(66, 165, 245)" : "green",
                        },
                    };
                } else {
                    return {
                        ...styles,
                        padding: "5px 10px 5px 10px",
                        backgroundColor: isSelected ? "rgb(66, 165, 245)" : isFocused ? "rgb(240, 240, 240)" : "white",
                        color: isSelected ? "white" : isFocused ? "black" : "black",
                        fontWeight: isSelected ? "800" : "400",
                        ":active": {
                            ...styles[":active"],
                            backgroundColor: isSelected ? "rgb(66, 165, 245)" : "green",
                        },
                    };
                }
            },
            dropdownIndicator: (provided) => ({
                ...provided,
                padding: "2px 8px 2px 8px",
            }),
            clearIndicator: (provided) => ({
                ...provided,
                padding: "2px 8px 2px 8px",
            }),
            input: (provided) => ({
                ...provided,
                padding: "0px 0px 0px 0px",
                margin: "0px 0px 0px 0px",
            }),

            indicatorSeparator: (provided) => ({
                ...provided,
                marginBottom: "0px",
                marginTop: "0px",
            }),
            valueContainer: (provided) => ({
                ...provided,
                // padding: FinalInputPadding,
				padding: "0px",
                display: "flex",
                flexWrap: "wrap",
                flex: 1,
                alignItems: "center",
                overflow: "hidden",
            }),
			
            placeholder: (provided) => ({
                // ...provided,
                position: "relative",
                padding: "2px 0px 2px 2px",
                color: Colors.Empty ? Colors.Empty : theme.FormTextInput.LabelEmpty.color,
            }),
            multiValueLabel: (provided) => ({
                ...provided,
                paddingTop: "1px",
                paddingBottom: "1px",
            }),
            multiValue: (provided) => ({
                ...provided,
                backgroundColor: SelectStyle.BackgroundColor ? SelectStyle.BackgroundColor : theme.FileUploader.Select.backgroundColor,
                color: SelectStyle.Color ? SelectStyle.Color : theme.FileUploader.Select.color,
            }),

            control: () => ({
                padding: "0px",
                background: "none",
                position: "relative",
                boxSizing: "border-box",
                margin: "0px",
                transition: "all 100ms",
                display: "flex",
                alignItems: "center",
                backgroundColor: "none",
                justifyContent: "space-between",
                outline: "0 !important",
                border: "0 none !important",
                // minHeight : "38px"
            }),
        };

        const SelectDropdownIndicatorComponent = () => {
            return null;
        };
        const IndicatorSeparatorComponent = () => {
            return null;
        };
        const ChipClick = (props) => {
            let data = props.data;

            //Finding the corresponding server path of the chip to open the file in another window;
            if (data) {
                let url = data.serverPath;
                window.open(url);
            }
        };

        function MultiValueLabelComponent(props) {
            var Icon = null;
            let ext = props.data.fileExt;
            let tooltipId = uuidv4();
            // console.log(ext);
            let ToolTip;
            if (props.data.isNew === true) {
                ToolTip = props.data.originalName;
            } else {
                ToolTip = props.data.serverPath;
            }

            if (SelectStyle.WithIcons === false) {
            } else {
                if (ext === "pdf") {
                    Icon = <PDF IconSize="20px" />;
                } else if (ext === "png" || ext === "jpg" || ext === "svg") {
                    Icon = <DocImage IconSize="20px" />;
                } else if (ext === "mp4") {
                    Icon = <DocVideo IconSize="20px" />;
                } else {
                    Icon = <DocGeneral IconSize="20px" />;
                }
            }
            return (
                <React.Fragment>
                    <div
                        className={classes.MultiValueLabel}
                        style={{color: SelectStyle.Color ? SelectStyle.Color : theme.FileUploader.Select.color, paddingLeft: "5px"}}
                        onClick={() => ChipClick(props)}
                        data-tip
                        data-for={tooltipId}
                    >
                        {Icon}
                        {props.children}
                    </div>
                    <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
                        <p className={classes.tooltipP}>{ToolTip}</p>
                    </ReactTooltip>
                </React.Fragment>
            );
        }
        const HandleClickRemove = (props) => {
            // <components.MultiValueRemove {...MultiValueRemoveProps}></components.MultiValueRemove>
            let StandardClickFunction = props.innerProps.onClick;
            //Open a dialog card to confirm the deletion of the file
            if (WithDeleteConfirmation === true) {
                Ref_RemoveSelectedFunction.current = StandardClickFunction;
                setShowCard(true);
            } else {
                StandardClickFunction();
            }
        };

        const HandleRemoveAll = (props) => {
            let StandardClickFunction = props.clearValue;
            if (WithDeleteConfirmation === true) {
                Ref_RemoveSelectedFunction.current = StandardClickFunction;
                setShowCard(true);
            } else {
                StandardClickFunction();
            }
        };
        function MultiValueRemoveComponent(MultiValueRemoveProps) {
            if (ReadOnly === true) {
                return <div className={classes.RemoveItem} onClick={null}></div>;
            } else {
                return (
                    <div className={classes.RemoveItem} onClick={() => HandleClickRemove(MultiValueRemoveProps)}>
                        <CloseIcon IconSize="10px" SVGFillColor="white" SVGStrokeColor="white" SVGStrokeWidth="12px" SVGStrokeHoverColor="rgb(222, 53, 11)" SVGFillHoverColor="rgb(222, 53, 11)" />
                    </div>
                );
            }
        }

        function ClearIndicatorComponent(ClearIndicatorProps) {
            return (
                <div
                    className={classes.RemoveAllItems}
                    onClick={() => HandleRemoveAll(ClearIndicatorProps)}
                    // {...restInnerProps}
                    // ref={ref}
                >
                    <CloseIcon IconSize="12px" SVGFillColor="grey" SVGStrokeColor="grey" SVGStrokeWidth="12px" SVGStrokeHoverColor="rgb(222, 53, 11)" SVGFillHoverColor="rgb(222, 53, 11)" />
                </div>
            );
        }

        const SelectComponents = {
            // Menu: SelectMenuComponent,
            // Option,
            // SelectContainer: SelectContainerComponent,
            // Control: SelectControlComponent,
            // ValueContainer: SelectValueComponent
            ClearIndicator: ClearIndicatorComponent,
            IndicatorSeparator: IndicatorSeparatorComponent,
            DropdownIndicator: SelectDropdownIndicatorComponent,
            MultiValueLabel: MultiValueLabelComponent,
            MultiValueRemove: MultiValueRemoveComponent,
        };

        let ModalCardDeleteComponent;
        if (ShowCard === true) {
            ModalCardDeleteComponent = (
                <CardModal
                    CardID="DeleteFileFromSelect"
                    // ParentCompRef={Ref_TabNavContent}
                    TopBarContent={<div className={classes.TopBar}>File delete</div>}
                    CloseCard={HandleCloseCardDelete}
                    CloseOnClickOutside={true}
                    WithCloseButton={true}
                    CardMaxWidth="300px"
                    CardHeight="200px"
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard={ShowCard}
                    WindowBackgroundColor="rgba(0,0,0,0.3)"
                    CardBackgroundColor="white"
                    CardBackground="white"
                    ClassExtendCard={classes.CardExtended}
                    ZIndex="9999999999999"
                >
                    <div className={classes.CardContent}>
                        <div className={classes.Content}>Are you sure?</div>
                        <div className={classes.Content}>This cannot be undone</div>
                        <div className={classes.BottomToolbar}>
                            <Button Width="80px" onClick={HandleDelete}>
                                Delete
                            </Button>
                            <Button Width="80px" onClick={HandleCloseCardDelete}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </CardModal>
            );
        } else {
            ModalCardDeleteComponent = null;
        }
        return (
            <React.Fragment>
                {ModalCardDeleteComponent}
                <Select
                    ref={SelectRef}
                    name={name}
                    isDisabled={false}
                    styles={SelectCustomStyles}
                    isClearable={ReadOnly === true ? false : true}
                    components={SelectComponents}
                    value={Multi}
                    options={SelectOptions}
                    onChange={HandleSelectChange}
                    readOnly={ReadOnly}
                    placeholder={placeHolder}
                    // "Drag Files Here"
                    isMulti={true}
                    closeMenuOnSelect={MultiSelect === true ? false : true}
                    isSearchable={false}
                    menuIsOpen={false}
                />
            </React.Fragment>
        );
    };

    return <FinalSelectComponent FieldSetProps={props.FieldSetProps} />;
});

FileUploaderSelect.defaultProps = {
    name: "FieldName",
    MultiSelect: true,
    AlternateRowStyle: false,
    placeHolder: null,
    HelperMessage: null,
    Colors: {Empty: null, Filled: null, Error: null, Focused: null, Disabled: null},
    InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
    WithDeleteConfirmation: false,
};

FileUploaderSelect.propTypes = {
    /**
     * input name
     */
    name: PropTypes.string,
    /**
     * MultiSelect : true/false : allow to select multiple values
     */
    MultiSelect: PropTypes.bool,
    /**
     * placeHolder value
     */
    placeHolder: PropTypes.string,
    /**
     * placeHolder value
     */
    HelperMessage: PropTypes.string,
    /**
     * placeHolder value
     */
    AlternateRowStyle: PropTypes.obj,
    /**
     * Colors
     */

    Colors: PropTypes.obj,
    /**
     * InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
     * Fonts for the label and the input
     */
    InputFontProp: PropTypes.obj,

    /**
     * WithDeleteConfirmation : true or false
     */
    WithDeleteConfirmation: PropTypes.any,
};

export default FileUploaderSelect;
