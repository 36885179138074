import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "../../../../../../../../artibulles-cis/TabNav/TabNav";
import InvoiceItemsTable from "../InvoiceItems/InvoiceItemsTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNav: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        InvoiceGenerationMethod,
        EditInvoiceGenerate,
        FormViewMode,
        SaleInvoiceID,
        HandlePurchaseItemChanged,
        FormValues,
        FieldOptions,
        HandleRefreshFormNoSave,
        HandleCloseGeneratePDFNoSave,
        CustomerAccountingPreference,
    } = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        console.log("EditDetailsTabNav, UseEffect");
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let FinalTabNav;
    if (FormValues.status_invoice === "Draft" || FormValues.status_invoice === "Created") {
        FinalTabNav = (
            <TabNav
                NoLink={true}
                MenuFullWidth={false}
                ClassExtendTabNav={classes.ClassExtendTabNav}
                TabNavMenuSeparation="Line"
                ActiveIndicator={{
                    WithIndicator: true,
                    Position: "Bottom",
                }}
                TabNavMenuHeight="25px"
                TabMenuContent={[
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Invoice Items</div>
                            </div>
                        ),

                        TabContent: (
                            <InvoiceItemsTable
                                FormViewMode={FormViewMode}
                                SaleInvoiceID={SaleInvoiceID}
                                SubmitParentForm={HandlePurchaseItemChanged}
                                FormValues={FormValues}
                                FieldOptionsParent={FieldOptions}
                                HandleRefreshFormNoSave={HandleRefreshFormNoSave}
                                EditInvoiceGenerate={EditInvoiceGenerate}
                                InvoiceGenerationMethod={InvoiceGenerationMethod}
                                HandleCloseGeneratePDFNoSave={HandleCloseGeneratePDFNoSave}
                                CustomerAccountingPreference={CustomerAccountingPreference}
                            />
                        ),
                    },
                ]}
            />
        );
    } else {
        FinalTabNav = (
            <TabNav
                NoLink={true}
                MenuFullWidth={false}
                ClassExtendTabNav={classes.ClassExtendTabNav}
                TabNavMenuSeparation="Line"
                ActiveIndicator={{
                    WithIndicator: true,
                    Position: "Bottom",
                }}
                TabNavMenuHeight="25px"
                TabMenuContent={[
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Invoice Items</div>
                            </div>
                        ),

                        TabContent: (
                            <InvoiceItemsTable
                                FormViewMode={FormViewMode}
                                SaleInvoiceID={SaleInvoiceID}
                                SubmitParentForm={HandlePurchaseItemChanged}
                                FormValues={FormValues}
                                FieldOptionsParent={FieldOptions}
                                HandleRefreshFormNoSave={HandleRefreshFormNoSave}
                            />
                        ),
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Reminders Options</div>
                            </div>
                        ),

                        TabContent: <div>Reminders Options</div>,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Reminder History</div>
                            </div>
                        ),

                        TabContent: <div>Reminder History</div>,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Reconciliation</div>
                            </div>
                        ),

                        TabContent: <div>Reconciliation Details</div>,
                    },
                ]}
            />
        );
    }

    return <React.Fragment>{FinalTabNav}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
