import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import TabNavContent from "../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import TemplateDetailsEditForm from "./TemplateDetailsEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/financials/invoices/invoice/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/invoices/invoicegeneratordetails`,
        DELETE: "",
        PUT: "",
        POST: "new", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("args", args);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            //We redirect to edit to reset the form and get the right buttons and editing
            History.push(`/financials/bills/table`);

            // History.push(`/crm/customers/customer/${Id}/view`);
        } else {
            let Id = args.data.data.InvoiceDetails._id;
            History.push(`/financials/bills/bill/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        //SET THE INITIAL FORM VALUES
        let InvoiceDetails = data.InvoiceDetails ? data.InvoiceDetails : null;
		let Invoice = data.Invoice ? data.Invoice : null;
        console.log("InvoiceDetails data", InvoiceDetails);
		console.log("Invoice", Invoice);
        var TexTemplates;
        if (data.TexTemplates && data.TexTemplates.length > 0) {
            TexTemplates = data.TexTemplates.map((elem) => {
                return {value: elem._id, label: elem.template_name, demoPath : elem.template_sample_file_path};
            });
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: InvoiceDetails ? InvoiceDetails._id : "new",
				invoice_id : Invoice.invoice_id?Invoice.invoice_id:null,
                ref_invoiceTexTemplate: InvoiceDetails ? InvoiceDetails.ref_invoiceTexTemplate : null,
                ref_Bill_To_Contacts: InvoiceDetails ? InvoiceDetails.ref_Bill_To_Contacts : null,
                ref_companyBankAccount: InvoiceDetails ? InvoiceDetails.ref_companyBankAccount : null,
                ref_customer: InvoiceDetails ? InvoiceDetails.ref_customer : null,
                is_draft: InvoiceDetails ? (InvoiceDetails.is_draft && InvoiceDetails.is_draft === true ? true : false) : false,
            },
            SelectOptions: {
                ref_invoiceTexTemplate: TexTemplates,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     bill_documents_paths: BillsPaths,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
			console.log("values", values)
            if (values) {
                let ServerData = {
                    API_id: values.ApiID,

                    InvoiceDetails: {
                        Updatable: {
                            ref_Bill_To_Address: values.ref_Bill_To_Address,
                            ref_Bill_To_Contacts: values.ref_Bill_To_Contacts,
                            ref_companyBankAccount: values.ref_companyBankAccount,
                            ref_customer: values.ref_customer,
                            is_draft: values.is_draft,
                        },
                    },
                };

                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={TemplateDetailsEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: true, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit", FormConditions: {invalid: false, pristine: false}}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
