/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";

import {createUseStyles, useTheme} from "react-jss";
import {IMaskInput} from "react-imask";
import {parse as parseFNS, isValid, add, parseISO, formatISO} from "date-fns";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Input: {
        alignSelf: "stretch",
        flex: "1 1 auto",
    },
}));

/**
 * Component Description
 */
const FieldTextInputMasked = React.memo(function FieldTextInputMasked(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputValue,
        OutputError,
        Error,
        Name, //Very Important for AutoFill
        ShareFocusState,
        ShareHasValue,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Mask,
        MaskType,
        FieldMaxWidth,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_LocalInput = useRef(null);
    const Ref_Imask = useRef(null);
    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [MaskDefinitionReady, setMaskDefinitionReady] = useState(false); //This is used to ensure we don't render the instance without a mask
    const [MaskInstanceReady, setMaskInstanceReady] = useState(false); //This is used to ensure we don't pass the initial value unless the instance is ready
    const [LocalInputValue, setLocalInputValue] = useState(null);
    const [LocalInputValueUnformatted, setLocalInputValueUnformatted] = useState(null);
    const [LazyPattern, setLazyPattern] = useState(true); //we hide the pattern by default;
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Ensuring MaskDefinition is existing Before rendering anything
    useEffect(() => {
        if (Mask) {
            setMaskDefinitionReady(true);
        }
    }, [Mask]);

    //Passing the Component Ref to the parent - only when it has been rendered once
    useEffect(() => {
        if (Ref_Imask.current) {
            if (Ref_Imask.current.element) {
                Ref_LocalInput.current = Ref_Imask.current.element;
                HandleUpdateRefInput(Ref_LocalInput.current);
                setTimeout(() => {
                    setMaskInstanceReady(true);
                }, 50);
            }
        }
    }, [Ref_Imask, MaskDefinitionReady]);

    //Initializing the value from InitalValue
    useEffect(() => {
        if (MaskInstanceReady === true) {
            // console.log("********* Imask Input ******");
            // console.log("initial value", InitialValue);
            // console.log("FormValue", FormValue);
            // console.log("Mask", Mask);
            // console.log("********* Imask Input ******");

            if (!Error) {
                if (InitialValue === FormValue) {
                    //Init or Reset
                    let UpdatedInitalValue = InitialValue;

                    if (MaskType === "Currency" && InitialValue) {
                        //We need to convert it to a string and use a comma for EUR if required

                        UpdatedInitalValue = InitialValue.toString().replace(".", ",");
                    }
                    // console.log("UpdatedInitalValue", UpdatedInitalValue);
                    if (MaskType === "Number" && InitialValue) {
                        //We need to convert it to a string and use a comma for EUR if required
                        UpdatedInitalValue = InitialValue.toString().replace(".", ",");
                    }
                    if (MaskType === "Pattern" && InitialValue) {
                        //We need to convert it to a string and use a comma for EUR if required
                        UpdatedInitalValue = InitialValue.toString();
                    }
                    // if (Name === "bill_amount") {
                    // 	console.log("Init", InitialValue)

                    // }

                    setLocalInputValue(UpdatedInitalValue);
                } else {
                    if (FormValue !== LocalInputValueUnformatted) {
                        let UpdatedValue = FormValue;
                        //Update from outside
                        if (MaskType === "Currency" && FormValue) {
                            //We need to convert it to a string and use a comma for EUR if required
                            UpdatedValue = FormValue.toString().replace(".", ",");
                            // UpdatedValue = "2345.45"
                        }
                        if (MaskType === "Number" && FormValue) {
                            //We need to convert it to a string and use a comma for EUR if required
                            UpdatedValue = FormValue.toString();
                            // UpdatedValue = FormValue.toString().replace(".", ",");
                        }
                        if (MaskType === "Pattern" && LocalInputValueUnformatted) {
                            // console.log("Mask Pattern");
                            //We need to convert it to a string and use a comma for EUR if required
                            UpdatedValue = LocalInputValueUnformatted.toString();
                        }
                        setLocalInputValue(UpdatedValue);
                    }
                }
            }
        }
    }, [InitialValue, Mask, MaskInstanceReady, FormValue, MaskType]);

    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/
    const HandleInputFocus = () => {
        ShareFocusState(true);
        setLazyPattern(false);
        //the mask placeholder should be hidden when there is no value and shown when there is focus on the input
    };

    const HandleOnAcceptMask = (Value, mask) => {
        //We always return a server type formatted value to the server!!!!!!!!
        // console.log("InputMask Accept", "Value : ", Value);
        // console.log("mask", mask);
        var FinalReturnValue;
        var Invalid = false;

        if (Mask.type === "Date" || Mask.type === "Time" || Mask.type === "DateTime") {
            var testFns;
            if (Mask.pattern.includes("MM") && Mask.pattern.includes("yy") && !Mask.pattern.includes("yyyy")) {
                //We need to addd one month to the date
                let temptestFns = parseFNS(Value, Mask.pattern, new Date());
                if (isValid(temptestFns)) {
                    testFns = add(temptestFns, {
                        months: 1,
                    });
                }
            } else if (Mask.pattern.includes("MMM")) {
                testFns = parseFNS(Value, Mask.pattern, new Date());
            } else {
                testFns = parseFNS(Value, Mask.pattern, new Date());
            }

            if (isValid(testFns)) {
                FinalReturnValue = formatISO(testFns);
            } else {
                Invalid = true;
            }
        } else if (MaskType === "Pattern") {
            FinalReturnValue = mask._unmaskedValue;
        } else {
            FinalReturnValue = mask._unmaskedValue;
        }

        //Update the input value
        setLocalInputValue(Value);
        setLocalInputValueUnformatted(FinalReturnValue);

        if (OutputValue && typeof OutputValue === "function") {
            if (Invalid === false) {
                let FinalPristine = InitialValue === FinalReturnValue ? true : false;
                OutputValue({Value: FinalReturnValue, FormattedValue: Value, Pristine: FinalPristine});
                if (OutputError && typeof OutputError === "function") {
                    OutputError(null);
                }
            } else {
                OutputError("Invalid Date");
            }
        }

        if (Value) {
            ShareHasValue(true);
            //We need to show the pattern (Lazy = false means show the pattern (we don't want it if there is no value))
            setLazyPattern(false);
        } else {
            ShareHasValue(false);
        }
    };
    const HandleInputBlur = () => {
        ShareFocusState(false);
        setLazyPattern(true);
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var FormElement;

    if (MaskDefinitionReady === true) {
        FormElement = (
            <IMaskInput
                mask={Mask.mask}
                //The Following is used for blocks
                blocks={Mask.blocks ? Mask.blocks : null}
                pattern={Mask.pattern ? Mask.pattern : null}
                format={Mask.format ? Mask.format : null}
                parse={Mask.parse ? Mask.parse : null}
                //This is used for no blocks
                thousandsSeparator={Mask.thousandsSeparator ? Mask.thousandsSeparator : ""}
                radix={Mask.radix ? Mask.radix : null}
                scale={Mask.scale ? Mask.scale : null}
                signed={Mask.signed ? Mask.signed : null}
                mapToRadix={Mask.mapToRadix ? Mask.mapToRadix : null}
                min={Mask.min ? Mask.min : null}
                max={Mask.max ? Mask.max : null}
                maxLength={Mask.maxLength ? Mask.maxLength : null}
                //This is standard
                lazy={LazyPattern}
                // overwrite={Mask.overwrite === true ? true : false}
                // autofix={Mask.autofix === true ? true : false}
                unmask={Mask.unmask === "typed" ? "typed" : Mask.unmask === true ? true : false}
                ref={Ref_Imask}
                inputRef={() => Ref_LocalInput} // access to nested input
                onAccept={(value, mask) => HandleOnAcceptMask(value, mask)}
                value={LocalInputValue}
                onFocus={HandleInputFocus}
                readOnly={ReadOnly === true ? true : false}
                onBlur={HandleInputBlur}
                style={{
                    flex: "1 1 auto",
                    width: FieldMaxWidth ? FieldMaxWidth : null,
                    ...Style,
                }}
                className={classes.Input}
                name={Name}
            />
        );
    } else {
        FormElement = null;
    }

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldTextInputMasked.defaultProps = {
    InitialValue: null,
    OutputValue: null,
    Name: null,
    ShareFocusState: null,
    ShareHasValue: null,
    FieldMaxWidth: null,
};

FieldTextInputMasked.propTypes = {
    /**
     * InitialValue :
     */
    InitialValue: PropTypes.any,
    /**
     * OutputValue :
     */
    OutputValue: PropTypes.any,
    /**
     * Name :
     */
    Name: PropTypes.any,
    /**
     * ShareFocusState :
     */
    ShareFocusState: PropTypes.any,
    /**
     * ShareHasValue :
     */
    ShareHasValue: PropTypes.any,
    /**
     * FieldMaxWidth :
     */
    FieldMaxWidth: PropTypes.any,
};

export default FieldTextInputMasked;
