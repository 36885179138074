import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormWithApi from "../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import InvoiceGeneratorEditForm from "./InvoiceGeneratorEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

	console.log("InvoiceGeneratorEditDetails")
    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/financials/invoices/invoice/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/invoices/invoice`,
        DELETE: "",
        PUT: "",
        POST: "new", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("args", args);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            //We redirect to edit to reset the form and get the right buttons and editing
            History.push(`/financials/bills/table`);

            // History.push(`/crm/customers/customer/${Id}/view`);
        } else {
            let Id = args.data.data.Bill._id;
            History.push(`/financials/bills/bill/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Bill data", data);
        //SET THE INITIAL FORM VALUES
        let Bill = data.Bill ? data.Bill : null;

        var Companies;
        if (data.Companies && data.Companies.length > 0) {
            Companies = data.Companies.map((elem) => {
                return {value: elem._id, label: elem.company};
            });
        }

        let BillsPaths = [];

        if (Bill && Bill.bill_documents_paths && Array.isArray(Bill.bill_documents_paths) && Bill.bill_documents_paths.length > 0) {
            Bill.bill_documents_paths.forEach((elem) => {
                BillsPaths.push(elem.serverPath);
                // bill_documents_paths.push(elem);
            });
        }
        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Bill ? Bill._id : "new",
                bill_amount: Bill ? Bill.bill_amount : null,
                is_VAT_Intracomm: Bill ? (Bill.is_VAT_Intracomm === true ? true : false) : false,
                is_VAT_Auto: Bill ? (Bill.is_VAT_Auto === true ? true : false) : false,
                vat_rate: Bill ? Bill.vat_rate : 21,
                vat_amount: Bill ? Bill.vat_amount : null,
                amount_without_vat: Bill ? Bill.amount_without_vat : null,
                is_grouped_bills: Bill ? (Bill.is_grouped_bills === true ? true : false) : false,
                due_date_ISo: Bill ? Bill.due_date_ISo : null,
                is_charged_automatically: Bill ? (Bill.is_charged_automatically === true ? true : false) : false,
                communication_structured: Bill ? Bill.communication_structured : null,
                communication_freeFormat: Bill ? Bill.communication_freeFormat : null,
                ref_bill_category: Bill ? Bill.ref_bill_category : null,
                ref_billSubCategory: Bill ? Bill.ref_billSubCategory : null,
                account_number: Bill ? Bill.account_number : null,
                account_name: Bill ? Bill.account_name : null,
                account_owner: Bill ? Bill.account_owner : null,
                bank_name: Bill ? Bill.bank_name : null,
                bic: Bill ? Bill.bic : null,
                ref_supplier: Bill ? Bill.ref_supplier : null,
                ref_companyBankAccount: Bill ? Bill.ref_companyBankAccount : null,
                is_paid: Bill ? (Bill.is_paid === true ? true : false) : false,
                paid_date_ISo: Bill ? Bill.paid_date_ISo : null,
                calc_is_late: Bill ? Bill.calc_is_late : null,
                calc_remaining_days: Bill ? Bill.calc_remaining_days : null,
                calc_datepaid_late_days: Bill ? Bill.calc_datepaid_late_days : null,
                is_reconciled_transaction: Bill ? (Bill.is_reconciled_transaction === true ? true : false) : false,
                reconciled_transaction_id: Bill ? Bill.reconciled_transaction_id : null,
                accounting_note: Bill ? Bill.accounting_note : null,
                accouting_communication: Bill ? Bill.accouting_communication : null,
				is_payement_scheduled : Bill ? (Bill.is_payement_scheduled === true ? true : false) : false,
            },
            SelectOptions: {
                ref_supplier: Companies,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                bill_documents_paths: BillsPaths,
            },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            let FilesUpload = FullSubmission.FilesUploader;

            console.log("values", values);

            let BillsPaths=[];
            if (FilesUpload && FilesUpload.bill_documents_paths && Array.isArray(FilesUpload.bill_documents_paths) && FilesUpload.bill_documents_paths.length > 0) {
                FilesUpload.bill_documents_paths.forEach((elem) => {
                    if (elem.originalName) {
                        BillsPaths.push(elem);
                    }
                });
            } else {
                BillsPaths = null;
            }
            if (values) {
                let ServerData = {
                    API_id: values.ApiID,

                    Bill: {
                        Updatable: {
                            bill_amount: values.bill_amount,
                            is_VAT_Intracomm: values.is_VAT_Intracomm,
                            is_VAT_Auto: values.is_VAT_Auto,
                            vat_rate: values.vat_rate,
                            vat_amount: values.vat_amount,
                            amount_without_vat: values.amount_without_vat,
                            is_grouped_bills: values.is_grouped_bills,
                            due_date_ISo: values.due_date_ISo,
                            is_charged_automatically: values.is_charged_automatically,
                            communication_structured: values.communication_structured,
                            communication_freeFormat: values.communication_freeFormat,
                            ref_bill_category: values.ref_bill_category,
                            ref_billSubCategory: values.ref_billSubCategory,
                            ref_companyBankAccount: values.ref_companyBankAccount,
                            ref_supplier: values.ref_supplier,
                            is_paid: values.is_paid,
                            paid_date_ISo: values.paid_date_ISo,
                            bill_documents_paths: BillsPaths,
                            accounting_note: values.accounting_note,
                            accouting_communication: values.accouting_communication,
							is_payement_scheduled : values.is_payement_scheduled
                        },
                    },
                };

                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={InvoiceGeneratorEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: true, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
