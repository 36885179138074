/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import PerfectScrollBarWrapper from "../PerfectScrollBarWrapper/PerfectScrollBarWrapper";
import {Preview} from "@artibulles-cis/react-icons";

import useNavSideBarContext from "../utils/context/navSideBarContext/useNavSideBarContext";
const LockedstylesWithProps = (props) => {
    return {
        SideBarNav: {
            position: "relative",
            boxSizing: "border-box",
            zIndex: props.ZIndex ? props.ZIndex : null,
        },
    };
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    SideBar: theme.NavSideBar.SideBar,
    SideBarContent: theme.NavSideBar.SideBarContent,
    SideBarContent_WithPerfectScrollbar: theme.NavSideBar.SideBarContent_WithPerfectScrollbar,
    SideBarContent_WithScrollBarX: theme.NavSideBar.SideBarContent_WithScrollBarX,
    SideBarContent_WithScrollBarY: theme.NavSideBar.SideBarContent_WithScrollBarY,
    SideBarContent_WithScrollBarXY: theme.NavSideBar.SideBarContent_WithScrollBarXY,
    ResizableHandle: {
        position: "absolute",
        right: "0px",
        height: "100%",
        top: "0px",
        zIndex: "1",
        "&:after": {
            content: "' '",
            width: "4px",
            position: "absolute",
            top: "0px",
            bottom: "0px",
            left: "-2px",
            cursor: "ew-resize",
            zIndex: "2",
        },
    },
    OverlayButtonShowSideBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0px",
        left: "0px",
        boxSizing: "border-box",
        borderRadius: "4px",
        height: "40px",
        width: "40px",
        backgroundColor: "rgba(14,14,14,0.7)",
        border: "none",
        zIndex: "100",
    },
}));

/**
 * Creates a Sidebar Container that can be :
 * -Shown or hidden
 * -Minimized and controlled through Mouse Click with a Controller or Mouse Hover
 * -Controlled with an Integrated Controller or an external Controller
 * It's part of the SideBar Component Package that facilitate the creation of hihgly customizable SideBar
 */
const NavSideBar = React.forwardRef(function NavSideBar(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Show,
        WidthMinimized,
        WidthExpanded,
        SideBarController,
        WithCollapseAllController,
        CustomCollapseAllController,
        WithExpandAllController,
        CustomExpandAllController,
        CollapseOnMinimize,
        Resizable,
        ResizeMinAllowed,
        ResizableMaxWidth,
        ResizableMinWidth,
        UpdateWidthDraggedDimensions,
        ExpandOnHover,
        TopRefComponentID,
        BottomRefComponentID,
        DelegateMinimizeStatus,
        UpdateMinimizedStatus,
        SetIsMinimizeFromOutside,
        ShareIsMinimizedStatus,
        WithPerfectScrollbar,
        WithScrollBar,
        ZIndex,
        ClassOverrideSideBarNav,
        ClassExtendSideBarNav,
        ClassOverrideSideBarNavContent,
        ClassExtendSideBarNavContent,
        children,
        ...other
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_Controller = React.useRef(); //Creating a ref to the Controller Node to be able to calculate it's size;
    const NavBarRef = React.useRef(); //To calculate the resizing position (mouse abs position)
    const DragableHandleRef = React.useRef(); //to handle the resizing
    /****************************** REFS *********************/

    /****************************** STATE *********************/

    const [Height, setHeight] = React.useState(null);
    const [SideBarContentHeight, setSideBarContentHeight] = React.useState(null);
    const [TopPosition, setTopPosition] = React.useState(null);
    const [IsMinimized, setIsMinimized] = React.useState(false);
    const [SideBarWidth, setSideBarWidth] = React.useState(WidthExpanded);
    const [FinalSideBarWidthMinAndExp, setFinalSideBarWidthMinAndExp] = React.useState({
        min: WidthMinimized,
        max: WidthExpanded,
    });
    const [IsHovered, setIsHovered] = React.useState(false);
    const [KeepOpen, setKeepOpen] = React.useState(true);
    const [InitDragging, setInitDragging] = React.useState(false); //Used to prevent the onmouseup event to fire when mounting
    const [IsDragging, setIsDragging] = React.useState(false);
    const [DevErrors, setDevErrors] = React.useState(null); //Only used in Development mode
    const {CollapseExpandAccordions, ResetTheContext, SetSideBarMinimizedStatus} = useNavSideBarContext();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    //ADJUSTING SIDEBAR HEIGHT WHEN REQUIRED (WINDOWRESIZE)
    const handleResize = React.useCallback(() => {
        var SideBarHeight, TopPos, ContentHeight, AbsoluteTop;

        //Setting the Height and Position of the SideBar Based on the TopRefComponentID, BottomRefComponentID, and LefRefComponentID, RightRefComponentID,
        if (TopRefComponentID && document.getElementById(TopRefComponentID)) {
            TopPos = document.getElementById(TopRefComponentID).getBoundingClientRect().height;
            AbsoluteTop = document.getElementById(TopRefComponentID).getBoundingClientRect().bottom;

            if (BottomRefComponentID && document.getElementById(BottomRefComponentID)) {
                SideBarHeight = document.getElementById(BottomRefComponentID).getBoundingClientRect().top - AbsoluteTop;
                //   window.innerHeight -
                //   document.getElementById(TopRefComponentID).getBoundingClientRect().height -
                //   document.getElementById(BottomRefComponentID).getBoundingClientRect().height;
            } else {
                SideBarHeight = window.innerHeight - document.getElementById(TopRefComponentID).getBoundingClientRect().height;
            }
        } else {
            TopPos = 0;
            if (BottomRefComponentID && document.getElementById(BottomRefComponentID)) {
                SideBarHeight = document.getElementById(BottomRefComponentID).getBoundingClientRect().top;

                //   window.innerHeight -
                //   document.getElementById(BottomRefComponentID).getBoundingClientRect().height;
            } else {
                SideBarHeight = window.innerHeight;
            }
        }
        // console.log("SideBarHeight", SideBarHeight);
        if (Ref_Controller.current) {
            let ControllerHeight = ReactDOM.findDOMNode(Ref_Controller.current).getBoundingClientRect().height;
            // console.log("ControllerHeight", ControllerHeight);
            ContentHeight = SideBarHeight - ControllerHeight;
        } else {
            ContentHeight = SideBarHeight;
        }
        setHeight(SideBarHeight);
        setTopPosition(TopPos);
        setSideBarContentHeight(ContentHeight);
    }, [TopRefComponentID, BottomRefComponentID, Ref_Controller]);

    //RESIZING THE SIDEBAR IF RESIZABLE IS TRUE - HANDLE DRAGGING ->CALCUATE NEW SIZE
    const HandleDraggingResize = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (IsDragging) {
                let maxDragExpanded = ResizableMaxWidth ? ResizableMaxWidth : null;
                let minDragExpanded = ResizableMinWidth ? ResizableMinWidth : WidthMinimized;
                let maxDragMinimized = FinalSideBarWidthMinAndExp.max;
                let minDragMinimized = ResizableMinWidth ? ResizableMinWidth : 0;

                let LeftSideBarPosX = NavBarRef.current.getBoundingClientRect().left;

                let DraggedWidth = event.pageX - LeftSideBarPosX;
                if (IsMinimized === false) {
                    if (DraggedWidth <= maxDragExpanded && DraggedWidth >= minDragExpanded) {
                        //Allow updating the maxwidth
                        setFinalSideBarWidthMinAndExp({
                            ...FinalSideBarWidthMinAndExp,
                            ...{max: DraggedWidth},
                        });
                        setSideBarWidth(DraggedWidth);
                    }
                } else {
                    if (DraggedWidth <= maxDragMinimized && DraggedWidth >= minDragMinimized) {
                        //Allow updating the maxwidth
                        setFinalSideBarWidthMinAndExp({
                            ...FinalSideBarWidthMinAndExp,
                            ...{min: DraggedWidth},
                        });
                        setSideBarWidth(DraggedWidth);
                    }
                }
            }
        },
        [FinalSideBarWidthMinAndExp, IsMinimized, ResizableMaxWidth, ResizableMinWidth, WidthMinimized, IsDragging]
    );
    //RESIZING THE SIDEBAR IF RESIZABLE IS TRUE - HANDLE MOUSE CLICK ON HANDLE ->SET DRAGGING TO TRUE
    const HandleDraggingStart = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();
            if (InitDragging === false) {
                setInitDragging(true);
            }
            setIsDragging(true);
        },
        [InitDragging]
    );
    //RESIZING THE SIDEBAR IF RESIZABLE IS TRUE - HANDLE RELEASE MOUSE CLICK -> SET DRAGGING TO FALSE AND PASS THE DIMENSIONS BACK
    const HandleDraggingStop = React.useCallback(
        (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (InitDragging) {
                if (event.target === DragableHandleRef.current) {
                    window.removeEventListener("mouseup", HandleDraggingStop);
                    setIsDragging(false);
                    if (UpdateWidthDraggedDimensions && typeof UpdateWidthDraggedDimensions) {
                        UpdateWidthDraggedDimensions({
                            WidthMinimized: FinalSideBarWidthMinAndExp.min,
                            widthExpanded: FinalSideBarWidthMinAndExp.max,
                        });
                    }
                }
            }
        },
        [FinalSideBarWidthMinAndExp, UpdateWidthDraggedDimensions, InitDragging]
    );

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    //   Handling the Hover Function
    React.useEffect(() => {
        //Prevent the function from running when dragging
        if (!IsDragging) {
            if (ExpandOnHover === true) {
                if (IsHovered === true) {
                    if (IsMinimized) {
                        setSideBarWidth(FinalSideBarWidthMinAndExp.max);
                        setIsMinimized(false);
                        SetSideBarMinimizedStatus(false);
                        if (ShareIsMinimizedStatus) {
                            ShareIsMinimizedStatus(true);
                        }
                    }
                } else {
                    if (!KeepOpen) {
                        setSideBarWidth(FinalSideBarWidthMinAndExp.min);
                        setIsMinimized(true);
                        SetSideBarMinimizedStatus(true);
                        if (ShareIsMinimizedStatus) {
                            ShareIsMinimizedStatus(false);
                        }
                    }
                }
            }
        }
    }, [IsHovered, IsMinimized, IsDragging, FinalSideBarWidthMinAndExp, KeepOpen, ShareIsMinimizedStatus, ExpandOnHover]);
    //Controlling the SideBar state from the Outside
    React.useEffect(() => {
        if (DelegateMinimizeStatus) {
            //checking that the props are correct to prevent bugs
            if (
                DelegateMinimizeStatus.StatusMinimized === null ||
                DelegateMinimizeStatus.StatusMinimized === undefined ||
                typeof DelegateMinimizeStatus.StatusMinimized !== "boolean" ||
                DelegateMinimizeStatus.ChangeStatusMinimized === null ||
                DelegateMinimizeStatus.ChangeStatusMinimized === undefined ||
                typeof DelegateMinimizeStatus.ChangeStatusMinimized !== "function"
            ) {
                setDevErrors("error in DelegateMinimizeStatus, wrong type of arguments");
            } else {
                if (!IsDragging) {
                    if (DelegateMinimizeStatus.StatusMinimized === true) {
                        setIsMinimized(true);
                        SetSideBarMinimizedStatus(true);
                        setSideBarWidth(FinalSideBarWidthMinAndExp.min);
                        setKeepOpen(false);
                        if (CollapseOnMinimize === true) {
                            //Close all sublink
                            CollapseExpandAccordions("Collapse");
                        }
                    } else {
                        setIsMinimized(false);
                        SetSideBarMinimizedStatus(false);
                        setSideBarWidth(FinalSideBarWidthMinAndExp.max);
                        setKeepOpen(true);
                    }
                }
            }
        }
    }, [DelegateMinimizeStatus, FinalSideBarWidthMinAndExp, IsDragging]);

    //Resize Event Listener
    React.useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return function cleanup() {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    //Draggging Event Listener
    React.useEffect(() => {
        if (Resizable) {
            var RefEventListener = DragableHandleRef.current;
            if (DragableHandleRef && DragableHandleRef.current) {
                RefEventListener.addEventListener("mousedown", HandleDraggingStart);
                window.addEventListener("mouseup", HandleDraggingStop);
                if (IsDragging) {
                    window.addEventListener("mousemove", HandleDraggingResize);
                }

                return function cleanup() {
                    RefEventListener.removeEventListener("mousedown", HandleDraggingStart);
                    window.removeEventListener("mouseup", HandleDraggingStop);
                    window.removeEventListener("mousemove", HandleDraggingResize);
                };
            }
        }
    }, [HandleDraggingResize, HandleDraggingStart, HandleDraggingStop, Resizable, IsDragging]);

    //Resetting the Context when show = false to fully unmount the components
    React.useEffect(() => {
        if (Show === false) {
            ResetTheContext();
        }
    }, [Show]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    //Handling Mouse Events for opening the NavBar onHover
    const handleMouseEnter = React.useCallback(() => {
        setIsHovered(true);
    }, [setIsHovered]);

    const handleMouseLeave = React.useCallback(() => {
        setIsHovered(false);
    }, [setIsHovered]);

    //Handling Click events With the Controller
    const handleMiniMize = () => {
        if (DelegateMinimizeStatus) {
            //Delegate the state to the parent component --> Ask the parent to change state
            DelegateMinimizeStatus.ChangeStatusMinimized();
        } else {
            //Manage the state internally
            if (!IsMinimized) {
                setIsMinimized(true);
                SetSideBarMinimizedStatus(true);
                setSideBarWidth(FinalSideBarWidthMinAndExp.min);
                setKeepOpen(false);
                if (ShareIsMinimizedStatus) {
                    ShareIsMinimizedStatus(true);
                }
                if (CollapseOnMinimize === true) {
                    //Close all sublink
                    CollapseExpandAccordions("Collapse");
                }
            } else {
                setIsMinimized(false);
                SetSideBarMinimizedStatus(false);
                setSideBarWidth(FinalSideBarWidthMinAndExp.max);
                setKeepOpen(true);
                if (ShareIsMinimizedStatus) {
                    ShareIsMinimizedStatus(false);
                }
            }
            if (UpdateMinimizedStatus) {
                UpdateMinimizedStatus();
            }
        }
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    //Setting the final class based on props
    var FinalClassNavSideBarContent;
    if (ClassOverrideSideBarNavContent) {
        FinalClassNavSideBarContent = ClassOverrideSideBarNavContent;
    } else {
        if (WithScrollBar) {
            if (WithScrollBar.X && !WithScrollBar.Y) {
                FinalClassNavSideBarContent = clsx(classes.SideBarContent_WithScrollBarX, ClassExtendSideBarNavContent);
            }
            if (!WithScrollBar.X && WithScrollBar.Y) {
                FinalClassNavSideBarContent = clsx(classes.SideBarContent_WithScrollBarY, ClassExtendSideBarNavContent);
            }
            if (WithScrollBar.X && WithScrollBar.Y) {
                FinalClassNavSideBarContent = clsx(classes.SideBarContent_WithScrollBarXY, ClassExtendSideBarNavContent);
            }
        } else if (WithPerfectScrollbar) {
            FinalClassNavSideBarContent = clsx(classes.SideBarContent_WithPerfectScrollbar, ClassExtendSideBarNavContent);
        } else {
            FinalClassNavSideBarContent = clsx(classes.SideBarContent, ClassExtendSideBarNavContent);
        }
    }
    //Adding the Controller Component
    let ControllerComponent;
	if (SideBarController){
		let ControllerElem = React.cloneElement(SideBarController, {
			// ref: Ref_Controller,
			Ref_Controller : Ref_Controller,
			IsMinimized: IsMinimized,
			HandleMiniMize: handleMiniMize,
			Hide: SideBarWidth === "0" ? true : false,
		});
		ControllerComponent = ControllerElem

	}else{
		ControllerComponent = null	
	}
	

    //Resizing element
    const ResizeHandle = () => {
        if (Resizable) {
            if (IsMinimized === false) {
                return <div ref={DragableHandleRef} className={classes.ResizableHandle}></div>;
            } else {
                if (ResizeMinAllowed === true && IsMinimized === true) {
                    return <div ref={DragableHandleRef} className={classes.ResizableHandle}></div>;
                } else {
                    return null;
                }
            }
        } else {
            return null;
        }
    };
    //Adding an overlay Button if the sideBarWidth is 0;
    const OverlayOpenSideBarButton = () => {
        if (SideBarWidth === "0") {
            return (
                <div className={classes.OverlayButtonShowSideBar} onClick={handleMiniMize}>
                    <Preview SVGFillColor="white" />
                </div>
            );
        } else {
            return null;
        }
    };
    //Mounting or unmounting the NavBar based on Show Prop
    const ErrorComponent = () => {
        if (process.env.NODE_ENV !== "production") {
            if (DevErrors) {
                return <div style={{color: "red"}}> {DevErrors}</div>;
            } else {
                return null;
            }
        }
    };
    var NavBar;
    if (Show) {
        NavBar = (
            <nav
                ref={NavBarRef}
                {...other}
                className={ClassOverrideSideBarNav ? ClassOverrideSideBarNav : clsx(classes.SideBar, ClassExtendSideBarNav)}
                style={{
                    ...LockedstylesWithProps(props).SideBarNav,
                    height: Height,
                    width: `${SideBarWidth}px`,
                    top: TopPosition,
                    borderRight: FinalSideBarWidthMinAndExp.min === "0" && IsMinimized === true ? "none" : null,
                    borderLeft: FinalSideBarWidthMinAndExp.min === "0" && IsMinimized === true ? "none" : null,
                    transition: IsDragging ? "all 0s" : null,
                }}
            >
                {OverlayOpenSideBarButton()}
                {ResizeHandle()}

                {ControllerComponent}
                <div
                    className={FinalClassNavSideBarContent}
                    style={{
                        height: SideBarContentHeight,
                        display: "block",
                        opacity: FinalSideBarWidthMinAndExp.min === "0" && IsMinimized === true ? "0" : "1",
                        transition: "opacity 300ms ease-in-out",
                    }}
                    onMouseEnter={ExpandOnHover ? handleMouseEnter : null}
                    onMouseLeave={ExpandOnHover ? handleMouseLeave : null}
                >
                    <PerfectScrollBarWrapper Active={WithPerfectScrollbar ? true : false} {...other}>
                        {ErrorComponent()}
                        {children ? children : "Developper : You are not Passing Children!!!!!"}
                    </PerfectScrollBarWrapper>
                </div>
            </nav>
        );
    } else {
        NavBar = null;
    }

    return <React.Fragment>{NavBar}</React.Fragment>;
    /****************************** RENDER *********************/
});

NavSideBar.defaultProps = {
    Show: true,
    WidthMinimized: 250,
    WidthExpanded: 90,
    SideBarController: null,
    WithCollapseAllController: false,
    CustomCollapseAllController: null,
    WithExpandAllController: false,
    CustomExpandAllController: null,
    CollapseOnMinimize: false,
    Resizable: false,
    ResizeMinAllowed: false,
    ResizableMaxWidth: null,
    ResizableMinWidth: null,
    UpdateWidthDraggedDimensions: null,
    ExpandOnHover: false,
    TopRefComponentID: null,
    BottomRefComponentID: null,
    DelegateMinimizeStatus: null,
    UpdateMinimizedStatus: null,
    SetIsMinimizeFromOutside: null,
    ShareIsMinimizedStatus: null,
    WithScrollBar: null,
    WithPerfectScrollbar: false,
    ZIndex: "200",
    ClassOverrideSideBarNav: null,
    ClassExtendSideBarNav: null,
    ClassOverrideSideBarNavContent: null,
    ClassExtendSideBarNavContent: null,
};

NavSideBar.propTypes = {
    /**
     * Show or hide the SideBar
     */
    Show: PropTypes.bool,
    /**
     * WidthMinimized : The Width of the expanded SideBar
     */
    WidthMinimized: PropTypes.string,
    /**
     * WidthExpanded :  The Width of the minimized SideBar
     */
    WidthExpanded: PropTypes.string,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    SideBarController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    WithCollapseAllController: PropTypes.bool,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CustomCollapseAllController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    WithExpandAllController: PropTypes.bool,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CustomExpandAllController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CollapseOnMinimize: PropTypes.bool,
    /**
     * Allow dragging the sideBar border to resize it
     */
    Resizable: PropTypes.bool,
    /**
     * If set to true both minimized and expanded width can be modified, if false, only the expanded width can be modified
     */
    ResizeMinAllowed: PropTypes.bool,
    /**
     * The maximum width for resizing, null => window
     */

    ResizableMaxWidth: PropTypes.string,
    /**
     * The minimu width for resizing the sidebar max_min =0
     */
    ResizableMinWidth: PropTypes.string,
    /**
     * A function to be used to store the updated dragged dimensions in case you want to store it as final settings in a DB or redux
     */
    UpdateWidthDraggedDimensions: PropTypes.func,
    /**
     * If true, MouseHover will open and minimize the SideBar
     */
    ExpandOnHover: PropTypes.bool,
    /**
     * TopRefComponentID : The id of the component to place the sidebar below
     * Used to reposition the SideBar Dynamically if the top component changes (hidden, or grow...)
     * If nothing is specified, then the top position will be 0px
     */
    TopRefComponentID: PropTypes.string,
    /**
     * BottomRefComponentID : The id of the component to stop the SideBar before
     * Used to reposition the SideBar Dynamically if the top component changes (hidden, or grow...)
     * If nothing is specified, then the top height will be the Overall container height
     */
    BottomRefComponentID: PropTypes.string,
    /**
     * DelegateMinimizeStatus : Control the minimized status from outside
     * This object requires 2 parameters
     * DelegateMinimizeStatus={{StatusMinimized: true/false,  ChangeStatusMinimized: function()}}
     * StatusMinimized : the minimized status desired true/false
     * ChangeStatusMinimized : a trigger to ask the parent to change the minimized status
     * example : in parent component
     * const [SideBarStatusMinimized, setSideBarStatusMinimized ] = React.useState(false)
     * const ToggleMinimize = ()=> {
     * setSideBarStatusMinimized(!SideBarStatusMinimized)
     * }
     * <button onClick={ToggleMinimized}>Toggle</button>
     * <NavSideBar
     * DelegateMinimizeStatus={{StatusMinimized: SideBarStatusMinimized,  ChangeStatusMinimized: ToggleMinimize}}
     * />
     */
    DelegateMinimizeStatus: PropTypes.object,
    /**
     * UpdateMinimizedStatus : Used to pass the minimized status of the SideBar to the Parent Component
     * A function needs to be passed returning true or false
     * This is required for instance when another component needs to resize when the sideBar size changes
     */
    UpdateMinimizedStatus: PropTypes.any,
    /**
     * SetIsMinimizeFromOutside : Controlling the width of the SideBar from the outside
     *
     */
    SetIsMinimizeFromOutside: PropTypes.bool,
    /**
     * SetIsMinimizeFromOutside : Controlling the width of the SideBar from the outside
     * The parent function used to control it must return true or false
     */
    ShareIsMinimizedStatus: PropTypes.string,
    /**
     * Specify if a Scrollbar should be displayed
     * WithScrollBar={{X:false,Y:true }}
     */
    WithScrollBar: PropTypes.object,
    /**
     * Specify if perfectScrollBar should be used for the SideBarContent Element
     * See the PerfectScrollBarWrapper to see what properties can be passed.
     */
    WithPerfectScrollbar: PropTypes.bool,
    /**
     * Overrule the theme Z-Index property.
     */
    ZIndex: PropTypes.string,
    /**
     * Overrides the class of the SideBar Element
     */
    ClassOverrideSideBarNav: PropTypes.string,
    /**
     * Extends the class of the SideBar Element
     */
    ClassExtendSideBarNav: PropTypes.string,
    /**
     * Overrides the class of the SideBar Content Element
     */
    ClassOverrideSideBarNavContent: PropTypes.string,
    /**
     * Overrides the class of the SideBar Content Element
     */
    ClassExtendSideBarNavContent: PropTypes.string,
};

export default NavSideBar;
