import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import {parseISO, add, sub, formatISO} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        // padding: "5px 5px",
        display: "flex",
        width: "100%",
        flex: "1 1 auto",
        height: "500px",
        padding: "2px 5px 0px 0px",
        // height: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        // height: "350px",
        // minHeight: "100%",
        // height : "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
    ClassExtendTabContent: {
        padding: "5px 5px",
        // height: "100%",
        // maxWidth: "1200px",
    },
    ButtonBar: {
        display: "flex",
        padding: "0px 5px 0px 0px",
        // height: "30px",
    },
    ButtonBar_Left: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    ButtonBar_Right: {
        flex: "1 1 auto",
        maxWidth: "220px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    ClassExtendButton: {
        padding: "0px",
        margin: "0px 0px",
    },
    Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
        fontSize: "0.8rem",
        boxSizing: "border-box",
    },
    Line_Title: {
        flex: "0 0 auto",
        width: "140px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
        boxSizing: "border-box",
    },
    Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
}));

const Component = React.memo(function Component(props) {
    const {CardId, ManualTempTotalAmount, StatementDateIso, FormViewMode, StatementId, SubmitParentForm} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const TableColumns = [
        {
            field: "calc_RefTransaction",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: false,
            // sort: "asc",
            // sortIndex: 2,
            // sortingOrder: ["asc", "desc"],
            // rowGroup: true,
            // showRowGroup : true,
            // hide: true,
            checkboxSelection: true,
        },
        {
            field: "transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
        },

        {
            field: "banking_amount_transaction",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            aggFunc: "sum",
        },
        {
            field: "communication",
            headerName: "communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: "location",
            headerName: "Location",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: "merchant",
            headerName: "Merchant",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
    ];
    const HandleRowSelectChange = (SelectedRows) => {
        console.log("SelectedRows", SelectedRows);
        if (SelectedRows.length === 0) {
            setSelectedRows(null);
            setSelectedAmount(0);
        } else {
            let Total = 0;
            SelectedRows.forEach((elem) => {
                Total = Total + elem.banking_amount_transaction;
            });
            console.log("Total", Total);
            setSelectedAmount(Total);
            setSelectedRows(SelectedRows);
        }
    };
    const TableOptions = {
        Selection: {
            Multiple: true,
            // GroupOnly: false,
            WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },
        // Grouping: {
        //     Type: "GroupRows",
        //     HeaderName: "Statement",
        //     MinWidth: 250,
        // },
        SideBar: "filters",
        Selectable: true,
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);

    const [APICallType, setAPICallType] = useState(null);

    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [SelectedRows, setSelectedRows] = useState(null);
    const [SelectedAmount, setSelectedAmount] = useState(0);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    if (!APICallType) {
                        console.log("Select Transactions Table", Data);

                        if (Init === false) {
                            // console.log("not init");

                            if (Data.Transactions && Array.isArray(Data.Transactions) && Data.Transactions.length > 0) {
                                Ref_LocaleData.current = Data;
                                setInit(true);
                            } else {
                                Ref_LocaleData.current = null;

                                setInit(true);
                            }
                        }
                    } else if (APICallType === "Add Selected Transactions") {
                        console.log("Need to recalculate the statement and save");
                        //Save the form and recalculate all
                        SubmitParentForm();
                        setTimeout(() => {
                            setInit(false);
                            setAPICallType(null);
                        }, 200);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;

        if (Ref_LocaleData.current) {
            FinalTableComponent = (
                <AGGridTable
                    TableName="Transactions"
                    Data={Ref_LocaleData.current}
                    // URLAbsolute={URLAbsolute}
                    TableColumns={TableColumns}
                    MainDataSource="Transactions"
                    // RowActionButtons={TableRowButtons}

                    TableOptions={TableOptions}
                    // ReloadData={ReloadTableData}
                    // SyncReloadDataStateFunction={setReloadTableData}
                    // TotalRow={TotalRow}
                />
            );
        }

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
        }, 200);
    }, [Ref_LocaleData]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        console.log("Selection Table", StatementDateIso);

        if (Init === false) {
            let StatementDate;
            if (StatementDateIso) {
                StatementDate = parseISO(StatementDateIso);
            }
            let StartDate, EndDate;

            if (StatementDate) {
                StartDate = sub(StatementDate, {months: 2});
                StartDate = formatISO(StartDate);
                EndDate = add(StatementDate, {months: 1});
                EndDate = formatISO(EndDate);
            }

            const ApiCall = {url: `${ProdURL}/api/companyaccount/transactions/?cardid=${CardId}&nostatement=true&startdate=${StartDate}&enddate=${EndDate}`, type: "get"};
            // const ApiCall = {url: `${ProdURL}/api/companyaccount/transactions/`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleAddToStatement = () => {
        console.log("SelectedRows", SelectedRows);
        let DataForm = {
            TransactionsIds: null,
            BankStatementId: StatementId,
        };
        let SelectedIds = [];
        SelectedRows.forEach((elem) => {
            SelectedIds.push(elem._id);
        });
        DataForm.TransactionsIds = SelectedIds;

        const ApiCall = {url: `${ProdURL}/api/companyaccount/addtransactiontostatement/`, type: "post", data: DataForm};
        setLoading(true);
        setAPICallType("Add Selected Transactions");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let FinalTableComponent;

    if (Init === true) {
        if (TableComponent) {
            if (SelectedRows) {
                FinalTableComponent = (
                    <React.Fragment>
                        <div className={classes.ButtonBar}>
                            <div className={classes.ButtonBar_Left}>
                                <Button Height="28px" Width="150px" ClassExtendButton={classes.ClassExtendButton} onClick={HandleAddToStatement}>
                                    Add to Statement
                                </Button>
                            </div>
                            <div className={classes.ButtonBar_Right}>
                                <div className={classes.Line}>
                                    <div className={classes.Line_Title}>Total Statement :</div>
                                    <div className={classes.Line_Value}>
                                        {ManualTempTotalAmount ? new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(ManualTempTotalAmount) : null}
                                    </div>
                                </div>
                                <div className={classes.Line}>
                                    <div className={classes.Line_Title}>Selected Total :</div>
                                    <div className={classes.Line_Value}>{new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(SelectedAmount)}</div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.TableWrapper}>{TableComponent}</div>
                    </React.Fragment>
                );
            } else {
                FinalTableComponent = (
                    <React.Fragment>
                        <div>Select the Transactions to add to the statement</div>

                        <div className={classes.TableWrapper}>{TableComponent}</div>
                    </React.Fragment>
                );
            }
        } else {
            FinalTableComponent = <div>Select the Transactions to add to the statement</div>;
        }
    } else {
        FinalTableComponent = "There is no data";
    }

    return (
        <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent} PerfectScrollBar={false}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
