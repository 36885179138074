import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const AppUpdater = React.memo(function AppUpdater(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
	const RefreshIntervalMillisSeconds = 60*60*60*1000
	
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                // console.log("ApiRes", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    // let data = ApiRes.data.data;
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        setInterval(() => {
            //Called once to get the company list and initiate the selects
            setLoading(true);
            const ApiCall = {url: `${ProdURL}/api/updatedbandclient/dbonly`, type: "get"};
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }, RefreshIntervalMillisSeconds);
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return <React.Fragment></React.Fragment>;

    /***************** RENDER ******************/
});

export default AppUpdater;
