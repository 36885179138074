/* eslint-disable */
import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";

import AccountingDashboardTabNavRouter from "./01AccountingDashboard/AccountingDashboardTabNavRouter";
import AccountAndTransactionsRouter from "./02AccountsAndTransactions/AccountAndTransactionsRouter";

const Component = React.memo(function Component(props) {
    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path="/financials/accounting">
                <Redirect to="/financials/accounting/dashboard" />
            </Route>
            <Route path="/financials/accounting/dashboard">
                <AccountingDashboardTabNavRouter />
            </Route>

            <Route path="/financials/accounting/accountsandtransactions">
                <AccountAndTransactionsRouter />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
});

export default Component;
// <Route path="/financials/accoutning/payments">
//                 <AccountAndTransactionsRouter />
//             </Route>
//             <Route path="/financials/accounting/journals">
//                 <AccountAndTransactionsRouter />
//             </Route>
//             <Route path="/financials/accounting/analysis">
//                 <AccountAndTransactionsRouter />
//             </Route>
//             <Route path="/financials/accounting/settings">
//                 <AccountAndTransactionsRouter />
//             </Route>
