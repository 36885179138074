import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Link, useRouteMatch, useLocation} from "react-router-dom";
import ScrollToRef from "../utils/ScrollToRef";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    NavSideBarLink: theme.NavSideBarLink,
    NavSideBarLinkActive: theme.NavSideBarLink.NavSideBarLink_Active,
    NavSideBarLink_ActiveIndicator_Inactive: theme.NavSideBarLink.ActiveIndicator_Inactive,
    NavSideBarLink_ActiveIndicator_Active: theme.NavSideBarLink.ActiveIndicator_Active,
}));
/**
 * A NavLink Element for the NavSideBar that manages the Active Status with different styles Options.
 * Note : There is a scroll option built in that only scrolls if the route is matched programmatically (not by clickin on the link).
 * For this to work, we pass a state to the link component with clickedLink : true when the Link is clicked
 * If the state doesn't exist, it means the match is coming from elsewhere --> scroll is then actived
 */

const NavSideBarLink = React.forwardRef(function NavSideBarLink(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Slug,
        ActiveLinkIndicator,
        ScrollToLink,
        onClick,
        ClassOverrideNavSideBarLink,
        ClassExtendNavSideBarLink,
        ClassOverrideNavSideBarLink_Active,
        ClassExtendNavSideBarLink_Active,
        ClassOverrideActiveLink_Indicator_Inactive,
        ClassOverrideActiveLink_Indicator_Active,
        Exact,
        ShowOnlyIfActive,
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/
    /****************************** REFS *********************/
    const RefLink = React.useRef(null);
    /****************************** REFS *********************/
    const match = useRouteMatch(Slug);
    const location = useLocation();
    /****************************** STATE *********************/
    const [IsActiveLink, setIsActiveLink] = React.useState(false);
    /****************************** STATE *********************/

    /****************************** EFFECT *********************/
    React.useEffect(() => {
        if (Slug) {
            //If the element is a link
            if (match) {
                if (Exact === true) {
                    if (match && match.isExact) {
                        //Detecting if a scroll is required. Only if the link was not clicked --> state.ClickedLink === false
                        if (ScrollToLink === true && RefLink && RefLink.current) {
                            if (location && location.state && location.state.ClickedLink === true) {
                            } else {
                                ScrollToRef(RefLink);
                            }
                        }
                        setIsActiveLink(true);
                    } else {
                        setIsActiveLink(false);
                    }
                } else {
                    //we check that the real link contains the slug, if yes -> we set it to active, of not we ignore

                    if (location.pathname.includes(Slug)) {
                        setIsActiveLink(true);
                    } else {
                        setIsActiveLink(false);
                    }
                }
            } else {
                setIsActiveLink(false);
            }
        }
    }, [Slug, match, ScrollToLink, location, Exact]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var ActiveIndicator;
    if (ActiveLinkIndicator) {
        ActiveIndicator = (
            <div
                className={
                    IsActiveLink
                        ? ClassOverrideActiveLink_Indicator_Active
                            ? ClassOverrideActiveLink_Indicator_Active
                            : classes.NavSideBarLink_ActiveIndicator_Active
                        : ClassOverrideActiveLink_Indicator_Inactive
                        ? ClassOverrideActiveLink_Indicator_Inactive
                        : classes.NavSideBarLink_ActiveIndicator_Inactive
                }
            ></div>
        );
    } else {
        ActiveIndicator = null;
    }
    var FinalComponent;

    var FinalLinkClass;
    if (ClassOverrideNavSideBarLink) {
        FinalLinkClass = ClassOverrideNavSideBarLink;
    } else {
        FinalLinkClass = clsx(classes.NavSideBarLink, ClassExtendNavSideBarLink);
    }
    if (IsActiveLink) {
        if (ClassOverrideNavSideBarLink_Active) {
            FinalLinkClass = clsx(FinalLinkClass, ClassOverrideNavSideBarLink_Active);
        } else {
            FinalLinkClass = clsx(FinalLinkClass, classes.NavSideBarLinkActive, ClassExtendNavSideBarLink_Active);
        }
    }

    if (Slug) {
        if (ShowOnlyIfActive === true) {
            if (IsActiveLink === true) {
                FinalComponent = (
                    <Link
                        ref={RefLink}
                        to={{
                            pathname: `${Slug}`,
                            state: {ClickedLink: false},
                        }}
                        className={FinalLinkClass}
                        // onClick={HandleOnClick}
                    >
                        {ActiveIndicator}

                        {children}
                    </Link>
                );
            } else {
                FinalComponent = null;
            }
        } else {
            FinalComponent = (
                <Link
                    ref={RefLink}
                    to={{
                        pathname: `${Slug}`,
                        state: {ClickedLink: false},
                    }}
                    className={FinalLinkClass}
                    // onClick={HandleOnClick}
                >
                    {ActiveIndicator}

                    {children}
                </Link>
            );
        }
    } else {
        FinalComponent = (
            <div ref={ref} className={ClassOverrideNavSideBarLink ? ClassOverrideNavSideBarLink : clsx(classes.NavSideBarLink, ClassExtendNavSideBarLink)} onClick={onClick}>
                {ActiveIndicator}
                {children}
            </div>
        );
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;
    /****************************** RENDER *********************/
});

NavSideBarLink.defaultProps = {
    Slug: null,
    ActiveLinkIndicator: false,
    ScrollToLink: false,
    onClick: null,
    ClassOverrideNavSideBarLink: null,
    ClassExtendNavSideBarLink: null,
    ClassOverrideNavSideBarLink_Active: null,
    ClassExtendNavSideBarLink_Active: null,
    ClassOverrideActiveLink_Indicator_Inactive: null,
    ClassOverrideActiveLink_Indicator_Active: null,
    Exact: true,
    ShowOnlyIfActive: false,
};

NavSideBarLink.propTypes = {
    /**
     * The relative link to the page to display
     * If no Slug is passed, then it will just be a button type component. No active class can be set (it's impossible to manage the other navlink state)
     */
    Slug: PropTypes.string,
    /**
     * Add an Indicator Box to the let of the SideBar that is Highlighted when the link is active.bool
     */
    ActiveLinkIndicator: PropTypes.bool,
    /**
     * If true, when the route matches from the oustide it will scroll to the element (useful for search or other programmatic navigation)
     */
    ScrollToLink: PropTypes.bool,
    /**
     * Only used for non link elements (button type)
     */
    onClick: PropTypes.func,
    /**
     * Overriding the default class of the NavSideBarLink.
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */

    ClassOverrideNavSideBarLink: PropTypes.string,
    /**
     * Overriding the default class of the Controller Icon Wrapper
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassExtendNavSideBarLink: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideNavSideBarLink_Active: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassExtendNavSideBarLink_Active: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideActiveLink_Indicator_Inactive: PropTypes.string,
    /**
     * Extending the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideActiveLink_Indicator_Active: PropTypes.string,
    /**
     * If Exact is specified than the route needs to perfectly match
     * If not, it will include all sub routes
     */
    Exact: PropTypes.any,
    /**
     * If true it only be shown when the link is active (must use EXACT=true)
     */
    ShowOnlyIfActive: PropTypes.any,
};

export default NavSideBarLink;
