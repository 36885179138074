import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";

import AllAcounts from "./DashBoardTabs/AllAccounts/AllAcounts";
import BankAccounts from "./DashBoardTabs/BankAccounts/BankAccounts";
import CreditCards from "./DashBoardTabs/CreditCards/CreditCards";
import OtherAccounts from "./DashBoardTabs/OtherAccounts/OtherAccounts";


//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavLink: {
        borderRight: "1px solid rgb(220 220 220)",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNav
            MainSlug={`/financials/accounting/accountsandtransactions/accountsdahboard`}
            DefaultTab={`/financials/accounting/accountsandtransactions/accountsdahboard/allacounts`}
            ClassExtendTabNavLink={classes.ClassExtendTabNavLink}
            TabNavMenuHeight="30px"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>All Accounts</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/accountsdahboard/allacounts`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <AllAcounts />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Bank Accounts</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/accountsdahboard/bankaccounts`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <BankAccounts />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Credit Cards</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/accountsdahboard/creditcardsaccounts`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <CreditCards />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Other Accounts</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/accountsdahboard/otheraccounts`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <OtherAccounts />,
                }
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
