import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import Button from "../Button/Button";
import useTableContext from "../utils/context/tableContext/useTableContext";

import TableHeader from "../TableHeader/TableHeader";
import TableFooter from "../TableFooter/TableFooter";
import TableBody from "../TableBody/TableBody";
import TablePager from "../TablePager/TablePager";
import TableToolbar from "../TableToolbar/TableToolbar";
import TableDevMode from "../TableDevMode/TableDevMode";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//This style is used to prevent screwing the table and contains the common styles that needs to be applied to keep the cols and rows aligned as well as the borders
const MandatorySyncStyle = (props) => {
    var MandatorySyncStyleFinal = {
        BodyMarginTop: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        BodyMarginBottom: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        ColBorder: null,
        RowBorder: null,
        BodyTopBottomBorder: null, // SYNC WITH FIXED BODY && CONDITIONS : IF BodyMarginTop = 0 -> 0, IF BodyMarginBottom = 0 -> 0
        FixedColsRightBorder: null, //SYNC BETWEEN HEADER AND BODY && CONDITIONS : IF FIXED COL -> NON FIXED COL 1ST ROW borderRight = 0 WHATEVER THE LAST COL OF THE FIXED COL NEEDS TO USE THIS
        Grid_Fixed_ColsBorder: null, //Right Border of the Fixed Cols
        FooterborderTop: null, //TOP BORDER OF THE FOOTER
        HeaderInnerBorderRow: null, //Inner border for Headers rows
        CellVerticalPadding: null, //Controls the Row heigh
        CellHorizontalPadding: null, //horizontal padding of each cell
        ExternalTableBorderRadius: null,
    };

    if (props.TableOptions.CommonStyle) {
        if (props.TableOptions.CommonStyle.ColBorder) {
            MandatorySyncStyleFinal.ColBorder = props.TableOptions.CommonStyle.ColBorder;
        }
        if (props.TableOptions.CommonStyle.RowBorder) {
            MandatorySyncStyleFinal.RowBorder = props.TableOptions.CommonStyle.RowBorder;
        }
        if (props.TableOptions.CommonStyle.BodyTopBottomBorder) {
            MandatorySyncStyleFinal.BodyTopBottomBorder = props.TableOptions.CommonStyle.BodyTopBottomBorder;
        }
        if (props.TableOptions.CommonStyle.FixedColsRightBorder) {
            MandatorySyncStyleFinal.FixedColsRightBorder = props.TableOptions.CommonStyle.FixedColsRightBorder;
        }
        if (props.TableOptions.CommonStyle.Grid_Fixed_ColsBorder) {
            MandatorySyncStyleFinal.Grid_Fixed_ColsBorder = props.TableOptions.CommonStyle.Grid_Fixed_ColsBorder;
        }
        if (props.TableOptions.CommonStyle.FooterborderTop) {
            MandatorySyncStyleFinal.FooterborderTop = props.TableOptions.CommonStyle.FooterborderTop;
        }

        if (props.TableOptions.CommonStyle.HeaderInnerBorderRow) {
            MandatorySyncStyleFinal.HeaderInnerBorderRow = props.TableOptions.CommonStyle.HeaderInnerBorderRow;
        }
        if (props.TableOptions.CommonStyle.CellVerticalPadding) {
            MandatorySyncStyleFinal.CellVerticalPadding = props.TableOptions.CommonStyle.CellVerticalPadding;
        }
        if (props.TableOptions.CommonStyle.CellHorizontalPadding) {
            MandatorySyncStyleFinal.CellHorizontalPadding = props.TableOptions.CommonStyle.CellHorizontalPadding;
        }

        if (props.TableOptions.CommonStyle.ExternalTableBorderRadius) {
            MandatorySyncStyleFinal.ExternalTableBorderRadius = props.TableOptions.CommonStyle.ExternalTableBorderRadius;
        }
    }

    return {
        BodyMarginTop: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        BodyMarginBottom: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        ColBorder: MandatorySyncStyleFinal.ColBorder,
        RowBorder: MandatorySyncStyleFinal.RowBorder,
        BodyTopBottomBorder: MandatorySyncStyleFinal.BodyTopBottomBorder, // SYNC WITH FIXED BODY && CONDITIONS : IF BodyMarginTop = 0 -> 0, IF BodyMarginBottom = 0 -> 0
        FixedColsRightBorder: MandatorySyncStyleFinal.FixedColsRightBorder, //SYNC BETWEEN HEADER AND BODY && CONDITIONS : IF FIXED COL -> NON FIXED COL 1ST ROW borderRight = 0 WHATEVER THE LAST COL OF THE FIXED COL NEEDS TO USE THIS
        Grid_Fixed_ColsBorder: MandatorySyncStyleFinal.Grid_Fixed_ColsBorder, //Right Border of the Fixed Cols
        FooterborderTop: MandatorySyncStyleFinal.FooterborderTop,
        HeaderInnerBorderRow: MandatorySyncStyleFinal.HeaderInnerBorderRow,
        CellVerticalPadding: MandatorySyncStyleFinal.CellVerticalPadding, //Controls the Row heigh
        CellHorizontalPadding: MandatorySyncStyleFinal.CellHorizontalPadding, //horizontal padding of each cell
        ExternalTableBorderRadius: MandatorySyncStyleFinal.ExternalTableBorderRadius,
    };
};

//Those styles are the common one (for developer, prevent having to search for all the styles everywhere)
const MainTableStyle = (props) => {
    var FinalStyle = {
        ExternalTableBorder: null, //External border of the table
        ExternalTableShadow: null, //Use a shadow instead of a border
        HeaderBottomBorder: null, // WHATEVER IS THE LAST HEADER ROW NEEDS TO USE THIS (SEARCH OR LATEST HEADER ROW)
        Body: {
            fontSize: null,
            fontFamily: null,
            fontWeight: null,
            color: null,
            PrimaryRowBackground: null,
            AlternateRowBackground: null,
            EmptyBackground: null,
        },
        Header: {
            fontSize: null,
            fontFamily: null,
            fontWeight: null,
            color: null,
            HeaderRowBackground: null,
        },
        Footer: {
            Background: null,
            fontSize: null,
            fontFamily: null,
            fontWeight: null,
            color: null,
            ActiveBackgroundSelected: null,
            padding: null,
        },
    };

    if (props.TableOptions.CommonStyle) {
        if (props.TableOptions.CommonStyle.ExternalTableBorder) {
            FinalStyle.ExternalTableBorder = props.TableOptions.CommonStyle.ExternalTableBorder;
        }
        if (props.TableOptions.CommonStyle.HeaderBottomBorder) {
            FinalStyle.HeaderBottomBorder = props.TableOptions.CommonStyle.HeaderBottomBorder;
        }
        if (props.TableOptions.CommonStyle.ExternalTableShadow) {
            FinalStyle.ExternalTableShadow = props.TableOptions.CommonStyle.ExternalTableShadow;
        }
    }
    if (props.TableOptions.BodyStyle) {
        if (props.TableOptions.BodyStyle.fontSize) {
            FinalStyle.Body.fontSize = props.TableOptions.BodyStyle.fontSize;
        }
        if (props.TableOptions.BodyStyle.fontFamily) {
            FinalStyle.Body.fontFamily = props.TableOptions.BodyStyle.fontFamily;
        }

        if (props.TableOptions.BodyStyle.fontWeight) {
            FinalStyle.Body.fontWeight = props.TableOptions.BodyStyle.fontWeight;
        }

        if (props.TableOptions.BodyStyle.color) {
            FinalStyle.Body.color = props.TableOptions.BodyStyle.color;
        }

        if (props.TableOptions.BodyStyle.PrimaryRowBackground) {
            FinalStyle.Body.PrimaryRowBackground = props.TableOptions.BodyStyle.PrimaryRowBackground;
        }

        if (props.TableOptions.BodyStyle.AlternateRowBackground) {
            FinalStyle.Body.AlternateRowBackground = props.TableOptions.BodyStyle.AlternateRowBackground;
        }
        if (props.TableOptions.BodyStyle.EmptyBackground) {
            FinalStyle.Body.EmptyBackground = props.TableOptions.BodyStyle.EmptyBackground;
        }
    }
    if (props.TableOptions.HeaderStyle) {
        if (props.TableOptions.HeaderStyle.fontSize) {
            FinalStyle.Header.fontSize = props.TableOptions.HeaderStyle.fontSize;
        }
        if (props.TableOptions.HeaderStyle.fontFamily) {
            FinalStyle.Header.fontFamily = props.TableOptions.HeaderStyle.fontFamily;
        }

        if (props.TableOptions.HeaderStyle.fontWeight) {
            FinalStyle.Header.fontWeight = props.TableOptions.HeaderStyle.fontWeight;
        }

        if (props.TableOptions.HeaderStyle.color) {
            FinalStyle.Header.color = props.TableOptions.HeaderStyle.color;
        }
        if (props.TableOptions.HeaderStyle.HeaderRowBackground) {
            FinalStyle.Header.HeaderRowBackground = props.TableOptions.HeaderStyle.HeaderRowBackground;
        }
    }

    if (props.TableOptions.FooterStyle) {
        if (props.TableOptions.FooterStyle.Background) {
            FinalStyle.Footer.Background = props.TableOptions.FooterStyle.Background;
        }
        if (props.TableOptions.FooterStyle.fontSize) {
            FinalStyle.Footer.fontSize = props.TableOptions.FooterStyle.fontSize;
        }
        if (props.TableOptions.FooterStyle.fontFamily) {
            FinalStyle.Footer.fontFamily = props.TableOptions.FooterStyle.fontFamily;
        }

        if (props.TableOptions.FooterStyle.fontWeight) {
            FinalStyle.Footer.fontWeight = props.TableOptions.FooterStyle.fontWeight;
        }

        if (props.TableOptions.FooterStyle.color) {
            FinalStyle.Footer.color = props.TableOptions.FooterStyle.color;
        }
        if (props.TableOptions.FooterStyle.ActiveBackgroundSelected) {
            FinalStyle.Footer.ActiveBackgroundSelected = props.TableOptions.FooterStyle.ActiveBackgroundSelected;
        }
        if (props.TableOptions.FooterStyle.padding) {
            FinalStyle.Footer.padding = props.TableOptions.FooterStyle.padding;
        }
    }

    return {
        ExternalTableBorder: FinalStyle.ExternalTableBorder,
        ExternalTableShadow: FinalStyle.ExternalTableShadow,

        HeaderBottomBorder: FinalStyle.HeaderBottomBorder, // WHATEVER IS THE LAST HEADER ROW NEEDS TO USE THIS (SEARCH OR LATEST HEADER ROW)
        Body: {
            fontSize: FinalStyle.Body.fontSize,
            fontFamily: FinalStyle.Body.fontFamily,
            fontWeight: FinalStyle.Body.fontWeight,
            color: FinalStyle.Body.color,
            EmptyBackground: FinalStyle.Body.EmptyBackground,
            PrimaryRowBackground: FinalStyle.Body.PrimaryRowBackground,
            AlternateRowBackground: FinalStyle.Body.AlternateRowBackground,
        },
        Header: {
            fontSize: FinalStyle.Header.fontSize,
            fontFamily: FinalStyle.Header.fontFamily,
            fontWeight: FinalStyle.Header.fontWeight,
            color: FinalStyle.Header.color,
            HeaderRowBackground: FinalStyle.Header.HeaderRowBackground,
        },
        Footer: {
            Background: FinalStyle.Footer.Background,
            fontSize: FinalStyle.Footer.fontSize,
            fontFamily: FinalStyle.Footer.fontFamily,
            fontWeight: FinalStyle.Footer.fontWeight,
            color: FinalStyle.Footer.color,
            ActiveBackgroundSelected: FinalStyle.Footer.ActiveBackgroundSelected,
            padding: FinalStyle.Footer.padding,
        },
    };
};
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableContainer: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            position: "relative",
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",

            // borderRadius: "5px",

            // background: MainTableStyle(props).Footer.Background ? MainTableStyle(props).Footer.Background : FinalTheme.Footer.Background,
            borderRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            boxShadow: MainTableStyle(props).ExternalTableShadow ? MainTableStyle(props).ExternalTableShadow : FinalTheme.CommonStyle.ExternalTableShadow,
        };
    },
    TableToolbar: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "0 1 auto",
            boxSizing: "border-box",
            // border: "1px solid grey",
            borderRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
        };
    },

    Grid_Container: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }

        let BorderRadius;
        if (props.TableOptions.TopToolbar.Disabled === true) {
            BorderRadius = MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius;
        } else {
            BorderRadius = null;
        }
        return {
            flex: "1 1 auto",
            position: "relative",
            // display: "flex",
            // flexDirection: "column",
            // overflow: "hidden",
            boxSizing: "border-box",
            background: MainTableStyle(props).EmptyBackground ? MainTableStyle(props).EmptyBackground : FinalTheme.Body.EmptyBackground,
            // height: "100%",
            // border: MainTableStyle(props).ExternalTableBorder ? MainTableStyle(props).ExternalTableBorder : FinalTheme.CommonStyle.ExternalTableBorder,
            // borderRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            borderTopLeftRadius: BorderRadius,
            borderTopRightRadius: BorderRadius,
        };
    },
    Grid_Body: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        let BorderRadius;
        if (props.TableOptions.TopToolbar.Disabled === true) {
            BorderRadius = MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius;
        } else {
            BorderRadius = null;
        }
        return {
            // flex: "1 1 auto",
            position: "relative",
            overflow: "auto",
            // display: "flex",
            // flexDirection: "column",
            // overflow: "hidden",
            boxSizing: "border-box",
            // height: "100%",
            // border: MainTableStyle(props).ExternalTableBorder ? MainTableStyle(props).ExternalTableBorder : FinalTheme.CommonStyle.ExternalTableBorder,
            // border : "2px solid red"
            // borderRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            borderTopLeftRadius: BorderRadius,
            borderTopRightRadius: BorderRadius,
        };
    },
    // TablePager: {
    //     flex: "0 1 auto",
    //     display: "flex",
    //     boxSizing: "border-box",
    //     border: "1px solid grey",
    // 	padding : "5px 0px"
    // },
    TablePager: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "0 1 auto",
            position: "relative",
            display: "flex",
            zIndex: "11",
            boxSizing: "border-box",
            padding: MainTableStyle(props).Footer.padding ? MainTableStyle(props).Footer.padding : FinalTheme.Footer.padding,
            borderTop: MandatorySyncStyle(props).FooterborderTop ? MandatorySyncStyle(props).FooterborderTop : FinalTheme.CommonStyle.FooterborderTop,
            background: MainTableStyle(props).Footer.Background ? MainTableStyle(props).Footer.Background : FinalTheme.Footer.Background,
            fontSize: MainTableStyle(props).Footer.fontSize ? MainTableStyle(props).Footer.fontSize : FinalTheme.Footer.fontSize,
            fontWeight: MainTableStyle(props).Footer.fontWeight ? MainTableStyle(props).Footer.fontWeight : FinalTheme.Footer.fontWeight,
            fontFamily: MainTableStyle(props).Footer.fontFamily ? MainTableStyle(props).Footer.fontFamily : FinalTheme.Footer.fontFamily,
            color: MainTableStyle(props).Footer.color ? MainTableStyle(props).Footer.color : FinalTheme.Footer.color,
            borderBottomLeftRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            borderBottomRightRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
        };
    },
}));

/**
 * Component Description
 */

const TableContainer = React.memo(
    function TableContainer(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TableOptions, TotalRow, DevMode} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        const Ref_TableContainer = useRef(null);
        const Ref_TableToolbar = useRef(null);
        const Ref_TablePager = useRef(null);
        /****************************** REFS *********************/

        /****************************** CONSTS *********************/
        // const ParentContainerDimensions = useResizeObserver({TrackElementRef: Ref_TableContainer, TrackElementRefNode: "parentNode"});
        /****************************** CONSTS *********************/

        /****************************** STATE *********************/
        const [IsInitialized, setIsInitialized] = useState(false);
        const [Dimensions, setDimensions] = React.useState({width: "100%", height: "auto", tableHeight: null, tableWidth: null});

        const {TableDimensionsOptions} = useTableContext();

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        const CalculateTableDimensions = React.useCallback(() => {
            //NEED TO BE COMPLETELY REWORKED FOR FIT PARENT AND AUTO AND WIDTH AND HEIGHT
            //IF WE MANAGE TO ONLY RENDER ONCE (EXLUDING RESIZING THEN WE ARE GOOD IT ONLY HAPPEND ONCE)
            //THEN FOR RESIZE WE SHOULD PUT A TRIGER FUNCTION TO PREVENT RESIZING ALL THE TIME

            let TableElem = Ref_TableContainer.current;
            let TableDimensionsOptions = TableOptions.DimensionsOptions;
            let TableToolbarElem = Ref_TableToolbar.current;
            let TablePagerElem = Ref_TablePager.current;

            // console.log("table container ref", TableElem);
            // console.log("TableDimensionsOptions", TableDimensionsOptions);
            // console.log("TableToolbarElem", TableToolbarElem);
            // console.log("TablePagerElem", TablePagerElem);

            //We need to get the dimensions of the Toolbar and of the Pager to set the final table dimensions

            var TableContainerWidth, TableContainerHeight, TableBodyHeight;
            if (TableDimensionsOptions.FitParentContainer === true) {
                //We expand the table container to fit the parent container
                let ParentElem = TableElem.parentNode;
                var ToolbarHeight = 0,
                    PagerHeight = 0;
                if (TableToolbarElem) {
                    ToolbarHeight = TableToolbarElem.getBoundingClientRect().height;
                }
                if (TablePagerElem) {
                    PagerHeight = TablePagerElem.getBoundingClientRect().height;
                }

                if (ParentElem) {
                    //We extract the parent dimensions
                    let ParentPaddingVertical =
                        parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-top")) + parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-bottom"));
                    let ParentPaddingHorizontal =
                        parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-left")) + parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-right"));
                    let ParentWidth = ParentElem.getBoundingClientRect().width - ParentPaddingHorizontal;
                    let ParentHeight = ParentElem.getBoundingClientRect().height - ParentPaddingVertical;

                    TableContainerWidth = ParentWidth;
                    TableContainerHeight = ParentHeight;
                    TableBodyHeight = TableContainerHeight - ToolbarHeight - PagerHeight; //10 is because of the tablecontainer padding
                    // console.log("TableContainerWidth", TableContainerWidth);
                    // console.log("TableContainerHeight", TableContainerHeight);
                    // console.log("ToolbarHeight", ToolbarHeight);
                    // console.log("PagerHeight", PagerHeight);
                } else {
                    console.log("error, no parent");
                }
            }

            if (TableElem) {
                // console.log("TableContainer - Dimensions : ", TableDimensions);
                //Setting the fake element to width 100px and height auto for the calculation
                // setDimensions({width: "100px", height: "auto"});
                // //Calculating all elements
                // var TopbarHeight, height, Tableheight;

                // if (TableDimensionsOptions.FitParentContainer === true) {
                //     let ParentElem = Elem.parentNode;
                //     console.log("parent", ParentElem);
                //     var ParentElemPadding;
                //     if (ParentElem) {
                //         ParentElemPadding =
                //             parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-top")) + parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-bottom"));

                //         height = ParentElem.getBoundingClientRect().height - ParentElemPadding;
                //         Tableheight = height - TopbarHeight - 2 * parseFloat(TableDimensionsOptions.padding);
                //     }
                // } else {
                //     if (TableDimensionsOptions.height) {
                //         height = parseInt(TableDimensionsOptions.height);
                //         if (TableDimensionsOptions.padding) {
                //             Tableheight = height - 2 * parseFloat(TableDimensionsOptions.padding) + "px";
                //         } else {
                //             Tableheight = height + "px";
                //         }
                //     }
                // }

                var width, height, tableWidth, tableHeight;
                if (TableDimensionsOptions.width) {
                    width = parseInt(TableDimensionsOptions.width);
                    tableWidth = width - 10;
                } else {
                    width = TableElem.getBoundingClientRect().width + "px";
                }
                if (TableDimensionsOptions.height) {
                    height = parseInt(TableDimensionsOptions.height);
                    tableHeight = height - 10 - 40;
                }
            }

            setDimensions({width: TableContainerWidth + "px", height: TableContainerHeight + "px", tableWidth: tableWidth + "px", tableHeight: TableBodyHeight + "px"});
            // GenerateTableDimensions({width: width, height: Tableheight});
            setIsInitialized(true);
        }, [TableDimensionsOptions]);

        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        //Calculate size when mounting
        useEffect(() => {
            //Give it a little time to render the dom and calculate correctly
            console.log("TableContainer Final Options", TableOptions);
            CalculateTableDimensions();
            // setTimeout(() => {
            //     CalculateTableDimensions();
            // }, 50);
        }, [Ref_TablePager.current, Ref_TableToolbar.current]);

        //Add a Window Resize Event Listener
        // useEffect(() => {
        //     window.addEventListener("resize", CalculateTableDimensions);
        //     return function cleanup() {
        //         window.removeEventListener("resize", CalculateTableDimensions);
        //     };
        // }, []);

        // useEffect(() => {

        //     // GenerateTableStyle({MandatorySyncStyle: MandatorySyncStyle, MainTableStyle: MainTableStyle});
        // }, []);

        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        //ADD YOUR FUNCTIONS HERE

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        // var PagerComponent;
        // if (DisplayPager === true) {
        //     PagerComponent = (
        //         <div ref={Ref_TablePager} className={classes.TablePager}>
        //             <TablePager MandatorySyncStyle={MandatorySyncStyle(props)} MainTableStyle={MainTableStyle(props)} TableOptions={TableOptions} />
        //         </div>
        //     );
        // } else {
        //     PagerComponent = null;
        // }

        let FooterComponent = null;
        if (TotalRow.Show === true) {
            FooterComponent = <TableFooter TotalRow={TotalRow} />;
        } else {
            FooterComponent = null;
        }
        let DevModeComponent = null;
        if (DevMode === true) {
            DevModeComponent = <TableDevMode />;
        } else {
            DevModeComponent = null;
        }
        let TopToolbarComponent = null;
        if (TableOptions.TopToolbar.Disabled === true) {
            TopToolbarComponent = null;
        } else {
            TopToolbarComponent = (
                <div ref={Ref_TableToolbar} className={classes.TableToolbar}>
                    <TableToolbar TableOptions={TableOptions} />
                </div>
            );
        }

        if (IsInitialized === true) {
            return (
                <div
                    ref={Ref_TableContainer}
                    className={classes.TableContainer}
                    style={{
                        ...{width: Dimensions.width, maxWidth: Dimensions.maxWidth, position: "relative", zIndex: 1, height: Dimensions.height},
                    }}
                >
                    {DevModeComponent}

                    {TopToolbarComponent}

                    <div className={classes.Grid_Container}>
                        <div className={classes.Grid_Body} style={{height: Dimensions.tableHeight}}>
                            <TableHeader MandatorySyncStyle={MandatorySyncStyle(props)} MainTableStyle={MainTableStyle(props)} TableOptions={TableOptions} />
                            <TableBody MandatorySyncStyle={MandatorySyncStyle(props)} MainTableStyle={MainTableStyle(props)} TableOptions={TableOptions} />
                            {FooterComponent}
                        </div>
                    </div>
                    <div ref={Ref_TablePager} className={classes.TablePager}>
                        <TablePager MandatorySyncStyle={MandatorySyncStyle(props)} MainTableStyle={MainTableStyle(props)} TableOptions={TableOptions} />
                    </div>
                </div>
            );
        } else {
            //Used to calculate all the dimensions
            return (
                <div
                    ref={Ref_TableContainer}
                    className={classes.TableContainer}
                    style={{
                        ...{width: "100%", maxWidth: "100%", position: "relative", zIndex: 1, height: "100%"},
                    }}
                >
                    {TopToolbarComponent}

                    <div className={classes.Grid_Container}></div>
                    <div ref={Ref_TablePager} className={classes.TablePager}>
                        <TablePager MandatorySyncStyle={MandatorySyncStyle(props)} MainTableStyle={MainTableStyle(props)} TableOptions={TableOptions} />
                    </div>
                </div>
            );
        }

        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableContainer.defaultProps = {
    MyProp: null,
};

TableContainer.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableContainer;
// <div className={classes.Grid_Body} style={{width: Dimensions.tableWidth, height: Dimensions.tableHeight}}></div>
