import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";

import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";

import TabNavContent from "@artibulles-cis/react/TabNavContent";
import APICallExternal from "../../../../../../../../../artibulles-cis/utils/APICallExternal";
import AGGridTable from "../../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {PDF} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        // maxWidth: "1200px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "400px",
        // height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    ClassExtendButton: {
        padding: "0px",
        margin: "0px 0px",
    },
    ButtonBar: {
        display: "flex",
        padding: "0px 5px 0px 0px",
        // height: "30px",
    },
    ButtonBar_Left: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    ButtonBar_Right: {
        flex: "1 1 auto",
        maxWidth: "220px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },

    Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
        fontSize: "0.8rem",
        boxSizing: "border-box",
    },
    Line_Title: {
        flex: "0 0 auto",
        width: "140px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
        boxSizing: "border-box",
    },
    Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
}));

const Component = React.memo(function Component(props) {
    const {TransactionID} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Init, setInit] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [TableType, setTableType] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    // console.log("Reconciliation", Data);

                    if (Init === false) {
                        if (Data.Invoices && Array.isArray(Data.Invoices) && Data.Invoices.length > 0) {
                            if (Data.Invoices[0].SelfTransactions) {
                                setTableType("BankTransactions");
                            }
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "Reconcile") {
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;
        if (TableType === "BankTransactions") {
            const PaymentCell = {
                display: "flex",
                padding: "0px 5px",
            };

            const AccountNumberRendering = (params) => {
                let RowData = params.data;
                console.log("RowData", RowData);
                let counterparty_bankAccount_number = RowData.CompanyAccountTransactions.counterparty_bankAccount_number;
                let location = RowData.location;
                let Output;
                if (counterparty_bankAccount_number) {
                    Output = counterparty_bankAccount_number;
                } else {
                    Output = location;
                }
                return <React.Fragment>{Output}</React.Fragment>;
            };
            const AccountOwnerRendering = (params) => {
                let RowData = params.data;
                let counterparty_bankAccount_name = RowData.CompanyAccountTransactions.counterparty_bankAccount_name;
                let merchant = RowData.merchant;
                let Output;
                if (counterparty_bankAccount_name) {
                    Output = counterparty_bankAccount_name;
                } else {
                    Output = merchant;
                }
                return <React.Fragment>{Output}</React.Fragment>;
            };

            const TableColumns = [
                {
                    field: "CompanyAccountTransactions.calc_RefTransaction",
                    headerName: "Transaction Id",
                    headerClass: "ag-CenterHeader",
                    flex: 1,
                    minWidth: 170,
                    // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    sort: "desc",
                    sortIndex: 1,
                    sortingOrder: ["asc", "desc"],
                    // Type: "Date",
                },
                {
                    field: "CompanyAccountTransactions.transaction_date_Iso",
                    headerName: "Date",
                    headerClass: "ag-CenterHeader",
                    width: 130,
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    sortingOrder: ["asc", "desc"],
                    // sortIndex: 1,
                    // sort: "desc",
                    Type: "Date",
                },
                {
                    field: "CompanyAccountTransactions.ReconciledDateIso",
                    headerName: "Reconciliation",
                    headerClass: "ag-CenterHeader",
                    width: 150,
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    // sort: "desc",
                    // sortIndex: 1,
                    sortingOrder: ["asc", "desc"],
                    Type: "Date",
                },
                {
                    field: "CompanyAccountTransactions.banking_amount_transaction",
                    headerName: "Amount",
                    headerClass: "ag-CenterHeader",
                    width: 150,
                    filter: "agNumberColumnFilter",
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    Type: "Currency",
                },
                {
                    field: "CompanyAccountTransactions.communication",
                    //
                    headerName: "Communication",
                    headerClass: "ag-CenterHeader",
                    flex: 2,
                    filter: true,
                    sortingOrder: ["asc", "desc"],
                    // cellRenderering: StatusRendering,
                    // cellStyle: PaymentCell,
                },

                {
                    field: "CompanyAccountTransactions.counterparty_bankAccount_number",
                    //
                    headerName: "Account Number",
                    headerClass: "ag-CenterHeader",
                    width: 180,
                    filter: true,
                    sortingOrder: ["asc", "desc"],
                    cellRenderering: AccountNumberRendering,
                    cellStyle: PaymentCell,
                },
                {
                    field: "CompanyAccountTransactions.counterparty_bankAccount_name",
                    //
                    headerName: "Account Owner",
                    headerClass: "ag-CenterHeader",
                    width: 180,
                    filter: true,
                    sortingOrder: ["asc", "desc"],
                    cellRenderering: AccountOwnerRendering,
                    cellStyle: PaymentCell,
                },
                {
                    field: "CompanyAccountTransactions.transaction_source",
                    headerName: "Source",
                    headerClass: "ag-CenterHeader",
                    width: 150,
                    filter: true,
                    // Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
                    // sort: "asc",
                    // sortIndex: 2,
                    sortingOrder: ["asc", "desc"],
                },
            ];

            const TableOptions = {
                // Selection: {
                //     // Multiple: true,
                //     WithCheckBox: true,
                // },
                Resizable: true,
                Sortable: true,
                Filterable: true,
                SupressMenu: true,
                WrapText: true,
                WrapHeader: false,
                HeaderHeight: 30,
                RowHeight: 24,
                RowFontSize: "13px",
                HeaderFontSize: "14px",
                StatusBar: true,
            };
            // const TotalRow = {
            //     Position: "Bottom",
            //     Style: null,
            // };

            let FinalTableComponent = null;
            if (Ref_LocaleData.current) {
                FinalTableComponent = (
                    <AGGridTable
                        TableName="Reconciliation"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="Invoices"
                        // RowActionButtons={TableRowButtons}
                        // ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            }
            setTimeout(() => {
                setTableComponent(FinalTableComponent);
            }, 200);
        } else {
            const HandleViewPdf = (arg) => {
                window.open(arg);
            };
            const PDFRendering = (params) => {
                let value = params.data.SaleInvoices.Invoices_File_Path;
                if (value && Array.isArray(value) && value.length > 0) {
                    let CellRenderComp = [];
                    value.forEach((elem) => {
                        let IconElem = (
                            <div onClick={() => HandleViewPdf(elem.serverPath)}>
                                <PDF SVGFillColor="white" />
                            </div>
                        );
                        CellRenderComp.push(IconElem);
                    });

                    return CellRenderComp;
                } else {
                    return null;
                }
            };

            const PaymentCell = {
                display: "flex",
                padding: "0px 5px",
            };
            const StatusRendering = (params) => {
                let RowData = params.data.SaleInvoices;

                let Status = RowData.status_invoice;

                if (Status === "Draft") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                                    Draft
                                </div>
                            </div>
                        </div>
                    );
                } else if (Status === "Posted") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                                    Posted
                                </div>
                            </div>
                        </div>
                    );
                } else if (Status === "Reconciled") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Reconciled
                                </div>
                            </div>
                        </div>
                    );
                } else {
                    return <React.Fragment />;
                }
            };
            const PaymentStatusRendering = (params) => {
                let RowData = params.data.SaleInvoices;

                let CalcIsPaid = RowData.CalcIsPaid;
                let ManualBankPaymentActionsStatus = RowData.ManualBankPaymentActionsStatus;
                let status_invoice = RowData.status_invoice;
                let PaymentMethod = RowData.PaymentMethod;
                if (status_invoice === "Draft") {
                    return <React.Fragment />;
                } else {
                    if (CalcIsPaid === true) {
                        //We check the details of the Manual Actions
                        if (ManualBankPaymentActionsStatus === "PaidExecuted") {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                            Paid Executed
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        } else if (ManualBankPaymentActionsStatus === "PaidPendingExecution") {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(108,145,0)"}}>
                                            Paid Pending
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        } else {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "green"}}>
                                            Paid
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        }
                    } else {
                        if (PaymentMethod === "Bank Account Automatic") {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                            Paid Auto Bank
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        } else if (PaymentMethod === "Bank Account Manual") {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                            Paid Man Bank
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        } else if (PaymentMethod === "Credit Card Automatic") {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                            Paid Auto CC
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        } else if (PaymentMethod === "Credit Card Manual") {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                            Paid Man CC
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        } else {
                            return (
                                <div className={classes.PaymentStatus}>
                                    <div className={classes.PaymentStatus_BadgeWrapper}>
                                        <div className={classes.PaymentStatus_Badge} style={{background: "orange"}}>
                                            {PaymentMethod}
                                        </div>
                                    </div>
                                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                                </div>
                            );
                        }
                    }
                }
            };

            const AccountantStatusRendering = (params) => {
                let RowData = params.data.SaleInvoices;
                let AccountantStatus = RowData.AccountantStatus;

                if (AccountantStatus) {
                    if (AccountantStatus.Uploaded === true) {
                        return (
                            <div className={classes.PaymentStatus}>
                                <div className={classes.PaymentStatus_BadgeWrapper}>
                                    <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                        Uploaded
                                    </div>
                                </div>
                                <div className={classes.PaymentStatus_WarningWrapper}></div>
                            </div>
                        );
                    } else if (AccountantStatus.UploadedMethod === "Email To Confirm") {
                        return (
                            <div className={classes.PaymentStatus}>
                                <div className={classes.PaymentStatus_BadgeWrapper}>
                                    <div className={classes.PaymentStatus_Badge} style={{background: "orange", color: "black"}}>
                                        Skwarel Upload
                                    </div>
                                </div>
                                <div className={classes.PaymentStatus_WarningWrapper}></div>
                            </div>
                        );
                    } else {
                        return (
                            <div className={classes.PaymentStatus}>
                                <div className={classes.PaymentStatus_BadgeWrapper}>
                                    <div className={classes.PaymentStatus_Badge} style={{background: "red", color: "black"}}>
                                        Not sent
                                    </div>
                                </div>
                                <div className={classes.PaymentStatus_WarningWrapper}></div>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "red"}}>
                                    Not sent
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                }
            };

            const TableColumns = [
                {
                    field: "SaleInvoices.InvoiceIssueDateIso",
                    headerName: "Invoice Date",
                    headerClass: "ag-CenterHeader",
                    width: 150,
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    sort: "desc",
                    sortIndex: 1,
                    sortingOrder: ["asc", "desc"],
                    Type: "Date",
                    // checkboxSelection: true,
                },
                {
                    field: "SaleInvoices.Calc_RefInvoice",
                    headerName: "Ref",
                    headerClass: "ag-CenterHeader",
                    width: 160,
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    sort: "asc",
                    sortIndex: 2,
                    sortingOrder: ["asc", "desc"],
                },

                {
                    field: "SaleInvoices.CalcTotalVATIncluded",
                    headerName: "Amount (VAT)",
                    headerClass: "ag-CenterHeader",
                    width: 140,
                    filter: "agNumberColumnFilter",
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    Type: "Currency",
                    // TotalRow: {
                    //     Show: true,
                    //     // Rendering: (params) => {
                    //     //     return <div>Coucou</div>;
                    //     // },
                    // },
                },
                {
                    field: "SaleInvoices.status_invoice",
                    //
                    headerName: "Invoice Status",
                    headerClass: "ag-CenterHeader",
                    width: 130,
                    filter: true,
                    sortingOrder: ["asc", "desc"],
                    cellRenderering: StatusRendering,
                },

                {
                    field: "SaleInvoices.PurchaseInvoicePaymentActionNeeded",
                    //
                    headerName: "Payment Status",
                    headerClass: "ag-CenterHeader",
                    width: 120,
                    filter: true,
                    sortingOrder: ["asc", "desc"],
                    cellRenderering: PaymentStatusRendering,
                    cellStyle: PaymentCell,
                },
                {
                    field: "SaleInvoices.AccountantStatus",
                    //
                    headerName: "Accountant Status",
                    headerClass: "ag-CenterHeader",
                    width: 130,
                    filter: true,
                    sortingOrder: ["asc", "desc"],
                    cellRenderering: AccountantStatusRendering,
                    cellStyle: PaymentCell,
                },
                {
                    field: "SaleInvoices.InvoicePaymentDueDateIso",
                    headerName: "Due Date",
                    headerClass: "ag-CenterHeader",
                    width: 150,
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    sortingOrder: ["asc", "desc"],
                    Type: "Date",
                },
                {
                    headerName: "Customer",
                    headerClass: "ag-CenterHeader",
                    width: 300,
                    filter: true,
                    Lookup: {LookupField: "SaleInvoices.ref_Customer", Source: "Customers", ReturnField: "company"},
                    sort: "asc",
                    sortIndex: 2,
                    sortingOrder: ["asc", "desc"],
                },

                {
                    field: "SaleInvoices.Description",
                    headerName: "Description",
                    headerClass: "ag-CenterHeader",
                    flex: 1,
                    filter: true,
                    autoHeight: true,
                    wrapText: true,
                    minWidth: 200,
                },
                // {
                //     field: "InvoicePaymentExpectedCommunication",
                //     headerName: "Communication",
                //     headerClass: "ag-CenterHeader",
                //     width: 300,
                //     filter: true,
                // },

                {
                    field: "Invoices_File_Path",
                    headerName: "Docs",
                    headerClass: "ag-CenterHeader",
                    width: 90,
                    filter: false,
                    cellRenderering: PDFRendering,
                    cellStyle: PDFCell,
                },

                {
                    field: "CalcTotalVATExcluded",
                    headerName: "Tot VAT Exc.",
                    headerClass: "ag-CenterHeader",
                    width: 150,
                    filter: "agNumberColumnFilter",
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    Type: "Currency",
                    // TotalRow: {
                    //     Show: true,
                    //     // Rendering: (params) => {
                    //     //     return <div>Coucou</div>;
                    //     // },
                    // },
                },
                {
                    field: "CalcTotalVATAmount",
                    headerName: "Tot VAT",
                    headerClass: "ag-CenterHeader",
                    width: 130,
                    filter: "agNumberColumnFilter",
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    Type: "Currency",
                    // TotalRow: {
                    //     Show: true,
                    //     // Rendering: (params) => {
                    //     //     return <div>Coucou</div>;
                    //     // },
                    // },
                },
                {
                    field: "updatedAt",
                    headerName: "Last updated",
                    headerClass: "ag-CenterHeader",
                    width: 130,
                    filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                    Type: "Date",
                    sortingOrder: ["asc", "desc"],
                },
            ];

            const TableOptions = {
                // Selection: {
                //     Multiple: true,
                //     // GroupOnly: false,
                //     WithCheckBox: true,
                //     OnSelectionChange: HandleRowSelectChange,
                // },

                Resizable: true,
                Sortable: true,
                Filterable: true,
                WrapText: true,
                WrapHeader: true,
                SideBar: "filters",
            };
            if (Ref_LocaleData.current) {
                FinalTableComponent = (
                    <AGGridTable
                        TableName="Reconciliation"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="Invoices"
                        // RowActionButtons={TableRowButtons}
                        // ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            }

            setTimeout(() => {
                setTableComponent(FinalTableComponent);
            }, 200);
        }
    }, [Ref_LocaleData.current]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/companyaccount/reconciliation/bytransaction/${TransactionID}`, type: "get"};
        setLoading(true);
        setAPICallType(null);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
            <LocalCircularLoader />

            <div className={classes.TableWrapper}>{TableComponent}</div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
