import {useEffect} from "react";


/**
 * Add Event Listener to detect if the user is closing a browser tab or the browser
 */
const useDetectNavigateAway = (activate) => {
  const handleWindowClose = event => {
    event.preventDefault();
    return (event.returnValue = "You have unsaved data. Are you sure you want to leave ?");
  };

  //Event Listener for Reload Browser actions (close browser, close tab, refresh page)
  useEffect(() => {
    if (activate){
      window.addEventListener("beforeunload", handleWindowClose);

      // Remove event listener on cleanup
      return () => {
        window.removeEventListener("beforeunload", handleWindowClose);
      };
    }
  }, [activate]);
};

export default useDetectNavigateAway;
