import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import Button from "../Button/Button";
import useTableContext from "../utils/context/tableContext/useTableContext";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    DevMode: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        border: "2px solid rgb(211, 78, 100)",
        borderRadius: "5px",
        padding: "10px 10px 10px 10px",
        margin: "10px 0px",
        background: "white",
    },
    DevModeButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 0 auto",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "0px 10px 0px 10px",
    },
    DevModeData: {
        flex: "0 1 auto",
        maxHeight: "300px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "10px 10px 10px 10px",
        boxSizing: "border-box",
    },
    DevModeDataIndividual: {
        flex: "0 1 auto",
        maxHeight: "150px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "0px",

        boxSizing: "border-box",
    },
}));

/**
 * Component Description
 */

const TableDevMode = React.memo(
    function TableDevMode(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {MyProp} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** STATE *********************/
        const {TableOriginalData} = useTableContext();
        const [DevCheckValues, setDevCheckValues] = useState({content: "", Show: false}); //ONLY USED FOR DEVELOPMENT CHECKS

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        //WHEN COMPONENT LOADS
        // useEffect(() => {
        //     if (TableOriginalData) {
        //         setTableData(TableOriginalData);
        //     }
        // }, [TableOriginalData]);
        // //WHEN PROPS ARE CHANGING
        // useEffect(() => {
        //     //Execute your code here
        // }, [condition]);
        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        //ADD YOUR FUNCTIONS HERE

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/

        return (
            <div className={classes.DevMode}>
                <div className={classes.DevModeButtonBar}>
                    <Button
                        type="button"
                        Width="200px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => {
                            setDevCheckValues((prev) => {
                                return {...prev, ...{Show: true}};
                            });
                        }}
                    >
                        DEV MODE API SHOW RESPONSE
                    </Button>
                    <Button
                        type="button"
                        Width="200px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => {
                            setDevCheckValues((prev) => {
                                return {...prev, ...{Show: false}};
                            });
                        }}
                    >
                        DEV MODE API HIDE RESPONSE
                    </Button>
                    <Button
                        type="button"
                        Width="200px"
                        Height="50px"
                        Background="rgb(211, 78, 100)"
                        ClassExtendButton={classes.ClassExtendButton}
                        style={{margin: "0px 10px 0px 10px"}}
                        onClick={() => {
                            console.log("Table - ApiResponse : ", TableOriginalData);
                        }}
                    >
                        LOG DATA IN CONSOLE
                    </Button>
                </div>
                <div style={{display: DevCheckValues.Show === true ? "block" : "none"}}>
                    <h2>API SERVER RESPONSE</h2>

                    <pre className={classes.DevModeData}>{JSON.stringify(TableOriginalData, 0, 3)}</pre>
                </div>
            </div>
        );
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableDevMode.defaultProps = {
    MyProp: null,
};

TableDevMode.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableDevMode;
