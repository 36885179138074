import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";


import TasksViewAllTasksTable from "./TasksViewAllTasksTable";
import TasksViewAllTasksTableToday from "./TasksViewAllTasksTableToday";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavLink: {
        borderRight: "1px solid rgb(220 220 220)",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNav
            MainSlug={`/tasktimemanagement/tasks/tables`}
            DefaultTab={`/tasktimemanagement/tasks/tables/today`}
            ClassExtendTabNavLink={classes.ClassExtendTabNavLink}
			TabNavMenuHeight="30px"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
				
				{
                    TabMenu: (
						
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Today</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/today`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTableToday />,
                },
				{
                    TabMenu: (
						
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Due</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/due`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTable />,
                },
                
                
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Weekly</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/weekly`,
					exact : true,
                    ShowOnlyIfActive: false,
                    TabContent: <TasksViewAllTasksTable />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Monthly</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/monthly`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTable />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>All Tasks</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/all`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTable />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Work</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/monthly`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTable />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Perso</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/monthly`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTable />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Completed</div>
                        </div>
                    ),
                    Slug: `/tasktimemanagement/tasks/tables/monthly`,
                    ShowOnlyIfActive: false,
					exact : true,
                    TabContent: <TasksViewAllTasksTable />,
                }
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
