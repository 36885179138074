import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Search, CloseIcon} from "@artibulles-cis/react-icons";
import FormAnimatedWrapper from "../FormAnimatedWrapper/FormAnimatedWrapper";
import FormTextAreaComponent from "../FormTextAreaComponent/FormTextAreaComponent";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

const Lockedstyles = {
    FormElement: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        width: "100%",
    },
    FormInput: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
    },
};

const styles = createUseStyles((theme) => ({
    FormElement: theme.FormTextInput.FormElement,
    FormInput: theme.FormTextInput.FormInput,
    InputWithToolBar: {
        display: "flex",
    },
    InputToolbar: {
        display: "flex",
        alignItems: "center",
    },
    SearchIcon: {
        margin: "0px 5px 0px 5px",
        // borderLeft :"1px solid grey",
        borderRight: "1px solid grey",
        boxSizing: "border-box",
        padding: "0px 10px 0px 10px",
        pointer: "cursor",
    },
    Input: {
        display: "block",
        // borderRadius: "3px",
        border: "0px ",
        backgroundColor: "white!important",
        boxSizing: "border-box",

        pointerEvents: "all",
        //Keep it here to set the full component height to harmonize with other inputs
        // minHeight: "25px",
        // lineHeight: "25px",
        // height: "30px"
        webkitAppearance: "none ",
        outline: "none ",
        // background: "white!important",

        "&:error": {
            webkitAppearance: "none ",
            outline: "none ",
            border: "none ",
            boxShadow: "none",
        },
        "&:invalid": {
            outline: "none ",
            border: "none ",
            boxShadow: "none ",
        },
        "&:focus": {
            webkitAppearance: "none ",
            outline: "none ",
            border: "none ",
            boxShadow: "none",
        },
        "&:active": {
            webkitAppearance: "none ",
            outline: "none ",
            border: "none ",
            boxShadow: "none",
        },
    },

    BottomMessageContent: theme.FormTextInput.BottomMessageContent,

    BottomMessageContentError: (props) => {
        let ErrorStyleFinal = props.ErrorStyle ? (props.ErrorStyle.color ? props.ErrorStyle.color : null) : null;
        let ColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;

        if (ErrorStyleFinal) {
            return {color: ErrorStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentError;
        }
    },
    BottomMessageContentHelper: (props) => {
        let ColorFinal = props.HelperStyle ? (props.HelperStyle.color ? props.HelperStyle.color : null) : null;

        if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentHelper;
        }
    },
    BottomMessageContentHidden: {
        visibility: "hidden",
    },
    EditOverlayComp_EditIcon: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

/**
 * Create a NavTopBar Wrapper that goes into a NavHorizontalBar Component
 */
const FormTextInput = React.forwardRef(function FormTextInput(props, ref) {
    const {
        variant,
        meta: {touched, invalid, error, dirty, pristine, modified},
        NoMessage,
        initialvalue,
        curvalue,
        name,
        label,
        placeHolder,
        HelperMessage,
        required,
        InputType,
        multiline,
        RowsToDisplay,
        MaxRows,
        readOnly,
        disabled,
        LabelScale,
        StyleBorderErrorFocusTrue,
        Colors,
        InputFontProp,
        LabelStyle,
        InputStyle,
        BorderStyle,
        HelperStyle,
        ErrorStyle,
        inputVerticalPadding,
        ShareInputValue,
        WithSearchIcon,
        OnSearchClick,
        Clearable,
        ControlledEditMode,
		ControlledEditModeActive,
		autocomplete,
        ...other
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    const InputRef = React.useRef(null);
    const TextAreaInputRef = React.useRef(null);
    const [InputValue, setInputValue] = React.useState(initialvalue);
    const [IsFocus, setIsFocus] = React.useState(false);
    const [HasValue, setHasValue] = React.useState(false);
    const [HasError, setHasError] = React.useState(false);
    const [ErrorMessage, setErrorMessage] = React.useState(null);

    //Adding the initial value
    React.useEffect(() => {
        if (initialvalue) {
            setInputValue(initialvalue);
            setHasValue(true);
        } else {
            setInputValue(null);
        }
    }, [initialvalue]);

    React.useEffect(() => {
        if (curvalue === undefined) {
            //skip undefined to prevent bugs
        } else {
            if (curvalue === null) {
                setInputValue("");
                setHasValue(false);
            } else {
                setInputValue(curvalue);
                setHasValue(true);
            }
        }
    }, [curvalue, InputRef]);

    //Changing Error state based on props passed by the form values
    React.useEffect(() => {
        if (readOnly === true || disabled === true) {
            //Deactivate if ReadOnly or Disabled
        } else {
            if (!props.meta) {
                setHasError(false);
                setErrorMessage(null);
            } else {
                if (pristine === true && modified === false) {
                    //Initial state -> Ignore errors

                    setHasError(false);
                    setErrorMessage(null);
                } else {
                    // console.log("Normal State",invalid,error)
                    if (invalid === true) {
                        if (dirty === true) {
                            setHasError(true);
                            setErrorMessage(error);
                        } else {
                            setHasError(true);
                            setErrorMessage(error);
                            //   setHasError(false);
                            //   setErrorMessage(null);
                        }
                    } else {
                        setHasError(false);
                        setErrorMessage(null);
                    }
                }
            }
        }
    }, [touched, invalid, error, dirty, pristine, modified, disabled, props.meta, readOnly]);

    //Prevent Entering Letters if field type is number
    const InputKeyPressed = (event) => {
        var charCode = event.which ? event.which : event.keyCode;
        if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };

    React.useEffect(() => {
        var InpRef = InputRef;
        if (readOnly === true || disabled === true) {
            //Deactivate if ReadOnly or Disabled
        } else if (InputType === "number") {
            if (InpRef && InpRef.current) {
                InpRef.current.addEventListener("keypress", InputKeyPressed);
                return function cleanup() {
                    InpRef.current.removeEventListener("keypress", InputKeyPressed);
                };
            }
        }
    }, [InputRef, readOnly, InputType, disabled]);
    //Setting the focus status to move the label
    const InputOnFocus = () => {
        if (readOnly === true || disabled === true) {
            //Deactivate if ReadOnly or Disabled
        } else {
            setIsFocus(true);
        }
    };

    const InputOnBlur = (event) => {
        //When leaving the input -> Changing the label position based on the presence of a value
        if (readOnly === true || disabled === true) {
            //Deactivate if ReadOnly or Disabled
        } else {
            let value = event.target.value;

            if (value) {
                setHasValue(true);
            } else {
                setHasValue(false);
            }
            setIsFocus(false);
        }
    };

    const InputOnChange = (dispatcher) => {
        setInputValue(dispatcher.target.value);
        setHasValue(true);
        if (ShareInputValue) {
            return ShareInputValue(dispatcher.target.value);
        }
    };
    const ClickSearch = () => {
        if (OnSearchClick) {
            OnSearchClick(InputRef.current.value);
        }
    };
    const Reset = () => {
        // console.log("reset")
        // InputRef.current.value = "";
        setInputValue("");
        if (ShareInputValue) {
            return ShareInputValue("");
        }
	};
	

    var BottomMessageClass = classes.BottomMessageContent;
    if (HasError) {
        BottomMessageClass = clsx(BottomMessageClass, classes.BottomMessageContentError);
    } else if (HelperMessage) {
        BottomMessageClass = clsx(BottomMessageClass, classes.BottomMessageContentHelper);
    } else {
        BottomMessageClass = clsx(BottomMessageClass, classes.BottomMessageContentHidden);
    }
    const BottomMessageContent = () => {
        if (HasError) {
            return ErrorMessage;
        } else if (HelperMessage) {
            return HelperMessage;
        } else {
            return "No Message";
        }
    };

    var RightToolbar;

    const InputComponent = (FieldSetProps) => {
        const {InputClass, InputMarginBottom, FinalInputPadding} = FieldSetProps;
        if (WithSearchIcon === true && Clearable === true) {
            RightToolbar = (
                <div className={classes.InputToolbar} style={{marginBottom: InputMarginBottom}}>
                    <div className={classes.SearchIcon} onClick={InputValue ? ClickSearch : null}>
                        <Search IconSize="20" SVGFillColor="white" SVGStrokeColor="grey" SVGFillHoverColor="white" SVGStrokeWidth="10" />
                    </div>
                    <div style={{margin: "0px 5px 0px 5px"}} onClick={Reset}>
                        <CloseIcon IconSize="20" FrameFillColor="" FrameStrokeColor="" InnerPadding="35" FrameStrokeWidth="10" SVGStrokeWidth="10" SVGFillColor="white" SVGStrokeColor="grey" />
                    </div>
                </div>
            );
		}
		var FinalDisabled = false;
            if (disabled === true) {
                FinalDisabled = true;
            } else {
                if (ControlledEditMode === true) {
                    FinalDisabled = true;
                }
            }
        if (multiline === true) {
            return (
                <FormTextAreaComponent
                    InputRef={InputRef}
                    TextAreaInputRef={TextAreaInputRef}
                    currentValue={InputValue}
                    autoComplete="off"
                    placeHolder={placeHolder}
                    InputClass={InputClass}
                    disabled={disabled}
                    readOnly={readOnly}
                    RowsToDisplay={RowsToDisplay}
                    MaxRows={MaxRows}
                    style={{
                        resize: "none",
                        padding: FinalInputPadding,
                        marginBottom: InputMarginBottom,
                        fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                        fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                        fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
                        width: "100%",
                    }}
                    name={name}
                    InputOnBlur={InputOnBlur}
                    InputOnFocus={InputOnFocus}
                    InputOnChange={InputOnChange}
                />
            );
        } else {
            
           

            return (
                <div className={classes.InputWithToolBar} >
                    <input
                        ref={InputRef}
						autoComplete={autocomplete}
                        placeholder={placeHolder}
                        type={InputType}
                        className={InputClass}
                        disabled={FinalDisabled}
                        readOnly={readOnly}
                        value={InputValue === null || InputValue === undefined ? "" : InputValue}
                        style={{
                            padding: FinalInputPadding,
                            marginBottom: InputMarginBottom,
                            fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                            fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                            fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
							width: "100%",
							
							
							
                        }}
                        name={name}
                        // onChange={props.input ? props.input.onChange : null}
                        onBlur={InputOnBlur}
                        onFocus={InputOnFocus}
                        // onTouchStart={InputOntouchStart}
                        // onTouchEnd={InputOnTouchEnd}
                        onChange={InputOnChange}
                        // onInput={InputOnInput}
                    />
                    {RightToolbar}
                </div>
            );
        }
    };
    var BottomMessageComponent;
    if (NoMessage === false) {
        BottomMessageComponent = (
            <div className={BottomMessageClass} style={HelperStyle ? HelperStyle : null}>
                {BottomMessageContent()}
            </div>
        );
    }
    return (
		<div className={classes.FormElement} style={{...Lockedstyles.FormElement}}>
		
            <div className={classes.FormInput} style={{...Lockedstyles.FormInput}}>
                <FormAnimatedWrapper
                    label={label}
                    InputRef={multiline ? TextAreaInputRef : InputRef}
					IsFocus={IsFocus}
					multiline={multiline}
                    HasValue={HasValue}
                    LabelScale={LabelScale}
                    HasError={HasError}
                    variant={variant}
                    placeHolder={placeHolder}
                    disabled={disabled}
                    required={required}
                    StyleBorderErrorFocusTrue={StyleBorderErrorFocusTrue}
                    Colors={Colors}
                    InputFontProp={InputFontProp}
                    LabelStyle={LabelStyle}
                    BorderStyle={BorderStyle}
                    inputVerticalPadding={inputVerticalPadding}
                    FormInputComponent={InputComponent}
                    ErrorStyle={ErrorStyle}
                    InputStyle={InputStyle}
                    ControlledEditMode={ControlledEditMode}
                    ControlledEditModeActive={ControlledEditModeActive}
                    {...other}
                />
            </div>
            {BottomMessageComponent}
        </div>
    );
});

FormTextInput.defaultProps = {
    variant: "standard",
    NoMessage: false,
    meta: {touched: null, invalid: null, error: null, dirty: null, pristine: null, modified: null},
    name: "FieldName",
    label: "Field Label",
    placeHolder: null,
    HelperMessage: null,
    required: false,
    multiline: false,
    RowsToDisplay: "1",
    MaxRows: 3,
    InputType: "text",
    readOnly: false,
    disabled: false,
    LabelScale: "0.75", //Range is 0.75 - 0.85 otherwise fonts will crack
    StyleBorderErrorFocusTrue: true,
    Colors: {Empty: null, Filled: null, Error: null, Focused: null, Disabled: null},
    InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
    inputVerticalPadding: null,
    BorderStyle: {Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null},
    HelperStyle: {fontFamily: null, fontSize: null, marginTop: null, color: null},
    ErrorStyle: {color: null},
    LabelStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
    InputStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
    ControlledEditMode: false,
	ControlledEditModeActive: null,
	autocomplete : false
};

FormTextInput.propTypes = {
    /**
     * meta: internal
     */
    meta: PropTypes.any,
    /**
     * NoMessage: Don't use the bottom part to show a message : more compact
     */
    NoMessage: PropTypes.any,

    /**
     * variant: outlined, standard
     */
    variant: PropTypes.string,
    /**
     * input name
     */
    name: PropTypes.string,
    /**
     * input label
     */
    label: PropTypes.string,
    /**
     * placeHolder value
     */
    placeHolder: PropTypes.string,
    /**
     * HelperMessage : Text to be displayed below the input
     */
    HelperMessage: PropTypes.string,
    /**
     * required
     */
    required: PropTypes.bool,
    /**
     * input type : number, text,email,textarea,password,.....
     */
    InputType: PropTypes.string,
    /**
     * multine
     */
    multiline: PropTypes.bool,
    /**
     * Number of RowsToDisplay to open
     */
    RowsToDisplay: PropTypes.string,
    /**
     * Max Number of Rows
     */
    MaxRows: PropTypes.any,

    /**
     * readOnly
     */
    readOnly: PropTypes.bool,
    /**
     * disabled
     */
    disabled: PropTypes.bool,
    /**
     * LabelScale : 0,75. Range is between 0.75 and 0.85, otherwise the text effect will not work
     */
    LabelScale: PropTypes.string,
    /**
     * StyleBorderErrorFocusTrue : default is true
     * If true then the color of the input is focused when focused otherwise the input color is error color
     */
    StyleBorderErrorFocusTrue: PropTypes.bool,

    Colors: PropTypes.object,
    /**
     * InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
     * Fonts for the label and the input
     */
    InputFontProp: PropTypes.object,
    /**
     * LabelStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
     * Customize the label (will override Colors)
     */
    LabelStyle: PropTypes.object,
    /**
     * InputStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}
     * Customize the Input (will override Colors)
     */
    InputStyle: PropTypes.object,
    /**
     * BorderStyle: {Empty: "1px solid grey", Focused: "1px solid grey", Error: "1px solid grey", Disabled: null, BoderRadius: "2px"},
     */
    BorderStyle: PropTypes.object,
    /**
     * HelperStyle: {fontFamily: null, FontSize: null, marginTop: null, color: null},
     */
    HelperStyle: PropTypes.object,
    /**
     * ErrorStyle: {fontFamily: "roboto", fontSize: "14px", marginTop: "2px", color: "red"},
     */
    ErrorStyle: PropTypes.object,
    /**
     * Change the vertical padding of the input
     * inputVerticalPadding: "2px"
     */
    inputVerticalPadding: PropTypes.string,
    /**
     * ErrorStyle: {fontFamily: "roboto", fontSize: "14px", marginTop: "2px", color: "red"},
     */
    ControlledEditMode: PropTypes.any,
    /**
     * Change the vertical padding of the input
     * inputVerticalPadding: "2px"
     */
	ControlledEditModeActive: PropTypes.any,
	/**
     * autocomplete
     */
    autocomplete: PropTypes.any,
};

export default FormTextInput;
