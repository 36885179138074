import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import StatementTransactionsTable from "./StatementTransactionsTable";
import StatementTransactionsViewTableBankAccount from "./StatementTransactionsViewTableBankAccount";
import StatementTransactionsSelectionTable from "./StatementTransactionsSelectionTable";
import StatementTransactionsViewCreditCard from "./StatementTransactionsViewCreditCard";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
}));

const Component = React.memo(function Component(props) {
    const {CardId, ManualTempTotalAmount, StatementDateIso, FormViewMode, AccountType, StatementId, HandleChangeSelectedTransactions} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let TabNavMenuContent;
    if (AccountType === "Credit Card") {
        if (FormViewMode === "View") {
            TabNavMenuContent = [
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Selected Transactions</div>
                        </div>
                    ),

                    TabContent: (
                        <StatementTransactionsViewCreditCard
                            ManualTempTotalAmount={ManualTempTotalAmount}
                            CardId={CardId}
                            StatementDateIso={StatementDateIso}
                            FormViewMode={FormViewMode}
                            StatementId={StatementId}
                            SubmitParentForm={HandleChangeSelectedTransactions}
                        />
                    ),
                },
            ];
        } else {
            TabNavMenuContent = [
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Selected Transactions</div>
                        </div>
                    ),

                    TabContent: (
                        <StatementTransactionsTable
                            ManualTempTotalAmount={ManualTempTotalAmount}
                            CardId={CardId}
                            StatementDateIso={StatementDateIso}
                            FormViewMode={FormViewMode}
                            StatementId={StatementId}
                            SubmitParentForm={HandleChangeSelectedTransactions}
                        />
                    ),
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Add to selection</div>
                        </div>
                    ),

                    TabContent: (
                        <StatementTransactionsSelectionTable
                            ManualTempTotalAmount={ManualTempTotalAmount}
                            CardId={CardId}
                            StatementDateIso={StatementDateIso}
                            FormViewMode={FormViewMode}
                            StatementId={StatementId}
                            SubmitParentForm={HandleChangeSelectedTransactions}
                        />
                    ),
                },
            ];
        }
    } else if (AccountType === "Bank Account") {
        TabNavMenuContent = [
            {
                TabMenu: (
                    <div className={classes.TabMenuDiv}>
                        <div className={classes.TabTitle}>Statement Transactions</div>
                    </div>
                ),

                TabContent: (
                    <StatementTransactionsViewTableBankAccount
                        StatementDateIso={StatementDateIso}
                        FormViewMode={FormViewMode}
                        StatementId={StatementId}
                        SubmitParentForm={HandleChangeSelectedTransactions}
                    />
                ),
            },
        ];
    }
    return (
        <TabNav
            NoLink={true}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={TabNavMenuContent}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
