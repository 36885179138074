/* eslint-disable */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import useTableContext from "../utils/context/tableContext/useTableContext";
import TableContainer from "../TableContainerNew/TableContainer";
import APICallExternal from "../utils/APICallExternal";
import Button from "../Button/Button";
import _ from "lodash";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const Table = React.memo(function Table(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        URLAbsolute,
        URLRelative,
        //eslint-disable-next-line
        LocalData,
        MainDataSource,
        ColomnsInput,
        TableDimensions,
        TableOptions,
        DevMode,
        ShareTableData,
        UpdateDataFromOutisde,
        TotalRow,
    } = props;

    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** CONSTS *********************/
    const InitialTableOptions = {
        Theme: "Dark",
        DimensionsOptions: {
            FitParentContainer: true,
            FitContent: true,
            ContainerScroll: true,
        },
        Selectable: false,
        Sortable: true,
        Searchable: false,
        SearchOptions: {
            WithFilterMenu: true,
            WithGlobalSearchToolbar: true,
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: true,
            DefaultPageSize: 20,
            AllowedPageSizes: [10, 20, 30],
        },
        RowButtons: {
            ColWidth: "100px",
            TotalStandardButtons: 0,
            TotalCustomButtons: 0,
            TotalButtons: 0,
            Buttons: [],
        },
        TopToolbar: {
            Disabled: false,
            AlwaysShow: false,
        },
        GroupRows: {
            Show: false,
        },

        HeaderStyle: {},
    };

    /****************************** CONSTS *********************/

    /****************************** STATE *********************/
    const {StoreOriginalData, InitializeRowsAndCols, TableAction, ResetTheContext} = useTableContext();

    const [ApiRes, setApiRes] = useState(null); //Loader and ensuring async is done for API response
    const [Loading, setLoading] = useState(false); //Loader and ensuring async is done for API response
    const [CallType, setCallType] = useState(null);
    const [Errors, setErrors] = useState(null);
    const [FinalTableOptions, setFinalTableOptions] = useState(null);
    const [FinalDevMode, setFinalDevMode] = useState(false);
    const [FinalTotalRow, setFinalTotalRow] = useState(null);
    const [LoadingProps, setLoadingProps] = useState(true);

    const [OriginalTableData, setOriginalTableData] = useState(null);
    const [IsInitialized, setIsInitialized] = useState(false);
    const [CrudAction, setCrudAction] = useState(null);
    const [ReloadTable, setReloadTable] = useState(false);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("Table Data Store error : ", ApiRes);
                //Something went wrong
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                if (CallType === "DeleteOne" && ReloadTable === false) {
                    console.log("EndofApi deleteone");
                    //We need to reset the table and rebuild it again
                    //Need to call again the API to get the data again
                    // DataOutput("Deleted");
                    setCallType(null);
                    setCrudAction(null);
                    ResetTheContext();
                    setReloadTable(true);
                    setTimeout(() => {
                        setReloadTable(false);
                    }, 100);
                } else {
                    setOriginalTableData(ApiRes.data.data);
                }
            }
        }
    }, [ApiRes, Loading, CallType, ReloadTable]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    useEffect(() => {
        if (LoadingProps === true) {
            /*********** ROW ACTION BUTTONS OPTIONS *******************/
            let FinalRowButtons = [];
            let TotalStandardButtons = 0;
            let TotalCustomButtons = 0;

            let RowButtonsInitial = TableOptions.RowButtons;
            if (RowButtonsInitial && Array.isArray(RowButtonsInitial) && RowButtonsInitial.length > 0) {
                RowButtonsInitial.forEach((elem) => {
                    let Tooltip = {
                        Show: false,
                        Position: "right",
                        Theme: "dark",
                        Content: "Tooltip",
                    };
                    if (elem.Tooltip && elem.Tooltip.Show === true) {
                        Tooltip.Show = true;
                        Tooltip.Position = elem.Tooltip.Position ? elem.Tooltip.Position : "right";
                        Tooltip.Theme = elem.Tooltip.Theme ? elem.Tooltip.Theme : "dark";
                    }
                    let Button = null;
                    if (elem.Standard === true) {
                        //Standard button
                        if (!elem.Name || (elem.Name !== "View" && elem.Name !== "Edit" && elem.Name !== "Delete")) {
                            //We ignore the button
                            console.log("Table - Error - Standard button has no name or the name is invalid");
                        } else {
                            if (elem.Name === "View") {
                                if (elem.Tooltip) {
                                    Tooltip.Content = elem.Tooltip.Content ? elem.Tooltip.Content : "View";
                                }
                            } else if (elem.Name === "Delete") {
                                if (elem.Tooltip) {
                                    Tooltip.Content = elem.Tooltip.Content ? elem.Tooltip.Content : "Delete";
                                }
                            } else if (elem.Name === "Edit") {
                                if (elem.Tooltip) {
                                    Tooltip.Content = elem.Tooltip.Content ? elem.Tooltip.Content : "Edit";
                                }
                            }
                            if (elem.OnClick && typeof (elem.OnClick === "function")) {
                                Button = {
                                    Standard: true,
                                    Name: elem.Name,
                                    ActiveCondition: null,
                                    OnClick: elem.OnClick,
                                    IconSize: elem.IconSize ? elem.IconSize : null,
                                    ActiveColor: elem.ActiveColor ? elem.ActiveColor : null,
                                    HoverColor: elem.HoverColor ? elem.HoverColor : null,
                                    InactiveColor: elem.InactiveColor ? elem.InactiveColor : null,
                                    Tooltip: Tooltip,
                                };
                                if (elem.ActiveCondition) {
                                    if (typeof (elem.ActiveCondition === "function")) {
                                        Button.ActiveCondition = elem.ActiveCondition;
                                    } else {
                                        console.log("Table - Error - Standard button ActiveCondition is not a function");
                                    }
                                }
                            } else {
                                if (elem.Name == "Delete") {
                                    Button = {
                                        Standard: true,
                                        Name: elem.Name,
                                        ActiveCondition: null,
                                        OnClick: elem.OnClick,
                                        IconSize: elem.IconSize ? elem.IconSize : null,
                                        ActiveColor: elem.ActiveColor ? elem.ActiveColor : null,
                                        HoverColor: elem.HoverColor ? elem.HoverColor : null,
                                        InactiveColor: elem.InactiveColor ? elem.InactiveColor : null,
                                        Tooltip: Tooltip,
                                    };
                                    if (elem.ActiveCondition) {
                                        if (typeof (elem.ActiveCondition === "function")) {
                                            Button.ActiveCondition = elem.ActiveCondition;
                                        } else {
                                            console.log("Table - Error - Standard button ActiveCondition is not a function");
                                        }
                                    }
                                } else {
                                    console.log("Table - Error - Standard button has no OnClick function");
                                }
                            }
                        }
                        if (Button) {
                            TotalStandardButtons = TotalStandardButtons + 1;
                            FinalRowButtons.push(Button);
                        }
                    } else {
                        if (elem.Tooltip && elem.Tooltip.Content) {
                            Tooltip.Content = elem.Tooltip.Content;
                        }
                        //Custom button
                        if (elem.OnClick && typeof (elem.OnClick === "function")) {
                            Button = {
                                Standard: false,
                                ActiveCondition: null,
                                OnClick: elem.OnClick,
                                IconActive: elem.IconActive ? elem.IconActive : null,
                                IconInactive: elem.IconInactive ? elem.IconInactive : null,
                                Tooltip: Tooltip,
                            };
                            if (elem.ActiveCondition) {
                                if (typeof (elem.ActiveCondition === "function")) {
                                    Button.ActiveCondition = elem.ActiveCondition;
                                } else {
                                    console.log("Table - Error - Custom button ActiveCondition is not a function");
                                }
                            }
                        } else {
                            console.log("Table - Error - Custom has no OnClick function");
                        }

                        if (Button) {
                            TotalCustomButtons = TotalCustomButtons + 1;
                            FinalRowButtons.push(Button);
                        }
                    }
                });
            }
            let TotalButtons = TotalStandardButtons + TotalCustomButtons;
            InitialTableOptions.RowButtons.TotalStandardButtons = TotalStandardButtons;
            InitialTableOptions.RowButtons.TotalCustomButtons = TotalCustomButtons;
            InitialTableOptions.RowButtons.TotalButtons = TotalButtons;
            InitialTableOptions.RowButtons.Buttons = FinalRowButtons;

            //We remove the keys from the tableOptions that was provided by the user if
            let FinalInitalOptions = _.omit(TableOptions, ["RowButtons"]);
            FinalInitalOptions = _.omit(FinalInitalOptions, ["GroupRows"]);

            /*********** ROW ACTION BUTTONS OPTIONS *******************/

            /*********** GROUP ROWS OPTIONS *******************/
            if (TableOptions.GroupRows) {
                if (TableOptions.GroupRows.Show === true) {
                    InitialTableOptions.GroupRows.Show = true;
                    InitialTableOptions.Selectable = false;
                    InitialTableOptions.Searchable = false;
                } else {
                    InitialTableOptions.GroupRows = {
                        Show: false,
                    };
                }
            } else {
                InitialTableOptions.GroupRows = {
                    Show: false,
                };
            }

            /*********** GROUP ROWS OPTIONS *******************/

            let InitFinalTableOptions;
            if (TableOptions) {
                InitFinalTableOptions = _.merge(InitialTableOptions, FinalInitalOptions);
            } else {
                InitFinalTableOptions = InitialTableOptions;
            }

            const InitialTotalRow = {
                Show: false,
            };
            let InitFinalTotalRow;
            if (TotalRow) {
                if (TotalRow.Render && typeof (TotalRow.Render === "function")) {
                    InitFinalTotalRow = _.merge(InitialTotalRow, TotalRow);
                } else {
                    InitFinalTotalRow = InitialTotalRow;
                    // console.log("Table Component - Total Row Render is not a function");
                }
            } else {
                InitFinalTotalRow = InitialTotalRow;
            }

            const NodeDevMode = process.env.NODE_ENV === "development" ? true : false;
            let InitFinalDevMode = false;
            if (NodeDevMode === true && DevMode === true) {
                InitFinalDevMode = true;
            }
            setFinalDevMode(InitFinalDevMode);
            setFinalTotalRow(InitFinalTotalRow);
            setFinalTableOptions(InitFinalTableOptions);
            setLoadingProps(false);
        }
    }, [LoadingProps]);

    useEffect(() => {
        if (LoadingProps === false) {
            //This portion is used to load the original table data
            if (ReloadTable === false) {
                var FinalURL;
                if (!LocalData && !URLAbsolute && !URLRelative) {
                    setErrors("Table Component error, You didn't pass LocaleData or URLAbsolute or URLRelative");
                } else if (LocalData && !URLAbsolute && !URLRelative) {
                    setOriginalTableData(LocalData);
                } else if (URLAbsolute && URLRelative) {
                    setErrors("Table Component error, Make up your mind URLAbsolute or URLRelative ?");
                } else {
                    if (URLAbsolute) {
                        FinalURL = {
                            MAIN: URLAbsolute.MAIN,
                            DELETE: URLAbsolute.DELETE,
                            PUT: URLAbsolute.PUT,
                            POST: URLAbsolute.POST,
                        };
                    } else {
                        FinalURL = {
                            MAIN: URLRelative.MAIN,
                            DELETE: URLRelative.MAIN + "/" + URLRelative.DELETE + "/",
                            PUT: URLRelative.PUT,
                            POST: URLRelative.POST,
                        };
                    }
                    let FinalAPICall;

                    if (CrudAction) {
                        if (CrudAction.action === "DeleteOne") {
                            FinalAPICall = {url: FinalURL.DELETE + CrudAction.Id, type: "delete"};
                            setCallType("DeleteOne");
                        }
                    } else {
                        FinalAPICall = {url: FinalURL.MAIN, type: "get"};
                    }

                    setLoading(true);
                    async function APIInternal() {
                        const res = await APICallExternal(FinalAPICall);

                        setApiRes(res);
                        setLoading(false);
                    }

                    APIInternal();
                }
            }
        }
    }, [LocalData, URLAbsolute, URLRelative, MainDataSource, CrudAction, ReloadTable, LoadingProps]);

    useEffect(() => {
        if (LoadingProps === false) {
            //GENERATING THE COLS AND ROWS INPUTS FOR THE HEADERS AND THE REST OF THE INFORMATION
            if (OriginalTableData) {
                var Error = null;
                var ColInputs = [],
                    RowInputs = [];

                let RowGroupingFields = [];
                if (ColomnsInput && Array.isArray(ColomnsInput) && ColomnsInput.length > 0) {
                    for (let i = 0; i < ColomnsInput.length; i++) {
                        let elem = ColomnsInput[i];
                        let FinalLookup;
                        if (elem.lookup) {
                            if (elem.lookup.data && elem.lookup.searchField && elem.lookup.returnField) {
                                FinalLookup = {data: elem.lookup.data, searchField: elem.lookup.searchField, returnField: elem.lookup.returnField};
                            }
                        }
                        if (elem.groupIndex) {
                            RowGroupingFields.push({field: elem.datafield, groupIndex: elem.groupIndex});
                        }
                        let DefaultTableCol = {
                            colId: i,
                            datafield: elem.datafield,
                            caption: elem.caption,
                            width: elem.width,
                            minWidth: elem.minWidth,
                            isVisible: true,
                            isFixed: elem.fixedCol === true ? true : false,
                            isAction: false,
                            isDraggable: false,
                            isDragged: false,
                            isGroupCol: elem.groupIndex ? true : false,
                            GroupColIndex: elem.groupIndex ? elem.groupIndex : null,
                            isSortable: elem.sortable === true ? true : false,
                            sortIndex: elem.initialSort ? (elem.initialSort.order ? elem.initialSort.order : null) : null,
                            sortDirection: elem.initialSort ? (elem.initialSort.sortDirection ? elem.initialSort.sortDirection : null) : null,
                            isSorted: elem.initialSort ? (elem.initialSort.order ? true : false) : false,
                            isSearchable: elem.searchable === true ? true : false,
                            isSearched: false,
                            searchValue: null,
                            searchFilterType: null,
                            dataType: elem.datatype ? elem.datatype : "text",
                            customFormatFunction: elem.cellFormatFunction ? (typeof elem.cellFormatFunction === "function" ? elem.cellFormatFunction : null) : null,
                            customRenderFunction: elem.cellCustomRender ? (typeof elem.cellCustomRender === "function" ? elem.cellCustomRender : null) : null,
                            isLookup: FinalLookup ? true : false,
                            lookup: FinalLookup ? FinalLookup : null,
                            wrapHeader: elem.wrapColHeader === true ? true : false,
                            wrapCell: elem.wrapCell === true ? true : false,
                        };
                        ColInputs.push(DefaultTableCol);
                    }
                } else {
                    Error = "Table Component error, ColomnsInput is not a valid array";
                }

                //STORING THE ORIGINAL DATA
                let TableRowsInputs;
                if (MainDataSource) {
                    TableRowsInputs = OriginalTableData[MainDataSource];
                    StoreOriginalData(OriginalTableData, TableRowsInputs);
                } else {
                    TableRowsInputs = OriginalTableData;
                    StoreOriginalData(OriginalTableData, OriginalTableData);
                }
                //GENERATING THE ROWS INPUTS INFORMATION FOR THE TABLE BODY
                if (TableRowsInputs && Array.isArray(TableRowsInputs) && TableRowsInputs.length > 0) {
                    if (FinalTableOptions.GroupRows.Show === false) {
                        for (let i = 0; i < TableRowsInputs.length; i++) {
                            //We look at each data rows
                            let RowElem = TableRowsInputs[i];

                            let DefaultTableRow = {
                                rowId: i,
                                rowOrder: i,
                                isSelectable: false,
                                isSelected: false,
                                isVisible: true,
                                sortedVisible: true,
                                isGroup: false,
                                isDeletable: false,
                                isEditable: false,
                                isEditing: false,
                                rowData: RowElem,
                                isDragable: false,
                                isDraged: false,
                            };
                            RowInputs.push({...DefaultTableRow, ...RowElem});
                        }
                    } else {
                        console.log("RowGroupingFields", RowGroupingFields);
                        //We need to generate the groups
						//We need to find the unique elements to generate the groupRow
						
						for (let i = 0; i < TableRowsInputs.length; i++) {

						}


                    }
                } else {
                    Error = "Table Component error, No Data";
                }

                if (Error) {
                    console.log(Error);
                    setIsInitialized(true);
                    setErrors(Error);
                } else {
                    //We sort the columns

                    InitializeRowsAndCols(RowInputs, ColInputs);
                    setIsInitialized(true);
                }
            }
        }
    }, [OriginalTableData, ColomnsInput, LoadingProps]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (TableAction && ReloadTable === false) {
            setCrudAction(TableAction);
        }
    }, [TableAction]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    if (IsInitialized === true && ReloadTable === false) {
        if (Errors) {
            return <div>{Errors}</div>;
        } else {
            return <TableContainer TableOptions={FinalTableOptions} TotalRow={FinalTotalRow} DevMode={FinalDevMode} />;
        }
    } else {
        return <div>Loading</div>;
    }

    /****************************** RENDER *********************/
});

Table.defaultProps = {
    TableData: null,
};

Table.propTypes = {
    /**
     * TableData :
     */
    TableData: PropTypes.any,
};

export default Table;
