/* eslint-disable */
import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";
import {SortBarsAsc, SortBarsDesc, ArrowSimpleLeft, ArrowSimpleRight, Preview, EditBox, Trash} from "@artibulles-cis/react-icons";

import TableSearchInput from "../TableSearchInput/TableSearchInput";
import TableMessages from "../TableMessages/TableMessages";
import CheckBox from "../../artibulles-cis/CheckBox/CheckBox";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {
    

    
    Header_Cell_Text_Wrap: {
        wordWrap: "break-word",
    },
};

//This style is used to prevent screwing the table and contains the common styles that needs to be applied to keep the cols and rows aligned as well as the borders
const MandatorySyncStyle = (props) => {
    var MandatorySyncStyleFinal = {
        BodyMarginTop: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        BodyMarginBottom: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        ColBorder: null,
        RowBorder: null,
        BodyTopBottomBorder: null, // SYNC WITH FIXED BODY && CONDITIONS : IF BodyMarginTop = 0 -> 0, IF BodyMarginBottom = 0 -> 0
        FixedColsRightBorder: null, //SYNC BETWEEN HEADER AND BODY && CONDITIONS : IF FIXED COL -> NON FIXED COL 1ST ROW borderRight = 0 WHATEVER THE LAST COL OF THE FIXED COL NEEDS TO USE THIS
        Grid_Fixed_ColsBorder: null, //Right Border of the Fixed Cols
        FooterborderTop: null, //TOP BORDER OF THE FOOTER
        HeaderInnerBorderRow: null, //Inner border for Headers rows
        CellVerticalPadding: null, //Controls the Row heigh
        CellHorizontalPadding: null, //horizontal padding of each cell
        ExternalTableBorderRadius: null,
    };

    if (props.TableOptions.CommonStyle) {
        if (props.TableOptions.CommonStyle.ColBorder) {
            MandatorySyncStyleFinal.ColBorder = props.TableOptions.CommonStyle.ColBorder;
        }
        if (props.TableOptions.CommonStyle.RowBorder) {
            MandatorySyncStyleFinal.RowBorder = props.TableOptions.CommonStyle.RowBorder;
        }
        if (props.TableOptions.CommonStyle.BodyTopBottomBorder) {
            MandatorySyncStyleFinal.BodyTopBottomBorder = props.TableOptions.CommonStyle.BodyTopBottomBorder;
        }
        if (props.TableOptions.CommonStyle.FixedColsRightBorder) {
            MandatorySyncStyleFinal.FixedColsRightBorder = props.TableOptions.CommonStyle.FixedColsRightBorder;
        }
        if (props.TableOptions.CommonStyle.Grid_Fixed_ColsBorder) {
            MandatorySyncStyleFinal.Grid_Fixed_ColsBorder = props.TableOptions.CommonStyle.Grid_Fixed_ColsBorder;
        }
        if (props.TableOptions.CommonStyle.FooterborderTop) {
            MandatorySyncStyleFinal.FooterborderTop = props.TableOptions.CommonStyle.FooterborderTop;
        }

        if (props.TableOptions.CommonStyle.HeaderInnerBorderRow) {
            MandatorySyncStyleFinal.HeaderInnerBorderRow = props.TableOptions.CommonStyle.HeaderInnerBorderRow;
        }
        if (props.TableOptions.CommonStyle.CellVerticalPadding) {
            MandatorySyncStyleFinal.CellVerticalPadding = props.TableOptions.CommonStyle.CellVerticalPadding;
        }
        if (props.TableOptions.CommonStyle.CellHorizontalPadding) {
            MandatorySyncStyleFinal.CellHorizontalPadding = props.TableOptions.CommonStyle.CellHorizontalPadding;
        }

        if (props.TableOptions.CommonStyle.ExternalTableBorderRadius) {
            MandatorySyncStyleFinal.ExternalTableBorderRadius = props.TableOptions.CommonStyle.ExternalTableBorderRadius;
        }
    }

    return {
        BodyMarginTop: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        BodyMarginBottom: "0px", //Note : Margin cannot be used because when scrolling the border will appear
        ColBorder: MandatorySyncStyleFinal.ColBorder,
        RowBorder: MandatorySyncStyleFinal.RowBorder,
        BodyTopBottomBorder: MandatorySyncStyleFinal.BodyTopBottomBorder, // SYNC WITH FIXED BODY && CONDITIONS : IF BodyMarginTop = 0 -> 0, IF BodyMarginBottom = 0 -> 0
        FixedColsRightBorder: MandatorySyncStyleFinal.FixedColsRightBorder, //SYNC BETWEEN HEADER AND BODY && CONDITIONS : IF FIXED COL -> NON FIXED COL 1ST ROW borderRight = 0 WHATEVER THE LAST COL OF THE FIXED COL NEEDS TO USE THIS
        Grid_Fixed_ColsBorder: MandatorySyncStyleFinal.Grid_Fixed_ColsBorder, //Right Border of the Fixed Cols
        FooterborderTop: MandatorySyncStyleFinal.FooterborderTop,
        HeaderInnerBorderRow: MandatorySyncStyleFinal.HeaderInnerBorderRow,
        CellVerticalPadding: MandatorySyncStyleFinal.CellVerticalPadding, //Controls the Row heigh
        CellHorizontalPadding: MandatorySyncStyleFinal.CellHorizontalPadding, //horizontal padding of each cell
        ExternalTableBorderRadius: MandatorySyncStyleFinal.ExternalTableBorderRadius,
    };
};

//Those styles are the common one (for developer, prevent having to search for all the styles everywhere)
const MainTableStyle = (props) => {
    var FinalStyle = {
        ExternalTableBorder: null, //External border of the table
        ExternalTableShadow: null, //Use a shadow instead of a border
        HeaderBottomBorder: null, // WHATEVER IS THE LAST HEADER ROW NEEDS TO USE THIS (SEARCH OR LATEST HEADER ROW)
        Body: {
            fontSize: null,
            fontFamily: null,
            fontWeight: null,
            color: null,
            PrimaryRowBackground: null,
            AlternateRowBackground: null,
        },
        Header: {
            fontSize: null,
            fontFamily: null,
            fontWeight: null,
            color: null,
            HeaderRowBackground: null,
        },
        Footer: {
            Background: null,
            fontSize: null,
            fontFamily: null,
            fontWeight: null,
            color: null,
            ActiveBackgroundSelected: null,
            padding: null,
        },
    };

    if (props.TableOptions.CommonStyle) {
        if (props.TableOptions.CommonStyle.ExternalTableBorder) {
            FinalStyle.ExternalTableBorder = props.TableOptions.CommonStyle.ExternalTableBorder;
        }
        if (props.TableOptions.CommonStyle.HeaderBottomBorder) {
            FinalStyle.HeaderBottomBorder = props.TableOptions.CommonStyle.HeaderBottomBorder;
        }
        if (props.TableOptions.CommonStyle.ExternalTableShadow) {
            FinalStyle.ExternalTableShadow = props.TableOptions.CommonStyle.ExternalTableShadow;
        }
    }
    if (props.TableOptions.BodyStyle) {
        if (props.TableOptions.BodyStyle.fontSize) {
            FinalStyle.Body.fontSize = props.TableOptions.BodyStyle.fontSize;
        }
        if (props.TableOptions.BodyStyle.fontFamily) {
            FinalStyle.Body.fontFamily = props.TableOptions.BodyStyle.fontFamily;
        }

        if (props.TableOptions.BodyStyle.fontWeight) {
            FinalStyle.Body.fontWeight = props.TableOptions.BodyStyle.fontWeight;
        }

        if (props.TableOptions.BodyStyle.color) {
            FinalStyle.Body.color = props.TableOptions.BodyStyle.color;
        }

        if (props.TableOptions.BodyStyle.PrimaryRowBackground) {
            FinalStyle.Body.PrimaryRowBackground = props.TableOptions.BodyStyle.PrimaryRowBackground;
        }

        if (props.TableOptions.BodyStyle.AlternateRowBackground) {
            FinalStyle.Body.AlternateRowBackground = props.TableOptions.BodyStyle.AlternateRowBackground;
        }
    }
    if (props.TableOptions.HeaderStyle) {
        if (props.TableOptions.HeaderStyle.fontSize) {
            FinalStyle.Header.fontSize = props.TableOptions.HeaderStyle.fontSize;
        }
        if (props.TableOptions.HeaderStyle.fontFamily) {
            FinalStyle.Header.fontFamily = props.TableOptions.HeaderStyle.fontFamily;
        }

        if (props.TableOptions.HeaderStyle.fontWeight) {
            FinalStyle.Header.fontWeight = props.TableOptions.HeaderStyle.fontWeight;
        }

        if (props.TableOptions.HeaderStyle.color) {
            FinalStyle.Header.color = props.TableOptions.HeaderStyle.color;
        }
        if (props.TableOptions.HeaderStyle.HeaderRowBackground) {
            FinalStyle.Header.HeaderRowBackground = props.TableOptions.HeaderStyle.HeaderRowBackground;
        }
    }

    if (props.TableOptions.FooterStyle) {
        if (props.TableOptions.FooterStyle.Background) {
            FinalStyle.Footer.Background = props.TableOptions.FooterStyle.Background;
        }
        if (props.TableOptions.FooterStyle.fontSize) {
            FinalStyle.Footer.fontSize = props.TableOptions.FooterStyle.fontSize;
        }
        if (props.TableOptions.FooterStyle.fontFamily) {
            FinalStyle.Footer.fontFamily = props.TableOptions.FooterStyle.fontFamily;
        }

        if (props.TableOptions.FooterStyle.fontWeight) {
            FinalStyle.Footer.fontWeight = props.TableOptions.FooterStyle.fontWeight;
        }

        if (props.TableOptions.FooterStyle.color) {
            FinalStyle.Footer.color = props.TableOptions.FooterStyle.color;
        }
        if (props.TableOptions.FooterStyle.ActiveBackgroundSelected) {
            FinalStyle.Footer.ActiveBackgroundSelected = props.TableOptions.FooterStyle.ActiveBackgroundSelected;
        }
        if (props.TableOptions.FooterStyle.padding) {
            FinalStyle.Footer.padding = props.TableOptions.FooterStyle.padding;
        }
    }
    return {
        ExternalTableBorder: FinalStyle.ExternalTableBorder,
        ExternalTableShadow: FinalStyle.ExternalTableShadow,

        HeaderBottomBorder: FinalStyle.HeaderBottomBorder, // WHATEVER IS THE LAST HEADER ROW NEEDS TO USE THIS (SEARCH OR LATEST HEADER ROW)
        Body: {
            fontSize: FinalStyle.Body.fontSize,
            fontFamily: FinalStyle.Body.fontFamily,
            fontWeight: FinalStyle.Body.fontWeight,
            color: FinalStyle.Body.color,
            PrimaryRowBackground: FinalStyle.Body.PrimaryRowBackground,
            AlternateRowBackground: FinalStyle.Body.AlternateRowBackground,
        },
        Header: {
            fontSize: FinalStyle.Header.fontSize,
            fontFamily: FinalStyle.Header.fontFamily,
            fontWeight: FinalStyle.Header.fontWeight,
            color: FinalStyle.Header.color,
            HeaderRowBackground: FinalStyle.Header.HeaderRowBackground,
        },
        Footer: {
            Background: FinalStyle.Footer.Background,
            fontSize: FinalStyle.Footer.fontSize,
            fontFamily: FinalStyle.Footer.fontFamily,
            fontWeight: FinalStyle.Footer.fontWeight,
            color: FinalStyle.Footer.color,
            ActiveBackgroundSelected: FinalStyle.Footer.ActiveBackgroundSelected,
            padding: FinalStyle.Footer.padding,
        },
    };
};

const styles = createUseStyles((theme, props) => ({
    Grid: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "1 1 auto",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            boxSizing: "border-box",
            height: "100%",
            borderRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            boxShadow: MainTableStyle(props).ExternalTableShadow ? MainTableStyle(props).ExternalTableShadow : FinalTheme.CommonStyle.ExternalTableShadow,
        };
    },

    Grid_TopToolbar: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "0 0 50px",
            width: "100%",
            position: "relative",
            boxSizing: "border-box",
            background: "rgb(10,10,10)",
        };
    },
    Grid_TopToolbar_Content: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "relative",
        boxSizing: "border-box",
        padding: "3px 10px",
    },
    Grid_TopToolbar_Content_Left: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    Grid_TopToolbar_Content_Middle: {
        flex: "1 1 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    Grid_TopToolbar_Content_Right: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "flex-end",
    },

    Grid_Container: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "1 1 auto",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            boxSizing: "border-box",
            height: "100%",
            border: MainTableStyle(props).ExternalTableBorder ? MainTableStyle(props).ExternalTableBorder : FinalTheme.CommonStyle.ExternalTableBorder,
            // borderRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
        };
    },
    Grid_Header: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "0 0 auto",
            position: "relative",
            zIndex: "20",
            background: "none",
            backgroundColor: "none",
            boxSizing: "border-box",
            borderTopLeftRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            borderTopRightRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            // overflowX: "hidden",
            // overflowY:"hidden",
            display: "flex",
            // paddingRight: "15px",
        };
    },

    Header_Scroll_Wrapper: {
        flex: "1 1 auto",
        position: "relative",
        minWidth: "0",
        minHeight: "0",
    },
    Header_Scroll_Container: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    Header_Scroll_Content: {
        display: "table",
        // flexDirection : "column",
        minWidth: "100%",
        width: "100%",
        boxSizing: "border-box",
    },
    Header_Rows: {
        position: "relative",
        boxSizing: "border-box",
    },

    Header_Row: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            height: "100%",
            boxSizing: "border-box",
            display: "flex",
            whiteSpace: "nowrap",
            background: MainTableStyle(props).Header.HeaderRowBackground ? MainTableStyle(props).Header.HeaderRowBackground : FinalTheme.Header.HeaderRowBackground,

            "&:first-child $Header_Cell": {
                borderTop: "none",
            },
            "&:last-child $Header_Cell": {
                borderBottom: MainTableStyle(props).Header.HeaderBottomBorder ? MainTableStyle(props).Header.HeaderBottomBorder : FinalTheme.CommonStyle.HeaderBottomBorder,
            },
        };
    },
    Header_Row_Search: {},
    Header_Cell: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            whiteSpace: "nowrap",
            padding: "0 8px",
            fontSize: MainTableStyle(props).Header.fontSize ? MainTableStyle(props).Header.fontSize : FinalTheme.Header.fontSize,
            color: MainTableStyle(props).Header.color ? MainTableStyle(props).Header.color : FinalTheme.Header.color,
            fontFamily: MainTableStyle(props).Header.fontFamily ? MainTableStyle(props).Header.fontFamily : FinalTheme.Header.fontFamily,
            fontWeight: MainTableStyle(props).Header.fontWeight ? MainTableStyle(props).Header.fontWeight : FinalTheme.Header.fontWeight,
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
            borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
        };
    },
    Header_Cell_Text: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        whiteSpace: "normal",
        wordWrap: "normal",
    },
    Header_Cell_Sort: {
        position: "absolute",
        top: "50%",
        right: "0px",
        transform: "translate(0,-50%)",
        flex: "0 0 40px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
    },
    Header_Cell_Sort_Order: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        paddingLeft: "4px",
        fontFamily: "Roboto",
        fontWeight: "300",
        fontSize: "14px",
    },

    Header_Cell_Search: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            whiteSpace: "nowrap",
            padding: "0 8px",
            height: "100%",
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
            borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
            borderTop: MandatorySyncStyle(props).HeaderInnerBorderRow ? MandatorySyncStyle(props).HeaderInnerBorderRow : FinalTheme.CommonStyle.HeaderInnerBorderRow,
        };
    },
    Grid_Body: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "1 1 auto",
            position: "relative",
            boxSizing: "border-box",
            display: "flex",
            height: "auto",
            overflow: "hidden",
            marginTop: MandatorySyncStyle(props).BodyMarginTop,
            marginBottom: MandatorySyncStyle(props).BodyMarginBottom,
        };
    },
    Data_Scroll_Wrapper: {
        flex: "1 1 auto",
        position: "relative",
        height: "auto",
        minWidth: "0",
        minHeight: "0",
    },
    Data_Scroll_Container: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
    },
    Data_Scroll_Content: {
        display: "table",
        minWidth: "100%",
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
    },

    Grid_Row: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            "&:nth-child(even)": {
                background: MainTableStyle(props).Body.PrimaryRowBackground ? MainTableStyle(props).Body.PrimaryRowBackground : FinalTheme.Body.PrimaryRowBackground,
            },
            "&:nth-child(odd)": {
                background: MainTableStyle(props).Body.AlternateRowBackground ? MainTableStyle(props).Body.AlternateRowBackground : FinalTheme.Body.AlternateRowBackground,
            },
            // overflow: "hidden",
            "&:last-child $Grid_Cell": {
                borderBottom: MandatorySyncStyle(props).RowBorder ? MandatorySyncStyle(props).RowBorder : FinalTheme.CommonStyle.RowBorder,
            },
            "&:first-child $Grid_Cell": {
                borderTop: MandatorySyncStyle(props).BodyTopBottomBorder ? MandatorySyncStyle(props).BodyTopBottomBorder : FinalTheme.CommonStyle.BodyTopBottomBorder,
            },
        };
    },
    Grid_Cell: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            position: "relative",
            display: "flex",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            paddingTop: MandatorySyncStyle(props).CellVerticalPadding ? MandatorySyncStyle(props).CellVerticalPadding : FinalTheme.CommonStyle.CellVerticalPadding,
            paddingBottom: MandatorySyncStyle(props).CellVerticalPadding ? MandatorySyncStyle(props).CellVerticalPadding : FinalTheme.CommonStyle.CellVerticalPadding,
            paddingLeft: MandatorySyncStyle(props).CellHorizontalPadding ? MandatorySyncStyle(props).CellHorizontalPadding : FinalTheme.CommonStyle.CellHorizontalPadding,
            paddingRight: MandatorySyncStyle(props).CellHorizontalPadding ? MandatorySyncStyle(props).CellHorizontalPadding : FinalTheme.CommonStyle.CellHorizontalPadding,
            fontSize: MainTableStyle(props).Body.fontSize ? MainTableStyle(props).Body.fontSize : FinalTheme.Body.fontSize,
            color: MainTableStyle(props).Body.color ? MainTableStyle(props).Body.color : FinalTheme.Body.color,
            fontFamily: MainTableStyle(props).Body.fontFamily ? MainTableStyle(props).Body.fontFamily : FinalTheme.Body.fontFamily,
            fontWeight: MainTableStyle(props).Body.fontWeight ? MainTableStyle(props).Body.fontWeight : FinalTheme.Body.fontWeight,
            borderTop: MandatorySyncStyle(props).RowBorder ? MandatorySyncStyle(props).RowBorder : FinalTheme.CommonStyle.RowBorder,
            borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
        };
    },
    Cell_Content: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },

    Grid_Footer: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "0 0 auto",
            position: "relative",
            zIndex: "11",
            boxSizing: "border-box",
            display: "flex",
            padding: MainTableStyle(props).Footer.padding ? MainTableStyle(props).Footer.padding : FinalTheme.Footer.padding,
            borderTop: MandatorySyncStyle(props).FooterborderTop ? MandatorySyncStyle(props).FooterborderTop : FinalTheme.CommonStyle.FooterborderTop,
            background: MainTableStyle(props).Footer.Background ? MainTableStyle(props).Footer.Background : FinalTheme.Footer.Background,
            fontSize: MainTableStyle(props).Footer.fontSize ? MainTableStyle(props).Footer.fontSize : FinalTheme.Footer.fontSize,
            fontWeight: MainTableStyle(props).Footer.fontWeight ? MainTableStyle(props).Footer.fontWeight : FinalTheme.Footer.fontWeight,
            fontFamily: MainTableStyle(props).Footer.fontFamily ? MainTableStyle(props).Footer.fontFamily : FinalTheme.Footer.fontFamily,
            color: MainTableStyle(props).Footer.color ? MainTableStyle(props).Footer.color : FinalTheme.Footer.color,
            borderBottomLeftRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            borderBottomRightRadius: MainTableStyle(props).ExternalTableBorderRadius ? MainTableStyle(props).ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
        };
    },
    Pager: {
        display: "flex",
        width: "100%",
    },
    Pager_Page_Sizes: {
        flex: "0 1  auto",
        display: "flex",
    },
    Pager_Page_Sizes_Button: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        margin: "0px 5px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    Pager_Page_Sizes_Button_Active: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            backgroundColor: MainTableStyle(props).Footer.ActiveBackgroundSelected ? MainTableStyle(props).Footer.ActiveBackgroundSelected : FinalTheme.Footer.ActiveBackgroundSelected,
        };
    },

    Pager_Page_Selector: {
        flex: "1 1  auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    Pager_Page_Selector_Info: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 10px",
    },
    Pager_Page_Selector_Indicators: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Pager_Page_Selector_Indicator: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        margin: "0px 5px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    Pager_Page_Selector_Indicator_Active: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            backgroundColor: MainTableStyle(props).Footer.ActiveBackgroundSelected ? MainTableStyle(props).Footer.ActiveBackgroundSelected : FinalTheme.Footer.ActiveBackgroundSelected,
        };
    },

    Pager_Page_Selector_Indicator_Arrow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    //Fixed Cols

    Grid_Fixed_Cols: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            position: "absolute",
            top: "0px",
            left: "0px",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            boxSizing: "border-box",
            overflow: "hidden",
            // background: "white",
            borderRight: MandatorySyncStyle(props).Grid_Fixed_ColsBorder ? MandatorySyncStyle(props).Grid_Fixed_ColsBorder : FinalTheme.CommonStyle.Grid_Fixed_ColsBorder,
            zIndex: "999999",
        };
    },
    Grid_Fixed_Cols_Header: {
        flex: "0 0 auto",
        position: "relative",
        zIndex: "9999",
        boxSizing: "border-box",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    },
    Grid_Fixed_Cols_Data_Scroll_Wrapper: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            position: "relative",
            boxSizing: "border-box",
            marginTop: MandatorySyncStyle(props).BodyMarginTop,
        };
    },
    Grid_Fixed_Cols_Data_Scroll_Container: {
        position: "relative",
        width: "100%",
        height: "100%",
        // overflow: "auto",

        // background: "white",
    },
    Grid_Fixed_Cols_Data_Scroll_Content: {
        display: "table",
        minWidth: "100%",
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
        position: "relative",
    },
    Header_Row_Fixed: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            boxSizing: "border-box",
            display: "flex",
            whiteSpace: "nowrap",
            "&:first-child $Header_Cell_Fixed": {
                borderTop: "none",
            },
            "&:last-child $Header_Cell_Fixed": {
                borderBottom: MainTableStyle(props).HeaderBottomBorder ? MainTableStyle(props).HeaderBottomBorder : FinalTheme.CommonStyle.HeaderBottomBorder,
            },
        };
    },
    Header_Cell_Fixed: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            whiteSpace: "nowrap",
            padding: "0 8px",
            height: "100%",
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: MandatorySyncStyle(props).FixedColsRightBorder ? MandatorySyncStyle(props).FixedColsRightBorder : FinalTheme.CommonStyle.FixedColsRightBorder,
            },
            borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
        };
    },
    Grid_Row_Fixed: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            "&:last-child $Grid_Cell_Fixed": {
                borderBottom: MandatorySyncStyle(props).RowBorder ? MandatorySyncStyle(props).RowBorder : FinalTheme.CommonStyle.RowBorder,
            },
            "&:first-child $Grid_Cell_Fixed": {
                borderTop: MandatorySyncStyle(props).BodyTopBottomBorder ? MandatorySyncStyle(props).BodyTopBottomBorder : FinalTheme.CommonStyle.BodyTopBottomBorder,
            },
        };
    },
    Grid_Cell_Fixed: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            position: "relative",
            display: "flex",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            overflow: "hidden",
            paddingTop: MandatorySyncStyle(props).CellVerticalPadding ? MandatorySyncStyle(props).CellVerticalPadding : FinalTheme.CommonStyle.CellVerticalPadding,
            paddingBottom: MandatorySyncStyle(props).CellVerticalPadding ? MandatorySyncStyle(props).CellVerticalPadding : FinalTheme.CommonStyle.CellVerticalPadding,
            paddingLeft: MandatorySyncStyle(props).CellHorizontalPadding ? MandatorySyncStyle(props).CellHorizontalPadding : FinalTheme.CommonStyle.CellHorizontalPadding,
            paddingRight: MandatorySyncStyle(props).CellHorizontalPadding ? MandatorySyncStyle(props).CellHorizontalPadding : FinalTheme.CommonStyle.CellHorizontalPadding,
            fontSize: "14px",
            color: "rgba(0,0,0,.7)",
            textAlign: "left",
            fontFamily: "Roboto",
            fontWeight: "400",
            borderTop: MandatorySyncStyle(props).RowBorder ? MandatorySyncStyle(props).RowBorder : FinalTheme.CommonStyle.RowBorder,
            borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: MandatorySyncStyle(props).FixedColsRightBorder ? MandatorySyncStyle(props).FixedColsRightBorder : FinalTheme.CommonStyle.FixedColsRightBorder,
            },
        };
    },
    RowButton: {
        display: "flex",
        padding: "0px 5px",
        boxSizing: "border-box",
        alignItems: "center",
        cursor: "pointer",
    },
    NoData: {
        position: "absolute",
        left: "50%",

        display: "flex",
        width: "200px",
        height: "100px",
        borderRadius: "5px",
        background: "grey",
        color: "white",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
    },
}));

/**
 * Component Description
 */

const TableMain = React.memo(function TableMain(props) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {Data, MainDataSource, ColomnsInput, TableDimensions, TableOptions, CRUDAction, ShareTableData, UpdateDataFromOutisde} = props;

    //Initializing the theme
    const theme = useTheme();
    const classes = styles({...props, theme});

    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_GridBody = useRef();
    const Ref_TableHeader = useRef();
    const Ref_TableFooter = useRef();
    const Ref_HeaderScrollContainer = useRef();
    const Ref_DataScrollContainer = useRef();
    const Ref_RowSelected_Data = useRef();
    const Ref_DataScrollContent = useRef();
    const Ref_TotalActionButton = useRef(0);
    const Ref_InitalSorting = useRef();

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    const TableOptionsInitial = {
        Searchable: true,
        SearchOptions: {
            WithFilterMenu: true,
            WithGlobalSearchToolbar: true,
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        Selectable: true,
        PagerOptions: {
            Visible: true,
            DefaultPageSize: 5,
            AllowedPageSizes: [5, 10, 20],
        },
        RowClickable: false,
        ClickRow: {
            Action: null,
            Condition: null,
        },
        RowButtons: {
            Standard: {},
            // Custom : []
        },
        BodyStyle: {
            cellPadding: "0px 0px 0px 0px",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
        },
        HeaderStyle: {
            cellPadding: "0px 0px 0px 0px",
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
        },
    };

    //Defining the Standard Icons style for the actions
    var FinalTheme;
    if (TableOptions.Theme === "Dark") {
        FinalTheme = theme.Table.Dark;
    } else {
        FinalTheme = theme.Table.Light;
    }

    var DefaultButtonStyle;
    if (TableOptions.RowButtons && TableOptions.RowButtons.Standard && TableOptions.RowButtons.Standard.IconFormat) {
        DefaultButtonStyle = {
            IconSize: TableOptions.RowButtons.Standard.IconFormat.IconSize ? TableOptions.RowButtons.Standard.IconFormat.IconSize : FinalTheme.RowButtonIconStyle.IconSize,
            Color: TableOptions.RowButtons.Standard.IconFormat.Color ? TableOptions.RowButtons.Standard.IconFormat.Color : FinalTheme.RowButtonIconStyle.Color,
            HoverColor: TableOptions.RowButtons.Standard.IconFormat.HoverColor ? TableOptions.RowButtons.Standard.IconFormat.HoverColor : FinalTheme.RowButtonIconStyle.HoverColor,
            DisabledColor: TableOptions.RowButtons.Standard.IconFormat.DisabledColor ? TableOptions.RowButtons.Standard.IconFormat.DisabledColor : FinalTheme.RowButtonIconStyle.DisabledColor,
        };
    } else {
        DefaultButtonStyle = {
            IconSize: FinalTheme.RowButtonIconStyle.IconSize,
            Color: FinalTheme.RowButtonIconStyle.Color,
            HoverColor: FinalTheme.RowButtonIconStyle.HoverColor,
            DisabledColor: FinalTheme.RowButtonIconStyle.DisabledColor,
        };
    }

    const DefaultRowButtons = {
        Edit: {
            Condition: null,
            Icon: <EditBox />,
            Position: 1,
            IconSize: DefaultButtonStyle.IconSize,
            IconColor: DefaultButtonStyle.Color,
            IconHoverColor: DefaultButtonStyle.HoverColor,
            InactiveIconColor: DefaultButtonStyle.DisabledColor,
            InactiveIconColorHover: DefaultButtonStyle.DisabledColor,
        },
        View: {
            Condition: null,
            Icon: <Preview />,
            Position: 2,
            IconSize: DefaultButtonStyle.IconSize,
            IconColor: DefaultButtonStyle.Color,
            IconHoverColor: DefaultButtonStyle.HoverColor,
            InactiveIconColor: DefaultButtonStyle.DisabledColor,
            InactiveIconColorHover: DefaultButtonStyle.DisabledColor,
        },
        Delete: {
            Condition: null,
            Icon: <Trash />,
            Position: 3,
            IconSize: DefaultButtonStyle.IconSize,
            IconColor: DefaultButtonStyle.Color,
            IconHoverColor: DefaultButtonStyle.HoverColor,
            InactiveIconColor: DefaultButtonStyle.DisabledColor,
            InactiveIconColorHover: DefaultButtonStyle.DisabledColor,
        },
    };
    const TableOptionsFinal = _.merge(TableOptionsInitial, TableOptions);
    const DefaultHeaderColom = {
        width: "100px",
        caption: "caption",
    };
    const ActionColWidth = {Button1: "45px", Button2: "80px", Button3: "120px"};

    var SortIconStyle;

    if (TableOptions.HeaderStyle && TableOptions.HeaderStyle.SortIconStyle) {
        let CurrentStyle = TableOptions.HeaderStyle.SortIconStyle;
        SortIconStyle = {
            IconSize: "15px",
            Color: CurrentStyle.Color ? CurrentStyle.Color : FinalTheme.Header.SortIcon.Color,
            ActiveColor: CurrentStyle.ActiveColor ? CurrentStyle.ActiveColor : FinalTheme.Header.SortIcon.ActiveColor,
        };
    } else {
        SortIconStyle = {
            IconSize: "15px",
            Color: FinalTheme.Header.SortIcon.Color,
            ActiveColor: FinalTheme.Header.SortIcon.ActiveColor,
        };
    }

    var WithDraggable = false;
    if (TableOptionsFinal.WithDraggable === true) {
        WithDraggable = true;
    }

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    //eslint-disable-next-line
    // const [TableDimensionsFinal, setTableDimensionsFinal] = useState({Width: null, Height: null});
    //eslint-disable-next-line
    const [TableBodyFinal, setTableBodyFinal] = useState({Width: null, Height: null});
    //eslint-disable-next-line
    // const [HeaderDimensions, setHeaderDimensions] = useState({Width: null, Height: null});
    //eslint-disable-next-line
    // const [FoterDimensions, setFooterDimensions] = useState({Width: null, Height: null});

    //Table Options for Rendering
    const [FixedCols, setFixedCols] = useState(false);
    //Table Internal Dimensions
    const [TableBodyHeight, setTableBodyHeight] = useState("100%");
    const [DataScrollContentWidth, setDataScrollContentWidth] = useState("100%");
    const [HeaderScrollLeft, setHeaderScrollLeft] = useState(0);
    const [FixedColVerticalTop, setFixedColVerticalTop] = useState(0);
    const [TotalActionButtons, setTotalActionButtons] = useState(0);

    //DATA
    const [TableData, setTableData] = useState(null);
    const [SortBy, setSortBy] = useState({});

    const [ActiveSortFilters, setActiveSortFilters] = useState(1);
    const [SearchBy, setSearchBy] = useState({});
    const [PageSize, setPageSize] = useState(null);
    const [TotalRows, setTotalRows] = useState(null);
    const [TotalPages, setTotalPages] = useState(null);
    const [PageSelected, setPageSelected] = useState(null);
    //eslint-disable-next-line
    // const [IsSelectAll, setIsSelectAll] = useState(false);
    const [RowSelected, setRowSelected] = useState([]);
    const [RowClickedId, setRowClickedId] = useState(null);
    //eslint-disable-next-line
    const [ShowTopToolbar, setShowTopToolbar] = useState(false);

    //Table Elements
    const [HeaderRowsComponents, setHeaderRowsComponents] = useState(null);
    const [HeaderSearchRowsComponents, setHeaderSearchRowsComponents] = useState(null);
    const [FixedHeaderRowComponents, setFixedHeaderRowComponents] = useState(null);
    const [FixedHeaderSearchRowsComponents, setFixedHeaderSearchRowsComponents] = useState(null);

    const [BodyRowsComponents, setBodyRowsComponents] = useState(null);
    const [FixedRowsComponents, setFixedRowsComponents] = useState(null);
    const [PageRangeComponents, setPageRangeComponents] = useState(null);
    const [PageSelectorsComponents, setPageSelectorsComponents] = useState(null);
    const [LeftToolBarComponent, setLeftToolBarComponent] = useState(null);
    const [RightToolBarComponent, setRightToolBarComponent] = useState(null);
    const [NoDataComponent, setNoDataComponent] = useState(null);

    //Other
    const [ShowModalMessage, setShowModalMessage] = useState(false);
    const [ModalMessage, setModalMessage] = useState(null);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const UpdateScroll = useCallback(() => {
        let LeftScroll = -Ref_DataScrollContainer.current.scrollLeft;
        let TopPosition = -Ref_DataScrollContainer.current.scrollTop;

        setHeaderScrollLeft(LeftScroll);
        setFixedColVerticalTop(TopPosition);
    }, [Ref_DataScrollContainer]);

    const GenerateTableData = React.useCallback(() => {
        //Preparing the table Data
        if (Data) {
            // console.log("Data exist");
            var TableDataTemp;

            if (MainDataSource) {
                // console.log("main data source", MainDataSource);
                TableDataTemp = Data[MainDataSource];
                // console.log("TableDataTemp",TableDataTemp)
            } else {
                TableDataTemp = Data;
            }

            if (TableDataTemp && TableDataTemp.length > 0) {
                const FinalTableData = [];

                for (var i = 0; i < TableDataTemp.length; i++) {
                    let DataRow = {
                        RowId: i,
                        ApiId: TableDataTemp[i]._id ? TableDataTemp[i]._id : null,
                        isSelected: false,
                        sortIndex: 0,
                        canEdit: false,
                        canSelect: true,
                        showRow: true,
                        render: null,
                        rowData: TableDataTemp[i],
                    };

                    FinalTableData.push(DataRow);
                }
                setTableData(FinalTableData);
            } else {
                setTableData("no data");
            }
        } else {
            setTableData("no data");
        }
    }, [Data, MainDataSource]);

    const GenerateTable = useCallback(() => {
        if (TableData && TableData !== "no data") {
            var MaxRowsToShow = TableData.length;
            var MinRowToShow = 0;
            if (PageSize && PageSelected) {
                if (PageSize > TotalRows) {
                    MaxRowsToShow = parseInt(TotalRows);
                } else {
                    MaxRowsToShow = parseInt(PageSize * PageSelected);
                    if (MaxRowsToShow >= TotalRows) {
                        MaxRowsToShow = parseInt(TotalRows);
                        MinRowToShow = parseInt(PageSize * PageSelected) - PageSize;
                    } else {
                        MinRowToShow = MaxRowsToShow - PageSize;
                    }
                }
            }

            /************************* GENERATING THE BODY ROWS AND THE FIXED COLS ******************************/
            var BodyRows = [],
                FixedColsRows = [],
                HasFixedCol = false;
            //Extracting the RowsData from the source
            let DataRows = TableData;

            for (var i = MinRowToShow; i < MaxRowsToShow; i++) {
                let RowData = DataRows[i];

                var ColsElement = [],
                    FixedColsElement = [];

                /************************* SELECT ELEMENTS ******************************/
                if (TableOptionsInitial.Selectable === true) {
                    //We need to add the selectable element

                    let RowId = RowData.rowData._id;
                    if (RowSelected.length > 0) {
                        //Let's check if the Row is already selected or not
                        const ExistSelected = _.find(RowSelected, {Id: RowId});
                        var IsSelected = false;
                        if (ExistSelected) {
                            IsSelected = true;
                        }
                    }
                    let SelectElem = (
                        <div key={uuidv4()} className={clsx(classes.Grid_Cell, classes.Grid_Cell_Fixed)} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}>
                            <div className={classes.Cell_Content}>
                                <CheckBox curvalue={IsSelected} GetValue={(value) => HandleSelectRow(RowData, value)} IconSize="15px" CirclePadding="5px" name="test" />
                            </div>
                        </div>
                    );
                    FixedColsElement.push(SelectElem);
                    let ColElement = (
                        <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}>
                            <div className={classes.Cell_Content}>
                                <CheckBox curvalue={IsSelected} GetValue={(value) => HandleSelectRow(RowData, value)} IconSize="15px" CirclePadding="5px" name="test" />
                            </div>
                        </div>
                    );
                    ColsElement.push(ColElement);
                }
                /************************* SELECT ELEMENTS ******************************/

                /************************* ROW BUTTONS ******************************/
                var EditButtonIcon, DeleteButtonIcon, ViewButtonIcon;
                var WidthButtonBar = ActionColWidth.Button3;
                if (TableOptionsFinal.RowButtons.Standard) {
                    if (TableOptionsFinal.RowButtons.Standard.Edit) {
                        if (TableOptionsFinal.RowButtons.Standard.Edit.Icon) {
                            let IconButton = TableOptionsFinal.RowButtons.Standard.Edit.Icon;
                            EditButtonIcon = (
                                <div className={classes.RowButton} onClick={() => TableOptionsFinal.RowButtons.Standard.Edit.OnClick(RowData)}>
                                    {IconButton}
                                </div>
                            );
                        } else {
                            let IconButton = React.cloneElement(DefaultRowButtons.Edit.Icon, {
                                IconSize: DefaultRowButtons.Edit.IconSize,
                                SVGFillColor: DefaultRowButtons.Edit.IconColor,
                                SVGFillHoverColor: DefaultRowButtons.Edit.IconHoverColor,
                            });
                            EditButtonIcon = (
                                <div className={classes.RowButton} onClick={() => TableOptionsFinal.RowButtons.Standard.Edit.OnClick(RowData)}>
                                    {IconButton}
                                </div>
                            );
                        }
                    }
                    if (TableOptionsFinal.RowButtons.Standard.View) {
                        if (TableOptionsFinal.RowButtons.Standard.View.Icon) {
                            let IconButton = TableOptionsFinal.RowButtons.Standard.View.Icon;
                            ViewButtonIcon = (
                                <div className={classes.RowButton} onClick={() => TableOptionsFinal.RowButtons.Standard.View.OnClick(RowData)}>
                                    {IconButton}
                                </div>
                            );
                        } else {
                            let IconButton = React.cloneElement(DefaultRowButtons.View.Icon, {
                                IconSize: DefaultRowButtons.View.IconSize,
                                SVGFillColor: DefaultRowButtons.View.IconColor,
                                SVGFillHoverColor: DefaultRowButtons.View.IconHoverColor,
                            });
                            ViewButtonIcon = (
                                <div className={classes.RowButton} onClick={() => TableOptionsFinal.RowButtons.Standard.View.OnClick(RowData)}>
                                    {IconButton}
                                </div>
                            );
                        }
                    }
                    if (TableOptionsFinal.RowButtons.Standard.Delete) {
                        if (TableOptionsInitial.RowButtons.Standard.Delete.Icon) {
                            let IconButton = TableOptionsFinal.RowButtons.Standard.Delete.Icon;
                            DeleteButtonIcon = (
                                <div className={classes.RowButton} onClick={() => TableOptionsFinal.RowButtons.Standard.Delete.OnClick(RowData)}>
                                    {IconButton}
                                </div>
                            );
                        } else {
                            var IconButton;
                            var Active = false;
                            if (TableOptionsFinal.RowButtons.Standard.Delete.Condition) {
                                Active = TableOptionsFinal.RowButtons.Standard.Delete.Condition(RowData);
                                if (Active) {
                                    IconButton = React.cloneElement(DefaultRowButtons.Delete.Icon, {
                                        IconSize: DefaultRowButtons.Delete.IconSize,
                                        SVGFillColor: DefaultRowButtons.Delete.IconColor,
                                        SVGFillHoverColor: DefaultRowButtons.Delete.IconHoverColor,
                                    });
                                } else {
                                    IconButton = React.cloneElement(DefaultRowButtons.Delete.Icon, {
                                        IconSize: DefaultRowButtons.Delete.IconSize,
                                        SVGFillColor: DefaultRowButtons.Delete.InactiveIconColor,
                                        SVGFillHoverColor: DefaultRowButtons.Delete.InactiveIconColorHover,
                                    });
                                }
                            } else {
                                IconButton = React.cloneElement(DefaultRowButtons.Delete.Icon, {
                                    IconSize: DefaultRowButtons.Delete.IconSize,
                                    SVGFillColor: DefaultRowButtons.Delete.IconColor,
                                    SVGFillHoverColor: DefaultRowButtons.Delete.IconHoverColor,
                                });
                            }
                            if (Active === true) {
                                if (TableOptionsFinal.RowButtons.Standard.Delete.OnClick) {
                                    //Custome Delete Function
                                    DeleteButtonIcon = (
                                        <div className={classes.RowButton} onClick={() => TableOptionsFinal.RowButtons.Standard.Delete.OnClick(RowData)}>
                                            {IconButton}
                                        </div>
                                    );
                                } else {
                                    //Default Delete Function
                                    DeleteButtonIcon = (
                                        <div className={classes.RowButton} onClick={() => HandleDeleteRow(RowData)}>
                                            {IconButton}
                                        </div>
                                    );
                                }
                            } else {
                                DeleteButtonIcon = <div className={classes.RowButton}>{IconButton}</div>;
                            }
                        }
                    }
                    if (EditButtonIcon && ViewButtonIcon && DeleteButtonIcon) {
                        setTotalActionButtons(3);
                        Ref_TotalActionButton.current = 3;
                        WidthButtonBar = ActionColWidth.Button3;
                        let RowButtonFixedCol = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Grid_Cell, classes.Grid_Cell_Fixed)}
                                style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}
                            >
                                <div className={classes.Cell_Content}>
                                    {EditButtonIcon}
                                    {ViewButtonIcon}
                                    {DeleteButtonIcon}
                                </div>
                            </div>
                        );
                        FixedColsElement.push(RowButtonFixedCol);
                        let RowButtonCol = (
                            <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}>
                                <div className={classes.Cell_Content}>
                                    {EditButtonIcon}
                                    {ViewButtonIcon}
                                    {DeleteButtonIcon}
                                </div>
                            </div>
                        );
                        ColsElement.push(RowButtonCol);
                    } else if (EditButtonIcon || ViewButtonIcon || DeleteButtonIcon) {
                        if (EditButtonIcon) {
                            if (!ViewButtonIcon && !DeleteButtonIcon) {
                                setTotalActionButtons(1);
                                Ref_TotalActionButton.current = 1;
                                WidthButtonBar = ActionColWidth.Button1;
                            } else if (ViewButtonIcon || DeleteButtonIcon) {
                                WidthButtonBar = ActionColWidth.Button2;
                                setTotalActionButtons(2);
                                Ref_TotalActionButton.current = 2;
                            } else {
                                WidthButtonBar = ActionColWidth.Button3;
                                setTotalActionButtons(3);
                                Ref_TotalActionButton.current = 3;
                            }
                        } else if (ViewButtonIcon) {
                            if (!EditButtonIcon && !DeleteButtonIcon) {
                                WidthButtonBar = ActionColWidth.Button1;

                                setTotalActionButtons(1);
                                Ref_TotalActionButton.current = 1;
                            } else if (EditButtonIcon || DeleteButtonIcon) {
                                WidthButtonBar = ActionColWidth.Button2;

                                setTotalActionButtons(2);
                                Ref_TotalActionButton.current = 2;
                            } else {
                                WidthButtonBar = ActionColWidth.Button3;
                                setTotalActionButtons(3);
                                Ref_TotalActionButton.current = 3;
                            }
                        } else if (DeleteButtonIcon) {
                            if (!ViewButtonIcon && !EditButtonIcon) {
                                setTotalActionButtons(1);
                                Ref_TotalActionButton.current = 1;
                                WidthButtonBar = ActionColWidth.Button1;
                            } else if (ViewButtonIcon || EditButtonIcon) {
                                WidthButtonBar = ActionColWidth.Button2;

                                setTotalActionButtons(2);
                                Ref_TotalActionButton.current = 2;
                            } else {
                                WidthButtonBar = ActionColWidth.Button3;
                                setTotalActionButtons(3);
                                Ref_TotalActionButton.current = 3;
                            }
                        } else {
                            WidthButtonBar = ActionColWidth.Button3;
                            setTotalActionButtons(3);
                            Ref_TotalActionButton.current = 3;
                        }

                        let RowButtonFixedCol = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Grid_Cell, classes.Grid_Cell_Fixed)}
                                style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}
                            >
                                <div className={classes.Cell_Content}>
                                    {EditButtonIcon}
                                    {ViewButtonIcon}
                                    {DeleteButtonIcon}
                                </div>
                            </div>
                        );
                        FixedColsElement.push(RowButtonFixedCol);
                        let RowButtonCol = (
                            <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}>
                                <div className={classes.Cell_Content}>
                                    {EditButtonIcon}
                                    {ViewButtonIcon}
                                    {DeleteButtonIcon}
                                </div>
                            </div>
                        );
                        ColsElement.push(RowButtonCol);
                    }
                }
                if (TableOptionsFinal.RowButtons.Custom) {
                    //Calculate the number of Buttons
                    let TotalButtons = TableOptionsFinal.RowButtons.Custom.length;
                    if (TotalButtons === 1) {
                        WidthButtonBar = ActionColWidth.Button1;
                    } else if (TotalButtons === 2) {
                        WidthButtonBar = ActionColWidth.Button2;
                    } else {
                        WidthButtonBar = ActionColWidth.Button3;
                    }
                    setTotalActionButtons(TotalButtons);
                    Ref_TotalActionButton.current = TotalButtons;
                    //Check if the entries are correct
                    if (Array.isArray(TableOptionsFinal.RowButtons.Custom)) {
                        var FinalCustomButtons = [];
                        for (var j = 0; j < TableOptionsFinal.RowButtons.Custom.length; j++) {
                            let CustomButton = TableOptionsFinal.RowButtons.Custom[j];

                            //Extract the information about the Buttons
                            var ButtonCustom = null;
                            // var ExpectedButton = {
                            //     Name: CustomButton.Name ? CustomButton.Name : null,
                            //     ShowCondition: CustomButton.ShowCondition ? CustomButton.ShowCondition : null,
                            //     Action: CustomButton.Action ? CustomButton.Action : null,
                            //     Icon: CustomButton.Icon ? CustomButton.Icon : null,
                            // };
                            let Active = true;
                            if (CustomButton.ActiveCondition && typeof CustomButton.ActiveCondition === "function") {
                                Active = CustomButton.ActiveCondition(RowData);
                            }

                            if (Active === true) {
                                if (CustomButton.Action) {
                                    ButtonCustom = (
                                        <div className={classes.RowButton} onClick={() => CustomButton.Action(RowData)}>
                                            {CustomButton.IconActive}
                                        </div>
                                    );
                                } else {
                                    ButtonCustom = <div className={classes.RowButton}>{CustomButton.IconActive}</div>;
                                }
                            } else {
                                ButtonCustom = <div className={classes.RowButton}>{CustomButton.IconInactive}</div>;
                            }

                            FinalCustomButtons.push(ButtonCustom);
                        }

                        let CustomRowButtonFixedCol = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Grid_Cell, classes.Grid_Cell_Fixed)}
                                style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}
                            >
                                <div className={classes.Cell_Content}>{FinalCustomButtons}</div>
                            </div>
                        );
                        FixedColsElement.push(CustomRowButtonFixedCol);
                        let CustomRowButtonCol = (
                            <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}>
                                <div className={classes.Cell_Content}>{FinalCustomButtons}</div>
                            </div>
                        );
                        ColsElement.push(CustomRowButtonCol);
                    } else {
                        console.log("Error in the custom Buttons");
                    }
                }

                /************************* ROW BUTTONS ******************************/

                /************************* COL ELEMENTS FOR FIXES COLS AND NORMAL COLS******************************/
                ColomnsInput.forEach((elem) => {
                    let key = elem.datafield;

                    let FixedCol = !elem.fixedCol || elem.fixedCol === false ? false : true;
                    //Body Cols
                    var OptionsForCell = _.find(ColomnsInput, {datafield: key});
                    var CellValue = null;
                    var FlexWidth, MinWidth, MaxWidth, Width;

                    if (elem.width) {
                        if (elem.width === "auto") {
                            FlexWidth = "1 1 auto";
                        } else {
                            FlexWidth = `0 0 ${elem.width}`;
                            MinWidth = elem.width;
                            MaxWidth = elem.width;
                            Width = elem.width;
                        }
                    }

                    if (FixedCol === true) {
                        //FIXED COLOMN
                        HasFixedCol = true;
                        let FixedColElement = (
                            <div key={uuidv4()} className={clsx(classes.Grid_Cell, classes.Grid_Cell_Fixed)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Cell_Content}>{_.get(RowData["rowData"], key)}</div>
                            </div>
                        );
                        FixedColsElement.push(FixedColElement);
                        let ColElement = (
                            <div key={uuidv4()} className={clsx(classes.Grid_Cell)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Cell_Content}>{_.get(RowData["rowData"], key)}</div>
                            </div>
                        );
                        ColsElement.push(ColElement);
                    } else {
                        if (OptionsForCell.cellCustomRender) {
                            //CUSTOM RENDERING FUNCTION

                            CellValue = OptionsForCell.cellCustomRender(_.get(RowData["rowData"], key));
                        } else {
                            if (OptionsForCell.datatype && OptionsForCell.datatype === "boolean") {
                                //BOOLEAN CELL
                                //Arnaud

                                var CheckBoxStyleRow = {
                                    CheckFillColor: FinalTheme.Body.CheckBoxRow.CheckFillColor,
                                    CheckMarkColor: FinalTheme.Body.CheckBoxRow.CheckMarkColor,
                                    UncheckedBorderColor: FinalTheme.Body.CheckBoxRow.UncheckedBorderColor,
                                };
                                if (TableOptions.CheckBoxRow) {
                                    CheckBoxStyleRow.CheckFillColor = TableOptions.CheckBoxRow.CheckFillColor ? TableOptions.CheckBoxRow.CheckFillColor : FinalTheme.Body.CheckBoxRow.CheckFillColor;
                                    CheckMarkColor.CheckMarkColor = TableOptions.CheckBoxRow.CheckMarkColor ? TableOptions.CheckBoxRow.CheckMarkColor : FinalTheme.Body.CheckBoxRow.CheckMarkColor;
                                    CheckBoxStyleRow.UncheckedBorderColor = TableOptions.CheckBoxRow.UncheckedBorderColor
                                        ? TableOptions.CheckBoxRow.UncheckedBorderColor
                                        : FinalTheme.Body.CheckBoxRow.UncheckedBorderColor;
                                }

                                CellValue = (
                                    <CheckBox
                                        IconSize="15px"
                                        CirclePadding="5px"
                                        name="test"
                                        initialvalue={_.get(RowData["rowData"], key)}
                                        curvalue={_.get(RowData["rowData"], key)}
                                        disabled={true}
                                        CheckFillColor={CheckBoxStyleRow.CheckFillColor}
                                        CheckMarkColor={CheckBoxStyleRow.CheckMarkColor}
                                        UncheckedBorderColor={CheckBoxStyleRow.UncheckedBorderColor}
                                    />
                                );
                            } else {
                                //FORMATTED CELL
                                if (OptionsForCell.cellFormatFunction) {
                                    let CellFormatFunctionApply = OptionsForCell.cellFormatFunction;

                                    if (typeof CellFormatFunctionApply === "function") {
                                        CellValue = CellFormatFunctionApply(_.get(RowData["rowData"], key));
                                    } else {
                                        CellValue = RowData["rowData"][key];
                                    }
                                } else {
                                    //LOOKUP CELL
                                    if (OptionsForCell.lookup && OptionsForCell.lookup.data && OptionsForCell.lookup.searchField && OptionsForCell.lookup.returnField) {
                                        //we check that the lookup array exists in the provided data
                                        let LookupList = Data[OptionsForCell.lookup.data];

                                        if (LookupList && Array.isArray(LookupList) && LookupList.length > 0) {
                                            //we search the corresponding field
                                            let lookupvalue = _.find(LookupList, {[OptionsForCell.lookup.searchField]: _.get(RowData["rowData"], key)});

                                            if (lookupvalue) {
                                                CellValue = lookupvalue[OptionsForCell.lookup.returnField];
                                            } else {
                                                CellValue = "lookup error";
                                            }
                                        }
                                    } else {
                                        //STANDARD CELLS
                                        CellValue = _.get(RowData["rowData"], key);
                                    }
                                }
                            }
                        }
                        //Applying custom style to each cell based on the user inputs
                        var FinalStyle;
                        if (OptionsForCell.style) {
                            FinalStyle = OptionsForCell.style;
                        }
                        var WrapCell = "nowrap";
                        if (OptionsForCell.WrapText === true) {
                            WrapCell = "normal";
                        }

                        let ColElement = (
                            <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth, whiteSpace: WrapCell}}>
                                <div className={classes.Cell_Content} style={FinalStyle}>
                                    {CellValue}
                                </div>
                            </div>
                        );
                        ColsElement.push(ColElement);
                    }
                });
                /************************* COL ELEMENTS FOR FIXES COLS AND NORMAL COLS******************************/
                //Body Rows

                var RowElement;
                if (TableOptionsFinal.RowClickable === true) {
                    if (TableOptionsFinal.ClickRow) {
                        var Clickable = true;

                        if (TableOptionsFinal.ClickRow.Condition) {
                            Clickable = TableOptionsFinal.ClickRow.Condition(RowData);
                        }

                        if (Clickable === true) {
                            if (TableOptionsFinal.ClickRow.Action) {
                                if (RowData.RowId === RowClickedId) {
                                    RowElement = (
                                        <div
                                            key={uuidv4()}
                                            className={classes.Grid_Row}
                                            //
                                            style={{display: `${RowData.showRow === false ? "none" : null}`, cursor: "default", background: "rgba(66, 165, 245,0.5)"}}
                                            // onClick={() => TableOptionsFinal.ClickRow.Action(RowData)}
                                            onClick={() => HandleRowClick(RowData)}
                                        >
                                            {ColsElement}
                                        </div>
                                    );
                                } else {
                                    RowElement = (
                                        <div
                                            key={uuidv4()}
                                            className={classes.Grid_Row}
                                            //
                                            style={{display: `${RowData.showRow === false ? "none" : null}`, cursor: "default"}}
                                            // onClick={() => TableOptionsFinal.ClickRow.Action(RowData)}
                                            onClick={() => HandleRowClick(RowData)}
                                        >
                                            {ColsElement}
                                        </div>
                                    );
                                }
                            } else {
                                RowElement = (
                                    <div key={uuidv4()} className={classes.Grid_Row} style={{display: `${RowData.showRow === false ? "none" : null}`, cursor: "not-allowed"}}>
                                        {ColsElement}
                                    </div>
                                );
                            }
                        } else {
                            RowElement = (
                                <div key={uuidv4()} className={classes.Grid_Row} style={{display: `${RowData.showRow === false ? "none" : null}`, cursor: "not-allowed"}}>
                                    {ColsElement}
                                </div>
                            );
                        }
                    }
                } else {
                    RowElement = (
                        <div key={uuidv4()} className={classes.Grid_Row} style={{display: `${RowData.showRow === false ? "none" : null}`}}>
                            {ColsElement}
                        </div>
                    );
                }

                BodyRows.push(RowElement);
                //Fixed Rows
                if (FixedColsElement.length > 0) {
                    let FixedRowElement = (
                        <div key={uuidv4()} className={clsx(classes.Grid_Row, classes.Grid_Row_Fixed)} style={{display: `${RowData.showRow === false ? "none" : null}`}}>
                            {FixedColsElement}
                        </div>
                    );
                    FixedColsRows.push(FixedRowElement);
                }
            }

            /************************* GENERATING THE BODY ROWS AND THE FIXED COLS ******************************/

            setBodyRowsComponents(BodyRows);
            setFixedRowsComponents(FixedColsRows);
            setFixedCols(HasFixedCol);
            setNoDataComponent(null);
        } else {
            let NotDataComp = <div className={classes.NoData}>No Data</div>;
            setNoDataComponent(NotDataComp);
        }
    }, [TableData, PageSize, PageSelected, RowSelected, RowClickedId]);

    const GenerateTableHeader = useCallback(() => {
        if (TableData) {
            /************************* EXTRACTION THE TABLE BODY CONTENT WIDTH  ******************************/
            if (Ref_DataScrollContent && Ref_DataScrollContent.current) {
                let Width = Ref_DataScrollContent.current.getBoundingClientRect().width;

                setDataScrollContentWidth(Width + "px");
            }

            /************************* EXTRACTION THE TABLE BODY CONTENT WIDTH  ******************************/

            /************************* GENERATING THE HEADER ROWS ******************************/
            var HeaderRowComps = [],
                FixedHeaderRowComps = [],
                HeaderCellComps = [],
                FixedHeaderCellsComp = [];

            var SortByInit = SortBy;

            /************* SELECT HEADER ROWS **********/
            if (TableOptionsFinal.Selectable === true) {
                let SelectHeaderElem = (
                    <div key={uuidv4()} className={classes.Header_Cell} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}>
                        <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}>
                            <CheckBox IconSize="15px" CirclePadding="5px" name="test" GetValue={HandleSelectAll} />
                        </div>
                    </div>
                );
                HeaderCellComps.push(SelectHeaderElem);
                //We need to add the selectable element
                let SelectElem = (
                    <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Fixed)} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}>
                        <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}>
                            <CheckBox IconSize="15px" CirclePadding="5px" name="test" GetValue={HandleSelectAll} />
                        </div>
                    </div>
                );
                FixedHeaderCellsComp.push(SelectElem);
            }

            /************* SELECT HEADER ROWS **********/

            /************************* HEADER ROW BUTTONS ******************************/
            var TotalRowButtons = 0;
            var WidthButtonBar;

            if (TableOptionsFinal.RowButtons.Standard) {
                if (TableOptionsFinal.RowButtons.Standard.Edit) {
                    TotalRowButtons = TotalRowButtons + 1;
                }
                if (TableOptionsFinal.RowButtons.Standard.Delete) {
                    TotalRowButtons = TotalRowButtons + 1;
                }
                if (TableOptionsFinal.RowButtons.Standard.View) {
                    TotalRowButtons = TotalRowButtons + 1;
                }

                if (TotalRowButtons > 0) {
                    WidthButtonBar = ActionColWidth.Button3;
                    if (TotalRowButtons === 1) {
                        WidthButtonBar = ActionColWidth.Button1;
                    } else if (TotalRowButtons === 2) {
                        WidthButtonBar = ActionColWidth.Button2;
                    }
                    //Below Should have actions as a content
                    let SelectHeaderElem = (
                        <div key={uuidv4()} className={classes.Header_Cell} style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}>
                            <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}></div>
                        </div>
                    );
                    HeaderCellComps.push(SelectHeaderElem);
                    //Below Should have actions as a content
                    let SelectElem = (
                        <div
                            key={uuidv4()}
                            className={clsx(classes.Header_Cell, classes.Header_Cell_Fixed)}
                            style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}
                        >
                            <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}></div>
                        </div>
                    );
                    FixedHeaderCellsComp.push(SelectElem);
                }
            }
            if (TableOptionsFinal.RowButtons.Custom && Array.isArray(TableOptionsFinal.RowButtons.Custom)) {
                TotalRowButtons = TableOptionsFinal.RowButtons.Custom.length;
                if (TotalRowButtons > 0) {
                    WidthButtonBar = ActionColWidth.Button3;
                    if (TotalRowButtons === 1) {
                        WidthButtonBar = ActionColWidth.Button1;
                    } else if (TotalRowButtons === 2) {
                        WidthButtonBar = ActionColWidth.Button2;
                    }
                }
                //Below Should have actions as a content
                let ButtonHeaderElem = (
                    <div key={uuidv4()} className={classes.Header_Cell} style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}>
                        <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}></div>
                    </div>
                );

                HeaderCellComps.push(ButtonHeaderElem);
                //Below Should have actions as a content
                let ButtonHeadElem = (
                    <div
                        key={uuidv4()}
                        className={clsx(classes.Header_Cell, classes.Header_Cell_Fixed)}
                        style={{flex: `0 0 ${WidthButtonBar}`, width: WidthButtonBar, minWidth: WidthButtonBar, maxWidth: WidthButtonBar}}
                    >
                        <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}></div>
                    </div>
                );
                FixedHeaderCellsComp.push(ButtonHeadElem);
            }

            /************************* HEADER ROW BUTTONS ******************************/

            var InitialSorting = false; //Used to create a fake sort action on init if a column needs to be sorted at start
            ColomnsInput.forEach((elem) => {
                //Checking if there are fixed Columns
                let FixedCol = !elem.fixedCol || elem.fixedCol === false ? false : true;
                /*Col Width*/
                var FlexWidth, MinWidth, MaxWidth, Width;
                if (elem.width) {
                    if (elem.width === "auto") {
                        FlexWidth = "1 1 auto";
                    } else {
                        FlexWidth = `0 0 ${elem.width}`;
                        MinWidth = elem.width;
                        MaxWidth = elem.width;
                        Width = elem.width;
                    }
                } else {
                }
                /*Col Width*/

                var caption = elem.caption ? elem.caption : DefaultHeaderColom.caption;
                var WrapCell = elem.wrapColHeader === true ? true : false;

                /************* SORTING COMPONENT **********/
                var SortComponent;
                if (TableOptionsFinal.Sortable === true) {
                    const SortIconAsc = <SortBarsAsc IconSize="15px" SVGFillColor={SortIconStyle.Color} SVGFillHoverColor={SortIconStyle.Color} />;
                    const SortIconAscActive = <SortBarsAsc IconSize={SortIconStyle.IconSize} SVGFillColor={SortIconStyle.ActiveColor} SVGFillHoverColor={SortIconStyle.ActiveColor} />;
                    const SortIconDescActive = <SortBarsDesc IconSize="15px" SVGFillColor={SortIconStyle.ActiveColor} SVGFillHoverColor={SortIconStyle.ActiveColor} />;

                    var FinalSortIcon, SortIndex, IsElemSortable;
                    elem.sortable === false ? (IsElemSortable = false) : (IsElemSortable = true);
                    var HeaderCell;
                    if (IsElemSortable === true) {
                        if (!SortByInit[elem.datafield]) {
                            //We check if we need to sort the columns when initializing
                            if (elem.initialSort && elem.initialSort.sortDirection) {
                                var InitSortDirection;
                                if (elem.initialSort.sortDirection === "dsc") {
                                    InitSortDirection = "descending";
                                    FinalSortIcon = SortIconDescActive;
                                    SortByInit = {...SortByInit, ...{[elem.datafield]: {direction: "descending", order: 1}}};
                                    InitialSorting = elem.datafield;
                                } else {
                                    InitSortDirection = "ascending";
                                    FinalSortIcon = SortIconAscActive;
                                    SortByInit = {...SortByInit, ...{[elem.datafield]: {direction: "ascending", order: 1}}};
                                    InitialSorting = elem.datafield;
                                }
                            } else {
                                //On Init, create the sortable list
                                SortByInit = {...SortByInit, ...{[elem.datafield]: {direction: null, order: null}}};
                            }
                        } else {
                            if (SortByInit[elem.datafield].direction === "ascending") {
                                FinalSortIcon = SortIconAscActive;
                            } else if (SortByInit[elem.datafield].direction === "descending") {
                                FinalSortIcon = SortIconDescActive;
                            } else {
                                FinalSortIcon = SortIconAsc;
                            }
                            if (ActiveSortFilters > 1) {
                                SortIndex = SortByInit[elem.datafield].order;
                            }
                        }
                        SortComponent = (
                            <div className={classes.Header_Cell_Sort} onClick={(e) => HandleSortCol(elem.datafield, e)}>
                                {FinalSortIcon}
                                <div className={classes.Header_Cell_Sort_Order}>{SortIndex}</div>
                            </div>
                        );
                        /************* HEADER ROWS **********/
                        HeaderCell = (
                            <div key={uuidv4()} className={classes.Header_Cell} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Header_Cell_Text} style={{...Lockedstyles.Header_Cell_Text_Wrap, ...{marginRight: "10px"}}}>
                                    {caption}
                                </div>
                                {SortComponent}
                            </div>
                        );
                        /************* HEADER ROWS **********/
                    } else {
                        /************* HEADER ROWS **********/
                        HeaderCell = (
                            <div key={uuidv4()} className={classes.Header_Cell} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text_Wrap}>
                                    {caption}
                                </div>
                                {SortComponent}
                            </div>
                        );
                        /************* HEADER ROWS **********/
                    }

                    /************* SORTING COMPONENT **********/

                    HeaderCellComps.push(HeaderCell);

                    /************* FIXED HEADER ROWS **********/
                    //Fixed Cols
                    if (FixedCol === true) {
                        let FixedColElement = (
                            <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Fixed)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}>
                                    {caption}
                                </div>
                                {SortComponent}
                            </div>
                        );
                        FixedHeaderCellsComp.push(FixedColElement);
                    }
                    /************* FIXED HEADER ROWS **********/
                }
            });
            let HeaderRow = (
                <div key={uuidv4()} className={classes.Header_Row}>
                    {HeaderCellComps}
                </div>
            );
            let HeaderFixedRow = (
                <div key={uuidv4()} className={clsx(classes.Header_Row, classes.Header_Row_Fixed)}>
                    {FixedHeaderCellsComp}
                </div>
            );

            HeaderRowComps.push(HeaderRow);
            FixedHeaderRowComps.push(HeaderFixedRow);

            /************************* GENERATING THE HEADER ROWS ******************************/

            setHeaderRowsComponents(HeaderRowComps);
            setFixedHeaderRowComponents(FixedHeaderRowComps);
            setSortBy(SortByInit);
            if (InitialSorting !== false) {
                Ref_InitalSorting.current = InitialSorting;
            }
        }
    }, [TableData, SortBy]);

    const GenerateTableHeaderSearch = useCallback(() => {
        var SearchHeaderCellComps = [],
            HeaderRowComps = [],
            FixedSearchHeaderCellsComp = [],
            FixedHeaderRowComps = [],
            HasASearchElem = false;

        if (TableData) {
            var SearchByInit = SearchBy;

            //We only add a search col if the overall Searchable option is active
            if (TableOptionsFinal.Searchable === true) {
                //We need to determine first if there will be a search element based on the users inputs to add the fake cols for select and actions
                ColomnsInput.forEach((elem) => {
                    if (elem.searchable === true) {
                        HasASearchElem = true;
                    }
                });

                if (HasASearchElem === true) {
                    // We need to check if we have to add a fake select
                    if (TableOptionsFinal.Selectable === true) {
                        let SelectHeaderElem = (
                            <div key={uuidv4()} className={classes.Header_Cell} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px", padding: "0px"}}>
                                <div className={clsx(classes.Header_Cell_Text, classes.Header_Cell_Search)} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}></div>
                            </div>
                        );
                        SearchHeaderCellComps.push(SelectHeaderElem);
                    }

                    if (TableOptionsFinal.Selectable === true) {
                        //We need to add the selectable element
                        let SelectElem = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Header_Cell, classes.Header_Cell_Search, classes.Header_Cell_Fixed)}
                                style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}
                            >
                                ><div className={clsx(classes.Header_Cell_Text, classes.Header_Cell_Search)} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}></div>
                            </div>
                        );
                        FixedSearchHeaderCellsComp.push(SelectElem);
                    }

                    //We need to check if we need to add a fake action col

                    if (Ref_TotalActionButton.current > 0) {
                        var ActionWidth;
                        if (Ref_TotalActionButton.current === 1) {
                            ActionWidth = ActionColWidth.Button1;
                        } else if (Ref_TotalActionButton.current === 2) {
                            ActionWidth = ActionColWidth.Button2;
                        } else if (Ref_TotalActionButton.current === 3) {
                            ActionWidth = ActionColWidth.Button3;
                        }

                        let SelectHeaderElem = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Header_Cell, classes.Header_Cell_Search)}
                                style={{flex: `0 0 ${ActionWidth}`, width: ActionWidth, minWidth: ActionWidth, maxWidth: ActionWidth}}
                            >
                                <div className={classes.Header_Cell_Text} style={{...Lockedstyles.Header_Cell_Text, ...{fontSize: "17px", visibility: "hidden"}}}>
                                    Actions
                                </div>
                            </div>
                        );
                        SearchHeaderCellComps.push(SelectHeaderElem);
                        let SelectElem = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Header_Cell, classes.Header_Cell_Fixed)}
                                style={{flex: `0 0 ${ActionWidth}`, width: ActionWidth, minWidth: ActionWidth, maxWidth: ActionWidth}}
                            >
                                <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}>
                                    Actions
                                </div>
                            </div>
                        );
                        FixedSearchHeaderCellsComp.push(SelectElem);
                    }
                }

                ColomnsInput.forEach((elem) => {
                    //Checking if there are fixed Columns
                    let FixedCol = !elem.fixedCol || elem.fixedCol === false ? false : true;
                    /*Col Width*/
                    var FlexWidth, MinWidth, MaxWidth, Width;
                    if (elem.width) {
                        if (elem.width === "auto") {
                            FlexWidth = "1 1 auto";
                        } else {
                            FlexWidth = `0 0 ${elem.width}`;
                            MinWidth = elem.width;
                            MaxWidth = elem.width;
                            Width = elem.width;
                        }
                    } else {
                    }
                    /*Col Width*/
                    /************* SEARCHING COMPONENT **********/

                    var SearchHeaderCell, IsSearchableElem;
                    elem.searchable === false ? (IsSearchableElem = false) : (IsSearchableElem = true);

                    if (IsSearchableElem === true) {
                        //Checking if there is an active search
                        var InitalFilter;
                        if (!SearchByInit[elem.datafield]) {
                            //On Init, create the search filters
                            SearchByInit = {...SearchByInit, ...{[elem.datafield]: {column: elem.datafield, type: null, value: null, datatype: elem.datatype}}};
                        } else {
                            //Updating the Input filter if there is a value
                            InitalFilter = SearchByInit[elem.datafield];
                        }
                        SearchHeaderCell = (
                            <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Search)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <TableSearchInput
                                    Column={elem.datafield}
                                    WithClearIcon={true}
                                    WithFilters={TableOptionsFinal.SearchOptions.WithFilterMenu}
                                    InitialFilter={InitalFilter}
                                    DataType={elem.datatype}
                                    SearchBehavior={{SearchOnClick: TableOptionsFinal.SearchOptions.OnClickSearch_Only, MinCharacters: TableOptionsFinal.SearchOptions.MinCharSearch}}
                                    SearchValue={(filter) => {
                                        HandleSearch(filter);
                                    }}
                                />
                            </div>
                        );
                    } else {
                        SearchHeaderCell = (
                            <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Search)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <TableSearchInput
                                    IsFake={true}
                                    // Column={elem.datafield}
                                    // WithClearIcon={true}
                                    // WithFilters={TableOptionsFinal.SearchOptions.WithFilterMenu}
                                    // InitialFilter={InitalFilter}
                                    // DataType={elem.datatype}
                                    // SearchBehavior={{SearchOnClick: TableOptionsFinal.SearchOptions.OnClickSearch_Only, MinCharacters: TableOptionsFinal.SearchOptions.MinCharSearch}}
                                    // SearchValue={(filter) => {
                                    //     HandleSearch(filter);
                                    // }}
                                />
                            </div>
                        );
                    }
                    SearchHeaderCellComps.push(SearchHeaderCell);

                    /************* FIXED HEADER ROWS **********/
                    //Fixed Cols
                    if (FixedCol === true) {
                        let FixedColElement = (
                            <div
                                key={uuidv4()}
                                className={clsx(classes.Header_Cell, classes.Header_Cell_Search, classes.Header_Cell_Fixed)}
                                style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}
                            >
                                <TableSearchInput />
                            </div>
                        );
                        FixedSearchHeaderCellsComp.push(FixedColElement);
                    }
                    /************* FIXED HEADER ROWS **********/

                    /************* SEARCHING COMPONENT **********/
                });

                var SearchHeaderRow, FixedSearchHeaderRow;
                if (SearchHeaderCellComps.length > 0) {
                    SearchHeaderRow = <div className={clsx(classes.Header_Row, classes.Header_Row_Search)}>{SearchHeaderCellComps}</div>;
                }
                if (FixedSearchHeaderCellsComp.length > 0) {
                    FixedSearchHeaderRow = <div className={clsx(classes.Header_Row, classes.Header_Row_Search, classes.Header_Row_Fixed)}>{FixedSearchHeaderCellsComp}</div>;
                }

                HeaderRowComps.push(SearchHeaderRow);
                FixedHeaderRowComps.push(FixedSearchHeaderRow);
                /************************* GENERATING THE HEADER ROWS ******************************/
                setHeaderSearchRowsComponents(HeaderRowComps);
                setFixedHeaderSearchRowsComponents(FixedHeaderRowComps);
                setSearchBy(SearchByInit);
            }
        }
    }, [TableData, SearchBy]);

    const GenerateFooter = useCallback(() => {
        if (TableData) {
            var TotalRecords = TableData.length;

            var PagerComp = [];

            for (var i = 0; i < TableOptionsFinal.PagerOptions.AllowedPageSizes.length; i++) {
                let TempPageSize = TableOptionsFinal.PagerOptions.AllowedPageSizes[[i]];

                if (PageSize) {
                    if (TableOptionsFinal.PagerOptions.AllowedPageSizes[i] === PageSize) {
                        PagerComp.push(
                            <div
                                key={uuidv4()}
                                data_page_range={TempPageSize}
                                className={clsx(classes.Pager_Page_Sizes_Button, classes.Pager_Page_Sizes_Button_Active)}
                                onClick={() => HandlePagerPageSizeChange(TempPageSize)}
                            >
                                {TableOptionsFinal.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    } else {
                        PagerComp.push(
                            <div key={uuidv4()} data_page_range={TempPageSize} className={clsx(classes.Pager_Page_Sizes_Button)} onClick={() => HandlePagerPageSizeChange(TempPageSize)}>
                                {TableOptionsFinal.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    }
                } else {
                    //INIT
                    if (TableOptionsFinal.PagerOptions.AllowedPageSizes[i] === TableOptionsFinal.PagerOptions.DefaultPageSize) {
                        PagerComp.push(
                            <div
                                key={uuidv4()}
                                data_page_range={TempPageSize}
                                className={clsx(classes.Pager_Page_Sizes_Button, classes.Pager_Page_Sizes_Button_Active)}
                                onClick={() => HandlePagerPageSizeChange(TempPageSize)}
                            >
                                {TableOptionsFinal.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    } else {
                        PagerComp.push(
                            <div key={uuidv4()} data_page_range={TempPageSize} className={clsx(classes.Pager_Page_Sizes_Button)} onClick={() => HandlePagerPageSizeChange(TempPageSize)}>
                                {TableOptionsFinal.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    }
                }
            }

            var TotalPagesTemp;

            if (PageSize) {
                TotalPagesTemp = Math.ceil(parseInt(TotalRecords) / parseInt(PageSize));
            } else {
                TotalPagesTemp = Math.ceil(parseInt(TotalRecords) / parseInt(TableOptionsFinal.PagerOptions.DefaultPageSize));
            }

            var SelectorPages = [];

            if (PageSelected) {
                for (var j = 0; j < TotalPagesTemp; j++) {
                    var PageSelElem;
                    var TempPageSelected = j + 1;
                    if (TempPageSelected === parseInt(PageSelected)) {
                        PageSelElem = (
                            <div
                                key={uuidv4()}
                                data-page-selected={TempPageSelected}
                                onClick={(e) => HandlePagerPageSelectorChange(e)}
                                className={clsx(classes.Pager_Page_Selector_Indicator, classes.Pager_Page_Selector_Indicator_Active)}
                            >
                                {parseInt(j + 1)}
                            </div>
                        );
                    } else {
                        PageSelElem = (
                            <div key={uuidv4()} data-page-selected={TempPageSelected} onClick={(e) => HandlePagerPageSelectorChange(e)} className={classes.Pager_Page_Selector_Indicator}>
                                {parseInt(j + 1)}
                            </div>
                        );
                    }
                    SelectorPages.push(PageSelElem);
                }
            }

            var PageSelectorComp;
            if (TotalPagesTemp === 1) {
                PageSelectorComp = <React.Fragment>{SelectorPages}</React.Fragment>;
            } else {
                PageSelectorComp = (
                    <React.Fragment>
                        <div onClick={() => HandlePagerPageArrow("Previous")} className={classes.Pager_Page_Selector_Indicator_Arrow}>
                            <ArrowSimpleLeft IconSize="20px" SVGStrokeWidth="10px" />
                        </div>
                        {SelectorPages}
                        <div onClick={() => HandlePagerPageArrow("Next")} className={classes.Pager_Page_Selector_Indicator_Arrow}>
                            <ArrowSimpleRight IconSize="20px" SVGStrokeWidth="10px" />
                        </div>
                    </React.Fragment>
                );
            }
            setTotalRows(TotalRecords);
            setTotalPages(TotalPagesTemp);
            setPageRangeComponents(PagerComp);
            if (!PageSize) {
                setPageSize(TableOptionsFinal.PagerOptions.DefaultPageSize);
            }

            setPageSelectorsComponents(PageSelectorComp);
            if (!PageSelected) setPageSelected(1);
        }
    }, [TableData, PageSize, PageSelected]);

    const UpdateTableDimensions = useCallback(() => {
        if (Ref_DataScrollContainer.current) {
            let BodyHeight = Ref_DataScrollContainer.current.clientHeight;
            setTableBodyHeight(BodyHeight + "px");
        }
    }, [Ref_DataScrollContainer, BodyRowsComponents]);

    const GenerateToolbar = useCallback(() => {
        console.log("Generating the table toolbar");
        if (RowSelected && RowSelected.length > 0) {
            setShowTopToolbar(true);

            //Check if there is a custom toolbar button to show onSelect;
            var CustomSelectButton;
            if (TableOptions.ToolBar && TableOptions.ToolBar.OnSelect && TableOptions.ToolBar.OnSelect.CustomButton) {
                CustomSelectButton = TableOptions.ToolBar.OnSelect.CustomButton(Ref_RowSelected_Data.current);

                setRightToolBarComponent(CustomSelectButton);
            } else {
                setRightToolBarComponent(null);
            }

            // ToolBar: {
            // 	OnSelect: {
            // 		CustomButton: (args) => {
            // 			console.log("args", args);
            // 			return <div>hello</div>;
            // 		},
            // 		Position : "center" //left, center, right
            // 	},
            // },

            const DeleteButtonComponent = (
                <div className={classes.Toolbar_DeleteButton}>
                    <Button Width="120px" Height="30px" style={{margin: "0px", padding: "1px 5px"}} onClick={HandleDeleteSelected}>{`Delete (${RowSelected.length})`}</Button>
                </div>
            );
            setLeftToolBarComponent(DeleteButtonComponent);
        } else {
            setLeftToolBarComponent(null);
        }
    }, [RowSelected]);

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Calculating all the table dimensions at start
    useEffect(() => {
        // console.log("Table_Main :  Dimensions", TableDimensions)
        // setTableDimensionsFinal({Width: TableDimensions.width, Height: TableDimensions.height});
        setTableBodyFinal({Width: parseInt(TableDimensions.width) - 2 + "px", Height: parseInt(TableDimensions.height) - 2 + "px"});
    }, [TableDimensions]);

    //Generating the Table Data and the fixed components
    useEffect(() => {
        GenerateTableData();
    }, [Data, MainDataSource, ColomnsInput]);

    useEffect(() => {
        GenerateTable();
        GenerateTableHeader();
        GenerateToolbar();
        // GenerateTableHeaderSearch();
    }, [TableData]);

    //Updating the search row with the fake button when the button number is calculated
    useEffect(() => {
        GenerateTableHeaderSearch();
    }, [Ref_TotalActionButton.current]);

    //Generating the Table Body
    useEffect(() => {
        GenerateTable();
    }, [TableData, PageSize, PageSelected, RowSelected, RowClickedId]);

    //Generating the Toolbar
    useEffect(() => {
        GenerateToolbar();
    }, [GenerateToolbar, RowSelected]);

    useEffect(() => {
        GenerateFooter();
    }, [GenerateFooter]);

    useEffect(() => {
        GenerateTableHeader();
    }, [SortBy, GenerateTableHeader]);

    //Calculating the height of the ScrollContainer without the Horizontal Scrollbar to fix the Height of the Fixed_Cols_Data_Scroll_Wrapper
    useEffect(() => {
        UpdateTableDimensions();
    }, [UpdateTableDimensions]);

    useEffect(() => {
        window.addEventListener("resize", UpdateTableDimensions);
        return function cleanup() {
            window.removeEventListener("resize", UpdateTableDimensions);
        };
    }, [UpdateTableDimensions]);

    //Adding Scroll Events to the table Scrollable Container to also scroll the Header AND the fixed Column content
    useEffect(() => {
        if (Ref_DataScrollContainer.current) {
            Ref_DataScrollContainer.current.addEventListener("scroll", UpdateScroll);
            return () => {
                if (Ref_DataScrollContainer.current) {
                    Ref_DataScrollContainer.current.removeEventListener("scroll", UpdateScroll);
                }
            };
        }
    }, [Ref_DataScrollContainer]);

    //Generating the inital table sort based on the user inputs
    useEffect(() => {
        if (Ref_InitalSorting.current) {
            //We kick the sorting by simulating a click
            HandleSortCol(Ref_InitalSorting.current, null);
        }
    }, [Ref_InitalSorting.current]);

    //Sharing the data with the parent component
    useEffect(() => {
        if (ShareTableData && typeof ShareTableData === "function") {
            ShareTableData(TableData);
        }
    }, [TableData]);

    //Uptading the table when the parent component request to update the data
    useEffect(() => {
        if (UpdateDataFromOutisde) {
            setTableData(UpdateDataFromOutisde);
        }
    }, [UpdateDataFromOutisde]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const HandleSortCol = (field, event) => {
        let SortedData = [...TableData];
        var UpdatedSortedBy = SortBy;

        let SortKeys = _.keys(UpdatedSortedBy);

        let maxOrder = ActiveSortFilters;

        if (SortKeys.length > 0) {
            SortKeys.forEach((SortKey) => {
                if (event && event.shiftKey) {
                    //Shift key pressed
                    if (TableOptionsFinal.SortOptions.MultiSearch === true) {
                        if (SortKey === field) {
                            if (UpdatedSortedBy[SortKey].direction) {
                                //Change the direction
                                if (UpdatedSortedBy[SortKey].direction === "ascending") {
                                    UpdatedSortedBy[SortKey].direction = "descending";
                                } else {
                                    UpdatedSortedBy[SortKey].direction = "ascending";
                                }
                            } else {
                                //add the colum
                                UpdatedSortedBy[SortKey].direction = "ascending";
                                maxOrder = maxOrder + 1;
                                UpdatedSortedBy[SortKey].order = maxOrder;
                            }
                        }
                    }
                } else {
                    //Normal key pressed -> change the filter column or change the direction
                    maxOrder = 1;
                    if (SortKey === field) {
                        if (UpdatedSortedBy[SortKey].direction) {
                            //Change the direction
                            if (UpdatedSortedBy[SortKey].direction === "ascending") {
                                UpdatedSortedBy[SortKey].direction = "descending";
                            } else {
                                UpdatedSortedBy[SortKey].direction = "ascending";
                            }
                        } else {
                            //sort by this column
                            UpdatedSortedBy[SortKey].direction = "ascending";
                            UpdatedSortedBy[SortKey].order = 1;
                        }
                    } else {
                        //Put all the other fields to null
                        UpdatedSortedBy[SortKey].direction = null;
                        UpdatedSortedBy[SortKey].order = null;
                    }
                }
            });
        }

        //SortBy is not an array but an object, we nee to create an array from it and sort it to generate the rigth sort order
        let KeysUpdatedSortedBy = _.keysIn(UpdatedSortedBy);

        var ArrayUpdatedSortedBy = [];
        KeysUpdatedSortedBy.forEach((key) => {
            if (UpdatedSortedBy[key].direction) {
                ArrayUpdatedSortedBy.push({field: key, direction: UpdatedSortedBy[key].direction, order: UpdatedSortedBy[key].order});
            }
        });

        const OrderedArrayUpdatedSortedBy = _.orderBy(ArrayUpdatedSortedBy, ["order"], ["asc"]);
        var _SortArrray = [],
            _SortDirection = [];

        OrderedArrayUpdatedSortedBy.forEach((elem) => {
            _SortArrray.push(`rowData.${elem.field}`);
            if (elem.direction === "descending") {
                _SortDirection.push("desc");
            } else {
                _SortDirection.push("asc");
            }
        });

        var FinalSortedData = _.orderBy(SortedData, _SortArrray, _SortDirection);

        //Updating the state
        setSortBy(UpdatedSortedBy);
        setTableData(FinalSortedData);
        setActiveSortFilters(maxOrder);
    };

    const HandleSearch = (filter) => {
        // filter ={column :"datafieldname", type : "contains", value : "filter value", datatype:"number"}
        let SearchedData = [...TableData];
        var UpdatedSearchBy = SearchBy;
        //Update the search
        UpdatedSearchBy[filter.column] = filter;

        let SearchKeys = _.keys(UpdatedSearchBy);

        if (SearchKeys.length > 0 && SearchedData.length > 0) {
            for (var i = 0; i < SearchedData.length; i++) {
                let ShowRow = true;
                SearchKeys.forEach((SearchKey) => {
                    let SearchValue = UpdatedSearchBy[SearchKey].value;

                    if (SearchValue) {
                        //if there is an active search value
                        let ColValue = SearchedData[i]["rowData"][SearchKey];

                        if (UpdatedSearchBy[SearchKey].datatype === "number") {
                            //Number
                            let FilterType = filter.type;

                            if (!FilterType) {
                                if (ColValue.includes(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "Equal") {
                                if (parseFloat(ColValue) === parseFloat(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "Different") {
                                if (parseFloat(ColValue) !== parseFloat(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "Greater") {
                                if (parseFloat(ColValue) > parseFloat(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "GreaterEqual") {
                                if (parseFloat(ColValue) >= parseFloat(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "Less") {
                                if (parseFloat(ColValue) < parseFloat(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "LessEqual") {
                                if (parseFloat(ColValue) <= parseFloat(SearchValue)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            }
                        } else {
                            //Text
                            let FilterType = filter.type;
                            if (!FilterType || FilterType === "Contains") {
                                if (ColValue.toLowerCase().includes(SearchValue.toLowerCase())) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "DoesNotContain") {
                                let reg = new RegExp(`^(?:(?!${SearchValue}).)*$\r?\n?`, "g");
                                if (ColValue.match(reg)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "StartsWith") {
                                let reg = new RegExp(`^${SearchValue}`, "g");
                                if (ColValue.match(reg)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "EndsWith") {
                                let reg = new RegExp(`${SearchValue}$`, "g");
                                if (ColValue.match(reg)) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "Equal") {
                                if (ColValue === SearchValue) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            } else if (FilterType === "Different") {
                                if (ColValue !== SearchValue) {
                                    if (ShowRow === true) {
                                        ShowRow = true;
                                    }
                                } else {
                                    ShowRow = false;
                                }
                            }
                        }
                    } else {
                        if (ShowRow === true) {
                            ShowRow = true;
                        }
                    }
                });
                SearchedData[i].showRow = ShowRow;
            }
        }

        //At the end we reput the focus on the input that was called
        if (filter.Ref_Input && filter.Ref_Input.current) {
            // filter.Ref_Input.current.focus();
        }
        setTableData(SearchedData);
        setSearchBy(UpdatedSearchBy);
    };

    const HandlePagerPageSizeChange = (RangeSel) => {
        setPageSize(RangeSel);
        setPageSelected(1);
    };
    const HandlePagerPageSelectorChange = (event) => {
        let Selected = event.target.getAttribute("data-page-selected");
        setPageSelected(Selected);
    };
    const HandlePagerPageArrow = (direction) => {
        if (direction === "Previous") {
            if (PageSelected > 1) {
                setPageSelected(PageSelected - 1);
            }
        } else {
            if (PageSelected < TotalPages) {
                setPageSelected(PageSelected + 1);
            }
        }
    };

    const HandleSelectAll = (value) => {
        if (value === false) {
            Ref_RowSelected_Data.current = [];
            setRowSelected([]);
            setShowTopToolbar(false);
        } else {
            if (TableData.length > 0) {
                let RowSelectedUpdated = [];
                for (var i = 0; i < TableData.length; i++) {
                    RowSelectedUpdated.push({Id: TableData[i]["rowData"]._id});
                }
                Ref_RowSelected_Data.current = RowSelectedUpdated;
                setRowSelected(RowSelectedUpdated);
            }
        }
    };
    const HandleSelectRow = (Row, value) => {
        let RowSelectedUpdated = [];

        let RowId = Row.rowData._id;

        if (Ref_RowSelected_Data.current && Ref_RowSelected_Data.current.length > 0) {
            var Handled = false;
            for (var i = 0; i < Ref_RowSelected_Data.current.length; i++) {
                if (RowId === Ref_RowSelected_Data.current[i].Id) {
                    if (value === true) {
                        //Keeping it into the Selection
                        RowSelectedUpdated.push(Ref_RowSelected_Data.current[i]);
                        Handled = true;
                    } else {
                    }
                } else {
                    //Pushing the existing selection
                    RowSelectedUpdated.push(Ref_RowSelected_Data.current[i]);
                }
            }

            if (Handled === false) {
                //Adding it to the selection
                if (value === true) {
                    RowSelectedUpdated.push({Id: RowId});
                }
            }
        } else {
            //Adding it to the selection
            if (value === true) {
                RowSelectedUpdated.push({Id: RowId});
            }
        }
        Ref_RowSelected_Data.current = RowSelectedUpdated;
        if (RowSelectedUpdated.length === 0) {
            setRowSelected([]);
            setShowTopToolbar(false);
        } else {
            setRowSelected(RowSelectedUpdated);
        }
    };

    const HandleDeleteSelected = () => {
        //We need to repeat the entire deletion
        console.log("Delete Rows");
    };

    const HandleDeleteRow = (RowData) => {
        let Id = RowData.rowData._id;

        setModalMessage({Action: "Delete", Id: Id});
        setShowModalMessage(true);
    };
    const HandleCloseCard = () => {
        setShowModalMessage(false);
        setModalMessage(null);
    };

    const HandleModalMessageClose = (action) => {
        if (action.Action === "Delete") {
            CRUDAction({type: "DeleteOne", Id: action.Id});
            setShowModalMessage(false);
            setModalMessage(null);
        }
    };

    const HandleRowClick = (RowData) => {
        const WasClickedId = TableOptionsFinal.ClickRow.Action(RowData);

        setRowClickedId(WasClickedId);
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var FixedColVisibility;
    if (FixedCols === false) {
        FixedColVisibility = {display: "none"};
    }

    return (
        <div className={classes.Grid}>
            <TableMessages ShowCard={ShowModalMessage} Action={ModalMessage} Output={HandleModalMessageClose} HandleCloseCard={HandleCloseCard} />

            <div className={classes.Grid_TopToolbar} style={{display: `${ShowTopToolbar ? "flex" : "none"}`}}>
                <div className={classes.Grid_TopToolbar_Content}>
                    <div className={classes.Grid_TopToolbar_Content_Left}>{LeftToolBarComponent}</div>
                    <div className={classes.Grid_TopToolbar_Content_Middle}></div>
                    <div className={classes.Grid_TopToolbar_Content_Right}>{RightToolBarComponent}</div>
                </div>
            </div>
            <div className={classes.Grid_Container}>
                <div ref={Ref_TableHeader} className={classes.Grid_Header}>
                    <div className={classes.Header_Scroll_Wrapper}>
                        <div
                            ref={Ref_HeaderScrollContainer}
                            className={classes.Header_Scroll_Container}
                            //We need to calculate the height based on the Data_Scroll_Content element
                            style={{left: HeaderScrollLeft + "px", width: DataScrollContentWidth}}
                        >
                            <div className={classes.Header_Scroll_Content}>
                                {HeaderRowsComponents}
                                {HeaderSearchRowsComponents}
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={Ref_GridBody} className={classes.Grid_Body}>
                    {NoDataComponent}
                    <div className={classes.Data_Scroll_Wrapper}>
                        <div ref={Ref_DataScrollContainer} className={classes.Data_Scroll_Container}>
                            <div ref={Ref_DataScrollContent} className={classes.Data_Scroll_Content}>
                                {BodyRowsComponents}
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={Ref_TableFooter} className={classes.Grid_Footer}>
                    <div className={classes.Pager}>
                        <div className={classes.Pager_Page_Sizes}>{PageRangeComponents}</div>
                        <div className={classes.Pager_Page_Selector}>
                            <div className={classes.Pager_Page_Selector_Info}> {`Page ${PageSelected} of ${TotalPages} (${TotalRows} items)`}</div>
                            <div className={classes.Pager_Page_Selector_Indicators}>{PageSelectorsComponents}</div>
                        </div>
                    </div>
                </div>
                <div className={classes.Grid_Fixed_Cols} style={FixedColVisibility}>
                    <div className={classes.Grid_Fixed_Cols_Header}>
                        {FixedHeaderRowComponents}
                        {FixedHeaderSearchRowsComponents}
                    </div>
                    <div className={classes.Grid_Fixed_Cols_Data_Scroll_Wrapper} style={{height: TableBodyHeight}}>
                        <div
                            className={classes.Grid_Fixed_Cols_Data_Scroll_Container}
                            style={{
                                top: `${FixedColVerticalTop}px`,
                            }}
                        >
                            <div className={classes.Grid_Fixed_Cols_Data_Scroll_Content}>{FixedRowsComponents}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    /****************************** RENDER *********************/
});

TableMain.defaultProps = {
    Data: null,
    MainDataSource: null,
    ColomnsInput: null,
    TableDimensions: {width: "800px", height: "500px"},
    TableOptions: {
        Searchable: true,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        Selectable: true,
    },
};

TableMain.propTypes = {
    /**
     * Data : The source for the data
     * {main : [{_id : "id", company : "test" }]
     * secondary :[{_id : "id", label :"", value : ""}]
     * }
     *
     */
    Data: PropTypes.any,
    /**
     * MainDataSource : The name of the source for the table
     * MainDataSource : "main"
     *
     */
    MainDataSource: PropTypes.any,
    /**
     * ColomnsInput : The columns definition to create the table
     * [
     * {width : "100%", minWidth : "50px", caption : "caption", datafield : "col1"}
     * ]
     */
    ColomnsInput: PropTypes.any,
    /**
     * TableDimensions
     */
    TableDimensions: PropTypes.any,
    /**
     * TableOptions
     */
    TableOptions: PropTypes.any,
};

export default TableMain;
