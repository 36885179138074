
/**
 * ScrollToRef is a utility tool that Scroll to a component using a ref
 * It works with native scrollbars and with perfectScrollbar
 */
const ScrollToRef = (InputRef) => {

	//Check if PerfectScrollBar is Active
    let IsperfectScrollbarActive = InputRef.current.closest(".scrollbar-container")

  if (IsperfectScrollbarActive){
	return InputRef.current.scrollIntoView();
  }else {
	return InputRef.current.scrollIntoView({behavior: "smooth"});
  }
};
export default ScrollToRef;
