import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Artibulles_Cis_Gage: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
    },
    GageIndicatorText: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        // transform: "translate(0,-50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    GageFixed: {
        imageRendering: "crisp-edges",
    },
    GageIndicator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageTicks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageTicksText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
        fontSize: "18px",
        fontFamily: "roboto",
        fontWeight: "300",
    },
    GageNeedle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
}));

/**
 * Complex Gage display with multiple Indicators
 *
 */

const CircularGage = React.forwardRef(function CircularGage(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        ExternalDiameter,
        Thickness,
        Value,
        TickMarkIndicator,
        GageTriggers,
        SeparateWarningColor,
        WithOuterText,
        WithInnerText,
        GageCentralText,
        EmptyColor,
        GageTickTextOuterfontSize,
        GageTickTextOuterPaddingInner,
        GageTickTextInnerfontSize,
        GageTickTextInnerPaddingOuter,
        GageTickOverflow,
        GageTickColor,
        GageStartAngle,
        MaxValue,
        GageCustomCentralText,

        //DontFold,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** CONST *********************/

    /******** MERGING THE INPUTS TO PREVENT ERRORS *******/

    var GageTriggersInitial = {
        Trigger1: {trigger: 30, colorBelow: "green", colorAbove: "orange"},
        Trigger2: {trigger: 80, colorAbove: "red"},
    };
    var GageTriggersFinal = _.merge(GageTriggersInitial, GageTriggers);

    /******** MERGING THE INPUTS TO PREVENT ERRORS *******/

    const SVGRatioReal_VBox = parseFloat(ExternalDiameter) / 100;
    var SVGDimensions = parseFloat(SVGRatioReal_VBox) * 100;
    var RealThickness = Thickness / SVGRatioReal_VBox;

    var RadiusInternal = (100 - RealThickness) / 2;

    const CircleCirconf = 2 * Math.PI * RadiusInternal;

    /******** GAGE TICKS ELEMENTS *******/
    const GageTickThickness = 1;

    const RealGageTickOverflow = parseFloat(GageTickOverflow) / SVGRatioReal_VBox;
    const GageTicksSVGVBoxDimensions = 100 + 2 * RealGageTickOverflow;
    const GageTicksSVGDimensions = GageTicksSVGVBoxDimensions * SVGRatioReal_VBox;

    const GageTickWidth = RealThickness + 2 * RealGageTickOverflow;
    const GageTickX = GageTicksSVGVBoxDimensions - RealThickness - 2 * RealGageTickOverflow;
    const GageTickY = GageTicksSVGVBoxDimensions / 2 - GageTickThickness / 2;
    /******** GAGE TICKS ELEMENTS *******/

    /******** GAGE TICKS TEXT ELEMENTS *******/
    const GageTickTextOuterfontSizeReal = parseFloat(GageTickTextOuterfontSize) / SVGRatioReal_VBox;
    const GageTickTextOuterPaddingInnerReal = parseFloat(GageTickTextOuterPaddingInner) / SVGRatioReal_VBox;
    const GageTickTextInnerfontSizeReal = parseFloat(GageTickTextInnerfontSize) / SVGRatioReal_VBox;
    const TextWithCoef = 0.75;

    const GageTickTextOuterWidth = (parseFloat(GageTickTextOuterfontSize) * 5 * TextWithCoef) / SVGRatioReal_VBox;

    const GageTickTextSVGBoxDimensions = GageTicksSVGVBoxDimensions + 2 * (GageTickTextOuterWidth + GageTickTextOuterPaddingInnerReal);
    const GageTickTextSVGDimensions = GageTickTextSVGBoxDimensions * SVGRatioReal_VBox;

    const GageTickTextInnerWidth = (parseFloat(GageTickTextInnerfontSize) * 5 * TextWithCoef) / SVGRatioReal_VBox;

    /******** GAGE TICKS TEXT ELEMENTS *******/

    /******** CALCULATING THE VALUE AND COLOR OF THE GAUGE*******/
    var FilledColorFinal, StrokeDashArray, StrokeDashOffset;
    var FilledColorFinal2, StrokeDashArray2, StrokeDashOffset2;
    var FilledColorFinal3, StrokeDashArray3, StrokeDashOffset3;

    let Trigger1 = GageTriggersFinal.Trigger1.trigger;
    let Trigger2 = GageTriggersFinal.Trigger2.trigger;

    FilledColorFinal = GageTriggersFinal.Trigger1.colorBelow;
    FilledColorFinal2 = GageTriggersFinal.Trigger1.colorAbove;
    FilledColorFinal3 = GageTriggersFinal.Trigger2.colorAbove;

    // let ValuePercentage = (Value / MaxValue) * 100;

    var ValPercent = (Value / parseFloat(MaxValue)) * 100;

    let RotationAngle = (360 * ValPercent) / 100;

    var ShowCircle2 = "none";
    var ShowCircle3 = "none";

    if (ValPercent >= Trigger2) {
        StrokeDashOffset = `${(CircleCirconf * 25) / 100}`;
        StrokeDashArray = `${(CircleCirconf * ValPercent) / 100}, ${(CircleCirconf * (100 - ValPercent)) / 100}`;
        ShowCircle2 = "none";
        ShowCircle3 = "none";

        FilledColorFinal = GageTriggersFinal.Trigger2.colorAbove;

        // StrokeDashOffset = `${(CircleCirconf * 25) / 100}`;
        // StrokeDashArray = `${(CircleCirconf * Trigger1) / 100}, ${(CircleCirconf * (100 - Trigger1)) / 100}`;

        // StrokeDashOffset2 = `${(-CircleCirconf * (Trigger1 - 25)) / 100}`;
        // StrokeDashArray2 = `${(CircleCirconf * (Trigger2 - Trigger1)) / 100}, ${(CircleCirconf * (100 - (Trigger2 - Trigger1))) / 100}`;

        // StrokeDashOffset3 = `${(-CircleCirconf * (Trigger2 - 25)) / 100}`;
        // StrokeDashArray3 = `${(CircleCirconf * (ValPercent - Trigger2)) / 100}, ${(CircleCirconf * (100 - (ValPercent - Trigger2))) / 100}`;
        // ShowCircle2 = "flex";
        // ShowCircle3 = "flex";
    } else if (ValPercent > Trigger1) {
        StrokeDashOffset = `${(CircleCirconf * 25) / 100}`;
        StrokeDashArray = `${(CircleCirconf * Trigger1) / 100}, ${(CircleCirconf * (100 - Trigger1)) / 100}`;

        StrokeDashOffset2 = `${(-CircleCirconf * (Trigger1 - 25)) / 100}`;
        StrokeDashArray2 = `${(CircleCirconf * (ValPercent - Trigger1)) / 100}, ${(CircleCirconf * (100 - (ValPercent - Trigger1))) / 100}`;
        ShowCircle2 = "flex";
        ShowCircle3 = "none";
    } else {
        StrokeDashOffset = `${(CircleCirconf * 25) / 100}`;
        StrokeDashArray = `${(CircleCirconf * ValPercent) / 100}, ${(CircleCirconf * (100 - ValPercent)) / 100}`;
        ShowCircle2 = "none";
        ShowCircle3 = "none";
    }

    /******** CALCULATING THE VALUE AND COLOR OF THE GAUGE*******/

    /****************************** CONST *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** STATE *********************/

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var TickIndicatorComponent = [];
    if (TickMarkIndicator) {
        let GageTickColor = TickMarkIndicator.Color ? TickMarkIndicator.Color : "white";

        TickIndicatorComponent.push(
            <rect
                key={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${RotationAngle - 90} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            />
        );
        TickIndicatorComponent.push(
            <rect
                key={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill="black"
                transform={`rotate(${-90} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            />
        );
    }

    /****************************** CENTRAL GAUGE TEXT *********************/
    var GageCentraltextMain, GageCentraltextSub, GageCustomCentralTextComp;
    if (GageCustomCentralText) {
        GageCustomCentralTextComp = GageCustomCentralText;
    } else {
        if (GageCentralText.MainText === "Percent") {
            GageCentraltextMain = ValPercent + "%";
        } else if (GageCentralText.MainText === "Value") {
            GageCentraltextMain = Value;
        } else {
            GageCentraltextMain = GageCentralText.MainText;
        }
        if (GageCentralText.SubText === "Value") {
            GageCentraltextSub = Value;
        } else {
            GageCentraltextSub = GageCentralText.SubText;
        }
    }

    // <div className={classes.Artibulles_Cis_Gage} ref={ref} style={{position: "relative", width: `${GageTickTextSVGDimensions}px`, height: `${GageTickTextSVGDimensions}px`}}></div>
    /****************************** CENTRAL GAUGE TEXT *********************/
    return (
        <div className={classes.Artibulles_Cis_Gage} ref={ref} style={{position: "relative"}}>
            <div className={classes.GageFixed}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r={`${RadiusInternal}`} strokeWidth={RealThickness} fill="none" stroke={EmptyColor} shapeRendering="geometricPrecision"></circle>
                </svg>
            </div>
            <div className={classes.GageIndicator}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle
                        shapeRendering="geometricPrecision"
                        // transform="rotate(-90) translate(-100 0)"
                        cx="50"
                        cy="50"
                        r={`${RadiusInternal}`}
                        strokeWidth={RealThickness}
                        fill="none"
                        stroke={FilledColorFinal}
                        strokeDasharray={StrokeDashArray}
                        strokeDashoffset={StrokeDashOffset}
                        // strokeDasharray={`${(CircleCirconf * 60) / 100}, ${(CircleCirconf * 40) / 100}`}
                        // strokeDashoffset={`${CircleCirconf*(-25)/100}`}
                    ></circle>
                </svg>
            </div>
            <div className={classes.GageIndicator} style={{display: ShowCircle2}}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle
                        shapeRendering="geometricPrecision"
                        // transform="rotate(-90) translate(-100 0)"
                        cx="50"
                        cy="50"
                        r={`${RadiusInternal}`}
                        strokeWidth={RealThickness}
                        fill="none"
                        stroke={FilledColorFinal2}
                        strokeDasharray={StrokeDashArray2}
                        strokeDashoffset={StrokeDashOffset2}
                        // strokeDasharray={`${(CircleCirconf * 60) / 100}, ${(CircleCirconf * 40) / 100}`}
                        // strokeDashoffset={`${CircleCirconf*(-25)/100}`}
                    ></circle>
                </svg>
            </div>
            <div className={classes.GageIndicator} style={{display: ShowCircle3}}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle
                        shapeRendering="geometricPrecision"
                        // transform="rotate(-90) translate(-100 0)"
                        cx="50"
                        cy="50"
                        r={`${RadiusInternal}`}
                        strokeWidth={RealThickness}
                        fill="none"
                        stroke={FilledColorFinal3}
                        strokeDasharray={StrokeDashArray3}
                        strokeDashoffset={StrokeDashOffset3}
                        // strokeDasharray={`${(CircleCirconf * 60) / 100}, ${(CircleCirconf * 40) / 100}`}
                        // strokeDashoffset={`${CircleCirconf*(-25)/100}`}
                    ></circle>
                </svg>
            </div>
            <div className={classes.GageTicks} style={{zIndex: 20}}>
                <svg
                    style={{display: "block", imageRendering: "optimizequality"}}
                    width={`${GageTicksSVGDimensions}`}
                    height={`${GageTicksSVGDimensions}`}
                    viewBox={`0 0 ${GageTicksSVGVBoxDimensions} ${GageTicksSVGVBoxDimensions}`}
                >
                    {TickIndicatorComponent}
                </svg>
            </div>

            <div className={classes.GageIndicatorText}>
                <div className={classes.GageIndicatorText_Main} style={{fontSize: GageCentralText.MainFontSize}}>
                    {GageCentraltextMain}
                </div>
                <div className={classes.GageIndicatorText_Sub} style={{fontSize: GageCentralText.SubFontSize}}>
                    {GageCentraltextSub}
                </div>
                {GageCustomCentralTextComp ? GageCustomCentralTextComp : null}
            </div>
        </div>
    );
    /****************************** RENDER *********************/
});

CircularGage.defaultProps = {
    ExternalDiameter: 100,
    Thickness: 10,
    Value: null,
    GageStartAngle: 90,
    GageCentralText: {
        MainText: null,
        MainFontSize: "18px",
        SubText: null,
        SubFontSize: "16px",
    },

    GageTriggers: {
        Perc100: {trigger: null, colorBelow: "purple", colorAbove: "green"},
        PercMax: {trigger: null, colorAbove: "red"},
        Warning: {triggerPercentage: null, color: "Orange"},
    },
    SeparateWarningColor: false,
    WithOuterText: true,
    WithInnerText: true,
    CentralIndicator: null,
    EmptyColor: "grey",
    GageTickTextOuterfontSize: "15px",
    GageTickTextOuterPaddingInner: 10,
    GageTickTextInnerfontSize: "15px",
    GageTickTextInnerPaddingOuter: 0.5,
    GageTickOverflow: 5,
    GageTickColor: "black",
    MaxValue: 100,
    GageCustomCentralText: null,
};

CircularGage.propTypes = {
    /**
     * ExternalRadius :
     */
    ExternalDiameter: PropTypes.any,
};

export default CircularGage;
