import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import {PDF} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },

    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        width: "620px",
        border: "1px solid grey",
        padding: "10px 10px",
        height: "100%",
        color: "black",
		borderRadius : "5px",
        // margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "3px 10px 3px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {Invoices_File_Path, Customers, PassedFormValues} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    const [FormComponent, setFormComponent] = useState();
    const [Resend, setResend] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    const GenerateFormComponent = useCallback(() => {
        let AccountantStatus = PassedFormValues.AccountantStatus;
        if (AccountantStatus) {
            let FormComp;
            FormComp = (
                <React.Fragment>
                    <div className={classes.FormWrapper}>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 540px"}}>
                                <FormCompontent
                                    Name="ref_Customer"
                                    InitialValue={InitialFormValues.ref_Customer ? InitialFormValues.ref_Customer : null}
                                    FormValue={FormValues.ref_Customer ? FormValues.ref_Customer : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ref_Customer", values)}
                                    SelectOptions={FieldOptions.ref_Customer}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Customer"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="130px"
                                    LabelAlign="Left"
                                    ReadOnly={true}
                                />
                            </div>
                        </div>

                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 260px"}}>
                                <FormCompontent
                                    Name="CalcTotalVATIncluded"
                                    InitialValue={InitialFormValues.CalcTotalVATIncluded ? InitialFormValues.CalcTotalVATIncluded : null}
                                    FormValue={FormValues.CalcTotalVATIncluded ? FormValues.CalcTotalVATIncluded : null}
                                    OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATIncluded", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Amount VAT Inc."
                                    ReadOnly={true}
                                    LabelMaxWidth="130px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="130px"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                <FormCompontent
                                    Name="CalcTotalVATAmount"
                                    InitialValue={InitialFormValues.CalcTotalVATAmount ? InitialFormValues.CalcTotalVATAmount : null}
                                    FormValue={FormValues.CalcTotalVATAmount ? FormValues.CalcTotalVATAmount : null}
                                    OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATAmount", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="VAT Amount"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="100px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="160px"
                                    ReadOnly={true}
                                />
                            </div>
                        </div>

                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 260px"}}>
                                <FormCompontent
                                    Name="InvoiceIssueDateIso"
                                    InitialValue={InitialFormValues.InvoiceIssueDateIso ? InitialFormValues.InvoiceIssueDateIso : null}
                                    FormValue={FormValues.InvoiceIssueDateIso ? FormValues.InvoiceIssueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoiceIssueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Issue Date"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="130px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="100px"
                                    ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 260px"}}>
                                <FormCompontent
                                    Name="InvoicePaymentDueDateIso"
                                    InitialValue={InitialFormValues.InvoicePaymentDueDateIso ? InitialFormValues.InvoicePaymentDueDateIso : null}
                                    FormValue={FormValues.InvoicePaymentDueDateIso ? FormValues.InvoicePaymentDueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentDueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Due Date"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="130px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="100px"
                                    ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                                <FormCompontent
                                    Name="Calc_RefInvoice"
                                    InitialValue={InitialFormValues.Calc_RefInvoice ? InitialFormValues.Calc_RefInvoice : null}
                                    FormValue={FormValues.Calc_RefInvoice ? FormValues.Calc_RefInvoice : null}
                                    OutputValue={(values) => HandleUpdateFormValues("Calc_RefInvoice", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Reference"
                                    LabelMaxWidth="100px"
                                    LabelAlign="Left"
                                    FieldMaxWidth="160px"
                                    ReadOnly={true}
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}} onClick={HandleViewPdf}>
                            <div>
                                <PDF SVGFillColor="black" />
                            </div>
                            <div style={{marginLeft: "5px", fontSize: "0.8rem"}}>{`${FormValues.Calc_RefInvoice}.pdf`}</div>
                        </div>
                    </div>
                </React.Fragment>
            );
            setFormComponent(FormComp);
        }
    }, [FieldOptions, FormValues, Resend]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (PassedFormValues) {
            let AccountantStatusHistory = PassedFormValues.AccountantStatusHistory;
            let InitialFormValuesTemp;
            if (AccountantStatusHistory && Array.isArray(AccountantStatusHistory) && AccountantStatusHistory.length > 0) {
                InitialFormValuesTemp = AccountantStatusHistory[0];
            }
            let Reference;
            if (InitialFormValuesTemp) {
                Reference = InitialFormValuesTemp.Doc_Reference;
            } else {
                Reference = PassedFormValues.Calc_RefInvoice;
            }
            setFieldOptions({ref_Customer: Customers});

            setInitialFormValues({
                APIID: InitialFormValuesTemp ? InitialFormValuesTemp._id : null,
                Calc_RefInvoice: Reference,
                CalcTotalVATIncluded: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATIncluded),
                CalcTotalVATAmount: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATAmount),
                InvoiceIssueDateIso: PassedFormValues.InvoiceIssueDateIso,
                InvoicePaymentDueDateIso: PassedFormValues.InvoicePaymentDueDateIso,
                ref_Customer: PassedFormValues.ref_Customer,
                Note: InitialFormValuesTemp ? InitialFormValuesTemp.Note : null,
                ReasonForChange: InitialFormValuesTemp ? InitialFormValuesTemp.ReasonForChange : "Enter a Reason",
            });
            setFormValues({
                APIID: InitialFormValuesTemp ? InitialFormValuesTemp._id : null,
                Calc_RefInvoice: Reference,
                CalcTotalVATIncluded: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATIncluded),
                CalcTotalVATAmount: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATAmount),
                InvoiceIssueDateIso: PassedFormValues.InvoiceIssueDateIso,
                InvoicePaymentDueDateIso: PassedFormValues.InvoicePaymentDueDateIso,
                ref_Customer: PassedFormValues.ref_Customer,
                Note: InitialFormValuesTemp ? InitialFormValuesTemp.Note : null,
                ReasonForChange: InitialFormValuesTemp ? InitialFormValuesTemp.ReasonForChange : "Enter a Reason",
            });
            setFormInitReady(true);

            if (PassedFormValues.AccountantStatusHistory && Array.isArray(PassedFormValues.AccountantStatusHistory) && PassedFormValues.AccountantStatusHistory.length > 0) {
                Ref_LocaleData.current = PassedFormValues.AccountantStatusHistory;
            }
        }
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (FormInitReady === true) {
            GenerateFormComponent();
        }
    }, [FormInitReady, GenerateFormComponent]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    const HandleViewPdf = () => {
        let InvoiceURL = Invoices_File_Path[0].serverPath;
        window.open(InvoiceURL);
    };
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        setFormValues(UpdatedFormValues);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return <React.Fragment>{FormComponent}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
