import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch} from "react-router-dom";

import CustomersRouter from "./Customers/CustomersRouter"
import ContactsRouter from "./Contacts/ContactsRouter"
import ProspectsRouter from "./Prospects/ProspectsRouter"

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));


const CRMRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route path="/crm/customers">
                    <CustomersRouter />
                </Route>
                <Route path="/crm/contacts">
                    <ContactsRouter />
                </Route>
                <Route path="/crm/prospects">
                    <ProspectsRouter />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default CRMRouter;
