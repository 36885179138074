/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";



// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";

import {Company, Categories, UploadFile, Preview, AddressBook, Contract, Offer, TableView, Dashboard, AddCircle, CheckBoxCircularFilled} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "30px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
        color: "white",
    },
	tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
}));

const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const MainRoute = "/financials/purchaseordersandinvoices/";
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCreateOrder = () => {
        History.push(`/financials/purchaseordersandinvoices/order/new/edit`);
    };
    const HandleCreateInvoice = () => {
        History.push(`/financials/purchaseordersandinvoices/invoices/invoice/new/edit`);
    };
    const HandleCreateSupplier = () => {
        History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/new/edit`);
    };

    const HandleCreateCategory = () => {
        History.push(`/financials/purchaseordersandinvoices/categories/category/new/edit`);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}dashboard`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Dashboard IconSize="24" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Dashboard</p>
                    </div>
                </div>
            </NavSideBarLink>

			<NavSideBarLinkAccordion
                Slug={`${MainRoute}invoices`}
                SlugList={[`${MainRoute}invoices/create`, `${MainRoute}invoices/list`,`${MainRoute}invoices/invoice/new/edit`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Contract IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Purchase Invoices</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}invoices/list`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Invoices</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}invoices/invoice/new/edit`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Create Purchase Invoice</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}invoices/generatefromtransactions`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Generate from transactions</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}invoices/signpayments`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Contract IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Manage Payments</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>

                </React.Fragment>
            </NavSideBarLinkAccordion>

            <NavSideBarLinkAccordion
                Slug={`${MainRoute}suppliers`}
                SlugList={[`${MainRoute}suppliers/create`, `${MainRoute}suppliers/table`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Company IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Suppliers</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}suppliers/table`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Suppliers</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink onClick={HandleCreateSupplier} ActiveLinkIndicator={true} WithTree={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Create supplier</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
					<NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}suppliers/import`}>
                        <div className={classes.SideBarLink_Content} data-tip data-for="ImportSupplier">
                            <div className={classes.SideBarLink_Icon}>
                                <UploadFile IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Import Suppliers</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>                  
                    <ReactTooltip id="ImportSupplier" place="right" type="dark" effect="solid">
                        <p className={classes.tooltipP}>Import suppliers from account transactions using the bank account number</p>
                    </ReactTooltip>
                </React.Fragment>
            </NavSideBarLinkAccordion>

            <NavSideBarLinkAccordion
                Slug={`${MainRoute}categories`}
                SlugList={[`${MainRoute}categories/create`, `${MainRoute}categories/list`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Categories IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Categories</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}categories/list`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Categories</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink onClick={HandleCreateCategory} ActiveLinkIndicator={true} WithTree={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="20" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Create category</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
