import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import DefaultAccoutingContactsTable from "./DefaultAccoutingContactsTable";
import DefaultAccoutingContactsTableSelect from "./DefaultAccoutingContactsTableSelect";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
        cursor: "pointer",
    },
}));

const Component = React.memo(function Component(props) {
    const {CompanyId} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleChangeTab = (CurrentIndex, NewIndex) => {
        // console.log("Change Tab", CurrentIndex, NewIndex);
        if (CurrentIndex === 2 || CurrentIndex === 3 || CurrentIndex === 4) {
            // console.log("TabNav Changed");
            // HandleSubmitForm()
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            NoLink={true}
            HandleChangeTab={HandleChangeTab}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Default Contacts</div>
                        </div>
                    ),

                    TabContent: <DefaultAccoutingContactsTable CompanyId={CompanyId} />,
                },

                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Select Contacts</div>
                        </div>
                    ),

                    TabContent: <DefaultAccoutingContactsTableSelect CompanyId={CompanyId} />,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
