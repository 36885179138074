import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import CheckBox from "../../../../../../../../artibulles-cis/CheckBox/CheckBox";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Button from "@artibulles-cis/react/Button";
import DefaultContactsTabNav from "./DefaultContacts/DefaultContactsTabNav";
import DefaultAddressesTabNav from "./DefaultAddresses/DefaultAddressesTabNav";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ContentFlex: {
        display: "flex",
        flexDirection: "row",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    ContentLeft: {
        flex: "1 1 auto",
        minWidth: "1200px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
        height: "100%",
        overflow: "hidden",
    },
    Scroll: {
        boxSizing: "border-box",
        height: "100%",
        overflow: "hidden",
    },

    ContentRight: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
    },
    FormGroup: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "row",
    },
    FormGroupCol: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },

    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormFieldGroup: {
        margin: "2px 5px 2px 5px",
    },
    FormField: {
        flex: "0 1 600px",
        flexDirection: "row",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    SupplierAddress: {
        display: "flex",
        flexDirection: "column",
    },
    SupplierAddressLine: {
        display: "flex",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 0px 2px 0px",
    },
    FormFlex: {
        display: "flex",
        padding: "0px 15px 0px 0px",
    },
    FormLeft: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
    },
    FormRight: {
        flex: "1 1 auto",

        display: "flex",
        flexDirection: "column",
    },
    PDFViewerIcon: {
        margin: "0px",
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "flex-end",
    },
    InvoiceSummaryBox: {
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "column",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        margin: "5px 0px 0px 10px",
        padding: "5px 5px",

        borderRadius: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    InvoiceSummary: {
        display: "flex",
        // padding: "0px 0px 0px 5px",
        width: "210px",
        alignSelf: "flex-end",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "0 0 120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    SummaryInvoiceError: {
        color: "orange",
        fontWeight: "600",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    FormPDFViewer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
    },
    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        flex: "0 0 35px",
        // height: "45px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
        justifyContent: "flex-start",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "0 0 1400px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightInfo: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 45px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },
    ItemsTable: {
        padding: "0px 5px 0px 0px",
    },
    Details: {
        minHeight: "150px",
    },
    ClassExtendTabContent: {
        padding: "0px 5px 0px 5px",
        boxSizing: "border-box",
        // maxWidth: "1200px",
    },
    ClassExtendButton: {
        fontSize: "0.8rem",
        margin: "5px 0px",
        padding: "0px 5px",
        justifyContent: "center",
    },
}));

const Component = React.memo(function Component(props) {
    const {CompanyId, HandleFormFieldChange} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FINAL FORM VALUES
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [Pristine, setPristine] = useState(true);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                // toast.error(`Payment not created`, {autoClose: true});
            } else {
                if (!ApiCallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data", Data);

                        let AccountingPreference = Data.AccountingPreference;

                        let DefaultItemTaxRateOptions = [],
                            DefaultPaymentTermsOptions = [],
                            DefaultCompanyBankAccountOptions = [],
                            DefaultPaymentMyCompanyBankAccountOptions = [];
							

                        if (Data.AccoutingTaxesRates && Array.isArray(Data.AccoutingTaxesRates) && Data.AccoutingTaxesRates.length > 0) {
                            Data.AccoutingTaxesRates.forEach((elem) => {
                                DefaultItemTaxRateOptions.push({value: elem._id, label: elem.TaxName});
                            });
                        }
                        if (Data.PaymentTerms && Array.isArray(Data.PaymentTerms) && Data.PaymentTerms.length > 0) {
                            Data.PaymentTerms.forEach((elem) => {
                                DefaultPaymentTermsOptions.push({label: elem.PaymentTerms, value: elem._id});
                            });
                        }
                        let InitialBankAccount = null;
                        if (Data.BankAccounts && Array.isArray(Data.BankAccounts) && Data.BankAccounts.length > 0) {
                            if (AccountingPreference && !AccountingPreference.DefaultCompanyBankAccount_ID) {
                                InitialBankAccount = AccountingPreference.DefaultCompanyBankAccount_ID;
                            } else {
                                if (Data.BankAccounts.length === 1) {
                                    if (Data.BankAccounts[0]) {
                                        InitialBankAccount = Data.BankAccounts[0]._id;
                                    }
                                }
                            }

                            Data.BankAccounts.forEach((elem) => {
                                DefaultCompanyBankAccountOptions.push({label: elem.account_number, value: elem._id});
                            });
                        }
                        let InitialMyBankAccount = null;
                        if (Data.MyCompanyBankAccounts && Array.isArray(Data.MyCompanyBankAccounts) && Data.MyCompanyBankAccounts.length > 0) {
                            if (AccountingPreference && !AccountingPreference.DefaultPaymentMyCompanyBankAccount_ID) {
                                InitialMyBankAccount = AccountingPreference.DefaultPaymentMyCompanyBankAccount_ID;
                            } else {
                                if (Data.MyCompanyBankAccounts.length === 1) {
                                    if (Data.MyCompanyBankAccounts[0]) {
                                        InitialMyBankAccount = Data.MyCompanyBankAccounts[0]._id;
                                    }
                                }
                            }
                            Data.MyCompanyBankAccounts.forEach((elem) => {
                                DefaultPaymentMyCompanyBankAccountOptions.push({value: elem._id, label: `${elem.bank_name} - ${elem.account_number}`});
                            });
                        }

						
                        let InitialValues = {
                            IsVatIntra: AccountingPreference ? AccountingPreference.IsVatIntra : null,
                            DefaultItemTaxRateId: AccountingPreference ? AccountingPreference.DefaultItemTaxRateId : null,
                            DefaultPaymentTerms_ID: AccountingPreference ? AccountingPreference.DefaultPaymentTerms_ID : null,
                            DefaultCompanyBankAccount_ID: InitialBankAccount,
                            DefaultPaymentMyCompanyBankAccount_ID: InitialMyBankAccount,
                        };
                        setFormValues(InitialValues);
                        setInitialFormValues(InitialValues);
                        setFieldOptions({
                            DefaultItemTaxRateId: DefaultItemTaxRateOptions,
                            DefaultPaymentTerms_ID: DefaultPaymentTermsOptions,
                            DefaultCompanyBankAccount_ID: DefaultCompanyBankAccountOptions,
                            DefaultPaymentMyCompanyBankAccount_ID: DefaultPaymentMyCompanyBankAccountOptions,
                        });
                    }
                } else {
                    if (ApiCallType === "GeneratePDF") {
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Getting the Invoice Generation Details
        const ApiCall = {url: `${ProdURL}/api/companies/company/saleinvoices/accountingpreference/bycompanyid/${CompanyId}`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});

        if (InitialFormValues[field] !== value) {
            setPristine(false);
        }
    };
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        setFormValues(UpdatedFormValues);
        if (InitialFormValues[field] !== SelectedValues) {
            setPristine(false);
        }

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
    };

    const HandleSubmit = () => {
        const ApiCall = {url: `${ProdURL}/api/companies/company/saleinvoices/accountingpreference/bycompanyid/${CompanyId}`, type: "put", data: FormValues};
        setLoading(true);
        // setApiCallType("SaveDraft");
        
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormDetailsComponent;
    if (FormValues) {
        FormDetailsComponent = (
            <React.Fragment>
                <div className={classes.ActionButton}>
                    <Button ClassExtendButton={classes.ClassExtendButton} Height="25px" Width="150px" onClick={HandleSubmit} disabled={Pristine}>
                        Save Changes
                    </Button>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 872px", height: "25px", display: "flex", alignItems: "center"}}>
                        <CheckBox
                            Inputname="IsVatIntra"
                            label="Intra Comm VAT"
                            initialvalue={InitialFormValues.IsVatIntra === true ? true : false}
                            curvalue={FormValues.IsVatIntra}
                            ShareInputValue={(value) => HandleUpdateFormValues("IsVatIntra", value)}
                            LabelMaxWidth="150px"
                        />
                    </div>
                </div>

				
				<div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 800px"}}>
                        <FormCompontent
                            Name="DefaultItemTaxRateId"
                            InitialValue={InitialFormValues.DefaultItemTaxRateId ? InitialFormValues.DefaultItemTaxRateId : null}
                            FormValue={FormValues.DefaultItemTaxRateId ? FormValues.DefaultItemTaxRateId : null}
                            OutputSelectedValues={(values) => handleSelectChange("DefaultItemTaxRateId", values)}
                            SelectOptions={FieldOptions.DefaultItemTaxRateId}
                            Component="Select"
                            Variant="Outlined"
                            Label="Tax Rate"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            // SortField=""

                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="160px"
                            FieldMaxWidth="400px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 800px"}}>
                        <FormCompontent
                            Name="DefaultPaymentTerms_ID"
                            InitialValue={InitialFormValues.DefaultPaymentTerms_ID ? InitialFormValues.DefaultPaymentTerms_ID : null}
                            FormValue={FormValues.DefaultPaymentTerms_ID ? FormValues.DefaultPaymentTerms_ID : null}
                            OutputSelectedValues={(values) => handleSelectChange("DefaultPaymentTerms_ID", values)}
                            SelectOptions={FieldOptions.DefaultPaymentTerms_ID}
                            Component="Select"
                            Variant="Outlined"
                            Label="Payment Terms"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            // SortField=""

                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="160px"
                            FieldMaxWidth="400px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 800px"}}>
                        <FormCompontent
                            Name="DefaultCompanyBankAccount_ID"
                            InitialValue={InitialFormValues.DefaultCompanyBankAccount_ID ? InitialFormValues.DefaultCompanyBankAccount_ID : null}
                            FormValue={FormValues.DefaultCompanyBankAccount_ID ? FormValues.DefaultCompanyBankAccount_ID : null}
                            OutputSelectedValues={(values) => handleSelectChange("DefaultCompanyBankAccount_ID", values)}
                            SelectOptions={FieldOptions.DefaultCompanyBankAccount_ID}
                            Component="Select"
                            Variant="Outlined"
                            Label="From Bank Account"
                            NoMessage={false}
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="160px"
                            FieldMaxWidth="400px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 800px"}}>
                        <FormCompontent
                            Name="DefaultPaymentMyCompanyBankAccount_ID"
                            InitialValue={InitialFormValues.DefaultPaymentMyCompanyBankAccount_ID ? InitialFormValues.DefaultPaymentMyCompanyBankAccount_ID : null}
                            FormValue={FormValues.DefaultPaymentMyCompanyBankAccount_ID ? FormValues.DefaultPaymentMyCompanyBankAccount_ID : null}
                            OutputSelectedValues={(values) => handleSelectChange("DefaultPaymentMyCompanyBankAccount_ID", values)}
                            SelectOptions={FieldOptions.DefaultPaymentMyCompanyBankAccount_ID}
                            Component="Select"
                            Variant="Outlined"
                            Label="To Bank Account"
                            NoMessage={false}
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="160px"
                            FieldMaxWidth="400px"
                            LabelAlign="Left"
                            Required={true}
                            // Disabled={true}
                            ReadOnly={false}
                        />
                    </div>
                </div>

                <DefaultContactsTabNav CompanyId={CompanyId} />
                <DefaultAddressesTabNav CompanyId={CompanyId} />
            </React.Fragment>
        );
    }
    return (
        <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {FormDetailsComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
