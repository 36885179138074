import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const AGGridAPICall = React.memo(function AGGridAPICall(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        URLAbsolute,
        CrudAction,
        APICallback,
        //
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [ApiRes, setApiRes] = useState(null); //Loader and ensuring async is done for API response
    const [Loading, setLoading] = useState(false); //Loader and ensuring async is done for API response
    const [FinalURL, setFinalURL] = useState(null);
    const [CallType, setCallType] = useState(null);

    const [Errors, setErrors] = useState(null);
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                //Something went wrong
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                if (CallType === "DeleteOne") {
                    toast.success(`Entry deleted`, {autoClose: true});
                    setCallType(null);
                    ReloadTable();
                } else {
                    let Data = ApiRes.data.data;
                    APICallback({Error: false, Data: Data});
                }
            }
        }
    }, [ApiRes, Loading, CallType]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    useEffect(() => {
        let URL;
        if (!URLAbsolute) {
            setErrors("Table Component error, You didn't pass URLAbsolute or URLRelative");
        } else {
            if (URLAbsolute) {
                URL = {
                    ALL: URLAbsolute.ALL,
                    QUERY: URLAbsolute.QUERY,
                    DELETEONE: URLAbsolute.DELETEONE,
                    DELETEMANY: URLAbsolute.DELETEMANY,
                    PUT: URLAbsolute.UPDATE,
                };
            }
            setFinalURL(URL);
            let FinalAPICall;

            if (CrudAction) {
                if (CrudAction.Action === "DeleteOne") {
                    FinalAPICall = {url: URL.DELETEONE + "/" + CrudAction.Id, type: "delete", timeout: 10000};
                    setCallType("DeleteOne");
                } else if (CrudAction.Action === "DeleteMany") {
                    FinalAPICall = {url: URL.DELETEMANY, type: "post", data: null, timeout: 10000};
                    setCallType("DeleteMany");
                } else if (CrudAction.Action === "UpdateOne") {
                    FinalAPICall = {url: URL.PUT + "/" + CrudAction.Id, type: "put", timeout: 10000};
                    setCallType("UpdateOne");
                }
            } else {
                FinalAPICall = {url: URL.ALL, type: "get", timeout : 10000};
            }

            setLoading(true);
            async function APIInternal() {
                const res = await APICallExternal(FinalAPICall);

                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [CrudAction, URLAbsolute]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    const ReloadTable = () => {
        let FinalAPICall = {url: FinalURL.ALL, type: "get"};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(FinalAPICall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    return (
        <React.Fragment>
            <LocalCircularLoader
                WithModalCard={true}
                Loading={Loading}
                FullSize={true}
                // ZIndex={null}
                // ModalCardBackground={"rgba(0, 0, 0, 0.2)"}
                // FitToParentDimensions={false}
                // FullHeight={false}
                // FixedCircleRadius={null}
                // FixedCircleThickness={null}
                // FixedCircleColor={null}
                // AnimatedCircleRadius={null}
                // AimatedCircleThickness={null}
                // AnimatedCircleColor={null}
                // ClassOverrideLocalLoaderWrapper={null}
                // ClassExtendLocalLoaderWrapper={null}
                // ClassOverrideAnimatedCircle={null}
            />
        </React.Fragment>
    );
    /****************************** RENDER *********************/
});

AGGridAPICall.defaultProps = {
    URLAbsolute: null,
};

AGGridAPICall.propTypes = {
    /**
     * URLAbsolute :
     */
    URLAbsolute: PropTypes.any,
};

export default AGGridAPICall;
