/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';
import _ from 'lodash';
import {
  Search,
  CloseIcon,
  FilterFunnel,
  FilterEqual,
  FilterDifferent,
  FilterGreater,
  FilterLess,
  FilterGreaterEqual,
  FilterLessEqual,
  FilterContains,
  FilterDoesntContain,
  FilterMatch,
  FilterStartsWith,
  FilterEndsWith,
} from '@artibulles-cis/react-icons';
//eslint-disable-next-line
const LockedstylesWithProps = props => {
  return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles(theme => ({
  InputWithToolBar: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    // zIndex: "500",
    // backgroundColor: "blue",
  },
  Toolbar: {
    display: 'flex',
    alignItems: 'center',
    flex: '0 1 auto',
  },
  SearchIcon: {
    flex: '0 0 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // margin: "0px 5px 0px 5px",

    // borderRight: "1px solid grey",
    boxSizing: 'border-box',
    padding: '0px 2px 0px 0px',
    cursor: 'pointer',
  },
  FilterIcon: {
    position: 'relative',
    flex: '0 0 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // margin: "0px 5px 0px 5px",

    // borderRight: "1px solid grey",
    boxSizing: 'border-box',
    padding: '0px 2px 0px 2px',
    cursor: 'pointer',
    zIndex: '99999',
  },
  FilterActiveIcon: {
    position: 'relative',
    flex: '0 0 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '0px 2px 0px 2px',
    // cursor: "pointer",
  },
  FilterMenu: {
    position: 'absolute',
    left: '10px',
    top: '25px',
    background: 'grey',
    color: 'white',
    display: 'none',
    zIndex: '99999',
  },
  FilterMenuContent: {
    flex: '1 1 auto',
    position: 'relative',
    flexDirection: 'column',
    boxSizing: 'border-box',
    zIndex: '99999',
  },
  FilterMenuItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 5px',
    boxSizing: 'border-box',
    '&:hover': {
      background: 'blue',
    },
    zIndex: '99999',
  },
  FilterMenuItem_Icon: {
    flex: '0 0 20px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingRight: '10px',
  },
  FilterMenuItem_Text: {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: '16px',
    color: 'white',
  },

  CloseIcon: {
    flex: '0 0 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // margin: "0px 5px 0px 5px",

    boxSizing: 'border-box',
    padding: '0px 0px 0px 2px',
    pointer: 'cursor',
  },

  InputWithLeftToolbar: {
    paddingLeft: '8px',
  },
  InputWithRightToolbar: {
    paddingRight: '8px',
  },
  InputWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
  },
  Input: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '16px',
    color: 'black',
    height: '100%',
    flex: '0 1 auto',
    display: 'block',
    width: '100%',
    // borderRadius: "3px",
    border: '0px ',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',

    pointerEvents: 'all',
    //Keep it here to set the full component height to harmonize with other inputs
    // minHeight: "25px",
    // lineHeight: "25px",
    // height: "30px"
    webkitAppearance: 'none ',
    outline: 'none ',
    background: 'none',

    '&:error': {
      webkitAppearance: 'none ',
      outline: 'none ',
      border: 'none ',
      boxShadow: 'none',
    },
    '&:invalid': {
      outline: 'none ',
      border: 'none ',
      boxShadow: 'none ',
    },
    '&:focus': {
      webkitAppearance: 'none ',
      outline: 'none ',
      border: 'none ',
      boxShadow: 'none',
    },
    '&:active': {
      webkitAppearance: 'none ',
      outline: 'none ',
      border: 'none ',
      boxShadow: 'none',
    },
  },
}));

/**
 * Component Description
 */

const TableSearchInput = React.forwardRef(function TableSearchInput(props, ref) {
  /****************************** PROPS AND JSS CLASSES *********************/
  const {
    InitialFilter,
    WithClearIcon,
    DataType,
    Column,
    WithFilters,
    SearchBehavior,
    IsFake,
    SearchValue,
  } = props;

  const theme = useTheme();
  const classes = styles({ ...props, theme });
  /****************************** PROPS AND JSS CLASSES *********************/

  /****************************** REFS *********************/
  const Ref_Input = React.useRef();
  const Ref_FilterButton = React.useRef();
  const Ref_FilterMenu = React.useRef();
  const Ref_FilterMenu_Hover = React.useRef(false);
  /****************************** REFS *********************/

  /****************************** CONST *********************/
  const FiltersList = {
    text: [
      { name: 'Equal', Icon: <FilterEqual />, FilterType: 'Equal' },
      { name: 'Does not equal', Icon: <FilterDifferent />, FilterType: 'Different' },
      { name: 'Contains', Icon: <FilterContains />, FilterType: 'Contains' },
      { name: 'Does not contain', Icon: <FilterDoesntContain />, FilterType: 'DoesNotContain' },
      { name: 'Match', Icon: <FilterMatch />, FilterType: 'Match' },
      { name: 'Starts with', Icon: <FilterStartsWith />, FilterType: 'StartsWith' },
      { name: 'Ends with', Icon: <FilterEndsWith />, FilterType: 'EndsWith' },
      {
        name: 'Reset',
        Icon: (
          <CloseIcon
            InnerPadding="35"
            FrameStrokeWidth="10"
            SVGStrokeWidth="10"
            SVGFillColor="white"
            SVGStrokeColor="black"
          />
        ),
        FilterType: 'Reset',
      },
    ],
    number: [
      { name: 'Equal', Icon: <FilterEqual />, FilterType: 'Equal' },
      { name: 'Does not equal', Icon: <FilterDifferent />, FilterType: 'Different' },
      { name: 'Greater than', Icon: <FilterGreater />, FilterType: 'Greater' },
      {
        name: 'Greater than or equal to',
        Icon: <FilterGreaterEqual />,
        FilterType: 'GreaterEqual',
      },
      { name: 'Less than', Icon: <FilterLess />, FilterType: 'Less' },
      { name: 'Less than or equal to', Icon: <FilterLessEqual />, FilterType: 'LessEqual' },
      { name: 'Between', Icon: <FilterContains />, FilterType: 'Between' },
      {
        name: 'Reset',
        Icon: (
          <CloseIcon
            InnerPadding="35"
            FrameStrokeWidth="10"
            SVGStrokeWidth="10"
            SVGFillColor="white"
            SVGStrokeColor="black"
          />
        ),
        FilterType: 'Reset',
      },
    ],
  };
  /****************************** CONST *********************/

  /****************************** STATE *********************/
  const [InputValue, setInputValue] = useState(null);
  const [ShowFilterMenu, setShowFilterMenu] = useState(false);
  const [FilterType, setFilterType] = useState(null);
  const [FilterTypeIcon, setFilterTypeIcon] = useState(null);

  /****************************** STATE *********************/

  /****************************** CALLBACK FUNCTIONS *********************/

  const HandleFilterButtonMouseEnter = React.useCallback(() => {
    setShowFilterMenu(true);
  });

  const HandleFilterButtonMouseLeave = React.useCallback(() => {
    setTimeout(() => {
      if (Ref_FilterMenu_Hover.current === false) {
        setShowFilterMenu(false);
      }
    }, 300);
  });
  const HandleFilterMenuMouseEnter = React.useCallback(() => {
    Ref_FilterMenu_Hover.current = true;
    setShowFilterMenu(true);
  });
  const HandleFilterMenuMouseLeave = React.useCallback(() => {
    setTimeout(() => {
      setShowFilterMenu(false);
      Ref_FilterMenu_Hover.current = false;
    }, 300);
  });

  /****************************** CALLBACK FUNCTIONS *********************/

  /****************************** EFFECT *********************/

  //Filter Menu event listener
  useEffect(() => {
    if (Ref_FilterButton.current && Ref_FilterMenu.current) {
      Ref_FilterButton.current.addEventListener('mouseenter', HandleFilterButtonMouseEnter);
      Ref_FilterButton.current.addEventListener('mouseleave', HandleFilterButtonMouseLeave);
      Ref_FilterMenu.current.addEventListener('mouseenter', HandleFilterMenuMouseEnter);
      Ref_FilterMenu.current.addEventListener('mouseleave', HandleFilterMenuMouseLeave);
      return () => {
        if (Ref_FilterButton.current && Ref_FilterMenu.current) {
          Ref_FilterButton.current.removeEventListener('mouseenter', HandleFilterButtonMouseEnter);
          Ref_FilterButton.current.removeEventListener('mouseleave', HandleFilterButtonMouseLeave);
          Ref_FilterMenu.current.removeEventListener('mouseenter', HandleFilterMenuMouseEnter);
          Ref_FilterMenu.current.removeEventListener('mouseleave', HandleFilterMenuMouseLeave);
        }
      };
    }
  }, [Ref_FilterButton, Ref_FilterMenu]);

  //Initial value of the input and filter type
  useEffect(() => {
    if (InitialFilter) {
      var IconElem;
      let IconSource = _.find(FiltersList[DataType], { FilterType: InitialFilter.type });
      if (IconSource) {
        IconElem = IconSource.Icon;
      }
      setInputValue(InitialFilter.value);
      setFilterType(InitialFilter.type);
      Ref_Input.current.value = InitialFilter.value;
      setFilterTypeIcon(IconElem);
    }
  }, [InitialFilter]);

  /****************************** EFFECT *********************/

  /****************************** FUNCTIONS *********************/
  const HandleSearch = () => {
    if (SearchBehavior.SearchOnClick === true) {
      SearchValue({ column: Column, type: FilterType, value: InputValue, datatype: DataType, Ref_Input : Ref_Input });
    }
  };
  const HandleReset = () => {
    Ref_Input.current.value = null;
    setInputValue(null);
    setFilterType(null);
    setFilterTypeIcon(null);

    if (SearchValue) {
      SearchValue({ column: Column, type: null, value: null, datatype: DataType, Ref_Input : Ref_Input });
    }
  };

  const InputOnChange = event => {
    let value = event.target.value;
    if (!value) {
      //Reset if the user deletes everything
      SearchValue({ column: Column, type: FilterType, value: null, datatype: DataType, Ref_Input : Ref_Input });
    }
    if (SearchBehavior.SearchOnClick === false) {
		
      if (DataType === 'number') {
        if (SearchValue) {
          SearchValue({ column: Column, type: FilterType, value: value, datatype: DataType, Ref_Input : Ref_Input });
        }
      } else {
        if (SearchBehavior.MinCharacters > 0) {
			
          if (value.length >= SearchBehavior.MinCharacters) {
            if (SearchValue) {
              SearchValue({ column: Column, type: FilterType, value: value, datatype: DataType ,Ref_Input : Ref_Input});
            }
          }
        } else {
          if (SearchValue) {
            SearchValue({ column: Column, type: FilterType, value: value, datatype: DataType,Ref_Input : Ref_Input });
          }
        }
      }
    }
    setInputValue(value);
  };
  const HandleSelectFilter = (type, Icon) => {
    //Prevent updating for no reasons (same filter activated)
    if (type !== FilterType) {
      if (type === 'Reset') {
        //Clear the filter
        setFilterType(null);
        setFilterTypeIcon(null);
        setShowFilterMenu(false);
        //Need to update the filter to ensure it is re-applied
        if (SearchValue) {
          SearchValue({ column: Column, type: null, value: InputValue, datatype: DataType });
        }
      } else {
        setFilterType(type);
        setFilterTypeIcon(Icon);
        setShowFilterMenu(false);
        //Need to update the filter to ensure it is re-applied
        if (SearchValue) {
          SearchValue({ column: Column, type: type, value: InputValue, datatype: DataType });
        }
      }
    }
  };

  /****************************** FUNCTIONS *********************/

  /****************************** RENDER *********************/
  var FilterComponent,
    RightToolbar,
    FilterMenuContent = [],
    ActiveFilterComponent;

  if (WithFilters === true) {
    if (DataType === 'number') {
      FiltersList.number.forEach(elem => {
        let IconFilterComp = React.cloneElement(elem.Icon, {
          IconSize: '15px',
        });
        let ListElem = (
          <div
            className={classes.FilterMenuItem}
            onClick={() => HandleSelectFilter(elem.FilterType, elem.Icon)}
          >
            <div className={classes.FilterMenuItem_Icon}>{IconFilterComp}</div>
            <div className={classes.FilterMenuItem_Text}>{elem.name}</div>
          </div>
        );
        FilterMenuContent.push(ListElem);
      });
    } else {
      FiltersList.text.forEach(elem => {
        let IconFilterComp = React.cloneElement(elem.Icon, {
          IconSize: '20px',
        });
        let ListElem = (
          <div
            className={classes.FilterMenuItem}
            onClick={() => HandleSelectFilter(elem.FilterType, elem.Icon)}
          >
            <div className={classes.FilterMenuItem_Icon}>{IconFilterComp}</div>
            <div className={classes.FilterMenuItem_Text}>{elem.name}</div>
          </div>
        );
        FilterMenuContent.push(ListElem);
      });
    }
    var IconFilterActiveComp;
    if (FilterTypeIcon) {
      IconFilterActiveComp = React.cloneElement(FilterTypeIcon, {
        IconSize: '20px',
      });
    } else {
      IconFilterActiveComp = (
        <FilterFunnel
          IconSize="20px"
          SVGFillColor="grey"
          SVGStrokeColor="grey"
          SVGFillHoverColor="grey"
          SVGStrokeWidth="10"
        />
      );
    }
    ActiveFilterComponent = <div className={classes.FilterActiveIcon}>{IconFilterActiveComp}</div>;
    FilterComponent = (
      <div ref={Ref_FilterButton} className={classes.FilterIcon}>
        {IconFilterActiveComp}
        <div
          ref={Ref_FilterMenu}
          className={classes.FilterMenu}
          style={{ display: `${ShowFilterMenu === true ? 'flex' : 'none'}` }}
        >
          <div className={classes.FilterMenuContent}>{FilterMenuContent}</div>
        </div>
      </div>
    );
  }

  if (WithClearIcon === true) {
    RightToolbar = (
      <div className={classes.Toolbar} style={{ display: `${InputValue ? 'flex' : 'none'}` }}>
        <div className={classes.CloseIcon} onClick={HandleReset}>
          <CloseIcon
            IconSize="20"
            FrameFillColor=""
            FrameStrokeColor=""
            InnerPadding="35"
            FrameStrokeWidth="10"
            SVGStrokeWidth="10"
            SVGFillColor="white"
            SVGStrokeColor="grey"
          />
        </div>
      </div>
    );
  }

  // var FinalSearchComponent = null;
  // if (IsFake === true){
  // 	FinalSearchComponent=
  // }else{
  // 	FinalSearchComponent=
  // }
  return (
    <div
      className={classes.InputWithToolBar}
      style={{ visibility: IsFake === true ? 'hidden' : null }}
    >
      <div className={classes.Toolbar}>
        <div className={classes.SearchIcon} onClick={HandleSearch}>
          <Search
            IconSize="20"
            SVGFillColor="none"
            SVGStrokeColor="grey"
            SVGFillHoverColor="none"
            SVGStrokeWidth="10"
          />
        </div>

        {FilterComponent}
      </div>
      <div className={classes.InputWrapper}>
        <input
          ref={Ref_Input}
          autoComplete="off"
          type="text"
          className={clsx(classes.Input, classes.InputWithLeftToolbar)}
          name={Column}
          disabled={IsFake === true ? true : false}
          // style={{
          //     padding: FinalInputPadding,
          //     marginBottom: InputMarginBottom,
          //     fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
          //     fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
          //     fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
          //     width: "100%",
          // }}

          // onBlur={InputOnBlur}
          // onFocus={InputOnFocus}
          onChange={IsFake === true ? null : InputOnChange}
        />
      </div>
      {RightToolbar}
    </div>
  );
  /****************************** RENDER *********************/
});

TableSearchInput.defaultProps = {
  Column: null,
  InitialFilter: null,
  WithClearIcon: true,
  WithFilters: true,
  DataType: 'text',
  SearchBehavior: { SearchOnClick: false, MinCharacters: 3 },
  SearchValue: null,
};

TableSearchInput.propTypes = {
  /**
   * Column "The field inteded to be searched"
   */
  Column: PropTypes.any,
  /**
   * InitialFilter : The intial filter value if needed : {column: "datafield", type: "FilterType", value: "Filter Value"}
   */
  InitialFilter: PropTypes.any,
  /**
   * WithClearIcon : true/false
   */
  WithClearIcon: PropTypes.any,
  /**
   * WithFilterIcon : true/false
   */
  WithFilters: PropTypes.any,
  /**
   * DataType : "Text"/"Number"
   */
  DataType: PropTypes.any,

  /**
   * ToolbarPosition : "Left"/"Right"
   */
  SearchBehavior: PropTypes.any,
  /**
   * SearchValue : the value returned to the parent component
   * SearchValue = (value) => {
   * 	console.log(value)
   * }
   */
  SearchValue: PropTypes.any,
};

export default TableSearchInput;
