import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    AGGridToolbar: {
        flex: "0 0 40px",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        boxSizing: "border-box",
        background: "rgb(60,60,60)",
        borderBottom: "2px solid grey",
        alignItems: "center",
        color: "white",
    },
}));

/**
 * Component Description
 */

const AGGridToolbarTop = React.forwardRef(function AGGridToolbarTop(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {ShowToolBar, children, ...other} = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    //const [val, setVal] = useState();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    let ButtonToolbar;
    if (ShowToolBar === true) {
        return <div className={classes.AGGridToolbar}>ToolBar</div>;
    } else {
        return <React.Fragment></React.Fragment>;
    }

    /****************************** RENDER *********************/
});

AGGridToolbarTop.defaultProps = {
    MyProp: null,
};

AGGridToolbarTop.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default AGGridToolbarTop;
