import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PopupMessage: {
        position: "absolute",
        display: "flex",
        boxSizing: "border-box",
        borderRadius: "5px",
        // opacity: "0",
        // transition: "all 400ms",
        fontWeight: "400",
        fontSize: "0.7em",
        // minWidth: "100px",
        padding: "5px",
        zIndex: "1000000",
        "&:before": {
            content: '""',
            position: "absolute",
            width: "0px",
            height: "0px",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            // top: "-12px",
            // bottom: (props) => (props.Position === "bottom" ? "-8px" : null),
            left: "50%",
            marginLeft: "-10px",
        },
        "&:after": {
            borderBottomColor: (props) => {
                if (props.BackgroundColor) {
                    return props.BackgroundColor;
                } else {
                    return theme.FormComponent.PopupErrorMessage.BackgroundColor;
                }
            },

            borderBottomStyle: "solid",
            borderBottomWidth: "9px",
            content: '""',
            position: "absolute",
            width: "0px",
            height: "0px",
            borderLeft: "7px solid transparent",
            borderRight: "7px solid transparent",
            top: "-8px",
            // bottom: (props) => (props.Position === "top" ? "-6px" : null),
            left: "18px",
            // right: (props) => (props.Alignment === "right" ? "14px" : null),
            marginLeft: "-10px",
        },
    },
}));

/**
 * Form Component
 * Wrapper handling the graphic interface for all form Input elements
 * Manages labels, animation, frames, border around all form elements to standardize them
 * Options are
 * Naked, Underlined, Outlined, OutlinedLabel
 */

const FormComponentPopupMessage = React.memo(function FormComponentPopupMessage(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Message,
        Ref_TargetComponent,
        BackgroundColor,
        TextColor,
        //Dont Fold
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_Tooltip = useRef(null);
    /****************************** REFS *********************/
    /****************************** CONST *********************/
    var StyleFinalColor, StyleBackgroundColor;
    if (BackgroundColor) {
        StyleBackgroundColor = BackgroundColor;
    } else {
        StyleBackgroundColor = theme.FormComponent.PopupErrorMessage.BackgroundColor;
    }
    if (TextColor) {
        StyleFinalColor = TextColor;
    } else {
        StyleFinalColor = theme.FormComponent.PopupErrorMessage.TextColor;
    }

    /****************************** CONST *********************/
    /****************************** STATE *********************/
    const [Init, setInit] = useState(true);

    const [PopUpPosition, setPopUpPosition] = useState(false);
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //Getting the position of the target and the dimensions of the popup container to set the appropriate position
    const CalculateFinalPopupPosition = useCallback(() => {
        if (Ref_TargetComponent && Ref_TargetComponent.current) {
            //Getting the info about the POPUP container
            let TargetHeight = Ref_TargetComponent.current.getBoundingClientRect().height;
            // let TargetLeft = Ref_TargetComponent.current.getBoundingClientRect().left;
            let TargetLeft = Ref_TargetComponent.current.offsetLeft - 14.5;

            var FinalPosition = {
                left: TargetLeft + 2,
                top: TargetHeight + 9,
            };
            setPopUpPosition(FinalPosition);
        }
    }, [Ref_TargetComponent]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Calculating the Position of the PopUpMessage
    React.useEffect(() => {
        CalculateFinalPopupPosition();
        setInit(false); //Just to prevent rendering multiple times when not necessary
    }, [Ref_TargetComponent.current]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var FinalComponent;

    if (Message) {
        FinalComponent = (
            <div
                ref={Ref_Tooltip}
                style={{
                    color: StyleFinalColor,
                    backgroundColor: StyleBackgroundColor,
                    left: PopUpPosition.left + "px",
                    top: PopUpPosition.top + "px",
                }}
                className={classes.PopupMessage}
            >
                {Message}
            </div>
        );
    } else {
        FinalComponent = null;
    }
    return <React.Fragment>{FinalComponent}</React.Fragment>;
    /****************************** RENDER *********************/
});

FormComponentPopupMessage.defaultProps = {
    Message: null,
    BackgroundColor: null,
    TextColor: null,
};

FormComponentPopupMessage.propTypes = {
    /**
     * Message : The message to display
     */
    Message: PropTypes.any,
    /**
     * Message : The message to display
     */
    BackgroundColor: PropTypes.any,
    /**
     * Message : The message to display
     */
    TextColor: PropTypes.any,
};

export default FormComponentPopupMessage;
