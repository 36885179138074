import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    DraggableWrapper: {
        width: "600px",
        margin: "10px",
        boxSizing: "border-box",
        padding: "10px",
        border: "1px solid black",
    },

    List: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        boxSizing: "border-box",
        padding: " 0px 5px",
    },
    Task: {
        display: "flex",
        justifyContent: "flex-start",
        margin: "1px 0px",
        boxSizing: "border-box",

        width: "100%",

        borderBottom: "1px solid grey",
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
        borderTop: "1px solid grey",
        // "&:first-child": {
        //     borderTop: "1px solid grey",
        // },
        // "&:last-child": {
        // 	borderRight: MandatorySyncStyle(props).ColBorder ? MandatorySyncStyle(props).ColBorder : FinalTheme.CommonStyle.ColBorder,
        // },
    },
    Task_Order: {
        flex: "0 0 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRight: "1px solid grey",
        padding: "10px 10px",
    },
    Task_Content: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "10px 10px",
    },
}));

const DragableList = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const finalSpaceCharacters = [
        {
            id: "01",
            task_name: "Gary Goodspeed",
        },
        {
            id: "02",
            task_name: "Little Cato",
        },
        {
            id: "03",
            task_name: "KVN",
        },
        {
            id: "04",
            task_name: "Mooncake",
        },
        {
            id: "05",
            task_name: "Quinn Ergon",
        },
    ];
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Characters, setCharacters] = useState(finalSpaceCharacters);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleDragStart = (args) => {
        console.log("HandleDragStart", args);
    };
    const HandleOnDragEnd = (result) => {
        console.log("Args", result);
        if (!result.destination) return;

        const items = Array.from(Characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setCharacters(items);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div className={classes.DraggableWrapper}>
            <DragDropContext onDragEnd={HandleOnDragEnd}>
                <Droppable droppableId="characters">
                    {(provided) => (
                        <div className={classes.List} {...provided.droppableProps} ref={provided.innerRef}>
                            <Draggable key="01" draggableId="01" index={1}>
                                {(provided) => (
                                    <div className={classes.Task} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className={classes.Task_Order}>1</div>
                                        <div className={classes.Task_Content}>Arnaud</div>
                                    </div>
                                )}
                            </Draggable>
                            <Draggable key="02" draggableId="02" index={2}>
                                {(provided) => (
                                    <div className={classes.Task} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className={classes.Task_Order}>2</div>
                                        <div className={classes.Task_Content}>Arnaud</div>
                                    </div>
                                )}
                            </Draggable>

                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );

    /***************** RENDER ******************/
};

export default DragableList;
