import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import PerfectScrollBarWrapper from "../PerfectScrollBarWrapper/PerfectScrollBarWrapper";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {
        TabNavContent: {
			// height: props.Height ? props.Height : null,
			flex : props.Height==="100%"?"1 1 100%":"1 1 auto",
            overflowY: props.PerfectScrollBar.Active ? "hidden" : props.ScrollY ? props.ScrollY : null,
            overflowX: props.PerfectScrollBar.Active ? "hidden" : props.ScrollX ? props.ScrollX : null,
        },
    };
};
//eslint-disable-next-line
const Lockedstyles = {};
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabNavContent: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px",
		margin: "0 px",
		
    },
}));
/**
 * Create a TabNavigationLink Element for the TabElement
 * Need to add option to add the indicator on top, default for now is bottom
 */
const TabNavContent = React.forwardRef(function TabNavContent(props, ref) {
    const {
		TabRef,
        ClassOverrideTabContent,
        ClassExtendTabContent,
        //eslint-disable-next-line
        Height,
        Border,
        Background,
        InnerPadding,
        MaxWidth,
        PerfectScrollBar,
        ScrollX,
        ScrollY,
        children,
    } = props;
    const theme = useTheme();
    const classes = styles({...props, theme});

    const ScrollBarRef = React.useRef();
    //Class of the tab element Default
    var FinalClassTabContent;
    if (ClassOverrideTabContent) {
        FinalClassTabContent = ClassOverrideTabContent;
    } else {
        FinalClassTabContent = clsx(classes.TabNavContent, ClassExtendTabContent);
    }

    var FinalBorder, FinalPadding, FinalBackground, FinalScrollX, FinalScrollY, FinalMaxWidth;
    if (Border) {
        FinalBorder = {border: Border};
    } else {
        FinalBorder = {};
    }
    if (InnerPadding) {
        FinalPadding = {padding: InnerPadding};
    } else {
        FinalPadding = {};
    }
    if (Background) {
        FinalBackground = {background: Background};
    } else {
        FinalBackground = {};
    }
    if (MaxWidth) {
        FinalMaxWidth = {width: MaxWidth};
    } else {
        FinalMaxWidth = {width: "100%"};
    }
    if (!PerfectScrollBar || PerfectScrollBar.Active === false) {
        if (ScrollX) {
            FinalScrollX = {overflowX: ScrollX};
        } else {
            FinalScrollX = {overflowX: "hidden"};
        }
        if (ScrollY) {
            FinalScrollY = {overflowY: ScrollY};
        } else {
            FinalScrollY = {overflowY: "hidden"};
        }
    } else {
    }

    const FinalStyle = () => {
        return {...FinalBorder, ...FinalPadding, ...FinalBackground, ...FinalScrollX, ...FinalScrollY, ...FinalMaxWidth};
    };

    return (
        <div ref={TabRef} className={FinalClassTabContent} style={{...LockedstylesWithProps(props).TabNavContent, ...FinalStyle()}}>
            <PerfectScrollBarWrapper
                ref={ScrollBarRef}
                Active={PerfectScrollBar.Active===true ? true : false}
                ScrollX={PerfectScrollBar.ScrollX}
                ScrollY={PerfectScrollBar.ScrollY}
                DisableDocumentSroll={true}
            >
                {children}
            </PerfectScrollBarWrapper>
        </div>
    );
});

TabNavContent.defaultProps = {
    Border: null,
    ClassOverrideTabContent: null,
    ClassExtendTabContent: null,
    Background: null,
    InnerPadding: null,
    PerfectScrollBar: {Active: false, ScrollX: false, ScrollY: false},
    ScrollX: "auto",
    ScrollY: "auto",
	Height: null,
	TabRef:null
};

TabNavContent.propTypes = {
    /**
     * Specify the border properties
     * border : "1px solid green"
     */
    Border: PropTypes.any,
    /**
     * Override the theme class of the content element
     */
    ClassOverrideTabContent: PropTypes.any,
    /**
     * Extend the theme class of the content element
     */
    ClassExtendTabContent: PropTypes.any,
    /**
     * Background color of the content element
     */
    Background: PropTypes.any,
    /**
     * padding of the content element
     */
    InnerPadding: PropTypes.any,
    /**
     * Specify if Perfect Scrollbar needs to be used. Properties :
     * {Active : true, ScrollX : true, ScrollY : false}
     */
    PerfectScrollBar: PropTypes.any,
    /**
     * Properties of Content for the X scroll propery (overflowX)
     * Default : hidden, options : hidden, scroll, auto
     */
    ScrollX: PropTypes.any,
    /**
     * Properties of Content for the Y scroll propery (overflowX)
     * Default : hidden, options : hidden, scroll, auto
     */
    ScrollY: PropTypes.any,
    /**
     * The height property of the Content element
     */
	Height: PropTypes.any,
	 /**
     * The Ref of the tab
     */
    TabRef: PropTypes.any,
};

export default TabNavContent;
