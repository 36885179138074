import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "@artibulles-cis/react/Page";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoaderIOS from "../../../../../../artibulles-cis/LocalCircularLoaderIOS/LocalCircularLoaderIOS";
import PopUpMenuWithButton from "../../../../../../artibulles-cis/PopUpMenuWithButton/PopUpMenuWithButton";
import BankChart from "./BankChart";
import {format, parseISO, differenceInCalendarDays} from "date-fns";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendsPageContent: {
        display: "flex",
        flexDirection: "column",
        color: "white",
    },
    Section: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    SectionTitle: {
        fontWeight: "500",
        fontSize: "1rem",
    },
    SectionMain: {
        display: "flex",
        boxSizing: "border-box",
        position: "relative",
        flexWrap: "wrap",
        width: "100%",
        // justifyContent : "space-between"
    },
    Card: {
        flex: "1 1 auto",
        display: "flex",
        maxWidth: "600px",
        minWidth: "400px",
        height: "270px",
        margin: "5px 5px",
        flexDirection: "column",
        borderRadius: "3px",
        color: "black",
        boxSizing: "border-box",
        // background: "linear-gradient(0deg, rgba(15, 14, 14, 0.9) 0%, rgba(49, 49, 49, 0.9) 100%)",
        boxShadow: "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        fontWeight: "400",
        fontSize: "0.8rem",
    },
    CardHeader: {
        flex: "0 1 auto",
        display: "flex",
        padding: "2px 5px",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        borderBottom: "1px solid grey",
    },
    CardHeader_Left: {
        flex: "1 1 auto",
        display: "flex",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    CardHeader_RightIcon: {
        flex: "0 0 40px",
        display: "flex",
        flexDirection: "column",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
    },

    CardContent: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    BalanceAndSyncInfo: {
        flex: "0 1 auto",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",

        // padding: "5px",
    },
    BalanceInformation: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "180px",

        // padding: "0px 5px",
        // alignSelf : "flex-end",
        // padding: "5px",
        color: "#FF6103",
    },
    BalanceInformation_Line: {
        flex: "1 1 auto",
        display: "flex",
        fontSize: "0.9rem",
        fontWeight: "500",

        // padding: "5px",
    },
    BalanceInformation_Line_Title: {
        flex: "1 1 auto",
        display: "flex",
        // justifyContent : "flex-start"

        // padding: "5px",
    },
    BalanceInformation_Line_Value: {
        flex: "0 0 80px",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    SyncInformation: {
        flex: "1 1 auto",

        display: "flex",
        flexDirection: "column",
        // maxWidth: "220px",
        alignSelf: "flex-end",
        // marginTop: "5px",
        // padding: "0px 5px",
        // color: "#FF6103",
    },
    SyncInformation_Line: {
        flex: "0 1 auto",
        display: "flex",
        fontSize: "0.75rem",
        // fontWeight: "500",

        // padding: "5px",
    },
    SyncInformation_Line_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    SyncInformation_Line_Value: {
        flex: "0 0 100px",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },

    CardFooter: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    PopupContent: {
        display: "flex",
        flexDirection: "column",
        height: "235px",
        width: "590px",
        background: "rgb(34,34,34)",
        opacity: "0.95",
        color: "white",
        padding: "5px",
        boxSizing: "border-box",
    },
    PopupColumns: {
        flex: "1 1 auto",
        display: "flex",
        // padding: "5px",
        boxSizing: "border-box",
    },
    PopupColumn: {
        flex: "1 1 33%",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
        boxSizing: "border-box",
        height: "100%",
    },
    PopupColumn_Title: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0px 0px 10px 0px",
        fontSize: "1.1rem",
        fontWeight: "600",
    },
    PopupColumn_Menu: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        fontSize: "0.9rem",
        fontWeight: "400",
    },
    PopupColumn_Menu_Item: {
        flex: "0 0 30px",
        display: "flex",
        cursor: "pointer",
        boxSizing: "border-box",
    },
}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Page = useRef(null);
    const Ref_CardBaccount = useRef(null);

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Loading, setLoading] = useState(false);
    const [LoadingBankAccount, setLoadingBankAccount] = useState(false);
    const [ReloadAll, setReloadAll] = useState(true);

    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [BankAccountsInformation, setBankAccountsInformation] = useState(null);
    const [BankAccountsComponent, setBankAccountsComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!ApiCallType) {
                    if (ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("All Data", Data);
                        if (Data.BankAccounts) {
                            setBankAccountsInformation(Data.BankAccounts);
                        }
                    }
                } else {
                    if (ApiCallType === "PontoSync") {
                        if (ApiRes.data && ApiRes.data.data) {
                            let Data = ApiRes.data.data;
                            console.log("PontoSyncData", Data);
                            setApiCallType(null);
                            setReloadAll(true);
                            // let Output = {
                            //     DBAccountBalance: MyBankAccountBlance,
                            //     NewAccountBalance: CurrentBalance,
                            //     DeltaAccountBalance: CurrentBalance - MyBankAccountBlance,
                            //     NewTransactionsTotal: FreshTransactionsTotal,
                            //     NewTransactions: FreshTransactions,
                            // };

                            // if (Data.BankAccounts) {
                            //     setBankAccountsInformation(Data.BankAccounts);
                            // }
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    const GenerateBankAccountComponents = useCallback(() => {
        let FinalBankCards = [];
        if (BankAccountsInformation && Array.isArray(BankAccountsInformation)) {
            BankAccountsInformation.forEach((elem) => {
                // console.log("BankAccountData", elem);
                let BankAccountId = elem._id;
                let LatestTransaction;
                let LatestStatementAmount, LatestStatementMMYY;
                let LastSyncDate = parseISO(elem.last_sync_dateIso);
                let Today = new Date(Date.now());
                let DaysSinceLastSync = differenceInCalendarDays(Today, LastSyncDate);
                let BankStatements = elem.BankStatements;
                let GraphData;
                if (BankStatements && Array.isArray(BankStatements)) {
                    GraphData = BankStatements;
                    let LatestStatement = BankStatements[0];

                    LatestStatementAmount = LatestStatement.Calc_TotalAmount;
                    LatestStatementMMYY = LatestStatement.CalcStatementYear + "/" + LatestStatement.CalcStatementMonth;
                    let LatestStatementTransactions = LatestStatement.StatementTransactions;

                    if (LatestStatementTransactions && Array.isArray(LatestStatementTransactions)) {
                        let LatestStatementTransactionsSorted = _.orderBy(LatestStatementTransactions, ["transaction_date_Iso"], ["desc"]);
                        LatestTransaction = LatestStatementTransactionsSorted[0];
                    }
                }
                let BankCard = (
                    <React.Fragment>
                        <div ref={Ref_CardBaccount} className={classes.Card}>
                            <div className={classes.CardHeader}>
                                <div className={classes.CardHeader_Left}>{`${elem.bank_name} - ${elem.account_number}`}</div>
                                <div className={classes.CardHeader_RightIcon}>
                                    <PopUpMenuWithButton
                                        Position="bottom"
                                        //
                                        IconSize="24px"
                                        // IconStyle={{
                                        //     IconSize: "30",
                                        //     // SVGStrokeColor : "",
                                        //     SVGStrokeWidth: "30px",
                                        //     // SVGFillColor : "",
                                        // }}
                                        Alignment="right"
                                        ZIndex="2"
                                        WithArrow={true}
                                        Offset={{
                                            top: "6px",
                                            left: "8px",
                                            bottom: "0px",
                                            right: "0px",
                                        }}
                                        // Offset=""
                                        // BackgroundColor="blue"
                                        // TransitionDuration=""
                                    >
                                        <div className={classes.PopupContent}>
                                            <div className={classes.PopupColumns}>
                                                <div className={classes.PopupColumn}>
                                                    <div className={classes.PopupColumn_Title}>View</div>
                                                    <div className={classes.PopupColumn_Menu}>
                                                        <div className={classes.PopupColumn_Menu_Item}>Statements</div>
                                                        <div className={classes.PopupColumn_Menu_Item}>Transactions</div>
                                                    </div>
                                                </div>
                                                <div className={classes.PopupColumn}>
                                                    <div className={classes.PopupColumn_Title}>Reconciliation</div>
                                                    <div className={classes.PopupColumn_Menu}>
                                                        <div className={classes.PopupColumn_Menu_Item}>Statements</div>
                                                        <div className={classes.PopupColumn_Menu_Item}>Transactions</div>
                                                    </div>
                                                </div>
                                                <div className={classes.PopupColumn}>
                                                    <div className={classes.PopupColumn_Title}>Actions</div>
                                                    <div className={classes.PopupColumn_Menu}>
                                                        <div className={classes.PopupColumn_Menu_Item} onClick={() => HandleSyncBankWithPonto(BankAccountId)}>
                                                            Sync with Ponto
                                                        </div>
                                                        <div className={classes.PopupColumn_Menu_Item}>Sync Transactions</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PopUpMenuWithButton>
                                </div>
                            </div>
                            <div className={classes.CardContent}>
                                <div className={classes.BalanceAndSyncInfo}>
                                    <div className={classes.BalanceInformation}>
                                        <div className={classes.BalanceInformation_Line}>
                                            <div className={classes.BalanceInformation_Line_Title}>Balance </div>
                                            <div className={classes.BalanceInformation_Line_Value}>
                                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(elem.balance)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.SyncInformation}>
                                        <div className={classes.SyncInformation_Line}>
                                            <div className={classes.SyncInformation_Line_Title}>Last Sync </div>
                                            <div className={classes.SyncInformation_Line_Value}>{`(${DaysSinceLastSync} days)`}</div>
                                            <div className={classes.SyncInformation_Line_Value}>{format(parseISO(elem.last_sync_dateIso), "dd-MMM-yyyy")}</div>
                                        </div>
                                        <div className={classes.SyncInformation_Line}>
                                            <div className={classes.SyncInformation_Line_Title}>Latest Transaction </div>
                                            <div className={classes.SyncInformation_Line_Value}>
                                                {LatestTransaction
                                                    ? new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(
                                                          LatestTransaction.banking_amount_transaction
                                                      )
                                                    : ""}
                                            </div>
                                            },
                                            <div className={classes.SyncInformation_Line_Value}>{LatestTransaction ? format(parseISO(LatestTransaction.transaction_date_Iso), "dd-MMM-yyyy") : ""}</div>
                                        </div>
                                        <div className={classes.SyncInformation_Line}>
                                            <div className={classes.SyncInformation_Line_Title}>Latest Statement </div>
                                            <div className={classes.SyncInformation_Line_Value}>
                                                {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(LatestStatementAmount)}
                                            </div>

                                            <div className={classes.SyncInformation_Line_Value}>{LatestStatementMMYY}</div>
                                        </div>
                                    </div>
                                </div>
                                <BankChart GraphData={GraphData} />
                            </div>
                            <div className={classes.CardFooter}></div>
                        </div>
                    </React.Fragment>
                );
                FinalBankCards.push(BankCard);
            });
        } else {
        }

        setBankAccountsComponent(FinalBankCards);
    }, [BankAccountsInformation]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (ReloadAll === true) {
            setReloadAll(false);
            const ApiCall = {url: `${ProdURL}/api/companyaccount/accountsdashboardoverview/`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, ReloadAll]);

    useEffect(() => {
        if (BankAccountsInformation) {
            GenerateBankAccountComponents();
        }
    }, [BankAccountsInformation, GenerateBankAccountComponents]);
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleSyncBankWithPonto = (BankAccountId) => {
        let DataForm = {
            Id: BankAccountId,
        };
        const ApiCall = {url: `${ProdURL}/api/securebanking/syncgetransactions/${BankAccountId}`, type: "post", data: DataForm};
        setApiCallType("PontoSync");
        setLoadingBankAccount(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoadingBankAccount(false);
        }
        APIInternal();
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Page PageRef={Ref_Page} PagePadding={"10px 10px"} ClassExtendsPageContent={classes.ClassExtendsPageContent} BackgroundColor="rgb(55,55,55)">
            <LocalCircularLoaderIOS
                FullSize={true}
                WithModalCard={true}
                Loading={Loading}
                // ModalCardBackground="rgba(0, 0, 0, 0.2)"
                ModalParentRef={Ref_Page}
                FitToParentDimensions={true}
                SpinnerDimension="60px"
                ZIndex="200000"
                // SpinnerColor=""
                // SpinnerCount=""
                // SpinnerRectHeight=""
                // SpinnerRectWidth=""
                // SpinnerRadius="150px"
            />
            <LocalCircularLoaderIOS
                FullSize={false}
                WithModalCard={true}
                Loading={LoadingBankAccount}
                ModalCardBackground="rgba(0, 0, 0, 0.2)"
                ModalParentRef={Ref_CardBaccount}
                FitToParentDimensions={true}
                SpinnerDimension="60px"
                ZIndex="200000"
                // SpinnerColor=""
                // SpinnerCount=""
                // SpinnerRectHeight=""
                // SpinnerRectWidth=""
                // SpinnerRadius="150px"
            />
            <div className={classes.Section}>
                <div className={classes.SectionTitle}>Bank Accounts</div>
                <div className={classes.SectionMain}>{BankAccountsComponent}</div>
            </div>
            <div className={classes.Section}>
                <div className={classes.SectionTitle}>Credit cards</div>
                <div className={classes.SectionMain}>
                    <div className={classes.Card}>
                        <div className={classes.CardHeader}>
                            <div className={classes.CardHeader_Left}>Bank cxxx</div>
                            <div className={classes.CardHeader_RightIcon}>
                                <PopUpMenuWithButton
                                    Position="bottom"
                                    //
                                    IconSize="28px"
                                    // IconStyle={{
                                    //     IconSize: "30",
                                    //     // SVGStrokeColor : "",
                                    //     SVGStrokeWidth: "30px",
                                    //     // SVGFillColor : "",
                                    // }}
                                    Alignment="right"
                                    ZIndex=""
                                    WithArrow={true}
                                    Offset={{
                                        top: "6px",
                                        left: "8px",
                                        bottom: "0px",
                                        right: "0px",
                                    }}
                                    // Offset=""
                                    // BackgroundColor="blue"
                                    // TransitionDuration=""
                                >
                                    <div className={classes.PopupContent}>hello</div>
                                </PopUpMenuWithButton>
                            </div>
                        </div>
                        <div className={classes.CardContent}>CardContent</div>
                        <div className={classes.CardFooter}>CardFooter</div>
                    </div>
                </div>
            </div>
            <div className={classes.Section}>
                <div className={classes.SectionTitle}>Others</div>
                <div className={classes.SectionMain}>
                    <div className={classes.Card}>
                        <div className={classes.CardHeader}>
                            <div className={classes.CardHeader_Left}>Bank cxxx</div>
                            <div className={classes.CardHeader_RightIcon}>
                                <PopUpMenuWithButton
                                    Position="bottom"
                                    //
                                    IconSize="28px"
                                    // IconStyle={{
                                    //     IconSize: "30",
                                    //     // SVGStrokeColor : "",
                                    //     SVGStrokeWidth: "30px",
                                    //     // SVGFillColor : "",
                                    // }}
                                    Alignment="right"
                                    ZIndex=""
                                    WithArrow={true}
                                    Offset={{
                                        top: "6px",
                                        left: "8px",
                                        bottom: "0px",
                                        right: "0px",
                                    }}
                                    // Offset=""
                                    // BackgroundColor="blue"
                                    // TransitionDuration=""
                                >
                                    <div className={classes.PopupContent}>hello</div>
                                </PopUpMenuWithButton>
                            </div>
                        </div>
                        <div className={classes.CardContent}>CardContent</div>
                        <div className={classes.CardFooter}>CardFooter</div>
                    </div>
                </div>
            </div>
        </Page>
    );
    /***************** RENDER ******************/
};

export default Component;
