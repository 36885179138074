import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";

import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {toast} from "react-toastify";
import {parseISO, differenceInDays, startOfDay} from "date-fns";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 0 100%",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px 5px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    FormWrapper: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        // overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },
}));

const Component = React.memo(function Component(props) {
    const {SelectedRows, HandleCloseCard} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TableData = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const TableColumns = [
        // {
        //     field: "RequestedExecutionDateIso",
        //     headerName: "Payment Date",
        //     headerClass: "ag-CenterHeader",
        //     width: 130,
        //     filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // sort: "desc",
        //     sortIndex: 1,
        //     sortingOrder: ["asc", "desc"],
        //     Type: "Date",
        // },
        {
            field: "Invoice.InvoicePaymentDueDateIso",
            headerName: "Due Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },

        {
            field: "Amount",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "Communication",
            //
            headerName: "Communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "CreditorName",
            //
            headerName: "Creditor",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "CreditorAccount",
            headerName: "Account",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "CreditorAccountBIC",
            headerName: "bic",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: true,
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
    ];

    const TableOptions = {
        Resizable: false,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ApiRes, setApiRes] = useState(false);

    const [Loading, setLoading] = useState(false);
    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [Invalid, setInvalid] = useState(true); //Form has invalid fields
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                toast.error(`Payment not created`, {autoClose: true});
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("New Ponto transaction", Data);
                    // let SignLink = "https://dashboard.myponto.com/organizations/e175cbd5-d4e1-41ef-81e0-d456d76c6823/live/accounts/cb585984-aafe-4658-a5ad-0c8715063d37/payments/signable";

                    // setSignLink(SignLink);

                    toast.success(`Payment created`, {autoClose: true});
                    // setTimeout(() => {
                    //     HandleCloseCard();
                    // }, 200);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        console.log("SelectedRows", SelectedRows);
        Ref_TableData.current = SelectedRows;
        let TotalAmount = 0;
        SelectedRows.forEach((elem) => {
            TotalAmount = TotalAmount + elem.Amount;
        });
        setInitialFormValues({
            ExecutionDate: null,
            TotalAmount: TotalAmount,
        });
        setFormValues({
            ExecutionDate: null,
            TotalAmount: TotalAmount,
        });
        setFormInitReady(true);
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCreateBulkPayment = () => {
        let Payments = [];
		let MyBankAccountId;
        SelectedRows.forEach((elem) => {
            Payments.push(elem._id);
			MyBankAccountId = elem.MyBankAccount._id
        });

        const DataForm = {
            BulkReference: null,
            RequestedExecutionDateIso: FormValues.ExecutionDate,
			MyBankAccountId : MyBankAccountId,
            TransactionsIds: Payments,
        };

        const ApiCall = {url: `${ProdURL}/api/securebanking/createbulkpayment`, type: "post", data: DataForm};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        if (field === "ExecutionDate") {
            if (!value) {
				setFormErrors({ExecutionDate: "Provide a date"});
					setInvalid(true)
            } else {
                let ExecutionDate = parseISO(value);

                let Today = new Date(Date.now());
                let StartOfDayToday = startOfDay(Today);

                let DiffDays = differenceInDays(ExecutionDate, StartOfDayToday);

                if (DiffDays <= 0) {
                    setFormErrors({ExecutionDate: "Cannot be in the past"});
					setInvalid(true)
                } else {
                    setFormErrors({});
					setInvalid(false)
                }
            }
        }
        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    const HandleUpdateErrors = (field, error) => {
        console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        // const ErrorsKeys = _.keys(FormErrors);
        // var FinalErrors = {};
        // var InvalidTemp = false;
        // if (ErrorsKeys && ErrorsKeys.length > 0) {
        //     // If there is an error
        //     ErrorsKeys.forEach((key) => {
        //         if (key !== field) {
        //             if (FormErrors[key]) {
        //                 //Add the error in the list
        //                 FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
        //                 InvalidTemp = true;
        //             }
        //         }
        //     });
        // }

        // if (error) {
        //     //Add the error in the list
        //     FinalErrors = {...FinalErrors, ...{[field]: error}};
        //     InvalidTemp = true;
        // }
        // // console.log("InfalErrors", FinalErrors);

        // setFormErrors(FinalErrors);

        // setInvalid(InvalidTemp);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let FormComp;
    let TableComponent;
    if (FormInitReady === true) {
        FormComp = (
            <div className={classes.FormWrapper}>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 270px"}}>
                        <FormCompontent
                            Name="ExecutionDate"
                            InitialValue={InitialFormValues.ExecutionDate ? InitialFormValues.ExecutionDate : null}
                            FormValue={FormValues.ExecutionDate ? FormValues.ExecutionDate : null}
                            OutputValue={(values) => HandleUpdateFormValues("ExecutionDate", values)}
                            NoMessage={false}
                            meta={{error: FormErrors.ExecutionDate, invalid: FormErrors.ExecutionDate ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("ExecutionDate", error)}
                            DisplayMessageIn="Popup"
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Execution Date"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                            ReadOnly={false}
                        />
                    </div>
                </div>

                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                        <FormCompontent
                            Name="TotalAmount"
                            InitialValue={InitialFormValues.TotalAmount ? InitialFormValues.TotalAmount : null}
                            FormValue={FormValues.TotalAmount ? FormValues.TotalAmount : null}
                            OutputValue={(values) => HandleUpdateFormValues("TotalAmount", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total Amount"
                            ReadOnly={true}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                            Imask={true}
                            Mask={{
                                Type: "Currency",
                                Options: {
                                    Currency: "EURO",
                                    // SymbolPosition: "Post",
                                    Scale: 2,
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );

        TableComponent = (
            <AGGridTable
                Data={Ref_TableData.current}
                // URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                // MainDataSource="PontoInvoiceTransactions"
                // RowActionButtons={TableRowButtons}
                // ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                PaginationOptions={{
                    Pagination: true,
                    PageSize: 50,
                }}
            />
        );
    }

    return (
        <div className={classes.CardWindow}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            <div className={classes.CardMain}>
                {FormComp}
                <div className={classes.TableWrapper}>{TableComponent}</div>
                <div className={classes.CardButtons}>
                    <Button Width="210px" ClassExtendButton={classes.Button} onClick={HandleCreateBulkPayment} disabled={Invalid}>
                        Create Payment
                    </Button>
                </div>
            </div>
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
