import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const TrackTimeRedirect = React.memo(function TrackTimeRedirect(props) {
    const {Id, HandleCloseViewCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const History = useHistory();

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
                //Entry doesn't exist -> we can create a new entry
                History.push("/tasktimemanagement/times/timeentry/newandtrack");
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    // const Data = ApiRes.data.data;
                    //Entry exist, we redirect to the clock view, the user may have forgotten he has an existing entry running

                    History.push("/tasktimemanagement/times/time/viewclock");
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/tasks/time/entrytracking/existing`, type: "get"};
        setLoading(true);
        // setLoginPhase("OTP code entered");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
        </div>
    );

    /***************** RENDER ******************/
});

export default TrackTimeRedirect;
