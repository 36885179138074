import React, {useEffect, useState, useRef} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
import {useParams} from "react-router-dom";


import TabNav from "@artibulles-cis/react/TabNav"


import CustomerEdit from "./CustomerEditDetails/CustomerEdit";
import CustomerContacts from "./CustomerContacts";
import CustomerFinancials from "./CustomerFinancials";
import CustomerProjects from "./CustomerProjects";



//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const CustomerRouter = React.memo(function CustomerRouter (props) {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
/***************** REFS ******************/
 
/***************** REFS ******************/
 
 
/***************** CONST ******************/
const LocationParams = useParams();
/***************** CONST ******************/
 
 
/***************** STATE ******************/
const [Id, SetId] = useState(LocationParams.id);
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
useEffect(() => {
	if (LocationParams.id) {
		SetId(LocationParams.id);
	}
}, [LocationParams]);
/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<React.Fragment>
            <TabNav
                MainSlug={`/crm/customers/customer/:id`}
                DefaultTab={`/crm/customers/customer/${Id}/view`}
                ActiveIndicator={{
                    WithIndicator: true,
                    Position: "Bottom",
                }}
                TabMenuContent={[
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Overview</div>
                            </div>
                        ),
                        Slug: `/crm/customers/customer/${Id}/view`,
                        TabContent: <CustomerEdit/>,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Financials</div>
                            </div>
                        ),
                        Slug: `/crm/customers/customer/${Id}/financials`,
                        TabContent: <CustomerFinancials Id={Id} />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Projects</div>
                            </div>
                        ),
                        Slug: `/crm/customers/customer/${Id}/projects`,
                        TabContent: <CustomerProjects Id={Id} />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Address Book</div>
                            </div>
                        ),
                        Slug: `/crm/customers/customer/${Id}/contacts`,
                        TabContent: <CustomerContacts Id={Id} />,
                    },
                    
					{
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle_WithIcon_Title}>Edit</div>
                            </div>
                        ),
                        Slug: `/crm/customers/customer/${Id}/edit`,
                        ShowOnlyIfActive: true,
                        TabContent: <CustomerEdit Id={Id} />,
                    },
                ]}
            />
        </React.Fragment>
  )
 
/***************** RENDER ******************/
 
});
 
export default CustomerRouter;