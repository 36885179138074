/**
 * ScriptName : ImaskPattern.js
 * Version : 1.0.0
 * Date : 2020/11/09
 * Author: ArnaudJaspard
 * Description : description
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/
const _ = require("lodash");
const {format, parse, parseISO} = require("date-fns");
const IMask = require("imask");
/********LOADING DEPENDENCIES************/

/********CORE FUNCTION************/
exports.ImaskNumber = (params) => {
    let InitialParams = {
        //EU VERSION
        ThousandsSeparator: ".",
        DecimalSeparator: ",",
        Scale: 2,
        AllowNegative: true,
        Min: null,
        Max: null,
        Digits: null,
    };
    var Inputs = _.merge(InitialParams, params);

    var FinalThousandsSeparator, FinalAllowNegative;
    if (Inputs.Digits) {
        FinalThousandsSeparator = "";
        FinalAllowNegative = false;
        //Remove thoushands sep
    } else {
        FinalThousandsSeparator = Inputs.ThousandsSeparator;
        FinalAllowNegative = Inputs.AllowNegative;
    }

    return {
        type: "Number",
        mask: Number,
        thousandsSeparator: FinalThousandsSeparator,
        radix: Inputs.DecimalSeparator,
        scale: Inputs.Scale,
        signed: FinalAllowNegative,
        mapToRadix: [","],
        min: Inputs.Min ? parseFloat(Inputs.Min) : null,
        max: Inputs.Max ? parseFloat(Inputs.Max) : null,
        maxLength: Inputs.Digits ? parseInt(Inputs.Digits) : null,
    };
};

exports.ImaskCurrency = (params) => {
    const Symbols = {
        EUR: "€",
        USD: "$",
    };

    let InitialParams = {
        Currency: "EUR",
        Symbol: "",
        SymbolPosition: null,
        ThousandsSeparator: null,
        DecimalSeparator: null,
        Scale: 2,
        AllowNegative: true,
        Min: null,
        Max: null,
    };

    var Inputs = _.merge(InitialParams, params);

    var FinalSymbol = Symbols[Inputs.Currency];
    if (!FinalSymbol) {
        FinalSymbol = Symbols.EUR;
    }
    var FinalMask;
    if (Inputs.SymbolPosition === "Pre") {
        FinalMask = `d${FinalSymbol}`;
    } else {
        if (Inputs.Currency === "USD") {
            FinalMask = `${FinalSymbol}d`;
        } else if (Inputs.Currency === "EUR") {
            FinalMask = `d${FinalSymbol}`;
        } else {
            FinalMask = `d${FinalSymbol}`;
        }
    }
    var FinalThousandsSeparator;
    if (Inputs.ThousandsSeparator) {
        FinalThousandsSeparator = Inputs.ThousandsSeparator;
    } else {
        if (Inputs.Currency === "USD") {
            FinalThousandsSeparator = ",";
        } else if (Inputs.Currency === "EUR") {
            FinalThousandsSeparator = ".";
        } else {
            FinalThousandsSeparator = ",";
        }
    }

    var FinalDecimalSeparator;
    if (Inputs.DecimalSeparator) {
        FinalDecimalSeparator = Inputs.DecimalSeparator;
    } else {
        if (Inputs.Currency === "USD") {
            FinalDecimalSeparator = ".";
        } else if (Inputs.Currency === "EUR") {
            FinalDecimalSeparator = ",";
        } else {
            FinalDecimalSeparator = ".";
        }
    }

    return {
        mask: FinalMask,
        blocks: {
            d: {
                mask: Number,
                thousandsSeparator: FinalThousandsSeparator,
                radix: FinalDecimalSeparator,
                scale: Inputs.Scale,
                signed: Inputs.AllowNegative === false ? false : true,
                mapToRadix: [","],
                min: Inputs.Min ? parseFloat(Inputs.Min) : null,
                max: Inputs.Max ? parseFloat(Inputs.Max) : null,
            },
        },
    };
};
exports.ImaskDate = (params) => {
    //Checking the type of Input
    var FinalType;
    if (params.Type === "Date") {
        FinalType = "Date";
    } else if (params.Type === "Time") {
        FinalType = "Time";
    } else if (params.Type === "DateTime") {
        FinalType = "DateTime";
    } else {
        //User screwed up
        FinalType = "Date";
    }

    var WithError = false; //Used in case the user uses wrong datefns input (That will for sure happens)

    var DefaultDateFormat = "yyyy/MM/dd";
    var DefaultTimeFormat = "HH:mm";
    var DefaultDateTimeFormat = "yyyy/MM/dd - HH:mm";

    let InitialParams = {
        Format: null, //default
        Max: null,
        Min: null,
    };

    var Options = _.merge(InitialParams, params.Options);
    // console.log("Options", Options);
    var FinalDatefnsFormat;
    if (FinalType === "Date") {
        if (InitialParams.Format) {
            //This is where we need to test that the format is ok!!!!
            FinalDatefnsFormat = Options.Format;
        } else {
            FinalDatefnsFormat = DefaultDateFormat;
        }
    } else if (FinalType === "Time") {
        if (InitialParams.Format) {
            //This is where we need to test that the format is ok!!!!
            FinalDatefnsFormat = Options.Format;
        } else {
            FinalDatefnsFormat = DefaultTimeFormat;
        }
    } else if (FinalType === "DateTime") {
        if (InitialParams.Format) {
            //This is where we need to test that the format is ok!!!!
            FinalDatefnsFormat = Options.Format;
        } else {
            FinalDatefnsFormat = DefaultDateTimeFormat;
        }
    }

    //Here the challenges begin...
    //we need the full year to create a date obj.
    //The followng Date cases will create issues
    //1. the user only wants to display the last two digits of the year dd/MM/yy,
    //Resolution -> if FinalDatefnsFormat only have 2 yy,
    //2. the user wants a month and year picker (i.e:credit card) -> MM/yy
    //3. time only -> How to handle that ?

    var FinalDatefnsParse = FinalDatefnsFormat;

    //Since we need to parse a real date, we need to have at least d/M/yyyy meaning the full year
    //if the user wants to only display the last two digits of the year, we need to assume that he want to refer to the current century

    var FinalBlocks;
    if (FinalType === "Date") {
        if (FinalDatefnsFormat.includes("E")) {
            FinalBlocks = {
                dd: {mask: IMask.MaskedRange, placeholderChar: "d", from: 1, to: 31, maxLength: 2},
                MMM: {mask: IMask.MaskedEnum, placeholderChar: "m", enum: ["jan", "feb"]},
                yy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 0, to: 99, maxLength: 2},
            };
        } else {
            if (FinalDatefnsFormat.includes("MMM")) {
                if (FinalDatefnsFormat.includes("yy") && !FinalDatefnsFormat.includes("yyyy")) {
                    FinalBlocks = {
                        dd: {mask: IMask.MaskedRange, placeholderChar: "d", from: 1, to: 31, maxLength: 2},
                        MMM: {mask: IMask.MaskedEnum, placeholderChar: "m", enum: ["jan", "feb"]},
                        yy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 0, to: 99, maxLength: 2},
                    };
                } else {
                    FinalBlocks = {
                        dd: {mask: IMask.MaskedRange, placeholderChar: "d", from: 1, to: 31, maxLength: 2},
                        MMM: {mask: IMask.MaskedEnum, placeholderChar: "m", enum: ["jan", "feb"]},
                        yyyy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 1900, to: 2999, maxLength: 4},
                    };
                }
            } else {
                if (FinalDatefnsFormat.includes("yy") && !FinalDatefnsFormat.includes("yyyy")) {
                    FinalBlocks = {
                        dd: {mask: IMask.MaskedRange, placeholderChar: "d", from: 1, to: 31, maxLength: 2},
                        MM: {mask: IMask.MaskedRange, placeholderChar: "m", from: 1, to: 12, maxLength: 2},
                        yy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 0, to: 99, maxLength: 2},
                    };
                } else {
                    FinalBlocks = {
                        dd: {mask: IMask.MaskedRange, placeholderChar: "d", from: 1, to: 31, maxLength: 2},
                        MM: {mask: IMask.MaskedRange, placeholderChar: "m", from: 1, to: 12, maxLength: 2},
                        yyyy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 1900, to: 2999, maxLength: 4},
                    };
                }
            }
        }
    } else if (FinalType === "MonthYear") {
        if (FinalDatefnsFormat.includes("yy") && !FinalDatefnsFormat.includes("yyyy")) {
            FinalBlocks = {
                MM: {mask: IMask.MaskedRange, placeholderChar: "m", from: 1, to: 12, maxLength: 2},
                yy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 0, to: 99, maxLength: 2},
            };
        } else {
            FinalBlocks = {
                MM: {mask: IMask.MaskedRange, placeholderChar: "m", from: 1, to: 12, maxLength: 2},
                yyyy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 1900, to: 2999, maxLength: 4},
            };
        }
    } else if (FinalType === "Time") {
        if (FinalDatefnsFormat.includes("ss")) {
            FinalBlocks = {
                HH: {mask: IMask.MaskedRange, placeholderChar: "h", from: 0, to: 24, maxLength: 2},
                mm: {mask: IMask.MaskedRange, placeholderChar: "m", from: 0, to: 59, maxLength: 2},
                ss: {mask: IMask.MaskedRange, placeholderChar: "s", from: 0, to: 59, maxLength: 2},
            };
        } else {
            FinalBlocks = {
                HH: {mask: IMask.MaskedRange, placeholderChar: "h", from: 0, to: 24, maxLength: 2},
                mm: {mask: IMask.MaskedRange, placeholderChar: "m", from: 0, to: 59, maxLength: 2},
            };
        }
    } else if (FinalType === "DateTime") {
        FinalBlocks = {
            dd: {mask: IMask.MaskedRange, placeholderChar: "d", from: 1, to: 31, maxLength: 2},
            MM: {mask: IMask.MaskedRange, placeholderChar: "m", from: 1, to: 12, maxLength: 2},
            yyyy: {mask: IMask.MaskedRange, placeholderChar: "y", from: 1900, to: 2999, maxLength: 4},
            HH: {mask: IMask.MaskedRange, placeholderChar: "h", from: 0, to: 60, maxLength: 2},
            mm: {mask: IMask.MaskedRange, placeholderChar: "m", from: 0, to: 60, maxLength: 2},
        };
    }

    let MinDate, MaxDate;
    if (Options.Min) {
        MinDate = parseISO(Options.Min);
    }
    if (Options.Max) {
        MaxDate = parseISO(Options.Max);
    }
    return {
        type: "Date",
        mask: Date,
        blocks: FinalBlocks,
        min: MinDate,
        max: MaxDate,
        format: (date) => {
            return format(date, FinalDatefnsFormat);
        },

        parse: (str) => {
            if (FinalDatefnsFormat.includes("MMM")) {
                return parse("01/jan/2015", "dd/MMM/yyyy", new Date());
            } else if (FinalDatefnsFormat.includes("E")) {
                return parse("01/jan/2015", "dd/MMM/yyyy", new Date());
            } else {
                return parse(str, FinalDatefnsParse, new Date());
            }
        },
        pattern: FinalDatefnsFormat,
    };
};

exports.ImaskEmail = (params) => {
    //Not working
    return {
        mask: /^\S*@?\S*$/,
    };
};

exports.ImaskSuffix = (params) => {
    let Symbol = params.Symbol ? params.Symbol : null;
    let Mask = "val" + Symbol;
    return {
        mask: Mask,
        blocks: {
            val: {
                mask: String,
                // thousandsSeparator : " "
            },
        },
    };
};
exports.ImaskPrefix = (params) => {
    let Symbol = params.Symbol ? params.Symbol : null;
    let Mask = Symbol + "val";
    return {
        mask: Mask,
        blocks: {
            val: {
                mask: String,
                // thousandsSeparator : " "
            },
        },
    };
};

exports.ImaskPattern = (params) => {
    let Pattern = params.Pattern ? params.Pattern : null;
    let Mask = Symbol + "val";
    return {
        mask: "+++000/0000/00000+++",
        // blocks: {
        //     val: {
        //         mask: String,
        //         // thousandsSeparator : " "
        //     },
        // },
    };
};

exports.ImaskCreditCard = (params) => {
    // let InitialParams = {
    //     Currency: "EUR",
    //     Symbol: "",
    //     SymbolPosition: null,
    //     ThousandsSeparator: null,
    //     DecimalSeparator: null,
    //     Scale: 2,
    //     AllowNegative: true,
    //     Min: null,
    //     Max: null,
    // };
    // var Inputs = _.merge(InitialParams, params);
    // return {
    //     mask: FinalMask,
    //     blocks: {
    //         d: {
    //             mask: Number,
    //             thousandsSeparator: FinalThousandsSeparator,
    //             radix: FinalDecimalSeparator,
    //             scale: Inputs.Scale,
    //             signed: Inputs.AllowNegative === false ? false : true,
    //             mapToRadix: [","],
    //             min: Inputs.Min ? parseFloat(Inputs.Min) : null,
    //             max: Inputs.Max ? parseFloat(Inputs.Max) : null,
    //         },
    //     },
    // };
};
/********CORE FUNCTION************/

/********GLOBAL FUNCTIONS************/

/********GLOBAL FUNCTIONS************/
