import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {EditBox} from "@artibulles-cis/react-icons";
const LockedstylesWithProps = (props) => {
    return {
        FieldSet: {
            // border: null,
            // border: props.variant === "outlined" ? null : "0 none"
            // padding: props.variant === "outlined" ? "0px 0px 0px 0px" : "0px"
            padding: props.InputType === "Select" ? "0px" : "0px 0px 0px 8px",
        },
        OverlayWrapper: {
            padding: props.InputType === "Select" ? "0px" : "0px 0px 0px 8px",
            margin: props.InputType === "Select" ? "0px" : props.multiline === true ? "-5px 0px -5px -8px" : "0px 0px 0px -8px",
        },
    };
};

const Lockedstyles = {
    FormElement: {
        display: "flex",
        flexDirection: "column",
    },
    FormInput: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
    },
    FieldSet: {
        // position: "relative",
        // top: "-5px",
        left: "0px",
        right: "0px",
        width: "100%",
        margin: "0px",
        boxSizing: "border-box",
    },
};

const styles = createUseStyles((theme) => ({
    FormElement: theme.FormTextInput.FormElement,
    FormInput: theme.FormTextInput.FormInput,
    FieldSet: (props) => {
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.BoderRadius ? props.BorderStyle.BoderRadius : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {borderRadius: BorderStyleFinal};
            } else {
                return theme.FormTextInput.FieldSet;
            }
        } else {
            return null;
        }
    },

    FieldSetStandard: {
        // position: "relative",
        top: "-5px",
        left: "0px",
        right: "0px",
        width: "100%",
        margin: "0px",
        // pointerEvents: "none ",
        padding: "0px 0px 0px 8px",
        boxSizing: "border-box",
        borderBottom: "1px solid transparent",
        borderTop: "0 none",
        borderLeft: "0 none",
        borderRight: "0 none",
        "&:hover $HoverBorderHovered": {
            visibility: "visible",
        },
    },

    StandardInputBorder: {
        position: "absolute",
        bottom: "-1px",
        boxSizing: "border-box",
        left: "0px",
        right: "0px",
        content: '""',
    },
    BorderEmpty: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Empty ? props.Colors.Empty : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Empty ? props.BorderStyle.Empty : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {borderBottom: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {borderBottom: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetBorderEmpty;
            }
        } else {
            return null;
        }
    },

    BorderFilled: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Filled ? props.BorderStyle.Filled : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {borderBottom: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {borderBottom: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetBorderFilled;
            }
        } else {
            return null;
        }
    },
    BorderDisabled: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Disabled ? props.Colors.Disabled : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Disabled ? props.BorderStyle.Disabled : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {border: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {border: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetBorderDisabled;
            }
        } else {
            return null;
        }
    },
    FocusBorderHidden: {
        transform: "scaleX(0)",
        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },

    FocusBorderFocused: {
        transform: "scaleX(1)",
        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    },

    HoverBorderHovered: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Filled ? props.BorderStyle.Filled : null) : null;
        if (BorderStyleFinal) {
            return {
                borderBottom: `2px solid borderBottom: ${BorderStyleFinal}`,
                visibility: "hidden",
            };
        } else if (BorderColorFinal) {
            return {
                borderBottom: `2px solid borderBottom: ${BorderColorFinal}`,
                visibility: "hidden",
            };
        } else {
            return {
                borderBottom: theme.FormTextInput.FieldSetBorderHover.borderBottom,
                visibility: "hidden",
            };
        }
    },

    ErrorBorder: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Error ? props.BorderStyle.Error : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {borderBottom: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {borderBottom: `2px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetBorderError;
            }
        } else {
            return null;
        }
    },

    FieldSetOutlinedBorderEmpty: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Empty ? props.Colors.Empty : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Empty ? props.BorderStyle.Empty : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {border: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {border: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetOutlinedBorderEmpty;
            }
        } else {
            return null;
        }
    },
    FieldSetOutlinedBorderFocus: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Focused ? props.Colors.Focused : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Focused ? props.BorderStyle.Focused : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {border: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {border: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetOutlinedBorderFocus;
            }
        } else {
            return null;
        }
    },
    FieldSetOutlinedBorderError: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Error ? props.BorderStyle.Error : null) : null;
        if (props.variant === "outlined") {
            if (BorderStyleFinal) {
                return {border: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {border: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetOutlinedBorderError;
            }
        } else {
            return null;
        }
    },
    FieldSetOutlinedBorderFilled: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Filled ? props.BorderStyle.Filled : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {border: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {border: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetOutlinedBorderFilled;
            }
        } else {
            return null;
        }
    },
    FieldSetOutlinedBorderDisabled: (props) => {
        let BorderColorFinal = props.Colors ? (props.Colors.Disabled ? props.Colors.Disabled : null) : null;
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.Disabled ? props.BorderStyle.Disabled : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {border: BorderStyleFinal};
            } else if (BorderColorFinal) {
                return {border: `1px solid ${BorderColorFinal}`};
            } else {
                return theme.FormTextInput.FieldSetOutlinedBorderDisabled;
            }
        } else {
            return null;
        }
    },

    Legend: (props) => {
        if (props.InputType === "Select") {
            //No Animation of the legend
            return {
                display: "block",
                padding: "0",
                textAlign: "left",
                width: "auto",
                visibility: "hidden",
                maxWidth: "0.01px", // when lable is at the bottom
                marginLeft: "8px",
            };
        } else {
            return {
                display: "block",
                padding: "0",
                textAlign: "left",
                width: "auto",
                visibility: "hidden",
                maxWidth: "0.01px", // when lable is at the bottom
                transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
            };
        }
    },
    LegendTop: (props) => {
        if (props.InputType === "Select") {
            //No Animation of the legend
            return {maxWidth: "1000px"};
        } else {
            return {
                maxWidth: "1000px",
                transition: "max-width  100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
            };
        }
    },
    LegendSpan: {
        visibility: "hidden",
        padding: "0px 5px 0px 5px",
    },
    // Label: theme.FormTextInput.LabelLeft,
    Label: (props) => {
        if (props.variant === "outlinedSimple") {
            return theme.FormTextInput.LabelLeft;
        } else {
            if (props.InputType === "Select") {
                //No Animation of the legend
                return {
                    position: "absolute",
                    padding: "0px",
                    margin: "0px",
                    transformOrigin: "top left",
                    top: "0px",
                    left: "0px",
                    pointerEvents: "none ",
                };
            } else {
                return {
                    position: "absolute",
                    padding: "0px",
                    margin: "0px",
                    transformOrigin: "top left",
                    top: "0px",
                    left: "0px",
                    pointerEvents: "none",
                    // lineHeight: "18px",
                    // fontWeight: "400",
                    // transform: "scale(1) translate(12px, 20px) translateX(0px) translateY(0px)", //When in the middle
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            }
        }
    },
    LabelEmpty: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Empty ? props.Colors.Empty : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Empty ? props.LabelStyle.Empty : null) : null;
        if (props.InputType === "Select") {
            if (LabelStyleFinal) {
                return {color: LabelStyleFinal};
            } else if (LabelColorFinal) {
                return {color: LabelColorFinal};
            } else {
                return {color: theme.FormTextInput.LabelEmpty.color};
            }
        } else {
            if (LabelStyleFinal) {
                return {
                    color: LabelStyleFinal,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            } else if (LabelColorFinal) {
                return {
                    color: LabelColorFinal,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            } else {
                return {
                    color: theme.FormTextInput.LabelEmpty.color,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            }
        }
    },

    LabelPlaceHolderEmpty: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Empty ? props.Colors.Empty : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Empty ? props.LabelStyle.Empty : null) : null;

        if (props.InputType === "Select") {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        pointerEvents: "none ",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        pointerEvents: "none ",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelPlaceHolderEmpty.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                        pointerEvents: "none ",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        pointerEvents: "none ",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        pointerEvents: "none ",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelPlaceHolderEmpty.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                        pointerEvents: "none ",
                    };
                }
            }
        } else {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none ",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none ",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelPlaceHolderEmpty.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none ",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none ",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none ",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelPlaceHolderEmpty.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                        pointerEvents: "none ",
                    };
                }
            }
        }
    },
    LabelFilled: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Empty ? props.Colors.Empty : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Empty ? props.LabelStyle.Empty : null) : null;

        if (props.InputType === "Select") {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelFilled.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelFilled.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                    };
                }
            }
        } else {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelFilled.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelFilled.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            }
        }
    },
    LabelFocus: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Focused ? props.Colors.Focused : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Focused ? props.LabelStyle.Focused : null) : null;

        if (props.InputType === "Select") {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelFocus.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelFocus.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                    };
                }
            }
        } else {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelFocus.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelFocus.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            }
        }
    },

    LabelErrorEmpty: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Error ? props.LabelStyle.Error : null) : null;

        if (props.InputType === "Select") {
            if (LabelStyleFinal) {
                return {color: LabelStyleFinal};
            } else if (LabelColorFinal) {
                return {color: LabelColorFinal};
            } else {
                return {color: theme.FormTextInput.LabelError.color};
            }
        } else {
            if (LabelStyleFinal) {
                return {
                    color: LabelStyleFinal,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            } else if (LabelColorFinal) {
                return {
                    color: LabelColorFinal,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            } else {
                return {
                    color: theme.FormTextInput.LabelError.color,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            }
        }
    },

    LabelError: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Error ? props.LabelStyle.Error : null) : null;
        if (props.InputType === "Select") {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelError.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelError.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                    };
                }
            }
        } else {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelError.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelError.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            }
        }
    },

    LabelDisabledEmpty: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Disabled ? props.Colors.Disabled : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Disabled ? props.LabelStyle.Disabled : null) : null;
        if (props.InputType === "Select") {
            if (LabelStyleFinal) {
                return {color: LabelStyleFinal};
            } else if (LabelColorFinal) {
                return {color: LabelColorFinal};
            } else {
                return {color: theme.FormTextInput.LabelDisabledEmpty};
            }
        } else {
            if (LabelStyleFinal) {
                return {
                    color: LabelStyleFinal,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            } else if (LabelColorFinal) {
                return {
                    color: LabelColorFinal,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            } else {
                return {
                    color: theme.FormTextInput.LabelDisabledEmpty,
                    transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                };
            }
        }
    },
    LabelDisabled: (props) => {
        let LabelColorFinal = props.Colors ? (props.Colors.Disabled ? props.Colors.Disabled : null) : null;
        let LabelStyleFinal = props.Colors ? (props.LabelStyle.Disabled ? props.LabelStyle.Disabled : null) : null;

        if (props.InputType === "Select") {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(13px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelDisabled.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {color: LabelStyleFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else if (LabelColorFinal) {
                    return {color: LabelColorFinal, transform: "scale(0.75) translate(0px, 0px)"};
                } else {
                    return {
                        color: theme.FormTextInput.LabelDisabled.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                    };
                }
            }
        } else {
            if (props.variant === "outlined" || props.variant === "outlinedSimple") {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelDisabled.color,
                        transform: "scale(0.75) translate(13px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            } else {
                if (LabelStyleFinal) {
                    return {
                        color: LabelStyleFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else if (LabelColorFinal) {
                    return {
                        color: LabelColorFinal,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                } else {
                    return {
                        color: theme.FormTextInput.LabelDisabled.color,
                        transform: "scale(0.75) translate(0px, 0px)",
                        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
                    };
                }
            }
        }
    },

    LabelSpan: {
        padding: "0px 5px 0px 5px",
    },
    Input: (props) => {
        if (props.variant === "outlinedSimple") {
            return {
                display: "block",
                // borderRadius: "3px",
                border: "0px ",
                backgroundColor: "white!important",
                boxSizing: "border-box",

                pointerEvents: "all",
                //Keep it here to set the full component height to harmonize with other inputs
                // minHeight: "25px",
                // lineHeight: "25px",
                // height: "30px"
                webkitAppearance: "none ",
                outline: "none ",
                // background: "white!important",
                marginTop: "5px",
                marginBottom: "5px",

                "&:error": {
                    webkitAppearance: "none ",
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none",
                },
                "&:invalid": {
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none ",
                },
                "&:focus": {
                    webkitAppearance: "none ",
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none",
                },
                "&:active": {
                    webkitAppearance: "none ",
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none",
                },
            };
        } else {
            return {
                display: "block",
                // borderRadius: "3px",
                border: "0px ",
                backgroundColor: "white!important",
                boxSizing: "border-box",

                pointerEvents: "all",
                //Keep it here to set the full component height to harmonize with other inputs
                // minHeight: "25px",
                // lineHeight: "25px",
                // height: "30px"
                webkitAppearance: "none ",
                outline: "none ",
                // background: "white!important",

                "&:error": {
                    webkitAppearance: "none ",
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none",
                },
                "&:invalid": {
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none ",
                },
                "&:focus": {
                    webkitAppearance: "none ",
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none",
                },
                "&:active": {
                    webkitAppearance: "none ",
                    outline: "none ",
                    border: "none ",
                    boxShadow: "none",
                },
            };
        }
    },
    InputFont: theme.FormTextInput.InputFont,

    InputColorFilled: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Filled ? props.InputStyle.Filled : null) : null;

        if (InputStyleFinal) {
            return {color: InputStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return {color: theme.FormTextInput.InputColor.color};
        }
    },
    InputColor: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Filled ? props.Colors.Filled : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Filled ? props.InputStyle.Filled : null) : null;

        if (InputStyleFinal) {
            return {color: InputStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return {color: theme.FormTextInput.InputColor.color};
        }
    },
    InputColorFocus: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Focused ? props.Colors.Focused : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Focused ? props.InputStyle.Focused : null) : null;

        if (InputStyleFinal) {
            return {color: InputStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return {color: theme.FormTextInput.InputColorFocus.color};
        }
    },
    InputColorError: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Error ? props.InputStyle.Error : null) : null;

        if (InputStyleFinal) {
            return {color: InputStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return {color: theme.FormTextInput.InputColorError.color};
        }
    },
    InputColorDisabled: (props) => {
        let ColorFinal = props.Colors ? (props.Colors.Disabled ? props.Colors.Disabled : null) : null;
        let InputStyleFinal = props.InputStyle ? (props.InputStyle.Disabled ? props.InputStyle.Disabled : null) : null;

        if (InputStyleFinal) {
            return {color: InputStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return {color: theme.FormTextInput.InputColorDisabled.color};
        }
    },

    BottomMessageContent: theme.FormTextInput.BottomMessageContent,

    BottomMessageContentError: (props) => {
        let ErrorStyleFinal = props.ErrorStyle ? (props.ErrorStyle.color ? props.ErrorStyle.color : null) : null;
        let ColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;

        if (ErrorStyleFinal) {
            return {color: ErrorStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentError;
        }
    },
    BottomMessageContentHelper: (props) => {
        let ColorFinal = props.HelperStyle ? (props.HelperStyle.color ? props.HelperStyle.color : null) : null;

        if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentHelper;
        }
    },
    BottomMessageContentHidden: {
        visibility: "hidden",
    },
    EditOverlayComp: (props) => {
        let BorderStyleFinal = props.BorderStyle ? (props.BorderStyle.BoderRadius ? props.BorderStyle.BoderRadius : null) : null;
        if (props.variant === "outlined" || props.variant === "outlinedSimple") {
            if (BorderStyleFinal) {
                return {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    background: "rgba(0,0,0,0.5)",
                    borderRadius: BorderStyleFinal,
                    boxSizing: "border-box",
                };
            } else {
                return {
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    top: "0px",
                    left: "0px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    background: "rgba(0,0,0,0.5)",
                    boxSizing: "border-box",
                    borderRadius: theme.FormTextInput.FieldSet,
                };
            }
        } else {
            return null;
        }
    },

    EditOverlayComp_EditIcon: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
}));

/**
 * Create a NavTopBar Wrapper that goes into a NavHorizontalBar Component
 */
const FormAnimatedWrapper = React.forwardRef(function FormAnimatedWrapper(props, ref) {
    const {
        options,
        labelFlex,
        curvalue,
        initialvalue,
        UpdateOptionsFromOutside,
        InputType,
        label,
        InputRef,
        FormInputComponent,
        IsFocus,
        HasValue,
        LabelScale,
        HasError,
        variant,
        placeHolder,
        disabled,
        required,
        StyleBorderErrorFocusTrue,
        Colors,
        InputFontProp,
        //eslint-disable-next-line
        multiline,
        //eslint-disable-next-line
        LabelStyle,
        BorderStyle,
        inputVerticalPadding,
        MultiSelect,
        ControlledEditMode,
        ControlledEditModeActive,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    // const [IsFocus, setIsFocus] = React.useState(false);
    // const [HasValue, setHasValue] = React.useState(false);
    // const [HasError, setHasError] = React.useState(false);
    const [LabelTransformY, setLabelTransformY] = React.useState(null);
    const [InputMarginBottom, setInputMarginBottom] = React.useState(null);
    const [LegendSpanStyle, setLegendSpanStyle] = React.useState({height: null, fontSize: null});
    const [OverLayEditMode, setOverLayEditMode] = React.useState(false);

    //Let's calculate the label Transform Y value based on the computed Input height
    const CalculateLabelTransform = React.useCallback(() => {
        if (InputRef) {
            if (InputRef.current && LabelRef.current) {
                const InputHeight = InputRef.current.getBoundingClientRect().height;
                const LabelHeight = LabelRef.current.getBoundingClientRect().height;
                const LegendHeight = parseFloat(LabelScale) * LabelHeight;

                //Checking what props to take for the fontSize
                let ThemeFontSize = theme.FormTextInput.InputFont.fontSize;
                let InputFontSizeFinal = InputFontProp.fontSize ? InputFontProp.fontSize : ThemeFontSize;
                const LegendFontSize = parseFloat(LabelScale) * parseFloat(InputFontSizeFinal);
                // const TformY = LegendHeight + parseInt(PaddingTop) + (InputHeight - LabelHeight) / 2;
                const TformY = LegendHeight + (InputHeight - LabelHeight) / 2;
                setLabelTransformY(`${TformY}px`);
                const FinalLegStyle = {height: `${LegendHeight}px`, fontSize: `${LegendFontSize}px`};
                setLegendSpanStyle(FinalLegStyle);

                if (placeHolder) {
                    const LegendHeightForMargin = LabelHeight;
                    setInputMarginBottom(`${LegendHeightForMargin / 2}px`);
                } else {
                    setInputMarginBottom(`${LegendHeight / 2}px`);
                }
            }
        } else {
            //Select option -> no need for a ref, fixed outlined

            // const InputHeight = InputRef.current.getBoundingClientRect().height;
            // const PaddingTop = InputRef.current.style.paddingTop;
            const LabelHeight = LabelRef.current.getBoundingClientRect().height;
            const LegendHeight = LabelHeight;
            // //Checking what props to take for the fontSize
            let ThemeFontSize = theme.FormTextInput.InputFont.fontSize;
            let InputFontSizeFinal = InputFontProp.fontSize ? InputFontProp.fontSize : ThemeFontSize;
            const LegendFontSize = parseFloat(LabelScale) * parseFloat(InputFontSizeFinal);

            // // const TformY = LegendHeight + parseInt(PaddingTop) + (InputHeight - LabelHeight) / 2;
            // const TformY = LegendHeight + (InputHeight - LabelHeight) / 2;
            // setLabelTransformY(`${TformY}px`);
            const FinalLegStyle = {height: `${LegendHeight}px`, fontSize: `${LegendFontSize}px`};
            setLegendSpanStyle(FinalLegStyle);
            setInputMarginBottom(`${LegendHeight / 2}px`);
        }
    }, [InputRef, InputFontProp, LabelScale, theme, placeHolder]);

    //Calculating the Label transform position when mounting
    React.useEffect(() => {
        if (variant === "outlined" || variant === "standard") {
            CalculateLabelTransform();
        }
    }, [CalculateLabelTransform, variant]);

    const HandleHoverFieldSet = (Event) => {
        if (Event === "Enter") {
            setOverLayEditMode(true);
        } else {
            setOverLayEditMode(false);
        }
    };

    const HandleInputClick = () => {
        ControlledEditModeActive(true);
    };

    var LegendClass = classes.Legend;
    var FieldSetClass;
    if (variant === "outlined") {
        FieldSetClass = clsx(classes.FieldSet, classes.FieldSetOutlinedBorderEmpty);
    } else if (variant === "outlinedSimple") {
        FieldSetClass = clsx(classes.FieldSet, classes.FieldSetOutlinedBorderEmpty);
    } else {
        FieldSetClass = clsx(classes.FieldSetStandard);
    }
    // = clsx(variant === "outlined" ? classes.FieldSet : classes.FieldSetStandard, variant === "outlined" ? classes.FieldSetOutlinedBorderEmpty : null);
    var LabelClass = clsx(classes.Label, classes.LabelEmpty, classes.InputFont);

    var InputClass = clsx(classes.Input, classes.InputColor);
    var LabelTransform;

    //Borderd for standard Input Type
    var FocusBorder;
    var HoverBorder;
    var ErrorBorder;
    var FinalFocusBorderStyle = {};

    if (variant !== "outlined") {
        let BorderColorFinal = Colors ? (Colors.Focused ? Colors.Focused : null) : null;
        let BorderStyleFinal = BorderStyle ? (BorderStyle.Focused ? BorderStyle.Focused : null) : null;
        if (BorderStyleFinal) {
            FinalFocusBorderStyle = {borderBottom: BorderStyleFinal};
        } else if (BorderColorFinal) {
            FinalFocusBorderStyle = {borderBottom: `2px solid ${BorderColorFinal}`};
        } else {
            FinalFocusBorderStyle = theme.FormTextInput.FieldSetBorderFocus;
        }
    }

    if (variant !== "outlined") {
        FocusBorder = clsx(classes.StandardInputBorder, classes.FocusBorderHidden);
        if (disabled === true) {
            //Disabled border
            FieldSetClass = clsx(FieldSetClass, classes.BorderDisabled);
        } else {
            if (HasError) {
                //Error border
                ErrorBorder = clsx(classes.StandardInputBorder, classes.ErrorBorder);
            } else {
                if (IsFocus) {
                    //Focus Border
                    FocusBorder = clsx(FocusBorder, classes.FocusBorderFocused);
                } else {
                    if (HasValue) {
                        //Filled Border
                        HoverBorder = clsx(classes.StandardInputBorder, classes.HoverBorderHovered);
                        FieldSetClass = clsx(FieldSetClass, classes.BorderFilled);
                    } else {
                        HoverBorder = clsx(classes.StandardInputBorder, classes.HoverBorderHovered);
                        FieldSetClass = clsx(FieldSetClass, classes.BorderEmpty);
                        //Empty Border
                    }
                }
            }
        }
    }

    if (InputType === "Select") {
        if (disabled === true) {
            //When input is disabled
            LegendClass = clsx(classes.Legend, classes.LegendTop);
            LabelClass = clsx(LabelClass, classes.LabelDisabled);
            FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderDisabled : null);
            InputClass = clsx(classes.Input, classes.InputColorDisabled);
        } else {
            if (HasError) {
                //With Errors
                if (IsFocus) {
                    if (StyleBorderErrorFocusTrue) {
                        //Keep the border and Error Style the same as error style, just the input color is focus
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelError);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderError : null);
                        InputClass = clsx(classes.Input, classes.InputColorFocus);
                    } else {
                        //Error and Focus Ignore Error while typing -> style = focus
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelFocus);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderFocus : null);
                        InputClass = clsx(classes.Input, classes.InputColorFocus);
                    }
                } else {
                    //Error and has value -> Style is Error with label on top
                    LegendClass = clsx(classes.Legend, classes.LegendTop);
                    LabelClass = clsx(LabelClass, classes.LabelError);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderError : null);
                    InputClass = clsx(classes.Input, classes.InputColorError);
                }
            } else {
                if (IsFocus) {
                    //Normal Focus, ignore the PlaceHolder ->  Label should be on top and style is Focused
                    LegendClass = clsx(classes.Legend, classes.LegendTop);
                    LabelClass = clsx(LabelClass, classes.LabelFocus);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderFocus : null);
                    InputClass = clsx(classes.Input, classes.InputColorFocus);
                } else {
                    //Not focused, and has a value -> Style is Filled
                    LegendClass = clsx(classes.Legend, classes.LegendTop);
                    LabelClass = clsx(LabelClass, classes.LabelFilled);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderFilled : null);
                    InputClass = clsx(classes.Input, classes.InputColorFilled);
                }
            }
        }
    } else {
        //Simple Input
        if (disabled === true) {
            //When input is disabled
            if (HasValue) {
                //Disabled style with legend on top
                LegendClass = clsx(classes.Legend, classes.LegendTop);
                LabelClass = clsx(LabelClass, classes.LabelDisabled);
                FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderDisabled : null);
                InputClass = clsx(classes.Input, classes.InputColorDisabled);
            } else {
                if (placeHolder) {
                    //Disabled style with legend on top
                    //Disabled style with legend on top
                    LegendClass = clsx(classes.Legend, classes.LegendTop);
                    LabelClass = clsx(LabelClass, classes.LabelDisabled);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderDisabled : null);
                    InputClass = clsx(classes.Input, classes.InputColorDisabled);
                } else {
                    //Disabled style with legend in the middle
                    LabelClass = clsx(LabelClass, classes.LabelDisabledEmpty);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderDisabled : null);
                    InputClass = clsx(classes.Input, classes.InputColorDisabled);
                    if (variant === "outlined" || variant === "outlinedSimple") {
                        LabelTransform = {
                            transform: `scale(1) translate(12px, ${LabelTransformY}) translateX(0px) translateY(0px)`,
                        };
                    } else {
                        LabelTransform = {
                            transform: `scale(1) translate(0px, ${LabelTransformY}) translateX(0px) translateY(0px)`,
                        };
                    }
                }
            }
        } else {
            if (HasError) {
                //With Errors
                if (IsFocus) {
                    if (StyleBorderErrorFocusTrue) {
                        //Keep the border and Error Style the same as error style, just the input color is focus
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelError);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderError : null);
                        InputClass = clsx(classes.Input, classes.InputColorFocus);
                    } else {
                        //Error and Focus Ignore Error while typing -> style = focus
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelFocus);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderFocus : null);
                        InputClass = clsx(classes.Input, classes.InputColorFocus);
                    }
                } else {
                    if (HasValue) {
                        //Error and has value -> Style is Error with label on top
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelError);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderError : null);
                        InputClass = clsx(classes.Input, classes.InputColorError);
                    } else {
                        if (placeHolder) {
                            //Error ,no value but placeHolder -> Style is Error with label on top
                            LegendClass = clsx(classes.Legend, classes.LegendTop);
                            LabelClass = clsx(LabelClass, classes.LabelError);
                            FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderError : null);
                            InputClass = clsx(classes.Input, classes.InputColorError);
                        } else {
                            //Error, no vlaue and no placeholder -> style is Error empty
                            LabelClass = clsx(LabelClass, classes.LabelErrorEmpty);
                            FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderError : null);
                            InputClass = clsx(classes.Input, classes.InputColorError);
                            if (variant === "outlined") {
                                LabelTransform = {
                                    transform: `scale(1) translate(12px, ${LabelTransformY}) translateX(0px) translateY(0px)`,
                                };
                            } else {
                                LabelTransform = {
                                    transform: `scale(1) translate(0px, ${LabelTransformY}) translateX(0px) translateY(0px)`,
                                };
                            }
                        }
                    }
                }
            } else if (placeHolder) {
                //No ERRORS && PlaceHolder
                if (IsFocus) {
                    //Normal Focus, ignore the PlaceHolder ->  Label should be on top and style is Focused
                    LegendClass = clsx(classes.Legend, classes.LegendTop);
                    LabelClass = clsx(LabelClass, classes.LabelFocus);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderFocus : null);
                    InputClass = clsx(classes.Input, classes.InputColorFocus);
                } else {
                    if (HasValue) {
                        //Not focused, and has a value -> Style is Filled
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelFilled);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderFilled : null);
                        InputClass = clsx(classes.Input, classes.InputColorFilled);
                    } else {
                        //When a placeHolder was passed and not Focused ->  Label should be on top and style is Empty
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelPlaceHolderEmpty);

                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" ? classes.FieldSetOutlinedBorderEmpty : null);
                        InputClass = clsx(classes.Input, classes.InputColorEmpty);
                    }
                }
            } else {
				
                //No ERRORS && No PlaceHolder

                if (IsFocus) {
                    //When Focused -> Label should be on top and style is Focused
                    LegendClass = clsx(classes.Legend, classes.LegendTop);
                    LabelClass = clsx(LabelClass, classes.LabelFocus);
                    FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderFocus : null);
                    InputClass = clsx(classes.Input, classes.InputColorFocus);
                } else {
                    if (HasValue) {
                        //Not focused, and has a value -> Style is Filled
                        LegendClass = clsx(classes.Legend, classes.LegendTop);
                        LabelClass = clsx(LabelClass, classes.LabelFilled);
                        FieldSetClass = clsx(FieldSetClass, variant === "outlined" || variant === "outlinedSimple" ? classes.FieldSetOutlinedBorderFilled : null);
                        InputClass = clsx(classes.Input, classes.InputColorFilled);
                    } else {
				
                        //Default Empty Style
                        if (variant === "outlined" || variant === "outlinedSimple") {
                            LabelTransform = {
                                transform: `scale(1) translate(12px, ${LabelTransformY}) translateX(0px) translateY(0px)`,
                            };
                        } else {
                            LabelTransform = {
                                transform: `scale(1) translate(0px, ${LabelTransformY}) translateX(0px) translateY(0px)`,
                            };
                        }
                    }
                }
            }
        }
    }

    const LabelRef = React.useRef(null);

    //Needs to be inline for calculation purposes
    var FinalInputPadding;
    if (inputVerticalPadding) {
        if (InputType === "Select") {
            if (MultiSelect) {
                FinalInputPadding = `${inputVerticalPadding} 0px ${inputVerticalPadding} 8px`;
            } else {
                FinalInputPadding = `${inputVerticalPadding} 0px ${inputVerticalPadding} 11px`;
            }
        } else {
            if (variant === "outlined") {
                FinalInputPadding = `${inputVerticalPadding} 13px ${inputVerticalPadding} 5px`;
            } else {
                FinalInputPadding = `${inputVerticalPadding} 0px ${inputVerticalPadding} 0px`;
            }
        }
    } else {
        if (InputType === "Select") {
            if (variant === "outlinedSimple") {
                if (MultiSelect) {
                    FinalInputPadding = `${theme.FormTextInput.Input_OutlinedSimple.paddingTop} 0px ${theme.FormTextInput.Input_OutlinedSimple.paddingBottom} 8px`;
                } else {
                    FinalInputPadding = `${theme.FormTextInput.Input_OutlinedSimple.paddingTop} 0px ${theme.FormTextInput.Input_OutlinedSimple.paddingBottom} 11px`;
                }
            } else {
                if (MultiSelect) {
                    FinalInputPadding = `${theme.FormTextInput.Input.paddingTop} 0px ${theme.FormTextInput.Input.paddingBottom} 8px`;
                } else {
                    FinalInputPadding = `${theme.FormTextInput.Input.paddingTop} 0px ${theme.FormTextInput.Input.paddingBottom} 11px`;
                }
            }
        } else {
            if (variant === "outlined") {
                FinalInputPadding = `${theme.FormTextInput.Input.paddingTop} 13px ${theme.FormTextInput.Input.paddingBottom} 5px`;
            } else {
                FinalInputPadding = "0px";
            }
        }
    }

    const FinalInputFonts = {
        fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
        fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
        fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
    };
    var FinalComponent;
    if (variant === "outlinedSimple") {
        var EditOverlayComp;
        if (ControlledEditMode === true) {
            if (OverLayEditMode === true) {
                EditOverlayComp = (
                    <div className={classes.EditOverlayComp} style={{zIndex: 10000000}} onClick={HandleInputClick}>
                        <div className={classes.EditOverlayComp_EditIcon}>
                            <EditBox IconSize="25px" />
                        </div>
                    </div>
                );
            }
        }

        FinalComponent = (
            <React.Fragment>
                <div
                    className={classes.Label}
                    style={{
                        fontSize: theme.FormTextInput.LabelFont.fontSize,
                        fontFamily: theme.FormTextInput.LabelFont.fontFamily,
                        fontWeight: theme.FormTextInput.LabelFont.fontWeight,
                        flex: labelFlex,
                        justifyContent: "flex-end",
                        whiteSpace: "nowrap",
                    }}
                >
                    {label}
                    {required && <span style={{paddingLeft: "4px"}}>{"*"}</span>}
                </div>
                <fieldset
                    onMouseEnter={() => HandleHoverFieldSet("Enter")}
                    onMouseLeave={() => HandleHoverFieldSet("Leave")}
                    className={FieldSetClass}
                    style={{
                        flex: "1 1 auto",
                        ...Lockedstyles.FieldSet,
                        ...LockedstylesWithProps(props).FieldSet,
                    }}
                >
                    <div
                        className={classes.OverlayWrapper}
                        style={{
                            position: "relative",
                            zIndex: OverLayEditMode === true ? 10000000 : null,
                            ...LockedstylesWithProps(props).OverlayWrapper,
                        }}
                    >
                        {EditOverlayComp}
                        {FormInputComponent({
                            InputClass,
                            InputMarginBottom,
                            FinalInputPadding,
                            FinalInputFonts,
                            options,
                            curvalue,
                            initialvalue,
                            UpdateOptionsFromOutside,
                        })}
                    </div>
                </fieldset>
            </React.Fragment>
        );
    } else {
        FinalComponent = (
            <fieldset
                className={FieldSetClass}
                style={{
                    ...Lockedstyles.FieldSet,
                    ...LockedstylesWithProps(props).FieldSet,
                }}
            >
                <legend className={LegendClass} style={{height: LegendSpanStyle.height, fontSize: LegendSpanStyle.fontSize}}>
                    <span className={classes.LegendSpan}>{label}</span>
                    {required && <span className={classes.LegendSpan}>{"*"}</span>}
                </legend>
                <div ref={LabelRef} className={LabelClass} style={LabelTransform}>
                    <span
                        className={classes.LabelSpan}
                        style={{
                            padding: variant === "outlined" ? null : "0px",
                            fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                            fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                            fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
                        }}
                    >
                        {label}
                    </span>
                    {required && (
                        <span
                            className={classes.LabelSpan}
                            style={{
                                fontSize: InputFontProp.fontSize ? InputFontProp.fontSize : theme.FormTextInput.InputFont.fontSize,
                                fontFamily: InputFontProp.fontFamily ? InputFontProp.fontFamily : theme.FormTextInput.InputFont.fontFamily,
                                fontWeight: InputFontProp.fontWeight ? InputFontProp.fontWeight : theme.FormTextInput.InputFont.fontWeight,
                            }}
                        >
                            {"*"}
                        </span>
                    )}
                </div>

                {FormInputComponent({
                    InputClass,
                    InputMarginBottom,
                    FinalInputPadding,
                    FinalInputFonts,
                    options,
                    curvalue,
                    initialvalue,
                    UpdateOptionsFromOutside,
                })}

                <div className={FocusBorder} style={FinalFocusBorderStyle} content='""'></div>
                <div className={HoverBorder} content='""'></div>
                <div className={ErrorBorder} content='""'></div>
            </fieldset>
        );
    }
    return <React.Fragment>{FinalComponent}</React.Fragment>;
});

FormAnimatedWrapper.defaultProps = {
    InputType: "Input",
    variant: "outlined",
    label: "Field Label",
    InputRef: null,
    FormInputComponent: null,
    required: false,
    placeHolder: null,
    readOnly: false,
    disabled: false,
    LabelScale: "0.75", //Range is 0.75 - 0.85 otherwise fonts will crack
    StyleBorderErrorFocusTrue: true,
    Colors: {Empty: null, Filled: null, Error: null, Focused: null, Disabled: null},
    InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
    inputVerticalPadding: null,
    BorderStyle: {Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null},
    HelperStyle: {fontFamily: null, fontSize: null, marginTop: null, color: null},
    ErrorStyle: {color: null},
    LabelStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
    InputStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
    WithEmptyStyle: false,
    ControlledEditMode: false,
    ControlledEditModeActive: null,
};

FormAnimatedWrapper.propTypes = {
    /**
     * FormInputComponent : the component to pass inside
     */
    FormInputComponent: PropTypes.any,
    /**
     * InputRef : Required ref of the input to calcuate the sizes
     */
    InputRef: PropTypes.any,
    /**
     * InputType : Input, Select. Defines the behaviors of the container and the syle to harmonize the sizes between the
     * different components
     */
    InputType: PropTypes.string,
    /**
     * variant: outlined, standard
     */
    variant: PropTypes.string,
    /**
     * input label
     */
    label: PropTypes.string,
    /**
     * placeHolder value
     */
    placeHolder: PropTypes.string,
    /**
     * required
     */
    required: PropTypes.bool,

    /**
     * readOnly
     */
    readOnly: PropTypes.bool,
    /**
     * disabled
     */
    disabled: PropTypes.bool,
    /**
     * LabelScale : 0,75. Range is between 0.75 and 0.85, otherwise the text effect will not work
     */
    LabelScale: PropTypes.string,
    /**
     * StyleBorderErrorFocusTrue : default is true
     * If true then the color of the input is focused when focused otherwise the input color is error color
     */
    StyleBorderErrorFocusTrue: PropTypes.bool,

    Colors: PropTypes.object,
    /**
     * InputFontProp: {fontSize: null, fontFamily: null, fontWeight: null},
     * Fonts for the label and the input
     */
    InputFontProp: PropTypes.object,
    /**
     * LabelStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null},
     * Customize the label (will override Colors)
     */
    LabelStyle: PropTypes.object,
    /**
     * InputStyle: {Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}
     * Customize the Input (will override Colors)
     */
    InputStyle: PropTypes.object,
    /**
     * BorderStyle: {Empty: "1px solid grey", Focused: "1px solid grey", Error: "1px solid grey", Disabled: null, BoderRadius: "2px"},
     */
    BorderStyle: PropTypes.object,
    /**
     * HelperStyle: {fontFamily: null, FontSize: null, marginTop: null, color: null},
     */
    HelperStyle: PropTypes.object,
    /**
     * ErrorStyle: {fontFamily: "roboto", fontSize: "14px", marginTop: "2px", color: "red"},
     */
    ErrorStyle: PropTypes.object,
    /**
     * Change the vertical padding of the input
     * inputVerticalPadding: "2px"
     */
    inputVerticalPadding: PropTypes.string,
    /**
     * Default : false
     * When using the standard theme, when false -> Empty style = Filled Style when true Empty style is applied
     */
    WithEmptyStyle: PropTypes.bool,
    /**
     * Default : false
     * When using the standard theme, when false -> Empty style = Filled Style when true Empty style is applied
     */
    ControlledEditMode: PropTypes.any,
    /**
     * Default : false
     * When using the standard theme, when false -> Empty style = Filled Style when true Empty style is applied
     */
    ControlledEditModeActive: PropTypes.any,
};

export default FormAnimatedWrapper;
