import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import {useHistory} from "react-router-dom";
import Page from "../../../../../../artibulles-cis/Page/Page";
import AGGridTable from "../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import Table from "../../../../../../artibulles-cis/TableNew/Table";
import CustomerEdit from "../Customer/CustomerEditDetails/CustomerEdit";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "250px",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const CustomerTable = React.memo(function CustomerTable(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Page = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
	console.log("Rendering Customer")
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const TypeRendering = (params) => {
        let RowData = params.data;
        // console.log("RowData", RowData);
        let IsCustomer = RowData.is_customer;
        let IsSupplier = RowData.is_supplier;
        let Output;
        if (IsCustomer === true && IsSupplier === true) {
            Output = "Both";
        } else if (IsCustomer === true) {
            Output = "Customer";
        } else {
            Output = "Supplier";
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };

    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };

    const DeleteCondition = (args) => {
        // console.log("args", args)
		return true
		
		// let status_invoice = args.status_invoice;
        // if (status_invoice === "Draft") {
        //     return true;
        // } else {
        //     return true; //DEVONLY!! Should be false
        // }
    };

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 2,
            // OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];
    const TableColumns = [
        {
            field: "company",
            headerName: "Company",
            headerClass: "ag-CenterHeader",
            flex: 1,
            minWidth: 170,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "is_supplier",
            headerName: "Type",
            headerClass: "ag-CenterHeader",
            width: 130,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            // sortIndex: 1,
            // sort: "desc",
            cellRenderering: TypeRendering,
        },
        {
            field: "description",
            headerName: "Description",
            headerClass: "ag-CenterHeader",
            flex : 2,
			minWidth: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
        },
    ];

    // const TableOptions = {
    //     Resizable: true,
    //     Sortable: true,
    //     Filterable: true,
    //     SupressMenu: true,
    //     WrapText: true,
    //     WrapHeader: false,
    //     HeaderHeight: 30,
    //     RowHeight: 24,
    //     RowFontSize: "13px",
    //     HeaderFontSize: "14px",
    //     StatusBar: true,
    // };
    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
    	StatusBar : true
    };

	let URLAbsolute = {
        ALL: `${ProdURL}/api/companies`,
        QUERY: "",
        DELETEONE: `${ProdURL}/api/companies/company/edit`,
        DELETEMANY: "",
        PUT: `${ProdURL}/api/companies/company/edit`,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [ReloadTableData, setReloadTableData] = useState(true);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCloseEditCard = () => {
        // console.log("Handle Close Edit Card");
        setShowEditCard(false);
        setEditCardId(null);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;

    if (ShowEditCard === true) {
        ModalCard = (
            <CardModal
                CardID="EditInvoiceCard"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <CustomerEdit Id={EditCardId} HandleCloseViewCard={HandleCloseEditCard} />
            </CardModal>
        );
    }

    return (
        <Page PageRef={Ref_Page} PagePadding={"10px 10px"} ClassExtendsPageContent={classes.ClassExtendsPageContent}>
            {ModalCard}
            <AGGridTable
                TableName="InvoiceTableAll"
                URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                MainDataSource=""
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTableData}
                SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        </Page>
    );

    /***************** RENDER ******************/
});

export default CustomerTable;
