/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {ArrowFilledLeft, FolderOpened, FolderClosed} from "@artibulles-cis/react-icons";

import useNavSideBarContext from "../utils/context/navSideBarContext/useNavSideBarContext";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {};
//eslint-disable-next-line
const Lockedstyles = {
    ControllerButtonsOnly: {
        position: "absolute",
        top: "0px",
        right: "0px",
        display: "flex",
        zIndex: "10000",
    },
};

const styles = createUseStyles((theme) => ({
    NavSideBarController: theme.NavSideBarController.Controller,
    NavSideBarControllerAbsolute: (props) => {
        if (props.ControllerScrollBarPadding === "Native") {
            return theme.NavSideBarController.ControllerWhitoutContent_NativeScrollBar;
        } else if (props.ControllerScrollBarPadding === "PerfectScrollBar") {
            return theme.NavSideBarController.ControllerWhitoutContent_PerfectScrollBar;
        } else {
            return theme.NavSideBarController.ControllerWhitoutContent_NoScrollBar;
        }
    },
    ControllerIconWrapper: theme.NavSideBarController.ControllerIconWrapper,
    ControllerContent: theme.NavSideBarController.ControllerContent,
    ControllerContent_Minimized: theme.NavSideBarController.ControllerContent_Minimized,
    ExpandCollapseController: theme.NavSideBarController.ExpandCollapseController,
    CollapseExpandIcon: theme.NavSideBarController.CollapseExpandIcon,
}));

/**
 * Controller for the NavSideBar to handle the Minimized Status
 * The Icon can be placed on the right or left Side of the SideBar
 */
const NavSideBarController = React.forwardRef(function NavSideBarController(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        IconController,
        IconControllerPosition,
        WithCollapseAllController,
        CustomCollapseAllController,
        WithExpandAllController,
        CustomExpandAllController,
        ControllerButtonsOnly,
        HandleMiniMize,
        IsMinimized,
        Hide,
        ClassOverrideNavSideBarController,
        ClassExtendNavSideBarController,
        ControllerScrollBarPadding,
        ClassOverrideControllerIconWrapper,
        ClassOverrideControllerContent,
        IconControllerStyle,
        CollapseExpandControllerIconStyle,
        ClassOverrideNavSideBarControllerExpandCollapseController,
        ClassExtendNavSideBarControllerExpandCollapseController,
        ClassOverrideNavSideBarControllerCollapseExpandIcon,
        ClassExtendNavSideBarControllerCollapseExpandIcon,
		Ref_Controller,
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const {CollapseExpandAccordions, HasOpenSubLink} = useNavSideBarContext();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    const HandleCollapseAll = () => {
        if (HasOpenSubLink === true) {
            CollapseExpandAccordions("Collapse");
        }
    };
    const HandleExpandAll = () => {
        if (HasOpenSubLink === false) {
            CollapseExpandAccordions("Expand");
        }
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    //MINIMIZE CONTROLLER COMPONENT AND STYLE

    const DefaultIconControllerStyle = {
        IconSize: theme.NavSideBarController.ControllerIconStyle.IconSize,
        SVGFillColor: theme.NavSideBarController.ControllerIconStyle.SVGFillColor,
        SVGStrokeColor: theme.NavSideBarController.ControllerIconStyle.SVGStrokeColor,
        SVGStrokeWidth: theme.NavSideBarController.ControllerIconStyle.SVGStrokeWidth,
        SVGStrokeHoverColor: theme.NavSideBarController.ControllerIconStyle.SVGStrokeHoverColor,
        SVGFillHoverColor: theme.NavSideBarController.ControllerIconStyle.SVGFillHoverColor,
        Frame: theme.NavSideBarController.ControllerIconStyle.Frame,
        FrameStrokeWidth: theme.NavSideBarController.ControllerIconStyle.FrameStrokeWidth,
        FrameStrokeColor: theme.NavSideBarController.ControllerIconStyle.FrameStrokeColor,
        FrameFillColor: theme.NavSideBarController.ControllerIconStyle.FrameFillColor,
        FrameStrokeColorHover: theme.NavSideBarController.ControllerIconStyle.FrameStrokeColorHover,
        FrameFillColorHover: theme.NavSideBarController.ControllerIconStyle.FrameFillColorHover,
        InnerPadding: theme.NavSideBarController.ControllerIconStyle.InnerPadding,
    };

    const FinalIconControllerStyle = {
        IconSize: IconControllerStyle ? (IconControllerStyle.IconSize ? IconControllerStyle.IconSize : DefaultIconControllerStyle.IconSize) : DefaultIconControllerStyle.IconSize,
        SVGFillColor: IconControllerStyle ? (IconControllerStyle.SVGFillColor ? IconControllerStyle.SVGFillColor : DefaultIconControllerStyle.SVGFillColor) : DefaultIconControllerStyle.SVGFillColor,
        SVGStrokeColor: IconControllerStyle
            ? IconControllerStyle.SVGStrokeColor
                ? IconControllerStyle.SVGStrokeColor
                : DefaultIconControllerStyle.SVGStrokeColor
            : DefaultIconControllerStyle.SVGStrokeColor,
        SVGStrokeWidth: IconControllerStyle
            ? IconControllerStyle.SVGStrokeWidth
                ? IconControllerStyle.SVGStrokeWidth
                : DefaultIconControllerStyle.SVGStrokeWidth
            : DefaultIconControllerStyle.SVGStrokeWidth,
        SVGStrokeHoverColor: IconControllerStyle
            ? IconControllerStyle.SVGStrokeHoverColor
                ? IconControllerStyle.SVGStrokeHoverColor
                : DefaultIconControllerStyle.SVGStrokeHoverColor
            : DefaultIconControllerStyle.SVGStrokeHoverColor,
        SVGFillHoverColor: IconControllerStyle
            ? IconControllerStyle.SVGFillHoverColor
                ? IconControllerStyle.SVGFillHoverColor
                : DefaultIconControllerStyle.SVGFillHoverColor
            : DefaultIconControllerStyle.SVGFillHoverColor,
        Frame: IconControllerStyle ? (IconControllerStyle.Frame ? IconControllerStyle.Frame : DefaultIconControllerStyle.Frame) : DefaultIconControllerStyle.Frame,
        FrameStrokeWidth: IconControllerStyle
            ? IconControllerStyle.FrameStrokeWidth
                ? IconControllerStyle.FrameStrokeWidth
                : DefaultIconControllerStyle.FrameStrokeWidth
            : DefaultIconControllerStyle.FrameStrokeWidth,
        FrameStrokeColor: IconControllerStyle
            ? IconControllerStyle.FrameStrokeColor
                ? IconControllerStyle.FrameStrokeColor
                : DefaultIconControllerStyle.FrameStrokeColor
            : DefaultIconControllerStyle.FrameStrokeColor,
        FrameFillColor: IconControllerStyle
            ? IconControllerStyle.FrameFillColor
                ? IconControllerStyle.FrameFillColor
                : DefaultIconControllerStyle.FrameFillColor
            : DefaultIconControllerStyle.FrameFillColor,
        FrameStrokeColorHover: IconControllerStyle
            ? IconControllerStyle.FrameStrokeColorHover
                ? IconControllerStyle.FrameStrokeColorHover
                : DefaultIconControllerStyle.FrameStrokeColorHover
            : DefaultIconControllerStyle.FrameStrokeColorHover,
        FrameFillColorHover: IconControllerStyle
            ? IconControllerStyle.FrameFillColorHover
                ? IconControllerStyle.FrameFillColorHover
                : DefaultIconControllerStyle.FrameFillColorHover
            : DefaultIconControllerStyle.FrameFillColorHover,
        InnerPadding: IconControllerStyle ? (IconControllerStyle.InnerPadding ? IconControllerStyle.InnerPadding : DefaultIconControllerStyle.InnerPadding) : DefaultIconControllerStyle.InnerPadding,
    };

    //COLLAPSE ICON AND CLASS STYLES
    var FinalCollapseExpandIconClass;
    if (ClassOverrideNavSideBarControllerCollapseExpandIcon) {
        FinalCollapseExpandIconClass = ClassOverrideNavSideBarControllerCollapseExpandIcon;
    } else {
        FinalCollapseExpandIconClass = clsx(classes.CollapseExpandIcon, ClassExtendNavSideBarControllerCollapseExpandIcon);
    }

    const DefaultCollapseExpandControllerIconStyle = {
        IconSize: theme.NavSideBarController.CollapseExpandControllerIconStyle.IconSize,
        SVGFillColor: theme.NavSideBarController.CollapseExpandControllerIconStyle.SVGFillColor,
        SVGStrokeColor: theme.NavSideBarController.CollapseExpandControllerIconStyle.SVGStrokeColor,
        SVGStrokeWidth: theme.NavSideBarController.CollapseExpandControllerIconStyle.SVGStrokeWidth,
        SVGStrokeHoverColor: theme.NavSideBarController.CollapseExpandControllerIconStyle.SVGStrokeHoverColor,
        SVGFillHoverColor: theme.NavSideBarController.CollapseExpandControllerIconStyle.SVGFillHoverColor,
        Frame: theme.NavSideBarController.CollapseExpandControllerIconStyle.Frame,
        FrameStrokeWidth: theme.NavSideBarController.CollapseExpandControllerIconStyle.FrameStrokeWidth,
        FrameStrokeColor: theme.NavSideBarController.CollapseExpandControllerIconStyle.FrameStrokeColor,
        FrameFillColor: theme.NavSideBarController.CollapseExpandControllerIconStyle.FrameFillColor,
        FrameStrokeColorHover: theme.NavSideBarController.CollapseExpandControllerIconStyle.FrameStrokeColorHover,
        FrameFillColorHover: theme.NavSideBarController.CollapseExpandControllerIconStyle.FrameFillColorHover,
        InnerPadding: theme.NavSideBarController.CollapseExpandControllerIconStyle.InnerPadding,
    };

    const FinalCollapseExpandControllerIconStyle = {
        IconSize: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.IconSize
                ? CollapseExpandControllerIconStyle.IconSize
                : DefaultCollapseExpandControllerIconStyle.IconSize
            : DefaultCollapseExpandControllerIconStyle.IconSize,
        SVGFillColor: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.SVGFillColor
                ? CollapseExpandControllerIconStyle.SVGFillColor
                : DefaultCollapseExpandControllerIconStyle.SVGFillColor
            : DefaultCollapseExpandControllerIconStyle.SVGFillColor,
        SVGStrokeColor: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.SVGStrokeColor
                ? CollapseExpandControllerIconStyle.SVGStrokeColor
                : DefaultCollapseExpandControllerIconStyle.SVGStrokeColor
            : DefaultCollapseExpandControllerIconStyle.SVGStrokeColor,
        SVGStrokeWidth: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.SVGStrokeWidth
                ? CollapseExpandControllerIconStyle.SVGStrokeWidth
                : DefaultCollapseExpandControllerIconStyle.SVGStrokeWidth
            : DefaultCollapseExpandControllerIconStyle.SVGStrokeWidth,
        SVGStrokeHoverColor: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.SVGStrokeHoverColor
                ? CollapseExpandControllerIconStyle.SVGStrokeHoverColor
                : DefaultCollapseExpandControllerIconStyle.SVGStrokeHoverColor
            : DefaultCollapseExpandControllerIconStyle.SVGStrokeHoverColor,
        SVGFillHoverColor: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.SVGFillHoverColor
                ? CollapseExpandControllerIconStyle.SVGFillHoverColor
                : DefaultCollapseExpandControllerIconStyle.SVGFillHoverColor
            : DefaultCollapseExpandControllerIconStyle.SVGFillHoverColor,
        Frame: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.Frame
                ? CollapseExpandControllerIconStyle.Frame
                : DefaultCollapseExpandControllerIconStyle.Frame
            : DefaultCollapseExpandControllerIconStyle.Frame,
        FrameStrokeWidth: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.FrameStrokeWidth
                ? CollapseExpandControllerIconStyle.FrameStrokeWidth
                : DefaultCollapseExpandControllerIconStyle.FrameStrokeWidth
            : DefaultCollapseExpandControllerIconStyle.FrameStrokeWidth,
        FrameStrokeColor: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.FrameStrokeColor
                ? CollapseExpandControllerIconStyle.FrameStrokeColor
                : DefaultCollapseExpandControllerIconStyle.FrameStrokeColor
            : DefaultCollapseExpandControllerIconStyle.FrameStrokeColor,
        FrameFillColor: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.FrameFillColor
                ? CollapseExpandControllerIconStyle.FrameFillColor
                : DefaultCollapseExpandControllerIconStyle.FrameFillColor
            : DefaultCollapseExpandControllerIconStyle.FrameFillColor,
        FrameStrokeColorHover: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.FrameStrokeColorHover
                ? CollapseExpandControllerIconStyle.FrameStrokeColorHover
                : DefaultCollapseExpandControllerIconStyle.FrameStrokeColorHover
            : DefaultCollapseExpandControllerIconStyle.FrameStrokeColorHover,
        FrameFillColorHover: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.FrameFillColorHover
                ? CollapseExpandControllerIconStyle.FrameFillColorHover
                : DefaultCollapseExpandControllerIconStyle.FrameFillColorHover
            : DefaultCollapseExpandControllerIconStyle.FrameFillColorHover,
        InnerPadding: CollapseExpandControllerIconStyle
            ? CollapseExpandControllerIconStyle.InnerPadding
                ? CollapseExpandControllerIconStyle.InnerPadding
                : DefaultCollapseExpandControllerIconStyle.InnerPadding
            : DefaultCollapseExpandControllerIconStyle.InnerPadding,
    };

    //COLLAPSE ALL CONTROLLER COMPONENT AND STYLE
    var CollapseAllController;

    if (WithCollapseAllController) {
        // if (CustomCollapseAllController) {
        //     //Need to return the customCollapseController
        //     return <CustomCollapseAllController />;
        // } else {
        CollapseAllController = (
            <div className={FinalCollapseExpandIconClass} onClick={HandleCollapseAll}>
                <FolderClosed
                    IconSize={FinalCollapseExpandControllerIconStyle.IconSize}
                    SVGFillColor={FinalCollapseExpandControllerIconStyle.SVGFillColor}
                    SVGStrokeColor={FinalCollapseExpandControllerIconStyle.SVGStrokeColor}
                    SVGStrokeWidth={FinalCollapseExpandControllerIconStyle.SVGStrokeWidth}
                    SVGStrokeHoverColor={FinalCollapseExpandControllerIconStyle.SVGStrokeHoverColor}
                    SVGFillHoverColor={FinalCollapseExpandControllerIconStyle.SVGFillHoverColor}
                    Frame={FinalCollapseExpandControllerIconStyle.Frame}
                    FrameStrokeWidth={FinalCollapseExpandControllerIconStyle.FrameStrokeWidth}
                    FrameStrokeColor={FinalCollapseExpandControllerIconStyle.FrameStrokeColor}
                    FrameFillColor={FinalCollapseExpandControllerIconStyle.FrameFillColor}
                    FrameStrokeColorHover={FinalCollapseExpandControllerIconStyle.FrameStrokeColorHover}
                    FrameFillColorHover={FinalCollapseExpandControllerIconStyle.FrameFillColorHover}
                    InnerPadding={FinalCollapseExpandControllerIconStyle.InnerPadding}
                />
            </div>
        );
        // }
    }

    //EXPAND ALL CONTROLLER COMPONENT AND STYLE
    var ExpandAllController;

    if (WithExpandAllController) {
        // if (CustomExpandAllController) {
        //     //Need to return the customCollapseController
        //     return <CustomCollapseAllController />;
        // } else {
        ExpandAllController = (
            <div className={classes.CollapseExpandIcon} onClick={HandleExpandAll}>
                <FolderOpened
                    IconSize={FinalCollapseExpandControllerIconStyle.IconSize}
                    SVGFillColor={FinalCollapseExpandControllerIconStyle.SVGFillColor}
                    SVGStrokeColor={FinalCollapseExpandControllerIconStyle.SVGStrokeColor}
                    SVGStrokeWidth={FinalCollapseExpandControllerIconStyle.SVGStrokeWidth}
                    SVGStrokeHoverColor={FinalCollapseExpandControllerIconStyle.SVGStrokeHoverColor}
                    SVGFillHoverColor={FinalCollapseExpandControllerIconStyle.SVGFillHoverColor}
                    Frame={FinalCollapseExpandControllerIconStyle.Frame}
                    FrameStrokeWidth={FinalCollapseExpandControllerIconStyle.FrameStrokeWidth}
                    FrameStrokeColor={FinalCollapseExpandControllerIconStyle.FrameStrokeColor}
                    FrameFillColor={FinalCollapseExpandControllerIconStyle.FrameFillColor}
                    FrameStrokeColorHover={FinalCollapseExpandControllerIconStyle.FrameStrokeColorHover}
                    FrameFillColorHover={FinalCollapseExpandControllerIconStyle.FrameFillColorHover}
                    InnerPadding={FinalCollapseExpandControllerIconStyle.InnerPadding}
                />
            </div>
        );
        // }
    }

    var FinalExpandCollapseClass;
    if (ClassOverrideNavSideBarControllerExpandCollapseController) {
        FinalExpandCollapseClass = ClassOverrideNavSideBarControllerExpandCollapseController;
    } else {
        FinalExpandCollapseClass = clsx(classes.ExpandCollapseController, ClassExtendNavSideBarControllerExpandCollapseController);
    }
    var FinalExpandCollapseControllerComponent;
    if (WithCollapseAllController === true || WithExpandAllController === true) {
        var IntController;
        if (HasOpenSubLink) {
            IntController = CollapseAllController;
        } else {
            IntController = ExpandAllController;
        }
        FinalExpandCollapseControllerComponent = (
            <div className={FinalExpandCollapseClass} style={{display: IsMinimized ? "none" : null}}>
                {IntController}
            </div>
        );
    }

    var FinalElem;
    const ControllerIcon = () => {
        if (IconController) {
            if (IconControllerPosition === "right") {
                let ControlElem = React.cloneElement(IconController, {Rotate: IsMinimized ? "0" : -180});

                if (children) {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                            <div className={ClassOverrideControllerContent ? ClassOverrideControllerContent : clsx(classes.ControllerContent)}>{children}</div>
                        </React.Fragment>
                    );
                } else {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                        </React.Fragment>
                    );
                }
                return FinalElem;
            } else {
                let ControlElem = React.cloneElement(IconController, {Rotate: IsMinimized ? "-180" : 0});
                if (children) {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerContent ? ClassOverrideControllerContent : clsx(classes.ControllerContent)}>{children}</div>

                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                        </React.Fragment>
                    );
                } else {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                        </React.Fragment>
                    );
                }
                return FinalElem;
            }
        } else {
            if (IconControllerPosition === "right") {
                let ControlElem = (
                    <ArrowFilledLeft
                        IconSize={FinalIconControllerStyle.IconSize}
                        SVGFillColor={FinalIconControllerStyle.SVGFillColor}
                        SVGStrokeColor={FinalIconControllerStyle.SVGStrokeColor}
                        SVGStrokeWidth={FinalIconControllerStyle.SVGStrokeWidth}
                        SVGStrokeHoverColor={FinalIconControllerStyle.SVGStrokeHoverColor}
                        SVGFillHoverColor={FinalIconControllerStyle.SVGFillHoverColor}
                        Frame={FinalIconControllerStyle.Frame}
                        FrameStrokeWidth={FinalIconControllerStyle.FrameStrokeWidth}
                        FrameStrokeColor={FinalIconControllerStyle.FrameStrokeColor}
                        FrameFillColor={FinalIconControllerStyle.FrameFillColor}
                        FrameStrokeColorHover={FinalIconControllerStyle.FrameStrokeColorHover}
                        FrameFillColorHover={FinalIconControllerStyle.FrameFillColorHover}
                        InnerPadding={FinalIconControllerStyle.InnerPadding}
                        Rotate={IsMinimized ? 0 : -180}
                    />
                );
                if (children) {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                            {FinalExpandCollapseControllerComponent}
                            <div className={ClassOverrideControllerContent ? ClassOverrideControllerContent : clsx(classes.ControllerContent)}>{children}</div>
                        </React.Fragment>
                    );
                } else {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                            {FinalExpandCollapseControllerComponent}
                        </React.Fragment>
                    );
                }
                return FinalElem;
            } else {
                let ControlElem = (
                    <ArrowFilledLeft
                        IconSize={DefaultIconControllerStyle.IconSize ? DefaultIconControllerStyle.IconSize : null}
                        SVGFillColor={DefaultIconControllerStyle.SVGFillColor ? DefaultIconControllerStyle.SVGFillColor : null}
                        SVGStrokeColor={DefaultIconControllerStyle.SVGStrokeColor ? DefaultIconControllerStyle.SVGStrokeColor : null}
                        SVGStrokeWidth={DefaultIconControllerStyle.SVGStrokeWidth ? DefaultIconControllerStyle.SVGStrokeWidth : null}
                        SVGStrokeHoverColor={DefaultIconControllerStyle.SVGStrokeHoverColor ? DefaultIconControllerStyle.SVGStrokeHoverColor : null}
                        SVGFillHoverColor={DefaultIconControllerStyle.SVGFillHoverColor ? DefaultIconControllerStyle.SVGFillHoverColor : null}
                        Frame={DefaultIconControllerStyle.Frame ? DefaultIconControllerStyle.Frame : null}
                        FrameStrokeWidth={DefaultIconControllerStyle.FrameStrokeWidth ? DefaultIconControllerStyle.FrameStrokeWidth : null}
                        FrameStrokeColor={DefaultIconControllerStyle.FrameStrokeColor ? DefaultIconControllerStyle.FrameStrokeColor : null}
                        FrameFillColor={DefaultIconControllerStyle.FrameFillColor ? DefaultIconControllerStyle.FrameFillColor : null}
                        FrameStrokeColorHover={DefaultIconControllerStyle.FrameStrokeColorHover ? DefaultIconControllerStyle.FrameStrokeColorHover : null}
                        FrameFillColorHover={DefaultIconControllerStyle.FrameFillColorHover ? DefaultIconControllerStyle.FrameFillColorHover : null}
                        InnerPadding={DefaultIconControllerStyle.InnerPadding ? DefaultIconControllerStyle.InnerPadding : null}
                        Rotate={IsMinimized ? -180 : 0}
                    />
                );

                if (children) {
                    FinalElem = (
                        <React.Fragment>
                            <div className={ClassOverrideControllerContent ? ClassOverrideControllerContent : clsx(classes.ControllerContent)}>{children}</div>
                            {FinalExpandCollapseControllerComponent}
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                        </React.Fragment>
                    );
                } else {
                    FinalElem = (
                        <React.Fragment>
                            {FinalExpandCollapseControllerComponent}
                            <div className={ClassOverrideControllerIconWrapper ? ClassOverrideControllerIconWrapper : clsx(classes.ControllerIconWrapper)} onClick={HandleMiniMize}>
                                {ControlElem}
                            </div>
                        </React.Fragment>
                    );
                }
                return FinalElem;
            }
        }
    };
    var FinalStyle;
    if (ControllerButtonsOnly === true) {
        FinalStyle = {
            ...Lockedstyles.ControllerButtonsOnly,
            ...{display: Hide === true ? "none" : null},
        };
    } else {
        FinalStyle = {display: Hide === true ? "none" : null};
    }
    var FinalClass;
    if (ControllerButtonsOnly === true) {
        FinalClass = classes.NavSideBarControllerAbsolute;
    } else {
        FinalClass = ClassOverrideNavSideBarController ? ClassOverrideNavSideBarController : clsx(classes.NavSideBarController, ClassExtendNavSideBarController);
    }
    return (
        <div ref={Ref_Controller} className={FinalClass} style={FinalStyle}>
            {ControllerIcon()}
        </div>
    );
    /****************************** RENDER *********************/
});

NavSideBarController.defaultProps = {
    IconController: null,
    IconControllerPosition: "left",
    WithCollapseAllController: false,
    CustomCollapseAllController: null,
    WithExpandAllController: false,
    CustomExpandAllController: null,
    ControllerButtonsOnly: false,
    ControllerScrollBarPadding: null,
    CollapseOnMinimize: false,
    ClassOverrideNavSideBarController: null,
    ClassExtendNavSideBarController: null,
    ClassOverrideControllerIconWrapper: null,
    ClassOverrideControllerContent: null,
    IconControllerStyle: null,
    CollapseExpandControllerIconStyle: null,
    ClassOverrideNavSideBarControllerExpandCollapseController: null,
    ClassExtendNavSideBarControllerExpandCollapseController: null,
    ClassOverrideNavSideBarControllerCollapseExpandIcon: null,
    ClassExtendNavSideBarControllerCollapseExpandIcon: null,
};

NavSideBarController.propTypes = {
    /**
     * A React Element containing the icon to be used to display the Minimize Sate
     */
    IconController: PropTypes.node,
    /**
     * The Position of the Icon Controller (left or right)
     */
    IconControllerPosition: PropTypes.string,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    WithCollapseAllController: PropTypes.bool,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CustomCollapseAllController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    WithExpandAllController: PropTypes.bool,
    /**
     * If true then it will only add the controller buttons as absolute to be able to modify the controller
     * content based on Panels for example
     */
    ControllerButtonsOnly: PropTypes.bool,
    /**
     * Used to set the padding of the controller based on the scrollbar that will be used
     * ControllerScrollBarPadding : Native, PerfectScrollBar, None
     */
    ControllerScrollBarPadding: PropTypes.string,

    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CustomExpandAllController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CollapseOnMinimize: PropTypes.bool,
    /**
     * Overriding the default class of the NavSideBarController.
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideNavSideBarController: PropTypes.string,
    /**
     * Extending the default class of the NavSideBarController.
     */
    ClassExtendNavSideBarController: PropTypes.string,
    /**
     * Overriding the default class of the Controller Icon Wrapper
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideControllerIconWrapper: PropTypes.string,
    /**
     * Overriding the default class of the Controller Content
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideControllerContent: PropTypes.string,
    /**
     * Changing the Style of the Default Icon Controller
     */
    IconControllerStyle: PropTypes.object,
    /**
     * Changing the Style of the Default CollapseExpandControllerIconStyle
     */
    CollapseExpandControllerIconStyle: PropTypes.object,
    /**
     * Overriding the default class
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideNavSideBarControllerExpandCollapseController: PropTypes.string,
    /**
     * Extend the default class
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassExtendNavSideBarControllerExpandCollapseController: PropTypes.string,
    /**
     * Overriding the default class
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassOverrideNavSideBarControllerCollapseExpandIcon: PropTypes.string,
    /**
     * Extend the default class
     * Look in the ArtiWebDefaultTheme to Find the corresponding css properties
     */
    ClassExtendNavSideBarControllerCollapseExpandIcon: PropTypes.string,
};

export default NavSideBarController;
