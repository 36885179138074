import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../artibulles-cis/FormComponent/FormCompontent";
import {ArrowFilledLeft, ArrowFilledRight} from "@artibulles-cis/react-icons";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import CardModal from "@artibulles-cis/react/CardModal";
import AGGridTable from "../../../../../artibulles-cis/AGGridTable/AGGridTable";
import Page from "@artibulles-cis/react/Page";
import TimeEntryEditNew from "../TimeEntryAndTracking/TimeEntryEditNew/TimeEntryEditNew";
import DailyTimeSheetDashboard from "./DailyTimeSheetDashboard";
import TimeSheetEntryDuplicate from "../TimeEntryAndTracking/TimeEntryEditNew/TimeSheetEntryDuplicate";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
const {format, parseISO, formatISO, add} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    DailyBar: {
        display: "flex",
        // alignItems: "center",
    },
    ButtonBar: {
        flex: "0 0 310px",
        display: "flex",
        // alignItems: "center",
    },

    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 10px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "500px",
        margin: "0 auto",
        justifyContent: "center",
    },
    Indicator: {
        padding: "10px 0px 0px 10px",
        flex: "0 1 auto",
        display: "flex",
    },
    BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
        background: "green",
    },
}));

const DailyTimeSheets = React.memo(function DailyTimeSheets(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    const Ref_LocaleIndicator = useRef();
    const Ref_GridApi = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const HandleEditRow = (Id, RowData) => {
        setShowViewCard(true);
        setViewCardId(Id);
    };

    const DeleteCondition = (args) => {
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else {
            return true; //DEVONLY!! Should be false
        }
    };

    const HandleDeleteRow = (Id) => {
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/tasks/time/entry/${Id}`, type: "delete"};
            setLoading(true);
            setAPICallType("deleteone");

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };
    const HandleDuplicateTimeEntry = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };
    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Duplicate",
            ActiveCondition: null,
            Index: 2,
            OnClick: HandleDuplicateTimeEntry,
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];
    const StartTimeRendering = (params) => {
        let RowData = params.data;

        let start_time_seconds = RowData.start_time_seconds;
        if (start_time_seconds) {
            return convertSecondsToTime(start_time_seconds);
        } else {
            return null;
        }
    };
    const EndTimeRendering = (params) => {
        let RowData = params.data;

        let end_time_seconds = RowData.end_time_seconds;
        if (end_time_seconds) {
            return convertSecondsToTime(end_time_seconds);
        } else {
            return null;
        }
    };
    const DurationRendering = (params) => {
        let RowData = params.data;

        let duration_seconds = RowData.duration_seconds;
        if (duration_seconds) {
            return convertSecondsToTime(duration_seconds);
        } else {
            return null;
        }
    };
    const TableColumns = [
        {
            field: "date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "date_Iso",
            headerName: "Date2",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            type: "dateColumn",
            cellClass: "dateType",
            hide: true,
        },
        {
            field: "entry_name",
            headerName: "Entry",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Company",
            headerClass: "ag-CenterHeader",
            width: 160,
            filter: true,
            Lookup: {LookupField: "ref_company", Source: "Companies", ReturnField: "company"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Program",
            headerClass: "ag-CenterHeader",
            width: 160,
            filter: true,
            Lookup: {LookupField: "ref_program", Source: "Programs", ReturnField: "program_name"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Milestone",
            headerClass: "ag-CenterHeader",
            width: 250,
            filter: true,
            Lookup: {LookupField: "ref_milestone", Source: "Milestones", ReturnField: "milestone_name"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Category",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: true,
            Lookup: {LookupField: "ref_taskCategory", Source: "Categories", ReturnField: "category_name"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "start_time_seconds",
            headerName: "Start",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            cellRenderering: StartTimeRendering,
            sortIndex: 1,
            sort: "asc",
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "end_time_seconds",
            headerName: "End",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            cellRenderering: EndTimeRendering,
        },

        {
            field: "duration_seconds",
            headerName: "Duration",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            cellRenderering: DurationRendering,
        },

        {
            field: "is_personal",
            headerName: "Personal",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "is_billable",
            headerName: "Billable",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "calc_billable_Amount",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Currency",
        },
    ];
    let ExcelStyles = [
        {
            id: "dateType",
            dataType: "DateTime",
            numberFormat: {
                format: "yyy-mm-dd",
            },
        },
        {
            id: "Entry",
        },
        {
            id: "Company",
        },
        {
            id: "Program",
        },
        {
            id: "Milestone",
        },
        {
            id: "Start",
        },
        {
            id: "End",
        },

        {
            id: "Duration",
        },

        {
            id: "Personal",

            dataType: "Boolean",
        },
        {
            id: "Billable",

            dataType: "Boolean",
        },
        {
            id: "Amount",
            numberFormat: {
                format: "#,##0.00 €",
            },
        },
    ];
    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = useState({
        date_Iso: formatISO(new Date(Date.now())),
    });
    const [FormValues, setFormValues] = useState({
        date_Iso: formatISO(new Date(Date.now())),
    });
    const [Init, setInit] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);
    const [ReloadTableData, setReloadTableData] = useState(true);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
                if (ApiRes.errorMessage.message === "Entry already running") {
                    toast.error(`There is already a time entry running`, {autoClose: true});
                }
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    // console.log("Data", Data);
                    if (Init === false) {
                        let TimeEntries = Data.TimeEntries;
                        if (TimeEntries && Array.isArray(TimeEntries) && TimeEntries.length > 0) {
                            Ref_LocaleData.current = Data;

                            //We calculate the indicator data
                            var Indicator = {
                                TotalWorkedHours_Seconds: null,
                                TotalWorkedHours_Hours: null,
                                TotalBillableAmount: 0,
                                TargetDailySeconds: 8 * 60 * 60,
                                TargetBillableAmount: 400, //4000 Net - 8000 brut - 20 days / Month /  
                                WorkedGageValue: 0,
                                BillableGageValue: 0,
                                StartTime_Seconds: TimeEntries[0].start_time_seconds,
                                StartTime_Hours: convertSecondsToTime(TimeEntries[0].start_time_seconds),
                                RemainingToWork_Seconds: null,
                                RemainingToWork_Hours: null,
                            };

                            let TotalWorkedHoursSeconds = 0;
                            let TotalAmount = 0;
                            TimeEntries.forEach((elem) => {
                                if (elem.duration_seconds) {
                                    TotalWorkedHoursSeconds = TotalWorkedHoursSeconds + parseFloat(elem.duration_seconds);
                                }
                                if (elem.calc_billable_Amount) {
                                    TotalAmount = TotalAmount + elem.calc_billable_Amount;
                                }
                            });
                            Indicator.TotalWorkedHours_Seconds = TotalWorkedHoursSeconds;
                            Indicator.TotalWorkedHours_Hours = convertSecondsToTime(TotalWorkedHoursSeconds, false);
                            let WorkedGageValue = parseInt((Indicator.TotalWorkedHours_Seconds / Indicator.TargetDailySeconds) * 100);
                            Indicator.WorkedGageValue = WorkedGageValue;

                            Indicator.TotalBillableAmount = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(TotalAmount);
                            let BillableGageValue = parseInt((TotalAmount / Indicator.TargetBillableAmount) * 100);
                            Indicator.BillableGageValue = BillableGageValue;

                            Ref_LocaleIndicator.current = Indicator;
                            setInit(true);
                        } else {
                            //Display no data
                        }
                    } else {
                        if (Data === "Start Tracking") {
                            History.push("/tasktimemanagement/tasks/dailyorganizer/timetracking");
                        } else {
                            console.log("error in data from server");
                        }
                    }
                } else {
                    if (APICallType === "deleteone") {
                        toast.success(`Entry deleteted`, {autoClose: true});
                        setInit(false);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    const ShareGridApi = (input) => {
        Ref_GridApi.current = input;
    };
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="TimeEntries"
                Data={Ref_LocaleData.current}
                // URLAbsolute={{
                //     ALL: `${ProdURL}/api/purchaseinvoices/invoices/queries/${RealQuery}`,
                //     QUERY: `${ProdURL}/api/companies/query`,
                //     DELETEONE: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
                //     DELETEMANY: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
                //     PUT: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
                // }}
                TableColumns={TableColumns}
                MainDataSource="TimeEntries"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTableData}
                SyncReloadDataStateFunction={setReloadTableData}
                
                ShareGridApi={ShareGridApi}
                ExcelStyles={ExcelStyles}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (FormValues.date_Iso) {
            if (Init === false) {
                const Date_Date = format(parseISO(FormValues.date_Iso), "yyyyMMdd");
                const ApiCall = {url: `${ProdURL}/api/tasks/time/entries/?date=${Date_Date}&exclude_running=true`, type: "get"};
                setLoading(true);
                setAPICallType(null);

                async function APIInternal() {
                    const res = await APICallExternal(ApiCall);
                    setApiRes(res);
                    setLoading(false);
                }

                APIInternal();
            }
        }
    }, [ProdURL, FormValues.date_Iso, Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        var UpdateFormValues = {[field]: value};
        setFormValues({...FormValues, ...UpdateFormValues});
    };
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        setInit(false);
    };
    const HandleCloseDuplicateCard = (Id) => {
        // console.log("Should close the card")
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setViewCardId(Id);
        setShowViewCard(true);
        setTimeout(() => {
            setReloadTableData(true);
        }, 300);
    };

    const HandleChangeDay = (type) => {
        let CurrentDate = parseISO(FormValues.date_Iso);
        var UpdateDate;
        if (type === "Previous") {
            UpdateDate = formatISO(add(CurrentDate, {days: -1}));
        } else {
            UpdateDate = formatISO(add(CurrentDate, {days: 1}));
        }

        setInit(false);
        setFormValues({...FormValues, ...{date_Iso: UpdateDate}});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <TimeEntryEditNew Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    if (ShowDuplicateCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseDuplicateCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <TimeSheetEntryDuplicate DuplicateId={DuplicateCardId} HandleCloseDuplicateCard={HandleCloseDuplicateCard} />
                </div>
            </CardModal>
        );
    }
    var FinalTableComponent, FinalIndicatorComponent;

    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = TableComponent;
        }

        // FinalIndicatorComponent = (
        //     <div className={classes.Indicator}>
        //         <div className={classes.LineInfo}>
        //             <span>Total Worked hours : </span>
        //             <span> {Ref_LocaleIndicator.current ? Ref_LocaleIndicator.current.totalWorkedHours_hours : null}</span>
        //         </div>
        //         <div className={classes.LineInfo} style={{marginLeft: "20px"}}>
        //             <span>Total Amount : </span>
        //             <div className={classes.Badge}>{Ref_LocaleIndicator.current ? Ref_LocaleIndicator.current.totalAmount : null}</div>
        //         </div>
        //     </div>
        // );
        FinalIndicatorComponent = <DailyTimeSheetDashboard WorkLoad={Ref_LocaleIndicator.current} />;
    } else {
        FinalTableComponent = <div>there is no data</div>;
        FinalIndicatorComponent = null;
    }

    return (
        <Page WithPerfectScrollBar={false} PagePadding="0px">
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {ModalCardView}
            <div className={classes.DailyBar}>
                <div className={classes.ButtonBar}>
                    <div className={classes.FormField} style={{flex: "1 1 250px", maxWidth: "250px", width: "250px"}}>
                        <FormCompontent
                            Name="date_Iso"
                            InitialValue={InitialFormValues.date_Iso ? InitialFormValues.date_Iso : null}
                            FormValue={FormValues.date_Iso ? FormValues.date_Iso : null}
                            OutputValue={(values) => HandleUpdateFormValues("date_Iso", values)}
                            Component="DatePicker"
                            Variant="OutlinedLabel"
                            Label="Entry Date"
                            NoMessage={false}
                            PickerDateType="Calendar"
                            PickerOnly={true}
                            // InputFormat="yyyy/MM/dd"
                            InputFormat="E - yyyy/MM/dd"
                            // LabelMaxWidth="200px"
                            // LabelAlign="Left"
                            ExternalToolbarLeft={<ArrowFilledLeft onClick={() => HandleChangeDay("Previous")} />}
                            ExternalToolbarRight={<ArrowFilledRight onClick={() => HandleChangeDay("Next")} />}
                        />
                    </div>
                </div>

                {FinalIndicatorComponent}
            </div>

            <div className={classes.TableWrapper}>{FinalTableComponent}</div>
        </Page>
    );

    /***************** RENDER ******************/
});

export default DailyTimeSheets;
