import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import FileUploader from "../../../../../../../../artibulles-cis/FileUploader/FileUploader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    UploaderWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "10px",
        maxWidth: "1000px",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    Uploader_Instruction: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        margin: "4px 0px 25px 0px",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "8px",
        background: "grey",
        color: "white",
        borderRadius: "5px",
        "& p": {
            margin: "2px 0px",
        },
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    Uploader_Button: {
        display: "flex",
        position: "relative",
        marginTop: "10px",
        justifyContent: "center",
    },
    FileNameType: {
        margin: "10px 0px",
        "& span": {
            fontWeight: "500",
            fontSize: "1.1em",
        },
    },
    ConvResults: {
        margin: "0px 10px",
    },
}));

const Component = (props) => {
    const {CloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [IsConverted, setIsConverted] = useState(false);
    const [ConvertedResults, setConvertedResults] = useState([]);

    // const [InitialFormValues, setInitialFormValues] = React.useState({
    //     current_date: Date.now(),
    // }); //INITIAL FORM VALUES
    // const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FilesUploaders, setFilesUploaders] = React.useState({});

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data) {
                    if (ApiRes.data.data) {
                        let Responses = ApiRes.data.data;
                        console.log("Responses", Responses);
                        if (Responses && Responses.length > 0) {
                            var Res = [];
                            Responses.forEach((elem) => {
                                if (elem.error === false) {
                                    Res.push(elem);
                                } else {
                                }
                            });
                            setConvertedResults(Res);
                        }

                        setIsConverted(true);
                        // CloseCard("refresh");
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleConvertCSV = () => {
        const ApiCall = {url: `${ProdURL}/api/companyaccount/converscvfiles`, type: "post"};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    const UpdateFormFiles = (fieldName, filedetails) => {
        if (fieldName && filedetails) {
            let FullFilesUploader = [];
            for (var i = 0; i < filedetails.length; i++) {
                FullFilesUploader.push({
                    serverPath: filedetails[i].serverPath,
                    originalName: filedetails[i].originalName,
                    fileName: filedetails[i].fileName,
                    fileExtension: filedetails[i].fileExtension,
                    fileType: filedetails[i].fileType,
                    fileSize: filedetails[i].fileSize,
                    keywords: filedetails[i].keywords,
                });
            }
            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);
        }
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var FinalComponent;
    if (IsConverted === true) {
        if (ConvertedResults) {
            var DisplayResults = [];
            ConvertedResults.forEach((elem) => {
                let FileRes = (
                    <div className={classes.FileResult}>
                        <div className={classes.FileNameType}>
                            <span>{elem.OriginalFile} </span>
                            <span> - </span>
                            <span>{elem.FileType} </span>
                        </div>
                        <div className={classes.ConvResults}>
                            <div>
                                <span>Total entries found in file : </span>
                                <span>{elem.results.totalFileEntries}</span>
                            </div>
                            <div>
                                <span>Total new entries uploaded in database : </span>
                                <span>{elem.results.totalNewDBEntries}</span>
                            </div>
                            <div>
                                <span>Total entries that could be duplicates : </span>
                                <span>{elem.results.totalDuplicateEntries}</span>
                            </div>
                            <div>
                                <span>Total entries skipped (already exist) : </span>
                                <span>{elem.results.totalDBSkippedEntries}</span>
                            </div>
                        </div>
                    </div>
                );
                DisplayResults.push(FileRes);
            });
        }

        FinalComponent = <React.Fragment>{DisplayResults}</React.Fragment>;
    } else {
        FinalComponent = (
            <React.Fragment>
                <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
                <div className={classes.Uploader_Instruction}>
                    <p>You can upload multiple files</p>
                    <p>You can upload bank accounts transactions and credit cards transactions in csv format but only with English headers</p>
                    <p>You can upload camt transactions in xml format</p>
                    <p>If they are existing transactions, those entries will skipped automatically</p>
                    <p>You can upload transactions for multiple bank accounts or cards. If they exist, they will be uploaded. If they don't they will be skipped</p>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 350px"}}>
                        <FileUploader
                            name="csv_files"
                            label="Transactions"
                            APIUploadPath="/api/companyaccount/uploadcsvtransactions/multiple"
                            APIDeletePath="/api/companyaccount/deleteuploadedtransactions"
                            UpdateFormFunction={UpdateFormFiles}
                            MaxFiles={10}
                            // InitialFiles={FilesUploaders ? FilesUploaders.csv_files : null}
                            UploadButton={{
                                Message: "Upload files",
                            }}
                            AcceptedFileTypes={["*"]}
                            // AcceptedFileTypes={["csv"]}
                            MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                            BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                            SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                            WithDragAndDrop={true}
                            WithProgressBar={true}
                            ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                        />
                    </div>
                </div>
                <div className={classes.Uploader_Button}>
                    <Button Width="350px" onClick={HandleConvertCSV}>
                        Convert Data and store in Database
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    return <div className={classes.UploaderWrapper}>{FinalComponent}</div>;

    /***************** RENDER ******************/
};

export default Component;
