import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import Page from "@artibulles-cis/react/Page";
import ContactEditForm from "./ContactEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerEdit = React.memo(function CustomerEdit(props) {
    const {Id, RedirectToSourceOnClosing, CreditCardsInformation, CardId} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_StatusInvoice = React.useRef(null);
    const Ref_CardDataForNewCreditcardStatement = React.useRef(null);

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        // IdFinal = PathName.replace("/crm/customers/customer/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};
    // console.log("InitialCall", InitialCall);
    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companies/contact/edit`,
        // MAIN: `${ProdURL}/api/purchaseinvoices/invoice`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("args.typeOfSubmit", args.typeOfSubmit);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Company._id;
            History.push(RedirectToSourceOnClosing);
            if (IdFinal === "new") {
                //We redirect to edit to reset the form and get the right buttons and editing
                History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/${Id}/edit`);
            }

            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("data", data);
        let Contact = data.Contact;

        let GenderOptions = [
            {value: "01", label: "Female"},
            {value: "02", label: "Male"},
            {value: "03", label: "Other"},
        ];
        let LanguageOptions = [
            {value: "01", label: "French"},
            {value: "02", label: "English"},
        ];
        let TitleOptions = [
            {value: "01", label: "Mr"},
            {value: "02", label: "Mss"},
            {value: "03", label: "Ing"},
        ];
        let CardData;
        let InitialCreditCard;
        if (CreditCardsInformation) {
            CardData = _.find(CreditCardsInformation, {_id: CardId});
            if (CardData) {
                Ref_CardDataForNewCreditcardStatement.current = CardData;
                InitialCreditCard = CardId;
            }
        }

        let PictureFilePath = [];
        if (Contact && Contact.PictureFilePath && Array.isArray(Contact.PictureFilePath) && Contact.PictureFilePath.length > 0) {
            Contact.PictureFilePath.forEach((elem) => {
                PictureFilePath.push(elem);
            });
        }

        let StatementError = data.Errors;
        if (StatementError) {
            console.log("StatementError", StatementError);
        }
        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Contact ? Contact._id : "new",
                FirstName: Contact ? Contact.FirstName : null,
                LastName: Contact ? Contact.LastName : null,
                Gender: Contact ? Contact.Gender : null,
                Language: Contact ? Contact.Language : null,
                Title: Contact ? Contact.Language : null,
                Websites: Contact ? Contact.Websites : null,

                //   Emails: [
                // 	{
                // 	  Email: {
                // 		type: String,
                // 		default: null,
                // 		trim: true,
                // 	  },
                // 	  Description: {
                // 		type: String,
                // 		default: null,
                // 	  },
                // 	  IsDefault: {
                // 		type: Boolean,
                // 		default: false,
                // 	  },
                // 	},
                //   ],
                //   PhoneNumber: [
                // 	{
                // 	  Email: {
                // 		type: String,
                // 		default: null,
                // 		trim: true,
                // 	  },
                // 	  Description: {
                // 		type: String,
                // 		default: null,
                // 	  },
                // 	  IsDefault: {
                // 		type: Boolean,
                // 		default: false,
                // 	  },
                // 	},
                //   ],
                PictureFilePath: PictureFilePath,

                // db_status: Company ? Company.db_status : null,
                // db_locked: Company ? Company.db_locked : null,
                // db_locked_by: Company ? Company.ref_db_locked_by : null,
                // db_locked_at: Company ? Company.db_locked_at : null,
                // owner: Company ? Company.ref_owner : null,
                createdAt: Contact ? (Contact.createdAt ? format(parseISO(Contact.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: Contact ? (Contact.updatedAt ? format(parseISO(Contact.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                Gender: GenderOptions,
                Language: LanguageOptions,
                Title: TitleOptions,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                PictureFilePath: PictureFilePath,
            },
            InitiaFormErrors: {
                StatementYear: StatementError,
            },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormDataToServer")
        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            let FilesUpload = FullSubmission.FilesUploader;
            console.log("FilesUpload", FilesUpload);

            let Statements_File_PathFinal;
            if (FilesUpload && FilesUpload.Statements_File_Path && Array.isArray(FilesUpload.Statements_File_Path) && FilesUpload.Statements_File_Path.length > 0) {
                Statements_File_PathFinal = FilesUpload.Statements_File_Path;
            } else {
                Statements_File_PathFinal = null;
            }

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    BankStatement: {
                        Updatable: {
                            Status: values.Status,
                            Statements_File_Path: Statements_File_PathFinal,
                            StatementDateIso: values.StatementDateIso,
                            Ref_CreditCardId: values.Ref_CreditCardId,
                            ManualTempTotalAmount: values.ManualTempTotalAmount,
                        },
                    },
                };
                //this is what will be sent back to the server

                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ButtonSaveAsDraftComp, ButtonSaveAndQuitComp, ButtonQuitNoSaveComp;

    if (IdFinal === "new") {
        ButtonSaveAsDraftComp = {Active: false, Text: "Create Initial Order", Width: "200px"};
        ButtonSaveAndQuitComp = {Active: false, Text: "Create Initial Order And Quit", Width: "200px"};
        ButtonQuitNoSaveComp = {Active: false, Text: "Exit"};
    } else {
        ButtonSaveAsDraftComp = {Active: false, Text: "Save draft"};
        ButtonSaveAndQuitComp = {Active: false, Text: "Save And Quit"};
        ButtonQuitNoSaveComp = {Active: false, Text: "Exit"};
    }

    let FinalViewMode;
    if (Id === "new") {
        FinalViewMode = false;
    } else {
        if (Ref_StatusInvoice.current && Ref_StatusInvoice.current === "Draft") {
            FinalViewMode = false;
        } else {
            FinalViewMode = true;
        }
    }
    // console.log("Ref_StatusInvoice.current", Ref_StatusInvoice.current);

    return (
        <Page WithPerfectScrollBar={false} PagePadding="0px">
            <FormWithApi
                // WithCardModal={Id === "new" ? true : false}
                WithCardModal={false}
                CardModalOptions={{
                    // CloseCard: HandleCloseEditCard,
                    CloseOnClickOutside: false,
                    WithCloseButton: true,
                    // CardMaxWidth="800px"
                    // CardHeight="700px"
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    WithPerfectScrollbar: false,
                    // CardWindowPaddingVertical="50px"

                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                OpenInViewMode={FinalViewMode}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={ContactEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={ButtonSaveAsDraftComp}
                ButtonSaveAndQuit={ButtonSaveAndQuitComp}
                ButtonQuitNoSave={ButtonQuitNoSaveComp}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
});

export default CustomerEdit;
