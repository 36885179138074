import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import TabNavContent from "../../../../../artibulles-cis/TabNavContent/TabNavContent";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import _ from "lodash";

const {isValid, format, parseISO, formatISO, add, endOfMonth, getDate, getYear} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Card: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
        padding: "10px",
        margin: "5px",
        borderRadius: "5px",
    },
    TableIndicator: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    TableRowHeader: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid grey",
    },
    TableRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid grey",
    },
    TableGroupedRowCol: {
        display: "flex",
        flexDirection: "row",
    },

    TableRowSubTotal: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid grey",
        fontWeight: "500",
    },
    TableRowTotal: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid grey",
        fontWeight: "600",
    },
    TableCol: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        padding: "0px 10px",
        borderRight: "1px solid grey",
    },
    TableColNoBorder: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        padding: "0px 10px",
        borderRight: "1px solid rgba(0,0,0,0)",
    },
}));

const ProjectSpendingSummary = React.memo(function ProjectSpendingSummary(props) {
    const {TableData, Companies, Programs, Milestones} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    console.log("reload summary");
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [GeneralSummary, setGeneralSummary] = useState({
        TotalHours: 0,
        TotalAmount: 0,
        DailyAverageHour: 0,
        DailyAverageAmount: 0,
    });
    const [GroupedTable, setGroupedTable] = useState();

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        if (TableData) {
            let TimeEntries = TableData.TimeEntries;
            console.log("TimeEntries", TimeEntries);
            let TotalHours = 0,
                TotalAmount = 0,
                DailyAverageHour = 0,
                DailyAverageAmount = 0;

            if (TimeEntries && Array.isArray(TimeEntries) && TimeEntries.length > 0) {
                for (let i = 0; i < TimeEntries.length; i++) {
                    let elem = TimeEntries[i];
                    TotalHours = TotalHours + elem.duration_seconds;
                    TotalAmount = TotalAmount + elem.calc_billable_Amount;
                }
                DailyAverageHour = TotalHours / TimeEntries.length;
                TotalHours = convertSecondsToTime(TotalHours, false);
                TotalAmount = Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 1}).format(TotalAmount);
            }

            setGeneralSummary({TotalHours: TotalHours, TotalAmount: TotalAmount, DailyAverageHour: 0, DailyAverageAmount: 0});

            //Generate table groups per selected entries
            let CompaniesList = TableData.Companies;
            let ProgramsList = TableData.Programs;
            let MilestonesList = TableData.Milestones;
            // console.log("CompaniesList", CompaniesList);
            // console.log("ProgramsList", ProgramsList);
            // console.log("MilestonesList", MilestonesList);
            let GroupedTable = [];

            Companies.forEach((Comp) => {
                let CompanyName = _.find(CompaniesList, {_id: Comp}).company;
                //We find the entrie corresponding to the company
                let CompEntries = _.filter(TimeEntries, {ref_company: Comp});

                //We then iterate through each selected programs
                ProgramsList.forEach((Prog) => {
                    let PrgramName = Prog.program_name;
                    if (Prog.ref_company === Comp) {
                        let GroupedRowCompany = [];
                        MilestonesList.forEach((Milestone) => {
                            let MilestoneHours = 0,
                                MilestoneAmount = 0;
                            if (Milestone.ref_program === Prog._id) {
                                // console.log("milestone matches the program", Milestone);
                                //We iterate through the timeEntries for that milestone

                                TimeEntries.forEach((TimeEntryMilestone) => {
                                    if (TimeEntryMilestone.ref_milestone === Milestone._id && TimeEntryMilestone.ref_program === Prog._id) {
                                        // console.log("TimeEntryMilestone", TimeEntryMilestone);
                                        MilestoneHours = MilestoneHours + TimeEntryMilestone.duration_seconds;
                                        MilestoneAmount = MilestoneAmount + TimeEntryMilestone.calc_billable_Amount;
                                    }
                                });
                                if (MilestoneHours > 0) {
                                    GroupedTable.push(
                                        <tr className={classes.TableRow}>
                                            <td className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                                                {CompanyName}
                                            </td>
                                            <td className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                                                {PrgramName}
                                            </td>
                                            <td className={classes.TableCol} style={{width: "500px", maxWidth: "500px"}}>
                                                {Milestone.milestone_name}
                                            </td>
                                            <td className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                                {convertSecondsToTime(MilestoneHours, false)}
                                            </td>
                                            <td className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                                {Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 1}).format(MilestoneAmount)}
                                            </td>
                                        </tr>
                                    );
                                }
                            }
                        });
                        // GroupedTable.push(
                        //     <div className={classes.TableRow}>
                        //         <div className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                        //             {CompanyName}
                        //         </div>
                        //         {GroupedRowCompany}
                        //     </div>
                        // );
                        //We add the total for the program

                        let ProgramHours = 0,
                            ProgramAmount = 0;
                        TimeEntries.forEach((TimeEntryProgram) => {
                            if (TimeEntryProgram.ref_program === Prog._id) {
                                ProgramHours = ProgramHours + TimeEntryProgram.duration_seconds;
                                ProgramAmount = ProgramAmount + TimeEntryProgram.calc_billable_Amount;
                            }
                        });
                        if (ProgramHours > 0) {
                            GroupedTable.push(
                                <tr className={classes.TableRowSubTotal}>
                                    <td className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                                        {CompanyName}
                                    </td>
                                    <td className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                                        {`Total ${PrgramName}`}
                                    </td>
                                    <td className={classes.TableCol} style={{width: "500px", maxWidth: "500px"}}></td>
                                    <td className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                        {convertSecondsToTime(ProgramHours, false)}
                                    </td>
                                    <td className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                        {Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 1}).format(ProgramAmount)}
                                    </td>
                                </tr>
                            );
                        }
                    }
                });

                //Calculating the company summary
                let CompanymHours = 0,
                    CompanyAmount = 0;
                console.log("Comp", Comp);
                console.log("TimeEntries", TimeEntries);
                TimeEntries.forEach((TimeEntryCompany) => {
                    if (TimeEntryCompany.ref_company === Comp) {
                        CompanymHours = CompanymHours + TimeEntryCompany.duration_seconds;
                        CompanyAmount = CompanyAmount + TimeEntryCompany.calc_billable_Amount;
                    }
                });
                if (CompanymHours >= 0) {
                    GroupedTable.push(
                        <tr className={classes.TableRowTotal}>
                            <td className={classes.TableColNoBorder} style={{width: "200px", maxWidth: "200px"}}>
                                {`Total ${CompanyName}`}
                            </td>
                            <td className={classes.TableColNoBorder} style={{width: "200px", maxWidth: "200px"}}></td>
                            <td className={classes.TableCol} style={{width: "500px", maxWidth: "500px"}}></td>
                            <td className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                {convertSecondsToTime(CompanymHours, false)}
                            </td>
                            <td className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                {Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 1}).format(CompanyAmount)}
                            </td>
                        </tr>
                    );
                }
            });

            console.log("GroupedTable", GroupedTable);
            setGroupedTable(GroupedTable);
            console.log("Companies", Companies);
            console.log("Programs", Programs);
            console.log("Milesotnes", Milestones);
        }
    }, [TableData]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNavContent>
            <div className={classes.Card} style={{width: "500px"}}>
                <div className={classes.TableIndicator}>
                    <div className={classes.TableRow}>
                        <div className={classes.TableCol} style={{maxWidth: "200px"}}>
                            Total hours :
                        </div>
                        <div className={classes.TableCol} style={{justifyContent: "flex-end"}}>
                            {GeneralSummary.TotalHours}
                        </div>
                        <div className={classes.TableCol} style={{maxWidth: "200px"}}>
                            Total amount :
                        </div>
                        <div className={classes.TableCol} style={{justifyContent: "flex-end"}}>
                            {GeneralSummary.TotalAmount}
                        </div>
                    </div>
                    <div className={classes.TableRow}>
                        <div className={classes.TableCol} style={{maxWidth: "200px"}}>
                            Average hours / day :
                        </div>
                        <div className={classes.TableCol} style={{justifyContent: "flex-end"}}>
                            100
                        </div>
                        <div className={classes.TableCol} style={{maxWidth: "200px"}}>
                            Average amount / day :
                        </div>
                        <div className={classes.TableCol} style={{justifyContent: "flex-end"}}>
                            100
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.Card} style={{width: "1400px"}}>
                <table className={classes.TableIndicator}>
                    <thead>
                        <tr className={classes.TableRowHeader}>
                            <th className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                                Customer
                            </th>
                            <th className={classes.TableCol} style={{width: "200px", maxWidth: "200px"}}>
                                Program
                            </th>
                            <th className={classes.TableCol} style={{width: "500px", maxWidth: "500px"}}>
                                Milestone
                            </th>
                            <th className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                Hours
                            </th>
                            <th className={classes.TableCol} style={{width: "130px", maxWidth: "130px"}}>
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>{GroupedTable}</tbody>
                </table>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default ProjectSpendingSummary;
