import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import Page from "../../../../../../artibulles-cis/Page/Page";

import Button from "@artibulles-cis/react/Button";
import {toast} from "react-toastify";
import {convertSecondsToTime} from "../../../../../../artibulles-cis/utils/dateUtils";
import CircularGageClock from "../../../../../../artibulles-cis/CircularGageClock/CircularGageClock";
import {useDispatch} from "react-redux";
import {UPDATE_TIME_ENTRY_TRACKING} from "../../../../../reduxFunctions/timeTrackingEntry/timeTrackingEntryConstants";

import TimeTrackingClockViewStop from "./TimeTrackingClockViewStop";

const {add, format} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    ClassExtendsPageContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    ClassExtendTabContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    TimeTrackingWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignSelf: "center",
        maxWidth: "500px",
        width: "100%",
        margin: "0 auto",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        background: "rgb(220,220,220)",
        borderRadius: "8px",
    },
    Title: {
        flex: " 0 0 30px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1.3rem",
        fontWeight: "600",
        borderBottom: "1px solid grey",
        margin: "0px 0px 5px 0px",
        // background : "rgb(200,200,200)",
    },
    BasicInfo: {
        width: "100%",
        flex: " 1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    TimeEntryName: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    LineInfo: {
        flex: "1 0 auto",
        display: "flex",
        maxWidth: "300px",
        width: "100%",
    },
    LineInfo_Left: {
        flex: "1 1 70%",
        display: "flex",
        justifyContent: "flex-start",
    },
    LineInfo_Right: {
        flex: "1 1 30%",
        display: "flex",
        justifyContent: "flex-end",
    },
    Clock: {
        flex: "1 1 180px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    BottomBar: {
        flex: " 0 0 60px",
        padding: "10px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const DailyOrganizerTimeTracking = React.memo(function DailyOrganizerTimeTracking(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef();
    const Ref_Interval = useRef();
    const Ref_ClockDuration = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const History = useHistory();
    const Dispatch = useDispatch();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Initialized, setInitialized] = useState(false);
    const [SaveManual, setSaveManual] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [ShowModalSave, setShowModalSave] = useState(false);
    const [ClockDuration, setClockDuration] = useState({
        Seconds: null,
        Hours: null,
        Percentage: null,
    });
    const [TimeEntryData, setTimeEntryData] = useState({
        entry_name: null,
        start_time_seconds: null,
        estimated_duration_Seconds: null,
        estimated_duration_hours: null,
        start_time_hours: null,
        entry_type: null,
        id: null,
        Customer: null,
        Program: null,
        Milestone: null,
    });
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    const Data = ApiRes.data.data;
                    let DataTimeEntry = Data.TimeEntry;
                    if (SaveManual === true) {
                        toast.success(`Saved`, {autoClose: true});

                        Dispatch({type: UPDATE_TIME_ENTRY_TRACKING, payload: {Exist: false, Data: null}});
                        setTimeout(() => {
                            History.push("/tasktimemanagement/times/dailytimesheet");
                        }, 500);
                    } else {
                        const estimated_duration_Seconds = DataTimeEntry.estimated_duration_Seconds;
                        const CurrentTime = new Date(Date.now());
                        let ServerTimeOffset = new Date(Date.now()).getTimezoneOffset();
                        let dateObjUTC0 = add(CurrentTime, {minutes: parseInt(ServerTimeOffset)});

                        const CurrentTimeSeconds = parseInt(format(CurrentTime, "H")) * 3600 + parseInt(format(CurrentTime, "m")) * 60 + parseInt(format(CurrentTime, "s"));
                        const StartTimeSeconds = DataTimeEntry.start_time_seconds;
                        const DurationSeconds = CurrentTimeSeconds - StartTimeSeconds;
                        const DurationHours = convertSecondsToTime(DurationSeconds);
                        const Percentage = parseInt((DurationSeconds / estimated_duration_Seconds) * 100);

                        setClockDuration({
                            Seconds: DurationSeconds,
                            Hours: DurationHours,
                            Percentage: Percentage,
                        });
                        Ref_ClockDuration.current = {
                            Seconds: DurationSeconds,
                            Hours: DurationHours,
                            Percentage: Percentage,
                            estimated_duration_Seconds: DataTimeEntry.estimated_duration_Seconds,
                            
                        };
                        console.log("Data For Clock", Data);
                        let Customer, Program, Milestone;
                        console.log("DataTimeEntry.ref_company", DataTimeEntry.ref_company);
                        if (DataTimeEntry.ref_company) {
                            Data.Companies.forEach((elem) => {
                                if (elem._id === DataTimeEntry.ref_company) {
                                    Customer = elem.company;
                                }
                            });
                        }
                        console.log("Company", Customer);

                        if (DataTimeEntry.ref_program) {
                            Data.Programs.forEach((elem) => {
                                if (elem._id === DataTimeEntry.ref_program) {
                                    Program = elem.program_name;
                                }
                            });
                        }
                        if (DataTimeEntry.ref_milestone) {
                            Data.Milestones.forEach((elem) => {
                                if (elem._id === DataTimeEntry.ref_milestone) {
                                    Milestone = elem.milestone_name;
                                }
                            });
                        }
                        setTimeEntryData({
                            entry_name: DataTimeEntry.entry_name,
                            start_time_seconds: DataTimeEntry.start_time_seconds,
                            estimated_duration_Seconds: DataTimeEntry.estimated_duration_Seconds,
                            estimated_duration_hours: convertSecondsToTime(DataTimeEntry.estimated_duration_Seconds, false),
                            start_time_hours: convertSecondsToTime(DataTimeEntry.start_time_seconds, false),
                            entry_type: DataTimeEntry.entry_type,
                            id: DataTimeEntry._id,
                            Customer: Customer,
                            Program: Program,
                            Milestone: Milestone,
                        });
                        StartInterval();
                        setInitialized(true);
                        Dispatch({type: UPDATE_TIME_ENTRY_TRACKING, payload: {Exist: true, Data: DataTimeEntry}});
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/tasks/time/entrytracking/existing`, type: "get"};
        setLoading(true);
        // setLoginPhase("OTP code entered");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, [ProdURL]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        //Required to stop the interval
        return () => {
            if (Ref_Interval.current) {
                clearInterval(Ref_Interval.current);
            }
        };
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const StartInterval = () => {
        //Start the interval
        if (Ref_Interval.current) {
            //We clear it just to make sure we don't repeat the interval
            clearInterval(Ref_Interval.current);
            Ref_Interval.current = null;
        }
        Ref_Interval.current = setInterval(() => {
            let CurrentClockDuration = JSON.parse(JSON.stringify(Ref_ClockDuration.current));
            var UpdatedClockDuration = {
                Seconds: CurrentClockDuration.Seconds + 1,
                Hours: convertSecondsToTime(CurrentClockDuration.Seconds + 1),
                Percentage: parseInt(((CurrentClockDuration.Seconds + 1) / CurrentClockDuration.estimated_duration_Seconds) * 100),
                RemainingSeconds: CurrentClockDuration.estimated_duration_Seconds - CurrentClockDuration.Seconds - 1,
                RemainingTime: convertSecondsToTime(CurrentClockDuration.estimated_duration_Seconds - CurrentClockDuration.Seconds - 1, false),
            };
            Ref_ClockDuration.current = {...Ref_ClockDuration.current, ...UpdatedClockDuration};
            setClockDuration(UpdatedClockDuration);
        }, 1000);
    };
    const HandleSubmit = (type) => {
        let EntryType = TimeEntryData.entry_type;
		console.log("EntryType",EntryType)
        if (EntryType === "Plan" || EntryType === "Task") {
            setShowModalSave(true);
        } else {
            //We just save the task by posting
            setLoading(true);
            setSaveManual(true);
            let FormData = {
                id: TimeEntryData.id,
                duration_seconds: ClockDuration.Seconds,
                is_completed: true,
                typeOfSubmit: "Manual",
            };
            const ApiCall = {url: `${ProdURL}/api/tasks/time/entrytracking/${TimeEntryData.id}`, type: "put", data: FormData};
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };
	const HandleCloseCard = () => {
		setShowModalSave(false)
	}
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var GageComponent = null;
    if (Initialized === true) {
        GageComponent = (
            <CircularGageClock
                TickMarkIndicator="1"
                ExternalDiameter="150"
                SeparateWarningColor="orange"
                EmptyColor="rgb(200,200,200)"
                Thickness="14"
                GageTickThickness="1"
                GageTickOverflow="0"
                GageTickColor="black"
                Value={ClockDuration.Seconds}
                // Value={600}
                MaxValue={Ref_ClockDuration.current.estimated_duration_Seconds}
                GageTriggers={{
                    Trigger1: {trigger: 80, colorBelow: "rgb(66, 165, 245)", colorAbove: "orange"},
                    Trigger2: {trigger: 100, colorAbove: "red"},
                }}
                WithOuterText={false}
                WithInnerText={false}
                // NeedleIndictator={{Active : true}}
                GageCentralText={{
                    MainText: "Percent",
                    MainFontSize: "1rem",
                    SubText: "Value",
                    SubFontSize: "18px",
                }}
                GageCustomCentralText={
                    <div style={{boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "center", flex: "1 0 auto"}}>
                        <div style={{flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.2rem"}}>{ClockDuration.Hours}</div>
                        <div style={{flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "1.2rem"}}>{ClockDuration.Percentage} %</div>
                    </div>
                }
            />
        );
    }

    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPageContent={classes.ClassExtendsPageContent}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="500px"
                CardHeight="300px"
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={ShowModalSave}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <TimeTrackingClockViewStop TypeOfEntry={TimeEntryData.entry_type} Data={TimeEntryData} DurationSeconds={ClockDuration.Seconds} HandleCloseCard={HandleCloseCard}/>
                </div>
            </CardModal>
            <div className={classes.TimeTrackingWrapper}>
                <div className={classes.Title}>Time Tracking</div>
                <div className={classes.BasicInfo}>
                    <div className={classes.TimeEntryName} style={{fontSize: "1.2rem", fontWeight: "500"}}>
                        {TimeEntryData.entry_name}
                    </div>
                    <div className={classes.TimeEntryName}>{TimeEntryData.Customer}</div>
                    <div className={classes.TimeEntryName}>{TimeEntryData.Program}</div>
                    <div className={classes.TimeEntryName}>{TimeEntryData.Milestone}</div>
                </div>
                <div className={classes.Clock}>{GageComponent}</div>
                <div className={classes.LineInfo} style={{maxWidth: "240px"}}>
                    <div className={classes.LineInfo_Left}>Started : </div>
                    <div className={classes.LineInfo_Right}> {TimeEntryData.start_time_hours}</div>
                </div>
                <div className={classes.LineInfo} style={{maxWidth: "240px"}}>
                    <div className={classes.LineInfo_Left}>Estimated Duration : </div>
                    <div className={classes.LineInfo_Right}> {TimeEntryData.estimated_duration_hours}</div>
                </div>
                <div className={classes.LineInfo} style={{maxWidth: "240px"}}>
                    <div className={classes.LineInfo_Left}>Remaining : </div>
                    <div className={classes.LineInfo_Right}> {ClockDuration.RemainingTime}</div>
                </div>
                <div className={classes.BottomBar}>
                    <Button
                        onClick={() => {
                            HandleSubmit("Save");
                        }}
                        style={{margin: "0px 10px"}}
                    >
                        Stop tracking
                    </Button>
                </div>
            </div>
        </Page>
    );

    /***************** RENDER ******************/
});

export default DailyOrganizerTimeTracking;
