import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import InvoicesLatePaymentTable from "./03_PaymentsTracking/InvoicesLatePaymentTable";
import InvoicesDueUpcomingPaymentTable from "./03_PaymentsTracking/InvoicesDueUpcomingPaymentTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavLink: {
        borderRight: "1px solid rgb(220 220 220)",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            MainSlug={`/financials/saleordersandinvoices/invoices/payments`}
            DefaultTab={`/financials/saleordersandinvoices/invoices/payments/unpaid`}
            ClassExtendTabNavLink={classes.ClassExtendTabNavLink}
            TabNavMenuHeight="30px"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Late Payments</div>
                        </div>
                    ),
                    Slug: `/financials/saleordersandinvoices/invoices/payments/unpaid`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <InvoicesLatePaymentTable/>,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Upcoming Due</div>
                        </div>
                    ),
                    Slug: `/financials/saleordersandinvoices/invoices/payments/due`,
                    exact: true,
                    ShowOnlyIfActive: false,
                    TabContent: <InvoicesDueUpcomingPaymentTable/>,
                }
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
