import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import PerfectScrollBarWrapper from "../PerfectScrollBarWrapper/PerfectScrollBarWrapper";
import clsx from "clsx";

import {createUseStyles, useTheme} from "react-jss";
import {ArrowSimpleLeft} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {
        ExpandableBox: {
            minWidth: props.MinWidth ? props.MinWidth : null,
            maxWidth: props.MaxWidth ? props.MaxWidth : "100%",
            width: "100%",
        },
        ExpandableBox_Controller_Wrapper: {
            backgroundColor: props.BackgroundColorTopBar ? props.BackgroundColorTopBar : null,
        },

        ContentClosedWrapper: {
            backgroundColor: props.BackroundColorClosed ? props.BackroundColorClosed : null,
            transition: `height ${props.TransitionEffect.Duration ? props.TransitionEffect.Duration : null}`,
        },
        ContentOpenedWrapper: {
            backgroundColor: props.BackroundColorOpened ? props.BackroundColorOpened : null,
            transition: `height ${props.TransitionEffect.Duration ? props.TransitionEffect.Duration : null}`,
        },
        Content: {
            transition: `height ${props.TransitionEffect.Duration ? props.TransitionEffect.Duration : null}`,
        },
    };
};

//eslint-disable-next-line
const Lockedstyles = {
    ExpandableBox: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
    },
    ExpandableBox_Controller_Wrapper: {
        display: "flex",
        alignItems: "center",
    },
    Content: {
        boxSizing: "border-box",
        position: "relative",
    },
    ContentOpenedWrapper: {
        boxSizing: "border-box",
        position: "relative",
    },
    ContentClosedWrapper: {
        boxSizing: "border-box",
        position: "relative",
    },
};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    //Theme for the Box Style
    ExpandableBox_BoxStyle: theme.ExpandableBox.BoxStyle.ExpandableBox,
    ExpandableBox_Controller_Wrapper_BoxStyle: theme.ExpandableBox.BoxStyle.ExpandableBox_Controller_Wrapper,
    Content_BoxStyle: theme.ExpandableBox.BoxStyle.Content,
    ContentOpenedWrapper_BoxStyle: theme.ExpandableBox.BoxStyle.ContentOpenedWrapper,
    ContentOpened_BoxStyle: theme.ExpandableBox.BoxStyle.ContentOpened,
    ContentClosedWrapper_BoxStyle: theme.ExpandableBox.BoxStyle.ContentClosedWrapper,
    ContentClosed_BoxStyle: theme.ExpandableBox.BoxStyle.ContentClosed,
    Buttonripple_BoxStyle: theme.ExpandableBox.BoxStyle.Buttonripple,
    TopContent_BoxStyle: theme.ExpandableBox.BoxStyle.TopContent,
    Controller_BoxStyle: theme.ExpandableBox.BoxStyle.Controller,
    ControllerButton_BoxStyle: theme.ExpandableBox.BoxStyle.ControllerButton,
    //Theme for the List Style
    ExpandableBox_ListStyle: theme.ExpandableBox.ListStyle.ExpandableBox,
    ExpandableBox_Controller_Wrapper_ListStyle: theme.ExpandableBox.ListStyle.ExpandableBox_Controller_Wrapper,
    Content_ListStyle: theme.ExpandableBox.ListStyle.Content,
    ContentOpenedWrapper_ListStyle: theme.ExpandableBox.ListStyle.ContentOpenedWrapper,
    ContentOpened_ListStyle: theme.ExpandableBox.ListStyle.ContentOpened,
    ContentClosedWrapper_ListStyle: theme.ExpandableBox.ListStyle.ContentClosedWrapper,
    ContentClosed_ListStyle: theme.ExpandableBox.ListStyle.ContentClosed,
    Buttonripple_ListStyle: theme.ExpandableBox.ListStyle.Buttonripple,
    TopContent_ListStyle: theme.ExpandableBox.ListStyle.TopContent,
    Controller_ListStyle: theme.ExpandableBox.ListStyle.Controller,
    ControllerButton_ListStyle: theme.ExpandableBox.ListStyle.ControllerButton,
}));

/**
 * A Expandable element that contains a top element, a closed component and an opened component
 * Usefull to display info in a condensed way without displaying all the content directly
 */

const ExpandableBox = React.forwardRef(function ExpandableBox(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        DefaultStyle,
        TopContent,
        OpenOnTopClick,
        InitialStatusOpen,
        ControllerPosition,
        ControllerRippleEffect,
        TransitionEffect,
        MaxHeightClosed,
        MaxHeightOpened,
        //eslint-disable-next-line
        MaxWidth,
        //eslint-disable-next-line
        MinWidth,
        ContentClosed,
        FlexMode,
        //eslint-disable-next-line
        WithScrollbar,
        WithPerfectScrollbar,
        CustomController,
        CustomControllerNoRotation,
        CloseFromOutside,
        //eslint-disable-next-line
        IconControllerStyle,
        //eslint-disable-next-line
        BackroundColorClosed,
        //eslint-disable-next-line
        BackroundColorOpened,
        //eslint-disable-next-line
        BackgroundColorTopBar,
        ClassExtendsExpandableBox,
        ClassExtends_ExpandableBox_Controller_Wrapper,
        ClassExtends_ExpandableBox_TopContent,
        ClassExtendsContentOpenedWrapper,
        ClassExtendsContent,
        ClassExtendsContentOpened,
        ClassExtendsContentClosedWrapper,
        ClassExtendsContentClosed,
        ClassExtendsIconControllerStyle,

        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const ExpandableContentRef = useRef(null);
    const ContenOpenWrapperRef = useRef(null);

    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [IsExpandableOpen, setIsExpandableOpen] = useState(InitialStatusOpen);
    const [ExpandandableHeight, setExpandandableHeight] = useState({
        closed: null,
        opened: null,
        contentOpened: null,
    });
    //eslint-disable-next-line
    const [InTransition, setInTransition] = useState(false);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const CalculateDimensions = useCallback(() => {
        //Calculate Expandable Content padding to be able to adjust the height accuratly

        if (ExpandableContentRef && ExpandableContentRef.current) {
            var ClosedHeight, OpenedHeight, OpenContentHeight;
            let ContentPaddingTop = window.getComputedStyle(ExpandableContentRef.current).getPropertyValue("padding-top");
            let ContentPaddingBottom = window.getComputedStyle(ExpandableContentRef.current).getPropertyValue("padding-bottom");
            let TotalPadding = parseFloat(ContentPaddingTop) + parseFloat(ContentPaddingBottom);
            if (FlexMode === true) {
                if (ContenOpenWrapperRef && ContenOpenWrapperRef.current) {
                    let ContentOpenHeight = ContenOpenWrapperRef.current.getBoundingClientRect().height;
                    OpenedHeight = parseFloat(ContentOpenHeight) + TotalPadding + "px";
                    OpenContentHeight = parseFloat(ContentOpenHeight) + "px";
                }
            } else {
                OpenedHeight = parseFloat(MaxHeightOpened) + TotalPadding + "px";
                OpenContentHeight = parseFloat(MaxHeightOpened) + "px";
            }
            ClosedHeight = parseFloat(MaxHeightClosed) + TotalPadding + "px";

            setExpandandableHeight({
                closed: ClosedHeight,
                opened: OpenedHeight,
                contentOpened: OpenContentHeight,
            });
        }
    }, [ExpandableContentRef, MaxHeightClosed, MaxHeightOpened, FlexMode]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
        if (FlexMode) {
        } else {
            CalculateDimensions();
        }
    }, [CalculateDimensions, FlexMode]);

    useEffect(() => {
        if (CloseFromOutside !== null) {
            setIsExpandableOpen(false);
        }
    }, [CloseFromOutside]);

    React.useEffect(() => {
        if (FlexMode) {
        } else {
            window.addEventListener("resize", CalculateDimensions);
            return function cleanup() {
                window.removeEventListener("resize", CalculateDimensions);
            };
        }
    }, [CalculateDimensions, FlexMode]);

    //Adding an effect to recaluclate the dimensions if the

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    const HandleToggleMenuOpenClose = () => {
        setIsExpandableOpen(!IsExpandableOpen);
        CalculateDimensions();
        setInTransition(true);
        setTimeout(() => {
            setInTransition(false);
        }, 300);
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    const WithPerfectScrollbarFinal = WithPerfectScrollbar;

    var TopContentComp = null;
    if (TopContent) {
        if (OpenOnTopClick) {
            if (DefaultStyle === "Box") {
                TopContentComp = <div className={clsx(DefaultStyle === "Box" ? classes.TopContent_BoxStyle : classes.TopContent_ListStyle, ClassExtends_ExpandableBox_TopContent)}>{TopContent}</div>;
            } else {
                let TopContentFinal = React.cloneElement(
                    TopContent,
                    //
                    {onClick: HandleToggleMenuOpenClose, style: {...TopContent.props.style, ...{cursor: "pointer"}}}
                );
                TopContentComp = (
                    <div className={clsx(DefaultStyle === "Box" ? classes.TopContent_BoxStyle : classes.TopContent_ListStyle, ClassExtends_ExpandableBox_TopContent)}>{TopContentFinal}</div>
                );
            }
        }
    }
    var ControlerComponent = null;

    //Generating the final style for the standard controller
    const IconControllerStyleInit = {
        IconSize: IconControllerStyle.IconSize ? IconControllerStyle.IconSize : null,
        SVGStrokeWidth: IconControllerStyle.SVGStrokeWidth ? IconControllerStyle.SVGStrokeWidth : null,
        SVGStrokeColor: IconControllerStyle.SVGStrokeColor ? IconControllerStyle.SVGStrokeColor : null,
        SVGStrokeHoverColor: IconControllerStyle.SVGStrokeHoverColor ? IconControllerStyle.SVGStrokeHoverColor : null,
    };

    var IconControllerStyleFinal = {
        IconSize: IconControllerStyleInit.IconSize
            ? IconControllerStyleInit.IconSize
            : ClassExtendsIconControllerStyle
            ? ClassExtendsIconControllerStyle.IconSize
                ? ClassExtendsIconControllerStyle.IconSize
                : DefaultStyle === "Box"
                ? theme.ExpandableBox.BoxStyle.IconControllerStyle.IconSize
                : theme.ExpandableBox.ListStyle.IconControllerStyle.IconSize
            : DefaultStyle === "Box"
            ? theme.ExpandableBox.BoxStyle.IconControllerStyle.IconSize
            : theme.ExpandableBox.ListStyle.IconControllerStyle.IconSize,
        SVGStrokeWidth: IconControllerStyleInit.SVGStrokeWidth
            ? IconControllerStyleInit.SVGStrokeWidth
            : ClassExtendsIconControllerStyle
            ? ClassExtendsIconControllerStyle.SVGStrokeWidth
                ? ClassExtendsIconControllerStyle.SVGStrokeWidth
                : DefaultStyle === "Box"
                ? theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGStrokeWidth
                : theme.ExpandableBox.ListStyle.IconControllerStyle.SVGStrokeWidth
            : DefaultStyle === "Box"
            ? theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGStrokeWidth
            : theme.ExpandableBox.ListStyle.IconControllerStyle.SVGStrokeWidth,
        SVGStrokeColor: IconControllerStyleInit.SVGStrokeColor
            ? IconControllerStyleInit.SVGStrokeColor
            : ClassExtendsIconControllerStyle
            ? ClassExtendsIconControllerStyle.SVGStrokeColor
                ? ClassExtendsIconControllerStyle.SVGStrokeColor
                : DefaultStyle === "Box"
                ? theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGStrokeColor
                : theme.ExpandableBox.ListStyle.IconControllerStyle.SVGStrokeColor
            : DefaultStyle === "Box"
            ? theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGStrokeColor
            : theme.ExpandableBox.ListStyle.IconControllerStyle.SVGStrokeColor,

        SVGStrokeHoverColor: IconControllerStyleInit.SVGStrokeHoverColor
            ? IconControllerStyleInit.SVGStrokeHoverColor
            : ClassExtendsIconControllerStyle
            ? ClassExtendsIconControllerStyle.SVGStrokeHoverColor
                ? ClassExtendsIconControllerStyle.SVGStrokeHoverColor
                : DefaultStyle === "Box"
                ? theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGStrokeHoverColor
                : theme.ExpandableBox.ListStyle.IconControllerStyle.SVGStrokeHoverColor
            : DefaultStyle === "Box"
            ? theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGStrokeHoverColor
            : theme.ExpandableBox.ListStyle.IconControllerStyle.SVGStrokeHoverColor,
        // SVGTransitionDurationRotate
    };

    //Generating the Ripple Effect Button based on the different inputs
    var ControllerButtonClass;
    if (ControllerRippleEffect === null) {
        //Use the Default style
        if (DefaultStyle === "Box") {
            ControllerButtonClass = clsx(classes.ControllerButton_BoxStyle, classes.Buttonripple_BoxStyle);
        } else {
            ControllerButtonClass = clsx(classes.ControllerButton_ListStyle, classes.Buttonripple_ListStyle);
        }
    } else if (ControllerRippleEffect === true) {
        if (DefaultStyle === "Box") {
            ControllerButtonClass = clsx(classes.ControllerButton_BoxStyle, classes.Buttonripple_BoxStyle);
        } else {
            ControllerButtonClass = clsx(classes.ControllerButton_ListStyle, classes.Buttonripple_ListStyle);
        }
    } else {
        if (DefaultStyle === "Box") {
            ControllerButtonClass = clsx(classes.ControllerButton_BoxStyle);
        } else {
            ControllerButtonClass = clsx(classes.ControllerButton_ListStyle);
        }
    }
    //Defining the Final position of the controller based on the inputs
    var ControllerPositionFinal;

    if (ControllerPosition === null) {
        if (DefaultStyle === "Box") {
            ControllerPositionFinal = "Right";
        } else {
            ControllerPositionFinal = "Left";
        }
    } else {
        ControllerPositionFinal = ControllerPosition;
    }
    //Defining the animation parameters based on the inputs
    var FinalTransitionEffet;
    if (TransitionEffect.Duration) {
        FinalTransitionEffet = {
            SVGTransitionDurationRotate: TransitionEffect.Duration,
        };
    } else {
        if (DefaultStyle === "Box") {
            FinalTransitionEffet = {
                SVGTransitionDurationRotate: theme.ExpandableBox.BoxStyle.IconControllerStyle.SVGTransitionDurationRotate,
            };
        } else {
            FinalTransitionEffet = {
                SVGTransitionDurationRotate: theme.ExpandableBox.ListStyle.IconControllerStyle.SVGTransitionDurationRotate,
            };
        }
    }

    if (CustomController) {
        var ControlElem;
        if (CustomControllerNoRotation === true) {
            ControlElem = CustomController;
        } else {
            ControlElem = React.cloneElement(CustomController, {Rotate: IsExpandableOpen ? -90 : 90});
        }

        if (OpenOnTopClick) {
            ControlerComponent = <div className={DefaultStyle === "Box" ? classes.Controller_BoxStyle : classes.Controller_ListStyle}>{ControlElem}</div>;
        } else {
            ControlerComponent = (
                <div className={DefaultStyle === "Box" ? classes.Controller_BoxStyle : classes.Controller_ListStyle} onClick={HandleToggleMenuOpenClose}>
                    {ControlElem}
                </div>
            );
        }
    } else {
        if (OpenOnTopClick) {
            if (DefaultStyle === "Box") {
                ControlerComponent = (
                    <div className={DefaultStyle === "Box" ? classes.Controller_BoxStyle : classes.Controller_ListStyle}>
                        <div className={ControllerButtonClass}>
                            <ArrowSimpleLeft
                                Rotate={IsExpandableOpen ? (DefaultStyle === "Box" ? 90 : 270) : DefaultStyle === "Box" ? -90 : 180}
                                IconSize={IconControllerStyleFinal.IconSize}
                                SVGStrokeColor={IconControllerStyleFinal.SVGStrokeColor}
                                SVGStrokeWidth={IconControllerStyleFinal.SVGStrokeWidth}
                                SVGStrokeHoverColor={IconControllerStyleFinal.SVGStrokeHoverColor}
                                SVGTransitionDurationRotate={FinalTransitionEffet.SVGTransitionDurationRotate}

                                //DontCloseMe
                            />
                        </div>
                    </div>
                );
            } else {
                ControlerComponent = (
                    <div className={DefaultStyle === "Box" ? classes.Controller_BoxStyle : classes.Controller_ListStyle} onClick={HandleToggleMenuOpenClose}>
                        <div className={ControllerButtonClass}>
                            <ArrowSimpleLeft
                                Rotate={IsExpandableOpen ? (DefaultStyle === "Box" ? 90 : 270) : DefaultStyle === "Box" ? -90 : 180}
                                IconSize={IconControllerStyleFinal.IconSize}
                                SVGStrokeColor={IconControllerStyleFinal.SVGStrokeColor}
                                SVGStrokeWidth={IconControllerStyleFinal.SVGStrokeWidth}
                                SVGStrokeHoverColor={IconControllerStyleFinal.SVGStrokeHoverColor}
                                SVGTransitionDurationRotate={FinalTransitionEffet.SVGTransitionDurationRotate}

                                //DontCloseMe
                            />
                        </div>
                    </div>
                );
            }
        } else {
            ControlerComponent = (
                <div className={DefaultStyle === "Box" ? classes.Controller_BoxStyle : classes.Controller_ListStyle} onClick={HandleToggleMenuOpenClose}>
                    <div className={ControllerButtonClass}>
                        <ArrowSimpleLeft
                            Rotate={IsExpandableOpen ? -90 : 90}
                            IconSize={IconControllerStyleFinal.IconSize}
                            SVGStrokeColor={IconControllerStyleFinal.SVGStrokeColor}
                            SVGStrokeWidth={IconControllerStyleFinal.SVGStrokeWidth}
                            SVGStrokeHoverColor={IconControllerStyleFinal.SVGStrokeHoverColor}
                            SVGTransitionDurationRotate={FinalTransitionEffet.SVGTransitionDurationRotate}

                            //DontCloseMe
                        />
                    </div>
                </div>
            );
        }
    }

    var TopConentWraperComponent = null;
    if (OpenOnTopClick) {
        if (DefaultStyle === "Box") {
            if (ControllerPositionFinal === "Right") {
                TopConentWraperComponent = (
                    <div
                        onClick={HandleToggleMenuOpenClose}
                        className={clsx(
                            DefaultStyle === "Box" ? classes.ExpandableBox_Controller_Wrapper_BoxStyle : classes.ExpandableBox_Controller_Wrapper_ListStyle,
                            ClassExtends_ExpandableBox_Controller_Wrapper
                        )}
                        style={{
                            ...LockedstylesWithProps(props).ExpandableBox_Controller_Wrapper,
                            ...Lockedstyles.ExpandableBox_Controller_Wrapper,
                            ...{cursor: "pointer"},
                        }}
                    >
                        {TopContentComp}
                        {ControlerComponent}
                    </div>
                );
            } else {
                TopConentWraperComponent = (
                    <div
                        onClick={HandleToggleMenuOpenClose}
                        className={clsx(
                            DefaultStyle === "Box" ? classes.ExpandableBox_Controller_Wrapper_BoxStyle : classes.ExpandableBox_Controller_Wrapper_ListStyle,
                            ClassExtends_ExpandableBox_Controller_Wrapper
                        )}
                        style={{
                            ...LockedstylesWithProps(props).ExpandableBox_Controller_Wrapper,
                            ...Lockedstyles.ExpandableBox_Controller_Wrapper,
                            ...{cursor: "pointer"},
                        }}
                    >
                        {ControlerComponent}
                        {TopContentComp}
                    </div>
                );
            }
        } else {
            if (ControllerPositionFinal === "Right") {
                TopConentWraperComponent = (
                    <div
                        className={clsx(
                            DefaultStyle === "Box" ? classes.ExpandableBox_Controller_Wrapper_BoxStyle : classes.ExpandableBox_Controller_Wrapper_ListStyle,
                            ClassExtends_ExpandableBox_Controller_Wrapper
                        )}
                        style={{
                            ...LockedstylesWithProps(props).ExpandableBox_Controller_Wrapper,
                            ...Lockedstyles.ExpandableBox_Controller_Wrapper,
                        }}
                    >
                        {TopContentComp}
                        {ControlerComponent}
                    </div>
                );
            } else {
                TopConentWraperComponent = (
                    <div
                        className={clsx(
                            DefaultStyle === "Box" ? classes.ExpandableBox_Controller_Wrapper_BoxStyle : classes.ExpandableBox_Controller_Wrapper_ListStyle,
                            ClassExtends_ExpandableBox_Controller_Wrapper
                        )}
                        style={{
                            ...LockedstylesWithProps(props).ExpandableBox_Controller_Wrapper,
                            ...Lockedstyles.ExpandableBox_Controller_Wrapper,
                        }}
                    >
                        {ControlerComponent}
                        {TopContentComp}
                    </div>
                );
            }
        }
    } else {
        if (ControllerPositionFinal === "Right") {
            TopConentWraperComponent = (
                <div
                    className={clsx(
                        DefaultStyle === "Box" ? classes.ExpandableBox_Controller_Wrapper_BoxStyle : classes.ExpandableBox_Controller_Wrapper_ListStyle,
                        ClassExtends_ExpandableBox_Controller_Wrapper
                    )}
                    style={{
                        ...LockedstylesWithProps(props).ExpandableBox_Controller_Wrapper,
                        ...Lockedstyles.ExpandableBox_Controller_Wrapper,
                        ...{width: "100%"},
                    }}
                >
                    {TopContentComp}
                    {ControlerComponent}
                </div>
            );
        } else {
            TopConentWraperComponent = (
                <div
                    className={clsx(
                        DefaultStyle === "Box" ? classes.ExpandableBox_Controller_Wrapper_BoxStyleclasses : classes.ExpandableBox_Controller_Wrapper_ListStyle,
                        ClassExtends_ExpandableBox_Controller_Wrapper
                    )}
                    style={{
                        ...LockedstylesWithProps(props).ExpandableBox_Controller_Wrapper,
                        ...Lockedstyles.ExpandableBox_Controller_Wrapper,
                        ...{width: "100%"},
                    }}
                >
                    {ControlerComponent}
                    {TopContentComp}
                </div>
            );
        }
    }

    var ContentClosedComponent = null;
    if (ContentClosed) {
        ContentClosedComponent = (
            <div
                className={clsx(DefaultStyle === "Box" ? classes.ContentClosedWrapper_BoxStyle : classes.ContentClosedWrapper_ListStyle, ClassExtendsContentClosedWrapper)}
                style={{
                    ...LockedstylesWithProps(props).ContentClosedWrapper,
                    ...Lockedstyles.ContentClosedWrapper,
                    ...{height: IsExpandableOpen ? "0px" : MaxHeightClosed, display: IsExpandableOpen === true ? null : "none"},
                }}
            >
                <div className={clsx(classes.ContentClosed_BoxStyle, ClassExtendsContentClosed)}>{ContentClosed ? ContentClosed : null}</div>
            </div>
        );
    }

    //Adding a timeOut to the overflow inner to prevent scrollBar glinch
    // // var overflowStyleOpenElem;
    // if (WithPerfectScrollbarFinal === true) {
    //     overflowStyleOpenElem = "hidden";
    // } else {
    //     if (IsExpandableOpen === true) {
    //         if (InTransition === true) {
    //             overflowStyleOpenElem = "hidden";
    //         } else {
    //             if (WithScrollbar) {
    //                 overflowStyleOpenElem = WithScrollbar;
    //             } else {
    //                 overflowStyleOpenElem = "hidden";
    //             }
    //         }
    //     } else {
    //         overflowStyleOpenElem = "hidden";
    //     }
    // }
    var ExpandableContentHeight;
    if (FlexMode) {
        if (IsExpandableOpen) {
            ExpandableContentHeight = null;
        } else {
            ExpandableContentHeight = "0px";
        }
    } else {
        if (ContentClosed) {
            if (IsExpandableOpen) {
                ExpandableContentHeight = ExpandandableHeight.opened;
            } else {
                ExpandableContentHeight = ExpandandableHeight.closed;
            }
        } else {
            if (IsExpandableOpen) {
                ExpandableContentHeight = ExpandandableHeight.opened;
            } else {
                ExpandableContentHeight = "0px";
            }
        }
    }
    var ContentOpenedWrapperHeight;
    if (FlexMode) {
        if (IsExpandableOpen) {
            ExpandableContentHeight = null;
        } else {
            ExpandableContentHeight = "0px";
        }
    } else {
        if (IsExpandableOpen) {
            ContentOpenedWrapperHeight = ExpandandableHeight.contentOpened;
        } else {
            ContentOpenedWrapperHeight = "0px";
        }
    }
    return (
        <div
            ref={ref}
            className={clsx(DefaultStyle === "Box" ? classes.ExpandableBox_BoxStyle : classes.ExpandableBox_ListStyle, ClassExtendsExpandableBox)}
            style={{...LockedstylesWithProps(props).ExpandableBox, ...Lockedstyles.ExpandableBox}}
        >
            {TopConentWraperComponent}
            <div
                ref={ExpandableContentRef}
                className={clsx(DefaultStyle === "Box" ? classes.Content_BoxStyle : classes.Content_ListStyle, ClassExtendsContent)}
                style={{
                    ...LockedstylesWithProps(props).Content,
                    ...Lockedstyles.Content,
                    ...{
                        height: ExpandableContentHeight,
                        padding: ContentClosed ? null : "0px",
                        // overflow: "hidden",
                        display: IsExpandableOpen === false ? "none" : null,
                    },
                }}
            >
                <div
                    className={clsx(DefaultStyle === "Box" ? classes.ContentOpenedWrapper_BoxStyle : classes.ContentOpenedWrapper_ListStyle, ClassExtendsContentOpenedWrapper)}
                    style={{
                        ...LockedstylesWithProps(props).ContentOpenedWrapper,
                        ...Lockedstyles.ContentOpenedWrapper,
                        ...{
                            height: ContentOpenedWrapperHeight,
                            // overflowY: overflowStyleOpenElem,
                            // overflow: WithPerfectScrollbarFinal === true ? "hidden" : FlexMode === true ? "hidden" : IsExpandableOpen ? (InTransition ? "hidden" : "auto") : "hidden"
                        },
                    }}
                >
                    <PerfectScrollBarWrapper Active={WithPerfectScrollbarFinal ? true : false}>
                        <div ref={ContenOpenWrapperRef} className={clsx(DefaultStyle === "Box" ? classes.ContentOpened_BoxStyle : classes.ContentOpened_ListStyle, ClassExtendsContentOpened)}>
                            {children}
                        </div>
                    </PerfectScrollBarWrapper>
                </div>

                {ContentClosedComponent}
            </div>
        </div>
    );
    /****************************** RENDER *********************/
});

ExpandableBox.defaultProps = {
    DefaultStyle: "Box",
    TopContent: null,
    OpenOnTopClick: false,
    InitialStatusOpen: true,
    ControllerPosition: null,
    ControllerRippleEffect: null,
    TransitionEffect: {Duration: null, Easing: null},
    MaxHeightOpened: "400px",
    MaxHeightClosed: null,
    MaxWidth: null,
    MinWidth: null,
    ContentClosed: null,
    FlexMode: false,
    WithScrollbar: null,
    WithPerfectScrollbar: false,
    CustomController: null,
    CustomControllerNoRotation: false,
    CloseFromOutside: null,
    IconControllerStyle: {IconSize: null, SVGStrokeWidth: null, SVGStrokeColor: null, SVGStrokeHoverColor: null},
    BackroundColorClosed: null,
    BackroundColorOpened: null,
    BackgroundColorTopBar: null,
    ClassExtendsExpandableBox: null,
    ClassExtends_ExpandableBox_Controller_Wrapper: null,
    ClassExtendsContent: null,
    ClassExtendsContentOpenedWrapper: null,
    ClassExtendsContentOpened: null,
    ClassExtendsContentClosedWrapper: null,
    ClassExtendsContentClosed: null,
    ClassExtendsIconControllerStyle: null,
};

ExpandableBox.propTypes = {
    /**
     * DefaultStyle : Sets the general theme for the expndable component
     * Default : box
     * Options : "Box" / "List"
     */
    DefaultStyle: PropTypes.any,
    /**
     * TopContent : A React Element to be displayed inside the top element
     */
    TopContent: PropTypes.any,
    /**
     * OpenOnTopClick : When true clicking the top bar will open the expandable
     */
    OpenOnTopClick: PropTypes.bool,
    /**
     * InitialStatusOpen :Initial State of the controller
     */
    InitialStatusOpen: PropTypes.any,
    /**
     * ControllerPosition : Position of the Controller : Left / Right
     */
    ControllerPosition: PropTypes.any,
    /**
     * ControllerRippleEffect : true/false
     * Add a Ripple effect to the controller button
     */
    ControllerRippleEffect: PropTypes.any,
    /**
     * TransitionEffect : {Duration: null, Easing: null},
     * The transition to open and close
     */
    TransitionEffect: PropTypes.any,
    /**
     * MaxHeightClosed : The height of the controller when closed
     */
    MaxHeightClosed: PropTypes.string,
    /**
     * MaxHeightOpened : The height of the controller when opened
     */
    MaxHeightOpened: PropTypes.string,
    /**
     * MaxWidth
     */
    MaxWidth: PropTypes.string,
    /**
     * MinWidth
     */
    MinWidth: PropTypes.string,
    /**
     * ContentClosed : A React Element to be displayed When the component is closed
     */
    ContentClosed: PropTypes.any,
    /**
     * FlexMode : If True, the height will be the full heigh of the contentOpened Element
     */
    FlexMode: PropTypes.bool,

    /**
     * Apply a scrollbar in the Content Opened only when opened
     * WithScrollbar : "auto", scroll, "hidden"
     */
    WithScrollbar: PropTypes.string,
    /**
     * WithPerfectScrollbar : Add a perfect scrollbar instead of the native scrollbar
     */
    WithPerfectScrollbar: PropTypes.bool,
    /**
     * CustomController : A React element to replace the arrow
     */
    CustomController: PropTypes.any,
    /**
     * CustomControllerNoRotation : typically the controller icon is an arrow.
     * This arrow must be oriented to the left so that the rotation is applied correctly
     * if you don't pass an arrow or just want to prevent the roation,
     * set CustomControllerNoRotation to false
     */
    CustomControllerNoRotation: PropTypes.bool,

    /**
     * CloseFromOutside : close the Expandable from outside. Only for closing the element!!!!!
     * you need to pass a value that changes everytime to trigger the closing
     * example in parent component :
     * CloseFromOutside={closeExpandable}
     * closeExpandable = closeExpandable+1
     */
    CloseFromOutside: PropTypes.any,
    /**
     * IconControllerStyle : {IconSize: null, SVGStrokeWidth: null, SVGStrokeColor: null, SVGStrokeHoverColor: null},
     */
    IconControllerStyle: PropTypes.string,
    /**
     * BackroundColorClosed
     */
    BackroundColorClosed: PropTypes.string,
    /**
     * BackroundColorOpened
     */
    BackroundColorOpened: PropTypes.string,
    /**
     * BackgroundColorTopBar
     */
    BackgroundColorTopBar: PropTypes.string,
    /**
     * ClassExtendsExpandableBox
     */
    ClassExtendsExpandableBox: PropTypes.string,
    /**
     * ClassExtends_ExpandableBox_Controller_Wrapper
     */
    ClassExtends_ExpandableBox_Controller_Wrapper: PropTypes.string,
    /**
     * ClassExtendsContent
     */
    ClassExtendsContent: PropTypes.string,
    /**
     * ClassExtendsContentOpenedWrapper
     */
    ClassExtendsContentOpenedWrapper: PropTypes.string,
    /**
     * ClassExtendsContentOpened
     */
    ClassExtendsContentOpened: PropTypes.string,
    /**
     * ClassExtendsContentClosedWrapper
     */
    ClassExtendsContentClosedWrapper: PropTypes.string,
    /**
     * ClassExtendsContentClosed
     */
    ClassExtendsContentClosed: PropTypes.string,
    /**
     * ClassExtendsIconControllerStyle
     */
    ClassExtendsIconControllerStyle: PropTypes.string,
};

export default ExpandableBox;
