import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';

const LockedstylesWithProps = props => {
  return {};
};

const Lockedstyles = {
  ProgressBar: {
    position: 'relative',
    overflow: 'hidden',
  },
  ProgressIndicator: {
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
  },
};

const styles = createUseStyles(theme => ({
  ProgressBar: theme.ProgressLinear.ProgressBar,
  ProgressIndicator: theme.ProgressLinear.ProgressIndicator,
  ProgressIndicatorInfinite1: {
    width: 'auto',
    transition: 'transform 0.2s linear',
    transformOrigin: 'left',
    animation: '$InfiniteProgressBar1  2.1s infinite  cubic-bezier(0.65, 0.815, 0.735, 0.395)',
  },
  ProgressIndicatorInfinite2: {
    width: 'auto',
    animation: '$InfiniteProgressBar2  2.1s infinite cubic-bezier(0.165, 0.84, 0.44, 1)  1.15s',
  },
  '@keyframes InfiniteProgressBar1': {
    '0%': { left: '-35%', right: '100%' },
    '60%': { left: '100%', right: '-90%' },
    '100%': { left: '100%', right: '-90%' },
  },
  '@keyframes InfiniteProgressBar2': {
    '0%': { left: '-200%', right: '100%' },
    '60%': { left: '107%', right: '-8%' },
    '100%': { left: '107%', right: '-8%' },
  },
}));

/**
 * Create a Linear Progress Bar
 */
const ProgressLinear = React.forwardRef(function ProgressLinear(props, ref) {
  const {
    Type,
    Loading,
    UnmountWhenDone,
    Progress,
    Height,
    Width,
    ColorEmpty,
    ColorFilled,
  } = props;

  const theme = useTheme();
  const classes = styles({ ...props, theme });

  var ProgressBarStyle = {
    height: null,
    width: null,
    backgroundColor: null,
  };
  var ProgressIndicatorStyle = {
    backgroundColor: null,
  };

  if (Height) {
    ProgressBarStyle.height = Height;
  }
  if (Width) {
    ProgressBarStyle.width = Width;
  }
  if (ColorEmpty) {
    ProgressBarStyle.backgroundColor = ColorEmpty;
  }
  if (ColorFilled) {
    ProgressIndicatorStyle.backgroundColor = ColorFilled;
  }

  const Bars = () => {
    if (Type === 'Infinite') {
      if (Loading == true) {
        return (
          <React.Fragment>
            <div
              className={clsx(classes.ProgressIndicatorInfinite1, classes.ProgressIndicator)}
              style={{ ...Lockedstyles.ProgressIndicator, ...ProgressIndicatorStyle }}
            />
            <div
              className={clsx(classes.ProgressIndicatorInfinite2, classes.ProgressIndicator)}
              style={{ ...Lockedstyles.ProgressIndicator, ...ProgressIndicatorStyle }}
            />
          </React.Fragment>
        );
      } else {
        return null;
      }
    } else {
      return (
        <div
          className={classes.ProgressIndicator}
          style={{
            ...Lockedstyles.ProgressIndicator,
            ...ProgressIndicatorStyle,
            transform: `translateX(${parseInt(Progress) - 100}%)`,
          }}
        />
      );
    }
  };

  const ProgressBarComp = () => {
    if (Type === 'Infinite') {
      if (UnmountWhenDone && Loading == false) {
        return null;
      } else {
        return (
          <div
            className={classes.ProgressBar}
            style={{
              ...Lockedstyles.ProgressBar,
              ...ProgressBarStyle,
              visibility:
                Type === 'Infinite'
                  ? Loading == false
                    ? 'hidden'
                    : 'visible'
                  : parseInt(Progress) === 100
                  ? 'hidden'
                  : 'visible',
            }}
          >
            <Bars />
          </div>
        );
      }
    } else {
      if (UnmountWhenDone && parseInt(Progress) === 100) {
        return null;
      } else {
        return (
          <div
            className={classes.ProgressBar}
            style={{
              ...Lockedstyles.ProgressBar,
              ...ProgressBarStyle,
              visibility:
                Type === 'Infinite'
                  ? Loading == false
                    ? 'hidden'
                    : 'visible'
                  : parseInt(Progress) === 100
                  ? 'hidden'
                  : 'visible',
            }}
          >
            <Bars />
          </div>
        );
      }
    }
  };

  return <React.Fragment>{ProgressBarComp()}</React.Fragment>;
});

ProgressLinear.defaultProps = {
  Type: 'Determinate',
  Loading: false,
  UnmountWhenDone: false,
  Progress: 0,
  Height: null,
  Width: null,
  ColorEmpty: null,
  ColorFilled: null,
};

ProgressLinear.propTypes = {
  /**
   * Type : "Determinate"/"Inifinite"
   */
  Type: PropTypes.string,
  /**
   * Loading : true/false used for Infinite only
   */
  Loading: PropTypes.bool,
  /**
   * UnmountWhenDone : true/false if true, the entire component is unmounted.
   * Sometimes it is usefull to keep it hidden (false) to prevent weird resizing of other elements
   */
  UnmountWhenDone: PropTypes.bool,
  /**
   * Progress : the progress value (0-100)
   */
  Progress: PropTypes.string,
  /**
   * Height : "10px"
   */
  Height: PropTypes.string,
  /**
   * Width : "10px", "100%"
   */
  Width: PropTypes.string,
  /**
   * ColorEmpty : the Color of the bar below
   */
  ColorEmpty: PropTypes.string,
  /**
   * ColorEmpty : the Color of the indicator
   */
  ColorFilled: PropTypes.string,
};

export default ProgressLinear;
