import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "@artibulles-cis/react/Page";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import LocalCircularLoaderIOS from "../../../../../../../../../artibulles-cis/LocalCircularLoaderIOS/LocalCircularLoaderIOS";
import PopUpMenuWithButton from "../../../../../../../../../artibulles-cis/PopUpMenuWithButton/PopUpMenuWithButton";
import BankChart from "./BankChart";
import CreditCardChart from "./CreditCardChart";
import CardWithPopup from "./CardWithPopup/CardWithPopup";
import Button from "@artibulles-cis/react/Button";
import {format, parseISO, differenceInCalendarDays, getMonth} from "date-fns";
import _ from "lodash";

import UploadCreditCardTransactions from "../../Common/UploadCreditCardCSVTransactions/UploadCreditCardTransactions";

import StatementEdit from "../../../../00CommonForAccouting/01_StatementCreateEdit/StatementEdit";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendsPageContent: {
        display: "flex",
        flexDirection: "column",
        color: "white",
    },
    Section: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    SectionTitle: {
        fontWeight: "500",
        fontSize: "1rem",
    },
    SectionMain: {
        display: "flex",
        boxSizing: "border-box",
        position: "relative",
        flexWrap: "wrap",
        width: "100%",
        // justifyContent : "space-between"
    },
    Card: {
        flex: "1 1 auto",
        display: "flex",
        maxWidth: "600px",
        minWidth: "400px",
        height: "270px",
        margin: "5px 5px",
        flexDirection: "column",
        borderRadius: "3px",
        color: "black",
        boxSizing: "border-box",
        // background: "linear-gradient(0deg, rgba(15, 14, 14, 0.9) 0%, rgba(49, 49, 49, 0.9) 100%)",
        boxShadow: "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        fontWeight: "400",
        fontSize: "0.8rem",
    },
    CardHeader: {
        flex: "0 1 auto",
        display: "flex",
        padding: "2px 5px",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        borderBottom: "1px solid grey",
    },
    CardHeader_Left: {
        flex: "1 1 auto",
        display: "flex",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    CardHeader_RightIcon: {
        flex: "0 0 40px",
        display: "flex",
        flexDirection: "column",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
    },

    CardContent: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    BalanceAndSyncInfo: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",

        // padding: "5px",
    },
    BalanceInformation: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "180px",

        // padding: "0px 5px",
        // alignSelf : "flex-end",
        // padding: "5px",
        color: "#FF6103",
    },
    BalanceInformation_Line: {
        flex: "1 1 auto",
        display: "flex",
        fontSize: "0.9rem",
        fontWeight: "500",

        // padding: "5px",
    },
    BalanceInformation_Line_Title: {
        flex: "1 1 auto",
        display: "flex",
        // justifyContent : "flex-start"

        // padding: "5px",
    },
    BalanceInformation_Line_Value: {
        flex: "0 0 80px",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    SyncInformation: {
        flex: "1 1 auto",

        display: "flex",
        flexDirection: "column",
        // maxWidth: "220px",
        // alignSelf: "flex-end",
        // marginTop: "5px",
        // padding: "0px 5px",
        // color: "#FF6103",
    },
    SyncInformation_Line: {
        flex: "0 1 auto",
        display: "flex",
        fontSize: "0.75rem",
        // fontWeight: "500",

        // padding: "5px",
    },
    SyncInformation_Line_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    SyncInformation_Line_Value: {
        flex: "0 0 100px",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    ActionsWrapper: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    ActionsButtonWrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "180px",
        justifyContent: "center",
        padding: "0px 10px",
    },
    ClassExtendButton: {
        margin: "0px",
        padding: "0px",
        fontSize: "0.8rem",
    },
    ActionInformationBox: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        border: "1px solid grey",
        margin: "0px 10px",
    },
    ActionInformationBox_Line: {
        flex: "0 1 auto",
        display: "flex",
        fontSize: "0.75rem",
        padding: "5px 0px",
    },
    ActionInformationBox_Line_Title: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 5px",
    },
    ActionInformationBox_Line_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",

        // padding: "5px",
    },

    CardFooter: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    PopupContent: {
        display: "flex",
        flexDirection: "column",
        height: "235px",
        width: "590px",
        background: "rgb(34,34,34)",
        opacity: "0.95",
        color: "white",
        padding: "5px",
        boxSizing: "border-box",
    },
    PopupColumns: {
        flex: "1 1 auto",
        display: "flex",
        // padding: "5px",
        boxSizing: "border-box",
    },
    PopupColumn: {
        flex: "1 1 33%",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
        boxSizing: "border-box",
        height: "100%",
    },
    PopupColumn_Title: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0px 0px 10px 0px",
        fontSize: "1.1rem",
        fontWeight: "600",
    },
    PopupColumn_Menu: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        fontSize: "0.9rem",
        fontWeight: "400",
    },
    PopupColumn_Menu_Item: {
        flex: "0 0 30px",
        display: "flex",
        cursor: "pointer",
        boxSizing: "border-box",
    },
}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Page = useRef(null);
    const Ref_CardBaccount = useRef(null);

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Loading, setLoading] = useState(false);
    const [LoadingBankAccount, setLoadingBankAccount] = useState(false);
    const [ReloadAll, setReloadAll] = useState(true);

    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [BankAccountsInformation, setBankAccountsInformation] = useState(null);

    const [BankAccountsComponent, setBankAccountsComponent] = useState(null);
    const [CreditCardsInformation, setCreditCardsInformation] = useState(null);
    const [CreditCardsComponent, setCreditCardsComponent] = useState(null);

    //Cards For the various components
    const [ShowCardUploadTransactions, setShowCardUploadTransactions] = useState(false);
    const [ShowCardCreateVisaStatement, setShowCardCreateVisaStatement] = useState(false);
    const [SelectedVisaCardId, setSelectedVisaCardId] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!ApiCallType) {
                    if (ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("All Data", Data);
                        if (Data.BankAccounts) {
                            setBankAccountsInformation(Data.BankAccounts);
                        }
                        if (Data.CreditCards) {
                            setCreditCardsInformation(Data.CreditCards);
                        }
                    }
                } else {
                    if (ApiCallType === "PontoSync") {
                        if (ApiRes.data && ApiRes.data.data) {
                            let Data = ApiRes.data.data;
                            console.log("PontoSyncData", Data);
                            setApiCallType(null);
                            setReloadAll(true);
                            // let Output = {
                            //     DBAccountBalance: MyBankAccountBlance,
                            //     NewAccountBalance: CurrentBalance,
                            //     DeltaAccountBalance: CurrentBalance - MyBankAccountBlance,
                            //     NewTransactionsTotal: FreshTransactionsTotal,
                            //     NewTransactions: FreshTransactions,
                            // };

                            // if (Data.BankAccounts) {
                            //     setBankAccountsInformation(Data.BankAccounts);
                            // }
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateBankAccountComponents = useCallback(() => {
        let FinalBankCards = [];
        if (BankAccountsInformation && Array.isArray(BankAccountsInformation)) {
            BankAccountsInformation.forEach((elem) => {
                let BankAccountId = elem._id;
                let Unreconciled = elem.TotalUnreconciledTransactions;

                let LatestTransaction;
                let LatestStatementAmount, LatestStatementMMYY;
                let LastSyncDate = parseISO(elem.last_sync_dateIso);
                let Today = new Date(Date.now());
                let DaysSinceLastSync = differenceInCalendarDays(Today, LastSyncDate);
                let BankStatements = elem.BankStatements;
                let GraphData;
                if (BankStatements && Array.isArray(BankStatements)) {
                    GraphData = BankStatements;
                    let LatestStatement = BankStatements[0];

                    LatestStatementAmount = LatestStatement.Calc_TotalAmount;
                    LatestStatementMMYY = LatestStatement.CalcStatementYear + "/" + LatestStatement.CalcStatementMonth;
                    let LatestStatementTransactions = LatestStatement.StatementTransactions;

                    if (LatestStatementTransactions && Array.isArray(LatestStatementTransactions)) {
                        let LatestStatementTransactionsSorted = _.orderBy(LatestStatementTransactions, ["transaction_date_Iso"], ["desc"]);
                        LatestTransaction = LatestStatementTransactionsSorted[0];
                    }
                }
                let BankCard = (
                    <React.Fragment>
                        <CardWithPopup
                            Loading={LoadingBankAccount}
                            CardTitle={{
                                Left: `${elem.bank_name} - ${elem.account_number}`,
                                Right: "Bank",
                            }}
                            //
                            PopupContent={{
                                Left: {
                                    Title: {
                                        Title: "View",
                                        // Style: {fontSize: "2rem"},
                                        className: classes.PopupColumn_Title,
                                    },
                                    Menu: {
                                        Items: [
                                            {
                                                Item: "Statements",
                                                OnClick: () => {
                                                    console.log("Item 1 click");
                                                },
                                            },
                                            {
                                                Item: "Transactions",
                                                OnClick: () => {
                                                    console.log("Item 2 click");
                                                },
                                            },
                                        ],
                                        // Style: {fontSize: "0.5rem"},
                                        className: classes.PopupColumn_Menu_Item,
                                    },
                                },
                                Middle: {
                                    Title: {
                                        Title: "Reconciliation",
                                        // Style: {fontSize: "2rem"},
                                        className: classes.PopupColumn_Title,
                                    },
                                    Menu: {
                                        Items: [
                                            {Item: "Statements", onClick: "HandleThis"},
                                            {Item: "Transactions", onClick: "HandleThis"},
                                        ],
                                        // Style: {fontSize: "0.5rem"},
                                        className: classes.PopupColumn_Menu_Item,
                                    },
                                },
                                Right: {
                                    Title: {
                                        Title: "Actions",
                                        // Style: {fontSize: "2rem"},
                                        className: classes.PopupColumn_Title,
                                    },
                                    Menu: {
                                        Items: [
                                            {Item: "Sync with Ponto", OnClick: () => HandleSyncBankWithPonto(BankAccountId)},
                                            {Item: "Sync Transactions", OnClick: "HandleThis"},
                                        ],
                                        // Style: {fontSize: "0.5rem"},
                                        className: classes.PopupColumn_Menu_Item,
                                    },
                                },
                            }}
                            CardContent={
                                <React.Fragment>
                                    <div className={classes.BalanceAndSyncInfo}>
                                        <div className={classes.BalanceInformation}>
                                            <div className={classes.BalanceInformation_Line}>
                                                <div className={classes.BalanceInformation_Line_Title}>Balance </div>
                                                <div className={classes.BalanceInformation_Line_Value}>
                                                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(elem.balance)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.SyncInformation}>
                                            <div className={classes.SyncInformation_Line}>
                                                <div className={classes.SyncInformation_Line_Title}>Last Sync </div>
                                                <div className={classes.SyncInformation_Line_Value}>{`(${DaysSinceLastSync} days)`}</div>
                                                <div className={classes.SyncInformation_Line_Value}>{format(parseISO(elem.last_sync_dateIso), "dd-MMM-yyyy")}</div>
                                            </div>
                                            <div className={classes.SyncInformation_Line}>
                                                <div className={classes.SyncInformation_Line_Title}>Latest Transaction </div>
                                                <div className={classes.SyncInformation_Line_Value}>
                                                    {LatestTransaction
                                                        ? new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(
                                                              LatestTransaction.banking_amount_transaction
                                                          )
                                                        : ""}
                                                </div>
                                                <div className={classes.SyncInformation_Line_Value}>
                                                    {LatestTransaction ? format(parseISO(LatestTransaction.transaction_date_Iso), "dd-MMM-yyyy") : ""}
                                                </div>
                                            </div>
                                            <div className={classes.SyncInformation_Line}>
                                                <div className={classes.SyncInformation_Line_Title}>Latest Statement </div>
                                                <div className={classes.SyncInformation_Line_Value}>
                                                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(LatestStatementAmount)}
                                                </div>

                                                <div className={classes.SyncInformation_Line_Value}>{LatestStatementMMYY}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ActionsWrapper}>
                                        <div className={classes.ActionsButtonWrapper}>
                                            <Button ClassExtendButton={classes.ClassExtendButton} Height="30px" Width="160px">
                                                Reconcile Transactions
                                            </Button>
                                        </div>
                                        <div className={classes.ActionInformationBox}>
                                            <div className={classes.ActionInformationBox_Line}>
                                                <div className={classes.ActionInformationBox_Line_Title}>Unreconciled Transactions : </div>
                                                <div className={classes.ActionInformationBox_Line_Value}>{Unreconciled}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <BankChart GraphData={GraphData} />
                                </React.Fragment>
                            }
                        />
                    </React.Fragment>
                );
                FinalBankCards.push(BankCard);
            });
        } else {
        }

        setBankAccountsComponent(FinalBankCards);
    }, [BankAccountsInformation, LoadingBankAccount]);

    const GenerateCreditCardsComponents = useCallback(() => {
        let FinalCards = [];
        if (CreditCardsInformation && Array.isArray(CreditCardsInformation)) {
            CreditCardsInformation.forEach((elem) => {
                console.log("CreditCardsInformation", elem);
                let Unreconciled = elem.TotalUnreconciledTransactions;
                let CardId = elem._id;
                let LatestTransaction;
                let LatestStatementAmount, LatestStatementMMYY;
                let LastSyncDate = parseISO(elem.last_sync_dateIso);

                let Today = new Date(Date.now());
                let DaysSinceLastSync = differenceInCalendarDays(Today, LastSyncDate);
                let BankStatements = elem.BankStatements;
                let GraphData;
                let ThisMonthSpending = 0;

                if (BankStatements && Array.isArray(BankStatements) && BankStatements.length > 0) {
                    GraphData = BankStatements;
                    let LatestStatement = BankStatements[0];
                    if (LatestStatement) {
                        //To get this month spending, we get the latest statement and we check that the statement date is older than today (otherwise it is an old one)
                        let LatestStatmentDateISO = parseISO(LatestStatement.StatementDateIso);
                        if (LatestStatmentDateISO > Today) {
                            ThisMonthSpending = LatestStatement.Calc_TotalAmount;
                        } else {
                            ThisMonthSpending = 0;
                        }

                        LatestStatementAmount = LatestStatement.Calc_TotalAmount;
                        LatestStatementMMYY = LatestStatement.CalcStatementYear + "/" + LatestStatement.CalcStatementMonth;

                        let LatestStatementTransactions = LatestStatement.StatementTransactions;

                        if (LatestStatementTransactions && Array.isArray(LatestStatementTransactions)) {
                            let LatestStatementTransactionsSorted = _.orderBy(LatestStatementTransactions, ["transaction_date_Iso"], ["desc"]);
                            LatestTransaction = LatestStatementTransactionsSorted[0];
                        }
                    }
                }
                let Card = (
                    <React.Fragment>
                        <CardWithPopup
                            Loading={LoadingBankAccount}
                            CardTitle={{
                                Left: `${elem.CardType.name} - ${elem.card_number} - ${elem.card_holder_Name}`,
                                Right: "Credit Card",
                            }}
                            //
                            PopupContent={{
                                Left: {
                                    Title: {
                                        Title: "View",
                                        // Style: {fontSize: "2rem"},
                                        className: classes.PopupColumn_Title,
                                    },
                                    Menu: {
                                        Items: [
                                            {
                                                Item: "Statements",
                                                OnClick: () => {
                                                    console.log("Item 1 click");
                                                },
                                            },
                                            {
                                                Item: "Transactions",
                                                OnClick: () => {
                                                    console.log("Item 2 click");
                                                },
                                            },
                                        ],
                                        // Style: {fontSize: "0.5rem"},
                                        className: classes.PopupColumn_Menu_Item,
                                    },
                                },
                                Middle: {
                                    Title: {
                                        Title: "Reconciliation",
                                        // Style: {fontSize: "2rem"},
                                        className: classes.PopupColumn_Title,
                                    },
                                    Menu: {
                                        Items: [
                                            {Item: "Statements", onClick: "HandleThis"},
                                            {Item: "Transactions", onClick: "HandleThis"},
                                        ],
                                        // Style: {fontSize: "0.5rem"},
                                        className: classes.PopupColumn_Menu_Item,
                                    },
                                },
                                Right: {
                                    Title: {
                                        Title: "Actions",
                                        // Style: {fontSize: "2rem"},
                                        className: classes.PopupColumn_Title,
                                    },
                                    Menu: {
                                        Items: [
                                            {Item: "Upload CSV transactions", OnClick: () => HandleUploadCSVCardTransactions(CardId)},
                                            {Item: "Create Statement", OnClick: () => HandleCreateCreditCardStatement(CardId)},
                                            {Item: "Sync Transactions", OnClick: "HandleThis"},
                                        ],
                                        // Style: {fontSize: "0.5rem"},
                                        className: classes.PopupColumn_Menu_Item,
                                    },
                                },
                            }}
                            CardContent={
                                <React.Fragment>
                                    <div className={classes.BalanceAndSyncInfo}>
                                        <div className={classes.BalanceInformation}>
                                            <div className={classes.BalanceInformation_Line}>
                                                <div className={classes.BalanceInformation_Line_Title}>Month Spending </div>
                                                <div className={classes.BalanceInformation_Line_Value}>
                                                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(ThisMonthSpending)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.SyncInformation}>
                                            <div className={classes.SyncInformation_Line}>
                                                <div className={classes.SyncInformation_Line_Title}>Last Sync </div>
                                                <div className={classes.SyncInformation_Line_Value}>{`(${DaysSinceLastSync})`}</div>
                                                <div className={classes.SyncInformation_Line_Value}>{format(parseISO(elem.last_sync_dateIso), "dd-MMM-yyyy")}</div>
                                            </div>
                                            <div className={classes.SyncInformation_Line}>
                                                <div className={classes.SyncInformation_Line_Title}>Latest Transaction </div>
                                                <div className={classes.SyncInformation_Line_Value}>
                                                    {LatestTransaction
                                                        ? new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(
                                                              LatestTransaction.banking_amount_transaction
                                                          )
                                                        : ""}
                                                </div>
                                                <div className={classes.SyncInformation_Line_Value}>
                                                    {LatestTransaction ? format(parseISO(LatestTransaction.transaction_date_Iso), "dd-MMM-yyyy") : ""}
                                                </div>
                                            </div>
                                            <div className={classes.SyncInformation_Line}>
                                                <div className={classes.SyncInformation_Line_Title}>Latest Statement </div>
                                                <div className={classes.SyncInformation_Line_Value}>
                                                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(LatestStatementAmount)}
                                                </div>

                                                <div className={classes.SyncInformation_Line_Value}>{LatestStatementMMYY}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ActionsWrapper}>
                                        <div className={classes.ActionsButtonWrapper}>
                                            <Button ClassExtendButton={classes.ClassExtendButton} Height="30px" Width="160px">
                                                Reconcile Transactions
                                            </Button>
                                        </div>
                                        <div className={classes.ActionInformationBox}>
                                            <div className={classes.ActionInformationBox_Line}>
                                                <div className={classes.ActionInformationBox_Line_Title}>Unreconciled Transactions : </div>
                                                <div className={classes.ActionInformationBox_Line_Value}>{Unreconciled}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <CreditCardChart GraphData={GraphData} />
                                </React.Fragment>
                            }
                        />
                    </React.Fragment>
                );
                FinalCards.push(Card);
            });
        } else {
        }

        setCreditCardsComponent(FinalCards);
    }, [CreditCardsInformation, LoadingBankAccount]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (ReloadAll === true) {
            setReloadAll(false);
            const ApiCall = {url: `${ProdURL}/api/companyaccount/accountsdashboardoverview/`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);

                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, ReloadAll]);

    useEffect(() => {
        if (BankAccountsInformation) {
            GenerateBankAccountComponents();
        }
    }, [BankAccountsInformation, GenerateBankAccountComponents]);

    useEffect(() => {
        if (CreditCardsInformation) {
            GenerateCreditCardsComponents();
        }
    }, [CreditCardsInformation, GenerateCreditCardsComponents]);

    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleSyncBankWithPonto = (BankAccountId) => {
        setLoadingBankAccount(true);
        let DataForm = {
            Id: BankAccountId,
        };
        const ApiCall = {url: `${ProdURL}/api/securebanking/syncgetransactions/${BankAccountId}`, type: "post", data: DataForm};
        setApiCallType("PontoSync");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoadingBankAccount(false);
        }
        APIInternal();
    };

    const HandleUploadCSVCardTransactions = (CardId) => {
        setShowCardUploadTransactions(true);
    };

    const HandleCreateCreditCardStatement = (CardId) => {
        setSelectedVisaCardId(CardId);
        setShowCardCreateVisaStatement(true);
    };

    const HandleCloseCardUpladCCTransactions = () => {
        setShowCardUploadTransactions(false);
    };

    const HandleCloseCardCreateVisaStatement = () => {
        setShowCardCreateVisaStatement(false);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    // let CreateCreditCardStatement;
    // if (ShowCardCreateVisaStatement === true) {
    //     CreateCreditCardStatement = (
    //         <StatementEdit
    //             // ShowCard={ShowCardCreateVisaStatement}
    //             // CreditCardsInformation={CreditCardsInformation}
    //             Id="new"
    //             // CardId={SelectedVisaCardId}
    //             // HandleCloseCard={HandleCloseCardCreateVisaStatement}
    //         />
    //     );
    // }
    let ModalCard;

    if (ShowCardCreateVisaStatement === true) {
        ModalCard = (
            <CardModal
                CardID="EditInvoiceCard"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseCardCreateVisaStatement}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <StatementEdit
                    // ShowCard={ShowCardCreateVisaStatement}
                    CreditCardsInformation={CreditCardsInformation}
                    Id="new"
                    CardId={SelectedVisaCardId}
                    HandleCloseCard={HandleCloseCardCreateVisaStatement}
                />
            </CardModal>
        );
    }
    return (
        <Page PageRef={Ref_Page} PagePadding={"10px 10px"} ClassExtendsPageContent={classes.ClassExtendsPageContent} BackgroundColor="rgb(55,55,55)">
            <LocalCircularLoaderIOS
                FullSize={true}
                WithModalCard={true}
                Loading={Loading}
                // ModalCardBackground="rgba(0, 0, 0, 0.2)"
                ModalParentRef={Ref_Page}
                FitToParentDimensions={true}
                SpinnerDimension="60px"
                ZIndex="200000"
                // SpinnerColor=""
                // SpinnerCount=""
                // SpinnerRectHeight=""
                // SpinnerRectWidth=""
                // SpinnerRadius="150px"
            />
            <UploadCreditCardTransactions ShowCard={ShowCardUploadTransactions} HandleCloseCard={HandleCloseCardUpladCCTransactions} />
            {ModalCard}
            <div className={classes.Section}>
                <div className={classes.SectionMain}>
                    {BankAccountsComponent}
                    {CreditCardsComponent}
                </div>
            </div>
        </Page>
    );
    /***************** RENDER ******************/
};

export default Component;
