import React, {useState} from "react";

const WebsiteAppInitContext = React.createContext([{}, () => {}]);

const WebsiteAppInitContextProvider = (props) => {
    const [state, setState] = useState({
        appConfigParameters: {
            appMaxWidth: null,
            mobileWidth: null,
            GeoLocation: null,
            withCookies: null,
            animationIntro: null,
            animationTime: null,
        },
        clientWindowDimensions: {
            isMobile: false,
            windowWidth: null,
            windowHeight: null,
        },
        clientDeviceInfo: {
            osName: null,
            osVersion: null,
            browserName: null,
            browserVersion: null,
            fullBrowserVersion: null,
        },
        geoLocation: {
            browserLanguage: null,
            country: null,
            countryLanguages: null,
        },
        privacyCookies: {
            shown: false,
            accepted: false,
        },
        siteCookiesList: null,
    });

    return <WebsiteAppInitContext.Provider value={[state, setState]}>{props.children}</WebsiteAppInitContext.Provider>;
};

export {WebsiteAppInitContext, WebsiteAppInitContextProvider};
