/* eslint-disable */
import React, {useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import {useHistory} from "react-router-dom";

import CardModal from "@artibulles-cis/react/CardModal";
import Table from "../../../../../../artibulles-cis/TableNew/Table";

import AccountEdit from "./AccountDetails/AccountEdit";

const LockedstylesWithProps = (props) => {
    return {};
};

const styles = createUseStyles((theme) => ({
    ClassExtendsPageContent: {},
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 15px 10px 15px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        width: "100%",
        // maxHeight: "800px",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();

    const ColomnsInput = [
        {width: "150px", caption: "Account", datafield: "account_number", fixedCol: false, searchable: true, sortable: false, datatype: "text"},
        {width: "auto", caption: "Owner", datafield: "account_owner", fixedCol: false, searchable: true, datatype: "text"},
        {width: "250px", caption: "Bank", datafield: "bank_name", searchable: true, datatype: "text"},
        {width: "250px", caption: "Balance", datafield: "balance", searchable: true, datatype: "number"},
    ];
    const deletecondition = (args) => {
        // console.log("args",args.rowData)
        return true;
        // if (args.rowData.company) {
        //     return true;
        // } else {
        //     return false;
        // }
    };
    const HandleEditRow = (RowData) => {
        let Id = RowData.rowData._id;
        History.push(`/financials/bankaccounts/accounts/account/${Id}/view`);
    };
    const HandlePreviewRow = (RowData) => {
        let Id = RowData.rowData._id;
        setShowViewCard(true);
        setViewCardId(Id);
    };
    const HandleDeleteRow = (RowData) => {
        console.log("Delete Row", RowData);
    };

    const TableOptions = {
        Searchable: true,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        Selectable: true,
        RowButtons: {
            Standard: {
                Edit: {Condition: null, Position: 1, OnClick: HandleEditRow},
                View: {Condition: null, Position: 2, OnClick: HandlePreviewRow},
                Delete: {Condition: deletecondition, Position: 3, OnClick: null},
            },
        },
    };
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companyaccount/bankaccounts/accounts`,
        DELETE: "",
        PUT: "",
        POST: "",
    };
    console.log("FinalAPIUrlRelative", FinalAPIUrlRelative);

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="400px"
                CardHeight="500px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"
                CardWindowPaddingHorizontal="50px"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
            >
                <div className={classes.CardContent}>
                    <AccountEdit Id={ViewCardId} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            <div className={classes.TableWrapper}>
                <Table URLRelative={FinalAPIUrlRelative} ColomnsInput={ColomnsInput} TableOptions={TableOptions} DevMode={false} />
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default Component;
