import React, {useEffect, useState, useCallback} from "react";
import {CheckBoxCircularFilled, WarningTriangleYellow, WarningTriangleRed} from "@artibulles-cis/react-icons";

import Button from "@artibulles-cis/react/Button";
import {format, parseISO} from "date-fns";
import {createUseStyles, useTheme} from "react-jss";

import SendingDocumentMain from "./SendingDocumentMain";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PaymentBox: {
        flex: "0 1 auto",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        margin: "0px 0px 10px 10px",

        borderRadius: "5px",
        fontSize: "0.9rem",
        background: "rgba(66, 165, 245,0.3)",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    PaymentBox_TopBar: {
        flex: "0 0 30px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: "1px solid rgba(200,200,200,0.8)",
        // borderBottom: "1px solid rgba(66, 165, 245,0.2)",
    },
    PaymentBox_TopBarLeft: {
        flex: "1 1 auto",
        display: "flex",
        padding: "0px 5px",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "0.8rem",
    },
    PaymentBox_TopBarActions: {
        flex: "1 1 auto",
        display: "flex",
        padding: "0px 5px",
        // justifyContent: "center",
        alignItems: "center",
    },
    PaymentBox_TopBarRight: {
        flex: "1 1 auto",
        display: "flex",
        padding: "0px 5px",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    PaymentBox_Content: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        padding: "5px 5px",
    },
    InvoiceSummary: {
        display: "flex",
        width: "300px",
        alignSelf: "flex-start",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "0 0 150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },
}));

const Component = React.memo(function Component(props) {
    const {InvoiceStatus, Invoices_File_Path, Customers, PassedFormValues, InvoiceId, HandleSaveRefreshForm} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [PaymentInfo, setPaymentInfo] = useState(null);
    const [ShowCardDetails, setShowCardDetails] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    const GenerateAccountantInformation = useCallback(() => {
        let TopBarPaymentTitle,
            TopBarPaymentTitleIcon,
            TopBarLeftTitle,
            TopBarActions = [],
            TopBarIcon,
            Lines = [];

        //This is only used for status = Invoiced or Paid
        let CustomerSendInfosAll = PassedFormValues.CustomerSendingStatusHistory;
        // console.log("CustomerSendInfosAll", CustomerSendInfosAll);
        TopBarPaymentTitle = "Customer Status";
        let CustomerSendInfos;
        if (CustomerSendInfosAll && Array.isArray(CustomerSendInfosAll) && CustomerSendInfosAll.length > 0) {
            CustomerSendInfos = CustomerSendInfosAll[0];
        }
        if (CustomerSendInfos) {
            // console.log("CustomerSendInfos", CustomerSendInfos);

            let EmailSentDateIso;
            if (CustomerSendInfos.EmailSentDateIso) {
                EmailSentDateIso = format(parseISO(CustomerSendInfos.EmailSentDateIso), "dd-MMM-yyyy");
            }
            let Reference = CustomerSendInfos.Doc_Reference;
            if (CustomerSendInfos.EmailSent === true) {
                //Fully Uploaded, we show the details
                TopBarPaymentTitleIcon = (
                    <React.Fragment>
                        <div style={{marginRight: "5px", fontSize: "0.8rem"}}>Sent</div>
                        <CheckBoxCircularFilled SVGFillColor="green" IconSize="22px" />
                    </React.Fragment>
                );

                let ButtonAction = (
                    <Button Height="25px" Width="60px" ClassExtendButton={classes.Button} onClick={HandleShowCardDetails}>
                        Details
                    </Button>
                );
                TopBarActions.push(ButtonAction);
                let Line = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Sent :</div>
                        <div className={classes.InvoiceSummary_Line_Value}>{EmailSentDateIso}</div>
                    </div>
                );
                Lines.push(Line);
                Line = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Reference :</div>
                        <div className={classes.InvoiceSummary_Line_Value}>{Reference}</div>
                    </div>
                );
                Lines.push(Line);
            } else {
                // //Not Fully Uploaded
                // TopBarPaymentTitleIcon = (
                // 	<React.Fragment>
                // 		<div style={{marginRight: "5px", fontSize: "0.8rem"}}>Confirm Upload</div>
                // 		<WarningTriangleYellow IconSize="25px" />
                // 	</React.Fragment>
                // );

                // //We need to ask the user to check the upload folder
                // let ButtonAction = (
                // 	<Button Height="25px" Width="130px" ClassExtendButton={classes.Button} onClick={HandleShowCardDetails}>
                // 		Confirm Upload
                // 	</Button>
                // );
                // TopBarActions.push(ButtonAction);
                // let Line = (
                // 	<div className={classes.InvoiceSummary_Line}>
                // 		<div className={classes.InvoiceSummary_Line_Title}>Email sent :</div>
                // 		<div className={classes.InvoiceSummary_Line_Value}>{EmailSentDateIso}</div>
                // 	</div>
                // );
                // Lines.push(Line);
                // Line = (
                // 	<div className={classes.InvoiceSummary_Line}>
                // 		<div className={classes.InvoiceSummary_Line_Title}>Reference :</div>
                // 		<div className={classes.InvoiceSummary_Line_Value}>{Reference}</div>
                // 	</div>
                // );
                // Lines.push(Line);

                TopBarPaymentTitleIcon = (
                    <React.Fragment>
                        <div style={{marginRight: "5px", fontSize: "0.8rem"}}>Not sent</div>

                        <WarningTriangleRed IconSize="25px" />
                    </React.Fragment>
                );
                let ButtonAction = (
                    <Button Height="25px" Width="130px" ClassExtendButton={classes.Button} onClick={HandleShowCardDetails}>
                        Send to Customers
                    </Button>
                );
                TopBarActions.push(ButtonAction);
            }
        } else {
            TopBarPaymentTitleIcon = (
                <React.Fragment>
                    <div style={{marginRight: "5px", fontSize: "0.8rem"}}>Not sent</div>

                    <WarningTriangleRed IconSize="25px" />
                </React.Fragment>
            );
            let ButtonAction = (
                <Button Height="25px" Width="130px" ClassExtendButton={classes.Button} onClick={HandleShowCardDetails}>
                    Send to Customers
                </Button>
            );
            TopBarActions.push(ButtonAction);
        }
        // console.log("TopBarPaymentTitle", TopBarPaymentTitle);
        setPaymentInfo({
            TopBarPaymentTitle: TopBarPaymentTitle,
            TopBarPaymentTitleIcon: TopBarPaymentTitleIcon,
            TopBarLeftTitle: TopBarLeftTitle,
            TopBarActions: TopBarActions,
            TopBarIcon: TopBarIcon,
            Lines: Lines,
        });
    }, []);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        GenerateAccountantInformation();
    }, [InvoiceStatus, InvoiceId, GenerateAccountantInformation]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleShowCardDetails = () => {
        setShowCardDetails(true);
    };

    const HandleCloseCard = () => {
        setShowCardDetails(false);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let FinalComponent;
    if (InvoiceStatus === "Draft" || InvoiceStatus === "Created") {
        FinalComponent = null;
    } else {
        if (PaymentInfo) {
            FinalComponent = (
                <div className={classes.PaymentBox}>
                    <div className={classes.PaymentBox_TopBar}>
                        <div className={classes.PaymentBox_TopBarLeft}>{PaymentInfo.TopBarPaymentTitle}</div>

                        <div className={classes.PaymentBox_TopBarRight}>{PaymentInfo.TopBarPaymentTitleIcon}</div>
                    </div>

                    <div className={classes.PaymentBox_TopBar}>
                        <div className={classes.PaymentBox_TopBarActions}>{PaymentInfo.TopBarActions}</div>
                    </div>
                    <div className={classes.PaymentBox_Content}>
                        <div className={classes.InvoiceSummary}>{PaymentInfo.Lines}</div>
                    </div>
                </div>
            );
        }
    }

    let CardComponent;
    if (ShowCardDetails === true) {
        CardComponent = (
            <SendingDocumentMain
                InvoiceStatus={InvoiceStatus}
                Invoices_File_Path={Invoices_File_Path}
                Customers={Customers}
                PassedFormValues={PassedFormValues}
                InvoiceId={InvoiceId}
                HandleSaveRefreshForm={HandleSaveRefreshForm}
                ShowCardUpload={true}
                HandleCloseCardParent={HandleCloseCard}
            />
        );
    } else {
        CardComponent = null;
    }
    return (
        <React.Fragment>
            {FinalComponent}
            {CardComponent}
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
