import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import CardModal from "@artibulles-cis/react/CardModal";
import Page from "../../../../../../artibulles-cis/Page/Page";
import AGGridTable from "../../../../../../artibulles-cis/AGGridTable/AGGridTable";

import Button from "@artibulles-cis/react/Button";
import TaskCreateEdit from "../TaskCreateEdit/TaskCreateEdit";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import _ from "lodash";
import {toast} from "react-toastify";
import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";
import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import {Calendar, Dashboard} from "@artibulles-cis/react-icons";
import {isValid, format, formatISO} from "date-fns";

import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapperMain: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
    },
    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
    },
    ToolBarSelection: {
        height: "40px",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        boxSizing: "border-box",
        background: "rgb(60,60,60)",
        borderBottom: "2px solid grey",
        alignItems: "center",
        color: "white",
    },
    ButtonExtendToolBar: {
        margin: "0px 5px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },
    TableToolbar: {
        display: "flex",
        flexDirection: "column",
    },
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardContent: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    CardButtons: {
        flex: "0 1 auto",
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        // flexWrap: "wrap",
        alignItems: "center",
        // justifyContent: "space-between",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    ButtonExtend: {
        margin: "0px 0px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },

    SearchButtons: {
        padding: "0px 0px 8px 0px",
        flex: "0 1 auto",
        display: "flex",
    },
    CardTitle: {
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "flex-start",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    Title: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-start",
        padding: "0px 5px",
    },
}));

const TasksViewAllTasksTable = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_FormValues = useRef(null);
    const Ref_FieldOptions = useRef(null);
    const Ref_TabNavContent = useRef(null);
    const Ref_LocaleData = useRef(null);
    const Ref_AgGridApi = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };

    const HandleDeleteRow = (id) => {
        console.log("HandleDeleteRow");
        const ApiCall = {url: `${ProdURL}/api/tasks/task/${id}`, type: "delete"};
        setLoading(true);
        setAPICallType("DeleteRow");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };

    const HandleDuplicateInvoice = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };

    const DeleteCondition = (args) => {
        return true;
        // let status_invoice = args.status_invoice;
        // if (status_invoice === "Draft") {
        //     return true;
        // } else {
        //     return true; //DEVONLY!! Should be false
        // }
    };

    const HandleTrackTask = (Id) => {
        if (Id) {
            let NewDate = new Date(Date.now());
            let ISODate = formatISO(NewDate);
            let StartTimeSeconds = parseInt(format(NewDate, "H")) * 3600 + parseInt(format(NewDate, "m")) * 60 + parseInt(format(NewDate, "s"));
            const FormData = {
                Id: Id,
                date_Iso: ISODate,
                start_time_seconds: StartTimeSeconds,
            };
            const ApiCall = {url: `${ProdURL}/api/tasks/createtimeentryfortask`, type: "post", data: FormData};
            setLoading(true);
            setAPICallType("TrackTask");
            setIsInitalized(false);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };

    const DaysRemaingRendering = (params) => {
        let RowData = params.data;
        let days_remaining = RowData.days_remaining;

        if (days_remaining < 0) {
            return <div style={{color: "red"}}>{days_remaining}</div>;
        } else {
            return days_remaining;
        }
    };
    const DurationRendering = (params) => {
        let RowData = params.data;
        let Value = RowData.estimated_duration_seconds;

        if (Value) {
            return convertSecondsToTime(Value, false);
        } else {
            return null;
        }
    };

    const PercentageCompletedRendering = (params) => {
        let RowData = params.data;
        let Value = RowData.completed_percentage;

        if (Value) {
            return Value;
        } else {
            return null;
        }
    };

    const ColumnButtonStyle = {
        Width: 170,
        padding: "0px 0px 0px 18px", //18px on right if selection is enabled
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
            checkboxSelection: true,
        },
        {
            Standard: true,
            Name: "Duplicate",
            ActiveCondition: null,
            Index: 2,
            OnClick: HandleDuplicateInvoice,
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: false,
            ActiveCondition: null,
            WithConfirmationMessage: false,
            ConfirmationMessageTitle: "Track",
            ConfirmationMessage: "Create Time Entry And Track",
            CustomConfirmButton: "Start Tracking",
            Index: 4,
            IconActive: <Dashboard IconSize="20px" SVGFillColor="rgb(66, 165, 245)" SVGFillHoverColor="red" />,
            IconInactive: <Dashboard IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
            OnClick: HandleTrackTask,
            Tooltip: {Show: true, Position: "right", Theme: "light", Content: "Track Task"},
        },
    ];

    const TableColumns = [
        {
            field: "task_name",
            headerName: "Task",
            headerClass: "ag-CenterHeader",
            flex: 1,
            minWidth: 270,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "due_date_Iso",
            headerName: "Due",
            headerClass: "ag-CenterHeader",
            width: 130,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },

        {
            field: "days_remaining",
            headerName: "Days",
            headerClass: "ag-CenterHeader",
            width: 90,
            cellRenderering: DaysRemaingRendering,
        },
        {
            field: "estimated_duration_seconds",
            headerName: "Duration",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: DurationRendering,
        },
        {
            headerName: "Company",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            Lookup: {LookupField: "ref_company", Source: "Companies", ReturnField: "company"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Program",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            Lookup: {LookupField: "ref_program", Source: "Programs", ReturnField: "program_name"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Milestone",
            headerClass: "ag-CenterHeader",
            width: 250,
            filter: true,
            Lookup: {LookupField: "ref_milestone", Source: "Milestones", ReturnField: "milestone_name"},
            sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "is_personal",
            headerName: "Perso",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "is_completed",
            headerName: "Done",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "completed_percentage",
            headerName: "%Done",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: PercentageCompletedRendering,
        },
    ];
    const HandleRowSelectChange = (SelectedRows) => {
        if (SelectedRows.length === 0) {
            setSelectedRows(null);
            setShowSelectionToolbar(false);
        } else {
            setSelectedRows(SelectedRows);
            setShowSelectionToolbar(true);
        }
    };
    const TableOptions = {
        Selection: {
            Multiple: true,
            // GroupOnly: false,
            WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
        // TopToolbar: true,
    };

    // const AddToOutlookCondition = (args) => {
    //     // console.log("conditional args", args.is_completed)
    //     if (args.is_completed === false) {
    //         return true;
    //     } else {
    //         console.log("Is completed true", args);
    //         return false;
    //     }
    // };
    // const HandleAddToOutlook = (arg) => {
    //     //we post to add it to outlook
    //     let Id = arg._id;
    //     if (Id) {
    //         const ApiCall = {url: `${ProdURL}/api/tasks/addtasktooutlook/${Id}`, type: "post", data: arg};
    //         setLoading(true);
    //         setAPICallType("addtooutlook");
    //         setIsInitalized(false);

    //         async function APIInternal() {
    //             const res = await APICallExternal(ApiCall);
    //             setApiRes(res);
    //             setLoading(false);
    //         }

    //         APIInternal();
    //     }
    // };
    const HandleShareGridApi = (GridApi) => {
        Ref_AgGridApi.current = GridApi;
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [IsQueryiesInitialized, setIsQueryiesInitialized] = useState(false);
    const [InitialFormValues, setInitialFormValues] = React.useState({});
    const [FormValues, setFormValues] = React.useState({});
    const [FieldOptions, setFieldOptions] = React.useState({});
    const [ReloadTable, setReloadTable] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [SelectedRows, setSelectedRows] = useState(null);
    const [FinalQuery, setFinalQuery] = useState({});
    const [RefreshApi, setRefreshApi] = useState(true);
    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);
    const [Init, setInit] = useState(false);
    const [SelectedAction, setSelectedAction] = useState(null);
    const [IsInitalized, setIsInitalized] = useState(true);
    // const [ViewCardId, setViewCardId] = useState(null);
    const [ShowSelectionToolbar, setShowSelectionToolbar] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="TasksAll"
                Data={Ref_LocaleData.current}
                TableColumns={TableColumns}
                MainDataSource="Tasks"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTable}
                SyncReloadDataStateFunction={setReloadTable}
                ShareGridApi={HandleShareGridApi}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!APICallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        // console.log("Data", Data);
                        if (IsQueryiesInitialized === false) {
                            console.log("Initialize Form");
                            let InitFormValues = {
                                is_personal: "All",
                                is_completed: "Due",
                                late_status: "All",
                                due_date_Iso: formatISO(Date.now()),
                            };

                            let InitFieldOptions = {
                                is_completed: [
                                    {label: "Due", value: "Due"},
                                    {label: "All", value: "All"},
                                    {label: "Completed", value: "Completed"},
                                ],
                                is_personal: [
                                    {label: "Work", value: "Work"},
                                    {label: "All", value: "All"},
                                    {label: "Personal", value: "Personal"},
                                ],
                                late_status: [
                                    {label: "Late", value: "Late"},
                                    {label: "All", value: "All"},
                                    {label: "Upcoming", value: "Upcoming"},
                                ],
                            };

                            setInitialFormValues(InitFormValues);
                            setFormValues(InitFormValues);
                            setFieldOptions(InitFieldOptions);
                            Ref_FormValues.current = InitFormValues;
                            Ref_FieldOptions.current = InitFieldOptions;

                            setIsQueryiesInitialized(true);
                        }
                    }
                } else if (APICallType === "InitalizeTable") {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        Ref_LocaleData.current = Data;
                        setReloadTable(true);
                    }
                } else if (APICallType === "Filter") {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        Ref_LocaleData.current = Data;
                        setReloadTable(true);
                    }
                } else if (APICallType === "DeleteRow") {
                    console.log("ApiCallType = Delete Row");
                    toast.success(`Entry deleteted`, {autoClose: true});
                    setSelectedAction(null);
                    setSelectedRows(null);
                    setSelectedAction(null);
                    setShowSelectionToolbar(false);
                    Ref_AgGridApi.current.deselectAll();
                    let UpdatedFormValues = {...FormValues, ...{due_date_Iso: formatISO(Date.now())}};
                    setFormValues(UpdatedFormValues);
                    HandleFilter();
                } else if (APICallType === "addtooutlook") {
                    toast.success(`Entry addedd to outook`, {autoClose: true});
                    setIsInitalized(true);
                } else if (APICallType === "TrackTask") {
                    if (ApiRes.data.status === "success") {
                        History.push(`/tasktimemanagement/times/time/newtimentryandtrack`);
                    }
                } else if (APICallType === "UpdateTasks") {
                    toast.success(`Due date changed`, {autoClose: true});
                    setSelectedAction(null);
                    setSelectedRows(null);
                    setSelectedAction(null);
                    setShowSelectionToolbar(false);
                    Ref_AgGridApi.current.deselectAll();
                    let UpdatedFormValues = {...FormValues, ...{due_date_Iso: formatISO(Date.now())}};
                    setFormValues(UpdatedFormValues);
                    HandleFilter();
                }
            }
        }
    }, [ApiRes, Loading]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/tasks/tables/initializequeries`, type: "get"};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    useEffect(() => {
        if (IsQueryiesInitialized === true) {
            let QueryTable = "";
            let FormVal = Ref_FormValues.current;
            if (FormVal) {
                if (FormVal.is_personal === "Work") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&is_personal=false";
                    } else {
                        QueryTable = QueryTable + "?is_personal=false";
                    }
                } else if (FormVal.is_personal === "Personal") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&is_personal=true";
                    } else {
                        QueryTable = QueryTable + "?is_personal=true";
                    }
                }
                if (FormVal.is_completed === "Due") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&dueonly=true";
                    } else {
                        QueryTable = QueryTable + "?dueonly=true";
                    }
                } else if (FormVal.is_completed === "Completed") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&dueonly=false";
                    } else {
                        QueryTable = QueryTable + "?dueonly=false";
                    }
                }
            }
            // console.log("QueryTable", QueryTable);

            const ApiCall = {url: `${ProdURL}/api/tasks/query/${QueryTable}`, type: "get"};
            setLoading(true);
            setAPICallType("InitalizeTable");
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [IsQueryiesInitialized]);

    useEffect(() => {
        if (ReloadTable === true) {
            GenerateTableComponent();
            setReloadTable(false);
        }
    }, [ReloadTable, GenerateTableComponent]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        // console.log("Handle Close Edit Card");
        setShowEditCard(false);
        setEditCardId(null);
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setTimeout(() => {
            HandleFilter();
        }, 300);
    };

    const HandleUpdateFormValues = (field, values) => {
        //Note!!! with Date Pickers it's mandatory to use the Ref and not the state ....impossible to understand why but that is it
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        setFormValues({...Ref_FormValues.current, ...{[field]: value}});
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: value}};
    };

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        // if (field === "ConditionalSelect1") {
        //     //We update the Options of the ConditionalSelect2
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect1 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
        //     //We also need to reset the Conditional 2;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        // }
        // if (field === "ConditionalSelect2") {
        //     //We update the Options of the ConditionalSelect3
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect2 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
        //     //We also need to reset the Conditional 3;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        // }
    };

    const HandleSelectedAction = (Action) => {
        setSelectedAction(Action);
    };
    const HandleCloseSelectedActionCard = (Id) => {
        // console.log("Should close the card")
        setSelectedAction(null);
        setSelectedRows(null);
        setSelectedAction(null);
        setShowSelectionToolbar(false);
        Ref_AgGridApi.current.deselectAll();
        setTimeout(() => {
            setReloadTable(true);
        }, 300);
        let UpdatedFormValues = {...FormValues, ...{due_date_Iso: formatISO(Date.now())}};
        setFormValues(UpdatedFormValues);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    const HandleFilter = () => {
        let QueryTable = "";
        let FormVal = Ref_FormValues.current;
        if (FormVal) {
            if (FormVal.is_personal === "Work") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&is_personal=false";
                } else {
                    QueryTable = QueryTable + "?is_personal=false";
                }
            } else if (FormVal.is_personal === "Personal") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&is_personal=true";
                } else {
                    QueryTable = QueryTable + "?is_personal=true";
                }
            }
            if (FormVal.is_completed === "Due") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&dueonly=true";
                } else {
                    QueryTable = QueryTable + "?dueonly=true";
                }
            } else if (FormVal.is_completed === "Completed") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&dueonly=false";
                } else {
                    QueryTable = QueryTable + "?dueonly=false";
                }
            }
        }
        console.log("QueryTable", QueryTable);

        const ApiCall = {url: `${ProdURL}/api/tasks/query/${QueryTable}`, type: "get"};
        setLoading(true);
        setAPICallType("Filter");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    const HandleResetFilter = () => {
        setInit(false);
    };

    const DeleteSelectedRows = () => {
        let FormData = {
            TasksIds: [],
        };

        SelectedRows.forEach((elem) => {
            FormData.TasksIds.push(elem._id);
        });
    };
    const ChangeSelectedRowDate = () => {
        let FormData = {
            TasksIds: [],
            NewDueDate: FormValues.due_date_Iso,
        };

        SelectedRows.forEach((elem) => {
            FormData.TasksIds.push(elem._id);
        });
        console.log("Change Date Selected Rows", FormData);
        const ApiCall = {url: `${ProdURL}/api/tasks/tables/changeduedate`, type: "put", data: FormData};
        setLoading(true);
        setAPICallType("UpdateTasks");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;

    if (ShowEditCard === true) {
        let TopBarContent = (
            <div className={classes.Title}>
                <div style={{flex: "1 1 auto", justifyContent: "center"}}>Edit Task</div>
            </div>
        );

        ModalCardView = (
            <CardModal
                CardID="CardEditTask"
                TopBarContent={TopBarContent}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1000px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div>
                    <TaskCreateEdit Id={EditCardId} HandleCloseViewCard={HandleCloseEditCard} />
                </div>
            </CardModal>
        );
    }
    if (SelectedAction) {
        let CardContent;
        let CardTitle;
        let CardWidth, CardHeight;
        if (SelectedAction === "DeleteAll") {
            CardWidth = "500px";
            CardHeight = "400px";
            CardTitle = "Delete Selected Tasks";
            CardContent = (
                <div className={classes.CardMain}>
                    <div className={classes.CardContent}>{`Are you sure you want to delete the (${SelectedRows.length}) selected tasks ?`}</div>
                    <div className={classes.CardButtons}>
                        <Button ClassExtendButton={classes.ButtonExtendToolBar} Height="25px" Width="140px" onClick={DeleteSelectedRows}>
                            Delete All
                        </Button>
                    </div>
                </div>
            );
        } else if (SelectedAction === "ChangeDate") {
            // console.log("CardChangeDate");
            CardTitle = "Change Date For Selected Tasks";
            CardWidth = "500px";
            CardHeight = "400px";
            CardContent = (
                <div className={classes.CardMain}>
                    <div className={classes.CardContent} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormWrapper}>
                            <div>{`Select the new due date for the (${SelectedRows.length}) selected tasks ?`}</div>
                            <div>
                                <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                    <FormCompontent
                                        Name="due_date_Iso"
                                        InitialValue={InitialFormValues.due_date_Iso ? InitialFormValues.due_date_Iso : null}
                                        FormValue={FormValues.due_date_Iso ? FormValues.due_date_Iso : null}
                                        OutputValue={(values) => HandleUpdateFormValues("due_date_Iso", values)}
                                        Component="DatePicker"
                                        Variant="Outlined"
                                        Label="Due date"
                                        NoMessage={false}
                                        // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                        // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"
                                        PickerDateType="Calendar"
                                        PickerOnly={false}
                                        InputFormat="dd/MM/yyyy"
                                        LabelMaxWidth="200px"
                                        LabelAlign="Left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.CardButtons}>
                        <Button ClassExtendButton={classes.ButtonExtendToolBar} Height="25px" Width="140px" onClick={ChangeSelectedRowDate}>
                            Change Date
                        </Button>
                    </div>
                </div>
            );
        }
        let TopBarContent = (
            <div className={classes.Title}>
                <div style={{flex: "1 1 auto", justifyContent: "center"}}>{CardTitle}</div>
            </div>
        );
        ModalCardView = (
            <CardModal
                CardID="CardSelectedTasks"
                TopBarContent={TopBarContent}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseSelectedActionCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth={CardWidth}
                CardHeight={CardHeight}
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardWindow}>{CardContent}</div>
            </CardModal>
        );
    }

    let FormComponent;

    if (IsQueryiesInitialized) {
        FormComponent = (
            <div className={classes.FormMultilineFlex}>
                <div className={classes.FormField} style={{flex: "0 0 150px", marginLeft: "0px"}}>
                    <FormCompontent
                        Name="is_completed"
                        InitialValue={InitialFormValues.is_completed ? InitialFormValues.is_completed : null}
                        FormValue={FormValues.is_completed ? FormValues.is_completed : null}
                        OutputSelectedValues={(values) => handleSelectChange("is_completed", values)}
                        SelectOptions={FieldOptions.is_completed}
                        Component="Select"
                        Variant="OutlinedLabel"
                        Label="Completed"
                        NoMessage={false}
                        // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                        // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                        MultiSelect={false}
                        // SortSelectOptions="Asc"
                        // SortField="order"
                        SortIcons={false}
                        AllowCreateOption={false}
                        LabelMaxWidth="200px"
                        LabelAlign="Left"
                        Required={true}
                    />
                </div>
                <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                    <FormCompontent
                        Name="is_personal"
                        InitialValue={InitialFormValues.is_personal ? InitialFormValues.is_personal : null}
                        FormValue={FormValues.is_completed ? FormValues.is_personal : null}
                        OutputSelectedValues={(values) => handleSelectChange("is_personal", values)}
                        SelectOptions={FieldOptions.is_personal}
                        Component="Select"
                        Variant="OutlinedLabel"
                        Label="Personal"
                        NoMessage={false}
                        // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                        // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                        MultiSelect={false}
                        // SortSelectOptions="Asc"
                        // SortField="order"
                        SortIcons={false}
                        AllowCreateOption={false}
                        LabelMaxWidth="200px"
                        LabelAlign="Left"
                        Required={true}
                    />
                </div>
                <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                    <FormCompontent
                        Name="late_status"
                        InitialValue={InitialFormValues.late_status ? InitialFormValues.late_status : null}
                        FormValue={FormValues.late_status ? FormValues.late_status : null}
                        OutputSelectedValues={(values) => handleSelectChange("late_status", values)}
                        SelectOptions={FieldOptions.late_status}
                        Component="Select"
                        Variant="OutlinedLabel"
                        Label="Status Late"
                        NoMessage={false}
                        // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                        // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                        MultiSelect={false}
                        // SortSelectOptions="Asc"
                        // SortField="order"
                        SortIcons={false}
                        AllowCreateOption={false}
                        LabelMaxWidth="200px"
                        LabelAlign="Left"
                        Required={true}
                    />
                </div>
            </div>
        );
    } else {
        FormComponent = null;
    }
    let ToolBarSelectionComponent;
    if (ShowSelectionToolbar === true) {
        ToolBarSelectionComponent = (
            <div className={classes.ToolBarSelection}>
                <Button ClassExtendButton={classes.ButtonExtendToolBar} Height="25px" Width="140px" onClick={() => HandleSelectedAction("DeleteAll")}>
                    Delete All
                </Button>
                <Button ClassExtendButton={classes.ButtonExtendToolBar} Height="25px" Width="140px" onClick={() => HandleSelectedAction("ChangeDate")}>
                    Change Date
                </Button>
            </div>
        );
    } else {
        ToolBarSelectionComponent = null;
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            InnerPadding="0px 10px 10px 10px"
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            {FormComponent}
            <div className={classes.TableToolbar}>
                <div className={classes.SearchButtons}>
                    <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleFilter}>
                        Apply Filters
                    </Button>
                    <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleResetFilter} style={{marginLeft: "10px"}}>
                        Reset Filters
                    </Button>
                </div>
            </div>
            <div className={classes.TableWrapperMain}>
                {ToolBarSelectionComponent}
                <div className={classes.TableWrapper}>{TableComponent}</div>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default TasksViewAllTasksTable;
