import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

const LockedstylesWithProps = props => {
    return {};
};

const Lockedstyles = {};

const styles = createUseStyles(theme => ({
    DragAnDropContainer: {
        flex: "1 1 auto",
        background: "none",
        position: "relative"
    },
    DragAnDropContainer_Draggin: {
        flex: "1 0 auto",
        maxWidth: "300px",
        border: "2px dashed green",
        borderRadius: "5px",
        marginLeft: "10px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center"
    },
    DragOverlayMessage: {
        height: "40px",
        width: "100%",
        color: "grey",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "green",
        color: "white"
    }
}));

/**
 * DragAndDropContainer For FileUploader
 */
const FileUploaderDragAndDropContainer = React.forwardRef(function FileUploaderDragAndDropContainer(props, ref) {
    const {TransferFileToInput, FileComponent} = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /************************************* STATE ******************************************/
    const [IsDragging, setIsDragging] = React.useState(false);
    /************************************* STATE ******************************************/

    /************************************* REFERENCE ******************************************/
    const DragContainerRef = React.useRef(null);
    const dragTargetsRef = React.useRef([]);
    /************************************* REFERENCE ******************************************/

    /************************************* INIT AND DEV ERRORS ******************************************/
    var errors = [];
    if (!TransferFileToInput) {
        errors.push("you didn't pass a function to capture the drop files");
    }
    if (!FileComponent) {
        errors.push("you didn't pass a component inside the drag and drop zone");
    } else {
        if (typeof TransferFileToInput !== "function") {
            errors.push("you didn't pass a function to capture the drop files");
        }
    }
    /************************************* INIT AND DEV ERRRORS ******************************************/

    /************************************* EFFECTS ******************************************/
    //ADDING DRAG AND DROP EVENT LISTENERS FOR THE CONTAINER
    React.useEffect(() => {
        if (DragContainerRef.current) {
            let DragContainer = DragContainerRef.current;
            DragContainer.addEventListener("dragenter", handleDragIn);
            DragContainer.addEventListener("dragleave", handleDragOut);
            DragContainer.addEventListener("drop", handleDrop);
            return function cleanup() {
                DragContainer.removeEventListener("dragenter", handleDragIn);
                DragContainer.removeEventListener("dragleave", handleDragOut);
                DragContainer.removeEventListener("drop", handleDrop);
            };
        }
    }, [TransferFileToInput, FileComponent]);

    //REMOVING STANDARD DROP BEHAVIOR ON THE DOCUMENT / WINDOW TO AVOID THE BROWSER TO ALLOW DROPPING THE FILE ANYWHERE AND OPEN IT
    React.useEffect(() => {
        window.addEventListener("dragover", HandleWindowDragDrop);
        window.addEventListener("drop", HandleWindowDragDrop);
        return function cleanup() {
            window.removeEventListener("dragover", HandleWindowDragDrop);
            window.removeEventListener("drop", HandleWindowDragDrop);
        };
    }, [TransferFileToInput, FileComponent]);
    /************************************* EFFECTS ******************************************/

    /************************************* FUNCTIONS ******************************************/
    const handleDragIn = event => {
        event.preventDefault();
        event.stopPropagation();

        // Count the dropzone and any children that are entered.
        if (dragTargetsRef.current.indexOf(event.target) === -1) {
            dragTargetsRef.current = [...dragTargetsRef.current, event.target];
        }
        setIsDragging(true);
    };
    const handleDragOut = event => {
        event.preventDefault();
        event.stopPropagation();

        // Only deactivate once the dropzone and all children have been left
        const targets = dragTargetsRef.current.filter(target => target !== event.target && DragContainerRef.current && DragContainerRef.current.contains(target));
        dragTargetsRef.current = targets;
        if (targets.length > 0) {
            return;
        }
        setIsDragging(false);
    };

    const handleDrop = event => {
        event.preventDefault();
        event.stopPropagation();

        //Pass back the files list to the parent component
        let files = event.dataTransfer ? event.dataTransfer.files : null;
        if (files) {
            TransferFileToInput(null, files);
        }
        setIsDragging(false);
    };

    //Preventing dropping anywhere else than in the drop Zone
    const HandleWindowDragDrop = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    /************************************* FUNCTIONS ******************************************/

    /************************************* RENDERING ******************************************/

    const FinalComponent = () => {
        if (errors.length <1) {
            return FileComponent(IsDragging);
        } else {
            console.group("DragAnDropContainer");
            errors.forEach(elem => {
                console.log(elem);
            });
            console.groupEnd()
            return "Dev error check the console";
        }
    };
    return (
        <div ref={DragContainerRef} className={classes.DragAnDropContainer}>
            {FinalComponent()}
        </div>
    );
    /************************************* RENDERING ******************************************/
});

FileUploaderDragAndDropContainer.defaultProps = {
    TransferFileToInput: null,
    FileComponent: null
};

FileUploaderDragAndDropContainer.propTypes = {
    /**
     * Function to pass the dropped files back to the Parent element
     */
    TransferFileToInput: PropTypes.func,
    /**
     * Component to be rendered inside the drag and drop zone
     */
    FileComponent: PropTypes.element
};

export default FileUploaderDragAndDropContainer;
