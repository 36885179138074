/* eslint-disable */
import React, {useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import {useHistory} from "react-router-dom";

import CardModal from "@artibulles-cis/react/CardModal";
import Table from "../../../../../../../artibulles-cis/TableNew/Table.js";

import CardEdit from "../CardDetails/CardEdit/CardEdit";
const {isValid, format, parseISO} = require("date-fns");
const LockedstylesWithProps = (props) => {
    return {};
};

const styles = createUseStyles((theme) => ({
    ClassExtendsPageContent: {},
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "400px",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const ColomnsInput = [
        {width: "250px", caption: "card_number", datafield: "card_number", fixedCol: false, searchable: true, sortable: false, datatype: "text"},
        {width: "350px", caption: "Owner", datafield: "card_holder_Name", fixedCol: false, searchable: true, datatype: "text"},
        {width: "250px", caption: "Bank", datafield: "bank_name", searchable: true, datatype: "text"},
        {
            width: "250px",
            caption: "expiration_date",
            datafield: "expiration_date",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "MM/yy");
                } else {
                    return value;
                }
            },
            searchable: true,
            datatype: "date",
        },
    ];

    // const HandleEditRow = (RowData) => {
    //     let Id = RowData.rowData._id;
    // 	History.push(`/financials/companyaccount/creditcards/creditacard/${Id}/view`);
    // };
    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };
    const TotalRow = {
        Show: false,
    };

    const HandleEditRow = (arg) => {
        let id = arg._id;

        setShowViewCard(true);
        setViewCardId(id);
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 15,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: true,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "View",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
    };

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companyaccount/creditcards/cards`,
        DELETE: "",
        PUT: "",
        POST: "",
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <CardEdit Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            <div className={classes.TableWrapper}>
                <Table
                    // LocalData={TableData}
                    URLRelative={FinalAPIUrlRelative}
                    // URLRelative="arnaud"
                    // MainDataSource="Transactions"
                    ColomnsInput={ColomnsInput}
                    TableDimensions={TableDimensions}
                    TableOptions={TableOptions}
                    TotalRow={TotalRow}
                />
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default Component;
