import React, {useState} from "react";

const TableContext = React.createContext([{}, () => {}]);

const TableContextProvider = (props) => {
    const [state, setState] = useState({
        OriginalTableData: null, //Full Api data (including lookup and other data)
        RowsOriginal: null, //Only the table data but unmutable, if only table data it is the same as the OriginalTableData
        RowsInput: null, //Mutable
        ColumnsInput: null, //Mutable
        HeaderData: null,
        FooterData: null,
        BodyData: null,
        TableDimensionsOptions: {Theme: "Dark"},
        TableDimensions: null,
        TableStyleOptions: null,
        TableStyle: null,
        PagerOptions: null,
        SortedColumsState: {
            IsSortable: true,
            TotalSortedColumns: null,
        },
        SelectedColumnsState: {
            IsSelectable: true,
            TotalSelectedRows: null,
        },
        ToolBarState: {
            ShowToolbar: false,
        },
        GenerateHeader: false,
        ActionButtonColWidth: null,

        //Dev Only
        DisplayPager: true,
		TableAction : null,
    });
    return <TableContext.Provider value={[state, setState]}>{props.children}</TableContext.Provider>;
};

export {TableContext, TableContextProvider};
