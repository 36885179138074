import React, {useState, useRef, useCallback, useEffect} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import Card from "../Card/Card";

const LockedstylesWithProps = (props) => {
    //Used to prevent the user from editing those styles other than those specified with props
    return {
        preloader: {
			position: props.FullSize === true ? "absolute" : props.ModalParentID?"absolute":"relative",
			zIndex : props.ZIndex?props.ZIndex:800,
            maxWidth: "100%",
            width: "100%",
            maxHeight: "100%",
            height: props.FullHeight ? "100%" : null,
            boxSizing: "content-box",
            // display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    };
};

const Lockedstyles = {
    //Used to Completely prevent the user from editing those styles
    LocalLoaderWrapper: {
        display: "flex",
        position: "absolute",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "borderbox",
    },
    CombinedCircleWrapper: {
        position: "relative",
    },
    SVGCircleWrapper: {
        position: "absolute",
    },
};

const styles = createUseStyles((theme) => ({
    LocalCircularLoader: {},
    Loader: {},
    LoaderWithModal: {
        display: "flex",
        height: "100%",
        width: "100%",
    },
    ClassOverrideCard: {
        borderRadius: "0px",
        background: "none",
    },
    //Used to upload the theme classes
    LocalLoaderWrapper: theme.LocalCircularLoader.LocalLoaderWrapper,
    AnimatedCircle: theme.LocalCircularLoader.AnimatedCircle,
    "@keyframes rotatethecircle": {
        from: {transform: "rotate(0deg)"},
        to: {transform: "rotate(360deg)"},
    },
}));

/**
 * Create a Local Circular Loader Element
 */
const LocalCircularLoader = React.forwardRef(function LocalCircularLoader(props, ref) {
    const {
		FullSize,
		WithModalCard,
		ZIndex,
        ModalCardBackground,
        ModalParentID,
        FitToParentDimensions,
        Loading,
        FixedCircleRadius,
        FixedCircleThickness,
        FixedCircleColor,
        AnimatedCircleRadius,
        AnimatedCircleThickness,
        AnimatedCircleColor,
        ClassOverrideLocalLoaderWrapper,
        ClassExtendLocalLoaderWrapper,
        ClassOverrideAnimatedCircle,
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    const LoaderRef = useRef();
    const [LoaderHeight, setLoaderHeight] = useState();

    const calculateLoaderHeight = useCallback(() => {
        if (LoaderRef && LoaderRef.current) {
            var parentHeight, ContentHeight;
            //Calculate the content page height
            let parentNode = LoaderRef.current.parentNode;
            parentHeight = parentNode.getBoundingClientRect().height;
            ContentHeight = parseFloat(parentHeight) + "px";
            setLoaderHeight(ContentHeight);
        }
    }, [LoaderRef]);

    useEffect(() => {
        calculateLoaderHeight();
    }, [calculateLoaderHeight]);

    //Extracting the Theme Default values to pass them as inputs
    var FinalFixedCircleRadius = FixedCircleRadius ? FixedCircleRadius : theme.LocalCircularLoader.FixedCircleProps.CircleRadius;
    var FinalFixedCircleThickness = FixedCircleThickness ? FixedCircleThickness : theme.LocalCircularLoader.FixedCircleProps.CircleThickness;
    var FinalFixedCircleColor = FixedCircleColor ? FixedCircleColor : theme.LocalCircularLoader.FixedCircleProps.CircleColor;
    var FinalAnimatedCircleRadius = AnimatedCircleRadius ? AnimatedCircleRadius : theme.LocalCircularLoader.AnimatedCircleProps.CircleRadius;
    var FinalAnimatedCircleThickness = AnimatedCircleThickness ? AnimatedCircleThickness : theme.LocalCircularLoader.AnimatedCircleProps.CircleThickness;
    var FinalAnimatedCircleColor = AnimatedCircleColor ? AnimatedCircleColor : theme.LocalCircularLoader.AnimatedCircleProps.CircleColor;

    var FinalLoader, LoaderComp, FinaLocalLoader;

    LoaderComp = (
        <div
            className={ClassOverrideLocalLoaderWrapper ? ClassOverrideLocalLoaderWrapper : clsx(classes.LocalLoaderWrapper, ClassExtendLocalLoaderWrapper)}
            style={{
                ...Lockedstyles.LocalLoaderWrapper,
            }}
        >
            <div style={{...Lockedstyles.CombinedCircleWrapper}} className="">
                <div
                    style={{
                        ...Lockedstyles.SVGCircleWrapper,
                        height: `${FinalFixedCircleRadius}px`,
                        width: `${FinalFixedCircleRadius}px`,
                        top: `${-FinalFixedCircleRadius / 2}px`,
                        left: `${-FinalFixedCircleRadius / 2}px`,
                    }}
                    className=""
                >
                    <svg viewBox="22 22 44 44">
                        <circle cx="44" cy="44" r="19" strokeWidth={FinalFixedCircleThickness} fill="none" stroke={FinalFixedCircleColor} />
                    </svg>
                </div>
                <div
                    style={{
                        ...Lockedstyles.SVGCircleWrapper,
                        height: `${FinalAnimatedCircleRadius}px`,
                        width: `${FinalAnimatedCircleRadius}px`,
                        top: `${-FinalAnimatedCircleRadius / 2}px`,
                        left: `${-FinalAnimatedCircleRadius / 2}px`,
                    }}
                    className=""
                >
                    <svg viewBox="22 22 44 44" className={ClassOverrideAnimatedCircle ? ClassOverrideAnimatedCircle : classes.AnimatedCircle}>
                        <circle cx="44" cy="44" r="19" strokeWidth={FinalAnimatedCircleThickness} fill="none" stroke={FinalAnimatedCircleColor} strokeDashoffset="0px" strokeDasharray="90 200" />
                    </svg>
                </div>
            </div>
        </div>
    );

    if (WithModalCard) {
        FinalLoader = (
            <Card
                CardID="MyCard"
                IsDraggable={false}
                ParentRefCompID={ModalParentID}
                FitToParentDimensions={FitToParentDimensions}
                CardBackgroundColor="none"
                ClassOverrideCard={classes.ClassOverrideCard}
                CardBackground="none"
                WithPerfectScrollbar={false}
                AlertUserWhenLeavingPage={false}
                CardWindowPaddingVertical="0px"
                CardWindowPaddingHorizontal="0px"
                ZIndex={ZIndex?ZIndex:800}
                WindowBackgroundColor={ModalCardBackground}
                // CardMaxWidth={}
                CardHeight={FullSize?"100%":LoaderHeight}
            >
                {LoaderComp}
            </Card>
        );
    } else {
        FinalLoader = <React.Fragment>{LoaderComp}</React.Fragment>;
    }

    if (Loading) {
        FinaLocalLoader = FinalLoader;
    } else {
        FinaLocalLoader = null;
    }

    return (
        <div ref={LoaderRef} style={{...LockedstylesWithProps(props).preloader, ...{height: LoaderHeight, display: Loading ? "flex" : "none"}}} className={classes.LocalCircularLoader}>
            {FinaLocalLoader}
            {children}
        </div>
    );
});
LocalCircularLoader.defaultProps = {
	FullSize : false,
	WithModalCard: false,
	ZIndex : null,
    ModalCardBackground: "rgba(0, 0, 0, 0.2)",
    ModalParentID: "",
    FitToParentDimensions: false,
    Loading: false,
    FullHeight: false,
    FixedCircleRadius: null,
    FixedCircleThickness: null,
    FixedCircleColor: null,
    AnimatedCircleRadius: null,
    AimatedCircleThickness: null,
    AnimatedCircleColor: null,
    ClassOverrideLocalLoaderWrapper: null,
    ClassExtendLocalLoaderWrapper: null,
    ClassOverrideAnimatedCircle: null,
};

LocalCircularLoader.propTypes = {
	/**
     * Full Size - > will set all to position absolute
     */
	FullSize: PropTypes.bool,
	 /**
     * Z-Index
     */
    ZIndex: PropTypes.any,
    /**
     * Add or not a modal card
     */
    WithModalCard: PropTypes.bool,
    /**
     * Background Color of the modal card : rgba(0,0,0,0.2)
     */
    ModalCardBackground: PropTypes.any,
    /**
     * id of the parent component where the card will be inserted
     */
    ModalParentID: PropTypes.any,
    /**
     * Fit to the parent dimensions
     */
    FitToParentDimensions: PropTypes.bool,
    /**
     * Pass the Loading props to display the loader
     */
    Loading: PropTypes.bool,
    /**
     * If true set the preloader height="100%" (useful for cards and elements taking a full height)
     */
    FullHeight: PropTypes.bool,
    /**
     * Radius of the fixed Circle
     */
    FixedCircleRadius: PropTypes.any,
    /**
     * Thickness of the Fixed Circle
     */
    FixedCircleThickness: PropTypes.any,
    /**
     * Color of the Fixed Circle
     */
    FixedCircleColor: PropTypes.any,
    /**
     * Radius of the Animated Circle
     */
    AnimatedCircleRadius: PropTypes.any,
    /**
     * Thickness of the Animated Circle
     */
    AimatedCircleThickness: PropTypes.any,
    /**
     * Color of the Animated Circle
     */
    AnimatedCircleColor: PropTypes.any,
    /**
     * Class override for the wrapper (background Color and Zindex mainly)
     */
    ClassOverrideLocalLoaderWrapper: PropTypes.any,
    /**
     * Class extend for the wrapper (background Color and Zindex mainly)
     */
    ClassExtendLocalLoaderWrapper: PropTypes.any,
    /**
     * Class override for the animated circle (Animation timing mainly)
     */
    ClassOverrideAnimatedCircle: PropTypes.any,
};
export default LocalCircularLoader;
