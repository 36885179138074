/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";
import {ArtiWeb, Company, AddressBook, Contract, Offer, TableView, Dashboard, AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "30px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
        color: "white",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
}));

const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const MainRoute = "/mycompany/settings/";
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    
    const HandleCreateCustomer = () => {
        History.push(`/crm/customers/customer/new/edit`);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <NavSideBarLink ActiveLinkIndicator={true} Slug={`${MainRoute}details`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Company IconSize="24" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Artibulles Company</p>
                    </div>
                </div>
            </NavSideBarLink>
            
            
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
