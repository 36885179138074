import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import AccountantUploadHistoryTable from "./AccountantUploadHistoryTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
}));

const Component = React.memo(function Component(props) {
    const {TableData} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            NoLink={true}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Upload History</div>
                        </div>
                    ),

                    TabContent: <AccountantUploadHistoryTable TableData={TableData} />,
                },

                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Message History</div>
                        </div>
                    ),

                    TabContent: <div>VAT</div>,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
