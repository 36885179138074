import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory, useLocation} from "react-router-dom";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import FormComponent from "@artibulles-cis/react/FormComponent";
import _ from "lodash";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        // height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
}));
const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const Ref_FormValues = useRef(null);
    const Ref_FieldOptions = useRef(null);
    /***************** CONST ******************/
    const {Id} = props;

    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const TableColumns = [
        {
            field: "StatementDateIso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
        },
        {
            field: "calc_RefBankStatement",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 160,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 2,
            sort: "desc",
            // Type: "Date",
        },
        {
            headerName: "Journal",
            headerClass: "ag-CenterHeader",
            width: 300,
            filter: true,
            Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "StartingBalance",
            headerName: "Starting Balance",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "EndingBalance",
            headerName: "Ending Balance",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "Calc_TotalAmount",
            headerName: "Total Statement",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
    };
    const TotalRow = {
        Position: "Bottom",
        Style: null,
    };

    let URLAbsolute = {
        ALL: `${ProdURL}/api/companyaccount/bankstatements`,
        QUERY: "",
        DELETEONE: "",
        DELETEMANY: "",
        PUT: "",
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [RefreshApi, setRefreshApi] = useState(true);
    const [IsInitalized, setIsInitalized] = useState(false);
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FormValues, setFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState({}); //FORM OPTIONS FOR SELECT
    // const [TableComponent, setTableComponent] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Statement Data", Data);
                    let SelectAccounts = Data.BankAccounts;
                    var BankAccountsOptions = [];
                    if (SelectAccounts && SelectAccounts.length > 0) {
                        for (var i = 0; i < SelectAccounts.length; i++) {
                            let Elem = SelectAccounts[i];
                            let Seloption = {label: Elem.select_account_owner_bank, value: Elem._id};
                            BankAccountsOptions.push(Seloption);
                        }
                    }
                    let DefaultAccount = _.find(SelectAccounts, {isDefaultAccount: true});
                    if (DefaultAccount) {
                        DefaultAccount = DefaultAccount._id;
                    }

                    let FinalOptions = {
                        ref_bankaccount: BankAccountsOptions,
                    };
                    let InitForm = {
                        ref_bankaccount: DefaultAccount,
                    };

                    setFieldOptions(FinalOptions);
                    setInitialFormValues({...InitialFormValues, ...InitForm});
                    // setTableData(Data.BankStatements)
                    setFormValues(InitForm);
                    setRefreshApi(false);
                    setIsInitalized(true);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (RefreshApi === true) {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/bankstatements`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, RefreshApi]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        // if (field === "ConditionalSelect1") {
        //     //We update the Options of the ConditionalSelect2
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect1 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
        //     //We also need to reset the Conditional 2;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        // }
        // if (field === "ConditionalSelect2") {
        //     //We update the Options of the ConditionalSelect3
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect2 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
        //     //We also need to reset the Conditional 3;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        // }
        // setTableComponent(null);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let TableComponent = null;
    if (IsInitalized === true) {
        TableComponent = (
            <AGGridTable
                URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                MainDataSource="BankStatements"
                // RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
            />
        );
    } else {
        TableComponent = null;
    }

    return (
        <TabNavContent TabRef={Ref_TabNavContent} ClassExtendTabContent={classes.ClassExtendTabContent} Height="100%">
            <div className={classes.OverviewWrapper}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 1 800px"}}>
                        <FormComponent
                            Name="ref_bankaccount"
                            InitialValue={InitialFormValues.ref_bankaccount ? InitialFormValues.ref_bankaccount : null}
                            FormValue={FormValues.ref_bankaccount ? FormValues.ref_bankaccount : null}
                            OutputSelectedValues={(values) => handleSelectChange("ref_bankaccount", values)}
                            SelectOptions={FieldOptions.ref_bankaccount}
                            Component="Select"
                            Variant="Outlined"
                            Label="Bank Account"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            AlternateRowStyle={true}
                            // SortIcons={true}
                            AllowCreateOption={false}
                            Required={false}
                            Clearable={false}
                            // CreateDeleteIfUnselected={}

                            LabelMaxWidth="120px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.TableWrapper}>{TableComponent}</div>
            </div>
        </TabNavContent>
    );
    /***************** RENDER ******************/
});

export default Component;
