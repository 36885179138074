import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import useResizeObserver from "../utils/useResizeObserver";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableContainer: {
        position: "relative",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "5px",
        // borderRadius: "5px",
        background: "rgb(240,240,240)",
    },
}));

/**
 * Component Description
 */

const TableContainer = React.memo(function TableContainer(props) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {NoData, TableDimensions, DevModeComponent, children} = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_TableContainer = useRef(null);
    /****************************** REFS *********************/

    /****************************** CONST *********************/

    const ParentContainerDimensions = useResizeObserver({TrackElementRef: Ref_TableContainer, TrackElementRefNode: "parentNode"});
    // console.log("ParentContainerDimensions", ParentContainerDimensions);

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [Dimensions, setDimensions] = React.useState({width: "100%", height: "auto", tableHeight: null});
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const CalculateTableDimensions = React.useCallback(() => {
        let Elem = Ref_TableContainer.current;

        if (Elem) {
            // console.log("TableContainer - Dimensions : ", TableDimensions);
            //Setting the fake element to width 100px and height auto for the calculation
            setDimensions({width: "100px", height: "auto"});
            //Calculating all elements
            var TopbarHeight, height, Tableheight;

            if (TableDimensions.FitParentContainer === true) {
                let ParentElem = Elem.parentNode;
                var ParentElemPadding;
                if (ParentElem) {
                    ParentElemPadding =
                        parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-top")) + parseFloat(window.getComputedStyle(ParentElem).getPropertyValue("padding-bottom"));

                    height = ParentElem.getBoundingClientRect().height - ParentElemPadding;
                    Tableheight = height - TopbarHeight - 2 * parseFloat(TableDimensions.padding);
                }
            } else {
                if (TableDimensions.height) {
                    height = parseInt(TableDimensions.height);
                    Tableheight = height - 2 * parseFloat(TableDimensions.padding);
                }
            }
            var width;
            if (TableDimensions.width) {
                width = TableDimensions.width;
            } else {
                width = Elem.getBoundingClientRect().width + "px";
            }
            if (NoData === true) {
                setDimensions({width: width, maxWidth: TableDimensions.width ? width : null, height: 500 + "px", tableHeight: Tableheight + "px"});
            } else {
                setDimensions({width: width, maxWidth: TableDimensions.width ? width : null, height: height + "px", tableHeight: Tableheight + "px"});
            }
        }
    }, [TableDimensions, NoData]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Calculate size when mounting
    useEffect(() => {
        //Give it a little time to render the dom and calculate correctly
        setTimeout(() => {
            CalculateTableDimensions();
        }, 50);
    }, [CalculateTableDimensions, ParentContainerDimensions]);

    //Add a Window Resize Event Listener
    useEffect(() => {
        window.addEventListener("resize", CalculateTableDimensions);
        return function cleanup() {
            window.removeEventListener("resize", CalculateTableDimensions);
        };
    }, [CalculateTableDimensions]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    return (
        <div
            ref={Ref_TableContainer}
            className={classes.TableContainer}
            style={{
                ...{width: Dimensions.width, maxWidth: Dimensions.maxWidth, position: "relative", zIndex: 1, height: TableDimensions.FitParentContainer ? Dimensions.height : null},
                ...LockedstylesWithProps(props).ContainerOptions,
            }}
        >
            {DevModeComponent ? DevModeComponent : null}
            {children}
        </div>
    );
    /****************************** RENDER *********************/
});

TableContainer.defaultProps = {
    MyProp: null,
};

TableContainer.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableContainer;
