import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {CheckBoxCircularFilled, WarningTriangleRed, WarningTriangleYellow, PDF} from "@artibulles-cis/react-icons";
import {toast} from "react-toastify";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        // maxWidth: "1200px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        // height: "400px",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    ClassExtendButton: {
        padding: "0px",
        margin: "0px 0px",
    },
    ButtonBar: {
        display: "flex",
        padding: "0px 5px 0px 0px",
        // height: "30px",
    },
    ButtonBar_Left: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    ButtonBar_Middle: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    ButtonBar_Right: {
        flex: "1 1 auto",
        maxWidth: "280px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },

    Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
        fontSize: "1rem",
        fontWeight: "600",
        boxSizing: "border-box",
    },
    Line_Title: {
        flex: "0 0 auto",
        width: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
        boxSizing: "border-box",
    },
    Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    FormWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        color: "black",
        textAlign: "left",
        margin: "0px 0px 20px 0px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 800px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {InvoiceId, FormValuesParent, HandleUpdateReconconcile} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const StatusRendering = (params) => {
        let RowData = params.data;

        let Status = RowData.Status;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Posted") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                            Posted
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Reconciled") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "green"}}>
                            Reconciled
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    };
    const AccountNumberRendering = (params) => {
        let RowData = params.data;
        let counterparty_bankAccount_number = RowData.counterparty_bankAccount_number;
        let location = RowData.location;
        let Output;
        if (counterparty_bankAccount_number) {
            Output = counterparty_bankAccount_number;
        } else {
            Output = location;
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };
    const AccountOwnerRendering = (params) => {
        let RowData = params.data;
        let counterparty_bankAccount_name = RowData.counterparty_bankAccount_name;
        let merchant = RowData.merchant;
        let Output;
        if (counterparty_bankAccount_name) {
            Output = counterparty_bankAccount_name;
        } else {
            Output = merchant;
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };

    const TableColumns = [
        {
            field: "transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
            checkboxSelection: true,
        },
        {
            field: "calc_RefTransaction",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 2,
            sort: "desc",
            // Type: "Date",
        },
        {
            field: "Status",
            //
            headerName: "Status",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "banking_amount_transaction",
            headerName: "Amount Statement",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "communication",
            //
            headerName: "Communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            sortingOrder: ["asc", "desc"],
            // cellRenderering: StatusRendering,
            // cellStyle: PaymentCell,
        },

        {
            field: "counterparty_bankAccount_number",
            //
            headerName: "Account Number",
            headerClass: "ag-CenterHeader",
            width: 210,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountNumberRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "counterparty_bankAccount_name",
            //
            headerName: "Account Owner",
            headerClass: "ag-CenterHeader",
            width: 250,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountOwnerRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "transaction_source",
            headerName: "Source",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: true,
            // Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
    ];
    const HandleRowSelectChange = (SelectedRows) => {
        if (SelectedRows.length === 0) {
            setSelectedRows(null);
            setSelectedAmount(0);
        } else {
            let Total = 0;
            SelectedRows.forEach((elem) => {
                // console.log("elem", elem)
                Total = Total + elem.banking_amount_transaction;
            });
            // console.log("Total", Total);
            setSelectedAmount(Total);
            setSelectedRows(SelectedRows);
        }
    };
    const TableOptions = {
        Selection: {
            Multiple: true,
            // GroupOnly: false,
            WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Init, setInit] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [SelectedRows, setSelectedRows] = useState(null);
    const [SelectedAmount, setSelectedAmount] = useState(0);
    const [ExpectedAmount, setExpectedAmount] = useState(0);
    const [FormValues, setFormValues] = useState({ReconciliationNote: null});
    const [InitialFormValues, setInitialFormValues] = useState({ReconciliationNote: null});
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    // console.log("Reconciliation", Data);

                    if (Init === false) {
                        if (Data.Transactions && Array.isArray(Data.Transactions) && Data.Transactions.length > 0) {
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "Reconcile") {
                        // console.log("Reconcile Done");
                        toast.success(`Transaction Reconciled`, {autoClose: true});

                        setTimeout(() => {
                            HandleUpdateReconconcile();
                        }, 200);

                        // toast.success(`Reconciliation Done`, {autoClose: true});
                        // Ref_LocaleData.current = null;
                        // setAPICallType(null);
                        // setTimeout(() => {
                        //     setInit(false);
                        // }, 50);
                        // setTimeout(() => {
                        //     SubmitParentForm();
                        // }, 200);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;

        if (Ref_LocaleData.current) {
            FinalTableComponent = (
                <AGGridTable
                    TableName="Reconciliation"
                    Data={Ref_LocaleData.current}
                    // URLAbsolute={URLAbsolute}
                    TableColumns={TableColumns}
                    MainDataSource="Transactions"
                    // RowActionButtons={TableRowButtons}
                    // ColumnButtonStyle={ColumnButtonStyle}
                    TableOptions={TableOptions}
                    // ReloadData={ReloadTableData}
                    // SyncReloadDataStateFunction={setReloadTableData}
                    // TotalRow={TotalRow}
                />
            );
        }

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
        }, 200);
    }, [Ref_LocaleData.current]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        if (FormValuesParent) {
            let Amount = -FormValuesParent.CalcTotalVATIncluded;
            setExpectedAmount(Amount);
            const ApiCall = {
                url: `${ProdURL}/api/purchaseinvoices/reconciliation/query/?invoiceid=${InvoiceId}&airecommendation=true`,
                type: "get",
            };
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [FormValuesParent, ProdURL]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleReconcile = () => {
        let DataForm = {
            TransactionsId: null,
            InvoiceId: InvoiceId,
			ReconciliationNote : FormValues.ReconciliationNote
        };
        let SelectedIds = [];
        SelectedRows.forEach((elem) => {
            SelectedIds.push(elem._id);
        });
        DataForm.TransactionsId = SelectedIds;
        const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/reconcileinvoice`, type: "post", data: DataForm};
        setLoading(true);
        setAPICallType("Reconcile");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let SelectionComponent;

    if (SelectedRows) {
        let ActionButtonComponent = null;
        if (Math.abs(SelectedAmount) === Math.abs(ExpectedAmount)) {
            ActionButtonComponent = (
                <Button Height="28px" Width="150px" style={{marginTop: "5px"}} ClassExtendButton={classes.ClassExtendButton} onClick={HandleReconcile}>
                    Add to reconciliation
                </Button>
            );
        } else {
            ActionButtonComponent = (
                <React.Fragment>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <WarningTriangleYellow IconSize="30px" />
                        <div style={{marginLeft: "5px", color: "red"}}>Amounts don't match</div>
                    </div>
                    <div>
                        <Button Height="28px" Background="red" Width="150px" style={{marginTop: "5px"}} ClassExtendButton={classes.ClassExtendButton} onClick={HandleReconcile}>
                            Force reconciliation
                        </Button>
                    </div>
                </React.Fragment>
            );
        }
        let ReconciliationNoteComponent = (
            <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                <FormCompontent
                    Name="ReconciliationNote"
                    InitialValue={InitialFormValues.ReconciliationNote ? InitialFormValues.ReconciliationNote : null}
                    FormValue={FormValues.ReconciliationNote ? FormValues.ReconciliationNote : null}
                    OutputValue={(values) => HandleUpdateFormValues("ReconciliationNote", values)}
                    Component="Input"
                    Variant="OutlinedLabel"
                    Label="Reconciliation Note"
                    NoMessage={false}
                    // ReadOnly={true}
                    // LegendOffsetHeight="0"
                    // LegendOffsetWidth="0"
                    // FieldMaxWidth="120px"
                    // LabelMaxWidth="130px"
                    // LabelAlign="Left"
                />
            </div>
        );
        SelectionComponent = (
            <React.Fragment>
                <div className={classes.ButtonBar}>
                    <div className={classes.ButtonBar_Left}>{ActionButtonComponent}</div>
                    <div className={classes.ButtonBar_Middle}>{ReconciliationNoteComponent}</div>
                    <div className={classes.ButtonBar_Right}>
                        <div className={classes.Line}>
                            <div className={classes.Line_Title}>Expected Amount :</div>
                            <div className={classes.Line_Value}>{new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(ExpectedAmount)}</div>
                        </div>

                        <div className={classes.Line} style={{color: `${Math.abs(SelectedAmount) === Math.abs(ExpectedAmount) ? "black" : "red"}`}}>
                            <div className={classes.Line_Title}>Selected Total :</div>
                            <div className={classes.Line_Value}>{new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(SelectedAmount)}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        SelectionComponent = (
            <React.Fragment>
                <div className={classes.ButtonBar}>
                    <div className={classes.ButtonBar_Left}>
                        <div style={{textAlign: "left", marginTop: "5px"}}>Select the Invoices to reconcile</div>
                    </div>
                    <div className={classes.ButtonBar_Right}>
                        <div className={classes.Line}>
                            <div className={classes.Line_Title}>Expected Amount :</div>
                            <div className={classes.Line_Value}>{new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(ExpectedAmount)}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    return (
        <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
            <LocalCircularLoader />
            {SelectionComponent}
            <div className={classes.TableWrapper}>{TableComponent}</div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
