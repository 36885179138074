/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";

import {Company, Configuration, UploadFile, Preview, AddressBook, Contract, Offer, TableView, Dashboard, AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "30px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
        color: "white",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
}));
const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const MainRoute = "/financials/accounting/";
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCreateOrder = () => {
        History.push(`/financials/accounting/order/new/edit`);
    };
    const HandleCreateInvoice = () => {
        History.push(`/financials/purchaseordersandinvoices/invoices/invoice/new/edit`);
    };
    const HandleCreateSupplier = () => {
        History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/new/edit`);
    };

    const HandleCreateAccountChart = () => {
        // History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/new/edit`);
    };
    const HandleCreateCategory = () => {
        History.push(`/financials/purchaseordersandinvoices/categories/category/new/edit`);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            {/************* DASHOBOARD  ***************/}
            <NavSideBarLink
                ActiveLinkIndicator={true}
                Exact={false}
                Slug={`${MainRoute}dashboard`}
                //
                IsActiveFromOutside={true}
            >
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Dashboard IconSize="24" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Dashboard</p>
                    </div>
                </div>
            </NavSideBarLink>
            {/************* ACCOUNTS AND TRANSACTIONS  ***************/}
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}accountsandtransactions/accountsdahboard`}
                SlugList={[
                    `${MainRoute}accountsandtransactions/accountsdahboard`,
                    `${MainRoute}accountsandtransactions/statements`,
                    `${MainRoute}accountsandtransactions/reconciliation`,
                    `${MainRoute}accountsandtransactions/transactions`,
                ]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Contract IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accounts and Transactions</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountsandtransactions/accountsdahboard`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Dashboard</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountsandtransactions/statements`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Statements</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountsandtransactions/reconciliation`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Reconciliation</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountsandtransactions/transactions`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Transactions</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            {/************* PAYMENTS  ***************/}
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}payments`}
                SlugList={[`${MainRoute}payments/all`, `${MainRoute}payments/unreconciled`, `${MainRoute}payments/reconciled`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Contract IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Payments</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}payments/all`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>All transactions</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}payments/unreconciled`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Unreconciled transactions</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}payments/reconciled`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Reconciled transactions</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            {/************* ACCOUNTANT  ***************/}
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}accountant/dashboard`}
                SlugList={[`${MainRoute}accountant/dashboard`, `${MainRoute}accountant/justificationsmissing`, `${MainRoute}accountant/justificationtosend`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Contract IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accountant</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountant/dashboard`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Accountant Dashboard</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountant/justificationsmissing`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Missing Justifications</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}accountant/justificationtosend`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Justification to Send</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            {/************* ACCOUNTING JOURNALS  ***************/}
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}journals`}
                SlugList={[
                    `${MainRoute}journals/all`,
                    //
                    `${MainRoute}journals/generalledger`,
                    `${MainRoute}journals/bank`,
                    `${MainRoute}journals/vendorbills`,
                    `${MainRoute}journals/customerinvoices`,
                ]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Contract IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accounting Journals</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}journals/generalledger`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>General Ledger</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}journals/bank`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Banks</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}journals/vendorbills`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Vendor Bills</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}journals/customerinvoices`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Customer Invoices</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            {/************* ACCOUNTING ANALYSIS  ***************/}
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}analysis`}
                SlugList={[`${MainRoute}analysis/annualaccounts`, `${MainRoute}analysis/incomestatement`, `${MainRoute}analysis/taxes`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Contract IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accounting Analysis</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}analysis/annualaccounts`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Annual Accounts</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}analysis/incomestatement`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Income Statement</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}analysis/taxes`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Taxes</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            {/************* ACCOUNTING SETTINGS  ***************/}
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}settings`}
                SlugList={[`${MainRoute}settings/all`, `${MainRoute}settings/unreconciled`, `${MainRoute}settings/reconciled`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Configuration Frame="rounded" FrameFillColor="white" FrameStrokeColor="white" IconSize="24" SVGFillColor="black" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accounting Settings</p>
                        </div>
                    </div>
                }
            >
                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}settings/`}
                    SlugList={[`${MainRoute}suppliers/create`, `${MainRoute}suppliers/table`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Company IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Suppliers</p>
                            </div>
                        </div>
                    }
                >
                    <React.Fragment>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}suppliers/table`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <TableView IconSize="24" SVGStrokeColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Suppliers</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <NavSideBarLinkAccordionSubLink onClick={HandleCreateSupplier} ActiveLinkIndicator={true} WithTree={true}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <AddCircle IconSize="24" SVGFillColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Create supplier</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}suppliers/import`}>
                            <div className={classes.SideBarLink_Content} data-tip data-for="ImportSupplier">
                                <div className={classes.SideBarLink_Icon}>
                                    <UploadFile IconSize="24" SVGFillColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Import Suppliers</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <ReactTooltip id="ImportSupplier" place="right" type="dark" effect="solid">
                            <p className={classes.tooltipP}>Import suppliers from account transactions using the bank account number</p>
                        </ReactTooltip>
                    </React.Fragment>
                </NavSideBarLinkAccordion>

                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}settings/accountcharts`}
                    SlugList={[`${MainRoute}settings/accountcharts/create`, `${MainRoute}settings/accountcharts/tables`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Company IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Account charts</p>
                            </div>
                        </div>
                    }
                >
                    <React.Fragment>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}settings/accountcharts/tables`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <TableView IconSize="24" SVGStrokeColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Account charts</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <NavSideBarLinkAccordionSubLink onClick={HandleCreateAccountChart} ActiveLinkIndicator={true} WithTree={true}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <AddCircle IconSize="24" SVGFillColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Create Account chart</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                    </React.Fragment>
                </NavSideBarLinkAccordion>
                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}settings/accountingtaxes`}
                    SlugList={[`${MainRoute}settings/accountingtaxes/create`, `${MainRoute}settings/accountingtaxes/tables`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Company IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Accounting taxes charts</p>
                            </div>
                        </div>
                    }
                >
                    <React.Fragment>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}settings/accountingtaxes/tables`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <TableView IconSize="24" SVGStrokeColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Accounting Taxes</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <NavSideBarLinkAccordionSubLink onClick={HandleCreateAccountChart} ActiveLinkIndicator={true} WithTree={true}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <AddCircle IconSize="24" SVGFillColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Create Accounting taxes</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                    </React.Fragment>
                </NavSideBarLinkAccordion>
                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}settings/accountingpaymentterms`}
                    SlugList={[`${MainRoute}settings/accountingpaymentterms/create`, `${MainRoute}settings/accountingpaymentterms/table`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Company IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Payment Terms</p>
                            </div>
                        </div>
                    }
                >
                    <React.Fragment>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}settings/accountingpaymentterms/table`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <TableView IconSize="24" SVGStrokeColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Payment Terms</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}settings/accountingpaymentterms/paymentterm/new`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <AddCircle IconSize="24" SVGFillColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Create Payment Terms</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                    </React.Fragment>
                </NavSideBarLinkAccordion>
                <NavSideBarLinkAccordion
                    Slug={`${MainRoute}settings/accountingjournals`}
                    SlugList={[`${MainRoute}settings/accountingjournals/accountingjournal/create`, `${MainRoute}settings/accountingjournals/tables`]}
                    ActiveLinkIndicator={true}
                    WithTree={true}
                    OpenOnClick={true}
                    ControllerContent={
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Company IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Accounting Journals</p>
                            </div>
                        </div>
                    }
                >
                    <React.Fragment>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}settings/accountingjournals/tables`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <TableView IconSize="24" SVGStrokeColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Journals</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                        <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}settings/accountingjournals/accountingjournal/new`}>
                            <div className={classes.SideBarLink_Content}>
                                <div className={classes.SideBarLink_Icon}>
                                    <AddCircle IconSize="24" SVGFillColor="white" />
                                </div>
                                <div className={classes.SideBarLink_Hidable_Wrapper}>
                                    <p className={classes.SideBarLink_Content_Text}>Create Journal</p>
                                </div>
                            </div>
                        </NavSideBarLinkAccordionSubLink>
                    </React.Fragment>
                </NavSideBarLinkAccordion>
            </NavSideBarLinkAccordion>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
