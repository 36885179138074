import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";

import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import TabNavContent from "../../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import Table from "../../../../../../../../artibulles-cis/Table/Table";
import {UploadFile, Trash} from "@artibulles-cis/react-icons";
import {toast} from "react-toastify";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 15px 10px 15px",
        display: "flex",
        width: "100%",
        // maxWidth: "1600px",
        // width: "100%",
        // maxHeight: "800px",
        // height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const AccountDuplicateTransactions = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const Ref_TabNavContent = useRef();
    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";

    const ColomnsInput = [
        {width: "150px", caption: "Company Account", datafield: "myCompany_bankAccount_number", fixedCol: false, searchable: true, sortable: false, datatype: "text"},
        {width: "100px", caption: "Amount", datafield: "amount", fixedCol: false, searchable: true, datatype: "text"},
        {width: "250px", caption: "Date", datafield: "booking_date_Iso", searchable: true, datatype: "date"},
        {width: "100px", caption: "Balance", datafield: "balance_after", searchable: true, datatype: "number"},
        {width: "auto", caption: "Comm. Structured", datafield: "communication_structured", searchable: true, datatype: "text"},
        {width: "300px", caption: "Comm. Free", datafield: "communication_freeFormat", searchable: true, datatype: "text"},
        {width: "300px", caption: "Counterparty Name", datafield: "counterparty_bankAccount_name", searchable: true, datatype: "text"},
        {width: "300px", caption: "Counterparty Bank Account", datafield: "counterparty_bankAccount_number", searchable: true, datatype: "text"},
    ];

    const HandleConditionalClickRow = (args) => {
        return true;
    };

    const HandleClickRow = (args) => {
        let DupData = args.rowData.related_transactions;
        if (DupData && DupData.length > 0) {
            let DuplicatedTableData = {
                Transactions: DupData,
            };
            setLocalTableDataDuplicate(DuplicatedTableData);
        }
        return args.RowId;
    };
    const HandleUploadDuplicateToMain = (args) => {
        var DuplicateId;

        if (args && args.rowData) {
            DuplicateId = args.rowData._id;
        }

        const ApiCall = {url: `${ProdURL}/api/companyaccount/bankaccounts/accountduplicatetransaction/upload/${DuplicateId}`, type: "get"};
        setLoading(true);
        setApiCallType("Duplicate");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    const HandleDeleteDuplicate = (args) => {
        console.log("delete", args);
    };
    const TableOptionsOriginal = {
        Searchable: false,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        PagerOptions: {
            Visible: true,
            DefaultPageSize: 5,
            AllowedPageSizes: [5, 10, 20],
        },
        Selectable: false,
        RowClickable: true,
        ClickRow: {
            Action: (args) => HandleClickRow(args),
            Condition: (args) => HandleConditionalClickRow(args),
        },
        RowButtons: {
            Custom: [
                {
                    Name: "Upload",
                    ShowCondition: null,
                    Action: HandleUploadDuplicateToMain,
                    Icon: <UploadFile IconSize="25px" />,
                },
                {
                    Name: "Delete",
                    ShowCondition: null,
                    Action: HandleDeleteDuplicate,
                    Icon: <Trash IconSize="25px" />,
                },
            ],
            Standard: {
                // Edit: {Condition: null, Position: 1, OnClick: HandleClickRow},
                //         // View: {Condition: null, Position: 2, OnClick: HandlePreviewRow},
                //         // Delete: {Condition: deletecondition, Position: 3, OnClick: null},
            },
        },
    };

    const TableOptionsDuplicate = {
        Searchable: false,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        PagerOptions: {
            Visible: true,
            DefaultPageSize: 5,
            AllowedPageSizes: [5, 10, 20],
        },
        Selectable: false,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [ApiCallType, setApiCallType] = useState("Init");
    const [LocalTableData, setLocalTableData] = useState(null);
    const [LocalTableDataDuplicate, setLocalTableDataDuplicate] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiCallType === "Init") {
                    if (ApiRes.data && ApiRes.data.data) {
                        setLocalTableData(ApiRes.data.data);
                    }
                } else if (ApiCallType === "Duplicate") {
                    if (ApiRes.data) {
                        if (ApiRes.data.message === "duplicated") {
                            //Toast success

                            toast.success("Upload successful");
                            setTimeout(() => {
                                setLocalTableData(null);
                                setLocalTableDataDuplicate(null);
                                setApiCallType("Init");
                            }, 100);
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (ApiCallType === "Init") {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/bankaccounts/accountduplicatetransactions`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, ApiCallType]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var TableComponent;
    if (LocalTableData) {
        TableComponent = (
            <Table
                LocalData={LocalTableData}
                TableDimensions={{
                    FitParentContainer: true,
                    FitContent: true,
                    height: "150px",
                    //
                }}
                MainDataSource="Transactions"
                ColomnsInput={ColomnsInput}
                TableOptions={TableOptionsOriginal}
                DevMode={true}
            />
        );
    } else {
        TableComponent = null;
    }
    var TableComponentDuplicate;
    if (LocalTableDataDuplicate) {
        TableComponentDuplicate = (
            <Table
                LocalData={LocalTableDataDuplicate}
                TableDimensions={{
                    FitParentContainer: false,
                    FitContent: false,
                    height: "150px",
					// width : "600px"
                    //
                }}
                MainDataSource="Transactions"
                ColomnsInput={ColomnsInput}
                TableOptions={TableOptionsDuplicate}
                DevMode={false}
            />
        );
    } else {
        TableComponentDuplicate = null;
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
            <h2>Duplicates</h2>
            <p>Select a row to show the original DB record</p>
            <p>Chose what to do with the duplicate (delete or add to DB)</p>

            <div className={classes.TableWrapper}>{TableComponent}</div>
            <h2>Current DB record that correspond to the duplicate</h2>

            <div className={classes.TableWrapper}>{TableComponentDuplicate}</div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default AccountDuplicateTransactions;
