import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import PerfectScrollBarWrapper from "../PerfectScrollBarWrapper/PerfectScrollBarWrapper";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {
        PageContentWithScroll: {
            position: "relative",
            overflowX: props.WithPerfectScrollBar === true ? "hidden" : props.WithScrollbar ? (props.WithScrollbar.X ? props.WithScrollbar.X : null) : null,
            overflowY: props.WithPerfectScrollBar === true ? "hidden" : props.WithScrollbar ? (props.WithScrollbar.Y ? props.WithScrollbar.Y : null) : null,
            fontFamily: props.FontStyle ? (props.FontStyle.fontFamily ? props.FontStyle.fontFamily : null) : null,
            fontSize: props.FontStyle ? (props.FontStyle.fontSize ? props.FontStyle.fontSize : null) : null,
            fontWeight: props.FontStyle ? (props.FontStyle.fontWeight ? props.FontStyle.fontWeight : null) : null,
            color: props.FontStyle ? (props.FontStyle.color ? props.FontStyle.color : null) : null,
        },
    };
};

//eslint-disable-next-line
const Lockedstyles = {
    Page: {
        position: "relative",
        height: "100%",
        backgroundColor: "green",
        display: "flex",
        flexDirection: "column",
    },
    PageContent: {
        height: "100%",
        boxSizing: "border-box",
    },
};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Page: {},
    PageContent: theme.PageContent,
    PageContentWithScroll: theme.PageContentWithScroll,
    PageTopBarWrapper: {},
    PageBottomBarWrapper: {},
}));

/**
 * Create a page Component that works in conjuction with the overall app layout,
 * Very usefull if you want to add top bar and bottom bar for a specific page or for the entire layout
 * compatible with sidebars
 */

const Page = React.forwardRef(function Page(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        id,
        PageRef,
        TopBarComponent,
        BottomBarComponent,
        //eslint-disable-next-line
        WithScrollbar,
        WithPerfectScrollBar,
        BackgroundColor,
        //eslint-disable-next-line
        FontStyle,
        ClassExtendsPage,
        ClassExtendsPageContent,
        PagePadding,
        //Dont fold
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    const IntPageRef = useRef(null);
    
    var FinalPageRef;
    if (id || PageRef) {
        if (PageRef) {
            FinalPageRef = PageRef;
        } else {
            FinalPageRef = id;
        }
    } else {
        FinalPageRef = IntPageRef;
    }
    const TopBarComponentRef = useRef(null);
    const BottomBarComponentRef = useRef(null);
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [PageContentHeight, setPageContentHeight] = useState();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const calculatePageHeight = useCallback(() => {
        var parentNode;
        if (typeof FinalPageRef === "object" && FinalPageRef.current) {
            parentNode = FinalPageRef.current.parentNode;
        } else {
            parentNode = document.getElementById(FinalPageRef).parentNode;
        }
        if (parentNode) {
            var parentHeight, topBarHeight, bottomBarHeight, ContentHeight;
            //Calculate the content page height

            parentHeight = parentNode.getBoundingClientRect().height;

            if (TopBarComponent && TopBarComponentRef && TopBarComponentRef.current) {
                topBarHeight = TopBarComponentRef.current.getBoundingClientRect().height;
            } else {
                topBarHeight = 0;
            }
            if (BottomBarComponent && BottomBarComponentRef && BottomBarComponentRef.current) {
                bottomBarHeight = BottomBarComponentRef.current.getBoundingClientRect().height;
            } else {
                bottomBarHeight = 0;
            }
            if (TopBarComponent || BottomBarComponent) {
                ContentHeight = parseFloat(parentHeight) - parseFloat(topBarHeight) - parseFloat(bottomBarHeight) + "px";
            } else {
                ContentHeight = "100%";
            }

            setPageContentHeight(ContentHeight);
        }
    }, [FinalPageRef, TopBarComponentRef, BottomBarComponentRef, BottomBarComponent, TopBarComponent]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    useEffect(() => {
        calculatePageHeight();
    }, [calculatePageHeight]);

    useEffect(() => {
        if (BottomBarComponent || TopBarComponent) {
            window.addEventListener("resize", calculatePageHeight);
            return function cleanup() {
                window.removeEventListener("resize", calculatePageHeight);
            };
        }
    }, [BottomBarComponent, TopBarComponent, calculatePageHeight]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var TopBarComponentFinal, BottomBarComponentFinal;

    if (TopBarComponent) {
        TopBarComponentFinal = (
            <div className={classes.PageTopBarWrapper} ref={TopBarComponentRef}>
                {TopBarComponent}
            </div>
        );
    } else {
        TopBarComponentFinal = null;
    }

    if (BottomBarComponent) {
        BottomBarComponentFinal = (
            <div className={classes.PageBottomBarWrapper} ref={BottomBarComponentRef}>
                {BottomBarComponent}
            </div>
        );
    } else {
        BottomBarComponentFinal = null;
    }

    return (
        <div id={id ? id : null} ref={!id ? FinalPageRef : null} className={classes.Page} style={{height: "100%", overflow: "hidden"}}>
            {TopBarComponentFinal}
            <div
                className={clsx(classes.PageContentWithScroll, ClassExtendsPage)}
                style={{...LockedstylesWithProps(props).PageContentWithScroll, ...{height: PageContentHeight, backgroundColor: BackgroundColor ? BackgroundColor : null}}}
            >
                <PerfectScrollBarWrapper Active={WithPerfectScrollBar}>
                    <div className={clsx(classes.PageContent, ClassExtendsPageContent)} style={{...Lockedstyles.PageContent, ...{padding: PagePadding ? PagePadding : null}}}>
                        {children}
                    </div>
                </PerfectScrollBarWrapper>
            </div>
            {BottomBarComponentFinal}
        </div>
    );
    /****************************** RENDER *********************/
});

Page.defaultProps = {
	id: null,
	PageRef : null,
    TopBarComponent: null,
    BottomBarComponent: null,
    WithScrollbar: {X: "hidden", Y: "hidden"},
    WithPerfectScrollBar: false,
    BackgroundColor: null,
    PagePadding: "0px",
    FontStyle: null,
    ClassExtendsPage: null,
    ClassExtendsPageContent: null,
};

Page.propTypes = {
    /**
     * id : Page Id
     * If you need to use the ref of that page somewhere else you can pass the id and it will create the ref using that id
     * You cannot pass a ReactR.Ref but. It needs to be a string
     */
	id: PropTypes.any,
	/**
     * id : Page Id
     * If you need to use the ref of that page somewhere else you can pass the id and it will create the ref using that id
     * You cannot pass a ReactR.Ref but. It needs to be a string
     */
    PageRef: PropTypes.any,
    /**
     * TopBarComponent : TopBar Element
     */
    TopBarComponent: PropTypes.any,
    /**
     * BottomBarComponent : Bottom Bar Element
     */
    BottomBarComponent: PropTypes.any,

    /**
     * BottomBarComponent : Bottom Bar Element
     */
    WithScrollbar: PropTypes.any,
    /**
     * BottomBarComponent : Bottom Bar Element
     */
    WithPerfectScrollBar: PropTypes.any,
    /**
     * BottomBarComponent : Bottom Bar Element
     */
    BackgroundColor: PropTypes.any,
    /**
     * Note : padding is not set in the theme because if you imbricate pages, the padding will be added to all pages and increase the real
     * desired padding
     */
    PagePadding: PropTypes.any,
    /**
     * BottomBarComponent : Bottom Bar Element
     */
    FontStyle: PropTypes.any,
    /**
     * BottomBarComponent : Bottom Bar Element
     */
    ClassExtendsPage: PropTypes.any,
    /**
     * BottomBarComponent : Bottom Bar Element
     */
    ClassExtendsPageContent: PropTypes.any,
};

export default Page;
