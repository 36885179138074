/* eslint-disable */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import {isMobileOnly, isTablet, isBrowser, browserName, osName, osVersion, browserVersion, fullBrowserVersion} from "react-device-detect";
import {useCookies} from "react-cookie";
import {useLocation} from "react-router-dom";

import useWebsiteAppInitContext from "../utils/context/websiteAppInitContext/useWebsiteAppInitContext";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const WebsiteAppInitComponent = React.memo(
    function WebsiteAppInitComponent(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {
            NoResizeCalc,
            appMaxWidth,
            mobileWidth,
            GeoLocation,
            withCookies,
            animationIntro,
            animationTime,
            AppMainComponent,
            MobileComponent,
            DesktopComponent,
            SiteCookiesList,
            CookiesComponent,
            HomePageAnimationComponent,
        } = props;

        const theme = useTheme();
        //eslint-disable-next-line
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** STATE *********************/

        const [IsMobile, setIsMobile] = useState(null);
        const [DeviceType, setDeviceType] = useState({
            deviceType: null,
            osName: osName,
            browserName: browserName,
            osVersion: osVersion,
            browserVersion: browserVersion,
            fullBrowserVersion: fullBrowserVersion,
        });
        const [WinDim, setWinDim] = useState({width: null, height: null});
        const [ShowAnimation, setShowAnimation] = useState(true);

        const [cookies, setCookie] = useCookies(null);
        const {updateConfigParameters, updateGeoLocation, updateClientWindowDimensions, updateDeviceInformation, updatePrivacyCookies, updateSiteCookiesList} = useWebsiteAppInitContext();
        const Location = useLocation();
        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        const CalculateWindowDimensions = useCallback(() => {
            // console.log("calculating window dimensions");
            var width = window.innerWidth;
            var height = window.innerHeight;
            setWinDim({width: width, height: height});
            var UpdatedMobile;

            if (mobileWidth === undefined || mobileWidth === null) {
                if (DeviceType.deviceType === "Browser") {
                    UpdatedMobile = false;
                } else {
                    UpdatedMobile = true;
                }
            } else if (DeviceType.deviceType === "Browser" || DeviceType.deviceType === "tablet") {
                if (parseInt(width) <= parseInt(mobileWidth)) {
                    UpdatedMobile = true;
                } else {
                    UpdatedMobile = false;
                }
            } else {
                UpdatedMobile = true;
            }

            //Only update if the state has changed -> Prevent rerendering the full app all the times

            if (IsMobile !== UpdatedMobile) {
                setIsMobile(UpdatedMobile);
            }
        }, [IsMobile, mobileWidth, DeviceType]);

        //eslint-disable-next-line
        const updateClientWindowDimensionsCallBack = useCallback(() => {
            let clientWindowDimensionsUpdate = {
                isMobile: IsMobile,
                windowWidth: WinDim.width,
                windowHeight: WinDim.height,
            };

            updateClientWindowDimensions(clientWindowDimensionsUpdate);
        }, [IsMobile, WinDim]);

        //eslint-disable-next-line
        const updateDeviceInformationCallBack = useCallback(() => {
            updateDeviceInformation(DeviceType);
        }, [DeviceType]);

        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        //WHEN COMPONENT LOADS
        useEffect(() => {
            var userLang = navigator.language || navigator.userLanguage;
            let configParameters = {
                appMaxWidth: appMaxWidth,
                mobileWidth: mobileWidth,
                GeoLocation: GeoLocation,
                withCookies: withCookies,
                animationIntro: animationIntro,
                animationTime: animationTime,
            };
            updateConfigParameters(configParameters);
            updateGeoLocation({browserLanguage: userLang});
            //Execute your code here
        }, []);

        //Adding device detection when the component mounts
        useEffect(() => {
            let Device = isMobileOnly ? "mobile" : isTablet ? "tablet" : isBrowser ? "Browser" : "Other";
            setDeviceType({deviceType: Device, osName: osName, browserName: browserName, osVersion: osVersion, browserVersion: browserVersion, fullBrowserVersion: fullBrowserVersion});
        }, []);
        //Create the list of site cookies from the configuration file
        useEffect(() => {
            updateSiteCookiesList(SiteCookiesList);
        }, []);

        useEffect(() => {
            if (Object.keys(cookies).length === 0) {
                //No Cookies yet
            } else {
                //Read the cookies and update the Context Store
                GetCookiesPreferences();
            }
        }, []);

        //Adding Resizing Listener when the component mounts
        useEffect(() => {
            if (NoResizeCalc === false) {
                CalculateWindowDimensions();
                // const debouncedHandleResize = debounce(function () {
                //     CalculateWindowDimensions();
                // }, 1000);
                // window.addEventListener("resize", debouncedHandleResize);
                // return (_) => {
                //     window.removeEventListener("resize", debouncedHandleResize);
                // };

                const ThrottleHandleResize = throttle(function () {
                    CalculateWindowDimensions();
                }, 1000);
                window.addEventListener("resize", ThrottleHandleResize);
                return () => {
                    window.removeEventListener("resize", ThrottleHandleResize);
                };
            }else {
				//We just do it once
				CalculateWindowDimensions();
			}
        }, [CalculateWindowDimensions, NoResizeCalc]);

        useEffect(() => {
            updateClientWindowDimensionsCallBack();
        }, [updateClientWindowDimensionsCallBack]);

        useEffect(() => {
            updateDeviceInformationCallBack();
        }, [updateDeviceInformationCallBack]);

        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        //Frequency at wich to update the window size (prevent rerunning the function too many times)
        function debounce(fn, ms) {
            let timer;
            return (_) => {
                clearTimeout(timer);
                timer = setTimeout((_) => {
                    timer = null;
                    fn.apply(this, arguments);
                }, ms);
            };
        }
        function throttle(fn, ms) {
            let shouldWait = false;

            return function (...args) {
                if (!shouldWait) {
                    fn.apply(this, args);

                    shouldWait = true;

                    setTimeout(function () {
                        shouldWait = false;
                    }, ms);
                }
            };
        }

        const GetCookiesPreferences = () => {
            //Find the privacy acceptance cookie Name from the list
            var privacyCookieName, visitedCookieName;
            if (SiteCookiesList && SiteCookiesList.length > 0) {
                SiteCookiesList.forEach((elem) => {
                    if (elem.type === "Mandatory") {
                        elem.content.forEach((subelem) => {
                            if (subelem.type === "privacy") {
                                privacyCookieName = subelem.name;
                            } else if (subelem.type === "visited") {
                                if (subelem.value === true) {
                                    setShowAnimation(false);
                                }
                            }
                        });
                    }
                });
            }

            //Find the privacy cookieName in the list of Browser cookies
            let BrowserPrivacyCookie = cookies[privacyCookieName];
            if (BrowserPrivacyCookie && BrowserPrivacyCookie.shown === true && BrowserPrivacyCookie.accepted === true) {
                //update the context with cookies
                updatePrivacyCookies({shown: true, accepted: true});
            }
        };
        const setCookies = (name, value, expirationDays) => {
            if (!expirationDays || expirationDays === 0 || expirationDays === "session") {
                setCookie(name, value, {sameSite: true});
            } else {
                let maxAge = expirationDays * 24 * 60 * 60;
                setCookie(name, value, {maxAge: maxAge, sameSite: true});
            }
        };

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/

        var FinalAnimationComponent;

        if (animationIntro === true) {
            //get the visited cookie
            var SiteVisitedCookieName, SiteVisited, SiteVisitedCookie;
            SiteCookiesList.forEach((elem) => {
                if (elem.type === "Mandatory") {
                    elem.content.forEach((subelem) => {
                        if (subelem.type === "visited") {
                            SiteVisitedCookieName = subelem.name;
                            SiteVisitedCookie = subelem;
                        }
                    });
                }
            });
            if (Object.keys(cookies).length === 0) {
                SiteVisited = false;
            } else {
                if (SiteVisitedCookie.value === true) {
                    SiteVisited = true;
                }
            }
            if (SiteVisited === false) {
                if (Location.pathname === "/") {
                    if (HomePageAnimationComponent) {
                        if (ShowAnimation === true) {
                            if (animationTime) {
                                //Display the Animation Component

                                setTimeout(() => {
                                    setShowAnimation(false);
                                    setCookies(SiteVisitedCookie.name, SiteVisitedCookie.value, SiteVisitedCookie.expirationDays);
                                }, animationTime);
                                FinalAnimationComponent = <HomePageAnimationComponent />;
                            } else {
                                FinalAnimationComponent = <div>Error AnimationTime not defined</div>;
                            }
                        }
                    } else {
                        FinalAnimationComponent = <div>Error Animation is missing</div>;
                    }
                } else {
                    FinalAnimationComponent = null;
                }
            } else {
                FinalAnimationComponent = null;
            }
        } else {
            FinalAnimationComponent = null;
        }

        var FinalCookieComponent;

        if (CookiesComponent) {
            if (animationIntro === true && ShowAnimation === true) {
                FinalCookieComponent = null;
            } else {
                FinalCookieComponent = <CookiesComponent />;
            }
        } else {
            FinalCookieComponent = null;
        }

        var MainComponentFinal;
        if (AppMainComponent) {
            if (animationIntro === true && ShowAnimation === true) {
                return null;
            } else {
                MainComponentFinal = <AppMainComponent />;
            }
        } else {
            MainComponentFinal = null;
        }

        var DeskMobComponentFinal;
        if (!MobileComponent || !DesktopComponent) {
            return (
                <div>
                    <h1 style={{color: "red"}}>AppLayoutInformation</h1>
                    <p>You didn't pass correct components</p>
                </div>
            );
        } else {
            if (animationIntro === true && ShowAnimation === true) {
                DeskMobComponentFinal = null;
            } else {
                DeskMobComponentFinal = <React.Fragment>{IsMobile === null ? <DesktopComponent /> : IsMobile === true ? <MobileComponent /> : <DesktopComponent />}</React.Fragment>;
            }
        }

        return (
            <React.Fragment>
                
                {FinalAnimationComponent}
                {FinalCookieComponent}
                {MainComponentFinal}
                {DeskMobComponentFinal}
            </React.Fragment>
        );
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        // const {
        //     NoResizeCalc,
        //     appMaxWidth,
        //     mobileWidth,
        //     GeoLocation,
        //     withCookies,
        //     animationIntro,
        //     animationTime,
        //     AppMainComponent,
        //     MobileComponent,
        //     DesktopComponent,
        //     SiteCookiesList,
        //     CookiesComponent,
        //     HomePageAnimationComponent,
        // } = props;
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

WebsiteAppInitComponent.defaultProps = {
    NoResizeCalc: false,
    appMaxWidth: null,
    mobileWidth: null,
    GeoLocation: null,
    withCookies: false,
    animationIntro: false,
    animationTime: null,
    AppMainComponent: null,
    MobileComponent: null,
    DesktopComponent: null,
    SiteCookiesList: null,
    CookiesComponent: null,
    HomePageAnimationComponent: null,
};

WebsiteAppInitComponent.propTypes = {
    /**
     * NoResizeCalc :
     */
    NoResizeCalc: PropTypes.bool,
    /**
     * appMaxWidth :
     */
    appMaxWidth: PropTypes.string,
    /**
     * mobileWidth :
     */
    mobileWidth: PropTypes.string,
    /**
     * GeoLocation :
     */
    GeoLocation: PropTypes.bool,
    /**
     * withCookies :
     */
    withCookies: PropTypes.bool,
    /**
     * animationIntro :
     */
    animationIntro: PropTypes.bool,
    /**
     * animationTime :
     */
    animationTime: PropTypes.any,
    /**
     * AppMainComponent :
     */
    AppMainComponent: PropTypes.any,
    /**
     * MobileComponent :
     */
    MobileComponent: PropTypes.any,
    /**
     * DesktopComponent :
     */
    DesktopComponent: PropTypes.any,
    /**
     * SiteCookiesList :
     */
    SiteCookiesList: PropTypes.any,
    /**
     * CookiesComponent :
     */
    CookiesComponent: PropTypes.any,
    /**
     * HomePageAnimationComponent :
     */
    HomePageAnimationComponent: PropTypes.any,
};

export default WebsiteAppInitComponent;
