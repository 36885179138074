import {useContext} from "react";
import {WebsiteAppInitContext} from "./websiteAppInitContext";

const useWebsiteAppInitContext = () => {
    const [state, setState] = useContext(WebsiteAppInitContext);

    function updateConfigParameters(args) {
        let appConfigParameters = {...state.appConfigParameters, ...args};
        setState((state) => ({...state, ...{appConfigParameters: appConfigParameters}}));
    }
    function updateGeoLocation(args) {
        let geoLocation = {...state.geoLocation, ...args};
        setState((state) => ({...state, ...{geoLocation: geoLocation}}));
    }
    function updateClientWindowDimensions(args) {
        let clientWindowDimensions = {...state.clientWindowDimensions, ...args};
        setState((state) => ({...state, ...{clientWindowDimensions: clientWindowDimensions}}));
    }
    function updateDeviceInformation(args) {
        let clientDeviceInfo = {...state.clientDeviceInfo, ...args};
        setState((state) => ({...state, ...{clientDeviceInfo: clientDeviceInfo}}));
    }

    function updatePrivacyCookies(args) {
        let privacyCookies = {...state.privacyCookies, ...args};
        setState((state) => ({...state, ...{privacyCookies: privacyCookies}}));
    }

    function updateSiteCookiesList(args) {
        setState((state) => ({...state, ...{siteCookiesList: args}}));
    }

    return {
        updateConfigParameters: updateConfigParameters,
        updateGeoLocation: updateGeoLocation,
        updateClientWindowDimensions: updateClientWindowDimensions,
        updateDeviceInformation: updateDeviceInformation,
        updatePrivacyCookies: updatePrivacyCookies,
        updateSiteCookiesList: updateSiteCookiesList,
        websiteAppInitContextState: state,
    };
};

export default useWebsiteAppInitContext;
