import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

import ProjectSpendingMain from "./ProjectSepending/ProjectSpendingMain";
import WeeklyTimeSheet from "./Timesheets/WeeklyTimeSheet/WeeklyTimeSheet";
import TimeEntriesTabNavRouter from "./TimeEntriesTables/TimeEntriesTabNavRouter";
import TimeEntryEditNew from "./TimeEntryEditNew/TimeEntryEditNew";

// import TrackTimeNewAndTrackRedirect from "../TaskManagement/TimeEntryAndTracking/TrackTimeNewAndTrackRedirect";
// import TimeTrackingClockView from "../TaskManagement/TimeEntryAndTracking/TimeTrackingClock/TimeTrackingClockView";

import TrackTimeNewAndTrackRedirect from "./TimeEntryAndTracking/TrackTimeNewAndTrackRedirect";
import TimeTrackingClockView from "./TimeEntryAndTracking/TimeTrackingClock/TimeTrackingClockView";

import DailyTimeSheets from "./DailyTimeSheets/DailyTimeSheets";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const TaskManagementRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path="/tasktimemanagement/times/">
                <Redirect to="/tasktimemanagement/times/dailytimesheet" />
            </Route>
            <Route exact path="/tasktimemanagement/times/dailytimesheet">
                <DailyTimeSheets />
            </Route>
            <Route path="/tasktimemanagement/times/timeentry/:id">
                <TimeEntryEditNew />
            </Route>
            <Route exact path="/tasktimemanagement/times/time/newtimentryandtrack">
                <TrackTimeNewAndTrackRedirect />
            </Route>
            <Route exact path="/tasktimemanagement/times/time/viewclock">
                <TimeTrackingClockView />
            </Route>

            <Route path="/tasktimemanagement/times/alltimeentries">
                <TimeEntriesTabNavRouter />
            </Route>
            <Route path="/tasktimemanagement/times/timesheets/weekly">
                <WeeklyTimeSheet />
            </Route>
            <Route path="/tasktimemanagement/times/projectspending">
                <ProjectSpendingMain />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
};

export default TaskManagementRouter;
// <Route exact path="/tasktimemanagement/tasks/time/addentryandtrack">
//                 <TimeCreateEntryMain />
//             </Route>
//             <Route exact path="/tasktimemanagement/tasks/time/manualentry">
//                 <TimeAddManualEntry />
//             </Route>
//             <Route exact path="/tasktimemanagement/tasks/time/viewtimetrack">
//                 <DailyOrganizerTimeTracking />
//             </Route>
