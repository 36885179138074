import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "../../../../../../../../../../artibulles-cis/TabNav/TabNav";
import InvoiceItemsTable from "../GenerateInvoiceLines/InvoiceItemsTable";
import Details from "./Details";
import GeneralConfiguration from "./GeneralConfiguration";
import GraphicControl from "./GraphicControl";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
        cursor: "pointer",
    },
}));

const Component = React.memo(function Component(props) {
    const {SaleInvoiceID, FieldOptions, FormViewMode, FormValues, HandleUpdateTableLine, HandleReloadPDF, HandleFormFieldChange, HandleSubmitForm, CustomerAccountingPreference} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleChangeTab = (CurrentIndex, NewIndex) => {
        // console.log("Change Tab", CurrentIndex, NewIndex);
        // if (CurrentIndex === 2 || CurrentIndex === 3 || CurrentIndex === 4) {
        // 	console.log("TabNav Changed")
        // 	HandleSubmitForm()
        // }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            NoLink={true}
            HandleChangeTab={HandleChangeTab}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Invoice Items</div>
                        </div>
                    ),

                    TabContent: (
                        <InvoiceItemsTable
                            FormViewMode={FormViewMode}
                            SaleInvoiceID={SaleInvoiceID}
                            SubmitParentForm={HandleUpdateTableLine}
                            FormValues={FormValues}
                            FieldOptionsParent={FieldOptions}
                            PDFUploadedUpdate={HandleReloadPDF}
                            CustomerAccountingPreference={CustomerAccountingPreference}
                        />
                    ),
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Details</div>
                        </div>
                    ),

                    TabContent: <Details InitalFormValuesFromParent={FormValues} FieldOptions={FieldOptions} HandleFormFieldChange={HandleFormFieldChange} HandleSubmitForm={HandleSubmitForm} />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>General Configuration</div>
                        </div>
                    ),

                    TabContent: (
                        <GeneralConfiguration InitalFormValuesFromParent={FormValues} FieldOptions={FieldOptions} HandleFormFieldChange={HandleFormFieldChange} HandleSubmitForm={HandleSubmitForm} />
                    ),
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Graphic Control</div>
                        </div>
                    ),

                    TabContent: (
                        <GraphicControl InitalFormValuesFromParent={FormValues} FieldOptions={FieldOptions} HandleFormFieldChange={HandleFormFieldChange} HandleSubmitForm={HandleSubmitForm} />
                    ),
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
