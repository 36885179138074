import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import CardModal from "@artibulles-cis/react/CardModal";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import {RotateRight, Preview} from "@artibulles-cis/react-icons";
import {format, parseISO} from "date-fns";
import AccountingOverviewSyncAccount from "./AccountingOverviewSyncAccount";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    FormField_WithIcon: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        alignItems: "center",
    },
    FormField_Icon: {
        marginBottom: "15px",
        marginRight: "5px",
        width: "35px",
        height: "35px",
    },
    ClassExtendsContentOpened: {
        padding: "0px",
    },
    ExpandableTitle: {
        margin: "0px",
    },
    Balance_Info_Wrapper: {
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        width: "100%",
        maxWidth: "800px",
        padding: "12px",
        height: "120px",
    },
    Balance_Wrapper: {
        flex: "0 0 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid black",
        borderRadius: "5px",
        fontWeight: "500",
        fontSize: "1.5em",
        height: "100%",
    },
    Upload_Wrapper: {
        flex: "0 0 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    Info_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        height: "100%",
        padding: "10px 10px 10px 30px",
    },
    Info_Wrapper_Lines: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    MonthlyOverview_Wrapper: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 30px 10px 30px",
        "& h3": {
            margin: "8px 0px",
        },
    },

    Monthly_Line_Wrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        boxSizing: "border-box",
    },
    Balance_Detail: {
        width: "150px",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid black",
        borderRadius: "5px",
        fontWeight: "400",
        fontSize: "1.2em",
        height: "70px",
    },
    Monthly_Line: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        justifyContent: "center",
        maxWidth: "800px",
        padding: "5px 20px",
    },
    Monthly_Line_Info: {
        flex: "1 1 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "5px 10px 5px 10px",
    },
    Monthly_Line_Info_Preview: {
        flex: "0 0 30px",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 10px",
    },
}));

const AccoutingOverview = React.memo(function AccoutingOverview(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [RefreshApi, setRefreshApi] = useState(true);
    const [InitialFormValues, setInitialFormValues] = React.useState({
        current_date: Date.now(),
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = useState({}); //FINAL FORM VALUES
    const [AccountData, setAccountData] = useState(null);
    const [FieldOptions, setFieldOptions] = useState({}); //FORM OPTIONS FOR SELECT
    const [ShowCardSyncAccount, setShowCardSyncAccount] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);

                    //Updating the required template fields
                    let DataAccount = Data;
                    const FinalAccountData = {
                        Balance: null,
                        LastSync: null,
                        LastTransactionDate: null,
                        TotalUnreconciled: null,
                    };
                    if (DataAccount) {
                        FinalAccountData.Balance = DataAccount.balance ? CurrencyConversion(DataAccount.balance, "EUR", "de", "DE") : "No information";
                        FinalAccountData.LastSync = DataAccount.last_sync_dateIso ? format(parseISO(DataAccount.last_sync_dateIso), "dd-MMM-yyyy HH:MM:ss") : "no information";
                        FinalAccountData.LastTransactionDate = DataAccount.last_transaction_dateIso ? format(parseISO(DataAccount.last_transaction_dateIso), "dd-MMM-yyyy HH:MM:ss") : "no information";
                        FinalAccountData.total_Accounting_ToHandle = DataAccount.total_Accounting_ToHandle ? DataAccount.total_Accounting_ToHandle : "no information";
                    }

                    setAccountData(FinalAccountData);
                    setRefreshApi(false);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (RefreshApi === true) {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/accountoverview`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, RefreshApi]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const CurrencyConversion = (number, currency, language, format, digits) => {
        var FinalCurrency = currency ? currency : "EUR",
            FinalLanguage = language ? language : "fr",
            FinalFormat = format ? format : "FR",
            maximumSignificantDigits = digits ? digits : 10;
        return new Intl.NumberFormat(`${FinalLanguage}-${FinalFormat}`, {style: "currency", currency: `${FinalCurrency}`, maximumSignificantDigits: `${maximumSignificantDigits}`}).format(number);
    };
    const HandleOpenCardSyncAccount = () => {
        setShowCardSyncAccount(true);
    };
    const HandleCloseCardSyncAccount = (refresh) => {
        if (refresh) {
            setShowCardSyncAccount(false);
            setRefreshApi(true);
        } else {
            setShowCardSyncAccount(false);
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardSyncAccount;
    if (ShowCardSyncAccount === true) {
        ModalCardSyncAccount = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                ParentCompRef={Ref_TabNavContent}
                TopBarContent={<h2 style={{margin: "0 auto"}}>Sync company account to bank accounts</h2>}
                CloseCard={HandleCloseCardSyncAccount}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="400px"
                // CardHeight="500px"
                WithPerfectScrollbar={true}
                CardWindowPaddingVertical="50px"
                CardWindowPaddingHorizontal="50px"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
            >
                <div className={classes.CardContent}>
                    <AccountingOverviewSyncAccount />
                </div>
            </CardModal>
        );
    } else {
        ModalCardSyncAccount = null;
    }

    var FinalComponent;
    if (AccountData) {
        FinalComponent = (
            <div className={classes.OverviewWrapper}>
                <h2 style={{margin: "10px 0px 0px 10px"}}>Account Balance</h2>
                <div className={classes.Balance_Info_Wrapper}>
                    <div className={classes.Balance_Wrapper} style={{color: "green", border: "2px solid green"}}>
                        {AccountData.Balance}
                    </div>
                    <div className={classes.Upload_Wrapper} data-tip data-for="uploadcsv" onClick={HandleOpenCardSyncAccount}>
                        <RotateRight
                            IconSize="40px"
                            InnerPadding="20px"
                            Frame="rounded"
                            SVGFillColor="white"
                            FrameFillColor="grey"
                            FrameStrokeColor="grey"
                            FrameStrokeColorHover="grey"
                            FrameFillColorHover="grey"
                        />
                    </div>
                    <ReactTooltip id="uploadcsv" place="bottom" type="dark" effect="solid">
                        <p className={classes.tooltipP}>Sync account</p>
                    </ReactTooltip>

                    <div className={classes.Info_Wrapper}>
                        <div className={classes.Info_Wrapper_Lines}>Last synched: {AccountData.LastSync} </div>
                        <div className={classes.Info_Wrapper_Lines}>Last transaction: {AccountData.LastTransactionDate} </div>
                        <div className={classes.Info_Wrapper_Lines}>VAT transaction to manage: {AccountData.total_Accounting_ToHandle} </div>
                    </div>
                </div>
            </div>
        );
    } else {
        FinalComponent = null;
    }
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
            {ModalCardSyncAccount}
            {FinalComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default AccoutingOverview;
