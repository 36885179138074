import {UPDATE_TIME_ENTRY_TRACKING} from "./timeTrackingEntryConstants";
import {createReducer} from "../../reduxStore/reduxReducerUtil";

// import axios from "axios";

const initialState = {
    Exist: false,
    Entry: null,
};

export const UpdateTimeEntryTracking = (state, payload) => {
    let data = payload.Data;
	let EntryExist = payload.Exist;
    console.log("data from redux reducer", data);

    // let User = {
    //     id: data.id,
    //     PermissionGroups: data.PermissionGroups,
    //     isAdmin: data.isAdmin,
    //     inRegistration: data.inRegistration ? data.inRegistration : false,
    //     userProfile: {
    //         id: data.userProfile.id ? data.userProfile.id : null,
    //         username: data.userProfile.username ? data.userProfile.username : null,
    //         avatar: data.userProfile.avatar ? data.userProfile.avatar : null,
    //         email: data.userProfile.email ? data.userProfile.email : null,
    //         firstName: data.userProfile.firstName ? data.userProfile.firstName : null,
    //         lastName: data.userProfile.lastName ? data.userProfile.lastName : null,
    //     },
    // };
    
    let UpdatedEntry = {title: "arnaud"};
    return {
        ...state,
        Exist: EntryExist,
        Entry: {...state.Entry, ...UpdatedEntry},
    };
};

export default createReducer(initialState, {
    [UPDATE_TIME_ENTRY_TRACKING]: UpdateTimeEntryTracking,
});
