/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";

import {ArtiWeb, Company, AddressBook, Contract, Offer} from "@artibulles-cis/react-icons";


//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "40px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 50px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 15px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 30px",
        margin: "0px",
        color: "white",
    },
}));

const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const MainRoute = "/customers/";
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
	const HanldeFakeNavDivClick = () => {
        console.log("fake Nav Div Clicked");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <NavSideBarLink exact ScrollToLink={true} ActiveLinkIndicator={true} Slug={`${MainRoute}/Link1`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <ArtiWeb IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Link1</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink ActiveLinkIndicator={true} onClick={HanldeFakeNavDivClick}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <ArtiWeb IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Not a Link, Just a div</p>
                    </div>
                </div>
            </NavSideBarLink>

            <NavSideBarLinkAccordion
                Slug={`${MainRoute}/accordion1`}
                SlugList={[`${MainRoute}/accordion1/link1`, `${MainRoute}/accordion1/link2`, `${MainRoute}/accordion1/link3`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Company IconSize="30" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accordion 1</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/accordion1/link1`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddressBook IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Address Book</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/accordion1/link2`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Contract IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Contracts</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/accordion1/link3`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Offer IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Offers</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            <NavSideBarLink ScrollToLink={true} ActiveLinkIndicator={true} Slug={`${MainRoute}/link2`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <ArtiWeb IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Link2</p>
                    </div>
                </div>
            </NavSideBarLink>

            <div style={{backgroundColor: "black", height: "450px"}}></div>
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}/accordion2`}
                SlugList={[`${MainRoute}/accordion2/link1`, `${MainRoute}/accordion2/link2`, `${MainRoute}/accordion2/link3`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Company IconSize="30" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Accordion 2</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/accordion2/link1`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddressBook IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Address Book</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} onClick={HanldeFakeNavDivClick}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddressBook IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Button Type - No nav</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/accordion2/link2`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Contract IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Contracts</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}/accordion2/link3`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Offer IconSize="30" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Offers</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            <NavSideBarLink ScrollToLink={true} ActiveLinkIndicator={true} Slug={`${MainRoute}/link3`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <ArtiWeb IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Link3</p>
                    </div>
                </div>
            </NavSideBarLink>
            <div style={{backgroundColor: "black", height: "450px"}}></div>
            <NavSideBarLink ScrollToLink={true} ActiveLinkIndicator={true} Slug={`${MainRoute}/link4`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <ArtiWeb IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Link4</p>
                    </div>
                </div>
            </NavSideBarLink>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
