import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import FormCompontent from "../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";

import {PDF} from "@artibulles-cis/react-icons";
import {toast} from "react-toastify";
import AccountantStatusInformationTabNav from "./AccountantStatusInformationTabNav";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },

    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        width: "620px",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        // margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "3px 10px 3px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {ShowCardUpload, HandleCloseCardParent, Invoices_File_Path, Suppliers, PassedFormValues, InvoiceId, HandleSaveRefreshForm} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCall, setApiCall] = useState(null);
    const [Loading, setLoading] = useState(false);

    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    const [FormComponent, setFormComponent] = useState();
    const [Resend, setResend] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                toast.error(`Email not sent, ${ApiRes.error}`, {autoClose: true});
            } else {
                if (!ApiCall) {
                    // if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    //     let Data = ApiRes.data.data;
                    // } else {
                    //     console.log("error");
                    // }
                } else if (ApiCall === "Sent By Email Direct") {
                    let Data = ApiRes.data.data;
                    console.log("Email Sent Direct", Data);
                    toast.success(`Email Sent`, {autoClose: true});
                    HandleSaveRefreshForm();
                } else if (ApiCall === "Sent By Email Confirmation pending") {
                    let Data = ApiRes.data.data;
                    toast.success(`Email Sent`, {autoClose: true});
                    console.log("Email Sent Indirect", Data);
                    HandleSaveRefreshForm();
                } else if (ApiCall === "Confirm Uploaded") {
                    let Data = ApiRes.data.data;
                    toast.success(`Confirm Uploaded`, {autoClose: true});
                    console.log("Confirm Uploaded", Data);
                    HandleSaveRefreshForm();
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateFormComponent = useCallback(() => {
        let AccountantStatus = PassedFormValues.AccountantStatus;
        if (AccountantStatus) {
            let AccountantStatusHistory = PassedFormValues.AccountantStatusHistory;
            let FormComp, CardButtonComponent, InstructionsComponent;
            let ReadOnly = true;

            if (Resend === true) {
                CardButtonComponent = (
                    <React.Fragment>
                        <Button Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={HandleSendByEmailWithoutNote}>
                            Send without note
                        </Button>
                        <Button Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={HandleSendByEmailWithNote}>
                            Send with note
                        </Button>
                        <Button Background="green" Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={() => HandleConfirmUploaded("ManualUpload")}>
                            Confirm Uploaded
                        </Button>
                    </React.Fragment>
                );
                InstructionsComponent = (
                    <div className={classes.CardMain} style={{flex: "0 1 auto"}}>
                        <div style={{textAlign: "left", fontSize: "0.8rem"}}>
                            You can send the document :
                            <ul style={{margin: "5px 0px 0px 0px"}}>
                                <li>
                                    <span>By email without a note.</span>
                                    <span style={{fontStyle: "italic"}}>(No further action is required)</span>{" "}
                                </li>
                                <li>
                                    <span>By email and add a note in Skwarel manually.</span>
                                    <span style={{fontStyle: "italic"}}>(You also need to upload it manually)</span>{" "}
                                </li>

                                <li>By uploading manually to Skwarel</li>
                            </ul>
                        </div>
                    </div>
                );
            } else {
                if (AccountantStatus.Uploaded === true) {
                    CardButtonComponent = (
                        <React.Fragment>
                            <Button Background="orange" Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={HandleResendDocument}>
                                Resend
                            </Button>
                        </React.Fragment>
                    );
                    FormComp = null;
                } else {
                    if (AccountantStatus.EmailSent === true) {
                        CardButtonComponent = (
                            <React.Fragment>
                                <Button Background="green" Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={() => HandleConfirmUploaded("Email")}>
                                    Confirm Uploaded
                                </Button>
                                <Button Background="orange" Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={HandleResendDocument}>
                                    Resend
                                </Button>
                            </React.Fragment>
                        );
                    } else {
                        ReadOnly = false;
                        CardButtonComponent = (
                            <React.Fragment>
                                <Button Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={HandleSendByEmailWithoutNote}>
                                    Send without note
                                </Button>
                                <Button Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={HandleSendByEmailWithNote}>
                                    Send with note
                                </Button>
                                <Button Background="green" Height="28px" Width="180px" ClassExtendButton={classes.Button} onClick={() => HandleConfirmUploaded("ManualUpload")}>
                                    Confirm Uploaded
                                </Button>
                            </React.Fragment>
                        );
                        InstructionsComponent = (
                            <div className={classes.CardMain} style={{flex: "0 1 auto"}}>
                                <div style={{textAlign: "left", fontSize: "0.8rem"}}>
                                    You can send the document :
                                    <ul style={{margin: "5px 0px 0px 0px"}}>
                                        <li>
                                            <span>By email without a note.</span>
                                            <span style={{fontStyle: "italic"}}>(No further action is required)</span>{" "}
                                        </li>
                                        <li>
                                            <span>By email and add a note in Skwarel manually.</span>
                                            <span style={{fontStyle: "italic"}}>(You also need to upload it manually)</span>{" "}
                                        </li>

                                        <li>By uploading manually to Skwarel</li>
                                    </ul>
                                </div>
                            </div>
                        );
                    }
                }
            }
            FormComp = (
                <React.Fragment>
                    {InstructionsComponent}
                    <div className={classes.CardMain} style={{flex: "0 1 auto"}}>
                        <div className={classes.FormWrapper}>
                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                <div className={classes.FormField} style={{flex: "0 0 540px"}}>
                                    <FormCompontent
                                        Name="ref_Supplier"
                                        InitialValue={InitialFormValues.ref_Supplier ? InitialFormValues.ref_Supplier : null}
                                        FormValue={FormValues.ref_Supplier ? FormValues.ref_Supplier : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_Supplier", values)}
                                        SelectOptions={FieldOptions.ref_Supplier}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Supplier"
                                        NoMessage={false}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="130px"
                                        LabelAlign="Left"
                                        ReadOnly={true}
                                    />
                                </div>
                            </div>

                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                <div className={classes.FormField} style={{flex: "0 0 260px"}}>
                                    <FormCompontent
                                        Name="CalcTotalVATIncluded"
                                        InitialValue={InitialFormValues.CalcTotalVATIncluded ? InitialFormValues.CalcTotalVATIncluded : null}
                                        FormValue={FormValues.CalcTotalVATIncluded ? FormValues.CalcTotalVATIncluded : null}
                                        OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATIncluded", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Amount VAT Inc."
                                        ReadOnly={true}
                                        LabelMaxWidth="130px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="130px"
                                    />
                                </div>
                                <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                    <FormCompontent
                                        Name="CalcTotalVATAmount"
                                        InitialValue={InitialFormValues.CalcTotalVATAmount ? InitialFormValues.CalcTotalVATAmount : null}
                                        FormValue={FormValues.CalcTotalVATAmount ? FormValues.CalcTotalVATAmount : null}
                                        OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATAmount", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="VAT Amount"
                                        PickerDateType="Calendar"
                                        PickerOnly={false}
                                        InputFormat="dd/MM/yyyy"
                                        LabelMaxWidth="100px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="160px"
                                        ReadOnly={true}
                                    />
                                </div>
                            </div>

                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}></div>
                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                <div className={classes.FormField} style={{flex: "0 0 260px"}}>
                                    <FormCompontent
                                        Name="InvoiceSupplierDateIso"
                                        InitialValue={InitialFormValues.InvoiceSupplierDateIso ? InitialFormValues.InvoiceSupplierDateIso : null}
                                        FormValue={FormValues.InvoiceSupplierDateIso ? FormValues.InvoiceSupplierDateIso : null}
                                        OutputValue={(values) => HandleUpdateFormValues("InvoiceSupplierDateIso", values)}
                                        Component="DatePicker"
                                        Variant="Outlined"
                                        Label="Invoice Date"
                                        PickerDateType="Calendar"
                                        PickerOnly={false}
                                        InputFormat="dd/MM/yyyy"
                                        LabelMaxWidth="130px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="100px"
                                        ReadOnly={true}
                                    />
                                </div>
                                <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                                    <FormCompontent
                                        Name="InvoiceShortId"
                                        InitialValue={InitialFormValues.InvoiceShortId ? InitialFormValues.InvoiceShortId : null}
                                        FormValue={FormValues.InvoiceShortId ? FormValues.InvoiceShortId : null}
                                        OutputValue={(values) => HandleUpdateFormValues("InvoiceShortId", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Reference"
                                        LabelMaxWidth="100px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="160px"
                                        ReadOnly={true}
                                    />
                                </div>
                            </div>

                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                <div className={classes.FormField} style={{flex: "0 0 540px"}}>
                                    <FormCompontent
                                        Name="Note"
                                        InitialValue={InitialFormValues.Note ? InitialFormValues.Note : null}
                                        FormValue={FormValues.Note ? FormValues.Note : null}
                                        OutputValue={(values) => HandleUpdateFormValues("Note", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Accountant note"
                                        LabelMaxWidth="130px"
                                        LabelAlign="Left"
                                        // FieldMaxWidth="270px"
                                        ReadOnly={ReadOnly}
                                        multiline={true}
                                        RowsToDisplay="2"
                                        MaxRows="4"
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                <div className={classes.FormField} style={{flex: "0 0 540px"}}>
                                    <FormCompontent
                                        Name="ReasonForChange"
                                        InitialValue={InitialFormValues.ReasonForChange ? InitialFormValues.ReasonForChange : null}
                                        FormValue={FormValues.ReasonForChange ? FormValues.ReasonForChange : null}
                                        OutputValue={(values) => HandleUpdateFormValues("ReasonForChange", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Reason For Change"
                                        LabelMaxWidth="130px"
                                        LabelAlign="Left"
                                        // FieldMaxWidth="270px"
                                        ReadOnly={Resend === true ? false : true}
                                        multiline={true}
                                        RowsToDisplay="2"
                                        MaxRows="4"
                                    />
                                </div>
                            </div>

                            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}} onClick={HandleViewPdf}>
                                <div>
                                    <PDF SVGFillColor="black" />
                                </div>
                                <div style={{marginLeft: "5px", fontSize: "0.8rem"}}>{`${FormValues.InvoiceShortId}.pdf`}</div>
                            </div>
                        </div>
                        <div className={classes.CardButtons}>{CardButtonComponent}</div>
                    </div>
                </React.Fragment>
            );
            setFormComponent(FormComp);
        }
    }, [FieldOptions, FormValues, Resend]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (PassedFormValues) {
            let AccountantStatusHistory = PassedFormValues.AccountantStatusHistory;
            let InitialFormValuesTemp;
            if (AccountantStatusHistory && Array.isArray(AccountantStatusHistory) && AccountantStatusHistory.length > 0) {
                InitialFormValuesTemp = AccountantStatusHistory[0];
            }
            let Reference;
            if (InitialFormValuesTemp) {
                Reference = InitialFormValuesTemp.Doc_Reference;
            } else {
                Reference = PassedFormValues.InvoiceShortId;
            }
            setFieldOptions({ref_Supplier: Suppliers});

            setInitialFormValues({
                APIID: InitialFormValuesTemp ? InitialFormValuesTemp._id : null,
                InvoiceShortId: Reference,
                CalcTotalVATIncluded: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATIncluded),
                CalcTotalVATAmount: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATAmount),
                InvoiceSupplierDateIso: PassedFormValues.InvoiceSupplierDateIso,
                ref_Supplier: PassedFormValues.ref_Supplier,
                Note: InitialFormValuesTemp ? InitialFormValuesTemp.Note : null,
                ReasonForChange: InitialFormValuesTemp ? InitialFormValuesTemp.ReasonForChange : "Enter a Reason",
            });
            setFormValues({
                APIID: InitialFormValuesTemp ? InitialFormValuesTemp._id : null,
                InvoiceShortId: Reference,
                CalcTotalVATIncluded: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATIncluded),
                CalcTotalVATAmount: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(PassedFormValues.CalcTotalVATAmount),
                InvoiceSupplierDateIso: PassedFormValues.InvoiceSupplierDateIso,
                ref_Supplier: PassedFormValues.ref_Supplier,
                Note: InitialFormValuesTemp ? InitialFormValuesTemp.Note : null,
                ReasonForChange: InitialFormValuesTemp ? InitialFormValuesTemp.ReasonForChange : "Enter a Reason",
            });
            setFormInitReady(true);

            if (PassedFormValues.AccountantStatusHistory && Array.isArray(PassedFormValues.AccountantStatusHistory) && PassedFormValues.AccountantStatusHistory.length > 0) {
                Ref_LocaleData.current = PassedFormValues.AccountantStatusHistory;
            }
        }
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (FormInitReady === true) {
            GenerateFormComponent();
        }
    }, [FormInitReady, GenerateFormComponent]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        HandleCloseCardParent();
    };

    const HandleSendByEmailWithoutNote = () => {
        let InvoiceURL = Invoices_File_Path[0].serverPath;
        const DataForm = {
            DirectUpload: true,
            DocType: "Purchases",
            InvoiceReference: FormValues.InvoiceShortId,
            URL: InvoiceURL,
            InvoiceId: InvoiceId,
        };
        setApiCall("Sent By Email Direct");
        const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/accountant/sendaccountantinvoicebyemail`, type: "post", data: DataForm};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleSendByEmailWithNote = () => {
        let InvoiceURL = Invoices_File_Path[0].serverPath;
        const DataForm = {
            DirectUpload: false,
            DocType: "Purchases",
            InvoiceReference: FormValues.InvoiceShortId,
            Note: FormValues.Note,
            URL: InvoiceURL,
            InvoiceId: InvoiceId,
        };
        setApiCall("Sent By Email Confirmation pending");
        const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/accountant/sendaccountantinvoicebyemail`, type: "post", data: DataForm};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleConfirmUploaded = (Type) => {
        let InvoiceURL = Invoices_File_Path[0].serverPath;
        let DataForm = {
            Type: Type,
            Note: FormValues.Note,
            Doc_Reference: FormValues.InvoiceShortId,
            Id: FormValues.APIID,
            ReasonForChange: FormValues.ReasonForChange,
            URL: InvoiceURL,
            InvoiceId: InvoiceId,
        };
        setApiCall("Confirm Uploaded");
        const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/accountant/confirmuploaded`, type: "post", data: DataForm};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
        console.log("AccountantStatus", DataForm);
    };

    const HandleResendDocument = () => {
        //We Just Update the FormValues
        let UpdateFormValues = {
            Note: null,
            InvoiceShortId: FormValues.InvoiceShortId + "_V2",
            ReasonForChange: "Enter a rationale",
        };

        setFormValues({...FormValues, ...UpdateFormValues});
        setResend(true);
        console.log("Resend");
    };

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    const HandleViewPdf = () => {
        let InvoiceURL = Invoices_File_Path[0].serverPath;
        window.open(InvoiceURL);
    };
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        setFormValues(UpdatedFormValues);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let ModalCard;
    if (ShowCardUpload === true) {
        let TabNavComponent;
        if (Ref_LocaleData.current) {
            TabNavComponent = <AccountantStatusInformationTabNav TableData={Ref_LocaleData.current} />;
        }
        ModalCard = (
            <CardModal
                CardID="ReviewPayment"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Send Invoice to accountant</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1400px"
                CardHeight="700px"
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardWindow}>
                    <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                    {FormComponent}
                    <div className={classes.CardMain} style={{flex: "1 1 auto"}}>
                        {TabNavComponent}
                    </div>
                </div>
            </CardModal>
        );
    }
    return (
        <React.Fragment>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {ModalCard}
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
