import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";

import {useHistory} from "react-router-dom";
import TabNavContent from "../../../../../../../artibulles-cis/TabNavContent/TabNavContent";

import Table from "../../../../../../../artibulles-cis/TableNew/Table";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";

import InvoiceEdit from "../InvoiceEdit/InvoiceEdit";
import InvoiceDuplicate from "../InvoiceEdit/InvoiceDuplicate";
import {PDF, CheckBoxCircularFilled, WarningTriangleRed, WarningTriangleYellow, RotateLeft} from "@artibulles-cis/react-icons";
import {parseISO, differenceInDays} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapperOld: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        // height: "100%",
        padding: "10px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },

    ClassExtendTabContent: {
        padding: "10px",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const Ref_TabNavContent = useRef(null);
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    let URLAbsolute = {
        ALL: `${ProdURL}/api/purchaseinvoices/invoices/queries?paymentstatus=false`,
        QUERY: `${ProdURL}/api/companies/query`,
        DELETEONE: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
        DELETEMANY: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
        PUT: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
    };

    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        if (value && Array.isArray(value) && value.length > 0) {
            let CellRenderComp = [];
            value.forEach((elem) => {
                let IconElem = (
                    <div onClick={() => HandleViewPdf(elem.serverPath)}>
                        <PDF SVGFillColor="white" />
                    </div>
                );
                CellRenderComp.push(IconElem);
            });

            return CellRenderComp;
        } else {
            return null;
        }
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const PaymentActionRendering = (params) => {
        let RowData = params.data;
        let PaymentStatus = RowData.PaymentStatus;
        let InvoiceDueDate = RowData.InvoicePaymentDueDateIso ? parseISO(RowData.InvoicePaymentDueDateIso) : null;
        let PurchaseInvoicePaymentActionNeeded = RowData.PurchaseInvoicePaymentActionNeeded;
        if (PaymentStatus === true) {
            return null;
        } else {
            if (PurchaseInvoicePaymentActionNeeded === "None") {
                return <React.Fragment></React.Fragment>;
            }
            if (PurchaseInvoicePaymentActionNeeded === "Pending") {
                <div className={classes.PaymentStatus}>
                    <RotateLeft />
                    Pending
                </div>;
            }
            if (PurchaseInvoicePaymentActionNeeded === "ToPay") {
                //We check if late payment or not
                let IsLate = false;
                if (InvoiceDueDate) {
                    let Now = new Date(Date.now());
                    if (differenceInDays(Now, InvoiceDueDate) > 0) {
                        // console.log("Late");
                        IsLate = true;
                    } else {
                        IsLate = false;
                    }
                }
                if (IsLate === true) {
                    return (
                        <div className={classes.PaymentStatus}>
                            <WarningTriangleRed />
                            To pay
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.PaymentStatus}>
                            <WarningTriangleYellow />
                            To pay
                        </div>
                    );
                }
            }
            if (PurchaseInvoicePaymentActionNeeded === "ToSign") {
                return (
                    <div className={classes.PaymentStatus}>
                        <WarningTriangleYellow />
                        To sign
                    </div>
                );
            }
            return <React.Fragment></React.Fragment>;
        }
    };
    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };

    const HandleDuplicateInvoice = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };

    const DeleteCondition = (args) => {
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else {
            return false;
        }
    };

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Duplicate",
            ActiveCondition: null,
            Index: 2,
            OnClick: HandleDuplicateInvoice,
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            // OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];

    const TableColumns = [
        {
            field: "InvoiceSupplierDateIso",
            headerName: "Invoice Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "CalcTotalVATIncluded",
            headerName: "Amount (VAT)",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "status_invoice",
            //
            headerName: "Invoice Status",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "PaymentStatus",
            //
            headerName: "Paid",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "PurchaseInvoicePaymentActionNeeded",
            //
            headerName: "Payment Action",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: PaymentActionRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "InvoicePaymentDueDateIso",
            headerName: "Due Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            headerName: "Supplier",
            headerClass: "ag-CenterHeader",
            width: 300,
            filter: true,
            Lookup: {LookupField: "ref_Supplier", Source: "Suppliers", ReturnField: "company"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "Description",
            headerName: "Description",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            autoHeight: true,
            minWidth: 200,
        },
        // {
        //     field: "InvoicePaymentExpectedCommunication",
        //     headerName: "Communication",
        //     headerClass: "ag-CenterHeader",
        //     width: 300,
        //     filter: true,
        // },

        {
            field: "Invoices_File_Path",
            headerName: "Documents",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: false,
            cellRenderering: PDFRendering,
            cellStyle: PDFCell,
        },

        {
            field: "CalcTotalVATExcluded",
            headerName: "Tot VAT Exc.",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "CalcTotalVATAmount",
            headerName: "Tot VAT",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);

    const [ReloadTableData, setReloadTableData] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        console.log("Handle Close Edit Card");
        setShowEditCard(false);
        setEditCardId(null);
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setReloadTableData(true);
    };

    const HandleCloseDuplicateCard = (Id) => {
        // console.log("Should close the card")
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;

    if (ShowEditCard === true) {
        ModalCard = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceEdit Id={EditCardId} HandleCloseCard={HandleCloseEditCard} />
                </div>
            </CardModal>
        );
    }
    if (ShowDuplicateCard === true) {
        ModalCard = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseDuplicateCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceDuplicate DuplicateId={DuplicateCardId} HandleCloseViewCard={HandleCloseDuplicateCard} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCard}

            <AGGridTable
                URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                MainDataSource="Invoices"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTableData}
                SyncReloadDataStateFunction={setReloadTableData}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
