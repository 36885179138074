/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";
import {useSelector} from "react-redux";

import {Calendar, Company, AddressBook, AvatarV2, Contract, Offer, TableView, Dashboard, AddCircle} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "30px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
        color: "white",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    Separator: {
        width: "100%",
        borderBottom: "1px solid rgba(255,255,255,0.4)",
    },
}));

const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const {timeEntryExist} = useSelector((state) => ({
        timeEntryExist: state.timeEntryTracking.Exist,
    }));
    const MainRoute = "/tasktimemanagement/times/";
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCreateTimeEntryAndTrack = () => {
        History.push(`/tasktimemanagement/times/time/newtimentryandtrack`);
    };
    const HandleViewTimeTrackingClock = () => {
        History.push(`/tasktimemanagement/times/time/newtimentryandtrack`);
    };

    const HandleAddManualTimeEntry = () => {
        History.push(`/tasktimemanagement/times/timeentry/new`);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let TrackTimeLinkComponent = null;
    if (timeEntryExist === true) {
        TrackTimeLinkComponent = (
            <NavSideBarLink ActiveLinkIndicator={true} onClick={HandleViewTimeTrackingClock}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Dashboard IconSize="30" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Time tracking view</p>
                    </div>
                </div>
            </NavSideBarLink>
        );
    } else {
        TrackTimeLinkComponent = (
            <NavSideBarLink ActiveLinkIndicator={true} onClick={HandleCreateTimeEntryAndTrack}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <AddCircle IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Quick Track Time</p>
                    </div>
                </div>
            </NavSideBarLink>
        );
    }

    return (
        <React.Fragment>
            <NavSideBarLink Exact={false} ActiveLinkIndicator={true} Slug={`${MainRoute}dailytimesheet`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Calendar IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Daily Time sheet</p>
                    </div>
                </div>
            </NavSideBarLink>
            {TrackTimeLinkComponent}

            <NavSideBarLink ActiveLinkIndicator={true} onClick={HandleAddManualTimeEntry}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <AddCircle IconSize="30" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Add Time Entry</p>
                    </div>
                </div>
            </NavSideBarLink>
            <div className={classes.Separator}></div>
            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}alltimeentries`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <TableView IconSize="24" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>All time entries</p>
                    </div>
                </div>
            </NavSideBarLink>

            <NavSideBarLinkAccordion
                Slug={`${MainRoute}timesheets`}
                SlugList={[`${MainRoute}timesheets/daily`, `${MainRoute}timesheets/weekly`, `${MainRoute}timesheets/mohthly`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Calendar IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Timesheets</p>
                        </div>
                    </div>
                }
            >
                <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}timesheets/daily`}>
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Calendar IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text_SubTree}>Daily</p>
                        </div>
                    </div>
                </NavSideBarLinkAccordionSubLink>
                <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}timesheets/weekly`}>
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Calendar IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text_SubTree}>Weekly</p>
                        </div>
                    </div>
                </NavSideBarLinkAccordionSubLink>
                <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}timesheets/monthly`}>
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Calendar IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text_SubTree}>Monthly</p>
                        </div>
                    </div>
                </NavSideBarLinkAccordionSubLink>
            </NavSideBarLinkAccordion>
            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}projectspending`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <Offer IconSize="24" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Project spending</p>
                    </div>
                </div>
            </NavSideBarLink>
            <NavSideBarLink exact ActiveLinkIndicator={true} Slug={`${MainRoute}addforcollaborator`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <AvatarV2 IconSize="24" SVGFillColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Add consultant entry</p>
                    </div>
                </div>
            </NavSideBarLink>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
