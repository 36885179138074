import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormCompontent from "../../../../../artibulles-cis/FormComponent/FormCompontent";
import {ArrowFilledLeft, ArrowFilledRight} from "@artibulles-cis/react-icons";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import CardModal from "@artibulles-cis/react/CardModal";
import AGGridTable from "../../../../../artibulles-cis/AGGridTable/AGGridTable";
import TabNavContent from "../../../../../artibulles-cis/TabNavContent/TabNavContent";
import TimeEntryEditNew from "../TimeEntryEditNew/TimeEntryEditNew";
import Button from "@artibulles-cis/react/Button";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
const {isValid, format, parseISO, formatISO, add, startOfYear, endOfYear, getYear, setYear, startOfMonth, endOfMonth} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabContent: {
        padding: "0px 0px 5px 0px",
    },
    Toolbar: {
        flex: "0 1 0px",
        height: "100px",
        display: "flex",
        padding: "0px 5px",
    },

    ButtonBarLeft: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    ButtonBarRight: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    FormMultilineFlex: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px",
        // flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },

    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        // flex: "0 1 600px",
        margin: "2px 2px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    TableWrapper: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        padding: "0px 5px",
        // padding: "20px",
        // position: "relative",
        boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },

    Main: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
    },

    ButtonExtend: {
        margin: "0px 0px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },
    ExportButton: {
        padding: "4px 5px",
        flex: "0 1 auto",
    },
    Indicator: {
        padding: "4px 5px",
        flex: "0 1 auto",
        display: "flex",
    },
    SearchButtons: {
        padding: "4px 5px",
        flex: "0 1 auto",
        display: "flex",
    },
}));

const DailyTimeSheets = React.memo(function DailyTimeSheets(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    const Ref_LocaleIndicator = useRef();
    const Ref_TabNavContent = useRef();
    const Ref_GridApi = useRef();

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const HandleEditRow = (Id, RowData) => {
        setShowViewCard(true);
        setViewCardId(Id);
    };

    const DeleteCondition = (args) => {
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else {
            return true; //DEVONLY!! Should be false
        }
    };
    const HandleDeleteRow = (id) => {
        console.log("id", id);
        const ApiCall = {url: `${ProdURL}/api/tasks/time/entry/${id}`, type: "delete"};
        setLoading(true);
        setAPICallType("DeleteRow");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };

    const ColumnButtonStyle = {
        Width: 75,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },

        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];
    const StartTimeRendering = (params) => {
        let RowData = params.data;

        let start_time_seconds = RowData.start_time_seconds;
        if (start_time_seconds) {
            return convertSecondsToTime(start_time_seconds);
        } else {
            return null;
        }
    };
    const EndTimeRendering = (params) => {
        let RowData = params.data;

        let end_time_seconds = RowData.end_time_seconds;
        if (end_time_seconds) {
            return convertSecondsToTime(end_time_seconds);
        } else {
            return null;
        }
    };
    const DurationRendering = (params) => {
        let RowData = params.data;

        let duration_seconds = RowData.duration_seconds;
        if (duration_seconds) {
            return convertSecondsToTime(duration_seconds);
        } else {
            return null;
        }
    };
    const TableColumns = [
        {
            field: "date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "date_Iso",
            headerName: "Date2",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            type: "dateColumn",
            cellClass: "dateType",
            hide: true,
        },
        {
            field: "entry_name",
            headerName: "Entry",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Company",
            headerClass: "ag-CenterHeader",
            width: 160,
            filter: true,
            Lookup: {LookupField: "ref_company", Source: "Companies", ReturnField: "company"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Program",
            headerClass: "ag-CenterHeader",
            width: 160,
            filter: true,
            Lookup: {LookupField: "ref_program", Source: "Programs", ReturnField: "program_name"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Milestone",
            headerClass: "ag-CenterHeader",
            width: 250,
            filter: true,
            Lookup: {LookupField: "ref_milestone", Source: "Milestones", ReturnField: "milestone_name"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "start_time_seconds",
            headerName: "Start",
            headerClass: "ag-CenterHeader",
			sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
            width: 100,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            cellRenderering: StartTimeRendering,
        },
        {
            field: "end_time_seconds",
            headerName: "End",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            cellRenderering: EndTimeRendering,
        },

        {
            field: "duration_seconds",
            headerName: "Duration",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            cellRenderering: DurationRendering,
        },

        {
            field: "is_personal",
            headerName: "Personal",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "is_billable",
            headerName: "Billable",
            headerClass: "ag-CenterHeader",
            width: 100,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "calc_billable_Amount",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Currency",
        },
    ];
    let ExcelStyles = [
        {
            id: "dateType",
            dataType: "DateTime",
            numberFormat: {
                format: "yyy-mm-dd",
            },
        },
        {
            id: "Entry",
        },
        {
            id: "Company",
        },
        {
            id: "Program",
        },
        {
            id: "Milestone",
        },
        {
            id: "Start",
        },
        {
            id: "End",
        },

        {
            id: "Duration",
        },

        {
            id: "Personal",

            dataType: "Boolean",
        },
        {
            id: "Billable",

            dataType: "Boolean",
        },
        {
            id: "Amount",
            numberFormat: {
                format: "#,##0.00 €",
            },
        },
    ];
    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = useState();
    const [FormValues, setFormValues] = useState();
    const [DateFilter, setDateFilter] = useState();
    const [FinalQuery, setFinalQuery] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    const [Init, setInit] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [ReloadTable, setReloadTable] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
                if (ApiRes.errorMessage.message === "Entry already running") {
                    toast.error(`There is already a time entry running`, {autoClose: true});
                }
            } else {
                if (!APICallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        // console.log("Data", Data);
                        if (Init === false) {
                            let Companies = Data.Companies;
                            let CompaniesOptions = [{value: "All", label: "All"}];
                            if (Companies && Array.isArray(Companies) && Companies.length > 0) {
                                Companies.forEach((elem) => {
                                    CompaniesOptions.push({value: elem._id, label: elem.company});
                                });
                            }
                            setFieldOptions({Company: CompaniesOptions});
                            let IntialVal = {
                                Company: "All",
                                Day: null,
                                YearPicker: formatISO(new Date(Date.now())),
                                MonthPicker: formatISO(new Date(Date.now())),
                                WeekPicker: null,
                                From: null,
                                To: null,
                            };
                            setInitialFormValues(IntialVal);
                            setFormValues(IntialVal);
                            let TimeEntries = Data.TimeEntries;
                            if (TimeEntries && Array.isArray(TimeEntries) && TimeEntries.length > 0) {
                                Ref_LocaleData.current = Data;

                                //We calculate the indicator data
                                var Indicator = {
                                    totalWorkedHours_seconds: null,
                                    totalWorkedHours_hours: null,
                                    totalAmount: 0,
                                };
                                var TotalWorkedHoursSeconds = 0;
                                let TotalAmount = 0;
                                TimeEntries.forEach((elem) => {
                                    // console.log("time entry elem", elem);
                                    if (elem.duration_seconds) {
                                        TotalWorkedHoursSeconds = TotalWorkedHoursSeconds + parseFloat(elem.duration_seconds);
                                    }
                                    if (elem.calc_billable_Amount) {
                                        TotalAmount = TotalAmount + elem.calc_billable_Amount;
                                    }
                                });
                                Indicator.totalWorkedHours_seconds = TotalWorkedHoursSeconds;
                                Indicator.totalWorkedHours_hours = convertSecondsToTime(TotalWorkedHoursSeconds, false);
                                Indicator.totalAmount = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(TotalAmount);

                                Ref_LocaleIndicator.current = Indicator;

                                setInit(true);
                            } else {
                                //Display no data
                            }
                        }
                    }
                } else if (APICallType === "Filter") {
                    let Data = ApiRes.data.data;
                    let TimeEntries = Data.TimeEntries;
                    if (TimeEntries && Array.isArray(TimeEntries) && TimeEntries.length > 0) {
                        Ref_LocaleData.current = Data;

                        //We calculate the indicator data
                        var Indicator = {
                            totalWorkedHours_seconds: null,
                            totalWorkedHours_hours: null,
                            totalAmount: 0,
                        };
                        var TotalWorkedHoursSeconds = 0;
                        let TotalAmount = 0;
                        TimeEntries.forEach((elem) => {
                            // console.log("time entry elem", elem);
                            if (elem.duration_seconds) {
                                TotalWorkedHoursSeconds = TotalWorkedHoursSeconds + parseFloat(elem.duration_seconds);
                            }
                            if (elem.calc_billable_Amount) {
                                TotalAmount = TotalAmount + elem.calc_billable_Amount;
                            }
                        });
                        Indicator.totalWorkedHours_seconds = TotalWorkedHoursSeconds;
                        Indicator.totalWorkedHours_hours = convertSecondsToTime(TotalWorkedHoursSeconds, false);
                        Indicator.totalAmount = new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(TotalAmount);
                        Ref_LocaleIndicator.current = Indicator;

                        setInit(true);
                    } else {
                        //Display no data

                        Ref_LocaleIndicator.current = null;
                        Ref_LocaleData.current = null;
                    }
                    setAPICallType(null);
                    setReloadTable(false);
                } else if (APICallType === "DeleteRow") {
                    toast.success(`Entry Deleted`, {autoClose: true});
					//We need to reload the table with the current selected filters
					HandleFilter()
                }
            }
        }
    }, [ApiRes, Loading]);

    const ShareGridApi = (input) => {
        Ref_GridApi.current = input;
    };
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="TimeEntries"
                Data={Ref_LocaleData.current}
                // LocaleData={Ref_LocaleData.current}
                // URLAbsolute={{
                //     ALL: `${ProdURL}/api/purchaseinvoices/invoices/queries/${RealQuery}`,
                //     QUERY: `${ProdURL}/api/companies/query`,
                //     DELETEONE: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
                //     DELETEMANY: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
                //     PUT: `${ProdURL}/api/purchaseinvoices/invoicetableoperation`,
                // }}
                TableColumns={TableColumns}
                MainDataSource="TimeEntries"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
                ShareGridApi={ShareGridApi}
                ExcelStyles={ExcelStyles}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/tasks/time/entries/?is_work=true&exclude_running=true`, type: "get"};
        setLoading(true);
        setAPICallType(null);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, [ProdURL, Init, FinalQuery]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init, ReloadTable]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        var UpdateFormValues = {[field]: value};
        if (field === "YearPicker") {
            //User changed year
            UpdateFormValues.From = null;
            UpdateFormValues.To = null;
            UpdateFormValues.MonthPicker = null;
            UpdateFormValues.WeekPicker = null;
            UpdateFormValues.Day = null;
            let FinalDate = parseISO(value);
            FinalQuery.DateStart = formatISO(startOfYear(FinalDate));
            FinalQuery.DateEnd = formatISO(endOfYear(FinalDate));
        }
        if (field === "MonthPicker") {
            UpdateFormValues.From = null;
            UpdateFormValues.To = null;
            UpdateFormValues.WeekPicker = null;
            UpdateFormValues.Day = null;

            if (FormValues.YearPicker) {
                let DateYear = parseISO(FormValues.YearPicker);
                let Year = getYear(DateYear);
                console.log("Year", Year);
                let DateMonth = parseISO(value);
                let FinalDate = setYear(DateMonth, Year);
                FinalQuery.DateStart = formatISO(startOfMonth(FinalDate));
                FinalQuery.DateEnd = formatISO(endOfMonth(FinalDate));
            }
        }
        if (field === "WeekPicker") {
            let YearPicker = FormValues.YearPicker;
            let MonthPicker = FormValues.MonthPicker;

            UpdateFormValues.From = null;
            UpdateFormValues.To = null;
            UpdateFormValues.WeekPicker = null;
            UpdateFormValues.Day = null;
        }
        if (field === "Day") {
            //User changed year
            UpdateFormValues.From = null;
            UpdateFormValues.To = null;
            UpdateFormValues.YearPicker = null;
            UpdateFormValues.MonthPicker = null;
            UpdateFormValues.WeekPicker = null;
            UpdateFormValues.Day = null;
        }
        setFormValues({...FormValues, ...UpdateFormValues});
    };
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        setInit(false);
    };
    const HandleChangeDay = (type) => {
        let CurrentDate = parseISO(FormValues.Day);
        var UpdateDate;
        if (type === "Previous") {
            UpdateDate = formatISO(add(CurrentDate, {days: -1}));
        } else {
            UpdateDate = formatISO(add(CurrentDate, {days: 1}));
        }

        setInit(false);
        setFormValues({...FormValues, ...{date_Iso: UpdateDate}});
    };

    const HandleExport = () => {
        Ref_GridApi.current.exportDataAsExcel({allColumns: true});
    };

    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        // if (field === "Language") {
        //     if (SelectedValues === "English") {
        //         UpdatedFormValues.EmailSubject = EmailLanguageOptions.Subject.English;
        //         UpdatedFormValues.EmailBody = EmailLanguageOptions.Body.English;
        //     } else {
        //         UpdatedFormValues.EmailSubject = EmailLanguageOptions.Subject.French;
        //         UpdatedFormValues.EmailBody = EmailLanguageOptions.Body.French;
        //     }
        // }

        setFormValues(UpdatedFormValues);
    };

    const HandleFilter = () => {
        //
        // console.log("FinalQuery", FinalQuery);
        let Query;
        if (FinalQuery.DateStart && FinalQuery.DateEnd) {
            Query = `&datestart=${FinalQuery.DateStart}&dateend=${FinalQuery.DateEnd}`;
        }
        if (FormValues.Company && FormValues.Company !== "All") {
            Query = Query + `&ref_company=${FormValues.Company}`;
        }

        const ApiCall = {
            url: `${ProdURL}/api/tasks/time/entries/?is_work=true&exclude_running=true${Query}`,
            type: "get",
        };
        setLoading(true);
        setTableComponent(null);
        setAPICallType("Filter");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleResetFilter = () => {
        setInit(false);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <TimeEntryEditNew Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    var FinalTableComponent, FinalIndicatorComponent;

    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = TableComponent;
        }

        FinalIndicatorComponent = (
            <div className={classes.Indicator}>
                <div className={classes.LineInfo}>
                    <span>Total Worked hours : </span>
                    <span> {Ref_LocaleIndicator.current ? Ref_LocaleIndicator.current.totalWorkedHours_hours : null}</span>
                </div>
                <div className={classes.LineInfo} style={{marginLeft: "20px"}}>
                    <span>Total Amount : </span>
                    <span> {Ref_LocaleIndicator.current ? Ref_LocaleIndicator.current.totalAmount : null}</span>
                </div>
            </div>
        );
    } else {
        FinalTableComponent = <div>there is no data</div>;
        FinalIndicatorComponent = null;
    }

    let FormComponent;
    if (Init === true) {
        FormComponent = (
            <React.Fragment>
                <div className={classes.ButtonBarLeft}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{width: "150px", marginLeft: "0px"}}>
                            <FormCompontent
                                Name="YearPicker"
                                InitialValue={InitialFormValues.YearPicker ? InitialFormValues.YearPicker : null}
                                FormValue={FormValues.YearPicker ? FormValues.YearPicker : null}
                                OutputValue={(values) => HandleUpdateFormValues("YearPicker", values)}
                                Component="DatePicker"
                                Variant="OutlinedLabel"
                                Label="Year"
                                NoMessage={false}
                                PickerDateType="Year"
                                PickerOnly={false}
                                InputFormat="yyyy"
                                FieldMaxWidth="100px"
                            />
                        </div>
                        <div className={classes.FormField} style={{width: "150px", marginLeft: "0px"}}>
                            <FormCompontent
                                Name="MonthPicker"
                                InitialValue={InitialFormValues.MonthPicker ? InitialFormValues.MonthPicker : null}
                                FormValue={FormValues.MonthPicker ? FormValues.MonthPicker : null}
                                OutputValue={(values) => HandleUpdateFormValues("MonthPicker", values)}
                                Component="DatePicker"
                                Variant="OutlinedLabel"
                                Label="Month"
                                NoMessage={false}
                                PickerDateType="Month"
                                PickerOnly={false}
                                InputFormat="MM"
                                FieldMaxWidth="100px"
                            />
                        </div>
                        <div className={classes.FormField} style={{width: "150px", marginLeft: "0px"}}>
                            <FormCompontent
                                Name="WeekPicker"
                                InitialValue={InitialFormValues.WeekPicker ? InitialFormValues.WeekPicker : null}
                                FormValue={FormValues.WeekPicker ? FormValues.WeekPicker : null}
                                OutputValue={(values) => HandleUpdateFormValues("WeekPicker", values)}
                                Component="DatePicker"
                                Variant="OutlinedLabel"
                                Label="Week"
                                NoMessage={false}
                                PickerDateType="Year"
                                PickerOnly={false}
                                InputFormat="yyyy"
                                FieldMaxWidth="100px"
                            />
                        </div>
                        <div className={classes.FormField} style={{width: "300px", marginLeft: "0px"}}>
                            <FormCompontent
                                Name="Day"
                                InitialValue={InitialFormValues.Day ? InitialFormValues.Day : null}
                                FormValue={FormValues.Day ? FormValues.Day : null}
                                OutputValue={(values) => HandleUpdateFormValues("Day", values)}
                                Component="DatePicker"
                                Variant="OutlinedLabel"
                                Label="Day"
                                NoMessage={false}
                                PickerDateType="Calendar"
                                PickerOnly={true}
                                // InputFormat="yyyy/MM/dd"
                                InputFormat="E - yyyy/MM/dd"
                                // LabelMaxWidth="200px"
                                // LabelAlign="Left"
                                ExternalToolbarLeft={<ArrowFilledLeft onClick={() => HandleChangeDay("Previous")} />}
                                ExternalToolbarRight={<ArrowFilledRight onClick={() => HandleChangeDay("Next")} />}
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{width: "200px", marginLeft: "0px"}}>
                            <FormCompontent
                                Name="From"
                                InitialValue={InitialFormValues.From ? InitialFormValues.From : null}
                                FormValue={FormValues.From ? FormValues.From : null}
                                OutputValue={(values) => HandleUpdateFormValues("From", values)}
                                Component="DatePicker"
                                Variant="OutlinedLabel"
                                Label="From"
                                NoMessage={false}
                                PickerDateType="Calendar"
                                PickerOnly={true}
                                // InputFormat="yyyy/MM/dd"
                                InputFormat="yyyy/MM/dd"
                                FieldMaxWidth="150px"
                                // LabelMaxWidth="200px"
                                // LabelAlign="Left"
                                // ExternalToolbarLeft={<ArrowFilledLeft onClick={() => HandleChangeDay("Previous")} />}
                                // ExternalToolbarRight={<ArrowFilledRight onClick={() => HandleChangeDay("Next")} />}
                            />
                        </div>
                        <div className={classes.FormField} style={{width: "200px", marginLeft: "0px"}}>
                            <FormCompontent
                                Name="To"
                                InitialValue={InitialFormValues.To ? InitialFormValues.To : null}
                                FormValue={FormValues.To ? FormValues.To : null}
                                OutputValue={(values) => HandleUpdateFormValues("To", values)}
                                Component="DatePicker"
                                Variant="OutlinedLabel"
                                Label="To"
                                NoMessage={false}
                                PickerDateType="Calendar"
                                PickerOnly={true}
                                // InputFormat="yyyy/MM/dd"
                                InputFormat="yyyy/MM/dd"
                                FieldMaxWidth="150px"
                                // LabelMaxWidth="200px"
                                // LabelAlign="Left"
                                // ExternalToolbarLeft={<ArrowFilledLeft onClick={() => HandleChangeDay("Previous")} />}
                                // ExternalToolbarRight={<ArrowFilledRight onClick={() => HandleChangeDay("Next")} />}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.ButtonBarRight}>
                    <div className={classes.FormField} style={{flex: "0 0 540px"}}>
                        <FormCompontent
                            Name="Company"
                            InitialValue={InitialFormValues.Company ? InitialFormValues.Company : null}
                            FormValue={FormValues.Company ? FormValues.Company : null}
                            OutputSelectedValues={(values) => handleSelectChange("Company", values)}
                            SelectOptions={FieldOptions.Company}
                            Component="Select"
                            Variant="OutlinedLabel"
                            Label="Company"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            Clearable={false}
                            // SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="130px"
                            FieldMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding="0px 5px"
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            <div className={classes.Main}>
                <div className={classes.Toolbar}>{FormComponent}</div>
                <div className={classes.SearchButtons}>
                    <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleFilter}>
                        Apply Filters
                    </Button>
                    <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleResetFilter} style={{marginLeft: "10px"}}>
                        Reset Filters
                    </Button>
                </div>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                {FinalIndicatorComponent}
                <div className={classes.ExportButton}>
                    <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="100px" onClick={HandleExport}>
                        Export
                    </Button>
                </div>

                <div className={classes.TableWrapper}>{FinalTableComponent}</div>
            </div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default DailyTimeSheets;
