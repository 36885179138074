import {useContext} from "react";
import {NavSideBarContext} from "./navSideBarContext";

function updateObjectInArray(array, updObj, key) {
    //If empty
    if (array.length === 0) {
        const newArray = [...array, updObj];
        return {UpdatedArray: newArray, HasOpenSublink: updObj.statusOpen};
    }
    //Check if exist
    var UpdatedArray = [];
    var HasOpenLink = false;
    array.forEach((elem) => {
        if (elem[key] === updObj[key]) {
            //update the element
            UpdatedArray.push(updObj);
            if (updObj.statusOpen === true) {
                HasOpenLink = true;
            }
        } else {
            //Don't touch the element
            UpdatedArray.push(elem);
            if (elem.statusOpen === true) {
                HasOpenLink = true;
            }
        }
    });
    //Push new element if not existing
    const ObjExist = array.filter((elem) => elem[key] === updObj[key]);
    if (ObjExist.length === 0) {
        UpdatedArray.push(updObj);
        if (updObj.statusOpen === true) {
            HasOpenLink = true;
        }
    }
    return {UpdatedArray: UpdatedArray, HasOpenSublink: HasOpenLink};
}

const useNavSideBarContext = () => {
    const [state, setState] = useContext(NavSideBarContext);

    function updateAccordionStatusContext(name, statusOpen) {
        let UpdObj = {name: name, statusOpen: statusOpen};
        const UpdatedStatus = updateObjectInArray(state.AccordionsStatus, UpdObj, "name");
        setState((state) => ({...state, AccordionsStatus: UpdatedStatus.UpdatedArray, HasOpenSubLink: UpdatedStatus.HasOpenSublink}));
    }

    function SetSideBarMinimizedStatus(status) {
        setState((state) => ({...state, ...{SideBarMinimizedStatus: status}}));
    }
    function initAccordionContext(name, statusOpen) {
        let UpdObj = {name: name, statusOpen: statusOpen};
        let NewArray = state.AccordionsStatus.slice();
        NewArray.splice(0, 0, UpdObj);
        setState((state) => ({...state, AccordionsStatus: [...state.AccordionsStatus, ...NewArray], HasOpenSubLink: statusOpen}));
    }

    function CollapseExpandAccordions(arg) {
        //Need to update all accordions statusOpen and set all clicked status to false
        const collapseStatus = arg === "Collapse" ? false : true;
        function updateStatus() {
            return state.AccordionsStatus.map((elem) => {
                return {name: elem.name, statusOpen: collapseStatus};
            });
        }
        setState((state) => ({...state, AccordionsStatus: updateStatus(), HasOpenSubLink: collapseStatus}));
    }
    function OpenAccordionWhenRouteMatches(name) {
        //Find the corresponding Accordion and update it

        function updateStatus() {
            return state.AccordionsStatus.map((elem) => {
                if (elem.name === name) {
                    return {name: elem.name, statusOpen: true};
                } else {
                    return elem;
                }
            });
        }

        setState((state) => ({...state, AccordionsStatus: updateStatus(), HasOpenSubLink: true}));
    }
    function ResetTheContext() {
        let ResetAccordionsStatus = {AccordionsStatus: []};
        setState(ResetAccordionsStatus);
    }

    return {
        updateAccordionStatusContext,
        initAccordionContext,
        CollapseExpandAccordions,
        OpenAccordionWhenRouteMatches,
        ResetTheContext,
        SetSideBarMinimizedStatus,
        AllAccordionsStatus: state.AccordionsStatus,
        HasOpenSubLink: state.HasOpenSubLink,
        SideBarMinimizedStatus: state.SideBarMinimizedStatus,
    };
};

export default useNavSideBarContext;
