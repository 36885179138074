/* eslint-disable */
import React, {useState, useRef, useEffect, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import FormDev from "../../../../../../../artibulles-cis/FormDev/FormDev";
import Button from "@artibulles-cis/react/Button";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";
import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../../artibulles-cis/CheckBox/CheckBox";
import {formatISO} from "date-fns";
import clsx from "clsx";
import {CheckEUVatCode} from "../../../../../../../artibulles-cis/utils/vatEUCodeCheck";
const {format} = require("date-fns");
import {Company, CheckBoxCircularFilled, CloseIcon} from "@artibulles-cis/react-icons";
import VATNumberValidation from "./VATNumberValidation";
import CustomerNameValidation from "./CustomerNameValidation";
import CustomerEditDetailsTabNav from "./CustomerEditDetailsTabNav";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        overflow: "hidden",
        textAlign: "left",
    },
    FormMainScroll: {
        // flex: "0 0 1330px",
        height: "100%",
        overflowY: "hidden",
        boxSizing: "border-box",
        // padding: "0px 10px",
        // border: "1px solid grey",
        // maxWidth: "1400px",
    },
    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        height: "35px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        height: "35px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "0 0 800px",
        width: "1200px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 0px 0px 0px",
        fontSize: "0.8rem",
    },
    FormTitleRightInfo: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 45px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 5px 0px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
    InvoiceDetailsSection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // maxWidth: "1400px",
        // padding: "10px 10px 0px 10px",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 5px",
    },
    ButtonValidate: {
        padding: "0px 5px",
        marginLeft: "5px",
    },
    ButtonArrowStart: {
        background: "none",
        // padding: "0px 16px",
        padding: "0px 10px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            // right: "-5px",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            // borderLeft: "50px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowStart_BeforeActive: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            // borderLeft: "5px solid rgb(66, 165, 245)",
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
    },
    ButtonArrowStart_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            // borderLeft: "5px solid rgb(66, 165, 245)",
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
    },
    ButtonArrowMiddle: {
        background: "none",
        // padding: "0px 16px",
        // padding: "0px 12px",
        padding: "0px 8px 0px 14px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            // right: "-5px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "16px solid transparent",
            borderBottom: "16px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            // borderLeft: "5px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowMiddle_BeforeActive: {
        background: "rgb(150, 201, 242)",
        "&:after": {
            borderLeft: "10px solid rgb(150, 201, 242)",
        },
        "&:before": {
            borderLeft: "10px solid rgb(150, 201, 242)",
        },
    },
    ButtonArrowMiddle_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },

    ButtonArrowEnd: {
        background: "transparent",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowEnd_Active: {
        background: "rgb(66, 165, 245)",

        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            // right: "-5px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid rgb(66, 165, 245)",
            zIndex: "10",
        },
    },
    TitleButtons: {
        fontSize: "0.9rem",
        padding: "0px",
        margin: "0px",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /*********** REFS ***********/
    const Ref_CustomerStatus = useRef(null);

    const Ref_VatValidationHistory = useRef([]);
    const Ref_FinalVATValidation = useRef({VATNumber: null, ValidationDateIso: null, ValidationDate: null});

    /*********** REFS ***********/
    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    const [ValidateVAT, setValidateVAT] = useState(false);
    const [IsValidVAT, setIsValidVAT] = useState(false);

    //Allowable Action Buttons
    const [DenyValidateVATVies, setDenyValidateVATVies] = useState(true);
    const [DenyValidateVATViesDetails, setDenyValidateVATViesDetails] = useState({
        VatNumberStructure: "Error",
        InvalidVATVIES: "Error",
        AlreadyCheckedVIES: "Error",
    });

    const [DenyCreateCustomer, setDenyCreateCustomer] = useState(true);
    const [DenyCreateCustomerDetails, setDenyCreateCustomerDetails] = useState({
        company: "Error",
        vat_number: "Error",
    });
    const [ValidateCustomerName, setValidateCustomerName] = useState(false);
    const [CustomerSimilarNameTableComponent, setCustomerSimilarNameTableComponent] = useState(null);

    const [DenySaveDraft, setDenySaveDraft] = useState(false);
    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);
    useEffect(() => {
        if (InitialFormValues && InitialFormValues.Customer_Status) {
            Ref_CustomerStatus.current = InitialFormValues.Customer_Status;
        }
        // if (InitialFormValues && InitialFormValues.InitialAddress) {
        //     let SupplierAddressComp = (
        //         <div className={classes.SupplierAddress}>
        //             <div className={classes.SupplierAddressLine}>{InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.Line1 : ""}</div>
        //             <div className={classes.SupplierAddressLine} style={{display: `${InitialFormValues.InitialAddress ? (InitialFormValues.InitialAddress.Line2 ? null : "none") : "none"}`}}>
        //                 {InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.Line2 : ""}
        //             </div>
        //             <div className={classes.SupplierAddressLine}>{InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.ZipCity : ""}</div>
        //             <div className={classes.SupplierAddressLine}>{InitialFormValues.InitialAddress ? InitialFormValues.InitialAddress.Country : ""}</div>
        //         </div>
        //     );
        //     setSupplierAddressComponent(SupplierAddressComp);
        // }
        // if (InitialFormValues.IsAutoGenerated === true) {
        //     setInvoiceGenerationMethod("Automatically");
        // } else if (InitialFormValues.IsAutoGenerated === false) {
        //     setInvoiceGenerationMethod("Manually");
        // }
    }, [InitialFormValues]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "ConditionalSelect1") {
            //We update the Options of the ConditionalSelect2
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
                if (elem.ref_ConditionalSelect1 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
            //We also need to reset the Conditional 2;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** HANDLING INPUT ENTER EXIT  *****************/
    const HandleFieldStatusChanged = (field, status) => {
        if (field === "company") {
            if (status === "Left") {
                // console.log(field, ": ", status);
                if (FormValues.company && FormValues.company.length > 2) {
                    setValidateCustomerName(true);
                }
            }
        }
    };
    /**************** HANDLING INPUT ENTER EXIT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (filesPath, fieldName, originalNames) => {
        if (fieldName) {
            let FullNamesPath = [];
            for (var i = 0; i < filesPath.length; i++) {
                FullNamesPath.push({serverPath: filesPath[i], originalName: originalNames[i]});
            }
            FilesUploaders[fieldName] = FullNamesPath;
            console.log("FilesUploaders", FilesUploaders);
            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES EXCEPTED FOR THE CREATE SELECT MULTI
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    if (key === "credit_card_Location_Keywords" || key === "credit_card_Merchant_Keywords") {
                        //We need to retrieve the label and not the value
                        let Final_credit_card_Location_Keywords = [];
                        if (FormValues.credit_card_Location_Keywords) {
                            for (let i = 0; i < FormValues.credit_card_Location_Keywords.length; i++) {
                                let Entry = _.find(FieldOptions.credit_card_Location_Keywords, {value: FormValues.credit_card_Location_Keywords[i]});

                                Final_credit_card_Location_Keywords.push(Entry.label);
                            }
                        }

                        FinalSubmittedValues = {...FinalSubmittedValues, ...{credit_card_Location_Keywords: Final_credit_card_Location_Keywords}};

                        let Final_credit_card_Merchant_Keywords = [];
                        if (FormValues.credit_card_Merchant_Keywords) {
                            for (let i = 0; i < FormValues.credit_card_Merchant_Keywords.length; i++) {
                                let Entry = _.find(FieldOptions.credit_card_Merchant_Keywords, {value: FormValues.credit_card_Merchant_Keywords[i]});

                                Final_credit_card_Merchant_Keywords.push(Entry.label);
                            }
                        }

                        FinalSubmittedValues = {...FinalSubmittedValues, ...{credit_card_Merchant_Keywords: Final_credit_card_Merchant_Keywords}};
                    } else {
                        //Get the IntialValue
                        let InitialValue = InitialFormValues[key];
                        let Value = FormValues[key];
                        if (InitialValue !== Value && key) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                        }
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        if (field === "company") {
            if (value) {
                delete UpdatedFormErrors.company;
            }
        }

        if (field === "vat_number") {
            if (value) {
                delete UpdatedFormErrors.vat_number;
            }
        }

        if (Ref_CustomerStatus.current === "Draft" || Ref_CustomerStatus.current === null) {
            console.log("Field Touching in Draft Mode");
            let UpdatedDenyCreateCustomerDetails = {...DenyCreateCustomerDetails};
            let UpdateDenyValidateVATViesDetails = {...DenyValidateVATViesDetails};
            if (field === "company") {
                if (!value) {
                    UpdatedDenyCreateCustomerDetails.company = "Error";
                } else {
                    delete UpdatedDenyCreateCustomerDetails.company;
                }
            }
            if (field === "vat_number") {
                if (!value) {
                    UpdateDenyValidateVATViesDetails.VatNumberStructure = "Error";
                } else {
                    //We check that the VAT Number is correct, otherwise we display an error.
                    //We first check that there are at least 10 characters (Country code(2) + 8 digits)
                    // BE0755717003 caca
                    if (value.length > 10) {
                        //We don't want to validate multiple times a number due to VIES Server limitations
                        let ExistValidation;
                        if (Ref_VatValidationHistory.current && Array.isArray(Ref_VatValidationHistory.current) && Ref_VatValidationHistory.current.length > 0) {
                            ExistValidation = _.find(Ref_VatValidationHistory.current, {vat_number: value});
                        }

                        if (ExistValidation) {
                            //Already exist -> We Update the last validation information

                            if (ExistValidation.valid === true) {
                                console.log("Previous Validation ok");
                                //Previously checked and valid
                                Ref_FinalVATValidation.current.VATNumber = value;
                                Ref_FinalVATValidation.current.ValidationDateIso = new Date(Date.now());
                                Ref_FinalVATValidation.current.ValidationDate = format(new Date(Date.now()), "dd/MMM/yyyy");
                                //Prevent revalidation button

                                delete UpdateDenyValidateVATViesDetails.VatNumberStructure;
                                delete UpdateDenyValidateVATViesDetails.InvalidVATVIES;
                                UpdateDenyValidateVATViesDetails.AlreadyCheckedVIES = "true";
                                setDenyValidateVATVies(true);
                                setDenyValidateVATViesDetails(UpdateDenyValidateVATViesDetails);
                                delete UpdatedDenyCreateCustomerDetails.vat_number;
                            } else {
                                console.log("Previous Validation Not ok");
                                //Previously checked and invalid
                                Ref_FinalVATValidation.current.VATNumber = null;
                                Ref_FinalVATValidation.current.ValidationDateIso = null;
                                Ref_FinalVATValidation.current.ValidationDate = null;
                                //Prevent revalidation button
                                delete UpdateDenyValidateVATViesDetails.VatNumberStructure;
                                UpdateDenyValidateVATViesDetails.InvalidVATVIES = "Error";
                                UpdateDenyValidateVATViesDetails.AlreadyCheckedVIES = "true";
                                setDenyValidateVATVies(true);
                                setDenyValidateVATViesDetails(UpdateDenyValidateVATViesDetails);
                                UpdatedDenyCreateCustomerDetails.vat_number = "Error";
                            }
                        } else {
                            //Does not exist -> We check the number structure
                            let IsVatCorrect = CheckEUVatCode(value);

                            if (IsVatCorrect === true) {
                                console.log("New VAT structure is correct");
                                //We allow the validation button
                                delete UpdateDenyValidateVATViesDetails.VatNumberStructure;
                                UpdateDenyValidateVATViesDetails.InvalidVATVIES = "Error";
                                UpdateDenyValidateVATViesDetails.AlreadyCheckedVIES = "false";
                                setDenyValidateVATViesDetails(UpdateDenyValidateVATViesDetails);
                                setDenyValidateVATVies(false);
                            } else {
                                console.log("New VAT structure is not correct");
                                //We don't allow the valdiation Button and display an error
                                UpdateDenyValidateVATViesDetails.VatNumberStructure = "Error";
                                UpdateDenyValidateVATViesDetails.InvalidVATVIES = "Error";
                                UpdateDenyValidateVATViesDetails.AlreadyCheckedVIES = "false";
                                setDenyValidateVATViesDetails(UpdateDenyValidateVATViesDetails);
                                setDenyValidateVATVies(true);
                                UpdatedFormErrors.vat_number = "Invalid vat number structure";
                            }
                            UpdatedDenyCreateCustomerDetails.vat_number = "Error";
                        }
                    } else {
                        UpdatedFormErrors.vat_number = "Invalid vat number structure";
                    }
                }
            }
            console.log("UpdatedDenyCreateCustomerDetails", UpdatedDenyCreateCustomerDetails);
            setDenyCreateCustomerDetails(UpdatedDenyCreateCustomerDetails);
            if (Object.keys(UpdatedDenyCreateCustomerDetails).length > 0) {
                setDenyCreateCustomer(true);
            } else {
                setDenyCreateCustomer(false);
            }
        }

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        if (Object.keys(UpdatedFormErrors).length > 0) {
            setFormErrors(UpdatedFormErrors);
            setInvalid(true);
        } else {
            setFormErrors({});
            setInvalid(false);
        }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };
    const HandleCreateInitialInvoice = () => {
        HandleSubmitForm("SaveDraft");
    };

    const HandleDetailsChanged = () => {
        HandleSubmitForm("SaveDraft");
    };
    const HandleValidateVAT = () => {
        setValidateVAT(true);
    };
    const VATValidationResponse = (data) => {
        // caca
        console.log("API Validate VAT response", data);
        setValidateVAT(false);

        if (data) {
            if (data.Valid === true) {
                setIsValidVAT(true);
                let UpdatedDenyCreateCustomerDetails = {...DenyCreateCustomerDetails};
                let UpdateDenyValidateVATViesDetails = {...DenyValidateVATViesDetails};
                Ref_VatValidationHistory.current.push({vat_number: FormValues.vat_number, valid: true});

                delete UpdateDenyValidateVATViesDetails.InvalidVATVIES;
                UpdateDenyValidateVATViesDetails.AlreadyCheckedVIES = "true";
                setDenyValidateVATVies(true);
                setDenyValidateVATViesDetails(UpdateDenyValidateVATViesDetails);
                Ref_FinalVATValidation.current = {VATNumber: FormValues.vat_number, ValidationDateIso: new Date(Date.now()), ValidationDate: format(new Date(Date.now()), "dd/MMM/yyyy")};
                delete UpdatedDenyCreateCustomerDetails.vat_number;
                setDenyCreateCustomerDetails(UpdatedDenyCreateCustomerDetails);
            } else {
                setIsValidVAT(false);
                let UpdatedDenyCreateCustomerDetails = {...DenyCreateCustomerDetails};
                let UpdateDenyValidateVATViesDetails = {...DenyValidateVATViesDetails};
                Ref_VatValidationHistory.current.push({vat_number: FormValues.vat_number, valid: false});
                UpdateDenyValidateVATViesDetails.InvalidVATVIES = "Error";
                UpdateDenyValidateVATViesDetails.AlreadyCheckedVIES = "true";
                setDenyValidateVATVies(true);
                setDenyValidateVATViesDetails(UpdateDenyValidateVATViesDetails);

                Ref_FinalVATValidation.current = {VATNumber: null, ValidationDateIso: null, ValidationDate: null};
                UpdatedDenyCreateCustomerDetails.vat_number = "Error";
                setDenyCreateCustomerDetails(UpdatedDenyCreateCustomerDetails);

                UpdatedDenyCreateCustomerDetails.IsValidVATNumber = "Error";

                if (InitialFormValues.ApiID === "new") {
                } else {
                    HandleSubmitForm("RefreshForm");
                }
            }
        }
    };

    const HandleCustomerExistCheck = (Answer) => {
        console.log("Answer", Answer);
        if (Answer && Answer.Exist === false) {
            setValidateCustomerName(false);
            if (Answer.SimilarCompaniesComponent) {
                setCustomerSimilarNameTableComponent(Answer.SimilarCompaniesComponent);
            } else {
                setCustomerSimilarNameTableComponent(null);
            }
            let UpdatedDenyCreateCustomerDetails = {...DenyCreateCustomerDetails};
            delete UpdatedDenyCreateCustomerDetails.company;
            setDenyCreateCustomerDetails(UpdatedDenyCreateCustomerDetails);
            setInvalid(false);
            // Caca
        } else {
            setValidateCustomerName(false);
            setCustomerSimilarNameTableComponent(null);
            setFormErrors({...FormErrors, ...{company: "This name already exist"}});
            setDenyCreateCustomerDetails({...DenyCreateCustomerDetails, ...{company: "Error"}});
            setInvalid(true);
        }

        // Prout
    };

    const HandleSaveDraft = () => {
        console.log("Save Draft");
    };
    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let ValidateVATNumberComponent;
    if (ValidateVAT === true) {
        ValidateVATNumberComponent = (
            <VATNumberValidation CustomerStatus={Ref_CustomerStatus.current} CompanyId={FormValues.ApiID} VATNumber={FormValues.vat_number} VATValidationResponse={VATValidationResponse} />
        );
    } else {
        ValidateVATNumberComponent = null;
    }

    let VATNumberValidationComponent, IconVatNumberValid;
    if (FormValues.ApiID === "new") {
        console.log("APi New");
        //caca
        // const Ref_FinalVATValidation = useRef({VATNumber: null, ValidationDateIso: null, ValidationDate: null});
        if (FormValues.vat_number) {
            if (DenyValidateVATVies === false) {
                console.log("deny validating false");
                VATNumberValidationComponent = (
                    <div style={{display: "flex", margin: "0px", display: "flex"}}>
                        <div className={classes.FormField} style={{flex: "0 0 110px", margin: "0px 15px 0px 5px", justifyContent: "flex-end", display: "flex"}}>
                            <Button Height="25px" Width="140px" ClassExtendButton={classes.ButtonValidate} onClick={HandleValidateVAT}>
                                Validate online
                            </Button>
                        </div>
                    </div>
                );
                IconVatNumberValid = <CloseIcon IconSize="25px" Frame="circle" InnerPadding="30px" FrameFillColor="red" SVGStrokeWidth="8px" />;
            } else {
                console.log("DenyValidateVATViesDetails", DenyValidateVATViesDetails);
                if (!DenyValidateVATViesDetails.InvalidVATVIES && DenyValidateVATViesDetails.AlreadyCheckedVIES === "true") {
                    VATNumberValidationComponent = (
                        <div style={{display: "flex", margin: "0px", display: "flex"}}>
                            <div style={{fontSize: "0.7rem", display: "flex", alignItems: "center"}}>{`Validated on: ${Ref_FinalVATValidation.current.ValidationDate}`}</div>
                        </div>
                    );
                    IconVatNumberValid = <CheckBoxCircularFilled IconSize="25px" SVGFillColor="green" />;
                } else {
                    IconVatNumberValid = <CloseIcon IconSize="25px" Frame="circle" InnerPadding="30px" FrameFillColor="red" SVGStrokeWidth="8px" />;
                }
            }

            // if (FormValues.IsValidVATNumber === true) {
            //     IconVatNumberValid = <CheckBoxCircularFilled IconSize="25px" SVGFillColor="green" />;
            // } else {
            //     IconVatNumberValid = <CloseIcon IconSize="25px" Frame="circle" InnerPadding="30px" FrameFillColor="red" SVGStrokeWidth="8px" />;
            // }
        } else {
            VATNumberValidationComponent = null;
            IconVatNumberValid = null;
        }
    } else {
    }

    let FormTitle, DetailsComponent;

    if (InitialFormValues.ApiID === "new") {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitleMain}>
                    <div className={classes.FormTitle_Box}>
                        <div className={classes.FormTitle_Logo_Wrapper}>
                            <Company IconSize="30px" SVGFillColor="white" />
                        </div>
                        <div className={classes.FormTitle_Title_Wrapper}>
                            <h1 className={classes.FormTitle_Title}>Customer</h1>
                            <div className={classes.ActionButton}>
                                <Button ClassExtendButton={classes.TitleButtons} Height="30px" Width="140px" onClick={HandleCreateInitialInvoice} disabled={Invalid || Pristine || DenyCreateCustomer}>
                                    Create Customer
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.FormTitleRightStatus}>
                        <div className={clsx(classes.ButtonArrowStart, classes.ButtonArrowStart_Active)} data-tip data-for="DraftIndicator">
                            Draft
                        </div>
                        <ReactTooltip id="DraftIndicator" place="bottom" type="dark" effect="solid">
                            <p className={classes.tooltipP}>Deletable</p>
                        </ReactTooltip>

                        <div className={clsx(classes.ButtonArrowMiddle)} data-tip data-for="InoicableIndicator">
                            Inoicable
                        </div>
                        <ReactTooltip id="InoicableIndicator" place="bottom" type="dark" effect="solid">
                            <p className={classes.tooltipP}>Can be used for Invoicing</p>
                        </ReactTooltip>
                        <div className={clsx(classes.ButtonArrowMiddle)} data-tip data-for="LockedIndicator">
                            Locked
                        </div>
                        <ReactTooltip id="LockedIndicator" place="bottom" type="dark" effect="solid">
                            <p className={classes.tooltipP}>Not deletable - limited editing</p>
                        </ReactTooltip>
                    </div>
                </div>
            </div>
        );

        DetailsComponent = (
            <div className={classes.InvoiceDetailsSection}>
                <div className={classes.InvoiceDetailsTabNavWrapper} style={{marginTop: "5px", borderTop: "1px solid grey"}}>
                    Create Customer to add additional details
                </div>
            </div>
        );
    } else {
        DetailsComponent = (
            <div className={classes.InvoiceDetailsSection}>
                <div className={classes.InvoiceDetailsTabNavWrapper}>
                    <CustomerEditDetailsTabNav
                        CustomerID={InitialFormValues.ApiID}
                        HandleDetailsChanged={HandleDetailsChanged}
                        FormViewMode={FormViewMode}
                        FormValues={FormValues}
                        FieldOptions={FieldOptions}
                    />
                </div>
            </div>
        );

        let DraftClass, InvoicableClass, LockedClass;
        let ActionButton1Component, ActionButton2Component, ActionButton3Component;
        if (InitialFormValues.Customer_Status === "Draft") {
            //Draft
            DraftClass = clsx(classes.ButtonArrowStart, classes.ButtonArrowStart_Active);
            InvoicableClass = clsx(classes.ButtonArrowMiddle);
            LockedClass = clsx(classes.ButtonArrowEnd);
            ActionButton1Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" disabled={Pristine || DenySaveDraft} onClick={HandleSaveDraft}>
                    Save Draft
                </Button>
            );
        } else if (InitialFormValues.Customer_Status === "Invoicable") {
            DraftClass = clsx(classes.ButtonArrowStart);
            InvoicableClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
            LockedClass = clsx(classes.ButtonArrowEnd);

            // ActionButton1Component = (
            //     <Button ClassExtendButton={classes.TitleButtons} Width="100px" Height="30px" disabled={Pristine} onClick={HandleSaveDraft}>
            //         Save
            //     </Button>
            // );
            // ActionButton2Component = (
            //     <Button
            //         ClassExtendButton={classes.TitleButtons}
            //         Width="90px"
            //         Height="30px"
            //         disabled={DenyPostInvoice}
            //         onClick={HandlePostInvoice}
            //         // disabled={DisablePost}
            //     >
            //         Post
            //     </Button>
            // );
        } else if (InitialFormValues.Customer_Status === "Locked") {
            //Invoiced
            DraftClass = clsx(classes.ButtonArrowStart);
            InvoicableClass = clsx(classes.ButtonArrowMiddle);
            LockedClass = clsx(classes.ButtonArrowEnd, classes.ButtonArrowEnd_Active);

            // ActionButton1Component = (
            //     <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleRevertToDraft}>
            //         Revert to Draft
            //     </Button>
            // );
        }

        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitleMain}>
                    <div className={classes.FormTitle_Box}>
                        <div className={classes.FormTitle_Logo_Wrapper}>
                            <Company IconSize="30px" SVGFillColor="white" />
                        </div>
                        <div className={classes.FormTitle_Title_Wrapper}>
                            <h1 className={classes.FormTitle_Title}>Customer</h1>
                            <div className={classes.ActionButton}>{ActionButton1Component}</div>
                            <div className={classes.ActionButton}>{ActionButton2Component}</div>
                            <div className={classes.ActionButton}>{ActionButton3Component}</div>
                        </div>
                    </div>
                    <div className={classes.FormTitleRightStatus}>
                        <div className={DraftClass} data-tip data-for="DraftIndicator">
                            Draft
                        </div>
                        <ReactTooltip id="DraftIndicator" place="bottom" type="dark" effect="solid">
                            <p className={classes.tooltipP}>No Number - Editable & Deletable</p>
                        </ReactTooltip>
                        <div className={InvoicableClass} data-tip data-for="InvoicableIndicator">
                            Invoicable
                        </div>
                        <ReactTooltip id="InvoicableIndicator" place="bottom" type="dark" effect="solid">
                            <p className={classes.tooltipP}>Invoice Legal Number created - Editable but not deletable</p>
                        </ReactTooltip>

                        <div className={LockedClass} data-tip data-for="LockedIndicator">
                            Locked
                        </div>
                        <ReactTooltip id="LockedIndicator" place="bottom" type="dark" effect="solid">
                            <p className={classes.tooltipP}>Payment made - not editable</p>
                        </ReactTooltip>
                    </div>
                </div>
                <div className={classes.FormTitleRightInfo}>
                    <div className={classes.FormTitleRightStatus_RecordInfo}>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Line}>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Title}>CreatedAt :</div>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Value}>{InitialFormValues.createdAt} </div>
                        </div>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Line}>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Title}>LastUpdated :</div>
                            <div className={classes.FormTitleRightStatus_RecordInfo_Value}>{InitialFormValues.updatedAt} </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let CustomerNameValidationComponent;
    if (ValidateCustomerName === true) {
        // Prout
        CustomerNameValidationComponent = <CustomerNameValidation CustomerName={FormValues.company} HandleCustomerExistCheck={HandleCustomerExistCheck} />;
    } else {
        CustomerNameValidationComponent = null;
    }

    let FinalFormComponent;

    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.FormWrapper}>
                {FormTitle}
                <div className={classes.FormMainScroll}>
                    <PerfectScrollBarWrapper Active={true}>
                        <div className={classes.FormLeft}>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 520px"}}>
                                    <FormCompontent
                                        Name="company"
                                        InitialValue={InitialFormValues.company ? InitialFormValues.company : null}
                                        FormValue={FormValues.company ? FormValues.company : null}
                                        OutputValue={(values) => HandleUpdateFormValues("company", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Company"
                                        NoMessage={false}
                                        meta={{error: FormErrors.company, invalid: FormErrors.company ? true : false}}
                                        OnFieldExit={(status) => HandleFieldStatusChanged("company", status)}
                                        OutputError={(error) => HandleUpdateErrors("company", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"
                                        Required={true}
                                        LabelMaxWidth="110px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="400px"
                                    />
                                </div>

                                <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                    <FormCompontent
                                        Name="ref_companySiteType"
                                        InitialValue={InitialFormValues.ref_companySiteType ? InitialFormValues.ref_companySiteType : null}
                                        FormValue={FormValues.ref_companySiteType ? FormValues.ref_companySiteType : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_companySiteType", values)}
                                        SelectOptions={FieldOptions.ref_companySiteType}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Site Type"
                                        NoMessage={false}
                                        meta={{error: FormErrors.ref_companySiteType, invalid: FormErrors.ref_companySiteType ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("ref_companySiteType", error)}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="70px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="220px"
                                    />
                                </div>
                            </div>
                            <div
                                className={classes.FormMultilineFlex}
                                style={{marginLeft: "7px", display: CustomerSimilarNameTableComponent ? "flex" : "none", height: "150px", maxHeight: "150px", width: "820px"}}
                            >
                                {CustomerSimilarNameTableComponent}
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 821px"}}>
                                    <FormCompontent
                                        Name="description"
                                        InitialValue={InitialFormValues.description ? InitialFormValues.description : null}
                                        FormValue={FormValues.description ? FormValues.description : null}
                                        OutputValue={(values) => HandleUpdateFormValues("description", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Description"
                                        NoMessage={false}
                                        meta={{error: FormErrors.description, invalid: FormErrors.description ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("description", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"
                                        LabelMaxWidth="110px"
                                        FieldMaxWidth="932px"
                                        multiline={true}
                                        RowsToDisplay="2"
                                        MaxRows="2"
                                        LabelAlign="Left"
                                        Required={false}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 0 100px", marginLeft: "5px"}}>
                                    <CheckBox
                                        Inputname="is_customer"
                                        label="Customer"
                                        initialvalue={InitialFormValues.is_customer === true ? true : false}
                                        curvalue={FormValues.is_customer}
                                        ShareInputValue={(value) => HandleUpdateFormValues("is_customer", value)}
                                        disabled={true}
                                    />
                                </div>
                                <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 0 300px"}}>
                                    <CheckBox
                                        Inputname="is_supplier"
                                        label="Supplier"
                                        initialvalue={InitialFormValues.is_supplier === true ? true : false}
                                        curvalue={FormValues.is_supplier}
                                        ShareInputValue={(value) => HandleUpdateFormValues("is_supplier", value)}
                                    />
                                </div>
                            </div>

                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                                    <FormCompontent
                                        Name="ref_industry"
                                        InitialValue={InitialFormValues.ref_industry ? InitialFormValues.ref_industry : null}
                                        FormValue={FormValues.ref_industry ? FormValues.ref_industry : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_industry", values)}
                                        SelectOptions={FieldOptions.ref_industry}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Industries"
                                        NoMessage={false}
                                        meta={{error: FormErrors.ref_industry, invalid: FormErrors.ref_industry ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("ref_industry", error)}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="110px"
                                        FieldMaxWidth="200px"
                                        LabelAlign="Left"
                                        ReadOnly={true}
                                    />
                                </div>
                                <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                    <FormCompontent
                                        Name="ref_sector"
                                        InitialValue={InitialFormValues.ref_sector ? InitialFormValues.ref_sector : null}
                                        FormValue={FormValues.ref_sector ? FormValues.ref_sector : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_sector", values)}
                                        SelectOptions={FieldOptions.ref_sector}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Sectors"
                                        NoMessage={false}
                                        meta={{error: FormErrors.ref_sector, invalid: FormErrors.ref_sector ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("ref_sector", error)}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="60px"
                                        FieldMaxWidth="200px"
                                        LabelAlign="Left"
                                        ReadOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex} style={{height: "45px"}}>
                                <div className={classes.FormField} style={{flex: "0 0 440px"}}>
                                    <FormCompontent
                                        Name="vat_number"
                                        InitialValue={InitialFormValues.vat_number ? InitialFormValues.vat_number : null}
                                        FormValue={FormValues.vat_number ? FormValues.vat_number : null}
                                        OutputValue={(values) => HandleUpdateFormValues("vat_number", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="VAT Number"
                                        NoMessage={false}
                                        meta={{error: FormErrors.vat_number, invalid: FormErrors.vat_number ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("vat_number", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"
                                        Required={true}
                                        LabelMaxWidth="110px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="300px"
                                        ExternalToolbarRight={IconVatNumberValid}
                                    />
                                </div>
                                {VATNumberValidationComponent}
                            </div>
                        </div>
                        {DetailsComponent}
                        {ValidateVATNumberComponent}
                        {CustomerNameValidationComponent}
                    </PerfectScrollBarWrapper>
                </div>
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <React.Fragment>{FinalFormComponent}</React.Fragment>;
});
export default Component;
