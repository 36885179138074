/* eslint-disable */
import React, {useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import FormDev from "../../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import ExpandableBox from "../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
/************** NEW COMPONENTS **************/

import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../artibulles-cis/CheckBox/CheckBox";
import FileUploader from "../../../../../../artibulles-cis/FileUploader/FileUploader";
import {ArrowFilledLeft, ArrowFilledRight, AvatarV2, Login} from "@artibulles-cis/react-icons";
import {formatISO} from "date-fns";
import InvoiceGeneratorBankAccountSelector from "./InvoiceGeneratorBankAccountSelector";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        // padding: "10px",
        // height: "100%",
        color: "black",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
        marginBottom: "20px",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "60px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 50px",
        boxSizing: "border-box",
        borderRadius: "3px",
        padding: "4px",
        border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 10px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "22px",
        margin: "0px",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    const Ref_BankAccount_Details = useRef(null);

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    const [SupplierDetailsComponent, setSupplierDetailsComponent] = useState(null);

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "ref_supplier") {
            console.log("SelectedValues", SelectedValues);
            if (SelectedValues) {
                setSupplierDetailsComponent(<InvoiceGeneratorBankAccountSelector Id={SelectedValues} HandleShareAccountInformation={HandleShareAccountInformation} />);
            } else {
                setSupplierDetailsComponent(null);
            }
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        //Conditional Fields
        // if (field === "is_VAT_Intracomm") {
        //     if (value === true) {
        //         //we Delete the values for the VAT amount  and the total without vat
        //         UpdateFormValues.vat_amount = null;
        //         UpdateFormValues.amount_without_vat = null;
        //     }
        // }

        // if (field === "accounting_Justification_needed") {
        //     if (value === false) {
        //         //We clear the justification provided
        //         //we clear the justification date

        //         UpdateFormValues.accounting_Justification_Provided = false;
        //         UpdateFormValues.accounting_Justification_Provided_Date = null;
        //     } else {
        //         UpdateFormValues.accounting_Justification_Provided = false;
        //         UpdateFormValues.accounting_Justification_Provided_Date = formatISO(new Date(Date.now()));
        //     }
        // }

        if (field === "bill_amount") {
            // console.log("bill_amount changed", value);
            if (FormValues.is_VAT_Intracomm === true) {
                UpdateFormValues.vat_rate = 0;
                UpdateFormValues.vat_amount = 0;
                UpdateFormValues.amount_without_vat = value;
            } else {
                if (FormValues.is_VAT_Auto === true) {
                    if (FormValues.vat_rate) {
                        let VATAmount = (FormValues.vat_rate / 100) * value;
                        let amount_without_vat = value - VATAmount;

                        UpdateFormValues.vat_amount = VATAmount;
                        UpdateFormValues.amount_without_vat = amount_without_vat;
                    }
                } else {
                    //Error no VAT RATE
                }
            }
        }
        if (field === "is_VAT_Intracomm") {
            if (value === true) {
                UpdateFormValues.vat_rate = 0;
                UpdateFormValues.vat_amount = 0;
                UpdateFormValues.amount_without_vat = FormValues.bill_amount;
            } else {
                if (FormValues.is_VAT_Auto === true) {
                    //We go back to the initial values
                    UpdateFormValues.vat_rate = InitialFormValues.vat_rate;
                    let VATAmount = (InitialFormValues.vat_rate / 100) * FormValues.bill_amount;
                    let amount_without_vat = FormValues.bill_amount - VATAmount;
                    UpdateFormValues.vat_amount = VATAmount;
                    UpdateFormValues.amount_without_vat = amount_without_vat;
                }
            }
        }
        if (field === "vat_rate") {
            if (FormValues.is_VAT_Intracomm === true) {
                UpdateFormValues.vat_rate = 0;
                UpdateFormValues.vat_amount = 0;
                UpdateFormValues.amount_without_vat = FormValues.bill_amount;
            } else {
                if (FormValues.is_VAT_Auto === true) {
                    if (FormValues.bill_amount) {
                        let VATAmount = (parseFloat(FormValues.bill_amount) * parseFloat(value)) / 100;

                        let amount_without_vat = parseFloat(FormValues.bill_amount) - VATAmount;

                        UpdateFormValues.vat_amount = VATAmount;
                        UpdateFormValues.amount_without_vat = amount_without_vat;
                    }
                } else {
                    //Error no VAT RATE
                }
            }
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (filesPath, fieldName, originalNames) => {
        if (fieldName) {
            let FullNamesPath = [];
            for (var i = 0; i < filesPath.length; i++) {
                FullNamesPath.push({serverPath: filesPath[i], originalName: originalNames[i]});
            }
            FilesUploaders[fieldName] = FullNamesPath;
            // console.log("FilesUploaders", FilesUploaders);
            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    //Get the IntialValue
                    let InitialValue = InitialFormValues[key];
                    let Value = FormValues[key];
                    if (InitialValue !== Value) {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                });
            }
            if (Ref_BankAccount_Details.current) {
                FinalSubmittedValues = {...FinalSubmittedValues, ...Ref_BankAccount_Details.current};
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
        // if (field === "bill_amount") {
        //     console.log("bill_amount changed", value);
        //     if (FormValues.is_VAT_Intracomm === true) {
        //         //vat_rate =0,  vat_amount=0, amount_without_vat = bill_amount
        //         setFormValues({...FormValues, ...{vat_rate: 0, vat_amount: 0, amount_without_vat: value}});
        //     } else {
        //         console.log("no intracom VAT");
        //         if (FormValues.vat_rate) {
        //             //vat_amount=0, amount_without_vat = bill_amount
        //             let VATAmount = FormValues.vat_rate * value;
        //             let amount_without_vat = value - VATAmount;
        // 			VATAmount = VATAmount?VATAmount.toString():null;
        // 			amount_without_vat = amount_without_vat?amount_without_vat.toString():null;
        //             setFormValues({...FormValues, ...{vat_amount: VATAmount, amount_without_vat: amount_without_vat}});
        //         } else {
        //             //Error no VAT RATE
        //         }
        //     }
        //     // setFormValues({...FormValues, ...{[field]: value}});
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };

    const HandleShareAccountInformation = (values) => {
        console.log("Handle Share Values Bank Account", values);

        Ref_BankAccount_Details.current = values;
    };

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let FormTitle;

    if (InitialFormValues.ApiID === "new") {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>Create new bill</h1>
                    </div>
                </div>
            </div>
        );
    } else {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>Bill</h1>
                    </div>
                </div>
            </div>
        );
    }

    var FinalFormComponent;

    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.FormWrapper}>
                {FormTitle}

                <div className={classes.FormLeft}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start", width: "1500px"}}>
                        <div className={classes.FormField} style={{flex: "0 1 300px"}}>
                            <FormCompontent
                                Name="bill_amount"
                                InitialValue={InitialFormValues.bill_amount ? InitialFormValues.bill_amount : null}
                                FormValue={FormValues.bill_amount ? FormValues.bill_amount : null}
                                OutputValue={(values) => HandleUpdateFormValues("bill_amount", values)}
                                Component="Input"
                                Variant="Outlined"
                                Imask={true}
                                Mask={{
                                    Type: "Currency",
                                    Options: {
                                        Currency: "EUR",
                                        // SymbolPosition: "Post",
                                        Scale: 2,
                                    },
                                }}
                                Label="Amount VAT inc."
                                // meta={{error: FormErrors.mask_currency_usd, invalid: FormErrors.mask_currency_usd ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("mask_currency_usd", error)}
                                DisplayMessageIn="Popup"
                                Required={true}
                                LabelMaxWidth="170px"
                                FieldMaxWidth="100px"
                                LabelAlign="Left"
                            />
                        </div>
                        <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 1 150px"}}>
                            <CheckBox
                                Inputname="is_VAT_Intracomm"
                                label="Intracom VAT"
                                initialvalue={InitialFormValues.is_VAT_Intracomm === true ? true : false}
                                curvalue={FormValues.is_VAT_Intracomm}
                                ShareInputValue={(value) => HandleUpdateFormValues("is_VAT_Intracomm", value)}
                            />
                        </div>
                        <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 1 120px"}}>
                            <CheckBox
                                Inputname="is_VAT_Auto"
                                label="Auto VAT"
                                initialvalue={InitialFormValues.is_VAT_Auto === true ? true : false}
                                curvalue={FormValues.is_VAT_Auto}
                                ShareInputValue={(value) => HandleUpdateFormValues("is_VAT_Auto", value)}
                            />
                        </div>

                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="vat_rate"
                                InitialValue={InitialFormValues.vat_rate ? InitialFormValues.vat_rate : null}
                                FormValue={FormValues.vat_rate ? FormValues.vat_rate : null}
                                OutputValue={(values) => HandleUpdateFormValues("vat_rate", values)}
                                Component="Input"
                                Variant="Outlined"
                                Imask={false}
                                Mask={{
                                    Type: "Number",
                                    Options: {
                                        AllowNegative: false,
                                        Max: 100,
                                    },
                                }}
                                Label="VAT Rate"
                                // meta={{error: FormErrors.mask_currency_usd, invalid: FormErrors.mask_currency_usd ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("mask_currency_usd", error)}
                                DisplayMessageIn="Popup"
                                Required={true}
                                LabelMaxWidth="140px"
                                FieldMaxWidth="100px"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="vat_amount"
                                InitialValue={InitialFormValues.vat_amount ? InitialFormValues.vat_amount : null}
                                FormValue={FormValues.vat_amount ? FormValues.vat_amount : null}
                                OutputValue={(values) => HandleUpdateFormValues("vat_amount", values)}
                                Component="Input"
                                Variant="Outlined"
                                Imask={true}
                                Mask={{
                                    Type: "Currency",
                                    Options: {
                                        Currency: "EUR",
                                        // SymbolPosition: "Post",
                                        Scale: 2,
                                    },
                                }}
                                Label="VAT Amount"
                                // meta={{error: FormErrors.mask_currency_usd, invalid: FormErrors.mask_currency_usd ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("mask_currency_usd", error)}
                                DisplayMessageIn="Popup"
                                Required={false}
                                LabelMaxWidth="140px"
                                FieldMaxWidth="100px"
                                ReadOnly={FormValues.is_VAT_Auto===true?true:false}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 250px"}}>
                            <FormCompontent
                                Name="amount_without_vat"
                                InitialValue={InitialFormValues.amount_without_vat ? InitialFormValues.amount_without_vat : null}
                                FormValue={FormValues.amount_without_vat ? FormValues.amount_without_vat : null}
                                OutputValue={(values) => HandleUpdateFormValues("amount_without_vat", values)}
                                Component="Input"
                                Variant="Outlined"
                                Imask={true}
                                Mask={{
                                    Type: "Currency",
                                    Options: {
                                        Currency: "EUR",
                                        // SymbolPosition: "Post",
                                        Scale: 2,
                                    },
                                }}
                                Label="Amount without VAT"
                                // meta={{error: FormErrors.mask_currency_usd, invalid: FormErrors.mask_currency_usd ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("mask_currency_usd", error)}
                                DisplayMessageIn="Popup"
                                Required={false}
                                LabelMaxWidth="200px"
                                FieldMaxWidth="100px"
                                ReadOnly={FormValues.is_VAT_Auto===true?true:false}
                            />
                        </div>
                    </div>
                    <ExpandableBox
                        TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Supplier</h3>}
                        DefaultStyle="List"
                        // TransitionEffect={{Duration : "600ms"}}
                        // MaxWidth="1020px"
                        // CustomController={<InfoCircleBlue />}
                        // CustomControllerNoRotation={true}
                        // BackgroundColorTopBar="grey"
                        // ClassExtendsExpandableBox={classes.ExpandableBox}
                        // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                        // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                        // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                        // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                        // MinWidth="1020px"
                        // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                        MaxHeightOpened="150px"
                        OpenOnTopClick={true}
                        WithPerfectScrollbar={false}
                        WithScrollbar={null}
                        FlexMode={true}
                    >
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="ref_supplier"
                                    InitialValue={InitialFormValues.ref_supplier ? InitialFormValues.ref_supplier : null}
                                    FormValue={FormValues.ref_supplier ? FormValues.ref_supplier : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ref_supplier", values)}
                                    SelectOptions={FieldOptions.ref_supplier}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Supplier"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ref_supplier, invalid: FormErrors.ref_supplier ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ref_supplier", error)}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                    Required={false}
                                />
                            </div>
                        </div>
                        {SupplierDetailsComponent}
                    </ExpandableBox>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="communication_structured"
                            InitialValue={InitialFormValues.communication_structured ? InitialFormValues.communication_structured : null}
                            FormValue={FormValues.communication_structured ? FormValues.communication_structured : null}
                            OutputValue={(values) => HandleUpdateFormValues("communication_structured", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Structured Communication"
                            NoMessage={false}
                            // meta={{error: FormErrors.communication_structured, invalid: FormErrors.communication_structured ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("communication_structured", error)}
                            Imask={true}
                            Mask={{
                                Type: "Pattern",
                                Options: {
                                    Pattern: "+++/000/0000/00000++++",
                                    // SymbolPosition: "Post",
                                    // Scale: 2,
                                },
                            }}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            Required={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="communication_freeFormat"
                            InitialValue={InitialFormValues.communication_freeFormat ? InitialFormValues.communication_freeFormat : null}
                            FormValue={FormValues.communication_freeFormat ? FormValues.communication_freeFormat : null}
                            OutputValue={(values) => HandleUpdateFormValues("communication_freeFormat", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Communication free format"
                            NoMessage={false}
                            // meta={{error: FormErrors.communication_structured, invalid: FormErrors.communication_structured ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("communication_structured", error)}
                            // Imask={true}
                            //     Mask={{
                            //         Type: "Pattern",
                            //         Options: {
                            //             Pattern: "+++/000/0000/00000++++",
                            //             // SymbolPosition: "Post",
                            //             // Scale: 2,
                            //         },
                            //     }}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            Required={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="due_date_ISo"
                            InitialValue={InitialFormValues.due_date_ISo ? InitialFormValues.due_date_ISo : null}
                            FormValue={FormValues.due_date_ISo ? FormValues.due_date_ISo : null}
                            OutputValue={(values) => HandleUpdateFormValues("due_date_ISo", values)}
                            OutputError={(error) => HandleUpdateErrors("due_date_ISo", error)}
                            meta={{error: FormErrors.due_date_ISo, invalid: FormErrors.due_date_ISo ? true : false}}
                            Variant="Outlined"
                            Component="DatePicker"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            NoMessage={false}
                            Label="Due date"
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            DisplayMessageIn="Popup"
                            Required={true}
                        />
                    </div>
                    <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 1 auto"}}>
                        <CheckBox
                            Inputname="is_charged_automatically"
                            label="Charged automatically"
                            initialvalue={InitialFormValues.is_charged_automatically === true ? true : false}
                            curvalue={FormValues.is_charged_automatically}
                            ShareInputValue={(value) => HandleUpdateFormValues("is_charged_automatically", value)}
                        />
                    </div>
					<div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 1 auto"}}>
                        <CheckBox
                            Inputname="is_payement_scheduled"
                            label="Scheduled payment"
                            initialvalue={InitialFormValues.is_payement_scheduled === true ? true : false}
                            curvalue={FormValues.is_payement_scheduled}
                            ShareInputValue={(value) => HandleUpdateFormValues("is_payement_scheduled", value)}
                        />
                    </div>

					
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start", width: "1200px"}}>
                        <div className={classes.FormFieldCheckBoxAligned} style={{flex: "0 1 auto"}}>
                            <CheckBox
                                Inputname="is_paid"
                                label="Is paid"
                                initialvalue={InitialFormValues.is_paid === true ? true : false}
                                curvalue={FormValues.is_paid}
                                ShareInputValue={(value) => HandleUpdateFormValues("is_paid", value)}
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "0 1 300px", display: `${FormValues.is_paid === true ? "flex" : "none"}`}}>
                            <FormCompontent
                                Name="paid_date_ISo"
                                InitialValue={InitialFormValues.paid_date_ISo ? InitialFormValues.paid_date_ISo : null}
                                FormValue={FormValues.paid_date_ISo ? FormValues.paid_date_ISo : null}
                                OutputValue={(values) => HandleUpdateFormValues("paid_date_ISo", values)}
                                OutputError={(error) => HandleUpdateErrors("paid_date_ISo", error)}
                                meta={{error: FormErrors.paid_date_ISo, invalid: FormErrors.paid_date_ISo ? true : false}}
                                Variant="Outlined"
                                Component="DatePicker"
                                PickerDateType="Calendar"
                                PickerOnly={false}
                                InputFormat="dd/MM/yyyy"
                                NoMessage={false}
                                Label="Paid date"
                                LabelMaxWidth="200px"
                                LabelAlign="Left"
                                FieldMaxWidth="100px"
                                DisplayMessageIn="Popup"
                                Required={true}
                            />
                        </div>
                    </div>

                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start", width: "1200px"}}>
                        <div className={classes.FormField} style={{flex: "1 1 350px"}}>
                            <FileUploader
                                name="bill_documents_paths"
                                label="Transactions"
                                APIUploadPath="/api/bills/uploadbills"
                                APIDeletePath="/api/bills/deleteuploadedbill"
                                UpdateFormFunction={UpdateFormFiles}
                                MaxFiles={10}
                                MaxSizeMB={10}
                                InitialFiles={FilesUploaders ? FilesUploaders.bill_documents_paths : []}
                                UploadButton={{
                                    Message: "Upload file",
                                }}
                                // AcceptedFileTypes={["*"]}
                                AcceptedFileTypes={["pdf"]}
                                MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                                BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                                SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                                WithDragAndDrop={true}
                                WithProgressBar={true}
                                ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                            />
                        </div>
                    </div>

                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="accouting_communication"
                            InitialValue={InitialFormValues.accouting_communication ? InitialFormValues.accouting_communication : null}
                            FormValue={FormValues.accouting_communication ? FormValues.accouting_communication : null}
                            OutputValue={(values) => HandleUpdateFormValues("accouting_communication", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Accounting communication"
                            NoMessage={false}
                            meta={{error: FormErrors.accouting_communication, invalid: FormErrors.accouting_communication ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("accouting_communication", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="200px"
                            multiline={true}
                            RowsToDisplay="3"
                            MaxRows="3"
                            LabelAlign="Left"
                            Required={false}
                        />
                    </div>

                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="accounting_note"
                            InitialValue={InitialFormValues.accounting_note ? InitialFormValues.accounting_note : null}
                            FormValue={FormValues.accounting_note ? FormValues.accounting_note : null}
                            OutputValue={(values) => HandleUpdateFormValues("accounting_note", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Note for accounting company"
                            NoMessage={false}
                            meta={{error: FormErrors.accounting_note, invalid: FormErrors.accounting_note ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("accounting_note", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="200px"
                            multiline={true}
                            RowsToDisplay="3"
                            MaxRows="3"
                            LabelAlign="Left"
                            Required={false}
                        />
                    </div>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                            <FormCompontent
                                Name="ref_bill_category"
                                InitialValue={InitialFormValues.ref_bill_category ? InitialFormValues.ref_bill_category : null}
                                FormValue={FormValues.ref_bill_category ? FormValues.ref_bill_category : null}
                                OutputSelectedValues={(values) => handleSelectChange("ref_bill_category", values)}
                                SelectOptions={FieldOptions.ref_bill_category}
                                Component="Select"
                                Variant="Outlined"
                                Label="Bill Category"
                                NoMessage={false}
                                meta={{error: FormErrors.ref_bill_category, invalid: FormErrors.ref_bill_category ? true : false}}
                                OutputError={(error) => HandleUpdateErrors("ref_bill_category", error)}
                                MultiSelect={false}
                                SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                // CreateDeleteIfUnselected={}

                                LabelMaxWidth="170px"
                                FieldMaxWidth="200px"
                                LabelAlign="Left"
                            />
                        </div>
                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                            <FormCompontent
                                Name="ref_billSubCategory"
                                InitialValue={InitialFormValues.ref_billSubCategory ? InitialFormValues.ref_billSubCategory : null}
                                FormValue={FormValues.ref_billSubCategory ? FormValues.ref_billSubCategory : null}
                                OutputSelectedValues={(values) => handleSelectChange("ref_billSubCategory", values)}
                                SelectOptions={FieldOptions.ref_billSubCategory}
                                Component="Select"
                                Variant="Outlined"
                                Label="Bill Subcategory"
                                NoMessage={false}
                                meta={{error: FormErrors.ref_billSubCategory, invalid: FormErrors.ref_billSubCategory ? true : false}}
                                OutputError={(error) => HandleUpdateErrors("ref_billSubCategory", error)}
                                MultiSelect={false}
                                SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                // CreateDeleteIfUnselected={}
                                UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_billSubCategory", options)}
                                LabelMaxWidth="170px"
                                FieldMaxWidth="200px"
                                LabelAlign="Left"
                            />
                        </div>
                    </div>
                </div>

                <FormDev
                    DevMode={DevMode}
                    FormValues={FormValues}
                    InitialFormValues={InitialFormValues}
                    FieldOptions={FieldOptions}
                    FieldSubOptionsFull={FieldSubOptionsFull}
                    FilesUploaders={FilesUploaders}
                    FormErrors={FormErrors}
                    NewFieldOptions={NewFieldOptions}
                    PristineDetails={PristineDetails}
                />
                {/* Button Bar */}
                <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                    {`${FormErrorMain ? FormErrorMain : null}`}
                </div>
                <div style={{height: "80px"}}>
                    <FormSubmitButtons
                        ButtonSaveAsDraft={ButtonSaveAsDraft}
                        ButtonSaveAndQuit={ButtonSaveAndQuit}
                        ButtonQuitNoSave={ButtonQuitNoSave}
                        ButtonDelete={ButtonDelete}
                        ButtonPublish={ButtonPublish}
                        ButtonReset={ButtonReset}
                        ButtonNext={ButtonNext}
                        ButtonPrevious={ButtonPrevious}
                        HandleSubmitForm={HandleSubmitForm}
                        Invalid={Invalid}
                        Pristine={Pristine}
                        DisableToolTip={DisableToolTip}
                        FormViewMode={FormViewMode}
                    />
                </div>

                {/* Button Bar */}
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <div className={classes.FormWrapper}>{FinalFormComponent}</div>;
});
export default Component;
