import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Document, Page as PagePDF} from "react-pdf/dist/esm/entry.webpack";
import {ZoomIn, ZoomOut, ArrowFilledLeft, ArrowFilledRight} from "@artibulles-cis/react-icons";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PDFContainer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
        flexDirection: "column",
        height: "100%",
        // width: "100%",
    },
    Toolbar: {
        display: "flex",
        alignItems: "center",
    },
    PDFWrapper: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        padding: "2px",
        boxSizing: "border-box",
        border: "1px solid grey",
        overflow: "auto",
        // overflow: "hidden",
    },
}));

const Component = React.memo(function Component(props) {
    const {FileUrl} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [NumPages, setNumPages] = useState(null);
    const [PageNumber, setPageNumber] = useState(1);

    const [Scale, setScale] = useState(1);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };
    const HandleZoom = (Direction) => {
        if (Direction === "In") {
            setScale(Scale + 0.2);
        } else {
            setScale(Scale - 0.2);
        }
    };
    const HandleChangePage = (Direction) => {
        if (Direction === "Previous") {
            if (PageNumber - 1 < 1) {
            } else {
                setPageNumber(PageNumber - 1);
            }
        } else {
            if (PageNumber + 1 > NumPages) {
            } else {
                setPageNumber(PageNumber + 1);
            }
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <div className={classes.PDFContainer}>
                <div className={classes.Toolbar}>
                    <ZoomIn onClick={() => HandleZoom("In")} />
                    <ZoomOut onClick={() => HandleZoom("Out")} />
                    <ArrowFilledLeft onClick={() => HandleChangePage("Previous")} />
                    {`${PageNumber} / ${NumPages}`}
                    <ArrowFilledRight onClick={() => HandleChangePage("Next")} />
                </div>
                <div className={classes.PDFWrapper}>
                    <Document
                        file={FileUrl}
                        // file="https://www.artibulles-bms.artibulles.com/api/fileServer/private/purchase_invoice/PurchaseInvoice_e8ae9f33-fbd1-490c-bf66-21e4c9ff61cb.pdf"

                        onLoadSuccess={onDocumentLoadSuccess}
                        // renderMode="none"
                        // rotate={90}
                    >
                        <PagePDF pageNumber={PageNumber} scale={Scale} />
                    </Document>
                </div>
            </div>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
