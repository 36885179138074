/* eslint-disable */
import React, {useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import Button from "@artibulles-cis/react/Button";
import FormDev from "../../../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import ExpandableBox from "../../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
/************** NEW COMPONENTS **************/

import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../../artibulles-cis/CheckBox/CheckBox";
import FileUploader from "../../../../../../../artibulles-cis/FileUploader/FileUploader";
import {AddCircle, ArrowFilledRight, AvatarV2, Login} from "@artibulles-cis/react-icons";
import {formatISO} from "date-fns";
import InvoicerItemsTable from "./InvoiceItems/InvoiceItemsTable";

// import CustomerAddresses from "./CustomerAddresses";
// import CustomerBankAccountForm from "./CustomerBankAccountForm";

/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
        color: "black",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "45px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 50px",
        boxSizing: "border-box",
        borderRadius: "3px",
        padding: "4px",
        border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 10px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "18px",
        margin: "0px",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
    ClassExtendsContentOpened: {
        padding: "0px 0px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "PaymentMethod") {
            if (SelectedValues === "01" || SelectedValues === "02") {
                //BankAccount
            } else {
                //Others
                //We reset the Communication and the BankAccount
                UpdatedFormValues.CounterpartyBankAccount_Id = null;
                UpdatedFormValues.InvoicePaymentExpectedCommunication = null;
            }
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }

        //When the ref_Customer changes, we update the vat_number and the options for the bank account
        if (field === "ref_Customer") {
            let SelectedId = SelectedDetails.Value;
            let SelectedInfo = _.find(FieldOptions.ref_Customer, {value: SelectedId});
            let vat_number = SelectedInfo.vat_number;

            let SuppliersOptions = FieldOptions.ref_Customer;
            if (SuppliersOptions && Array.isArray(SuppliersOptions)) {
                let BankAccountOptions = _.find(SuppliersOptions, {value: SelectedId});
                if (BankAccountOptions) {
                    BankAccountOptions = BankAccountOptions.BankAccounts;
                    if (BankAccountOptions && Array.isArray(BankAccountOptions)) {
                        let FinalBankAccountOptions = [];
                        BankAccountOptions.forEach((elem) => {
                            FinalBankAccountOptions.push({value: elem._id, label: elem.account_number});
                        });
                        //We change the options for the CounterpartyBankAccount_Id field
                        setFieldOptions({...FieldOptions, ...{CounterpartyBankAccount_Id: FinalBankAccountOptions}});
                        //And we reset the formValue
                        setFormValues({...UpdatedFormValues, ...{CounterpartyBankAccount_Id: null}});
                    }
                }
                
            }

            setFormValues({...UpdatedFormValues, ...{vat_number: vat_number}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        //Conditional Fields
        if (field === "is_VAT_Intracomm") {
            if (value === true) {
                //we Delete the values for the VAT amount  and the total without vat
                UpdateFormValues.vat_amount = null;
                UpdateFormValues.amount_without_vat = null;
            }
        }

        if (field === "accounting_Justification_needed") {
            if (value === false) {
                //We clear the justification provided
                //we clear the justification date

                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = null;
            } else {
                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = formatISO(new Date(Date.now()));
            }
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (fieldName, filedetails) => {
        if (fieldName && filedetails) {
            let FullFilesUploader = [];
            for (var i = 0; i < filedetails.length; i++) {
                FullFilesUploader.push({
                    serverPath: filedetails[i].serverPath,
                    originalName: filedetails[i].originalName,
                    fileName: filedetails[i].fileName,
                    fileExtension: filedetails[i].fileExtension,
                    fileType: filedetails[i].fileType,
                    fileSize: filedetails[i].fileSize,
                    keywords: filedetails[i].keywords,
                });
            }
            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function" || submitType === "SaveDraft") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES EXCEPTED FOR THE CREATE SELECT MULTI
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            console.log("Submitting change", submitType);
            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    if (key === "status_invoice") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.status_invoice, {value: FormValues.status_invoice});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{status_invoice: Entry}};
                    } else if (key === "PaymentCondition") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.PaymentCondition, {value: FormValues.PaymentCondition});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{PaymentCondition: Entry}};
                    } else if (key === "PaymentMethod") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.PaymentMethod, {value: FormValues.PaymentMethod});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{PaymentMethod: Entry}};
                    } else {
                        //Get the IntialValue
                        let InitialValue = InitialFormValues[key];
                        let Value = FormValues[key];
                        if (InitialValue !== Value && key) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                        }
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };

    const HandlePurchaseItemChanged = () => {
        console.log("Table Item has changed");
        HandleSubmitForm("SaveDraft");
    };

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let FormTitle, PurchaseItemsTableComponent, InvoiceSummaryComponent;

    if (InitialFormValues.ApiID === "new") {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>New Purchase Invoice</h1>
                    </div>
                </div>
            </div>
        );
    } else {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Logo_Wrapper}>
                        <img className={classes.FormTitle_Logo} src="" alt="" />
                    </div>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>Purchase Invoice</h1>
                    </div>
                </div>
            </div>
        );

        if (InitialFormValues.CalcTotalVATIncluded >= 0) {
            InvoiceSummaryComponent = (
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="CalcTotalVATExcluded"
                            InitialValue={InitialFormValues.CalcTotalVATExcluded ? InitialFormValues.CalcTotalVATExcluded : null}
                            FormValue={FormValues.CalcTotalVATExcluded ? FormValues.CalcTotalVATExcluded : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATExcluded", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT Exc."
                            NoMessage={false}
                            meta={{error: FormErrors.CalcTotalVATExcluded, invalid: FormErrors.CalcTotalVATExcluded ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CalcTotalVATExcluded", error)}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="140px"
                            FieldMaxWidth="110px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 300px", marginRight: "10px"}}>
                        <FormCompontent
                            Name="CalcTotalVATAmount"
                            InitialValue={InitialFormValues.CalcTotalVATAmount ? InitialFormValues.CalcTotalVATAmount : null}
                            FormValue={FormValues.CalcTotalVATAmount ? FormValues.CalcTotalVATAmount : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATAmount", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT Amount"
                            NoMessage={false}
                            meta={{error: FormErrors.CalcTotalVATAmount, invalid: FormErrors.CalcTotalVATAmount ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CalcTotalVATAmount", error)}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="170px"
                            FieldMaxWidth="110px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                        <FormCompontent
                            Name="CalcTotalVATIncluded"
                            InitialValue={InitialFormValues.CalcTotalVATIncluded ? InitialFormValues.CalcTotalVATIncluded : null}
                            FormValue={FormValues.CalcTotalVATIncluded ? FormValues.CalcTotalVATIncluded : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATIncluded", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT Inc."
                            NoMessage={false}
                            meta={{error: FormErrors.CalcTotalVATIncluded, invalid: FormErrors.CalcTotalVATIncluded ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CalcTotalVATIncluded", error)}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="140px"
                            FieldMaxWidth="110px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
            );
        } else {
            InvoiceSummaryComponent = null;
        }
        PurchaseItemsTableComponent = (
            <ExpandableBox
                TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Invoice Details</h3>}
                DefaultStyle="List"
                // TransitionEffect={{Duration : "600ms"}}
                // MaxWidth="1020px"
                // CustomController={<InfoCircleBlue />}
                // CustomControllerNoRotation={true}
                // BackgroundColorTopBar="grey"
                // ClassExtendsExpandableBox={classes.ExpandableBox}
                // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                ClassExtendsContentOpened={classes.ClassExtendsContentOpened}
                // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                // MinWidth="1020px"
                // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                MaxHeightOpened="150px"
                OpenOnTopClick={true}
                WithPerfectScrollbar={false}
                WithScrollbar={null}
                FlexMode={true}
            >
                <div className={classes.FormLeft}>
                    {InvoiceSummaryComponent}
                    <InvoicerItemsTable SaleInvoiceID={InitialFormValues.ApiID} SubmitParentForm={HandlePurchaseItemChanged} />
                </div>
            </ExpandableBox>
        );
    }

    var FinalFormComponent;

    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.FormWrapper}>
                {FormTitle}

                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Accounting information</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    ClassExtendsContentOpened={classes.ClassExtendsContentOpened}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                                <FormCompontent
                                    Name="ref_Customer"
                                    InitialValue={InitialFormValues.ref_Customer ? InitialFormValues.ref_Customer : null}
                                    FormValue={FormValues.ref_Customer ? FormValues.ref_Customer : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ref_Customer", values)}
                                    SelectOptions={FieldOptions.ref_Customer}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Supplier"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="230px"
                                    LabelAlign="Left"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 70px"}}>
                                <Button Height="28px" Width="40px" style={{margin: "0px 0px 1px 0px", padding: "0px"}}>
                                    <AddCircle IconSize="25px" SVGFillColor="white" />
                                </Button>
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                <FormCompontent
                                    Name="vat_number"
                                    InitialValue={InitialFormValues.vat_number ? InitialFormValues.vat_number : null}
                                    FormValue={FormValues.vat_number ? FormValues.vat_number : null}
                                    OutputValue={(values) => HandleUpdateFormValues("vat_number", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="VAT number"
                                    NoMessage={false}
                                    meta={{error: FormErrors.vat_number, invalid: FormErrors.vat_number ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("vat_number", error)}
                                    ReadOnly={true}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"

                                    LabelMaxWidth="120px"
                                    LabelAlign="Left"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                                <FormCompontent
                                    Name="PaymentCondition"
                                    InitialValue={InitialFormValues.PaymentCondition ? InitialFormValues.PaymentCondition : null}
                                    FormValue={FormValues.PaymentCondition ? FormValues.PaymentCondition : null}
                                    OutputSelectedValues={(values) => handleSelectChange("PaymentCondition", values)}
                                    SelectOptions={FieldOptions.PaymentCondition}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Payment Conditions"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortField="value"
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="230px"
                                    LabelAlign="Left"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 500px", margin: "0px 0px 0px 10px"}}>
                                <FormCompontent
                                    Name="PaymentMethod"
                                    InitialValue={InitialFormValues.PaymentMethod ? InitialFormValues.PaymentMethod : null}
                                    FormValue={FormValues.PaymentMethod ? FormValues.PaymentMethod : null}
                                    OutputSelectedValues={(values) => handleSelectChange("PaymentMethod", values)}
                                    SelectOptions={FieldOptions.PaymentMethod}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Payment Method"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortField="value"
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                    Required={true}
                                />
                            </div>
                        </div>
                        <div
                            className={classes.FormMultilineFlex}
                            style={{justifyContent: "flex-start", display: `${FormValues.PaymentMethod === "01" || FormValues.PaymentMethod === "02" ? "flex" : "none"}`}}
                        >
                            <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                                <FormCompontent
                                    Name="CounterpartyBankAccount_Id"
                                    InitialValue={InitialFormValues.CounterpartyBankAccount_Id ? InitialFormValues.CounterpartyBankAccount_Id : null}
                                    FormValue={FormValues.CounterpartyBankAccount_Id ? FormValues.CounterpartyBankAccount_Id : null}
                                    OutputSelectedValues={(values) => handleSelectChange("CounterpartyBankAccount_Id", values)}
                                    SelectOptions={FieldOptions.CounterpartyBankAccount_Id}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Bank Account"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortField="value"
                                    Clearable={false}
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="230px"
                                    LabelAlign="Left"
                                    // Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 500px", margin: "0px 0px 0px 10px"}}>
                                <FormCompontent
                                    Name="InvoicePaymentExpectedCommunication"
                                    InitialValue={InitialFormValues.InvoicePaymentExpectedCommunication ? InitialFormValues.InvoicePaymentExpectedCommunication : null}
                                    FormValue={FormValues.InvoicePaymentExpectedCommunication ? FormValues.InvoicePaymentExpectedCommunication : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentExpectedCommunication", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Invoice Communication"
                                    NoMessage={false}
                                    meta={{error: FormErrors.InvoicePaymentExpectedCommunication, invalid: FormErrors.InvoicePaymentExpectedCommunication ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("InvoicePaymentExpectedCommunication", error)}
                                    ReadOnly={false}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "0 0 800px"}}>
                                <FormCompontent
                                    Name="description"
                                    InitialValue={InitialFormValues.Description ? InitialFormValues.Description : null}
                                    FormValue={FormValues.Description ? FormValues.Description : null}
                                    OutputValue={(values) => HandleUpdateFormValues("Description", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Description"
                                    NoMessage={false}
                                    meta={{error: FormErrors.Description, invalid: FormErrors.Description ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("Description", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    LabelMaxWidth="230px"
                                    multiline={true}
                                    RowsToDisplay="2"
                                    MaxRows="4"
                                    LabelAlign="Left"
                                    Required={false}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>

                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Purchase Invoice Status Details</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    ClassExtendsContentOpened={classes.ClassExtendsContentOpened}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 465px"}}>
                                <FormCompontent
                                    Name="status_invoice"
                                    InitialValue={InitialFormValues.status_invoice ? InitialFormValues.status_invoice : null}
                                    FormValue={FormValues.status_invoice ? FormValues.status_invoice : null}
                                    OutputSelectedValues={(values) => handleSelectChange("status_invoice", values)}
                                    SelectOptions={FieldOptions.status_invoice}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Status"
                                    NoMessage={false}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    SortField="value"
                                    // SortIcons={true}
                                    AllowCreateOption={false}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                    Required={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 250px", marginLeft: "95px"}}>
                                <FormCompontent
                                    Name="OrderPlacedDateIso"
                                    InitialValue={InitialFormValues.OrderPlacedDateIso ? InitialFormValues.OrderPlacedDateIso : null}
                                    FormValue={FormValues.OrderPlacedDateIso ? FormValues.OrderPlacedDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("OrderPlacedDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Order placed"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="150px"
                                    LabelAlign="Left"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                <FormCompontent
                                    Name="ExpectedDeliveryDateIso"
                                    InitialValue={InitialFormValues.ExpectedDeliveryDateIso ? InitialFormValues.ExpectedDeliveryDateIso : null}
                                    FormValue={FormValues.ExpectedDeliveryDateIso ? FormValues.ExpectedDeliveryDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("ExpectedDeliveryDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Expected Delivery Date"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 250px", marginLeft: "160px"}}>
                                <FormCompontent
                                    Name="DeliveryDateIso"
                                    InitialValue={InitialFormValues.DeliveryDateIso ? InitialFormValues.DeliveryDateIso : null}
                                    FormValue={FormValues.DeliveryDateIso ? FormValues.DeliveryDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("DeliveryDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Reception Date"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="150px"
                                    LabelAlign="Left"
                                />
                            </div>
                        </div>

                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                <FormCompontent
                                    Name="InvoiceSupplierDateIso"
                                    InitialValue={InitialFormValues.InvoiceSupplierDateIso ? InitialFormValues.InvoiceSupplierDateIso : null}
                                    FormValue={FormValues.InvoiceSupplierDateIso ? FormValues.InvoiceSupplierDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoiceSupplierDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Date"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 400px", marginLeft: "160px"}}>
                                <FormCompontent
                                    Name="InvoicePaymentDueDateIso"
                                    InitialValue={InitialFormValues.InvoicePaymentDueDateIso ? InitialFormValues.InvoicePaymentDueDateIso : null}
                                    FormValue={FormValues.InvoicePaymentDueDateIso ? FormValues.InvoicePaymentDueDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentDueDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Due Date"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="150px"
                                    LabelAlign="Left"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                <FormCompontent
                                    Name="InvoicePaidDateIso"
                                    InitialValue={InitialFormValues.InvoicePaidDateIso ? InitialFormValues.InvoicePaidDateIso : null}
                                    FormValue={FormValues.InvoicePaidDateIso ? FormValues.InvoicePaidDateIso : null}
                                    OutputValue={(values) => HandleUpdateFormValues("InvoicePaidDateIso", values)}
                                    Component="DatePicker"
                                    Variant="Outlined"
                                    Label="Invoice Payment Date"
                                    NoMessage={false}
                                    // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                    // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                    PickerDateType="Calendar"
                                    PickerOnly={false}
                                    InputFormat="dd/MM/yyyy"
                                    LabelMaxWidth="200px"
                                    LabelAlign="Left"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "0 0 400px", marginLeft: "160px"}}>
                                <FormCompontent
                                    Name="vat_number"
                                    InitialValue={InitialFormValues.vat_number ? InitialFormValues.vat_number : null}
                                    FormValue={FormValues.vat_number ? FormValues.vat_number : null}
                                    OutputValue={(values) => HandleUpdateFormValues("vat_number", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Payment delay"
                                    NoMessage={false}
                                    meta={{error: FormErrors.vat_number, invalid: FormErrors.vat_number ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("vat_number", error)}
                                    ReadOnly={true}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"

                                    LabelMaxWidth="150px"
                                    LabelAlign="Left"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                            <div className={classes.FormField} style={{flex: "1 1 350px"}}>
                                <FileUploader
                                    name="Invoices_File_Path"
                                    label="Sales Invoices"
                                    APIUploadPath="/api/saleinvoices/uploadsaleinvoices"
                                    APIDeletePath="/api/saleinvoices/deleteuploadedsaleinvoice"
                                    UpdateFormFunction={UpdateFormFiles}
                                    MaxFiles={10}
                                    MaxSizeMB={10}
                                    InitialFiles={FilesUploaders ? FilesUploaders.Invoices_File_Path : []}
                                    UploadButton={{
                                        Message: "Upload Invoices",
                                    }}
                                    // AcceptedFileTypes={["*"]}
                                    AcceptedFileTypes={["pdf"]}
                                    MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                                    BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                                    SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                                    WithDragAndDrop={true}
                                    WithProgressBar={true}
                                    ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                                    WithDeleteConfirmation={true}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                {PurchaseItemsTableComponent}

                <FormDev
                    DevMode={DevMode}
                    FormValues={FormValues}
                    InitialFormValues={InitialFormValues}
                    FieldOptions={FieldOptions}
                    FieldSubOptionsFull={FieldSubOptionsFull}
                    FilesUploaders={FilesUploaders}
                    FormErrors={FormErrors}
                    NewFieldOptions={NewFieldOptions}
                    PristineDetails={PristineDetails}
                />
                {/* Button Bar */}
                <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                    {`${FormErrorMain ? FormErrorMain : null}`}
                </div>
                <div style={{height: "80px"}}>
                    <FormSubmitButtons
                        ButtonSaveAsDraft={ButtonSaveAsDraft}
                        ButtonSaveAndQuit={ButtonSaveAndQuit}
                        ButtonQuitNoSave={ButtonQuitNoSave}
                        ButtonDelete={ButtonDelete}
                        ButtonPublish={ButtonPublish}
                        ButtonReset={ButtonReset}
                        ButtonNext={ButtonNext}
                        ButtonPrevious={ButtonPrevious}
                        HandleSubmitForm={HandleSubmitForm}
                        Invalid={Invalid}
                        Pristine={Pristine}
                        DisableToolTip={DisableToolTip}
                        FormViewMode={FormViewMode}
                    />
                </div>

                {/* Button Bar */}
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <React.Fragment>{FinalFormComponent}</React.Fragment>;
});
export default Component;
