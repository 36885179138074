import React from "react";
import {createUseStyles, useTheme} from "react-jss";

// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

import Page from "@artibulles-cis/react/Page";
import InvoiceEditForm from "./InvoiceEditForm";
import _ from "lodash";
const {format, parseISO, formatISO, addDays} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerEdit = React.memo(function CustomerEdit(props) {
    const {Id, RedirectToOnClosingUrl, CloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_StatusInvoice = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    }

    const InitialCall = {type: "Edit", ID: IdFinal};
    // console.log("InitialCall", InitialCall);
    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/saleinvoices/invoice`,
        // MAIN: `${ProdURL}/api/purchaseinvoices/invoice`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Company._id;
            // History.push(RedirectToSourceOnClosing);
            History.push(RedirectToOnClosingUrl);
        }
    };
    const HandleRedirectOnCloseCard = () => {
        History.push(RedirectToOnClosingUrl);
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        // console.log("data", data);
        let Invoice = data.Invoice ? data.Invoice : null;

        //We need to chack the invoice status before determining what portion of data to be used
        // If Invoice_status = Draft || Created || Posted -> It is editable and the fields to be used are the one linked in the DB
        // If Invoice_status = Issued || Accounted || Reconciled -> It is not editable anymore, and we don't want the links to potential value change to be upgraded
        //Example: Customer name change, customer address change, customer VAT change, Customer contacts.....
        //When the Invoice is sent to the customer, we copy all those values directly in the DB to prevent any future changes

        let CustomersList;
        if (data.Customers && data.Customers.length > 0) {
            CustomersList = data.Customers.map((elem) => {
                return {value: elem._id, label: elem.company, vat_number: elem.vat_number, BankAccounts: elem.BankAccounts, Addresses: elem.Adresses, AccountingPreference: elem.AccountingPreference};
            });
        }

        let PaymentTermsOptions = [];
        let PaymentTerms = data.PaymentTerms;
        if (PaymentTerms && Array.isArray(PaymentTerms)) {
            PaymentTerms.forEach((elem) => {
                PaymentTermsOptions.push({
                    label: elem.PaymentTerms,
                    value: elem._id,
                    InvoiceDescription_FR: elem.InvoiceDescription_FR,
                    InvoiceDescription_EN: elem.InvoiceDescription_EN,
                    DueDaysAfterInvoice: elem.DueDaysAfterInvoice,
                    DueDayOfNextMonth: elem.DueDayOfNextMonth,
                });
            });
        }

        let CustomerInitialId = Invoice ? Invoice.ref_Customer : null;
        let InitialCustomer;

        let CustomerAccountingPreference;
        if (CustomerInitialId) {
            InitialCustomer = _.find(data.Customers, {_id: CustomerInitialId});
            CustomerAccountingPreference = InitialCustomer.AccountingPreference;
        }
        let VatNumber, isValidVATNumber;
        if (InitialCustomer) {
            VatNumber = InitialCustomer.vat_number;
            isValidVATNumber = InitialCustomer.isValidVATNumber;
        }
        let InvoicesFilePaths = [];

        if (Invoice && Invoice.Invoices_File_Path && Array.isArray(Invoice.Invoices_File_Path) && Invoice.Invoices_File_Path.length > 0) {
            Invoice.Invoices_File_Path.forEach((elem) => {
                InvoicesFilePaths.push(elem);
            });
        }

        let InitialAddress;
        let AddressTypes = [];
        let InitialAddressType;

        if (InitialCustomer) {
            if (Invoice.BillingAddressId) {
                let InitialAddressFull,
                    Addresses = [];
                //We only push the general or billing address for the select
                InitialCustomer.Adresses.forEach((elem) => {
                    if (elem.AddressType.name_EN === "Billing") {
                        Addresses.push({Type: "Billing", Address: elem});
                        AddressTypes.push({label: "Billing", value: "Billing"});
                    } else if (elem.AddressType.name_EN === "General") {
                        Addresses.push({Type: "General", Address: elem});
                        AddressTypes.push({label: "General", value: "General"});
                    }
                });
                //We extract the selected address

                InitialAddressFull = _.find(InitialCustomer.Adresses, {_id: Invoice.BillingAddressId});

                InitialAddressType = InitialAddressFull.AddressType.name_EN;

                InitialAddress = {
                    Line1: InitialAddressFull.street_line1,
                    Line2: InitialAddressFull.street_line2,
                    ZipCity: InitialAddressFull.zip_code + ", " + InitialAddressFull.city,
                    Country: InitialAddressFull.Country.country_name_EN,
                };
            }
        }
        Ref_StatusInvoice.current = Invoice ? (Invoice.status_invoice ? Invoice.status_invoice : "Draft") : "Draft";

        let InitialInvoiceCommunication;

        let MyBankAccountOptions = [],
            InitialBankAccount;
        if (data.MyCompanyBankAccounts && Array.isArray(data.MyCompanyBankAccounts) && data.MyCompanyBankAccounts.length > 0) {
            data.MyCompanyBankAccounts.forEach((elem) => {
                MyBankAccountOptions.push({value: elem._id, label: `${elem.bank_name} - ${elem.account_number}`});
            });
        }
        if (Invoice) {
            if (Invoice.ref_MyBankAccount) {
                InitialBankAccount = Invoice.ref_MyBankAccount;
            } else {
                if (data.MyCompanyBankAccounts && Array.isArray(data.MyCompanyBankAccounts) && data.MyCompanyBankAccounts.length > 0) {
                    if (data.MyCompanyBankAccounts.length === 1) {
                        InitialBankAccount = data.MyCompanyBankAccounts[0]._id;
                    }
                }
            }
        } else {
            if (data.MyCompanyBankAccounts && Array.isArray(data.MyCompanyBankAccounts) && data.MyCompanyBankAccounts.length > 0) {
                if (data.MyCompanyBankAccounts.length === 1) {
                    InitialBankAccount = data.MyCompanyBankAccounts[0]._id;
                }
            }
        }

        let InitialInvoiceReference;
        if (!Invoice) {
            InitialInvoiceCommunication = "INVxxxxxTEMP";
        } else {
            if (!Invoice.Calc_InvoiceLegaReference) {
                InitialInvoiceReference = "INVxxxxxTEMP";
                InitialInvoiceCommunication = "INVxxxxxTEMP";
            } else {
                InitialInvoiceReference = Invoice.Calc_InvoiceLegaReference;
                InitialInvoiceCommunication = Invoice.InvoicePaymentExpectedCommunication;
            }
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Invoice ? Invoice._id : "new",
                Calc_RefInvoice: Invoice ? Invoice.Calc_RefInvoice : null,
                Calc_InvoiceLegaReference: InitialInvoiceReference,
                MyCompanyBankAccounts: data.MyCompanyBankAccounts,
                status_invoice: Invoice ? Invoice.status_invoice : "Draft",
                InvoicePaymentExpectedCommunication: InitialInvoiceCommunication ? InitialInvoiceCommunication : null,
                Description: Invoice ? Invoice.Description : null,
                CalcTotalVATIncluded: Invoice ? Invoice.CalcTotalVATIncluded : null,
                CalcTotalVATExcluded: Invoice ? Invoice.CalcTotalVATExcluded : null,
                CalcTotalVATAmount: Invoice ? Invoice.CalcTotalVATAmount : null,
                TaxesDetails: Invoice ? (Invoice.InvoiceAmountSummary ? (Invoice.InvoiceAmountSummary.VATDetails ? Invoice.InvoiceAmountSummary.VATDetails : null) : null) : null,
                TotalVATExcludedPurchaseCurrency: Invoice ? Invoice.TotalVATExcludedPurchaseCurrency : null,
                TotlVATAmountPurchaseCurrency: Invoice ? Invoice.TotlVATAmountPurchaseCurrency : null,
                TotalVATIncludedPurchaseCurrency: Invoice ? Invoice.TotalVATIncludedPurchaseCurrency : null,
                Currency: Invoice ? Invoice.Currency : null,
                CurrencyExchangeRate: Invoice ? Invoice.CurrencyExchangeRate : null,
                InvoiceIssueDateIso: Invoice ? Invoice.InvoiceIssueDateIso : formatISO(new Date(Date.now())),
                ManualInvoicePaymentDueDateIso: Invoice ? Invoice.ManualInvoicePaymentDueDateIso : null,
                InvoicePaymentDueDateIso: Invoice ? Invoice.InvoicePaymentDueDateIso : null,
                ref_PaymentTerms: Invoice ? Invoice.ref_PaymentTerms : null,
                ref_Customer: CustomerInitialId ? CustomerInitialId : null,
                vat_number: VatNumber,
                InitialAddress: InitialAddress,
                ManualBankPaymentActionsStatus: Invoice ? Invoice.ManualBankPaymentActionsStatus : null,
                CalcIsPaid: Invoice ? Invoice.CalcIsPaid : null,
                CalcPaymentDateIso: Invoice ? Invoice.CalcPaymentDateIso : null,
                ManualPaymentDone: Invoice ? Invoice.ManualPaymentDone : null,
                ManualPaymentDateIso: Invoice ? Invoice.ManualPaymentDateIso : null,
                IsInvoicePaymentExpectedCommunicationStructured: Invoice ? (Invoice.IsInvoicePaymentExpectedCommunicationStructured === true ? true : false) : null,
                AccountantStatus: Invoice ? Invoice.AccountantStatus : null,
                AccountantStatusHistory: Invoice ? Invoice.AccountantUploadedJustification_SaleInvoice : null,
                CustomerSendingStatusHistory: Invoice ? Invoice.CustomerSendingStatusInformation : null,
                ref_MyBankAccount: InitialBankAccount,
                EmailToCustomerStatus: Invoice ? (Invoice.EmailToCustomerStatus === true ? true : false) : false,
                IsAutoGenerated: Invoice ? Invoice.IsAutoGenerated : null,
                BillingAddressId: Invoice ? Invoice.BillingAddressId : null,
                AddressTypes: InitialAddressType ? InitialAddressType : null,
                NeedsToRegeneratePDF: Invoice ? (Invoice.NeedsToRegeneratePDF === true ? true : false) : false,
                CustomerAccountingPreference: CustomerAccountingPreference ? CustomerAccountingPreference : null,
                MinIssueDateIso: data.MinDateISO ? data.MinDateISO : null,
                MaxIssueDateIso: formatISO(addDays(new Date(Date.now()), 1)),
                AdminDevTestMode: Invoice ? (Invoice.AdminDevTestMode === true ? true : false) : false,
                isValidVATNumber: isValidVATNumber,
                // db_status: Company ? Company.db_status : null,
                // db_locked: Company ? Company.db_locked : null,
                // db_locked_by: Company ? Company.ref_db_locked_by : null,
                // db_locked_at: Company ? Company.db_locked_at : null,
                // owner: Company ? Company.ref_owner : null,
                createdAt: Invoice ? (Invoice.createdAt ? format(parseISO(Invoice.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: Invoice ? (Invoice.updatedAt ? format(parseISO(Invoice.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                ref_Customer: CustomersList,
                ref_PaymentTerms: PaymentTermsOptions,
                ref_MyBankAccount: MyBankAccountOptions,
                AddressTypes: AddressTypes,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                Invoices_File_Path: InvoicesFilePaths,
            },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormDataToServer")
        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            let FilesUpload = FullSubmission.FilesUploader;

            let InvoicesFilepathFinal;
            if (FilesUpload && FilesUpload.Invoices_File_Path && Array.isArray(FilesUpload.Invoices_File_Path) && FilesUpload.Invoices_File_Path.length > 0) {
                InvoicesFilepathFinal = FilesUpload.Invoices_File_Path;
            } else {
                InvoicesFilepathFinal = null;
            }

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Invoice: {
                        Updatable: {
                            status_invoice: values.status_invoice,
                            InvoicePaymentExpectedCommunication: values.InvoicePaymentExpectedCommunication,
                            Description: values.Description,
                            TotalVATExcludedPurchaseCurrency: values.TotalVATExcludedPurchaseCurrency,
                            TotlVATAmountPurchaseCurrency: values.TotlVATAmountPurchaseCurrency,
                            TotalVATIncludedPurchaseCurrency: values.TotalVATIncludedPurchaseCurrency,
                            Currency: values.Currency,
                            CurrencyExchangeRate: values.CurrencyExchangeRate,
                            PaymentCondition: values.PaymentCondition,
                            InvoiceIssueDateIso: values.InvoiceIssueDateIso,
                            ManualInvoicePaymentDueDateIso: values.ManualInvoicePaymentDueDateIso,
                            ref_Customer: values.ref_Customer,
                            ref_PaymentTerms: values.ref_PaymentTerms,
                            Invoices_File_Path: InvoicesFilepathFinal,
                            IsInvoicePaymentExpectedCommunicationStructured: values.IsInvoicePaymentExpectedCommunicationStructured,
                            ref_MyBankAccount: values.ref_MyBankAccount,
                            IsAutoGenerated: values.IsAutoGenerated,
                            BillingAddressId: values.BillingAddressId,
                            AdminDevTestMode: values.AdminDevTestMode,
                        },
                    },
                    CompanyAddresses: values.CompanyAddresses,
                };
                //this is what will be sent back to the server
                // console.log("SubmittedData", ServerData);
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ButtonSaveAsDraftComp, ButtonSaveAndQuitComp, ButtonQuitNoSaveComp;

    if (IdFinal === "new") {
        ButtonSaveAsDraftComp = {Active: false, Text: "Create Initial Order", Width: "200px"};
        ButtonSaveAndQuitComp = {Active: false, Text: "Create Initial Order And Quit", Width: "200px"};
        ButtonQuitNoSaveComp = {Active: false, Text: "Exit"};
    } else {
        ButtonSaveAsDraftComp = {Active: false, Text: "Save draft"};
        ButtonSaveAndQuitComp = {Active: false, Text: "Save And Quit"};
        ButtonQuitNoSaveComp = {Active: false, Text: "Exit"};
    }

    let FinalViewMode;
    if (Id === "new") {
        FinalViewMode = false;
    } else {
        if (Ref_StatusInvoice.current && Ref_StatusInvoice.current === "Draft") {
            FinalViewMode = false;
        } else {
            FinalViewMode = true;
        }
    }
    // console.log("Ref_StatusInvoice.current", Ref_StatusInvoice.current);

    return (
        <Page WithPerfectScrollBar={false} PagePadding="0px">
            <FormWithApi
                WithCardModal={Id === "new" ? true : false}
                // WithCardModal={true}
                CardModalOptions={{
                    CloseCard: HandleRedirectOnCloseCard,
                    CloseOnClickOutside: false,
                    WithCloseButton: true,
                    // CardMaxWidth="800px"
                    // CardHeight="700px"
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    WithPerfectScrollbar: false,
                    // CardWindowPaddingVertical="50px"

                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                OpenInViewMode={FinalViewMode}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={InvoiceEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                ToastQuitWihtouSaveConfirmation={false}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={ButtonSaveAsDraftComp}
                ButtonSaveAndQuit={ButtonSaveAndQuitComp}
                ButtonQuitNoSave={ButtonQuitNoSaveComp}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
});

export default CustomerEdit;
