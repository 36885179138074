import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";
import CardModal from "@artibulles-cis/react/CardModal";
import AGGridTable from "../../../../../artibulles-cis/AGGridTable/AGGridTable";

import Button from "@artibulles-cis/react/Button";
import TaskCreateEdit from "../TaskCreateEdit";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import _ from "lodash";
import {toast} from "react-toastify";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import FormCompontent from "../../../../../artibulles-cis/FormComponent/FormCompontent";
import {Calendar, Dashboard} from "@artibulles-cis/react-icons";
import {isValid, format, formatISO, add, setMilliseconds, setSeconds, setMinutes, setHours} from "date-fns";
import TabNavContent from "../../../../../artibulles-cis/TabNavContent/TabNavContent";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },
    // TableWrapper: {
    //     position: "relative",
    //     boxSizing: "border-box",
    //     padding: "10px 15px 10px 15px",
    //     display: "flex",
    //     width: "100%",
    //     // maxWidth: "1200px",
    //     // maxHeight: "800px",
    //     height: "100%",
    //     maxHeight: "700px",
    //     margin: "0 auto",
    //     justifyContent: "center",
    // },
    // TableWrapper: {
    //     position: "relative",
    //     boxSizing: "border-box",
    //     padding: "10px 10px",
    //     display: "flex",
    //     width: "100%",
    //     // maxWidth: "1200px",
    //     // maxHeight: "800px",
    //     height: "500px",
    //     margin: "0 auto",
    //     justifyContent: "center",
    // },
    TableToolbar: {
        display: "flex",
        flexDirection: "column",
    },
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        // flexWrap: "wrap",
        alignItems: "center",
        // justifyContent: "space-between",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    ButtonExtend: {
        margin: "0px 0px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },

    SearchButtons: {
        padding: "0px 0px 8px 0px",
        flex: "0 1 auto",
        display: "flex",
    },
}));

const TasksViewAllTasksTable = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_FormValues = useRef(null);
    const Ref_FieldOptions = useRef(null);
    const Ref_TabNavContent = useRef(null);
    const Ref_LocaleData = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Ref_URLAbsolute = useRef({
        MAIN: `${ProdURL}/api/tasks/query`,
        DELETE: `${ProdURL}/api/tasks/task/`,
        PUT: "",
        POST: "",
    });

    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };

    const HandleDeleteRow = (id) => {
        console.log("id", id);
        const ApiCall = {url: `${ProdURL}/api/tasks/task/${id}`, type: "delete"};
        setLoading(true);
        setAPICallType("DeleteRow");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };

    const HandleDuplicateInvoice = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };

    const DeleteCondition = (args) => {
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else {
            return true; //DEVONLY!! Should be false
        }
    };

    const HandleTrackTask = (Id) => {
        console.log("Id", Id);
        if (Id) {
            let NewDate = new Date(Date.now());
            let ISODate = formatISO(NewDate);
            let StartTimeSeconds = parseInt(format(NewDate, "H")) * 3600 + parseInt(format(NewDate, "m")) * 60 + parseInt(format(NewDate, "s"));
            const FormData = {
                Id: Id,
                date_Iso: ISODate,
                start_time_seconds: StartTimeSeconds,
            };
            const ApiCall = {url: `${ProdURL}/api/tasks/createtimeentryfortask`, type: "post", data: FormData};
            setLoading(true);
            setAPICallType("TrackTask");
            setIsInitalized(false);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };

    const AddToOutlookCondition = (args) => {
        if (args.is_completed === false) {
            return true;
        } else {
            return false;
        }
    };

    const HanldeAddToOutlook = (Id, Args) => {
        let FinalArgs = {...Args};
        console.log("Add to outlook", FinalArgs);
        //we post to add it to outlook
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/tasks/addtasktooutlook/${Id}`, type: "post", data: Args};
            setLoading(true);
            setAPICallType("AddToOutlook");
            setIsInitalized(false);
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };

    const DaysRemaingRendering = (params) => {
        let RowData = params.data;
        let days_remaining = RowData.days_remaining;

        if (days_remaining < 0) {
            return <div style={{color: "red"}}>{days_remaining}</div>;
        } else {
            return days_remaining;
        }
    };
    const DurationRendering = (params) => {
        let RowData = params.data;
        let Value = RowData.estimated_duration_seconds;

        if (Value) {
            return convertSecondsToTime(Value, false);
        } else {
            return null;
        }
    };

    const PercentageCompletedRendering = (params) => {
        let RowData = params.data;
        let Value = RowData.completed_percentage;

        if (Value) {
            return Value;
        } else {
            return null;
        }
    };

    const ColumnButtonStyle = {
        Width: 150,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Duplicate",
            ActiveCondition: null,
            Index: 2,
            OnClick: HandleDuplicateInvoice,
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: false,
            ActiveCondition: null,
            WithConfirmationMessage: true,
            ConfirmationMessageTitle: "Track",
            ConfirmationMessage: "Create Time Entry And Track",
            CustomConfirmButton: "Start Tracking",
            Index: 4,
            IconActive: <Dashboard IconSize="20px" SVGFillColor="rgb(66, 165, 245)" SVGFillHoverColor="red" />,
            IconInactive: <Dashboard IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
            OnClick: HandleTrackTask,
            Tooltip: {Show: true, Position: "right", Theme: "light", Content: "Track Task"},
        },
        {
            Standard: false,
            ActiveCondition: AddToOutlookCondition,
            // WithConfirmationMessage: true,
            // ConfirmationMessageTitle: "Track",
            // ConfirmationMessage: "Create Time Entry And Track",
            // CustomConfirmButton: "Start Tracking",
            Index: 5,
            IconActive: <Calendar IconSize="20px" SVGFillColor="rgb(66, 165, 245)" SVGFillHoverColor="red" />,
            IconInactive: <Calendar IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
            OnClick: HanldeAddToOutlook,
            Tooltip: {Show: true, Position: "right", Theme: "light", Content: "Add to outlook"},
        },
    ];

    const TableColumns = [
        {
            field: "task_name",
            headerName: "Task",
            headerClass: "ag-CenterHeader",
            flex: 1,
			minWidth : 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "due_date_Iso",
            headerName: "Due",
            headerClass: "ag-CenterHeader",
            width: 130,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },

        {
            field: "days_remaining",
            headerName: "Days",
            headerClass: "ag-CenterHeader",
            width: 90,
            cellRenderering: DaysRemaingRendering,
        },
        {
            field: "estimated_duration_seconds",
            headerName: "Duration",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: DurationRendering,
        },
        {
            headerName: "Company",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            Lookup: {LookupField: "ref_company", Source: "Companies", ReturnField: "company"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Program",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            Lookup: {LookupField: "ref_program", Source: "Programs", ReturnField: "program_name"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            headerName: "Milestone",
            headerClass: "ag-CenterHeader",
            width: 250,
            filter: true,
            Lookup: {LookupField: "ref_milestone", Source: "Milestones", ReturnField: "milestone_name"},
            sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "is_personal",
            headerName: "Perso",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "is_completed",
            headerName: "Done",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: true,
            sortingOrder: ["asc", "desc"],
            Type: "Boolean",
        },
        {
            field: "completed_percentage",
            headerName: "%Done",
            headerClass: "ag-CenterHeader",
            width: 110,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: PercentageCompletedRendering,
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [NoData, setNoData] = useState(true);
    const [IsQueryiesInitialized, setIsQueryiesInitialized] = useState(false);
    const [InitialFormValues, setInitialFormValues] = React.useState({});
    const [FormValues, setFormValues] = React.useState({});
    const [FieldOptions, setFieldOptions] = React.useState({});

    const [ReloadTable, setReloadTable] = useState(false);

    const [TableComponent, setTableComponent] = useState(null);

    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);

    const [FinalQuery, setFinalQuery] = useState({});
    const [RefreshApi, setRefreshApi] = useState(true);

    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);

    const [Init, setInit] = useState(false);
    // const [ShowViewCard, setShowViewCard] = useState(false);
    const [IsInitalized, setIsInitalized] = useState(true);
    // const [ViewCardId, setViewCardId] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="TasksAll"
                Data={Ref_LocaleData.current}
                TableColumns={TableColumns}
                MainDataSource="Tasks"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTable}
                SyncReloadDataStateFunction={setReloadTable}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        );
        setTableComponent(FinalTableComponent);
        setReloadTable(false);
    }, [Ref_LocaleData.current]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!APICallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        // console.log("Data", Data);
                        if (IsQueryiesInitialized === false) {
                            console.log("Initialize Form");
                            let InitFormValues = {
                                is_personal: "All",
                                is_completed: "Due",
                                late_status: "All",
                            };

                            let InitFieldOptions = {
                                is_completed: [
                                    {label: "Due", value: "Due"},
                                    {label: "All", value: "All"},
                                    {label: "Completed", value: "Completed"},
                                ],
                                is_personal: [
                                    {label: "Work", value: "Work"},
                                    {label: "All", value: "All"},
                                    {label: "Personal", value: "Personal"},
                                ],
                                late_status: [
                                    {label: "Late", value: "Late"},
                                    {label: "All", value: "All"},
                                    {label: "Upcoming", value: "Upcoming"},
                                ],
                            };
                            let Today = new Date(Date.now());
                            let dateStartOfDay = formatISO(setMilliseconds(setSeconds(setMinutes(setHours(Today, 0), 0), 1), 0));
                            let dateEndOfDay = formatISO(setMilliseconds(setSeconds(setMinutes(setHours(Today, 23), 59), 59), 0));

                            InitFormValues.datestart = dateStartOfDay;
                            InitFormValues.dateend = dateEndOfDay;

                            setInitialFormValues(InitFormValues);
                            setFormValues(InitFormValues);
                            setFieldOptions(InitFieldOptions);
                            Ref_FormValues.current = InitFormValues;
                            Ref_FieldOptions.current = InitFieldOptions;

                            setIsQueryiesInitialized(true);
                        }
                    }
                } else if (APICallType === "InitalizeTable") {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        if (Data) {
                            setNoData(false);
                        } else {
                            setNoData(true);
                        }
                        Ref_LocaleData.current = Data;
                        setReloadTable(true);
                    }
                } else if (APICallType === "Filter") {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        if (Data) {
                            setNoData(false);
                        } else {
                            setNoData(true);
                        }
                        Ref_LocaleData.current = Data;
                        setReloadTable(true);
                    }
                } else if (APICallType === "deleteone") {
                    toast.success(`Entry deleteted`, {autoClose: true});
                    setIsInitalized(true);
                } else if (APICallType === "AddToOutlook") {
                    toast.success(`Entry addedd to outook`, {autoClose: true});
                    //We then reload the table by calling Filter
                    HandleFilter();
                } else if (APICallType === "TrackTask") {
                    console.log("Data", ApiRes.data);
                    if (ApiRes.data.status === "success") {
                        History.push(`/tasktimemanagement/times/time/newtimentryandtrack`);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/tasks/tables/initializequeries`, type: "get"};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    useEffect(() => {
        if (IsQueryiesInitialized === true) {
            let QueryTable = "";
            let FormVal = Ref_FormValues.current;
            if (FormVal) {
                if (FormVal.is_personal === "Work") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&is_personal=false";
                    } else {
                        QueryTable = QueryTable + "?is_personal=false";
                    }
                } else if (FormVal.is_personal === "Personal") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&is_personal=true";
                    } else {
                        QueryTable = QueryTable + "?is_personal=true";
                    }
                }
                if (FormVal.is_completed === "Due") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&dueonly=true";
                    } else {
                        QueryTable = QueryTable + "?dueonly=true";
                    }
                } else if (FormVal.is_completed === "Completed") {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&dueonly=false";
                    } else {
                        QueryTable = QueryTable + "?dueonly=false";
                    }
                }

                if (FormVal.datestart && FormVal.dateend) {
                    if (QueryTable) {
                        QueryTable = QueryTable + "&datestart=" + FormVal.datestart + "&dateend=" + FormVal.dateend;
                    } else {
                        QueryTable = QueryTable + "?datestart=" + FormVal.datestart + "&dateend=" + FormVal.dateend;
                    }
                }
            }
            console.log("QueryTable", QueryTable);

            const ApiCall = {url: `${ProdURL}/api/tasks/query/${QueryTable}`, type: "get"};
            setLoading(true);
            setAPICallType("InitalizeTable");
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [IsQueryiesInitialized]);

    useEffect(() => {
        if (ReloadTable === true) {
            GenerateTableComponent();
        }
    }, [ReloadTable, GenerateTableComponent]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        // console.log("Handle Close Edit Card");
        setShowEditCard(false);
        setEditCardId(null);
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setTimeout(() => {
            HandleFilter();
        }, 300);
    };

    const HandleCloseDuplicateCard = (Id) => {
        // console.log("Should close the card")
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setShowEditCard(false);
        setEditCardId(null);
        setTimeout(() => {
            setReloadTable(true);
        }, 300);
    };

    // const HandleCloseViewCard = () => {
    //     setShowViewCard(false);
    //     setViewCardId(null);
    //     //Reload the table
    //     setIsInitalized(false);
    //     setTimeout(() => {
    //         setIsInitalized(true);
    //     }, 50);
    // };

    const HandleUpdateFormValues = (field, values) => {
        //Note!!! with Date Pickers it's mandatory to use the Ref and not the state ....impossible to understand why but that is it
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        setFormValues({...Ref_FormValues.current, ...{[field]: value}});
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: value}};
    };

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        Ref_FormValues.current = {...Ref_FormValues.current, ...{[field]: SelectedValues}};
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        // if (field === "ConditionalSelect1") {
        //     //We update the Options of the ConditionalSelect2
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect1 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
        //     //We also need to reset the Conditional 2;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        // }
        // if (field === "ConditionalSelect2") {
        //     //We update the Options of the ConditionalSelect3
        //     let SubOptions = [];
        //     FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
        //         if (elem.ref_ConditionalSelect2 === SelectedValues) {
        //             let newsub = {value: elem.id, label: elem.name};
        //             SubOptions.push(newsub);
        //         }
        //     });
        //     setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
        //     //We also need to reset the Conditional 3;
        //     setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        // }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    const HandleFilter = () => {
        let QueryTable = "";
        let FormVal = Ref_FormValues.current;
        if (FormVal) {
            if (FormVal.is_personal === "Work") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&is_personal=false";
                } else {
                    QueryTable = QueryTable + "?is_personal=false";
                }
            } else if (FormVal.is_personal === "Personal") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&is_personal=true";
                } else {
                    QueryTable = QueryTable + "?is_personal=true";
                }
            }
            if (FormVal.is_completed === "Due") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&dueonly=true";
                } else {
                    QueryTable = QueryTable + "?dueonly=true";
                }
            } else if (FormVal.is_completed === "Completed") {
                if (QueryTable) {
                    QueryTable = QueryTable + "&dueonly=false";
                } else {
                    QueryTable = QueryTable + "?dueonly=false";
                }
            }
            if (FormVal.datestart && FormVal.dateend) {
                if (QueryTable) {
                    QueryTable = QueryTable + "&datestart=" + FormVal.datestart + "&dateend=" + FormVal.dateend;
                } else {
                    QueryTable = QueryTable + "?datestart=" + FormVal.datestart + "&dateend=" + FormVal.dateend;
                }
            }
        }
        console.log("QueryTable", QueryTable);

        const ApiCall = {url: `${ProdURL}/api/tasks/query/${QueryTable}`, type: "get"};
        setLoading(true);
        setAPICallType("Filter");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    const HandleResetFilter = () => {
        setInit(false);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;

    if (ShowEditCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <TaskCreateEdit Id={EditCardId} HandleCloseViewCard={HandleCloseEditCard} />
                </div>
            </CardModal>
        );
    }
    // if (ShowDuplicateCard === true) {
    //     ModalCardView = (
    //         <CardModal
    //             // CardID="HealFit_CreateDailyIntake"
    //             // ParentCompRef={Ref_TabNavContent}
    //             // TopBarContent={CardTitle}
    //             CloseCard={HandleCloseDuplicateCard}
    //             CloseOnClickOutside={true}
    //             WithCloseButton={true}
    //             // CardMaxWidth="800px"
    //             // CardHeight="700px"
    //             CardWindowPaddingVertical="10px"
    //             CardWindowPaddingHorizontal="10px"
    //             WithPerfectScrollbar={true}
    //             // CardWindowPaddingVertical="50px"

    //             ShowCard={true}
    //             WindowBackgroundColor="rgba(0,0,0,0.3)"
    //             CardBackgroundColor="white"
    //             CardBackground="white"
    //             ClassExtendCard={classes.ClassExtendCard}
    //         >
    //             <div className={classes.CardContent}>
    //                 <InvoiceDuplicate DuplicateId={DuplicateCardId} HandleCloseViewCard={HandleCloseDuplicateCard} />
    //             </div>
    //         </CardModal>
    //     );
    // }

    let FormComponent;

    if (IsQueryiesInitialized) {
        FormComponent = (
            <div className={classes.FormMultilineFlex}>
                <div className={classes.FormField} style={{flex: "0 0 150px", marginLeft: "0px"}}>
                    <FormCompontent
                        Name="is_completed"
                        InitialValue={InitialFormValues.is_completed ? InitialFormValues.is_completed : null}
                        FormValue={FormValues.is_completed ? FormValues.is_completed : null}
                        OutputSelectedValues={(values) => handleSelectChange("is_completed", values)}
                        SelectOptions={FieldOptions.is_completed}
                        Component="Select"
                        Variant="OutlinedLabel"
                        Label="Completed"
                        NoMessage={false}
                        // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                        // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                        MultiSelect={false}
                        // SortSelectOptions="Asc"
                        // SortField="order"
                        SortIcons={false}
                        AllowCreateOption={false}
                        LabelMaxWidth="200px"
                        LabelAlign="Left"
                        Required={true}
                    />
                </div>
                <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                    <FormCompontent
                        Name="is_personal"
                        InitialValue={InitialFormValues.is_personal ? InitialFormValues.is_personal : null}
                        FormValue={FormValues.is_completed ? FormValues.is_personal : null}
                        OutputSelectedValues={(values) => handleSelectChange("is_personal", values)}
                        SelectOptions={FieldOptions.is_personal}
                        Component="Select"
                        Variant="OutlinedLabel"
                        Label="Personal"
                        NoMessage={false}
                        // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                        // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                        MultiSelect={false}
                        // SortSelectOptions="Asc"
                        // SortField="order"
                        SortIcons={false}
                        AllowCreateOption={false}
                        LabelMaxWidth="200px"
                        LabelAlign="Left"
                        Required={true}
                    />
                </div>
                <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                    <FormCompontent
                        Name="late_status"
                        InitialValue={InitialFormValues.late_status ? InitialFormValues.late_status : null}
                        FormValue={FormValues.late_status ? FormValues.late_status : null}
                        OutputSelectedValues={(values) => handleSelectChange("late_status", values)}
                        SelectOptions={FieldOptions.late_status}
                        Component="Select"
                        Variant="OutlinedLabel"
                        Label="Status Late"
                        NoMessage={false}
                        // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                        // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                        MultiSelect={false}
                        // SortSelectOptions="Asc"
                        // SortField="order"
                        SortIcons={false}
                        AllowCreateOption={false}
                        LabelMaxWidth="200px"
                        LabelAlign="Left"
                        Required={true}
                    />
                </div>
            </div>
        );
    } else {
        FormComponent = null;
    }

    let FinalComponent;
    if (NoData === true) {
        FinalComponent = <div>There are no tasks</div>;
    } else {
        FinalComponent = (
            <React.Fragment>
                {FormComponent}
                <div className={classes.TableToolbar}>
                    <div className={classes.SearchButtons}>
                        <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleFilter}>
                            Apply Filters
                        </Button>
                        <Button ClassExtendButton={classes.ButtonExtend} Height="25px" Width="150px" onClick={HandleResetFilter} style={{marginLeft: "10px"}}>
                            Reset Filters
                        </Button>
                    </div>
                </div>
                <div className={classes.TableWrapper}>{TableComponent}</div>
            </React.Fragment>
        );
    }
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            InnerPadding="0px 10px 10px 10px"
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardView}
            {FinalComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default TasksViewAllTasksTable;
