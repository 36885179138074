import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

import CompanyAccountRouter from "./CompanyAccount/AccountRouter";
import BankAccountsRouter from "./BankAccounts/AccountsRouter";
import CreditCardsRouter from "./CreditCards/CreditCardsRouter";
import TransactionsRouter from "./Transactions/TransactionsRouter";
import AccountingRouter from "./Accounting/AccountingRouter";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const SettingsRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/companyaccount">
                    <Redirect to="/financials/companyaccount/account" />
				</Route>
				<Route path="/financials/companyaccount/account">
                    <CompanyAccountRouter />
                </Route>
                <Route path="/financials/companyaccount/bankaccounts">
                    <BankAccountsRouter />
                </Route>
                <Route path="/financials/companyaccount/creditcards">
                    <CreditCardsRouter />
                </Route>
                <Route path="/financials/companyaccount/transactions">
                    <TransactionsRouter />
                </Route>
				<Route path="/financials/companyaccount/accounting">
                    <AccountingRouter />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SettingsRouter;
