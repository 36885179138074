import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Wrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        textAlign: "left",
    },
    Uploader_Button: {
        flex: "0 1 auto",
    },
    Summary: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        color: "black",
        alignItems: "center",
    },
    SummaryRow: {
        display: "flex",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        padding: "10px 10px",
        boxSizing: "border-box",
        minHeight: "20px",
    },
}));

const Component = React.memo(function Component(props) {
    const {BankAccountId} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";

    const TableColumns = [
        {
            field: "value_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "amount",
            headerName: "Amount",
            // headerClass: "ag-CenterHeader",
            width: 130,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
		{
            field: "counterparty_bankAccount_number",
            headerName: "Bank Account",
            // headerClass: "ag-CenterHeader",
            width: 300,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        },
		
        {
            field: "communication_freeFormat",
            headerName: "Free Communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
        },
		{
            field: "communication_structured",
            headerName: "Structured Communication",
            headerClass: "ag-CenterHeader",
            width: 270,
            filter: true,
        },
    ];
    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableData, setTableData] = useState(null);
    const [SummarySync, setSummarySync] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("Error", ApiRes.error);
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data) {
                    if (ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data", Data);
                        let DataTable = Data.NewTransactions;

                        let SummaryComponent;
                        if (Data.NewTransactionsTotal > 0) {
                            SummaryComponent = (
                                <div className={classes.Summary}>
                                    <div className={classes.SummaryRow}>
                                        <div>New transactions :</div>
                                        <div style={{marginLeft: "5px"}}>{Data.NewTransactionsTotal}</div>
                                    </div>
                                    <div className={classes.SummaryRow}>
                                        <div>Current Account Balance in DB :</div>
                                        <div style={{marginLeft: "5px"}}>
                                            {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(Data.DBAccountBalance)}
                                        </div>
                                    </div>
                                    <div className={classes.SummaryRow}>
                                        <div>Sync Account Balance :</div>
                                        <div style={{marginLeft: "5px"}}>
                                            {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(Data.NewAccountBalance)}
                                        </div>
                                    </div>
                                    <div className={classes.SummaryRow}>
                                        <h2>Please upload csv transactions to reconcile the account</h2>
                                    </div>
                                </div>
                            );
                        } else {
                            SummaryComponent = (
                                <div className={classes.Summary}>
                                    <h2>Your account is up to date</h2>
                                </div>
                            );
                        }

                        setTableData(DataTable);
                        setSummarySync(SummaryComponent);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleSyncAccount = () => {
        const ApiCall = {url: `${ProdURL}/api/securebanking/syncgetransactions/${BankAccountId}`, type: "post", data: {Id: BankAccountId}};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let TableComponent;
    if (TableData) {
        TableComponent = (
            <AGGridTable
                Data={TableData}
                //
                TableColumns={TableColumns}
                MainDataSource="Invoices"
                TableOptions={TableOptions}
            />
        );
    }

    return (
        <div className={classes.Wrapper}>
            <div className={classes.Uploader_Button}>
                <Button Width="250px" onClick={HandleSyncAccount}>
                    Sync Bank Account
                </Button>
            </div>
            {SummarySync}
            <div className={classes.TableWrapper}>{TableComponent}</div>
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
