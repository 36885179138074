import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const GenerateInvoiceFromTransactions = React.memo(function GenerateInvoiceFromTransactions(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Initialized, setInitialized] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [ApiRes, setApiRes] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                // console.log("ApiRes", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    let data = ApiRes.data.data;

                    console.log("data",data)
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleGenerateFromTransactions = () => {
        console.log("Loading");
        setInitialized(true);
        const ApiCall = {url: `${ProdURL}/api/companyaccount/generateinvoices`, type: "get"};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalComponent = null;
    if (Initialized === false) {
        FinalComponent = (
            <React.Fragment>
                <h1>Generate from account transactions</h1>
                <Button onClick={HandleGenerateFromTransactions}>Generate Invoice</Button>
            </React.Fragment>
        );
    } else if (Loading === true) {
        FinalComponent = <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />;
    } else {
        FinalComponent = <h2>Completed</h2>;
    }

    return <div>{FinalComponent}</div>;

    /***************** RENDER ******************/
});

export default GenerateInvoiceFromTransactions;
