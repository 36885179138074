import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import _ from "lodash";
import ExpandableBox from "../../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {Id, CustomerId, HandleShareAddressInformation} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [isIntialized, setisIntialized] = useState(true);

    const [APICallType, setAPICallType] = useState("InitSelect");
    const [InitialFormValues, setInitialFormValues] = React.useState({
        ref_Bill_To_Address: null,
        street_line1: null,
        street_line2: null,
        zip_code: null,
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({
        ref_Bill_To_Address: null,
        street_line1: null,
        street_line2: null,
        zip_code: null,
    }); //FINAL FORM VALUES

    const [FieldOptions, setFieldOptions] = useState({
        ref_Bill_To_Address: [],
    });
    const [FullaccountsData, setFullaccountsData] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                // console.log("ApiRes-address", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    let data = ApiRes.data.data;
                    let CountriesList = data.Countries;
                    let AddressTypesList = data.CompanyAddressTypes;

                    var CompanyAddresses = [];
                    let Country, AddressType;

                    let DefaultAddressInformation;

                    if (data.CompanyAddresses && data.CompanyAddresses.length > 0) {
                        if (Id) {
                            
                            CompanyAddresses = data.CompanyAddresses.map((elem) => {
                                if (elem._id === Id) {
                                    DefaultAddressInformation = elem;
                            
                                    if (elem.ref_address_type) {
                                        //We find the corresponding value
                                        if (AddressTypesList && AddressTypesList.length > 0) {
                                            let addressType = _.find(AddressTypesList, {_id: elem.ref_address_type});
                                            if (addressType) {
                                                AddressType = addressType.name_EN;
                                            }
                                        }
                                    }
                                    if (elem.ref_country) {
                                        //We find the corresponding country
                                        if (CountriesList && CountriesList.length > 0) {
                                            let country = _.find(CountriesList, {_id: elem.ref_country});
                                            if (country) {
                                                Country = country.country_name_EN;
                                            }
                                        }
                                    }
                                }

                                let FullAddress;

                                if (elem.ref_address_type) {
                                    //We find the corresponding value
                                    if (AddressTypesList && AddressTypesList.length > 0) {
                                        let addressType = _.find(AddressTypesList, {_id: elem.ref_address_type});
                                        if (addressType) {
                                            FullAddress = addressType.name_EN + " - ";
                                        }
                                    }
                                }

                                FullAddress = FullAddress + elem.street_line1 + " - " + elem.city;

                                if (elem.ref_country) {
                                    //We find the corresponding country
                                    if (CountriesList && CountriesList.length > 0) {
                                        let country = _.find(CountriesList, {_id: elem.ref_country});
                                        if (country) {
                                            FullAddress = FullAddress + " - " + country.country_name_EN;
                                        }
                                    }
                                }
                                return {value: elem._id, label: FullAddress};
                            });
                        } else {
                            CompanyAddresses = data.CompanyAddresses.map((elem) => {
                                if (elem.is_defaultAddress === true) {
                                    DefaultAddressInformation = elem;
                                    if (elem.ref_address_type) {
                                        //We find the corresponding value
                                        if (AddressTypesList && AddressTypesList.length > 0) {
                                            let addressType = _.find(AddressTypesList, {_id: elem.ref_address_type});
                                            if (addressType) {
                                                AddressType = addressType.name_EN;
                                            }
                                        }
                                    }
                                    if (elem.ref_country) {
                                        //We find the corresponding country
                                        if (CountriesList && CountriesList.length > 0) {
                                            let country = _.find(CountriesList, {_id: elem.ref_country});
                                            if (country) {
                                                Country = country.country_name_EN;
                                            }
                                        }
                                    }
                                }

                                let FullAddress;

                                if (elem.ref_address_type) {
                                    //We find the corresponding value
                                    if (AddressTypesList && AddressTypesList.length > 0) {
                                        let addressType = _.find(AddressTypesList, {_id: elem.ref_address_type});
                                        if (addressType) {
                                            FullAddress = addressType.name_EN + " - ";
                                        }
                                    }
                                }

                                FullAddress = FullAddress + elem.street_line1 + " - " + elem.city;

                                if (elem.ref_country) {
                                    //We find the corresponding country
                                    if (CountriesList && CountriesList.length > 0) {
                                        let country = _.find(CountriesList, {_id: elem.ref_country});
                                        if (country) {
                                            FullAddress = FullAddress + " - " + country.country_name_EN;
                                        }
                                    }
                                }
                                return {value: elem._id, label: FullAddress};
                            });
                        }
                    }

                    if (DefaultAddressInformation) {
                        setFormValues({
                            ref_Bill_To_Address: DefaultAddressInformation._id,
                            street_line1: DefaultAddressInformation.street_line1,
                            street_line2: DefaultAddressInformation.street_line2,
                            zip_code: DefaultAddressInformation.zip_code,
                            AddressType: AddressType,
                            Country: Country,
                        });
                        setInitialFormValues({
                            ref_Bill_To_Address: DefaultAddressInformation._id,
                            street_line1: DefaultAddressInformation.street_line1,
                            street_line2: DefaultAddressInformation.street_line2,
                            zip_code: DefaultAddressInformation.zip_code,
                            AddressType: AddressType,
                            Country: Country,
                        });
                        HandleShareAddressInformation({
                            ref_Bill_To_Address: DefaultAddressInformation._id,
                        });
                    } else {
                        setFormValues({account_number: null, street_line1: null, street_line2: null, zip_code: null, Country: null, AddressType: null});
                        setInitialFormValues({account_number: null, street_line1: null, street_line2: null, zip_code: null, Country: null, AddressType: null});
                        HandleShareAddressInformation({
                            ref_Bill_To_Address: null,
                        });
                    }

                    //We also select the default account if it exist

                    setFullaccountsData(data.CompanyAddresses);

                    setFieldOptions({...FieldOptions, ...{ref_Bill_To_Address: CompanyAddresses}});
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        setLoading(true);

        const ApiCall = {url: `${ProdURL}/api/companies/company/companyaddresses/${CustomerId}`, type: "get"};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, [CustomerId]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        if (field === "ref_Bill_To_Address") {
            
            //We find the data corrsponding to the account CustomerId
            let DataForAccount = _.find(FullaccountsData, ["_id", SelectedValues]);

            UpdatedFormValues = {...UpdatedFormValues, ...{street_line1: DataForAccount.street_line1, street_line2: DataForAccount.street_line2, zip_code: DataForAccount.zip_code}};
            setFormValues(UpdatedFormValues);
            HandleShareAddressInformation({
                ref_Bill_To_Address: SelectedValues,
            });
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalComponent;
    if (isIntialized === true) {
        FinalComponent = (
            <ExpandableBox
                TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Address details</h3>}
                DefaultStyle="List"
                // TransitionEffect={{Duration : "600ms"}}
                // MaxWidth="1020px"
                // CustomController={<InfoCircleBlue />}
                // CustomControllerNoRotation={true}
                // BackgroundColorTopBar="grey"
                // ClassExtendsExpandableBox={classes.ExpandableBox}
                // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                // MinWidth="1020px"
                // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                MaxHeightOpened="150px"
                OpenOnTopClick={true}
                WithPerfectScrollbar={false}
                WithScrollbar={null}
                FlexMode={true}
            >
                <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                    <FormCompontent
                        Name="ref_Bill_To_Address"
                        InitialValue={InitialFormValues.ref_Bill_To_Address ? InitialFormValues.ref_Bill_To_Address : null}
                        FormValue={FormValues.ref_Bill_To_Address ? FormValues.ref_Bill_To_Address : null}
                        OutputSelectedValues={(values) => handleSelectChange("ref_Bill_To_Address", values)}
                        SelectOptions={FieldOptions.ref_Bill_To_Address}
                        Component="Select"
                        Variant="Outlined"
                        Label="Select an address"
                        NoMessage={false}
                        MultiSelect={false}
                        SortSelectOptions="Asc"
                        // SortIcons={true}
                        AllowCreateOption={false}
                        // CreateDeleteIfUnselected={}
                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                        LabelMaxWidth="300px"
                        LabelAlign="Left"
                        Required={false}
                        Clearable={false}
                    />
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="AddressType"
                            InitialValue={InitialFormValues.AddressType ? InitialFormValues.AddressType : null}
                            FormValue={FormValues.AddressType ? FormValues.AddressType : null}
                            OutputValue={(values) => HandleUpdateFormValues("AddressType", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Address Type"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="street_line1"
                            InitialValue={InitialFormValues.street_line1 ? InitialFormValues.street_line1 : null}
                            FormValue={FormValues.street_line1 ? FormValues.street_line1 : null}
                            OutputValue={(values) => HandleUpdateFormValues("street_line1", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Address line 1"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{display: InitialFormValues.street_line2 ? "flex" : "none"}}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="street_line2"
                            InitialValue={InitialFormValues.street_line2 ? InitialFormValues.street_line2 : null}
                            FormValue={FormValues.street_line2 ? FormValues.street_line2 : null}
                            OutputValue={(values) => HandleUpdateFormValues("street_line2", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Address line 2"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="zip_code"
                            InitialValue={InitialFormValues.zip_code ? InitialFormValues.zip_code : null}
                            FormValue={FormValues.zip_code ? FormValues.zip_code : null}
                            OutputValue={(values) => HandleUpdateFormValues("zip_code", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Zip/Postal code"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="Country"
                            InitialValue={InitialFormValues.Country ? InitialFormValues.Country : null}
                            FormValue={FormValues.Country ? FormValues.Country : null}
                            OutputValue={(values) => HandleUpdateFormValues("Country", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Country"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
            </ExpandableBox>
        );
    } else {
        FinalComponent = null;
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
