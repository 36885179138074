/* eslint-disable */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import {TableContextProvider} from "../utils/context/tableContext/tableContext";

import TableWithContext from "../TableWithContext/TableWithContext";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const Table = React.memo(
    function Table(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {
            URLAbsolute,
            URLRelative,
            //eslint-disable-next-line
            LocalData,
            MainDataSource,
            ColomnsInput,
            TableDimensions,
            TableOptions,
            DevMode,
            ShareTableData,
            UpdateDataFromOutisde,
			TotalRow
        } = props;
        const theme = useTheme();
        //eslint-disable-next-line
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** CONST *********************/
        /****************************** CONST *********************/

        /****************************** STATE *********************/

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/

        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        return (
            <TableContextProvider>
                <TableWithContext {...props} />
            </TableContextProvider>
        );

        /****************************** RENDER *********************/
    }
    // ,
    // (prevProps, nextProps) => {
    //     // const {
    //     //     NoResizeCalc,
    //     //     appMaxWidth,
    //     //     mobileWidth,
    //     //     GeoLocation,
    //     //     withCookies,
    //     //     animationIntro,
    //     //     animationTime,
    //     //     AppMainComponent,
    //     //     MobileComponent,
    //     //     DesktopComponent,
    //     //     SiteCookiesList,
    //     //     CookiesComponent,
    //     //     HomePageAnimationComponent,
    //     // } = props;
    //     if (prevProps === nextProps) {
    //         return true;
    //     }
    //     return false;
    // }
);

Table.defaultProps = {
    LocalData: null,
    URLAbsolute: null,
    URLRelative: null,
    MainDataSource: null,
    ColomnsInput: null,
    TableDimensions: null,
    TableOptions: null,
    DevMode: false,
    ShareTableData: null,
    UpdateDataFromOutisde: null,
};

Table.propTypes = {
    /**
     * LocalData ={main : [], additional :[] }
     *
     */
    LocalData: PropTypes.any,

    /**
     * URLAbsolute : {
     * 	MAIN: URLAbsolute.MAIN,
     * 	DELETE: URLAbsolute.DELETE,
     * 	PUT: URLAbsolute.PUT,
     * 	POST: URLAbsolute.POST
     * }
     *
     */
    URLAbsolute: PropTypes.any,

    /**
     * URRelative : {
     * 	MAIN: URLAbsolute.MAIN,
     * 	DELETE: URLAbsolute.DELETE,
     * 	PUT: URLAbsolute.PUT,
     * 	POST: URLAbsolute.POST
     * }
     *
     */
    URLRelative: PropTypes.any,
    /**
     * MainDataSource : The name of the source for the table from the server api response
     * MainDataSource : "main"
     *
     */
    MainDataSource: PropTypes.any,
    /**
     * ColomnsInput : The columns definition to create the table
     * [
     * {width: "150px", caption: "Id", datafield: "_id", fixedCol: true, searchable: true, sortable: false, datatype: "text"},
     * ]
     */
    ColomnsInput: PropTypes.any,
    /**
     * TableDimensions
     */
    TableDimensions: PropTypes.any,
    /**
     * TableOptions
	 * const TableOptions = {
        Searchable: true,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        Selectable: true,
        RowButtons: {
			IconFormat:{
					IconSize : "10px",
					Color : "red",
					HoverColor : "green",
					DisabledColor : "blue"

				},
            Standard: {
                Edit: {Condition: null, Position: 1, OnClick: HandleEditRow},
                View: {Condition: null, Position: 2, OnClick: HandlePreviewRow},
                Delete: {Condition: deletecondition, Position: 3, OnClick: null},
            },
        },
		CommonStyle: {
            CellVerticalPadding: "0px",
            CellHorizontalPadding: "0px",
        },
        BodyStyle: {
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
            color: "blue",
        },
        FixedCellStyle: {
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
            color: "red",
        },
        HeaderStyle: {
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
            color: "green",
        },
    };
     */
    TableOptions: PropTypes.any,
    /**
     * If true -> Show Api Data to help building the table
     */
    DevMode: PropTypes.any,
    /**
     * ShareTableData : Pass the full data table to the parent component to perform operations (filter, sort...)
     */
    ShareTableData: PropTypes.any,
    /**
     * UpdateDataFromOutisde : Update the Table Data from outside to show, hide rows, cols....
     */
    UpdateDataFromOutisde: PropTypes.any,
};

export default Table;
