/* eslint-disable */
import React, {useState, useEffect, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import AxiosAsyncAPICallsInit from "@artibulles-cis/react/utils/AxiosAsyncAPICallsInit";
import NavTopBarLink from "@artibulles-cis/react/NavTopBarLink";
import NavTopBarSubMenu from "@artibulles-cis/react/NavTopBarSubMenu";
import NavTopBarSubLink from "@artibulles-cis/react/NavTopBarSubLink";

import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {useSelector} from "react-redux";

// import {RotateLeft} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CompanyLink: {
        flex: "0 0 300px",
        boxSizing: "border-box",
        padding: "0px 10px 0px 10px",
    },
    CompanyLinkContent: {
        fontSize: "30px",
    },
    CompanyText: {
        flex: "1 0 auto",
    },
    CompanyLinkActive: {
        // background: "#FF6103",
        // opacity : 0.4
    },
    RefreshButton: {
        height: "100%",
        display: "flex",
        flex: "0 0 auto",
        position: "relative",
        alignItems: "center",
        justifyContent: "flex-end",
        background: "rgb(237, 45, 146)",
        padding: "10px",
        boxSizing: "border-box",
        zIndex: 2000,
        border: "1px solid rgb(120,120,120)",
    },
    TopNavIconCompany: {
        width: "38px",
        height: "38px",
    },
    ClassExtendTopNavLink: {
        padding: "0px 5px 0px 5px",
    },
    ClassExtendNavTopBarSubMenu: {
        flex: "0 0 200px",
    },
    ClassExtendNavTopBarSubMenuAdmin: {
        flex: "0 0 200px",
    },
    ClassExtendNavTopBarSubMenu_ControllerAdmin: {
        background: "rgb(255, 68, 0)",
        color: "white",
    },
    TopNavIcon: {
        width: "45px",
        height: "45px",
    },
}));

const TopNavBar = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    

    const {UserEmail, Username, User} = useSelector((state) => ({
        User: state.auth.User.userProfile,
        UserEmail: state.auth.User.userProfile.email ? state.auth.User.userProfile.email : null,
        Username: state.auth.User.userProfile.username ? state.auth.User.userProfile.username : null,
    }));
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const [Loading, setLoading] = useState(false);
    const [APIServerResponse, setAPIServerResponse] = useState(AxiosAsyncAPICallsInit); //Contains the Server Response
    const [ApiRes, setApiRes] = useState(false);

    //Listening to API Answers

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                // console.log("Loged out", ApiRes);
                window.location.reload(true);
                if (ApiRes.data && ApiRes.data.data) {
                }
            }
        }
    }, [ApiRes, Loading]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    const HandleLogout = () => {
        const ApiCall = {url: `${ProdURL}/api/auth/logout`, type: "post", data: null};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();

        console.log("Logout");
    };

    return (
        <React.Fragment>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} ZIndex="50000" />
            <NavTopBarLink
                exact
                Slug="/"
                ClassExtendTopNavLink={classes.CompanyLink}
                ClassExtendNavTopBarLinkContent={classes.CompanyLinkContent}
                ClassExtendTopNavLinkActive={classes.CompanyLinkActive}
            >
                <img className={classes.TopNavIconCompany} src="/images/icons/ArtibullesLogo.svg" alt="Artibulles_Logo" />
                <p style={{padding: "10px"}} className={classes.CompanyText}>
                    ArTiBulles-BMS
                </p>
            </NavTopBarLink>

            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                // Slug="/customers"
                SlugList={["/crm/customers", "/crm/contacts", "/crm/prospects"]}
                ControllerContent={<p style={{padding: "10px"}}>CRM</p>}
            >
                <NavTopBarSubLink Slug="/crm/customers">Customers</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/crm/contacts">Contacts</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/crm/prospects">Propsects</NavTopBarSubLink>
            </NavTopBarSubMenu>
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                // Slug="/bodyphysicalinfo"
                SlugList={["/financials/companyaccount", "/financials/purchaseorderesinvoices", "/financials/salesinvoices", "/financials/accounting"]}
                ControllerContent={<p style={{padding: "10px"}}>Financials</p>}
            >
                <NavTopBarSubLink Slug="/financials/companyaccount">Business Analysis</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/financials/purchaseordersandinvoices">Purchases</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/financials/saleordersandinvoices">Sales</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/financials/accounting">Accounting</NavTopBarSubLink>
				<NavTopBarSubLink Slug="/financials/settings">Settings</NavTopBarSubLink>
            </NavTopBarSubMenu>
            <NavTopBarLink exact Slug="/programs" WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink}>
                Programs
            </NavTopBarLink>

            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                // Slug=
                SlugList={["/tasktimemanagement/tasks", "/tasktimemanagement/times", "/tasktimemanagement/timesheets"]}
                ControllerContent={<p style={{padding: "10px"}}>Tasks And Time Management</p>}
            >
                <NavTopBarSubLink Slug="/tasktimemanagement/tasks">Tasks</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/tasktimemanagement/times">Time Management</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/tasktimemanagement/timesheets">Time Sheets</NavTopBarSubLink>
            </NavTopBarSubMenu>

            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                // Slug="/activity"
                SlugList={["/mycompany/settings", "/mycompany/employees", "/mycompany/contacts"]}
                ControllerContent={<p style={{padding: "10px"}}>ArTiBulles Company</p>}
            >
                <NavTopBarSubLink Slug="/mycompany/settings">Company Settings</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/mycompany/employees">Employees</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/mycompany/contacts">Address Book</NavTopBarSubLink>
            </NavTopBarSubMenu>
            <NavTopBarSubMenu
                WithActiveIndicator={true}
                ClassExtendTopNavLink={classes.ClassExtendTopNavLink}
                // Slug="/settings"
                SlugList={["/logout", "/userprofile", "/appsettings", "/testrunanalysis"]}
                ControllerContent={<p style={{padding: "10px"}}>Settings</p>}
            >
                <NavTopBarSubLink Slug="/settings/userprofile">{`${Username ? Username : "User profile"}`}</NavTopBarSubLink>
                <NavTopBarSubLink Slug="/settings/appsettings">App Settings</NavTopBarSubLink>
                <NavTopBarSubLink onClick={HandleLogout}>Logout</NavTopBarSubLink>
            </NavTopBarSubMenu>
            <NavTopBarLink exact Slug="/demos" WithActiveIndicator={true} ClassExtendTopNavLink={classes.ClassExtendTopNavLink}>
                Component Demos
            </NavTopBarLink>
        </React.Fragment>
    );
};

export default TopNavBar;
