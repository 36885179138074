import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

import AccountDetailsTabRouter from "./AccountDetails/AccountDetailsTabRouter";
import AccountList from "./AccountList";
import CreateAccount from "./CreateAccount";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/companyaccount/bankaccounts">
                    <Redirect to="/financials/companyaccount/bankaccounts/account/default/overview" />
                </Route>
                <Route exact path="/financials/companyaccount/bankaccounts/account">
                    <Redirect to="/financials/companyaccount/bankaccounts/account/default/overview" />
                </Route>
                <Route path="/financials/companyaccount/bankaccounts/account/:id">
                    <AccountDetailsTabRouter />
                    
                </Route>
                <Route path="/financials/companyaccount/bankaccounts/list">
                    <AccountList />
                </Route>
                <Route path="/financials/companyaccount/bankaccounts/create">
                    <CreateAccount />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default Component;
