/**
 * ScriptName : useResizeObserver.js
 * Version : 1.0.0
 * Date : 2021/02/04
 * Author: ArnaudJaspard
 * Description : A react hook that you can use to detect if an element has been resized
 * You just need to pass the ref of the element you want to track and pass the options based on your needs
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/
import {useEffect, useRef, useState} from "react";

/********LOADING DEPENDENCIES************/

/********CORE FUNCTION************/

const useResizeObserver = (props) => {
    const {TrackElementRef = null, TrackElementRefNode = null, TrackDomElement = null} = props;

    /****************************** REFS *********************/
    //Defining the observer instance and what to do when the resize occurs
    const RefObserver = useRef(
        new ResizeObserver((entries) => {
            const ObserverDimensions = entries[0].contentRect;
            // console.log("ObserverDimensions : ", ObserverDimensions);
            setDimensions({width: ObserverDimensions.width, height: ObserverDimensions.height});

            // setBreakSize(findBreakPoint(breakpoints, width));
        })
    );
    /****************************** REFS *********************/

    /****************************** CONST *********************/
    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [Dimensions, setDimensions] = useState(null);

    /****************************** STATE *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
        // console.log("Observer use Effect");
        // console.log("TrackDomElement", TrackDomElement);
        // console.log("TrackElementRef", TrackElementRef);
        let ToObserve = RefObserver.current;
        let ToTrackElement = TrackElementRef.current;
        if (TrackDomElement) {
            ToObserve.observe(TrackDomElement);
        } else if (TrackElementRef && ToTrackElement) {
            if (TrackElementRefNode) {
                if (ToObserve) {
                    ToObserve.observe(ToTrackElement[TrackElementRefNode]);
                }
            } else {
                if (ToObserve) {
                    ToObserve.observe(ToTrackElement);
                }
            }
        }
        return () => {
            if (ToObserve) {
                if (TrackDomElement) {
                    ToObserve.unobserve(TrackDomElement);
                } else if (TrackElementRef && ToTrackElement) {
                    ToObserve.unobserve(ToTrackElement);
                }
            }
        };
    }, [TrackElementRef, TrackDomElement, RefObserver, TrackElementRefNode]);
    /****************************** EFFECT *********************/
    return Dimensions;
};

export default useResizeObserver;
/********CORE FUNCTION************/
