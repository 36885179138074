import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import useTableContext from "../utils/context/tableContext/useTableContext";
import {ArrowSimpleLeft, ArrowSimpleRight} from "@artibulles-cis/react-icons";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Pager_Page_Sizes: {
        flex: "0 1  auto",
        display: "flex",
    },
    Pager_Page_Sizes_Button: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        margin: "0px 5px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    Pager_Page_Sizes_Button_Active: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            backgroundColor: props.MainTableStyle.Footer.ActiveBackgroundSelected ? props.MainTableStyle.Footer.ActiveBackgroundSelected : FinalTheme.Footer.ActiveBackgroundSelected,
        };
    },

    Pager_Page_Selector: {
        flex: "1 1  auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    Pager_Page_Selector_Info: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 10px",
    },
    Pager_Page_Selector_Indicators: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    Pager_Page_Selector_Indicator: {
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        margin: "0px 5px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    Pager_Page_Selector_Indicator_Active: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            backgroundColor: props.MainTableStyle.Footer.ActiveBackgroundSelected ? props.MainTableStyle.Footer.ActiveBackgroundSelected : FinalTheme.Footer.ActiveBackgroundSelected,
        };
    },

    Pager_Page_Selector_Indicator_Arrow: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

/**
 * Component Description
 */

const TablePager = React.memo(
    function TablePager(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TableOptions} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        const Ref_PagerOptions = useRef(null);
        /****************************** REFS *********************/

        /****************************** STATE *********************/
        const {RowsOriginal, GeneratePagerOptions, setSortedRowsAndCols} = useTableContext();
        const [PageSelectorsComponents, setPageSelectorsComponents] = useState(null);
        const [PageRangeComponents, setPageRangeComponents] = useState(null);
        const [PageSize, setPageSize] = useState(null);
        const [TotalRows, setTotalRows] = useState(null);
        const [TotalPages, setTotalPages] = useState(null);
        const [PageSelected, setPageSelected] = useState(null);
        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/
        const GeneratePagerComponents = useCallback(() => {
            var TotalRecords = RowsOriginal.length;
			// console.log("TotalRecords", RowsOriginal)

            var PagerComp = [];

            for (let i = 0; i < TableOptions.PagerOptions.AllowedPageSizes.length; i++) {
                let TempPageSize = TableOptions.PagerOptions.AllowedPageSizes[[i]];

                if (PageSize) {
                    if (TableOptions.PagerOptions.AllowedPageSizes[i] === PageSize) {
                        PagerComp.push(
                            <div
                                key={uuidv4()}
                                data_page_range={TempPageSize}
                                className={clsx(classes.Pager_Page_Sizes_Button, classes.Pager_Page_Sizes_Button_Active)}
                                onClick={() => HandlePagerPageSizeChange(TempPageSize)}
                            >
                                {TableOptions.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    } else {
                        PagerComp.push(
                            <div key={uuidv4()} data_page_range={TempPageSize} className={clsx(classes.Pager_Page_Sizes_Button)} onClick={() => HandlePagerPageSizeChange(TempPageSize)}>
                                {TableOptions.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    }
                } else {
                    //INIT
                    if (TableOptions.PagerOptions.AllowedPageSizes[i] === TableOptions.PagerOptions.DefaultPageSize) {
                        PagerComp.push(
                            <div
                                key={uuidv4()}
                                data_page_range={TempPageSize}
                                className={clsx(classes.Pager_Page_Sizes_Button, classes.Pager_Page_Sizes_Button_Active)}
                                onClick={() => HandlePagerPageSizeChange(TempPageSize)}
                            >
                                {TableOptions.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    } else {
                        PagerComp.push(
                            <div key={uuidv4()} data_page_range={TempPageSize} className={clsx(classes.Pager_Page_Sizes_Button)} onClick={() => HandlePagerPageSizeChange(TempPageSize)}>
                                {TableOptions.PagerOptions.AllowedPageSizes[i]}
                            </div>
                        );
                    }
                }
            }

            var TotalPagesCalc;

            if (PageSize) {
                TotalPagesCalc = Math.ceil(parseInt(TotalRecords) / parseInt(PageSize));
            } else {
                TotalPagesCalc = Math.ceil(parseInt(TotalRecords) / parseInt(TableOptions.PagerOptions.DefaultPageSize));
            }

            var SelectorPages = [];

            if (PageSelected) {
                for (var j = 0; j < TotalPagesCalc; j++) {
                    var PageSelElem;
                    var TempPageSelected = j + 1;
                    if (TempPageSelected === parseInt(PageSelected)) {
                        PageSelElem = (
                            <div
                                key={uuidv4()}
                                data-page-selected={TempPageSelected}
                                onClick={(e) => HandlePagerPageSelectorChange(e)}
                                className={clsx(classes.Pager_Page_Selector_Indicator, classes.Pager_Page_Selector_Indicator_Active)}
                            >
                                {parseInt(j + 1)}
                            </div>
                        );
                    } else {
                        PageSelElem = (
                            <div key={uuidv4()} data-page-selected={TempPageSelected} onClick={(e) => HandlePagerPageSelectorChange(e)} className={classes.Pager_Page_Selector_Indicator}>
                                {parseInt(j + 1)}
                            </div>
                        );
                    }
                    SelectorPages.push(PageSelElem);
                }
            }

            var PageSelectorComp;
            if (TotalPagesCalc === 1) {
                PageSelectorComp = <React.Fragment>{SelectorPages}</React.Fragment>;
            } else {
                PageSelectorComp = (
                    <React.Fragment>
                        <div onClick={() => HandlePagerPageArrow("Previous")} className={classes.Pager_Page_Selector_Indicator_Arrow}>
                            <ArrowSimpleLeft IconSize="20px" SVGStrokeWidth="10px" />
                        </div>
                        {SelectorPages}
                        <div onClick={() => HandlePagerPageArrow("Next")} className={classes.Pager_Page_Selector_Indicator_Arrow}>
                            <ArrowSimpleRight IconSize="20px" SVGStrokeWidth="10px" />
                        </div>
                    </React.Fragment>
                );
            }
            setTotalRows(TotalRecords);
            setTotalPages(TotalPagesCalc);
            setPageRangeComponents(PagerComp);
            if (!PageSize) {
                setPageSize(TableOptions.PagerOptions.DefaultPageSize);
            }

            setPageSelectorsComponents(PageSelectorComp);

            if (!PageSelected) {
                //On Initiation
                setPageSelected(1);
                Ref_PagerOptions.current = {
                    TotalRows: TotalRecords,
                    TotalPages: TotalPagesCalc,
                    PageSize: TableOptions.PagerOptions.DefaultPageSize,
                    PageSelected: PageSelected ? PageSelected : 1,
                };
            }

            
			
				GeneratePagerOptions(Ref_PagerOptions.current);	
			
            
        }, [RowsOriginal, PageSize, PageSelected]);

        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/

        useEffect(() => {
            if (RowsOriginal && Array.isArray(RowsOriginal) && RowsOriginal.length > 0) {
                GeneratePagerComponents();
            }
        }, [RowsOriginal, GeneratePagerComponents]);

		

        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        const HandlePagerPageSizeChange = (RangeSel) => {
            setPageSize(RangeSel);
            setPageSelected(1);
            Ref_PagerOptions.current.PageSize = RangeSel;
            Ref_PagerOptions.current.PageSelected = 1;
        };
        const HandlePagerPageSelectorChange = (event) => {
            let Selected = event.target.getAttribute("data-page-selected");
            setPageSelected(Selected);
            Ref_PagerOptions.current.PageSelected = Selected;
        };
        const HandlePagerPageArrow = (direction) => {
            if (direction === "Previous") {
                if (PageSelected > 1) {
                    setPageSelected(PageSelected - 1);
                    Ref_PagerOptions.current.PageSelected = parseInt(PageSelected) - 1;
                }
            } else {
                if (PageSelected < TotalPages) {
                    setPageSelected(PageSelected + 1);
                    Ref_PagerOptions.current.PageSelected = parseInt(PageSelected) + 1;
                }
            }
        };

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/

        return (
            <React.Fragment>
                <div className={classes.Pager_Page_Sizes}>{PageRangeComponents}</div>
                <div className={classes.Pager_Page_Selector}>
                    <div className={classes.Pager_Page_Selector_Info}> {`Page ${PageSelected} of ${TotalPages} (${TotalRows} items)`}</div>
                    <div className={classes.Pager_Page_Selector_Indicators}>{PageSelectorsComponents}</div>
                </div>
            </React.Fragment>
        );

        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TablePager.defaultProps = {
    MyProp: null,
};

TablePager.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TablePager;
