import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import CalendarRollerPickers from "../../../../../artibulles-cis/CalendarRollerPickers/CalendarRollerPickers";
import {format} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const TestScrollRoller = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [SelectedDate, setSelectedDate] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleDateChange = (arg) => {
        // console.log("arg", arg);
        setSelectedDate(arg);
    };
    const HandleNavigateToMonth = () => {
        // setGoToMonth(2);
    };
    const HandleNavigateToDay = () => {};
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div style={{textAlign: "center"}}>
            <div style={{height: "50px"}}></div>
            <CalendarRollerPickers
                OutputDate={HandleDateChange}
                InitialDate={new Date(Date.now())}
                //
                // CustomType={[{Roller: "Year"},{Roller: "Month"},{Roller: "Day"}, {Roller: "Hour"}, {Roller: "Minute"}, {Roller: "Second"}]}
                // CustomType={[{Roller: "Day"}, {Roller: "Month", Format: "LLLL", Alignement: "left"}, {Roller: "Year"}]}
                // QuickType={{Roller: "MonthYear", MonthFormat: "L"}}
                // QuickType={{Roller: "TimeHM", MinuteInterval: "5"}}
                CustomType={[{Roller: "Month", Width: "300px"}]}
                Rows="7"
            />
        </div>
    );
    // {SelectedDate ? `Date : ${format(SelectedDate, "dd/MM/yyyy - HH:mm:ss")}` : null}
    /***************** RENDER ******************/
};

export default TestScrollRoller;
