/* eslint-disable */
import React, {useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import FormDev from "../../../../../../artibulles-cis/FormDev/FormDev";
import Button from "@artibulles-cis/react/Button";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";
import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../artibulles-cis/CheckBox/CheckBox";
import {formatISO} from "date-fns";
import clsx from "clsx";
import {Company} from "@artibulles-cis/react-icons";
import CustomerEditDetailsTabNav from "./CustomerEditDetailsTabNav";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        overflow: "hidden",
        textAlign: "left",
    },
    FormMainScroll: {
        // flex: "0 0 1330px",
        height: "100%",
        overflowY: "hidden",
        boxSizing: "border-box",
        // padding: "0px 10px",
        // border: "1px solid grey",
        // maxWidth: "1400px",
    },
    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        height: "35px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "0 0 1400px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightInfo: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 45px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 5px 0px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
    InvoiceDetailsSection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // maxWidth: "1400px",
        // padding: "10px 10px 0px 10px",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 5px",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "ConditionalSelect1") {
            //We update the Options of the ConditionalSelect2
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
                if (elem.ref_ConditionalSelect1 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
            //We also need to reset the Conditional 2;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        //Conditional Fields
        if (field === "is_VAT_Intracomm") {
            if (value === true) {
                //we Delete the values for the VAT amount  and the total without vat
                UpdateFormValues.vat_amount = null;
                UpdateFormValues.amount_without_vat = null;
            }
        }

        if (field === "accounting_Justification_needed") {
            if (value === false) {
                //We clear the justification provided
                //we clear the justification date

                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = null;
            } else {
                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = formatISO(new Date(Date.now()));
            }
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (filesPath, fieldName, originalNames) => {
        if (fieldName) {
            let FullNamesPath = [];
            for (var i = 0; i < filesPath.length; i++) {
                FullNamesPath.push({serverPath: filesPath[i], originalName: originalNames[i]});
            }
            FilesUploaders[fieldName] = FullNamesPath;
            console.log("FilesUploaders", FilesUploaders);
            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES EXCEPTED FOR THE CREATE SELECT MULTI
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    if (key === "credit_card_Location_Keywords" || key === "credit_card_Merchant_Keywords") {
                        //We need to retrieve the label and not the value
                        let Final_credit_card_Location_Keywords = [];
                        if (FormValues.credit_card_Location_Keywords) {
                            for (let i = 0; i < FormValues.credit_card_Location_Keywords.length; i++) {
                                let Entry = _.find(FieldOptions.credit_card_Location_Keywords, {value: FormValues.credit_card_Location_Keywords[i]});

                                Final_credit_card_Location_Keywords.push(Entry.label);
                            }
                        }

                        FinalSubmittedValues = {...FinalSubmittedValues, ...{credit_card_Location_Keywords: Final_credit_card_Location_Keywords}};

                        let Final_credit_card_Merchant_Keywords = [];
                        if (FormValues.credit_card_Merchant_Keywords) {
                            for (let i = 0; i < FormValues.credit_card_Merchant_Keywords.length; i++) {
                                let Entry = _.find(FieldOptions.credit_card_Merchant_Keywords, {value: FormValues.credit_card_Merchant_Keywords[i]});

                                Final_credit_card_Merchant_Keywords.push(Entry.label);
                            }
                        }

                        FinalSubmittedValues = {...FinalSubmittedValues, ...{credit_card_Merchant_Keywords: Final_credit_card_Merchant_Keywords}};
                    } else {
                        //Get the IntialValue
                        let InitialValue = InitialFormValues[key];
                        let Value = FormValues[key];
                        if (InitialValue !== Value && key) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                        }
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };
    const HandleCreateInitialInvoice = () => {
        HandleSubmitForm("SaveDraft");
    };

    const HandleDetailsChanged = () => {
        HandleSubmitForm("SaveDraft");
    };
	

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let FormTitle, DetailsComponent;
    if (InitialFormValues.ApiID === "new") {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitleMain}>
                    <div className={classes.FormTitle_Box}>
                        <div className={classes.FormTitle_Logo_Wrapper}>
                            <Company IconSize="30px" SVGFillColor="white" />
                        </div>
                        <div className={classes.FormTitle_Title_Wrapper}>
                            <h1 className={classes.FormTitle_Title}>Customer</h1>
                            <div className={classes.ActionButton}>
                                <Button Height="30px" Width="200px" onClick={HandleCreateInitialInvoice} disabled={Invalid || Pristine}>
                                    Create Customer
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        DetailsComponent = (
            <div className={classes.InvoiceDetailsSection}>
                <div className={classes.InvoiceDetailsTabNavWrapper} style={{marginTop: "5px", borderTop: "1px solid grey"}}>
                    Create Customer to add additional details
                </div>
            </div>
        );
    } else {
        FormTitle = (
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitleMain}>
                    <div className={classes.FormTitle_Box}>
                        <div className={classes.FormTitle_Logo_Wrapper}>
                            <Company IconSize="30px" SVGFillColor="white" />
                        </div>
                        <div className={classes.FormTitle_Title_Wrapper}>
                            <h1 className={classes.FormTitle_Title}>Customer</h1>
                            <div className={classes.ActionButton}>
                                <Button Height="30px" Width="200px" onClick={HandleCreateInitialInvoice} disabled={Invalid || Pristine}>
                                    Save Changes
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        DetailsComponent = (
            <div className={classes.InvoiceDetailsSection}>
                <div className={classes.InvoiceDetailsTabNavWrapper}>
                    <CustomerEditDetailsTabNav
                        CustomerID={InitialFormValues.ApiID}
                        HandleDetailsChanged={HandleDetailsChanged}
                        FormViewMode={FormViewMode}
                        FormValues={FormValues}
                        FieldOptions={FieldOptions}
						
                    />
                </div>
            </div>
        );
    }
    var FinalFormComponent;

    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.FormWrapper}>
                {FormTitle}
                <div className={classes.FormMainScroll}>
                    <PerfectScrollBarWrapper Active={true}>
                        <div className={classes.FormLeft}>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                    <FormCompontent
                                        Name="company"
                                        InitialValue={InitialFormValues.company ? InitialFormValues.company : null}
                                        FormValue={FormValues.company ? FormValues.company : null}
                                        OutputValue={(values) => HandleUpdateFormValues("company", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Company"
                                        NoMessage={false}
                                        meta={{error: FormErrors.company, invalid: FormErrors.company ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("company", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"

                                        LabelMaxWidth="100px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="300px"
                                    />
                                </div>

                                <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                    <FormCompontent
                                        Name="ref_companySiteType"
                                        InitialValue={InitialFormValues.ref_companySiteType ? InitialFormValues.ref_companySiteType : null}
                                        FormValue={FormValues.ref_companySiteType ? FormValues.ref_companySiteType : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_companySiteType", values)}
                                        SelectOptions={FieldOptions.ref_companySiteType}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Site Type"
                                        NoMessage={false}
                                        meta={{error: FormErrors.ref_companySiteType, invalid: FormErrors.ref_companySiteType ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("ref_companySiteType", error)}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="100px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="220px"
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 800px"}}>
                                    <FormCompontent
                                        Name="description"
                                        InitialValue={InitialFormValues.description ? InitialFormValues.description : null}
                                        FormValue={FormValues.description ? FormValues.description : null}
                                        OutputValue={(values) => HandleUpdateFormValues("description", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="Description"
                                        NoMessage={false}
                                        meta={{error: FormErrors.description, invalid: FormErrors.description ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("description", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"
                                        LabelMaxWidth="100px"
                                        FieldMaxWidth="632px"
                                        multiline={true}
                                        RowsToDisplay="2"
                                        MaxRows="2"
                                        LabelAlign="Left"
                                        Required={false}
                                    />
                                </div>
                            </div>
                            

                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                    <FormCompontent
                                        Name="ref_industry"
                                        InitialValue={InitialFormValues.ref_industry ? InitialFormValues.ref_industry : null}
                                        FormValue={FormValues.ref_industry ? FormValues.ref_industry : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_industry", values)}
                                        SelectOptions={FieldOptions.ref_industry}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Industries"
                                        NoMessage={false}
                                        meta={{error: FormErrors.ref_industry, invalid: FormErrors.ref_industry ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("ref_industry", error)}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="100px"
                                        FieldMaxWidth="200px"
                                        LabelAlign="Left"
                                        ReadOnly={true}
                                    />
                                </div>
                                <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                    <FormCompontent
                                        Name="ref_sector"
                                        InitialValue={InitialFormValues.ref_sector ? InitialFormValues.ref_sector : null}
                                        FormValue={FormValues.ref_sector ? FormValues.ref_sector : null}
                                        OutputSelectedValues={(values) => handleSelectChange("ref_sector", values)}
                                        SelectOptions={FieldOptions.ref_sector}
                                        Component="Select"
                                        Variant="Outlined"
                                        Label="Sectors"
                                        NoMessage={false}
                                        meta={{error: FormErrors.ref_sector, invalid: FormErrors.ref_sector ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("ref_sector", error)}
                                        MultiSelect={false}
                                        SortSelectOptions="Asc"
                                        // SortIcons={true}
                                        AllowCreateOption={false}
                                        // CreateDeleteIfUnselected={}
                                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                        LabelMaxWidth="100px"
                                        FieldMaxWidth="200px"
                                        LabelAlign="Left"
                                        ReadOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={classes.FormMultilineFlex}>
                                <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                                    <FormCompontent
                                        Name="vat_number"
                                        InitialValue={InitialFormValues.vat_number ? InitialFormValues.vat_number : null}
                                        FormValue={FormValues.vat_number ? FormValues.vat_number : null}
                                        OutputValue={(values) => HandleUpdateFormValues("vat_number", values)}
                                        Component="Input"
                                        Variant="Outlined"
                                        Label="VAT Number"
                                        NoMessage={false}
                                        meta={{error: FormErrors.vat_number, invalid: FormErrors.vat_number ? true : false}}
                                        OutputError={(error) => HandleUpdateErrors("vat_number", error)}
                                        // LegendOffsetHeight="0"
                                        // LegendOffsetWidth="0"

                                        LabelMaxWidth="100px"
                                        LabelAlign="Left"
                                        FieldMaxWidth="300px"
                                    />
                                </div>
                            </div>
                        </div>
                        {DetailsComponent}
                        <FormDev
                            DevMode={DevMode}
                            FormValues={FormValues}
                            InitialFormValues={InitialFormValues}
                            FieldOptions={FieldOptions}
                            FieldSubOptionsFull={FieldSubOptionsFull}
                            FilesUploaders={FilesUploaders}
                            FormErrors={FormErrors}
                            NewFieldOptions={NewFieldOptions}
                            PristineDetails={PristineDetails}
                        />
                        {/* Button Bar */}
                        <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                            {`${FormErrorMain ? FormErrorMain : null}`}
                        </div>

                        {/* Button Bar */}
                    </PerfectScrollBarWrapper>
                </div>
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <React.Fragment>{FinalFormComponent}</React.Fragment>;
});
export default Component;
