import React, {useEffect, useState, useRef, Fragment, useCallback} from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import clsx from "clsx";
import _ from "lodash";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {
    TrackElement: {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        flex: "1 1 auto",
        textAlign: "center",
        boxSizing: "border-box",
        // pointerEvents: "none", //To prevent touching the elements
        cursor: "pointer",
        // zIndex:10
    },
    ViewerIndicator: {
        position: "absolute",
        top: 0,
        boxSizing: "border-box",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        padding: 0,
        margin: 0,
    },
};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Roller: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",
    },
    Track: {
        position: "relative",
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "center",
        alignItems: "center",
        overflowY: "hidden",
        // overflowY: "visible",
        overflowX: "visible",
        userSelect: "none",
    },
    ViewerIndicator: theme.PickerRoller.ViewerIndicator,

    Track_Animated: {
        "& $TrackElement": {
            // transition: "transform 500ms cubic-bezier(0.45, 0, 0.55, 1)",
        },
    },
    OriginalElementsWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    TrackElement: theme.PickerRoller.TrackElement,

    TrackElement_Active: theme.PickerRoller.TrackElement_Active,
    CalcFakeElem: {},
    HiddenElems: {
        visibility: "hidden",
        display: "flex",
        alignItems: "center",
        width: "100%",
        flex: "1 1 auto",
        boxSizing: "border-box",
        padding: "0px 5px",
    },
}));

/**
 * Component Description
 */

const PickerRoller = React.memo(function PickerRoller(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InputArray,
        PaddingBetweenElements,
        Width,
        SlidesToShow,
        Alignement,
        UpdateSelected,
        InitialIndex,
        NavigateToIndex,
        RollerName,
        RollerType,
        RollerMonthLength,
        InitialYear,
        YearMin,
        YearMax,
        AutoWidth,
        //
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/
    /****************************** REFS *********************/
    const Ref_Track = useRef();
    const Ref_FakeTrackElement = useRef();
    const Ref_ScrollDirection = useRef(0);
    const Ref_WheelScrolling = useRef(false);
    const Ref_MouseMoveDistance = useRef(0);
    const Ref_MouseTimeStamp = useRef(0);
    /****************************** REFS *********************/

    /****************************** STATE *********************/

    const [TrackParameters, setTrackParameters] = useState({
        PaddingBetweenElements: 2,
        SlidesToShow: null,
        SlidesToScroll: null,
        TotalSlides: null,
        InitialSlide: 0,
        centeredSlide: true,
    });
    const [TrackElementsStatus, setTrackElementsStatus] = useState([]);
    const [UnmutableTrackElementsStatus, setUnmutableTrackElementsStatus] = useState([]);
    const [LocalInitialArray, setLocalInitialArray] = useState(null);
    const [LocalInitialIndex, setLocalInitialIndex] = useState(null);
    const [LocaleRollerMonthLength, setLocaleRollerMonthLength] = useState(null);
    const [Initialized, setInitialized] = useState(false);
    const [GoToIndex, setGoToIndex] = useState(null);
    const [ActiveIndex, setActiveIndex] = useState({NonCentered: null, Centered: null, OriginalArray: null}); //NonCentered = the top one wihout additional translation 1 by default, the centered one is translated by the Central steps, the original is compared to the original Array
    const [ScrollY, setScrollY] = useState(0);
    const [TranslateStep, setTranslateStep] = useState(0);
    const [TrackElements, setTrackElements] = useState([]); //Storing the track elements only
    const [ViewerComponent, setViewerComponent] = useState(null);
    const [AutoWidthTrackElements, setAutoWidthTrackElements] = useState([]); //to control the width automatically

    const [TouchMouseDistance, setTouchMouseDistance] = useState(0);
    /****************************** STATE *********************/

    /***************** CALLBACK ******************/
    /**************** GENERATE TRACK ELEMENTS FOR STANDARD ROLLER ************/
    const GenerateTrackElements = useCallback(() => {
        if (InputArray) {
            //1.We calculate all the dimensions and the track parameters.
            //Here we do it manually
            let UpdatedTrackParameters = {
                PaddingBetweenElements: 2,
                SlidesToShow: parseFloat(SlidesToShow),
                SlidesToScroll: 1,
                TotalSlides: InputArray.length,
                CentralIsActive: true,
                CentralIndex: null,
            };

            //1. Calculating the height of 1 track element
            const FakeElem = Ref_FakeTrackElement.current;
            const FakeElemH = FakeElem.getBoundingClientRect().height;
            let UpdatedTranslateStep = FakeElemH + 2 * PaddingBetweenElements;

            var GoToIndexInit = 1;
            //Determining the central index and the original index
            /**
             * The way the index works, is that the position 0 is -t, therefore the defulat visible is the Index 1 - the Active Index
             * [-t, 0, +t, +2t, ....] translation elements values, length =2*max
             * [0 , 1,  2,   3, ....] translation elements index,  length =2*max
             * [max , 0,  1,   2, ....] original elements index, length =max
             * GoToIndex is the one of the translations -> if you need a value, you need to calculate what is the index of the translations array
             * ActiveIndex NonCentered is the one that would be visible on top without any additional translation - > corresponds to the one with the 0 translation value
             */

            if (UpdatedTrackParameters.CentralIsActive === true) {
                if (UpdatedTrackParameters.SlidesToShow % 2 == 0) {
                    UpdatedTrackParameters.CentralIsActive = false; //Cannot work for even numbers
                    return;
                }
                let InitOriginalSlideIndex = parseInt(InitialIndex);
                UpdatedTrackParameters.CentralIndex = (UpdatedTrackParameters.SlidesToShow - 1) / 2;
                GoToIndexInit = InitOriginalSlideIndex + 1; //We add one because the visible one is not 0 but 1 in the translations array
            }

            //We create the track Elements once the Track has been rendered
            let NewTrackElements = [];
            let NewAutoWidthTrackElements = [];
            let TranslationArrayStatus = [];
            //Adding 1 element before the original elements - always the last index
            let NewElemBefore = (
                <div
                    className={clsx(classes.TrackElement)}
                    data-track="track-element"
                    data-index="-1"
                    style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                >
                    {InputArray[UpdatedTrackParameters.TotalSlides - 1]}
                </div>
            );
            NewTrackElements.push(NewElemBefore);
            if (AutoWidth === true) {
                let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {InputArray[UpdatedTrackParameters.TotalSlides - 1]}</div>;
                NewAutoWidthTrackElements.push(AutoWidthElem);
            }
            //Setting the Initial translation
            let StatusElemBefore = {translateY: -UpdatedTranslateStep, isActive: false, dataIndex: -1, index: 0, originalIndex: UpdatedTrackParameters.TotalSlides - 1};
            TranslationArrayStatus.push(StatusElemBefore);

            //Adding the original elements - all of them
            for (var i = 0; i < UpdatedTrackParameters.TotalSlides; i++) {
                let NewElem = (
                    <div
                        className={clsx(classes.TrackElement)}
                        data-track="track-element"
                        data-index={i}
                        style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                    >
                        {InputArray[i]}
                    </div>
                );
                NewTrackElements.push(NewElem);
                if (AutoWidth === true) {
                    let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {InputArray[i]}</div>;
                    NewAutoWidthTrackElements.push(AutoWidthElem);
                }
                let StatusElem = {translateY: i * UpdatedTranslateStep, isActive: i === 0 ? true : false, dataIndex: i, index: i + 1, originalIndex: i};
                TranslationArrayStatus.push(StatusElem);
            }

            //Adding the new elements after the original element - we need to create a full loop -> added slides = TotalSlides-1
            for (var i = 0; i < UpdatedTrackParameters.TotalSlides - 1; i++) {
                let NewElemAfter = (
                    <div
                        className={clsx(classes.TrackElement)}
                        data-track="track-element"
                        data-index={UpdatedTrackParameters.TotalSlides + i}
                        style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                        //For cloning ->TrackElement.innerHTML = this.OriginalItems[TotalSlides - SlidesToShow + i].innerHTML;
                    >
                        {InputArray[i]}
                    </div>
                );
                NewTrackElements.push(NewElemAfter);
                if (AutoWidth === true) {
                    let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {InputArray[i]}</div>;
                    NewAutoWidthTrackElements.push(AutoWidthElem);
                }
                let StatusElemAfter = {
                    translateY: (UpdatedTrackParameters.TotalSlides + i) * UpdatedTranslateStep,
                    isActive: false,
                    index: UpdatedTrackParameters.TotalSlides + i + 1,
                    dataIndex: UpdatedTrackParameters.TotalSlides + i,
                    originalIndex: i,
                };
                TranslationArrayStatus.push(StatusElemAfter);
            }

            let ViewerComp = (
                <div
                    className={classes.ViewerIndicator}
                    style={{
                        ...Lockedstyles.ViewerIndicator,
                        ...{
                            height: `${UpdatedTranslateStep}px`,
                            transform: `translateY(${UpdatedTrackParameters.CentralIndex * UpdatedTranslateStep}px)`,
                        },
                    }}
                ></div>
            );

            setViewerComponent(ViewerComp);
            setTrackParameters(UpdatedTrackParameters);
            setTranslateStep(UpdatedTranslateStep);
            setTrackElements(NewTrackElements);
            setAutoWidthTrackElements(NewAutoWidthTrackElements);
            setTrackElementsStatus(TranslationArrayStatus);
            setGoToIndex(GoToIndexInit);
            setLocalInitialArray(InputArray);
            setLocalInitialIndex(InitialIndex);
            setInitialized(true);
        }
    }, [Ref_Track, Ref_FakeTrackElement]);
    /**************** GENERATE TRACK ELEMENTS FOR STANDARD ROLLER ************/

    /**************** GENERATE TRACK ELEMENTS FOR DAYS ROLLER ************/
    const GenerateDaysTrackElements = useCallback(() => {
        //Special case for month roller - we need to always have 31 days but we will hide the days based on the MonthLength Input - If not there would be flashes of rerefsh on screen all the time
        //1. We generate all 31 days component
        var DaysMonthArray = [];
        for (var i = 1; i < 32; i++) {
            DaysMonthArray.push(`${i}`);
        }

        //1.We calculate all the dimensions and the track parameters.
        //Here we do it manually
        let UpdatedTrackParameters = {
            PaddingBetweenElements: 2,
            SlidesToShow: parseFloat(SlidesToShow),
            SlidesToScroll: 1,
            TotalSlides: DaysMonthArray.length,
            CentralIsActive: true,
            CentralIndex: null,
        };

        //1. Calculating the height of 1 track element
        const FakeElem = Ref_FakeTrackElement.current;
        const FakeElemH = FakeElem.getBoundingClientRect().height;
        let UpdatedTranslateStep = FakeElemH + 2 * PaddingBetweenElements;

        var GoToIndexInit = 1;
        //Determining the central index and the original index
        /**
         * The way the index works, is that the position 0 is -t, therefore the defulat visible is the Index 1 - the Active Index
         * [-t, 0, +t, +2t, ....] translation elements values, length =2*max
         * [0 , 1,  2,   3, ....] translation elements index,  length =2*max
         * [max , 0,  1,   2, ....] original elements index, length =max
         * GoToIndex is the one of the translations -> if you need a value, you need to calculate what is the index of the translations array
         * ActiveIndex NonCentered is the one that would be visible on top without any additional translation - > corresponds to the one with the 0 translation value
         */

        if (UpdatedTrackParameters.CentralIsActive === true) {
            if (UpdatedTrackParameters.SlidesToShow % 2 == 0) {
                UpdatedTrackParameters.CentralIsActive = false; //Cannot work for even numbers
                return;
            }
            let InitOriginalSlideIndex = parseInt(InitialIndex);
            UpdatedTrackParameters.CentralIndex = (UpdatedTrackParameters.SlidesToShow - 1) / 2;
            GoToIndexInit = InitOriginalSlideIndex + 1; //We add one because the visible one is not 0 but 1 in the translations array
        }

        //We create the track Elements once the Track has been rendered
        let NewTrackElements = [];
        let NewAutoWidthTrackElements = [];
        let TranslationArrayStatus = [];
        //Adding 1 element before the original elements - always the last index
        let NewElemBefore = (
            <div
                className={classes.TrackElement}
                data-track="track-element"
                data-index="-1"
                style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
            >
                {DaysMonthArray[UpdatedTrackParameters.TotalSlides - 1]}
            </div>
        );
        NewTrackElements.push(NewElemBefore);
        if (AutoWidth === true) {
            let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {DaysMonthArray[UpdatedTrackParameters.TotalSlides - 1]}</div>;
            NewAutoWidthTrackElements.push(AutoWidthElem);
        }
        //Setting the Initial translation
        let StatusElemBefore = {translateY: -UpdatedTranslateStep, isActive: false, dataIndex: -1, index: 0, originalIndex: UpdatedTrackParameters.TotalSlides - 1, isHidden: false};
        TranslationArrayStatus.push(StatusElemBefore);

        //Adding the original elements - all of them
        for (var i = 0; i < UpdatedTrackParameters.TotalSlides; i++) {
            let NewElem = (
                <div
                    className={classes.TrackElement}
                    data-track="track-element"
                    data-index={i}
                    style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                >
                    {DaysMonthArray[i]}
                </div>
            );
            NewTrackElements.push(NewElem);
            if (AutoWidth === true) {
                let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {DaysMonthArray[i]}</div>;
                NewAutoWidthTrackElements.push(AutoWidthElem);
            }
            let StatusElem = {translateY: i * UpdatedTranslateStep, isActive: i === 0 ? true : false, dataIndex: i, index: i + 1, originalIndex: i, isHidden: false};
            TranslationArrayStatus.push(StatusElem);
        }

        //Adding the new elements after the original element - we need to create a full loop -> added slides = TotalSlides-1
        for (var i = 0; i < UpdatedTrackParameters.TotalSlides - 1; i++) {
            let NewElemAfter = (
                <div
                    className={clsx(classes.TrackElement)}
                    data-track="track-element"
                    data-index={UpdatedTrackParameters.TotalSlides + i}
                    style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                >
                    {DaysMonthArray[i]}
                </div>
            );
            NewTrackElements.push(NewElemAfter);
            if (AutoWidth === true) {
                let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {DaysMonthArray[i]}</div>;
                NewAutoWidthTrackElements.push(AutoWidthElem);
            }
            let StatusElemAfter = {
                translateY: (UpdatedTrackParameters.TotalSlides + i) * UpdatedTranslateStep,
                isActive: false,
                index: UpdatedTrackParameters.TotalSlides + i + 1,
                dataIndex: UpdatedTrackParameters.TotalSlides + i,
                originalIndex: i,
                isHidden: false,
            };
            TranslationArrayStatus.push(StatusElemAfter);
        }

        let ViewerComp = (
            <div
                className={classes.ViewerIndicator}
                style={{
                    ...Lockedstyles.ViewerIndicator,
                    ...{
                        height: `${UpdatedTranslateStep}px`,
                        transform: `translateY(${UpdatedTrackParameters.CentralIndex * UpdatedTranslateStep}px)`,
                    },
                }}
            ></div>
        );

        setViewerComponent(ViewerComp);
        setTrackParameters(UpdatedTrackParameters);
        setTranslateStep(UpdatedTranslateStep);
        setTrackElements(NewTrackElements);
        setAutoWidthTrackElements(NewAutoWidthTrackElements);
        setTrackElementsStatus(TranslationArrayStatus);
        setUnmutableTrackElementsStatus(TranslationArrayStatus);
        setGoToIndex(GoToIndexInit);
        setLocalInitialArray(DaysMonthArray);
        setLocalInitialIndex(InitialIndex);
        setInitialized(true);
    }, [Ref_Track, Ref_FakeTrackElement]);
    /**************** GENERATE TRACK ELEMENTS FOR DAYS ROLLER ************/

    /**************** GENERATE TRACK ELEMENTS FOR YEARS ROLLER ************/
    const GenerateYearsTrackElements = useCallback(() => {
        //Special case for Year roller
        //1. We generate the years structure based on the user's inputs
        var ReadyToGo = false;
        var YearsArray = [];
        var InitYearIndex;
        if (YearMin & YearMax) {
            let YearLength = parseInt(YearMax) - parseInt(YearMin) + 1;
            if (YearLength < SlidesToShow) {
                //We need to add years to compensate for the stupid user :-)
            } else {
                for (var i = 0; i < YearLength; i++) {
                    let NewYear = parseInt(YearMin) + i;
                    if (parseInt(InitialYear) === NewYear) {
                        InitYearIndex = i;
                    }
                    YearsArray.push(NewYear);
                }
            }
            ReadyToGo = true;
        } else if (InitialYear && !YearMin && !YearMax) {
            //This is a completely different situation - we cannot update the components continuously because it would flash on screen, instead we will change the inner html
            //We first need to generate an array of years by the size of the SlideToShow
            let Half = (SlidesToShow - 1) / 2;

            for (var i = 0; i < Half + 1; i++) {
                let NewYear = parseInt(InitialYear) - Half + i - 1;
                YearsArray.push(NewYear);
            }
            YearsArray.push(InitialYear);
            for (var i = 0; i < Half + 1; i++) {
                let NewYear = parseInt(InitialYear) + i + 1;
                YearsArray.push(NewYear);
            }
            InitYearIndex = Half;
            ReadyToGo = true;
        }

        /**************** INFINITE NON CIRCULAR ROLLER ************/
        if (ReadyToGo === true && !YearMin && !YearMax) {
            //1.We calculate all the dimensions and the track parameters.
            //Here we do it manually
            let UpdatedTrackParameters = {
                PaddingBetweenElements: 2,
                SlidesToShow: parseFloat(SlidesToShow),
                SlidesToScroll: 1,
                TotalSlides: YearsArray.length,
                CentralIsActive: true,
                CentralIndex: null,
            };

            //1. Calculating the height of 1 track element
            const FakeElem = Ref_FakeTrackElement.current;
            const FakeElemH = FakeElem.getBoundingClientRect().height;
            let UpdatedTranslateStep = FakeElemH + 2 * PaddingBetweenElements;

            var GoToIndexInit = 1;
            //Determining the central index and the original index
            /**
             * The way the index works, is that the position 0 is -t, therefore the defulat visible is the Index 1 - the Active Index
             * [-t, 0, +t, +2t, ....] translation elements values, length =2*max
             * [0 , 1,  2,   3, ....] translation elements index,  length =2*max
             * [max , 0,  1,   2, ....] original elements index, length =max
             * GoToIndex is the one of the translations -> if you need a value, you need to calculate what is the index of the translations array
             * ActiveIndex NonCentered is the one that would be visible on top without any additional translation - > corresponds to the one with the 0 translation value
             */

            if (UpdatedTrackParameters.CentralIsActive === true) {
                if (UpdatedTrackParameters.SlidesToShow % 2 == 0) {
                    UpdatedTrackParameters.CentralIsActive = false; //Cannot work for even numbers
                    return;
                }
                let InitOriginalSlideIndex = parseInt(InitYearIndex);
                UpdatedTrackParameters.CentralIndex = (UpdatedTrackParameters.SlidesToShow - 1) / 2;
                GoToIndexInit = InitOriginalSlideIndex + 1; //We add one because the visible one is not 0 but 1 in the translations array
            }

            //We create the track Elements once the Track has been rendered
            let NewTrackElements = [];
            let NewAutoWidthTrackElements = [];
            let TranslationArrayStatus = [];
            //Adding 1 element before the original elements - always the last index
            let NewElemBefore = (
                <div
                    className={classes.TrackElement}
                    data-track="track-element"
                    data-index="-1"
                    style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                >
                    {YearsArray[0]}
                </div>
            );
            NewTrackElements.push(NewElemBefore);
            if (AutoWidth === true) {
                let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {YearsArray[0]}</div>;
                NewAutoWidthTrackElements.push(AutoWidthElem);
            }
            //Setting the Initial translation
            let StatusElemBefore = {
                translateY: -UpdatedTranslateStep,
                isActive: false,
                dataIndex: -1,
                index: 0,
                originalIndex: UpdatedTrackParameters.TotalSlides - 1,
                YearValue: YearsArray[0],
            };
            TranslationArrayStatus.push(StatusElemBefore);

            for (var i = 1; i < UpdatedTrackParameters.TotalSlides; i++) {
                let NewElem = (
                    <div
                        className={classes.TrackElement}
                        data-track="track-element"
                        data-index={i - 1}
                        style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                    >
                        {YearsArray[i]}
                    </div>
                );
                NewTrackElements.push(NewElem);
                if (AutoWidth === true) {
                    let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {YearsArray[i]}</div>;
                    NewAutoWidthTrackElements.push(AutoWidthElem);
                }
                let StatusElem = {
                    translateY: (i - 1) * UpdatedTranslateStep,
                    isActive: i === UpdatedTrackParameters.CentralIndex + 1 ? true : false,
                    dataIndex: i - 1,
                    index: i,
                    originalIndex: i,
                    YearValue: YearsArray[i],
                };
                TranslationArrayStatus.push(StatusElem);
            }
            let ViewerComp = (
                <div
                    className={classes.ViewerIndicator}
                    style={{
                        ...Lockedstyles.ViewerIndicator,
                        ...{
                            height: `${UpdatedTranslateStep}px`,
                            transform: `translateY(${UpdatedTrackParameters.CentralIndex * UpdatedTranslateStep}px)`,
                        },
                    }}
                ></div>
            );

            setViewerComponent(ViewerComp);
            setTrackParameters(UpdatedTrackParameters);
            setTranslateStep(UpdatedTranslateStep);
            setTrackElements(NewTrackElements);
            setAutoWidthTrackElements(NewAutoWidthTrackElements);
            setTrackElementsStatus(TranslationArrayStatus);
            setUnmutableTrackElementsStatus(TranslationArrayStatus);
            setGoToIndex(GoToIndexInit);
            setLocalInitialArray(YearsArray);
            setLocalInitialIndex(InitialIndex);
            setInitialized(true);
        } else {
            /**************** WITH MIN AND MAX YEAR CIRUCLAR ROLLER ************/
            let UpdatedTrackParameters = {
                PaddingBetweenElements: 2,
                SlidesToShow: parseFloat(SlidesToShow),
                SlidesToScroll: 1,
                TotalSlides: YearsArray.length,
                CentralIsActive: true,
                CentralIndex: null,
            };
            //1. Calculating the height of 1 track element
            const FakeElem = Ref_FakeTrackElement.current;
            const FakeElemH = FakeElem.getBoundingClientRect().height;
            let UpdatedTranslateStep = FakeElemH + 2 * PaddingBetweenElements;

            var GoToIndexInit = 1;
            //Determining the central index and the original index
            /**
             * The way the index works, is that the position 0 is -t, therefore the defulat visible is the Index 1 - the Active Index
             * [-t, 0, +t, +2t, ....] translation elements values, length =2*max
             * [0 , 1,  2,   3, ....] translation elements index,  length =2*max
             * [max , 0,  1,   2, ....] original elements index, length =max
             * GoToIndex is the one of the translations -> if you need a value, you need to calculate what is the index of the translations array
             * ActiveIndex NonCentered is the one that would be visible on top without any additional translation - > corresponds to the one with the 0 translation value
             */

            if (UpdatedTrackParameters.CentralIsActive === true) {
                if (UpdatedTrackParameters.SlidesToShow % 2 == 0) {
                    UpdatedTrackParameters.CentralIsActive = false; //Cannot work for even numbers
                    return;
                }
                let InitOriginalSlideIndex = parseInt(InitYearIndex);
                UpdatedTrackParameters.CentralIndex = (UpdatedTrackParameters.SlidesToShow - 1) / 2;
                GoToIndexInit = InitOriginalSlideIndex + 1; //We add one because the visible one is not 0 but 1 in the translations array
            }

            //We create the track Elements once the Track has been rendered
            let NewTrackElements = [];
            let NewAutoWidthTrackElements = [];
            let TranslationArrayStatus = [];
            //Adding 1 element before the original elements - always the last index
            let NewElemBefore = (
                <div
                    className={clsx(classes.TrackElement)}
                    data-track="track-element"
                    data-index="-1"
                    style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                    //For cloning ->TrackElement.innerHTML = this.OriginalItems[TotalSlides - SlidesToShow + i].innerHTML;
                >
                    {YearsArray[UpdatedTrackParameters.TotalSlides - 1]}
                </div>
            );
            NewTrackElements.push(NewElemBefore);
            if (AutoWidth === true) {
                let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {YearsArray[UpdatedTrackParameters.TotalSlides - 1]}</div>;
                NewAutoWidthTrackElements.push(AutoWidthElem);
            }
            //Setting the Initial translation
            let StatusElemBefore = {
                translateY: -UpdatedTranslateStep,
                isActive: false,
                dataIndex: -1,
                index: 0,
                originalIndex: UpdatedTrackParameters.TotalSlides - 1,
                YearValue: YearsArray[UpdatedTrackParameters.TotalSlides - 1],
            };
            TranslationArrayStatus.push(StatusElemBefore);

            //Adding the original elements - all of them
            for (var i = 0; i < UpdatedTrackParameters.TotalSlides; i++) {
                let NewElem = (
                    <div
                        className={classes.TrackElement}
                        data-track="track-element"
                        data-index={i}
                        style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                    >
                        {YearsArray[i]}
                    </div>
                );
                NewTrackElements.push(NewElem);

                if (AutoWidth === true) {
                    let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {YearsArray[i]}</div>;
                    NewAutoWidthTrackElements.push(AutoWidthElem);
                }
                let StatusElem = {translateY: i * UpdatedTranslateStep, isActive: i === 0 ? true : false, dataIndex: i, index: i + 1, originalIndex: i, YearValue: YearsArray[i]};
                TranslationArrayStatus.push(StatusElem);
            }

            //Adding the new elements after the original element - we need to create a full loop -> added slides = TotalSlides-1
            for (var i = 0; i < UpdatedTrackParameters.TotalSlides - 1; i++) {
                let NewElemAfter = (
                    <div
                        className={clsx(classes.TrackElement)}
                        data-track="track-element"
                        data-index={UpdatedTrackParameters.TotalSlides + i}
                        style={{...Lockedstyles.TrackElement, ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement}}}
                    >
                        {YearsArray[i]}
                    </div>
                );
                NewTrackElements.push(NewElemAfter);
                if (AutoWidth === true) {
                    let AutoWidthElem = <div className={clsx(classes.HiddenElems, classes.TrackElement)}> {YearsArray[i]}</div>;
                    NewAutoWidthTrackElements.push(AutoWidthElem);
                }
                let StatusElemAfter = {
                    translateY: (UpdatedTrackParameters.TotalSlides + i) * UpdatedTranslateStep,
                    isActive: false,
                    index: UpdatedTrackParameters.TotalSlides + i + 1,
                    dataIndex: UpdatedTrackParameters.TotalSlides + i,
                    originalIndex: i,
                    YearValue: YearsArray[i],
                };
                TranslationArrayStatus.push(StatusElemAfter);
            }

            let ViewerComp = (
                <div
                    className={classes.ViewerIndicator}
                    style={{
                        ...Lockedstyles.ViewerIndicator,
                        ...{
                            height: `${UpdatedTranslateStep}px`,
                            transform: `translateY(${UpdatedTrackParameters.CentralIndex * UpdatedTranslateStep}px)`,
                        },
                    }}
                ></div>
            );

            setViewerComponent(ViewerComp);
            setTrackParameters(UpdatedTrackParameters);
            setTranslateStep(UpdatedTranslateStep);
            setTrackElements(NewTrackElements);
            setAutoWidthTrackElements(NewAutoWidthTrackElements);
            setTrackElementsStatus(TranslationArrayStatus);
            setGoToIndex(GoToIndexInit);
            setLocalInitialArray(YearsArray);
            setLocalInitialIndex(InitialIndex);
            setInitialized(true);
        }
    }, [Ref_Track, Ref_FakeTrackElement]);
    /**************** GENERATE TRACK ELEMENTS FOR DAYS ROLLER ************/

    /***************** CALLBACK ******************/

    /****************************** EFFECT *********************/
    //Initializing the plugin - calculating the dimensions, parameters and creating the elements
    useEffect(() => {
        if (Initialized === false) {
            if (Ref_FakeTrackElement.current) {
                if (RollerType === "Days") {
                    GenerateDaysTrackElements();
                } else if (RollerType === "Years") {
                    GenerateYearsTrackElements();
                } else {
                    GenerateTrackElements();
                }
            }
        }
    }, [Ref_FakeTrackElement, GenerateTrackElements, Initialized]);

    //Used to update the Full content if the inputs are changing
    useEffect(() => {
        //Only refreshing for standard roller
        if (RollerType != "Days") {
            if (InputArray !== LocalInitialArray) {
                setInitialized(false);
            }
        }
    }, [InputArray, InitialIndex]);

    //Used to update the Month Roller only when MonthLength is changing without rerendering it completely
    useEffect(() => {
        if (Initialized === true && RollerType === "Days") {
            if (RollerMonthLength !== LocaleRollerMonthLength) {
                var MaxTrigger;
                if (parseInt(RollerMonthLength) === 30) {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2 - 2) * TranslateStep; //we have removed 2 values
                } else if (parseInt(RollerMonthLength) === 29) {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2 - 4) * TranslateStep; //we have removed 2 values
                } else if (parseInt(RollerMonthLength) === 28) {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2 - 6) * TranslateStep; //we have removed 2 values
                } else {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2) * TranslateStep;
                }
                //We may also need to update the initial Index that may have changed
                var GoToIndexInit;
                if (LocalInitialIndex !== parseInt(InitialIndex) + 1) {
                    GoToIndexInit = parseInt(InitialIndex) + 1; //We add one because the visible one is not 0 but 1 in the translations array
                } else {
                    GoToIndexInit = GoToIndex;
                }

                let TrackElementsHtml = Ref_Track.current.querySelectorAll(`[data-track = "track-element"]`);
                var UpdatedTrackElementsStatus = JSON.parse(JSON.stringify(UnmutableTrackElementsStatus)); //Important - We need to start from the unmuted status otherwise all calculations are wrong
                var UpdatedActiveIndex = {NonCentered: null, Centered: null, OriginalArray: null};
                var RealOriginalIndex;
                if (parseInt(RollerMonthLength) < 31) {
                    if (parseInt(RollerMonthLength) === 30) {
                        //We need to update the translation status
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElemHidden = {
                                translateY: null,
                                isActive: null,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: true,
                            };
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: false,
                            };
                            if (i === 0 || i === 31) {
                                //We hide the two indexes corresponding to the 31 value
                                UpdatedTrackElementsStatus[i] = UpdateTrackElemHidden;
                            }
                            if (i > 31 && i < 61) {
                                //We need to translate those by -t
                                UpdateTrackElem.translateY = UpdatedTrackElementsStatus[i].translateY - TranslateStep;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                            if (i === 61) {
                                //Value 30 becomes the -t
                                UpdateTrackElem.translateY = -TranslateStep;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                        }
                    }
                    if (parseInt(RollerMonthLength) === 28) {
                        //We need to update the translation status
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElemHidden = {
                                translateY: null,
                                isActive: null,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: true,
                            };
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: false,
                            };
                            if (i === 0 || i === 29 || i === 30 || i === 31 || i === 60 || i === 61) {
                                //We hide the 6 indexes corresponding to the 30 & 31 value
                                UpdatedTrackElementsStatus[i] = UpdateTrackElemHidden;
                            }
                            if (i > 31 && i < 60) {
                                //We need to translate those by -3*t
                                UpdateTrackElem.translateY = UpdatedTrackElementsStatus[i].translateY - 3 * TranslateStep;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                            if (i === 59) {
                                //Value 28 becomes the -t
                                UpdateTrackElem.translateY = -TranslateStep;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                        }
                    }
                    if (parseInt(RollerMonthLength) === 29) {
                        //We need to update the translation status
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElemHidden = {
                                translateY: null,
                                isActive: null,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: true,
                            };
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: false,
                            };
                            if (i === 0 || i === 30 || i === 31 || i === 61) {
                                //We hide the 4 indexes corresponding to the 30 & 31 value
                                UpdatedTrackElementsStatus[i] = UpdateTrackElemHidden;
                            }
                            if (i > 31 && i < 61) {
                                //We need to translate those by -2*t
                                UpdateTrackElem.translateY = UpdatedTrackElementsStatus[i].translateY - 2 * TranslateStep;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                            if (i === 60) {
                                //Value 29 becomes the -t
                                UpdateTrackElem.translateY = -TranslateStep;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                        }
                    }
                    // Now we can start moving to the centered value to init the roller
                    const CurrentIndex = 1; //The default active one
                    var Cycles = GoToIndexInit - CurrentIndex - TrackParameters.CentralIndex;
                    const translateDirection = -Math.sign(Cycles);

                    for (var j = 0; j < Math.abs(Cycles); j++) {
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            //We skip the hidden elements
                            if (UpdatedTrackElementsStatus[i].isHidden === false) {
                                var UpdateTrackElem = {
                                    translateY: null,
                                    isActive: false,
                                    index: UpdatedTrackElementsStatus[i].index,
                                    dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                    originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                    isHidden: UpdatedTrackElementsStatus[i].isHidden,
                                };

                                let NextTranstlateElem = UpdatedTrackElementsStatus[i].translateY + TranslateStep * translateDirection;

                                if (NextTranstlateElem > MaxTrigger) {
                                    NextTranstlateElem = -TranslateStep;
                                } else if (NextTranstlateElem < -TranslateStep) {
                                    NextTranstlateElem = MaxTrigger;
                                }
                                if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                                    UpdateTrackElem.isActive = true;
                                    RealOriginalIndex = UpdateTrackElem.originalIndex;
                                }
                                UpdateTrackElem.translateY = NextTranstlateElem;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                        }
                    }

                    UpdatedActiveIndex.NonCentered = GoToIndexInit;
                    UpdatedActiveIndex.Centered = GoToIndexInit;
                    UpdatedActiveIndex.OriginalArray = GoToIndexInit;
                } else {
                    //Nothing to hide just use the standard translation matrix
                    //Inititializing -> Simulate 1 step translation to reach the GoToIndex
                    const CurrentIndex = 1; //The default active one
                    var Cycles = GoToIndexInit - CurrentIndex - TrackParameters.CentralIndex;
                    const translateDirection = -Math.sign(Cycles);

                    for (var j = 0; j < Math.abs(Cycles); j++) {
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                isHidden: false, //Unhiding if they were hidden
                            };
                            let NextTranstlateElem = UpdatedTrackElementsStatus[i].translateY + TranslateStep * translateDirection;

                            if (NextTranstlateElem > MaxTrigger) {
                                NextTranstlateElem = -TranslateStep;
                            } else if (NextTranstlateElem < -TranslateStep) {
                                NextTranstlateElem = MaxTrigger;
                            }
                            if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                                UpdateTrackElem.isActive = true;
                                RealOriginalIndex = UpdateTrackElem.originalIndex;
                            }
                            UpdateTrackElem.translateY = NextTranstlateElem;
                            UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                        }
                    }

                    UpdatedActiveIndex.NonCentered = GoToIndexInit;
                    UpdatedActiveIndex.Centered = GoToIndexInit;
                    UpdatedActiveIndex.OriginalArray = GoToIndexInit;
                }

                setTrackElementsStatus(UpdatedTrackElementsStatus);

                setActiveIndex(UpdatedActiveIndex);

                for (var i = 0; i < TrackElementsHtml.length; i++) {
                    if (UpdatedTrackElementsStatus[i].isHidden === true) {
                        TrackElementsHtml[i].style.display = "none";
                    } else {
                        TrackElementsHtml[i].style.transform = `translateY(${UpdatedTrackElementsStatus[i].translateY}px)`;
                        TrackElementsHtml[i].style.display = "flex";
                        if (UpdatedTrackElementsStatus[i].isActive === true) {
                            TrackElementsHtml[i].classList.add(classes.TrackElement_Active);
                        } else {
                            TrackElementsHtml[i].classList.remove(classes.TrackElement_Active);
                        }
                    }
                }

                setGoToIndex(GoToIndexInit);
                setLocalInitialIndex(GoToIndexInit);
            }
        }
    }, [RollerMonthLength, Initialized, RollerType]);

    //When Scrolling Updating the position of each slides - live - for standard roller only
    useEffect(() => {
        if (Initialized === true && RollerType !== "Days") {
            if (RollerType === "Years" && !YearMin && !YearMax) {
            } else {
                var UpdateState = false;

                var UpdatedTrackElementsStatus = [];
                const MaxTrigger = (2 * TrackParameters.TotalSlides - 2) * TranslateStep;
                var UpdatedActiveIndex = {NonCentered: null, Centered: null, OriginalArray: null};
                var RealOriginalIndex;
                if (ActiveIndex.NonCentered === null) {
                    UpdateState = true;

                    //Inititializing -> Simulate 1 step translation to reach the GoToIndex
                    const CurrentIndex = 1; //The default active one
                    const Cycles = GoToIndex - CurrentIndex - TrackParameters.CentralIndex;

                    const translateDirection = -Math.sign(Cycles);
                    UpdatedTrackElementsStatus = JSON.parse(JSON.stringify(TrackElementsStatus));

                    for (var j = 0; j < Math.abs(Cycles); j++) {
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                YearValue: UpdatedTrackElementsStatus[i].YearValue,
                            };
                            let NextTranstlateElem = UpdatedTrackElementsStatus[i].translateY + TranslateStep * translateDirection;

                            if (NextTranstlateElem > MaxTrigger) {
                                NextTranstlateElem = -TranslateStep;
                            } else if (NextTranstlateElem < -TranslateStep) {
                                NextTranstlateElem = MaxTrigger;
                            }
                            if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                                UpdateTrackElem.isActive = true;
                                RealOriginalIndex = UpdateTrackElem.originalIndex;
                            }
                            UpdateTrackElem.translateY = NextTranstlateElem;
                            UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                        }
                    }
                    UpdatedActiveIndex.NonCentered = GoToIndex;
                    UpdatedActiveIndex.Centered = GoToIndex;
                    UpdatedActiveIndex.OriginalArray = GoToIndex;
                } else {
                    if (GoToIndex && GoToIndex !== ActiveIndex.NonCentered) {
                        UpdateState = true;

                        //We need to find the closest Index corresponding to the OriginalArray Index
                        const CurrentIndex = ActiveIndex.NonCentered; //The default active one

                        var ClosestIndex = {Distance: null, Index: null};
                        var MatchIndexes = [];

                        TrackElementsStatus.forEach((elem) => {
                            if (elem.originalIndex === GoToIndex) {
                                MatchIndexes.push(elem.index);
                                let Distance = Math.abs(elem.index - CurrentIndex);
                                if (ClosestIndex.Distance === null) {
                                    ClosestIndex.Distance = Distance;
                                    ClosestIndex.Index = elem.index;
                                } else {
                                    if (Distance < ClosestIndex.Distance) {
                                        ClosestIndex.Distance = Distance;
                                        ClosestIndex.Index = elem.index;
                                    }
                                }
                            }
                        });

                        const Cycles = ClosestIndex.Index - CurrentIndex;

                        UpdatedTrackElementsStatus = JSON.parse(JSON.stringify(TrackElementsStatus));
                        const translateDirection = -Math.sign(Cycles);
                        for (var j = 0; j < Math.abs(Cycles); j++) {
                            for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                                var UpdateTrackElem = {
                                    translateY: null,
                                    isActive: false,
                                    index: UpdatedTrackElementsStatus[i].index,
                                    dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                    originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                    YearValue: UpdatedTrackElementsStatus[i].YearValue,
                                };
                                let NextTranstlateElem = UpdatedTrackElementsStatus[i].translateY + TranslateStep * translateDirection;

                                if (NextTranstlateElem > MaxTrigger) {
                                    NextTranstlateElem = -TranslateStep;
                                } else if (NextTranstlateElem < -TranslateStep) {
                                    NextTranstlateElem = MaxTrigger;
                                }
                                if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                                    UpdateTrackElem.isActive = true;
                                    RealOriginalIndex = UpdateTrackElem.originalIndex;
                                }
                                UpdateTrackElem.translateY = NextTranstlateElem;
                                UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                            }
                        }
                        UpdatedActiveIndex.NonCentered = GoToIndex;
                        UpdatedActiveIndex.Centered = GoToIndex;
                        UpdatedActiveIndex.OriginalArray = GoToIndex;
                    } else if (ScrollY) {
                        UpdateState = true;

                        //NormalStuff
                        let TranslateStepsSign = Math.sign(ScrollY) * TranslateStep;

                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: TrackElementsStatus[i].index,
                                dataIndex: TrackElementsStatus[i].dataIndex,
                                originalIndex: TrackElementsStatus[i].originalIndex,
                                YearValue: TrackElementsStatus[i].YearValue,
                            };
                            let NextTranstlateElem = TrackElementsStatus[i].translateY + TranslateStepsSign;

                            if (NextTranstlateElem > MaxTrigger) {
                                NextTranstlateElem = -TranslateStep;
                            } else if (NextTranstlateElem < -TranslateStep) {
                                NextTranstlateElem = MaxTrigger;
                            }
                            if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                                UpdateTrackElem.isActive = true;

                                UpdatedActiveIndex.NonCentered = TrackElementsStatus[i].index;
                                UpdatedActiveIndex.Centered = TrackElementsStatus[i].index;
                                UpdatedActiveIndex.OriginalArray = TrackElementsStatus[i].index;
                                RealOriginalIndex = UpdateTrackElem.originalIndex;
                            }
                            UpdateTrackElem.translateY = NextTranstlateElem;
                            UpdatedTrackElementsStatus.push(UpdateTrackElem);
                        }
                    }
                }
                if (UpdateState === true) {
                    setTrackElementsStatus(UpdatedTrackElementsStatus);
                    setActiveIndex(UpdatedActiveIndex);
                    setGoToIndex(null);
                    setScrollY(0);
                    if (UpdateSelected && typeof UpdateSelected === "function") {
                        if (RollerType === "Years") {
                            let CurrentYearSelected = UpdatedTrackElementsStatus[RealOriginalIndex + 1].YearValue;
                            UpdateSelected(CurrentYearSelected);
                        } else {
                            UpdateSelected(RealOriginalIndex);
                        }
                    }
                    let TrackElementsHtml = Ref_Track.current.querySelectorAll(`[data-track = "track-element"]`);

                    for (var i = 0; i < TrackElementsHtml.length; i++) {
                        TrackElementsHtml[i].style.transform = `translateY(${UpdatedTrackElementsStatus[i].translateY}px)`;
                        if (UpdatedTrackElementsStatus[i].isActive === true) {
                            TrackElementsHtml[i].classList.add(classes.TrackElement_Active);
                        } else {
                            TrackElementsHtml[i].classList.remove(classes.TrackElement_Active);
                        }
                    }
                }
                // Ref_WheelScrolling.current = false;
            }
        }
    }, [ScrollY, Initialized, GoToIndex, RollerType]);

    //When Scrolling Updating the position of each slides - live - for Month Roller only
    useEffect(() => {
        if (Initialized === true && RollerType === "Days") {
            var UpdateState = false;
            if (ScrollY !== 0) {
                var UpdatedTrackElementsStatus = [];
                var MaxTrigger;
                if (parseInt(RollerMonthLength) === 30) {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2 - 2) * TranslateStep; //we have removed 2 values
                } else if (parseInt(RollerMonthLength) === 29) {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2 - 4) * TranslateStep; //we have removed 2 values
                } else if (parseInt(RollerMonthLength) === 28) {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2 - 6) * TranslateStep; //we have removed 2 values
                } else {
                    MaxTrigger = (2 * TrackParameters.TotalSlides - 2) * TranslateStep;
                }

                var UpdatedActiveIndex = {NonCentered: null, Centered: null, OriginalArray: null};
                var RealOriginalIndex;
                UpdateState = true;
                let TranslateStepsSign = Math.sign(ScrollY) * TranslateStep;

                for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                    if (TrackElementsStatus[i].isHidden === false) {
                        var UpdateTrackElem = {
                            translateY: null,
                            isActive: false,
                            index: TrackElementsStatus[i].index,
                            dataIndex: TrackElementsStatus[i].dataIndex,
                            originalIndex: TrackElementsStatus[i].originalIndex,
                            isHidden: TrackElementsStatus[i].isHidden,
                        };
                        let NextTranstlateElem = TrackElementsStatus[i].translateY + TranslateStepsSign;

                        if (NextTranstlateElem > MaxTrigger) {
                            NextTranstlateElem = -TranslateStep;
                        } else if (NextTranstlateElem < -TranslateStep) {
                            NextTranstlateElem = MaxTrigger;
                        }
                        if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                            UpdateTrackElem.isActive = true;

                            UpdatedActiveIndex.NonCentered = TrackElementsStatus[i].index;
                            UpdatedActiveIndex.Centered = TrackElementsStatus[i].index;
                            UpdatedActiveIndex.OriginalArray = TrackElementsStatus[i].index;
                            RealOriginalIndex = UpdateTrackElem.originalIndex;
                        }
                        UpdateTrackElem.translateY = NextTranstlateElem;
                        UpdatedTrackElementsStatus.push(UpdateTrackElem);
                    } else {
                        UpdatedTrackElementsStatus.push(TrackElementsStatus[i]);
                    }
                }
            }

            if (UpdateState === true) {
                setTrackElementsStatus(UpdatedTrackElementsStatus);
                setActiveIndex(UpdatedActiveIndex);
                setGoToIndex(null);
                setScrollY(0);
                if (UpdateSelected && typeof UpdateSelected === "function") {
                    UpdateSelected(RealOriginalIndex);
                }
                let TrackElementsHtml = Ref_Track.current.querySelectorAll(`[data-track = "track-element"]`);

                for (var i = 0; i < TrackElementsHtml.length; i++) {
                    if (UpdatedTrackElementsStatus[i].isHidden === true) {
                        TrackElementsHtml[i].style.display = "none";
                    } else {
                        TrackElementsHtml[i].style.transform = `translateY(${UpdatedTrackElementsStatus[i].translateY}px)`;
                        TrackElementsHtml[i].style.display = "flex";
                        if (UpdatedTrackElementsStatus[i].isActive === true) {
                            TrackElementsHtml[i].classList.add(classes.TrackElement_Active);
                        } else {
                            TrackElementsHtml[i].classList.remove(classes.TrackElement_Active);
                        }
                    }
                }
            }
        }
    }, [ScrollY, Initialized, GoToIndex, RollerType]);
    //When Scrolling Updating the position of each slides - live - for Years roller only
    useEffect(() => {
        if (Initialized === true && RollerType === "Years" && !YearMin && !YearMax) {
            var UpdateStatus = false;
            var UpdatedTrackElementsStatus = [];
            const MaxTrigger = (TrackParameters.TotalSlides - 2) * TranslateStep;
            var UpdatedActiveIndex = {NonCentered: null, Centered: null, OriginalArray: null};
            var UpdatedYearValue;
            if (ActiveIndex.NonCentered === null) {
                UpdateStatus = true;

                //This is for the init just to update the translation at the end and set the active one
                UpdatedTrackElementsStatus = JSON.parse(JSON.stringify(TrackElementsStatus));
                UpdatedActiveIndex.NonCentered = GoToIndex;
                UpdatedActiveIndex.Centered = GoToIndex;
                UpdatedActiveIndex.OriginalArray = GoToIndex;
                UpdatedYearValue = TrackElementsStatus[GoToIndex].YearValue;
            } else {
                if (GoToIndex && GoToIndex !== ActiveIndex.NonCentered) {
                    //When GoToIndex changes
                    UpdateStatus = true;
                    //We need to find the closest Index corresponding to the OriginalArray Index
                    const CurrentIndex = ActiveIndex.NonCentered; //The default active one

                    var ClosestIndex = {Distance: null, Index: null};

                    TrackElementsStatus.forEach((elem) => {
                        if (elem.originalIndex === GoToIndex) {
                            let Distance = Math.abs(elem.index - CurrentIndex);
                            if (ClosestIndex.Distance === null) {
                                ClosestIndex.Distance = Distance;
                                ClosestIndex.Index = elem.index;
                            } else {
                                if (Distance < ClosestIndex.Distance) {
                                    ClosestIndex.Distance = Distance;
                                    ClosestIndex.Index = elem.index;
                                }
                            }
                        }
                    });

                    const Cycles = ClosestIndex.Index - CurrentIndex;

                    UpdatedTrackElementsStatus = JSON.parse(JSON.stringify(TrackElementsStatus));
                    const translateDirection = -Math.sign(Cycles);
                    for (var j = 0; j < Math.abs(Cycles); j++) {
                        for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                            var UpdateTrackElem = {
                                translateY: null,
                                isActive: false,
                                index: UpdatedTrackElementsStatus[i].index,
                                dataIndex: UpdatedTrackElementsStatus[i].dataIndex,
                                originalIndex: UpdatedTrackElementsStatus[i].originalIndex,
                                YearValue: UpdatedTrackElementsStatus[i].YearValue,
                            };
                            let NextTranstlateElem = UpdatedTrackElementsStatus[i].translateY + TranslateStep * translateDirection;

                            if (NextTranstlateElem > MaxTrigger) {
                                NextTranstlateElem = -TranslateStep;
                            } else if (NextTranstlateElem < -TranslateStep) {
                                NextTranstlateElem = MaxTrigger;
                            }
                            if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                                UpdateTrackElem.isActive = true;
                                UpdatedYearValue = UpdateTrackElem.YearValue;
                            }
                            UpdateTrackElem.translateY = NextTranstlateElem;
                            UpdatedTrackElementsStatus[i] = UpdateTrackElem;
                        }
                    }
                    UpdatedActiveIndex.NonCentered = GoToIndex;
                    UpdatedActiveIndex.Centered = GoToIndex;
                    UpdatedActiveIndex.OriginalArray = GoToIndex;
                } else if (ScrollY) {
                    UpdateStatus = true;
                    //NormalStuff
                    let TranslateStepsSign = Math.sign(ScrollY) * TranslateStep;

                    const CurrentMaxYear = _.maxBy(TrackElementsStatus, "YearValue").YearValue;
                    const CurrentMinYear = _.minBy(TrackElementsStatus, "YearValue").YearValue;

                    for (var i = 0; i < TrackParameters.TotalSlides; i++) {
                        var UpdateTrackElem = {
                            translateY: null,
                            isActive: false,
                            index: TrackElementsStatus[i].index,
                            dataIndex: TrackElementsStatus[i].dataIndex,
                            originalIndex: TrackElementsStatus[i].originalIndex,
                            YearValue: TrackElementsStatus[i].YearValue,
                        };
                        let NextTranstlateElem = TrackElementsStatus[i].translateY + TranslateStepsSign;

                        if (NextTranstlateElem > MaxTrigger) {
                            NextTranstlateElem = -TranslateStep;
                            UpdateTrackElem.YearValue = CurrentMinYear - 1;
                        } else if (NextTranstlateElem < -TranslateStep) {
                            NextTranstlateElem = MaxTrigger;
                            UpdateTrackElem.YearValue = CurrentMaxYear + 1;
                        }
                        if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                            UpdateTrackElem.isActive = true;

                            UpdatedActiveIndex.NonCentered = TrackElementsStatus[i].index;
                            UpdatedActiveIndex.Centered = TrackElementsStatus[i].index;
                            UpdatedActiveIndex.OriginalArray = TrackElementsStatus[i].index;
                            UpdatedYearValue = UpdateTrackElem.YearValue;
                        }
                        UpdateTrackElem.translateY = NextTranstlateElem;
                        UpdatedTrackElementsStatus.push(UpdateTrackElem);
                    }
                }
            }
            if (UpdateStatus === true) {
                setTrackElementsStatus(UpdatedTrackElementsStatus);
                setActiveIndex(UpdatedActiveIndex);
                setGoToIndex(null);
                setScrollY(0);
                if (UpdateSelected && typeof UpdateSelected === "function") {
                    UpdateSelected(parseInt(UpdatedYearValue));
                }
                let TrackElementsHtml = Ref_Track.current.querySelectorAll(`[data-track = "track-element"]`);
                for (var i = 0; i < TrackElementsHtml.length; i++) {
                    // if (i === 0 || i === TrackElementsHtml.length - 1) {
                    //     TrackElementsHtml[i].innerHtml = UpdatedTrackElementsStatus[i].YearValue;
                    // }
                    TrackElementsHtml[i].innerHTML = UpdatedTrackElementsStatus[i].YearValue;
                    TrackElementsHtml[i].style.transform = `translateY(${UpdatedTrackElementsStatus[i].translateY}px)`;
                    if (UpdatedTrackElementsStatus[i].isActive === true) {
                        TrackElementsHtml[i].classList.add(classes.TrackElement_Active);
                    } else {
                        TrackElementsHtml[i].classList.remove(classes.TrackElement_Active);
                    }
                }
            }
            // Ref_WheelScrolling.current = false;
        }
    }, [ScrollY, Initialized, GoToIndex, RollerType]);

    useEffect(() => {
        if (NavigateToIndex) {
            setGoToIndex(NavigateToIndex);
        }
    }, [NavigateToIndex]);

    //Live translation update based on mouse/touch position - for standard roller only
    useEffect(() => {
        if (Initialized === true && RollerType !== "Days") {
            if (RollerType === "Years" && !YearMin && !YearMax) {
            } else {
                var UpdateState = false;

                var UpdatedTrackElementsStatus = [];
                // const MaxTrigger = (2 * TrackParameters.TotalSlides - 2) * TranslateStep;
                const MaxTrigger = (2 * TrackParameters.TotalSlides - 2) * TranslateStep + TranslateStep; //We only switch when fully passed over the trigger
                var UpdatedActiveIndex = {NonCentered: null, Centered: null, OriginalArray: null};
                var RealOriginalIndex;

                if (TouchMouseDistance !== 0) {
                    //We need to see it as discrete invents of 1px increment
                    const RealIncrement = 2;
                    UpdateState = true;
                    //NormalStuff
                    // let TranslateStepsSign = Math.sign(ScrollY) * TranslateStep; //Nope here we do a real translation
                    for (var i = 0; i < 2 * TrackParameters.TotalSlides; i++) {
                        var UpdateTrackElem = {
                            translateY: null,
                            isActive: false,
                            index: TrackElementsStatus[i].index,
                            dataIndex: TrackElementsStatus[i].dataIndex,
                            originalIndex: TrackElementsStatus[i].originalIndex,
                            YearValue: TrackElementsStatus[i].YearValue,
                        };
                        // let NextTranstlateElem = TrackElementsStatus[i].translateY + TranslateStepsSign;
                        let NextTranstlateElem = TrackElementsStatus[i].translateY + RealIncrement;
                        if (NextTranstlateElem > MaxTrigger) {
                            // NextTranstlateElem = -TranslateStep;
                            NextTranstlateElem = -TranslateStep + RealIncrement; // we need to add the current translation
                        } else if (NextTranstlateElem < -TranslateStep) {
                            NextTranstlateElem = MaxTrigger;
                        }
                        if (NextTranstlateElem === TrackParameters.CentralIndex * TranslateStep) {
                            UpdateTrackElem.isActive = true;
                            UpdatedActiveIndex.NonCentered = TrackElementsStatus[i].index;
                            UpdatedActiveIndex.Centered = TrackElementsStatus[i].index;
                            UpdatedActiveIndex.OriginalArray = TrackElementsStatus[i].index;
                            RealOriginalIndex = UpdateTrackElem.originalIndex;
                        }
                        UpdateTrackElem.translateY = NextTranstlateElem;
                        UpdatedTrackElementsStatus.push(UpdateTrackElem);
                    }
                }

                if (UpdateState === true) {
                    setTrackElementsStatus(UpdatedTrackElementsStatus);
                    setActiveIndex(UpdatedActiveIndex);
                    setGoToIndex(null);
                    setScrollY(0);
                    if (UpdateSelected && typeof UpdateSelected === "function") {
                        if (RollerType === "Years") {
                            let CurrentYearSelected = UpdatedTrackElementsStatus[RealOriginalIndex + 1].YearValue;
                            UpdateSelected(CurrentYearSelected);
                        } else {
                            UpdateSelected(RealOriginalIndex);
                        }
                    }
                    let TrackElementsHtml = Ref_Track.current.querySelectorAll(`[data-track = "track-element"]`);

                    for (var i = 0; i < TrackElementsHtml.length; i++) {
                        TrackElementsHtml[i].style.transform = `translateY(${UpdatedTrackElementsStatus[i].translateY}px)`;
                        // TrackElementsHtml[i].innerHTML = `translate y : ${UpdatedTrackElementsStatus[i].translateY}px`;
                        if (UpdatedTrackElementsStatus[i].isActive === true) {
                            TrackElementsHtml[i].classList.add(classes.TrackElement_Active);
                        } else {
                            TrackElementsHtml[i].classList.remove(classes.TrackElement_Active);
                        }
                    }
                }
            }
        }
    }, [TouchMouseDistance, Initialized, RollerType]);

    //Adding all the event listeners for touch and mouse
    useEffect(() => {
        if (Ref_Track.current) {
            Ref_Track.current.addEventListener("wheel", HandleWheelScroll);
            Ref_Track.current.addEventListener("mousedown", HandleMouseDown);
            // Ref_Track.current.addEventListener("mousemove", HandleMouseMove);
            Ref_Track.current.addEventListener("mouseup", HandleMouseUp);
            Ref_Track.current.addEventListener("mouseenter", HandleMouseEnter);
            // Ref_Track.current.addEventListener("mouseleave", HandleMouseLeave);

            // Ref_Track.current.addEventListener("touchstart", HandleTouchStart);
            // Ref_Track.current.addEventListener("touchmove", HandleTouchMove);

            // Ref_Track.current.addEventListener("touchend", HanldeTouchEnd);
            setInitialized(true);
            return function cleanup() {
                if (Ref_Track.current) {
                    Ref_Track.current.removeEventListener("wheel", HandleWheelScroll);
                    // Ref_Track.current.removeEventListener("mousedown", HandleMouseDown);
                    Ref_Track.current.removeEventListener("touchstart", HandleTouchStart);
                    Ref_Track.current.removeEventListener("touchmove", HandleTouchMove);
                    // Ref_Track.current.removeEventListener("mouseup", HandleMouseUp);
                    Ref_Track.current.removeEventListener("touchend", HanldeTouchEnd);
                }
            };
        }
    }, [Ref_Track]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const HandleWheelScroll = (event) => {
        event.stopPropagation();
        //We consider Each Scroll as a discret element to avoid having to detect scroll stop
        let ScrollDirection = -event.deltaY;

        let PreviousDirection = Ref_ScrollDirection.current;
        // console.log("ScrollDirection, PreviousDirection", ScrollDirection, PreviousDirection);
        var NextScrollY;
        if (ScrollDirection > 0 && PreviousDirection < 0) {
            NextScrollY = +1;
        } else if (ScrollDirection > 0 && PreviousDirection > 0) {
            NextScrollY = PreviousDirection + ScrollDirection;
        } else if (ScrollDirection < 0 && PreviousDirection > 0) {
            NextScrollY = -1;
        } else if (ScrollDirection < 0 && PreviousDirection < 0) {
            NextScrollY = PreviousDirection + ScrollDirection;
        } else if (PreviousDirection === 0) {
            NextScrollY = ScrollDirection;
        }

        setScrollY(NextScrollY);
        Ref_WheelScrolling.current = true;
        Ref_ScrollDirection.current = NextScrollY;
    };

    const HandleMouseDown = (event) => {
        // console.log("Mouse was clicked");
        //If the mouse was clicked -> We add EventListener to mouse move and mouse Leave
        Ref_Track.current.addEventListener("mousemove", HandleMouseMove);
        Ref_Track.current.addEventListener("mouseleave", HandleMouseLeave);
    };
    const HandleMouseMove = (event) => {
        // console.log("mouse is moving");
        // //We need to store the first one
        // if (Ref_MouseMoveDistance.current === 0) {
        //     Ref_MouseTimeStamp.current = Date.now();
        //     //We do nothing it is the start -> we just update the current distance to
        //     Ref_MouseMoveDistance.current = event.clientY;
        // } else {
        //     let RealDistanceY = event.clientY - Ref_MouseMoveDistance.current;
        //     const dt = Date.now() - Ref_MouseTimeStamp.current;
        //     const speedY = RealDistanceY / dt;
        //     const accelerationY = speedY / dt;
        //     console.log("speedY", speedY, "accelerationY", accelerationY);
        //     setTouchMouseDistance(RealDistanceY);
        //     // setTouchMouseSpeedRepeat()
        // }
    };
    const HandleMouseUp = (event) => {
        // console.log("Mouse click was released");
        //We remove all mouse event listeners
        Ref_Track.current.removeEventListener("mousemove", HandleMouseMove);
        Ref_Track.current.removeEventListener("mouseleave", HandleMouseLeave);

        //And we reset all the distances
        console.log("Reset all distances");
        setTouchMouseDistance(0);
        Ref_MouseMoveDistance.current = 0;
    };
    const HandleMouseEnter = (event) => {
        //We had the mouseLeaveEvent
        // console.log("Event", event);
        Ref_Track.current.addEventListener("mouseleave", HandleMouseLeave);
    };
    const HandleMouseLeave = (event) => {
        // console.log("MouseLeave");
        //We remove all mouse event listeners
        Ref_Track.current.removeEventListener("mousemove", HandleMouseMove);
        Ref_Track.current.removeEventListener("mouseleave", HandleMouseLeave);
        //Reset the WheelScroll
    };

    const HandleTouchStart = (event) => {
        console.log("Event", event);
    };

    const HandleTouchMove = (event) => {
        console.log("Event mouse", event);
        console.log("Event", event);
    };

    const HanldeTouchEnd = (event) => {
        console.log("Event", event);
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var FinalWidth;
    if (AutoWidth === true) {
        FinalWidth = null;
    } else {
        FinalWidth = Width;
    }
    return (
        <div className={classes.Roller}>
            <div
                ref={Ref_Track}
                className={clsx(classes.Track, Ref_WheelScrolling.current === false ? classes.Track_Animated : null)}
                style={{
                    height: TrackParameters ? `${TrackParameters.SlidesToShow * TranslateStep}px` : null,
                    width: FinalWidth,
                }}
            >
                {ViewerComponent}
                <div className={classes.OriginalElementsWrapper}>{AutoWidthTrackElements}</div>

                <div className={classes.CalcFakeElem}>
                    <div
                        ref={Ref_FakeTrackElement}
                        className={classes.TrackElement}
                        style={{
                            ...Lockedstyles.TrackElement,
                            ...{margin: `${PaddingBetweenElements}px 0px`, textAlign: Alignement, justifyContent: Alignement, display: Initialized === true ? "none" : null},
                        }}
                    >
                        fake
                    </div>
                </div>
                {TrackElements}
            </div>
        </div>
    );
    /****************************** RENDER *********************/
});

PickerRoller.defaultProps = {
    Width: "30px",
    PaddingBetweenElements: 2,
    SlidesToShow: 5,
    Alignement: "center",
    UpdateSelected: null,
    InitialIndex: 0,
    NavigateToIndex: null,
    RollerName: null,
    RollerType: null,
    RollerMonthLength: 31,
    InitialYear: null,
    YearMin: null,
    YearMax: null,
    AutoWidth: true,
};

PickerRoller.propTypes = {
    /**
     * Width :
     */
    Width: PropTypes.string,
    /**
     * Width :
     */
    PaddingBetweenElements: PropTypes.number,
    /**
     * Width :
     */
    SlidesToShow: PropTypes.number,
    /**
     * Width :
     */
    Alignement: PropTypes.string,
    /**
     * Width :
     */
    UpdateSelected: PropTypes.any,
    /**
     * Width :
     */
    InitialIndex: PropTypes.any,
    /**
     * Width :
     */
    NavigateToIndex: PropTypes.any,
    /**
     * Width :
     */
    RollerName: PropTypes.any,
    /**
     * Width :
     */
    RollerType: PropTypes.any,

    /**
     * Width :
     */
    RollerMonthLength: PropTypes.any,
    /**
     * Width :
     */
    InitialYear: PropTypes.any,
    /**
     * Width :
     */
    YearMin: PropTypes.any,
    /**
     * Width :
     */
    YearMax: PropTypes.any,
    /**
     * Width :
     */
    AutoWidth: PropTypes.any,
};

export default PickerRoller;
