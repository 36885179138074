import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {RotateLeft} from "@artibulles-cis/react-icons";
import AIReconciliationTable from "./AIReconciliationTable";
import ReconciliationRecommendedTable from "./ReconciliationRecommendedTable";
import ReconciliationAllTable from "./ReconciliationAllTable";
// import PurchaseInvoicesRecommend from "./PurchaseInvoicesRecommend";
// import PurchaseInvoicesAll from "./PurchaseInvoicesAll";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
    MainWrapper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    ClassExtendTabContent: {
        // maxWidth: "1200px",
    },
}));

const Component = React.memo(function Component(props) {
    const {InvoiceId, HandleTransactionChangedParent, FormValues} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Div = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Loading, setLoading] = useState(false);
    const [LoadingBankAccount, setLoadingBankAccount] = useState(false);
    const [ReloadAll, setReloadAll] = useState(true);

    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [BankAccountsInformation, setBankAccountsInformation] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!ApiCallType) {
                    if (ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("All Data", Data);
                    }
                } else {
                    if (ApiCallType === "PontoSync") {
                        if (ApiRes.data && ApiRes.data.data) {
                            let Data = ApiRes.data.data;
                            console.log("PontoSyncData", Data);
                            setApiCallType(null);
                            setReloadAll(true);
                            // let Output = {
                            //     DBAccountBalance: MyBankAccountBlance,
                            //     NewAccountBalance: CurrentBalance,
                            //     DeltaAccountBalance: CurrentBalance - MyBankAccountBlance,
                            //     NewTransactionsTotal: FreshTransactionsTotal,
                            //     NewTransactions: FreshTransactions,
                            // };

                            // if (Data.BankAccounts) {
                            //     setBankAccountsInformation(Data.BankAccounts);
                            // }
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleUpdateReconconcile = () => {
        HandleTransactionChangedParent();
    };
    const SyncPonto = () => {
        const ApiCall = {url: `${ProdURL}/api/securebanking/syncgetransactionsdefaultaccount`, type: "post", data: null};
        setApiCallType("PontoSync");
        setLoadingBankAccount(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoadingBankAccount(false);
        }
        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div class={classes.MainWrapper}>
            <LocalCircularLoader Loading={LoadingBankAccount} WithModalCard={true} FullSize={true} />
            <Button Width="190px" Height="25px" style={{margin: "5px 0px 5px 0px", padding: "0px 5px 0px 5px"}} onClick={SyncPonto}>
                <div style={{display: "flex"}}>
                    <div style={{marginRight: "5px"}}>
                        <RotateLeft IconSize="20" SVGFillColor="white" />
                    </div>
                    Reconcile with Ponto
                </div>
            </Button>
            <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
                <TabNav
                    NoLink={true}
                    MenuFullWidth={false}
                    ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
                    TabNavMenuSeparation="Line"
                    ActiveIndicator={{
                        WithIndicator: true,
                        Position: "Bottom",
                    }}
                    TabNavMenuHeight="25px"
                    TabMenuContent={[
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>AI Recommendation</div>
                                </div>
                            ),

                            // TabContent: <InvoiceItemsTable FormViewMode={FormViewMode} PurchaseInvoiceID={PurchaseInvoiceID} SubmitParentForm={HandlePurchaseItemChanged} />,

                            TabContent: <AIReconciliationTable InvoiceId={InvoiceId} FormValues={FormValues} HandleUpdateReconconcile={HandleUpdateReconconcile} />,
                        },
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Recommendation</div>
                                </div>
                            ),

                            TabContent: <ReconciliationRecommendedTable InvoiceId={InvoiceId} FormValues={FormValues} HandleUpdateReconconcile={HandleUpdateReconconcile} />,
                        },
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>All Transactions</div>
                                </div>
                            ),

                            TabContent: <ReconciliationAllTable InvoiceId={InvoiceId} FormValuesParent={FormValues} HandleUpdateReconconcile={HandleUpdateReconconcile} />,
                        },
                    ]}
                />
            </TabNavContent>
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
