/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Page from "../../../../../artibulles-cis/Page/Page";


import _ from "lodash";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
const {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, setSeconds, addHours} = require("date-fns");

import TimeEntryEditNewForm from "./TimeEntryEditNewForm";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "10px",
    },
}));
const Component = React.memo(function Component(props) {
    const {Id, HandleCloseViewCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    
    const LocationParams = useParams();

    const Location = useLocation();
    const PathName = Location.pathname;
    const History = useHistory();
    var FinalId, FinalAPIUrlRelative, InitialCall;
    var OpenInModal = true;

    //We define the FinalId based on the Id prop or the locationParam
    if (Id) {
        //When the Id was passed in the props of the component
        FinalId = Id;
        OpenInModal = false;
    } else {
        //The id was passed in the route
        FinalId = LocationParams.id;
        OpenInModal = true;
    }

    if (FinalId === "new") {
        InitialCall = {type: "New"};
    } else if (FinalId === "newandtrack") {
        InitialCall = {type: "New"};
    } else {
        InitialCall = {type: "Edit", ID: FinalId};
    }

    FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/tasks/time/entry`,
        DELETE: "",
        PUT: "",
        POST: "new", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("redirect New Entry", args);

        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            if (FinalId === "newandtrack" && args.typeOfSubmit === "SaveAndQuit") {
                let Data = args.data.TimeEntry;

                History.push(`/tasktimemanagement/times/time/viewclock`);
                // if (OpenInModal === false) {
                //     HandleCloseViewCard(true);
                //     // History.push(`/tasktimemanagement/tasks/alltasks`);
                // } else {
                //     History.push(`/tasktimemanagement/tasks`);
                // }
            } else {
                if (OpenInModal === false) {
                    HandleCloseViewCard(true);
                    // History.push(`/tasktimemanagement/tasks/alltasks`);
                } else {
                    History.push(`/tasktimemanagement/times/dailytimesheet`);
                }
            }
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Data", data);
        var timeEntry = null;
        if (data.TimeEntry) {
            timeEntry = data.TimeEntry;
        }

        var Categories = [];
        if (data.Categories && data.Categories.length > 0) {
            Categories = data.Categories.map((elem) => {
                return {value: elem._id, label: elem.category_name};
            });
        }
        var Priorities = [];
        if (data.Priorities && data.Priorities.length > 0) {
            Priorities = data.Priorities.map((elem) => {
                return {value: elem._id, label: elem.piority_name, order: elem.priority_order};
            });
        }

        var Companies = [];
        if (data.Companies && data.Companies.length > 0) {
            Companies = data.Companies.map((elem) => {
                return {value: elem._id, label: elem.company};
            });
        }
        var ProgramsSubOptions = [];
        if (data.Programs && data.Programs.length > 0) {
            ProgramsSubOptions = data.Programs;
        }
        var MilestonesSubOptions = [];
        if (data.Milestones && data.Milestones.length > 0) {
            MilestonesSubOptions = data.Milestones;
        }

        let ProgramSubOptionsInit = [];
        if (timeEntry && timeEntry.ref_company) {
            //We initialize the suboptions

            ProgramsSubOptions.forEach((elem) => {
                if (elem.ref_company === timeEntry.ref_company) {
                    let newsub = {value: elem.id, label: elem.program_name};
                    ProgramSubOptionsInit.push(newsub);
                }
            });
        }

        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }

        let EstimaterDurationSecondsInit = null;
        if (FinalId !== "new") {
            if (timeEntry && timeEntry.estimated_duration_seconds) {
                let TimeFromSeconds = convertSecondsToTime(timeEntry.estimated_duration_seconds).split(":");

                let TimeNow = setSeconds(setMinutes(setHours(Date.now(), TimeFromSeconds[0]), TimeFromSeconds[1]), 0);
                EstimaterDurationSecondsInit = formatISO(TimeNow);
            } else {
                let TimeNow = setMinutes(setHours(Date.now(), 1), 0);
                EstimaterDurationSecondsInit = formatISO(TimeNow);
            }
        }

        let DurationSecondsInit = null;
        if (FinalId !== "new" && FinalId !== "newandtrack") {
            if (timeEntry && timeEntry.duration_seconds) {
                DurationSecondsInit = convertSecondsToTime(timeEntry.duration_seconds, false);
            }
        }

        let start_time = null;
        if (timeEntry && timeEntry.start_time_seconds) {
            let TimeFromSeconds = convertSecondsToTime(timeEntry.start_time_seconds).split(":");

            start_time = formatISO(setSeconds(setMinutes(setHours(Date.now(), TimeFromSeconds[0]), TimeFromSeconds[1]), 0));
        }
        let end_time = null;
        if (timeEntry && timeEntry.end_time_seconds) {
            let TimeFromSeconds = convertSecondsToTime(timeEntry.end_time_seconds).split(":");

            end_time = formatISO(setSeconds(setMinutes(setHours(Date.now(), TimeFromSeconds[0]), TimeFromSeconds[1]), 0));
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: timeEntry ? timeEntry._id : FinalId,
                entry_name: timeEntry ? timeEntry.entry_name : null,
                entry_details: timeEntry ? (timeEntry.entry_details ? timeEntry.entry_details : null) : null,
                is_billable: timeEntry ? (timeEntry.is_billable === true ? true : false) : false,
                is_personal: timeEntry ? (timeEntry.is_personal === true ? true : false) : false,
                ref_taskCategory: timeEntry ? timeEntry.ref_taskCategory : null,
                date_Iso: timeEntry ? timeEntry.date_Iso : formatISO(Date.now()),
                estimated_duration_seconds: EstimaterDurationSecondsInit,
                start_time: start_time,
                end_time: end_time,
                duration_seconds: DurationSecondsInit,
                ref_company: timeEntry ? timeEntry.ref_company : null,
                ref_program: timeEntry ? timeEntry.ref_program : null,
                ref_milestone: timeEntry ? timeEntry.ref_milestone : null,
            },

            SelectOptions: {
                ref_taskPriority: Priorities,
                ref_taskCategory: Categories,
                ref_company: Companies,
                ref_program: ProgramSubOptionsInit,
                ref_milestone: [],
            },
            SubOptionsFull: {
                ref_company: ProgramsSubOptions,
                ref_program: MilestonesSubOptions,
            },
            FilesUploaders: {
                // fileuploaderName: data.tutorial.Files,
            },
            InitiaFormErrors: {},
            InitialFormErrorMain: FormErrorMain,
        };

        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            if (values) {
                //We need to consider the different cases
                let ISODate;
                let EstimatedDurationSeconds;

                let end_time_seconds = null;
                let start_time_seconds = null;
                if (FinalId === "new") {
                    EstimatedDurationSeconds = null;
                    if (values.start_time) {
                        let hours = getHours(parseISO(values.start_time));
                        let minutes = getMinutes(parseISO(values.start_time));
                        start_time_seconds = hours * 60 * 60 + minutes * 60;
                    }
                    if (values.end_time) {
                        let hours = getHours(parseISO(values.end_time));
                        let minutes = getMinutes(parseISO(values.end_time));
                        end_time_seconds = hours * 60 * 60 + minutes * 60;
                    }
                    ISODate = values.date_Iso;
                } else if (FinalId === "newandtrack") {
                    let NewDate = new Date(Date.now());
                    ISODate = formatISO(NewDate);
                    start_time_seconds = parseInt(format(NewDate, "H")) * 3600 + parseInt(format(NewDate, "m")) * 60 + parseInt(format(NewDate, "s"));
                    if (values.estimated_duration_seconds) {
                        let hours = getHours(parseISO(values.estimated_duration_seconds));
                        let minutes = getMinutes(parseISO(values.estimated_duration_seconds));
                        EstimatedDurationSeconds = hours * 3600 + minutes * 60;
                    } else {
                        EstimatedDurationSeconds = 3600;
                    }
                } else {
                    //this is for editing an existing entry;
                    ISODate = values.date_Iso;
                    EstimatedDurationSeconds = null;
                    if (values.start_time) {
                        let hours = getHours(parseISO(values.start_time));
                        let minutes = getMinutes(parseISO(values.start_time));
                        start_time_seconds = hours * 60 * 60 + minutes * 60;
                    }
                    if (values.end_time) {
                        let hours = getHours(parseISO(values.end_time));
                        let minutes = getMinutes(parseISO(values.end_time));
                        end_time_seconds = hours * 60 * 60 + minutes * 60;
                    }
                }

                var Newcategory = null;
                if (FullSubmission.NewCreatedOptions) {
                    Newcategory = values.ref_taskCategory;
                    //new options for categories
                }

                let DateDayFormat;
                if (ISODate) {
                    let ParsedDate = parseISO(ISODate);
                    DateDayFormat = format(ParsedDate, "yyyyMMdd");
                }
                let ServerData;
                if (FinalId === "new" || FinalId === "newandtrack") {
                    ServerData = {
                        API_id: values.ApiID,
                        Newcategory: Newcategory,
                        entry_name: values.entry_name,
                        entry_details: values.entry_details ? values.entry_details : null,
                        ref_taskCategory: values.ref_taskCategory,
                        date_Iso: ISODate,
                        DateDayFormat: DateDayFormat,
                        estimated_duration_seconds: EstimatedDurationSeconds,
                        start_time_seconds: start_time_seconds,
                        end_time_seconds: end_time_seconds,
                        is_billable: values.is_billable,
                        is_personal: values.is_personal,
                        ref_company: values.ref_company,
                        ref_program: values.ref_program,
                        ref_milestone: values.ref_milestone,
                    };
                } else {
                    console.log("values", values);

                    ServerData = {
                        entry_name: values.entry_name,
                        entry_details: values.entry_details ? values.entry_details : null,
                        ref_taskCategory: values.ref_taskCategory,
                        date_Iso: ISODate,
                        estimated_duration_seconds: EstimatedDurationSeconds,
                        start_time_seconds: start_time_seconds,
                        end_time_seconds: end_time_seconds,
                        DateDayFormat: DateDayFormat,
                        is_billable: values.is_billable,
                        is_personal: values.is_personal,
                        ref_company: values.ref_company,
                        ref_company: values.ref_company,
                        ref_company: values.ref_company,
                        ref_program: values.ref_program,
                        ref_milestone: values.ref_milestone,
                    };
                }

                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <Page
            // TabRef={Ref_TabNavContent}
            // ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            WithPerfectScrollBar={true}
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                WithCardModal={OpenInModal}
                CardModalOptions={{
                    // ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    CardMaxWidth: "800px",
                    CardHeight: "700px",
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                OpenInViewMode={false}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={TimeEntryEditNewForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save"}}
                ButtonSaveAndQuit={FinalId === "newandtrack" ? {Active: true, Text: "Start Tracking"} : {Active: true, Text: "Save"}}
                ButtonQuitNoSave={{Active: true, Text: "Cancel"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "Reset"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
});

export default Component;
