import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        // width: "500px",
        alignSelf: "center",
        justifyContent: "center",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px 5px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "flex-start",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    Title: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-start",
        padding: "0px 5px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const {InvoiceId, HandleUnreconcileCloseCard, PassedFormValues} = props;
    /***************** REFS ******************/
    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                toast.success(`Invoice Unreconciled`, {autoClose: true});
                setTimeout(() => {
                    HandleUnreconcileCloseCard();
                }, 200);
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        // setShowCard(false);
        HandleUnreconcileCloseCard();
    };
    const HandleUnreconcile = () => {
        console.log("HandleUnreconcile");
        let DataForm = {
            InvoiceId: InvoiceId,
        };
        const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/unreconcileinvoice`, type: "post", data: DataForm};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let TopBarContent = (
        <div className={classes.Title}>
            <div style={{flex: "1 1 auto"}}>{PassedFormValues.InvoiceShortId}</div>
        </div>
    );
    return (
        <CardModal
            CardID="Unreconcile"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={TopBarContent}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={HandleCloseCard}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            CardMaxWidth="450px"
            CardHeight="150px"
            // CardWindowPaddingVertical="10px"
            // CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="50px"

            ShowCard={true}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
        >
            <div className={classes.CardWindow}>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                <div className={classes.CardMain}>
                    Are you sure you want to unreconcile this invoice ?<Button onClick={HandleUnreconcile}> Unreconcile </Button>
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default Component;
