import {useContext} from "react";
import {TableContext} from "./tableContext";
import _ from "lodash";

const useTableContext = () => {
    const [state, setState] = useContext(TableContext);

    function StoreOriginalData(FullData, TableRows) {
        //This contains the original table data
        setState((state) => ({...state, OriginalTableData: FullData, RowsOriginal: TableRows}));
    }
    function InitializeRowsAndCols(rows, cols) {
        //We store the col info and the rows info and intililiaze the sorting
        let CurrentSortedRows = [...rows];
        let ColsInput = cols;

        const OrderedArrayUpdatedSortedBy = _.orderBy(ColsInput, ["sortIndex"], ["asc"]);

        var _SortArrray = [],
            _SortDirection = [],
            TotalSorted = 0;
        OrderedArrayUpdatedSortedBy.forEach((elem) => {
            if (elem.isSorted === true) {
                TotalSorted = TotalSorted + 1;
                _SortArrray.push(elem.datafield);
                if (elem.sortDirection === "Dsc") {
                    _SortDirection.push("desc");
                } else {
                    _SortDirection.push("asc");
                }
            }
        });
        let SortedRows;
        if (_SortArrray) {
            SortedRows = _.orderBy(CurrentSortedRows, _SortArrray, _SortDirection);
        } else {
            SortedRows = CurrentSortedRows;
        }

        setState((state) => ({...state, RowsInput: SortedRows, ColumnsInput: cols, SortedColumsState: {IsSortable: true, TotalSorted: TotalSorted}}));
    }

    function GenerateTableDimensionsOptions(data) {
        setState((state) => ({...state, TableDimensionsOptions: data}));
    }
    function GenerateTableDimensions(data) {
        setState((state) => ({...state, TableDimensions: data}));
    }

    function GenerateTableStyleOptions(data) {
        setState((state) => ({...state, TableStyleOptions: data}));
    }

    function GenerateTableStyle(data) {
        setState((state) => ({...state, TableStyle: data}));
    }

    function GeneratePagerOptions(data) {
        let PreviousOptions = state.PagerOptions;
        let UpdatedOptions = _.merge(PreviousOptions, data);

        //We also need to update the RowsInput to only display the requested Rows
        let RowsInput = [...state.RowsInput];
        let UpdatedRowsInput = [];

        let PageSize = data.PageSize;
        let PageSelected = data.PageSelected;
        let TotalRows = data.TotalRows;

        var MaxRowsToShow = RowsInput.length;
        var MinRowToShow = 0;
        if (PageSize && PageSelected) {
            if (PageSize > TotalRows) {
                MaxRowsToShow = parseInt(TotalRows);
            } else {
                MaxRowsToShow = parseInt(PageSize * PageSelected);
                if (MaxRowsToShow >= TotalRows) {
                    MaxRowsToShow = parseInt(TotalRows);
                    MinRowToShow = parseInt(PageSize * PageSelected) - PageSize;
                } else {
                    MinRowToShow = MaxRowsToShow - PageSize;
                }
            }
        }

        //We update all row data
        //We update all row data
        for (let i = 0; i < RowsInput.length; i++) {
            let RowElem = RowsInput[i];
            if (i >= MinRowToShow && i < MaxRowsToShow) {
                if (RowElem.sortedVisible === true) {
                    RowElem.isVisible = true;
                } else {
                    RowElem.isVisible = false;
                }
            } else {
                RowElem.isVisible = false;
            }
            UpdatedRowsInput.push(RowElem);
        }
        setState((state) => ({...state, PagerOptions: UpdatedOptions, RowsInput: UpdatedRowsInput}));
    }

    function SetSelectedRows(Id, SelectedState) {
        var UpdatedRowsInput = [];
        let RowsInput = state.RowsInput;
        if (Id === "SelectAll") {
            RowsInput.forEach((elem) => {
                let UpdatedElem = elem;
                if (elem.isVisible === true) {
                    //We only select the visible rows
                    UpdatedElem.isSelected = true;
                }
                UpdatedRowsInput.push(UpdatedElem);
            });
        } else if (Id === "UnselectAll") {
            RowsInput.forEach((elem) => {
                let UpdatedElem = elem;
                UpdatedElem.isSelected = false;
                UpdatedRowsInput.push(UpdatedElem);
            });
        } else {
            RowsInput.forEach((elem) => {
                let UpdatedElem = elem;
                if (elem.rowId === Id) {
                    //We only select the visible rows
                    UpdatedElem.isSelected = SelectedState;
                }
                UpdatedRowsInput.push(UpdatedElem);
            });
        }

        setState((state) => ({...state, RowsInput: UpdatedRowsInput}));
    }

    function setSortedRowsAndCols(Id, event) {
        let CurrentSortedRows = [...state.RowsInput];
        let ColsInput = [...state.ColumnsInput];
        let CurrentSortedCols = JSON.stringify(JSON.parse(state.SortedColumsState.TotalSorted));

        ColsInput.forEach((elem) => {
            //We run through each column

            if (event && event.shiftKey) {
                //Shift key pressed
                // if (TableOptionsFinal.SortOptions.MultiSearch === true) {
                if (elem.colId === Id) {
                    if (elem.sortDirection) {
                        //Change the direction
                        if (elem.sortDirection === "Asc") {
                            elem.sortDirection = "Dsc";
                        } else {
                            elem.sortDirection = "Asc";
                        }
                    } else {
                        //add the colum
                        CurrentSortedCols = parseInt(CurrentSortedCols) + 1;
                        elem.sortDirection = "Asc";
                        elem.sortIndex = parseInt(CurrentSortedCols);
                        elem.isSorted = true;
                    }
                }
                // }
            } else {
                //Normal key pressed -> change the filter column or change the direction
                CurrentSortedCols = 1;
                if (elem.colId === Id) {
                    if (elem.sortDirection) {
                        elem.sortIndex = 1;
                        //Change the direction
                        if (elem.sortDirection === "Asc") {
                            elem.sortDirection = "Dsc";
                        } else {
                            elem.sortDirection = "Asc";
                        }
                    } else {
                        //sort by this column

                        elem.sortDirection = "Asc";
                        elem.sortIndex = 1;
                        elem.isSorted = true;
                    }
                } else {
                    //Put all the other fields to null
                    elem.sortDirection = null;
                    elem.sortIndex = null;
                    elem.isSorted = false;
                }
            }
        });

        const OrderedArrayUpdatedSortedBy = _.orderBy(ColsInput, ["sortIndex"], ["asc"]);

        var _SortArrray = [],
            _SortDirection = [];
        OrderedArrayUpdatedSortedBy.forEach((elem) => {
            if (elem.isSorted === true) {
                _SortArrray.push(elem.datafield);
                if (elem.sortDirection === "Dsc") {
                    _SortDirection.push("desc");
                } else {
                    _SortDirection.push("asc");
                }
            }
        });

        let SortedRows = _.orderBy(CurrentSortedRows, _SortArrray, _SortDirection);

        //We also need to update the RowsInput to only display the requested Rows
        let UpdatedRowsInput = [];

        //We also need to reapply the pager
        let PagerOptions = state.PagerOptions;
        if (PagerOptions) {
            let PageSize = PagerOptions.PageSize;
            let PageSelected = PagerOptions.PageSelected;
            let TotalRows = PagerOptions.TotalRows;

            var MaxRowsToShow = SortedRows.length;
            var MinRowToShow = 0;
            if (PageSize && PageSelected) {
                if (PageSize > TotalRows) {
                    MaxRowsToShow = parseInt(TotalRows);
                } else {
                    MaxRowsToShow = parseInt(PageSize * PageSelected);
                    if (MaxRowsToShow >= TotalRows) {
                        MaxRowsToShow = parseInt(TotalRows);
                        MinRowToShow = parseInt(PageSize * PageSelected) - PageSize;
                    } else {
                        MinRowToShow = MaxRowsToShow - PageSize;
                    }
                }
            }

            //We update all row data
            for (let i = 0; i < SortedRows.length; i++) {
                let RowElem = SortedRows[i];
                if (i >= MinRowToShow && i < MaxRowsToShow) {
                    RowElem.isVisible = true;
                } else {
                    RowElem.isVisible = false;
                }
                UpdatedRowsInput.push(RowElem);
            }
        } else {
            UpdatedRowsInput = SortedRows;
        }

        setState((state) => ({...state, RowsInput: UpdatedRowsInput, ColumnsInput: ColsInput, SortedColumsState: {IsSortable: true, TotalSorted: CurrentSortedCols}}));
    }

    function SetSearchRows(filter) {
        let UpdateRows = [...state.RowsInput];
        let ColsInput = [...state.ColumnsInput];

        // filter ={column :"datafieldname", type : "contains", value : "filter value", datatype:"number"}

        //We need to reset the visibility of each rows to ensure we display them all when resetting the filters so that we search all rows and not only the visible ones

        UpdateRows.forEach((elem) => {
            elem.isVisible = true;
            elem.sortedVisible = true;
        });

        //First we find the current searched cols and we add/update the filter
        ColsInput.forEach((elem) => {
            if (filter.column === elem.datafield) {
                if (filter.value) {
                    elem.isSearched = true;
                    elem.searchValue = filter.value;
                    elem.searchFilterType = filter.type;
                } else {
                    //We reset the filters
                    elem.isSearched = false;
                    elem.searchValue = null;
                    elem.searchFilterType = null;
                }
            }
        });

        UpdateRows.forEach((Row) => {
            //We go through each row

            let CurrentRowData = Row.rowData;
            let ShowRow = true; //Used for multiSearchCriteria
            ColsInput.forEach((elem) => {
                if (elem.isSearched === true) {
                    //we check if the row meets the criteria of the filter
                    let SearchValue = elem.searchValue;
                    let ColToSearch = elem.datafield;

                    if (SearchValue) {
                        let CellValue = CurrentRowData[ColToSearch];
                        let DataType = elem.dataType;
                        let FilterType = filter.type;
                        if (CellValue) {
                            if (DataType === "number") {
                                //Number
                                if (!FilterType) {
                                    if (CellValue.toString().includes(SearchValue.toString())) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "Equal") {
                                    if (parseFloat(CellValue) === parseFloat(SearchValue)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;

                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "Different") {
                                    if (parseFloat(CellValue) !== parseFloat(SearchValue)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "Greater") {
                                    if (parseFloat(CellValue) > parseFloat(SearchValue)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "GreaterEqual") {
                                    if (parseFloat(CellValue) >= parseFloat(SearchValue)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "Less") {
                                    if (parseFloat(CellValue) < parseFloat(SearchValue)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "LessEqual") {
                                    if (parseFloat(CellValue) <= parseFloat(SearchValue)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                }
                            } else {
                                //Text

                                if (!FilterType || FilterType === "Contains") {
                                    if (CellValue.toLowerCase().includes(SearchValue.toLowerCase())) {
                                        // console.log("zyt is included", CellValue);
                                        if (ShowRow === true) {
                                            // console.log("Show Row", CellValue);
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "DoesNotContain") {
                                    let reg = new RegExp(`^(?:(?!${SearchValue}).)*$\r?\n?`, "g");
                                    if (CellValue.match(reg)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "StartsWith") {
                                    let reg = new RegExp(`^${SearchValue}`, "g");
                                    if (CellValue.match(reg)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "EndsWith") {
                                    let reg = new RegExp(`${SearchValue}$`, "g");
                                    if (CellValue.match(reg)) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "Equal") {
                                    if (CellValue === SearchValue) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                } else if (FilterType === "Different") {
                                    if (CellValue !== SearchValue) {
                                        if (ShowRow === true) {
                                            Row.isVisible = true;
                                            Row.sortedVisible = true;
                                        }
                                    } else {
                                        Row.isVisible = false;
                                        Row.sortedVisible = false;
                                        ShowRow = false;
                                    }
                                }
                            }
                        } else {
                            //When no cell value we hide it

                            Row.isVisible = false;
                            Row.sortedVisible = false;
                            ShowRow = false;
                        }
                    } else {
                        if (ShowRow === true) {
                            Row.isVisible = true;
                            Row.sortedVisible = true;
                        }
                    }
                }
            });
        });

        //To reapply the Pager, we only take the Rows that have been set to sortedVisible === true, the other should be hidden

        //We don't want to screw up the portential sorting -> We split the visible and non visible rows
        let VisibleSortRows = [],
            InvisbleSortRows = [],
            UpdatedVisibleRows = [],
            UpdatedRowsInput = [];
        var MaxRowsToShow = 0;
        UpdateRows.forEach((elem) => {
            if (elem.sortedVisible === true) {
                MaxRowsToShow = MaxRowsToShow + 1;
                VisibleSortRows.push(elem);
            } else {
                InvisbleSortRows.push(elem);
            }
        });

        //We also need to reapply the pager but only to hide the rows that are not sorted
        let PagerOptions = state.PagerOptions;
        if (PagerOptions) {
            let PageSize = PagerOptions.PageSize;
            let PageSelected = PagerOptions.PageSelected;
            let TotalRows = PagerOptions.TotalRows;

            //We find the real maximum of rows to show that meet the search criteria

            var MinRowToShow = 0;
            if (PageSize && PageSelected) {
                if (PageSize > TotalRows) {
                    MaxRowsToShow = parseInt(TotalRows);
                } else {
                    MaxRowsToShow = parseInt(PageSize * PageSelected);
                    if (MaxRowsToShow >= TotalRows) {
                        MaxRowsToShow = parseInt(TotalRows);
                        MinRowToShow = parseInt(PageSize * PageSelected) - PageSize;
                    } else {
                        MinRowToShow = MaxRowsToShow - PageSize;
                    }
                }
            }

            //We update all row data
            for (let i = 0; i < VisibleSortRows.length; i++) {
                let RowElem = VisibleSortRows[i];
                if (i >= MinRowToShow && i < MaxRowsToShow) {
                    if (RowElem.sortedVisible === true) {
                        RowElem.isVisible = true;
                    } else {
                        RowElem.isVisible = false;
                    }
                } else {
                    RowElem.isVisible = false;
                }
                UpdatedVisibleRows.push(RowElem);
            }
            //At the end we merge all rows togetether
            UpdatedRowsInput = [...UpdatedVisibleRows, ...InvisbleSortRows];
        } else {
            UpdatedRowsInput = UpdateRows;
        }

        setState((state) => ({...state, RowsInput: UpdatedRowsInput, ColumnsInput: ColsInput}));
    }
    function SetActionButtonColWidth(arg) {
        setState((state) => ({...state, ActionButtonColWidth: arg}));
    }

    function setGenerateHeader(arg) {
        setState((state) => ({...state, GenerateHeader: arg}));
    }

    function setTableAction(arg) {
        // console.log("Table Action in context");
        setState((state) => ({...state, TableAction: arg}));
    }
    function ResetTheContext() {
        let ResetStatus = {
            OriginalTableData: null, //Full Api data (including lookup and other data)
            RowsOriginal: null, //Only the table data but unmutable, if only table data it is the same as the OriginalTableData
            RowsInput: null, //Mutable
            ColumnsInput: null, //Mutable
            HeaderData: null,
            FooterData: null,
            BodyData: null,
            TableDimensionsOptions: {Theme: "Dark"},
            TableDimensions: null,
            TableStyleOptions: null,
            TableStyle: null,
            PagerOptions: null,
            SortedColumsState: {
                IsSortable: true,
                TotalSortedColumns: null,
            },
            SelectedColumnsState: {
                IsSelectable: true,
                TotalSelectedRows: null,
            },
            ToolBarState: {
                ShowToolbar: false,
            },
            GenerateHeader: false,
            ActionButtonColWidth: null,

            //Dev Only
            DisplayPager: true,
            TableAction: null,
            OriginalTableData: null,

            AccordionsStatus: [],
        };
        setState(ResetStatus);
    }
    //DEV TEST ONLY
    function HideShowPager(data) {
        setState((state) => ({...state, DisplayPager: data}));
    }

    return {
        StoreOriginalData,
        InitializeRowsAndCols,
        GenerateTableDimensionsOptions,
        GenerateTableDimensions,
        GenerateTableStyleOptions,
        GenerateTableStyle,
        GeneratePagerOptions,
        SetSelectedRows,
        setSortedRowsAndCols,
        SetSearchRows,
        SetActionButtonColWidth,
        setGenerateHeader,
        setTableAction,
        ResetTheContext,

        TableOriginalData: state.OriginalTableData,
        RowsOriginal: state.RowsOriginal,
        TableColsInputs: state.ColumnsInput,
        TableRowsInputs: state.RowsInput,
        TableDimensionsOptions: state.TableDimensionsOptions,
        TableDimensions: state.TableDimensions,
        TableStyleOptions: state.TableStyleOptions,
        TableStyle: state.TableStyle,
        PagerOptions: state.PagerOptions,
        SortedColumsState: state.SortedColumsState,
        SelectedColumnsState: state.SelectedColumnsState,
        ToolBarState: state.ToolBarState,
        ActionButtonColWidth: state.ActionButtonColWidth,
        GenerateHeader: state.GenerateHeader,
        TableAction: state.TableAction,
        //DEV TEST ONLY
        HideShowPager,
        DisplayPager: state.DisplayPager,
    };
};

export default useTableContext;
