import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import SendingStatusInformationTabNav from "./SendingHistory/SendingStatusInformationTabNav";
import SendingDocumentCard from "./SendingCard/SendingDocumentCard";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px ",
    },

    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        width: "620px",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        // margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "3px 10px 3px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {ShowCardUpload, HandleCloseCardParent, Invoices_File_Path, Customers, PassedFormValues, InvoiceId, HandleSaveRefreshForm} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCall, setApiCall] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [ShowComponentType, setShowComponentType] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                // toast.error(`Email not sent, ${ApiRes.error}`, {autoClose: true});
            } else {
                if (!ApiCall) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Card Document Main", Data);
                        let EmailToCustomer = Data.Invoice.EmailToCustomerStatus;
                        if (EmailToCustomer && EmailToCustomer.Sent === false) {
                            setShowComponentType("Send");
                        } else {
                            setShowComponentType("History");
                        }
                    } else {
                        console.log("error");
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoice/email/checkstatus/${InvoiceId}`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        HandleCloseCardParent();
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalComponent;

    if (ShowComponentType === "History") {
        FinalComponent = <SendingStatusInformationTabNav />;
    } else if (ShowComponentType === "Send") {
        FinalComponent = <SendingDocumentCard Invoices_File_Path={Invoices_File_Path} HandleCloseCardParent={HandleCloseCard} Customers={Customers} PassedFormValues={PassedFormValues} InvoiceId={InvoiceId} />;
    }

    return (
        <React.Fragment>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {FinalComponent}
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
