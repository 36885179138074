import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CircularGageFourQuarters from "../../../../../artibulles-cis/CircularGageFourQuarters/CircularGageFourQuarters";
import CircularGage from "../../../../../artibulles-cis/CircularGage/CircularGage";
import CircularGageClock from "../../../../../artibulles-cis/CircularGageClock/CircularGageClock";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Dashboard: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    GageWrapper: {
        display: "flex",
        alignItems: "center",
		padding : "0px 5px"
    },
    Gage: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "5px",
    },
    GageCentralText: {
		flex : "1 1 auto",
		width : "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    GageCentralTextMain: {
		
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
		fontSize: "0.85rem",
        fontWeight: "500",
    },
    GageCentralTextSub: {
        
		display: "flex",
		padding : "2px 0px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontSize: "0.80rem",
        fontWeight: "400",
    },
	GageBottomText: {
		fontSize: "0.9rem",
        fontWeight: "400",
	}
}));

const Component = React.memo(function Component(props) {
    const {WorkLoad} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div className={classes.Dashboard}>
            <div className={classes.GageWrapper}>
                <div className={classes.Gage}>
                    <CircularGageClock
                        ExternalDiameter="110"
                        Thickness="12"
                        Value={WorkLoad.WorkedGageValue}
                        GageTriggers={{
                            Trigger1: {trigger: 80, colorBelow: "red", colorAbove: "orange"},
                            Trigger2: {trigger: 99, colorAbove: "green"},
                            // Trigger3: {trigger: 100, colorAbove: "green"},
                        }}
                        // TickMarkIndicator={{Color: "grey"}}
                        // SeparateWarningColor=""
                        WithOuterText={false}
                        WithInnerText={false}
                        GageCustomCentralText={
                            <div className={classes.GageCentralText}>
                                <div className={classes.GageCentralTextMain}>{WorkLoad.TotalWorkedHours_Hours}</div>
                                <div className={classes.GageCentralTextSub}>{WorkLoad.WorkedGageValue + "%"}</div>
                            </div>
                        }
                        // GageCentralText={{
                        //     MainText: WorkLoad.TotalWorkedHours_Hours,
                        //     SubText: WorkLoad.RealWorkedGagePercentage + "%",
                        // }}
                        EmptyColor="rgb(220,220,220)"
                        // GageTickTextOuterfontSize=""
                        // GageTickTextOuterPaddingInner=""
                        // GageTickTextInnerfontSize=""
                        // GageTickTextInnerPaddingOuter=""
                        // GageTickOverflow=""
                        // GageTickColor=""
                        // GageStartAngle=""
                        MaxValue="100"
                        // GageCustomCentralText=""
                    />
                    <div className={classes.GageBottomText}>Worked Hours</div>
                </div>
            </div>
			<div className={classes.GageWrapper}>
                <div className={classes.Gage}>
                    <CircularGageClock
                        ExternalDiameter="110"
                        Thickness="12"
                        Value={WorkLoad.BillableGageValue}
                        GageTriggers={{
                            Trigger1: {trigger: 80, colorBelow: "red", colorAbove: "orange"},
                            Trigger2: {trigger: 99, colorAbove: "green"},
                            // Trigger3: {trigger: 100, colorAbove: "green"},
                        }}
                        // TickMarkIndicator={{Color: "grey"}}
                        // SeparateWarningColor=""
                        WithOuterText={false}
                        WithInnerText={false}
                        GageCustomCentralText={
                            <div className={classes.GageCentralText}>
                                <div className={classes.GageCentralTextMain}>{WorkLoad.TotalBillableAmount}</div>
                                <div className={classes.GageCentralTextSub}>{WorkLoad.BillableGageValue + "%"}</div>
                            </div>
                        }
                        // GageCentralText={{
                        //     MainText: WorkLoad.TotalWorkedHours_Hours,
                        //     SubText: WorkLoad.RealWorkedGagePercentage + "%",
                        // }}
                        EmptyColor="rgb(220,220,220)"
                        // GageTickTextOuterfontSize=""
                        // GageTickTextOuterPaddingInner=""
                        // GageTickTextInnerfontSize=""
                        // GageTickTextInnerPaddingOuter=""
                        // GageTickOverflow=""
                        // GageTickColor=""
                        // GageStartAngle=""
                        MaxValue="100"
                        // GageCustomCentralText=""
                    />
                    
					<div className={classes.GageBottomText}>Billable Amount</div>
                </div>
            </div>
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
// {MixingSealData.DetailsOpening.EaseOfOpeningGageText}
// <CircularGageFourQuarters
//                         ExternalDiameter="130"
//                         EmptyColor="rgb(220,220,220)"
//                         Thickness="20"
//                         GageTickThickness="1"
//                         GageTickOverflow="0"
//                         GageTickColor="black"
//                         // Value={WorkLoad.DetailsOpening.EaseOfOpeningGageValue}
//                         Value={30}
//                         MaxValue="100"
//                         GageTriggers={{
//                             Quarter1: {
//                                 Text: "UNDERWORK",
//                                 TextColor: "black",
//                                 GageColor: "red",
//                                 FontSize: "9px",
//                                 FontWeight: "300",
//                                 FontFamily: "Roboto",
//                                 OffsetRadius: 1,
//                                 InnerText: "18.5",
//                                 OuterText: "60kg",
//                             },
//                             Quarter2: {
//                                 Trigger: 25,
//                                 Text: "AVERAGE",
//                                 TextColor: "black",
//                                 GageColor: "orange",
//                                 FontSize: "9px",
//                                 FontWeight: "300",
//                                 FontFamily: "Roboto",
//                                 OffsetRadius: 1.2,
//                             },
//                             Quarter3: {
//                                 Trigger: 50,
//                                 Text: "GOOD",
//                                 TextColor: "black",
//                                 GageColor: "rgb(66,185,245)",
//                                 FontSize: "9px",
//                                 FontWeight: "300",
//                                 FontFamily: "Roboto",
//                                 OffsetRadius: 1,
//                             },
//                             Quarter4: {
//                                 Trigger: 75,
//                                 Text: "OVERWORK",
//                                 TextColor: "black",
//                                 GageColor: "rgb(100,204,98)",
//                                 FontSize: "9px",
//                                 FontWeight: "300",
//                                 FontFamily: "Roboto",
//                                 OffsetRadius: 1,
//                             },
//                         }}
//                         WithOuterText={false}
//                         WithInnerText={false}
//                         // NeedleIndictator={{Active : true}}
//                         GageCentralText={{
//                             MainText: "Percent",
//                             MainFontSize: "14px",
//                             SubText: "Value",
//                             SubFontSize: "14px",
//                         }}
//                         GageCustomCentralText={
//                             <div style={{boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "center", flex: "1 0 auto"}}>
//                                 <div style={{flex: "0 0 auto", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "14px"}}>Fantastic</div>
//                             </div>
//                         }
//                     />
