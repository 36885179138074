import React from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import clsx from "clsx";
import {ArrowSimpleLeft} from "@artibulles-cis/react-icons";

const LockedstylesWithProps = (props) => {
    return {
        ControllerWrapper: {
            display: "flex",
            width: "100%",
            boxSizing: "border-box",
            position: "relative",
        },
        AccordionVerticalWrapper: {
            display: "block",
            width: "100%",
            position: "relative",
            boxSizing: "border-box",
            overflow: "hidden",
        },
    };
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    AccordionVerticalWrapper_Init: {
        transition: "none",
    },
    AccordionVerticalWrapper: theme.AccordionVertical.AccordionVerticalWrapper,
    ControllerWrapper: theme.AccordionVertical.ControllerWrapper,
    ControllerContent: theme.AccordionVertical.ControllerContent,
    ControllerIconWrapper: theme.AccordionVertical.ControllerIconWrapper,
    CollapsibleContent: theme.AccordionVertical.CollapsibleContent,
}));
/**
 * Collapsible component with full height animations and nested features
 * It can handle an infinite number of collapsible elements.
 */
const AccordionVertical = React.forwardRef(function AccordionVertical(props, ref) {
    const {
        ControllerContent,
        ControllerIcon,
        ControllerIconPosition,
        ExternalController,
        IsNavlInk,
        IsOpenAccordionFromOutside,
        ShareIsOpenAccordionStatus,
        ClassOverrideAccordionVerticalWrapper,
        ClassOverrideControllerWrapper,
        ClassOverrideCollapsibleContent,
        IconStyle,
        children,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    var TransitionDurationTheme = parseInt(theme.AccordionVertical.AccordionVerticalWrapper.transitionDuration);
    var ArrowSimpleStyle;
    if (IconStyle) {
        ArrowSimpleStyle = {...theme.AccordionVertical.ArrowSimple, ...IconStyle};
    } else {
        ArrowSimpleStyle = theme.AccordionVertical.ArrowSimple;
    }

    const [Init, setInit] = React.useState(true);
    const [IsOpen, setIsOpen] = React.useState(false);
    const [ControllerHeight, setControllerHeight] = React.useState("0px");
    const [CurrentHeight, setCurrentHeight] = React.useState("0px");
    const [FinalHeight, setFinalHeight] = React.useState("0px");
    const [InTransition, setInTransition] = React.useState(false);

    const CalcChildren = React.useRef();
    const CalcController = React.useRef();

    //Calculate the Children height
    const CalculateHeight = () => {
        if (CalcChildren.current) {
            let ControllerHeight = CalcController.current.getBoundingClientRect().height;
            let CalcHeight = CalcChildren.current.getBoundingClientRect().height;
            setCurrentHeight(CalcHeight + ControllerHeight);
        }
    };

    //Calculate the Controller height
    const CalculateControllerHeight = () => {
        if (CalcController.current) {
            let CalcHeight = CalcController.current.getBoundingClientRect().height;
            setControllerHeight(CalcHeight);
        }
    };

    const UpdateFinalHeight = () => {
        if (!InTransition && IsOpen) {
            //When Fully Opened
            setFinalHeight("auto");
            return;
        }
        if (!IsOpen) {
            //When Fully Closed
            setFinalHeight(ControllerHeight);
            return;
        }
        if (InTransition) {
            //When in transition
            setFinalHeight(CurrentHeight);
        }
    };

    //Recalculate the Children and control the animation of the slider
    React.useEffect(() => {
        CalculateHeight();
        UpdateFinalHeight();
    });

    //Calculate the controller height
    React.useEffect(() => {
        CalculateControllerHeight();
    });

    //Caclucating the height of the controller for the initialization
    React.useEffect(() => {
        setTimeout(() => {
            setInit(false);
        }, 200);
    }, []);

    //Handling Controler click
    const ToggleOpen = () => {
        if (!IsOpen) {
            setInTransition(true);
            setIsOpen(true);
            if (ShareIsOpenAccordionStatus) {
                ShareIsOpenAccordionStatus(true);
            }
            setTimeout(() => {
                setInTransition(false);
            }, TransitionDurationTheme);
        } else {
            setInTransition(true);
            setTimeout(() => {
                setIsOpen(false);
            }, 10);
            if (ShareIsOpenAccordionStatus) {
                ShareIsOpenAccordionStatus(false);
            }
            setTimeout(() => {
                setInTransition(false);
            }, TransitionDurationTheme);
        }
    };

    //Handling Open from Outside
    const HandleOpenFromOutside = React.useCallback(
        (status) => {
            if (status) {
                setInTransition(true);
                setIsOpen(true);
                setTimeout(() => {
                    setInTransition(false);
                }, TransitionDurationTheme);
            } else {
                setInTransition(true);
                setTimeout(() => {
                    setIsOpen(false);
                }, 10);
                setTimeout(() => {
                    setInTransition(false);
                }, TransitionDurationTheme);
            }
        },
        [TransitionDurationTheme]
    );

    //   Controlling the opening from the Outside - Only when IsOpenAccordionFromOutside has changed
    React.useEffect(() => {
        if (IsOpenAccordionFromOutside === true || IsOpenAccordionFromOutside === false) {
            //Update the SideBar Minimized Status
            HandleOpenFromOutside(IsOpenAccordionFromOutside);
        }
    }, [IsOpenAccordionFromOutside, HandleOpenFromOutside]);

    //Creating the Elements Based on the inputs
    var FinalController;
    if (ExternalController) {
        //Full External Controller
        FinalController = <div ref={CalcController}>{ExternalController}</div>;
    } else {
        if (ControllerIcon) {
            //Only change the Icon Controller
            let FinalIconController = React.cloneElement(ControllerIcon, {
                Rotate: IsOpen ? -90 : 0,
                onClick: IsNavlInk ? ToggleOpen : null,
            });
            if (ControllerIconPosition === "Left") {
                FinalController = (
                    <div
                        className={ClassOverrideControllerWrapper ? ClassOverrideControllerWrapper : clsx(classes.ControllerWrapper)}
                        style={LockedstylesWithProps(props).ControllerWrapper}
                        onClick={!IsNavlInk ? ToggleOpen : null}
                        ref={CalcController}
                    >
                        <div className={clsx(classes.ControllerIconWrapper)}>{FinalIconController}</div>
                        <div className={clsx(classes.ControllerContent)}>{ControllerContent}</div>
                    </div>
                );
            } else {
                FinalController = (
                    <div
                        className={ClassOverrideControllerWrapper ? ClassOverrideControllerWrapper : clsx(classes.ControllerWrapper)}
                        style={LockedstylesWithProps(props).ControllerWrapper}
                        onClick={!IsNavlInk ? ToggleOpen : null}
                        ref={CalcController}
                    >
                        <div className={clsx(classes.ControllerContent)}>{ControllerContent}</div>
                        <div className={clsx(classes.ControllerIconWrapper)}>{FinalIconController}</div>
                    </div>
                );
            }
        } else {
            //Default Icon Controller
            let DefautlIconController = (
                <ArrowSimpleLeft
                    IconSize={ArrowSimpleStyle.IconSize}
                    SVGStrokeWidth={ArrowSimpleStyle.SVGStrokeWidth}
                    SVGStrokeColor={ArrowSimpleStyle.SVGStrokeColor}
                    SVGFillColor={ArrowSimpleStyle.SVGFillColor}
                    SVGStrokeHoverColor={ArrowSimpleStyle.SVGStrokeHoverColor}
                    SVGFillHoverColor={ArrowSimpleStyle.SVGFillHoverColor}
                    SVGTransitionDurationHover={ArrowSimpleStyle.SVGTransitionDurationHover}
                    SVGTransitionDurationRotate={ArrowSimpleStyle.SVGTransitionDurationRotate}
                    Rotate={IsOpen ? -90 : 0}
                    onClick={IsNavlInk ? ToggleOpen : null}
                />
            );
            if (ControllerIconPosition === "Left") {
                FinalController = (
                    <div
                        className={ClassOverrideControllerWrapper ? ClassOverrideControllerWrapper : clsx(classes.ControllerWrapper)}
                        style={LockedstylesWithProps(props).ControllerWrapper}
                        onClick={!IsNavlInk ? ToggleOpen : null}
                        ref={CalcController}
                    >
                        <div className={clsx(classes.ControllerIconWrapper)}>{DefautlIconController}</div>
                        <div className={clsx(classes.ControllerContent)}>{ControllerContent}</div>
                    </div>
                );
            } else {
                FinalController = (
                    <div
                        className={ClassOverrideControllerWrapper ? ClassOverrideControllerWrapper : clsx(classes.ControllerWrapper)}
                        style={LockedstylesWithProps(props).ControllerWrapper}
                        onClick={!IsNavlInk ? ToggleOpen : null}
                        ref={CalcController}
                    >
                        <div className={clsx(classes.ControllerContent)}>{ControllerContent}</div>
                        <div className={clsx(classes.ControllerIconWrapper)}>{DefautlIconController}</div>
                    </div>
                );
            }
        }
    }
    return (
        <div
            ref={ref}
            className={Init ? clsx(classes.AccordionVerticalWrapper_Init) : ClassOverrideAccordionVerticalWrapper ? ClassOverrideAccordionVerticalWrapper : clsx(classes.AccordionVerticalWrapper)}
            style={{...LockedstylesWithProps(props).AccordionVerticalWrapper, height: FinalHeight}}
        >
            {FinalController}
            <div className={ClassOverrideCollapsibleContent ? ClassOverrideCollapsibleContent : clsx(classes.CollapsibleContent)} ref={CalcChildren}>
                {children}
            </div>
        </div>
    );
});

AccordionVertical.defaultProps = {
    ControllerContent: null,
    ControllerIcon: null,
    ControllerIconPosition: "Right",
    ExternalController: null,
    IsNavlInk: false,
    IsOpenAccordionFromOutside: null,
    ShareIsOpenAccordionStatus: null,
    ClassOverrideAccordionVerticalWrapper: null,
    ClassOverrideControllerWrapper: null,
    ClassOverrideCollapsibleContent: null,
    IconStyle: null,
};

AccordionVertical.propTypes = {
    /**
     * The Content of the Controller (top of the Accordion)
     * Can be just text or an HTML element or a React Component
     */
    ControllerContent: PropTypes.node,
    /**
     * Change the default Icon - The default Icon Controller is a SimpleArrowLeft
     * Pass an Icon Element that will inherit the click function and rotation properties
     */
    ControllerIcon: PropTypes.node,
    /**
     * Position of the Controller Icon - Default = Right - Options : Left/Right
     */
    ControllerIconPosition: PropTypes.string,
    /**
     * Fully Externalize the Simple Controller to use your own Controller - No Icon, no Content on top
     * You need to handle the function to set the accordion Opened or closed using IsOpenAccordionFromOutside
     */
    ExternalController: PropTypes.node,
    /**
     * When a Navlink is used, the click function is applied only to the Icon Controller and the Main Controller Content handles
     * the NavlInk click. The reason for this is to prevent lagging because of Navlink rerendering multiple times and creating issues
     * with the transition effects used to create the nice Accordion animations.
     * Can be used to disabled the click on Controller and only allow it on the IconController if you need to.
     */
    IsNavlInk: PropTypes.bool,
    /**
     * Set the open and close status from outside the Component
     */
    IsOpenAccordionFromOutside: PropTypes.bool,
    /**
     * Used to share the status of the Controller to the Parent Element.
     * Must be a function.
     * Example :
     * const ToggleOpenExtController = () => {
     *	setExtControllerIsOpen(!ExtControllerIsOpen);
     * };
     */
    ShareIsOpenAccordionStatus: PropTypes.func,
    /**
     * Overrides the Default style of the the Controller
     */
    ClassOverrideAccordionVerticalWrapper: PropTypes.string,
    /**
     * Overrides the Default style of the the Controller
     */
    ClassOverrideControllerWrapper: PropTypes.string,
    /**
     * Overrides the Default style of the the Content of the Accordion
     */
    ClassOverrideCollapsibleContent: PropTypes.string,
    /**
     * Change the SimpleArrowLeftDefault Controller Style
     */
    IconStyle: PropTypes.object,
};

export default AccordionVertical;
