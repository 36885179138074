import React from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
// import { toast } from "react-toastify";
import Button from "../Button/Button";

const LocaleMap = {
    en: {
        DeleteMessage: "Are you sure you want to delete this record ?",
        DeleteButton: "Delete",
        CancelButton: "Cancel",
        PublishMessage: "Are you sure you want to publish this record ?",
        PublishButton: "Publish",
        QuitWithoutSaveMessage: "Are you sure you want to leave wihtout saving ? All Data will be lost",
        SaveAndQuitButton: "Save",
        QuitWithouSaveButton: "Quit",
        SavedMessage: "Record Saved",
        DeletedMessage: "Record Deleted",
        PublishedMessage: "Record Published",
    },
    fr: {
        DeleteMessage: "Etes-vous sûr de vouloir effacer cette entrée ?",
        DeleteButton: "Effacer",
        CancelButton: "Annuler",
        PublishMessage: "Etes-vous sûr de vouloir publier cette entrée ?",
        PublishButton: "Publier",
        QuitWithoutSaveMessage: "Etes-vous sur de vouloir quitter ? Les modifications ne seront pas sauvegardées",
        SaveAndQuitButton: "Sauver",
        QuitWithouSaveButton: "Quitter",
        SavedMessage: "Entrée sauvegardée",
        DeletedMessage: "Entrée supprimée",
        PublishedMessage: "Entrée publiée",
    },
};
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};
//eslint-disable-next-line
const Lockedstyles = {};
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Form Toastr Component sending toast and creating toast with actions
 */
const FormToastr = React.forwardRef(function FormToastr(props, ref) {
    const {
        toast,
        ToastInput,
        OnToastrConfirmation,
        Locale,
        ToastDeleteConfirmation,
        ToastPublishConfirmation,
        ToastQuitWihtouSaveConfirmation,
        ToastSavedMessage,
        ToastDeletedMessage,
        ToastPublishedMessage,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    //Loading the toasts based on the input required

    const DeletedConfirmMessage = React.useCallback(() => {
        var message, localeMessage;
        if (Locale) {
            localeMessage = LocaleMap[Locale].DeletedMessage;
        } else {
            localeMessage = LocaleMap.en.DeletedMessage;
        }
        if (ToastDeleteConfirmation) {
            message = ToastDeletedMessage ? ToastDeletedMessage : localeMessage;
        } else {
            message = localeMessage;
        }
        return message;
    }, [Locale, ToastDeleteConfirmation, ToastDeletedMessage]);

    const PublishedConfirmMessage = React.useCallback(() => {
        var message, localeMessage;
        if (Locale) {
            localeMessage = LocaleMap[Locale].PublishedMessage;
        } else {
            localeMessage = LocaleMap.en.PublishedMessage;
        }
        if (ToastPublishedMessage) {
            message = ToastPublishedMessage ? ToastPublishedMessage : localeMessage;
        } else {
            message = localeMessage;
        }
        return message;
    }, [Locale, ToastPublishedMessage]);
    const SavedConfirmMessage = React.useCallback(() => {
        var message, localeMessage;
        if (Locale) {
            localeMessage = LocaleMap[Locale].SavedMessage;
        } else {
            localeMessage = LocaleMap.en.SavedMessage;
        }
        if (ToastSavedMessage) {
            message = ToastSavedMessage ? ToastSavedMessage : localeMessage;
        } else {
            message = localeMessage;
        }
        return message;
    }, [Locale, ToastSavedMessage]);

    React.useEffect(() => {
        if (ToastInput) {
            if (ToastInput.typeOfSubmit === "Saved") {
                toast.success(SavedConfirmMessage());
            }
            if (ToastInput.typeOfSubmit === "Delete") {
                toast.warning(<MsgDelete />, {autoClose: false, closeButton: false});
            }
            if (ToastInput.typeOfSubmit === "Deleted") {
                toast.success(DeletedConfirmMessage());
            }
            if (ToastInput.typeOfSubmit === "Published") {
                toast.success(PublishedConfirmMessage());
            }

            if (ToastInput.typeOfSubmit === "QuitNoSave" || ToastInput.typeOfSubmit === "PreviousQuit" || ToastInput.typeOfSubmit === "NextQuit") {
                toast.warning(<MsgQuit values={ToastInput.values} typeOfSubmit={ToastInput.typeOfSubmit} />, {autoClose: false, closeButton: false});
            }
            if (ToastInput.typeOfSubmit === "Publish") {
                toast.warning(<MsgPublish values={ToastInput.values} />, {autoClose: false, closeButton: false});
            }
        }
    }, [ToastInput, DeletedConfirmMessage, SavedConfirmMessage, PublishedConfirmMessage, toast]);

    /***************** TOASTR MESSAGE CONFIRMATION ******************/

    const QuitMessages = () => {
        var message, QuitB, SaveAndQuitB, localeMessage, localeQuit, localeSaveAndQuit;
        if (Locale) {
            localeMessage = LocaleMap[Locale].QuitWithoutSaveMessage;
            localeQuit = LocaleMap[Locale].QuitWithouSaveButton;
            localeSaveAndQuit = LocaleMap[Locale].SaveAndQuitButton;
        } else {
            localeMessage = LocaleMap.en.QuitWithoutSaveMessage;
            localeQuit = LocaleMap.en.QuitWithouSaveButton;
            localeSaveAndQuit = LocaleMap.en.SaveAndQuitButton;
        }
        if (ToastDeleteConfirmation) {
            message = ToastQuitWihtouSaveConfirmation.Message ? ToastQuitWihtouSaveConfirmation.Message : localeMessage;
            QuitB = ToastQuitWihtouSaveConfirmation.QuitNoSaveButton ? ToastQuitWihtouSaveConfirmation.QuitNoSaveButton : localeQuit;
            SaveAndQuitB = ToastQuitWihtouSaveConfirmation.SaveAndQuitButton ? ToastQuitWihtouSaveConfirmation.SaveAndQuitButton : localeSaveAndQuit;
        } else {
            message = localeMessage;
            QuitB = localeQuit;
            SaveAndQuitB = localeSaveAndQuit;
        }
        return {message: message, quitNoSaveButton: QuitB, saveAndQuitButton: SaveAndQuitB};
    };

    const MsgQuit = ({closeToast, values, typeOfSubmit}) => {
	  var Save,Quit;
		
		
		if (typeOfSubmit === "QuitNoSave"){
			Save = {origin : "QuitNoSave" , action :"Save"}
			Quit = {origin : "QuitNoSave" , action :"Quit"}
		}
		if (typeOfSubmit === "PreviousQuit"){
			Save = {origin : "PreviousQuit" , action :"Save"}
			Quit = {origin : "PreviousQuit" , action :"Quit"}
		}
		if (typeOfSubmit === "NextQuit"){
			Save = {origin : "NextQuit" , action :"Save"}
			Quit = {origin : "NextQuit" , action :"Quit"}
		}

		
		return (
            <div className={classes.ToastrWrapper}>
                <div className={classes.ToastrBody}>{QuitMessages().message}</div>
                <div className={classes.ToastrButtonBar} style={{display: "flex"}}>
                    <Button
                        Height="30px"
                        Width="100px"
                        onClick={() => {
                            ToastInput.typeOfSubmit = Save;
                            toast.dismiss();
                            OnToastrConfirmation( ToastInput);
                        }}
                    >
                        {QuitMessages().saveAndQuitButton}
                    </Button>
                    <Button
                        Height="30px"
                        Width="100px"
                        onClick={() => {
                            ToastInput.typeOfSubmit = Quit;
                            toast.dismiss();
                            OnToastrConfirmation( ToastInput);
                        }}
                    >
                        {QuitMessages().quitNoSaveButton}
                    </Button>
                </div>
            </div>
        );
    };

    const PublishMessages = () => {
        var message, CancelB, PublishB, localeMessage, localeCancel, localePublish;
        if (Locale) {
            localeMessage = LocaleMap[Locale].PublishMessage;
            localeCancel = LocaleMap[Locale].CancelButton;
            localePublish = LocaleMap[Locale].PublishButton;
        } else {
            localeMessage = LocaleMap.en.PublishMessage;
            localeCancel = LocaleMap.en.CancelButton;
            localePublish = LocaleMap.en.PublishButton;
        }
        if (ToastPublishConfirmation) {
            message = ToastPublishConfirmation.Message ? ToastPublishConfirmation.Message : localeMessage;
            CancelB = ToastPublishConfirmation.CancelButton ? ToastPublishConfirmation.CancelButton : localeCancel;
            PublishB = ToastPublishConfirmation.PublishButton ? ToastPublishConfirmation.PublishButton : localePublish;
        } else {
            message = localeMessage;
            CancelB = localeCancel;
            PublishB = localePublish;
        }
        return {message: message, cancelButton: CancelB, publishButton: PublishB};
    };

    const MsgPublish = ({closeToast, values}) => (
        <div className={classes.ToastrWrapper}>
            <div className={classes.ToastrBody}>{PublishMessages().message}</div>
            <div className={classes.ToastrButtonBar} style={{display: "flex"}}>
                <Button Height="30px" Width="100px" onClick={() => toast.dismiss()}>
                    {PublishMessages().cancelButton}
                </Button>
                <Button
                    Height="30px"
                    Width="100px"
                    onClick={() => {
                        toast.dismiss();
                        OnToastrConfirmation(ToastInput);
                    }}
                >
                    {PublishMessages().publishButton}
                </Button>
            </div>
        </div>
    );

    const DeleteMessages = () => {
        var message, cancelB, deleteB, localeMessage, localeCancel, localeDelete;
        if (Locale) {
            localeMessage = LocaleMap[Locale].DeleteMessage;
            localeCancel = LocaleMap[Locale].CancelButton;
            localeDelete = LocaleMap[Locale].DeleteButton;
        } else {
            localeMessage = LocaleMap.en.DeleteMessage;
            localeCancel = LocaleMap.en.CancelButton;
            localeDelete = LocaleMap.en.DeleteButton;
        }
        if (ToastDeleteConfirmation) {
            message = ToastDeleteConfirmation.Message ? ToastDeleteConfirmation.Message : localeMessage;
            cancelB = ToastDeleteConfirmation.CancelButton ? ToastDeleteConfirmation.CancelButton : localeCancel;
            deleteB = ToastDeleteConfirmation.DeleteButton ? ToastDeleteConfirmation.DeleteButton : localeDelete;
        } else {
            message = localeMessage;
            cancelB = localeCancel;
            deleteB = localeDelete;
        }
        return {message: message, cancelButton: cancelB, deleteButton: deleteB};
    };

    const MsgDelete = ({closeToast, ID}) => (
        <div className={classes.ToastrWrapper}>
            <div className={classes.ToastrBody}>{DeleteMessages().message}</div>
            <div className={classes.ToastrButtonBar} style={{display: "flex"}}>
                <Button Height="30px" Width="100px" onClick={() => toast.dismiss()}>
                    {DeleteMessages().cancelButton}
                </Button>
                <Button
                    Height="30px"
                    Width="100px"
                    onClick={() => {
                        toast.dismiss();
                        OnToastrConfirmation(ToastInput);
                    }}
                >
                    {DeleteMessages().deleteButton}
                </Button>
            </div>
        </div>
    );
    /***************** TOASTR MESSAGE CONFIRMATION ******************/

    return <React.Fragment></React.Fragment>;
});

FormToastr.defaultProps = {
    ToastInput: null,
    OnToastrConfirmation: null,
    Locale: "en",
    ToastDeleteConfirmation: null,
    ToastPublishConfirmation: null,
    ToastQuitWihtouSaveConfirmation: null,
    ToastSavedMessage: null,
    ToastDeletedMessage: null,
    ToastPublishedMessage: null,
};

FormToastr.propTypes = {
    /**
     * Override the class
     */
    ToastInput: PropTypes.any,
    /**
     * Override the class
     */
    OnToastrConfirmation: PropTypes.any,
    /**
     * Override the class
     */
    Locale: PropTypes.string,
    /**
     * Override the class
     */
    ToastDeleteConfirmation: PropTypes.any,
    /**
     * Override the class
     */
    ToastPublishConfirmation: PropTypes.any,
    /**
     * Override the class
     */
    ToastQuitWihtouSaveConfirmation: PropTypes.any,
    /**
     * Override the class
     */
    ToastSavedMessage: PropTypes.any,
    /**
     * Override the class
     */
    ToastDeletedMessage: PropTypes.any,
    /**
     * Override the class
     */
    ToastPublishedMessage: PropTypes.any,
};

export default FormToastr;
