/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import TabNavContent from "../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import AccountEditForm from "./AccountEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "0px",
    },
}));

const ReactComponent = (props) => {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/financials/companyaccount/bankaccounts/account/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companyaccount/bankaccounts/account/edit`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Customer._id;
            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;

        // var CompanySiteTypes;
        // if (data.CompanySiteTypes && data.CompanySiteTypes.length > 0) {
        //     CompanySiteTypes = data.CompanySiteTypes.map((elem) => {
        //         return {value: elem._id, label: elem.name_EN};
        //     });
        // }

        // var CompanySiteTypesInitial = data.Company.ref_companySiteType;
        // if (!CompanySiteTypesInitial) {
        //     CompanySiteTypesInitial = _.find(data.CompanySiteTypes, {name_EN: "Headquarters"});
        //     if (CompanySiteTypesInitial) {
        //         CompanySiteTypesInitial = CompanySiteTypesInitial._id;
        //     }
        // }

        // var parentId = [];
        // if (data.Companies && data.Companies.length > 0) {
        //     parentId = data.Companies.map((elem) => {
        //         return {value: elem._id, label: elem.company};
        //     });
        // }

        // //Handling the Addresses
        // var Addresses = [];
        // if (data.CompanyAddresses && data.CompanyAddresses.Addresses && data.CompanyAddresses.Addresses.length > 0) {
        //     Addresses = data.CompanyAddresses.Addresses;
        // }
        // //We will need to do something with the initial country and address type using the select...

        // var countries;
        // if (data.CompanyAddresses && data.CompanyAddresses.ref_country && data.CompanyAddresses.ref_country.length > 0) {
        //     countries = data.CompanyAddresses.ref_country.map((elem) => {
        //         return {value: elem._id, label: elem.country_name_EN, code: elem.country_code};
        //     });
        // }
        // var CompanyAddressTypes;
        // if (data.CompanyAddresses && data.CompanyAddresses.ref_address_type && data.CompanyAddresses.ref_address_type.length > 0) {
        //     CompanyAddressTypes = data.CompanyAddresses.ref_address_type.map((elem) => {
        //         return {value: elem._id, label: elem.name_EN};
        //     });
        // }

        // //Simulating the address
        // var CompanyAddresses = {
        //     Addresses: Addresses,
        //     ref_country: countries,
        //     ref_address_type: CompanyAddressTypes,
        // };

        //SET THE INITIAL FORM VALUES

        let Account = data.Account ? data.Account : null;

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Account ? Account._id : "new",
                account_number: Account ? Account.account_number : null,
                bic: Account ? Account.bic : null,
                account_owner: Account ? Account.account_owner : null,
                bank_name: Account ? Account.bank_name : null,
                ref_contact: Account ? Account.ref_contact : null,
                ref_company: Account ? Account.ref_company : null,
                isDefaultAccount: Account ? Account.isDefaultAccount : false,
                status: Account ? Account.status : null,
                db_status: Account ? Account.db_status : null,
                db_locked: Account ? Account.db_locked : null,
                db_locked_by: Account ? Account.ref_db_locked_by : null,
                db_locked_at: Account ? Account.db_locked_at : null,
                owner: Account ? Account.ref_owner : null,
                createdAt: Account ? (Account.createdAt ? format(parseISO(Account.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: Account ? (Account.updatedAt ? format(parseISO(Account.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                // ref_companySiteType: CompanySiteTypes,
                // parentId: parentId,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                console.log("values", values);
                let ServerData = {
                    API_id: values.ApiID,
                    Account: {
                        Updatable: {
                            account_number: values.account_number,
                            bic: values.bic,
                            account_owner: values.account_owner,
                            bank_name: values.bank_name,
                            isDefaultAccount: values.isDefaultAccount?values.isDefaultAccount:null,
                            status: values.status,
                        },
                    },
                };

                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={AccountEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save Draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save"}}
                ButtonQuitNoSave={{Active: false, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default ReactComponent;
