import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {useHistory, useParams} from "react-router-dom";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";

import AccountOverview from "./AccountOverview/AccountOverview";
import AccountEdit from "./AccountEdit";
import AccountTransactions from "./AccountTransactions/AccountTransactions";
import AccountStatements from "./AccountStatements";
import AccountStatementsGroups from "./AccountStatementsGroups";
import AccountDuplicateTransactions from "./AccountTransactions/AccountDuplicateTransactions";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    NoAccount_Wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        jusitfyContent: "center",
        boxSizing: "border-box",
        marginTop: "15px",
    },
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const AccountDetails = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const LocationParams = useParams();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    const History = useHistory();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [CompReady, setCompReady] = useState(false);
    const [Id, SetId] = useState(LocationParams.id);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);
                    History.push(`/financials/companyaccount/bankaccounts/account/${Data._id}/overview`);
                    setCompReady(true);
                } else {
                    //No bank account, propose to create one
                    setCompReady(true);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    //If there is no Id in the route -> Contact the Api to get the Id
    useEffect(() => {
        if (Id === "default") {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/bankaccounts/account/default`, type: "get"};
            setLoading(true);
            setCompReady(false);
            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, Id]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (LocationParams.id) {
            if (LocationParams.id !== "default") {
                SetId(LocationParams.id);
                setCompReady(true);
                setLoading(false);
            }
        }
    }, [LocationParams]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleAddAccount = () => {
        History.push("/financials/companyaccount/bankaccounts/account/new/view");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var FinalComponent;
    if (Loading === true || CompReady === false) {
        FinalComponent = (
            <div>
                <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
            </div>
        );
    } else {
        if (Id === "default") {
            FinalComponent = (
                <div className={classes.NoAccount_Wrapper}>
                    <div>There is no Bank Account </div>

                    <Button Width="250px" onClick={HandleAddAccount}>
                        Add Bank Account
                    </Button>
                </div>
            );
        } else {
            FinalComponent = (
                <TabNav
                    MainSlug={`/financials/companyaccount/bankaccounts/account/:id`}
                    DefaultTab={`/financials/companyaccount/bankaccounts/account/${Id}/overview`}
                    ActiveIndicator={{
                        WithIndicator: true,
                        Position: "Bottom",
                    }}
                    TabMenuContent={[
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Overview</div>
                                </div>
                            ),
                            Slug: `/financials/companyaccount/bankaccounts/account/${Id}/overview`,
                            TabContent: <AccountOverview />,
                        },

                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Bank Transactions</div>
                                </div>
                            ),
                            Slug: `/financials/companyaccount/bankaccounts/account/${Id}/transactions`,
                            TabContent: <AccountTransactions Id={Id ? Id : null} />,
                        },
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Bank Statements</div>
                                </div>
                            ),
                            Slug: `/financials/companyaccount/bankaccounts/account/${Id}/statements`,
                            TabContent: <AccountStatements Id={Id ? Id : null} />,
                        },
						{
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Bank Statements Groups</div>
                                </div>
                            ),
                            Slug: `/financials/companyaccount/bankaccounts/account/${Id}/statementsgroups`,
                            TabContent: <AccountStatementsGroups Id={Id ? Id : null} />,
                        },
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Account Details</div>
                                </div>
                            ),
                            Slug: `/financials/companyaccount/bankaccounts/account/${Id}/view`,
                            TabContent: <AccountEdit Id={Id ? Id : null} />,
                            ShowOnlyIfActive: true,
                        },
                        {
                            TabMenu: (
                                <div className={classes.TabMenuDiv}>
                                    <div className={classes.TabTitle}>Create Bank Account</div>
                                </div>
                            ),

                            ShowOnlyIfActive: true,
                            exact: true,
                            Slug: `/financials/companyaccount/bankaccounts/account/new/edit`,
                            TabContent: <AccountEdit Id="new" />,
                        },
                    ]}
                />
            );
        }
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;

    /***************** RENDER ******************/
};

export default AccountDetails;
