/**
 * The following steps are performed :
 * 1) Load the Initial values on the first render if Initial values exist :
 * 	a) It gets the data from the initial form with the DB values and following structure :
 * 	serverPath (mandatory), downloadName, (optional), originalName (optional), fileSize (optional)
 * b) If there are inital files, it connects to the file using the serverPath to extract the fileSize if it doesn't exist or to check if the file hase been deleted
 * c) It thens render the select with the appropriate extension (icon) and shortName and fileSize
 *
 * 2)
 * 	a) When adding a file to the select, it connects to the server and upload the file, the server sends back the serverPath and so on
 *  b) When deleting a file, it deletes the file from the server and remove it from the select
 *
 */

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import axios from "axios";
import filesize from "filesize";

import {UploadCloud} from "@artibulles-cis/react-icons";
import ProgressLinear from "../ProgressLinear/ProgressLinear";

import FileUploaderDragAndDropContainer from "../FileUploaderDragAndDropContainer/FileUploaderDragAndDropContainer";
import FormAnimatedWrapper from "../FormAnimatedWrapper/FormAnimatedWrapper";
import FileUploaderSelect from "../FileUploaderSelect/FileUploaderSelect";

import Button from "../Button/Button";

//Get file size info in async mode
const getFileSize = async (url) => {
    var totalSize = 0;
    const AxiosConfig = {
        onDownloadProgress: (progress) => {
            const {total} = progress;
            totalSize = total;
        },
    };
    try {
        await axios.get(url, AxiosConfig);

        return totalSize;
    } catch (error) {
        console.error(error);
    }
};

//Loading the initial files information into the select if initial files
const LoadInitialFiles = async ({fileList, renameFile, rename, fileNameDisplayMaxCharacters}) => {
    /**
     * This function returns :
     * -A list of ShortNames file with the size
     * -The new Total Size
     * -The new Total Files
     */
    //Building the Function props with props and initial values and destructuring for easiness

    const FinalProps = {
        fileListFn: fileList && Array.isArray(fileList) ? fileList : null, //The list of files to filter
        renameFn: rename ? rename : "localfile", //the renaming file
        fileNameDisplayMaxCharactersfn: fileNameDisplayMaxCharacters ? parseInt(fileNameDisplayMaxCharacters) : null, //trimming for short names
    };
    const {fileListFn, renameFn, fileNameDisplayMaxCharactersfn} = FinalProps;
    //Starting the function

    if (fileListFn) {
        let ShortNames = [];
        let OriginalNames = [];
        let FileNames = [];
        let FileSizes = [];
        let FileExtensions = [];
        let FileTypes = [];
        let Keywords = [];
        let ServerPathNames = [];

        let newTotalFiles = 0;
        let newTotalSize = 0;

        for (var i = 0; i < fileListFn.length; i++) {
            let file = fileListFn[i];

            if (file) {
                let serverPath = file.serverPath;
                ServerPathNames.push(serverPath);
                let OriginalFileName = file.originaName;
                OriginalNames.push(OriginalFileName);
                let FileName = file.fileName;
                FileNames.push(FileName);
                let FileType = file.fileType;
                FileTypes.push(FileType);
                let Keyword = file.keywords;
                Keywords.push(Keyword);

                //Extract the file size information :
                let fileSize = await getFileSize(serverPath);
                let sizeMB;
                if (fileSize) {
                    FileSizes.push(fileSize);
                    sizeMB = filesize(parseFloat(fileSize), {round: 0});
                }
                //Defining the short name and size info for each file

                if (fileNameDisplayMaxCharactersfn) {
                    let ShortFileName;

                    let filePath = serverPath.toString();
                    let ext = filePath.slice(((filePath.lastIndexOf(".") - 1) >>> 0) + 2);

                    if (renameFn.length + 2 > fileNameDisplayMaxCharactersfn) {
                        let trimmed = renameFn.substring(0, fileNameDisplayMaxCharactersfn);
                        ShortFileName = `${trimmed}_${i}(...).${ext}`;
                    } else {
                        ShortFileName = `${renameFn}_${i}.${ext}`;
                    }

                    let shortname = `${ShortFileName}(${sizeMB})`;
                    FileExtensions.push(ext);
                    ShortNames.push(shortname);
                } else {
                    let shortname = `${file}(${sizeMB})`;
                    ShortNames.push(shortname);
                }

                newTotalFiles = newTotalFiles + 1;
                newTotalSize = newTotalSize + parseFloat(fileSize);
            }
        }

        let Output = {
            OriginalNames: OriginalNames,
            FileNames: FileNames,
            ShortNames: ShortNames,
            FileSizes: FileSizes,
            FileExtensions: FileExtensions,
            FileTypes: FileTypes,
            Keywords: Keywords,
            totalSize: newTotalSize,
            totalFiles: newTotalFiles,
            serverPaths: ServerPathNames,
        };

        return Output;
    }
};

//Function that checks if the files are allowed based on type, size, totalSize
const CheckAllowedFilesList = ({currentFileList, fileList, maxSizeTotalMB, maxFiles, maxSizePerFileMB, typesAllowed, initialSize, initialTotalFiles, fileNameDisplayMaxCharacters}) => {
    /**
     * This function returns :
     * -A list of allowed files
     * -A list of reject files
     * -A list of ShortNames file with the size
     * -The new Total Size
     * -The new Total Files
     * -an error object specifing what errors happened
     */

    //Building the Function props with props and initial values and destructuring for easiness
    const FinalProps = {
        currentFileListFn: currentFileList && Array.isArray(currentFileList) ? currentFileList : null, //The list of current existing file (to prevent duplicates)
        fileListFn: fileList && Array.isArray(fileList) ? fileList : null, //The list of files to filter
        maxSizeTotalMBFn: maxSizeTotalMB ? parseFloat(maxSizeTotalMB) * 1000000 : null, //The Maximum Total Size Allowed
        maxFilesFn: maxFiles ? parseInt(maxFiles) : null, //The maximum number of files
        maxSizePerFileMBFn: maxSizePerFileMB ? parseFloat(maxSizePerFileMB) * 1000000 : null, //The size limit per file
        typesAllowedFn: typesAllowed && Array.isArray(typesAllowed) ? typesAllowed : null, //Array or file types allowed
        initialSizeFn: initialSize ? parseFloat(initialSize) : 0, //The initial size if any
        initialTotalFilesFn: initialTotalFiles ? parseInt(initialTotalFiles) : 0, //The initial Total File if any
        fileNameDisplayMaxCharactersfn: fileNameDisplayMaxCharacters ? parseInt(fileNameDisplayMaxCharacters) : null, //trimming for short names
    };
    const {currentFileListFn, fileListFn, maxSizeTotalMBFn, maxFilesFn, maxSizePerFileMBFn, typesAllowedFn, initialSizeFn, initialTotalFilesFn, fileNameDisplayMaxCharactersfn} = FinalProps;

    //Starting the function
    if (fileListFn) {
        var filesAllowed = [];
        var filesRejected = [];
        var ShortNames = [];
        var FileExtensions = [];
        //
        let OriginalNames = [];
        let FileNames = [];
        let FileSizes = [];
        let FileTypes = [];
        let Keywords = [];

        var newTotalFiles = initialTotalFilesFn;
        var newTotalSize = initialSizeFn;
        var fnError = {Error: null, MaxFile: null, MaxSize: null, FileType: null, IndividualSize: null}; //Display error message

        //Checking all filters (maximum files allowed, max file size, file types)

        for (var i = 0; i < fileListFn.length; i++) {
            let file = fileListFn[i];
            if (file) {
                //Checking that the file is not already in the list
                let excludeFile = false;

                if (currentFileListFn) {
                    currentFileListFn.forEach((elem) => {
                        if (elem === file.name) {
                            excludeFile = true;
                            //Skip the file
                        }
                    });
                }
                if (excludeFile) {
                    continue;
                }

                newTotalFiles = newTotalFiles + 1;
                newTotalSize = newTotalSize + parseFloat(file.size);

                //Checking total files
                if (maxFilesFn && newTotalFiles > maxFilesFn) {
                    //Total Files exceeded
                    //File rejected : revert all values to previous
                    newTotalSize = newTotalSize - parseFloat(file.size);
                    newTotalFiles = newTotalFiles - 1;
                    fnError = {...fnError, Error: true, MaxFile: maxFilesFn};
                    filesRejected.push(file);
                    continue;
                }

                //Checking individual file size
                if (maxSizePerFileMBFn && file.size > maxSizePerFileMBFn) {
                    //Individual size exceeded
                    //File rejected : revert all values to previous
                    newTotalSize = newTotalSize - parseFloat(file.size);
                    newTotalFiles = newTotalFiles - 1;
                    fnError = {...fnError, Error: true, IndividualSize: maxSizePerFileMBFn};
                    filesRejected.push(file);
                    continue;
                }

                //Checking total file size
                if (maxSizeTotalMBFn && newTotalSize > maxSizeTotalMBFn) {
                    //Total size exceeded
                    //File rejected : revert all values to previous
                    fnError = {...fnError, Error: true, MaxSize: maxSizeTotalMBFn};
                    filesRejected.push(file);
                    newTotalSize = newTotalSize - parseFloat(file.size);
                    newTotalFiles = newTotalFiles - 1;
                    continue;
                }

                //Checking the file type
                if (typesAllowedFn) {
                    var typeError = true;

                    let riskyAcceptAll = typesAllowedFn.find((type) => type === "*");

                    if (riskyAcceptAll) {
                        //At your own risks....
                        // console.log("skip mime type")
                        typeError = false;
                    } else {
                        let mimeType = file.type;
                        // console.log(type)
                        if (mimeType.startsWith("image")) {
                            let exist = typesAllowedFn.find((type) => type === "image");
                            if (exist) {
                                typeError = false;
                            }
                        }
                        if (mimeType.startsWith("video")) {
                            let exist = typesAllowedFn.find((type) => type === "video");
                            if (exist) {
                                typeError = false;
                            }
                        }
                        if (mimeType.startsWith("application/pdf")) {
                            let exist = typesAllowedFn.find((type) => type === "pdf");
                            if (exist) {
                                typeError = false;
                            }
                        }
                        if (mimeType.startsWith("application/xml") || mimeType.startsWith("text/xml")) {
                            let exist = typesAllowedFn.find((type) => type === "xml");
                            if (exist) {
                                typeError = false;
                            }
                        }
                        if (mimeType.startsWith("text/csv")) {
                            let exist = typesAllowedFn.find((type) => type === "csv");
                            if (exist) {
                                typeError = false;
                            }
                        }
                        if (typeError) {
                            //File rejected : revert all values to previous
                            fnError = {...fnError, Error: true, FileType: "not accepted"};
                            filesRejected.push(file);
                            newTotalSize = newTotalSize - parseFloat(file.size);
                            newTotalFiles = newTotalFiles - 1;
                            continue;
                        }
                    }
                }

                //Dealing with the allowed files

                let ext = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
                let sizeMB = filesize(file.size, {round: 0});
                let Size = file.size;
                FileExtensions.push(ext);
                FileTypes.push(ext); //To check later what to do with that
                FileSizes.push(Size);
                filesAllowed.push(file);
                OriginalNames.push(file.name);

                //Defining the short name and size info for each file
                if (fileNameDisplayMaxCharactersfn) {
                    var ShortFileName;

                    if (file.name.length > fileNameDisplayMaxCharactersfn) {
                        let trimmed = file.name.substring(0, fileNameDisplayMaxCharactersfn);
                        ShortFileName = `${trimmed}(...).${ext}`;
                    } else {
                        ShortFileName = file.name;
                    }
                    let shortname = `${ShortFileName}(${sizeMB})`;

                    ShortNames.push(shortname);
                } else {
                    let shortname = `${file.name}(${sizeMB})`;
                    ShortNames.push(shortname);
                }
            }
        }

        //Sending the output of the function

        let Output = {
            AllowedFiles: filesAllowed, //The list of allowed files
            RejectedFiles: filesRejected, //The list of rejected files
            TotalAllowedSize: newTotalSize,
            TotalAllowedFiles: newTotalFiles,
            totalnewSize: newTotalFiles,
            totalSize: newTotalSize,
            totalFiles: newTotalFiles,
            ShortNames: ShortNames, //The list of shortNames (used to be displayed inside the select)
            FileExtensions: FileExtensions, //Extensions (pdf / xlsx...)
            Errors: fnError, //List of errors
            OriginalNames: OriginalNames, //List of original filenames
            FileNames: FileNames, //List of FileNames to be used for download or other
            FileSizes: FileSizes, //the size for each files
            FileTypes: FileTypes, //the types for each files
            Keywords: Keywords, //The list of keywords for each files
        };
        return Output;
    }
};

const LockedstylesWithProps = (props) => {
    return {};
};

const Lockedstyles = {
    FormElement: {
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        maxWidth: "2000px",
        justifyContent: "center",
    },
};

const styles = createUseStyles((theme) => ({
    FileUploader: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: "1 0 auto",
    },
    FileUploaderButton: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: "0 0 auto",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 0px 10px 0px",
        margin: "0px 10px 0px 0px",
    },
    UploaderIcon: theme.FileUploader.UploadButton,

    FormElement: theme.FormTextInput.FormElement,
    ProgressBar: {
        flex: "0 0 auto",
    },
    ClassExtendButton: {},

    FileUploaderField: {
        display: "flex",
        position: "relative",
        alignItems: "center",
    },
    BottomMessageContent: theme.FormTextInput.BottomMessageContent,

    BottomMessageContentError: (props) => {
        let ErrorStyleFinal = props.ErrorStyle ? (props.ErrorStyle.color ? props.ErrorStyle.color : null) : null;
        let ColorFinal = props.Colors ? (props.Colors.Error ? props.Colors.Error : null) : null;

        if (ErrorStyleFinal) {
            return {color: ErrorStyleFinal};
        } else if (ColorFinal) {
            return {color: ColorFinal};
        } else {
            return theme.FormTextInput.BottomMessageContentError;
        }
    },

    BottomMessageContentHidden: {
        visibility: "hidden",
    },
}));

/**
 * Create a File Uploader Component using select element and drag and drop
 * Connnects with the API
 */
const FileUploader = React.forwardRef(function FileUploader(props, ref) {
    const {
        name,
        label,
        InitialFiles,
        RenameFile,
        InitialFileName,
        APIUploadPath,
        APIDeletePath,
        UpdateFormFunction,
        AcceptedFileTypes,
        MaxFiles,
        MaxSizeMB,
        maxIndividualSizeMB,
        FileNameDisplayMaxCharacters,
        WithDragAndDrop,
        WithUploadButton,
        UploadButton,
        MessageDragAndDrop,
        BorderStyles,
        ErrorMessageStyle,
        SelectStyle,
        WithProgressBar,
        ProgressBarStyle,
        WithDeleteConfirmation,
        DownloadName,
        ReadOnly,
        ParentErrors,
        ...other
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /************************************* STATE ******************************************/
    const [uploadPercentage, setUploadPercentage] = React.useState(0); //Updating the percentage for the progressBar
    const [IsUploading, setIsUploading] = React.useState(false); //Setting is loading to switch the display between progress and select
    const [FilesOptionsForSelect, setFilesOptionsForSelect] = React.useState([]); //List of options for the MultiSelect Files
    const [InitialFilesOptionsForSelect, setInitialFilesOptionsForSelect] = React.useState([]); //List of iniitial Files for the MultiSelect Files
    const [CurrentFilesSelect, setCurrentFilesSelect] = React.useState([]); //Change the Values of the select
    const [CurrentTotalFileSize, setCurrentTotalFileSize] = React.useState(0); //Use to calculate the max remaining files allowed
    const [HasError, setHasError] = React.useState(false); //Set error if any
    const [ErrorMessage, setErrorMessage] = React.useState(null); //Display the error messages
    /************************************* STATE ******************************************/

    /************************************* REFERENCE ******************************************/
    const FileInputRef = React.useRef(null);
    /************************************* REFERENCE ******************************************/

    /************************************* INIT AND DEV ERRORS ******************************************/

    /************************************* EFFECTS ******************************************/
    //Loading inital files into the select files
    React.useEffect(() => {
        if (InitialFiles && Array.isArray(InitialFiles)) {
            //Renaming the files with the fieldName.
            InitializeSelect();
        }
    }, [InitialFiles]);

    //Updated
    React.useEffect(() => {
        if (ParentErrors) {
            setHasError(true);
            setErrorMessage(ParentErrors);
        } else {
        }
    }, [ParentErrors]);

    /************************************* EFFECTS ******************************************/

    /************************************* FUNCTIONS ******************************************/

    //CALCULATING THE INTIIAL FILE SIZES, ASSIGN A NAME AND LOAD THE SELECT WITH THE APPROPRIATE INFORMATION
    const InitializeSelect = async () => {
        //The structure of the select is the following :
        // let SelectOptions = {
        // 	value:  calculated,
        // 	label: calculated,
        // 	fileExt: to be deleted,
        // 	originalName: the name of the original local file,
        // 	serverPath: the path to the server after uploading,
        // 	downloadName: The desired name of the file, it will be indexed with _i based on the file number,
        // 	fileExtension: the file extension,
        // 	fileType: the file type,
        // 	fileSize: the file size,
        // 	keywords: the keywords for the files (needs to be the same for all files),
        // }

        let renameFinal = InitialFileName ? InitialFileName : name;
        let output = await LoadInitialFiles({
            fileList: InitialFiles,
            renameFile: RenameFile,
            rename: renameFinal,
            fileNameDisplayMaxCharacters: FileNameDisplayMaxCharacters,
        });
        var selOptions = [];
        var selCurValue = [];
        if (output) {
            for (var i = 0; i < output.ShortNames.length; i++) {
                let tempoutput = {
                    value: output.ShortNames[i],
                    label: output.ShortNames[i],
                    fileExt: output.FileExtensions[i],
                    originalName: output.OriginalNames[i],
                    serverPath: output.serverPaths[i],
                    downloadName: output.FileNames[i],
                    fileExtension: output.FileExtensions[i],
                    fileType: output.FileTypes[i],
                    fileSize: output.FileSizes[i],
                    keywords: output.Keywords[i],
                };
                selOptions.push(tempoutput);
                selCurValue.push(tempoutput.value);
            }
        }
        setFilesOptionsForSelect(selOptions);
        setInitialFilesOptionsForSelect(selCurValue);
        setCurrentFilesSelect(selCurValue);
        setCurrentTotalFileSize(output.totalSize);
    };

    //SIMULATE ON INPUT CLICK WHEN THE BUTTON IS CLICKED
    const FileUploadClick = () => {
        if (ReadOnly === false) {
            FileInputRef.current.click();
        }
    };

    //WHEN THE SELECT IS CHANGING (DELETE) -> UPDATE THE VALUES INTO THE FORM, AND DELETE THE FILES ON THE API IF REQUIRED
    const UpdateOptionsFromOutside = async (options, selectedVal) => {
        //1. Identify the file to delete from the server
        //Find the entries in the current FileOptions that do not exist anymore in the current select values

        var toDelete = [];
        var updatedSelectOptions = [];
        var currentSelected = [];
        var filesServerPath = [];

        if (selectedVal && Array.isArray(selectedVal) && selectedVal.length > 0) {
            FilesOptionsForSelect.forEach((elem) => {
                selectedVal.forEach((opt) => {
                    if (elem.value !== opt) {
                        //Was deleted
                        toDelete.push(elem.serverPath);
                    } else {
                        //Keep and update
                        updatedSelectOptions.push(elem);
                        currentSelected.push(elem.value);
                    }
                });
            });
        } else {
            //Everything was deleted
            toDelete = FilesOptionsForSelect.map((elem) => elem.serverPath);
        }

        filesServerPath = updatedSelectOptions.map((elem) => elem.serverPath);
        let originalNames = updatedSelectOptions.map((elem) => elem.originalName);
        setFilesOptionsForSelect(updatedSelectOptions);

        UpdateFormFunction(name, updatedSelectOptions);
        // UpdateFormFunction(name, filesServerPath, originalNames,fileNames,fileExtensions, fileTypes, fileSizes, keywords);
        // UpdateFormFunction(filesServerPath, name, originalNames);

        setInitialFilesOptionsForSelect(currentSelected);
        setCurrentFilesSelect(currentSelected);
        setCurrentTotalFileSize(currentSelected.length);

        //2. Delete on the server API
        if (APIDeletePath) {
            try {
                let res = await axios.post(APIDeletePath, {toDelete});
                //On API ANSWER DO SOMETHING -> here it would be to set the value of the input field...
                // console.log(res);
            } catch (error) {
                console.log(error);
            }
        }
    };

    //WHEN FILES ARE UPLOADED FROM LOCAL TO THE INPUT (THROUGH NATIVE UPLOAD OR DRAG AND DROP)
    //-> UPDATE THE SELECT, UPLOAD THE FILES TO THE API, UPDATE THE FORM VALUES
    const uploadfile = async (eventUploadButton, DragAndDropFiles, PersistOptionsState, persistSelectedState, persistTotalFilesState) => {
        //Extracting the Files from the input
        const formData = new FormData();
        var files;
        //Reseting previous errors
        setHasError(false);

        //Loading files from the draganddrop or from the upload button;
        if (DragAndDropFiles) {
            files = DragAndDropFiles;
        } else {
            files = eventUploadButton.target.files;
        }

        // //Checking the files parameters
        if (files) {
            let FilesList = [...files];

            //Checking all filters (maximum files allowed, max file size, file types)
            let TotFilesCurrent = CurrentFilesSelect ? CurrentFilesSelect.length : 0;
            let TotSizeCurrent = CurrentTotalFileSize ? CurrentTotalFileSize : 0;

            let FilteredFiles = CheckAllowedFilesList({
                currentFileList: FilesOptionsForSelect ? FilesOptionsForSelect.map((elem) => elem.originalName) : null,
                fileList: FilesList,
                maxSizeTotalMB: MaxSizeMB,
                maxFiles: MaxFiles,
                maxSizePerFileMB: maxIndividualSizeMB,
                typesAllowed: AcceptedFileTypes,
                initialSize: TotSizeCurrent,
                initialTotalFiles: TotFilesCurrent,
                fileNameDisplayMaxCharacters: FileNameDisplayMaxCharacters,
            });

            if (FilteredFiles && FilteredFiles.Errors && FilteredFiles.Errors.Error) {
                var ErrorMsfFormatted = [];
                // if (FilteredFiles.Errors.MaxFileError) ErrorMsfFormatted.push(FilteredFiles.Errors.MaxFile);
                // if (FilteredFiles.Errors.MaxSize) ErrorMsfFormatted.push(FilteredFiles.Errors.MaxSize);
                // if (FilteredFiles.Errors.FileType) ErrorMsfFormatted.push(FilteredFiles.Errors.FileType);
                // if (FilteredFiles.Errors.IndividualSize) ErrorMsfFormatted.push(FilteredFiles.Errors.IndividualSize);
                let AcceptedFilesFormatted = "";
                for (let j = 0; j < AcceptedFileTypes.length; j++) {
                    let FileType = AcceptedFileTypes[j];
                    AcceptedFilesFormatted = AcceptedFilesFormatted ? AcceptedFilesFormatted + FileType : FileType;
                }
                if (FilteredFiles.Errors.MaxFileError) ErrorMsfFormatted.push(`Max Files : ${MaxFiles}`);
                if (FilteredFiles.Errors.MaxSize) ErrorMsfFormatted.push(`Max total size : ${MaxSizeMB} MB`);
                if (FilteredFiles.Errors.FileType) ErrorMsfFormatted.push(`AcceptedFiles : ${AcceptedFilesFormatted}`);
                if (FilteredFiles.Errors.IndividualSize) ErrorMsfFormatted.push(`Max size : ${maxIndividualSizeMB} MB`);

                var finalFormattedMessage = "";
                if (ErrorMsfFormatted) {
                    ErrorMsfFormatted.forEach((elem) => {
                        finalFormattedMessage = finalFormattedMessage ? `${finalFormattedMessage} , ${elem}` : elem;
                    });
                }

                let ErrorMessage = `Some files were not uploaded. ${finalFormattedMessage}`;
                setHasError(true);
                setErrorMessage(ErrorMessage);
            }

            //REALLY UPLOADING TO THE SERVER

            if (APIUploadPath) {
                //Storing the files inside the FormDATA to send them to the server
                if (FilteredFiles && FilteredFiles.AllowedFiles && FilteredFiles.AllowedFiles.length > 0) {
                    // formData.append("file", FilteredFiles.AllowedFiles[0]);

                    for (var i = 0; i < FilteredFiles.AllowedFiles.length; i++) {
                        formData.append("file", FilteredFiles.AllowedFiles[i]);
                    }

                    const AxiosConfig = {
                        onUploadProgress: (progress) => {
                            const {total, loaded} = progress;
                            const totalSizeInMB = total / 1000000;
                            const loadedSizeInMB = loaded / 1000000;
                            const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
                            setUploadPercentage(uploadPercentage.toFixed(2));
                        },
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    };
                    //Showing the progress bar
                    setIsUploading(true);

                    axios
                        // .post("/api/tutorials/multertest/single", formData, AxiosConfig)
                        .post(APIUploadPath, formData, AxiosConfig)

                        .then((res) => {
                            setIsUploading(false);
                            //Updating the Selected files
                            if (res && res.data) {
                                //Preparing the full list of accepted files to render the select
                                var SelectFullDataInit = [];

                                for (var i = 0; i < FilteredFiles.AllowedFiles.length; i++) {
                                    let output = {
                                        value: FilteredFiles.ShortNames[i],
                                        label: FilteredFiles.ShortNames[i],
                                        fileExt: FilteredFiles.FileExtensions[i],
                                        originalName: FilteredFiles.AllowedFiles[i].name,
                                        serverPath: null, //Not known yet
                                        downloadName: FilteredFiles.FileNames[i],
                                        fileExtension: FilteredFiles.FileExtensions[i],
                                        fileType: FilteredFiles.FileTypes[i],
                                        fileSize: FilteredFiles.FileSizes[i],
                                        keywords: FilteredFiles.Keywords[i],
                                        isNew: true,
                                    };
                                    SelectFullDataInit.push(output);
                                }

                                //When receiving the server answer, adding the server path to the Sect data
                                var ServerFileList = [];
                                let ResFiles = res.data;

                                if (Array.isArray(ResFiles)) {
                                    ServerFileList = ResFiles;
                                } else {
                                    ServerFileList.push(ResFiles);
                                }
                            }

                            var SelectFullData = [];

                            //Adding the serverPath info
                            SelectFullDataInit.forEach((elem) => {
                                ServerFileList.forEach((selem) => {
                                    if (elem.originalName === selem.originalfile) {
                                        let output = {...elem, serverPath: selem.file};
                                        SelectFullData.push(output);
                                    }
                                });
                            });

                            let SelectedValuesArray = SelectFullData.map((elem) => elem.value); //Create an array of value
                            let NewSelectedValues = [...InitialFilesOptionsForSelect, ...SelectedValuesArray]; //Adding the Values to the selected
                            let newFilesOptionsForSelect = [...FilesOptionsForSelect, ...SelectFullData]; //Contains everything

                            setInitialFilesOptionsForSelect(NewSelectedValues);
                            setCurrentFilesSelect(NewSelectedValues);

                            setFilesOptionsForSelect(newFilesOptionsForSelect);
                            UpdateFormFunction(name, newFilesOptionsForSelect);

                            setCurrentTotalFileSize(FilteredFiles.totalnewSize);
                        })
                        .catch((error) => {
                            console.log(error);
                            if (error) {
                                setIsUploading(false);
                                setHasError(true);
                                setErrorMessage("Server error, the file was not uploaded");
                                // console.log(error);
                            }
                        });
                }
            }
        }
    };

    /************************************* RENDERING ******************************************/

    //SET THE COMPONENT INSIDE THE FORMWRAPPER BOX
    //if uploading -> show the progress bar
    //If Dragging ->  show drop me message
    //If not -> show the select Component :
    //-a placeholder saying drag files here
    //-b the selected files with icons and possibility to delete them

    //DEEP MERGING PROPS TO AVOID INIT ISSUES
    const MessageDragAndDropPropsFinal = {
        DragColor: MessageDragAndDrop.DragColor ? MessageDragAndDrop.DragColor : null,
        DragMessage: MessageDragAndDrop.DragMessage ? MessageDragAndDrop.DragMessage : "DRAG FILES HERE",
        DropColor: MessageDragAndDrop.DropColor ? MessageDragAndDrop.DropColor : null,
        DropMessage: MessageDragAndDrop.DropMessage ? MessageDragAndDrop.DropMessage : "DROP FILES HERE",
        NoDragMessage: MessageDragAndDrop.NoDragMessage ? MessageDragAndDrop.NoDragMessage : "Upload Files ...",
    };

    const BorderStylesPropsFinal = {
        EmptyColor: BorderStyles.EmptyColor ? BorderStyles.EmptyColor : null,
        DraggingColor: BorderStyles.DraggingColor ? BorderStyles.DraggingColor : null,
    };

    const SelectStylePropsFinal = {
        WithIcons: SelectStyle.WithIcons ? (SelectStyle.WithIcons === true ? true : false) : false,
        BackgroundColor: SelectStyle.BackgroundColor ? SelectStyle.BackgroundColor : null,
        Color: SelectStyle.Color ? SelectStyle.Color : null,
    };

    const ProgressBarStylePropsFinal = {
        EmptyColor: ProgressBarStyle.EmptyColor ? ProgressBarStyle.EmptyColor : null,
        ProgressColor: ProgressBarStyle.ProgressColor ? ProgressBarStyle.ProgressColor : null,
    };

    const FileUploaderSelectFinal = (FieldSetProps, IsDragging) => {
        const ProgressBarFinal = () => {
            if (WithProgressBar) {
                return (
                    <div
                        className={FieldSetProps.InputClass}
                        style={{
                            fontSize: FieldSetProps.FinalInputFonts.fontSize,
                            fontFamily: FieldSetProps.FinalInputFonts.fontFamily,
                            fontWeight: FieldSetProps.FinalInputFonts.fontWeight,
                            marginBottom: `${parseInt(FieldSetProps.InputMarginBottom) - 4}px`,
                            padding: "14px 13px 14px 13px",
                        }}
                    >
                        <ProgressLinear
                            Type="Determinate"
                            Progress={uploadPercentage}
                            Height="5px"
                            ColorEmpty={ProgressBarStylePropsFinal.EmptyColor}
                            ColorFilled={ProgressBarStylePropsFinal.ProgressColor}
                        />
                    </div>
                );
            } else {
                return (
                    <div
                        className={FieldSetProps.InputClass}
                        style={{
                            fontSize: FieldSetProps.FinalInputFonts.fontSize,
                            fontFamily: FieldSetProps.FinalInputFonts.fontFamily,
                            fontWeight: FieldSetProps.FinalInputFonts.fontWeight,
                            marginBottom: `${parseInt(FieldSetProps.InputMarginBottom) - 4}px`,
                            padding: "4px 0px 4px 13px",
                        }}
                    >
                        Uploading ...
                    </div>
                );
            }
        };

        if (IsUploading) {
            return ProgressBarFinal();
        } else {
            if (IsDragging) {
                return (
                    <div
                        className={FieldSetProps.InputClass}
                        style={{
                            fontSize: FieldSetProps.FinalInputFonts.fontSize,
                            fontFamily: FieldSetProps.FinalInputFonts.fontFamily,
                            fontWeight: FieldSetProps.FinalInputFonts.fontWeight,
                            marginBottom: `${parseInt(FieldSetProps.InputMarginBottom) - 4}px`,
                            padding: "4px 0px 4px 13px",
                        }}
                    >
                        {MessageDragAndDropPropsFinal.DropMessage}
                    </div>
                );
            } else {
                return (
                    <FileUploaderSelect
                        WithDeleteConfirmation={WithDeleteConfirmation}
                        FieldSetProps={FieldSetProps}
                        placeHolder={WithDragAndDrop === true ? MessageDragAndDropPropsFinal.DragMessage : MessageDragAndDropPropsFinal.NoDragMessage}
                        Colors={{Empty: MessageDragAndDropPropsFinal.DragColor, Filled: null, Error: null, Focused: null, Disabled: null}}
                        // SelectStyle={{WithIcons:SelectStylePropsFinal.WithIcons, BackgroundColor:SelectStylePropsFinal.BackgroundColor}}
                        SelectStyle={{WithIcons: SelectStylePropsFinal.WithIcons, BackgroundColor: SelectStylePropsFinal.BackgroundColor, Color: SelectStylePropsFinal.Color}}
                        ReadOnly={ReadOnly}
                    />
                );
            }
        }
    };
    // MessageDragAndDrop: {DragColor: null, DragMessage: "DRAG FILES HERE", DropColor : null, DropMessage: "DROP FILES HERE"},
    //The box form element, passsing is draggin from the drag and drop component
    const FormWrapperFinal = (IsDragging) => {
        return (
            <FormAnimatedWrapper
                label={label}
                name={name}
                UpdateOptionsFromOutside={UpdateOptionsFromOutside}
                options={FilesOptionsForSelect}
                curvalue={CurrentFilesSelect}
                initialvalue={InitialFilesOptionsForSelect}
                InputType="Select"
                InputRef={null}
                LabelScale="0.75"
                IsFocus={IsDragging ? true : false}
                HasValue={true}
                HasError={false}
                variant="outlined"
                disabled={false}
                required={false}
                Colors={{Empty: BorderStylesPropsFinal.EmptyColor, Filled: BorderStylesPropsFinal.EmptyColor, Error: null, Focused: BorderStylesPropsFinal.DraggingColor, Disabled: null}}
                FormInputComponent={(FieldSetProps) => FileUploaderSelectFinal(FieldSetProps, IsDragging)}
                {...other}
            />
        );
    };

    var BottomMessageClass = classes.BottomMessageContent;
    if (HasError) {
        BottomMessageClass = clsx(BottomMessageClass, classes.BottomMessageContentError);
    } else {
        BottomMessageClass = clsx(BottomMessageClass, classes.BottomMessageContentHidden);
    }
    const BottomMessageContent = () => {
        if (HasError) {
            return ErrorMessage;
        } else {
            return "No Message";
        }
    };

    //Creating the acceptable file list to uplaod Note that it only works with the upload button not the drag and drop
    var AcceptableFiles;
    if (AcceptedFileTypes) {
        for (var i = 0; i < AcceptedFileTypes.length; i++) {
            let elem = AcceptedFileTypes[i];
            if (elem === "*") {
                AcceptableFiles = null;
                break;
            }
            if (elem === "video") {
                AcceptableFiles = AcceptableFiles ? AcceptableFiles + ",video/*" : "video/*";
            }
            if (elem === "image") {
                AcceptableFiles = AcceptableFiles ? AcceptableFiles + ",image/*" : "image/*";
            }
            if (elem === "pdf") {
                AcceptableFiles = AcceptableFiles ? AcceptableFiles + ",.pdf/*" : ".pdf";
            }
        }
    }

    const FinalUploadButton = () => {
        var Icon;
        if (UploadButton.WithIcon === false) {
            Icon = null;
        } else {
            Icon = (
                <div style={{marginLeft: UploadButton.Message ? "2px" : "0px"}}>
                    <UploadCloud
                        IconSize={
                            UploadButton.IconStyle
                                ? UploadButton.IconStyle.IconSize
                                    ? UploadButton.IconStyle.IconSize
                                    : theme.FileUploader.UploadIcon.IconStyle
                                : theme.FileUploader.UploadIcon.IconStyle
                        }
                        SVGFillColor={
                            UploadButton.IconStyle
                                ? UploadButton.IconStyle.SVGFillColor
                                    ? UploadButton.IconStyle.SVGFillColor
                                    : theme.FileUploader.UploadIcon.SVGFillColor
                                : theme.FileUploader.UploadIcon.SVGFillColor
                        }
                        SVGFillHoverColor={
                            UploadButton.IconStyle
                                ? UploadButton.IconStyle.SVGFillHoverColor
                                    ? UploadButton.IconStyle.SVGFillHoverColor
                                    : theme.FileUploader.UploadIcon.SVGFillHoverColor
                                : theme.FileUploader.UploadIcon.SVGFillHoverColor
                        }
                    />
                </div>
            );
        }

        if (WithUploadButton === true) {
            return (
                <div className={classes.FileUploaderButton}>
                    <Button
                        type="button"
                        Color={UploadButton.TextColor ? UploadButton.TextColor : null}
                        Height={UploadButton.Height ? UploadButton.Height : theme.FileUploader.Button.height}
                        Width={UploadButton.Width ? UploadButton.Width : null}
                        Background={UploadButton.ButtonColor ? UploadButton.ButtonColor : null}
                        ClassExtendButton={UploadButton.ClassExtendButton ? UploadButton.ClassExtendButton : null}
                        style={{margin: "0px"}}
                        onClick={FileUploadClick}
                        disabled={ReadOnly}
                    >
                        {UploadButton.Message ? UploadButton.Message : null}
                        {Icon}
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    };

    const FinalDragAnDrop = () => {
        if (WithDragAndDrop === true) {
            return <FileUploaderDragAndDropContainer TransferFileToInput={uploadfile} FileComponent={FormWrapperFinal} />;
        } else {
            return FormWrapperFinal(false);
        }
    };

    if (AcceptableFiles) {
        return (
            <div className={classes.FileUploader}>
                <input ref={FileInputRef} multiple={true} name="files[]" type="file" onChange={uploadfile} style={{display: "none"}} accept={AcceptableFiles} />
                {FinalUploadButton()}

                <div className={classes.FormElement} style={{...Lockedstyles.FormElement}}>
                    <div className={classes.FileUploaderField}>{FinalDragAnDrop()}</div>

                    <div className={BottomMessageClass}>{BottomMessageContent()}</div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={classes.FileUploader}>
                <input ref={FileInputRef} multiple={true} name="files[]" type="file" onChange={uploadfile} style={{display: "none"}} accept="csv" />
                {FinalUploadButton()}

                <div className={classes.FormElement} style={{...Lockedstyles.FormElement}}>
                    <div className={classes.FileUploaderField}>{FinalDragAnDrop()}</div>

                    <div className={BottomMessageClass}>{BottomMessageContent()}</div>
                </div>
            </div>
        );
    }

    /************************************* RENDERING ******************************************/
});

FileUploader.defaultProps = {
    name: "File Uploader - noName",
    label: "File Uploader - noName",
    InitialFiles: null,
    RenameFile: null,
    InitialFileName: null,
    APIUploadPath: null,
    APIDeletePath: null,
    UpdateFormFunction: null,
    AcceptedFileTypes: ["pdf", "image", "video"],
    MaxFiles: 5,
    MaxSizeMB: 1.5,
    maxIndividualSizeMB: 1.5,
    FileNameDisplayMaxCharacters: 15,
    WithDragAndDrop: true,
    WithUploadButton: true,
    UploadButton: {TextColor: null, ButtonColor: null, Message: null, ClassExtendButton: null, WithIcon: true, IconStyle: {IconSize: null, SVGFillColor: null, SVGFillHoverColor: null}},
    MessageDragAndDrop: {DragColor: null, DragMessage: "DRAG FILES HERE", DropColor: null, DropMessage: "DROP FILES HERE", NoDragMessage: null},
    BorderStyles: {EmptyColor: null, DraggingColor: null},
    ErrorMessageStyle: {Color: null},
    SelectStyle: {WithIcons: true, BackgroundColor: null, Color: null},
    WithProgressBar: true,
    ProgressBarStyle: {EmptyColor: null, ProgressColor: null},
    WithDeleteConfirmation: false,
    DownloadName: null,
    ReadOnly: false,
};

FileUploader.propTypes = {
    /**
     * name : the input name
     */
    name: PropTypes.string,
    /**
     * label : the label
     */
    label: PropTypes.string,
    /**
     * InitialFiles : array of initial file path sent from the API
     * InitialFiles :  ["http://192.195.1.4/api/private/myimage.jpg","http://192.195.1.4/api/private/myimage.pdf"]
     */
    InitialFiles: PropTypes.array,
    /**
     * InitialFileName : the name to be given to the initial files : offer,
     * if no name is given then the full path will be used
     */
    InitialFileName: PropTypes.string,
    /**
     * RenameFile : Rename File - This will give this name to all files with an Index Number
     */
    RenameFile: PropTypes.string,
    /**
     * APIUploadPath : the path to the api server to uplaod the files
     * APIUploadPath="/api/tutorials/multertest/multiple"
     */
    APIUploadPath: PropTypes.string,
    /**
     * APIDeletePath : the path to the api server to delete the files
     * APIDeletePath="/api/tutorials/testdelete/multiple"
     */
    APIDeletePath: PropTypes.string,
    /**
     * UpdateFormFunction : the function to update the parent component with the selected files
     * const UpdateFormFunction = (filesPath, fieldName, originalNames) => {
     *  if (fieldName) {
     *    FilesUploaders[fieldName] = filesPath;
     *    setFilesUploaders(FilesUploaders);
     *  }
     * };
     */
    UpdateFormFunction: PropTypes.func,
    /**
     * AcceptedFileTypes : the types of files accepted. If null -> all files are allowed;
     * AcceptedFileTypes: ["pdf", "image", "video"],
     */
    AcceptedFileTypes: PropTypes.string,
    /**
     * MaxFiles : 5, the max number of files that can be uploaded (note it takes the initial files into account)
     */
    MaxFiles: PropTypes.string,
    /**
     * MaxSizeMB : 1.3, the total size allowed for all files in MB
     */
    MaxSizeMB: PropTypes.string,
    /**
     * maxIndividualSizeMB : 1.5, the maximum size of each individual files
     */
    maxIndividualSizeMB: PropTypes.string,
    /**
     * FileNameDisplayMaxCharacters : the maximum number of characters allowed to display the select.
     * The filename will be trimmed at that limit
     */
    FileNameDisplayMaxCharacters: PropTypes.string,
    /**
     * Activate drag and drop
     */
    WithDragAndDrop: PropTypes.bool,
    /**
     * Show the upload Button
     */
    WithUploadButton: PropTypes.bool,
    /**
     * UploadButton: {TextColor: null, ButtonColor: null, Message: null, ClassExtendButton: null, WithIcon: true,
     * IconStyle: {IconSize: null, SVGFillColor: null, SVGFillHoverColor: null}},
     */
    UploadButton: PropTypes.obj,
    /**
     * MessageDragAndDrop: {DragColor: null, DragMessage: "DRAG FILES HERE", DropColor: null,
     * DropMessage: "DROP FILES HERE", NoDragMessage: null},
     */
    MessageDragAndDrop: PropTypes.obj,
    /**
     * Style for the borders of the file uploader
     * BorderStyles: {EmptyColor: null, DraggingColor: null},
     */
    BorderStyles: PropTypes.obj,
    /**
     * ErrorMessageStyle: {Color: null}
     */
    ErrorMessageStyle: PropTypes.obj,
    /**
     * The style of the file inside the select
     * SelectStyle : {WithIcons: true, BackgroundColor: null, Color: null},
     */
    SelectStyle: PropTypes.obj,
    /**
     * Show or hide loader bar
     */
    WithProgressBar: PropTypes.bool,
    /**
     * ProgressBarStyle : {EmptyColor: null, ProgressColor: null}
     */
    ProgressBarStyle: PropTypes.obj,

    /**
     * WithDeleteConfirmation : true or false
     */
    WithDeleteConfirmation: PropTypes.any,

    /**
     * DownloadName : the name of the file for the download from the server
     */
    DownloadName: PropTypes.any,
    /**
     * ReadOnly : the name of the file for the download from the server
     */
    ReadOnly: PropTypes.any,
};

export default FileUploader;
