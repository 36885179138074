/* eslint-disable */
import React, {useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import FormDev from "../../../../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import ExpandableBox from "../../../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
/************** NEW COMPONENTS **************/

import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../../../artibulles-cis/CheckBox/CheckBox";
import {formatISO} from "date-fns";

/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
        color: "black",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "60px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 50px",
        boxSizing: "border-box",
        borderRadius: "3px",
        padding: "4px",
        border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "center",
        padding: "0px 0px 0px 10px",
        alignItems: "center",
        margin: "20px 0px 15px 0px",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "22px",
        margin: "0px",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [SubCompaniesTableComp, setSubCompaniesTableComp] = useState(null);

    const GenerateSubsidiaries = React.useCallback(() => {
        setSubCompaniesTableComp(<CustomerSubsidiariesTable Id={InitialFormValues.ApiID} />);
    }, [InitialFormValues.ApiID]);

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "ConditionalSelect1") {
            //We update the Options of the ConditionalSelect2
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
                if (elem.ref_ConditionalSelect1 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
            //We also need to reset the Conditional 2;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        //Calculated fields
        if (field === "UnitPriceVATExcluded") {
            let UnitPriceVATExcluded = value;
            let Quantity = FormValues.Quantity;
            let VATPercent = FormValues.VATPercent;

            if (UnitPriceVATExcluded && Quantity) {
                let CalcTotalVATExcluded = UnitPriceVATExcluded * Quantity;
                UpdateFormValues.CalcTotalVATExcluded = CalcTotalVATExcluded;
                if (VATPercent) {
                    let CalcVATAmount = (UnitPriceVATExcluded * Quantity * VATPercent) / 100;
                    UpdateFormValues.CalcVATAmount = CalcVATAmount;
                    UpdateFormValues.CalcTotalVATIncluded = CalcTotalVATExcluded + CalcVATAmount;
                }
            }
        }
        if (field === "Quantity") {
            let UnitPriceVATExcluded = FormValues.UnitPriceVATExcluded;
            let Quantity = value;
            let VATPercent = FormValues.VATPercent;

            if (UnitPriceVATExcluded && Quantity) {
                let CalcTotalVATExcluded = UnitPriceVATExcluded * Quantity;
                UpdateFormValues.CalcTotalVATExcluded = CalcTotalVATExcluded;
                if (VATPercent) {
                    let CalcVATAmount = (UnitPriceVATExcluded * Quantity * VATPercent) / 100;
                    UpdateFormValues.CalcVATAmount = CalcVATAmount;
                    UpdateFormValues.CalcTotalVATIncluded = CalcTotalVATExcluded + CalcVATAmount;
                }
            }
        }
        if (field === "VATPercent") {
            let VATPercent = value;
            let UnitPriceVATExcluded = FormValues.UnitPriceVATExcluded;
            let Quantity = FormValues.Quantity;

            if (VATPercent && UnitPriceVATExcluded && Quantity) {
                let CalcVATAmount = (UnitPriceVATExcluded * Quantity * VATPercent) / 100;

                UpdateFormValues.CalcVATAmount = CalcVATAmount;
                let CalcTotalVATExcluded = UnitPriceVATExcluded * Quantity;
                UpdateFormValues.CalcTotalVATIncluded = CalcTotalVATExcluded + CalcVATAmount;
            }
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (filesPath, fieldName, originalNames) => {
        if (fieldName) {
            let FullNamesPath = [];
            for (var i = 0; i < filesPath.length; i++) {
                FullNamesPath.push({serverPath: filesPath[i], originalName: originalNames[i]});
            }
            FilesUploaders[fieldName] = FullNamesPath;
            console.log("FilesUploaders", FilesUploaders);
            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    if (key === "TypeOfPurchase") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.TypeOfPurchase, {value: FormValues.TypeOfPurchase});
						if (Entry){
							Entry = Entry.label;
							FinalSubmittedValues = {...FinalSubmittedValues, ...{TypeOfPurchase: Entry}};
						}
                        
                    } else {
                        //Get the IntialValue
                        let InitialValue = InitialFormValues[key];
                        let Value = FormValues[key];
                        if (InitialValue !== Value && key) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                        }
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    var FinalFormComponent;

    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.FormWrapper}>
                <div className={classes.FormTitle_Title_Wrapper}>
                    <h1 className={classes.FormTitle_Title}>Purchase Invoice Item</h1>
                </div>

                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="SupplierRefCode"
                            InitialValue={InitialFormValues.SupplierRefCode ? InitialFormValues.SupplierRefCode : null}
                            FormValue={FormValues.SupplierRefCode ? FormValues.SupplierRefCode : null}
                            OutputValue={(values) => HandleUpdateFormValues("SupplierRefCode", values)}
                            Component="Input"
                            Variant="Outlined"
                            Type="text"
                            Label="Supplier code"
                            NoMessage={false}
                            meta={{error: FormErrors.SupplierRefCode, invalid: FormErrors.SupplierRefCode ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("SupplierRefCode", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"

                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="bank_name"
                            InitialValue={InitialFormValues.SupplierDescription ? InitialFormValues.SupplierDescription : null}
                            FormValue={FormValues.SupplierDescription ? FormValues.SupplierDescription : null}
                            OutputValue={(values) => HandleUpdateFormValues("SupplierDescription", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Supplier Description"
                            NoMessage={false}
                            meta={{error: FormErrors.SupplierDescription, invalid: FormErrors.SupplierDescription ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("SupplierDescription", error)}
                            multiline={true}
                            RowsToDisplay="2"
                            MaxRows="4"
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"

                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="bic"
                            InitialValue={InitialFormValues.InternalDescription ? InitialFormValues.InternalDescription : null}
                            FormValue={FormValues.InternalDescription ? FormValues.InternalDescription : null}
                            OutputValue={(values) => HandleUpdateFormValues("InternalDescription", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Internal Description"
                            NoMessage={false}
                            meta={{error: FormErrors.InternalDescription, invalid: FormErrors.InternalDescription ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("InternalDescription", error)}
                            multiline={true}
                            RowsToDisplay="2"
                            MaxRows="4"
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"

                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="TypeOfPurchase"
                            InitialValue={InitialFormValues.TypeOfPurchase ? InitialFormValues.TypeOfPurchase : null}
                            FormValue={FormValues.TypeOfPurchase ? FormValues.TypeOfPurchase : null}
                            OutputSelectedValues={(values) => handleSelectChange("TypeOfPurchase", values)}
                            SelectOptions={FieldOptions.TypeOfPurchase}
                            Component="Select"
                            Variant="Outlined"
                            Label="Type of Purchase"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_country, invalid: FormErrors.ref_country ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_country", error)}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortField="order"
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            Required={false}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 100px"}}>
                        <FormCompontent
                            Name="UnitPriceVATExcluded"
                            InitialValue={InitialFormValues.UnitPriceVATExcluded ? InitialFormValues.UnitPriceVATExcluded : null}
                            FormValue={FormValues.UnitPriceVATExcluded ? FormValues.UnitPriceVATExcluded : null}
                            OutputValue={(values) => HandleUpdateFormValues("UnitPriceVATExcluded", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Unit Price"
                            NoMessage={false}
                            meta={{error: FormErrors.UnitPriceVATExcluded, invalid: FormErrors.UnitPriceVATExcluded ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("UnitPriceVATExcluded", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="100px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 100px"}}>
                        <FormCompontent
                            Name="Quantity"
                            InitialValue={InitialFormValues.Quantity ? InitialFormValues.Quantity : null}
                            FormValue={FormValues.Quantity ? FormValues.Quantity : null}
                            OutputValue={(values) => HandleUpdateFormValues("Quantity", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Quantity"
                            NoMessage={false}
                            meta={{error: FormErrors.Quantity, invalid: FormErrors.Quantity ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("Quantity", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="100px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 100px"}}>
                        <FormCompontent
                            Name="Currency"
                            InitialValue={InitialFormValues.Currency ? InitialFormValues.Currency : null}
                            FormValue={FormValues.Currency ? FormValues.Currency : null}
                            OutputValue={(values) => HandleUpdateFormValues("Currency", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Currency"
                            NoMessage={false}
                            meta={{error: FormErrors.Currency, invalid: FormErrors.Currency ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("Currency", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="100px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 150px"}}>
                        <FormCompontent
                            Name="CurrencyExchangeRate"
                            InitialValue={InitialFormValues.CurrencyExchangeRate ? InitialFormValues.CurrencyExchangeRate : null}
                            FormValue={FormValues.CurrencyExchangeRate ? FormValues.CurrencyExchangeRate : null}
                            OutputValue={(values) => HandleUpdateFormValues("CurrencyExchangeRate", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Exchange Rate"
                            NoMessage={false}
                            meta={{error: FormErrors.CurrencyExchangeRate, invalid: FormErrors.CurrencyExchangeRate ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CurrencyExchangeRate", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="150px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 130px"}}>
                        <FormCompontent
                            Name="VATPercent"
                            InitialValue={InitialFormValues.VATPercent ? InitialFormValues.VATPercent : null}
                            FormValue={FormValues.VATPercent ? FormValues.VATPercent : null}
                            OutputValue={(values) => HandleUpdateFormValues("VATPercent", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="VATPercent"
                            NoMessage={false}
                            meta={{error: FormErrors.VATPercent, invalid: FormErrors.VATPercent ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("VATPercent", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="130px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="CalcTotalVATExcluded"
                            InitialValue={InitialFormValues.CalcTotalVATExcluded ? InitialFormValues.CalcTotalVATExcluded : null}
                            FormValue={FormValues.CalcTotalVATExcluded ? FormValues.CalcTotalVATExcluded : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATExcluded", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT Exc"
                            NoMessage={false}
                            meta={{error: FormErrors.CalcTotalVATExcluded, invalid: FormErrors.CalcTotalVATExcluded ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CalcTotalVATExcluded", error)}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="120px"
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 250px"}}>
                        <FormCompontent
                            Name="CalcVATAmount"
                            InitialValue={InitialFormValues.CalcVATAmount ? InitialFormValues.CalcVATAmount : null}
                            FormValue={FormValues.CalcVATAmount ? FormValues.CalcVATAmount : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcVATAmount", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT"
                            NoMessage={false}
                            meta={{error: FormErrors.CalcVATAmount, invalid: FormErrors.CalcVATAmount ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CalcVATAmount", error)}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="120px"
                            LabelMaxWidth="90px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="CalcTotalVATIncluded"
                            InitialValue={InitialFormValues.CalcTotalVATIncluded ? InitialFormValues.CalcTotalVATIncluded : null}
                            FormValue={FormValues.CalcTotalVATIncluded ? FormValues.CalcTotalVATIncluded : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATIncluded", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT Inc"
                            NoMessage={false}
                            meta={{error: FormErrors.CalcTotalVATIncluded, invalid: FormErrors.CalcTotalVATIncluded ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("CalcTotalVATIncluded", error)}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="120px"
                            LabelMaxWidth="130px"
                            // LabelMaxWidth="200px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>

                <FormDev
                    DevMode={DevMode}
                    FormValues={FormValues}
                    InitialFormValues={InitialFormValues}
                    FieldOptions={FieldOptions}
                    FieldSubOptionsFull={FieldSubOptionsFull}
                    FilesUploaders={FilesUploaders}
                    FormErrors={FormErrors}
                    NewFieldOptions={NewFieldOptions}
                    PristineDetails={PristineDetails}
                />
                {/* Button Bar */}
                <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                    {`${FormErrorMain ? FormErrorMain : null}`}
                </div>
                <div style={{height: "80px"}}>
                    <FormSubmitButtons
                        ButtonSaveAsDraft={ButtonSaveAsDraft}
                        ButtonSaveAndQuit={ButtonSaveAndQuit}
                        ButtonQuitNoSave={ButtonQuitNoSave}
                        ButtonDelete={ButtonDelete}
                        ButtonPublish={ButtonPublish}
                        ButtonReset={ButtonReset}
                        ButtonNext={ButtonNext}
                        ButtonPrevious={ButtonPrevious}
                        HandleSubmitForm={HandleSubmitForm}
                        Invalid={Invalid}
                        Pristine={Pristine}
                        DisableToolTip={DisableToolTip}
                        FormViewMode={FormViewMode}
                    />
                </div>

                {/* Button Bar */}
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <div className={classes.FormWrapper}>{FinalFormComponent}</div>;
});
export default Component;
