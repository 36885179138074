import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import CardModal from "@artibulles-cis/react/CardModal";
import Table from "../../../../../../../../artibulles-cis/TableNew/Table";
import CustomerEditAddress from "./CustomerEditAddress";
import {AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 10px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "200px",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const CustomerEditBankAccountTable = React.memo(function CustomerEditBankAccountTable(props) {
    const {CompanyId} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const ColomnsInput = [
        {
            width: "130px",
            caption: "Address Type",
            datafield: "ref_address_type",
            lookup: {data: "CompanyAddressTypes", searchField: "_id", returnField: "name_EN"},
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            // style: {fontSize: "20px", justifyContent: "center"},
        },
        {
            width: "130px",
            caption: "Country",
            datafield: "ref_country",
            lookup: {data: "Countries", searchField: "_id", returnField: "country_name_EN"},
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            // style: {fontSize: "20px", justifyContent: "center"},
        },
        {width: "300px", caption: "Region", datafield: "region_state", fixedCol: false, searchable: false, sortable: true, datatype: "text"},
        {width: "150px", caption: "City", datafield: "city", fixedCol: false, searchable: false, sortable: true, datatype: "text"},
        {width: "300px", caption: "Zip code", datafield: "zip_code", fixedCol: false, searchable: false, sortable: true, datatype: "text"},
        {width: "250px", caption: "Line 1 ", datafield: "street_line1", fixedCol: false, searchable: false, sortable: true, datatype: "text"},
        {width: "80px", caption: "Default", datafield: "is_defaultAddress", fixedCol: false, searchable: false, sortable: true, datatype: "boolean"},
    ];

    const HandleEditRow = (arg) => {
        console.log("arg", arg);
        if (arg) {
            let id = arg._id;

            setShowViewCard(true);
            setViewCardId(id);
        }
    };
    const HandleDeleteRow = (arg) => {
        let Id = arg._id;
        console.log("HandleDeleteRow", arg);
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/companies/company/address/${Id}`, type: "delete"};
            setLoading(true);
            setAPICallType("deleteone");

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };
    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };
    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 30,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: false,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "Edit",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
            {
                Standard: true,
                Name: "Delete",
                ActiveCondition: null,
                Position: 2,
                OnClick: HandleDeleteRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
    };
    const TotalRow = {
        Show: false,
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [CreateNewOnly, setCreateNewOnly] = useState(false);

    const [APICallType, setAPICallType] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;

                    if (Init === false) {
                        if (Data.CompanyAddresses && Array.isArray(Data.CompanyAddresses) && Data.CompanyAddresses.length > 0) {
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        } else {
                            //There is no bank account, we display a add button

                            setCreateNewOnly(true);
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "deleteone") {
                        toast.success(`Entry deleted`, {autoClose: true});
                        setInit(false);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <Table
                LocalData={Ref_LocaleData.current}
                // URLRelative={FinalAPIUrlRelative}
                // URLRelative="arnaud"
                MainDataSource="CompanyAddresses"
                ColomnsInput={ColomnsInput}
                TableDimensions={TableDimensions}
                TableOptions={TableOptions}
                TotalRow={TotalRow}
                DevMode={false}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current, Init]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (Init === false) {
            const ApiCall = {url: `${ProdURL}/api/companies/company/companyaddresses/${CompanyId}`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true && CreateNewOnly === false) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        
        setCreateNewOnly(false);
        setTimeout(() => {
			setInit(false);
			
		}, 50);
    };

    const HanldeCreateNewAddress = () => {
        setShowViewCard(true);
        setViewCardId("new");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1200px"
                CardHeight="800px"
                // CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <CustomerEditAddress CompanyId={CompanyId} BankAccountId={ViewCardId} CloseCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    var FinalTableComponent;

    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = (
                <div>
                    <Button Width="230px" Height="35px" style={{margin: "0px 0px", justifyContent: "space-between"}} onClick={HanldeCreateNewAddress}>
                        <AddCircle />
                        Add Address
                    </Button>

                    <div className={classes.TableWrapper}>{TableComponent}</div>
                </div>
            );
        } else {
            FinalTableComponent = (
                <Button Width="250px" onClick={HanldeCreateNewAddress}>
                    Add Address
                </Button>
            );
        }
    } else {
        FinalTableComponent = "There is no data";
    }

    return (
        <div>
            {ModalCardView}
            <div className={classes.DailytButtonBar}>
                <div className={classes.FormField} style={{flex: "1 1 250px", maxWidth: "250px", width: "250px"}}></div>
            </div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </div>
    );

    /***************** RENDER ******************/
});

export default CustomerEditBankAccountTable;
