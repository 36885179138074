/* eslint-disable */
import React, {useState, useEffect} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormDev from "../../../../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import ExpandableBox from "../../../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
import Button from "@artibulles-cis/react/Button";
/************** NEW COMPONENTS **************/

import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../../../artibulles-cis/CheckBox/CheckBox";
import FileUploader from "../../../../../../../../artibulles-cis/FileUploader/FileUploader";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";

import {AddCircle, PDF, Contract, AvatarV2, Login} from "@artibulles-cis/react-icons";
import {formatISO, format, parseISO, differenceInCalendarDays, startOfDay} from "date-fns";

import PdfViewer from "./TransactionEditFormParts/PdfViewer";
import TransactionEditDetailsTabNav from "./TransactionEditFormParts/TransactionEditDetailsTabNav";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        overflow: "hidden",
        textAlign: "left",
    },
    FormFlexHorizontal: {
        display: "flex",
        height: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
    },
    FormPDFViewer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
    },
    FormMainScroll: {
        flex: "1 0 1400px",
        height: "100%",
        // overflowY: "auto",
        boxSizing: "border-box",
        // padding: "0px 10px",
        border: "1px solid grey",
        maxWidth: "1400px",
    },

    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        height: "35px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "0 0 1400px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightInfo: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 45px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    InvoiceDetailsSection: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        // maxWidth: "1400px",
        // padding: "10px 10px 0px 10px",
    },
    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormTopFlex: {
        display: "flex",
        width: "100%",

        boxSizing: "border-box",
        position: "relative",
        // padding: "0px 10px 0px 10px",
    },
    FormTopLeft: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        borderRight: "1px solid grey",
        borderBottom: "1px solid grey",
        padding: "0px 0px 0px 10px",
    },
    FormTopRight: {
        flex: "0 0 350px",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        justifyContent: "flex-end",
        borderBottom: "1px solid grey",
        padding: "0px 10px 0px 0px",
    },
    PDFViewerIcon: {
        margin: "0px",
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "flex-end",
    },
    InvoiceSummaryBox: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        // justifyContent: "flex-end",
        boxSizing: "border-box",
        margin: "5px 0px 5px 10px",
        padding: "5px 5px",

        borderRadius: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
    ClassExtendsContentOpened: {
        padding: "0px 0px 0px 10px",
    },

    ButtonArrowStart: {
        background: "none",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowStart_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
    },
    ButtonArrowMiddle: {
        background: "none",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "16px solid transparent",
            borderBottom: "16px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowMiddle_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },

    ButtonArrowEnd: {
        background: "transparent",
        padding: "0px 16px",

        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowEnd_Active: {
        background: "rgb(66, 165, 245)",
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },
    //ARROWS
    SupplierAddress: {
        display: "flex",
        flexDirection: "column",
    },
    SupplierAddressLine: {
        display: "flex",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 0px 2px 0px",
    },
    InvoiceSummary: {
        display: "flex",
        // padding: "0px 0px 0px 5px",
        width: "250px",
        alignSelf: "flex-end",
        flexDirection: "column",
        // justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "0 0 120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    UploadButton: {
        fontSize: "0.8rem",
        padding: "0px",
        margin: "0px",
    },
    TitleButtons: {
        fontSize: "0.9rem",
        padding: "0px",
        margin: "0px",
    },
    SummaryInvoiceError: {
        color: "orange",
        fontWeight: "600",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [ShowInvoiceResultsReconciliation, setShowInvoiceResultsReconciliation] = useState(false); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [ShowViewPDF, setShowViewPDF] = useState(false); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [PDFFileURL, setPDFFileURL] = useState(null);
    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    useEffect(() => {
        if (FilesUploaders && FilesUploaders.Statements_File_Path && FilesUploaders.Statements_File_Path.length > 0) {
            let FileUrl;
            if (FilesUploaders.Statements_File_Path && FilesUploaders.Statements_File_Path.length > 0) {
                FileUrl = FilesUploaders.Statements_File_Path[0].serverPath;
            }
            if (FileUrl) {
                setPDFFileURL(FileUrl);
            } else {
                setPDFFileURL(null);
            }
        } else {
            setPDFFileURL(null);
        }
    }, [FilesUploaders]);

    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "ConditionalSelect1") {
            //We update the Options of the ConditionalSelect2
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
                if (elem.ref_ConditionalSelect1 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
            //We also need to reset the Conditional 2;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        //Conditional Fields
        if (field === "is_VAT_Intracomm") {
            if (value === true) {
                //we Delete the values for the VAT amount  and the total without vat
                UpdateFormValues.vat_amount = null;
                UpdateFormValues.amount_without_vat = null;
            }
        }

        if (field === "accounting_Justification_needed") {
            if (value === false) {
                //We clear the justification provided
                //we clear the justification date

                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = null;
            } else {
                UpdateFormValues.accounting_Justification_Provided = false;
                UpdateFormValues.accounting_Justification_Provided_Date = formatISO(new Date(Date.now()));
            }
        }

        if (field === "accounting_Justification_Provided") {
            if (value === false) {
                UpdateFormValues.accounting_Justification_Provided_Date = null;
            } else {
                UpdateFormValues.accounting_Justification_Provided_Date = formatISO(new Date(Date.now()));
            }
        }

        if (field === "accouting_communication") {
            let AccountingNoteBasic = "Ref : " + FormValues.Calc_accounting_ref_id + "\n";
            let AccountingNote = "";
            if (value) {
                AccountingNote = AccountingNoteBasic + value;
            } else {
                AccountingNote = AccountingNoteBasic;
            }

            UpdateFormValues.accounting_note = AccountingNote;
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (fieldName, filedetails) => {
        if (fieldName) {
            let FullFilesUploader = [];
            for (var i = 0; i < filedetails.length; i++) {
                FullFilesUploader.push({
                    serverPath: filedetails[i].serverPath,
                    originalName: filedetails[i].originalName,
                    downloadName: filedetails[i].downloadName,
                    fileExtension: filedetails[i].fileExtension,
                    fileType: filedetails[i].fileType,
                    fileSize: filedetails[i].fileSize,
                    keywords: filedetails[i].keywords,
                });
            }
            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function" || submitType === "SaveDraft") {
            
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    //Get the IntialValue
                    let InitialValue = InitialFormValues[key];
                    let Value = FormValues[key];
                    if (InitialValue !== Value) {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/

    const HandleViewPDF = () => {
        // console.log("HandleViewPDF");
        setShowViewPDF(!ShowViewPDF);
    };
    const HandleTransactionChangedParent = () => {
        
        HandleSubmitForm("SaveDraft");
    };
    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let FormTitle, TransactionTabNavComponent, TransactionSummaryComponent, InvoiceFirstSaveComponent;

    InvoiceFirstSaveComponent = null;
    let DraftClass, InvoicedClass, PaidClass, ReconciledClass, AccountedClass;
    let ActionButton1Component, ActionButton2Component, ActionButton3Component;
    if (InitialFormValues.status_invoice === "Draft") {
        //Draft
        DraftClass = clsx(classes.ButtonArrowStart, classes.ButtonArrowStart_Active);
        InvoicedClass = clsx(classes.ButtonArrowMiddle);
        PaidClass = clsx(classes.ButtonArrowMiddle);
        ReconciledClass = clsx(classes.ButtonArrowMiddle);
        AccountedClass = clsx(classes.ButtonArrowEnd);
        ActionButton1Component = (
            <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" disabled={Pristine} onClick={HandleSaveDraft}>
                Save Draft
            </Button>
        );
        ActionButton2Component = (
            <Button
                ClassExtendButton={classes.TitleButtons}
                Width="90px"
                Height="30px"
                // disabled={Invalid || Pristine}
                onClick={HandlePost}
                // disabled={DisablePost}
            >
                Post
            </Button>
        );
    } else if (InitialFormValues.status_invoice === "Invoiced") {
        //Invoiced
        DraftClass = clsx(classes.ButtonArrowStart);
        InvoicedClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
        PaidClass = clsx(classes.ButtonArrowMiddle);
        ReconciledClass = clsx(classes.ButtonArrowMiddle);
        AccountedClass = clsx(classes.ButtonArrowEnd);

        if (InitialFormValues.PaymentMethod === "01") {
            if (InitialFormValues.ManualBankPaymentActionsStatus === "Create Payment") {
                ActionButton1Component = (
                    <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleRevertToDraft}>
                        Revert to Draft
                    </Button>
                );
                ActionButton2Component = (
                    <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" Width="80px" onClick={HandleGeneratePayment}>
                        Pay
                    </Button>
                );
            }
        } else {
            ActionButton1Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" onClick={HandleRevertToDraft}>
                    Revert to Draft
                </Button>
            );
        }
    } else if (InitialFormValues.status_invoice === "Paid") {
        //Paid
        DraftClass = clsx(classes.ButtonArrowStart);
        InvoicedClass = clsx(classes.ButtonArrowMiddle);
        PaidClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
        ReconciledClass = clsx(classes.ButtonArrowMiddle);
        AccountedClass = clsx(classes.ButtonArrowEnd);
        ActionButton1Component = (
            <Button ClassExtendButton={classes.TitleButtons} Width="80px" Height="30px" onClick={HandleAdminEdit}>
                Admin
            </Button>
        );
        ActionButton2Component = (
            <Button ClassExtendButton={classes.TitleButtons} Width="80px" Height="30px">
                Reconcile
            </Button>
        );
        if (AdminMode === true) {
            ActionButton3Component = (
                <Button ClassExtendButton={classes.TitleButtons} Width="100px" Height="30px" onClick={HandleSaveDraft}>
                    Admin Save
                </Button>
            );
        }
    } else if (InitialFormValues.status_invoice === "Reconciled") {
        //Reconciled
        DraftClass = clsx(classes.ButtonArrowStart);
        InvoicedClass = clsx(classes.ButtonArrowMiddle);
        PaidClass = clsx(classes.ButtonArrowMiddle);
        ReconciledClass = clsx(classes.ButtonArrowMiddle, classes.ButtonArrowMiddle_Active);
        AccountedClass = clsx(classes.ButtonArrowEnd);
        ActionButton1Component = (
            <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" Width="80px">
                Unreconile
            </Button>
        );
        ActionButton2Component = (
            <Button ClassExtendButton={classes.TitleButtons} Width="120px" Height="30px" Width="80px">
                Send to Accountant
            </Button>
        );
    } else if (InitialFormValues.status_invoice === "Accounted") {
        //Accounted
        DraftClass = clsx(classes.ButtonArrowStart);
        InvoicedClass = clsx(classes.ButtonArrowMiddle);
        PaidClass = clsx(classes.ButtonArrowMiddle);
        ReconciledClass = clsx(classes.ButtonArrowMiddle);
        AccountedClass = clsx(classes.ButtonArrowEnd, classes.ButtonArrowEnd_Active);
        ActionButton1Component = <Button Height="30px">Change Accounting Status</Button>;
    }

    FormTitle = (
        <div className={classes.FormTitle_Wrapper}>
            <div className={classes.FormTitleMain}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Logo_Wrapper}>
                        <Contract IconSize="30px" SVGFillColor="white" />
                    </div>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>{InitialFormValues.calc_RefTransaction}</h1>
                        <div className={classes.ActionButton}>{ActionButton1Component}</div>
                        <div className={classes.ActionButton}>{ActionButton2Component}</div>
                        <div className={classes.ActionButton}>{ActionButton3Component}</div>
                    </div>
                </div>
                <div className={classes.FormTitleRightStatus}>
                    <div className={DraftClass}>Draft</div>
                    <div className={InvoicedClass}>Invoiced</div>
                    <div className={PaidClass}>Paid</div>
                    <div className={ReconciledClass}>Reconciled</div>
                    <div className={AccountedClass}>Accounted</div>
                </div>
            </div>
            <div className={classes.FormTitleRightInfo}>
                <div className={classes.FormTitleRightStatus_RecordInfo}>
                    <div className={classes.FormTitleRightStatus_RecordInfo_Line}>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Title}>CreatedAt :</div>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Value}>{InitialFormValues.createdAt} </div>
                    </div>
                    <div className={classes.FormTitleRightStatus_RecordInfo_Line}>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Title}>LastUpdated :</div>
                        <div className={classes.FormTitleRightStatus_RecordInfo_Value}>{InitialFormValues.updatedAt} </div>
                    </div>
                </div>
            </div>
        </div>
    );

    TransactionSummaryComponent = (
        <div className={classes.InvoiceSummary}>
            <div className={classes.InvoiceSummary_Line}>
                <div className={classes.InvoiceSummary_Line_Title} style={{fontSize: "1rem", fontWeight: "600"}}>
                    Date:
                </div>
                <div className={classes.InvoiceSummary_Line_Value} style={{fontSize: "1rem", fontWeight: "600"}}>
                    {InitialFormValues.transaction_date_Iso ? format(parseISO(InitialFormValues.transaction_date_Iso), "dd-MMM-yyyy") : null}
                </div>
            </div>
            <div className={classes.InvoiceSummary_Line}>
                <div className={classes.InvoiceSummary_Line_Title} style={{fontSize: "1rem", fontWeight: "600"}}>
                    Amount:
                </div>
                <div className={classes.InvoiceSummary_Line_Value} style={{fontSize: "1rem", fontWeight: "600"}}>
                    {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(InitialFormValues.banking_amount_transaction)}
                </div>
            </div>
        </div>
    );

    TransactionTabNavComponent = (
        <div className={classes.InvoiceDetailsSection}>
            <div className={classes.InvoiceDetailsTabNavWrapper}>
                <TransactionEditDetailsTabNav
                    TransactionID={InitialFormValues.ApiID}
                    HandleTransactionChangedParent={HandleTransactionChangedParent}
                    FormViewMode={FormViewMode}
                    FormValues={FormValues}
                />
            </div>
        </div>
    );

    let FinalFormComponent;
    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.FormWrapper}>
                {FormTitle}
                <div className={classes.FormFlexHorizontal}>
                    <div className={classes.FormMainScroll}>
                        <PerfectScrollBarWrapper Active={true}>
                            <div className={classes.FormTopFlex}>
                                <div className={classes.FormTopLeft}>
                                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                            <FormCompontent
                                                Name="transaction_source"
                                                InitialValue={InitialFormValues.transaction_source ? InitialFormValues.transaction_source : null}
                                                FormValue={FormValues.transaction_source ? FormValues.transaction_source : null}
                                                OutputValue={(values) => HandleUpdateFormValues("transaction_source", values)}
                                                // OutputError={(error) => HandleUpdateErrors("banking_effective_date_Iso", error)}
                                                // meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                                Component="Input"
                                                Variant="Outlined"
                                                NoMessage={true}
                                                Label="Source"
                                                DisplayMessageIn="Popup"
                                                ReadOnly={true}
                                                FieldMaxWidth="120px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                            />
                                        </div>
                                        <div className={classes.FormField} style={{flex: "0 0 300px", display: `${FormValues.myCompany_bankAccount_number ? null : "none"}`}}>
                                            <FormCompontent
                                                Name="myCompany_bankAccount_number"
                                                InitialValue={InitialFormValues.myCompany_bankAccount_number ? InitialFormValues.myCompany_bankAccount_number : null}
                                                FormValue={FormValues.myCompany_bankAccount_number ? FormValues.myCompany_bankAccount_number : 0}
                                                OutputValue={(values) => HandleUpdateFormValues("myCompany_bankAccount_number", values)}
                                                Component="Input"
                                                Variant="Outlined"
                                                Label="Bank Account Number"
                                                NoMessage={false}
                                                // meta={{error: FormErrors.task_name, invalid: FormErrors.task_name ? true : false}}
                                                // OutputError={(error) => HandleUpdateErrors("task_name", error)}
                                                // LegendOffsetHeight="0"
                                                // LegendOffsetWidth="0"

                                                FieldMaxWidth="200px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                                ReadOnly={true}
                                            />
                                        </div>
                                        <div className={classes.FormField} style={{flex: "0 0 300px", display: `${FormValues.credit_card_number ? null : "none"}`}}>
                                            <FormCompontent
                                                Name="credit_card_number"
                                                InitialValue={InitialFormValues.credit_card_number ? InitialFormValues.credit_card_number : null}
                                                FormValue={FormValues.credit_card_number ? FormValues.credit_card_number : 0}
                                                OutputValue={(values) => HandleUpdateFormValues("credit_card_number", values)}
                                                Component="Input"
                                                Variant="Outlined"
                                                Label="Credit Card Number"
                                                NoMessage={false}
                                                // meta={{error: FormErrors.task_name, invalid: FormErrors.task_name ? true : false}}
                                                // OutputError={(error) => HandleUpdateErrors("task_name", error)}
                                                // LegendOffsetHeight="0"
                                                // LegendOffsetWidth="0"
                                                FieldMaxWidth="200px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                                ReadOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FormMultilineFlex} style={{display: `${FormValues.counterparty_bankAccount_number ? null : "none"}`, justifyContent: "flex-start"}}>
                                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                            <FormCompontent
                                                Name="counterparty_bankAccount_number"
                                                InitialValue={InitialFormValues.counterparty_bankAccount_number ? InitialFormValues.counterparty_bankAccount_number : null}
                                                FormValue={FormValues.counterparty_bankAccount_number ? FormValues.counterparty_bankAccount_number : null}
                                                OutputValue={(values) => HandleUpdateFormValues("counterparty_bankAccount_number", values)}
                                                OutputError={(error) => HandleUpdateErrors("counterparty_bankAccount_number", error)}
                                                meta={{error: FormErrors.counterparty_bankAccount_number, invalid: FormErrors.counterparty_bankAccount_number ? true : false}}
                                                Component="Input"
                                                Variant="Outlined"
                                                NoMessage={true}
                                                Label="Counterparty Account Number"
                                                FieldMaxWidth="200px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                                ReadOnly={true}
                                            />
                                        </div>
                                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                            <FormCompontent
                                                Name="counterparty_bankAccount_bic"
                                                InitialValue={InitialFormValues.counterparty_bankAccount_bic ? InitialFormValues.counterparty_bankAccount_bic : null}
                                                FormValue={FormValues.counterparty_bankAccount_bic ? FormValues.counterparty_bankAccount_bic : null}
                                                OutputValue={(values) => HandleUpdateFormValues("counterparty_bankAccount_bic", values)}
                                                // OutputError={(error) => HandleUpdateErrors("transaction_date_Iso", error)}
                                                // meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                                Component="Input"
                                                Variant="Outlined"
                                                NoMessage={true}
                                                Label="BIC"
                                                DisplayMessageIn="Popup"
                                                FieldMaxWidth="200px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                                ReadOnly={true}
                                            />
                                        </div>
                                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                            <FormCompontent
                                                Name="counterparty_bankAccount_name"
                                                InitialValue={InitialFormValues.counterparty_bankAccount_name ? InitialFormValues.counterparty_bankAccount_name : null}
                                                FormValue={FormValues.counterparty_bankAccount_name ? FormValues.counterparty_bankAccount_name : null}
                                                OutputValue={(values) => HandleUpdateFormValues("counterparty_bankAccount_name", values)}
                                                // OutputError={(error) => HandleUpdateErrors("transaction_date_Iso", error)}
                                                // meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                                Component="Input"
                                                Variant="Outlined"
                                                NoMessage={true}
                                                Label="Owner"
                                                DisplayMessageIn="Popup"
                                                FieldMaxWidth="200px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                                ReadOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FormMultilineFlex} style={{display: `${FormValues.credit_card_number ? null : "none"}`, justifyContent: "flex-start"}}>
                                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                            <FormCompontent
                                                Name="location"
                                                InitialValue={InitialFormValues.location ? InitialFormValues.location : null}
                                                FormValue={FormValues.location ? FormValues.location : null}
                                                OutputValue={(values) => HandleUpdateFormValues("location", values)}
                                                // OutputError={(error) => HandleUpdateErrors("transaction_date_Iso", error)}
                                                // meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                                Component="Input"
                                                Variant="OutlinedLabel"
                                                NoMessage={true}
                                                Label="location"
                                                DisplayMessageIn="Popup"
                                                ReadOnly={true}
                                            />
                                        </div>
                                        <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                            <FormCompontent
                                                Name="merchant"
                                                InitialValue={InitialFormValues.merchant ? InitialFormValues.merchant : null}
                                                FormValue={FormValues.merchant ? FormValues.merchant : null}
                                                OutputValue={(values) => HandleUpdateFormValues("merchant", values)}
                                                // OutputError={(error) => HandleUpdateErrors("transaction_date_Iso", error)}
                                                // meta={{error: FormErrors.mask_date, invalid: FormErrors.mask_date ? true : false}}
                                                Component="Input"
                                                Variant="OutlinedLabel"
                                                NoMessage={true}
                                                Label="merchant"
                                                DisplayMessageIn="Popup"
                                                ReadOnly={true}
                                            />
                                        </div>
                                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                            <FormCompontent
                                                Name="communication"
                                                InitialValue={InitialFormValues.communication ? InitialFormValues.communication : null}
                                                FormValue={FormValues.communication ? FormValues.communication : null}
                                                OutputValue={(values) => HandleUpdateFormValues("communication", values)}
                                                OutputError={(error) => HandleUpdateErrors("communication", error)}
                                                meta={{error: FormErrors.communication, invalid: FormErrors.communication ? true : false}}
                                                Component="Input"
                                                Variant="Outlined"
                                                NoMessage={true}
                                                Label="Communication"
                                                DisplayMessageIn="Popup"
                                                ReadOnly={true}
                                                ShowErrorInReadOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FormMultilineFlex} style={{display: `${FormValues.myCompany_bankAccount_number ? "flex" : "none"}`}}>
                                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                            <FormCompontent
                                                Name="communication"
                                                InitialValue={InitialFormValues.communication ? InitialFormValues.communication : null}
                                                FormValue={FormValues.communication ? FormValues.communication : null}
                                                OutputValue={(values) => HandleUpdateFormValues("communication", values)}
                                                OutputError={(error) => HandleUpdateErrors("communication", error)}
                                                meta={{error: FormErrors.communication, invalid: FormErrors.communication ? true : false}}
                                                Component="Input"
                                                Variant="Outlined"
                                                NoMessage={true}
                                                Label="Communication"
                                                DisplayMessageIn="Popup"
                                                multiline={true}
                                                RowsToDisplay="2"
                                                MaxRows="4"
                                                // FieldMaxWidth="200px"
                                                LabelMaxWidth="160px"
                                                LabelAlign="Left"
                                                ReadOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.FormTopRight}>
                                    <div className={classes.PDFViewerIcon} onClick={HandleViewPDF} style={{display: `${PDFFileURL ? "flex" : "none"}`}}>
                                        <div style={{display: `${PDFFileURL ? "flex" : "none"}`}}>
                                            <PDF />
                                        </div>
                                    </div>

                                    <div className={classes.InvoiceSummaryBox}>
                                        <div className={classes.InvoiceSummary_Line} style={{borderBottom: "1px solid grey", fontSize: "0.85rem"}}>
                                            Transaction Summary
                                        </div>
                                        {TransactionSummaryComponent}
                                    </div>
                                </div>
                            </div>
                            {TransactionTabNavComponent}
                            <FormDev
                                DevMode={DevMode}
                                FormValues={FormValues}
                                InitialFormValues={InitialFormValues}
                                FieldOptions={FieldOptions}
                                FieldSubOptionsFull={FieldSubOptionsFull}
                                FilesUploaders={FilesUploaders}
                                FormErrors={FormErrors}
                                NewFieldOptions={NewFieldOptions}
                                PristineDetails={PristineDetails}
                            />
                            {/* Button Bar */}
                            <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                                {`${FormErrorMain ? FormErrorMain : null}`}
                            </div>

                            {/* Button Bar */}
                        </PerfectScrollBarWrapper>
                    </div>
                    <div className={classes.FormPDFViewer} style={{display: `${ShowViewPDF === true ? "flex" : "none"}`}}>
                        <PdfViewer FileUrl={PDFFileURL} />
                    </div>
                </div>
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <div className={classes.FormWrapper}>{FinalFormComponent}</div>;
});
export default Component;
