import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import PopUpMenuWithButton from "../../../../../../../../../../artibulles-cis/PopUpMenuWithButton/PopUpMenuWithButton";
import LocalCircularLoaderIOS from "../../../../../../../../../../artibulles-cis/LocalCircularLoaderIOS/LocalCircularLoaderIOS";
import clsx from "clsx";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Card: {
        flex: "1 1 auto",
        display: "flex",
        maxWidth: "600px",
        minWidth: "400px",
        height: "270px",
        margin: "5px 5px",
        flexDirection: "column",
        borderRadius: "3px",
        color: "black",
        boxSizing: "border-box",
        // background: "linear-gradient(0deg, rgba(15, 14, 14, 0.9) 0%, rgba(49, 49, 49, 0.9) 100%)",
        boxShadow: "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        fontWeight: "400",
        fontSize: "0.8rem",
    },
    CardHeader: {
        flex: "0 1 auto",
        display: "flex",
        padding: "2px 5px",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        borderBottom: "1px solid grey",
    },
    CardHeader_Left: {
        flex: "1 1 auto",
        display: "flex",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    CardHeader_Left_Left: {
        flex: "1 1 auto",
        display: "flex",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    CardHeader_Left_Right: {
        flex: "1 1 auto",
        display: "flex",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
        justifyContent: "flex-end",
		color :"rgb(80,80,80)"
    },
    CardHeader_RightIcon: {
        flex: "0 0 40px",
        display: "flex",
        flexDirection: "column",
        fontWeight: "500",
        fontSize: "0.9rem",
        alignItems: "center",
    },

    CardContent: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    BalanceAndSyncInfo: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",

        // padding: "5px",
    },
    BalanceInformation: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: "180px",

        // padding: "0px 5px",
        // alignSelf : "flex-end",
        // padding: "5px",
        color: "#FF6103",
    },
    BalanceInformation_Line: {
        flex: "1 1 auto",
        display: "flex",
        fontSize: "0.9rem",
        fontWeight: "500",

        // padding: "5px",
    },
    BalanceInformation_Line_Title: {
        flex: "1 1 auto",
        display: "flex",
        // justifyContent : "flex-start"

        // padding: "5px",
    },
    BalanceInformation_Line_Value: {
        flex: "0 0 80px",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    SyncInformation: {
        flex: "1 1 auto",

        display: "flex",
        flexDirection: "column",
        // maxWidth: "220px",
        alignSelf: "flex-end",
        // marginTop: "5px",
        // padding: "0px 5px",
        // color: "#FF6103",
    },
    SyncInformation_Line: {
        flex: "0 1 auto",
        display: "flex",
        fontSize: "0.75rem",
        // fontWeight: "500",

        // padding: "5px",
    },
    SyncInformation_Line_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },
    SyncInformation_Line_Value: {
        flex: "0 0 100px",
        display: "flex",
        justifyContent: "flex-end",

        // padding: "5px",
    },

    CardFooter: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    PopupContent: {
        display: "flex",
        flexDirection: "column",
        height: "235px",
        width: "590px",
        background: "rgb(34,34,34)",
        opacity: "0.95",
        color: "white",
        padding: "5px",
        boxSizing: "border-box",
    },
    PopupColumns: {
        flex: "1 1 auto",
        display: "flex",
        // padding: "5px",
        boxSizing: "border-box",
    },
    PopupColumn: {
        flex: "1 1 33%",
        display: "flex",
        flexDirection: "column",
        padding: "5px",
        boxSizing: "border-box",
        height: "100%",
    },
    PopupColumn_Title: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        padding: "0px 0px 10px 0px",
        fontSize: "1.1rem",
        fontWeight: "600",
    },
    PopupColumn_Menu: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        fontSize: "0.9rem",
        fontWeight: "400",
    },
    PopupColumn_Menu_Item: {
        flex: "0 0 30px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        boxSizing: "border-box",
        transition: "background 600ms",
        background: "none",
        "&:visited": {
            textDecoration: "none",
        },
        "&:hover": {
            background: "rgb(65,65,65)",
            color: "white",
        },
    },
}));

const Component = React.memo(function Component(props) {
    const {
        CardHeight,
        Loading,
        //
        CardWidth,
        CardTitle,
        PopupContent,
        PopupCard,
        CardContent,
        ChartContent,
        CardBackground,
    } = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Card = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let PopupComponent;
    if (PopupContent) {
        // let DefaultPopupContent = {
        //     Left: {
        //         Title: {
        //             Title: "View",
        //             Style: {fontSize: "2rem"},
        //             className: classes.PopupColumn_Title,
        //         },
        //         Menu: {
        //             Items: [
        //                 {Item: "Statements", onClick: "HandleThis"},
        //                 {Item: "Transactions", onClick: "HandleThis"},
        //             ],
        //             Style: {fontSize: "0.5rem"},
        //             className: classes.PopupColumn_Menu_Item,
        //         },
        //     },
        // };

        let LeftColumComponent, MiddleColumComponent, RightColumComponent;

        if (PopupContent.Left) {
            LeftColumComponent = (
                <div className={classes.PopupColumn}>
                    <div
                        className={clsx(classes.PopupColumn_Title, PopupContent.Left.Title.className)}
                        //
                        style={{...PopupContent.Left.Title.Style}}
                    >
                        {PopupContent.Left.Title.Title}
                    </div>
                    <div className={classes.PopupColumn_Menu}>
                        {PopupContent.Left.Menu.Items.map((elem) => {
                            return (
                                <div
                                    className={clsx(classes.PopupColumn_Menu_Item, PopupContent.Left.Menu.className)}
                                    //
                                    style={{...PopupContent.Left.Menu.Style}}
                                    onClick={elem.OnClick ? elem.OnClick : null}
                                >
                                    {elem.Item}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        if (PopupContent.Middle) {
            MiddleColumComponent = (
                <div className={classes.PopupColumn}>
                    <div
                        className={clsx(classes.PopupColumn_Title, PopupContent.Middle.Title.className)}
                        //
                        style={{...PopupContent.Middle.Title.Style}}
                    >
                        {PopupContent.Middle.Title.Title}
                    </div>
                    <div className={classes.PopupColumn_Menu}>
                        {PopupContent.Middle.Menu.Items.map((elem) => {
                            return (
                                <div
                                    className={clsx(classes.PopupColumn_Menu_Item, PopupContent.Middle.Menu.className)}
                                    //
                                    style={{...PopupContent.Middle.Menu.Style}}
                                    onClick={elem.OnClick ? elem.OnClick : null}
                                >
                                    {elem.Item}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        if (PopupContent.Right) {
            RightColumComponent = (
                <div className={classes.PopupColumn}>
                    <div
                        className={clsx(classes.PopupColumn_Title, PopupContent.Right.Title.className)}
                        //
                        style={{...PopupContent.Right.Title.Style}}
                    >
                        {PopupContent.Right.Title.Title}
                    </div>
                    <div className={classes.PopupColumn_Menu}>
                        {PopupContent.Right.Menu.Items.map((elem) => {
                            return (
                                <div
                                    className={clsx(classes.PopupColumn_Menu_Item, PopupContent.Right.Menu.className)}
                                    //
                                    style={{...PopupContent.Right.Menu.Style}}
                                    onClick={elem.OnClick ? elem.OnClick : null}
                                >
                                    {elem.Item}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        PopupComponent = (
            <div className={classes.CardHeader_RightIcon}>
                <PopUpMenuWithButton
                    Position="bottom"
                    IconSize="20px"
                    Alignment="right"
                    ZIndex="2"
                    WithArrow={true}
                    Offset={{
                        top: "6px",
                        left: "8px",
                        bottom: "0px",
                        right: "0px",
                    }}
                >
                    <div className={classes.PopupContent}>
                        <div className={classes.PopupColumns}>
                            {LeftColumComponent}
                            {MiddleColumComponent}
                            {RightColumComponent}
                        </div>
                    </div>
                </PopUpMenuWithButton>
            </div>
        );
    } else {
        PopupComponent = null;
    }

    
    return (
        <React.Fragment>
            <div ref={Ref_Card} className={classes.Card} style={{height: `${CardHeight}?CardHeight:"null"}`, width: `${CardWidth}?CardHeight:"null"}`}}>
                <LocalCircularLoaderIOS
                    FullSize={false}
                    WithModalCard={true}
                    Loading={Loading}
                    ModalCardBackground="rgba(0, 0, 0, 0.2)"
                    ModalParentRef={Ref_Card}
                    FitToParentDimensions={true}
                    SpinnerDimension="60px"
                    ZIndex="200000"
                    // SpinnerColor=""
                    // SpinnerCount=""
                    // SpinnerRectHeight=""
                    // SpinnerRectWidth=""
                    // SpinnerRadius="150px"
                />
                <div className={classes.CardHeader}>
                    <div className={classes.CardHeader_Left}>
                        <div className={classes.CardHeader_Left_Left}>{CardTitle.Left}</div>
                        <div className={classes.CardHeader_Left_Right}>{CardTitle.Right}</div>
                    </div>
                    {PopupComponent}
                </div>
                <div className={classes.CardContent}>
                    {CardContent}
                    {ChartContent}
                </div>
                <div className={classes.CardFooter}></div>
            </div>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
