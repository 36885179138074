import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import CardModal from "@artibulles-cis/react/CardModal";
import CardModal from "../../../../../../../artibulles-cis/CardModal/CardModal";
import {useHistory} from "react-router-dom";
import TabNavContent from "../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import InvoiceEdit from "../01InvoiceEdit/InvoiceEdit";
import InvoiceDuplicate from "../02InvoiceDuplicate/InvoiceDuplicate";
import {useSelector} from "react-redux";
import {PDF, CheckBoxCircularFilled, WarningTriangleRed, WarningTriangleYellow, RotateLeft} from "@artibulles-cis/react-icons";
import {parseISO, differenceInDays} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },

    TableWrapper: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },

    ClassExtendTabContent: {
        padding: "10px",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_TabNavContent = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/

    const {isAdmin} = useSelector((state) => ({
        isAdmin: state.auth.User.isAdmin,
    }));
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    let URLAbsolute = {
        ALL: `${ProdURL}/api/saleinvoices/invoices/queries`,
        QUERY: `${ProdURL}/api/companies/query`,
        DELETEONE: `${ProdURL}/api/saleinvoices/invoicetableoperation`,
        DELETEMANY: `${ProdURL}/api/saleinvoices/invoicetableoperation`,
        PUT: `${ProdURL}/api/saleinvoices/invoicetableoperation`,
    };

    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        if (value && Array.isArray(value) && value.length > 0) {
            let CellRenderComp = [];
            value.forEach((elem) => {
                let IconElem = (
                    <div onClick={() => HandleViewPdf(elem.serverPath)}>
                        <PDF SVGFillColor="white" />
                    </div>
                );
                CellRenderComp.push(IconElem);
            });

            return CellRenderComp;
        } else {
            return null;
        }
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const StatusRendering = (params) => {
        let RowData = params.data;

        let Status = RowData.status_invoice;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Posted") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                            Invoiced
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Paid") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(108,145,0)"}}>
                            Paid
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Reconciled") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                            Reconciled
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment>{Status}</React.Fragment>;
        }
    };
    const PaymentStatusRendering = (params) => {
        let RowData = params.data;

        let CalcIsPaid = RowData.CalcIsPaid;
        let ManualBankPaymentActionsStatus = RowData.ManualBankPaymentActionsStatus;
        let status_invoice = RowData.status_invoice;
        let PaymentMethod = RowData.PaymentMethod;
        if (status_invoice === "Draft") {
            return <React.Fragment />;
        } else {
            if (CalcIsPaid === true) {
                //We check the details of the Manual Actions
                if (ManualBankPaymentActionsStatus === "PaidExecuted") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Executed
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (ManualBankPaymentActionsStatus === "PaidPendingExecution") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(108,145,0)"}}>
                                    Paid Pending
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                }
            } else {
                if (PaymentMethod === "Bank Account Automatic") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Auto Bank
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (PaymentMethod === "Bank Account Manual") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Man Bank
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (PaymentMethod === "Credit Card Automatic") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Auto CC
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else if (PaymentMethod === "Credit Card Manual") {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                    Paid Man CC
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                } else {
                    return (
                        <div className={classes.PaymentStatus}>
                            <div className={classes.PaymentStatus_BadgeWrapper}>
                                <div className={classes.PaymentStatus_Badge} style={{background: "orange"}}>
                                    {PaymentMethod}
                                </div>
                            </div>
                            <div className={classes.PaymentStatus_WarningWrapper}></div>
                        </div>
                    );
                }
            }
        }
    };

    const AccountantStatusRendering = (params) => {
        let RowData = params.data;
        let AccountantStatus = RowData.AccountantStatus;

        if (AccountantStatus) {
            if (AccountantStatus.Uploaded === true) {
                return (
                    <div className={classes.PaymentStatus}>
                        <div className={classes.PaymentStatus_BadgeWrapper}>
                            <div className={classes.PaymentStatus_Badge} style={{background: "rgb(0,147,47)"}}>
                                Uploaded
                            </div>
                        </div>
                        <div className={classes.PaymentStatus_WarningWrapper}></div>
                    </div>
                );
            } else if (AccountantStatus.UploadedMethod === "Email To Confirm") {
                return (
                    <div className={classes.PaymentStatus}>
                        <div className={classes.PaymentStatus_BadgeWrapper}>
                            <div className={classes.PaymentStatus_Badge} style={{background: "orange", color: "black"}}>
                                Skwarel Upload
                            </div>
                        </div>
                        <div className={classes.PaymentStatus_WarningWrapper}></div>
                    </div>
                );
            } else {
                return (
                    <div className={classes.PaymentStatus}>
                        <div className={classes.PaymentStatus_BadgeWrapper}>
                            <div className={classes.PaymentStatus_Badge} style={{background: "red", color: "black"}}>
                                Not sent
                            </div>
                        </div>
                        <div className={classes.PaymentStatus_WarningWrapper}></div>
                    </div>
                );
            }
        } else {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "red"}}>
                            Not sent
                        </div>
                    </div>
                    <div className={classes.PaymentStatus_WarningWrapper}></div>
                </div>
            );
        }
    };

    const HandleEditRow = (Id, RowData) => {
        setShowEditCard(true);
        setEditCardId(Id);
    };

    const HandleDuplicateInvoice = (Id, RowData) => {
        setDuplicateCardId(Id);
        setShowDuplicateCard(true);
    };

    const DeleteCondition = (args) => {
        // console.log("DeleteCondition", args);
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else if (status_invoice === "Created") {
            if (isAdmin === true) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Duplicate",
            ActiveCondition: null,
            Index: 2,
            OnClick: HandleDuplicateInvoice,
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            // OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];

    const TableColumns = [
        {
            field: "InvoiceIssueDateIso",
            headerName: "Issued",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "createdAt",
            headerName: "Created",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "Calc_RefInvoice",
            headerName: "Ref",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "Calc_InvoiceLegaReference",
            headerName: "Legal Ref",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "Calc_InvoiceLegaNumber",
            headerName: "Legal Number",
            headerClass: "ag-CenterHeader",
            width: 170,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "CalcTotalVATIncluded",
            headerName: "Amount (VAT)",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "status_invoice",
            //
            headerName: "Invoice Status",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
        },

        {
            field: "PurchaseInvoicePaymentActionNeeded",
            //
            headerName: "Payment Status",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: PaymentStatusRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "AccountantStatus",
            //
            headerName: "Accountant Status",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountantStatusRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "InvoicePaymentDueDateIso",
            headerName: "Due Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            headerName: "Supplier",
            headerClass: "ag-CenterHeader",
            width: 300,
            filter: true,
            Lookup: {LookupField: "ref_Customer", Source: "Customers", ReturnField: "company"},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "Description",
            headerName: "Description",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            autoHeight: true,
            wrapText: true,
            minWidth: 200,
        },
        // {
        //     field: "InvoicePaymentExpectedCommunication",
        //     headerName: "Communication",
        //     headerClass: "ag-CenterHeader",
        //     width: 300,
        //     filter: true,
        // },

        {
            field: "Invoices_File_Path",
            headerName: "Docs",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: false,
            cellRenderering: PDFRendering,
            cellStyle: PDFCell,
        },

        {
            field: "CalcTotalVATExcluded",
            headerName: "Tot VAT Exc.",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "CalcTotalVATAmount",
            headerName: "Tot VAT",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "updatedAt",
            headerName: "Last updated",
            headerClass: "ag-CenterHeader",
            width: 130,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Date",
            sortingOrder: ["asc", "desc"],
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
        StatusBar: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [ShowDuplicateCard, setShowDuplicateCard] = useState(false);
    const [DuplicateCardId, setDuplicateCardId] = useState(null);

    const [ReloadTableData, setReloadTableData] = useState(true);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        // console.log("Handle Close Edit Card");
        setShowEditCard(false);
        setEditCardId(null);
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setTimeout(() => {
            setReloadTableData(true);
        }, 300);
    };

    const HandleCloseDuplicateCard = (Id) => {
        // console.log("Should close the card")
        setShowDuplicateCard(false);
        setDuplicateCardId(null);
        setShowEditCard(false);
        setEditCardId(null);
        setTimeout(() => {
            setReloadTableData(true);
        }, 300);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;

    if (ShowEditCard === true) {
        ModalCard = (
            <CardModal
                CardID="EditInvoiceCard"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <InvoiceEdit Id={EditCardId} HandleCloseCard={HandleCloseEditCard} />
            </CardModal>
        );
    }
    if (ShowDuplicateCard === true) {
        ModalCard = (
            <CardModal
                CardID="DuplicateInvoiceCard"
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseDuplicateCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceDuplicate DuplicateId={DuplicateCardId} HandleCloseViewCard={HandleCloseDuplicateCard} />
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCard}

            <AGGridTable
                TableName="InvoiceTableAll"
                URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                MainDataSource="Invoices"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                ReloadData={ReloadTableData}
                SyncReloadDataStateFunction={setReloadTableData}
				
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
