/**
 * ScriptName : CalendarFunctions.js
 * Version : 1.0.0
 * Date : 2020/11/24
 * Author: ArnaudJaspard
 * Description : description
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/
const _ = require("lodash");
const {startOfMonth, getDay, startOfWeek, getISOWeek, getMonth, getDate, add, format, isSameDay, isBefore, isAfter, sub, getDaysInMonth, startOfYear} = require("date-fns");
var frLocale = require("date-fns/locale/fr");
frLocale = frLocale.default;
var usLocale = require("date-fns/locale/en-US");
usLocale = usLocale.default;
/********LOADING DEPENDENCIES************/

/********CORE FUNCTION************/
//Generate an array of 6 rows containing weekNumber, and all days in the week starting with firstDayOfWeek
exports.GenerateDaysMonthView = ({currentDate = new Date(Date.now()), language = "EN", firstDayOfWeek = 1, MinDate, MaxDate}) => {
    //Generate the 6 weeks data based on the currentDate

    let TodaysDate = new Date(Date.now());
    //1. get the First day of the month with the specified start of week
    var FirstDayOfMonth = startOfMonth(currentDate);

    const FirstDayOfMonthDayofWeek = startOfWeek(FirstDayOfMonth, {weekStartsOn: firstDayOfWeek});

    const CurrentMonth = getMonth(currentDate);
    //Generate week Objects
    let MonthData = [];
    var DateIteration = FirstDayOfMonthDayofWeek;

    for (var i = 0; i < 6; i++) {
        let Week = {
            weekNumber: getISOWeek(DateIteration),
            days: [],
        };

        for (var j = 0; j < 7; j++) {
            let Disabled = false,
                DisabledMin = false,
                DisabledMax = false;
            if (MinDate) {
                DisabledMin = isBefore(DateIteration, MinDate);
            }
            if (MaxDate) {
                DisabledMax = isAfter(DateIteration, MaxDate);
            }
            if (DisabledMin === true || DisabledMax === true) {
                Disabled = true;
            }
            let day = {
                dateLocale: DateIteration,
                dateUTC: null,
                day: getDate(DateIteration),
                isCurrentMonth: getMonth(DateIteration) === CurrentMonth ? true : false,
                isSelected: isSameDay(DateIteration, currentDate) === true ? true : false,
                isToday: isSameDay(DateIteration, TodaysDate) === true ? true : false,
                isDisabled: Disabled,
            };
            Week.days.push(day);
            DateIteration = add(DateIteration, {days: 1});
        }
        MonthData.push(Week);
    }

    // console.log("FirstDayOfMonth", FirstDayOfMonth);
    // console.log("MonthData", MonthData);

    return MonthData;
};
//Return the Days Name in locale given a date
exports.GetDaysWeekNameLocale = ({locale = null, firstDayOfWeek = 1}) => {
    // var FinalLocale = locale ? locale : usLocale;

    const Today = Date.now();
    var DayOfWeek = startOfWeek(Today, {weekStartsOn: firstDayOfWeek});
    var DaysName = [];
    for (var i = 0; i < 7; i++) {
        var DayName;
        if (locale) {
            DayName = format(DayOfWeek, "EEEEEE", {locale: locale});
        } else {
            DayName = format(DayOfWeek, "EEEEEE");
        }

        DaysName.push(DayName);
        DayOfWeek = add(DayOfWeek, {days: 1});
    }
    return DaysName;
};

//Return the month name in locale given a date
exports.GetYearMonthNameLocale = ({date = null, locale = null, monthFormat = "LLL"}) => {
    locale = locale ? locale : usLocale;

    let Year = format(date, "yyyy", {locale: locale});
    var MonthName;
    if (monthFormat === "LLL" || monthFormat === "LLLL") {
        MonthName = format(date, monthFormat, {locale: locale});
    } else {
        MonthName = format(date, "LLL", {locale: locale});
    }

    return {year: Year, month: MonthName};
};

//Generate all months of a year name in the locale format
exports.GetAllMonthsNameLocal = ({locale = null, monthFormat = "LLL"}) => {
    let date = Date.now();
    let StartOfYear = startOfYear(date);

    locale = locale ? locale : usLocale;
    var Output = [];
    for (var i = 0; i < 12; i++) {
        Output.push(format(StartOfYear, monthFormat, {locale: locale}));
        StartOfYear = add(StartOfYear, {months: 1});
    }
    return Output;
};
//Generate an array of all days in a month
exports.GenerateDaysMonths = ({date}) => {
    date = date ? date : new Date(Date.now());

    var FirstDayOfMonth = startOfMonth(date);
    let TotDays = getDaysInMonth(date);
    var Output = [];
    for (var i = 0; i < TotDays; i++) {
        Output.push(getDate(FirstDayOfMonth));
        FirstDayOfMonth = add(FirstDayOfMonth, {days: 1});
    }
    return Output;
};

exports.GenerateHoursDay = ({hFormat = 24}) => {
    var Output = [];
    var length = hFormat === 24 ? 24 : 12;
    for (var i = 0; i < length; i++) {
        Output.push(i);
    }
    return Output;
};

exports.GenerateMinutesInterval = ({interval = 1}) => {
    var Output = [];

    for (var i = 0; i < 60; i++) {
        let h = parseInt(i * interval);
        if (h >= 60) {
            break;
        }
        Output.push(h);
    }
    return Output;
};

exports.FindClosestIndexToArrayWithValue = ({InputArray = null, Value = null}) => {
    if (InputArray && Array.isArray(InputArray) && Value !== null && Value !== undefined) {
        var ClosestIndex = {Distance: null, Index: null, Value: null};
        for (var i = 0; i < InputArray.length; i++) {
            var elem = InputArray[i];
            if (elem === Value) {
                ClosestIndex.Distance = 0;
                ClosestIndex.Index = i;
                ClosestIndex.Value = elem;
                break;
            } else {
                let Distance = Math.abs(Value - elem);
                if (ClosestIndex.Distance === null) {
                    ClosestIndex.Distance = Distance;
                    ClosestIndex.Index = i;
                    ClosestIndex.Value = elem;
                } else {
                    if (Distance < ClosestIndex.Distance) {
                        ClosestIndex.Distance = Distance;
                        ClosestIndex.Index = i;
                        ClosestIndex.Value = elem;
                    }
                }
            }
        }

        return ClosestIndex;
    }
};

/********CORE FUNCTION************/

/********GLOBAL FUNCTIONS************/

/********GLOBAL FUNCTIONS************/
