/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";


import Page from "@artibulles-cis/react/Page";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ExpandableBox: {
        borderRadius: "0px",
        boxShadow: "none",
    },
    ClassExtends_ExpandableBox_Controller_Wrapper: {
        borderRadius: "0px",
        background: "none",
        color: "black",
    },
    ClassExtends_ExpandableBox_TopContent: {
        fontSize: "12px",
        justifyContent: "flex-start",
    },

    ContentOpenedWrapper: {
        background: "none",
        color: "black",
        borderRadius: "0px",
    },
    FormField_WithIcon: {
        // margin: "0px 10px 0px 10px",
        display: "flex",
        alignItems: "center",
    },
    FormField_Icon: {
        // marginBottom: "15px",
        marginRight: "5px",
        width: "25px",
        height: "25px",
    },
    FormLeft: {
        maxWidth: "700px",
    },
}));
const ClassExtendsIconControllerStyle = {
    SVGStrokeColor: "black",
    SVGStrokeHoverColorHover: "black",
    IconControllerStrokeWidth: "15px",
};
const HomePage = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialFormValues, setInitialFormValues] = useState({
        firstName: null,
    });

    const [FormValues, setFormValues] = useState({
        firstName: null,
    });
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const ShareInput = (value) => {};
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    // const IntroSelectConfiguration = <h3 style={{margin: "0px", textAlign: "center"}}>Customer Information</h3>;
    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px">
            Welcome to Artibulles Business Management Solution
        </Page>
    );

    /***************** RENDER ******************/
};

export default HomePage;
