import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, addHours} from "date-fns";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormWrapper: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        color: "black",
        textAlign: "left",
        margin: "0px 0px 20px 0px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        display: "flex",
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        flex: "0 1 auto",
        display: "flex",
    },
    ButtonBarExtend: {
        margin: "0px 5px",
        padding: "0px 5px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },
}));

const Component = React.memo(function Component(props) {
    const {PurchaseInvoiceID, FormValuesParent, HandleCloseRecurrence} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Loading, setLoading] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [FormInitialized, setFormInitialized] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [NewRecurrence, setNewRecurrence] = useState(true);
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FormValues, setFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState({
        Repeat: [
            {value: "Monthly", label: "Monthly"},
            {value: "Annually", label: "Annually"},
        ],
    });
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [Invalid, setInvalid] = useState(true); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [TempFormErrors, setTempFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!APICallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data", Data);
                        if (Data && Data.Invoice && Data.Invoice.InvoiceReccurence && Data.Invoice.InvoiceReccurence.IsActive === true) {
                            console.log("Invoice", Data.Invoice.InvoiceReccurence);
                            let InvoiceReccurence = Data.Invoice.InvoiceReccurence;

                            let InitFormValues = {
                                Repeat: InvoiceReccurence ? InvoiceReccurence.Repeat : null,
                                StartRecurrence_DateIso: InvoiceReccurence
                                    ? InvoiceReccurence.StartRecurrence_DateIso
                                        ? InvoiceReccurence.StartRecurrence_DateIso
                                        : formatISO(new Date(Date.now()))
                                    : formatISO(new Date(Date.now())),
                                EndRecurrenceOnDateIso: InvoiceReccurence
                                    ? InvoiceReccurence.EndRecurrenceOnDateIso
                                        ? InvoiceReccurence.EndRecurrenceOnDateIso
                                        : formatISO(new Date(Date.now()))
                                    : formatISO(new Date(Date.now())),
                                GenerateRecurrentTasksOn: InvoiceReccurence.GenerateRecurrentTasksOn ? InvoiceReccurence.GenerateRecurrentTasksOn : null,
                            };
                            let GenerateRecurrentTasksOn;
                            if (InvoiceReccurence.Repeat === "Monthly") {
                                GenerateRecurrentTasksOn = [
                                    {value: "Month End", label: "Month End"},
                                    {value: "Month Start", label: "Month Start"},
                                ];
                            } else {
                                GenerateRecurrentTasksOn = [
                                    {value: "Year End", label: "Year End"},
                                    {value: "Year Start", label: "Year Start"},
                                ];
                            }
                            setFieldOptions({
                                ...FieldOptions,
                                ...{
                                    GenerateRecurrentTasksOn: GenerateRecurrentTasksOn,
                                },
                            });
                            setInitialFormValues(InitFormValues);
                            setFormValues(InitFormValues);
                            // setFieldOptions(InitFieldOptions);
                            setFormInitialized(true);
                        } else {
                            // setInitialFormValues(InitFormValues);
                            // setFormValues(InitFormValues);

                            setFormInitialized(true);
                        }
                    }
                } else if (APICallType === "InitalizeTable") {
                    // if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    //     let Data = ApiRes.data.data;
                    //     if (Data) {
                    //         setNoData(false);
                    //     } else {
                    //         setNoData(true);
                    //     }
                    //     Ref_LocaleData.current = Data;
                    //     setReloadTable(true);
                    // }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        console.log("FormValuesParent", FormValuesParent);
		if (FormValuesParent.InvoiceReccurence && FormValuesParent.InvoiceReccurence.IsActive === true) {
            
            console.log("Not New");
            setNewRecurrence(false);
        } else {
            setNewRecurrence(true);
        }
        let ApiCall;

        ApiCall = {url: `${ProdURL}/api/purchaseinvoices/reccurrence/byinvoice/${PurchaseInvoiceID}`, type: "get"};

        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const handleSelectChange = (field, SelectedDetails) => {
        console.log("Handle Select Change");
        let SelectedValues = SelectedDetails.Value;
        let FieldPristine = SelectedDetails.Pristine;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        if (field === "Repeat") {
            // console.log("Repeat", SelectedValues);

            if (SelectedValues === "Monthly") {
                setFieldOptions({
                    ...FieldOptions,
                    ...{
                        GenerateRecurrentTasksOn: [
                            {value: "Month End", label: "Month End"},
                            {value: "Month Start", label: "Month Start"},
                        ],
                    },
                });
            } else if (SelectedValues === "Annually") {
                setFieldOptions({
                    ...FieldOptions,
                    ...{
                        GenerateRecurrentTasksOn: [
                            {value: "Year End", label: "Year End"},
                            {value: "Year Start", label: "Year Start"},
                        ],
                    },
                });
            }
        }

        setFormValues(UpdatedFormValues);
        ValidateForm(field, SelectedValues);
    };
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;
        let FieldPristine = values.Pristine;
        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        ValidateForm(field, value);
    };
    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(TempFormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (TempFormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: TempFormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setTempFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...TempFormErrors};
        console.log("Validate Form FormValues", FormValues);
        //Dealing with empty fields
        if (!FormValues.Repeat) {
            UpdatedFormErrors.Repeat = "Cannot be empty";
        } else {
            delete UpdatedFormErrors.Repeat;
        }

        console.log("UpdatedFormErrors", UpdatedFormErrors);
        if (Object.keys(UpdatedFormErrors).length > 0) {
            setTempFormErrors(UpdatedFormErrors);
            // setFormErrors(UpdatedFormErrors);
            setInvalid(true);
        } else {
            setTempFormErrors({});
            // setFormErrors({});
            setInvalid(false);
        }
    };

    const HandleSubmitForm = (SubmitType) => {
        console.log("Submit", SubmitType);
        if (SubmitType === "Create") {
            if (Object.keys(TempFormErrors).length > 0) {
                setFormErrors(TempFormErrors);
            } else {
                let FormData = {
                    InvoiceId: PurchaseInvoiceID,
                    InvoiceReccurence: {
                        IsActive: true,
                        StartRecurrence_DateIso: new Date(Date.now()),
                        Repeat: FormValues.Repeat,
                        EndRecurrenceOnDateIso: FormValues.EndRecurrenceOnDateIso,
                        GenerateRecurrentTasksOn: FormValues.GenerateRecurrentTasksOn,
                    },
                };
                console.log("FormData", FormData);
                setAPICallType("Create");
                const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/reccurrence/createrecurrence`, type: "post", data: FormData};
                setLoading(true);
                async function APIInternal() {
                    const res = await APICallExternal(ApiCall);
                    setApiRes(res);
                    setLoading(false);
                }
                APIInternal();
            }
        } else if (SubmitType === "Update") {
        } else if (SubmitType === "Delete") {
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormComponent, FormButtons;
    if (PurchaseInvoiceID) {
        let TopBarContent = (
            <div className={classes.Title}>
                <div style={{flex: "1 1 auto", justifyContent: "center"}}>Task Recurrence</div>
            </div>
        );

        if (FormInitialized === true) {
            FormComponent = (
                <div className={classes.FormWrapper}>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                            <FormCompontent
                                Name="Repeat"
                                InitialValue={InitialFormValues.Repeat ? InitialFormValues.Repeat : null}
                                FormValue={FormValues.Repeat ? FormValues.Repeat : null}
                                OutputSelectedValues={(values) => handleSelectChange("Repeat", values)}
                                meta={{error: FormErrors.Repeat, invalid: FormErrors.Repeat ? true : false}}
                                OutputError={(error) => HandleUpdateErrors("Repeat", error)}
                                NoMessage={false}
                                DisplayMessageIn="Popup"
                                SelectOptions={FieldOptions.Repeat}
                                Component="Select"
                                Variant="Outlined"
                                Label="Repeat"
                                MultiSelect={false}
                                // SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                LabelMaxWidth="70px"
                                LabelAlign="Left"
                                FieldMaxWidth="200px"
                                Required={true}
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex}>
                        <div className={classes.FormField} style={{flex: "0 0 50px"}}>
                            <FormCompontent
                                Name="EndRecurrenceOnDateIso"
                                InitialValue={InitialFormValues.EndRecurrenceOnDateIso ? InitialFormValues.EndRecurrenceOnDateIso : null}
                                FormValue={FormValues.EndRecurrenceOnDateIso ? FormValues.EndRecurrenceOnDateIso : null}
                                OutputValue={(values) => HandleUpdateFormValues("EndRecurrenceOnDateIso", values)}
                                Component="DatePicker"
                                Variant="Outlined"
                                Label="End Date"
                                NoMessage={false}
                                // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                                // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                                // LegendOffsetHeight="0"
                                // LegendOffsetWidth="0"
                                PickerDateType="Calendar"
                                PickerOnly={false}
                                InputFormat="dd/MM/yyyy"
                                LabelMaxWidth="70px"
                                FieldMaxWidth="100px"
                                LabelAlign="Left"
                            />
                        </div>
                    </div>

                    <div className={classes.FormMultilineFlex} style={{display: `${FormValues.Repeat ? "flex" : "none"}`}}>
                        <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                            <FormCompontent
                                Name="GenerateRecurrentTasksOn"
                                InitialValue={InitialFormValues.GenerateRecurrentTasksOn ? InitialFormValues.GenerateRecurrentTasksOn : null}
                                FormValue={FormValues.GenerateRecurrentTasksOn ? FormValues.GenerateRecurrentTasksOn : null}
                                OutputSelectedValues={(values) => handleSelectChange("GenerateRecurrentTasksOn", values)}
                                SelectOptions={FieldOptions.GenerateRecurrentTasksOn}
                                Component="Select"
                                Variant="Outlined"
                                Label="Generate ocurrences at"
                                NoMessage={false}
                                MultiSelect={false}
                                // SortSelectOptions="Asc"
                                // SortIcons={true}
                                AllowCreateOption={false}
                                LabelMaxWidth="240px"
                                LabelAlign="Left"
                                FieldMaxWidth="150px"
                                Required={true}
                            />
                        </div>
                    </div>
                </div>
            );
            if (NewRecurrence === true) {
                FormButtons = (
                    <div className={classes.ButtonBar}>
                        <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Create")} disabled={Invalid || Pristine}>
                            Create Recurrence
                        </Button>
                    </div>
                );
            } else {
                FormButtons = (
                    <div className={classes.ButtonBar}>
                        <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Update")} disabled={Invalid || Pristine}>
                            Update Recurrence
                        </Button>

                        <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Delete")} Background="red">
                            Delete Recurrences
                        </Button>
                    </div>
                );
            }
        } else {
            FormComponent = null;
            FormButtons = null;
        }
    }

    return (
        <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {FormComponent}
            {FormButtons}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
