import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Switch, Route, Redirect, useParams} from "react-router-dom";

import TaskCreateEdit from "./TaskCreateEdit";
// import TasksViewAllTasksTable from "./Tables/TasksViewAllTasksTable";
import TasksTablesTabNavRouter from "./Tables/TasksTablesTabNavRouter";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const TaskManagementRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path="/tasks">
                <Redirect to="/tasks/tables" />
            </Route>
            <Route path="/tasks/tables">
                <TasksTablesTabNavRouter />
            </Route>
            <Route path="/tasks/task/edit/:id">
                <TaskCreateEdit />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
};

export default TaskManagementRouter;
