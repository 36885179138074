import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {AccountNumber, AccountValidationResponse} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.status !== 200) {
                console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data) {
                    let Data = ApiRes.data;
                    console.log("Data", Data);
                    let Output = {
                        Valid: Data.valid,
                        BIC: Data.bankData.bic,
                        BankName: Data.bankData.name,
                        BankCode: Data.bankData.bankCode,
                    };
                    AccountValidationResponse(Output);
                } else {
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        console.log("Calling the API");
        const ApiCall = {url: `https://openiban.com/validate/${AccountNumber}?getBIC=true`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
