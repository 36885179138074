/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import Page from "../../../../../../../../artibulles-cis/Page/Page";

import _ from "lodash";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
const {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, addHours} = require("date-fns");

import AccountingTransactionEntryEditForm from "./AccountingTransactionEntryEditForm";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "10px",
    },
}));

const Component = (props) => {
    const {Id, HandleCloseViewCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const LocationParams = useParams();
    const Location = useLocation();
    const PathName = Location.pathname;
    const History = useHistory();

    let FinalId = Id;
    let OpenInModal = false;
    let InitialCall = {type: "Edit", ID: FinalId};

    let FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companyaccount/transactionwithstatement`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("redirect", args);
        // if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
        //     if (OpenInModal === false) {
        //         HandleCloseViewCard(true);
        //         // History.push(`/tasktimemanagement/tasks/alltasks`);
        //     } else {
        //         History.push(`/tasktimemanagement/tasks`);
        //     }
        // }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Transaction Data", data);
        var Transaction = null;
        if (data.Transaction) {
            Transaction = data.Transaction;
        }

        let SuppliersList, CustomerList;
        if (data.Suppliers && data.Suppliers.length > 0) {
            SuppliersList = data.Suppliers.map((elem) => {
                return {value: elem._id, label: elem.company, vat_number: elem.vat_number};
            });
        }
        if (data.Customers && data.Customers.length > 0) {
            CustomerList = data.Customers.map((elem) => {
                return {value: elem._id, label: elem.company, vat_number: elem.vat_number};
            });
        }

        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }

        let Invoice, SupplierInitialId, CustomerInitialId, InitialSupplier;

        if (Transaction.ref_PurchaseInvoice && Transaction.PurchaseInvoice) {
            Invoice = Transaction.PurchaseInvoice;
            SupplierInitialId = Invoice ? Invoice.ref_Supplier : null;
            if (SupplierInitialId) {
                InitialSupplier = _.find(data.Suppliers, {_id: SupplierInitialId});
            }
        } else if (Transaction.ref_SaleInvoice && Transaction.SaleInvoice) {
            Invoice = Transaction.SaleInvoice;
            CustomerInitialId = Invoice ? Invoice.ref_Customer : null;
            if (SupplierInitialId) {
                InitialSupplier = _.find(data.Customers, {_id: CustomerInitialId});
            }
        }

        console.log("Invoice", Invoice);

        let FinalAccountingCommunication;
        if (Transaction.accouting_communication) {
            FinalAccountingCommunication = Transaction.accouting_communication;
        } else {
            if (Invoice && Invoice.Description) {
                FinalAccountingCommunication = Invoice.Description;
            }
        }

        let BankAccountsOptions = [];
        if (InitialSupplier) {
            let BankAccounts = InitialSupplier.BankAccounts;
            if (BankAccounts && Array.isArray(BankAccounts)) {
                BankAccounts.forEach((elem) => {
                    BankAccountsOptions.push({label: elem.account_number, value: elem._id});
                });
            }
        }

        let FinalAccountingNote;
        if (Transaction.accounting_note) {
            FinalAccountingNote = Transaction.accounting_note;
        } else {
            if (FinalAccountingCommunication) {
                FinalAccountingNote = "Ref : " + Transaction.Calc_accounting_ref_id + "\n" + FinalAccountingCommunication;
            } else {
                FinalAccountingNote = "Ref : " + Transaction.Calc_accounting_ref_id;
            }
        }
        let InitialFormErrors = {};
        if (Transaction) {
            if (Transaction.CalcAccountingInvoiceHasError === true) {
                if (Transaction.AccountingInvoiceErrors.CalcCounterparty_bankAccount_number === true) {
                    InitialFormErrors.counterparty_bankAccount_number = true;
                    InitialFormErrors.CounterpartyBankAccount_Id = true;
                }
                if (Transaction.AccountingInvoiceErrors.CalcAmount === true) {
                    InitialFormErrors.banking_amount_transaction = true;
                    InitialFormErrors.CalcTotalVATIncluded = true;
                }
                if (Transaction.AccountingInvoiceErrors.CalcCommunication === true) {
                    InitialFormErrors.communication = true;
                    InitialFormErrors.InvoicePaymentExpectedCommunication = true;
                }
            }
        }
        let Statements_File_Path = [];

        if (
            Transaction &&
            Transaction.ref_BankStatement &&
            Transaction.ref_BankStatement.Statements_File_Path &&
            Array.isArray(Transaction.ref_BankStatement.Statements_File_Path) &&
            Transaction.ref_BankStatement.Statements_File_Path.length > 0
        ) {
            Transaction.ref_BankStatement.Statements_File_Path.forEach((elem) => {
                Statements_File_Path.push(elem);
            });
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Transaction ? Transaction._id : "new",
                transaction_date_Iso: Transaction ? Transaction.transaction_date_Iso : null,
                banking_effective_date_Iso: Transaction ? Transaction.banking_effective_date_Iso : null,
                banking_amount_transaction: Transaction ? Transaction.banking_amount_transaction : null,
                original_amount_transaction: Transaction ? Transaction.original_amount_transaction : null,
                type_expense_income: Transaction ? Transaction.type_expense_income : null,
                transaction_source: Transaction ? Transaction.transaction_source : null,
                myCompany_bankAccount_number: Transaction ? Transaction.myCompany_bankAccount_number : null,
                credit_card_number: Transaction ? Transaction.credit_card_number : null,
                counterparty_bankAccount_number: Transaction ? Transaction.counterparty_bankAccount_number : null,
                counterparty_bankAccount_bic: Transaction ? Transaction.counterparty_bankAccount_bic : null,
                counterparty_bankAccount_name: Transaction ? Transaction.counterparty_bankAccount_name : null,
                counterparty_bankAccount_address: Transaction ? Transaction.counterparty_bankAccount_address : null,
                communication: Transaction ? Transaction.communication : null,
                location: Transaction ? Transaction.location : null,
                location: Transaction ? Transaction.location : null,
                merchant: Transaction ? Transaction.merchant : null,
                accounting_Justification_needed: Transaction ? Transaction.accounting_Justification_needed : null,
                accounting_Justification_Provided: Transaction ? Transaction.accounting_Justification_Provided : null,
                accounting_Justification_Provided_Date: Transaction ? (Transaction.accounting_Justification_Provided_Date ? Transaction.accounting_Justification_Provided_Date : null) : null,
                customer_name: Transaction ? Transaction.customer_name : null,
                vat_amount: Transaction ? (Transaction.vat_amount ? Transaction.vat_amount.toString().replace(".", ",") : null) : null,
                amount_with_vat: Transaction ? (Transaction.amount_with_vat ? Transaction.amount_with_vat.toString().replace(".", ",") : null) : null,
                amount_without_vat: Transaction ? (Transaction.amount_without_vat ? Transaction.amount_without_vat.toString().replace(".", ",") : null) : null,
                is_VAT_Intracomm: Transaction ? (Transaction.is_VAT_Intracomm === true ? true : false) : false,
                calc_RefTransaction: Transaction ? Transaction.calc_RefTransaction : null,
                createdAt: Transaction ? (Transaction.createdAt ? format(parseISO(Transaction.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: Transaction ? (Transaction.updatedAt ? format(parseISO(Transaction.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                IsReconciled: Transaction ? Transaction.IsReconciled : null,
                Reconciliations: Transaction ? Transaction.Reconciliations : null,
            },
            SelectOptions: {
                ref_Supplier: SuppliersList,
                ref_Customer: CustomerList,
                CounterpartyBankAccount_Id: BankAccountsOptions,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                Statements_File_Path: Statements_File_Path,
            },
            InitiaFormErrors: InitialFormErrors,
            InitialFormErrorMain: FormErrorMain,
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Transaction: {
                        Updatable: {
                            accounting_Justification_needed: values.accounting_Justification_needed,
                            accounting_Justification_Provided: values.accounting_Justification_Provided,
                            accounting_Justification_Provided_Date: values.accounting_Justification_Provided_Date,
                            vat_amount: values.vat_amount,
                            amount_with_vat: values.amount_with_vat,
                            amount_without_vat: values.amount_without_vat,
                            is_VAT_Intracomm: values.is_VAT_Intracomm,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <Page WithPerfectScrollBar={true}>
            <FormWithApi
                // WithCardModal={Id ? false : true}
                WithCardModal={OpenInModal}
                CardModalOptions={{
                    // ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    CardMaxWidth: "800px",
                    CardHeight: "700px",
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : true) : true}
                OpenInViewMode={false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={AccountingTransactionEntryEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "Reset"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
};

export default Component;
