import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "../../../../../../artibulles-cis/CardModal/CardModal";
import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import {toast} from "react-toastify";
import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, addHours} from "date-fns";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardContent: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
    },
    CardButtons: {
        flex: "0 1 auto",
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
    },
    CardTitle: {
        display: "flex",
        flex: "0 0 40px",
        justifyContent: "flex-start",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    Title: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-start",
        padding: "0px 5px",
    },
    FormWrapper: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        color: "black",
        textAlign: "left",
        margin: "0px 0px 20px 0px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        display: "flex",
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        flex: "0 1 auto",
        display: "flex",
    },
    ButtonBarExtend: {
        margin: "0px 5px",
        padding: "0px 5px",
        fontSize: "0.75rem",
        fontWeight: "400",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const {FormValuesParent, HandleCloseRecurrence} = props;
    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Loading, setLoading] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [FormInitialized, setFormInitialized] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [NewRecurrence, setNewRecurrence] = useState(true);
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FormValues, setFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [Invalid, setInvalid] = useState(true); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [TempFormErrors, setTempFormErrors] = useState({}); //HNDLING FORM ERRORS

    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (!APICallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data", Data);
                        let InitFieldOptions = {
                            Repeat: Data.Repeat,
                            EndRecurrenceType: Data.EndRecurrenceType,
                            IntervalOnDay: Data.IntervalOnDay,
                            IntervalRepeatEveryYear_MonthNumber: Data.IntervalRepeatEveryYear_MonthNumber,
                            IntervalOnDayMonthOrYear: Data.IntervalOnDayMonthOrYear,
                            GenerateRecurrentTasksOn: Data.GenerateRecurrentTasksOn,
                            GenerateRecurrence_When: Data.GenerateRecurrence_When,
                        };

                        let TaskRecurrence = Data.TaskRecurrence;

                        let InitFormValues = {
                            ApiID: TaskRecurrence ? (TaskRecurrence._id ? TaskRecurrence._id : "New") : "New",
                            StartRecurrence_DateIso: FormValuesParent.due_date_Iso,
                            Repeat: TaskRecurrence ? (TaskRecurrence.Repeat ? TaskRecurrence.Repeat : null) : null,
                            EndRecurrenceType: TaskRecurrence ? (TaskRecurrence.EndRecurrenceType ? TaskRecurrence.EndRecurrenceType : null) : null,
                            IntervalRepeatEvery: TaskRecurrence ? (TaskRecurrence.IntervalRepeatEvery ? TaskRecurrence.IntervalRepeatEvery : null) : null,
                            GenerateRecurrentTasks_NumberOfOcurrence: TaskRecurrence
                                ? TaskRecurrence.GenerateRecurrentTasks_NumberOfOcurrence
                                    ? TaskRecurrence.GenerateRecurrentTasks_NumberOfOcurrence
                                    : 1
                                : 1,
                            GenerateRecurrentTasksOn: TaskRecurrence ? (TaskRecurrence.GenerateRecurrentTasksOn ? TaskRecurrence.GenerateRecurrentTasksOn : "01") : "01",
                            GenerateRecurrence_When: TaskRecurrence ? (TaskRecurrence.GenerateRecurrence_When ? TaskRecurrence.GenerateRecurrence_When : "01") : "01",
                            Calc_RecurrenceMessage: TaskRecurrence ? (TaskRecurrence.Calc_RecurrenceMessage ? TaskRecurrence.Calc_RecurrenceMessage : null) : null,
                            EndAfterRecurrenceTotal: TaskRecurrence ? (TaskRecurrence.EndAfterRecurrenceTotal ? TaskRecurrence.EndAfterRecurrenceTotal : null) : null,
                            EndOnDateIso_Input: TaskRecurrence
                                ? TaskRecurrence.EndOnDateIso_Input
                                    ? TaskRecurrence.EndOnDateIso_Input
                                    : formatISO(new Date(Date.now()))
                                : formatISO(new Date(Date.now())),
                        };
                        setInitialFormValues(InitFormValues);
                        setFormValues(InitFormValues);
                        setFieldOptions(InitFieldOptions);
                        setFormInitialized(true);
                        // // console.log("Data", Data);
                        // if (IsQueryiesInitialized === false) {
                        //     console.log("Initialize Form");
                        //     let InitFormValues = {
                        //         is_personal: "All",
                        //         is_completed: "Due",
                        //         late_status: "All",
                        //     };

                        //     let InitFieldOptions = {
                        //         is_completed: [
                        //             {label: "Due", value: "Due"},
                        //             {label: "All", value: "All"},
                        //             {label: "Completed", value: "Completed"},
                        //         ],
                        //         is_personal: [
                        //             {label: "Work", value: "Work"},
                        //             {label: "All", value: "All"},
                        //             {label: "Personal", value: "Personal"},
                        //         ],
                        //         late_status: [
                        //             {label: "Late", value: "Late"},
                        //             {label: "All", value: "All"},
                        //             {label: "Upcoming", value: "Upcoming"},
                        //         ],
                        //     };
                        //     let Today = new Date(Date.now());
                        //     let dateStartOfDay = formatISO(setMilliseconds(setSeconds(setMinutes(setHours(Today, 0), 0), 1), 0));
                        //     let dateEndOfDay = formatISO(setMilliseconds(setSeconds(setMinutes(setHours(Today, 23), 59), 59), 0));

                        //     InitFormValues.datestart = dateStartOfDay;
                        //     InitFormValues.dateend = dateEndOfDay;

                        //     setInitialFormValues(InitFormValues);
                        //     setFormValues(InitFormValues);
                        //     setFieldOptions(InitFieldOptions);
                        //     Ref_FormValues.current = InitFormValues;
                        //     Ref_FieldOptions.current = InitFieldOptions;

                        //     setIsQueryiesInitialized(true);
                        // }
                    }
                } else if (APICallType === "InitalizeTable") {
                    // if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    //     let Data = ApiRes.data.data;
                    //     if (Data) {
                    //         setNoData(false);
                    //     } else {
                    //         setNoData(true);
                    //     }
                    //     Ref_LocaleData.current = Data;
                    //     setReloadTable(true);
                    // }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (FormValuesParent.Is_Recurrent === true) {
            setNewRecurrence(false);
        } else {
            setNewRecurrence(true);
        }
        let ApiCall;
        if (FormValuesParent.Is_Recurrent === true) {
            ApiCall = {url: `${ProdURL}/api/tasks/task/recurrence/${FormValuesParent.ApiID}`, type: "get"};
        } else {
            ApiCall = {url: `${ProdURL}/api/tasks/task/recurrence/${FormValuesParent.ApiID}`, type: "post"};
        }

        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const handleSelectChange = (field, SelectedDetails) => {
        console.log("Handle Select Change");
        let SelectedValues = SelectedDetails.Value;
        let FieldPristine = SelectedDetails.Pristine;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        if (field === "Repeat") {
            // console.log("Repeat", SelectedValues);

            if (SelectedValues === "01") {
                UpdatedFormValues.Calc_RecurrenceMessage = `Task will occur every ${FormValues.IntervalRepeatEvery} days, starting on ${format(parseISO(FormValuesParent.due_date_Iso), "dd/MM/yyyy")}`;
            } else if (SelectedValues === "02") {
                UpdatedFormValues.Calc_RecurrenceMessage = `Task will occur every ${FormValues.IntervalRepeatEvery} weeks, starting on ${format(
                    parseISO(FormValuesParent.due_date_Iso),
                    "dd/MM/yyyy"
                )}`;
            } else if (SelectedValues === "03") {
                UpdatedFormValues.Calc_RecurrenceMessage = `Task will occur every ${FormValues.IntervalRepeatEvery} months, starting on ${format(
                    parseISO(FormValuesParent.due_date_Iso),
                    "dd/MM/yyyy"
                )}`;
            }
        }
        if (field === "GenerateRecurrentTasksOn") {
            if (SelectedValues === "01") {
            }
        }

        setFormValues(UpdatedFormValues);
        ValidateForm(field, SelectedValues);
    };
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;
        let FieldPristine = values.Pristine;
        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};
        if (field === "IntervalRepeatEvery") {
            let Repeat = FormValues.Repeat;
            if (Repeat === "01") {
                UpdateFormValues.Calc_RecurrenceMessage = `Task will occur every ${value} days, starting on ${format(parseISO(FormValuesParent.due_date_Iso), "dd/MM/yyyy")}`;
            } else if (Repeat === "02") {
                UpdateFormValues.Calc_RecurrenceMessage = `Task will occur every ${value} weeks, starting on ${format(parseISO(FormValuesParent.due_date_Iso), "dd/MM/yyyy")}`;
            } else if (Repeat === "03") {
                UpdateFormValues.Calc_RecurrenceMessage = `Task will occur every ${value} months, starting on ${format(parseISO(FormValuesParent.due_date_Iso), "dd/MM/yyyy")}`;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        ValidateForm(field, value);
    };
    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(TempFormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (TempFormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: TempFormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setTempFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...TempFormErrors};
        console.log("Validate Form FormValues", FormValues);
        //Dealing with empty fields
        if (!FormValues.Repeat) {
            UpdatedFormErrors.Repeat = "Cannot be empty";
        } else {
            delete UpdatedFormErrors.Repeat;
        }
        if (!FormValues.EndRecurrenceType) {
            UpdatedFormErrors.EndRecurrenceType = "Cannot be empty";
        } else {
            if (FormValues.EndRecurrenceType === "01") {
                delete UpdatedFormErrors.EndRecurrenceType;
                if (!FormValues.EndAfterRecurrenceTotal) {
                    UpdatedFormErrors.EndAfterRecurrenceTotal = "Cannot be empty";
                } else {
                    delete UpdatedFormErrors.EndAfterRecurrenceTotal;
                }
            } else {
                delete UpdatedFormErrors.EndRecurrenceType;
                delete UpdatedFormErrors.EndAfterRecurrenceTotal;
            }
        }

        if (field === "Repeat") {
            if (value) {
                if (value === "01") {
                    //Daily
                    if (!FormValues.IntervalRepeatEvery) {
                        UpdatedFormErrors.IntervalRepeatEvery = "Cannot be empty";
                    }
                }
                delete UpdatedFormErrors.Repeat;
            } else {
                UpdatedFormErrors.Repeat = "Cannot be empty";
            }
        }
        if (field === "IntervalRepeatEvery") {
            if (value) {
                delete UpdatedFormErrors.IntervalRepeatEvery;
            } else {
                UpdatedFormErrors.IntervalRepeatEvery = "Cannot be empty";
            }
        }
        if (field === "EndRecurrenceType") {
            if (value) {
                delete UpdatedFormErrors.EndRecurrenceType;
                if (value === "01") {
                    if (!FormValues.EndAfterRecurrenceTotal) {
                        UpdatedFormErrors.EndAfterRecurrenceTotal = "Cannot be empty";
                    } else {
                        delete UpdatedFormErrors.EndAfterRecurrenceTotal;
                    }
                } else {
                    delete UpdatedFormErrors.EndAfterRecurrenceTotal;
                }
            } else {
                UpdatedFormErrors.EndRecurrenceType = "Cannot be empty";
            }
        }
        if (field === "EndAfterRecurrenceTotal") {
            if (FormValues.EndRecurrenceType === "01") {
                if (value) {
                    delete UpdatedFormErrors.EndAfterRecurrenceTotal;
                } else {
                    UpdatedFormErrors.EndAfterRecurrenceTotal = "Cannot be empty";
                }
            } else {
                delete UpdatedFormErrors.EndAfterRecurrenceTotal;
            }
        }

        console.log("UpdatedFormErrors", UpdatedFormErrors);
        if (Object.keys(UpdatedFormErrors).length > 0) {
            setTempFormErrors(UpdatedFormErrors);
            // setFormErrors(UpdatedFormErrors);
            setInvalid(true);
        } else {
            setTempFormErrors({});
            // setFormErrors({});
            setInvalid(false);
        }
    };

    const HandleCloseCard = () => {
        HandleCloseRecurrence("Save");
    };
    const HandleSubmitForm = (SubmitType) => {
        console.log("Submit", SubmitType);
        if (SubmitType === "Quit") {
            HandleCloseRecurrence("QuitNoSave");
        } else if (SubmitType === "Create") {
            if (Object.keys(TempFormErrors).length > 0) {
                setFormErrors(TempFormErrors);
            } else {
                let FormData = {
                    Recurrence: FormValues,
                    TaskId: FormValuesParent.ApiID,
                };
                console.log("FormValues", FormValues);
                // setAPICallType("Create");
                // const ApiCall = {url: `${ProdURL}/api/tasks/task/recurrence/${FormValuesParent.ApiID}`, type: "put", data: FormData};
                // setLoading(true);
                // async function APIInternal() {
                //     const res = await APICallExternal(ApiCall);
                //     setApiRes(res);
                //     setLoading(false);
                // }
                // APIInternal();
            }
        } else if (SubmitType === "Update") {
        } else if (SubmitType === "Delete") {
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let TopBarContent = (
        <div className={classes.Title}>
            <div style={{flex: "1 1 auto", justifyContent: "center"}}>Task Recurrence</div>
        </div>
    );

    let FormComponent, FormButtons;
    if (FormInitialized === true) {
        FormComponent = (
            <div className={classes.FormWrapper}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="task_name"
                            InitialValue={FormValuesParent.task_name ? FormValuesParent.task_name : null}
                            FormValue={FormValuesParent.task_name ? FormValuesParent.task_name : null}
                            OutputValue={(values) => HandleUpdateFormValues("task_name", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Task name"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            // LabelMaxWidth="200px"
                            // LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                        <FormCompontent
                            Name="Repeat"
                            InitialValue={InitialFormValues.Repeat ? InitialFormValues.Repeat : null}
                            FormValue={FormValues.Repeat ? FormValues.Repeat : null}
                            OutputSelectedValues={(values) => handleSelectChange("Repeat", values)}
                            meta={{error: FormErrors.Repeat, invalid: FormErrors.Repeat ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("Repeat", error)}
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            SelectOptions={FieldOptions.Repeat}
                            Component="Select"
                            Variant="Outlined"
                            Label="Repeat"
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            LabelMaxWidth="70px"
                            LabelAlign="Left"
                            FieldMaxWidth="200px"
                            Required={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{display: `${FormValues.Repeat === "01" || FormValues.Repeat === "02" || FormValues.Repeat === "03" ? "flex" : "none"}`}}>
                    <div className={classes.FormField} style={{flex: "0 0 130px"}}>
                        <FormCompontent
                            Name="IntervalRepeatEvery"
                            InitialValue={InitialFormValues.IntervalRepeatEvery ? InitialFormValues.IntervalRepeatEvery : null}
                            FormValue={FormValues.IntervalRepeatEvery ? FormValues.IntervalRepeatEvery : null}
                            OutputValue={(values) => HandleUpdateFormValues("IntervalRepeatEvery", values)}
                            meta={{error: FormErrors.IntervalRepeatEvery, invalid: FormErrors.IntervalRepeatEvery ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("IntervalRepeatEvery", error)}
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            Component="Input"
                            Variant="Outlined"
                            Label="Every"
                            Mask={{
                                Type: "Number",
                                Options: {
                                    Min: 1,
                                    Max: FormValues.Repeat === "01" ? 6 : 100,
                                    Digits: 3,
                                    // Scale: 2,
                                    AllowNegative: false,
                                },
                            }}
                            LabelMaxWidth="70px"
                            FieldMaxWidth="60px"
                            LabelAlign="Left"
                            Required={true}
                        />
                    </div>
                    {`${FormValues.Repeat === "01" ? "Days" : FormValues.Repeat === "02" ? "Weeks" : FormValues.Repeat === "03" ? "Months" : null}`}
                </div>
                <div className={classes.FormMultilineFlex} style={{display: `${FormValues.Repeat ? "flex" : "none"}`}}>
                    <div className={classes.FormField} style={{flex: "0 0 170px"}}>
                        <FormCompontent
                            Name="EndRecurrenceType"
                            InitialValue={InitialFormValues.EndRecurrenceType ? InitialFormValues.EndRecurrenceType : null}
                            FormValue={FormValues.EndRecurrenceType ? FormValues.EndRecurrenceType : null}
                            OutputSelectedValues={(values) => handleSelectChange("EndRecurrenceType", values)}
                            SelectOptions={FieldOptions.EndRecurrenceType}
                            meta={{error: FormErrors.EndRecurrenceType, invalid: FormErrors.EndRecurrenceType ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("EndRecurrenceType", error)}
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            Component="Select"
                            Variant="Outlined"
                            Label="End"
                            MultiSelect={false}
                            // SortSelectOptions="Asc"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            LabelMaxWidth="70px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                            Required={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 50px", display: `${FormValues.EndRecurrenceType === "01" ? "flex" : "none"}`}}>
                        <FormCompontent
                            Name="EndAfterRecurrenceTotal"
                            InitialValue={InitialFormValues.EndAfterRecurrenceTotal ? InitialFormValues.EndAfterRecurrenceTotal : null}
                            FormValue={FormValues.EndAfterRecurrenceTotal ? FormValues.EndAfterRecurrenceTotal : null}
                            OutputValue={(values) => HandleUpdateFormValues("EndAfterRecurrenceTotal", values)}
                            meta={{error: FormErrors.EndAfterRecurrenceTotal, invalid: FormErrors.EndAfterRecurrenceTotal ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("EndAfterRecurrenceTotal", error)}
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            Component="Input"
                            Variant="Outlined"
                            // Label="Every"

                            Mask={{
                                Type: "Number",
                                Options: {
                                    Min: 1,
                                    Max: 100,
                                    Digits: 3,
                                    // Scale: 2,
                                    AllowNegative: false,
                                },
                            }}
                            // LabelMaxWidth="70px"
                            FieldMaxWidth="50px"
                            // LabelAlign="Left"

                            Required={true}
                        />
                        <div style={{marginLeft: "10px"}}>Ocurrences</div>
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 50px", display: `${FormValues.EndRecurrenceType === "02" ? "flex" : "none"}`}}>
                        <FormCompontent
                            Name="EndOnDateIso_Input"
                            InitialValue={InitialFormValues.EndOnDateIso_Input ? InitialFormValues.EndOnDateIso_Input : null}
                            FormValue={FormValues.EndOnDateIso_Input ? FormValues.EndOnDateIso_Input : null}
                            OutputValue={(values) => HandleUpdateFormValues("EndOnDateIso_Input", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="End Date"
                            NoMessage={false}
                            // meta={{error: FormErrors.date_calendarNokeyboard, invalid: FormErrors.date_calendarNokeyboard ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("date_calendarNokeyboard", error)}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="70px"
                            FieldMaxWidth="100px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{marginLeft: "10px", fontSize: "0.8rem", display: `${FormValues.Repeat ? "flex" : "none"}`}}>
                    {FormValues.Calc_RecurrenceMessage}
                </div>
                
                
                
            </div>
        );
        if (NewRecurrence === true) {
            FormButtons = (
                <div className={classes.ButtonBar}>
                    <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Create")} disabled={Invalid || Pristine}>
                        Create Series
                    </Button>
                    <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Quit")} Background="orange">
                        Quit
                    </Button>
                </div>
            );
        } else {
            FormButtons = (
                <div className={classes.ButtonBar}>
                    <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Update")} disabled={Invalid || Pristine}>
                        Update Series
                    </Button>
                    <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Quit")} Background="orange">
                        Quit
                    </Button>
                    <Button ClassExtendButton={classes.ButtonBarExtend} Height="25px" Width="130px" onClick={() => HandleSubmitForm("Delete")} Background="red">
                        Delete Series
                    </Button>
                </div>
            );
        }
    } else {
        FormComponent = null;
        FormButtons = null;
    }

    return (
        <CardModal
            CardID="TaskRecurrence_CardId"
            TopBarContent={TopBarContent}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={HandleCloseCard}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            CardMaxWidth="700px"
            // CardHeight="700px"
            CardWindowPaddingVertical="10px"
            CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="50px"

            ShowCard={true}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
        >
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            <div className={classes.CardMain}>
                <div className={classes.CardContent} style={{justifyContent: "flex-start"}}>
                    {FormComponent}
                    {FormButtons}
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default Component;
