import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {CloseIcon, Search} from "@artibulles-cis/react-icons";
import FieldTextInput from "../FieldTextInput/FieldTextInput";
import FieldTextInputMasked from "../FieldTextInputMasked/FieldTextInputMasked";
import {ImaskCurrency, ImaskDate, ImaskNumber, ImaskEmail, ImaskSuffix, ImaskPrefix, ImaskPattern} from "../utils/ImaskPattern";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    InputWrapper: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        flex: "0 1 auto",
        alignSelf: "stretch",
        // width : "100%"
    },
    ToolbarLeft: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
        borderRight: "1px solid grey",
        overflow: "hidden",
        justifyContent: "center",
    },
    ToolbarIconLeft: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        top: "0px",
    },
    ToolbarIconLeft_Hide: {
        top: "-50px",
    },
    ToolbarIconLeftAnimated: {
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        top: "50%",
        transform: "translate(0,-50%)",
    },
    ToolbarIconLeftAnimated_Hide: {
        // display: "none",
        top: "100px",
        transform: "translate(0,0)",
    },
    ToolbarRight: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
    },
    ToolBarIconWrapper_Right: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: "1px solid grey",
        alignSelf: "stretch",
    },
    ToolBarIcon: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "0px 5px",
        cursor: "pointer",
    },
}));

/**
 * Component Description
 */
const FieldTextInputMain = React.memo(function FieldTextInputMain(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputValue,
		FieldStatus,
        OutputError,
        Error,
        Name, //Important for AutoFill
        InternalToolbarLeft,
        AnimateToolBarLeft,
        InternalToolbarLeftIcon,
        AnimateToolbarLeftColor,
        FinalLabelColor,
        InternalToolbarRight,
        ShareFocusState,
        ShareHasValue,
        IsFocused,
        HasValue,
        Clearable,
        Searchable,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Disabled,
        Mask,
        LocalValidation,
        Type,
        Required,
        FinalBorderRadius,
        FieldMaxWidth,
        multiline,
        RowsToDisplay,
        MaxRows,
        OnFieldExit,

        //Dont Fold
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [FinalInputComponent, setFinalInputComponent] = useState(null);
    const [MaskedDefinition, setMaskedDefinition] = useState(null);
    const [MaskType, setMaskType] = useState(null); //Used to pass the value to the maskInput to manipulate the values if needed

    const [LocalValueForSearch, setLocalValueForSearch] = useState(null);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
        if (Mask) {
            //Need to check that the mask is correct before rendering anything
            let MaskProps, TypeMask;
            if (Mask.Type === "Currency") {
                MaskProps = ImaskCurrency(Mask.Options);
                TypeMask = "Currency";
            } else if (Mask.Type === "Date" || Mask.Type === "Time" || Mask.Type === "DateTime") {
                MaskProps = ImaskDate(Mask);
                TypeMask = "Date";
            } else if (Mask.Type === "Number") {
                MaskProps = ImaskNumber(Mask.Options);
                TypeMask = "Number";
            } else if (Mask.Type === "Email") {
                MaskProps = ImaskEmail(Mask.Options);
                TypeMask = "Email";
            } else if (Mask.Type === "Suffix") {
                MaskProps = ImaskSuffix(Mask.Options);
                TypeMask = "Pattern";
            } else if (Mask.Type === "Prefix") {
                MaskProps = ImaskPrefix(Mask.Options);
                TypeMask = "Pattern";
            } else if (Mask.Type === "Pattern") {
                MaskProps = ImaskPattern(Mask.Options);
                TypeMask = "Pattern";
            }
            if (MaskProps) {
                setFinalInputComponent("MaskedInput");
                setMaskType(TypeMask);
                setMaskedDefinition(MaskProps);
            } else {
                setFinalInputComponent("Input");
            }
        } else {
            setFinalInputComponent("Input");
        }
    }, [Mask]);
    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/
    const HandleClear = () => {
        //To clear the Value, we pass null to the formValue to reset the input
        if (OutputValue && typeof OutputValue === "function") {
            OutputValue({value: null, formattedValue: null});
        }
        ShareHasValue(false);
    };
    const HandleSearch = () => {
        //We only pass the Value when the Search Icon is clicked
        if (OutputValue && typeof OutputValue === "function") {
            OutputValue(LocalValueForSearch);
        }
    };
    const HandleOutputValue = (args) => {
        if (Searchable === false) {
            if (OutputValue && typeof OutputValue === "function") {
                OutputValue(args);
            }
        } else {
            //We just store the value here and wait until the Search is Clicked to pass the value
            setLocalValueForSearch(args);
        }
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var ToolbarComponentLeft, ToolbarComponentRight;
    var FinalClassTopIcon, FinalClassBottomIcon;
    if (InternalToolbarLeft) {
        if (AnimateToolBarLeft === true) {
            //When using any type of component

            if (IsFocused === true || HasValue === true) {
                FinalClassTopIcon = clsx(classes.ToolbarIconLeft, classes.ToolbarIconLeft_Hide);
                FinalClassBottomIcon = classes.ToolbarIconLeftAnimated;
            } else {
                FinalClassTopIcon = classes.ToolbarIconLeft;
                FinalClassBottomIcon = clsx(classes.ToolbarIconLeftAnimated, classes.ToolbarIconLeftAnimated_Hide);
            }

            ToolbarComponentLeft = (
                <div className={classes.ToolbarLeft}>
                    <div className={FinalClassTopIcon}>{InternalToolbarLeft}</div>
                    <div className={FinalClassBottomIcon}>{InternalToolbarLeft}</div>
                </div>
            );
        }
    } else if (InternalToolbarLeftIcon) {
        //When using Icons

        if (AnimateToolBarLeft === true) {
            if (IsFocused === true || HasValue === true) {
                FinalClassTopIcon = clsx(classes.ToolbarIconLeft, classes.ToolbarIconLeft_Hide);
                FinalClassBottomIcon = classes.ToolbarIconLeftAnimated;
            } else {
                FinalClassTopIcon = classes.ToolbarIconLeft;
                FinalClassBottomIcon = clsx(classes.ToolbarIconLeftAnimated, classes.ToolbarIconLeftAnimated_Hide);
            }
            if (AnimateToolbarLeftColor === true) {
                //We use the same color as for the label itself
                //We need to clone first the Icon
                let ClonedIcon = React.cloneElement(InternalToolbarLeftIcon, {
                    SVGFillColor: FinalLabelColor,
                    SVGStrokeColor: FinalLabelColor,
                    SVGStrokeHoverColor: FinalLabelColor,
                    SVGFillHoverColor: FinalLabelColor,
                });

                ToolbarComponentLeft = (
                    <div className={classes.ToolbarLeft}>
                        <div className={FinalClassTopIcon} style={{padding: "0px 5px"}}>
                            {ClonedIcon}
                        </div>
                        <div className={FinalClassBottomIcon} style={{padding: "0px 5px"}}>
                            {ClonedIcon}
                        </div>
                    </div>
                );
            } else {
                ToolbarComponentLeft = (
                    <div className={classes.ToolbarLeft}>
                        <div className={FinalClassTopIcon} style={{padding: "0px 5px"}}>
                            {InternalToolbarLeftIcon}
                        </div>
                        <div className={FinalClassBottomIcon} style={{padding: "0px 5px"}}>
                            {InternalToolbarLeftIcon}
                        </div>
                    </div>
                );
            }
        }
    } else {
        ToolbarComponentLeft = null;
    }

    if (Clearable === true || Searchable === true) {
        var ClearIconComponent, SearchIconComponent;

        if (Clearable === true) {
            ClearIconComponent = (
                <div className={classes.ToolBarIconWrapper_Right}>
                    <div className={classes.ToolBarIcon} onClick={HandleClear}>
                        <CloseIcon IconSize="18px" FrameFillColor="" FrameStrokeColor="" InnerPadding="35" FrameStrokeWidth="10" SVGStrokeWidth="10" SVGStrokeHoverColor="black" />
                    </div>
                </div>
            );
        }
        if (Searchable === true) {
            SearchIconComponent = (
                <div className={classes.ToolBarIconWrapper_Right}>
                    <div className={classes.ToolBarIcon} onClick={HandleSearch}>
                        <Search IconSize="18px" SVGFillColor="none" SVGStrokeWidth="10" SVGFillHoverColor="none" SVGStrokeHoverColor="black" />
                    </div>
                </div>
            );
        }
        ToolbarComponentRight = (
            <div className={classes.ToolbarRight}>
                {ClearIconComponent}
                {SearchIconComponent}
            </div>
        );
    } else if (InternalToolbarRight) {
        ToolbarComponentRight = <div className={classes.ToolbarRight}>{InternalToolbarRight}</div>;
    }

    var FormElement;
    if (FinalInputComponent === "Input") {
        FormElement = (
            <div className={classes.InputWrapper}>
                {ToolbarComponentLeft}
                <FieldTextInput
                    HandleUpdateRefInput={HandleUpdateRefInput}
                    Style={Style}
                    InitialValue={InitialValue}
                    FormValue={FormValue}
                    OutputValue={HandleOutputValue}
					FieldStatus={FieldStatus}
                    Error={Error}
                    ShareFocusState={ShareFocusState}
                    ShareHasValue={ShareHasValue}
                    OutputError={OutputError}
                    ReadOnly={ReadOnly}
                    Disabled={Disabled}
                    Name={Name}
                    LocalValidation={LocalValidation}
                    Type={Type}
                    Required={Required}
                    FinalBorderRadius={FinalBorderRadius}
                    Clearable={Clearable}
                    Searchable={Searchable}
                    FieldMaxWidth={FieldMaxWidth}
                    multiline={multiline}
                    RowsToDisplay={RowsToDisplay}
                    MaxRows={MaxRows}
                    OnFieldExit={OnFieldExit}
                />
                {ToolbarComponentRight}
            </div>
        );
    } else if (FinalInputComponent === "MaskedInput") {
        FormElement = (
            <div className={classes.InputWrapper}>
                {ToolbarComponentLeft}
                <FieldTextInputMasked
                    Mask={MaskedDefinition}
                    MaskType={MaskType}
                    HandleUpdateRefInput={HandleUpdateRefInput}
                    Style={Style}
                    InitialValue={InitialValue}
                    Error={Error}
                    FormValue={FormValue}
                    OutputValue={OutputValue}
					FieldStatus={FieldStatus}
                    OutputError={OutputError}
                    ShareFocusState={ShareFocusState}
                    ShareHasValue={ShareHasValue}
                    ReadOnly={ReadOnly}
                    Disabled={Disabled}
                    Name={Name}
                    LocalValidation={LocalValidation}
                    Required={Required}
                    FinalBorderRadius={FinalBorderRadius}
                    Clearable={Clearable}
                    Searchable={Searchable}
                    FieldMaxWidth={FieldMaxWidth}
                />
                {ToolbarComponentRight}
            </div>
        );
    } else if (FinalInputComponent === "TextArea") {
    } else {
        FormElement = null;
    }

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldTextInputMain.defaultProps = {
    InitialValue: null,
    FormValue: null,
    OutputValue: null,
	FieldStatus : null,
    Error: null,
    Name: null,
    ShareFocusState: null,
    ShareHasValue: null,
    multiline: false,
    RowsToDisplay: "1",
    MaxRows: 3,
    OnFieldExit: null,
    Mask: null,
};

FieldTextInputMain.propTypes = {
    /**
     * InitialValue :
     */
    InitialValue: PropTypes.any,
    /**
     * FormValue :
     */
    FormValue: PropTypes.any,
    /**
     * OutputValue :
     */
    OutputValue: PropTypes.any,
    /**
     * Error :
     */
    Error: PropTypes.any,
    /**
     * Name :
     */
    Name: PropTypes.any,
    /**
     * ShareFocusState :
     */
    ShareFocusState: PropTypes.any,
    /**
     * ShareHasValue :
     */
    ShareHasValue: PropTypes.any,
    /**
     * multiline :
     */
    multiline: PropTypes.any,
    /**
     * RowsToDisplay :
     */
    RowsToDisplay: PropTypes.any,
    /**
     * MaxRows :
     */
    MaxRows: PropTypes.any,
    /**
     * OnFieldExit :
     */
    OnFieldExit: PropTypes.any,
    /**
     * Mask :
     */
    Mask: PropTypes.any,
};

export default FieldTextInputMain;
