/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch, Redirect} from "react-router-dom";

import ContactTabNav from "./ContactTabNav";
import ContactNew from "./ContactEdit/ContactNew";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/crm/contacts">
                    <Redirect to="/crm/contacts/tables" />
                </Route>
                <Route path="/crm/contacts/tables">
                    <ContactTabNav />
                </Route>
                <Route exact path="/crm/contacts/createcontact">
                    <ContactNew Id="new" RedirectToOnClosingUrl="/crm/contacts/tables" />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
