/* eslint-disable */
import React, {useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormDev from "../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";


import ExpandableBox from "../../../../../artibulles-cis/ExpandableBox/ExpandableBox";

/************** NEW COMPONENTS **************/

import FormCompontent from "../../../../../artibulles-cis/FormComponent/FormCompontent";
import {ArrowFilledLeft, ArrowFilledRight, AvatarV2, Login} from "@artibulles-cis/react-icons";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "60px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 50px",
        boxSizing: "border-box",
        borderRadius: "3px",
        padding: "4px",
        border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 10px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "22px",
        margin: "0px",
    },

    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 10px 10px",
    },
    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 10px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    React.useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "ConditionalSelect1") {
            //We update the Options of the ConditionalSelect2
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect1.forEach((elem) => {
                if (elem.ref_ConditionalSelect1 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect2: SubOptions}});
            //We also need to reset the Conditional 2;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect2: null}});
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY
    const UpdateFormFiles = (filesPath, fieldName) => {
        if (fieldName) {
            FilesUploaders[fieldName] = filesPath;
            setFilesUploaders(FilesUploaders);
        }
    };
    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function") {
            //If there are new created options, we need to pass them too with the value
            NewFieldOptions;
            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);

            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    //Get the IntialValue
                    let InitialValue = InitialFormValues[key];
                    let Value = FormValues[key];
                    if (InitialValue !== Value) {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
                NewCreatedOptions: NewFieldOptions,
            };

            OnSubmitExternal(FullSubmission);
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    var FinalFormComponent;
    if (FormDataInitReady === true) {
        FinalFormComponent = (
            <div className={classes.Artiweb_form_Wrapper_Flex}>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Select</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="standard_input"
                                    InitialValue={InitialFormValues.standard_input ? InitialFormValues.standard_input : null}
                                    FormValue={FormValues.standard_input ? FormValues.standard_input : null}
                                    OutputValue={(values) => HandleUpdateFormValues("standard_input", values)}
                                    Component="Input"
                                    Variant="OutlinedLabel"
                                    Label="Normal Outlined"
                                    NoMessage={false}
                                    meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("normal", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="simple_select"
                                    InitialValue={InitialFormValues.simple_select ? InitialFormValues.simple_select : null}
                                    FormValue={FormValues.simple_select ? FormValues.simple_select : null}
                                    OutputSelectedValues={(values) => handleSelectChange("simple_select", values)}
                                    SelectOptions={FieldOptions.simple_select}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Simple select"
                                    NoMessage={false}
                                    meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("normal", error)}
                                    // MultiSelect={true}
                                    // ControlledEditViewMode={true}
                                    // EditModeActive={FormViewMode === "Edit" ? true : false}
                                    // SetFormEditMode={HandleEnableEditMode}
                                    SortSelectOptions="Dsc"
                                    SortLastItemName={{Exact: false, Name: "Arnaud"}}
                                    // SortIcons={true}
                                    // Required={true}
                                    // ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="multi_select"
                                    InitialValue={InitialFormValues.multi_select ? InitialFormValues.multi_select : null}
                                    FormValue={FormValues.multi_select ? FormValues.multi_select : null}
                                    OutputSelectedValues={(values) => handleSelectChange("multi_select", values)}
                                    SelectOptions={FieldOptions.multi_select}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Multi select"
                                    NoMessage={false}
                                    meta={{error: FormErrors.multi_select, invalid: FormErrors.multi_select ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("multi_select", error)}
                                    MultiSelect={true}
                                    ControlledEditViewMode={true}
                                    EditModeActive={FormViewMode === "Edit" ? true : false}
                                    SetFormEditMode={HandleEnableEditMode}
                                    SortSelectOptions="Dsc"
                                    SortLastItemName={{Exact: false, Name: "Arnaud"}}
                                    SortIcons={true}
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="create_single"
                                    InitialValue={InitialFormValues.create_single ? InitialFormValues.create_single : null}
                                    FormValue={FormValues.create_single ? FormValues.create_single : null}
                                    OutputSelectedValues={(values) => handleSelectChange("create_single", values)}
                                    SelectOptions={FieldOptions.create_single}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Creatable single"
                                    NoMessage={false}
                                    meta={{error: FormErrors.create_single, invalid: FormErrors.create_single ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("create_single", error)}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="create_multiple"
                                    InitialValue={InitialFormValues.create_multiple ? InitialFormValues.create_multiple : null}
                                    FormValue={FormValues.create_multiple ? FormValues.create_multiple : null}
                                    OutputSelectedValues={(values) => handleSelectChange("create_multiple", values)}
                                    SelectOptions={FieldOptions.create_multiple}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Creatable Multiple"
                                    NoMessage={false}
                                    meta={{error: FormErrors.create_multiple, invalid: FormErrors.create_multiple ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("create_multiple", error)}
                                    MultiSelect={true}
                                    SortSelectOptions="Asc"
                                    AllowCreateOption={true}
                                    CreateDeleteIfUnselected={false}
                                    UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_multiple", options)}
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="ConditionalSelect1"
                                    InitialValue={InitialFormValues.ConditionalSelect1 ? InitialFormValues.ConditionalSelect1 : null}
                                    FormValue={FormValues.ConditionalSelect1 ? FormValues.ConditionalSelect1 : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ConditionalSelect1", values)}
                                    SelectOptions={FieldOptions.ConditionalSelect1}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Conditional 1"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ConditionalSelect1, invalid: FormErrors.ConditionalSelect1 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ConditionalSelect1", error)}
                                    MultiSelect={false}
                                    // SortSelectOptions="Asc"
                                    // AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="ConditionalSelect2"
                                    InitialValue={InitialFormValues.ConditionalSelect2 ? InitialFormValues.ConditionalSelect2 : null}
                                    FormValue={FormValues.ConditionalSelect2 ? FormValues.ConditionalSelect2 : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ConditionalSelect2", values)}
                                    SelectOptions={FieldOptions.ConditionalSelect2}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Conditional 2"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ConditionalSelect2, invalid: FormErrors.ConditionalSelect2 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ConditionalSelect2", error)}
                                    MultiSelect={false}
                                    Disabled={FormValues.ConditionalSelect1 ? false : true}
                                    Clearable={false}

                                    // SortSelectOptions="Asc"
                                    // AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="ConditionalSelect3"
                                    InitialValue={InitialFormValues.ConditionalSelect3 ? InitialFormValues.ConditionalSelect3 : null}
                                    FormValue={FormValues.ConditionalSelect3 ? FormValues.ConditionalSelect3 : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ConditionalSelect3", values)}
                                    SelectOptions={FieldOptions.ConditionalSelect3}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Conditional 3"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ConditionalSelect3, invalid: FormErrors.ConditionalSelect3 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ConditionalSelect3", error)}
                                    MultiSelect={false}
                                    Disabled={FormValues.ConditionalSelect2 ? false : true}

                                    // SortSelectOptions="Asc"
                                    // AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <ExpandableBox
                    TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Different variant and options</h3>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.FormLeft}>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="readOnly"
                                    InitialValue="01"
                                    FormValue="01"
                                    // OutputSelectedValues={(values) => handleSelectChange("simple_select", values)}
                                    SelectOptions={[{label: "Readonly select", value: "01"}]}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="ReadOnly"
                                    ReadOnly={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="disabled"
                                    InitialValue="01"
                                    FormValue="01"
                                    // OutputSelectedValues={(values) => handleSelectChange("simple_select", values)}
                                    SelectOptions={[{label: "Disabled", value: "01"}]}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Disabled"
                                    Disabled={true}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="simple_select"
                                    InitialValue={InitialFormValues.simple_select ? InitialFormValues.simple_select : null}
                                    FormValue={FormValues.simple_select ? FormValues.simple_select : null}
                                    OutputSelectedValues={(values) => handleSelectChange("simple_select", values)}
                                    SelectOptions={FieldOptions.simple_select}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Sort and search"
                                    NoMessage={false}
                                    meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("normal", error)}
                                    // MultiSelect={true}
                                    // ControlledEditViewMode={true}
                                    // EditModeActive={FormViewMode === "Edit" ? true : false}
                                    // SetFormEditMode={HandleEnableEditMode}
                                    SortSelectOptions="Dsc"
                                    SortLastItemName={{Exact: false, Name: "Arnaud"}}
                                    SortIcons={true}
                                    // Required={true}
                                    // ReadOnly={true}
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="standard_input"
                                    InitialValue={InitialFormValues.standard_input ? InitialFormValues.standard_input : null}
                                    FormValue={FormValues.standard_input ? FormValues.standard_input : null}
                                    OutputValue={(values) => HandleUpdateFormValues("standard_input", values)}
                                    Component="Input"
                                    Variant="Outlined"
                                    Label="Normal Outlined"
                                    NoMessage={false}
                                    meta={{error: FormErrors.normal, invalid: FormErrors.normal ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("normal", error)}
                                    // LegendOffsetHeight="0"
                                    // LegendOffsetWidth="0"
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="create_single"
                                    InitialValue={InitialFormValues.create_single ? InitialFormValues.create_single : null}
                                    FormValue={FormValues.create_single ? FormValues.create_single : null}
                                    OutputSelectedValues={(values) => handleSelectChange("create_single", values)}
                                    SelectOptions={FieldOptions.create_single}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Creatable single"
                                    NoMessage={false}
                                    meta={{error: FormErrors.create_single, invalid: FormErrors.create_single ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("create_single", error)}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 400px"}}>
                                <FormCompontent
                                    Name="create_multiple"
                                    InitialValue={InitialFormValues.create_multiple ? InitialFormValues.create_multiple : null}
                                    FormValue={FormValues.create_multiple ? FormValues.create_multiple : null}
                                    OutputSelectedValues={(values) => handleSelectChange("create_multiple", values)}
                                    SelectOptions={FieldOptions.create_multiple}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Creatable Multiple"
                                    NoMessage={false}
                                    meta={{error: FormErrors.create_multiple, invalid: FormErrors.create_multiple ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("create_multiple", error)}
                                    MultiSelect={true}
                                    SortSelectOptions="Asc"
                                    AllowCreateOption={true}
                                    CreateDeleteIfUnselected={false}
									UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_multiple", options)}
									LabelMaxWidth="200px"
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="ConditionalSelect1"
                                    InitialValue={InitialFormValues.ConditionalSelect1 ? InitialFormValues.ConditionalSelect1 : null}
                                    FormValue={FormValues.ConditionalSelect1 ? FormValues.ConditionalSelect1 : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ConditionalSelect1", values)}
                                    SelectOptions={FieldOptions.ConditionalSelect1}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Conditional 1"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ConditionalSelect1, invalid: FormErrors.ConditionalSelect1 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ConditionalSelect1", error)}
                                    MultiSelect={false}
                                    // SortSelectOptions="Asc"
                                    // AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="ConditionalSelect2"
                                    InitialValue={InitialFormValues.ConditionalSelect2 ? InitialFormValues.ConditionalSelect2 : null}
                                    FormValue={FormValues.ConditionalSelect2 ? FormValues.ConditionalSelect2 : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ConditionalSelect2", values)}
                                    SelectOptions={FieldOptions.ConditionalSelect2}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Conditional 2"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ConditionalSelect2, invalid: FormErrors.ConditionalSelect2 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ConditionalSelect2", error)}
                                    MultiSelect={false}
                                    Disabled={FormValues.ConditionalSelect1 ? false : true}
                                    Clearable={false}

                                    // SortSelectOptions="Asc"
                                    // AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                            <div className={classes.FormField} style={{flex: "1 1 250px"}}>
                                <FormCompontent
                                    Name="ConditionalSelect3"
                                    InitialValue={InitialFormValues.ConditionalSelect3 ? InitialFormValues.ConditionalSelect3 : null}
                                    FormValue={FormValues.ConditionalSelect3 ? FormValues.ConditionalSelect3 : null}
                                    OutputSelectedValues={(values) => handleSelectChange("ConditionalSelect3", values)}
                                    SelectOptions={FieldOptions.ConditionalSelect3}
                                    Component="Select"
                                    Variant="OutlinedLabel"
                                    Label="Conditional 3"
                                    NoMessage={false}
                                    meta={{error: FormErrors.ConditionalSelect3, invalid: FormErrors.ConditionalSelect3 ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("ConditionalSelect3", error)}
                                    MultiSelect={false}
                                    Disabled={FormValues.ConditionalSelect2 ? false : true}

                                    // SortSelectOptions="Asc"
                                    // AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
                                    // UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
                                />
                            </div>
                        </div>
                        <div className={classes.FormMultilineFlex}>
                            <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                                <FormCompontent
                                    Name="create_single"
                                    InitialValue={InitialFormValues.create_single ? InitialFormValues.create_single : null}
                                    FormValue={FormValues.create_single ? FormValues.create_single : null}
                                    OutputSelectedValues={(values) => handleSelectChange("create_single", values)}
                                    SelectOptions={FieldOptions.create_single}
                                    Component="Select"
                                    Variant="Outlined"
                                    Label="Creatable single"
                                    NoMessage={false}
                                    meta={{error: FormErrors.create_single, invalid: FormErrors.create_single ? true : false}}
                                    OutputError={(error) => HandleUpdateErrors("create_single", error)}
                                    MultiSelect={false}
                                    SortSelectOptions="Asc"
                                    AllowCreateOption={true}
                                    // CreateDeleteIfUnselected={}
									UpdateCreatableOptions={(options) => UpdateCreatableOptions("create_single", options)}
									InternalToolbarLeftIcon={<AvatarV2 IconSize="25px" SVGFillColor="black" />}
                                    AnimateToolBarLeft={true}
                                    AnimateToolbarLeftColor={true}
                                />
                            </div>
                        </div>
                    </div>
                </ExpandableBox>

                <div style={{height: "50px"}}></div>
                <FormDev
                    DevMode={DevMode}
                    FormValues={FormValues}
                    InitialFormValues={InitialFormValues}
                    FieldOptions={FieldOptions}
                    FieldSubOptionsFull={FieldSubOptionsFull}
                    FilesUploaders={FilesUploaders}
                    FormErrors={FormErrors}
                    NewFieldOptions={NewFieldOptions}
                    PristineDetails={PristineDetails}
                />
                {/* Button Bar */}
                <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                    {`${FormErrorMain ? FormErrorMain : null}`}
                </div>
                <FormSubmitButtons
                    ButtonSaveAsDraft={ButtonSaveAsDraft}
                    ButtonSaveAndQuit={ButtonSaveAndQuit}
                    ButtonQuitNoSave={ButtonQuitNoSave}
                    ButtonDelete={ButtonDelete}
                    ButtonPublish={ButtonPublish}
                    ButtonReset={ButtonReset}
                    ButtonNext={ButtonNext}
                    ButtonPrevious={ButtonPrevious}
                    HandleSubmitForm={HandleSubmitForm}
                    Invalid={Invalid}
                    Pristine={Pristine}
                    DisableToolTip={DisableToolTip}
                    FormViewMode={FormViewMode}
                />

                {/* Button Bar */}
            </div>
        );
    } else {
        FinalFormComponent = null;
    }
    return <div className={classes.FormWrapper}>{FinalFormComponent}</div>;
});
export default Component;
