/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory} from "react-router-dom";

// import NavSideBarLink from "@artibulles-cis/react/NavSideBarLink";
// import NavSideBarLinkAccordion from "@artibulles-cis/react/NavSideBarLinkAccordion";
// import NavSideBarLinkAccordionSubLink from "@artibulles-cis/react/NavSideBarLinkAccordionSubLink";
import NavSideBarLink from "../../../../artibulles-cis/NavSideBarLink/NavSideBarLink";
import NavSideBarLinkAccordion from "../../../../artibulles-cis/NavSideBarLinkAccordion/NavSideBarLinkAccordion";
import NavSideBarLinkAccordionSubLink from "../../../../artibulles-cis/NavSideBarLinkAccordionSubLink/NavSideBarLinkAccordionSubLink";
import {Company, AddressBook, Preview, Offer, TableView, Dashboard, AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    SideBarController_Icon_Logo: {
        padding: "5px",
        height: "30px",
        width: "auto",
    },
    SideBarController_Hidable_Wrapper: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Hidable_Wrapper_Hidden: {
        flex: "0 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarController_Title_Left: {
        flex: "0 1 auto",
        padding: "0px 0px 0px 5px",
        margin: "0px",
    },
    SideBarLink_Content: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        padding: "0px 20px 0px 0px",
    },
    SideBarLink_Icon: {
        flex: "0 0 10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px 0px 5px",
    },
    SideBarLink_Hidable_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        overflow: "hidden",
        wordWrap: "nowrap",
        whiteSpace: "nowrap",
    },
    SideBarLink_Content_Text: {
        flex: "1 0 auto",
        padding: "0px 0px 0px 10px",
        margin: "0px",
        color: "white",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
}));

const SideBarPanelProspects = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const MainRoute = "/financials/companyaccount/";
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCreateBankAccount = () => {
        History.push(`/financials/companyaccount/bankaccounts/account/new/edit`);
    };
    const HandleCreateCreditCard = () => {
        History.push(`/financials/companyaccount/creditcards/card/new/view`);
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}account`}
                SlugList={[`${MainRoute}account/dashboard`, `${MainRoute}account/accountsbank`, `${MainRoute}account/revenue`, `${MainRoute}account/spending`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                ScrollToLink={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Company IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Company Account</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}account/dashboard`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Dashboard IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Dashboard</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}account/accountsbank`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Preview IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Accounts (Bank)</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}account/revenue`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Preview IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Revenue Analysis</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}account/spending`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Preview IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Spending Analysis</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            <NavSideBarLinkAccordion
                Slug={`${MainRoute}bankaccounts`}
                SlugList={[`${MainRoute}bankaccounts/account`, `${MainRoute}bankaccounts/list`, `${MainRoute}bankaccounts/createaccount`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                ScrollToLink={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Company IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Bank Accounts</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}bankaccounts/account`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Preview IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Account Details</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}bankaccounts/list`} ScrollToLink={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Bank Accounts List</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink onClick={HandleCreateBankAccount} WithTree={true} ActiveLinkIndicator={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Add Bank Account</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>

            <NavSideBarLinkAccordion
                Slug={`${MainRoute}creditcards`}
                SlugList={[`${MainRoute}creditcards/card`, `${MainRoute}creditcards/list`, `${MainRoute}creditcards/card/create`]}
                ActiveLinkIndicator={true}
                WithTree={true}
                OpenOnClick={true}
                exact
                ControllerContent={
                    <div className={classes.SideBarLink_Content}>
                        <div className={classes.SideBarLink_Icon}>
                            <Company IconSize="24" SVGFillColor="white" />
                        </div>
                        <div className={classes.SideBarLink_Hidable_Wrapper}>
                            <p className={classes.SideBarLink_Content_Text}>Credit Cards</p>
                        </div>
                    </div>
                }
            >
                <React.Fragment>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}creditcards/card`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <Preview IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Card Details</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink ActiveLinkIndicator={true} WithTree={true} Slug={`${MainRoute}creditcards/list`}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <TableView IconSize="24" SVGStrokeColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Cards List</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                    <NavSideBarLinkAccordionSubLink onClick={HandleCreateCreditCard} ActiveLinkIndicator={true} WithTree={true}>
                        <div className={classes.SideBarLink_Content}>
                            <div className={classes.SideBarLink_Icon}>
                                <AddCircle IconSize="24" SVGFillColor="white" />
                            </div>
                            <div className={classes.SideBarLink_Hidable_Wrapper}>
                                <p className={classes.SideBarLink_Content_Text}>Add Credit Card</p>
                            </div>
                        </div>
                    </NavSideBarLinkAccordionSubLink>
                </React.Fragment>
            </NavSideBarLinkAccordion>
            <NavSideBarLink Exact={false} ActiveLinkIndicator={true} Slug={`${MainRoute}accounting`} IsActiveFromOutside={false}>
                <div className={classes.SideBarLink_Content}>
                    <div className={classes.SideBarLink_Icon}>
                        <TableView IconSize="24" SVGStrokeColor="white" />
                    </div>
                    <div className={classes.SideBarLink_Hidable_Wrapper}>
                        <p className={classes.SideBarLink_Content_Text}>Accounting</p>
                    </div>
                </div>
            </NavSideBarLink>
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default SideBarPanelProspects;
