import React, {useState, useEffect, useRef, useCallback, Fragment} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import {startOfMonth, formatISO, parseISO, getDay, setDate, startOfWeek, getISOWeek, getMonth, getDate, add, format, isSameDay, sub} from "date-fns";
import {ArrowCircleLeft, ArrowCircleRight, SettingsVertical} from "@artibulles-cis/react-icons";
import Button from "@artibulles-cis/react/Button";
import CalendarRollerPickers from "../CalendarRollerPickers/CalendarRollerPickers";
const {GenerateDaysMonthView, GetDaysWeekNameLocale, GetYearMonthNameLocale} = require("../utils/CalendarFunctions");

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {
    CalendarPopup: {
        position: "absolute",
        top: "20px",
        left: "0px",
        display: "flex",
        flexDirection: "column",
        zIndex: 10000,
        boxSizing: "border-box",
    },
    Day: {
        position: "relative",
        display: "flex",
        flex: "1 1 0",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        textAlign: "center",
        borderRadius: "3px",
        padding: "3px",
        margin: "1px",
        minWidth: "25px",
        cursor: "pointer",
    },

    DayIndicator: {
        position: "relative",
        display: "flex",
        flex: "1 1 0",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        textAlign: "center",
        borderRadius: "3px",
        padding: "3px",
        margin: "1px",
        minWidth: "25px",
        cursor: "default",
    },
};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CalendarPicker: {
        position: "relative",
    },
    CalendarPopupLight: theme.Calendar.Light.CalendarPopup,
    CalendarPopupDark: theme.Calendar.Dark.CalendarPopup,

    Header: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        flex: "0 1 auto",
    },
    Body: {
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        flex: "1 1 auto",
    },
    Footer: {
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        flex: "0 1 auto",
        alignItems: "center",
        justifyContent: "center",
    },
    HeaderToolBar: {
        flex: "0 1 auto",
        position: "relative",
        display: "flex",
        boxSizing: "border-box",
    },
    Header_ArrowIcon: {
        flex: "0 1 auto",
        position: "relative",
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
        padding: "2px",
        margin: "0px 2px",
        cursor: "pointer",
    },
    Header_YearMonthIndicator: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
        // padding: "2px",
        border: "1px solid grey",
        borderRadius: "5px",
        cursor: "pointer",
    },
    Header_YearMonthIndicator_Year: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        fontSize: "14px",
        padding: "0px 5px",
        cursor: "pointer",
    },

    Header_YearMonthIndicator_Month: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        fontSize: "13px",
        padding: "0px 5px",
        cursor: "pointer",
    },

    HeaderDaysIndicator: {
        flex: "1 1 auto",
        position: "relative",
        display: "flex",
        boxSizing: "border-box",
    },

    Month: {
        flex: "1 1 auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
    },

    Week: {
        flex: "1 1 auto",
        position: "relative",
        display: "flex",
        boxSizing: "border-box",
    },
    WeekNumber: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderRadius: "3px",
        padding: "2px",
        minWidth: "17px",
        cursor: "default",
    },
    DayDark: theme.Calendar.Dark.Day,
    DayLight: theme.Calendar.Light.Day,
    DayDarkDisabled: theme.Calendar.Dark.DayDisabled,
    DayLightDisabled: theme.Calendar.Light.DayDisabled,
    DaySelectedLight: theme.Calendar.Light.DaySelected,
    DaySelectedDark: theme.Calendar.Dark.DaySelected,
    DayDisabled: {
        color: "rgb(200, 200, 200)",
    },

    DayNotCurrentMonth: {
        color: "grey",
    },
    DayToday: {
        color: "rgb(66, 165, 245)",
    },
    RollerWrapper: {
        flex: "1 1 auto",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        justifyContent: "center",
    },
}));

/**
 * Component Description
 */
const Calendar = React.memo(function Calendar(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        PickerDateType,
        CalendarOptions,
        RollerOptions,
        //ViewMode
        MonthView, //Show days in month
        // YearView, //show all months
        // WeekView, //Show days in week
        // ShowWeekNumber, //Display the week number
        // PortalMode,

        IsOpenCalendar,
        //Top Toolbar
        //Bottom Toolbar

        //INTERNAL FUNCTIONS
        Ref_Calendar, //Pass a ref to expose the method
        InputCalendarDate,
        UpdateInputFromCalendar,
        //Methods
        // OnSelectDate,
        // OnMenuOpen,
        // OnMenuClose,
        // OnDateHover,
        // ClickedOutside,
        // ShareHasValue,
        // ShareFocusState,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** CONST *********************/

    //Generating the Final Calendar Options
    var CalendarOptionsInitial = {
        Theme: "Light",
        Locale: null,
        StartOfWeek: 1,
        TodayButton: true,
        SaveButton: true,
        ResetButton: false,
        ShowWeekNumber: true,
        MonthFormat: "",
    };
    var CalendarOptionsFinal = _.merge(CalendarOptionsInitial, CalendarOptions);

    var FinalTheme;
    if (CalendarOptionsFinal.Theme === "Dark") {
        FinalTheme = "Dark";
    } else {
        FinalTheme = "Light";
    }
    const PopupClass = FinalTheme === "Dark" ? classes.CalendarPopupDark : classes.CalendarPopupLight;
    const ArrowYearIndicatorColor = FinalTheme === "Dark" ? theme.Calendar.Dark.ArrowIndicators.YearColor : theme.Calendar.Light.ArrowIndicators.YearColor;
    const ArrowMonthIndicatorColor = FinalTheme === "Dark" ? theme.Calendar.Dark.ArrowIndicators.MonthColor : theme.Calendar.Light.ArrowIndicators.MonthColor;
    const DayClass = FinalTheme === "Dark" ? classes.DayDark : classes.DayLight;
    const DayClassSelected = FinalTheme === "Dark" ? classes.DaySelectedDark : classes.DaySelectedLight;

    var FinalLocale = CalendarOptionsFinal.Locale ? CalendarOptionsFinal.Locale : null;
    var FinalStartOfWeek;
    let ISW = parseInt(CalendarOptionsFinal.StartOfWeek);
    if (ISW === 0 || ISW === 1 || ISW === 2 || ISW === 3 || ISW === 4 || ISW === 5 || ISW === 6) {
        FinalStartOfWeek = ISW;
    } else {
        FinalStartOfWeek = 1;
    }

    var FinalTodayButton;
    if (CalendarOptionsFinal.TodayButton === false) {
        FinalTodayButton = false;
    } else {
        FinalTodayButton = true;
    }

    var FinalSaveButton;
    if (CalendarOptionsFinal.SaveButton === false) {
        FinalSaveButton = false;
    } else {
        FinalSaveButton = true;
    }
    var FinalResetButton;
    if (CalendarOptionsFinal.ResetButton === false) {
        FinalResetButton = false;
    } else {
        FinalResetButton = true;
    }
    var FinalShowWeekNumber;
    if (CalendarOptionsFinal.ShowWeekNumber === false) {
        FinalShowWeekNumber = false;
    } else {
        FinalShowWeekNumber = true;
    }
    var FinalMonthFormat = CalendarOptionsFinal.MonthFormat;
    //Generating the Final Roller Options
    var RollerOptionsInitial = {
        Theme: "Light",
        Locale: null,
        Format: "dd-LLL-yyyy",
        Alignement: "left",
        MinuteInterval: 1,
        SecondInterval: 1,
    };
    var RollerOptionsFinal = _.merge(RollerOptionsInitial, RollerOptions);
    var CustomRollerOptions = [],
        QuickRollerOptions;
    if (PickerDateType === "CalendarRoller") {
        var Index0, Index1, Index2;
        let FormatRollerArray = RollerOptionsFinal.Format.split("-");
        if (Array.isArray(FormatRollerArray) && FormatRollerArray.length === 3) {
            //Checking the first one
            if (FormatRollerArray[0] === "dd") {
                Index0 = {Roller: "Day", Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index0);
            } else if (FormatRollerArray[0] === "LL" || FormatRollerArray[0] === "LLL" || FormatRollerArray[0] === "LLLL") {
                Index0 = {Roller: "Month", Format: FormatRollerArray[0], Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index0);
            } else if (FormatRollerArray[0] === "yy" || FormatRollerArray[0] === "yyyy") {
                Index0 = {Roller: "Year", Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index0);
            } else {
                //Wrong Defintion, Default Custom type
                CustomRollerOptions = [{Roller: "Day"}, {Roller: "Month", Format: "LLL"}, {Roller: "Year"}];
                QuickRollerOptions = null;
            }
            if (FormatRollerArray[1] === "dd") {
                Index1 = {Roller: "Day", Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index1);
            } else if (FormatRollerArray[1] === "LL" || FormatRollerArray[1] === "LLL" || FormatRollerArray[1] === "LLLL") {
                Index1 = {Roller: "Month", Format: FormatRollerArray[1], Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index1);
            } else if (FormatRollerArray[1] === "yy" || FormatRollerArray[1] === "yyyy") {
                Index1 = {Roller: "Year", Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index1);
            } else {
                //Wrong Defintion, Default Custom type
                CustomRollerOptions = [{Roller: "Day"}, {Roller: "Month", Format: "LLL"}, {Roller: "Year"}];
                QuickRollerOptions = null;
            }
            if (FormatRollerArray[2] === "dd") {
                Index2 = {Roller: "Day", Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index2);
            } else if (FormatRollerArray[2] === "LL" || FormatRollerArray[2] === "LLL" || FormatRollerArray[2] === "LLLL") {
                Index2 = {Roller: "Month", Format: FormatRollerArray[2], Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index2);
            } else if (FormatRollerArray[2] === "yy" || FormatRollerArray[2] === "yyyy") {
                Index2 = {Roller: "Year", Alignement: RollerOptionsFinal.Alignement};
                CustomRollerOptions.push(Index2);
            } else {
                //Wrong Defintion, Default Custom type
                CustomRollerOptions = [{Roller: "Day"}, {Roller: "Month", Format: "LLL"}, {Roller: "Year"}];
                QuickRollerOptions = null;
            }
        } else {
            //Wrong Defintion, Default Custom type
            CustomRollerOptions = [{Roller: "Day"}, {Roller: "Month", Format: "LLL"}, {Roller: "Year"}];
            QuickRollerOptions = null;
        }
    } else if (PickerDateType === "TimeHM") {
        QuickRollerOptions = {Roller: "TimeHM", MinuteInterval: RollerOptionsFinal.MinuteInterval};
    } else if (PickerDateType === "TimeHMS") {
        QuickRollerOptions = {Roller: "TimeHMS", MinuteInterval: RollerOptionsFinal.MinuteInterval, SecondInterval: RollerOptionsFinal.SecondInterval};
    } else if (PickerDateType === "MonthYear") {
        QuickRollerOptions = {Roller: "MonthYear", MonthFormat: "L"};
    } else if (PickerDateType === "Year") {
        QuickRollerOptions = {Roller: "Year"};
    } else if (PickerDateType === "Month") {
        QuickRollerOptions = {Roller: "Month"};
    }

    /****************************** CONST *********************/

    /****************************** REFS *********************/
    const Ref_ArtibullesCalendar = useRef(null);
    if (Ref_Calendar) {
        Ref_ArtibullesCalendar = Ref_Calendar;
    }

    const Ref_CalendarPopup = useRef(null);

    /****************************** REFS *********************/

    /****************************** STATE *********************/
    const [Initialized, setInitialized] = useState(false);

    const [FinalComponent, setFinalComponent] = useState(null);
    const [HeaderComponent, setHeaderComponent] = useState(null);

    const [InitialCalendarViewDate, setInitialCalendarViewDate] = useState(null);
    const [CalendarViewDate, setCalendarViewDate] = useState(null);
    const [CalendarView, setCalendarView] = useState("Calendar"); //Calendar or Roller
    const [PopupDimensions, setPopupDimensions] = useState({width: null, height: null}); //Calendar or Roller
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    const GenerateComponent = useCallback(() => {
        var InputDate = CalendarViewDate;
        var DateObjInputCalendarDate = InputDate;

        if (DateObjInputCalendarDate) {
            InputDate = DateObjInputCalendarDate;

            if (InputDate) {
                if (PickerDateType === "Calendar") {
                    if (CalendarView === "Calendar") {
                        if (MonthView) {
                            let MinDate = CalendarOptions ? (CalendarOptions.Min ? parseISO(CalendarOptions.Min) : null) : null;
                            let MaxDate = CalendarOptions ? (CalendarOptions.Max ? parseISO(CalendarOptions.Max) : null) : null;
                            //Generating the rows with the days and week number
                            const MonthData = GenerateDaysMonthView({currentDate: InputDate, firstDayOfWeek: FinalStartOfWeek, MinDate: MinDate, MaxDate: MaxDate});
                            var MonthDivs = [];

                            for (var i = 0; i < MonthData.length; i++) {
                                let WeekData = MonthData[i];
                                let WeekDivs = [];
                                var WeekIndicatorDiv;
                                if (FinalShowWeekNumber === true) {
                                    WeekIndicatorDiv = <div className={classes.WeekNumber}>{WeekData.weekNumber}</div>;
                                    WeekDivs.push(WeekIndicatorDiv);
                                }

                                for (var j = 0; j < WeekData.days.length; j++) {
                                    let DayData = WeekData.days[j];

                                    let DayClassFinal;
                                    if (DayData.isDisabled === true) {
                                        DayClassFinal = FinalTheme === "Dark" ? classes.DayDarkDisabled : classes.DayLightDisabled;
                                    } else {
                                        DayClassFinal = DayClass;
                                    }

                                    let DayDiv = (
                                        <div
                                            className={clsx(
                                                DayClassFinal,
                                                DayData.isSelected ? DayClassSelected : null,
                                                DayData.isCurrentMonth ? null : classes.DayNotCurrentMonth,
                                                DayData.isToday ? classes.DayToday : null,
                                                DayData.isDisabled ? classes.DayDisabled : null
                                            )}
                                            style={{...Lockedstyles.Day, ...{cursor: DayData.isDisabled === true ? "not-allowed" : "default"}}}
                                            onClick={() => HandleSelectCalendarDay(DayData)}
                                        >
                                            {DayData.day}
                                        </div>
                                    );
                                    WeekDivs.push(DayDiv);
                                }
                                let WeekDiv = <div className={classes.Week}>{WeekDivs}</div>;
                                MonthDivs.push(WeekDiv);
                            }
                            let MonthDiv = <div className={classes.Month}>{MonthDivs}</div>;

                            //Generating the Header Component
                            //Getting the days name using datefns and locale
                            const DaysName = GetDaysWeekNameLocale({locale: FinalLocale, firstDayOfWeek: FinalStartOfWeek});
                            //Getting the Month Name for the Header display with Locale
                            const YearAndMonthLocale = GetYearMonthNameLocale({date: InputDate, locale: FinalLocale, monthFormat: FinalMonthFormat});
                            //Showing or Hiding the # title for the weekNumber
                            const WeekDivIndicatorTitle = FinalShowWeekNumber === true ? <div className={classes.WeekNumber}>#</div> : null;
                            var MonthHeader = (
                                <Fragment>
                                    <div className={classes.HeaderToolBar}>
                                        <div className={classes.Header_ArrowIcon} onClick={() => HandleYearChange("Prev")}>
                                            <ArrowCircleLeft IconSize="25px" SVGFillColor={ArrowYearIndicatorColor} />
                                        </div>
                                        <div className={classes.Header_ArrowIcon} onClick={() => HandleMonthChange("Prev")}>
                                            <ArrowCircleLeft IconSize="25px" SVGFillColor={ArrowMonthIndicatorColor} />
                                        </div>
                                        <div className={classes.Header_YearMonthIndicator} onClick={HandleYearMonthIndicatorClick}>
                                            <div className={classes.Header_YearMonthIndicator_Year}>{YearAndMonthLocale.year}</div>
                                            <div className={classes.Header_YearMonthIndicator_Month}>{YearAndMonthLocale.month}</div>
                                        </div>
                                        <div className={classes.Header_ArrowIcon} onClick={() => HandleMonthChange("Next")}>
                                            <ArrowCircleRight IconSize="25px" SVGFillColor={ArrowMonthIndicatorColor} />
                                        </div>
                                        <div className={classes.Header_ArrowIcon} onClick={() => HandleYearChange("Next")}>
                                            <ArrowCircleRight IconSize="25px" SVGFillColor={ArrowYearIndicatorColor} />
                                        </div>
                                    </div>
                                    <div className={classes.HeaderDaysIndicator}>
                                        {WeekDivIndicatorTitle}
                                        {DaysName.map((elem) => {
                                            return (
                                                <div style={{...Lockedstyles.DayIndicator}} className={classes.DayIndicator}>
                                                    {elem}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Fragment>
                            );

                            setFinalComponent(MonthDiv);
                            setHeaderComponent(MonthHeader);
                        }
                    } else {
                        const YearAndMonthLocale = GetYearMonthNameLocale({date: InputDate});
                        var MonthHeader = (
                            <Fragment>
                                <div className={classes.HeaderToolBar}>
                                    <div className={classes.Header_YearMonthIndicator} onClick={HandleYearMonthIndicatorClick}>
                                        <div className={classes.Header_YearMonthIndicator_Year}>{YearAndMonthLocale.year}</div>
                                        <div className={classes.Header_YearMonthIndicator_Month}>{YearAndMonthLocale.month}</div>
                                    </div>
                                </div>
                            </Fragment>
                        );
                        let MonthDiv = (
                            <div className={classes.RollerWrapper}>
                                <CalendarRollerPickers OutputDate={HandleDateChange} Rows="7" InitialDate={InputDate} QuickType={{Roller: "MonthYear"}} />
                            </div>
                        );
                        setFinalComponent(MonthDiv);
                        setHeaderComponent(MonthHeader);
                    }

                    setInitialized(true);
                } else if (PickerDateType === "CalendarRoller") {
                    let MonthDiv = (
                        <div className={classes.RollerWrapper}>
                            <CalendarRollerPickers
                                Locale={RollerOptionsFinal.Locale}
                                OutputDate={HandleDateChange}
                                Rows="7"
                                InitialDate={InputDate}
                                QuickType={QuickRollerOptions}
                                //
                                CustomType={CustomRollerOptions}
                            />
                        </div>
                    );
                    setFinalComponent(MonthDiv);
                } else if (PickerDateType === "TimeHM" || PickerDateType === "TimeHMS") {
                    let MonthDiv = (
                        <div className={classes.RollerWrapper}>
                            <CalendarRollerPickers
                                Locale={RollerOptionsFinal.Locale}
                                OutputDate={HandleDateChange}
                                Rows="7"
                                InitialDate={InputDate}
                                QuickType={QuickRollerOptions}
                                //
                                // CustomType={CustomRollerOptions}
                            />
                        </div>
                    );
                    setFinalComponent(MonthDiv);
                } else if (PickerDateType === "MonthYear") {
                    let MonthDiv = (
                        <div className={classes.RollerWrapper}>
                            <CalendarRollerPickers Locale={RollerOptionsFinal.Locale} OutputDate={HandleDateChange} Rows="7" InitialDate={InputDate} QuickType={QuickRollerOptions} />
                        </div>
                    );
                    setFinalComponent(MonthDiv);
                } else if (PickerDateType === "Year") {
                    let MonthDiv = (
                        <div className={classes.RollerWrapper}>
                            <CalendarRollerPickers Locale={RollerOptionsFinal.Locale} OutputDate={HandleDateChange} Rows="7" InitialDate={InputDate} QuickType={QuickRollerOptions} />
                        </div>
                    );
                    setFinalComponent(MonthDiv);
                } else if (PickerDateType === "Month") {
                    let MonthDiv = (
                        <div className={classes.RollerWrapper}>
                            <CalendarRollerPickers Locale={RollerOptionsFinal.Locale} OutputDate={HandleDateChange} Rows="7" InitialDate={InputDate} QuickType={QuickRollerOptions} />
                        </div>
                    );
                    setFinalComponent(MonthDiv);
                }
            }
        }
    }, [MonthView, CalendarView, CalendarViewDate]);

    const CalculateDimensions = useCallback(() => {
        if (Ref_CalendarPopup && Ref_CalendarPopup.current) {
            let Width = Ref_CalendarPopup.current.getBoundingClientRect().width;
            let Height = Ref_CalendarPopup.current.getBoundingClientRect().height;

            setPopupDimensions({width: `${Width}px`, height: `${Height}px`});
        }
    }, [Ref_CalendarPopup.current]);
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Generating the Initial Input Date
    useEffect(() => {
        if (InputCalendarDate) {
            let DateObjInputCalendarDate = new Date(InputCalendarDate);
            setCalendarViewDate(DateObjInputCalendarDate);
            setInitialCalendarViewDate(DateObjInputCalendarDate);
        } else {
            setCalendarViewDate(new Date(Date.now()));
        }
    }, [InputCalendarDate]);

    //Generating the components or switching views
    useEffect(() => {
        if (IsOpenCalendar === true) {
            GenerateComponent();
        }
    }, [CalendarView, GenerateComponent, IsOpenCalendar]);

    //Calculating the dimensions of the Card to prevent resizing when switching views
    useEffect(() => {
        if (PickerDateType === "Calendar") {
            if (IsOpenCalendar === true) {
                CalculateDimensions();
            }
        }
    }, [CalculateDimensions, IsOpenCalendar, PickerDateType]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    const HandleMonthChange = (direction) => {
        var UpdatedDate;
        if (direction === "Next") {
            UpdatedDate = add(CalendarViewDate, {months: 1});
        } else {
            UpdatedDate = sub(CalendarViewDate, {months: 1});
        }

        setCalendarViewDate(UpdatedDate);

        UpdateInputFromCalendar(UpdatedDate);
    };
    const HandleYearChange = (direction) => {
        var UpdatedDate;
        if (direction === "Next") {
            UpdatedDate = add(CalendarViewDate, {years: 1});
        } else {
            UpdatedDate = sub(CalendarViewDate, {years: 1});
        }

        setCalendarViewDate(UpdatedDate);
        UpdateInputFromCalendar(UpdatedDate);
    };
    const HandleViewToday = () => {
        setCalendarViewDate(new Date(Date.now()));
        UpdateInputFromCalendar(Date.now());
    };
    const HandleYearMonthIndicatorClick = () => {
        if (CalendarView === "Roller") {
            setCalendarView("Calendar");
        } else {
            setCalendarView("Roller");
        }
    };
    const HandleDateChange = (date) => {
        setCalendarViewDate(date);
        UpdateInputFromCalendar(date);
    };
    const HandleSelectCalendarDay = (dataDate) => {
        var UpdatedDate;
        if (dataDate.isCurrentMonth) {
            UpdatedDate = setDate(CalendarViewDate, dataDate.day);
        } else {
            //This is another month -> we need to use the real date and move to the correct month
            UpdatedDate = dataDate.dateLocale;
        }
        // console.log("dataDate", dataDate);
        // console.log("DateArg", date);
        // console.log("UpdatedDate", UpdatedDate);
        // console.log("CalendarViewDate", CalendarViewDate);
        if (FinalSaveButton === true) {
            if (dataDate.isDisabled === false) {
                setCalendarViewDate(UpdatedDate);
            }
        } else {
            if (dataDate.isDisabled === false) {
                setCalendarViewDate(UpdatedDate);
                UpdateInputFromCalendar(UpdatedDate, true);
            }
        }
    };

    const HandleSetButton = () => {
        UpdateInputFromCalendar(CalendarViewDate, true);
    };

    const HandleResetButton = () => {
        setCalendarViewDate(InitialCalendarViewDate);

        UpdateInputFromCalendar(InitialCalendarViewDate, true);
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDERING FUNCTIONS *********************/

    /****************************** RENDERING FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var FinalCalendar;
    var TodayButtonComponent, ResetButtonComponent, SaveButtonComponent;
    if (FinalTodayButton === true) {
        //
        if (PickerDateType === "TimeHM" || PickerDateType === "TimeHMS") {
            TodayButtonComponent = (
                <Button Width="80px" Height="20px" style={{margin: "0px 3px", fontSize: "14px"}} onClick={HandleViewToday}>
                    Now
                </Button>
            );
        } else {
            TodayButtonComponent = (
                <Button Width="80px" Height="20px" style={{margin: "0px 3px", fontSize: "14px"}} onClick={HandleViewToday}>
                    Today
                </Button>
            );
        }
    } else {
        if (PickerDateType === "MonthYear" || PickerDateType === "Year" || PickerDateType === "Month") {
            TodayButtonComponent = null;
        }
    }
    if (FinalResetButton === true) {
        ResetButtonComponent = (
            <Button Width="80px" Height="20px" style={{margin: "0px 3px", fontSize: "14px"}} onClick={HandleResetButton}>
                Reset
            </Button>
        );
    }
    if (FinalSaveButton === false) {
        SaveButtonComponent = null;
    } else {
        SaveButtonComponent = (
            <Button Width="80px" Height="20px" style={{margin: "0px 3px", fontSize: "14px"}} onClick={HandleSetButton}>
                Set
            </Button>
        );
    }

    if (IsOpenCalendar && FinalComponent) {
        FinalCalendar = (
            <div ref={Ref_ArtibullesCalendar} className={classes.CalendarPicker}>
                <div
                    ref={Ref_CalendarPopup}
                    className={PopupClass}
                    style={{...Lockedstyles.CalendarPopup, width: PopupDimensions.width ? PopupDimensions.width : null, height: PopupDimensions.height ? PopupDimensions.height : null}}
                >
                    <div className={classes.Header}>{HeaderComponent}</div>
                    <div className={classes.Body}>{FinalComponent}</div>
                    <div className={classes.Footer}>
                        {ResetButtonComponent}
                        {TodayButtonComponent}
                        {SaveButtonComponent}
                    </div>
                </div>
            </div>
        );
    } else {
        FinalCalendar = null;
    }

    return <Fragment>{FinalCalendar} </Fragment>;
    /****************************** RENDER *********************/
});

Calendar.defaultProps = {
    MonthView: true,
    CalendarOptions: {
        Theme: "Light",
        Locale: null,
        StartOfWeek: 1,
        TodayButton: true,
        SaveButton: false,
        ResetButton: false,
        ShowWeekNumber: true,
    },
};

Calendar.propTypes = {
    /**
     * MyProp :
     */
    MonthView: PropTypes.boolean,

    /**
     * MyProp :
     */
    CalendarOptions: PropTypes.object,
};

export default Calendar;
