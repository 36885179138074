import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "@artibulles-cis/react/Page";
import Button from "@artibulles-cis/react/Button";
import {UploadFile} from "@artibulles-cis/react-icons";
import Table from "../../../../../../../artibulles-cis/TableNew/Table";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {useHistory} from "react-router-dom";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    Title: {
        margin: "0 auto",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        // height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const SupplierImportFromBankAccount = React.memo(function SupplierImportFromBankAccount(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_ImportOneSupplierData = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const History = useHistory();

    const ColomnsInput = [
        {
            width: "270px",
            caption: "Account Number / Card Location",
            datafield: "counterparty_bankAccount_number",
            cellCustomRender: (value, rowData) => {
                if (value) {
                    return value;
                } else {
                    let CCLocation = rowData.location;
                    if (CCLocation) {
                        return CCLocation;
                    }
                }
            },
            fixedCol: false,
            searchable: true,
            datatype: "text",
            sortable: true,
            wrapColHeader: true,
            wrapCell: true,
        },
        {
            width: "250px",
            caption: "Account Name / Card Merchant",
            datafield: "counterparty_bankAccount_name",
            cellCustomRender: (value, rowData) => {
                if (value) {
                    return value;
                } else {
                    let CCLocation = rowData.merchant;
                    if (CCLocation) {
                        return CCLocation;
                    }
                }
            },
            fixedCol: false,
            searchable: true,
            datatype: "text",
            sortable: true,
            wrapColHeader: true,
            wrapCell: true,
        },
        {width: "150px", caption: "BIC", datafield: "counterparty_bankAccount_bic", fixedCol: false, searchable: true, sortable: true, datatype: "text", wrapCell: true},

        {width: "80px", caption: "Month", datafield: "calc_transaction_date_MMM", sortable: true, searchable: true, datatype: "text", wrapCell: true},
        {width: "80px", caption: "Year", datafield: "calc_transaction_date_yyyy", sortable: true, searchable: true, datatype: "text", wrapCell: true},

        {width: "100px", caption: "Source", datafield: "transaction_source", fixedCol: false, searchable: true, sortable: true, datatype: "text"},
        {
            width: "100px",
            caption: "Amount (Bank)",
            datafield: "banking_amount_transaction",
            cellFormatFunction: (value, rowData) => {
                if (value) {
                    return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
                }
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {
            width: "100px",
            caption: "Original Amount",
            datafield: "original_amount_transaction",
            cellFormatFunction: (value, rowData) => {
                if (value) {
                    let Currency = rowData.currency;
                    if (Currency) {
                        return new Intl.NumberFormat("de-DE", {style: "currency", currency: Currency, maximumFractionDigits: 2}).format(value);
                    }
                }
                // let AmountValue = rowData.banking_amount_transaction;
                // if (value === AmountValue) {
                //     return AmountValue;
                // } else {
                //     return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
                // }
            },
            fixedCol: false,
            searchable: true,
            datatype: "number",
            sortable: true,
            wrapColHeader: true,
        },
        {width: "auto", caption: "communication", datafield: "communication", searchable: true, datatype: "text", wrapCell: true},
    ];

    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        // FitParentContainer: true,
    };
    const TotalRow = {
        Show: false,
    };

    const HandleImportSupplier = (arg) => {
        console.log("arg", arg);
        if (arg) {
            let FormDataSupplier = {
                Company: {
                    Updatable: {
                        company: "test Import",
                        is_supplier: true,
                    },
                },
            };

            if (arg.transaction_source === "BankAccount") {
                let Name = arg.counterparty_bankAccount_name;
                if (!Name) {
                    Name = arg.communication;
                    //We need to use the communication to Support the user in creating the supplier
                }
                FormDataSupplier.Company.Updatable.company = Name;

                let BankAccount = {
                    account_number: arg.counterparty_bankAccount_number,
                    bic: arg.counterparty_bankAccount_bic,
                };

                Ref_ImportOneSupplierData.current = {
                    Type: "BankAccount",
                    BankAccount: BankAccount,
                };
            } else if (arg.transaction_source === "CreditCard") {
                let Name = arg.merchant;
                if (!Name) {
                    Name = "New import";
                }
                FormDataSupplier.Company.Updatable.company = Name;
                let credit_card_Location_Keywords = [];
                let credit_card_Merchant_Keywords = [];
                credit_card_Location_Keywords.push(arg.location);
                credit_card_Merchant_Keywords.push(arg.merchant);
                Ref_ImportOneSupplierData.current = {
                    Type: "CreditCard",
                    credit_card_Location_Keywords: credit_card_Location_Keywords,
                    credit_card_Merchant_Keywords: credit_card_Merchant_Keywords,
                };
            }
            //We post to the api to create a new supplier

            const ApiCall = {url: `${ProdURL}/api/companies/company/edit/new`, type: "put", data: FormDataSupplier};
            setLoading(true);
            setPhase("CreateSupplier");

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: true,
            DefaultPageSize: 30,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: true,
        Selectable: false,
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
        RowButtons: [
            {
                Standard: false,
                ActiveCondition: null,
                Position: 1,
                IconActive: <UploadFile IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
                IconInactive: <UploadFile IconSize="20px" SVGFillColor="white" SVGFillHoverColor="white" />,
                OnClick: HandleImportSupplier,
            },
        ],
    };

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/purchaseinvoices/suppliersimportinglist`,
        DELETE: "",
        PUT: "",
        POST: "",
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Init, setInit] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [Phase, setPhase] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <Table
                // LocalData={TableData}
                URLRelative={FinalAPIUrlRelative}
                // URLRelative="arnaud"
                MainDataSource="Transactions"
                ColomnsInput={ColomnsInput}
                TableDimensions={TableDimensions}
                TableOptions={TableOptions}
                TotalRow={TotalRow}
                DevMode={false}
            />
        );
        setTableComponent(FinalTableComponent);
    }, []);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;

                    if (Phase === "CreateSupplier") {
                        console.log("Create Supplier", Data);
                        console.log("Ref_ImportOneSupplierData", Ref_ImportOneSupplierData.current);

                        //We check what is next to update
                        if (Ref_ImportOneSupplierData.current.Type === "BankAccount") {
                            //We need to post the bank account information
                            let SupplierId = Data.Company._id;
                            Ref_ImportOneSupplierData.current.SupplierId = SupplierId;
                            setPhase("BankAccountCreation");
                            setLoading(true);
                            let FormData = {
                                BankAccount: {
                                    Updatable: {
										ref_company : SupplierId,
										account_number: Ref_ImportOneSupplierData.current.BankAccount.account_number,
                                        bic: Ref_ImportOneSupplierData.current.BankAccount.bic,
                                        
                                    },
                                },
                            };
                            const ApiCall = {url: `${ProdURL}/api/companies/company/bankaccount/new`, type: "put", data: FormData};
                            setLoading(true);
                            setPhase("Final");

                            async function APIInternal() {
                                const res = await APICallExternal(ApiCall);
                                setApiRes(res);
                                setLoading(false);
                            }

                            APIInternal();
                        } else if (Ref_ImportOneSupplierData.current.Type === "CreditCard") {
                            //We need to update the keywords for the credit card
                            let SupplierId = Data.Company._id;
                            Ref_ImportOneSupplierData.current.SupplierId = SupplierId;
                            setPhase("CreditCardCreation");
                            setLoading(true);
                            let FormData = {
								API_id: SupplierId,
                                Company: {
                                    Updatable: {
                                        credit_card_Location_Keywords: Ref_ImportOneSupplierData.current.credit_card_Location_Keywords,
                                        credit_card_Merchant_Keywords: Ref_ImportOneSupplierData.current.credit_card_Merchant_Keywords,
                                    },
                                },
                            };
                            const ApiCall = {url: `${ProdURL}/api/companies/company/edit/${SupplierId}`, type: "put", data: FormData};
                            setLoading(true);
                            setPhase("Final");

                            async function APIInternal() {
                                const res = await APICallExternal(ApiCall);
                                setApiRes(res);
                                setLoading(false);
                            }

                            APIInternal();
                        }
                    } else if (Phase === "Final") {
                        History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/${Ref_ImportOneSupplierData.current.SupplierId}/edit`);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    // useEffect(() => {
    //     if (Init === false) {
    //         const ApiCall = {url: `${ProdURL}/api/companies/company/companyaddresses/${CompanyId}`, type: "get"};
    //         setLoading(true);
    //         setAPICallType(null);

    //         async function APIInternal() {
    //             const res = await APICallExternal(ApiCall);
    //             setApiRes(res);
    //             setLoading(false);
    //         }

    //         APIInternal();
    //     }
    // }, [Init]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [Init, GenerateTableComponent]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleImportAccounts = () => {
        setInit(true);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let InitialComponent = null;
    if (Init === false) {
        InitialComponent = (
            <div className={classes.Introduction}>
                <p>This will go through all transactions and identify the bank accounts number that are not part of the current suppliers</p>
                <p>This can take some time</p>
                <Button Width="250px" onClick={HandleImportAccounts}>
                    Start the process
                </Button>
            </div>
        );
    } else {
        InitialComponent = null;
    }

    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px">
            <div className={classes.Main}>
                <h1 className={classes.Title}>Import suppliers from Bank account</h1>
                {InitialComponent}
            </div>
            {TableComponent}
        </Page>
    );

    /***************** RENDER ******************/
});

export default SupplierImportFromBankAccount;
