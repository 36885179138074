import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 5px",
        display: "flex",
        width: "100%",
        flex: "1 1 auto",
        height: "500px",

        // maxWidth: "1200px",
        // maxHeight: "800px",
        // height: "350px",
        // minHeight: "100%",
        // height : "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
    ClassExtendTabContent: {
        // maxWidth: "1200px",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
}));

const CustomerEditBankAccountTable = React.memo(function CustomerEditBankAccountTable(props) {
    const {FormViewMode, StatementId, SubmitParentForm} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };
    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const StatusRendering = (params) => {
        let RowData = params.data;

        let Status = RowData.Status;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Posted") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                            Posted
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Reconciled") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "green"}}>
                            Reconciled
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    };
    const TableColumns = [
        {
            field: "calc_RefTransaction",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: false,
            // sort: "asc",
            // sortIndex: 2,
            // sortingOrder: ["asc", "desc"],
            // rowGroup: true,
            // showRowGroup : true,
            // hide: true,
            // checkboxSelection: true,
        },
        {
            field: "transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
        },

        {
            field: "banking_amount_transaction",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            aggFunc: "sum",
        },
        {
            field: "Status",
            //
            headerName: "Status",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
            cellStyle: PaymentCell,
        },

        {
            field: "communication",
            headerName: "communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: "counterparty_bankAccount_number",
            headerName: "Account Number",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
        {
            field: "counterparty_bankAccount_name",
            headerName: "Account Owner",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
    ];
    const HandleRowSelectChange = (SelectedRows) => {
        console.log("SelectedRows", SelectedRows);
        // if (SelectedRows.length === 0) {
        //     setSelectedRows(null);
        // } else {
        //     setSelectedRows(SelectedRows);
        // }
    };
    const TableOptions = {
        Selection: {
            Multiple: true,
            GroupOnly: true,
            // WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },
        // Grouping: {
        //     Type: "GroupRows",
        //     HeaderName: "Statement",
        //     MinWidth: 250,
        // },
        SideBar: "filters",
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [CreateNewOnly, setCreateNewOnly] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;

                    if (Init === false) {
                        // console.log("not init");

                        if (Data.Transactions && Array.isArray(Data.Transactions) && Data.Transactions.length > 0) {
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        } else {
                            Ref_LocaleData.current = null;
                            // console.log("New ONly");
                            setCreateNewOnly(true);
                            setInit(true);
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;

        if (Ref_LocaleData.current) {
            if (FormViewMode === "View") {
                FinalTableComponent = (
                    <AGGridTable
                        TableName="Transactions"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="Transactions"
                        // RowActionButtons={TableRowButtons}
                        ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            } else {
                FinalTableComponent = (
                    <AGGridTable
                        TableName="Transactions"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="Transactions"
                        // RowActionButtons={TableRowButtons}
                        ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            }
        }

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
        }, 200);
    }, [Ref_LocaleData.current, Init, FormViewMode]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    
    useEffect(() => {
        if (Init === false) {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/transactions/?ref_BankStatement=${StatementId}`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true && CreateNewOnly === false) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init, FormViewMode]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalTableComponent;
    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = <div className={classes.TableWrapper}>{TableComponent}</div>;
        } else {
            FinalTableComponent = "There are no transactions";
        }
    }

    return (
        <React.Fragment>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default CustomerEditBankAccountTable;
