import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";
import FormCompontent from "../../../../../../../../../../artibulles-cis/FormComponent/FormCompontent";

import {PDF} from "@artibulles-cis/react-icons";
import {toast} from "react-toastify";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },

    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        width: "620px",
        border: "1px solid grey",
        padding: "10px 10px",
        height: "100%",
        color: "black",
        borderRadius: "5px",
        // margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "3px 10px 3px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {InvoiceHistoryId, Invoices_File_Path, Customers, PassedFormValues, HandleFieldChange} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCall, setApiCall] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    const [EmailLanguageOptions, setEmailLanguageOptions] = useState();

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                // toast.error(`Email not sent, ${ApiRes.error}`, {autoClose: true});
            } else {
                if (!ApiCall) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        let InvoiceSendHistory = Data.InvoiceSendHistory;
                        console.log("Send Email Form", InvoiceSendHistory);
                        let LanguageOptions = [
                            {value: "English", label: "English"},
                            {value: "French", label: "French"},
                        ];
                        setFieldOptions({Language: LanguageOptions});

                        let SubjectOptions = {
                            English: `Invoice ${PassedFormValues.Calc_RefInvoice}`,
                            French: `Facture ${PassedFormValues.Calc_RefInvoice}`,
                        };
                        let BodyOptions = {
                            English: `Dear Customer,\nPlease find a new invoice ${PassedFormValues.Calc_RefInvoice}`,
                            French: `Cher client,\nVeuillez trouver une nouvelle facture ${PassedFormValues.Calc_RefInvoice}`,
                        };
                        let SignatureOptions = {
                            English: `ArTiBulles Accounting`,
                            French: `ArTiBulles Accounting`,
                        };
                        setEmailLanguageOptions({Subject: SubjectOptions, Body: BodyOptions});
                        let InitialSubject, InitialBody, InitialSignature;
                        if (!InvoiceSendHistory.EmailSubject) {
                            if (InvoiceSendHistory.Language === "English") {
                                InitialSubject = SubjectOptions.English;
                            } else {
                                InitialSubject = SubjectOptions.French;
                            }
                        } else {
                            InitialSubject = InvoiceSendHistory.EmailSubject;
                        }
                        if (!InvoiceSendHistory.EmailBody) {
                            if (InvoiceSendHistory.Language === "English") {
                                InitialBody = BodyOptions.English;
                            } else {
                                InitialBody = BodyOptions.French;
                            }
                        } else {
                            InitialBody = InvoiceSendHistory.EmailBody;
                        }
                        if (!InvoiceSendHistory.EmailSignature) {
                            if (InvoiceSendHistory.Language === "English") {
                                InitialSignature = SignatureOptions.English;
                            } else {
                                InitialSignature = SignatureOptions.French;
                            }
                        } else {
                            InitialSignature = InvoiceSendHistory.EmailSignature;
                        }
						let SentFromAddress = InvoiceSendHistory.SentFromEmailAddress.Email

                        let FormValInit = {
                            Language: InvoiceSendHistory.Language,
                            EmailSubject: InitialSubject,
                            EmailBody: InitialBody,
                            EmailSignature: InitialSignature,
							SentFromAddressTemp : SentFromAddress
                        };

                        setInitialFormValues(FormValInit);
                        setFormValues(FormValInit);
                        setFormInitReady(true);
                        HandleFieldChange(FormValInit);

                        if (PassedFormValues.AccountantStatusHistory && Array.isArray(PassedFormValues.AccountantStatusHistory) && PassedFormValues.AccountantStatusHistory.length > 0) {
                            Ref_LocaleData.current = PassedFormValues.AccountantStatusHistory;
                        }
                    } else {
                        console.log("error");
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoice/email/history/${InvoiceHistoryId}`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
        HandleFieldChange({...FormValues, ...UpdateFormValues});
    };

    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        if (field === "Language") {
            if (SelectedValues === "English") {
                UpdatedFormValues.EmailSubject = EmailLanguageOptions.Subject.English;
                UpdatedFormValues.EmailBody = EmailLanguageOptions.Body.English;
            } else {
                UpdatedFormValues.EmailSubject = EmailLanguageOptions.Subject.French;
                UpdatedFormValues.EmailBody = EmailLanguageOptions.Body.French;
            }
        }

        setFormValues(UpdatedFormValues);
        HandleFieldChange({...FormValues, ...UpdatedFormValues});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormComponent;
    if (FormValues && FormInitReady === true) {
        FormComponent = (
            <React.Fragment>
                <div className={classes.FormWrapper}>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "0 0 540px"}}>
                            <FormCompontent
                                Name="Language"
                                InitialValue={InitialFormValues.Language ? InitialFormValues.Language : null}
                                FormValue={FormValues.Language ? FormValues.Language : null}
                                OutputSelectedValues={(values) => handleSelectChange("Language", values)}
                                SelectOptions={FieldOptions.Language}
                                Component="Select"
                                Variant="Outlined"
                                Label="Language"
                                NoMessage={false}
                                MultiSelect={false}
                                SortSelectOptions="Asc"
                                Clearable={false}
                                // SortIcons={true}
                                AllowCreateOption={false}
                                // CreateDeleteIfUnselected={}
                                // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                LabelMaxWidth="130px"
                                FieldMaxWidth="200px"
                                LabelAlign="Left"
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                            <FormCompontent
                                Name="EmailSubject"
                                InitialValue={InitialFormValues.EmailSubject ? InitialFormValues.EmailSubject : null}
                                FormValue={FormValues.EmailSubject ? FormValues.EmailSubject : null}
                                OutputValue={(values) => HandleUpdateFormValues("EmailSubject", values)}
                                Component="Input"
                                Variant="Outlined"
                                Label="Email Subject"
                                LabelMaxWidth="130px"
                                LabelAlign="Left"
                                // FieldMaxWidth="130px"
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                            <FormCompontent
                                Name="EmailBody"
                                InitialValue={InitialFormValues.EmailBody ? InitialFormValues.EmailBody : null}
                                FormValue={FormValues.EmailBody ? FormValues.EmailBody : null}
                                OutputValue={(values) => HandleUpdateFormValues("EmailBody", values)}
                                Component="Input"
                                Variant="Outlined"
                                Label="Email Body"
                                ReadOnly={false}
                                LabelMaxWidth="130px"
                                LabelAlign="Left"
                                multiline={true}
                                RowsToDisplay="2"
                                MaxRows="4"
                                // FieldMaxWidth="130px"
                            />
                        </div>
                    </div>
                    <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                            <FormCompontent
                                Name="EmailSignature"
                                InitialValue={InitialFormValues.EmailSignature ? InitialFormValues.EmailSignature : null}
                                FormValue={FormValues.EmailSignature ? FormValues.EmailSignature : null}
                                OutputValue={(values) => HandleUpdateFormValues("EmailSignature", values)}
                                Component="Input"
                                Variant="Outlined"
                                Label="Email Signature"
                                LabelMaxWidth="130px"
                                LabelAlign="Left"
                                // FieldMaxWidth="130px"
                            />
                        </div>
                    </div>
					<div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                        <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                            <FormCompontent
                                Name="SentFromAddressTemp"
                                InitialValue={InitialFormValues.SentFromAddressTemp ? InitialFormValues.SentFromAddressTemp : null}
                                FormValue={FormValues.SentFromAddressTemp ? FormValues.SentFromAddressTemp : null}
                                OutputValue={(values) => HandleUpdateFormValues("SentFromAddressTemp", values)}
                                Component="Input"
                                Variant="Outlined"
                                Label="Send From"
                                LabelMaxWidth="130px"
                                LabelAlign="Left"
								ReadOnly={true}
                                // FieldMaxWidth="130px"
                            />
                        </div>
                    </div>
					
                </div>
            </React.Fragment>
        );
    }

    return <React.Fragment>{FormComponent}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
