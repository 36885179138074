/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import PerfectScrollbar from 'react-perfect-scrollbar';
// import "../utils/configAndCss/perfect-scrollbar.css";
import 'react-perfect-scrollbar/dist/css/styles.css';

/**
 * A simple wrapper to pass children to perfect scrollbar
 * Important : You need to manually load the css file for the PerfectScrollBar plugin :
 * import 'react-perfect-scrollbar/dist/css/styles.css'
 */

const PerfectScrollBarWrapper = React.forwardRef(function PerfectScrollBarWrapper(props, ref) {
  const { Active, ScrollX, ScrollY, DisableDocumentSroll, children } = props;

  const ScrollBarRef = React.useRef();

  const UpdateScrollBar = () => {
    if (!ref) {
      if (ScrollBarRef.current) {
        ScrollBarRef.current.updateScroll();
      }
    } else {
      if (ref.current) {
        ref.current.updateScroll();
      }
    }
  };

  //Adding Resize Events

  React.useEffect(() => {
    window.addEventListener('resize', UpdateScrollBar);
    return function cleanup() {
      window.removeEventListener('resize', UpdateScrollBar);
    };
  }, []);

  //Updating the ScrollBar When Mounting (stupid but not done automatically)

  React.useEffect(() => {
    UpdateScrollBar();
  }, []);

  if (Active) {
    if (DisableDocumentSroll && ScrollX) {
      document.body.style.overflowX = 'hidden';
    } else {
      //   document.body.style.overflowX = 'auto';
    }
    if (DisableDocumentSroll && ScrollY) {
      document.body.style.overflowY = 'hidden';
    } else {
      //   document.body.style.overflowY = 'auto';
    }
    return (
      <PerfectScrollbar
        ref={ref ? ref : ScrollBarRef}
        options={{
          suppressScrollX: ScrollX ? false : true,
          suppressScrollY: ScrollY ? false : true,
        }}
      >
        {children}
      </PerfectScrollbar>
    );
  } else {
    return children;
  }
});

PerfectScrollBarWrapper.defaultProps = {
  Active: false,
  ScrollX: false,
  ScrollY: true,
  DisableDocumentSroll: true,
};

PerfectScrollBarWrapper.propTypes = {
  /**
   * Determine if PerfectScrollbar is active or not
   */
  Active: PropTypes.bool,
  /**
   * Add a Scrollbar on X axis
   */
  ScrollX: PropTypes.bool,
  /**
   * Add a Scrollable on Y axis
   */
  ScrollY: PropTypes.bool,
  /**
   *If true, and active it will adjust the document body scroll properties
   *	If ScrollX it will set document.body.style.overflowX = 'hidden';
   *	If ScrollY it will set document.body.style.overflowY = 'hidden';
   */
  DisableDocumentSroll: PropTypes.bool,
};
export default PerfectScrollBarWrapper;
