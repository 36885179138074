import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import ReactTooltip from "react-tooltip";

import CardModal from "@artibulles-cis/react/CardModal";
import ExpandableBox from "../../../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import TabNavContent from "../../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {UploadFile, Preview} from "@artibulles-cis/react-icons";

import Select from "../../../../../../../../artibulles-cis/Select/Select";

import {format, parseISO} from "date-fns";

import UploadTransactions from "./UploadTransactions";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    FormField_WithIcon: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        alignItems: "center",
    },
    FormField_Icon: {
        marginBottom: "15px",
        marginRight: "5px",
        width: "35px",
        height: "35px",
    },
    ClassExtendsContentOpened: {
        padding: "0px",
    },
    ExpandableTitle: {
        margin: "0px",
    },
    Balance_Info_Wrapper: {
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        width: "100%",
        maxWidth: "800px",
        padding: "12px",
        height: "120px",
    },
    Balance_Wrapper: {
        flex: "0 0 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid black",
        borderRadius: "5px",
        fontWeight: "500",
        fontSize: "1.5em",
        height: "100%",
    },
    Upload_Wrapper: {
        flex: "0 0 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    Info_Wrapper: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        height: "100%",
        padding: "10px 10px 10px 30px",
    },
    Info_Wrapper_Lines: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    MonthlyOverview_Wrapper: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 30px 10px 30px",
        "& h3": {
            margin: "8px 0px",
        },
    },

    Monthly_Line_Wrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative",
        boxSizing: "border-box",
    },
    Balance_Detail: {
        width: "150px",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid black",
        borderRadius: "5px",
        fontWeight: "400",
        fontSize: "1.2em",
        height: "70px",
    },
    Monthly_Line: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        boxSizing: "border-box",
        justifyContent: "center",
        maxWidth: "800px",
        padding: "5px 20px",
    },
    Monthly_Line_Info: {
        flex: "1 1 auto",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        padding: "5px 10px 5px 10px",
    },
    Monthly_Line_Info_Preview: {
        flex: "0 0 30px",
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 10px",
    },
}));

const AccountOverview = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const Ref_TabNavContent = useRef();
    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [RefreshApi, setRefreshApi] = useState(true);
    const [InitialFormValues, setInitialFormValues] = React.useState({
        current_date: Date.now(),
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = useState({}); //FINAL FORM VALUES
    const [AccountData, setAccountData] = useState(null);
    const [FieldOptions, setFieldOptions] = useState({}); //FORM OPTIONS FOR SELECT
    const [ShowCardUploadTransactions, setShowCardUploadTransactions] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);
                    let SelectAccounts = Data.Accounts;
                    var BankAccountsOptions = [];
                    if (SelectAccounts && SelectAccounts.length > 0) {
                        for (var i = 0; i < SelectAccounts.length; i++) {
                            let Elem = SelectAccounts[i];
                            let Seloption = {label: Elem.select_account_owner_bank, value: Elem._id};
                            BankAccountsOptions.push(Seloption);
                        }
                    }
                    let DefaultAccount = _.find(SelectAccounts, {isDefaultAccount: true});
                    if (DefaultAccount) {
                        DefaultAccount = DefaultAccount._id;
                    }

                    let FinalOptions = {
                        ref_bankaccount: BankAccountsOptions,
                    };
                    let InitForm = {
                        ref_bankaccount: DefaultAccount,
                    };

                    //Updating the required template fields
                    let DataAccount = Data.Account;
                    const FinalAccountData = {
                        Balance: null,
                        LastSync: null,
                        LastTransactionDate: null,
                        TotalUnreconciled: null,
                        Monthly: {
                            CurrentBalance: null,
                            CurrentSpending: null,
                            CurrentIncome: null,
                            ForecastBalance: null,
                            ForecastSpending: null,
                            ForecastIncome: null,
                        },
                    };
                    if (DataAccount) {
                        FinalAccountData.Balance = DataAccount.balance ? CurrencyConversion(DataAccount.balance, "EUR", "de", "DE") : "No information";
                        FinalAccountData.LastSync = DataAccount.last_sync_dateIso ? format(parseISO(DataAccount.last_sync_dateIso), "dd-MMM-yyyy HH:MM:ss") : "no information";
                        FinalAccountData.LastTransactionDate = DataAccount.last_transaction_dateIso ? format(parseISO(DataAccount.last_transaction_dateIso), "dd-MMM-yyyy HH:MM:ss") : "no information";
                        FinalAccountData.TotalUnreconciled = DataAccount.total_unreconciled ? DataAccount.total_unreconciled : "no information";
                    }
                    let MonthlyData = Data.MonthlyInfo;
                    if (MonthlyData) {
                        FinalAccountData.Monthly.CurrentBalance = MonthlyData.CurrentBalance ? CurrencyConversion(MonthlyData.CurrentBalance, "EUR", "de", "DE") : "No information";
                        FinalAccountData.Monthly.CurrentSpending = MonthlyData.CurrentSpending ? CurrencyConversion(MonthlyData.CurrentSpending, "EUR", "de", "DE") : "No information";
                        FinalAccountData.Monthly.CurrentIncome = MonthlyData.CurrentIncome ? CurrencyConversion(MonthlyData.CurrentIncome, "EUR", "de", "DE") : "No information";
                        FinalAccountData.Monthly.ForecastBalance = null;
                        FinalAccountData.Monthly.ForecastSpending = null;
                        FinalAccountData.Monthly.ForecastIncome = null;
                    }

                    setFieldOptions(FinalOptions);
                    setInitialFormValues({...InitialFormValues, ...InitForm});
                    setFormValues(InitForm);
                    setAccountData(FinalAccountData);
                    setRefreshApi(false);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //If there is no Id in the route -> Contact the Api to get the Id
    useEffect(() => {
        if (RefreshApi === true) {
            const ApiCall = {url: `${ProdURL}/api/companyaccount/accountoverview`, type: "get"};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }
            APIInternal();
        }
    }, [ProdURL, RefreshApi]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const handleSelectChange = (field, value) => {
        setFormValues({...FormValues, ...{[field]: value}});
        HandleFiedsChange(field, value);
    };
    const ShareInputValue = (field, value) => {
        setFormValues({...FormValues, ...{[field]: value}});
        HandleFiedsChange(field, value);
    };
    const HandleFiedsChange = (field, value) => {};

    const CurrencyConversion = (number, currency, language, format, digits) => {
        var FinalCurrency = currency ? currency : "EUR",
            FinalLanguage = language ? language : "fr",
            FinalFormat = format ? format : "FR",
            maximumSignificantDigits = digits ? digits : 10;
        return new Intl.NumberFormat(`${FinalLanguage}-${FinalFormat}`, {style: "currency", currency: `${FinalCurrency}`, maximumSignificantDigits: `${maximumSignificantDigits}`}).format(number);
    };
    const HandleOpenCardUploadTransactions = () => {
        setShowCardUploadTransactions(true);
    };
    const HandleCloseCardUploadTransactions = (refresh) => {
        if (refresh) {
            setShowCardUploadTransactions(false);
            setRefreshApi(true);
        } else {
            setShowCardUploadTransactions(false);
        }
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardUploadTransactions;
    if (ShowCardUploadTransactions === true) {
        ModalCardUploadTransactions = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                ParentCompRef={Ref_TabNavContent}
                TopBarContent={<h2 style={{margin: "0 auto"}}>Upload CSV Bank Transaction Files</h2>}
                CloseCard={HandleCloseCardUploadTransactions}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="400px"
                // CardHeight="500px"
                WithPerfectScrollbar={true}
                CardWindowPaddingVertical="50px"
                CardWindowPaddingHorizontal="50px"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.CardExtended}
            >
                <div className={classes.CardContent}>
                    <UploadTransactions account_id="myaccount" CloseCard={HandleCloseCardUploadTransactions} />
                </div>
            </CardModal>
        );
    }

    var FinalComponent;
    if (AccountData) {
        FinalComponent = (
            <div className={classes.OverviewWrapper}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 1 550px"}}>
                        <Select
                            name="ref_bankaccount"
                            initialvalue={InitialFormValues.ref_bankaccount ? InitialFormValues.ref_bankaccount : null}
                            options={FieldOptions.ref_bankaccount}
                            curvalue={FormValues.ref_bankaccount}
                            onSelectChange={(value) => handleSelectChange("ref_bankaccount", value)}
                            // UpdateOptionsFromOutside={(Options, selected) => UpdateCreatableOptions(Options, selected, "ref_companySiteType")} //To use if you want to add all created values as options even if the user deselect it",
                            label="Bank Account"
                            variant="outlined" //standard,outlined,filled
                            labelFlex="0 0 100px"
                            NoMessage={true}
                            // disabled={FormViewMode === "Edit" ? false : true}
                            ControlledEditMode={false}
                            // ControlledEditModeActive={HandleEnableEdit}
                            // MultiSelect={false}
                            // AllowCreateOption={false}
                            // RemoveOptionIfUnselected={true}
                            // required={true}
                            // readOnly={true}
                            // disabled={FormViewMode === "Edit" ? false : true}
                            Clearable={false}
                            // isSearchable={true}
                            // placeHolder="PlaceHolder"
                            // HelperMessage="HelperMessage"
                            // sortSelectOptions="asc" //asc,desc
                            // sortLastItemName={{name: "other", exact: false}}
                            // isSearchable={false}
                            // minMenuHeight="100px"
                            // maxMenuHeight="150px"
                            // menuPlacement="auto" //top,bottom,auto
                            // AlternateRowStyle={false}
                            // MenuMaxHeight="200px"
                            // LabelScale="'0.75'"
                            // StyleBorderErrorFocusTrue={true}
                            // Colors={{Empty: null, Filled: null, Error: null, Focused: null, Disabled: null}}
                            // InputFontProp={{fontSize: null, fontFamily: null, fontWeight: null}}
                            // LabelStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                            // InputStyle={{Empty: null, Filled: null, Focused: null, Disabled: null, Error: null}}
                            // BorderStyle={{Empty: null, Focused: null, Error: null, Disabled: null, BoderRadius: null}}
                            // HelperStyle={{fontFamily: null, fontSize: null, marginTop: null, color: null}}
                            // ErrorStyle={{color: null}}
                            // inputVerticalPadding="null"
                        />
                    </div>
                </div>
                <h2 style={{margin: "10px 0px 0px 10px"}}>Account Balance</h2>
                <div className={classes.Balance_Info_Wrapper}>
                    <div className={classes.Balance_Wrapper} style={{color: "green", border: "2px solid green"}}>
                        {AccountData.Balance}
                    </div>
                    <div className={classes.Upload_Wrapper} data-tip data-for="uploadcsv" onClick={HandleOpenCardUploadTransactions}>
                        <UploadFile
                            IconSize="40px"
                            InnerPadding="20px"
                            Frame="rounded"
                            SVGFillColor="white"
                            FrameFillColor="grey"
                            FrameStrokeColor="grey"
                            FrameStrokeColorHover="grey"
                            FrameFillColorHover="grey"
                        />
                    </div>
                    <ReactTooltip id="uploadcsv" place="bottom" type="dark" effect="solid">
                        <p className={classes.tooltipP}>Upload CSV files</p>
                    </ReactTooltip>

                    <div className={classes.Info_Wrapper}>
                        <div className={classes.Info_Wrapper_Lines}>Last synched: {AccountData.LastSync} </div>
                        <div className={classes.Info_Wrapper_Lines}>Last transaction: {AccountData.LastTransactionDate} </div>
                        <div className={classes.Info_Wrapper_Lines}>Unreconciled transactions: {AccountData.TotalUnreconciled} </div>
                    </div>
                </div>
                <ExpandableBox
                    TopContent={<h2 className={classes.ExpandableTitle}>{`${format(InitialFormValues.current_date, "MMMMMM yyyy")}  - Monthly Overview`}</h2>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}

                    ClassExtendsContentOpened={classes.ClassExtendsContentOpened}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    <div className={classes.MonthlyOverview_Wrapper}>
                        <h3>Current</h3>

                        <div className={classes.Monthly_Line_Wrapper}>
                            <div className={classes.Balance_Detail} style={{color: "green", border: "2px solid green"}}>
                                {AccountData.Monthly.CurrentBalance}
                            </div>
                            <div className={classes.Monthly_Line}>
                                <div className={classes.Monthly_Line_Info}>
                                    {AccountData.Monthly.CurrentSpending}
                                    <div className={classes.Monthly_Line_Info_Preview}>
                                        <Preview />
                                    </div>
                                </div>
                                <div className={classes.Monthly_Line_Info}>
                                    {AccountData.Monthly.CurrentIncome}
                                    <div className={classes.Monthly_Line_Info_Preview}>
                                        <Preview />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3>End of month forecast</h3>
                        <div className={classes.Monthly_Line_Wrapper}>
                            <div className={classes.Balance_Detail} style={{color: "green", border: "2px solid green"}}>
                                {CurrencyConversion(12569.32, "EUR", "de", "DE")}
                            </div>
                            <div className={classes.Monthly_Line}>
                                <div className={classes.Monthly_Line_Info}>
                                    {`Spending: ${CurrencyConversion(12569.32, "EUR", "de", "DE")}`}
                                    <div className={classes.Monthly_Line_Info_Preview}>
                                        <Preview />
                                    </div>
                                </div>
                                <div className={classes.Monthly_Line_Info}>
                                    {`Income: ${CurrencyConversion(12569.32, "EUR", "de", "DE")}`}
                                    <div className={classes.Monthly_Line_Info_Preview}>
                                        <Preview />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ExpandableBox>
                <ExpandableBox
                    TopContent={<h2 className={classes.ExpandableTitle}>Custom Forecast</h2>}
                    DefaultStyle="List"
                    // TransitionEffect={{Duration : "600ms"}}
                    // MaxWidth="1020px"
                    // CustomController={<InfoCircleBlue />}
                    // CustomControllerNoRotation={true}
                    // BackgroundColorTopBar="grey"
                    // ClassExtendsExpandableBox={classes.ExpandableBox}
                    // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                    // ClassExtendsTopContentWrapper={classes.TopContentWrapper}

                    ClassExtendsContentOpened={classes.ClassExtendsContentOpened}
                    // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                    // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                    // MinWidth="1020px"
                    // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                    MaxHeightOpened="150px"
                    OpenOnTopClick={true}
                    WithPerfectScrollbar={false}
                    WithScrollbar={null}
                    FlexMode={true}
                >
                    Custom
                </ExpandableBox>
            </div>
        );
    } else {
        FinalComponent = null;
    }
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            {ModalCardUploadTransactions}
            {FinalComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default AccountOverview;
