import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import CardModal from "@artibulles-cis/react/CardModal";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import InvoiceItemsEdit from "./InvoiceItemsEdit";
import GenerateInvoicePDF from "../InvoiceEditFormParts/GenerateInvoicePDF/GenerateInvoicePDF";

import {AddCircle, PDF} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    ClassExtendTabContent: {
        padding: "0px 5px 0px 5px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    DailytButtonBar: {
        flex: "0 1 auto",
    },
    TableWithToolbar: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        // display: "flex",
        width: "100%",
        minHeight: "250px",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px",
    },
    ButtonBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        paddingTop: "5px",
    },
    ButtonLeft: {
        flex: " 1 1 auto",
        display: "flex",
    },
    ButtonMiddle: {
        flex: " 1 1 auto",
        display: "flex",
    },
    ButtonRight: {
        flex: " 1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },
    CardContent: {
        color: "black",
        height: "100%",
        overflow: "hidden",
    },
    ClassExtendCardNoScroll: {
        overflow: "hidden",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        InvoiceGenerationMethod,
        EditInvoiceGenerate,
        FormViewMode,
        SaleInvoiceID,
        SubmitParentForm,
        FormValues,
        FieldOptionsParent,
        HandleRefreshFormNoSave,
        HandleCloseGeneratePDFNoSave,
        CustomerAccountingPreference,
    } = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    const Ref_PurchaseItemViewMode = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const HandleDeleteRow = (Id) => {
        // console.log("HandleDeleteRow", Id);
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoiceitems/invoiceitem/${Id}`, type: "delete"};
            setLoading(true);
            setAPICallType("deleteone");

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };
    const HandleEditRow = (Id, RowData) => {
        setShowEditPuchaseItemCard(true);
        setViewCartPurchaseItemId(Id);
        Ref_PurchaseItemViewMode.current = "Edit";
    };
    const HandleEditDuplicate = (Id, RowData) => {};

    const HandleViewRow = (Id, RowData) => {
        Ref_PurchaseItemViewMode.current = "View";
        setShowEditPuchaseItemCard(true);
        setViewCartPurchaseItemId(Id);
    };

    const TableColumns = [
        {
            field: "ArticleName",
            headerName: "Article Name",
            headerClass: "ag-CenterHeader",
            flex: 1,
            minWidth: 200,
            autoHeight: true,
            wrapText: true,
            // width: 150,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            // sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        // {
        //     field: "AccountingArticleName",
        //     headerName: "Accounting Name",
        //     headerClass: "ag-CenterHeader",
        //     flex: 2,
        //     // width: 150,
        //     // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // sort: "desc",
        //     // sortIndex: 1,
        //     // sortingOrder: ["asc", "desc"],
        //     // Type: "Date",
        // },
        // {
        //     field: "AccountingBelgianAccount.AccountDescription",
        //     headerName: "Description",
        //     headerClass: "ag-CenterHeader",
        //     flex: 3,
        // 	// width: 150,
        //     // lookup: {data: "Suppliers", searchField: "_id", returnField: "company"},
        //     // filter: "agNumberColumnFilter",
        //     // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // Type: "Currency",
        //     // TotalRow: {
        //     //     Show: true,
        //     //     // Rendering: (params) => {
        //     //     //     return <div>Coucou</div>;
        //     //     // },
        //     // },
        // },
        // {
        //     field: "TypeOfPurchase",
        //     //
        //     headerName: "Type",
        //     headerClass: "ag-CenterHeader",
        //     width: 95,
        //     // filter: true,
        //     // sortingOrder: ["asc", "desc"],
        // },
        {
            field: "UnitPriceVATExcluded",
            //
            headerName: "Unit Price",
            headerClass: "ag-CenterHeader",
            width: 105,
            Type: "Currency",
            // filter: true,
            // sortingOrder: ["asc", "desc"],
            // Type: "Boolean",
        },
        {
            field: "Quantity",
            //
            headerName: "Quantity",
            headerClass: "ag-CenterHeader",
            width: 90,
            // filter: true,
            // sortingOrder: ["asc", "desc"],
            // cellRenderering: PaymentActionRendering,
            // cellStyle: PaymentCell,
        },
        {
            field: "CalcTotalVATExcluded",
            headerName: "Total",
            headerClass: "ag-CenterHeader",
            width: 105,
            Type: "Currency",
            // filter: "agNumberColumnFilter",
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            //
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        // {
        //     field: "TaxCode.TaxName",
        //     headerName: "Taxes",
        //     headerClass: "ag-CenterHeader",
        //     width: 85,
        //     // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
        //     // sortingOrder: ["asc", "desc"],
        //     // Type: "Date",
        // },

        {
            field: "CalcVATAmount",
            headerName: "VAT",
            headerClass: "ag-CenterHeader",
            width: 95,
            Type: "Currency",
            // filter: "agNumberColumnFilter",
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},

            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "CalcTotalVATIncluded",
            headerName: "Tot VAT",
            headerClass: "ag-CenterHeader",
            width: 105,
            Type: "Currency",
            // filter: "agNumberColumnFilter",
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},

            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        SupressMenu: true,
        WrapText: true,
        WrapHeader: false,
        HeaderHeight: 30,
        RowHeight: 24,
        RowFontSize: "13px",
        HeaderFontSize: "14px",
        StatusBar: true,
    };
    // const TotalRow = {
    //     Position: "Bottom",
    //     Style: null,
    // };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [CreateNewOnly, setCreateNewOnly] = useState(false);

    const [APICallType, setAPICallType] = useState(null);
    const [ShowEditPuchaseItemCard, setShowEditPuchaseItemCard] = useState(false);
    const [ViewCartPurchaseItemId, setViewCartPurchaseItemId] = useState(null);

    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [ShowGenerateInvoiceCard, setShowGenerateInvoiceCard] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    // console.log("PurchaseInvoiceItems", Data);

                    if (Init === false) {
                        // console.log("not init");

                        if (Data.SaleInvoiceItems && Array.isArray(Data.SaleInvoiceItems) && Data.SaleInvoiceItems.length > 0) {
                            // console.log("Data.SaleInvoiceItems", Data.SaleInvoiceItems);
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        } else {
                            Ref_LocaleData.current = null;
                            // console.log("New ONly");
                            setCreateNewOnly(true);
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "deleteone") {
                        toast.success(`Entry deleted`, {autoClose: true});

                        Ref_LocaleData.current = null;
                        setAPICallType(null);
                        setTimeout(() => {
                            setInit(false);
                        }, 50);
                        setTimeout(() => {
                            SubmitParentForm();
                        }, 200);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;

        if (Ref_LocaleData.current) {
            let TableRowButtons;
            if (FormViewMode === "View") {
                let ColumnButtonStyle = {
                    Width: 45,
                    padding: "0px 0px 0px 0px",
                };
                TableRowButtons = [
                    {
                        Standard: true,
                        Name: "View",
                        ActiveCondition: null,
                        Index: 1,
                        OnClick: HandleViewRow,
                        // IconSize: "10px",
                        // ActiveColor: "red",
                        // HoverColor: "green",
                        // InactiveColor: "blue",
                        Tooltip: {Show: true, Position: "right", Theme: "light"},
                    },
                ];
                FinalTableComponent = (
                    <AGGridTable
                        TableName="InvoiceitemsTable_ViewMode"
                        Data={Ref_LocaleData.current}
                        // URLAbsolute={URLAbsolute}
                        TableColumns={TableColumns}
                        MainDataSource="SaleInvoiceItems"
                        RowActionButtons={TableRowButtons}
                        ColumnButtonStyle={ColumnButtonStyle}
                        TableOptions={TableOptions}
                        // ReloadData={ReloadTableData}
                        // SyncReloadDataStateFunction={setReloadTableData}
                        // TotalRow={TotalRow}
                    />
                );
            } else {
                if (InvoiceGenerationMethod === "Automatically") {
                    let ColumnButtonStyle = {
                        Width: 45,
                        padding: "0px 0px 0px 0px",
                    };
                    TableRowButtons = [
                        {
                            Standard: true,
                            Name: "View",
                            ActiveCondition: null,
                            Index: 1,
                            OnClick: HandleViewRow,
                            // IconSize: "10px",
                            // ActiveColor: "red",
                            // HoverColor: "green",
                            // InactiveColor: "blue",
                            Tooltip: {Show: true, Position: "right", Theme: "light"},
                        },
                    ];
                    FinalTableComponent = (
                        <AGGridTable
                            TableName="InvoiceitemsTable_ViewMode"
                            Data={Ref_LocaleData.current}
                            // URLAbsolute={URLAbsolute}
                            TableColumns={TableColumns}
                            MainDataSource="SaleInvoiceItems"
                            RowActionButtons={TableRowButtons}
                            ColumnButtonStyle={ColumnButtonStyle}
                            TableOptions={TableOptions}
                            // ReloadData={ReloadTableData}
                            // SyncReloadDataStateFunction={setReloadTableData}
                            // TotalRow={TotalRow}
                        />
                    );
                } else {
                    let ColumnButtonStyle = {
                        Width: 95,
                        padding: "0px 0px 0px 0px",
                    };
                    TableRowButtons = [
                        {
                            Standard: true,
                            Name: "Edit",
                            ActiveCondition: null,
                            Index: 1,
                            OnClick: HandleEditRow,
                            // IconSize: "10px",
                            // ActiveColor: "red",
                            // HoverColor: "green",
                            // InactiveColor: "blue",
                            Tooltip: {Show: true, Position: "right", Theme: "light"},
                        },

                        {
                            Standard: true,
                            Name: "Delete",
                            // ActiveCondition: DeleteCondition,
                            Index: 2,
                            OnClick: HandleDeleteRow,
                            ConfirmationMessage: true,
                            // IconSize: "10px",
                            // ActiveColor: "red",
                            // HoverColor: "green",
                            // InactiveColor: "blue",
                            Tooltip: {Show: true, Position: "right", Theme: "light"},
                        },
                        {
                            Standard: true,
                            Name: "Duplicate",
                            ActiveCondition: null,
                            Index: 3,
                            OnClick: HandleEditDuplicate,
                            // IconSize: "10px",
                            // ActiveColor: "red",
                            // HoverColor: "green",
                            // InactiveColor: "blue",
                            Tooltip: {Show: true, Position: "right", Theme: "light"},
                        },
                    ];
                    FinalTableComponent = (
                        <AGGridTable
                            TableName="InvoiceitemsTable_EditMode"
                            Data={Ref_LocaleData.current}
                            // URLAbsolute={URLAbsolute}
                            TableColumns={TableColumns}
                            MainDataSource="SaleInvoiceItems"
                            RowActionButtons={TableRowButtons}
                            ColumnButtonStyle={ColumnButtonStyle}
                            TableOptions={TableOptions}
                            // ReloadData={ReloadTableData}
                            // SyncReloadDataStateFunction={setReloadTableData}
                            // TotalRow={TotalRow}
                        />
                    );
                }
            }
        }

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
        }, 200);
    }, [Ref_LocaleData.current, Init, FormViewMode]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (Init === false) {
            const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoiceitems/invoiceitems/${SaleInvoiceID}`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true && CreateNewOnly === false) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init, FormViewMode]);

    useEffect(() => {
        if (EditInvoiceGenerate === true) {
            setShowGenerateInvoiceCard(true);
        } else {
            setShowGenerateInvoiceCard(false);
        }
    }, [EditInvoiceGenerate]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditItemCard = () => {
        setShowEditPuchaseItemCard(false);
        setViewCartPurchaseItemId(null);
        // console.log("InvoiceItemsTable, FormViewMode", FormViewMode);
        if (FormViewMode === "Edit") {
            //We refresh the parent form to recalculate the invoice amount
            // console.log("InvoiceItemsTable, Edit");
            setCreateNewOnly(false);
            setTimeout(() => {
                setInit(false);
            }, 50);

            setTimeout(() => {
                SubmitParentForm();
            }, 200);
        }
    };

    const HanldeCreateNewPurcahseItem = () => {
        setShowEditPuchaseItemCard(true);
        setViewCartPurchaseItemId("new");
    };

    const HandleGenerateInvoice = () => {
        setShowGenerateInvoiceCard(true);
    };
    const HandleCloseGenerateInvoice = () => {
        setShowGenerateInvoiceCard(false);
        // console.log("Refresh Form")
        HandleRefreshFormNoSave();
        //We cannot close the window and return to the form otherwise it deletes the sale invoice files
        //We need to pass the data or we need to reload the form without saving it
    };

    const HandleCloseQuitCard = () => {
        setShowGenerateInvoiceCard(false);
        HandleCloseGeneratePDFNoSave();
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowEditPuchaseItemCard === true) {
        ModalCardView = (
            <CardModal
                CardID="PurchaseItemCard"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Purchase Invoice Item</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseEditItemCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1000px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <InvoiceItemsEdit SaleInvoiceID={SaleInvoiceID} InvoiceItemID={ViewCartPurchaseItemId} CloseCard={HandleCloseEditItemCard} ViewEditMode={Ref_PurchaseItemViewMode.current} />
                </div>
            </CardModal>
        );
    }
    if (ShowGenerateInvoiceCard === true) {
        ModalCardView = (
            <CardModal
                CardID="GenerateInvoicePDF"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={<div>Generate Invoice PDF</div>}
                // ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseQuitCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="1000px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCardNoScroll}
            >
                <div className={classes.CardContent}>
                    <GenerateInvoicePDF
                        SaleInvoiceID={SaleInvoiceID}
                        CloseCard={HandleCloseGenerateInvoice}
                        ViewEditMode={Ref_PurchaseItemViewMode.current}
                        FormValuesFromParent={FormValues}
                        FieldOptionsParent={FieldOptionsParent}
                        FormViewMode={FormViewMode}
                        CustomerAccountingPreference={CustomerAccountingPreference}
                    />
                </div>
            </CardModal>
        );
    }
    var FinalTableComponent;

    if (Init === true) {
        if (TableComponent) {
            if (InvoiceGenerationMethod === "Automatically") {
                FinalTableComponent = (
                    <div className={classes.TableWithToolbar}>
                        <div className={classes.TableWrapper}>{TableComponent}</div>
                    </div>
                );
            } else {
                FinalTableComponent = (
                    <div className={classes.TableWithToolbar}>
                        <div className={classes.ButtonBar}>
                            <div className={classes.ButtonLeft}>
                                <Button
                                    Width="170px"
                                    Height="25px"
                                    style={{margin: "0px 0px", padding: "0px 5px 0px 5px", justifyContent: "space-between", display: `${FormViewMode === "View" ? "none" : null}`}}
                                    onClick={HanldeCreateNewPurcahseItem}
                                >
                                    <AddCircle SVGFillColor="white" IconSize="20px" />
                                    Add Purchase Item
                                </Button>
                            </div>
                        </div>
                        <div className={classes.TableWrapper}>{TableComponent}</div>
                    </div>
                );
            }
        } else {
            if (InvoiceGenerationMethod === "Automatically") {
                FinalTableComponent = null;
            } else {
                FinalTableComponent = (
                    <div className={classes.TableWithToolbar}>
                        <div className={classes.ButtonBar}>
                            <div className={classes.ButtonLeft}>
                                <Button
                                    Width="170px"
                                    Height="25px"
                                    style={{margin: "0px 0px", padding: "0px 5px 0px 5px", justifyContent: "space-between", display: `${FormViewMode === "View" ? "none" : null}`}}
                                    onClick={HanldeCreateNewPurcahseItem}
                                >
                                    <AddCircle SVGFillColor="white" IconSize="20px" />
                                    Add Purchase Item
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    } else {
        FinalTableComponent = "There is no data";
    }

    return (
        <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent}>
            {ModalCardView}

            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
