import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import {useHistory} from "react-router-dom";
import Page from "../../../../../../artibulles-cis/Page/Page";
import AGGridTable from "../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import CustomerEdit from "./CustomerEditDetails/CustomerEdit";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    OverviewWrapper: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    ClassExtendCard: {
        padding: "0px",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
    PaymentStatus: {
        display: "flex",
        alignItems: "center",
		justifyContent : "center"
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
		justifyContent : "center"
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
		justifyContent : "center",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_Page = useRef(null);
    /***************** REFS ******************/

    /***************** CONST ******************/
    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const StatusRendering = (params) => {
        let RowData = params.data;
        let Status = RowData.Customer_Status;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Invoicable") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(108,145,0)"}}>
                            Invoicable
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Locked") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "red", color: "black"}}>
                            Locked
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment>{Status}</React.Fragment>;
        }
    };
    const HandleEditRow = (Id, RowData) => {
        setShowViewCard(true);
        setViewCardId(Id);
    };

    const DeleteCondition = (args) => {
        let status_invoice = args.status_invoice;
        if (status_invoice === "Draft") {
            return true;
        } else {
            return true; //DEVONLY!! Should be false
        }
    };
    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };
    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },

        {
            Standard: true,
            Name: "Delete",
            ActiveCondition: DeleteCondition,
            Index: 3,
            // OnClick: HandleDeleteRow,
            ConfirmationMessage: true,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];
	const BadgeCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const TableColumns = [
        {
            field: "company",
            headerName: "Company",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "description",
            headerName: "Description",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "Customer_Status",
            //
            headerName: "Status",
            headerClass: "ag-CenterHeader",
            width: 130,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
			cellStyle: BadgeCell,
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },

        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
        SideBar: "filters",
    };

    // const ColomnsInput = [
    //     {
    //         width: "auto",
    //         caption: "Company",
    //         datafield: "company",
    //         searchable: true,
    //         datatype: "date",
    //         sortable: true,
    //         initialSort: {order: 1, sortDirection: "dsc"},
    //     },

    //     {width: "200px", caption: "Description", datafield: "description", fixedCol: false, searchable: true, sortable: false, datatype: "text", wrapcell: true},
    // ];

    // const HandlePreviewRow = (arg) => {
    //     if (arg) {
    //         let id = arg._id;

    //         setShowViewCard(true);
    //         setViewCardId(id);
    //     }
    // };
    // const HandleEditRow = (arg) => {
    //     let Id = arg._id;
    //     History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/${Id}/edit`);
    // };

    // const HandleDeleteRow = (arg) => {
    //     console.log("delete");
    // };

    const URLAbsolute = {
        ALL: `${ProdURL}/api/companies/?is_customer=true`,
        QUERY: null,
        DELETEONE: `${ProdURL}/api/companies/company/edit/`,
        DELETEMANY: null,
        PUT: null,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);

    const [TableComponent, setTableComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="Suppliers"
                URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                // MainDataSource="Invoices"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                PaginationOptions={{
                    Pagination: true,
                    PageSize: 50,
                }}
            />
        );
        setTableComponent(FinalTableComponent);
    }, []);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        GenerateTableComponent();
    }, [GenerateTableComponent]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <CustomerEdit Id={ViewCardId} HandleCloseViewCard={HandleCloseViewCard} />
            </CardModal>
        );
    }

    return (
        <Page PageRef={Ref_Page} PagePadding={"10px 10px"} ClassExtendsPageContent={classes.ClassExtendsPageContent}>
            {ModalCardView}
            {TableComponent}
        </Page>
    );

    /***************** RENDER ******************/
});

export default Component;
