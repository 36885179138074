import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import FileUploader from "../../../../../../../../../artibulles-cis/FileUploader/FileUploader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        height: "30px",
        flex: "0 0 35px",
    },
    ClassExtendCard: {
        color: "black",
    },
    UploaderWrapper: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "0 auto",
        marginTop: "10px",
        maxWidth: "650px",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
    },
    Uploader_Instruction: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        margin: "4px 0px 25px 0px",
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "5px",
        background: "grey",
        color: "white",
        borderRadius: "5px",
        "& p": {
            margin: "2px 0px",
        },
        fontSize: "0.9rem",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    Uploader_Button: {
        display: "flex",
        position: "relative",
        marginTop: "10px",
        justifyContent: "center",
    },
    FileNameType: {
        margin: "5px 0px",
        "& span": {
            fontWeight: "500",
            fontSize: "1rem",
        },
    },
    ConvResults: {
        margin: "0px 10px",
    },
}));

const Component = (props) => {
    const {ShowCard, HandleCloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [IsConverted, setIsConverted] = useState(false);
    const [ConvertedResults, setConvertedResults] = useState([]);

    // const [InitialFormValues, setInitialFormValues] = React.useState({
    //     current_date: Date.now(),
    // }); //INITIAL FORM VALUES
    // const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FilesUploaders, setFilesUploaders] = React.useState({});

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data) {
                    if (ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Data", Data);
                        if (Data && Data.length > 0) {
                            setConvertedResults(Data);
                        }

                        setIsConverted(true);
                        // CloseCard("refresh");
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleConvertCSV = () => {
        console.log("FilesUploaders", FilesUploaders);

        const ApiCall = {url: `${ProdURL}/api/companyaccount/uploadcreditcardcsvfiles`, type: "post", data: FilesUploaders};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };
    const UpdateFormFiles = (fieldName, filedetails) => {
        if (fieldName && filedetails) {
            console.log("");
            let FullFilesUploader = [];
            for (var i = 0; i < filedetails.length; i++) {
                FullFilesUploader.push({
                    serverPath: filedetails[i].serverPath,
                    originalName: filedetails[i].originalName,
                    fileName: filedetails[i].fileName,
                    fileExtension: filedetails[i].fileExtension,
                    fileType: filedetails[i].fileType,
                    fileSize: filedetails[i].fileSize,
                    keywords: filedetails[i].keywords,
                });
            }
            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);
        }
    };

    const HandleCloseCardCredit = () => {
        setIsConverted(false);
        HandleCloseCard();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var FinalComponent;
    if (IsConverted === true) {
        if (ConvertedResults) {
            var DisplayResults = [];
            ConvertedResults.forEach((elem) => {
                let FileRes = (
                    <div className={classes.FileResult}>
                        <div className={classes.FileNameType}>
                            <span>{`Card : ${elem.CardNumber}`} </span>
                        </div>
                        <div className={classes.ConvResults}>
                            <div>
                                <span>Total entries found in file : </span>
                                <span>{elem.NewTransactions + elem.SkippedTransactions}</span>
                            </div>
                            <div>
                                <span>Total new entries : </span>
                                <span>{elem.NewTransactions}</span>
                            </div>
                            <div>
                                <span>Total skipped entries : </span>
                                <span>{elem.SkippedTransactions}</span>
                            </div>
                        </div>
                    </div>
                );
                DisplayResults.push(FileRes);
            });
        }

        FinalComponent = <React.Fragment>{DisplayResults}</React.Fragment>;
    } else {
        FinalComponent = (
            <React.Fragment>
                <LocalCircularLoader WithModalCard={true} Loading={Loading} FullSize={true} />
                <div className={classes.Uploader_Instruction}>
                    <p>You can upload multiple csv files but a file should be for ONE CARD ONLY</p>
                    <p>Only download the transactions in English, otherwise the headers will be wrong</p>
                    <p>You can upload transactions for multiple cards. If they exist, they will be uploaded.</p>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 350px"}}>
                        <FileUploader
                            name="csv_files"
                            label="Transactions"
                            APIUploadPath="/api/companyaccount/uploadcsvtransactions/multiple"
                            APIDeletePath="/api/companyaccount/deleteuploadedtransactions"
                            UpdateFormFunction={UpdateFormFiles}
                            MaxFiles={10}
                            // InitialFiles={FilesUploaders ? FilesUploaders.csv_files : null}
                            UploadButton={{
                                Message: "Upload files",
                            }}
                            AcceptedFileTypes={["*"]}
                            // AcceptedFileTypes={["csv"]}
                            MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                            BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                            SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                            WithDragAndDrop={true}
                            WithProgressBar={true}
                            ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                        />
                    </div>
                </div>
                <div className={classes.Uploader_Button}>
                    <Button Width="350px" onClick={HandleConvertCSV}>
                        Convert Data and store in Database
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    let FinalCardComponent;
    if (ShowCard) {
        FinalCardComponent = (
            <CardModal
                CardID="CreditCardUpload"
                TopBarContent={<div>Upload Credit card csv transactions</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseCardCredit}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="900px"
                CardHeight="500px"
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={ShowCard}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.UploaderWrapper}>{FinalComponent}</div>
            </CardModal>
        );
    } else {
        FinalCardComponent = null;
    }
    return <React.Fragment>{FinalCardComponent}</React.Fragment>;
};

export default Component;
