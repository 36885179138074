import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {AddCircle} from "@artibulles-cis/react-icons";

import AllStatements from "./Tables/AllStatements";
import StatementsByAcccount from "./Tables/StatementsByAcccount";
import UnreconciledStatements from "./Tables/UnreconciledStatements";
import StatementEditMain from "./EditStatement/StatementEditMain";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavLink: {
        borderRight: "1px solid rgb(220 220 220)",
    },
	TabMenuDiv : {
		display :" flex",
		alignItems : "center",
		fontSize : "0.9rem"
	},
	
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNav
            MainSlug={`/financials/accounting/accountsandtransactions/statements`}
            DefaultTab={`/financials/accounting/accountsandtransactions/statements/all`}
            ClassExtendTabNavLink={classes.ClassExtendTabNavLink}
            TabNavMenuHeight="30px"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>All Statements</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/statements/all`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <AllStatements />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Statements by Account</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/statements/byaccount`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <StatementsByAcccount />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Unreconcilled Statements</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/statements/unreconcilled`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <UnreconciledStatements />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv} >
                            <AddCircle IconSize="24"/>
                            <div className={classes.TabTitle} style={{paddingLeft : "5px"}}>Create Statement</div>
                        </div>
                    ),
                    Slug: `/financials/accounting/accountsandtransactions/statements/create`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <StatementEditMain />,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
