import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import {useHistory, useParams} from "react-router-dom";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";

import AccoutingOverview from "./AccoutingOverview"
import AccoutingAllTransactionsTable from "./AccoutingAllTransactionsTable"
import AccountingVATEdit from "./AccountingVATEdit"
import AccountingVATCheck from "./AccountingVATCheck"
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    NoAccount_Wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        jusitfyContent: "center",
        boxSizing: "border-box",
        marginTop: "15px",
    },
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const AccountingRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const LocationParams = useParams();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-BMS.artibulles.com";
    const History = useHistory();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [CompReady, setCompReady] = useState(false);
    const [Id, SetId] = useState(LocationParams.id);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    History.push(`/financials/companyaccount/bankaccounts/account/${Data._id}/overview`);
                    setCompReady(true);
                } else {
                    //No bank account, propose to create one
                    setCompReady(true);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    //If there is no Id in the route -> Contact the Api to get the Id
    useEffect(() => {
        // if (Id === "default") {
        //     const ApiCall = {url: `${ProdURL}/api/companyaccount/bankaccounts/account/default`, type: "get"};
        //     setLoading(true);
        //     setCompReady(false);
        //     async function APIInternal() {
        //         const res = await APICallExternal(ApiCall);
        //         setApiRes(res);
        //         setLoading(false);
        //     }
        //     APIInternal();
        // }
    }, [ProdURL, Id]);

    //PREVENT API CALLS LOOPS
    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleAddAccount = () => {
        History.push("/financials/companyaccount/bankaccounts/account/new/view");
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            MainSlug={`/financials/companyaccount/accounting`}
            DefaultTab={`/financials/companyaccount/accounting/overview`}
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Overview</div>
                        </div>
                    ),
                    Slug: `/financials/companyaccount/accounting/overview`,
                    TabContent: <AccoutingOverview/>,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Account transactions</div>
                        </div>
                    ),
                    Slug: `/financials/companyaccount/accounting/alltransactions`,
                    TabContent: <AccoutingAllTransactionsTable/>,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Update VAT</div>
                        </div>
                    ),
                    Slug: `/financials/companyaccount/accounting/vatupdate`,
                    TabContent: <AccountingVATEdit/>,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Check VAT</div>
                        </div>
                    ),
                    Slug: `/financials/companyaccount/accounting/vatcheck`,
                    TabContent: <AccountingVATCheck/>,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
};

export default AccountingRouter;
