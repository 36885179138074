import React, {useEffect, useState} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
import {Route, Switch} from "react-router-dom";

import SettingsRouter from "./Settings/SettingsRouter"
import EmployeesRouter from "./Employees/EmployeesRouter"
import AddressBookRouter from "./AddressBook/AddressBookRouter"
//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const myCompanyRouter = props => {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
/***************** CONST ******************/
 
/***************** CONST ******************/
 
 
/***************** STATE ******************/
 
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
 
/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<React.Fragment>
            <Switch>
                <Route path="/mycompany/settings">
                    <SettingsRouter />
                </Route>
                <Route path="/mycompany/employees">
                    <EmployeesRouter />
                </Route>
                <Route path="/mycompany/contacts">
                    <AddressBookRouter />
                </Route>
            </Switch>
        </React.Fragment>
  )
 
/***************** RENDER ******************/
 
};
 
export default myCompanyRouter;