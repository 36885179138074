import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import * as agCharts from "ag-charts-community";
import {AgChartsReact} from "ag-charts-react";
import _ from "lodash";
import {format, parseISO, fromUnixTime, getTime, isSameDay} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    GraphResults: {
        flex: "0 1 auto",
        display: "flex",
        // width: "100%",
        // height: "200px",
        // flex: "0 0 500px",
        boxSizing: "border-box",
        position: "relative",
        // margin : "0px 5px",
        width: "590px",
        // padding: "10px",
        // border: "1px solid grey",
        // borderRadius: "10px",
        // margin: "0px 5px",
    },
}));

const Component = React.memo(function Component(props) {
    const {GraphData} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ChartOptions, setChartOptions] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (GraphData && Array.isArray(GraphData)) {
            //We need to sort the Statement asc
            let SortedStatements = _.orderBy(GraphData, ["StatementDateIso"], ["asc"]);
            let InitialBalance = parseFloat(SortedStatements[0].StartingBalance);

            let FinalChartData = [];
            let PreviousDayBalance = InitialBalance;
            SortedStatements.forEach((elem) => {
                let Transactions = elem.StatementTransactions;
                let SortedTransactions = _.orderBy(Transactions, ["transaction_date_Iso"], ["asc"]);

                SortedTransactions.forEach((Transaction) => {
                    let TransactionDateIso = parseISO(Transaction.transaction_date_Iso);
                    let TransactionDateYYYMMMDD = format(TransactionDateIso, "dd-MMM-yyyy");
                    let TransactionAmount = Transaction.banking_amount_transaction;
                    if (FinalChartData.length === 0) {
                        //First Transaction
                        let BalanceData = {
                            Date: TransactionDateIso,
                            Balance: InitialBalance + TransactionAmount,
                            DateYYYMMMDD: TransactionDateYYYMMMDD,
                        };
                        FinalChartData.push(BalanceData);
                        PreviousDayBalance = BalanceData.Balance;
                    } else {
                        let DayExistIndex = _.findIndex(FinalChartData, {DateYYYMMMDD: TransactionDateYYYMMMDD});
                        if (DayExistIndex > -1) {
                            //Same Day, we just update the Balance
                            let NewBalance = FinalChartData[DayExistIndex].Balance + TransactionAmount;
                            FinalChartData[DayExistIndex].Balance = NewBalance;
                            PreviousDayBalance = NewBalance;
                        } else {
                            //We need to push the day and use the previous balance to get it correctly
                            let NewBalance = PreviousDayBalance + TransactionAmount;
                            let BalanceData = {
                                Date: TransactionDateIso,
                                Balance: NewBalance,
                                DateYYYMMMDD: TransactionDateYYYMMMDD,
                            };
                            PreviousDayBalance = NewBalance;
                            FinalChartData.push(BalanceData);
                        }
                    }
                });
            });
            let FinalChartOptions = {
                // title: {
                //     text: "Internet Explorer Market Share",
                // },
                // subtitle: {
                //     text: '2009-2019 (aka "good times")',
                // },
                // container: <div></div>,
                data: FinalChartData,
                autoSize: false,
                padding: {
                    top: 0,
                    right: 15,
                    left: 0,
                    bottom: 0,
                },
                width: 590,
                height: 100,
                series: [
                    {
                        type: "area",
                        xKey: "Date",
                        yKeys: ["Balance"],
                        yNames: ["Balance"],
                        tooltip: {
                            renderer: function (params) {
                                // console.log("params", params);
                                return {
                                    title: format(params.xValue, "dd-MMM-yyyy"),
                                    content: new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(params.yValue),
                                };
                            },
                        },
                        // color: "#FF6103",
                        fills: ["#FF6103"],
                        strokes: ["#FF6103"],
                        fillOpacity: 0.7,
                        marker: {
                            stroke: "black",
                            // size: 20,
                            fill: "#FF6103",
                        },
                    },
                ],
                axes: [
                    {
                        position: "bottom",
                        type: "time",
                        tick: {
                            count: agCharts.time.month.every(1),
                        },
                        title: {
                            enabled: false,
                            text: "Date",
                        },
                        label: {
                            fontSize: 10,

                            // rotation: -45,
                        },
                        gridStyle: {
                            // stroke?: string; // default: 'rgba(195, 195, 195, 1)'
                            lineDash: [0, 0], // default: [4, 2]
                        },
                    },
                    {
                        position: "left",
                        type: "number",
                        title: {
                            enabled: false,
                            text: "Price in pence",
                        },
                        gridStyle: {
                            // stroke?: string; // default: 'rgba(195, 195, 195, 1)'
                            lineDash: [0, 0], // default: [4, 2]
                        },
                        label: {
                            fontSize: 10,

                            // rotation: -45,
                        },
						tick :{
							count : 5
						}
                    },
                ],
                legend: {
                    enabled: false,
                },
            };
            // console.log("FinalChartOptions", FinalChartOptions);
            setChartOptions(FinalChartOptions);
        }
    }, [GraphData]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var GraphComponent = null;

    if (ChartOptions) {
        GraphComponent = <AgChartsReact options={ChartOptions} />;
    } else {
        GraphComponent = null;
    }

    return <div className={classes.GraphResults}>{GraphComponent}</div>;

    /***************** RENDER ******************/
});

export default Component;
