import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Document, Page as PagePDF} from "react-pdf/dist/esm/entry.webpack";
import {ZoomIn, ZoomOut, ArrowFilledLeft, ArrowFilledRight, UploadFile} from "@artibulles-cis/react-icons";
// import "react-pdf/dist/esm/Page/AnnotationLayer.css";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    PDFContainer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
        flexDirection: "column",
        height: "100%",
        // width: "100%",
    },
    Toolbar: {
        display: "flex",
        alignItems: "center",
		justifyContent : "center"
    },
    PDFWrapper: {
        width: "100%",
        flex: "1 1 auto",
        display: "flex",
        padding: "2px",
        boxSizing: "border-box",
        borderTop: "1px solid grey",
        overflow: "auto",
        justifyContent: "center",
        // overflow: "hidden",
    },
    DocumentPDFClass: {
        border: "1px solid grey",
    },
}));

const Component = React.memo(function Component(props) {
    const {FileUrl} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_PDFViewer = useRef(null);
    const Ref_Toolbar = useRef(null);

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [NumPages, setNumPages] = useState(null);
    const [PageNumber, setPageNumber] = useState(1);
    const [Scale, setScale] = useState(1);
    const [PageHeight, setPageHeight] = useState(null);
    const [PageWidth, setPageWidth] = useState(null);
    const [IsInitialized, setIsInitialized] = useState(false);
    const [PDFComponent, setPDFComponent] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const calculatePageHeight = useCallback(() => {
        // var parentNode;
        if (typeof Ref_PDFViewer === "object" && Ref_PDFViewer.current) {
            let ContainerHeight = Ref_PDFViewer.current.getBoundingClientRect().height;
            let ContainerWidth = Ref_PDFViewer.current.getBoundingClientRect().width - 3;
            let TopToolbarHeight = Ref_Toolbar.current.getBoundingClientRect().height;
            let PageHeightTemp = parseFloat(ContainerHeight) - parseFloat(TopToolbarHeight) - 6;

            // console.log("Height", PageHeightTemp);
            // console.log("Width", ContainerWidth);
            // console.log("PageHeightTemp", PageHeightTemp);
            setPageHeight(PageHeightTemp);
            setIsInitialized(true);
        }
    }, [Ref_PDFViewer]);

    const GeneratePDFComponent = useCallback(() => {
        if (IsInitialized === true) {
            let PDFComponentTemp = (
                <Document
                    className={classes.DocumentPDFClass}
                    file={FileUrl}
                    // file="https://www.artibulles-bms.artibulles.com/api/fileServer/private/purchase_invoice/PurchaseInvoice_e8ae9f33-fbd1-490c-bf66-21e4c9ff61cb.pdf"

                    onLoadSuccess={onDocumentLoadSuccess}
                    // renderMode="none"
                    // rotate={90}
                >
                    <PagePDF
                        pageNumber={PageNumber}
                        height={PageHeight}
                        // width={PageWidth}
                        scale={Scale}
                    />
                </Document>
            );
            setPDFComponent(PDFComponentTemp);
        } else {
            setPDFComponent(null);
        }
    }, [IsInitialized, FileUrl, PageNumber, PageWidth, PageHeight, Scale]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        calculatePageHeight();
    }, [calculatePageHeight]);

    useEffect(() => {
        // console.log("IsInitialized", IsInitialized);
        if (IsInitialized === true) {
            GeneratePDFComponent();
            // setTimeout(() => {
            //     GeneratePDFComponent();
            // }, 300);
        }
    }, [IsInitialized, GeneratePDFComponent]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };
    const HandleZoom = (Direction) => {
        if (Direction === "In") {
            setScale(Scale + 0.1);
        } else {
            setScale(Scale - 0.1);
        }
    };
    const HandleChangePage = (Direction) => {
        if (Direction === "Previous") {
            if (PageNumber - 1 < 1) {
            } else {
                setPageNumber(PageNumber - 1);
            }
        } else {
            if (PageNumber + 1 > NumPages) {
            } else {
                setPageNumber(PageNumber + 1);
            }
        }
    };
    const HandleOpenWindow = () => {
        window.open(FileUrl);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalPDFComponent;
    if (IsInitialized === true) {
        FinalPDFComponent = PDFComponent;
    }
    return (
        <React.Fragment>
            <div className={classes.PDFContainer} ref={Ref_PDFViewer}>
                <div className={classes.Toolbar} ref={Ref_Toolbar}>
                    <ZoomIn onClick={() => HandleZoom("In")} />
                    <ZoomOut onClick={() => HandleZoom("Out")} />
                    <ArrowFilledLeft onClick={() => HandleChangePage("Previous")} />
                    {`${PageNumber} / ${NumPages}`}
                    <ArrowFilledRight onClick={() => HandleChangePage("Next")} />
                    <UploadFile onClick={() => HandleOpenWindow()} />
                </div>
                <div className={classes.PDFWrapper}>{FinalPDFComponent}</div>
            </div>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
// <PagePDF pageNumber={PageNumber} scale={Scale} height={PageHeight} />
