/* eslint-disable */
/**
 * This is a full login frontend module
 * Step1: Show a login window to the user with email, password and a wait to reset the password
 * Step2: Receive the server response to the login
 * 	2a) Server offline -> pass back the information to display an error or allow an offline app to handle local login
 * 	2b) Wrong credentials, account locked, registration or handled error are shown to the user (not the confidential information) in toasts
 * 	2c) Right credentials -> handled the server response
 * 		2c1) Email MFA required -> show a new window to enter the code received by email
 * 		2c2) TOTP required -> if activated -> show a TOPT code window to allow the user to enter the code or to reset the system in case she/he has an issue with TOTP (Backup codes)
 * 		2c3) TOTP required -> not active -> Show a QR code that the user needs to scan to activate the authenticator
 * If in the end the use is authenticated, Pass back the authentication data to the parent component to be stored the way you want (redux....) and redirect the user
 */

import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";
import {useHistory} from "react-router-dom";
import qrcode from "qrcode";
import {toast} from "react-toastify";

import APICallExternal from "../../../../artibulles-cis/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";

import FormCompontent from "@artibulles-cis/react/FormComponent";
import Button from "@artibulles-cis/react/Button";

import {authserverurl} from "../../../common/util/APIServerAddresses";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCardWindow: {
        backgroundImage: "url('/images/LabTestingBackground1.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backdropFfilter: "blur(5px)",
    },
    ClassExtendCard: {
        borderRadius: "10px",
        padding: "10px",
        background: "rgba(255,255,255,0.99)",
        boxShadow: "rgba(0, 0, 0, 0.419608) 0 14px 26px -12px, rgba(0, 0, 0, 0.117647) 0 4px 23px 0, rgba(0, 0, 0, 0.2) 0 8px 10px -5px",
    },

    Login: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "10px",
        alignItems: "center",
    },

    FixedLoginContent: {
        flex: "0 0 80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
    },
    ImgLogin: {
        width: "60px",
        padding: "10px",
    },
    FormEmail: {
        flex: "1 1 auto",
        padding: "30px 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "350px",
        boxSizing: "border-box",
    },
    FormNoPadding: {
        flex: "1 1 auto",
        padding: "0px 0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "350px",
        boxSizing: "border-box",
    },
    FormOTPCodes: {
        flex: "1 1 auto",
        padding: "0px 0px",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        maxWidth: "400px",
        boxSizing: "border-box",
    },
    LoginTitle: {
        margin: "5px auto",
        textAlign: "center",
    },
    FormField: {
        minWidth: "320px",
        display: "flex",
        justifyContent: "center",
        padding: "20px 0px",
    },
    ForgorPassword: {
        cursor: "pointer",
        margin: "10px 0px",
    },
    QR_Wrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100px",
        height: "100px",
    },
    QR_Image: {
        width: "100px",
        height: "100px",
    },
    OTPBackup: {
        fontSize: "0.8rem",
        margin: "5px 5px",
    },
}));

const Login = (props) => {
    const {HandleLoginCallBack} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const History = useHistory();
    // const AuthServerAddress = authserverurl("production");
    const DevMode = process.env.NODE_ENV === "development" ? "development" : "production";
    // const AuthServerAddress = authserverurl(DevMode);

    // const AuthServerAddress = authserverurl("development");
    const AuthServerAddress = authserverurl(DevMode);

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [InitLoginMode, setInitLoginMode] = useState(false); //Used to first check that the user is loged in or not before rendering anything
    const [ApiRes, setApiRes] = useState(null);
    const [OTPCode, setOTPCode] = useState(null); //Used to store the generated OTP code from the server to generate the QR Code
    const [QRCode, setQRCode] = useState(null); //Used to store the QR code
    const [IsInvalidEmailCode, setIsInvalidEmailCode] = useState(false); // USed to hide/show a resend button
    const [OTPBackupCodes, setOTPBackupCodes] = useState({
        OTPBackupCode1: null,
        OTPBackupCode2: null,
        OTPBackupCode3: null,
    });

    const [Loading, setLoading] = useState(false);
    const [TokenCode, setTokenCode] = useState(null);
    const [InitalFormValues, setInitalFormValues] = React.useState({
        email: null,
        password: null,
        verificationCode: null,
        otp_code: null,
        otp_recovery1: null,
        otp_recovery2: null,
        otp_recovery3: null,
    });

    //Managing the form : Fields, errors, messages, buttons...
    const [FormValues, setFormValues] = useState({
        email: null,
        password: null,
        emailcode: null,
        otp_code: null,
        otp_recovery1: null,
        otp_recovery2: null,
        otp_recovery3: null,
        password_reset_code: null,
        newpassword: null,
        newpassword_confirm: null,
    }); //FINAL FORM VALUES
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristine === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [LoginPhase, setLoginPhase] = useState(null);
    const [ReloadForm, setReloadForm] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                //Something went wrong for instance wrong credentials are captured here
                if (ApiRes.errorMessage.message === "wrong credentials") {
                    //we reset the form
                    setReloadForm(true);
                    setFormValues(InitalFormValues);
                    setPristine(true);
                    setPristineDetails({});
                    setFormErrors({});
                    setFormErrorMain(null);
                    setTimeout(() => {
                        setReloadForm(false);
                    }, 200);
                    toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
                } else if (ApiRes.errorMessage.message === "invalid code") {
                    //We don't reset the entire form, wait for another code
                    toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
                    setLoginPhase("Enter OTP code");
                    setFormValues({...FormValues, ...{otp_code: null}});
                } else if (ApiRes.errorMessage.message === "invalid code add otp") {
                    //We go back one step in providing the QR code again
                    toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
                    setLoginPhase("MFA_OTP_GenerateQR");

                    setFormValues({...FormValues, ...{otp_code: null}});
                } else if (ApiRes.errorMessage.message === "invalid email code") {
                    //We don't reset the entire form, wait for another code
                    setIsInvalidEmailCode(true);
                    setFormValues({...FormValues, ...{emailcode: null}});
                    toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
                } else if (ApiRes.errorMessage.message === "Wrong OTP recovery code") {
                    toast.error(`Error : Wrong backup codes`, {autoClose: true});

                    setPristine(true);
                    setPristineDetails({});
                    setFormErrors({});
                    setFormErrorMain(null);
                    setFormValues({...FormValues, ...{otp_recovery1: null, otp_recovery2: null, otp_recovery3: null}});
                } else if (ApiRes.errorMessage.message === "email not sent") {
                    toast.error(`Internal server error - email couldn't be sent`, {autoClose: true});
                } else if (ApiRes.errorMessage.message === "something is wrong, check your email") {
                    toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
                    setReloadForm(true);
                    setFormValues(InitalFormValues);
                    setPristine(true);
                    setPristineDetails({});
                    setFormErrors({});
                    setFormErrorMain(null);
                    setTimeout(() => {
                        setReloadForm(false);
                    }, 200);
                } else if (ApiRes.errorMessage.message === "You Must be logged in to access this API") {
                    //It means that the checked login was fired and the user is not authenticated-> we activate the login component
                    console.log("not authenticated response from the server, LoginPhase : ", LoginPhase);
                    setInitLoginMode(true);
                } else {
                    // console.log("Server error", ApiRes.error);
                    return {error: true, errorMessage: "offline"};
                }

                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
            } else {
                // console.log("ApiRes.data", ApiRes.data);
                if (ApiRes.data.message === "authenticated" || ApiRes.data.message === "Loged in") {
                    //User successfully loged in
                    let UserInfo = ApiRes.data.data;
                    HandleLoginCallBack({Offline: false, User: UserInfo});
                    History.push("/");
                } else {
                    if (!LoginPhase) {
                        //When server answer to email and password
                        if (ApiRes.data.message === "Generate OTP verification") {
                            //Need to add OTP verification

                            setOTPCode(ApiRes.data.data.OTPCodeGenerate);
                            setLoginPhase("MFA_OTP_GenerateQR");
                        } else if (ApiRes.data.message === "Enter OTP verification code") {
                            //Confirm the OTP code
                            setLoginPhase("Enter OTP code");
                        } else if (ApiRes.data.message === "Enter email verification code") {
                            //Confirm email code
                            setLoginPhase("Enter Email code");
                        } else if (ApiRes.data.message === "Select MFA method") {
                            //Propose method to the user
                            setLoginPhase("Select MFA Method");
                        }
                    } else {
                        if (LoginPhase === "OTP get backup codes") {
                            let data = ApiRes.data.data;
                            //The serer has sent back the backup codes, ask the user to save them
                            setOTPBackupCodes({
                                OTPBackupCode1: data.OTPBackupCode1,
                                OTPBackupCode2: data.OTPBackupCode2,
                                OTPBackupCode3: data.OTPBackupCode3,
                            });
                            setLoginPhase("OTP save backup codes");
                        } else if (LoginPhase === "OTP Reset authenticator") {
                            //We reset everything to zero
                            setReloadForm(true);
                            setFormValues(InitalFormValues);
                            setPristine(true);
                            setPristineDetails({});
                            setFormErrors({});
                            setFormErrorMain(null);
                            setLoginPhase(null);
                            toast.success(`Resetting authenticator`, {autoClose: true});
                            setTimeout(() => {
                                setReloadForm(false);
                            }, 200);
                        } else if (LoginPhase === "Forgot Password Enter Email") {
                            let message = ApiRes.data.message;
                            if (message === "email sent") {
                                setLoginPhase("Forgot Password Enter Code");
                            } else {
                                //Something is wrong with the account
                                toast.error(`Error : Something is wrong with your account, please check your email`, {autoClose: true});
                                setTimeout(() => {
                                    History.push("/");
                                }, 1000);
                            }
                        } else if (LoginPhase === "Forgot Password Enter Code") {
                            let message = ApiRes.data.message;
                            if (message === "Password updated") {
                                toast.success(`Password changed`, {autoClose: true});
                                //We reset the form and start over
                                setReloadForm(true);
                                setFormValues(InitalFormValues);
                                setPristine(true);
                                setPristineDetails({});
                                setFormErrors({});
                                setFormErrorMain(null);
                                setLoginPhase(null);
                                toast.success(`Resetting authenticator`, {autoClose: true});
                                setTimeout(() => {
                                    setReloadForm(false);
                                }, 200);
                            }
                        } else if (LoginPhase === "Confirm MFA Method") {
                            let message = ApiRes.data.message;
                            if (message === "Enter email verification code") {
                                setLoginPhase("Enter Email code");
                            } else if (message === "Generate OTP verification") {
                                setOTPCode(ApiRes.data.data.OTPCodeGenerate);
                                setLoginPhase("MFA_OTP_GenerateQR");
                            }
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Before doing anything, we check first if the user is loged in or not. If loged in, we don't show the login widnow but pass a callback so that the parent component can store the user credential

        const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post"};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...{[field]: value}});
        setPristine(FinalPristine);
        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};
        var FinalUpdatedFormErrors = UpdatedFormErrors;
        if (field === "password_reset_code") {
            if (value) {
                let length = value.length;

                if (length === 40) {
                    // UpdatedFormErrors[field]=null

                    FinalUpdatedFormErrors = _.omit(FinalUpdatedFormErrors, field);
                } else {
                    FinalUpdatedFormErrors[field] = "Incorrect code";
                }
            } else {
                FinalUpdatedFormErrors[field] = "Required";
            }
        }
        var strongRegex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
        if (field === "newpassword") {
            if (value) {
                let confpwd = FormValues.newpassword_confirm;

                if (strongRegex.test(value)) {
                    if (confpwd) {
                        if (confpwd === value) {
                            FinalUpdatedFormErrors = _.omit(UpdatedFormErrors, [field]);
                        } else {
                            FinalUpdatedFormErrors[field] = "Passwords don't match";
                        }
                    } else {
                        FinalUpdatedFormErrors = _.omit(UpdatedFormErrors, [field]);
                    }
                } else {
                    FinalUpdatedFormErrors[field] = "length=8,1 special character, 1 uppercase, 1 number";
                }
            } else {
                FinalUpdatedFormErrors[field] = "Required";
            }
        }
        if (field === "newpassword_confirm") {
            if (value) {
                if (strongRegex.test(value)) {
                    //We check if the password matches
                    let newpwd = FormValues.newpassword;
                    if (newpwd === value) {
                        FinalUpdatedFormErrors = _.omit(UpdatedFormErrors, [field]);
                    } else {
                        FinalUpdatedFormErrors[field] = "Passwords don't match";
                    }
                } else {
                    FinalUpdatedFormErrors[field] = "length=8,1 special character, 1 uppercase, 1 number";
                }
            } else {
                FinalUpdatedFormErrors[field] = "Required";
            }
        }

        if (Object.keys(FinalUpdatedFormErrors).length > 0) {
            setFormErrors(FinalUpdatedFormErrors);
            setInvalid(true);
        } else {
            setFormErrors({});
            setInvalid(false);
        }
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/
    const HandleSubmitEmailPassword = () => {
        setLoading(true);
        let FinalFormValues = {
            email: FormValues.email,
            password: FormValues.password,
            appName: "Artibulles-ES-testing-App",
            logoPath: "https://www.artibulles-es-testapp.artibulles.com/api/public/images/CompanyLogo.png",
        };
        const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email`, type: "post", data: FinalFormValues};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleSubmitEmailCode = () => {
        const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email/confiremailcode`, type: "post", data: FormValues};
        setLoading(true);
        // setLoginPhase("OTP code entered");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleForgotPasswordEnterEmail = () => {
        setFormValues(InitalFormValues);
        setPristineDetails({});
        setFormErrors({});
        setFormErrorMain(null);
        setLoginPhase("Forgot Password Enter Email");
        setPristine(true);
        setInvalid(true);
    };
    const HandleForgotPasswordSendCode = () => {
        const ApiCall = {url: `${AuthServerAddress}/api/auth/forgotpassword`, type: "post", data: FormValues};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleUpdatePassword = () => {
        setLoading(true);
        const FormData = {
            email: FormValues.email,
            token: FormValues.password_reset_code,
            password: FormValues.newpassword_confirm,
        };
        const ApiCall = {url: `${AuthServerAddress}/api/auth/updatepassword`, type: "post", data: FormData};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleAddOTPMethod = () => {
        //We generate a QR code based on the information from the server
        qrcode.toDataURL(OTPCode, (err, imageUrl) => {
            if (err) {
                console.log("Error with QR");
                console.log(err);
                return;
            }
            setQRCode(imageUrl);
        });
        setLoginPhase("Scan QR Authenticator");
    };
    const HandleSubmitAddOTP = () => {
        const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email/addotp`, type: "post", data: FormValues};
        setLoading(true);
        setLoginPhase("OTP get backup codes");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleSaveBackupCodes = () => {
        const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email/otpsavebackupcodes`, type: "post", data: FormValues};
        setLoading(true);
        setLoginPhase("OTP backups saved");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleSubmitOTP = () => {
        const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email/confirmotpcode`, type: "post", data: FormValues};
        setLoading(true);
        setLoginPhase("OTP code entered");
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    const HandleResetAuthenticator = () => {
        setLoginPhase("OTP Reset authenticator");
    };

    const HandleResetAuthenticatorWithBackup = () => {
        const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email/recoverotp`, type: "post", data: FormValues};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleSelectMFAMethod = (type) => {
        if (type === "authenticator" || type === "email") {
            setLoginPhase("Confirm MFA Method");

            let FinalFormValues = {
                email: FormValues.email,
                password: FormValues.password,
                appName: "Artibulles-ES-testing-App",
                logoPath: "https://www.artibulles-es-testapp.artibulles.com/api/public/images/CompanyLogo.png",
                mfaType: type,
            };
            const ApiCall = {url: `${AuthServerAddress}/api/auth/login/email/selectmfa`, type: "post", data: FinalFormValues};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ReactFormComponent = null;

    if (ReloadForm === false) {
        if (!LoginPhase) {
            ReactFormComponent = (
                <div className={classes.FormEmail}>
                    <div className={classes.FormField}>
                        <FormCompontent
                            Name="email"
                            // InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                            FormValue={FormValues.email ? FormValues.email : null}
                            OutputValue={(values) => HandleUpdateFormValues("email", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Email"
                            Type="email"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Typing"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("email", error)}
                            Colors={{Filled: "rgb(0,0,0)"}}
                        />
                    </div>
                    <div className={classes.FormField}>
                        <FormCompontent
                            Name="password"
                            // InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                            FormValue={FormValues.password ? FormValues.password : null}
                            OutputValue={(values) => HandleUpdateFormValues("password", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Password"
                            Type="password"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Typing"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.password, invalid: FormErrors.password ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("password", error)}
                        />
                    </div>
                </div>
            );
        } else if (LoginPhase === "Select MFA Method") {
            ReactFormComponent = (
                <div className={classes.FormNoPadding} style={{alignItems: "flex-start"}}>
                    <h4 className={classes.LoginTitle}>2 Factor authentication is required to access this application. Please select a method </h4>

                    <p style={{margin: "15px 0px 0px 0px"}}>1. Receive a code by email</p>

                    <Button
                        Width="300px"
                        onClick={() => {
                            HandleSelectMFAMethod("email");
                        }}
                    >
                        Email verification code
                    </Button>
                    <p style={{margin: "15px 0px 0px 0px"}}>2. Use an authenticator (Google Auth, Microsoft, 1 password,...)</p>

                    <Button
                        Width="300px"
                        onClick={() => {
                            HandleSelectMFAMethod("authenticator");
                        }}
                    >
                        Authenticator
                    </Button>
                </div>
            );
        } else if (LoginPhase === "MFA_OTP_GenerateQR") {
            ReactFormComponent = (
                <div className={classes.FormNoPadding}>
                    <h4 className={classes.LoginTitle}>MFA is required, you will need to use an authenticator to be able to connect to this app. </h4>

                    <Button Width="300px" onClick={HandleAddOTPMethod}>
                        Generate authenticator QR code
                    </Button>
                </div>
            );
        } else if (LoginPhase === "Scan QR Authenticator") {
            ReactFormComponent = (
                <div className={classes.FormNoPadding}>
                    <h4 className={classes.LoginTitle}>Please scan the following code with your authenticator </h4>
                    <div className={classes.QR_Wrapper}>
                        <img className={classes.QR_Image} src={QRCode} alt="QrCode" />
                    </div>
                    <p style={{fontSize: "0.9rem"}}>Enter your Authenticator verification code</p>

                    <div className={classes.FormField} style={{padding: "0px"}}>
                        <FormCompontent
                            Name="otp_code"
                            // InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                            FormValue={FormValues.otp_code ? FormValues.otp_code : null}
                            OutputValue={(values) => HandleUpdateFormValues("otp_code", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="OTP code"
                            Type="password"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Typing"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.otp_code, invalid: FormErrors.otp_code ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("otp_code", error)}
                        />
                    </div>
                    <Button onClick={HandleSubmitAddOTP} disabled={FormValues.otp_code === null ? true : false}>
                        Confirm code
                    </Button>
                </div>
            );
        } else if (LoginPhase === "OTP save backup codes") {
            ReactFormComponent = (
                <div className={classes.FormOTPCodes}>
                    <h5 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        Your authenticator is set.
                    </h5>

                    <h5 className={classes.LoginTitle} style={{margin: "0px 0px 10px 0px", textAlign: "left"}}>
                        Please save those backup codes, you'll need them if you lose your authenticator.
                    </h5>
                    <h5 className={classes.LoginTitle} style={{margin: "0px 0px 5px 0px", textAlign: "left"}}>
                        If you lose those codes you won't be able to recover your account
                    </h5>
                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="otp_bckup1"
                            InitialValue={OTPBackupCodes.OTPBackupCode1}
                            FormValue={OTPBackupCodes.OTPBackupCode1}
                            // OutputValue={(values) => HandleUpdateFormValues("otp_code", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Code 1 "
                            Type="text"
                            NoMessage={true}
                            ReadOnly={true}
                            InputTextStyle={{fontSize: "0.8rem", fontWeight: "400"}}
                            // Required={true}
                            // FieldMaxWidth="200px"
                            // LocalValidation={{When: "Typing"}} //Leaving / Typing
                            // DisplayMessageIn="Popup" //Popup / Bottom
                            // meta={{error: FormErrors.otp_code, invalid: FormErrors.otp_code ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("otp_code", error)}
                        />
                    </div>
                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="otp_bckup2"
                            InitialValue={OTPBackupCodes.OTPBackupCode2}
                            FormValue={OTPBackupCodes.OTPBackupCode2}
                            // OutputValue={(values) => HandleUpdateFormValues("otp_code", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Code 2 "
                            Type="text"
                            NoMessage={true}
                            ReadOnly={true}
                            InputTextStyle={{fontSize: "0.8rem", fontWeight: "400"}}
                            // Required={true}
                            // FieldMaxWidth="200px"
                            // LocalValidation={{When: "Typing"}} //Leaving / Typing
                            // DisplayMessageIn="Popup" //Popup / Bottom
                            // meta={{error: FormErrors.otp_code, invalid: FormErrors.otp_code ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("otp_code", error)}
                        />
                    </div>
                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="otp_bckup3"
                            InitialValue={OTPBackupCodes.OTPBackupCode3}
                            FormValue={OTPBackupCodes.OTPBackupCode3}
                            // OutputValue={(values) => HandleUpdateFormValues("otp_code", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Code 3 "
                            Type="text"
                            NoMessage={true}
                            ReadOnly={true}
                            InputTextStyle={{fontSize: "0.8rem", fontWeight: "400"}}
                            // Required={true}
                            // FieldMaxWidth="200px"
                            // LocalValidation={{When: "Typing"}} //Leaving / Typing
                            // DisplayMessageIn="Popup" //Popup / Bottom
                            // meta={{error: FormErrors.otp_code, invalid: FormErrors.otp_code ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("otp_code", error)}
                        />
                    </div>
                </div>
            );
        } else if (LoginPhase === "Enter OTP code") {
            ReactFormComponent = (
                <div className={classes.FormNoPadding}>
                    <h4 className={classes.LoginTitle}>Please enter your authenticator verification code</h4>
                    <div style={{display: "flex", marginTop: "5px", flexDirection: "column", flex: "1 1 auto"}}>
                        <div className={classes.FormField} style={{padding: "0px", flex: "1 0 auto"}}>
                            <FormCompontent
                                Name="otp_code"
                                // InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                                FormValue={FormValues.otp_code ? FormValues.otp_code : null}
                                OutputValue={(values) => HandleUpdateFormValues("otp_code", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="OTP code"
                                Type="text"
                                NoMessage={false}
                                Required={true}
                                // FieldMaxWidth="200px"
                                LocalValidation={{When: "Typing"}} //Leaving / Typing
                                DisplayMessageIn="Popup" //Popup / Bottom
                                meta={{error: FormErrors.otp_code, invalid: FormErrors.otp_code ? true : false}}
                                OutputError={(error) => HandleUpdateErrors("otp_code", error)}
                            />
                        </div>
                    </div>
                    <Button onClick={HandleSubmitOTP} disabled={FormValues.otp_code === null ? true : false}>
                        Confirm code
                    </Button>
                    <div className={classes.ForgorPassword} onClick={HandleResetAuthenticator}>
                        Reset Authenticator
                    </div>
                </div>
            );
        } else if (LoginPhase === "Enter Email code") {
            ReactFormComponent = (
                <div className={classes.FormNoPadding}>
                    <h4 className={classes.LoginTitle}>Please enter the verification code sent to you by email</h4>
                    <div style={{display: "flex", marginTop: "5px", flexDirection: "column", flex: "1 1 auto"}}>
                        <div className={classes.FormField} style={{padding: "0px", flex: "1 0 auto"}}>
                            <FormCompontent
                                Name="emailcode"
                                // InitialValue={InitialFormValues.operator_name ? InitialFormValues.operator_name : null}
                                FormValue={FormValues.emailcode ? FormValues.emailcode : null}
                                OutputValue={(values) => HandleUpdateFormValues("emailcode", values)}
                                Component="Input"
                                Variant="OutlinedLabel"
                                Label="Verification code"
                                Type="text"
                                NoMessage={false}
                                Required={true}
                                // FieldMaxWidth="200px"
                                LocalValidation={{When: "Typing"}} //Leaving / Typing
                                DisplayMessageIn="Popup" //Popup / Bottom
                                meta={{error: FormErrors.emailcode, invalid: FormErrors.emailcode ? true : false}}
                                OutputError={(error) => HandleUpdateErrors("emailcode", error)}
                            />
                        </div>
                    </div>
                    <Button onClick={HandleSubmitEmailCode} disabled={FormValues.emailcode === null ? true : false}>
                        Confirm code
                    </Button>
                    <Button onClick={HandleSubmitEmailPassword} style={{display: IsInvalidEmailCode ? "flex" : "none"}}>
                        Resend code
                    </Button>
                </div>
            );
        } else if (LoginPhase === "OTP Reset authenticator") {
            ReactFormComponent = (
                <div className={classes.FormOTPCodes}>
                    <h5 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        To reset your athenticator, enter your backup codes
                    </h5>
                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="otp_recovery1"
                            InitialValue={OTPBackupCodes.otp_recovery1}
                            FormValue={OTPBackupCodes.otp_recovery1}
                            OutputValue={(values) => HandleUpdateFormValues("otp_recovery1", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Code 1 "
                            Type="text"
                            InputTextStyle={{fontSize: "0.8rem", fontWeight: "400"}}
                            Required={true}
                            NoMessage={false}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Leaving"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.otp_recovery1, invalid: FormErrors.otp_recovery1 ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("otp_recovery1", error)}
                        />
                    </div>
                    <div className={classes.FormField} style={{padding: "5px 0px", marginTop: "30px"}}>
                        <FormCompontent
                            Name="otp_recovery2"
                            InitialValue={OTPBackupCodes.otp_recovery2}
                            FormValue={OTPBackupCodes.otp_recovery2}
                            OutputValue={(values) => HandleUpdateFormValues("otp_recovery2", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Code 2 "
                            Type="text"
                            InputTextStyle={{fontSize: "0.8rem", fontWeight: "400"}}
                            Required={true}
                            NoMessage={false}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Leaving"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.otp_recovery2, invalid: FormErrors.otp_recovery2 ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("otp_recovery2", error)}
                        />
                    </div>
                    <div className={classes.FormField} style={{padding: "5px 0px", marginTop: "30px"}}>
                        <FormCompontent
                            Name="otp_recovery3"
                            InitialValue={OTPBackupCodes.otp_recovery3}
                            FormValue={OTPBackupCodes.otp_recovery3}
                            OutputValue={(values) => HandleUpdateFormValues("otp_recovery3", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Code 3 "
                            Type="text"
                            InputTextStyle={{fontSize: "0.8rem", fontWeight: "400"}}
                            Required={true}
                            NoMessage={false}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Leaving"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.otp_recovery3, invalid: FormErrors.otp_recovery3 ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("otp_recovery3", error)}
                        />
                    </div>
                </div>
            );
        } else if (LoginPhase === "Forgot Password Enter Email") {
            ReactFormComponent = (
                <div className={classes.FormOTPCodes}>
                    <h4 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        Password reset
                    </h4>
                    <h5 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        Please enter your email
                    </h5>
                    <div className={classes.FormField} style={{padding: "5px 0px", flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="email"
                            FormValue={FormValues.email ? FormValues.email : null}
                            OutputValue={(values) => HandleUpdateFormValues("email", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Email"
                            Type="email"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Typing"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.email, invalid: FormErrors.email ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("email", error)}
                            Colors={{Filled: "rgb(0,0,0)"}}
                        />
                    </div>
                    <Button Width="300px" onClick={HandleForgotPasswordSendCode}>
                        Reset Password
                    </Button>
                </div>
            );
        } else if (LoginPhase === "Forgot Password Enter Code") {
            ReactFormComponent = (
                <div className={classes.FormOTPCodes}>
                    <h4 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        Password reset
                    </h4>
                    <h5 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        Please enter the code received by email
                    </h5>
                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="password_reset_code"
                            FormValue={FormValues.password_reset_code ? FormValues.password_reset_code : null}
                            OutputValue={(values) => HandleUpdateFormValues("password_reset_code", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Reset code"
                            Type="text"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Custom"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.password_reset_code, invalid: FormErrors.password_reset_code ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("password_reset_code", error)}
                            Colors={{Filled: "rgb(0,0,0)"}}
                        />
                    </div>
                    <h5 className={classes.LoginTitle} style={{margin: "5px 0px 5px 0px"}}>
                        Please enter your new password and confirm it
                    </h5>
                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="newpassword"
                            FormValue={FormValues.newpassword ? FormValues.newpassword : null}
                            OutputValue={(values) => HandleUpdateFormValues("newpassword", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Password"
                            Type="password"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Custom"}} //Leaving / Typing
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.newpassword, invalid: FormErrors.newpassword ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("newpassword", error)}
                            Colors={{Filled: "rgb(0,0,0)"}}
                        />
                    </div>

                    <div className={classes.FormField} style={{padding: "5px 0px"}}>
                        <FormCompontent
                            Name="newpassword_confirm"
                            FormValue={FormValues.newpassword_confirm ? FormValues.newpassword_confirm : null}
                            OutputValue={(values) => HandleUpdateFormValues("newpassword_confirm", values)}
                            Component="Input"
                            Variant="OutlinedLabel"
                            Label="Confirm Password"
                            Type="Password"
                            NoMessage={false}
                            Required={true}
                            // FieldMaxWidth="200px"
                            LocalValidation={{When: "Custom"}} //Leaving / Typing / Custom
                            DisplayMessageIn="Popup" //Popup / Bottom
                            meta={{error: FormErrors.newpassword_confirm, invalid: FormErrors.newpassword_confirm ? true : false}}
                            OutputError={(error) => HandleUpdateErrors("newpassword_confirm", error)}
                            Colors={{Filled: "rgb(0,0,0)"}}
                        />
                    </div>

                    <Button Width="300px" onClick={HandleUpdatePassword} disabled={Invalid || Pristine}>
                        Update password
                    </Button>
                </div>
            );
        }
    } else {
        ReactFormComponent = null;
    }

    var LoginAndForgotPwdComponent = null;

    if (!LoginPhase) {
        LoginAndForgotPwdComponent = (
            <React.Fragment>
                <Button disabled={Invalid || Pristine} onClick={HandleSubmitEmailPassword}>
                    Login
                </Button>
                <div className={classes.ForgorPassword} onClick={HandleForgotPasswordEnterEmail}>
                    Forgot Password
                </div>
            </React.Fragment>
        );
    } else if (LoginPhase === "OTP save backup codes") {
        LoginAndForgotPwdComponent = (
            <Button onClick={HandleSaveBackupCodes} Width="250px">
                Codes saved
            </Button>
        );
    } else if (LoginPhase === "OTP Reset authenticator") {
        LoginAndForgotPwdComponent = (
            <Button onClick={HandleResetAuthenticatorWithBackup} Width="250px" disabled={FormValues.otp_recovery1 && FormValues.otp_recovery2 && FormValues.otp_recovery3 ? false : true}>
                Submit codes
            </Button>
        );
    } else {
        LoginAndForgotPwdComponent = null;
    }

    var InitComponent = null;
    if (InitLoginMode === false) {
        InitComponent = null;
    } else {
        InitComponent = (
            <div className={classes.Login}>
                <div className={classes.FixedLoginContent}>
                    <img className={classes.ImgLogin} src="/images/icons/ArtibullesLogo.svg" alt="Company logo" />
                    <h1 className={classes.AppLogin}>ArTiBulles BMS</h1>
                </div>
                {ReactFormComponent}
                {LoginAndForgotPwdComponent}
            </div>
        );
    }
    return (
        <div>
            <CardModal
                // CardID="HealFit_EditDailyIntake"
                // CloseCard={HandleCloseCardEdit}
                CardMaxWidth="450px"
                CardHeight="450px"
                CloseOnClickOutside={false}
                ShowCard={true}
                // WindowBackgroundColor="rgba(0,0,0,0.3)"
                // CardBackgroundColor="rgba(255,255,255,0.8)"
                ClassExtendCardWindow={classes.ClassExtendCardWindow}
                // WithCloseButton={true}
                ClassExtendCard={classes.ClassExtendCard}
                BackgroundImage={true}
            >
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                {InitComponent}
            </CardModal>
        </div>
    );

    /***************** RENDER ******************/
};

export default Login;
