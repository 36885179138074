/**
 * ScriptName : AgGridHelpers.js
 * Version : 1.0.0
 * Date : 2022/01/14
 * Author: ArnaudJaspard
 * Description : description
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/
import {isValid, format, parseISO} from "date-fns";
import _ from "lodash";
/********LOADING DEPENDENCIES************/

/********CORE FUNCTION************/
export const DateFormatter = (value) => {
    if (value) {
        let dateFromIso = parseISO(value);

        if (isValid(dateFromIso)) {
            return format(parseISO(value), "dd-MMM-yyyy");
        } else {
            return value;
        }
    }
};

export const DateFormatterObj = (value) => {
    if (value) {
        if (value === "dateError") {
            return "dateError";
        } else {
            return format(value, "dd-MMM-yyyy");
        }
    } else {
        return null;
    }
};

export const DateValueGetter = (field, props) => {
    if (props && props.data) {
        let value = _.get(props.data, field);
        // let value = props.data[field];

        if (value) {
            let dateFromIso = parseISO(value);
            return dateFromIso;
        } else {
            return null;
        }
    }
    return null;
};

export const CurrencyFormatter = (value) => {
    
    if (value) {
        return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(value);
    } else {
        return null;
    }
};

export const LookupValueGetter = (props) => {
	
    const {LookupField, Source, ReturnField, Params} = props;
    if (Params && Params.data) {
        // let value = Params.data[LookupField];
		let value = _.get(Params.data, LookupField);
        let Output;
        if (value && Source) {
            if (Source) {
                let Record = _.find(Source, {_id: value});
                if (Record) {
                    Output = Record[ReturnField];
                }
            }
        }
        return Output;
    } else {
        return null;
    }
};

/********CORE FUNCTION************/

/********GLOBAL FUNCTIONS************/

/********GLOBAL FUNCTIONS************/
