import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import CustomerEditAddressTable from "./CustomerAddress/CustomerEditAddressTable";
import CustomerEditBankAccountTable from "./CustomerBankAccount/CustomerEditBankAccountTable";
import CustomerEditCreditCardTable from "./CustomerCreditCards/CustomerEditCreditCardTable";
import CustomerEditContactTable from "./CustomerContacts/CustomerEditContactTable";
import CustomerAccountingEdit from "./CustomerAccounting/CustomerAccountingEdit";
import BusinessUnitsTable from "./BusinessUnits/BusinessUnitsTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
        cursor: "pointer",
    },
}));

const Component = React.memo(function Component(props) {
    const {FormViewMode, CustomerID, HandleSubmitForm, HandleFormFieldChange, FormValues, FieldOptions} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleChangeTab = (CurrentIndex, NewIndex) => {
        console.log("Change Tab", CurrentIndex, NewIndex);
        if (CurrentIndex === 4) {
            console.log("TabNav Changed");
            HandleSubmitForm();
        }
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNav
            NoLink={true}
            HandleChangeTab={HandleChangeTab}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Addresses</div>
                        </div>
                    ),

                    TabContent: <CustomerEditAddressTable CustomerID={CustomerID} />,
                },

                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Bank Accounts</div>
                        </div>
                    ),

                    TabContent: <CustomerEditBankAccountTable CompanyId={CustomerID} />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Credit Cards</div>
                        </div>
                    ),

                    TabContent: <CustomerEditCreditCardTable CompanyId={CustomerID} />,
                },

                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Contacts</div>
                        </div>
                    ),

                    TabContent: <CustomerEditContactTable CompanyId={CustomerID} />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Accounting</div>
                        </div>
                    ),
                    TabContent: <CustomerAccountingEdit CompanyId={CustomerID} HandleFormFieldChange={HandleFormFieldChange} />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Business Units</div>
                        </div>
                    ),

                    TabContent: <BusinessUnitsTable CompanyId={CustomerID} />,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
