/* eslint-disable */
import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import clsx from "clsx";
import Button from "@artibulles-cis/react/Button";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";

import {ZoomIn, ZoomOut, Expand, RotateLeft} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    MapWrapper: {
        flex: "0 0 300px",
        minWidth: "200px",
        minHeight: "200px",
        position: "relative",
        display: "flex",
    },
    MapLocateAddress: {
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "5px",
        background: "rgb(220,220,220)",
        minHeight: "100px",
    },
    MapLocateAddress_Message: {
        flex: "1 1 auto",
        display: "flex",
        whiteSpace: "normal",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8em",
        fontWeight: "300",
        margin: "0px",
        padding: "0px",
        textAlign: "center",
        height: "100%",
    },
    MapLocateAddress_Update: {
        position: "absolute",
        top: "10px",
        left: "10px",
        display: "flex",
        zIndex: "100000",
    },
    MapContainer: {
        position: "absolute",
        // width: "800px",
        // height: "400px",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    Marker: {
        backgroundSize: "cover",
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        background: "black",
        cursor: "pointer",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    MapControlToolbar: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        zIndex: 1000,
        bottom: 25,
        right: 15,
        borderRadius: "8px",
        background: "rgba(255,255,255,0.95)",
        width: "30px",
        justifyContent: "center",
        alignItems: "center",
        // boxShadow: "rgba(0, 0, 0, .419608) 0 14px 26px -12px, rgba(0, 0, 0, .117647) 0 4px 23px 0, rgba(0, 0, 0, .2) 0 8px 10px -5px",
        boxShadow: "rgba(0, 0, 0, 0.419608) 0 14px 26px -12px, rgba(0, 0, 0, 0.117647) 0 4px 23px 0, rgba(0, 0, 0, 0.2) 0 8px 10px -5px",
    },
    MapControlButton: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",

        alignItems: "center",
        padding: "5px 0px 5px 0px",
        justifyContent: "center",
        borderBottom: "1px solid rgb(180,180,180)",
    },
    MapControlButtonLast: {
        borderBottom: "none !important",
    },
    ClassExtendButton: {
        fontSize: "0.8em",
        margin: "0px",
        padding: "0px",
    },
}));

const AddressMap = (props) => {
    const {id, Coordinates, WithUpdateAddressButton, ShareCoordinates, HandleGetAddressLocator} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    mapboxgl.accessToken = "pk.eyJ1IjoiYXJuYXVkamFzcGFyZCIsImEiOiJja2RiYjcyaGIxZGltMnlubmFyZm9mM3BqIn0.QE_rL41ltdY3pE1Axwhp4A";
    /***************** REFS ******************/

    const Ref_MapContainer = useRef(null);
    const Ref_MapBoxInstance = useRef(null);
    const Ref_MapContainerFullScreen = useRef(null);

    /***************** REFS ******************/
    /***************** CONST ******************/
    const Marker = () => {
        return <div id={`marker`} className="MapBoxmarker"></div>;
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [FinalCoordinates, setFinalCoordinates] = useState(null);
    const [FinalComponent, setFinalComponent] = useState();
    const [DisplayMap, setDisplayMap] = useState(false);
    const [ApiRes, setApiRes] = useState(null); //Loader and ensuring async is done for API response
    const [Loading, setLoading] = useState(false); //Loader and ensuring async is done for API response
    const [ShowErrorMessage, setShowErrorMessage] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateFinalComponent = useCallback(() => {
        var FinalComp;
        if (FinalCoordinates) {
            FinalComp = (
                <div className={classes.MapWrapper}>
                    <div className={classes.MapLocateAddress_Update}>
                        <Button onClick={HandleLocateAddress} ClassExtendButton={classes.ClassExtendButton} style={{margin: "0px"}}>
                            Update Map
                        </Button>
                    </div>
                    <div className={classes.MapContainer} ref={Ref_MapContainer}>
                        <div className={classes.MapControlToolbar}>
                            <div className={classes.MapControlButton}>
                                <ZoomIn IconSize="25px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("ZoomIn")} />
                            </div>
                            <div className={classes.MapControlButton}>
                                <ZoomOut IconSize="25px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("ZoomOut")} />
                            </div>
                            <div className={clsx(classes.MapControlButton)}>
                                <RotateLeft IconSize="22px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("Reset")} />
                            </div>
                            <div className={clsx(classes.MapControlButton, classes.MapControlButtonLast)}>
                                <Expand IconSize="22px" SVGFillHoverColor="#FF6103" onClick={HandleOpenMapFullScreen} />
                            </div>
                        </div>
                    </div>
                </div>
            );
            setDisplayMap(true);
        } else {
            FinalComp = (
                <div className={classes.MapLocateAddress}>
                    <Button Width="120px" Height="25px" onClick={HandleLocateAddress} ClassExtendButton={classes.ClassExtendButton}>
                        Locate Address
                    </Button>
                    <div className={classes.MapLocateAddress_Message}>
                        <p>{ShowErrorMessage}</p>
                    </div>
                </div>
            );
            setDisplayMap(false);
        }

        setFinalComponent(FinalComp);
    }, [FinalCoordinates, ShowErrorMessage]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                //Something went wrong
                // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
                console.log("error", ApiRes.error);
            } else {
                console.log("ApiRes", ApiRes);
                if (ApiRes.features) {
                    let FinalCoordinates = {lng: ApiRes.features[0].center[0], lat: ApiRes.features[0].center[1]};

                    ShareCoordinates(FinalCoordinates);
                    setFinalCoordinates(FinalCoordinates);
                    //Reset the map
                    let UpdateDisplayMap = DisplayMap + 1;
                    if (UpdateDisplayMap > 1) {
                        setDisplayMap(UpdateDisplayMap);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        GenerateFinalComponent();
    }, [GenerateFinalComponent]);

    //Ensuring coordinates are ok as props before rendering anything
    useEffect(() => {
        if (Coordinates) {
            if (Coordinates.lng && Coordinates.lat) {
                setFinalCoordinates({lng: Coordinates.lng, lat: Coordinates.lat});
            }
        }
    }, [Coordinates]);

    useEffect(() => {
        if (DisplayMap) {
            const map = new mapboxgl.Map({
                container: Ref_MapContainer.current,
                // See style options here: https://docs.mapbox.com/api/maps/#styles
                style: "mapbox://styles/mapbox/streets-v11",
                center: [FinalCoordinates.lng, FinalCoordinates.lat],
                zoom: 10,
            });
            Ref_MapBoxInstance.current = map;
            // Create a default Marker and add it to the map.
			var marker1 = new mapboxgl.Marker().setLngLat(FinalCoordinates).addTo(map);
            map.scrollZoom.disable();

            return () => map.remove();
        }
    }, [DisplayMap]);

    useEffect(() => {
        if (ShowViewCard === true) {
            const map = new mapboxgl.Map({
                container: Ref_MapContainerFullScreen.current,
                // See style options here: https://docs.mapbox.com/api/maps/#styles
                style: "mapbox://styles/mapbox/streets-v11",
                // style:"mapbox://styles/mapbox/satellite-v9",
                center: [FinalCoordinates.lng, FinalCoordinates.lat],
                zoom: 10,
            });
            Ref_MapBoxInstance.current = map;
            // Create a default Marker and add it to the map.
            var marker1 = new mapboxgl.Marker().setLngLat(FinalCoordinates).addTo(map);

            return () => map.remove();
        }
    }, [ShowViewCard]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleMapControls = (action) => {
        //Matomo Tracking

        if (action === "ZoomIn") {
            Ref_MapBoxInstance.current.zoomIn({duration: 1000});
        } else if (action === "ZoomOut") {
            Ref_MapBoxInstance.current.zoomOut({duration: 1000});
        } else if (action === "Reset") {
            Ref_MapBoxInstance.current.setCenter([FinalCoordinates.lng, FinalCoordinates.lat]);
        }
    };
    const HandleOpenMapFullScreen = () => {
        setShowViewCard(true);
    };
    const HandleCloseViewCard = () => {
        setShowViewCard(false);
    };
    // https://api.mapbox.com/geocoding/v5/mapbox.places/chester.json?proximity=-74.70850,40.78375&access_token=YOUR_MAPBOX_ACCESS_TOKEN
    const HandleLocateAddress = () => {
        const AddressInputs = HandleGetAddressLocator();

        if (AddressInputs === "No Data") {
            setShowErrorMessage("Please provide country, city, street Number and street");
        } else {
            //Double cheking inputs
            if (AddressInputs.country && AddressInputs.city && AddressInputs.street_line1 && AddressInputs.zip_code) {
                var FinalAddress;
                if (AddressInputs.street_line2 && AddressInputs.region) {
                    FinalAddress = ` ${AddressInputs.street_line1} ${AddressInputs.street_line2} ${AddressInputs.region} ${AddressInputs.zip_code} ${AddressInputs.city}.json?country=${AddressInputs.country}`;
                } else {
                    FinalAddress = ` ${AddressInputs.street_line1}  ${AddressInputs.zip_code} ${AddressInputs.city}.json?country=${AddressInputs.country}`;
                }

                FinalAddress = encodeURI(FinalAddress.toLowerCase());

                const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${FinalAddress}&access_token=pk.eyJ1IjoiYXJuYXVkamFzcGFyZCIsImEiOiJja2RiYjcyaGIxZGltMnlubmFyZm9mM3BqIn0.QE_rL41ltdY3pE1Axwhp4A`;
				let ApiCall = {url: url, type: "get"};
                setLoading(true);
                async function APIInternal() {
                    const res = await APICallExternal(ApiCall);
                    setApiRes(res.data);
                    setLoading(false);
                }

                APIInternal();
            } else {
                setShowErrorMessage("Please provide country, city, street Address 1");
            }
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                // CardMaxWidth="1300px"
                // CardHeight="800px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <div className={classes.MapContainer} ref={Ref_MapContainerFullScreen}>
                        <div className={classes.MapControlToolbar}>
                            <div className={classes.MapControlButton}>
                                <ZoomIn IconSize="25px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("ZoomIn")} />
                            </div>
                            <div className={classes.MapControlButton}>
                                <ZoomOut IconSize="25px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("ZoomOut")} />
                            </div>
                            <div className={clsx(classes.MapControlButton)}>
                                <RotateLeft IconSize="22px" SVGFillHoverColor="#FF6103" onClick={() => HandleMapControls("Reset")} />
                            </div>
                        </div>
                    </div>
                </div>
            </CardModal>
        );
    }
    return (
        <React.Fragment>
            {ModalCardView}
            {FinalComponent}
        </React.Fragment>
    );

    /***************** RENDER ******************/
};

export default AddressMap;
