import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import {toast} from "react-toastify";

import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px 5px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {PassedFormValues, InvoiceId, Suppliers, HandleUpdateShowStatus} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                toast.error(`Payment not created`, {autoClose: true});
            } else {
                if (!ApiCallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Create Single Payment", Data);
                        // let SignLink = "https://dashboard.myponto.com/organizations/e175cbd5-d4e1-41ef-81e0-d456d76c6823/live/accounts/cb585984-aafe-4658-a5ad-0c8715063d37/payments/signable";

                        // setSignLink(SignLink);

                        toast.success(`Payment created`, {autoClose: true});
                        setTimeout(() => {
                            HandleCloseCard();
                        }, 500);
                    }
                } else {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        console.log("Add Payment for Bulk", Data);
                        // let SignLink = "https://dashboard.myponto.com/organizations/e175cbd5-d4e1-41ef-81e0-d456d76c6823/live/accounts/cb585984-aafe-4658-a5ad-0c8715063d37/payments/signable";

                        // setSignLink(SignLink);

                        toast.success(`Payment created`, {autoClose: true});
                        setTimeout(() => {
                            HandleCloseCard();
                        }, 500);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        let Amount, CreditorAccount, CreditorAccountBIC, DueDate, Communication, CreditorName, InvoicePaymentDueDateIso;
        if (PassedFormValues) {
            Amount = PassedFormValues.CalcTotalVATIncluded.toFixed(2);
            let BankAccountId = PassedFormValues.CounterpartyBankAccount_Id;
            DueDate = PassedFormValues.InvoicePaymentDueDateIso;
            Communication = PassedFormValues.InvoicePaymentExpectedCommunication;
            let SelectedSupplier = _.find(Suppliers, {value: PassedFormValues.ref_Supplier});
            if (SelectedSupplier) {
                CreditorName = SelectedSupplier.label;
                if (BankAccountId) {
                    if (SelectedSupplier.BankAccounts) {
                        let Account = _.find(SelectedSupplier.BankAccounts, {_id: BankAccountId});
                        if (Account) {
                            CreditorAccount = Account.account_number;
                            CreditorAccountBIC = Account.bic;
                        }
                    }
                }
            }
            InvoicePaymentDueDateIso = PassedFormValues.InvoicePaymentDueDateIso;

            let Accounts = PassedFormValues.MyCompanyBankAccounts;
            var BankAccountsOptions = [];
            if (Accounts && Accounts.length > 0) {
                for (var i = 0; i < Accounts.length; i++) {
                    let Elem = Accounts[i];
                    let Seloption = {label: Elem.select_account_owner_bank, value: Elem._id};
                    BankAccountsOptions.push(Seloption);
                }
            }
            let DefaultAccount = _.find(Accounts, {isDefaultAccount: true});
            if (DefaultAccount) {
                DefaultAccount = DefaultAccount._id;
            }

            let FinalOptions = {
                ref_bankaccount: BankAccountsOptions,
            };
            setFieldOptions(FinalOptions);
            setInitialFormValues({
                Communication: Communication,
                Amount: Amount,
                CreditorName: CreditorName,
                CreditorAccount: CreditorAccount,
                CreditorAccountBIC: CreditorAccountBIC,
                DueDate: DueDate,
                InvoicePaymentDueDateIso: InvoicePaymentDueDateIso,
                ref_bankaccount: DefaultAccount,
            });
            setFormValues({
                Communication: Communication,
                Amount: Amount,
                CreditorName: CreditorName,
                CreditorAccount: CreditorAccount,
                CreditorAccountBIC: CreditorAccountBIC,
                DueDate: DueDate,
                InvoicePaymentDueDateIso: InvoicePaymentDueDateIso,
                ref_bankaccount: DefaultAccount,
            });
            setFormInitReady(true);
        }
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleConfirmDeletion = () => {
        // const DataForm = {
        //     MyBankAccountId: FormValues.ref_bankaccount,
        //     Amount: FormValues.Amount,
        //     CreditorAccount: FormValues.CreditorAccount,
        //     CreditorAccountBIC: FormValues.CreditorAccountBIC,
        //     DueDate: FormValues.DueDate,
        //     Communication: FormValues.Communication,
        //     CreditorName: FormValues.CreditorName,
        //     ref_Invoice: InvoiceId,
        // };
        // const ApiCall = {url: `${ProdURL}/api/securebanking/createpayment`, type: "post", data: DataForm};
        // setLoading(true);
        // async function APIInternal() {
        //     const res = await APICallExternal(ApiCall);
        //     setApiRes(res);
        //     setLoading(false);
        // }
        // APIInternal();
    };

    const HandleCloseCard = () => {
        // setShowCard(false);
        HandleUpdateShowStatus(false);
    };

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };

    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        setFormValues(UpdatedFormValues);
    };

    // const SignPayment = () => {
    //     window.open(SignLink);
    // };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let FormComp;
    if (FormInitReady === true) {
        FormComp = (
            <div className={classes.FormWrapper}>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                        <FormCompontent
                            Name="ref_bankaccount"
                            InitialValue={InitialFormValues.ref_bankaccount ? InitialFormValues.ref_bankaccount : null}
                            FormValue={FormValues.ref_bankaccount ? FormValues.ref_bankaccount : null}
                            OutputSelectedValues={(values) => handleSelectChange("ref_bankaccount", values)}
                            SelectOptions={FieldOptions.ref_bankaccount}
                            Component="Select"
                            Variant="Outlined"
                            Label="Bank Account"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>

                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 270px"}}>
                        <FormCompontent
                            Name="InvoicePaymentDueDateIso"
                            InitialValue={InitialFormValues.InvoicePaymentDueDateIso ? InitialFormValues.InvoicePaymentDueDateIso : null}
                            FormValue={FormValues.InvoicePaymentDueDateIso ? FormValues.InvoicePaymentDueDateIso : null}
                            OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentDueDateIso", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Due Date"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                            ReadOnly={true}
                        />
                    </div>
                </div>

                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                        <FormCompontent
                            Name="DueDate"
                            InitialValue={InitialFormValues.DueDate ? InitialFormValues.DueDate : null}
                            FormValue={FormValues.DueDate ? FormValues.DueDate : null}
                            OutputValue={(values) => HandleUpdateFormValues("DueDate", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Payment Date"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                        <FormCompontent
                            Name="Amount"
                            InitialValue={InitialFormValues.Amount ? InitialFormValues.Amount : null}
                            FormValue={FormValues.Amount ? FormValues.Amount : null}
                            OutputValue={(values) => HandleUpdateFormValues("Amount", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Amount"
                            ReadOnly={true}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                            Imask={true}
                            Mask={{
                                Type: "Currency",
                                Options: {
                                    Currency: "EURO",
                                    // SymbolPosition: "Post",
                                    Scale: 2,
                                },
                            }}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="CreditorName"
                            InitialValue={InitialFormValues.CreditorName ? InitialFormValues.CreditorName : null}
                            FormValue={FormValues.CreditorName ? FormValues.CreditorName : null}
                            OutputValue={(values) => HandleUpdateFormValues("CreditorName", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Creditor Name"
                            ReadOnly={true}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="CreditorAccount"
                            InitialValue={InitialFormValues.CreditorAccount ? InitialFormValues.CreditorAccount : null}
                            FormValue={FormValues.CreditorAccount ? FormValues.CreditorAccount : null}
                            OutputValue={(values) => HandleUpdateFormValues("CreditorAccount", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Creditor Account"
                            ReadOnly={true}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="CreditorAccountBIC"
                            InitialValue={InitialFormValues.CreditorAccountBIC ? InitialFormValues.CreditorAccountBIC : null}
                            FormValue={FormValues.CreditorAccountBIC ? FormValues.CreditorAccountBIC : null}
                            OutputValue={(values) => HandleUpdateFormValues("CreditorAccountBIC", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Account BIC"
                            ReadOnly={true}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="Communication"
                            InitialValue={InitialFormValues.Communication ? InitialFormValues.Communication : null}
                            FormValue={FormValues.Communication ? FormValues.Communication : null}
                            OutputValue={(values) => HandleUpdateFormValues("Communication", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Communication"
                            ReadOnly={true}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                        />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <CardModal
            CardID="ReviewPayment"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={<div>Confirm transaction is deleted from Bank</div>}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={HandleCloseCard}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            CardMaxWidth="800px"
            CardHeight="600px"
            // CardWindowPaddingVertical="10px"
            // CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="50px"

            ShowCard={true}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
        >
            <div className={classes.CardWindow}>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                <div className={classes.CardMain}>
                    <div style={{maxWidth: "500px", wordWrap: "normal", alignSelf: "center", margin: "5px 0px 10px 0px"}}>You need to manually delete the following transaction from your Bank </div>
                    {FormComp}
                    <div style={{maxWidth: "500px", wordWrap: "normal", alignSelf: "center", margin: "5px 0px 10px 0px"}}>
                        Please only confirm deletion once done at the Bank otherwise the entire application will be out of sync{" "}
                    </div>
                </div>
                <div className={classes.CardButtons}>
                    <Button Width="210px" ClassExtendButton={classes.Button} onClick={HandleConfirmDeletion}>
                        Confirm deletion in bank
                    </Button>
                    <Button Width="210px" ClassExtendButton={classes.Button} onClick={HandleCloseCard}>
                        Cancel
                    </Button>
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default Component;
