import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";

import PurchaseReconcileTransactionsTabNav from "./ReconciliationTables/Purchases/PurchaseReconcileTransactionsTabNav";
import SalesReconcileTransactionsTabNav from "./ReconciliationTables/Sales/SalesReconcileTransactionsTabNav";
import AccountTransactionsReconcileTransactionsTabNav from "./ReconciliationTables/BankAccountTransactions/AccountTransactionsReconcileTransactionsTabNav";
import ReconciliationTablePurchase from "./ReconciliationTablePurchase";
import ReconciliationTableSale from "./ReconciliationTableSale";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
}));

const Component = React.memo(function Component(props) {
    const {FormViewMode, TransactionID, HandleTransactionChangedParent, FormValues} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ReconcileTransactionComponent;
    let ExpenseType = FormValues.type_expense_income;
    if (FormValues.IsReconciled === true) {
        if (ExpenseType === "Expense") {
            ReconcileTransactionComponent = <ReconciliationTablePurchase TransactionID={TransactionID} />;
        } else {
            ReconcileTransactionComponent = <ReconciliationTableSale TransactionID={TransactionID} />;
        }
    } else {
        if (FormValues.transaction_source === "CreditCard" && ExpenseType === "Income") {
            // console.log("Refund or Auto Payment");
            ReconcileTransactionComponent = (
                <AccountTransactionsReconcileTransactionsTabNav TransactionID={TransactionID} HandleTransactionChangedParent={HandleTransactionChangedParent} FormValues={FormValues} />
            );
        } else {
            if (ExpenseType === "Expense") {
                ReconcileTransactionComponent = (
                    <PurchaseReconcileTransactionsTabNav TransactionID={TransactionID} HandleTransactionChangedParent={HandleTransactionChangedParent} FormValues={FormValues} />
                );
            } else {
                ReconcileTransactionComponent = (
                    <SalesReconcileTransactionsTabNav TransactionID={TransactionID} HandleTransactionChangedParent={HandleTransactionChangedParent} FormValues={FormValues} />
                );
            }
        }
    }
    return (
        <TabNav
            NoLink={true}
            MenuFullWidth={false}
            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
            TabNavMenuSeparation="Line"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabNavMenuHeight="25px"
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Reconciliation</div>
                        </div>
                    ),

                    TabContent: ReconcileTransactionComponent,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Accounting Journal</div>
                        </div>
                    ),

                    TabContent: <div>Details</div>,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Accountant</div>
                        </div>
                    ),

                    TabContent: <div>VAT</div>,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
