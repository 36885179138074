import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {DotsMore} from "@artibulles-cis/react-icons";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {
        PopupWithButton: {
            zIndex: props.ZIndex ? props.ZIndex : "50",
            position: "relative",
        },
        PopupWithButtonButton: {
            backgroundColor: props.BackgroundColor ? props.BackgroundColor : "#222",
            width: props.IconSize ? props.IconSize : "35px",
            height: props.IconSize ? props.IconSize : "35px",
        },
    };
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    PopUpWithButton: {
        position: "relative",
    },
    PopUP: {
        position: "absolute",
        boxSizing: "border-box",
        // backgroundColor: props => (props.BackgroundColor ? props.BackgroundColor : "#222"),
        borderRadius: "5px",
        opacity: "0",
        transition: (props) => (props.TransitionDuration ? `all ${props.TransitionDuration}ms` : "all 400ms"),
        "&:before": {
            visibility: (props) => (props.WithArrow ? "visible" : "hidden"),
            content: '""',
            position: "absolute",
            width: "0px",
            height: "0px",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            top: (props) => (props.Position === "top" ? "-8px" : null),
            bottom: (props) => (props.Position === "bottom" ? "-8px" : null),
            left: "50%",
            marginLeft: "-10px",
        },
        "&:after": {
            visibility: (props) => (props.WithArrow ? "visible" : "hidden"),
            borderBottomColor: (props) => (props.Position === "bottom" ? (props.BackgroundColor ? props.BackgroundColor : "#222") : null),
            borderBottomStyle: (props) => (props.Position === "bottom" ? "solid" : null),
            borderBottomWidth: (props) => (props.Position === "bottom" ? "6px" : null),
            borderTopColor: (props) => (props.Position === "top" ? (props.BackgroundColor ? props.BackgroundColor : "#222") : null),
            borderTopStyle: (props) => (props.Position === "top" ? "solid" : null),
            borderTopWidth: (props) => (props.Position === "top" ? "6px" : null),
            content: '""',
            position: "absolute",
            width: "0px",
            height: "0px",
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            top: (props) => (props.Position === "bottom" ? "-6px" : null),
            bottom: (props) => (props.Position === "top" ? "-6px" : null),
            left: (props) => (props.Alignment === "center" ? "50%" : props.Alignment === "left" ? "23px" : null),
            right: (props) => (props.Alignment === "right" ? "14px" : null),
            marginLeft: "-8px",
        },
    },
    PopUp_hide: {
        visibility: "hidden",
        opacity: "0",
        transition: (props) => (props.TransitionDuration ? `all ${props.TransitionDuration}ms` : "all 400ms"),
    },
    PopUp_show: {
        visibility: "visible",
        opacity: "1",
    },
    ripple: {
        position: "relative",
        overflow: "hidden",
        transform: "translate3d(0, 0, 0)",
        "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            pointerEvents: "none",
            backgroundImage: "radial-gradient(circle, #000 10%, transparent 10.01%)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50%",
            transform: "scale(10,10)",
            opacity: "0",
            transition: "transform .5s, opacity 1s",
        },
        "&:active:after": {
            transform: "scale(0,0)",
            opacity: ".2",
            transition: "0s",
        },
    },
    IconEdit: {
        borderRadius: "50%",
        flex: "0 0 45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

/**
 * Create a Popup Menu with a control button
 */
const PopUpMenuWithButton = React.forwardRef(function PopUpMenuWithButton(props, ref) {
    const {
        Position,
        //
        CustomIcon,
        Alignment,
        ZIndex,
        WithArrow,
        Offset,
        BackgroundColor,
        TransitionDuration,
        IconStyle,
        children,
        IconSize,
        
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});

    const Tooltip = React.useRef();
    const ButtonPopUp = React.useRef();
    //Storing the DomNode component to attach the popover to
    const [IsOpenPopUp, setIsOpenPopUp] = React.useState(false);
    const [PopUpPosition, setPopUpPosition] = React.useState(false);
    const [Init, setInit] = React.useState(true);
    //Handle Click outside
    const HandleClickOutside = (e) => {
        if (Tooltip.current.contains(e.target)) {
            setIsOpenPopUp(true);
        } else {
            setIsOpenPopUp(false);
        }
    };
    //Adding Event Listeners for Click Outside
    React.useEffect(() => {
        if (IsOpenPopUp) {
            document.addEventListener("mousedown", HandleClickOutside);
        } else {
            document.removeEventListener("mousedown", HandleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", HandleClickOutside);
        };
    }, [IsOpenPopUp]);
    

    //Handle Click on target element
    const HandleClick = () => {
        setIsOpenPopUp(!IsOpenPopUp);
    };

    //Getting the position of the target and the dimensions of the popup container to set the appropriate position
    const GetFinalPosition = React.useCallback(() => {
        let TargetNode = ButtonPopUp.current ? ButtonPopUp.current : null;
        //Getting the info about the POPUP container
        let PopUpWidth = Tooltip.current.getBoundingClientRect().width;
        let PopUpHeight = Tooltip.current.getBoundingClientRect().height;
        let TargetWidth = TargetNode.getBoundingClientRect().width;
        let TargetHeight = TargetNode.getBoundingClientRect().height;
        let TargetLeft = TargetNode.offsetLeft ? TargetNode.offsetLeft : 0;
        let TargetTop = TargetNode.offsetTop ? TargetNode.offsetTop : 0;
        var LeftPos, TopPos;
        if (Alignment === "center") {
            LeftPos = TargetLeft - PopUpWidth / 2 + TargetWidth / 2;
        }
        if (Alignment === "right") {
            LeftPos = TargetLeft - PopUpWidth + TargetWidth;
        }
        if (Alignment === "left") {
            LeftPos = TargetLeft;
        }
        if (Position === "top") {
            TopPos = TargetTop - PopUpHeight;
        } else {
            TopPos = TargetTop + TargetHeight;
        }

        if (Offset) {
            LeftPos = LeftPos + parseInt(Offset.left) - parseInt(Offset.right);
            TopPos = TopPos + parseInt(Offset.top) - parseInt(Offset.bottom);
        }

        var FinalPosition = {
            left: LeftPos,
            top: TopPos,
        };
        setPopUpPosition(FinalPosition);
    }, [ButtonPopUp, Alignment, Offset, Position]);

    //Getting the position on mount
    React.useEffect(() => {
        GetFinalPosition();
        setInit(false);
    }, [GetFinalPosition]);

    var FinalIconButton;
    if (CustomIcon) {
        FinalIconButton = React.cloneElement(CustomIcon, {
            IconSize: "45",

            // SVGStrokeWidth: "15",

            Frame: "circle",
            FrameFillColor: "rgba(255, 255, 255, 0.1)",
            FrameFillColorHover: "rgba(0, 0, 0, 0.15)",
            FrameStrokeColorHover: "transparent",
            FrameStrokeColor: "transparent)",
            SVGTransitionDurationHover: "400",
            SVGTransitionHoverEasing: "ease-in-out",
        });
    } else {
        FinalIconButton = (
            <DotsMore
                IconSize={IconStyle ? IconStyle.IconSize : "45"}
                SVGStrokeColor={IconStyle ? IconStyle.SVGStrokeColor : "#353535"}
                SVGStrokeWidth={IconStyle ? IconStyle.SVGStrokeWidth : "15"}
                SVGFillColor={IconStyle ? IconStyle.SVGFillColor : "white"}
                Frame="circle"
                InnerPadding="-15"
                FrameFillColor="rgba(255, 255, 255, 0.1)"
                FrameFillColorHover="rgba(0, 0, 0, 0.15)"
                FrameStrokeColorHover="transparent"
                FrameStrokeColor="transparent)"
                SVGTransitionDurationHover="400"
                SVGTransitionHoverEasing="ease-in-out"
            />
        );
    }
    return (
        <div className={classes.PopUpWithButton} style={LockedstylesWithProps(props).PopupWithButton}>
            <div ref={ButtonPopUp} className={clsx(classes.IconEdit, classes.ripple)} onClick={HandleClick} style={LockedstylesWithProps(props).PopupWithButtonButton}>
                {FinalIconButton}
            </div>

            <div
                ref={Tooltip}
                style={{
                    visibility: `${Init ? "hidden" : ""}`,
                    left: PopUpPosition.left + "px",
                    top: PopUpPosition.top + "px",
                }}
                className={IsOpenPopUp ? clsx(classes.PopUp_show, classes.PopUP) : clsx(classes.PopUp_hide, classes.PopUP)}
            >
                {children}
            </div>
        </div>
    );
});

PopUpMenuWithButton.defaultProps = {
    Position: "bottom",
    CustomIcon: null,
    Alignment: "right",
    ZIndex: "400",
    WithArrow: true,
    Offset: {
        top: "0px",
        left: "0px",
        bottom: "0px",
        right: "0px",
    },
    BackgroundColor: null,
    TransitionDuration: "400",
};

PopUpMenuWithButton.propTypes = {
    /**
     * position compare to the button : top or bottom
     */
    Position: PropTypes.PropTypes.oneOf(["top", "bottom"]),
    /**
     * Pass an Artibulles-CIS Icon instead of the 3 dots Icon
     */
    CustomIcon: PropTypes.PropTypes.any,
    /**
     * position compare to the button : left or right
     */
    Alignment: PropTypes.PropTypes.oneOf(["left", "right"]),
    /**
     * The Zindex of the popup
     */
    ZIndex: PropTypes.PropTypes.string,
    /**
     * Add an arrow to the popup
     */
    WithArrow: PropTypes.bool,
    /**
     * Offset from button controller
     *Offset: {
     *  top: '0px',
     *  left: '0px',
     *  bottom: '0px',
     *  right: '0px',
     *},
     */
    Offset: PropTypes.object,
    /**
     * BackgroundColor for the popup
     */
    BackgroundColor: PropTypes.string,
    /**
     * Duration of the transition (appear and disappear) in ms
     */
    TransitionDuration: PropTypes.string,
    /**
     * The style of the Icon
     * {IconSize}
     */
    IconStyle: PropTypes.any,
};

export default PopUpMenuWithButton;
