import React, {useState, useEffect, useRef, useMemo, useCallback} from "react";
import PropTypes from "prop-types";
// import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-enterprise";
import {CurrencyFormatter, DateValueGetter, DateFormatterObj, LookupValueGetter} from "../utils/AGGridHelpers/AgGridHelpers";
import AGGridRowActionButton from "../AGGridRowActionButton/AGGridRowActionButton";
import AGGridToolbarTop from "../AGGridToolbarTop/AGGridToolbarTop";
import CheckBox from "../CheckBox/CheckBox";
import _ from "lodash";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    AGTableWrapperMain: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },

    AGTableWrapper: {
        flex: "1 0 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },
    CheckBox: {
        paddingLeft: "18px",
    },
}));

/**
 * Component Description
 */

const AGGridTableMain = React.memo(function AGGridTableMain(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        TableColumns,
        TableData,
        FullTableData,
        RowActionButtons,
        ColumnButtonStyle,
        TableOptions,
        TotalRow,
        PaginationOptions,
        //Internal
        CrudAPIUpdate,
        RowDragUpdateServer,
        ShareGridApi,
        ExcelStyles,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_Tablegrid = useRef(null);
    const Ref_GridApi = useRef(null);
    const Ref_GridColumnApi = useRef(null);
    const Ref_UpdatedTableData = useRef(null);
    /****************************** REFS *********************/

    /****************************** CONST *********************/

    const DefaultTableOptions = {};

    const DefaultColumnOptions = {
        resizable: true,
        sortable: true,
        filter: true,
        // wrapText: true,
        // flex: 1,
        // cellStyle: staticCellStyle
    };

    let FinalTableOptions = {};

    let FinalColumnOptions = {};

    //Pagination
    let Pagination;
    let PaginationPageSize;
    if (PaginationOptions) {
        Pagination = PaginationOptions.Pagination === true ? true : false;
        PaginationPageSize = PaginationOptions.PageSize ? PaginationOptions.PageSize : 20;
    }

    let Resizable, Sortable, Filterable, SupressMenu;

    if (TableOptions) {
        if (TableOptions.Resizable === false) {
            Resizable = false;
        } else {
            Resizable = true;
        }
        if (TableOptions.Sortable === false) {
            Sortable = false;
        } else {
            Sortable = true;
        }
        if (TableOptions.Filterable === false) {
            Filterable = false;
        } else {
            Filterable = true;
        }

        if (TableOptions.SupressMenu === true) {
            SupressMenu = true;
        } else {
            SupressMenu = false;
        }

        if (TableOptions.Selection) {
            if (TableOptions.Selection.GroupOnly === true) {
                FinalTableOptions.suppressRowClickSelection = true;
                FinalTableOptions.rowSelection = "multiple";
            } else {
                if (TableOptions.Selection.Multiple === true) {
                    FinalTableOptions.rowSelection = "multiple";
                }
                if (TableOptions.Selection.WithCheckBox === false) {
                } else {
                    FinalTableOptions.suppressRowClickSelection = true;
                }
            }
        } else {
            FinalTableOptions.suppressRowClickSelection = true;
        }
        if (TableOptions.autoGroupColumnDef) {
            FinalTableOptions.autoGroupColumnDef = TableOptions.autoGroupColumnDef;
        }
        if (TableOptions.groupDisplayType) {
            FinalTableOptions.groupDisplayType = TableOptions.groupDisplayType;
        }
    }

    const staticCellStyle = {
        lineHeight: "26px",
        // margin : "0px",
        // padding : "2px 2px",
        // display: "flex", //If used it will create a very strange overlap
        alignItems: "center",
        // boxSizing : "border-box"
        fontSize: TableOptions.RowFontSize ? TableOptions.RowFontSize : null,
    };

    const defaultColDef = useMemo(
        () => ({
            resizable: Resizable,
            sortable: Sortable,
            filter: Filterable,
            suppressMenu: SupressMenu,
            // allow every column to be aggregated
            enableValue: true,
            // allow every column to be grouped
            enableRowGroup: true,
            // allow every column to be pivoted
            enablePivot: true,
            wrapText: true,
            autoHeight: true,
            // flex: 1,
            cellStyle: staticCellStyle,
            headerComponentParams: {
                template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    "  </div>" +
                    "</div>",
            },
        }),
        []
    );

    const ButtonCell = (padding) => {
        return {
            display: "flex",
            alignItems: "center",
            padding: padding ? padding : "0px 0px 0px 17px",
            height: "100%",
        };
    };
    const CheckBoxCell = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const CheckBoxRendering = (params) => {
        let value = params.value;

        let FinalValue;
        if (value === true) {
            FinalValue = true;
        } else {
            FinalValue = false;
        }

        return (
            <div className={classes.CheckBox}>
                <CheckBox
                    IconSize="15px"
                    CirclePadding="5px"
                    name="boolean"
                    initialvalue={FinalValue}
                    curvalue={FinalValue}
                    disabled={true}
                    // CheckFillColor="blue"
                    // CheckFillColor={CheckBoxStyleRow.CheckFillColor}
                    // CheckMarkColor={CheckBoxStyleRow.CheckMarkColor}
                    // UncheckedBorderColor={CheckBoxStyleRow.UncheckedBorderColor}
                />
            </div>
        );
    };

    const CustomPinnedRowRenderer = (params) => {
        let FinalValue;
        if (params.valueFormatted) {
            FinalValue = params.valueFormatted;
        } else {
            FinalValue = params.value;
        }
        return <div style={{fontWeight: 600}}>{FinalValue}</div>;
    };
    /****************************** CONST *********************/

    /****************************** STATE *********************/

    const [GridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [FinalTableData, setFinalTableData] = useState(null);
    const [FinalTableColumns, setFinalTableColumns] = useState(null);
    const [FrameworkComponents, setFrameworkComponents] = useState(null);
    const [ShowSelectionToolbar, setShowSelectionToolbar] = useState(false);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    useEffect(() => {
        if (TableData && Array.isArray(TableData)) {
            TableData.forEach(function (data, index) {
                data.id = index;
                data.InitialIndex = index;
                data.NewIndex = index;
            });
            setFinalTableData(TableData);
        }
    }, [TableData]);

    useEffect(() => {
        let CurrentFrameworkComponents = FrameworkComponents;
        let FinalTableColumns = [];
        //GENERATING THE COLUMN COMPONENTS
        if (TableColumns && Array.isArray(TableColumns)) {
            for (let i = 0; i < TableColumns.length; i++) {
                let elem = TableColumns[i];
                // console.log("elem", elem);
                if (elem.cellRenderering) {
                    let RenderingFrameWorkName = `FrameworkRender_${i}`;
                    CurrentFrameworkComponents = {...CurrentFrameworkComponents, ...{[RenderingFrameWorkName]: elem.cellRenderering}};
                    elem.cellRenderer = RenderingFrameWorkName;

                    // Adding the total row rendering
                    if (elem.TotalRow && elem.TotalRow.Show === true) {
                        if (elem.TotalRow.Rendering) {
                            let RenderingFrameWorkName = `TotalRowFrameworkRender_${i}`;
                        } else {
                            elem.cellRendererSelector = (params) => {
                                if (params.node.rowPinned) {
                                    return {
                                        component: RenderingFrameWorkName,
                                        params: {style: {fontWeight: "600"}},
                                    };
                                } else {
                                    return undefined;
                                }
                            };
                        }
                    }
                } else if (elem.cellRenderer) {
                    // console.log("elem", elem);
                } else {
                    if (elem.Type === "Currency") {
                        elem.valueFormatter = (params) => CurrencyFormatter(params.value);
                    }
                    if (elem.Lookup) {
                        elem.valueGetter = (params) =>
                            LookupValueGetter({
                                LookupField: elem.Lookup.LookupField,
                                Source: FullTableData[elem.Lookup.Source],
                                ReturnField: elem.Lookup.ReturnField,
                                Params: params,
                            });
                    }

                    if (elem.Type === "Date") {
                        elem.valueGetter = (params) => DateValueGetter(elem.field, params);
                        elem.valueFormatter = (params) => DateFormatterObj(params.value);
                        elem.filter = "agDateColumnFilter";
                    }

                    if (elem.Type === "Boolean") {
                        let RenderingFrameWorkName = `CheckBoxRendering`;
                        CurrentFrameworkComponents = {...CurrentFrameworkComponents, ...{[RenderingFrameWorkName]: CheckBoxRendering}};
                        elem.cellRenderer = RenderingFrameWorkName;
                        elem.cellStyle = CheckBoxCell;
                        elem.cellRendererSelector = (params) => {
                            if (params.node.rowPinned) {
                                return <React.Fragment></React.Fragment>;
                            } else {
                                return undefined;
                            }
                        };
                    }

                    // Adding the total row rendering
                    if (elem.TotalRow && elem.TotalRow.Show === true) {
                        if (elem.TotalRow.Rendering) {
                            let RenderingFrameWorkName = `TotalRowFrameworkRender_${i}`;
                        } else {
                            elem.cellRendererSelector = (params) => {
                                if (params.node.rowPinned) {
                                    return {
                                        component: "customPinnedRowRenderer",
                                        // params: {style: {"font-size": "20px"}},
                                    };
                                } else {
                                    return undefined;
                                }
                            };
                        }
                    }
                }
                FinalTableColumns.push(elem);
            }
        }

        //GENERATING THE ROW ACTION BUTTONS COMPONENTS
        if (RowActionButtons && Array.isArray(RowActionButtons)) {
            let TotalButtons = RowActionButtons.length;
            let CellWidth, CellStyle;
            if (ColumnButtonStyle) {
                if (ColumnButtonStyle.Width) {
                    CellWidth = parseInt(ColumnButtonStyle.Width);
                }
                if (ColumnButtonStyle.padding) {
                    CellStyle = ButtonCell(ColumnButtonStyle.padding);
                }
            } else {
                if (TotalButtons === 2) {
                    CellWidth = 110;
                }
            }
            //We create the column button

            let ColActionButton = {
                field: null,
                width: CellWidth,
                cellRenderer: "FrameworkButtonRendering",
                cellRendererParams: {TableRowButtons: RowActionButtons, Theme: "Dark", GridApi: Ref_GridApi.current, CrudAPIUpdate: CrudAPIUpdate},
                cellStyle: CellStyle,
                filter: false,
                sortable: false,
                menuTabs: [],
                checkboxSelection: false,
                headerCheckboxSelection: false,
                rowDrag: TableOptions.RowDragging === true ? true : false,
                cellRendererSelector: (params) => {
                    if (params.node.rowPinned) {
                        return <React.Fragment></React.Fragment>;
                    } else {
                        return undefined;
                    }
                },
            };
            if (TableOptions && TableOptions.Selection) {
                if (TableOptions.Selection.GroupOnly === true) {
                    ColActionButton.checkboxSelection = false;
                } else {
                    if (TableOptions.Selection.WithCheckBox === true) {
                        if (TableOptions.Selection.ConditionalSelect) {
                            if (typeof TableOptions.Selection.ConditionalSelect === "function") {
                                ColActionButton.checkboxSelection = TableOptions.Selection.ConditionalSelect;
                            } else {
                                ColActionButton.checkboxSelection = true;
                            }
                        } else {
                            ColActionButton.checkboxSelection = true;
                        }

                        if (TableOptions.Selection.Multiple === true) {
                            ColActionButton.headerCheckboxSelection = true;
                        }
                    }
                }
            }
            //Add the Buttons to the Framework
            CurrentFrameworkComponents = {...CurrentFrameworkComponents, ...{FrameworkButtonRendering: AGGridRowActionButton}};
            //Insert the column at index 0
            // FinalTableColumns.splice(1, 0, ColActionButton);
            FinalTableColumns.unshift(ColActionButton);
        }
        //Adding the total row rendering framework if needed
        // console.log("CurrentFrameworkComponents", CurrentFrameworkComponents);
        if (TotalRow && TotalRow.ColsToShow) {
            CurrentFrameworkComponents = {...CurrentFrameworkComponents, ...{customPinnedRowRenderer: CustomPinnedRowRenderer}};
        }

        //PUSHING ALL TO THE STATES
        setFinalTableColumns(FinalTableColumns);
        setFrameworkComponents(CurrentFrameworkComponents);
    }, [TableColumns, RowActionButtons, RowActionButtons, Ref_GridApi.current, ColumnButtonStyle]);

    useEffect(() => {
        if (GridApi) {
            Ref_GridApi.current = GridApi;
        }
    }, [GridApi]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    const onGridReady = (params) => {
        setGridApi(params.api);
        if (ShareGridApi && typeof ShareGridApi === "function") {
            ShareGridApi(params.api);
        }
        setGridColumnApi(params.columnApi);
        Ref_GridApi.current = params.api;
        Ref_GridColumnApi.current = params.columnApi;

        //Loading the CSS style before calculating the height

        // Adding the total rows if needed
        if (TotalRow && TotalRow.ColsToShow) {
            let ColsToShow = TotalRow.ColsToShow;
            if (ColsToShow && Array.isArray(ColsToShow) && ColsToShow.length > 0) {
                setTimeout(() => {
                    let PinnedBottomData = GenerateEmptyFooterRow(ColsToShow);
                    Ref_GridApi.current.setPinnedBottomRowData([PinnedBottomData]);
                }, 500);
            }
        }
    };

    const HandleSetHeaderHeight = () => {
        if (TableOptions.WrapHeader === true) {
            var padding = 20;
            var height = HeaderHeightGetter() + padding;
            GridApi.setHeaderHeight(height);
            GridApi.resetRowHeights();
        }
    };

    const HeaderHeightGetter = () => {
        var columnHeaderTexts = [...document.querySelectorAll(".ag-header-cell-text")];
        var clientHeights = columnHeaderTexts.map((headerText) => headerText.clientHeight);
        var tallestHeaderTextHeight = Math.max(...clientHeights);

        return tallestHeaderTextHeight;
    };

    const GenerateEmptyFooterRow = (ColsToShow) => {
        // generate a row-data with null values
        let AllCols = {};
        if (Ref_GridColumnApi.current && Ref_GridColumnApi.current.getAllGridColumns) {
            Ref_GridColumnApi.current.getAllGridColumns().forEach((item) => {
                AllCols[item.colId] = null;
            });
            return CalculatePinnedBottomData(AllCols, ColsToShow);
        }
    };

    const CalculatePinnedBottomData = (AllCols, ColsToShow) => {
        //List the colums where to add a total
        let ColumnsToSum = ColsToShow;
        ColumnsToSum.forEach((element) => {
            let ColTotal = 0;

            //We only select the rendered rows
            let RenderedRows = Ref_GridApi.current.getRenderedNodes();
            let PageSize = Ref_GridApi.current.paginationGetPageSize();
            let CurrentPage = Ref_GridApi.current.paginationGetCurrentPage();

            let AllRowsInPage = [];
            let StartIndex = CurrentPage * PageSize;
            let EndIndex = StartIndex + PageSize - 1;

            let FilteredRows = [];
            Ref_GridApi.current.forEachNodeAfterFilter((rowNode) => {
                FilteredRows.push(rowNode);
            });

            let SortedRows = _.orderBy(FilteredRows, ["rowIndex"], ["asc"]);

            for (let i = StartIndex; i < EndIndex + 1; i++) {
                let Elem = SortedRows[i];
                AllRowsInPage.push(Elem);
            }

            // console.log("PageSize", PageSize);
            // console.log("CurrentPage", CurrentPage);
            // console.log("AllRowsInPage", AllRowsInPage);

            AllRowsInPage.forEach((rowNode) => {
                if (rowNode.data[element]) {
                    let CellValue = rowNode.data[element];
                    ColTotal = ColTotal + CellValue;
                }
            });

            AllCols[element] = ColTotal;
        });

        return AllCols;
    };

    const onSelectionChangedInternal = () => {
        if (TableOptions.Selection && TableOptions.Selection.OnSelectionChange) {
            if (typeof TableOptions.Selection.OnSelectionChange === "function") {
                let SelectedRows = GridApi.getSelectedRows();
                if (SelectedRows.length > 0) {
                    //Display the Toolbar
                    setShowSelectionToolbar(true);
                } else {
                    setShowSelectionToolbar(false);
                }
                TableOptions.Selection.OnSelectionChange(SelectedRows);
            }
        }
    };

    let AutogroupDef = {
        headerName: "My Group",
        // field : "Invoice.Invoices_File_Path",
        minWidth: 220,
        cellRendererParams: {
            suppressCount: true,
            checkbox: true,
            // innerRenderer : () =>{return(<div>Coucou</div>)}
        },
    };
    let GroupDisplayType;
    if (TableOptions.Grouping) {
        if (TableOptions.Grouping.Type === "Custom") {
            // console.log("CustomGroup");
            GroupDisplayType = "custom";
            AutogroupDef = null;
        } else {
            GroupDisplayType = null;
            AutogroupDef.headerName = TableOptions.Grouping.HeaderName;
            AutogroupDef.minWidth = TableOptions.Grouping.MinWidth;
        }
    }

    const SideBarDefaultOptions = {
        toolPanels: [
            {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                minWidth: 225,
                maxWidth: 225,
                width: 0,
            },
            {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
                minWidth: 180,
                maxWidth: 400,
                width: 0,
            },
        ],
        position: "right",
        // defaultToolPanel: 'filters',
        // hiddenByDefault : true
    };

    let FinalSideBar;
    if (TableOptions && TableOptions.SideBar) {
        FinalSideBar = SideBarDefaultOptions;
    } else {
        FinalSideBar = false;
    }

    // console.log("Final Table Options", FinalTableOptions);

    let StatusBar = {};

    if (TableOptions.StatusBar) {
        StatusBar = {
            statusPanels: [
                {statusPanel: "agTotalAndFilteredRowCountComponent", align: "left"},
                {statusPanel: "agTotalRowCountComponent", align: "center"},
                {statusPanel: "agFilteredRowCountComponent"},
                {statusPanel: "agSelectedRowCountComponent"},
                {statusPanel: "agAggregationComponent"},
            ],
        };
    }
    let ImmutableData = false;
    if (TableOptions.RowDragging === true) {
        ImmutableData = true;
    }
    const getRowNodeId = useCallback((data) => {
        return data.id;
    }, []);

    const onRowDragMove = useCallback(
        (event) => {
            if (TableOptions.RowDragging === true) {
                // console.log("Event", event);
                var movingNode = event.node;
                var overNode = event.overNode;

                var rowNeedsToMove = movingNode !== overNode;
                if (rowNeedsToMove && movingNode && overNode) {
                    // the list of rows we have is data, not row nodes, so extract the data
                    var movingData = movingNode.data;
                    var overData = overNode.data;
                    var fromIndex = FinalTableData.indexOf(movingData);
                    var toIndex = FinalTableData.indexOf(overData);

                    var newStore = FinalTableData.slice();
                    moveInArray(newStore, fromIndex, toIndex);
                    // TableData = newStore;
                    // console.log("newStore", newStore);
                    for (let i = 0; i < newStore.length; i++) {
                        let Row = newStore[i];
                        Row.NewIndex = i;
                    }
                    if (TableOptions.RowDragUpdateServer && typeof TableOptions.RowDragUpdateServer === "function") {
                        Ref_UpdatedTableData.current = newStore;
                        // TableOptions.RowDragUpdateServer(newStore);
                    } else {
                        setFinalTableData(newStore);
                    }

                    Ref_Tablegrid.current.api.clearFocusedCell();
                }
                function moveInArray(arr, fromIndex, toIndex) {
                    var element = arr[fromIndex];
                    arr.splice(fromIndex, 1);
                    arr.splice(toIndex, 0, element);
                }
            }
        },
        [FinalTableData]
    );
    const onRowDragEnd = useCallback((event) => {
        // console.log("DragEnd", event);
        if (TableOptions.RowDragUpdateServer && typeof TableOptions.RowDragUpdateServer === "function") {
            if (Ref_UpdatedTableData.current && Array.isArray(Ref_UpdatedTableData.current)) {
                // console.log("Ref_UpdatedTableData.current", Ref_UpdatedTableData.current);
                let ToUpdate = [];
                Ref_UpdatedTableData.current.forEach((elem) => {
                    if (elem.InitialIndex === elem.NewIndex) {
                    } else {
                        ToUpdate.push({_id: elem._id, OldIndex: elem.InitialIndex, NewIndex: elem.NewIndex});
                    }
                });
                TableOptions.RowDragUpdateServer(ToUpdate);
            }
        }
    }, []);
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    let TopToolBarComponent;
    if (TableOptions.TopToolbar) {
        TopToolBarComponent = <AGGridToolbarTop ShowToolBar={ShowSelectionToolbar} />;
    } else {
        TopToolBarComponent = null;
    }

    let TableComponent;
    if (FinalTableData && FinalTableColumns) {
        TableComponent = (
            <AgGridReact
                reactUi={true}
                onGridReady={onGridReady}
                pagination={Pagination}
                paginationPageSize={PaginationPageSize}
                suppressPaginationPanel={!Pagination}
                suppressCellSelection={true}
                frameworkComponents={FrameworkComponents}
                // rowStyle={rowStyle}
                style={{width: "100%", height: "100%"}}
                className="ag-theme-alpine-dark-custom"
                // className="ag-theme-alpine-dark"
                // className="ag-theme-balham-dark"
                rowHeight={TableOptions.RowHeight ? TableOptions.RowHeight : 35}
                headerHeight={TableOptions.HeaderHeight ? TableOptions.HeaderHeight : 40}
                rowData={FinalTableData}
                ref={Ref_Tablegrid}
                autoSizeColumn={true}
                //

                columnDefs={FinalTableColumns}
                // animateRows="true"
                // modules={modules}
                // modules={AllModules}
                defaultColDef={defaultColDef}
                // enableRangeSelection="true"

                // onFilterOpened={onFilterOpened}
                // onFilterChanged={onFilterChanged}
                // onFilterModified={onFilterModified}
                {...FinalTableOptions}
                // rowSelection="single"
                // suppressRowClickSelection={true}
                animateRows={true}
                enableCellTextSelection={true}
                onFirstDataRendered={HandleSetHeaderHeight}
                onColumnResized={HandleSetHeaderHeight}
                //For total rows
                onFilterChanged={onGridReady} //Need to recalculate on filter change
                onSortChanged={onGridReady} //Need to recalculate on filter change
                onPaginationChanged={onGridReady} //Need to recalculate on filter change
                onSelectionChanged={onSelectionChangedInternal}
                groupSelectsChildren={true}
                autoGroupColumnDef={AutogroupDef}
                groupDisplayType={GroupDisplayType}
                sideBar={FinalSideBar}
                suppressAggFuncInHeader={true}
                statusBar={StatusBar}
                immutableData={ImmutableData}
                getRowNodeId={getRowNodeId}
                onRowDragMove={onRowDragMove}
                onRowDragEnd={onRowDragEnd}
                excelStyles={ExcelStyles}
            />
        );
    } else {
        TableComponent = null;
    }
    return (
        <div className={classes.AGTableWrapperMain}>
            {TopToolBarComponent}
            <div className={classes.AGTableWrapper}>{TableComponent}</div>
        </div>
    );
    /****************************** RENDER *********************/
});

AGGridTableMain.defaultProps = {
    MyProp: null,
};

AGGridTableMain.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default AGGridTableMain;
