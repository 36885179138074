/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {EditBox} from "@artibulles-cis/react-icons";

import FormComponentPopupMessage from "../FormComponentPopupMessage/FormComponentPopupMessage";
import FieldTextInputMain from "../FieldTextInputMain/FieldTextInputMain";
import FieldSelectMain from "../FieldSelectMain/FieldSelectMain";
import FieldDatePickerMain from "../FieldDatePickerMain/FieldDatePickerMain";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {
    FormComponent: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flex: "1 1 auto",
        // width: "100%",
        boxSizing: "border-box",
    },
    FormElement: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        flex: "1 1 auto",
    },
    FieldSet: {
        left: "0px",
        right: "0px",
        // width: "100%",
        margin: "0px",
        boxSizing: "border-box",
        padding: "0px",
    },
};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormComponent: {},
    FormComponent_Wrapper: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
    },
    FieldSet: {},
    FormElement: {},
    BottomMessage: {},
    ExternalToolbar_Left: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 5px 0px 0px",
        alignSelf: "stretch",
    },
    ExternalToolbar_Right: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        padding: "0px 0px 0px 5px ",
        alignSelf: "stretch",
    },
    SideLabelLeft: {
        flex: "1 0 auto",
        position: "relative",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
    },
    SideLabelRight: {
        flex: "1 0 auto",
        position: "relative",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
    },
    PopupMessage: {
        position: "absolute",
        boxSizing: "border-box",
        borderRadius: "5px",
        opacity: "0",
        transition: "all 400ms",
        "&:before": {
            content: '""',
            position: "absolute",
            width: "0px",
            height: "0px",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            top: "-8px",
            // bottom: (props) => (props.Position === "bottom" ? "-8px" : null),
            left: "50%",
            marginLeft: "-10px",
        },
        "&:after": {
            borderBottomColor: "#222",
            borderBottomStyle: "solid",
            borderBottomWidth: "6px",
            content: '""',
            position: "absolute",
            width: "0px",
            height: "0px",
            borderLeft: "8px solid transparent",
            borderRight: "8px solid transparent",
            top: "-6px",
            // bottom: (props) => (props.Position === "top" ? "-6px" : null),
            // left: (props) => (props.Alignment === "center" ? "50%" : props.Alignment === "left" ? "23px" : null),
            // right: (props) => (props.Alignment === "right" ? "14px" : null),
            marginLeft: "-8px",
        },
    },
    OverlayWrapper: {},
    EditOverlay: {
        position: "absolute",
        height: "100%",
        width: "100%",
        top: "0px",
        left: "0px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",

        background: "rgba(0,0,0,0.7)",
        border: "1px solid rgba(0,0,0,0.7)",
        // borderRadius: BorderStyleFinal,
        boxSizing: "border-box",
    },
    EditOverlay_EditIcon: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        boxSizing: "border-box",
        paddingRight: "5px",
    },
    Legend: {
        display: "block",
        padding: "0px",
        textAlign: "left",
        width: "auto",
        visibility: "hidden",
        maxWidth: "0.01px", // when lable is at the bottom
        // transition: "max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        transition: "all 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        marginLeft: "7px", //Move the Legend/Label to the right
        lineHeight: "0",
    },
    LegendSpan: {
        visibility: "hidden",
        // padding: "0px 5px 0px 5px", // Add the same padding as for the Label!!!!!
    },
    Label: {
        position: "absolute",
        padding: "0px",
        margin: "0px",
        transformOrigin: "top left",
        top: "0px",
        left: "0px",
        pointerEvents: "none",
        // transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms", //Need to also include the padding to prevent strange effect
    },
    LabelSpan: {
        // padding: "0px 0px 0px 5px",
    },
}));

/**
 * Form Component
 * Wrapper handling the graphic interface for all form Input elements
 * Manages labels, animation, frames, border around all form elements to standardize them
 * Options are
 * Naked, Underlined, Outlined, OutlinedLabel
 */

const FormComponent = React.memo(function FormComponent(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Component,
        InitialValue,
        FormValue,
        OutputValue,
        OutputError,
		FieldStatus,
        Variant,
        Name, //Important for AutoFill
        Type, //Important for AutoFill
        Label, //Important for AutoFill
        ShowLabel,
        LabelPosition,
        LabelMaxWidth,
        FieldMaxWidth,
        LabelAlign,
        NoMessage,
        Required,
        ReadOnly,
        Disabled,
        PlaceHolder,
        ExternalToolbarLeft,
        ExternalToolbarRight,
        InternalToolbarLeft,
        AnimateToolBarLeft,
        InternalToolbarLeftIcon,
        AnimateToolbarLeftColor,
        Clearable,
        Searchable,
        DisplayMessageIn, //Diplay Messages at the Bottom or as a Popup
        HelperMessage,
        DetachedLabelStyle,
        InputTextStyle,
        Colors,
        Borders,
        BorderRadius,
        ControlledEditViewMode,
        EditModeActive,
        SetFormEditMode,
        meta: {invalid, error},
        LabelScale,
        Mask,
        LocalValidation,
        //Select Options
        OutputSelectedValues,
        SelectOptions,
        MultiSelect,
        AllowCreateOption,
        SortSelectOptions,
        SortLastItemName,
        SortFirstItemName,
        SortField,
        isSearchable,
        minMenuHeight,
        maxMenuHeight,
        menuPlacement,
        AlternateRowStyle,
        LegendOffsetHeight,
        LegendOffsetWidth,
        SortIcons,
        CreateOnServerWhenCreate,
        CreateDeleteIfUnselected,
        CreateCheckServerAccept,
        CreateOnServerURLS,
        UpdateCreatableOptions,
        //Date Picker Options
        PickerOnly,
        PickerDateType,
        CalendarOptions,
        RollerOptions,
        InputFormat,
        multiline,
        RowsToDisplay,
        MaxRows,
        OnFieldExit,
        ShowErrorInReadOnly,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_InputMain = useRef(null);
    const Ref_Fieldset = useRef(null);
    const Ref_Input = useRef(null);
    const Ref_Label = React.useRef(null);

    /****************************** REFS *********************/
    /****************************** CONST *********************/

    /****************************** Final styles *********************/
    /****************************** CONST *********************/
    /****************************** STATE *********************/
    const [Init, setInit] = useState(true);

    const [IsFocused, setIsFocused] = useState(false);
    const [HasValue, setHasValue] = useState(false);
    const [HasError, setHasError] = useState(false);
    const [FinalMessage, setFinalMessage] = useState(null);
    const [ShowOverlay, setShowOverlay] = useState(false);
    const [UpdateRefs, setUpdateRefs] = useState(0);
    const [LabelTransformXY, setLabelTransformXY] = useState({XFilled: null, YFilled: null, XEmpty: null, YEmpty: null, Scale: null});
    const [LegendSpanStyle, setLegendSpanStyle] = useState({height: null, fontSize: null, width: null});
    const [InputPadding, setInputPadding] = useState(null);
    const [InputHeight, setInputInputHeight] = useState(null);

    const [ComponentMarginTop, setComponentMarginTop] = useState(null);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    //Let's calculate the label Transform Y value based on the computed Input height
    const CalculateLabelTransform = React.useCallback(() => {
        if (Ref_Input) {
            if (Ref_Input.current && Ref_Label.current) {
                /*
				*Concept :
				*The Legend Height controls everything -> It defines the input padding and it also defines the height for the select
				*1) We get the labelHeight, we apply the scale to the label height to get the Legend Height
				*2) We Calculate the Vertical translation required for the label both when it is in the middle of the input and when it is becoming a legend
				*	-The Vertical Top position when Label is a legend is -LegendHeight/2
				*	-The Vertical Top position when Label is an input is +LegendHeight/2 + BorderThickness/2;
				*	Because of the fonts and lineHeight, we need to be able to adjust the vertical offset and the horizontal offset...Nothing we can do about it
				*3) The Input vertical padding (at least the minimum needed) is therefore the LegendHeight /2
				*4) We also need to set the select height because due to absolute position of the value container, we can't get it to work correctly by just playing with the padding

				*/
                //Getting the border thickness
                var BorderThickness;
                if (Ref_Fieldset && Ref_Fieldset.current) {
                    BorderThickness = parseFloat(Ref_Fieldset.current.style.borderBottomWidth);
                } else {
                    //We need to assume it is 1px....
                    BorderThickness = 1;
                }

                const LabelHeight = Ref_Label.current.getBoundingClientRect().height;
				
                const LegendHeight = parseFloat(LabelScale) * LabelHeight;
                // Calculating the XY transform for the Label
                var FinalOffsetLegendVertical = 0;
                if (LegendOffsetHeight) {
                    FinalOffsetLegendVertical = parseFloat(LegendOffsetHeight);
                } else {
                    FinalOffsetLegendVertical = parseFloat(theme.FormComponent.OutlinedLegendOffset.LegendOffsetHeight);
                }

                const TformYFilled = -LegendHeight / 2 - BorderThickness / 2 + FinalOffsetLegendVertical;
                const FinalCompMarginTop = LegendHeight / 2 - FinalOffsetLegendVertical;

                const TformYEmpty = LegendHeight / 2 + BorderThickness / 2; //We need to add 1/2 the border thickness
                const InputLeft = Ref_Input.current.offsetLeft;
                const TformX = InputLeft + 12; //Corresponds to the legend marginLeft + the Legend Padding Left

                const LabelWidth = Ref_Label.current.getBoundingClientRect().width;

                const FinalLabelWidth = LabelWidth * parseFloat(LabelScale) + 10 - 7; //We need to add the Label padding (5px --> Don't want it to be a prop!!!!!)

                //Fixing the Input Height Min
                var InputH;

                InputH = LabelHeight + LegendHeight;

                //Calculating the OverlayWrapper Dimensions

                //Checking what props to take for the fontSize
                // const RealLabelFont = Ref_Label.current.style.fontSize;

                // var FontUnit;
                // if (RealLabelFont.includes("rem")) {
                //     FontUnit = "rem";
                // }
                // if (RealLabelFont.includes("em") && RealLabelFont.includes("rem") === false) {
                //     FontUnit = "em";
                // }
                // if (RealLabelFont.includes("px")) {
                //     FontUnit = "px";
                // }

                let ThemeFontSize = theme.FormComponent.Input.fontSize;
                let InputFontSizeFinal = FinalInputTextStyle.fontSize ? FinalInputTextStyle.fontSize : ThemeFontSize;
                
                const LegendFontSize = parseFloat(LabelScale) * parseFloat(InputFontSizeFinal);

                // Calculating the Legend Style
                var FinalMarginLeft = 7;
                if (InternalToolbarLeft || InternalToolbarLeftIcon) {
                    //If there is internal Toolbar left, we need to move the legend outside of the toolbar
                    FinalMarginLeft = InputLeft + 5;
                }

                const FinalLegStyle = {
                    height: `${LegendHeight}px`,
                    fontSize: `${LegendFontSize}rem`,
                    width: `${FinalLabelWidth + parseFloat(LegendOffsetWidth)}px`,
                    marginLeft: `${FinalMarginLeft}px`,
                };

                //Calculating the Input Padding
                var PaddingInput;
                // PaddingInput = `${(LegendHeight - ReducedPaddingV) / 2}px 12px ${(LegendHeight - ReducedPaddingV) / 2}px 12px`;
                
				
				//THIS IS THE STANDARD VALUE FOR THE VERTICAL PADDING
				PaddingInput = `${LegendHeight / 2}px 12px`;

				//TEST WITH A REDUCED PADDING
				PaddingInput = `${LegendHeight / 2 -4}px 12px`;

                setLegendSpanStyle(FinalLegStyle);

                setLabelTransformXY({XFilled: `${TformX}px`, YFilled: `${TformYFilled}px`, XEmpty: `${TformX}px`, YEmpty: `${TformYEmpty}px`, Scale: LabelScale});
                setInputPadding(PaddingInput);
                setInputInputHeight(`${InputH}px`);
                setComponentMarginTop(`${FinalCompMarginTop}px`);
            }
        }
    }, [Ref_Input.current, Ref_Label.current, FinalInputTextStyle, LabelScale, theme, PlaceHolder]);

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //Storing the Initial Value to ensure the Child component gets the right one
    useEffect(() => {
        if (InitialValue) {
            setHasValue(true);
        }
    }, [InitialValue, Init]);

    //Updating the Error State and Message based on the error status of the component
    useEffect(() => {
        var Message = null,
            ErrorState = false;
        if (!Disabled || !ReadOnly) {
            //Only Show Errors if not disabled and not readOnly
            if (invalid) {
                ErrorState = true;
                if (error) {
                    Message = error;
                }
            } else if (HelperMessage) {
                Message = HelperMessage;
            }
        } else {
            if (ShowErrorInReadOnly === true) {
                if (invalid) {
                    ErrorState = true;
                    if (error) {
                        Message = error;
                    }
                } else if (HelperMessage) {
                    Message = HelperMessage;
                }
            } else {
                if (HelperMessage) {
                    Message = HelperMessage;
                }
            }
        }

        setFinalMessage(Message);
        setHasError(ErrorState);

        if (Init === true) {
            setInit(false); //Just to prevent rendering multiple times when not necessary
        }
    }, [HelperMessage, invalid, error, Disabled, ReadOnly, Init]);

    //Dealing with Disabled, ReadOnly and PlaceHolder
    useEffect(() => {
        if (Disabled || ReadOnly) {
            if (InitialValue) {
                setHasValue(true);
            }
        }
    }, [PlaceHolder, Disabled, ReadOnly, HasValue, InitialValue]);

    //Adding Mouse Events for fieldset based on edit mode
    useEffect(() => {
        if (Ref_Fieldset.current) {
            let FieldSetNode = Ref_Fieldset.current;
            if (ControlledEditViewMode === true) {
                if (EditModeActive === false) {
                    //Attach Events
                    FieldSetNode.addEventListener("mouseenter", HandleHoverEnterFieldSet);
                    FieldSetNode.addEventListener("mouseleave", HandleHoverLeaveFieldSet);
                    return function cleanup() {
                        FieldSetNode.removeEventListener("mouseenter", HandleHoverEnterFieldSet);
                        FieldSetNode.removeEventListener("mouseleave", HandleHoverLeaveFieldSet);
                    };
                } else {
                    FieldSetNode.removeEventListener("mouseenter", HandleHoverEnterFieldSet);
                    FieldSetNode.removeEventListener("mouseleave", HandleHoverLeaveFieldSet);
                }
            } else {
                FieldSetNode.removeEventListener("mouseenter", HandleHoverEnterFieldSet);
                FieldSetNode.removeEventListener("mouseleave", HandleHoverLeaveFieldSet);
            }
        }
    }, [ControlledEditViewMode, EditModeActive, Ref_Fieldset.current, Init]);

    // Calculating the Label transform position when mounting
    React.useEffect(() => {
        if (Variant === "OutlinedLabel") {
            CalculateLabelTransform();
        }
    }, [Variant, UpdateRefs]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    //Sharing the Input Ref and making sure it is updated
    const HandleUpdateRefInput = (arg) => {
        Ref_Input.current = arg;
        setUpdateRefs(UpdateRefs + 1);
    };
    const HandleIsFocused = (state) => {
        setIsFocused(state);
    };
    const HandleHasValue = (state) => {
        setHasValue(state);
    };
    const HandleHoverEnterFieldSet = (Event) => {
        setShowOverlay(true);
    };
    const HandleHoverLeaveFieldSet = (Event) => {
        setShowOverlay(false);
    };

    const HandleInputClick = () => {
        setShowOverlay(false);
        SetFormEditMode(true);
    };
    const HandleSelectChange = (args) => {
        // //We use this in case of multiselect or overflow to recalcuate the height of the input
        OutputSelectedValues(args);
        // setTimeout(() => {
        //     UpdateHeight();
        // }, 50);
    };
    /****************************** Final styles *********************/

    /****************************** Border and Radius for Outlined *********************/

    var FinalBorderRadius, BorderStyleEmpty, BorderStyleFocused, BorderStyleFilled, BorderStyleError, BorderStyleDisabled, BorderStyleReadOnly;
    if (Borders.Disabled) {
        BorderStyleDisabled = Borders.Disabled;
    } else {
        BorderStyleDisabled = theme.FormComponent.Outlined.Borders.Disabled;
    }
    if (Borders.ReadOnly) {
        BorderStyleReadOnly = Borders.ReadOnly;
    } else {
        BorderStyleReadOnly = theme.FormComponent.Outlined.Borders.ReadOnly;
    }
    if (Borders.Empty) {
        BorderStyleEmpty = Borders.Empty;
    } else {
        BorderStyleEmpty = theme.FormComponent.Outlined.Borders.Empty;
    }
    if (Borders.Focused) {
        BorderStyleFocused = Borders.Focused;
    } else {
        BorderStyleFocused = theme.FormComponent.Outlined.Borders.Focused;
    }
    if (Borders.Filled) {
        BorderStyleFilled = Borders.Filled;
    } else {
        BorderStyleFilled = theme.FormComponent.Outlined.Borders.Filled;
    }
    if (Borders.Error) {
        BorderStyleError = Borders.Error;
    } else {
        BorderStyleError = theme.FormComponent.Outlined.Borders.Error;
    }

    var FinalBorderStyle;
    if (Disabled === true) {
        FinalBorderStyle = BorderStyleDisabled;
    } else if (ReadOnly === true && ShowErrorInReadOnly === false) {
        FinalBorderStyle = BorderStyleReadOnly;
    } else if (HasError === true) {
        FinalBorderStyle = BorderStyleError;
    } else if (IsFocused === true) {
        FinalBorderStyle = BorderStyleFocused;
    } else {
        if (HasValue === true) {
            FinalBorderStyle = BorderStyleFilled;
        } else {
            FinalBorderStyle = BorderStyleEmpty;
        }
    }
    if (BorderRadius) {
        FinalBorderRadius = BorderRadius;
    } else {
        FinalBorderRadius = theme.FormComponent.Outlined.borderRadius;
    }
    /****************************** Border and Radius for Outlined *********************/

    /****************************** Text Styles for Input and Detached Label *********************/
    var FinalInputTextStyle, FinalDeatchedLabelTextStyle;
    // InputTextStyle: {fontWeight: null, fontFamily: null, fontSize: null},
    // DetachedLabelStyle: {fontWeight: null, fontFamily: null, fontSize: null},
    FinalInputTextStyle = {
        fontWeight: InputTextStyle.fontWeight ? InputTextStyle.fontWeight : theme.FormComponent.Input.fontWeight,
        fontFamily: InputTextStyle.fontFamily ? InputTextStyle.fontFamily : theme.FormComponent.Input.fontFamily,
        fontSize: InputTextStyle.fontSize ? InputTextStyle.fontSize : theme.FormComponent.Input.fontSize,
    };
    FinalDeatchedLabelTextStyle = {
        fontWeight: DetachedLabelStyle.fontWeight ? DetachedLabelStyle.fontWeight : theme.FormComponent.DetachedLabel.fontWeight,
        fontFamily: DetachedLabelStyle.fontFamily ? DetachedLabelStyle.fontFamily : theme.FormComponent.DetachedLabel.fontFamily,
        fontSize: DetachedLabelStyle.fontSize ? DetachedLabelStyle.fontSize : theme.FormComponent.DetachedLabel.fontSize,
    };
    /****************************** Text Styles for Input and Detached Label *********************/

    /****************************** Colors *********************/
    var FinalLabelColor, FinalInputColor, FinalColorEmpty, FinalColorFocused, FinalColorFilled, FinalColorDisabled, FinalColorReadOnly, FinalColorError, FinalColorPlaceHolder;

    FinalColorEmpty = Colors.Empty ? Colors.Empty : theme.FormComponent.TextColors.Empty;
    FinalColorFocused = Colors.Focused ? Colors.Focused : theme.FormComponent.TextColors.Focused;
    FinalColorFilled = Colors.Filled ? Colors.Filled : theme.FormComponent.TextColors.Filled;
    FinalColorDisabled = Colors.Disabled ? Colors.Disabled : theme.FormComponent.TextColors.Disabled;
    FinalColorReadOnly = Colors.ReadOnly ? Colors.ReadOnly : theme.FormComponent.TextColors.ReadOnly;
    FinalColorError = Colors.Error ? Colors.Error : theme.FormComponent.TextColors.Error;
    FinalColorPlaceHolder = Colors.PlaceHolder ? Colors.PlaceHolder : theme.FormComponent.TextColors.PlaceHolder;

    if (Disabled === true) {
        FinalLabelColor = FinalColorDisabled;
        FinalInputColor = FinalColorDisabled;
    } else if (ReadOnly === true && ShowErrorInReadOnly === false) {
        FinalLabelColor = FinalColorReadOnly;
        FinalInputColor = FinalColorReadOnly;
    } else if (HasError) {
        FinalLabelColor = FinalColorError;
        if (IsFocused === true) {
            FinalInputColor = FinalColorFocused;
        } else {
            FinalInputColor = FinalColorError;
        }
    } else if (IsFocused === true) {
        FinalLabelColor = FinalColorFocused;
        FinalInputColor = FinalColorFocused;
    } else if (HasValue === true) {
        FinalLabelColor = FinalColorFilled;
        FinalInputColor = FinalColorFilled;
    } else if (PlaceHolder) {
        FinalLabelColor = FinalColorPlaceHolder;
        FinalInputColor = FinalColorPlaceHolder;
    } else {
        FinalLabelColor = FinalColorEmpty;
        FinalInputColor = FinalColorEmpty;
    }

    /****************************** Colors *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var LeftLabelComponent, LeftExternalToolbarComponent, RightLabelComponent, RightExternalToolbarComponent, BottomMessageComponent, PopupMessageComponent;
    /****************************** Left-Right Label Component for detached label *********************/
    var FinalShowLabel = true;
    if (Variant === "Naked") {
        if (!ShowLabel || ShowLabel === false) {
            FinalShowLabel = false;
        }
    }
    if (Variant === "Outlined") {
        if (ShowLabel === false) {
            FinalShowLabel = false;
        }
    }

    if (Variant === "Naked" || Variant === "Outlined") {
        //Label is placed on the side
        if (FinalShowLabel === true && Label) {
            var FinalLabelMaxWidth;
            if (LabelMaxWidth) {
                FinalLabelMaxWidth = LabelMaxWidth;
            } else {
                FinalLabelMaxWidth = "150px";
            }
            if (LabelPosition === "Right") {
                RightLabelComponent = (
                    <div
                        className={classes.SideLabelRight}
                        style={{
                            maxWidth: FinalLabelMaxWidth,

                            justifyContent: `${LabelAlign === "Left" ? "flex-start" : LabelAlign === "Right" ? "flex-end" : "flex-end"}`,
                            ...FinalDeatchedLabelTextStyle,
                        }}
                    >
                        <span style={{textAlign: `${LabelAlign ? LabelAlign : "left"}`}}></span>
                        {Label}
                    </div>
                );
            } else {
                //Default to left if the user passed a wrong value
                LeftLabelComponent = (
                    <div
                        className={classes.SideLabelLeft}
                        style={{
                            width: "100%",
                            maxWidth: FinalLabelMaxWidth,
                            justifyContent: `${LabelAlign === "Left" ? "flex-start" : LabelAlign === "Right" ? "flex-end" : "flex-end"}`,
                            paddingRight: "10px",
                            ...FinalDeatchedLabelTextStyle,
                        }}
                    >
                        {Label}
                        {Required && <span style={{paddingLeft: "4px"}}>{"*"}</span>}
                    </div>
                );
            }
        }
    }

    /****************************** Left-Right Label Component for detached label *********************/

    /****************************** Left External Toolbar Component *********************/
    if (Variant !== "Naked") {
        if (ExternalToolbarLeft) {
            if (ControlledEditViewMode === true) {
                if (EditModeActive === true) {
                    LeftExternalToolbarComponent = <div className={classes.ExternalToolbar_Left}> {ExternalToolbarLeft}</div>;
                }
            } else {
                LeftExternalToolbarComponent = <div className={classes.ExternalToolbar_Left}> {ExternalToolbarLeft}</div>;
            }
        }
    }

    /****************************** Left External Toolbar Component *********************/

    /****************************** Right External Toolbar Component *********************/
    if (Variant !== "Naked") {
        if (ExternalToolbarRight) {
            if (ControlledEditViewMode === true) {
                if (EditModeActive === true) {
                    RightExternalToolbarComponent = <div className={classes.ExternalToolbar_Right}> {ExternalToolbarRight}</div>;
                }
            } else {
                RightExternalToolbarComponent = <div className={classes.ExternalToolbar_Right}> {ExternalToolbarRight}</div>;
            }
        }
    }

    /****************************** Right External Toolbar Component *********************/

    /****************************** Bottom Message Component *********************/
    if (FinalMessage) {
        if (Variant !== "Naked") {
            if (NoMessage === false) {
                if (DisplayMessageIn === "Bottom") {
                    if (ControlledEditViewMode === true) {
                        if (EditModeActive === true) {
                            BottomMessageComponent = <div className={classes.BottomMessage}>{FinalMessage}</div>;
                        }
                    }
                } else {
                    if (ControlledEditViewMode === true) {
                        if (EditModeActive === true) {
                            PopupMessageComponent = <FormComponentPopupMessage Message={FinalMessage} Ref_TargetComponent={Ref_InputMain} />;
                        }
                    } else {
                        PopupMessageComponent = <FormComponentPopupMessage Message={FinalMessage} Ref_TargetComponent={Ref_InputMain} />;
                    }
                }
            }
        }
    }

    /****************************** Bottom Message Component *********************/

    /****************************** Overlay Edit/View Component *********************/
    if (Variant !== "Naked") {
        var EditOverlayComp;
        if (ControlledEditViewMode === true) {
            if (EditModeActive === false) {
                if (ShowOverlay === true) {
                    EditOverlayComp = (
                        <div className={classes.EditOverlay} style={{zIndex: 10000000, borderRadius: "3px"}} onClick={HandleInputClick}>
                            <div className={classes.EditOverlay_EditIcon}>
                                <EditBox IconSize="25px" SVGStrokeColor="white" SVGFillColor="white" />
                            </div>
                        </div>
                    );
                }
            } else {
                EditOverlayComp = null;
            }
        }
    }
    /****************************** Overlay Edit/View Component *********************/

    /****************************** FinalProps *********************/
    var FinalWithSearchIcon, FinalClearable, FinalInternalToolbarLeft;
    if (Variant !== "Naked") {
        if (Component === "Select") {
            if (Clearable === true || Clearable === false) {
                FinalClearable = Clearable;
            } else {
                FinalClearable = true;
            }
        } else {
            FinalWithSearchIcon = Searchable;
            if (Clearable === true || Clearable === false) {
                FinalClearable = Clearable;
            } else {
                FinalClearable = false;
            }

            FinalInternalToolbarLeft = InternalToolbarLeft;
        }
    }

    /****************************** FinalProps *********************/

    /****************************** COMPONENT DEFINITION *********************/
    var InputComponent;
    if (Component === "Input" || Component === "DatePicker") {
        var FinalPadding;
        if (Variant === "OutlinedLabel") {
            FinalPadding = InputPadding;
        } else if (Variant === "Outlined") {
            FinalPadding = theme.FormComponent.Outlined.InputPadding;
        }
        InputComponent = (
            <FieldTextInputMain
                HandleUpdateRefInput={HandleUpdateRefInput}
                Style={{
                    ...FinalInputTextStyle,
                    padding: FinalPadding ? FinalPadding : null,
                    height: InputHeight ? InputHeight : null,
                    color: FinalInputColor,
                }}
                FieldMaxWidth={FieldMaxWidth}
                InitialValue={InitialValue}
                FormValue={FormValue}
                OutputValue={OutputValue}
				FieldStatus = {FieldStatus}
                OutputError={OutputError}
                ShareFocusState={HandleIsFocused}
                IsFocused={IsFocused}
                HasValue={HasValue}
                ShareHasValue={HandleHasValue}
                Clearable={FinalClearable}
                Searchable={FinalWithSearchIcon}
                InternalToolbarLeft={FinalInternalToolbarLeft}
                AnimateToolBarLeft={AnimateToolBarLeft}
                InternalToolbarLeftIcon={InternalToolbarLeftIcon}
                AnimateToolbarLeftColor={AnimateToolbarLeftColor}
                FinalLabelColor={FinalLabelColor}
                ReadOnly={ReadOnly}
                Disabled={Disabled}
                Mask={Mask}
                Name={Name}
                Error={FinalMessage}
                LocalValidation={LocalValidation}
                Type={Type}
                Required={Required}
                FinalBorderRadius={FinalBorderRadius}
                multiline={multiline}
                RowsToDisplay={RowsToDisplay}
                MaxRows={MaxRows}
                OnFieldExit={OnFieldExit}
            />
        );
    }

    if (Component === "Select") {
        var SplitPadding, FinalPaddingTop, FinalPaddingBottom, FinalPaddingLeft, FinalPaddingRight;
        if (Variant === "OutlinedLabel") {
            let InitialPadding = InputPadding;
            if (InitialPadding) {
                SplitPadding = InitialPadding.split(" ");
            }
        } else if (Variant === "Outlined") {
            //!!!!!We need to express the padding in top, bottom, left, right to use it for calulcation in other components
            let InitialPadding = theme.FormComponent.Outlined.InputPadding;
            if (InitialPadding) {
                SplitPadding = InitialPadding.split(" ");
            }
        }
        if (SplitPadding) {
            if (SplitPadding.length === 2) {
                FinalPaddingTop = SplitPadding[0];
                FinalPaddingBottom = SplitPadding[0];
                FinalPaddingLeft = SplitPadding[1];
                FinalPaddingRight = SplitPadding[1];
            } else {
                FinalPaddingTop = SplitPadding[0];
                FinalPaddingBottom = SplitPadding[2];
                FinalPaddingLeft = SplitPadding[1];
                FinalPaddingRight = SplitPadding[3];
            }
        }
        InputComponent = (
            <FieldSelectMain
                HandleUpdateRefInput={HandleUpdateRefInput}
                Style={{
                    ...FinalInputTextStyle,
                    paddingTop: FinalPaddingTop,
                    paddingBottom: FinalPaddingBottom,
                    paddingLeft: FinalPaddingLeft,
                    paddingRight: FinalPaddingRight,
                    height: InputHeight ? InputHeight : null,
                    color: FinalInputColor,
                }}
                InputFontProp={{
                    fontWeight: FinalInputTextStyle.fontWeight,
                    fontFamily: FinalInputTextStyle.fontFamily,
                    fontSize: FinalInputTextStyle.fontSize,
                }}
                FieldMaxWidth={FieldMaxWidth}
                InitialValue={InitialValue}
                FormValue={FormValue}
                // OutputValue={OutputValue}
                OutputSelectedValues={HandleSelectChange}
                OutputError={OutputError}
                ShareFocusState={HandleIsFocused}
                IsFocused={IsFocused}
                HasValue={HasValue}
                ShareHasValue={HandleHasValue}
                InternalToolbarLeft={FinalInternalToolbarLeft}
                AnimateToolBarLeft={AnimateToolBarLeft}
                InternalToolbarLeftIcon={InternalToolbarLeftIcon}
                AnimateToolbarLeftColor={AnimateToolbarLeftColor}
                Clearable={FinalClearable}
                Searchable={FinalWithSearchIcon}
                FinalLabelColor={FinalLabelColor}
                ReadOnly={ReadOnly}
                Disabled={Disabled}
                Name={Name}
                Error={FinalMessage}
                LocalValidation={LocalValidation}
                Type={Type}
                Required={Required}
                FinalBorderRadius={FinalBorderRadius}
                SelectOptions={SelectOptions}
                ControlledEditViewMode={ControlledEditViewMode}
                Variant={Variant}
                MultiSelect={MultiSelect}
                AllowCreateOption={AllowCreateOption}
                SortSelectOptions={SortSelectOptions}
                SortLastItemName={SortLastItemName}
                SortFirstItemName={SortFirstItemName}
                SortField={SortField}
                isSearchable={isSearchable}
                minMenuHeight={minMenuHeight}
                maxMenuHeight={maxMenuHeight}
                menuPlacement={menuPlacement}
                AlternateRowStyle={AlternateRowStyle}
                SortIcons={SortIcons}
                CreateOnServerWhenCreate={CreateOnServerWhenCreate}
                CreateDeleteIfUnselected={CreateDeleteIfUnselected}
                CreateCheckServerAccept={CreateCheckServerAccept}
                CreateOnServerURLS={CreateOnServerURLS}
                UpdateCreatableOptions={UpdateCreatableOptions}
            />
        );
    }
    if (Component === "DatePicker") {
        InputComponent = (
            <FieldDatePickerMain
                HandleUpdateRefInput={HandleUpdateRefInput}
                Style={{
                    ...FinalInputTextStyle,
                    padding: FinalPadding ? FinalPadding : null,
                    height: InputHeight ? InputHeight : null,
                    color: FinalInputColor,
                }}
                FieldMaxWidth={FieldMaxWidth}
                InitialValue={InitialValue}
                FormValue={FormValue}
                OutputValue={OutputValue}
                OutputError={OutputError}
                ShareFocusState={HandleIsFocused}
                IsFocused={IsFocused}
                HasValue={HasValue}
                ShareHasValue={HandleHasValue}
                Clearable={FinalClearable}
                Searchable={FinalWithSearchIcon}
                InternalToolbarLeft={FinalInternalToolbarLeft}
                AnimateToolBarLeft={AnimateToolBarLeft}
                InternalToolbarLeftIcon={InternalToolbarLeftIcon}
                AnimateToolbarLeftColor={AnimateToolbarLeftColor}
                FinalLabelColor={FinalLabelColor}
                ReadOnly={ReadOnly}
                Disabled={Disabled}
                Mask={Mask}
                Name={Name}
                Error={FinalMessage}
                LocalValidation={LocalValidation}
                Type={Type}
                Required={Required}
                FinalBorderRadius={FinalBorderRadius}
                PickerOnly={PickerOnly}
                PickerDateType={PickerDateType}
                CalendarOptions={CalendarOptions}
                RollerOptions={RollerOptions}
                InputFormat={InputFormat}
            />
        );
    }

    var FinalComponent;
    /****************************** COMPONENT DEFINITION *********************/

    /****************************** GRAPHIC UI BASED ON VARIANT AND TYPE *********************/
    var ComponentWrapper;
    var FieldSetClass;
    if (Variant === "Naked") {
        ComponentWrapper = (
            <fieldset
                // className={FieldSetClass}
                style={{
                    flex: "1 1 auto",
                    border: "none",
                    ...Lockedstyles.FieldSet,
                    ...LockedstylesWithProps(props).FieldSet,
                }}
            >
                {InputComponent}
            </fieldset>
        );
    } else if (Variant === "Underlined") {
        ComponentWrapper = null;
    } else if (Variant === "Outlined") {
        FieldSetClass = clsx(classes.FieldSet);

        ComponentWrapper = (
            <fieldset
                ref={Ref_Fieldset}
                className={FieldSetClass}
                style={{
                    flex: "1 1 auto",
                    border: FinalBorderStyle,
                    borderRadius: FinalBorderRadius,
                    ...Lockedstyles.FieldSet,
                    ...LockedstylesWithProps(props).FieldSet,
                }}
            >
                <div
                    className={classes.OverlayWrapper}
                    style={{
                        position: "relative",

                        zIndex: ShowOverlay === true ? 10000000 : null,
                        ...LockedstylesWithProps(props).OverlayWrapper,
                    }}
                >
                    {EditOverlayComp}
                    {InputComponent}
                </div>
            </fieldset>
        );
    } else if (Variant === "OutlinedLabel") {
        /*This is a really shitty thing that is happening in here to manage the correct height of the different elements
		*IN SUMMARY, WHAT IS IMPORTANT IS :
		*FOR THE LABEL, YOU NEED TO APPLY THE FONTS TO THE LABEL DIV ITSELF, NOT JUST THE SPAN OR THE HEIGHT WILL NOT BE CORRECT....SO WEIRD

		*/

        var LabelTransform, LegendMaxWidth;
        var LabelPadding;

        FieldSetClass = clsx(classes.FieldSet);
        // const [LabelTransformXY, setLabelTransformXY] = useState({XFilled: null, YFilled: null, XEmpty:null, YEmpty: null,Scale: null});
        if (Component === "Select") {
            //Label always on top
            LabelTransform = {
                transform: `scale(${LabelTransformXY.Scale}) translate(${LabelTransformXY.XFilled}, ${LabelTransformXY.YFilled}) translateX(0px) translateY(0px)`,
                zIndex: 10,
            };
            LegendMaxWidth = "150px";
            // LegendMaxWidth = "1000px";
            if (InternalToolbarLeftIcon || InternalToolbarLeftIcon) {
                LabelPadding = `0px 12px 0px 12px`;
            } else {
                LabelPadding = `0px 5px 0px 5px`;
            }
        } else {
            //For Input
            if (HasValue === true || IsFocused === true) {
                LabelTransform = {
                    transform: `scale(${LabelTransformXY.Scale}) translate(${LabelTransformXY.XFilled}, ${LabelTransformXY.YFilled}) translateX(0px) translateY(0px)`,
                    zIndex: 10,
                };
                LegendMaxWidth = "150px";
                // LegendMaxWidth = "1000px";
                if (InternalToolbarLeftIcon || InternalToolbarLeftIcon) {
                    LabelPadding = `0px 12px 0px 12px`;
                } else {
                    LabelPadding = `0px 5px 0px 5px`;
                }
            } else {
                LabelTransform = {
                    transform: `scale(1) translate(${LabelTransformXY.XEmpty}, ${LabelTransformXY.YEmpty}) translateX(0px) translateY(0px)`,
                    zIndex: 10,
                };
                LegendMaxWidth = "0.01px";
                LabelPadding = "0px";
            }
        }

        var LegendPaddingMain;
        if (Required === true) {
            LegendPaddingMain = "0px 0px 0px 5px";
        } else {
            LegendPaddingMain = "0px 5px 0px 5px";
        }

        var LabelClass = clsx(classes.Label, classes.LabelEmpty, classes.InputFont);
        var LegendClass = classes.Legend;
        ComponentWrapper = (
            <fieldset
                ref={Ref_Fieldset}
                className={FieldSetClass}
                style={{
                    // height: Component === "Select" ? SelectFieldSetHeight : null,
                    flex: "1 1 auto",
                    border: FinalBorderStyle,
                    borderRadius: FinalBorderRadius,
                    ...Lockedstyles.FieldSet,
                    ...LockedstylesWithProps(props).FieldSet,
                }}
            >
                <legend
                    className={LegendClass}
                    style={{
                        // height: LegendSpanStyle.height,
                        //
                        width: LegendSpanStyle.width,
                        fontSize: LegendSpanStyle.fontSize,
                        maxWidth: LegendMaxWidth,
                        marginLeft: LegendSpanStyle.marginLeft,
                    }}
                >
                    <span className={classes.LegendSpan} style={{padding: LegendPaddingMain}}>
                        {Label}
                    </span>
                    {Required && (
                        <span className={classes.LegendSpan} style={{paddingRight: "5px", paddingLeft: "5px"}}>
                            {"*"}
                        </span>
                    )}
                </legend>
                <div
                    ref={Ref_Label}
                    className={LabelClass}
                    style={{
                        fontSize: FinalInputTextStyle.fontSize,
                        fontFamily: FinalInputTextStyle.fontFamily,
                        fontWeight: FinalInputTextStyle.fontWeight,
                        color: FinalLabelColor,
                        padding: LabelPadding,
                        ...LabelTransform,
                    }}
                >
                    <span
                        className={classes.LabelSpan}
                        // style={{
                        //     fontSize: FinalInputTextStyle.fontSize,
                        //     fontFamily: FinalInputTextStyle.fontFamily,
                        //     fontWeight: FinalInputTextStyle.fontWeight,
                        // }}
                    >
                        {Label}
                    </span>
                    {Required && (
                        <span
                            className={classes.LabelSpan}
                            style={{
                                paddingLeft: "5px",
                                // fontSize: FinalInputTextStyle.fontSize,
                                // fontFamily: FinalInputTextStyle.fontFamily,
                                // fontWeight: FinalInputTextStyle.fontWeight,
                            }}
                        >
                            {"*"}
                        </span>
                    )}
                </div>
                <div
                    className={classes.OverlayWrapper}
                    style={{
                        // height: OverlayDimensions.height ? OverlayDimensions.height : null,
                        // top: OverlayDimensions.top ? OverlayDimensions.top : null,
                        position: "relative",

                        zIndex: ShowOverlay === true ? 10000000 : null,
                        ...LockedstylesWithProps(props).OverlayWrapper,
                    }}
                >
                    {EditOverlayComp}

                    {InputComponent}
                </div>
            </fieldset>
        );
    }

    /****************************** INITITIALIZATION *********************/
    if (Init === false) {
        FinalComponent = (
            <div className={classes.FormComponent} style={{marginTop: ComponentMarginTop, ...Lockedstyles.FormComponent}}>
                <div className={classes.FormComponent_Wrapper}>
                    {LeftLabelComponent}
                    {LeftExternalToolbarComponent}
                    <div ref={Ref_InputMain} className={classes.FormElement} style={{maxWidth: FieldMaxWidth ? FieldMaxWidth : null, ...Lockedstyles.FormElement}}>
                        {ComponentWrapper}
                    </div>
                    {RightExternalToolbarComponent}
                    {RightLabelComponent}
                    {PopupMessageComponent}
                </div>
                {BottomMessageComponent}
            </div>
        );
    } else {
        FinalComponent = null;
    }
    /****************************** INITITIALIZATION *********************/
    return <React.Fragment>{FinalComponent}</React.Fragment>;
    /****************************** RENDER *********************/
});

FormComponent.defaultProps = {
    Component: "Input",
    InitialValue: null,
    FormValue: null,
    OutputValue: null,
	FieldStatus : null,
    OutputError: null,
    Variant: "Outlined", //Naked / Underlined / Outlined / OutlinedLabel
    Name: null,
    Label: null,
    ShowLabel: null, //To be able to set it based on the variant type
    LabelPosition: null,
    LabelMaxWidth: null,
    FieldMaxWidth: null,
    LabelAlign: null,
    NoMessage: true,
    ExternalToolbarLeft: null,
    ExternalToolbarRight: null,
    InternalToolbarLeft: null,
    AnimateToolBarLeft: false,
    InternalToolbarLeftIcon: null,
    AnimateToolbarLeftColor: true,
    InternalToolbarRight: null,
    DisplayMessageIn: "Popup", //Options are Bottom or Popup
    HelperMessage: null,
    meta: {invalid: null, error: null},
    Required: false,
    ReadOnly: false,
    Disabled: false,
    Colors: {Empty: null, Filled: null, Focused: null, ReadOnly: null, Disabled: null, Error: null, PlaceHolder: null},
    Borders: {Empty: null, Filled: null, Focused: null, ReadOnly: null, Disabled: null, Error: null},
    BorderRadius: null,
    InputTextStyle: {fontWeight: null, fontFamily: null, fontSize: null},
    DetachedLabelStyle: {fontWeight: null, fontFamily: null, fontSize: null},
    Clearable: null, //Because we need to know what the user has passed
    Searchable: false,
    ControlledEditViewMode: false,
    EditModeActive: false,
    SetFormEditMode: null,
    PlaceHolder: null,
    LabelScale: 0.75,
    LocalValidation: null,
    Type: "text",
    //Select Options
    SelectOptions: null,
    OutputSelectedValues: null,
    MultiSelect: false,
    AllowCreateOption: false,
    SortSelectOptions: null,
    SortLastItemName: {name: null, exact: true},
    SortFirstItemName: {name: null, exact: true},
    SortField: null,
    isSearchable: true,
    minMenuHeight: null,
    maxMenuHeight: null,
    menuPlacement: "auto",
    AlternateRowStyle: false,
    LegendOffsetHeight: null,
    LegendOffsetWidth: null,
    SortIcons: false,
    CreateOnServerWhenCreate: false,
    CreateDeleteIfUnselected: true,
    CreateCheckServerAccept: false,
    CreateOnServerURLS: {get: "", post: "", delete: ""},
    UpdateCreatableOptions: null,
    PickerOnly: null,
    PickerDateType: null,
    CalendarOptions: null,
    RollerOptions: null,
    InputFormat: null,
    multiline: false,
    RowsToDisplay: "1",
    MaxRows: 3,
    OnFieldExit: null,
    ShowErrorInReadOnly: false,
};

FormComponent.propTypes = {
    /**
     * Component : Type of component to generate :
     * Input, DatePicker, Select
     */
    Component: PropTypes.any,
    /**
     * InitialValue : used to preload data in the component
     */
    InitialValue: PropTypes.any,
    /**
     * FormValue : the value passed to the component containin the current value
     */
    FormValue: PropTypes.any,
    /**
     * OutputValue : the value passed back to the parent to handle the formatting
     */
    OutputValue: PropTypes.any,

    /**
     * OutputError : share the error
     */
    OutputError: PropTypes.any,

    /**
     * Variant : the type of input //Naked / Underlined / Outlined / OutlinedLabel
     */
    Variant: PropTypes.any,

    /**
     * Name : the input name, usefull for autocomplete and password managers
     */
    Name: PropTypes.any,

    /**
     * Label : the label
     */
    Label: PropTypes.any,

    /**
     * ShowLabel : display the label
     */
    ShowLabel: PropTypes.any,

    /**
     * LabelPosition : position of the label Right or Left
     */
    LabelPosition: PropTypes.any,

    /**
     * LabelMaxWidth : the max width for the label
     */
    LabelMaxWidth: PropTypes.any,
    /**
     * FieldMaxWidth :
     */
    FieldMaxWidth: PropTypes.any,

    /**
     * LabelAlign : Alignement of the label : Left or Right
     */
    LabelAlign: PropTypes.any,

    /**
     * NoMessage : Don't display error messages
     */
    NoMessage: PropTypes.any,

    /**
     * ExternalToolbarLeft : Show a toolbar on the left of the input
     */
    ExternalToolbarLeft: PropTypes.any,

    /**
     * ExternalToolbarRight :Show a toolbar on the left of the input
     */
    ExternalToolbarRight: PropTypes.any,

    /**
     * InternalToolbarLeft :
     */
    InternalToolbarLeft: PropTypes.any,
    /**
     * AnimateToolBarLeft :
     */
    AnimateToolBarLeft: PropTypes.any,
    /**
     * InternalToolbarLeftIcon :
     */
    InternalToolbarLeftIcon: PropTypes.any,

    /**
     * AnimateToolbarLeftColor :
     */
    AnimateToolbarLeftColor: PropTypes.any,

    /**
     * InternalToolbarRight :
     */
    InternalToolbarRight: PropTypes.any,

    /**
     * DisplayMessageIn : display the error message Bottom or Tooltip
     */
    DisplayMessageIn: PropTypes.any,

    /**
     * HelperMessage : Display a helper message
     */
    HelperMessage: PropTypes.any,

    /**
     * meta : used to pass the errors to the component
     */
    meta: PropTypes.any,

    /**
     * Required :
     */
    Required: PropTypes.any,

    /**
     * ReadOnly :
     */
    ReadOnly: PropTypes.any,

    /**
     * Disabled :
     */
    Disabled: PropTypes.any,

    /**
     * Colors :{Empty: null, Filled: null, Focused: null, ReadOnly: null, Disabled: null, Error: null, PlaceHolder: null},
     */
    Colors: PropTypes.any,

    /**
     * Borders: {Empty: null, Filled: null, Focused: null, ReadOnly: null, Disabled: null, Error: null},
     */
    Borders: PropTypes.any,

    /**
     * BorderRadius :
     */
    BorderRadius: PropTypes.any,

    /**
     *   InputTextStyle: {fontWeight: null, fontFamily: null, fontSize: null}, :
     */
    InputTextStyle: PropTypes.any,

    /**
     * DetachedLabelStyle: {fontWeight: null, fontFamily: null, fontSize: null}, :
     */
    DetachedLabelStyle: PropTypes.any,

    /**
     * Clearable
     */
    Clearable: PropTypes.any,

    /**
     * Searchable
     */
    Searchable: PropTypes.any,

    /**
     * ControlledEditViewMode
     */
    ControlledEditViewMode: PropTypes.any,

    /**
     * EditModeActive
     */
    EditModeActive: PropTypes.any,
    /**
     * SetFormEditMode
     */
    SetFormEditMode: PropTypes.any,

    /**
     * PlaceHolder
     */
    PlaceHolder: PropTypes.any,

    /**
     * LabelScale
     */
    LabelScale: PropTypes.any,

    /**
     * LocalValidation
     */
    LocalValidation: PropTypes.any,

    /**
     * Type
     */
    Type: PropTypes.any,

    /**
     * SelectOptions
     */
    SelectOptions: PropTypes.any,

    /**
     * OutputSelectedValues
     */
    OutputSelectedValues: PropTypes.any,
    /**
     * MultiSelect
     */
    MultiSelect: PropTypes.any,
    /**
     * AllowCreateOption
     */
    AllowCreateOption: PropTypes.any,

    /**
     * SortSelectOptions
     */
    SortSelectOptions: PropTypes.any,
    /**
     * SortLastItemName
     */
    SortLastItemName: PropTypes.any,
    /**
     * SortFirstItemName
     */
    SortFirstItemName: PropTypes.any,

    /**
     * SortField : use to sort the list on another filed than label or value, the field must of course be passed in the options list
     */
    SortField: PropTypes.any,

    /**
     * isSearchable
     */
    isSearchable: PropTypes.any,

    /**
     * minMenuHeight
     */
    minMenuHeight: PropTypes.any,

    /**
     * maxMenuHeight
     */
    maxMenuHeight: PropTypes.any,

    /**
     * menuPlacement
     */
    menuPlacement: PropTypes.any,

    /**
     * AlternateRowStyle
     */
    AlternateRowStyle: PropTypes.any,

    /**
     * LegendOffsetHeight
     */
    LegendOffsetHeight: PropTypes.any,

    /**
     * LegendOffsetWidth
     */
    LegendOffsetWidth: PropTypes.any,

    /**
     * SortIcons
     */
    SortIcons: PropTypes.any,

    /**
     * CreateOnServerWhenCreate
     */
    CreateOnServerWhenCreate: PropTypes.any,

    /**
     * CreateDeleteIfUnselected
     */
    CreateDeleteIfUnselected: PropTypes.any,
    /**
     * CreateCheckServerAccept
     */
    CreateCheckServerAccept: PropTypes.any,

    /**
     * CreateOnServerURLS
     */
    CreateOnServerURLS: PropTypes.any,

    /**
     * UpdateCreatableOptions
     */
    UpdateCreatableOptions: PropTypes.any,

    /**
     * PickerOnly :
     * If true, the keyboard input is disabled. It's only used to display the date. When the user clicks on the input, the calendar opens.
     * If false, both keyboard and calendar can be used. The calendar is opened via the calendar button
     */
    PickerOnly: PropTypes.any,
    /**
     * PickerDateType : Different diplays of calendar and input :
     * PickerDateType : "Calendar, RollerCalendar", CalendarDateTime, RollerDateTime, MonthYear, TimeHM, TimeHMS. The only one using the real calendar is Calendar. The other options are using Roller
     *
     */
    PickerDateType: PropTypes.any,
    /**
     * CalendarOptions : {
     * Theme : "Light/Dark"
     * Locale : "frLocale"
     * 		you need to import them yourself from the date-fns/locale folder
     * 		import frLocale from "date-fns/locale/fr"
     * StartOfWeek : "0 1 2 3 4 5 6" //0 =Monday
     * ShowWeekNumber : true/false
     * TodayButton : "true/false"
     * SaveButton : "true/false" //If true -> Click Outside will be disabled
     * ResetButton : "true/false" //Reset the date to the initial opened calendar value
     * }
     */
    CalendarOptions: PropTypes.any,
    /**
     * RollerOptions : {
     * Format : "dd-LLL-yyyy"/"LLLL-dd-yyyy" //or any other valid combination
     * Locale : "frLocale"
     * 		you need to import them yourself from the date-fns/locale folder
     * 		import frLocale from "date-fns/locale/fr"
     * }
     * Alignement : "left"/"center"/"right"
     *
     *
     */
    RollerOptions: PropTypes.any,
    /**
     * InputFormat : The format to be used in the input. Both for display and for using mask while typing
     * dd/MM/yyyy
     * hh:mm
     * hh:mm:ss
     */
    InputFormat: PropTypes.any,
    /**
     * multine
     */
    multiline: PropTypes.bool,
    /**
     * Number of RowsToDisplay to open
     */
    RowsToDisplay: PropTypes.string,
    /**
     * Max Number of Rows
     */
    MaxRows: PropTypes.any,
    /**
     * When the field is left - used to share with the parent component
     */
    OnFieldExit: PropTypes.any,
    /**
     * if true -> Show errors even in readonly from initial errors
     */
    ShowErrorInReadOnly: PropTypes.any,
};

export default FormComponent;
