import React, {useEffect, useState, useRef} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
import {useParams} from "react-router-dom";
import {Route, Switch, Redirect} from "react-router-dom";

import SupplierEdit from "./SupplierEditDetails/SupplierEdit"
//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const CustomerRouter = React.memo(function CustomerRouter (props) {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});
 
/***************** REFS ******************/
 
/***************** REFS ******************/
 
 
/***************** CONST ******************/
const LocationParams = useParams();
/***************** CONST ******************/
 
 
/***************** STATE ******************/
const [Id, SetId] = useState(LocationParams.id);
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
useEffect(() => {
	console.log("SupplierRouter", LocationParams)
	if (LocationParams.id) {
		SetId(LocationParams.id);
	}
}, [LocationParams]);
/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<React.Fragment>
            <Switch>
                <Route path={`/financials/purchaseordersandinvoices/suppliers/supplier/${Id}/edit`}>
                    <SupplierEdit Id={Id} RedirectToSourceOnClosing="/financials/purchaseordersandinvoices/suppliers/table" />
                </Route>
				
                
				
            </Switch>
        </React.Fragment>
  )
 
/***************** RENDER ******************/
 
});
 
export default CustomerRouter;