import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch} from "react-router-dom";

import DemosHome from "./DemosHome";
import FormDemosEditTextInput from "./Forms/FormDemosEditTextInput";
import FormDemosEditSelect from "./Forms/FormDemosEditSelect";
import FormDemosDatePickersEdit from "./Forms/FormDemosDatePickersEdit";
import TestScrollRoller from "./Forms/TestScrollRoller"
import DragableList from "./DragableList/DragableList"
// import NewTableDemoRouter from "./NewTableDemo/NewTableDemoRouter"
import DemoReactPDF from "./DemoReactPDF"
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path="/demos">
                <DemosHome />
            </Route>
            <Route path="/demos/form/input">
                <FormDemosEditTextInput />
            </Route>
            <Route path="/demos/form/select">
                <FormDemosEditSelect />
			</Route>
			<Route path="/demos/form/datepicker">
                <FormDemosDatePickersEdit />
			</Route>
			<Route path="/demos/form/checkswitch">
                <TestScrollRoller />
			</Route>
			<Route path="/demos/dragablelist">
                <DragableList />
			</Route>
			
			<Route path="/demos/reactpdf">
                <DemoReactPDF />
			</Route>
			
        </Switch>
    );

    /***************** RENDER ******************/
};

export default Component;
