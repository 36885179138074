import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        padding: "10px 0px 0px 0px",
        position: "relative",
        boxSizing: "border-box",
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const Component = React.memo(function Component(props) {
    const {CustomerName, HandleCustomerExistCheck} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [ApiRes, setApiRes] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    console.log("Data", Data);
                    if (Data) {
                        let FinalOutput = {
                            Exist: false,
                            SimilarCompaniesComponent: null,
                        };
                        if (Data.Exist === true) {
                            FinalOutput = {
                                Exist: true,
                                SimilarCompaniesComponent: null,
                            };
                            HandleCustomerExistCheck(FinalOutput);
                        } else {
                            let FinalTableComponent;
                            if (Data.SimilarNames && Array.isArray(Data.SimilarNames)) {
                                const TableColumns = [
                                    {
                                        field: "Company",
                                        headerName: "Similar Company Name",
                                        headerClass: "ag-CenterHeader",
                                        flex: 1,
                                        filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                                        // sort: "asc",
                                        // sortIndex: 1,
                                        sortingOrder: ["asc", "desc"],
                                        // Type: "Date",
                                    },
                                    {
                                        field: "Score",
                                        headerName: "Similarity Score",
                                        headerClass: "ag-CenterHeader",
                                        flex: 1,
                                        filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
                                        // sort: "asc",
                                        // sortIndex: 2,
                                        sortingOrder: ["asc", "desc"],
                                    },
                                ];
                                const TableOptions = {
                                    // Selection: {
                                    //     // Multiple: true,
                                    //     WithCheckBox: true,
                                    // },

                                    Resizable: false,
                                    Sortable: false,
                                    Filterable: false,
                                    WrapText: true,
                                    WrapHeader: true,
                                    // SideBar: "filters",
                                };
                                FinalTableComponent = (
                                    <AGGridTable
                                        TableName="Suppliers"
                                        Data={Data.SimilarNames}
                                        TableColumns={TableColumns}
                                        // MainDataSource="Invoices"
                                        // RowActionButtons={TableRowButtons}
                                        // ColumnButtonStyle={ColumnButtonStyle}
                                        TableOptions={TableOptions}
                                        // ReloadData={ReloadTableData}
                                        // SyncReloadDataStateFunction={setReloadTableData}
                                        // TotalRow={TotalRow}
                                        PaginationOptions={{
                                            Pagination: false,
                                            PageSize: 50,
                                        }}
                                    />
                                );
                            }
                            FinalOutput = {
                                Exist: false,
                                SimilarCompaniesComponent: FinalTableComponent,
                            };
                            HandleCustomerExistCheck(FinalOutput);
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        const DataForm = {
            CustomerName: CustomerName,
        };
        const ApiCall = {url: `${ProdURL}/api/companies/checkcustomernameexists`, type: "post", data: DataForm};
        setLoading(true);
        setAPICallType(null);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            Customer Name Validation
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
