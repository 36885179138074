import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {CustomerStatus, CompanyId, VATNumber, VATValidationResponse} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                // toast.error(`Payment not created`, {autoClose: true});
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;

                    // console.log("Data", Data);
                    let Output = {
                        Valid: false,
                    };
                    if (!Data.error) {
                        Output.Valid = true;
                        VATValidationResponse(Output);
                    } else {
                        VATValidationResponse(Output);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (VATNumber) {
            let DataForm = {CompanyId: CompanyId, VATNumber: VATNumber, CustomerStatus: CustomerStatus};
            const ApiCall = {url: `${ProdURL}/api/companies/company/vatnumbercheck`, type: "post", data: DataForm};
            setLoading(true);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [VATNumber]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
