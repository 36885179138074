/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Route, Switch, Redirect} from "react-router-dom";
import InvoicesDashboard from "./Dashboard/InvoicesDashboard";
import SuppliersTable from "./Suppliers/SuppliersTable";
import InvoicesTabNavRouter from "./PurchaseInvoices/InvoicesTabNavRouter";
import InvoiceGeneratorRouter from "./InvoiceGenerator/InvoiceGeneratorRouter";
import InvoiceRouter from "./PurchaseInvoices/InvoiceEdit/InvoiceRouter";
import SupplierRouter from "./Suppliers/Supplier/SupplierRouter";
import SupplierImportFromAccountTable from "./Suppliers/ImportingFromAccouting/SupplierImportFromAccountTable";
import GenerateInvoiceFromTransactions from "./GenerateInvoiceFromTransactions/GenerateInvoiceFromTransactions";
import PaymentManagementTabNavRouter from "./PurchaseInvoices/PaymentManagement/PaymentManagementTabNavRouter";



//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
	
    /***************** CONST ******************/

    /***************** STATE ******************/
	
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
	
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/purchaseordersandinvoices">
                    <Redirect to="/financials/purchaseordersandinvoices/dashboard" />
                </Route>
				<Route exact path="/financials/purchaseordersandinvoices/invoices">
                    <Redirect to="/financials/purchaseordersandinvoices/invoices/list" />
                </Route>
                <Route exact path="/financials/purchaseordersandinvoices/dashboard">
                    <InvoicesDashboard />
                </Route>
                <Route path="/financials/purchaseordersandinvoices/invoices/list">
                    <InvoicesTabNavRouter />
                </Route>
				<Route exact path="/financials/purchaseordersandinvoices/invoices/generatefromtransactions">
                    <GenerateInvoiceFromTransactions />
                </Route>
				<Route path="/financials/purchaseordersandinvoices/invoices/invoice/:id">
                    <InvoiceRouter/>
                </Route>
				<Route path="/financials/purchaseordersandinvoices/invoices/signpayments">
				<PaymentManagementTabNavRouter/>
                </Route>

                
                <Route exact path="/financials/purchaseordersandinvoices/suppliers/table">
                    <SuppliersTable />
                </Route>
				<Route path="/financials/purchaseordersandinvoices/suppliers/supplier/:id">
                    <SupplierRouter/>
                </Route>
				<Route exact path="/financials/purchaseordersandinvoices/suppliers/import">
                    <SupplierImportFromAccountTable />
                </Route>
                <Route exact path="/financials/purchaseordersandinvoices/categories/table">
                    
                </Route>
				
				
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
