import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";

import PontoTransactionsTable from "./PontoTransactionsTable";
import PontoSignBulks from "./PontoSignBulks";
import CreateBulkTransactions from "./CreateBulkTransactions";
import EditBulkTransactions from "./EditBulkTransactions";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavLink: {
        borderRight: "1px solid rgb(220 220 220)",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNav
            MainSlug={`/financials/purchaseordersandinvoices/invoices/signpayments`}
            DefaultTab={`/financials/purchaseordersandinvoices/invoices/signpayments/pontotransactions`}
            ClassExtendTabNavLink={classes.ClassExtendTabNavLink}
            TabNavMenuHeight="30px"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Sign Single</div>
                        </div>
                    ),
                    Slug: `/financials/purchaseordersandinvoices/invoices/signpayments/pontotransactions`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <PontoTransactionsTable />,
                },
				{
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Sign Bulk</div>
                        </div>
                    ),
                    Slug: `/financials/purchaseordersandinvoices/invoices/signpayments/signBulk`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <PontoSignBulks />,
                },

                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Create Bulk Transaction</div>
                        </div>
                    ),
                    Slug: `/financials/purchaseordersandinvoices/invoices/signpayments/createbulk`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <CreateBulkTransactions />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Edit Bulk Transactions</div>
                        </div>
                    ),
                    Slug: `/financials/purchaseordersandinvoices/invoices/signpayments/editbulk`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <EditBulkTransactions />,
                },

                
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
