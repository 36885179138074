import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import useTableContext from "../utils/context/tableContext/useTableContext";
import CheckBox from "../CheckBox/CheckBox";
import {SortBarsAsc, SortBarsDesc} from "@artibulles-cis/react-icons";
import {v4 as uuidv4} from "uuid";
import TableSearch from "../TableSearch/TableSearch";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    HeaderWrapper: {
        position: "sticky",
        display: "table",
        top: "0px",
        // height: "80px",
        // width: "1200px",

        zIndex: "10",
        width: "100%",
    },
    Grid_Header: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "0 0 auto",
            position: "relative",
            zIndex: "20",
            background: "none",
            backgroundColor: "none",
            boxSizing: "border-box",
            borderTopLeftRadius: props.MainTableStyle.ExternalTableBorderRadius ? props.MainTableStyle.ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            borderTopRightRadius: props.MainTableStyle.ExternalTableBorderRadius ? props.MainTableStyle.ExternalTableBorderRadius : FinalTheme.CommonStyle.ExternalTableBorderRadius,
            // overflowX: "hidden",
            // overflowY:"hidden",
            display: "flex",
            // paddingRight: "15px",
        };
    },

    // Header_Scroll_Wrapper: {
    //     flex: "1 1 auto",
    //     position: "relative",
    //     minWidth: "0",
    //     minHeight: "0",
    // },
    // Header_Scroll_Container: {
    //     position: "relative",
    //     width: "100%",
    //     height: "100%",
    // },
    // Header_Scroll_Content: {
    //     display: "table",
    //     // flexDirection : "column",
    //     minWidth: "100%",
    //     width: "100%",
    //     boxSizing: "border-box",
    // },
    // Header_Rows: {
    //     position: "relative",
    //     boxSizing: "border-box",
    // },

    Header_Row: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            height: "100%",
            boxSizing: "border-box",
            // overflow : "hidden",
            width: "100%",
            display: "flex",
            whiteSpace: "nowrap",
            background: props.MainTableStyle.Header.HeaderRowBackground ? props.MainTableStyle.Header.HeaderRowBackground : FinalTheme.Header.HeaderRowBackground,

            "&:first-child $Header_Cell": {
                borderTop: "none",
            },
            "&:last-child $Header_Cell": {
                borderBottom: props.MainTableStyle.Header.HeaderBottomBorder ? props.MainTableStyle.Header.HeaderBottomBorder : FinalTheme.CommonStyle.HeaderBottomBorder,
            },
        };
    },
    // Header_Row_Search: {},
    Header_Cell: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            whiteSpace: "nowrap",
            padding: "0 8px",
            fontSize: props.MainTableStyle.Header.fontSize ? props.MainTableStyle.Header.fontSize : FinalTheme.Header.fontSize,
            color: props.MainTableStyle.Header.color ? props.MainTableStyle.Header.color : FinalTheme.Header.color,
            fontFamily: props.MainTableStyle.Header.fontFamily ? props.MainTableStyle.Header.fontFamily : FinalTheme.Header.fontFamily,
            fontWeight: props.MainTableStyle.Header.fontWeight ? props.MainTableStyle.Header.fontWeight : FinalTheme.Header.fontWeight,
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
            borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
        };
    },
    Header_Cell_Text: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        whiteSpace: "normal",
        wordWrap: "normal",
    },
    Header_Cell_Sort: {
        position: "absolute",
        top: "50%",
        right: "0px",
        transform: "translate(0,-50%)",
        flex: "0 0 40px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginRight: "2px",
    },
    Header_Cell_Sort_Order: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        paddingLeft: "4px",
        fontFamily: "Roboto",
        fontWeight: "300",
        fontSize: "14px",
    },
}));

/**
 * Component Description
 */

const TableHeader = React.memo(
    function TableHeader(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TableOptions} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});

        var FinalTheme;
        if (TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }

        var SortIconStyle;

        if (TableOptions.HeaderStyle && TableOptions.HeaderStyle.SortIconStyle) {
            let CurrentStyle = TableOptions.HeaderStyle.SortIconStyle;
            SortIconStyle = {
                IconSize: "15px",
                Color: CurrentStyle.Color ? CurrentStyle.Color : FinalTheme.Header.SortIcon.Color,
                ActiveColor: CurrentStyle.ActiveColor ? CurrentStyle.ActiveColor : FinalTheme.Header.SortIcon.ActiveColor,
            };
        } else {
            SortIconStyle = {
                IconSize: "15px",
                Color: FinalTheme.Header.SortIcon.Color,
                ActiveColor: FinalTheme.Header.SortIcon.ActiveColor,
            };
        }
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** STATE *********************/
        const {TableColsInputs, SetSelectedRows, setSortedRowsAndCols, SortedColumsState, GenerateHeader, ActionButtonColWidth} = useTableContext();
        const [HeaderRowsComponent, setHeaderRowsComponent] = useState(null);

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/
        const GenerateHeaderComponent = useCallback(() => {
            
            var HeaderRowComps = [],
                HeaderCellComps = [];

            /************************* SELECT ELEMENTS ******************************/
            if (TableOptions.Selectable === true) {
                let SelectHeaderElem = (
                    <div key={uuidv4()} className={classes.Header_Cell} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}>
                        <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}>
                            <CheckBox IconSize="15px" CirclePadding="5px" name="test" GetValue={HandleSelectAll} />
                        </div>
                    </div>
                );
                HeaderCellComps.push(SelectHeaderElem);
            }
            /************************* SELECT ELEMENTS ******************************/

            /************************* FAKE ACTION BUTTON COL ******************************/
            if (ActionButtonColWidth) {
                let FakeActionColElem = (
                    <div
                        key={uuidv4()}
                        className={classes.Header_Cell}
                        style={{flex: `0 0 ${ActionButtonColWidth}`, width: ActionButtonColWidth, minWidth: ActionButtonColWidth, maxWidth: ActionButtonColWidth}}
                    >
                        <div className={classes.Header_Cell_Text} style={Lockedstyles.Header_Cell_Text}></div>
                    </div>
                );
                HeaderCellComps.push(FakeActionColElem);
            }

            /************************* FAKE ACTION BUTTON COL ******************************/

            for (let i = 0; i < TableColsInputs.length; i++) {
                let ColOptions = TableColsInputs[i];

                let HeaderCell, FlexWidth, MinWidth, MaxWidth, Width;
                if (ColOptions.width) {
                    if (ColOptions.width === "auto") {
                        if (ColOptions.minWidth) {
                            FlexWidth = `1 1 ${ColOptions.minWidth}`;
                            MinWidth = ColOptions.minWidth;
                        } else {
                            FlexWidth = "1 1 auto";
                        }
                    } else {
                        FlexWidth = `0 0 ${ColOptions.width}`;
                        MinWidth = ColOptions.width;
                        MaxWidth = ColOptions.width;
                        Width = ColOptions.width;
                    }
                } else {
                }
                /*Col Width*/

                var caption = ColOptions.caption ? ColOptions.caption : "no caption";
                var WrapCell = ColOptions.wrapColHeader === true ? true : false;

                /************************* MAIN HEADER ROW ******************************/
                if (TableOptions.Sortable === true) {
                    //It the entire table is sortable
                    const SortIconAsc = <SortBarsAsc IconSize="15px" SVGFillColor={SortIconStyle.Color} SVGFillHoverColor={SortIconStyle.Color} />;
                    const SortIconAscActive = <SortBarsAsc IconSize={SortIconStyle.IconSize} SVGFillColor={SortIconStyle.ActiveColor} SVGFillHoverColor={SortIconStyle.ActiveColor} />;
                    const SortIconDescActive = <SortBarsDesc IconSize="15px" SVGFillColor={SortIconStyle.ActiveColor} SVGFillHoverColor={SortIconStyle.ActiveColor} />;

                    let FinalSortIcon, SortIndex;

                    if (ColOptions.isSortable === true) {
                        //SORTABLE ELEMENTS

                        if (ColOptions.isSorted === true) {
                            if (SortedColumsState.TotalSorted <= 1) {
                                SortIndex = null;
                            } else {
                                SortIndex = <div className={classes.Header_Cell_Sort_Order}>{ColOptions.sortIndex}</div>;
                            }

                            //The sort is active
                            if (ColOptions.sortDirection === "Asc") {
                                FinalSortIcon = SortIconAscActive;
                            } else {
                                FinalSortIcon = SortIconDescActive;
                            }
                        } else {
                            //The sort is inactive
                            FinalSortIcon = SortIconAsc;
                        }

                        let SortComponent = (
                            <div className={classes.Header_Cell_Sort} onClick={(e) => HandleSortColumn(ColOptions.colId, e)}>
                                {FinalSortIcon}
                                {SortIndex}
                            </div>
                        );

                        HeaderCell = (
                            <div key={uuidv4()} className={classes.Header_Cell} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Header_Cell_Text} style={{whiteSpace : ColOptions.wrapHeader===true?"normal":"nowrap", marginRight: "10px"}}>
                                    {caption}
                                </div>
                                {SortComponent}
                            </div>
                        );
                    } else {
                        //NO SORTING COL
						//Arnaud
                        HeaderCell = (
                            <div key={uuidv4()} className={classes.Header_Cell} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <div className={classes.Header_Cell_Text} style={{whiteSpace : ColOptions.wrapHeader===true?"normal":"nowrap"}}>
                                    {caption}
                                </div>
                            </div>
                        );
                    }

                    /************* SORTING COMPONENT **********/

                    HeaderCellComps.push(HeaderCell);
                }
                /************************* MAIN HEADER ROW ******************************/
            }
            let HeaderRow = (
                <div key={uuidv4()} className={classes.Header_Row}>
                    {HeaderCellComps}
                </div>
            );

            HeaderRowComps.push(HeaderRow);

            setHeaderRowsComponent(HeaderRowComps);
        }, [SortedColumsState, TableColsInputs, GenerateHeader]);

        //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        useEffect(() => {
            
            //Iniitating the Header with the different options
            if (GenerateHeader === true) {
				
                if (TableColsInputs && Array.isArray(TableColsInputs) && TableColsInputs.length > 0) {
                    GenerateHeaderComponent();
                }
            }
        }, [TableColsInputs, SortedColumsState, GenerateHeaderComponent, GenerateHeader]);

        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        const HandleSelectAll = (value) => {
            if (value === false) {
                SetSelectedRows("UnselectAll");

                // setShowTopToolbar(false);
            } else {
                SetSelectedRows("SelectAll");
                // 	if (TableData.length > 0) {
                // 		let RowSelectedUpdated = [];
                // 		for (var i = 0; i < TableData.length; i++) {
                // 			RowSelectedUpdated.push({Id: TableData[i]["rowData"]._id});
                // 		}
                // 		Ref_RowSelected_Data.current = RowSelectedUpdated;
                // 		setRowSelected(RowSelectedUpdated);
                // 	}
            }
        };

        const HandleSortColumn = (Id, event) => {
            setSortedRowsAndCols(Id, event);
        };
        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        var TableSearchRowComponent = null;
        if (TableOptions.Searchable === true) {
            if (GenerateHeader === true) {
                TableSearchRowComponent = <TableSearch MandatorySyncStyle={props.MandatorySyncStyle} MainTableStyle={props.MainTableStyle} TableOptions={TableOptions} />;
            } else {
                TableSearchRowComponent = null;
            }
        } else {
            TableSearchRowComponent = null;
        }

        return (
            <div className={classes.HeaderWrapper}>
                {HeaderRowsComponent}
                {TableSearchRowComponent}
            </div>
        );
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableHeader.defaultProps = {
    MyProp: null,
};

TableHeader.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableHeader;
// <div ref={Ref_TableHeader} className={classes.Grid_Header}>
//                 <div className={classes.Header_Scroll_Wrapper}>
//                     <div
//                         ref={Ref_HeaderScrollContainer}
//                         className={classes.Header_Scroll_Container}
//                         //We need to calculate the height based on the Data_Scroll_Content element
//                         style={{left: HeaderScrollLeft + "px", width: DataScrollContentWidth}}
//                     >
//                         <div className={classes.Header_Scroll_Content}>
//                             {HeaderRowsComponents}
//                             {HeaderSearchRowsComponents}
//                         </div>
//                     </div>
//                 </div>
//             </div>
