import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {v4 as uuidv4} from "uuid";
import _ from "lodash";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Artibulles_Cis_Gage: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px",
    },
    GageIndicatorText: {
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        // transform: "translate(0,-50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    GageFixed: {
        imageRendering: "crisp-edges",
    },
    GageIndicator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageTicks: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageTicksText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
    },
    GageText: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: "0px",
        top: "0px",
        imageRendering: "crisp-edges",
        fontSize: "18px",
        fontFamily: "roboto",
        fontWeight: "300",
    },
}));

/**
 * Complex Gage display with multiple Indicators
 *
 */

const CircularGage = React.forwardRef(function CircularGage(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        ExternalDiameter,
        Thickness,
        Value,
        GageTriggers,
        SeparateWarningColor,
        WithOuterText,
        WithInnerText,
        GageCentralText,
        EmptyColor,
        GageTickTextOuterfontSize,
        GageTickTextOuterPaddingInner,
        GageTickTextInnerfontSize,
        GageTickTextInnerPaddingOuter,
        GageTickOverflow,
        GageTickColor,
        GageStartAngle,
        MaxValue,
        GageCustomCentralText,

        //DontFold,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** CONST *********************/

    /******** MERGING THE INPUTS TO PREVENT ERRORS *******/

    var GageTriggersInitial = {
        Perc100: {trigger: null, colorBelow: "purple", colorAbove: "green"},
        Warning: {triggerPercentage: null, color: "Orange"},
    };
    var GageTriggersFinal = _.merge(GageTriggersInitial, GageTriggers);
    /******** MERGING THE INPUTS TO PREVENT ERRORS *******/

    const SVGRatioReal_VBox = parseFloat(ExternalDiameter) / 100;
    var SVGDimensions = parseFloat(SVGRatioReal_VBox) * 100;
    var RealThickness = Thickness / SVGRatioReal_VBox;

    var RadiusInternal = (100 - RealThickness) / 2;

    const CircleCirconf = 2 * Math.PI * RadiusInternal;
    var SDashArray;

    /******** GAGE TICKS ELEMENTS *******/
    const GageTickThickness = 1;

    const RealGageTickOverflow = parseFloat(GageTickOverflow) / SVGRatioReal_VBox;
    const GageTicksSVGVBoxDimensions = 100 + 2 * RealGageTickOverflow;
    const GageTicksSVGDimensions = GageTicksSVGVBoxDimensions * SVGRatioReal_VBox;

    const GageTickWidth = RealThickness + 2 * RealGageTickOverflow;
    const GageTickX = GageTicksSVGVBoxDimensions - RealThickness - 2 * RealGageTickOverflow;
    const GageTickY = GageTicksSVGVBoxDimensions / 2 - GageTickThickness / 2;
    /******** GAGE TICKS ELEMENTS *******/

    /******** GAGE TICKS TEXT ELEMENTS *******/
    const GageTickTextOuterfontSizeReal = parseFloat(GageTickTextOuterfontSize) / SVGRatioReal_VBox;
    const GageTickTextOuterPaddingInnerReal = parseFloat(GageTickTextOuterPaddingInner) / SVGRatioReal_VBox;
    const GageTickTextInnerfontSizeReal = parseFloat(GageTickTextInnerfontSize) / SVGRatioReal_VBox;
    const TextWithCoef = 0.75;

    const GageTickTextOuterWidth = (parseFloat(GageTickTextOuterfontSize) * 5 * TextWithCoef) / SVGRatioReal_VBox;

    const GageTickTextSVGBoxDimensions = GageTicksSVGVBoxDimensions + 2 * (GageTickTextOuterWidth + GageTickTextOuterPaddingInnerReal);
    const GageTickTextSVGDimensions = GageTickTextSVGBoxDimensions * SVGRatioReal_VBox;

    const GageTickTextInnerWidth = (parseFloat(GageTickTextInnerfontSize) * 5 * TextWithCoef) / SVGRatioReal_VBox;

    /******** GAGE TICKS TEXT ELEMENTS *******/

    /******** CALCULATING THE VALUE AND COLOR OF THE GAUGE*******/
    var FilledColorFinal, FilledColorWarningValueFinal, SDashArrayWarning;

    if (GageTriggersFinal.PercMax) {
        //2 TRIGGERS GAUGE
        if (Value <= GageTriggersFinal.Perc100.trigger) {
            FilledColorFinal = GageTriggersFinal.Perc100.colorBelow;
            SDashArray = (((CircleCirconf * 50) / parseFloat(GageTriggersFinal.Perc100.trigger)) * Value) / 100;
        } else if (Value > GageTriggersFinal.Perc100.trigger && Value < GageTriggersFinal.PercMax.trigger) {
            FilledColorFinal = GageTriggersFinal.Perc100.colorAbove;
            SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.PercMax.trigger)) * Value) / 100;
        } else if (Value >= GageTriggersFinal.PercMax.trigger) {
            FilledColorFinal = GageTriggersFinal.PercMax.colorAbove;
            SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.PercMax.trigger)) * Value) / 100;
        }
    } else {
        //SIMPLE GAUGE
        if (GageTriggersFinal.Warning.triggerPercentage) {
            //WITH WARNING COLOR
            let WarningValue = (GageTriggersFinal.Perc100.trigger * GageTriggersFinal.Warning.triggerPercentage) / 100;

            if (Value <= WarningValue) {
                FilledColorFinal = GageTriggersFinal.Perc100.colorBelow;
                SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * Value) / 100;
            } else if (Value > WarningValue && Value <= GageTriggersFinal.Perc100.trigger) {
                if (SeparateWarningColor === true) {
                    //ADDING A WARNING RING
                    FilledColorFinal = GageTriggersFinal.Perc100.colorBelow;
                    SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * WarningValue) / 100;
                    FilledColorWarningValueFinal = GageTriggersFinal.Warning.color;
                    SDashArrayWarning = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * WarningValue) / 100;
                } else {
                    //JUST CHANGING THE COLOR
                    FilledColorFinal = GageTriggersFinal.Warning.color;
                    SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * Value) / 100;
                }
            } else {
                FilledColorFinal = GageTriggersFinal.Perc100.colorAbove;
                SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * Value) / 100;
            }
        } else {
            //WITHOUT WARNING COLOR
            if (Value <= GageTriggersFinal.Perc100.trigger) {
                FilledColorFinal = GageTriggersFinal.Perc100.colorBelow;
                SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * Value) / 100;
            } else {
                FilledColorFinal = GageTriggersFinal.Perc100.colorAbove;
                SDashArray = (((CircleCirconf * 75) / parseFloat(GageTriggersFinal.Perc100.trigger)) * Value) / 100;
            }
        }
    }

    var ValPercent = ((Value / parseFloat(MaxValue)) * 100).toFixed(0);

    /******** CALCULATING THE VALUE AND COLOR OF THE GAUGE*******/

    /****************************** CONST *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** STATE *********************/

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var MajorTicksComponents = [];
    var MajorTicksText = [];

    /****************************** TICKS *********************/
    if (GageTriggersFinal.PercMax) {
        let TickElemZero = (
            <rect
                id={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${0} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            ></rect>
        );
        let TickElem100 = (
            <rect
                id={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${180} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            ></rect>
        );
        let TickElemMax = (
            <rect
                id={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${270} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            ></rect>
        );
        MajorTicksComponents.push(TickElemZero);
        MajorTicksComponents.push(TickElem100);
        MajorTicksComponents.push(TickElemMax);
    } else {
        let TickElemZero = (
            <rect
                id={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${0} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            ></rect>
        );

        let TickElemMax = (
            <rect
                id={uuidv4()}
                x={GageTickX}
                y={GageTickY}
                height={GageTickThickness}
                width={GageTickWidth}
                fill={GageTickColor}
                transform={`rotate(${270} ${GageTicksSVGVBoxDimensions / 2} ${GageTicksSVGVBoxDimensions / 2})`}
            ></rect>
        );
        MajorTicksComponents.push(TickElemZero);

        MajorTicksComponents.push(TickElemMax);
    }
    /****************************** TICKS *********************/

    /****************************** TICKS EXTERNAL GAUGE TEXT *********************/
    if (WithOuterText === true) {
        let XTextOuterZero = GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 0);
        let YTextOuterZero = GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 0);
        let TextOuterZero = (
            <text x={XTextOuterZero} y={YTextOuterZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                0 %
            </text>
        );
        MajorTicksText.push(TextOuterZero);
        if (GageTriggersFinal.PercMax) {
            let XTextOuter100 =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 180);
            let YTextOuter100 =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 180);
            var Text;
            if (GageTriggersFinal.Perc100.OuterText === "Percentage") {
                Text = "100%";
            } else if (GageTriggersFinal.Perc100.OuterText === "Value") {
                Text = GageTriggersFinal.Perc100.trigger;
            } else {
                Text = GageTriggersFinal.Perc100.OuterText;
            }

            let TextOuter100 = (
                <text x={XTextOuter100} y={YTextOuter100} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {Text}
                </text>
            );

            if (GageTriggersFinal.PercMax.OuterText === "Percentage") {
                Text = ((parseFloat(GageTriggersFinal.PercMax.trigger) / parseFloat(GageTriggersFinal.Perc100.trigger)) * 100).toFixed(0) + "%";
            } else if (GageTriggersFinal.PercMax.OuterText === "Value") {
                Text = GageTriggersFinal.PercMax.trigger;
            } else {
                Text = GageTriggersFinal.PercMax.OuterText;
            }

            MajorTicksText.push(TextOuter100);
            let XTextOuterMax =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 270);
            let YTextOuterMax =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 270);
            let TextOuterMax = (
                <text x={XTextOuterMax} y={YTextOuterMax} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {Text}
                </text>
            );
            MajorTicksText.push(TextOuterMax);
        } else {
            let XTextOuterMax =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.cos((Math.PI / 180) * 270);
            let YTextOuterMax =
                GageTickTextSVGBoxDimensions / 2 + (GageTicksSVGVBoxDimensions / 2 + GageTickTextOuterWidth / 2 + GageTickTextOuterPaddingInnerReal / 2) * Math.sin((Math.PI / 180) * 270);
            var Text;
            if (GageTriggersFinal.Perc100.OuterText === "Percentage") {
                Text = "100%";
            } else if (GageTriggersFinal.Perc100.OuterText === "Value") {
                Text = GageTriggersFinal.Perc100.trigger;
            } else {
                Text = GageTriggersFinal.Perc100.OuterText;
            }
            let TextOuterMax = (
                <text x={XTextOuterMax} y={YTextOuterMax} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextOuterfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {Text}
                </text>
            );
            MajorTicksText.push(TextOuterMax);
        }
    }
    /****************************** TICKS EXTERNAL GAUGE TEXT *********************/

    /****************************** TICKS INTERNAL GAUGE TEXT *********************/
    if (WithInnerText === true) {
        let XTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 0);
        let YTextInnerZero = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 0);
        let TextInnerZero = (
            <text x={XTextInnerZero} y={YTextInnerZero} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                0
            </text>
        );
        MajorTicksText.push(TextInnerZero);
        if (GageTriggersFinal.PercMax) {
            let XTextInner100 = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 180);
            let YTextInner100 = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 180);

            var Text;
            if (GageTriggersFinal.Perc100.InnerText === "Percentage") {
                Text = "100%";
            } else if (GageTriggersFinal.Perc100.InnerText === "Value") {
                Text = GageTriggersFinal.Perc100.trigger;
            } else {
                Text = GageTriggersFinal.Perc100.InnerText;
            }
            let TextInner100 = (
                <text x={XTextInner100} y={YTextInner100} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {Text}
                </text>
            );

            if (GageTriggersFinal.PercMax.InnerText === "Percentage") {
                Text = ((parseFloat(GageTriggersFinal.PercMax.trigger) / parseFloat(GageTriggersFinal.Perc100.trigger)) * 100).toFixed(0) + "%";
            } else if (GageTriggersFinal.PercMax.InnerText === "Value") {
                Text = GageTriggersFinal.PercMax.trigger;
            } else {
                Text = GageTriggersFinal.PercMax.InnerText;
            }

            MajorTicksText.push(TextInner100);
            let XTextInnerMax = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 270);
            let YTextInnerMax = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 270);
            let TextInnerMax = (
                <text x={XTextInnerMax} y={YTextInnerMax} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {Text}
                </text>
            );
            MajorTicksText.push(TextInnerMax);
        } else {
            //SIMPLE GAUGE 1 TRIGGER MAX
            let XTextInnerMax = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.cos((Math.PI / 180) * 270);
            let YTextInnerMax = GageTickTextSVGBoxDimensions / 2 + (RadiusInternal - GageTickTextInnerWidth / 2 - GageTickTextInnerPaddingOuter * SVGRatioReal_VBox) * Math.sin((Math.PI / 180) * 270);

            var Text;

            if (GageTriggersFinal.Perc100.InnerText === "Percentage") {
                Text = "100%";
            } else if (GageTriggersFinal.Perc100.InnerText === "Value") {
                Text = GageTriggersFinal.Perc100.trigger;
            } else {
                Text = GageTriggersFinal.Perc100.InnerText;
            }

            let TextInnerMax = (
                <text x={XTextInnerMax} y={YTextInnerMax} dominantBaseline="middle" textAnchor="middle" fontSize={`${GageTickTextInnerfontSizeReal}px`} fontFamily="Roboto" fontWeight="300">
                    {Text}
                </text>
            );
            MajorTicksText.push(TextInnerMax);
        }
    }

    /****************************** TICKS INTERNAL GAUGE TEXT *********************/

    /****************************** CENTRAL GAUGE TEXT *********************/
    var GageCentraltextMain, GageCentraltextSub, GageCustomCentralTextComp;
    if (GageCustomCentralText) {
        GageCustomCentralTextComp = GageCustomCentralText;
    } else {
        if (GageCentralText.MainText === "Percent") {
            GageCentraltextMain = ValPercent + "%";
        } else if (GageCentralText.MainText === "Value") {
            GageCentraltextMain = Value;
        } else {
            GageCentraltextMain = GageCentralText.MainText;
        }
        if (GageCentralText.SubText === "Value") {
            GageCentraltextSub = Value;
        } else {
            GageCentraltextSub = GageCentralText.SubText;
        }
    }

    /****************************** CENTRAL GAUGE TEXT *********************/

    var WarningColorGaugeComp;
    if (SeparateWarningColor === true) {
        WarningColorGaugeComp = (
            <div className={classes.GageIndicator}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle
                        shapeRendering="geometricPrecision"
                        cx="50"
                        cy="50"
                        r={`${RadiusInternal}`}
                        strokeWidth={RealThickness}
                        fill="none"
                        stroke={FilledColorFinal}
                        strokeDasharray={`${SDashArray}, ${CircleCirconf}`}
                        // strokeDashoffset={SDashOffset}
                    ></circle>
                </svg>
            </div>
        );
    }

    return (
        <div className={classes.Artibulles_Cis_Gage} ref={ref} style={{position: "relative", width: `${GageTickTextSVGDimensions}px`, height: `${GageTickTextSVGDimensions}px`}}>
            <div className={classes.GageFixed}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle cx="50" cy="50" r={`${RadiusInternal}`} strokeWidth={RealThickness} fill="none" stroke={EmptyColor} shapeRendering="geometricPrecision"></circle>
                </svg>
            </div>
            <div className={classes.GageIndicator}>
                <svg style={{display: "block", imageRendering: "optimizequality"}} width={`${SVGDimensions}`} height={`${SVGDimensions}`} viewBox="0 0 100 100">
                    <circle
                        shapeRendering="geometricPrecision"
                        cx="50"
                        cy="50"
                        r={`${RadiusInternal}`}
                        strokeWidth={RealThickness}
                        fill="none"
                        stroke={FilledColorFinal}
                        strokeDasharray={`${SDashArray}, ${CircleCirconf}`}
                        // strokeDashoffset={SDashOffset}
                        // strokeDasharray={`${(CircleCirconf * 60) / 100}, ${(CircleCirconf * 40) / 100}`}
                        // strokeDashoffset={`${CircleCirconf*(-25)/100}`}
                    ></circle>
                </svg>
            </div>

            <div className={classes.GageTicks}>
                <svg
                    style={{display: "block", imageRendering: "optimizequality"}}
                    width={`${GageTicksSVGDimensions}`}
                    height={`${GageTicksSVGDimensions}`}
                    viewBox={`0 0 ${GageTicksSVGVBoxDimensions} ${GageTicksSVGVBoxDimensions}`}
                >
                    {MajorTicksComponents}
                </svg>
            </div>
            <div className={classes.GageTicksText}>
                <svg
                    style={{display: "block", imageRendering: "optimizequality"}}
                    width={`${GageTickTextSVGDimensions}`}
                    height={`${GageTickTextSVGDimensions}`}
                    viewBox={`0 0 ${GageTickTextSVGBoxDimensions} ${GageTickTextSVGBoxDimensions}`}
                >
                    {MajorTicksText}
                </svg>
            </div>

            <div className={classes.GageIndicatorText}>
                <div className={classes.GageIndicatorText_Main} style={{fontSize: GageCentralText.MainFontSize}}>
                    {GageCentraltextMain}
                </div>
                <div className={classes.GageIndicatorText_Sub} style={{fontSize: GageCentralText.SubFontSize}}>
                    {GageCentraltextSub}
                </div>
                {GageCustomCentralTextComp ? GageCustomCentralTextComp : null}
            </div>
        </div>
    );
    /****************************** RENDER *********************/
});

CircularGage.defaultProps = {
    ExternalDiameter: 100,
    Thickness: 10,
    Value: null,
    GageStartAngle: 90,
    GageCentralText: {
        MainText: null,
        MainFontSize: "18px",
        SubText: null,
        SubFontSize: "16px",
    },

    GageTriggers: {
        Perc100: {trigger: null, colorBelow: "purple", colorAbove: "green"},
        PercMax: {trigger: null, colorAbove: "red"},
        Warning: {triggerPercentage: null, color: "Orange"},
    },
    SeparateWarningColor: false,
    WithOuterText: true,
    WithInnerText: true,
    CentralIndicator: null,
    EmptyColor: "grey",
    GageTickTextOuterfontSize: "15px",
    GageTickTextOuterPaddingInner: 10,
    GageTickTextInnerfontSize: "15px",
    GageTickTextInnerPaddingOuter: 0.5,
    GageTickOverflow: 5,
    GageTickColor: "black",
    MaxValue: 100,
    GageCustomCentralText: null,
};

CircularGage.propTypes = {
    /**
     * ExternalRadius :
     */
    ExternalDiameter: PropTypes.any,
};

export default CircularGage;
