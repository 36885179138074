import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Rnd} from "react-rnd";
import PerfectScrollBarWrapper from "../PerfectScrollBarWrapper/PerfectScrollBarWrapper";
import useDetectNavigateAway from "../utils/useDetectNavigateAway";

const LockedstylesWithProps = (props) => {
    return {
        cardWindowCentered: {
            padding: `${props.CardWindowPaddingVertical} ${props.CardWindowPaddingHorizontal} ${props.CardWindowPaddingVertical} ${props.CardWindowPaddingHorizontal}`,
            zIndex: props.ZIndex ? props.ZIndex : null,
        },
    };
};

const Lockedstyles = {
    cardWindowCentered: {
        position: "fixed",
        display: "flex",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "2000",

        boxSizing: "border-box",
    },
    cardWindowWithParent: {
        position: "fixed",
        display: "flex",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        zIndex: "2000",
    },
    cardCentered: {
        display: "flex",
        position: "relative",
        marginTop: 0,
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        boxSizing: "border-box",
    },
    cardWithParent: {
        flex: "1 1 auto",
        display: "flex",
        position: "relative",
        marginTop: 0,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflow: "hidden",
        boxSizing: "border-box",
    },
};

const styles = createUseStyles((theme) => ({
    CardWindow: theme.CardWindow,
    Card: theme.Card,
}));

/**
 * Create a Modal Window With card and multiple Options.
 * The Card can be centered on the screen :
 *  -It can have a maxWidth and Height properties
 *  -Or it can take up the full space with a WindowMaxWidth prop and PaddingVertical and PaddingHorizontal to insert gaps
 * The Card can also be displayed inside a parent element (simulated position), in that case the parent id needs to be specified.
 * The parent css should at least have : width :"100%", position :"relative"
 *
 */
const Card = React.forwardRef(function Card(props, ref) {
    const {
        AlertUserWhenLeavingPage,
        CardID,
        IsDraggable,
        IsResizable,
        WithPerfectScrollbar,
        ParentRefCompID,
        CardMaxWidth,
		CardHeight,
		//eslint-disable-next-line
		WindowMaxWidth,
		//eslint-disable-next-line
		CardWindowPaddingVertical,
		//eslint-disable-next-line
        CardWindowPaddingHorizontal,
        WindowBackgroundColor,
        CardBackgroundColor,
        CardBackground,
        ZIndex,
        ClassOverrideCardWindow,
        ClassExtendCardWindow,
        ClassOverrideCard,
        ClassExtendCard,
        children,
    } = props;

    //Temes and Class with JSS
    const theme = useTheme();
    const classes = styles({...props, theme});
	const CardRef = React.useRef();
	//State
    const [RnDPosition, setRnDPosition] = React.useState({x: 100, y: 200});
    const [Position, setPosition] = React.useState({});
    // const [CardDimension, setCardDimension] = React.useState({});
    const [Init, setInit] = React.useState(true);

    //Adding leave page hook
    useDetectNavigateAway(AlertUserWhenLeavingPage);

    //Function to calculate the card Element Position for RnD Element
    const CalculateRnDCardPosition = React.useCallback(() => {
        if (CardRef.current) {
            let top = CardRef.current.getBoundingClientRect().top;
            let left = CardRef.current.getBoundingClientRect().left;
            setRnDPosition({x: left, y: top});
            setInit(false);
        }
    }, [CardRef]);

    //Function to calculate the position of the card element when ParentId is specified
    const CalculateCardPositionWithParentID = React.useCallback(() => {
        //Get the Parent Eement From the ID
        if (ParentRefCompID && document.getElementById(ParentRefCompID)) {
            let top = document.getElementById(ParentRefCompID).getBoundingClientRect().top + window.scrollY;
            
            let left = document.getElementById(ParentRefCompID).getBoundingClientRect().left;
            //We then need to calculate the accecptable Height for the component to fit inside the window (it's a card so it shouldn't be overflowing)
            // var CardHeightFinal;
            // if (CardHeight) {
            //     CardHeightFinal = CardHeight;
            // } else {
            //     CardHeightFinal = window.innerHeight - top;
            // }

            let CardWidth = document.getElementById(ParentRefCompID).getBoundingClientRect().width;
            setPosition({x: left, y: top, height: CardHeight, width: CardWidth});
        }
    }, [ParentRefCompID,CardHeight]);

    //Function to calculate the Dimensions of the Card Element when MaxWidth and/or MaxHeight are not specified
    // const CalculateCardDimensions = React.useCallback(() => {
    //     var FinalCardWidth, FinalCardHeight;

    //     if (CardMaxWidth) {
    //         FinalCardWidth = parseInt(CardMaxWidth);
    //     } else {
    //         if (WindowMaxWidth) {
    //             if (window.innerWidth > parseInt(WindowMaxWidth)) {
    //                 FinalCardWidth = parseInt(WindowMaxWidth) - 2 * parseInt(CardWindowPaddingHorizontal);
    //             } else {
    //                 FinalCardWidth = window.innerWidth - 2 * parseInt(CardWindowPaddingHorizontal);
    //             }
    //         } else {
    //             FinalCardWidth = window.innerWidth - 2 * parseInt(CardWindowPaddingHorizontal);
    //         }
    //     }
    //     if (CardHeight) {
    //         FinalCardHeight = parseInt(CardHeight);
    //     } else {
    //         FinalCardHeight = window.innerHeight - 2 * parseInt(CardWindowPaddingVertical);
    //     }
    //     setCardDimension({width: FinalCardWidth + "px", height: FinalCardHeight + "px"});
    // }, []);

    //Calculating Card position when componenent loads
    React.useEffect(() => {
        if (IsDraggable) {
            CalculateRnDCardPosition();
        }
        if (ParentRefCompID) {
            CalculateCardPositionWithParentID();
        }
        // CalculateCardDimensions();
    }, [IsDraggable,ParentRefCompID,CalculateRnDCardPosition,CalculateCardPositionWithParentID]);
	
	React.useEffect(() => {
        CalculateCardPositionWithParentID();
	}, [CalculateCardPositionWithParentID]);
	
    // Card Position - Resize Event Listener
    React.useEffect(() => {
        if (ParentRefCompID) {
            window.addEventListener("resize", CalculateCardPositionWithParentID);
            return function cleanup() {
                window.removeEventListener("resize", CalculateCardPositionWithParentID);
            };
        }
    }, [ParentRefCompID,CalculateCardPositionWithParentID]);

    

    var CardConditionalStyle;
    if (ParentRefCompID) {
        CardConditionalStyle = {
            left: Position.x + "px",
            top: Position.y + "px",
        };
    }
    var LockedCardStyle;
    if (ParentRefCompID) {
        LockedCardStyle = "cardWithParent";
    } else {
        LockedCardStyle = "cardCentered";
    }
    const FinalComponent = () => {
        if (IsDraggable) {
            if (Init) {
                return (
                    <div
                        ref={CardRef}
                        style={{
                            ...Lockedstyles[LockedCardStyle],
                            flex: `0 1 ${CardMaxWidth}`,
                            height: CardHeight ? CardHeight : "100%",
                            background: CardBackground,
                            backgroundColor: CardBackgroundColor,
                            opacity: "0",
                        }}
                        className={ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)}
                    >
                        {children}
                    </div>
                );
            } else {
                if (WithPerfectScrollbar) {
                    return (
                        <Rnd default={RnDPosition} bounds="window" enableResizing={IsResizable}>
                            <PerfectScrollBarWrapper Active={true} ScrollX={false} ScrollY={true} DisableDocumentSroll={true}>
                                <div
                                    ref={CardRef}
                                    style={{
                                        ...Lockedstyles.card,
                                        flex: `0 1 ${CardMaxWidth}`,
                                        height: `${CardHeight ? CardHeight : "auto"}`,
                                        // height: "inherit",
                                        background: CardBackground,
                                        backgroundColor: CardBackgroundColor,
                                    }}
                                    className={ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)}
                                >
                                    {children}
                                </div>
                            </PerfectScrollBarWrapper>
                        </Rnd>
                    );
                } else {
                    return (
                        <Rnd default={RnDPosition} bounds="window" enableResizing={IsResizable}>
                            <div
                                ref={CardRef}
                                style={{
                                    ...Lockedstyles[LockedCardStyle],
                                    ...CardConditionalStyle,
                                    flex: CardMaxWidth ? `0 1 ${CardMaxWidth}` : "1 1 auto",
                                    // height: `${CardHeight ? CardHeight : "auto"}`,
                                    height: CardHeight ? CardHeight : "100%",
                                    background: CardBackground,
                                    backgroundColor: CardBackgroundColor,
                                }}
                                className={ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)}
                            >
                                {children}
                            </div>
                        </Rnd>
                    );
                }
            }
        } else {
            if (WithPerfectScrollbar) {
                return (
                    <div
                        style={{
                            ...Lockedstyles.card,
                            flex: `0 1 ${CardMaxWidth}`,
                            height: CardHeight,
                            background: CardBackground,
                            backgroundColor: CardBackgroundColor,
                        }}
                        className={ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)}
                    >
                        <PerfectScrollBarWrapper Active={true} ScrollX={false} ScrollY={true} DisableDocumentSroll={true}>
                            {children}
                        </PerfectScrollBarWrapper>
                    </div>
                );
            } else {
                return (
                    <div
                        id={CardID}
                        style={{
                            ...Lockedstyles[LockedCardStyle],
                            ...CardConditionalStyle,
                            flex: ParentRefCompID ? `0 0 ${Position.width}px` : CardMaxWidth ? `0 1 ${CardMaxWidth}` : "1 1 auto",
                            height: CardHeight ? CardHeight : "100%",
                            background: CardBackground,
                            backgroundColor: CardBackgroundColor,
                        }}
                        className={!ParentRefCompID ? (ClassOverrideCard ? ClassOverrideCard : clsx(ClassExtendCard, classes.Card)) : null}
                    >
                        {children}
                    </div>
                );
            }
        }
    };
    var LockedCardWindowStyle;
    if (ParentRefCompID) {
        LockedCardWindowStyle = "cardWindowWithParent";
    } else {
        LockedCardWindowStyle = "cardWindowCentered";
    }
    return (
        <div
            ref={ref}
            style={{
                ...Lockedstyles[LockedCardWindowStyle],
                ...LockedstylesWithProps(props).cardWindowCentered,
				background: WindowBackgroundColor,
				zIndex : ZIndex?ZIndex:null
            }}
            className={ClassOverrideCardWindow ? ClassOverrideCardWindow : clsx(ClassExtendCardWindow, classes.CardWindow)}
        >
            <FinalComponent />
        </div>
    );
});

Card.defaultProps = {
    AlertUserWhenLeavingPage: false,
    CardID: null,
    IsDraggable: false,
    IsResizable: false,
    WithPerfectScrollbar: false,
    ParentRefCompID: null,
    CardMaxWidth: null,
    CardHeight: null,
    WindowMaxWidth: "2500px",
    CardWindowPaddingVertical: "60px",
    CardWindowPaddingHorizontal: "60px",
    WindowBackgroundColor: null,
    CardBackgroundColor: null,
    CardBackground: null,
    ZIndex: null,
    ClassOverrideCardWindow: null,
    ClassExtendCardWindow: null,
    ClassOverrideCard: null,
    ClassExtendCard: null,
};

Card.propTypes = {
    /**
     * Add an event listener to warn the user if he leaves the page or close the browser
     * Note : doesn't work with back and next browser button
     */
    AlertUserWhenLeavingPage: PropTypes.bool,
    /**
     * Add an ID to the card Element (to be used later on for other purposes)
     */
    CardID: PropTypes.any,
    /**
     * Specify If the Card Window is Draggable.
     */
    IsDraggable: PropTypes.bool,
    /**
     * Specify If the Card Window is Resizable
     */
    IsResizable: PropTypes.bool,
    /**
     * Specify If a perfectScrollbar should be added or not inside the card window
     */
    WithPerfectScrollbar: PropTypes.bool,
    /**
     * If you want the card window to appear at a certain position, you can specify a parentID (ref to the component)
     * The Card Window will take that exact position.a1
     * This can be useful if you want to prevent the user from clicking anything in your app but still want to show the card as if it was part of the app
     */
    ParentRefCompID: PropTypes.any,
    /**
     * MaxWidth of the Card Window
     */
    CardMaxWidth: PropTypes.any,
    /**
     * Height of the Card Window
     */
    CardHeight: PropTypes.any,
    /**
     * Window Max Width (only used to calculate the width of the card if no MaxWidth is specified)
     */
    WindowMaxWidth: PropTypes.any,
    /**
     * Vertical spacing between the browser window and the card when no Height is specified
     */
    CardWindowPaddingVertical: PropTypes.any,
    /**
     * Horizontal spacing between the browser window and the card when no width is specified
     */
    CardWindowPaddingHorizontal: PropTypes.any,
    /**
     * Color of the window background
     */
    WindowBackgroundColor: PropTypes.any,
    /**
     * Color of the card
     */
    CardBackgroundColor: PropTypes.any,
    /**
     * Background of the card
     */
    CardBackground: PropTypes.any,
    /**
     * ZIndex for the card Window
     */
    ZIndex: PropTypes.any,
    /**
     * Override the class
     */
    ClassOverrideCardWindow: PropTypes.any,
    /**
     * Extend the class
     */
    ClassExtendCardWindow: PropTypes.any,
    /**
     * Override the class
     */
    ClassOverrideCard: PropTypes.any,
    /**
     * Extend the class
     */
    ClassExtendCard: PropTypes.any,
};

export default Card;
