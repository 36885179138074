import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormWithApi from "../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import ProgramEditForm from "./ProgramEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/programs/program/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/programs/program`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("args", args);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            let Id = args.data.data.Company._id;
            if (IdFinal === "new") {
                //We redirect to edit to reset the form and get the right buttons and editing
                History.push(`/crm/customers/customer/${Id}/edit`);
            }

            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        // console.log("data", data);
        //SET THE INITIAL FORM VALUES
        let Program = data.Program ? data.Program : null;

        var Companies;
        if (data.Companies && data.Companies.length > 0) {
            Companies = data.Companies.map((elem) => {
                return {value: elem._id, label: elem.company};
            });
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Program ? Program._id : "new",
                program_name: Program ? Program.program_name : null,
                description: Program ? Program.description : null,
                is_completed: Program ? Program.is_completed : null,
                is_started: Program ? Program.is_started : null,
                is_internal: Program ? Program.is_internal : null,
                is_billable: Program ? Program.is_billable : null,
                date_started_Iso: Program ? Program.date_started_Iso : null,
                expected_due_date_Iso: Program ? Program.expected_due_date_Iso : null,
                ref_company: Program ? Program.ref_company : null,
            },
            SelectOptions: {
                ref_company: Companies,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,

                    Program: {
                        Updatable: {
                            program_name: values.program_name,
                            description: values.description,
                            is_completed: values.is_completed,
                            is_started: values.is_started,
                            is_internal: values.is_internal,
                            is_billable: values.is_billable,
                            date_started_Iso: values.date_started_Iso,
                            expected_due_date_Iso: values.expected_due_date_Iso,
                            ref_company: values.ref_company,
                        },
                    },
                    CompanyAddresses: values.CompanyAddresses,
                };

                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                // DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={ProgramEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: true, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
