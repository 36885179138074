/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch, Redirect} from "react-router-dom";
import SalesDashboard from "./Dashboard/SalesDashboard";
import SalesInvoicesRouter from "./02_SalesInvoices/00SalesInvoicesRouter";

import CustomerRouter from "./03_Customers/CustomerRouter";

import InvoicesTabNavRouter from "./Invoices/InvoicesTabNavRouter";


//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/saleordersandinvoices">
                    <Redirect to="/financials/saleordersandinvoices/dashboard" />
                </Route>
                <Route exact path="/financials/saleordersandinvoices/dashboard">
                    <SalesDashboard />
                </Route>
                <Route path="/financials/saleordersandinvoices/orders">
                    <InvoicesTabNavRouter />
                </Route>
                <Route path="/financials/saleordersandinvoices/invoices">
                    <SalesInvoicesRouter />
                </Route>
                <Route path="/financials/saleordersandinvoices/customers">
                    <CustomerRouter />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
