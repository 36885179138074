/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";

import FieldSelect from "../FieldSelect/FieldSelect";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    InputWrapper: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        flex: "0 1 auto",
        alignSelf: "stretch",
        // width : "100%"
    },
    ToolbarLeft: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
        borderRight: "1px solid grey",
        overflow: "hidden",
        justifyContent: "center",
    },
    ToolbarIconLeft: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        top: "0px",
    },
    ToolbarIconLeft_Hide: {
        top: "-50px",
    },
    ToolbarIconLeftAnimated: {
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 50ms",
        top: "50%",
        transform: "translate(0,-50%)",
    },
    ToolbarIconLeftAnimated_Hide: {
        // display: "none",
        top: "100px",
        transform: "translate(0,0)",
    },
    ToolbarRight: {
        flex: "0 1 auto",
        display: "flex",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
        alignSelf: "stretch",
    },
    ToolBarIconWrapper_Right: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        borderLeft: "1px solid grey",
        alignSelf: "stretch",
    },
    ToolBarIcon: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        padding: "0px 5px",
        cursor: "pointer",
    },
    Select: {
        display: "flex",
        flex: "1 1 auto",
        position: "relative",
        alignItems: "center",
        boxSizing: "border-box",
    },
}));

/**
 * Component Description
 */
const FieldSelectMain = React.memo(function FieldSelectMain(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputSelectedValues,
        OutputError,
        Error,
        SelectOptions,
        Name, //Important for AutoFill
        ShareFocusState,
        ShareHasValue,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Disabled,
        Required,
        MultiSelect,
        AllowCreateOption,
        SortSelectOptions,
        SortLastItemName,
		SortFirstItemName,
		SortField,

        Clearable,
        isSearchable,
        minMenuHeight,
        maxMenuHeight,
        menuPlacement,
        AlternateRowStyle,
        ControlledEditViewMode,
        Variant,
        FinalLabelColor,
        InputFontProp,
        //Dont Fold
        InternalToolbarLeft,
        AnimateToolBarLeft,
        InternalToolbarLeftIcon,
        AnimateToolbarLeftColor,
        IsFocused,
        HasValue,
        Searchable,
        FinalBorderRadius,
        FieldMaxWidth,
        SortIcons,
        CreateOnServerWhenCreate,
        CreateDeleteIfUnselected,
        CreateCheckServerAccept,
        CreateOnServerURLS,
        UpdateCreatableOptions,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    /****************************** CONST *********************/

    /****************************** STATE *********************/

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    //Initiating the sortDirection for the first time

    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var ToolbarComponentLeft, ToolbarComponentRight;
    if (InternalToolbarLeft) {
        if (AnimateToolBarLeft === true) {
            //When using any type of component
            var FinalClassTopIcon, FinalClassBottomIcon;
            if (IsFocused === true || HasValue === true) {
                FinalClassTopIcon = clsx(classes.ToolbarIconLeft, classes.ToolbarIconLeft_Hide);
                FinalClassBottomIcon = classes.ToolbarIconLeftAnimated;
            } else {
                FinalClassTopIcon = classes.ToolbarIconLeft;
                FinalClassBottomIcon = clsx(classes.ToolbarIconLeftAnimated, classes.ToolbarIconLeftAnimated_Hide);
            }

            ToolbarComponentLeft = (
                <div className={classes.ToolbarLeft}>
                    <div className={FinalClassTopIcon}>{InternalToolbarLeft}</div>
                    <div className={FinalClassBottomIcon}>{InternalToolbarLeft}</div>
                </div>
            );
        }
    } else if (InternalToolbarLeftIcon) {
        //When using Icons

        if (AnimateToolBarLeft === true) {
            var FinalClassTopIcon, FinalClassBottomIcon;
            if (IsFocused === true || HasValue === true) {
                FinalClassTopIcon = clsx(classes.ToolbarIconLeft, classes.ToolbarIconLeft_Hide);
                FinalClassBottomIcon = classes.ToolbarIconLeftAnimated;
            } else {
                FinalClassTopIcon = classes.ToolbarIconLeft;
                FinalClassBottomIcon = clsx(classes.ToolbarIconLeftAnimated, classes.ToolbarIconLeftAnimated_Hide);
            }
            if (AnimateToolbarLeftColor === true) {
                //We use the same color as for the label itself
                //We need to clone first the Icon
                let ClonedIcon = React.cloneElement(InternalToolbarLeftIcon, {
                    SVGFillColor: FinalLabelColor,
                    SVGStrokeColor: FinalLabelColor,
                    SVGStrokeHoverColor: FinalLabelColor,
                    SVGFillHoverColor: FinalLabelColor,
                });

                ToolbarComponentLeft = (
                    <div className={classes.ToolbarLeft}>
                        <div className={FinalClassTopIcon} style={{padding: "0px 5px"}}>
                            {ClonedIcon}
                        </div>
                        <div className={FinalClassBottomIcon} style={{padding: "0px 5px"}}>
                            {ClonedIcon}
                        </div>
                    </div>
                );
            } else {
                ToolbarComponentLeft = (
                    <div className={classes.ToolbarLeft}>
                        <div className={FinalClassTopIcon} style={{padding: "0px 5px"}}>
                            {InternalToolbarLeftIcon}
                        </div>
                        <div className={FinalClassBottomIcon} style={{padding: "0px 5px"}}>
                            {InternalToolbarLeftIcon}
                        </div>
                    </div>
                );
            }
        }
    } else {
        ToolbarComponentLeft = null;
    }

    var FormElement;

    FormElement = (
        <div className={classes.InputWrapper}>
            {ToolbarComponentLeft}
            <div className={classes.Select}>
                <FieldSelect
                    HandleUpdateRefInput={HandleUpdateRefInput}
                    Style={Style}
                    InputFontProp={InputFontProp}
                    FieldMaxWidth={FieldMaxWidth}
                    InitialValue={InitialValue}
                    FormValue={FormValue}
                    // OutputValue={OutputValue}
                    OutputSelectedValues={OutputSelectedValues}
                    OutputError={OutputError}
                    ShareFocusState={ShareFocusState}
                    IsFocused={IsFocused}
                    HasValue={HasValue}
                    ShareHasValue={ShareHasValue}
                    Clearable={Clearable}
                    Searchable={Searchable}
                    FinalLabelColor={FinalLabelColor}
                    ReadOnly={ReadOnly}
                    Disabled={Disabled}
                    Name={Name}
                    Error={Error}
                    Required={Required}
                    FinalBorderRadius={FinalBorderRadius}
                    SelectOptions={SelectOptions}
                    ControlledEditViewMode={ControlledEditViewMode}
                    Variant={Variant}
                    MultiSelect={MultiSelect}
                    AllowCreateOption={AllowCreateOption}
                    SortSelectOptions={SortSelectOptions}
                    SortLastItemName={SortLastItemName}
					SortFirstItemName ={SortFirstItemName}
					SortField={SortField}
                    SortIcons={SortIcons}
                    isSearchable={isSearchable}
                    minMenuHeight={minMenuHeight}
                    maxMenuHeight={maxMenuHeight}
                    menuPlacement={menuPlacement}
                    AlternateRowStyle={AlternateRowStyle}
                    CreateOnServerWhenCreate={CreateOnServerWhenCreate}
                    CreateDeleteIfUnselected={CreateDeleteIfUnselected}
                    CreateCheckServerAccept={CreateCheckServerAccept}
                    CreateOnServerURLS={CreateOnServerURLS}
                    UpdateCreatableOptions={UpdateCreatableOptions}
                />
            </div>
            {ToolbarComponentRight}
        </div>
    );

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldSelectMain.defaultProps = {
    InitialValue: null,
    FormValue: null,
    Error: null,
    Variant: "Outlined",
    Name: null,
    ShareFocusState: null,
    ShareHasValue: null,
};

FieldSelectMain.propTypes = {
    /**
     * InitialValue :
     */
    InitialValue: PropTypes.any,
    /**
     * FormValue :
     */
    FormValue: PropTypes.any,
    /**
     * Error :
     */
    Error: PropTypes.any,
    /**
     * Variant :
     */
    Variant: PropTypes.any,
    /**
     * Name :
     */
    Name: PropTypes.any,
    /**
     * ShareFocusState :
     */
    ShareFocusState: PropTypes.any,
    /**
     * ShareHasValue :
     */
    ShareHasValue: PropTypes.any,
};

export default FieldSelectMain;
