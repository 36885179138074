import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../artibulles-cis/utils/APICallExternal";
import FormCompontent from "../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import ExpandableBox from "../../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
import _ from "lodash";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {Id, CustomerId, HandleShareBankAccountInformation} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [isIntialized, setisIntialized] = useState(true);

    const [APICallType, setAPICallType] = useState("InitSelect");
    const [InitialFormValues, setInitialFormValues] = React.useState({
        ref_companyBankAccount: null,
        bank_name: null,
        bic: null,
        account_owner: null,
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({
        ref_companyBankAccount: null,
        bank_name: null,
        bic: null,
        account_owner: null,
    }); //FINAL FORM VALUES

    const [FieldOptions, setFieldOptions] = useState({
        ref_companyBankAccount: [],
    });
    const [FullaccountsData, setFullaccountsData] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                // console.log("ApiRes", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    let data = ApiRes.data.data;

                    var CompanyBankAccount = [];

                    let DefaultAccountInformation;
                    if (Id) {
                        if (data.CompanyBankAccount && data.CompanyBankAccount.length > 0) {
                            CompanyBankAccount = data.CompanyBankAccount.map((elem) => {
                                if (elem._id === Id) {
                                    DefaultAccountInformation = elem;
                                }
                                return {value: elem._id, label: elem.account_number};
                            });
                        }
                    } else {
                        if (data.CompanyBankAccount && data.CompanyBankAccount.length > 0) {
                            CompanyBankAccount = data.CompanyBankAccount.map((elem) => {
                                if (elem.isDefaultAccount === true) {
                                    DefaultAccountInformation = elem;
                                }
                                return {value: elem._id, label: elem.account_number};
                            });
                        }
                    }

                    if (DefaultAccountInformation) {
                        setFormValues({
                            ref_companyBankAccount: DefaultAccountInformation._id,
                            bank_name: DefaultAccountInformation.bank_name,
                            bic: DefaultAccountInformation.bic,
                            account_owner: DefaultAccountInformation.account_owner,
                        });
                        setInitialFormValues({
                            ref_companyBankAccount: DefaultAccountInformation._id,
                            bank_name: DefaultAccountInformation.bank_name,
                            bic: DefaultAccountInformation.bic,
                            account_owner: DefaultAccountInformation.account_owner,
                        });
                        HandleShareBankAccountInformation({
                            ref_companyBankAccount: DefaultAccountInformation._id,
                        });
                    } else {
                        setFormValues({account_number: null, bank_name: null, bic: null, account_owner: null});
                        setInitialFormValues({account_number: null, bank_name: null, bic: null, account_owner: null});
                        HandleShareBankAccountInformation({
                            ref_companyBankAccount: null,
                            account_number: null,
                            bank_name: null,
                            bic: null,
                            account_owner: null,
                        });
                    }

                    //We also select the default account if it exist

                    setFullaccountsData(data.CompanyBankAccount);

                    setFieldOptions({...FieldOptions, ...{ref_companyBankAccount: CompanyBankAccount}});
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        setLoading(true);

        const ApiCall = {url: `${ProdURL}/api/companies/company/companybankaccounts/${CustomerId}`, type: "get"};
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, [CustomerId]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        if (field === "ref_companyBankAccount") {
            //We find the data corrsponding to the account CustomerId
            let DataForAccount = _.find(FullaccountsData, ["_id", SelectedValues]);

            UpdatedFormValues = {...UpdatedFormValues, ...{bank_name: DataForAccount.bank_name, bic: DataForAccount.bic, account_owner: DataForAccount.account_owner}};
            setFormValues(UpdatedFormValues);
            HandleShareBankAccountInformation({
                ref_companyBankAccount: SelectedValues,
            });
        }
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalComponent;
    if (isIntialized === true) {
        FinalComponent = (
            <ExpandableBox
                TopContent={<h3 style={{margin: "0px", textAlign: "center"}}>Bank Account details</h3>}
                DefaultStyle="List"
                // TransitionEffect={{Duration : "600ms"}}
                // MaxWidth="1020px"
                // CustomController={<InfoCircleBlue />}
                // CustomControllerNoRotation={true}
                // BackgroundColorTopBar="grey"
                // ClassExtendsExpandableBox={classes.ExpandableBox}
                // ClassExtends_ExpandableBox_Controller_Wrapper={classes.ClassExtends_ExpandableBox_Controller_Wrapper}
                // ClassExtendsTopContentWrapper={classes.TopContentWrapper}
                // ClassExtendsContentOpenedWrapper={classes.ContentOpenedWrapper}
                // ClassExtendsIconControllerStyle={classes.ClassExtendsIconControllerStyle}
                // MinWidth="1020px"
                // MaxHeightClosed={MainExpandableConfig.MaxHeightClosed}
                MaxHeightOpened="150px"
                OpenOnTopClick={true}
                WithPerfectScrollbar={false}
                WithScrollbar={null}
                FlexMode={true}
            >
                <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                    <FormCompontent
                        Name="ref_companyBankAccount"
                        InitialValue={InitialFormValues.ref_companyBankAccount ? InitialFormValues.ref_companyBankAccount : null}
                        FormValue={FormValues.ref_companyBankAccount ? FormValues.ref_companyBankAccount : null}
                        OutputSelectedValues={(values) => handleSelectChange("ref_companyBankAccount", values)}
                        SelectOptions={FieldOptions.ref_companyBankAccount}
                        Component="Select"
                        Variant="Outlined"
                        Label="Select a bank account by number"
                        NoMessage={false}
                        MultiSelect={false}
                        SortSelectOptions="Asc"
                        // SortIcons={true}
                        AllowCreateOption={false}
                        // CreateDeleteIfUnselected={}
                        // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                        LabelMaxWidth="300px"
                        LabelAlign="Left"
                        Required={false}
                        Clearable={false}
                    />
                </div>

                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="bank_name"
                            InitialValue={InitialFormValues.bank_name ? InitialFormValues.bank_name : null}
                            FormValue={FormValues.bank_name ? FormValues.bank_name : null}
                            OutputValue={(values) => HandleUpdateFormValues("bank_name", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Bank Name"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="bic"
                            InitialValue={InitialFormValues.bic ? InitialFormValues.bic : null}
                            FormValue={FormValues.bic ? FormValues.bic : null}
                            OutputValue={(values) => HandleUpdateFormValues("bic", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="BIC"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "1 1 auto"}}>
                        <FormCompontent
                            Name="account_owner"
                            InitialValue={InitialFormValues.account_owner ? InitialFormValues.account_owner : null}
                            FormValue={FormValues.account_owner ? FormValues.account_owner : null}
                            OutputValue={(values) => HandleUpdateFormValues("account_owner", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Account Owner"
                            NoMessage={false}
                            LabelMaxWidth="300px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
            </ExpandableBox>
        );
    } else {
        FinalComponent = null;
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
