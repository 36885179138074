import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';
//eslint-disable-next-line
const LockedstylesWithProps = props => {
  return {};
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles(theme => ({
  Button: theme.Button,
}));
/**
 * Simple Customizable Button with ripple effect
 */
const Button = React.forwardRef(function Button(props, ref) {
  const theme = useTheme();
  const classes = styles({ ...props, theme });
  const {
    Background,
    Color,
    Height,
    Width,
    FontSize,
    ClassOverrideButton,
    ClassExtendButton,
    style,
    children,
    ...other
  } = props;
  return (
    <button
      ref={ref}
      style={{
        ...style,
        background: Background ? Background : null,
        color: Color ? Color : null,
        height: Height ? Height : null,
        width: Width ? Width : null,
        fontSize: FontSize ? FontSize : null,
      }}
      className={
        ClassOverrideButton ? ClassOverrideButton : clsx(ClassExtendButton, classes.Button)
      }
      {...other}
    >
      {children}
    </button>
  );
});

Button.defaultProps = {
  Background: null,
  Color: null,
  Height: null,
  Width: null,
  FontSize: null,
  ClassOverrideButton: null,
  ClassExtendButton: null,
};

Button.propTypes = {
  /**
   * Background Color
   */
  Background: PropTypes.string,
  /**
   * Text Color
   */
  Color: PropTypes.string,
  /**
   * Height
   */
  Height: PropTypes.string,
  /**
   * Width
   */
  Width: PropTypes.string,
  /**
   * fontSize
   */
  FontSize: PropTypes.string,
  /**
   * Overrides  the class
   */
  ClassOverrideButton: PropTypes.string,
  /**
   * Extend the class
   */
  ClassExtendButton: PropTypes.string,
};

export default Button;
