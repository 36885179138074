import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNavContent from "../../../../../artibulles-cis/TabNavContent/TabNavContent";
import Table from "../../../../../artibulles-cis/TableNew/Table";
import CardModal from "@artibulles-cis/react/CardModal";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
const {isValid, format, parseISO, formatISO, add, endOfMonth, getDate, getYear} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 15px 10px 15px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "100%",
        maxHeight: "700px",
        margin: "0 auto",
        justifyContent: "center",
    },

    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
}));

const ProjectSpendingDetails = React.memo(function ProjectSpendingDetails(props) {
	const {TableData} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const ColomnsInput = [
        {
            width: "120px",
            caption: "Due Date",
            datafield: "date_Iso",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "dd-MMM-yyyy");
                } else {
                    return value;
                }
            },

            fixedCol: false,
            searchable: false,
            sortable: true,
            initialSort: {order: 1, sortDirection: "Asc"},
            datatype: "date",
        },
        {width: "250px", caption: "Name", datafield: "entry_name", fixedCol: false, searchable: false, sortable: true, datatype: "text", wrapCell: true},
        {
            width: "190px",
            caption: "Company",
            datafield: "ref_company",
            lookup: {data: "Companies", searchField: "_id", returnField: "company"},
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapCell: true,
            // style: {fontSize: "20px", justifyContent: "center"},
        },
        {
            width: "200px",
            caption: "Program",
            datafield: "ref_program",
            lookup: {data: "Programs", searchField: "_id", returnField: "program_name"},
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapCell: true,
            // style: {fontSize: "20px", justifyContent: "center"},
        },
        {
            width: "250px",
            caption: "Milestone",
            datafield: "ref_milestone",
            lookup: {data: "Milestones", searchField: "_id", returnField: "milestone_name"},
            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "text",
            wrapCell: true,
            // style: {fontSize: "20px", justifyContent: "center"},
        },
        {
            width: "100px",
            caption: "Start",
            datafield: "start_time_seconds",
            cellFormatFunction: (value) => {
                if (value) {
                    return convertSecondsToTime(value, false);
                }
            },
            fixedCol: false,
            searchable: false,
            sortable: true,

            datatype: "number",
            wrapColHeader: true,
            initialSort: {order: 1, sortDirection: "Asc"},
        },
        {
            width: "100px",
            caption: "End",
            datafield: "end_time_seconds",
            cellFormatFunction: (value) => {
                if (value) {
                    return convertSecondsToTime(value, false);
                }
            },
            fixedCol: false,
            searchable: false,
            sortable: true,

            datatype: "number",
            wrapColHeader: true,
            // initialSort: {order: 1, sortDirection: "dsc"}
        },
        {
            width: "100px",
            caption: "Duration",
            datafield: "duration_seconds",
            cellFormatFunction: (value) => {
                if (value) {
                    return convertSecondsToTime(value, false);
                }
            },
            fixedCol: false,
            searchable: false,
            sortable: true,

            datatype: "number",
            wrapColHeader: true,
            // initialSort: {order: 1, sortDirection: "dsc"}
        },
        {width: "100px", caption: "Personal", datafield: "is_personal", fixedCol: false, searchable: false, sortable: true, datatype: "boolean"},
        {width: "100px", caption: "Billable", datafield: "is_billable", fixedCol: false, searchable: false, sortable: true, datatype: "boolean"},
        {
            width: "100px",
            caption: "Billable amount",
            datafield: "calc_billable_Amount",
            cellFormatFunction: (value) => {
                if (value) {
                    return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 1}).format(value);
                } else {
                    return null;
                }
            },

            fixedCol: false,
            searchable: false,
            sortable: true,
            datatype: "number",
            wrapColHeader: true,
        },
    ];

    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };

    const HandleRenderTotalRow = (args) => {
        let TotalWorkedSeconds = 0;
        let TotalBillableAmount = 0;

        if (args && Array.isArray(args) && args.length > 0) {
            for (let i = 0; i < args.length; i++) {
                let RowValue = args[i].rowData;
                TotalWorkedSeconds = TotalWorkedSeconds + RowValue.duration_seconds;
                TotalBillableAmount = TotalBillableAmount + RowValue.calc_billable_Amount;
            }
            TotalWorkedSeconds = convertSecondsToTime(TotalWorkedSeconds, false);
            TotalBillableAmount = Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 1}).format(TotalBillableAmount);
        }
        return (
            <div style={{display: "flex", color: "white", padding: "10px 15px"}}>
                <span>Total worked hours : </span>
                <span style={{marginLeft: "15px"}}>{TotalWorkedSeconds}</span>
                <span style={{marginLeft: "30px"}}>Total Billable Amount : </span>

                <span style={{marginLeft: "15px"}}>{TotalBillableAmount}</span>
            </div>
        );
    };
    const TotalRow = {
        Show: true,
        Render: HandleRenderTotalRow,
    };

    const HandleEditRow = (arg) => {
        console.log("arg", arg);
        if (arg) {
            let id = arg._id;

            setShowViewCard(true);
            setViewCardId(id);
        }
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 30,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: false,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "Edit",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [isIntialized, setisIntialized] = useState(true);
    const [ViewCardId, setViewCardId] = useState(null);
    const [UpdatedTableData, setUpdatedTableData] = useState(null);
    const [TableComponent, setTableComponent] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState("InitSelect");
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNavContent>
		<div className={classes.TableWrapper}>
            <Table
                LocalData={TableData}
                // URLAbsolute={Ref_URLAbsolute.current}
                MainDataSource="TimeEntries"
                ColomnsInput={ColomnsInput}
                TableDimensions={TableDimensions}
                TableOptions={TableOptions}
                TotalRow={TotalRow}
                DevMode={false}
            />
			</div>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default ProjectSpendingDetails;
