import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormWithApi from "../../../../../../../../artibulles-cis/FormWithApi/FormWithApi";

import {toast} from "react-toastify";

import CustomerEditContactForm from "./CustomerEditContactForm";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {CompanyId, ContactId, CloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const InitialCall = {type: "CustomEdit", ID: ContactId, Data: {CompanyId: CompanyId}};

    const FinalAPIUrlAbsolute = {
        GETONE: `${ProdURL}/api/companies/contact/bycompany/get`,
        DELETE: null,
        PUT: `${ProdURL}/api/companies/contact/bycompany/edit`,
        POST: null,
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            CloseCard();
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Bank Account Data", data);

        //SET THE INITIAL FORM VALUES
        let ContactDetails = data.ContactDetails ? data.ContactDetails : null;
		let Contact;
		if (ContactDetails){
			Contact= ContactDetails.Contact ? ContactDetails.Contact : null;
		}
		

        let GenderOptions = [
            {value: "Male", label: "Male"},
            {value: "Female", label: "Female"},
            {value: "Other", label: "Other"},
        ];
        let TitleOptions = [
            {value: "Mr", label: "Mr"},
            {value: "Ms", label: "Ms"},
            {value: "Ir", label: "Ir"},
            {value: "Ing", label: "Ing"},
            {value: "Prof", label: "Prof"},
            {value: "Dr", label: "Dr"},
            {value: "Other", label: "Other"},
        ];

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: ContactDetails ? ContactDetails._id : "new",
                FirstName: Contact ? Contact.FirstName : null,
                LastName:  Contact ? Contact.LastName : null,
                Gender:  Contact ? Contact.Gender : null,
                Language:  Contact ? Contact.Language : null,
                Websites:  Contact ? Contact.Websites : null,
                Title:  Contact ? Contact.Title : null,
                PictureFilePaths:  Contact ? Contact.PictureFilePaths : null,
                JobTitle: ContactDetails ? ContactDetails.JobTitle : null,
                OfficePhone: ContactDetails ? ContactDetails.OfficePhone : null,
                MobilePhone: ContactDetails ? ContactDetails.MobilePhone : null,
                Email: ContactDetails ? ContactDetails.Email : null,
                IsDefault: ContactDetails ? ContactDetails.IsDefault : null,
                Description: ContactDetails ? ContactDetails.Description : null,
                Department: ContactDetails ? ContactDetails.Department : null,
                Ref_Company: ContactDetails ? ContactDetails.Ref_Company : null,
                Ref_Contact: ContactDetails ? ContactDetails.Ref_Contact : null,
            },
            SelectOptions: {
                Gender: GenderOptions,
                Title: TitleOptions,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Contact: {
                        FirstName: values.FirstName,
                        LastName: values.LastName,
                        Gender: values.Gender,
                        Language: values.Language,
                        Websites: values.Websites,
                        Title: values.Title,
                    },
                    CompanyContact: {
                        Ref_Company: CompanyId,
                        Ref_Contact: values.Ref_Contact,
                        JobTitle: values.JobTitle,
                        OfficePhone: values.OfficePhone,
                        MobilePhone: values.MobilePhone,
                        Email: values.Email,
                        Department: values.Department,
                        IsDefault: values.IsDefault,
                    },
                };
                console.log("ServerData", ServerData);
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div>
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    // ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                // DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                // APIUrlRelative={FinalAPIUrlRelative}
                APIUrlAbsolute={FinalAPIUrlAbsolute}
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={CustomerEditContactForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
