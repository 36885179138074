import React from "react";
import PropTypes from "prop-types";
import {createUseStyles, useTheme} from "react-jss";
import clsx from "clsx";
import {Link, useLocation, useRouteMatch, useHistory} from "react-router-dom";

import ScrollToRef from "../utils/ScrollToRef";
import AccordionVertical from "../AccordionVertical/AccordionVertical";
import useNavSideBarContext from "../utils/context/navSideBarContext/useNavSideBarContext";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {};
//eslint-disable-next-line
const Lockedstyles = {};
const styles = createUseStyles((theme) => ({
    NavSideBarLinkAccordion: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion,
    NavSideBarLinkAccordion_AccordionVerticalWrapper: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_AccordionVerticalWrapper,
    NavSideBarLinkAccordion_ControllerWrapper: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_ControllerWrapper,

    NavSideBarLinkAccordion_ControllerWrapper_Active: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_ControllerWrapper_Active,

    NavSideBarLinkAccordion_CollapsibleContent: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_CollapsibleContent,

    NavSideBarLinkAccordion_NavSideBarLink_Controller: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_NavSideBarLink_Controller,

    NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Inactive: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Inactive,

    NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Active: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Active,

    NavSideBarLinkAccordion_CollapsibleContent_WithTree: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_CollapsibleContent_WithTree,

    NavSideBarLinkAccordion_NavSideBarLink_Controller_WithTree: theme.NavSideBarLinkAccordion.NavSideBarLinkAccordion_NavSideBarLink_Controller_WithTree,

    NavSideBarLink_Controller_ActiveIndicator_Inactive_WithTree: theme.NavSideBarLinkAccordion.NavSideBarLink_Controller_ActiveIndicator_Inactive_WithTree,

    NavSideBarLink_Controller_ActiveIndicator_Active_WithTree: theme.NavSideBarLinkAccordion.NavSideBarLink_Controller_ActiveIndicator_Active_WithTree,

    NavSideBarLinkContent: theme.NavSideBarLink.Content,
}));
/**
 * Create a NavLink Element with Sub Elements in a Collapsible Frame (Accordion)
 * Uses the AccordionVertical Component to create a Collapsible NavLink Group of Elements
 */
const NavSideBarLinkAccordion = React.forwardRef(function NavSideBarLinkAccordion(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        Slug,
        SlugList,
        ActiveLinkIndicator,
        ControllerContent,
        WithTree,
        IconStyle,
        ControllerWithTree,
        ControllerIcon,
        //To be updated later if there is a need to change the controller Icon
        //eslint-disable-next-line
        ExternalController,
        OpenOnClick,
        ScrollToLink,
        ClassOverrideAccordionVerticalWrapper,
        ClassOverrideControllerWrapper,
        ClassOverrideControllerWrapper_Active,
        ClassOverrideCollapsibleContent,
        ClassOverrideNavSideBarLink,
        ClassOverrideNavSideBarLink_Active,
        ClassOverrideActiveLink_Indicator_Inactive,
        ClassOverrideActiveLink_Indicator_Active,
        children,
    } = props;
    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    const {updateAccordionStatusContext, initAccordionContext, AllAccordionsStatus, OpenAccordionWhenRouteMatches, SideBarMinimizedStatus} = useNavSideBarContext();
    const Location = useLocation();
    const match = useRouteMatch(Slug);
    const History = useHistory();

    /****************************** REFS *********************/
    const RefLink = React.useRef(null);
    const Ref_IsOpenAccordion = React.useRef(false);
    /****************************** REFS *********************/
    /****************************** STATE *********************/
    const [Init, setInit] = React.useState(true);
    const [IsActiveLink, setIsActiveLink] = React.useState(false);
    const [IsOpenAccordion, SetIsOpenAccordion] = React.useState(false);

    //Managing the Accordion Status from Outside (usecontext)
    //Find this accordion in the context

    /****************************** STATE *********************/
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    /****************************** EFFECT *********************/

    // When Component mounts --> Initiate the context to create the accordions in the context state
    React.useEffect(() => {
        if (Init === true && Slug) {
            initAccordionContext(Slug, false);
            setInit(false);
        }
    }, [Slug, Init, initAccordionContext]);

    // console.log("SideBarMinimizedStatus", SideBarMinimizedStatus);
	if (SideBarMinimizedStatus === true){

	}else{
		
	}

    //Defining the state open of the accordion -> coming from the context
    React.useEffect(() => {
        if (Slug && Init === false) {
            if (Array.isArray(AllAccordionsStatus) && AllAccordionsStatus.length > 0) {
                let ElemExist = AllAccordionsStatus.filter((elem) => elem.name === Slug);
                if (ElemExist.length > 0) {
                    SetIsOpenAccordion(ElemExist[0].statusOpen);
                    Ref_IsOpenAccordion.current = ElemExist[0].statusOpen;
                }
            }
        }
    }, [AllAccordionsStatus, Slug, Init]);

    //Setting Active Link when a route is matched
    React.useEffect(() => {
        if (Slug) {
            if (match && match.isExact === true) {
                setIsActiveLink(true);
            } else {
                setIsActiveLink(false);
                // If the current route macthes one of the sluglist element
                if (SlugList) {
                    let CurrentPath = Location.pathname;
                    var Active = false;
                    SlugList.forEach((Slug) => {
                        if (CurrentPath.includes(Slug)) {
                            Active = true;
                        }
                    });
                    if (Active === true) {
                        setIsActiveLink(true);
                    } else {
                        setIsActiveLink(false);
                    }
                }
            }
        }
    }, [Slug, SlugList, Location, match]);

    //Scrolling to the element if route matches and route were activated without cliking an element inside the sidebar
    React.useEffect(() => {
        if (Slug) {
            if (ScrollToLink === true && RefLink && RefLink.current) {
                if (match && match.isExact === true) {
                    if (Location && Location.state && Location.state.ClickedLink === true) {
                    } else {
                        // ScrollToRef(RefLink);
                        //restoring the Location click to prevent further scroll on other actions
                        // History.push({ path: Location.pathname, state: { ClickedLink: true } });
                    }
                }
            }
        }
    }, [Slug, match, Location, ScrollToLink, History]);

    //Opening the Accordion if route matches and route were activated without cliking an element inside the sidebar
    React.useEffect(() => {
        //   console.log("Effect to Open the Accordion",Slug)
        if (Slug && ScrollToLink === true) {
            if (SlugList) {
                let CurrentPath = Location.pathname;
                var Active = false;
                //Checking if a sublink is active
                SlugList.forEach((Slug) => {
                    if (CurrentPath.includes(Slug)) {
                        Active = true;
                    }
                });
                if (Active === true) {
                    var OpenAccordion = false;
                    if (Slug && Init === false) {
                        if (Array.isArray(AllAccordionsStatus) && AllAccordionsStatus.length > 0) {
                            let ElemExist = AllAccordionsStatus.filter((elem) => elem.name === Slug);
                            if (ElemExist.length > 0) {
                                OpenAccordion = ElemExist[0].statusOpen;
                            }
                        }
                    }

                    if (IsOpenAccordion === false) {
                        OpenAccordionWhenRouteMatches(Slug);
                    }

                    //Check if the Accordion is already opened

                    //   if (Location && Location.state && Location.state.ClickedLink === true) {
                    // 	//Don't open if it if any side bar button was clicked

                    //   } else {
                    //     //Only open it if is closed
                    // 	console.log('Open me please')
                    //     OpenAccordionWhenRouteMatches(Slug);
                    //     //restoring the Location click to prevent further opening or strange behaviors on other actions

                    // History.push({ path: Location.pathname, state: { ClickedLink: true } });

                    //   }
                }
            }
        }
    }, [Slug, SlugList, ScrollToLink, Location, History]);
    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/
    const HandleClickAccordionController = (status) => {
        updateAccordionStatusContext(Slug, status);
    };
    const HandleOnClick = () => {
        if (OpenOnClick === true) {
            updateAccordionStatusContext(Slug, !IsOpenAccordion);
        }
    };

    /****************************** FUNCTIONS *********************/
    /****************************** RENDER *********************/
    var FinalClassOverrideAccordionVerticalWrapper;
    if (ClassOverrideAccordionVerticalWrapper) {
        FinalClassOverrideAccordionVerticalWrapper = ClassOverrideAccordionVerticalWrapper;
    } else {
        FinalClassOverrideAccordionVerticalWrapper = classes.NavSideBarLinkAccordion_AccordionVerticalWrapper;
    }
    var FinalClassOverrideControllerWrapper;
    if (ClassOverrideControllerWrapper) {
        FinalClassOverrideControllerWrapper = ClassOverrideControllerWrapper;
    } else {
        FinalClassOverrideControllerWrapper = classes.NavSideBarLinkAccordion_ControllerWrapper;
    }

    var FinalClassOverrideControllerWrapper_Active;
    if (ClassOverrideControllerWrapper_Active) {
        FinalClassOverrideControllerWrapper_Active = ClassOverrideControllerWrapper_Active;
    } else {
        FinalClassOverrideControllerWrapper_Active = classes.NavSideBarLinkAccordion_ControllerWrapper_Active;
    }

    var FinalClassOverrideCollapsibleContent;
    if (ClassOverrideCollapsibleContent) {
        FinalClassOverrideCollapsibleContent = ClassOverrideCollapsibleContent;
    } else {
        if (WithTree) {
            FinalClassOverrideCollapsibleContent = classes.NavSideBarLinkAccordion_CollapsibleContent_WithTree;
        } else {
            FinalClassOverrideCollapsibleContent = classes.NavSideBarLinkAccordion_CollapsibleContent;
        }
    }

    var ActiveIndicator;
    if (ActiveLinkIndicator) {
        ActiveIndicator = (
            <div
                className={
                    IsActiveLink
                        ? ClassOverrideActiveLink_Indicator_Active
                            ? ClassOverrideActiveLink_Indicator_Active
                            : ControllerWithTree
                            ? classes.NavSideBarLink_Controller_ActiveIndicator_Active_WithTree
                            : classes.NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Active
                        : ClassOverrideActiveLink_Indicator_Inactive
                        ? ClassOverrideActiveLink_Indicator_Inactive
                        : ControllerWithTree
                        ? classes.NavSideBarLink_Controller_ActiveIndicator_Inactive_WithTree
                        : classes.NavSideBarLinkAccordion_NavSideBarLink_Controller_ActiveIndicator_Inactive
                }
            ></div>
        );
    } else {
        ActiveIndicator = null;
    }

    var FinalLinkClass;
    if (ClassOverrideNavSideBarLink) {
        FinalLinkClass = ClassOverrideNavSideBarLink;
    } else {
        if (ControllerWithTree) {
            FinalLinkClass = classes.NavSideBarLinkAccordion_NavSideBarLink_Controller_WithTree;
        } else {
            FinalLinkClass = clsx(classes.NavSideBarLinkAccordion_NavSideBarLink_Controller);
        }
    }

    if (IsActiveLink) {
        if (ClassOverrideNavSideBarLink_Active) {
            FinalLinkClass = clsx(FinalLinkClass, ClassOverrideNavSideBarLink_Active);
        } else {
            FinalLinkClass = clsx(FinalLinkClass, classes.NavSideBarLinkActive);
        }
    }

    let NavCollapsibleController = () => {
        if (Slug) {
            return (
                <Link
                    ref={RefLink}
                    to={{
                        pathname: `${Slug}`,
                        state: {ClickedLink: false},
                    }}
                    className={FinalLinkClass}
                    onClick={HandleOnClick}
                >
                    {ActiveIndicator}
                    <div className={classes.NavSideBarLinkContent}>{ControllerContent}</div>
                </Link>
            );
        } else {
            return (
                <div ref={ref} className={FinalLinkClass}>
                    {ActiveIndicator}
                    <div className={classes.NavSideBarLinkContent}>{ControllerContent}</div>
                </div>
            );
        }
    };

    // var FinalExternalController;

    // if (ExternalController) {
    //   FinalExternalController = () => {
    // 	if (Slug) {
    // 	  return (
    // 		<NavLink
    // 		  ref={ref}
    // 		  to={`${Slug}`}
    // 		  isActive={HandleActive}
    // 		  className={
    // 			ClassOverrideNavSideBarLink
    // 			  ? ClassOverrideNavSideBarLink
    // 			  : ControllerWithTree
    // 			  ? classes.NavSideBarLinkAccordion_NavSideBarLink_Controller_WithTree
    // 			  : clsx(classes.NavSideBarLinkAccordion_NavSideBarLink_Controller)
    // 		  }
    // 		  activeClassName={
    // 			ClassOverrideNavSideBarLink_Active
    // 			  ? ClassOverrideNavSideBarLink_Active
    // 			  : clsx(classes.NavSideBarLinkActive)
    // 		  }
    // 		>
    // 		  {ActiveIndicator}
    // 		  <div className={classes.NavSideBarLinkContent}>{ExternalController}</div>
    // 		</NavLink>
    // 	  );
    // 	} else {
    // 	  return (
    // 		<div
    // 		  ref={ref}
    // 		  className={
    // 			ClassOverrideNavSideBarLink
    // 			  ? ClassOverrideNavSideBarLink
    // 			  : ControllerWithTree
    // 			  ? classes.NavSideBarLinkAccordion_NavSideBarLink_Controller_WithTree
    // 			  : clsx(classes.NavSideBarLinkAccordion_NavSideBarLink_Controller)
    // 		  }
    // 		  activeClassName={
    // 			ClassOverrideNavSideBarLink_Active
    // 			  ? ClassOverrideNavSideBarLink_Active
    // 			  : clsx(classes.NavSideBarLinkActive)
    // 		  }
    // 		>
    // 		  {ActiveIndicator}
    // 		  <div className={classes.NavSideBarLinkContent}>{ExternalController}</div>
    // 		</div>
    // 	  );
    // 	}
    //   };
    // } else {
    //   FinalExternalController = null;
    // }

    return (
        <div ref={ref} className={classes.NavSideBarLinkAccordion}>
            <AccordionVertical
                IconStyle={IconStyle}
                IsNavlInk={Slug ? true : false}
                ControllerIcon={ControllerIcon}
                ControllerContent={NavCollapsibleController()}
                IsOpenAccordionFromOutside={Ref_IsOpenAccordion.current ? Ref_IsOpenAccordion.current : false}
                ShareIsOpenAccordionStatus={HandleClickAccordionController}
                ClassOverrideAccordionVerticalWrapper={FinalClassOverrideAccordionVerticalWrapper}
                ClassOverrideControllerWrapper={IsActiveLink ? FinalClassOverrideControllerWrapper_Active : FinalClassOverrideControllerWrapper}
                ClassOverrideCollapsibleContent={FinalClassOverrideCollapsibleContent}
            >
                {children}
            </AccordionVertical>
        </div>
    );
    /****************************** RENDER *********************/
});

NavSideBarLinkAccordion.defaultProps = {
    Slug: null,
    SlugList: null,
    ActiveLinkIndicator: false,
    ControllerContent: null,
    WithTree: false,
    IconStyle: null,
    ControllerWithTree: false,
    ControllerIcon: null,
    ScrollToLink: true,
    ExternalController: null,
    OpenOnClick: true,
    ClassOverrideAccordionVerticalWrapper: null,
    ClassOverrideControllerWrapper: null,
    ClassOverrideControllerWrapper_Active: null,
    ClassOverrideCollapsibleContent: null,
    ClassOverrideNavSideBarLink: null,
    ClassOverrideNavSideBarLink_Active: null,
    ClassOverrideActiveLink_Indicator_Inactive: null,
    ClassOverrideActiveLink_Indicator_Active: null,
};

NavSideBarLinkAccordion.propTypes = {
    /**
     * Slug for the NavLink destination
     */
    Slug: PropTypes.string,
    /**
     * An array containing all the slugs that should render the Controller Active
     */
    SlugList: PropTypes.array,
    /**
     * Specify if an indicator box should be placed on the left Side of the element when active
     */
    ActiveLinkIndicator: PropTypes.bool,
    /**
     * The Content of the NavLink Controller
     */
    ControllerContent: PropTypes.node,
    /**
     * Change the SimpleArrowLeftDefault Controller Style
     */
    IconStyle: PropTypes.object,
    /**
     * Specify If a tree View should be added
     */
    WithTree: PropTypes.bool,
    /**
     * Specify if a tree horizontal bar should be added before the controller (only to be used if it is a sub-accordion)
     */
    ControllerWithTree: PropTypes.bool,
    /**
     * Change the default Icon - The default Icon Controller is a SimpleArrowLeft
     * Pass an Icon Element that will inherit the click function and rotation properties
     */
    ControllerIcon: PropTypes.node,
    /**
     * If true, when the route matches from the oustide it will scroll AND OPEN the element (useful for search or other programmatic navigation)
     */
    ScrollToLink: PropTypes.bool,
    /**
     * Fully Externalize the Simple Controller to use your own Controller - No Icon, no Content on top
     * You need to handle the function to set the accordion Opened or closed using IsOpenAccordionFromOutside
     */
    ExternalController: PropTypes.node,
    /**
     * Open the accordion onClick in addtion to using the arrow controller
     */
    OpenOnClick: PropTypes.bool,
    /**
     * Controls the accordion status from outisde this component.
     * True or False
     */
    ClassOverrideAccordionVerticalWrapper: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideControllerWrapper
     */
    ClassOverrideControllerWrapper: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideControllerWrapper_Active
     */
    ClassOverrideControllerWrapper_Active: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideCollapsibleContent
     */
    ClassOverrideCollapsibleContent: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideNavSideBarLink
     */
    ClassOverrideNavSideBarLink: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideNavSideBarLink_Active
     */
    ClassOverrideNavSideBarLink_Active: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideActiveLink_Indicator_Inactive
     */
    ClassOverrideActiveLink_Indicator_Inactive: PropTypes.string,
    /**
     * Overrides the Default style of the the ClassOverrideActiveLink_Indicator_Active
     */
    ClassOverrideActiveLink_Indicator_Active: PropTypes.string,
};

export default NavSideBarLinkAccordion;
