import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useParams} from "react-router-dom";

import TabNav from "../../../../../artibulles-cis/TabNav/TabNav";
import APICallExternal from "../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "../../../../../artibulles-cis/LocalCircularLoader/LocalCircularLoader";

import ProgramEditDetails from "./ProgramEdit/ProgramEditDetails";
import MilestonesPerProgramTable from "./Milestones/MilestonesPerProgramTable";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerRouter = React.memo(function CustomerRouter(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const LocationParams = useParams();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Id, SetId] = useState(LocationParams.id);
    const [ProgramDetails, setProgramDetails] = useState(null);

    const [IsInitialized, setIsInitialized] = useState(false);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let ProgramData = ApiRes.data.data.Program;

                    console.log("Program Data", ProgramData);

                    setIsInitialized(true);
                    setProgramDetails(ProgramData);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        if (LocationParams.id) {
            SetId(LocationParams.id);
        }
    }, [LocationParams, ProdURL]);

    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/programs/program/${LocationParams.id}`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FinalComponent;
    if (IsInitialized === true) {
        FinalComponent = (
            <TabNav
                MainSlug={`/crm/customers/customer/:id`}
                DefaultTab={`/crm/customers/customer/${Id}/view`}
                ActiveIndicator={{
                    WithIndicator: true,
                    Position: "Bottom",
                }}
                TabMenuContent={[
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Overview</div>
                            </div>
                        ),
                        Slug: `/programs/program/${Id}/view`,
                        TabContent: <ProgramEditDetails />,
                    },
                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle}>Milestones</div>
                            </div>
                        ),
                        Slug: `/programs/program/${Id}/milestones`,
                        TabContent: <MilestonesPerProgramTable Id={Id} ProgramDetails={ProgramDetails}/>,
                        // TabContent: <div>Milestones</div>,
                    },
                    // {
                    //     TabMenu: (
                    //         <div className={classes.TabMenuDiv}>
                    //             <div className={classes.TabTitle}>Projects</div>
                    //         </div>
                    //     ),
                    //     Slug: `/programs/program/${Id}/projects`,
                    //     // TabContent: <CustomerProjects Id={Id} />,
                    //     TabContent: <div>Edit</div>,
                    // },
                    // {
                    //     TabMenu: (
                    //         <div className={classes.TabMenuDiv}>
                    //             <div className={classes.TabTitle}>Address Book</div>
                    //         </div>
                    //     ),
                    //     Slug: `/programs/program/${Id}/contacts`,
                    //     // TabContent: <CustomerContacts Id={Id} />,
                    //     TabContent: <div>Edit</div>,
                    // },

                    {
                        TabMenu: (
                            <div className={classes.TabMenuDiv}>
                                <div className={classes.TabTitle_WithIcon_Title}>Edit</div>
                            </div>
                        ),
                        Slug: `/programs/program/${Id}/edit`,
                        ShowOnlyIfActive: true,
                        TabContent: <ProgramEditDetails Id={Id} />,
                    },
                ]}
            />
        );
    } else {
        FinalComponent = null;
    }

    return (
        <React.Fragment>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {FinalComponent}
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default CustomerRouter;
