import {combineReducers} from "redux";

import authReducer from "../auth/authReducer";
import timeTrackingEntryReducer from "../reduxFunctions/timeTrackingEntry/timeTrackingEntryReducer";

const appReducer = combineReducers({
    auth: authReducer,
	timeEntryTracking : timeTrackingEntryReducer
    // ActivePage: pageTransitionReducer,
    // globalLoader : globalLoaderReducer,
    // SidebarMessages : sidebarMessagesReducer,
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
