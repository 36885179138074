/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {Route, Switch, Redirect} from "react-router-dom";

import ProgramsDashboard from "./Dashboard/ProgramsDashboard";
import ProgramsTable from "./ProgramsTable";

import ProgramRouter from "./Program/ProgramRouter";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/programs">
                    <Redirect to="/programs/table" />
                </Route>
                <Route exact path="/programs/dashboard">
                    <ProgramsDashboard />
                </Route>
                <Route exact path="/programs/table">
                    <ProgramsTable />
                </Route>
                <Route path="/programs/program/:id">
                    <ProgramRouter />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
