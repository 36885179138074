import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {parseISO, add, sub, formatISO} from "date-fns";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        // width: "500px",
        alignSelf: "center",
        justifyContent: "center",
    },
    ButtonBar: {
        display: "flex",
        padding: "5px 5px 5px 0px",
        // height: "30px",
    },
    ButtonBar_Left: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    ButtonBar_Right: {
        flex: "1 1 auto",
        maxWidth: "220px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    ClassExtendButton: {
        padding: "0px",
        margin: "0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        // padding: "5px 5px",
        display: "flex",
        width: "100%",
        flex: "1 1 auto",
        height: "500px",
        padding: "2px 5px 0px 0px",
        // height: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        // height: "350px",
        // minHeight: "100%",
        // height : "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
    FormWrapper: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        // height: "100%",
        color: "black",
        width: "650px",
        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
	PaymentStatus: {
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_BadgeWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
    PaymentStatus_Badge: {
        flex: "1 1 auto",
        padding: "0px 10px",
        height: "16px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        fontSize: "0.7rem",
        boxSizing: "border-box",
    },

    PaymentStatus_WarningWrapper: {
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
    },
}));

const Component = React.memo(function Component(props) {
    const {PassedFormValues, Ref_CreditCardIdrdOptions, ManualTempTotalAmount, StatementDateIso, StatementId, HandleUpdateReconciledTransaction} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 0px",
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };
    const StatusRendering = (params) => {
        let RowData = params.data;

        let Status = RowData.Status;

        if (Status === "Draft") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "grey"}}>
                            Draft
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Posted") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "rgb(66, 165, 245)"}}>
                            Posted
                        </div>
                    </div>
                </div>
            );
        } else if (Status === "Reconciled") {
            return (
                <div className={classes.PaymentStatus}>
                    <div className={classes.PaymentStatus_BadgeWrapper}>
                        <div className={classes.PaymentStatus_Badge} style={{background: "green"}}>
                            Reconciled
                        </div>
                    </div>
                </div>
            );
        } else {
            return <React.Fragment />;
        }
    };
    const TableColumns = [
        {
            field: "calc_RefTransaction",
            headerName: "Reference",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: false,
            // sort: "asc",
            // sortIndex: 2,
            // sortingOrder: ["asc", "desc"],
            // rowGroup: true,
            // showRowGroup : true,
            // hide: true,
            checkboxSelection: true,
        },
        {
            field: "transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 120,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            sortIndex: 1,
            sort: "desc",
            Type: "Date",
        },

        {
            field: "banking_amount_transaction",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            aggFunc: "sum",
        },
        {
            field: "Status",
            //
            headerName: "Status",
            headerClass: "ag-CenterHeader",
            width: 120,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: StatusRendering,
            cellStyle: PaymentCell,
        },

        {
            field: "communication",
            headerName: "communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            // filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // wrapText: true,
            // autoHeight: true,
        },
    ];

    const HandleRowSelectChange = (SelectedRows) => {
        console.log("SelectedRows", SelectedRows);
        if (SelectedRows.length === 0) {
            setSelectedRows(null);
        } else {
            setSelectedRows(SelectedRows);
        }
    };
    const TableOptions = {
        Selection: {
            Multiple: false,
            GroupOnly: true,
            // WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },
        // Grouping: {
        //     Type: "GroupRows",
        //     HeaderName: "Statement",
        //     MinWidth: 250,
        // },
        SideBar: "filters",
        Resizable: true,
        Sortable: true,
        Filterable: true,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Init, setInit] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [SelectedRows, setSelectedRows] = useState(null);
    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    if (!APICallType) {
                        console.log("Transactions", Data);

                        if (Init === false) {
                            // console.log("not init");

                            if (Data.Transactions && Array.isArray(Data.Transactions) && Data.Transactions.length > 0) {
                                Ref_LocaleData.current = Data;
                                setInit(true);
                            }
                        }
                    } else {
                        HandleUpdateReconciledTransaction("Reconciled Statement");
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = (
            <AGGridTable
                TableName="Transactions"
                Data={Ref_LocaleData.current}
                // URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                MainDataSource="Transactions"
                // RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
            />
        );

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
        }, 200);
    }, [Ref_LocaleData.current, Init]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        setInitialFormValues({
            calc_RefBankStatement: PassedFormValues.calc_RefBankStatement,
            Ref_CreditCardId: PassedFormValues.Ref_CreditCardId,
            Calc_TotalAmount: PassedFormValues.Calc_TotalAmount,
            StatementDateIso: PassedFormValues.StatementDateIso,
        });
        setFormValues({
            calc_RefBankStatement: PassedFormValues.calc_RefBankStatement,
            Ref_CreditCardId: PassedFormValues.Ref_CreditCardId,
            Calc_TotalAmount: PassedFormValues.Calc_TotalAmount,
            StatementDateIso: PassedFormValues.StatementDateIso,
        });
        setFieldOptions({Ref_CreditCardId: Ref_CreditCardIdrdOptions});
        setFormInitReady(true);
    }, []);

    useEffect(() => {
        console.log("Selection Table", StatementDateIso);

        if (Init === false) {
            let StatementDate;
            if (StatementDateIso) {
                StatementDate = parseISO(StatementDateIso);
            }
            let StartDate, EndDate;

            if (StatementDate) {
                StartDate = sub(StatementDate, {months: 2});
                StartDate = formatISO(StartDate);
                EndDate = add(StatementDate, {months: 1});
                EndDate = formatISO(EndDate);
            }

            const ApiCall = {url: `${ProdURL}/api/companyaccount/transactions/?amount=${PassedFormValues.Calc_TotalAmount.toFixed(2)}&startdate=${StartDate}&enddate=${EndDate}`, type: "get"};
            console.log("ApiCall", ApiCall);
            // const ApiCall = {url: `${ProdURL}/api/companyaccount/transactions/`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        HandleUpdateReconciledTransaction();
    };
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        setFormValues(UpdatedFormValues);
    };

    const HandleReconcileTransaction = () => {
        console.log("SelectedRows", SelectedRows);
        let DataForm = {
            CreditCardStatementId: StatementId,
            BankTransactionId: SelectedRows[0]._id,
        };

        const ApiCall = {url: `${ProdURL}/api/companyaccount/reconcilecreditcardwithbankaccount`, type: "post", data: DataForm};
        setLoading(true);
        setAPICallType("Reconcile");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let FormComp;
    if (FormInitReady === true) {
        FormComp = (
            <div className={classes.FormWrapper}>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 600px", marginLeft: "20px"}}>
                        <FormCompontent
                            Name="Ref_CreditCardId"
                            InitialValue={InitialFormValues.Ref_CreditCardId ? InitialFormValues.Ref_CreditCardId : null}
                            FormValue={FormValues.Ref_CreditCardId ? FormValues.Ref_CreditCardId : null}
                            OutputSelectedValues={(values) => handleSelectChange("Ref_CreditCardId", values)}
                            SelectOptions={FieldOptions.Ref_CreditCardId}
                            Component="Select"
                            Variant="Outlined"
                            Label="Credit Card"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="120px"
                            LabelAlign="Left"
                            Clearable={false}
                            // Required={true}
                            // Disabled={true}
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 200px"}}>
                        <FormCompontent
                            Name="StatementDateIso"
                            InitialValue={InitialFormValues.StatementDateIso ? InitialFormValues.StatementDateIso : null}
                            FormValue={FormValues.StatementDateIso ? FormValues.StatementDateIso : null}
                            OutputValue={(values) => HandleUpdateFormValues("StatementDateIso", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Statement Date"
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="130px"
                            FieldMaxWidth="100px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>

                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 420px"}}>
                        <FormCompontent
                            Name="Calc_TotalAmount"
                            InitialValue={InitialFormValues.Calc_TotalAmount ? InitialFormValues.Calc_TotalAmount : null}
                            FormValue={FormValues.Calc_TotalAmount ? FormValues.Calc_TotalAmount : null}
                            OutputValue={(values) => HandleUpdateFormValues("Calc_TotalAmount", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total Amount"
                            NoMessage={false}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            // Imask={true}
                            // Mask={{
                            //     Type: "Currency",
                            //     Options: {
                            //         Currency: "EUR",
                            //         // SymbolPosition: "Post",
                            //         Scale: 2,
                            //     },
                            // }}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            FieldMaxWidth="100px"
                        />
                    </div>
                </div>
            </div>
        );
    }
    let ActionBarComponent;
    if (SelectedRows) {
        ActionBarComponent = (
            <div className={classes.ButtonBar}>
                <div className={classes.ButtonBar_Left}>
                    <Button Height="28px" Width="180px" ClassExtendButton={classes.ClassExtendButton} onClick={HandleReconcileTransaction}>
                        Reconcile Transaction
                    </Button>
                </div>
            </div>
        );
    }
    return (
        <CardModal
            CardID="ReconcileStatement"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={<div>Reconcile Statement with Bank transaction</div>}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={HandleCloseCard}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            CardMaxWidth="1500px"
            // CardHeight="700px"
            CardWindowPaddingVertical="10px"
            // CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="50px"

            ShowCard={true}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
			ZIndex="210000"
        >
            <div className={classes.CardWindow}>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                {FormComp}
                <div className={classes.CardMain}>
                    Bank Transactions
                    {ActionBarComponent}
                    {TableComponent}
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default Component;
