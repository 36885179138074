import React, {useEffect, useState} from 'react'
import {createUseStyles, useTheme} from 'react-jss'
import {Route, Switch} from "react-router-dom";

import CompanyAccountRouter from "./CompanyAccount/CompanyAccountRouter"
import SaleOrdersAndInvoicesRouter from "./SaleOrdersAndInvoices/SaleOrdersAndInvoicesRouter"
import PurchaseOrdersAndInvoicesRouter from "./PurchaseOrdersAndInvoices/PurchaseOrdersAndInvoicesRouter"
import AccountingRouter from "./Accounting/AccountingRouter"

//eslint-disable-next-line
const styles = createUseStyles(theme => ({
 
}));
 
const myCompanyRouter = props => {
//eslint-disable-next-line
  const theme = useTheme();
//eslint-disable-next-line
  const classes = styles({...props, theme});

 
/***************** CONST ******************/
 
/***************** CONST ******************/
 
 
/***************** STATE ******************/
 
/***************** STATE ******************/
 
 
/***************** CALLBACK ******************/
 
/***************** CALLBACK ******************/
 
 
/***************** EFFECTS ******************/
 
/***************** EFFECTS ******************/
 
 
/***************** FUNCTIONS ******************/
 
/***************** FUNCTIONS ******************/
 
/***************** RENDER ******************/
  return (
	<React.Fragment>
            <Switch>
                <Route path="/financials/companyaccount">
                    <CompanyAccountRouter />
                </Route>
                <Route path="/financials/purchaseordersandinvoices">
                    <PurchaseOrdersAndInvoicesRouter />
				</Route>
				<Route path="/financials/saleordersandinvoices">
                    <SaleOrdersAndInvoicesRouter />
                </Route>
				<Route path="/financials/accounting">
                    <AccountingRouter />
				</Route>
				
            </Switch>
        </React.Fragment>
  )
 
/***************** RENDER ******************/
 
};
 
export default myCompanyRouter;