import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useParams} from "react-router-dom";
import {Route, Switch, Redirect} from "react-router-dom";
import CustomerEdit from "./CustomerEditDetails/CustomerEdit";
import CustomersTable from "./CustomersTable";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerRouter = React.memo(function CustomerRouter(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    
    /***************** CONST ******************/

    /***************** STATE ******************/
    
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route exact path={`/financials/saleordersandinvoices/customers`}>
                <Redirect to="/financials/saleordersandinvoices/customers/table" />
            </Route>
            <Route exact path={`/financials/saleordersandinvoices/customers/table`}>
                <CustomersTable />
            </Route>
			<Route exact path="/financials/saleordersandinvoices/customers/customer/new">
			<CustomerEdit Id="new" RedirectToOnClosingUrl="/financials/saleordersandinvoices/customers/table" />
		</Route>


        </Switch>
    );

    /***************** RENDER ******************/
});

export default CustomerRouter;
