import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
//eslint-disable-next-line
import clsx from 'clsx';
import { createUseStyles, useTheme } from 'react-jss';
//eslint-disable-next-line
const LockedstylesWithProps = props => {
  return {};
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles(theme => ({}));

/**
 * Component Description
 */

const FormTextAreaComponent = React.forwardRef(function FormTextAreaComponent(props, ref) {
  /****************************** PROPS AND JSS CLASSES *********************/

  const {
    InputRef,
    TextAreaInputRef,
    currentValue,
    placeHolder,
    InputClass,
    disabled,
    readOnly,
    RowsToDisplay,
    MaxRows,
    style,
    name,
    InputOnBlur,
    InputOnFocus,
    InputOnChange,
  } = props;

  const theme = useTheme();
  //eslint-disable-next-line
  const classes = styles({ ...props, theme });
  /****************************** PROPS AND JSS CLASSES *********************/

  /****************************** REFS *********************/

  const FakeTextArea = useRef(null);

  /****************************** REFS *********************/

  /****************************** STATE *********************/
  const [LineHeight, setLineHeight] = useState(null);
  const [TextAreaStyle, setTextAreaStyle] = useState({ height: null, overflow: 'hidden' });

  /****************************** STATE *********************/

  /****************************** EFFECT *********************/
  //WHEN COMPONENT LOADS
  // useEffect(() => {
  //     //Execute your code here
  // }, []);
  //CALCULATING THE LINEHEIGHT FOR THE MULTILINE
  useEffect(() => {
    if (InputRef.current && TextAreaInputRef.current) {
      //1. Calculate the initial height with the fake input
	  const FakeInputHeight = TextAreaInputRef.current.getBoundingClientRect().height;
	  
      setLineHeight(`${FakeInputHeight}px`);
      setTextAreaStyle({ height: `${FakeInputHeight}px`, overflow: 'hidden' });
    }
  }, [InputRef, TextAreaInputRef]);

  useEffect(() => {
	  
    if (currentValue) {
      //Calculate only if the input is not empty
      if (InputRef.current && TextAreaInputRef.current && FakeTextArea.current) {
		
		const TextAreaRealHeight = FakeTextArea.current.scrollHeight + 4;

        if (TextAreaRealHeight > parseInt(LineHeight)) {
          //Means that we have mutiple lines -> Calculate number of lines
          const CurrentRows = Math.round(TextAreaRealHeight / parseInt(LineHeight));

          if (CurrentRows < MaxRows) {
            const NewHeight = CurrentRows * parseInt(LineHeight);
            setTextAreaStyle({ height: `${NewHeight}px`, overflow: 'hidden' });
          } else {
            const NewHeight = parseInt(MaxRows) * parseInt(LineHeight);
            setTextAreaStyle({ height: `${NewHeight}px`, overflow: 'hidden' });
          }
        } else {
          //Display the requested number of rows

          const NewHeight = parseInt(RowsToDisplay) * parseInt(LineHeight);
          setTextAreaStyle({ height: `${NewHeight}px`, overflow: 'hidden' });
        }
      }
    }
  }, [currentValue, InputRef, TextAreaInputRef, FakeTextArea, LineHeight, MaxRows, RowsToDisplay]);

  /****************************** EFFECT *********************/

  /****************************** FUNCTIONS *********************/
  //CALCULATE THE LINE HEIGHT WHEN MOUNTING

  //UPDATE THE TEXTAREA HEIGHT WHEN CHANGING

  /****************************** FUNCTIONS *********************/

  /****************************** RENDER *********************/
  return (
    <React.Fragment>
      <input
        ref={TextAreaInputRef}
        value={currentValue}
        style={{ ...style, display: LineHeight ? 'none' : 'box' }}
        className={InputClass}
      />
      <textarea
        ref={FakeTextArea}
        value={currentValue}
        className={InputClass}
        rows={RowsToDisplay}
        style={{
          ...style,
          marginBottom: '0px',
          padding: '0px',
          height: '0px',
          visibility: 'hidden',
        }}
      />
      <textarea
        ref={InputRef}
        value={currentValue}
        autoComplete="off"
        placeholder={placeHolder}
        className={InputClass}
        disabled={disabled}
        readOnly={readOnly}
        rows={RowsToDisplay}
        style={{ ...style, height: TextAreaStyle.height }}
        name={name}
        onBlur={InputOnBlur}
        onFocus={InputOnFocus}
        onChange={InputOnChange}
      />
    </React.Fragment>
  );
  /****************************** RENDER *********************/
});

FormTextAreaComponent.defaultProps = {
  MyProp: null,
};

FormTextAreaComponent.propTypes = {
  /**
   * MyProp :
   */
  MyProp: PropTypes.string,
};

export default FormTextAreaComponent;
