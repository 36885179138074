/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import _ from "lodash";
import {createUseStyles, useTheme} from "react-jss";
import validator from "validator";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Input: {
        alignSelf: "stretch",
        flex: "1 1 auto",
    },
    TextAreaWrapper: {
        display: "flex",
        position: "relative",
        boxSizing: "border-box",
        alignItems: "center",
        alignSelf: "stretch",
        flex: "1 1 auto",
    },
}));

/**
 * Component Description
 */
const FieldTextInput = React.memo(function FieldTextInput(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        InitialValue,
        FormValue,
        OutputValue,
        FieldStatus,
        OutputError,
        Name, //Important for AutoFill
        ShareFocusState,
        ShareHasValue,
        Style,
        HandleUpdateRefInput,
        ReadOnly,
        Disabled,
        LocalValidation,
        Type, //Important for AutoFill
        Required,
        FinalBorderRadius,
        multiline,
        RowsToDisplay,
        MaxRows,
        OnFieldExit,
        FieldMaxWidth,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    const Ref_LocalInput = useRef(null);

    const Ref_InputForTextArea = useRef(null);
    const Ref_FakeTextArea = useRef(null);

    /****************************** REFS *********************/

    /****************************** CONST *********************/

    const ValidatorFunctions = {
        empty: {function: (args) => validator.isEmpty(args), message: "Required field"},
        email: {function: (args) => validator.isEmail(args), message: "Invalid email"},
        creditcard: {function: (args) => validator.isCreditCard(args), message: "Invalid credit card"},
    };

    var FinalValidateWhen;

    //VALIDATING THE FIELDS
    var FinalValidators = [];
    if (LocalValidation) {
        //Specify when to Validate
        if (LocalValidation.When === "Typing") {
            FinalValidateWhen = "Typing";
        } else if (LocalValidation.When === "Leaving") {
            FinalValidateWhen = "Leaving";
        } else if (LocalValidation.When === "Custom") {
            FinalValidateWhen = "Custom";
        } else {
            //Default if nothing is specified
            FinalValidateWhen = "Leaving";
        }

        //Sepcify the validators to be used
        if (LocalValidation.Validators) {
            //Custom Validation
        } else {
            //Define validators based on Required and Type

            if (Type === "email") {
                //Check for email type
                FinalValidators.push("email");
            }
        }
    }
    //VALIDATING THE FIELDS

    /****************************** CONST *********************/

    /****************************** STATE *********************/

    const [LocalInputValue, setLocalInputValue] = useState(null);
    const [LineHeight, setLineHeight] = useState(null);
    const [TextAreaStyle, setTextAreaStyle] = useState({height: null, overflow: "hidden"});

    //const [val, setVal] = useState();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    //Setting the Inital Value
    useEffect(() => {
        if (InitialValue === FormValue) {
            //Init or Reset
            setLocalInputValue(InitialValue);
            OutputValue({Value: FormValue, FormattedValue: null, Pristine: true, Dirty: false});
        } else {
            if (FormValue !== LocalInputValue) {
                //Update from outside
                setLocalInputValue(FormValue);
                OutputValue({Value: FormValue, FormattedValue: null, Pristine: false, Dirty: false});
            }
        }
    }, [InitialValue, FormValue]);

    //Passing the Component Ref to the parent
    useEffect(() => {
        if (Ref_LocalInput.current) {
            HandleUpdateRefInput(Ref_LocalInput.current);
        }
    }, [Ref_LocalInput]);

    //CALCULATING THE LINEHEIGHT FOR THE MULTILINE ON INIT ONLY
    useEffect(() => {
        if (multiline) {
            if (Ref_LocalInput.current && Ref_InputForTextArea.current) {
                //1. Calculate the initial line height with the fake input
                const FakeInputHeight = Ref_InputForTextArea.current.getBoundingClientRect().height + 2;

                setLineHeight(`${FakeInputHeight}px`);
                setTextAreaStyle({height: `${FakeInputHeight}px`, overflow: "hidden"});
            }
        }
    }, [Ref_LocalInput, Ref_InputForTextArea, multiline]);

    useEffect(() => {
        if (multiline) {
            if (LocalInputValue) {
                //Calculate only if the input is not empty

                if (Ref_LocalInput.current && Ref_InputForTextArea.current && Ref_FakeTextArea.current) {
                    //Getting the current scroll value of the fake text area
                    // const TextAreaRealHeight = Ref_FakeTextArea.current.scrollHeight + 4;
                    const TextAreaRealHeight = Ref_FakeTextArea.current.scrollHeight + 2;

                    let VerticalPadding = parseFloat(getComputedStyle(Ref_InputForTextArea.current).paddingTop) + parseFloat(getComputedStyle(Ref_InputForTextArea.current).paddingBottom);

                    if (TextAreaRealHeight > parseInt(LineHeight)) {
                        //Means that we have mutiple lines -> Calculate number of lines
                        const CurrentRows = Math.round(TextAreaRealHeight / parseInt(LineHeight));

                        if (CurrentRows < MaxRows) {
                            //We reduce the text area to match the number of rows but only

                            // const NewHeight = CurrentRows * parseInt(LineHeight);
                            // const NewHeight = parseInt(RowsToDisplay) * parseInt(LineHeight) + VerticalPadding;
                            const NewHeight = parseInt(RowsToDisplay) * parseInt(LineHeight);

                            setTextAreaStyle({divHeight: `${NewHeight + VerticalPadding}px`, height: `${NewHeight}px`, overflow: "hidden"});
                        } else {
                            //We need the scroll bar
                            // const NewHeight = parseInt(MaxRows) * parseInt(LineHeight) + VerticalPadding;
                            const NewHeight = parseInt(MaxRows) * parseInt(LineHeight);

                            setTextAreaStyle({divHeight: `${NewHeight + VerticalPadding}px`, height: `${NewHeight}px`, overflow: "auto"});
                        }
                    } else {
                        //Display the requested number of rows
                        const NewHeight = parseInt(RowsToDisplay) * parseInt(LineHeight);
                        setTextAreaStyle({divHeight: `${NewHeight + VerticalPadding}px`, height: `${NewHeight}px`, overflow: "hidden"});
                    }
                }
            }
        }
    }, [LocalInputValue, Ref_LocalInput, Ref_InputForTextArea, Ref_FakeTextArea, multiline, LineHeight, MaxRows, RowsToDisplay]);

    /****************************** EFFECT *********************/
    /****************************** FUNCTIONS *********************/
    const HandleInputFocus = () => {
        ShareFocusState(true);
        if (OnFieldExit && typeof OnFieldExit === "function") {
            OnFieldExit("Entered");
        }
    };
    const HandleInputChange = (event) => {
        let Value = event.target.value ? event.target.value : null;
        //Validating the field on Typing if FinalValidateWhen === "Typing"

        if (FinalValidateWhen === "Typing") {
            var FinalError;

            if (!Value) {
                //No need to check anything unless the field is required
                if (Required === true) {
                    FinalError = "Required field";
                    //We pass the error to the Form
                    OutputError(FinalError);
                }
            } else {
                //There is an Input Value -> Check if there are validators to be applied
                if (FinalValidators && FinalValidators.length > 0) {
                    //Applying validators in order and exit after the first failure

                    for (var i = 0; i < FinalValidators.length; i++) {
                        //Extracting the validation function
                        let elem = FinalValidators[i];
                        let fn = ValidatorFunctions[elem] ? ValidatorFunctions[elem].function : null;
                        let message = ValidatorFunctions[elem] ? ValidatorFunctions[elem].message : null;
                        //Applying the validator
                        let validationStatus = fn(Value);

                        if (validationStatus === false) {
                            FinalError = message;
                            //exit on failure
                            break;
                        }
                    }
                }
            }
            if (FinalError) {
                //We pass the error to the Form
                OutputError(FinalError);
            } else {
                //Reset the Error!!! If not the error stays
                OutputError(null);
            }
        }

        setLocalInputValue(Value);
        if (OutputValue && typeof OutputValue === "function") {
            var FinalPristine = false;
            if (InitialValue === Value) {
                FinalPristine = true;
            }
            OutputValue({Value: Value, FormattedValue: null, Pristine: FinalPristine, Dirty: true});
        }

        if (Value) {
            ShareHasValue(true);
        } else {
            ShareHasValue(false);
        }
    };

    const HandleInputBlur = () => {
        //Validating the field on Leaving if FinalValidateWhen === "Leaving"

        var FinalError;
        if (FinalValidateWhen === "Leaving") {
            if (!LocalInputValue) {
                //No need to check anything unless the field is required
                if (Required === true) {
                    FinalError = "Required field";
                }
            } else {
                //There is an Input Value -> Check if there are validators to be applied

                if (FinalValidators && FinalValidators.length > 0) {
                    //Applying validators in order and exit after the first failure

                    for (var i = 0; i < FinalValidators.length; i++) {
                        //Extracitng the validation function
                        let elem = FinalValidators[i];
                        let fn = ValidatorFunctions[elem] ? ValidatorFunctions[elem].function : null;
                        let message = ValidatorFunctions[elem] ? ValidatorFunctions[elem].message : null;
                        //Applying the validator
                        let validationStatus = fn(LocalInputValue);

                        if (validationStatus === false) {
                            FinalError = message;
                            //exit on failure
                            break;
                        }
                    }
                }
            }
            if (FinalError) {
                //We pass the error to the Form
                OutputError(FinalError);
            } else {
                //Reset the Error!!! If not the error stays
                OutputError(null);
            }
        }
        if (OnFieldExit && typeof OnFieldExit === "function") {
            OnFieldExit("Left");
        }
        ShareFocusState(false);
    };

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/

    var FormElement;
    if (multiline === true) {
        FormElement = (
            <React.Fragment>
                <div className={classes.TextAreaWrapper} style={{padding: Style.padding, height: TextAreaStyle.divHeight, overflow: "hidden"}}>
                    <input
                        ref={Ref_InputForTextArea}
                        value={"Test"}
                        style={{...Style, display: LineHeight ? "none" : "box", lineHeight: Style.fontSize, padding: "0px", height: null}}
                        // style={{...Style, display: "box", lineHeight:Style.fontSize, padding : "0px", height : null}}
                        // style={{display: LineHeight ? "none" : "box"}}
                        // className={classes.Input}
                    />
                    <textarea
                        ref={Ref_FakeTextArea}
                        value={LocalInputValue === null || LocalInputValue === undefined ? "" : LocalInputValue}
                        // className={classes.Input}
                        // rows={RowsToDisplay}
                        style={{
                            ...Style,
                            visibility: "hidden",
                            display: "block",
                            position: "absolute",
                            lineHeight: Style.fontSize,
                            // display: "none",
                            padding: "0px",
                            height: "0px",
                            resize: "none",
                            border: "0px",
                            outline: 0,
                        }}
                    />
                    <textarea
                        ref={Ref_LocalInput}
                        value={LocalInputValue === null || LocalInputValue === undefined ? "" : LocalInputValue}
                        autoComplete="false"
                        className={classes.Input}
                        onFocus={HandleInputFocus}
                        onChange={HandleInputChange}
                        onBlur={HandleInputBlur}
                        //DOntFold
                        disabled={Disabled === true ? true : false}
                        readOnly={ReadOnly === true ? true : false}
                        rows={RowsToDisplay}
                        // rows="1"
                        // style={{...Style, resize: "none", border: "0px", display: "block", boxSizing: "border-box", outline: 0}}
                        style={{
                            ...Style,
                            // height: TextAreaStyle.height,
                            height: null,
                            padding: "0px",
                            overflowY: TextAreaStyle.overflow,
                            lineHeight: Style.fontSize,
                            resize: "none",
                            border: "0px",
                            display: "block",
                            boxSizing: "border-box",
                            outline: 0,
                        }}
                        name={Name}
                    />
                </div>
            </React.Fragment>
        );
    } else {
        FormElement = (
            <input
                ref={Ref_LocalInput}
                style={{
                    // backgroundColor: "cyan",
                    borderRadius: FinalBorderRadius ? FinalBorderRadius : null,
                    flex: "1 1 auto",
                    height: Style.height ? Style.height : null,
                    width: FieldMaxWidth ? FieldMaxWidth : null,
                    ...Style,
                }}
                className={classes.Input}
                name={Name}
                value={LocalInputValue === null || LocalInputValue === undefined ? "" : LocalInputValue}
                onFocus={HandleInputFocus}
                onChange={HandleInputChange}
                onBlur={HandleInputBlur}
                //DOntFold
                disabled={Disabled === true ? true : false}
                readOnly={ReadOnly === true ? true : false}
                type={Type}
                autoComplete="false"
            />
        );
    }

    return <React.Fragment>{FormElement}</React.Fragment>;
    /****************************** RENDER *********************/
});

FieldTextInput.defaultProps = {
    InitialValue: null,
    FormValue: null,
    OutputValue: null,
    FieldStatus: null,
    Name: null,
    ShareFocusState: null,
    ShareHasValue: null,
    multiline: false,
    RowsToDisplay: "1",
    MaxRows: 3,
    OnFieldExit: false,
    FieldMaxWidth: null,
};

FieldTextInput.propTypes = {
    /**
     * InitialValue :
     */
    InitialValue: PropTypes.any,
    /**
     * FormValue :
     */
    FormValue: PropTypes.any,
    /**
     * OutputValue :
     */
    OutputValue: PropTypes.any,
    /**
     * Name :
     */
    Name: PropTypes.any,
    /**
     * ShareFocusState :
     */
    ShareFocusState: PropTypes.any,
    /**
     * ShareHasValue :
     */
    ShareHasValue: PropTypes.any,
    /**
     * multiline :
     */
    multiline: PropTypes.any,
    /**
     * RowsToDisplay :
     */
    RowsToDisplay: PropTypes.any,
    /**
     * MaxRows :
     */
    MaxRows: PropTypes.any,
    /**
     * OnFieldExit :
     */
    OnFieldExit: PropTypes.any,
    /**
     * FieldMaxWidth :
     */
    FieldMaxWidth: PropTypes.any,
};

export default FieldTextInput;
