import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";

import InvoiceTableAll from "./Tables/InvoiceTableAll";
import InvoicesTableBySupplier from "./Tables/InvoicesTableBySupplier";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavLink: {
        borderRight: "1px solid rgb(220 220 220)",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNav
            MainSlug={`/financials/saleordersandinvoices/invoices/tables`}
            DefaultTab={`/financials/saleordersandinvoices/invoices/tables/all`}
            ClassExtendTabNavLink={classes.ClassExtendTabNavLink}
            TabNavMenuHeight="30px"
            ActiveIndicator={{
                WithIndicator: true,
                Position: "Bottom",
            }}
            TabMenuContent={[
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>All invoices</div>
                        </div>
                    ),
                    Slug: `/financials/saleordersandinvoices/invoices/tables/all`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <InvoiceTableAll />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Invoices by supplier</div>
                        </div>
                    ),
                    Slug: `/financials/saleordersandinvoices/invoices/tables/bysupplier`,
                    exact: true,
                    ShowOnlyIfActive: false,
                    TabContent: <InvoicesTableBySupplier />,
                },
                {
                    TabMenu: (
                        <div className={classes.TabMenuDiv}>
                            <div className={classes.TabTitle}>Unpaid Invoices</div>
                        </div>
                    ),
                    Slug: `/financials/saleordersandinvoices/invoices/tables/unpaid`,
                    ShowOnlyIfActive: false,
                    exact: true,
                    TabContent: <div>Unpaid</div>,
                },
            ]}
        />
    );

    /***************** RENDER ******************/
});

export default Component;
