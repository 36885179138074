import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import Button from "@artibulles-cis/react/Button";

import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../artibulles-cis/CheckBox/CheckBox";
import _ from "lodash";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {UPDATE_TIME_ENTRY_TRACKING} from "../../../../../reduxFunctions/timeTrackingEntry/timeTrackingEntryConstants";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "10px",
        // height: "100%",
        color: "black",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "60px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        display: "flex",
        flex: "0 0 300px",
        margin: "5px 10px 5px 10px",
        postion: "relative",
        boxSizing: "border-box",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    BottomBar: {
        flex: " 0 0 60px",
        padding: "10px 0px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const TimeEntrySave = React.memo(function TimeEntrySave(props) {
    const {TypeOfEntry, Data, DurationSeconds, HandleCloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    const Dispatch = useDispatch();
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);

    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({
        is_completed: true,
    }); //FINAL FORM VALUES

    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});

    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error");
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    const Data = ApiRes.data.data;
                    toast.success(`Time entry saved`, {autoClose: true});
                    Dispatch({type: UPDATE_TIME_ENTRY_TRACKING, payload: {Exist: false, Data: null}});
					setTimeout(() => {
						HandleCloseCard()	
					}, 200);
					
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        //Conditional Fields
        // if (field === "is_completed") {
        //     if (value === true) {
        //         //we need to reset the date of the Completion Date
        //         // UpdateFormValues.completed_date_Iso = formatISO(new Date(Date.now()));
        //     } else {
        //         //we need to reset the date of the Completion Date
        //         UpdateFormValues.completed_date_Iso = null;
        //     }
        // }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        var UpdatedFormErrors = {...FormErrors};

        // if (field === "testval") {
        //     if (value) {
        //         if (value === "Caca") {
        //             UpdatedFormErrors.testval = "Caca is not allowed";
        //         }
        //     } else {
        //         UpdatedFormErrors.testval = "Cannot be empty";
        //     }
        // }

        // if (Object.keys(UpdatedFormErrors).length > 0) {
        //     setFormErrors(UpdatedFormErrors);
        //     setInvalid(true);
        // } else {
        //     setFormErrors({});
        //     setInvalid(false);
        // }
    };
    /**************** FORM VALIDATION  *****************/
    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };
    const HandleCancel = () => {
        HandleCloseCard();
    };
    const HandleSubmit = () => {
        let FinalSumbit = {
            id: Data.id,
            duration_seconds: DurationSeconds,
            is_completed: FormValues.is_completed,
            completed_percentage: parseInt(FormValues.completed_percentage),
            typeOfSubmit: TypeOfEntry === "Plan" ? "Plan" : "FromTask",
        };
        console.log("FinalSumbit", FinalSumbit);

        let ApiCall;
        if (TypeOfEntry === "Plan") {
            ApiCall = {url: `${ProdURL}/api/tasks/time/entry/${FinalSumbit.id}`, type: "put", data: FinalSumbit};
        } else {
            ApiCall = {url: `${ProdURL}/api/tasks/time/entrytracking/${FinalSumbit.id}`, type: "put", data: FinalSumbit};
        }

        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    };

    /**************** ON CHANGE UPDATES  *****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div className={classes.FormWrapper}>
            <h1 style={{margin: "0 auto"}}>Saving task</h1>

            <div className={classes.FormFieldCheckBoxAligned} style={{flex: "1 1 auto"}}>
                <CheckBox
                    Inputname="is_completed"
                    label="Is the task completed ?"
                    initialvalue={true}
                    curvalue={FormValues.is_completed}
                    ShareInputValue={(value) => HandleUpdateFormValues("is_completed", value)}
                />
            </div>
            <div className={classes.FormField} style={{flex: "0 0 50px", width: "250px", display: `${FormValues.is_completed === true ? "none" : "flex"}`}}>
                <FormCompontent
                    Name="completed_percentage"
                    InitialValue={InitialFormValues.completed_percentage ? InitialFormValues.completed_percentage : null}
                    FormValue={FormValues.completed_percentage ? FormValues.completed_percentage : null}
                    OutputValue={(values) => HandleUpdateFormValues("completed_percentage", values)}
                    Component="Input"
                    Variant="Outlined"
                    Label="Percentage completed"
                    NoMessage={false}
                    meta={{error: FormErrors.completed_percentage, invalid: FormErrors.completed_percentage ? true : false}}
                    OutputError={(error) => HandleUpdateErrors("completed_percentage", error)}
                    // LegendOffsetHeight="0"
                    // LegendOffsetWidth="0"
                    LabelMaxWidth="250px"
                    FieldMaxWidth="80px"
                    LabelAlign="Left"
                    Required={true}
                />
            </div>
            <div className={classes.BottomBar}>
                <Button onClick={HandleSubmit} style={{margin: "0px 10px"}}>
                    Save Entry
                </Button>
                <Button onClick={HandleCancel} style={{margin: "0px 10px"}}>
                    Cancel
                </Button>
            </div>
        </div>
    );

    /***************** RENDER ******************/
});

export default TimeEntrySave;
