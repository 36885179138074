import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";

import Page from "@artibulles-cis/react/Page";
import InvoiceEditForm from "./InvoiceEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerEdit = React.memo(function CustomerEdit(props) {
    const {Id, RedirectToSourceOnClosing} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_StatusInvoice = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    }

    const InitialCall = {type: "Edit", ID: IdFinal};
    // console.log("InitialCall", InitialCall);
    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/purchaseinvoices/invoiceeditonly`,
        // MAIN: `${ProdURL}/api/purchaseinvoices/invoice`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("args.typeOfSubmit", args.typeOfSubmit);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Company._id;
            History.push(RedirectToSourceOnClosing);
            if (IdFinal === "new") {
                //We redirect to edit to reset the form and get the right buttons and editing
                History.push(`/financials/purchaseordersandinvoices/suppliers/supplier/${Id}/edit`);
            }

            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("data", data);
        let Invoice = data.Invoice ? data.Invoice : null;

        let SuppliersList;
        if (data.Suppliers && data.Suppliers.length > 0) {
            SuppliersList = data.Suppliers.map((elem) => {
                return {value: elem._id, label: elem.company, vat_number: elem.vat_number, BankAccounts: elem.BankAccounts, Addresses: elem.Adresses};
            });
        }
        let PaymentTermsOptions = [];
        let PaymentTerms = data.PaymentTerms;
        if (PaymentTerms && Array.isArray(PaymentTerms)) {
            PaymentTerms.forEach((elem) => {
                PaymentTermsOptions.push({
                    label: elem.PaymentTerms,
                    value: elem._id,
                    InvoiceDescription_FR: elem.InvoiceDescription_FR,
                    InvoiceDescription_EN: elem.InvoiceDescription_EN,
                    DueDaysAfterInvoice: elem.DueDaysAfterInvoice,
                    DueDayOfNextMonth: elem.DueDayOfNextMonth,
                });
            });
        }

        let PaymentConditionsSelectOptions = data.PaymentConditionsSelectOptions;
        let PaymentMethodSelectOptions = data.PaymentMethodSelectOptions;

        let PaymentConditionsInitial = Invoice ? Invoice.PaymentCondition : null;
        if (!PaymentConditionsInitial) {
            PaymentConditionsInitial = "01";
        } else {
            PaymentConditionsInitial = _.find(PaymentConditionsSelectOptions, {label: PaymentConditionsInitial}).value;
        }

        let PaymentMethodInitial = Invoice ? Invoice.PaymentMethod : null;

        if (PaymentMethodInitial) {
            PaymentMethodInitial = _.find(PaymentMethodSelectOptions, {label: PaymentMethodInitial});
            if (PaymentMethodInitial) {
                PaymentMethodInitial = PaymentMethodInitial.value;
            }
        }

        let SupplierInitialId = Invoice ? Invoice.ref_Supplier : null;
        let InitialSupplier;

        if (SupplierInitialId) {
            InitialSupplier = _.find(data.Suppliers, {_id: SupplierInitialId});
        }

        let VatNumber;
        if (InitialSupplier) {
            VatNumber = InitialSupplier.vat_number;
        }

        let InvoicesFilePaths = [];

        if (Invoice && Invoice.Invoices_File_Path && Array.isArray(Invoice.Invoices_File_Path) && Invoice.Invoices_File_Path.length > 0) {
            Invoice.Invoices_File_Path.forEach((elem) => {
                InvoicesFilePaths.push(elem);
            });
        }

        let BankAccountsOptions = [];

        if (InitialSupplier) {
            let BankAccounts = InitialSupplier.BankAccounts;

            if (BankAccounts && Array.isArray(BankAccounts)) {
                BankAccounts.forEach((elem) => {
                    BankAccountsOptions.push({label: elem.account_number, value: elem._id});
                });
            }
        }

        let CreditCardsOptions = [];
        if (data.CreditCards && Array.isArray(data.CreditCards) && data.CreditCards.length > 0) {
            data.CreditCards.forEach((elem) => {
                CreditCardsOptions.push({value: elem._id, label: elem.card_number + "-" + elem.card_holder_Name});
            });
        }

        let InitialAddress;
        if (InitialSupplier) {
            if (InitialSupplier.Adresses && InitialSupplier.Adresses.length > 0) {
                //We need to find the Billing Address or the General one if the billing doesn't exist
                let InitialAddressFull,
                    Addresses = [];
                if (InitialSupplier.Adresses.length === 1) {
                    InitialAddressFull = InitialSupplier.Adresses[0];
                } else {
                    InitialSupplier.Adresses.forEach((elem) => {
                        if (elem.AddressType.name_EN === "Billing") {
                            Addresses.push({Type: "Billing", Address: elem});
                        } else if (elem.AddressType.name_EN === "General") {
                            Addresses.push({Type: "General", Address: elem});
                        }
                    });
                    InitialAddressFull = _.find(Addresses, {Type: "Billing"});
                    if (!InitialAddressFull) {
                        InitialAddressFull = _.find(Addresses, {Type: "General"});
                    }
                }

                if (InitialAddressFull) {
                    InitialAddress = {
                        Line1: InitialAddressFull.street_line1,
                        Line2: InitialAddressFull.street_line2,
                        ZipCity: InitialAddressFull.zip_code + ", " + InitialAddressFull.city,
                        Country: InitialAddressFull.Country.country_name_EN,
                    };
                }
            }
        }
        Ref_StatusInvoice.current = Invoice ? (Invoice.status_invoice ? Invoice.status_invoice : "Draft") : "Draft";

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Invoice ? Invoice._id : "new",
                PontoDetails: Invoice ? Invoice.PontoDetails : null,
                InvoiceShortId: Invoice ? Invoice.InvoiceShortId : null,
                MyCompanyBankAccounts: data.MyCompanyBankAccounts,
                status_invoice: Invoice ? Invoice.status_invoice : "Draft",
                InvoicePaymentExpectedCommunication: Invoice ? Invoice.InvoicePaymentExpectedCommunication : null,
                Description: Invoice ? Invoice.Description : null,
                CalcTotalVATIncluded: Invoice ? Invoice.CalcTotalVATIncluded : null,
                CalcTotalVATExcluded: Invoice ? Invoice.CalcTotalVATExcluded : null,
                CalcTotalVATAmount: Invoice ? Invoice.CalcTotalVATAmount : null,
                TaxesDetails: Invoice ? (Invoice.InvoiceAmountSummary ? (Invoice.InvoiceAmountSummary.VATDetails ? Invoice.InvoiceAmountSummary.VATDetails : null) : null) : null,
                TotalVATExcludedPurchaseCurrency: Invoice ? Invoice.TotalVATExcludedPurchaseCurrency : null,
                TotlVATAmountPurchaseCurrency: Invoice ? Invoice.TotlVATAmountPurchaseCurrency : null,
                TotalVATIncludedPurchaseCurrency: Invoice ? Invoice.TotalVATIncludedPurchaseCurrency : null,
                Currency: Invoice ? Invoice.Currency : null,
                CurrencyExchangeRate: Invoice ? Invoice.CurrencyExchangeRate : null,
                PaymentCondition: PaymentConditionsInitial,
                PaymentMethod: PaymentMethodInitial,
                InvoiceSupplierDateIso: Invoice ? Invoice.InvoiceSupplierDateIso : null,
                ManualInvoicePaymentDueDateIso: Invoice ? Invoice.ManualInvoicePaymentDueDateIso : null,
                ref_PaymentTerms: Invoice ? Invoice.ref_PaymentTerms : null,
                InvoicePaymentDueDateIso: Invoice ? Invoice.InvoicePaymentDueDateIso : null,
                InvoicePaidDateIso: Invoice ? Invoice.InvoicePaidDateIso : null,
                ref_Supplier: SupplierInitialId ? SupplierInitialId : null,
                vat_number: VatNumber,
                CounterpartyBankAccount_Id: Invoice ? Invoice.CounterpartyBankAccount_Id : null,
                CreditCard_Id: Invoice ? Invoice.CreditCard_Id : null,
                InitialAddress: InitialAddress,
                ManualBankPaymentActionsStatus: Invoice ? Invoice.ManualBankPaymentActionsStatus : null,
                CalcIsPaid: Invoice ? Invoice.CalcIsPaid : null,
                CalcPaymentDateIso: Invoice ? Invoice.CalcPaymentDateIso : null,
                ManualPaymentDone: Invoice ? Invoice.ManualPaymentDone : null,
                ManualPaymentDateIso: Invoice ? Invoice.ManualPaymentDateIso : null,
                IsInvoicePaymentExpectedCommunicationStructured: Invoice ? (Invoice.IsInvoicePaymentExpectedCommunicationStructured === true ? true : false) : null,
                AccountantStatus: Invoice ? Invoice.AccountantStatus : null,
                AccountantStatusHistory: Invoice ? Invoice.AccountantUploadedJustification_PurchaseInvoice : null,
				IsCreditNote : Invoice ? Invoice.IsCreditNote : null,
				IsReconciled : Invoice ? Invoice.IsReconciled : null,
				InvoiceReccurence : Invoice ? Invoice.InvoiceReccurence : null,
                // db_status: Company ? Company.db_status : null,
                // db_locked: Company ? Company.db_locked : null,
                // db_locked_by: Company ? Company.ref_db_locked_by : null,
                // db_locked_at: Company ? Company.db_locked_at : null,
                // owner: Company ? Company.ref_owner : null,
                createdAt: Invoice ? (Invoice.createdAt ? format(parseISO(Invoice.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: Invoice ? (Invoice.updatedAt ? format(parseISO(Invoice.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                ref_Supplier: SuppliersList,
                PaymentCondition: PaymentConditionsSelectOptions,
                PaymentMethod: PaymentMethodSelectOptions,
                CounterpartyBankAccount_Id: BankAccountsOptions,
                CreditCard_Id: CreditCardsOptions,
                ref_PaymentTerms: PaymentTermsOptions,
            },
            SubOptionsFull: {},
            FilesUploaders: {
                Invoices_File_Path: InvoicesFilePaths,
            },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormDataToServer")
        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            let FilesUpload = FullSubmission.FilesUploader;

            let InvoicesFilepathFinal;
            if (FilesUpload && FilesUpload.Invoices_File_Path && Array.isArray(FilesUpload.Invoices_File_Path) && FilesUpload.Invoices_File_Path.length > 0) {
                InvoicesFilepathFinal = FilesUpload.Invoices_File_Path;
            } else {
                InvoicesFilepathFinal = null;
            }

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Invoice: {
                        Updatable: {
                            status_invoice: values.status_invoice,
                            InvoicePaymentExpectedCommunication: values.InvoicePaymentExpectedCommunication,
                            Description: values.Description,
                            TotalVATExcludedPurchaseCurrency: values.TotalVATExcludedPurchaseCurrency,
                            TotlVATAmountPurchaseCurrency: values.TotlVATAmountPurchaseCurrency,
                            TotalVATIncludedPurchaseCurrency: values.TotalVATIncludedPurchaseCurrency,
                            Currency: values.Currency,
                            CurrencyExchangeRate: values.CurrencyExchangeRate,
                            PaymentCondition: values.PaymentCondition,
                            PaymentMethod: values.PaymentMethod,
                            InvoiceSupplierDateIso: values.InvoiceSupplierDateIso,
                            ManualInvoicePaymentDueDateIso: values.ManualInvoicePaymentDueDateIso,
                            InvoicePaidDateIso: values.InvoicePaidDateIso,
                            ref_Supplier: values.ref_Supplier,
                            ref_PaymentTerms: values.ref_PaymentTerms,
                            Invoices_File_Path: InvoicesFilepathFinal,
                            CounterpartyBankAccount_Id: values.CounterpartyBankAccount_Id,
                            CreditCard_Id: values.CreditCard_Id,
                            ManualPaymentDone: values.ManualPaymentDone,
                            IsInvoicePaymentExpectedCommunicationStructured: values.IsInvoicePaymentExpectedCommunicationStructured,
                            ManualPaymentDateIso: values.ManualPaymentDateIso,
							IsCreditNote :values.IsCreditNote,
                        },
                    },
                    CompanyAddresses: values.CompanyAddresses,
                };
                //this is what will be sent back to the server
                console.log("ServerData", ServerData);
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ButtonSaveAsDraftComp, ButtonSaveAndQuitComp, ButtonQuitNoSaveComp;

    if (IdFinal === "new") {
        ButtonSaveAsDraftComp = {Active: false, Text: "Create Initial Order", Width: "200px"};
        ButtonSaveAndQuitComp = {Active: false, Text: "Create Initial Order And Quit", Width: "200px"};
        ButtonQuitNoSaveComp = {Active: false, Text: "Exit"};
    } else {
        ButtonSaveAsDraftComp = {Active: false, Text: "Save draft"};
        ButtonSaveAndQuitComp = {Active: false, Text: "Save And Quit"};
        ButtonQuitNoSaveComp = {Active: false, Text: "Exit"};
    }

    let FinalViewMode;
    if (Id === "new") {
        FinalViewMode = false;
    } else {
        if (Ref_StatusInvoice.current && Ref_StatusInvoice.current === "Draft") {
            FinalViewMode = false;
        } else {
            FinalViewMode = true;
        }
    }
    // console.log("Ref_StatusInvoice.current", Ref_StatusInvoice.current);

    return (
        <Page WithPerfectScrollBar={false} PagePadding="0px">
            <FormWithApi
                WithCardModal={Id === "new" ? true : false}
                // WithCardModal={true}
                CardModalOptions={{
                    // CloseCard: HandleCloseEditCard,
                    CloseOnClickOutside: false,
                    WithCloseButton: true,
                    // CardMaxWidth="800px"
                    // CardHeight="700px"
                    CardWindowPaddingVertical: "10px",
                    CardWindowPaddingHorizontal: "10px",
                    WithPerfectScrollbar: false,
                    // CardWindowPaddingVertical="50px"

                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                OpenInViewMode={FinalViewMode}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={InvoiceEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={ButtonSaveAsDraftComp}
                ButtonSaveAndQuit={ButtonSaveAndQuitComp}
                ButtonQuitNoSave={ButtonQuitNoSaveComp}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </Page>
    );

    /***************** RENDER ******************/
});

export default CustomerEdit;
