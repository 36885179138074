import React from "react";
import ReactDOM from "react-dom";
//eslint-disable-next-line
import {BrowserRouter} from "react-router-dom";
//eslint-disable-next-line
import {HashRouter} from "react-router-dom";

import ScrollToTop from "./app/common/util/ScrollToTop";

import {Provider} from "react-redux";
import {configureStore} from "./app/reduxStore/configureStore";
import "./scss/App.scss";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
const store = configureStore();

const rootEl = document.getElementById("root");

let render = () => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTop>
                        <App />
                    </ScrollToTop>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        rootEl
    );
};
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
