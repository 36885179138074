import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const InvoiceDuplicate = React.memo(function InvoiceDuplicate(props) {
    let {HandleCloseViewCard, DuplicateId} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                if (ApiRes.errorStatus === 404) {
                }
            } else {
                if (ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    HandleCloseViewCard(Data.InvoiceID);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    let FormData = {DuplicateId: DuplicateId};
    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/invoiceitems/invoiceitemduplicate`, type: "post", data: FormData};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }
        APIInternal();
    }, [ProdURL]);

    useEffect(() => {
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <div>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
        </div>
    );

    /***************** RENDER ******************/
});

export default InvoiceDuplicate;
