import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormWithApi from "../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import TabNavContent from "../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import MilestoneEditForm from "./MilestoneEditForm";
import _ from "lodash";
import {convertSecondsToTime} from "@artibulles-cis/react/utils/dateUtils";
import {format, parseISO, formatISO, getHours, getMinutes, getUnixTime, fromUnixTime, setHours, setMinutes, addHours} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {Id, ProgramId, HandleCloseViewCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    let IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/programs/program/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/programs/milestone`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            if (HandleCloseViewCard) {
                HandleCloseViewCard();
            }
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("Milestone Edit data", data);
        //SET THE INITIAL FORM VALUES
        let Milestone = data.Milestone ? data.Milestone : null;

        var expected_duration_secondsInit;
        if (Milestone && Milestone.expected_duration_seconds) {
            expected_duration_secondsInit = parseInt(Milestone.expected_duration_seconds) / 3600;
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Milestone ? Milestone._id : "new",
                milestone_name: Milestone ? Milestone.milestone_name : null,
                description: Milestone ? Milestone.description : null,
                is_completed: Milestone ? Milestone.is_completed : null,
                is_started: Milestone ? Milestone.is_started : null,
                is_billable: Milestone ? Milestone.is_billable : null,
                expected_start_date_Iso: Milestone ? Milestone.expected_start_date_Iso : null,
                date_started_Iso: Milestone ? Milestone.date_started_Iso : null,
                expected_duration_seconds: expected_duration_secondsInit?expected_duration_secondsInit.toString():null,
                due_date_Iso: Milestone ? Milestone.due_date_Iso : null,
                ref_program: Milestone ? Milestone.ref_program : null,
            },
            SelectOptions: {
                // ref_company: Companies,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;
            let expected_duration_seconds;

            if (values.expected_duration_seconds) {
                console.log("values.expected_duration_seconds");
                expected_duration_seconds = parseInt(values.expected_duration_seconds) * 3600;
            }
            let ref_programFinal;
            if (IdFinal === "new") {
                ref_programFinal = ProgramId;
            }
            if (values) {
                let ServerData = {
                    API_id: values.ApiID,

                    Milestone: {
                        Updatable: {
                            milestone_name: values.milestone_name,
                            description: values.description,
                            is_completed: values.is_completed,
                            is_started: values.is_started,
                            is_billable: values.is_billable,
                            expected_start_date_Iso: values.expected_start_date_Iso,
                            date_started_Iso: values.date_started_Iso,
                            expected_duration_seconds: expected_duration_seconds,
                            due_date_Iso: values.due_date_Iso,
                            ref_program: ref_programFinal,
                        },
                    },
                };

                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={MilestoneEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: true, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
