/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import NavSideBarWithContext from "../NavSideBarWithContext/NavSideBarWithContext";
import {NavSideBarContextProvider} from "../utils/context/navSideBarContext/navSideBarContext";

/**
 * Creates a Sidebar Container that can be :
 * -Shown or hidden
 * -Minimized and controlled through Mouse Click with a Controller or Mouse Hover
 * -Controlled with an Integrated Controller or an external Controller
 * It's part of the SideBar Component Package that facilitate the creation of hihgly customizable SideBar
 */
const NavSideBar = React.forwardRef(function NavSideBar(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    //eslint-disable-next-line
    const {
        Show,
        WidthMinimized,
        WidthExpanded,
        SideBarController,
        WithCollapseAllController,
        CustomCollapseAllController,
        WithExpandAllController,
        CustomExpandAllController,
        CollapseOnMinimize,
        Resizable,
        ResizeMinAllowed,
        ResizableMaxWidth,
        ResizableMinWidth,
        UpdateWidthDraggedDimensions,
        ExpandOnHover,
        TopRefComponentID,
        BottomRefComponentID,
        DelegateMinimizeStatus,
        UpdateMinimizedStatus,
        SetIsMinimizeFromOutside,
        ShareIsMinimizedStatus,
        WithPerfectScrollbar,
        WithScrollBar,
        ZIndex,
        ClassOverrideSideBarNav,
        ClassExtendSideBarNav,
        ClassOverrideSideBarNavContent,
        ClassExtendSideBarNavContent,
        children,
    } = props;

    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** STATE *********************/

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    //Setting the final class based on props

    return (
        <NavSideBarContextProvider>
            <NavSideBarWithContext {...props}>{children}</NavSideBarWithContext>
        </NavSideBarContextProvider>
    );
    /****************************** RENDER *********************/
});

NavSideBar.defaultProps = {
    Show: true,
    WidthMinimized: 250,
    WidthExpanded: 90,
    SideBarController: null,
    WithCollapseAllController: false,
    CustomCollapseAllController: null,
    WithExpandAllController: false,
    CustomExpandAllController: null,
    CollapseOnMinimize: false,
    Resizable: false,
    ResizeMinAllowed: false,
    ResizableMaxWidth: null,
    ResizableMinWidth: null,
    UpdateWidthDraggedDimensions: null,
    ExpandOnHover: false,
    TopRefComponentID: null,
    BottomRefComponentID: null,
    DelegateMinimizeStatus: null,
    UpdateMinimizedStatus: null,
    SetIsMinimizeFromOutside: null,
    ShareIsMinimizedStatus: null,
    WithScrollBar: null,
    WithPerfectScrollbar: false,
    ZIndex: "200",
    ClassOverrideSideBarNav: null,
    ClassExtendSideBarNav: null,
    ClassOverrideSideBarNavContent: null,
    ClassExtendSideBarNavContent: null,
};

NavSideBar.propTypes = {
    /**
     * Show or hide the SideBar
     */
    Show: PropTypes.bool,
    /**
     * WidthMinimized : The Width of the expanded SideBar
     */
    WidthMinimized: PropTypes.string,
    /**
     * WidthExpanded :  The Width of the minimized SideBar
     */
    WidthExpanded: PropTypes.string,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    SideBarController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    WithCollapseAllController: PropTypes.bool,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CustomCollapseAllController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    WithExpandAllController: PropTypes.bool,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CustomExpandAllController: PropTypes.node,
    /**
     * A React Element that will be used as the SideBar Controller. See the NavSideBarController Component
     */
    CollapseOnMinimize: PropTypes.bool,
    /**
     * Allow dragging the sideBar border to resize it
     */
    Resizable: PropTypes.bool,
    /**
     * If set to true both minimized and expanded width can be modified, if false, only the expanded width can be modified
     */
    ResizeMinAllowed: PropTypes.bool,
    /**
     * The maximum width for resizing, null => window
     */

    ResizableMaxWidth: PropTypes.string,
    /**
     * The minimu width for resizing the sidebar max_min =0
     */
    ResizableMinWidth: PropTypes.string,
    /**
     * A function to be used to store the updated dragged dimensions in case you want to store it as final settings in a DB or redux
     */
    UpdateWidthDraggedDimensions: PropTypes.func,

    /**
     * If true, MouseHover will open and minimize the SideBar
     */
    ExpandOnHover: PropTypes.bool,
    /**
     * TopRefComponentID : The id of the component to place the sidebar below
     * Used to reposition the SideBar Dynamically if the top component changes (hidden, or grow...)
     * If nothing is specified, then the top position will be 0px
     */
    TopRefComponentID: PropTypes.string,
    /**
     * BottomRefComponentID : The id of the component to stop the SideBar before
     * Used to reposition the SideBar Dynamically if the top component changes (hidden, or grow...)
     * If nothing is specified, then the top height will be the Overall container height
     */
    BottomRefComponentID: PropTypes.string,
    /**
     * DelegateMinimizeStatus : Control the minimized status from outside
     * This object requires 2 parameters
     * DelegateMinimizeStatus={{StatusMinimized: true/false,  ChangeStatusMinimized: function()}}
     * StatusMinimized : the minimized status desired true/false
     * ChangeStatusMinimized : a trigger to ask the parent to change the minimized status
     * example : in parent component
     * const [SideBarStatusMinimized, setSideBarStatusMinimized ] = React.useState(false)
     * const ToggleMinimize = ()=> {
     * setSideBarStatusMinimized(!SideBarStatusMinimized)
     * }
     * <button onClick={ToggleMinimized}>Toggle</button>
     * <NavSideBar
     * DelegateMinimizeStatus={{StatusMinimized: SideBarStatusMinimized,  ChangeStatusMinimized: ToggleMinimize}}
     * />
     */
    DelegateMinimizeStatus: PropTypes.object,
    /**
     * UpdateMinimizedStatus : Used to pass the minimized status of the SideBar to the Parent Component
     * A function needs to be passed returning true or false
     * This is required for instance when another component needs to resize when the sideBar size changes
     */
    UpdateMinimizedStatus: PropTypes.any,
    /**
     * SetIsMinimizeFromOutside : Controlling the width of the SideBar from the outside
     *
     */
    SetIsMinimizeFromOutside: PropTypes.bool,
    /**
     * SetIsMinimizeFromOutside : Controlling the width of the SideBar from the outside
     * The parent function used to control it must return true or false
     */
    ShareIsMinimizedStatus: PropTypes.string,
    /**
     * Specify if a Scrollbar should be displayed
     * WithScrollBar={{X:false,Y:true }}
     */
    WithScrollBar: PropTypes.object,
    /**
     * Specify if perfectScrollBar should be used for the SideBarContent Element
     * See the PerfectScrollBarWrapper to see what properties can be passed.
     */
    WithPerfectScrollbar: PropTypes.bool,
    /**
     * Overrule the theme Z-Index property.
     */
    ZIndex: PropTypes.string,
    /**
     * Overrides the class of the SideBar Element
     */
    ClassOverrideSideBarNav: PropTypes.string,
    /**
     * Extends the class of the SideBar Element
     */
    ClassExtendSideBarNav: PropTypes.string,
    /**
     * Overrides the class of the SideBar Content Element
     */
    ClassOverrideSideBarNavContent: PropTypes.string,
    /**
     * Overrides the class of the SideBar Content Element
     */
    ClassExtendSideBarNavContent: PropTypes.string,
};

export default NavSideBar;
