import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};
//eslint-disable-next-line
const Lockedstyles = {};

const styles = createUseStyles((theme) => ({
    TabNavLink: theme.TabNavLink,
    TabNavLink_Link: theme.TabNavLink_Link,
    TabNavLink_Link_Active: theme.TabNavLink_Link_Active,
    TabNavLink_Link_Indicator_Active_Bottom: theme.TabNavLink_Link_Indicator_Active_Bottom,
    TabNavLink_Link_Indicator_Inactive_Bottom: theme.TabNavLink_Link_Indicator_Inactive_Bottom,
    TabNavLink_Link_Indicator_Active_Top: theme.TabNavLink_Link_Indicator_Active_Top,
    TabNavLink_Link_Indicator_Inactive_Top: theme.TabNavLink_Link_Indicator_Inactive_Top,
    TabNavLink_Content: theme.TabNavLink_Content,
    TabNavLink_Link_TabStyle: theme.TabNavLink_Link_TabStyle,
    Tooltip: {
        zIndex: "200000 !important",
    },
}));
/**
 * Create a TabNavigationLink Element for the TabElement
 * Need to add option to add the indicator on top, default for now is bottom
 */
const TabNavLink = React.forwardRef(function TabNavLink(props, ref) {
    const {
        OnClick,
        ShowContent,
        Key,
        IsActive,
        ActiveIndicator,
        NavLinkStyle,
        Tooltip,
        ClassOverrideTabNavLink,
        ClassExtendTabNavLink,
        ClassOverrideTabNavLink_Link,
        ClassExtendTabNavLink_Link,
        ClassOverrideTabNavLinkActive,
        ClassExtendTabNavLinkActive,
        ClassOverrideTabNavLink_Link_Indicator_Active,
        ClassExtendTabNavLink_Link_Indicator_Active,
        ClassOverrideTabNavLink_Link_Indicator_Inactive,
        ClassExtendTabNavLink_Link_Indicator_Inactive,
        ClassOverrideTabNavLink_Content,
        ClassExtendTabNavLink_Content,
        children,
        ...other
    } = props;
    const theme = useTheme();
    const classes = styles({...props, theme});

    const [IsActiveLink, setIsActiveLink] = React.useState(IsActive);

    // console.log("Button", OnClick);
    const HandleClickTabButton = (key) => {
        if (OnClick) {
			OnClick()
        } else {
            ShowContent(key);
        }
    };
    //Class of the tab element Default
    var FinalClassTabNavLink;
    if (ClassOverrideTabNavLink_Link) {
        FinalClassTabNavLink = ClassOverrideTabNavLink_Link;
    } else {
        FinalClassTabNavLink = clsx(classes.TabNavLink_Link, ClassExtendTabNavLink_Link, NavLinkStyle === "Tab" ? classes.TabNavLink_Link_TabStyle : null);
    }

    //Class of the tab element Active
    var FinalClassTabNavLinkActive;
    if (IsActive === true) {
        if (ClassOverrideTabNavLinkActive) {
            FinalClassTabNavLinkActive = ClassOverrideTabNavLinkActive;
        } else {
            if (ActiveIndicator.WithIndicator) {
                FinalClassTabNavLinkActive = clsx(classes.TabNavLink_Link_Active, ClassExtendTabNavLinkActive);
            } else {
                FinalClassTabNavLinkActive = clsx(classes.TabNavLink_Link_Active, ClassExtendTabNavLinkActive);
            }
        }
    }
    //Class of the Indicator element
    var FinalClassIndicator;

    if (ActiveIndicator.WithIndicator === true) {
        if (IsActive === true) {
            FinalClassIndicator = clsx(
                ActiveIndicator.Position === "Top" ? classes.TabNavLink_Link_Indicator_Active_Top : classes.TabNavLink_Link_Indicator_Active_Bottom,
                ClassExtendTabNavLink_Link_Indicator_Active
            );
        } else {
            FinalClassIndicator = clsx(
                ActiveIndicator.Position === "Top" ? classes.TabNavLink_Link_Indicator_Inactive_Top : classes.TabNavLink_Link_Indicator_Inactive_Bottom,
                ClassExtendTabNavLink_Link_Indicator_Inactive
            );
        }
    }
    var FinalClass;
    FinalClass = clsx(FinalClassTabNavLink, FinalClassTabNavLinkActive, FinalClassIndicator);

    var ToolTipComp;
    if (Tooltip) {
        ToolTipComp = (
            <ReactTooltip id={Key} place="bottom" type="dark" effect="solid" className={clsx(classes.Tooltip, Tooltip.className)} {...Tooltip}>
                <div className={classes.Tooltip}>{Tooltip.Content}</div>
            </ReactTooltip>
        );
    }
    var FinalComponent;

    FinalComponent = (
        <React.Fragment>
            <div data-tip data-for={Key} className={clsx("TabNavLink", classes.TabNavLink, IsActiveLink ? classes.TabActive : null)} onClick={() => HandleClickTabButton(Key)}>
                <div ref={ref} {...other} className={FinalClass}>
                    <div className={ClassOverrideTabNavLink_Content ? ClassOverrideTabNavLink_Content : clsx(classes.TabNavLink_Content, ClassExtendTabNavLink_Content)}>{children}</div>
                </div>
            </div>

            {ToolTipComp}
        </React.Fragment>
    );

    return <React.Fragment>{FinalComponent}</React.Fragment>;
});

TabNavLink.defaultProps = {
    Slug: null,
    exact: true,
    ShowOnlyIfActive: false,
    NavLinkStyle: null,
    Tooltip: true,
    ActiveIndicator: {WithIndicator: false, Position: "Bottom", ClassExtendActiveIndicator: null, ClassExtendInactiveIndicator: null},
    ClassOverrideTabNavLink: null,
    ClassExtendTabNavLink: null,
    ClassOverrideTabNavLink_Link: null,
    ClassExtendTabNavLink_Link: null,
    ClassOverrideTabNavLinkActive: null,
    ClassExtendTabNavLinkActive: null,
    ClassOverrideTabNavLink_Link_Indicator_Active: null,
    ClassExtendTabNavLink_Link_Indicator_Active: null,
    ClassOverrideTabNavLink_Link_Indicator_Inactive: null,
    ClassExtendTabNavLink_Link_Indicator_Inactive: null,
    ClassOverrideTabNavLink_Content: null,
    ClassExtendTabNavLink_Content: null,
};

TabNavLink.propTypes = {
    /**
     * The relative link to the page to display
     * If no Slug is passed, then it will just be a button type component
     */
    Slug: PropTypes.string,
    /**
     * The relative link to the page to display
     * If no Slug is passed, then it will just be a button type component
     */
    exact: PropTypes.any,
    /**
     * Used to hide a tab and only show it when the route is active
     * Useful for instance to use a view element that needs to be shown only when a route is activated
     */
    ShowOnlyIfActive: PropTypes.bool,
    /**
     * Style of the TabLink (default = rectangle) option : Tab (rounded rectangle)
     */
    NavLinkStyle: PropTypes.string,
    /**
     * Add an Indicator or not when the link is active:
     *  ActiveIndicator: {WithIndicator: false, Position: "Top", ClassExtendActiveIndicator: null, ClassExtendInactiveIndicator: null},
     */

    ActiveIndicator: PropTypes.any,
    /**
     * Add a ReactTooltip Component for the tab element
     * See https://www.npmjs.com/package/react-tooltip to check the properties that can be used.
     * The main props are :
     * place (top, right, bottom, left)
     * multiline : true/false
     * the strucutre of the object is :
     *  Tooltip: {Content: "hello I am There", disable : true}
     */
    Tooltip: PropTypes.any,

    /**
     * Extend the class
     */
    ClassExtendTabNavLink: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Link: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink_Link: PropTypes.string,

    /**
     * Override the class
     */

    ClassOverrideTabNavLinkActive: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLinkActive: PropTypes.string,

    /**
     * Extend the class
     */
    ClassOverrideTabNavLink_Link_Indicator_Active: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Link_Indicator_Active: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink_Link_Indicator_Inactive: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Link_Indicator_Inactive: PropTypes.string,
    /**
     * Override the class
     */
    ClassOverrideTabNavLink_Content: PropTypes.string,
    /**
     * Extend the class
     */
    ClassExtendTabNavLink_Content: PropTypes.string,
};

export default TabNavLink;
