import React, {useState} from "react";

const NavSideBarContext = React.createContext([{}, () => {}]);

const NavSideBarContextProvider = props => {
    // const [state, setState] = useState({});//Without initial values
    const [state, setState] = useState({
        AccordionsStatus: [],
		SideBarMinimizedStatus : null,
    });
    return <NavSideBarContext.Provider value={[state, setState]}>{props.children}</NavSideBarContext.Provider>;
};

export {NavSideBarContext, NavSideBarContextProvider};
