import React, {useEffect, useState, useCallback, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import CardModal from "@artibulles-cis/react/CardModal";
import CardModal from "@artibulles-cis/react/CardModal";
import {useHistory} from "react-router-dom";

import Button from "@artibulles-cis/react/Button";
import AGGridTable from "../../../../../../../artibulles-cis/AGGridTable/AGGridTable";

import InvoiceEdit from "../InvoiceEdit/InvoiceEdit";

import {PDF} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 0 100%",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px 5px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    FormWrapper: {
        flex: "1 1 auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    TableWrapper: {
        flex: "1 1 auto",
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        // overflow: "hidden",
        // padding: "20px",
        // position: "relative",
        // boxSizing: "border-box",
        // display: "flex",
        // margin: "0 auto",
        // justifyContent: "center",
    },
}));

const Component = React.memo(function Component(props) {
    const {TableData, HandleCloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    const Ref_TableData = useRef(null);

    /***************** REFS ******************/

    /***************** CONST ******************/

    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    let URLAbsolute = {
        ALL: `${ProdURL}/api/securebanking/transactionstosign`,
        QUERY: null,
        DELETEONE: null,
        DELETEMANY: null,
        PUT: null,
    };

    const PDFCell = {
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        height: "100%",
    };
    const HandleViewPdf = (arg) => {
        window.open(arg);
    };
    const PDFRendering = (params) => {
        let value = params.value;

        if (value && Array.isArray(value) && value.length > 0) {
            let CellRenderComp = [];
            value.forEach((elem) => {
                let IconElem = (
                    <div onClick={() => HandleViewPdf(elem.serverPath)}>
                        <PDF SVGFillColor="white" />
                    </div>
                );
                CellRenderComp.push(IconElem);
            });

            return CellRenderComp;
        } else {
            return null;
        }
    };

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };

    const HandleEditRow = (Id, RowData) => {
        let InvoiceId = RowData.Invoice._id;
        setShowEditCard(true);
        setEditCardId(InvoiceId);
    };

    const ColumnButtonStyle = {
        Width: 95,
        padding: "0px 0px 0px 17px",
    };

    const TableRowButtons = [
        {
            Standard: true,
            Name: "Edit",
            ActiveCondition: null,
            Index: 1,
            OnClick: HandleEditRow,
            // IconSize: "10px",
            // ActiveColor: "red",
            // HoverColor: "green",
            // InactiveColor: "blue",
            Tooltip: {Show: true, Position: "right", Theme: "light"},
        },
    ];

    const TableColumns = [
        {
            field: "DateCreatedIso",
            headerName: "Created",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "Invoice.InvoicePaymentDueDateIso",
            headerName: "Due Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "RequestedExecutionDateIso",
            headerName: "Payment Date",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },

        {
            field: "Amount",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 140,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
            // TotalRow: {
            //     Show: true,
            //     // Rendering: (params) => {
            //     //     return <div>Coucou</div>;
            //     // },
            // },
        },
        {
            field: "Communication",
            //
            headerName: "Communication",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "CreditorName",
            //
            headerName: "Creditor",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "CreditorAccount",
            headerName: "Account",
            headerClass: "ag-CenterHeader",
            width: 200,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "CreditorAccountBIC",
            headerName: "bic",
            headerClass: "ag-CenterHeader",
            width: 200,
            filter: true,
            sort: "asc",
            sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "Invoice.Invoices_File_Path",
            headerName: "Docs",
            headerClass: "ag-CenterHeader",
            width: 90,
            filter: false,
            cellRenderering: PDFRendering,
            cellStyle: PDFCell,
        },
        // {
        //     field: "PontoTransaction.BulkShortId",
        // 	// ref_PontoTransaction
        //     headerName: "Bulk",
        //     headerClass: "ag-CenterHeader",
        //     width: 90,
        //     filter: false,
        //     // Type: "Boolean",
        //     rowGroup: true,
        // 	// showRowGroup : true,
        //     hide: true,
        //     // cellRenderer: "agGroupCellRenderer",
        //     // cellRendererParams: {
        //     //     suppressCount: true,
        //     //     checkbox: false,

        //     //     // suppressDoubleClickExpand: true,
        //     //     // suppressEnterExpand: true,
        //     // },

        // },
    ];

    const TableOptions = {
        // Selection: {
        //     Multiple: true,
        // 	GroupOnly : true,
        //     // WithCheckBox: true,
        //     OnSelectionChange: HandleRowSelectChange,
        // },
        Resizable: false,
        Sortable: false,
        Filterable: false,
        WrapText: true,
        WrapHeader: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/

    const [TableComponent, setTableComponent] = useState(null);
    const [ShowEditCard, setShowEditCard] = useState(false);
    const [EditCardId, setEditCardId] = useState(null);
    const [SignUrl, setSignUrl] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                Data={Ref_TableData.current}
                // URLAbsolute={URLAbsolute}
                TableColumns={TableColumns}
                // MainDataSource="PontoInvoiceTransactions"
                RowActionButtons={TableRowButtons}
                ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                PaginationOptions={{
                    Pagination: true,
                    PageSize: 50,
                }}
            />
        );

        setTableComponent(FinalTableComponent);
    }, []);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        console.log("TableData", TableData);
        if (TableData && Array.isArray(TableData)) {
            let PontoTransaction = TableData[0].PontoTransaction;
            console.log("PontoTransaction", PontoTransaction);
            let Url = PontoTransaction.PontoSignUrl;
            setSignUrl(Url);

            Ref_TableData.current = TableData;
            GenerateTableComponent();
        }
    }, [TableData, GenerateTableComponent]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        setShowEditCard(false);
        setEditCardId(null);
    };
    const SignPayment = () => {
        window.open(SignUrl);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;

    if (ShowEditCard === true) {
        ModalCard = (
            <CardModal
                CardID="EditInvoiceCard"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseEditCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="800px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={false}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <InvoiceEdit Id={EditCardId} HandleCloseCard={HandleCloseEditCard} />
            </CardModal>
        );
    }
    return (
        <div className={classes.CardWindow}>
            {ModalCard}
            <div className={classes.CardButtons}>
                <Button Width="210px" ClassExtendButton={classes.Button} onClick={SignPayment}>
                    Sign
                </Button>
            </div>
            <div className={classes.CardMain}>
                Summary
                <div className={classes.TableWrapper}>{TableComponent}</div>
            </div>
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;

//
