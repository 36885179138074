import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import useTableContext from "../utils/context/tableContext/useTableContext";
import Button from "@artibulles-cis/react/Button";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

/**
 * Component Description
 */

const TableToolbar = React.memo(
    function TableToolbar(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TableOptions} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** STATE *********************/
        //const [val, setVal] = useState();
        const [DisplayPager, setDisplayPager] = useState(true);

        const {HideShowPager} = useTableContext();

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        //WHEN COMPONENT LOADS
        // useEffect(() => {
        //     if (TableOptions.TopToolbar) {
        //         setTableData(TableOriginalData);
        //     }
        // }, [TableOriginalData]);
        // //WHEN PROPS ARE CHANGING
        // useEffect(() => {
        //     //Execute your code here
        // }, [TableOptions]);
        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        const HandleHideShowPager = () => {
            console.log("HideShowPager");
            setDisplayPager(!DisplayPager);
            HideShowPager(!DisplayPager);
        };

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        return (
            <React.Fragment>
                <Button onClick={HandleHideShowPager}>HideShowPager</Button>
            </React.Fragment>
        );
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableToolbar.defaultProps = {
    MyProp: null,
};

TableToolbar.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableToolbar;
