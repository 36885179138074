/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Table from "../../../../../../../artibulles-cis/Table/Table";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 15px 10px 15px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        width: "100%",
        maxHeight: "300px",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
    },
}));

const CustomerSubsidiariesTable = React.memo(function CustomerSubsidiariesTable(props) {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    const ColomnsInput = [
        {width: "150px", caption: "Id", datafield: "_id", fixedCol: true, searchable: true, sortable: false, datatype: "text"},
        {width: "auto", caption: "Company", datafield: "company", fixedCol: false, searchable: true, datatype: "text"},
        {width: "250px", caption: "status", datafield: "status", searchable: true, datatype: "text"},
        {width: "250px", caption: "updated", datafield: "updatedAt", searchable: true, datatype: "number"},
    ];

    const TableOptions = {
        Searchable: true,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        Selectable: true,
        // RowButtons: {
        //     Standard: {
        //         Edit: {Condition: null, Position: 1, OnClick: HandleEditRow},
        //         View: {Condition: null, Position: 2, OnClick: HandlePreviewRow},
        //         Delete: {Condition: deletecondition, Position: 3, OnClick: null},
        //     },
        // },
    };
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    // const FinalAPIUrlRelative = {
    //     MAIN: `${ProdURL}/api/customers`,
    //     DELETE: `customer/edit`,
    //     PUT: "",
    //     POST: "",
    // };

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companies/company/sub/${Id}`,
        DELETE: `company/edit`,
        PUT: "",
        POST: "",
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
	var FinalComp;
	if (Id){
		FinalComp = <div className={classes.TableWrapper}>
		<Table URLRelative={FinalAPIUrlRelative} ColomnsInput={ColomnsInput} TableOptions={TableOptions} />
	</div>
	}
	return (
	 <React.Fragment>
	 {FinalComp}
	 </React.Fragment>   
    );

    /***************** RENDER ******************/
});

export default CustomerSubsidiariesTable;
