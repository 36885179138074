import React, {useEffect, useState, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {Switch, Route} from "react-router-dom";

import MyCompanyEdit from "./MyCompanyEdit/MyCompanyEdit";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const SettingsRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [Id, setId] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCall, setApiCall] = useState(null);
    const [Loading, setLoading] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (!ApiCall) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        // console.log("Data", Data);
                        if (Data.CompanyId) {
                            setId(Data.CompanyId);
                            setInit(true);
                        } else {
                            setId("new");
                            setInit(true);
                        }
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    useEffect(() => {
        const ApiCall = {url: `${ProdURL}/api/mycompany/default`, type: "get"};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    if (Init === true) {
        return (
            <Switch>
                <Route exact path="/mycompany/settings/details">
                    <MyCompanyEdit Id={Id} />
                </Route>
            </Switch>
        );
    } else {
        return <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />;
    }

    /***************** RENDER ******************/
};

export default SettingsRouter;
