import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {AddCircle} from "@artibulles-cis/react-icons";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "150px",
        margin: "0 auto",
        justifyContent: "center",
    },
    ClassExtendTabContent: {
        padding: "0px 0px",
        boxSizing: "border-box",
        // maxWidth: "1200px",
    },
    ClassExtendButton: {
        fontSize: "0.8rem",
        margin: "0px 0px",
        padding: "0px 5px",
        // justifyContent: "flex-start",
    },
    NoData: {
        minHeight: "50px",
        padding: "5px  0px",
    },
}));

const Component = React.memo(function Component(props) {
    const {CompanyId} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const TableColumns = [
        {
            field: "Contact.LastName",
            headerName: "Last Name",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "asc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            checkboxSelection: true,
        },
        {
            field: "Contact.FirstName",
            headerName: "First Name",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "JobTitle",
            headerName: "JobTitle",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "Email",
            headerName: "Email",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "MobilePhone",
            headerName: "MobilePhone",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
        {
            field: "OfficePhone",
            headerName: "OfficePhone",
            headerClass: "ag-CenterHeader",
            flex: 1,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },

        {
            field: "IsDefault",
            headerName: "Default",
            headerClass: "ag-CenterHeader",
            flex: 1,
            Type: "Boolean",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
    ];
    const HandleRowSelectChange = (SelectedRows) => {
        if (SelectedRows.length === 0) {
            setSelectedRows(null);
        } else {
            let Total = 0;
            SelectedRows.forEach((elem) => {
                Total = Total + elem.banking_amount_transaction;
            });
            setSelectedRows(SelectedRows);
        }
    };
    const TableOptions = {
        Selection: {
            Multiple: true,
            // GroupOnly: false,
            WithCheckBox: true,
            OnSelectionChange: HandleRowSelectChange,
        },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        SupressMenu: true,
        WrapText: true,
        WrapHeader: false,
        HeaderHeight: 30,
        RowHeight: 24,
        RowFontSize: "13px",
        HeaderFontSize: "14px",
        StatusBar: true,
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    const [Init, setInit] = useState(false);
    const [CreateNewOnly, setCreateNewOnly] = useState(false);

    const [APICallType, setAPICallType] = useState(null);
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [SelectedRows, setSelectedRows] = useState(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;

                    if (Init === false) {
                        if (Data.CompanyContacts && Array.isArray(Data.CompanyContacts) && Data.CompanyContacts.length > 0) {
                            Ref_LocaleData.current = Data;
                            setInit(true);
                        } else {
                            //There is no bank account, we display a add button
                            setCreateNewOnly(true);
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "RemoveContacts") {
                        setTableComponent(null);
                        setTimeout(() => {
                            setInit(false);
                        }, 100);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        let FinalTableComponent = (
            <AGGridTable
                TableName="CompanyContacts"
                Data={Ref_LocaleData.current}
                TableColumns={TableColumns}
                MainDataSource="CompanyContacts"
                // RowActionButtons={TableRowButtons}
                // ColumnButtonStyle={ColumnButtonStyle}
                TableOptions={TableOptions}
                // ReloadData={ReloadTableData}
                // SyncReloadDataStateFunction={setReloadTableData}
                // TotalRow={TotalRow}
                // PaginationOptions={{
                //     Pagination: true,
                //     PageSize: 50,
                // }}
            />
        );
        setTableComponent(FinalTableComponent);
    }, [Ref_LocaleData.current, Init]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (Init === false) {
            const ApiCall = {url: `${ProdURL}/api/companies/contacts/?companyid=${CompanyId}&accountingdefault=true`, type: "get"};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true && CreateNewOnly === false) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init]);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleReconcile = () => {
        let DataForm = {
            SelectedContactsIds: null,
            CompanyId: CompanyId,
        };
        let SelectedIds = [];
        SelectedRows.forEach((elem) => {
            SelectedIds.push(elem._id);
        });
        DataForm.SelectedContactsIds = SelectedIds;
        // console.log("DataForm", DataForm);
        const ApiCall = {url: `${ProdURL}/api/companies/contacts/removedefaultaccounting`, type: "post", data: DataForm};
        setLoading(true);
        setAPICallType("RemoveContacts");

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);
            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let SelectionComponent;
    if (SelectedRows) {
        SelectionComponent = (
            <React.Fragment>
                <div className={classes.ButtonBar}>
                    <div className={classes.ButtonBar_Left}>
                        <Button Height="28px" Width="130px" style={{marginTop: "5px"}} ClassExtendButton={classes.ClassExtendButton} onClick={HandleReconcile}>
                            Remove Selected
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        SelectionComponent = <div style={{textAlign: "left", fontSize: "0.85rem", marginTop: "5px"}}>Unselect the Default Contacts</div>;
    }
    let FinalTableComponent;
    if (Init === true) {
        if (TableComponent) {
            FinalTableComponent = (
                <React.Fragment>
                    {SelectionComponent}
                    <div className={classes.TableWrapper}>{TableComponent}</div>
                </React.Fragment>
            );
        } else {
            FinalTableComponent = <div className={classes.NoData}>There are no contacts</div>;
        }
    }

    return (
        <TabNavContent ClassExtendTabContent={classes.ClassExtendTabContent}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />

            {FinalTableComponent}
        </TabNavContent>
    );
    /***************** RENDER ******************/
});

export default Component;
