import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {v4 as uuidv4} from "uuid";
import TableSearchInput from "../TableSearchInputNew/TableSearchInput";
import useTableContext from "../utils/context/tableContext/useTableContext";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FooterWrapper: {
        position: "sticky",
        display: "table",
        width: "100%",
        bottom: "0px",
        // height: "40px",
        // width: "1200px",
        background: "blue",
        zIndex: "10",
    },
    Header_Row: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            height: "100%",
            boxSizing: "border-box",
            // overflow : "hidden",
            width: "100%",
            display: "flex",
            whiteSpace: "nowrap",
            background: props.MainTableStyle.Header.HeaderRowBackground ? props.MainTableStyle.Header.HeaderRowBackground : FinalTheme.Header.HeaderRowBackground,

            "&:first-child $Header_Cell": {
                borderTop: "none",
            },
            "&:last-child $Header_Cell": {
                borderBottom: props.MainTableStyle.Header.HeaderBottomBorder ? props.MainTableStyle.Header.HeaderBottomBorder : FinalTheme.CommonStyle.HeaderBottomBorder,
            },
        };
    },
    Header_Row_Search: {},
    Header_Cell: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            whiteSpace: "nowrap",
            padding: "0 8px",
            fontSize: props.MainTableStyle.Header.fontSize ? props.MainTableStyle.Header.fontSize : FinalTheme.Header.fontSize,
            color: props.MainTableStyle.Header.color ? props.MainTableStyle.Header.color : FinalTheme.Header.color,
            fontFamily: props.MainTableStyle.Header.fontFamily ? props.MainTableStyle.Header.fontFamily : FinalTheme.Header.fontFamily,
            fontWeight: props.MainTableStyle.Header.fontWeight ? props.MainTableStyle.Header.fontWeight : FinalTheme.Header.fontWeight,
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
            borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
        };
    },

    Header_Cell_Text: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        whiteSpace: "normal",
        wordWrap: "normal",
    },

    Header_Cell_Search: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            position: "relative",
            whiteSpace: "nowrap",
            padding: "0 8px",
            height: "100%",
            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
            borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
            borderTop: props.MandatorySyncStyle.HeaderInnerBorderRow ? props.MandatorySyncStyle.HeaderInnerBorderRow : FinalTheme.CommonStyle.HeaderInnerBorderRow,
        };
    },
}));

/**
 * Component Description
 */

const TableSearch = React.memo(
    function TableSearch(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TableOptions} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        // const ref = useRef(null)
        /****************************** REFS *********************/

        /****************************** STATE *********************/
        const {TableColsInputs, TableRowsInputs, SetSearchRows, ActionButtonColWidth} = useTableContext();
        const [SearchRowComponent, setSearchRowComponent] = useState(null);

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/
        const GenerateSearchRowComponent = useCallback(() => {
            let HeaderRowComps = [];
            let SearchHeaderCellComps = [];

            //ADDING A FAKE SELECTABLE TO KEEP THE COLS ALIGNED IF REQUIRED
            if (TableOptions.Selectable === true) {
                let SelectHeaderElem = (
                    <div key={uuidv4()} className={classes.Header_Cell} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px", padding: "0px"}}>
                        <div className={clsx(classes.Header_Cell_Text, classes.Header_Cell_Search)} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}></div>
                    </div>
                );
                SearchHeaderCellComps.push(SelectHeaderElem);
            }
            //ADDING A FAKE SELECTABLE TO KEEP THE COLS ALIGNED IF REQUIRED

            /************************* FAKE ACTION BUTTON COL ******************************/
            if (ActionButtonColWidth) {
                let FakeActionColElem = (
                    <div
                        key={uuidv4()}
                        className={clsx(classes.Header_Cell)}
                        style={{flex: `0 0 ${ActionButtonColWidth}`, width: ActionButtonColWidth, minWidth: ActionButtonColWidth, maxWidth: ActionButtonColWidth, padding: "0px"}}
                    >
                        <div
                            className={clsx(classes.Header_Cell_Text, classes.Header_Cell_Search)}
                            style={{flex: `0 0 ${ActionButtonColWidth}`, width: ActionButtonColWidth, minWidth: ActionButtonColWidth, maxWidth: ActionButtonColWidth}}
                        ></div>
                    </div>
                );
                SearchHeaderCellComps.push(FakeActionColElem);
            }

            /************************* FAKE ACTION BUTTON COL ******************************/

            for (let i = 0; i < TableColsInputs.length; i++) {
                let ColOptions = TableColsInputs[i];

                let HeaderCell, FlexWidth, MinWidth, MaxWidth, Width;
                if (ColOptions.width) {
                    if (ColOptions.width === "auto") {
                        if (ColOptions.minWidth) {
                            FlexWidth = `1 1 ${ColOptions.minWidth}`;
                            MinWidth = ColOptions.minWidth;
                        } else {
                            FlexWidth = "1 1 auto";
                        }
                    } else {
                        FlexWidth = `0 0 ${ColOptions.width}`;
                        MinWidth = ColOptions.width;
                        MaxWidth = ColOptions.width;
                        Width = ColOptions.width;
                    }
                } else {
                }
                /*Col Width*/
                let SearchHeaderCell;
                if (ColOptions.isSearchable === true) {
                    if (ColOptions.isSearched === true) {
                        SearchHeaderCell = (
                            <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Search)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <TableSearchInput
                                    Column={ColOptions.datafield}
                                    WithClearIcon={true}
                                    WithFilters={TableOptions.SearchOptions.WithFilterMenu}
                                    InitialFilter={{value: ColOptions.searchValue, type: ColOptions.searchFilterType}}
                                    DataType={ColOptions.dataType}
                                    SearchBehavior={{SearchOnClick: TableOptions.SearchOptions.OnClickSearch_Only, MinCharacters: TableOptions.SearchOptions.MinCharSearch}}
                                    SearchValue={(filter) => {
                                        HandleSearch(filter);
                                    }}
                                />
                            </div>
                        );
                    } else {
                        SearchHeaderCell = (
                            <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Search)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                                <TableSearchInput
                                    Column={ColOptions.datafield}
                                    WithClearIcon={true}
                                    WithFilters={TableOptions.SearchOptions.WithFilterMenu}
                                    InitialFilter={null}
                                    DataType={ColOptions.dataType}
                                    SearchBehavior={{SearchOnClick: TableOptions.SearchOptions.OnClickSearch_Only, MinCharacters: TableOptions.SearchOptions.MinCharSearch}}
                                    SearchValue={(filter) => {
                                        HandleSearch(filter);
                                    }}
                                />
                            </div>
                        );
                    }
                } else {
                    SearchHeaderCell = (
                        <div key={uuidv4()} className={clsx(classes.Header_Cell, classes.Header_Cell_Search)} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth}}>
                            <TableSearchInput
                                IsFake={true}
                                // Column={elem.datafield}
                                // WithClearIcon={true}
                                // WithFilters={TableOptionsFinal.SearchOptions.WithFilterMenu}
                                // InitialFilter={InitalFilter}
                                // DataType={elem.datatype}
                                // SearchBehavior={{SearchOnClick: TableOptionsFinal.SearchOptions.OnClickSearch_Only, MinCharacters: TableOptionsFinal.SearchOptions.MinCharSearch}}
                                // SearchValue={(filter) => {
                                //     HandleSearch(filter);
                                // }}
                            />
                        </div>
                    );
                }
                SearchHeaderCellComps.push(SearchHeaderCell);
            }

            // console.log("SearchHeaderCellComps", SearchHeaderCellComps);
            let SearchHeaderRow = <div className={clsx(classes.Header_Row, classes.Header_Row_Search)}>{SearchHeaderCellComps}</div>;

            HeaderRowComps.push(SearchHeaderRow);

            setSearchRowComponent(HeaderRowComps);
        }, [TableRowsInputs, TableColsInputs]);

        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        useEffect(() => {
            GenerateSearchRowComponent();
        }, [TableColsInputs, TableRowsInputs, GenerateSearchRowComponent]);
        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        const HandleSearch = (filter) => {
            console.log("TableSearch Filter", filter);
            SetSearchRows(filter);
        };

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        return <React.Fragment>{SearchRowComponent}</React.Fragment>;
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableSearch.defaultProps = {
    MyProp: null,
};

TableSearch.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableSearch;
