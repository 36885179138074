import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "@artibulles-cis/react/CardModal";
import FormCompontent from "../../../../../../../../artibulles-cis/FormComponent/FormCompontent";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import InvoiceReconcileTabNav from "./InvoiceReconcileTabNav";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        // width: "500px",
        alignSelf: "center",
        justifyContent: "center",
    },
    Button: {
        margin: "0px 5px",
        padding: "0px 5px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "flex-start",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
    },
    Title: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-start",
        padding: "0px 5px",
    },
    FormWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        // border: "1px solid grey",
        padding: "0px 0px",
        color: "black",
        textAlign: "left",
        margin: "0px 0px 20px 0px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    const {InvoiceId, PassedFormValues, HandleCloseInvoiceReconcilerCard, SupplierOptions, PaymentMethodOptions, FieldOptionsFromParent} = props;
    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
   
    const [Loading, setLoading] = useState(false);
    const [FormInitReady, setFormInitReady] = useState(false);
    const [FormValues, setFormValues] = useState({});
    const [InitialFormValues, setInitialFormValues] = useState({});
    const [FieldOptions, setFieldOptions] = useState();
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (PassedFormValues) {
            // console.log("PassedFormValues", PassedFormValues);

            let RequestedExecutionDateIso;
            if (PassedFormValues.PontoDetails) {
                let PontoTransaction = PassedFormValues.PontoDetails.PontoTransaction;
                if (PontoTransaction) {
                    RequestedExecutionDateIso = PontoTransaction.RequestedExecutionDateIso;
                }
            }

            let FinalOptions = {
                ref_Supplier: SupplierOptions,
                PaymentMethod: PaymentMethodOptions,
                CounterpartyBankAccount_Id: FieldOptionsFromParent.CounterpartyBankAccount_Id,
                CreditCard_Id: FieldOptionsFromParent.CreditCard_Id,
            };
            setFieldOptions(FinalOptions);

            setInitialFormValues({
                ref_Supplier: PassedFormValues.ref_Supplier,
                InvoiceSupplierDateIso: PassedFormValues.InvoiceSupplierDateIso,
                InvoicePaymentDueDateIso: PassedFormValues.InvoicePaymentDueDateIso,
                InvoicePaymentExpectedCommunication: PassedFormValues.InvoicePaymentExpectedCommunication,
                PaymentMethod: PassedFormValues.PaymentMethod,
                CalcTotalVATIncluded: PassedFormValues.CalcTotalVATIncluded,
                CounterpartyBankAccount_Id: PassedFormValues.CounterpartyBankAccount_Id,
                CreditCard_Id: PassedFormValues.CreditCard_Id,
                RequestedExecutionDateIso: RequestedExecutionDateIso,
            });
            setFormValues({
                ref_Supplier: PassedFormValues.ref_Supplier,
                InvoiceSupplierDateIso: PassedFormValues.InvoiceSupplierDateIso,
                InvoicePaymentDueDateIso: PassedFormValues.InvoicePaymentDueDateIso,
                InvoicePaymentExpectedCommunication: PassedFormValues.InvoicePaymentExpectedCommunication,
                PaymentMethod: PassedFormValues.PaymentMethod,
                CalcTotalVATIncluded: PassedFormValues.CalcTotalVATIncluded,
                CounterpartyBankAccount_Id: PassedFormValues.CounterpartyBankAccount_Id,
                CreditCard_Id: PassedFormValues.CreditCard_Id,
                RequestedExecutionDateIso: RequestedExecutionDateIso,
            });
            setFormInitReady(true);
        }
    }, []);
    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        // setShowCard(false);
        HandleCloseInvoiceReconcilerCard();
    };

    
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        setFormValues(UpdatedFormValues);
    };
    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;

        var UpdateFormValues = {[field]: value};

        setFormValues({...FormValues, ...UpdateFormValues});
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let TopBarContent = (
        <div className={classes.Title}>
            <div style={{flex: "0 1 auto"}}>{PassedFormValues.InvoiceShortId}</div>
            <div style={{flex: "1 1 auto", justifyContent: "center"}}>Reconcile Purchase Invoice</div>
        </div>
    );
    let FormComp;
    if (FormInitReady === true) {
        FormComp = (
            <div className={classes.FormWrapper}>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 600px"}}>
                        <FormCompontent
                            Name="ref_Supplier"
                            InitialValue={InitialFormValues.ref_Supplier ? InitialFormValues.ref_Supplier : null}
                            FormValue={FormValues.ref_Supplier ? FormValues.ref_Supplier : null}
                            OutputSelectedValues={(values) => handleSelectChange("ref_Supplier", values)}
                            SelectOptions={FieldOptions.ref_Supplier}
                            Component="Select"
                            Variant="Outlined"
                            Label="Supplier"
                            // meta={{error: FormErrors.ref_Supplier, invalid: FormErrors.ref_Supplier ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_Supplier", error)}
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortIcons={false}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            // Required={true}
                            // Disabled={true}
                            ReadOnly={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="InvoiceSupplierDateIso"
                            InitialValue={InitialFormValues.InvoiceSupplierDateIso ? InitialFormValues.InvoiceSupplierDateIso : null}
                            FormValue={FormValues.InvoiceSupplierDateIso ? FormValues.InvoiceSupplierDateIso : null}
                            OutputValue={(values) => HandleUpdateFormValues("InvoiceSupplierDateIso", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Invoice Date"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="100px"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="90px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="InvoicePaymentDueDateIso"
                            InitialValue={InitialFormValues.InvoicePaymentDueDateIso ? InitialFormValues.InvoicePaymentDueDateIso : null}
                            FormValue={FormValues.InvoicePaymentDueDateIso ? FormValues.InvoicePaymentDueDateIso : null}
                            OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentDueDateIso", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Invoice Due Date"
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            FieldMaxWidth="100px"
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            // FieldMaxWidth="350px"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="120px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>

                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                        <FormCompontent
                            Name="PaymentMethod"
                            InitialValue={InitialFormValues.PaymentMethod ? InitialFormValues.PaymentMethod : null}
                            FormValue={FormValues.PaymentMethod ? FormValues.PaymentMethod : null}
                            OutputSelectedValues={(values) => handleSelectChange("PaymentMethod", values)}
                            SelectOptions={FieldOptions.PaymentMethod}
                            Component="Select"
                            Variant="Outlined"
                            Label="Payment Method"
                            Clearable={false}
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortField="value"
                            FieldMaxWidth="250px"
                            // SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            // Required={true}
                            ReadOnly={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 350px", display: `${InitialFormValues.CounterpartyBankAccount_Id ? "flex" : "none"}`}}>
                        <FormCompontent
                            Name="CounterpartyBankAccount_Id"
                            InitialValue={InitialFormValues.CounterpartyBankAccount_Id ? InitialFormValues.CounterpartyBankAccount_Id : null}
                            FormValue={FormValues.CounterpartyBankAccount_Id ? FormValues.CounterpartyBankAccount_Id : null}
                            OutputSelectedValues={(values) => handleSelectChange("CounterpartyBankAccount_Id", values)}
                            SelectOptions={FieldOptions.CounterpartyBankAccount_Id}
                            DisplayMessageIn="Popup"
                            Component="Select"
                            Variant="Outlined"
                            Label="Bank Account"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortField="value"
                            Clearable={false}
                            // SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="120px"
                            LabelAlign="Left"
                            // Required={true}
                            ReadOnly={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 350px", display: `${InitialFormValues.CreditCard_Id ? "flex" : "none"}`}}>
                        <FormCompontent
                            Name="CreditCard_Id"
                            InitialValue={InitialFormValues.CreditCard_Id ? InitialFormValues.CreditCard_Id : null}
                            FormValue={FormValues.CreditCard_Id ? FormValues.CreditCard_Id : null}
                            OutputSelectedValues={(values) => handleSelectChange("CreditCard_Id", values)}
                            SelectOptions={FieldOptions.CreditCard_Id}
                            DisplayMessageIn="Popup"
                            Component="Select"
                            Variant="Outlined"
                            Label="Credit Card"
                            NoMessage={false}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            SortField="value"
                            Clearable={false}
                            // SortIcons={true}
                            AllowCreateOption={false}
                            // CreateDeleteIfUnselected={}
                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                            LabelMaxWidth="120px"
                            LabelAlign="Left"
                            // Required={true}
                            ReadOnly={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 350px", margin: "0px 0px 0px 50px"}}>
                        <FormCompontent
                            Name="InvoicePaymentExpectedCommunication"
                            InitialValue={InitialFormValues.InvoicePaymentExpectedCommunication ? InitialFormValues.InvoicePaymentExpectedCommunication : null}
                            FormValue={FormValues.InvoicePaymentExpectedCommunication ? FormValues.InvoicePaymentExpectedCommunication : null}
                            OutputValue={(values) => HandleUpdateFormValues("InvoicePaymentExpectedCommunication", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Communication"
                            NoMessage={false}
                            DisplayMessageIn="Popup"
                            FieldMaxWidth="250px"
                            // Required={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                    <div className={classes.FormField} style={{flex: "0 0 400px"}}>
                        <FormCompontent
                            Name="CalcTotalVATIncluded"
                            InitialValue={InitialFormValues.CalcTotalVATIncluded ? InitialFormValues.CalcTotalVATIncluded : null}
                            FormValue={FormValues.CalcTotalVATIncluded ? FormValues.CalcTotalVATIncluded : null}
                            OutputValue={(values) => HandleUpdateFormValues("CalcTotalVATIncluded", values)}
                            Component="Input"
                            Variant="Outlined"
                            Label="Total VAT Inc"
                            NoMessage={false}
                            ReadOnly={true}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="120px"
                            LabelMaxWidth="130px"
                            LabelAlign="Left"
                            Imask={true}
                            Mask={{
                                Type: "Currency",
                                Options: {
                                    Currency: "EURO",
                                    // SymbolPosition: "Post",
                                    Scale: 2,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                        <FormCompontent
                            Name="RequestedExecutionDateIso"
                            InitialValue={InitialFormValues.RequestedExecutionDateIso ? InitialFormValues.RequestedExecutionDateIso : null}
                            FormValue={FormValues.RequestedExecutionDateIso ? FormValues.RequestedExecutionDateIso : null}
                            OutputValue={(values) => HandleUpdateFormValues("RequestedExecutionDateIso", values)}
                            Component="DatePicker"
                            Variant="Outlined"
                            Label="Expected Payment Date"
                            NoMessage={false}
                            // LegendOffsetHeight="0"
                            // LegendOffsetWidth="0"
                            FieldMaxWidth="100px"
                            PickerDateType="Calendar"
                            PickerOnly={false}
                            InputFormat="dd/MM/yyyy"
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            ReadOnly={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <CardModal
            CardID="ReviewPayment"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={TopBarContent}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={HandleCloseCard}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            CardMaxWidth="1500px"
            // CardHeight="700px"
            CardWindowPaddingVertical="10px"
            // CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={true}
            // CardWindowPaddingVertical="50px"

            ShowCard={true}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
        >
            <div className={classes.CardWindow}>
                <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                <div className={classes.CardMain}>
                    {FormComp}
                    <InvoiceReconcileTabNav InvoiceId={InvoiceId} HandleTransactionChangedParent={HandleCloseInvoiceReconcilerCard} FormValues={PassedFormValues} />
                </div>
            </div>
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default Component;
