import React, {useState, useEffect, useRef, useCallback} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import {Preview, EditBox, Trash} from "@artibulles-cis/react-icons";
import useTableContext from "../utils/context/tableContext/useTableContext";
import ReactTooltip from "react-tooltip";
import CheckBox from "../CheckBox/CheckBox";
import {v4 as uuidv4} from "uuid";

import TableMessages from "../TableMessages/TableMessages";

import _ from "lodash";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableData: {
        position: "relative",
        display: "table",
        overflow: "hidden",
        // height: "2400px",
        // height : "100%",
        // width: "1200px",
        background: "orange",
        flex: "1 1 auto",
        width: "100%",
    },
    Grid_Body: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            flex: "1 1 auto",
            position: "relative",
            boxSizing: "border-box",
            display: "flex",
            height: "auto",
            overflow: "hidden",
            marginTop: props.MandatorySyncStyle.BodyMarginTop,
            marginBottom: props.MandatorySyncStyle.BodyMarginBottom,
        };
    },
    Data_Scroll_Wrapper: {
        flex: "1 1 auto",
        position: "relative",
        height: "auto",
        minWidth: "0",
        minHeight: "0",
    },
    Data_Scroll_Container: {
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "auto",
    },
    Data_Scroll_Content: {
        display: "table",
        minWidth: "100%",
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
    },

    Grid_Row: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            width: "100%",
            display: "flex",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            // overflow: "hidden",
            "&:nth-child(even)": {
                background: props.MainTableStyle.Body.PrimaryRowBackground ? props.MainTableStyle.Body.PrimaryRowBackground : FinalTheme.Body.PrimaryRowBackground,
            },

            "&:nth-child(odd)": {
                background: props.MainTableStyle.Body.AlternateRowBackground ? props.MainTableStyle.Body.AlternateRowBackground : FinalTheme.Body.AlternateRowBackground,
            },

            "&:last-child $Grid_Cell": {
                borderBottom: props.MandatorySyncStyle.RowBorder ? props.MandatorySyncStyle.RowBorder : FinalTheme.CommonStyle.RowBorder,
            },
            "&:first-child $Grid_Cell": {
                borderTop: props.MandatorySyncStyle.BodyTopBottomBorder ? props.MandatorySyncStyle.BodyTopBottomBorder : FinalTheme.CommonStyle.BodyTopBottomBorder,
            },
        };
    },
    Grid_Cell: (props) => {
        var FinalTheme;
        if (props.TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        return {
            position: "relative",
            display: "flex",
            boxSizing: "border-box",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            paddingTop: props.MandatorySyncStyle.CellVerticalPadding ? props.MandatorySyncStyle.CellVerticalPadding : FinalTheme.CommonStyle.CellVerticalPadding,
            paddingBottom: props.MandatorySyncStyle.CellVerticalPadding ? props.MandatorySyncStyle.CellVerticalPadding : FinalTheme.CommonStyle.CellVerticalPadding,
            paddingLeft: props.MandatorySyncStyle.CellHorizontalPadding ? props.MandatorySyncStyle.CellHorizontalPadding : FinalTheme.CommonStyle.CellHorizontalPadding,
            paddingRight: props.MandatorySyncStyle.CellHorizontalPadding ? props.MandatorySyncStyle.CellHorizontalPadding : FinalTheme.CommonStyle.CellHorizontalPadding,
            fontSize: props.MainTableStyle.Body.fontSize ? props.MainTableStyle.Body.fontSize : FinalTheme.Body.fontSize,
            color: props.MainTableStyle.Body.color ? props.MainTableStyle.Body.color : FinalTheme.Body.color,
            fontFamily: props.MainTableStyle.Body.fontFamily ? props.MainTableStyle.Body.fontFamily : FinalTheme.Body.fontFamily,
            fontWeight: props.MainTableStyle.Body.fontWeight ? props.MainTableStyle.Body.fontWeight : FinalTheme.Body.fontWeight,
            borderTop: props.MandatorySyncStyle.RowBorder ? props.MandatorySyncStyle.RowBorder : FinalTheme.CommonStyle.RowBorder,
            borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,

            "&:first-child": {
                borderLeft: "none",
            },
            "&:last-child": {
                borderRight: props.MandatorySyncStyle.ColBorder ? props.MandatorySyncStyle.ColBorder : FinalTheme.CommonStyle.ColBorder,
            },
        };
    },
    Cell_Content: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    Cell_Content_Fake_Button: {
        display: "flex",
        alignItems: "center",
    },
    RowButton: {
        display: "flex",
        padding: "0px 5px",
        boxSizing: "border-box",
        alignItems: "center",
        cursor: "pointer",
    },
    RowButtonInactive: {
        display: "flex",
        padding: "0px 5px",
        boxSizing: "border-box",
        alignItems: "center",
        cursor: "not-allowed",
    },
}));

/**
 * Component Description
 */

const TableBody = React.memo(
    function TableBody(props, ref) {
        /****************************** PROPS AND JSS CLASSES *********************/
        const {TableOptions} = props;

        const theme = useTheme();
        const classes = styles({...props, theme});
        /****************************** PROPS AND JSS CLASSES *********************/

        /****************************** REFS *********************/
        const Ref_FakeButtonCalc = useRef(null);
        const Ref_ButtonColWidth = useRef(null);
        /****************************** REFS *********************/

        /****************************** CONST *********************/
        let FinalTheme;
        if (TableOptions.Theme === "Dark") {
            FinalTheme = theme.Table.Dark;
        } else {
            FinalTheme = theme.Table.Light;
        }
        let DefaultRowActionButtonStyle = {
            IconSize: FinalTheme.RowButtonIconStyle.IconSize,
            ActiveColor: FinalTheme.RowButtonIconStyle.Color,
            HoverColor: FinalTheme.RowButtonIconStyle.HoverColor,
            DisabledColor: FinalTheme.RowButtonIconStyle.DisabledColor,
        };

        /****************************** CONST *********************/

        /****************************** STATE *********************/

        const {TableOriginalData, TableRowsInputs, TableColsInputs, SetSelectedRows, SetActionButtonColWidth, setGenerateHeader, setTableAction} = useTableContext();

        const [IsInitialized, setIsInitialized] = useState(false);
        const [BodyRowsComponent, setBodyRowsComponent] = useState(null);
        const [CalcButton, setCalcButton] = useState(false);
        const [ShowModalMessage, setShowModalMessage] = useState(false);
        const [ModalMessage, setModalMessage] = useState(null);

        /****************************** STATE *********************/

        /****************************** CALLBACK FUNCTIONS *********************/

        const GenerateFakeButtonRows = useCallback(() => {
            let TotalRowActionButtons = TableOptions.RowButtons.TotalButtons;

            /************************* ACTION BUTTON COLUMN ELEMENTS ******************************/
            //FOR EACH ROW WE GENERATE THE BUTTONS DEPENDING ON THE ROW DETAILS (CONDITIONS)
            let FakeButtonCol = [];

            if (TotalRowActionButtons > 0) {
                let ActionButtons = [];

                let RowActionButtons = TableOptions.RowButtons.Buttons;
                RowActionButtons.forEach((RowActionButton) => {
                    let IconButton, ButtonWrapper;

                    if (RowActionButton.Standard === true) {
                        let IconIcon;
                        if (RowActionButton.Name === "Delete") {
                            IconIcon = <Trash />;
                        }
                        if (RowActionButton.Name === "View") {
                            IconIcon = <Preview />;
                        }
                        if (RowActionButton.Name === "Edit") {
                            IconIcon = <EditBox />;
                        }

                        IconButton = React.cloneElement(IconIcon, {
                            IconSize: DefaultRowActionButtonStyle.IconSize,
                            SVGFillColor: DefaultRowActionButtonStyle.ActiveColor,
                            SVGFillHoverColor: DefaultRowActionButtonStyle.HoverColor,
                        });
                    } else {
                        IconButton = RowActionButton.IconActive;
                    }

                    if (IconButton) {
                        ButtonWrapper = <div className={classes.RowButton}>{IconButton}</div>;
                        ActionButtons.push(ButtonWrapper);
                    }
                });

                let ColButtons = (
                    <div ref={Ref_FakeButtonCalc} key={uuidv4()} className={classes.Grid_Cell} style={{flex: `0 1 auto`}}>
                        <div className={classes.Cell_Content_Fake_Button}>{ActionButtons}</div>
                    </div>
                );

                FakeButtonCol.push(ColButtons);
            }
            let RowElem = (
                <div key={uuidv4()} className={classes.Grid_Row}>
                    {FakeButtonCol}
                </div>
            );

            setBodyRowsComponent(RowElem);
            setCalcButton(true);

            /************************* ACTION BUTTON COLUMN ELEMENTS ******************************/
        }, [TableOptions]);

        const CalculateButtonColWidth = useCallback(() => {
            if (Ref_FakeButtonCalc && Ref_FakeButtonCalc.current) {
                let FakeColElemWidth = Ref_FakeButtonCalc.current.getBoundingClientRect().width;

                Ref_ButtonColWidth.current = `${FakeColElemWidth}px`;
                setIsInitialized(true);
            }
        }, [Ref_FakeButtonCalc]);

        const GenerateBodyRowsComponent = useCallback(() => {
            // console.log("Generate body rows components");
            var RowComps = [];

            let TotalRowActionButtons = TableOptions.RowButtons.TotalButtons;

            for (let i = 0; i < TableRowsInputs.length; i++) {
                let ColComps = [];
                //We look at each row and grab the options
                let RowOptions = TableRowsInputs[i];
                let RowData = RowOptions.rowData;

                if (RowOptions.isVisible === true) {
                    /************************* SELECT ELEMENTS ******************************/
                    if (TableOptions.Selectable === true) {
                        let RowId = RowOptions.rowId;
                        let IsSelected = RowOptions.isSelected;

                        let SelectElem = (
                            <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: "0 0 40px", width: "40px", minWidth: "40px", maxWidth: "40px"}}>
                                <div className={classes.Cell_Content}>
                                    <CheckBox curvalue={IsSelected} GetValue={(value) => HandleSelectRow(RowId, value)} IconSize="15px" CirclePadding="5px" name="test" />
                                </div>
                            </div>
                        );
                        ColComps.push(SelectElem);
                    }
                    /************************* SELECT ELEMENTS ******************************/

                    /************************* ACTION BUTTON COLUMN ELEMENTS ******************************/
                    //FOR EACH ROW WE GENERATE THE BUTTONS DEPENDING ON THE ROW DETAILS (CONDITIONS)

                    if (TotalRowActionButtons > 0) {
                        let ActionButtons = [];

                        let RowActionButtons = TableOptions.RowButtons.Buttons;
                        RowActionButtons.forEach((RowActionButton) => {
                            let IconButton, ButtonWrapper;
                            let IconIcon;
                            let IsActiveButton = true;
                            if (RowActionButton.Standard === true) {
                                //Standard buttons

                                if (RowActionButton.Name === "Delete") {
                                    IconIcon = <Trash />;
                                }
                                if (RowActionButton.Name === "View") {
                                    IconIcon = <Preview />;
                                }
                                if (RowActionButton.Name === "Edit") {
                                    IconIcon = <EditBox />;
                                }
                                if (RowActionButton.ActiveCondition) {
                                    let ActiveButton = RowActionButton.ActiveCondition(RowData);
                                    if (ActiveButton === true) {
                                        IconButton = React.cloneElement(IconIcon, {
                                            IconSize: DefaultRowActionButtonStyle.IconSize,
                                            SVGFillColor: DefaultRowActionButtonStyle.ActiveColor,
                                            SVGFillHoverColor: DefaultRowActionButtonStyle.HoverColor,
                                        });
                                    } else {
                                        IsActiveButton = false;
                                        IconButton = React.cloneElement(IconIcon, {
                                            IconSize: DefaultRowActionButtonStyle.IconSize,
                                            SVGFillColor: DefaultRowActionButtonStyle.DisabledColor,
                                            SVGFillHoverColor: DefaultRowActionButtonStyle.DisabledColor,
                                        });
                                    }
                                } else {
                                    IconButton = React.cloneElement(IconIcon, {
                                        IconSize: DefaultRowActionButtonStyle.IconSize,
                                        SVGFillColor: DefaultRowActionButtonStyle.ActiveColor,
                                        SVGFillHoverColor: DefaultRowActionButtonStyle.HoverColor,
                                    });
                                }
                            } else {
                                //Custom Row Buttons
                                if (RowActionButton.ActiveCondition) {
                                    let ActiveButton = RowActionButton.ActiveCondition(RowData);

                                    if (ActiveButton === true) {
                                        IconButton = RowActionButton.IconActive;
                                    } else {
                                        IsActiveButton = false;
                                        IconButton = RowActionButton.IconInactive;
                                    }
                                } else {
                                    IconButton = RowActionButton.IconInactive;
                                }
                            }
                            if (RowActionButton.Name === "Delete") {
                                if (RowActionButton.OnClick) {
                                    //Custom delete function with message to prevent mistakes
                                    if (RowActionButton.Tooltip.Show === true) {
                                        ButtonWrapper = (
                                            <div className={classes.RowButton} onClick={() => HandleDeleteRowMessageOnly(RowData, RowActionButton.OnClick)}>
                                                {IconButton}
                                            </div>
                                        );
                                    } else {
                                        ButtonWrapper = (
                                            <div className={classes.RowButton} onClick={() => HandleDeleteRowMessageOnly(RowData, RowActionButton.OnClick)}>
                                                {IconButton}
                                            </div>
                                        );
                                    }
                                } else {
                                    //Standard delete function
                                    if (RowActionButton.Tooltip.Show === true) {
                                        ButtonWrapper = (
                                            <div className={classes.RowButton} onClick={() => HandleDeleteRow(RowData)}>
                                                {IconButton}
                                            </div>
                                        );
                                    } else {
                                        ButtonWrapper = (
                                            <div className={classes.RowButton} onClick={() => HandleDeleteRow(RowData)}>
                                                {IconButton}
                                            </div>
                                        );
                                    }
                                }
                            } else {
                                if (IsActiveButton === false) {
                                    if (RowActionButton.Tooltip.Show === true) {
                                        ButtonWrapper = <div className={classes.RowButtonInactive}>{IconButton}</div>;
                                    } else {
                                        ButtonWrapper = <div className={classes.RowButtonInactive}>{IconButton}</div>;
                                    }
                                } else {
                                    let Tooltip = RowActionButton.Tooltip;

                                    let ButtonID = uuidv4();
                                    if (RowActionButton.Tooltip.Show === true) {
                                        ButtonWrapper = (
                                            <React.Fragment>
                                                <div className={classes.RowButton} onClick={() => RowActionButton.OnClick(RowData)} data-tip data-for={ButtonID}>
                                                    {IconButton}
                                                </div>
                                                <ReactTooltip id={ButtonID} place={RowActionButton.Tooltip.Position} type={RowActionButton.Tooltip.Theme} effect="solid">
                                                    <p className={classes.tooltipP}>{RowActionButton.Tooltip.Content}</p>
                                                </ReactTooltip>
                                            </React.Fragment>
                                        );
                                    } else {
                                        ButtonWrapper = (
                                            <div className={classes.RowButton} onClick={() => RowActionButton.OnClick(RowData)}>
                                                {IconButton}
                                            </div>
                                        );
                                    }
                                }
                            }

                            ActionButtons.push(ButtonWrapper);
                        });

                        let ColButtons = (
                            <div
                                key={uuidv4()}
                                className={classes.Grid_Cell}
                                style={{flex: `0 0 ${Ref_ButtonColWidth.current}`, width: Ref_ButtonColWidth.current, minWidth: Ref_ButtonColWidth.current, maxWidth: Ref_ButtonColWidth.current}}
                            >
                                <div className={classes.Cell_Content}>{ActionButtons}</div>
                            </div>
                        );
                        ColComps.push(ColButtons);
                    }

                    /************************* ACTION BUTTON COLUMN ELEMENTS ******************************/

                    /************************* NORMAL CELLS ******************************/

                    for (let j = 0; j < TableColsInputs.length; j++) {
                        let ColOptions = TableColsInputs[j]; //the columns info provided by the user
                        let key = ColOptions.datafield; //we extract the key from the colInputs
                        //We define the width
                        let CellValue, FlexWidth, MinWidth, MaxWidth, Width;

                        if (ColOptions.width) {
                            if (ColOptions.width === "auto") {
                                if (ColOptions.minWidth) {
                                    FlexWidth = `1 1 ${ColOptions.minWidth}`;
                                    MinWidth = ColOptions.minWidth;
                                } else {
                                    FlexWidth = "1 1 auto";
                                }
                            } else {
                                FlexWidth = `0 0 ${ColOptions.width}`;
                                MinWidth = ColOptions.width;
                                MaxWidth = ColOptions.width;
                                Width = ColOptions.width;
                            }
                        } else {
                            //Should we apply a default width ?
                        }

                        if (ColOptions.customRenderFunction) {
                            //CUSTOM RENDERING FUNCTION
                            //We pass both the value and the rowdata

                            CellValue = ColOptions.customRenderFunction(_.get(RowOptions["rowData"], key), RowOptions);
                        } else {
                            if (ColOptions.dataType && ColOptions.dataType === "boolean") {
                                // //BOOLEAN CELL

                                var CheckBoxStyleRow = {
                                    CheckFillColor: FinalTheme.Body.CheckBoxRow.CheckFillColor,
                                    CheckMarkColor: FinalTheme.Body.CheckBoxRow.CheckMarkColor,
                                    UncheckedBorderColor: FinalTheme.Body.CheckBoxRow.UncheckedBorderColor,
                                };
                                if (TableOptions.CheckBoxRow) {
                                    CheckBoxStyleRow.CheckFillColor = TableOptions.CheckBoxRow.CheckFillColor ? TableOptions.CheckBoxRow.CheckFillColor : FinalTheme.Body.CheckBoxRow.CheckFillColor;
                                    CheckBoxStyleRow.CheckMarkColor = TableOptions.CheckBoxRow.CheckMarkColor ? TableOptions.CheckBoxRow.CheckMarkColor : FinalTheme.Body.CheckBoxRow.CheckMarkColor;
                                    CheckBoxStyleRow.UncheckedBorderColor = TableOptions.CheckBoxRow.UncheckedBorderColor
                                        ? TableOptions.CheckBoxRow.UncheckedBorderColor
                                        : FinalTheme.Body.CheckBoxRow.UncheckedBorderColor;
                                }
                                CellValue = (
                                    <CheckBox
                                        IconSize="15px"
                                        CirclePadding="5px"
                                        name="test"
                                        initialvalue={_.get(RowOptions["rowData"], key)}
                                        curvalue={_.get(RowOptions["rowData"], key)}
                                        disabled={true}
                                        CheckFillColor={CheckBoxStyleRow.CheckFillColor}
                                        CheckMarkColor={CheckBoxStyleRow.CheckMarkColor}
                                        UncheckedBorderColor={CheckBoxStyleRow.UncheckedBorderColor}
                                    />
                                );
                            } else {
                                //FORMATTED CELL
                                if (ColOptions.customFormatFunction) {
                                    let CellFormatFunctionApply = ColOptions.customFormatFunction;

                                    if (typeof CellFormatFunctionApply === "function") {
                                        //we pass both the value and the rowdata
                                        CellValue = CellFormatFunctionApply(_.get(RowOptions["rowData"], key), RowOptions);
                                    } else {
                                        CellValue = RowData["rowData"][key];
                                    }
                                } else {
                                    // //LOOKUP CELL
                                    if (ColOptions.isLookup === true) {
                                        //we check that the lookup array exists in the provided data
                                        let LookupList = TableOriginalData[ColOptions.lookup.data];

                                        if (LookupList && Array.isArray(LookupList) && LookupList.length > 0) {
                                            //we search the corresponding field
                                            let lookupvalue = _.find(LookupList, {[ColOptions.lookup.searchField]: _.get(RowOptions["rowData"], key)});
                                            if (lookupvalue) {
                                                CellValue = lookupvalue[ColOptions.lookup.returnField];
                                            } else {
                                                // CellValue = "lookup error";
                                            }
                                        }
                                    } else {
                                        //STANDARD CELLS
                                        CellValue = _.get(RowOptions["rowData"], key);
                                    }
                                }
                            }
                        }

                        //We define the style
                        let FinalStyle;
                        if (ColOptions.style) {
                            FinalStyle = ColOptions.style;
                        }
                        var WrapCell = "nowrap";
                        if (ColOptions.wrapCell === true) {
                            WrapCell = "normal";
                        }
                        let ColElement = (
                            <div key={uuidv4()} className={classes.Grid_Cell} style={{flex: FlexWidth, width: Width, minWidth: MinWidth, maxWidth: MaxWidth, whiteSpace: WrapCell}}>
                                <div className={classes.Cell_Content} style={FinalStyle}>
                                    {CellValue}
                                </div>
                            </div>
                        );
                        ColComps.push(ColElement);
                    }

                    /************************* NORMAL CELLS ******************************/

                    //GENERATING THE ROW
                    let RowElement = (
                        <div key={uuidv4()} className={classes.Grid_Row} style={{display: `${RowData.showRow === false ? "none" : null}`}}>
                            {ColComps}
                        </div>
                    );
                    RowComps.push(RowElement);
                }
            }

            setBodyRowsComponent(RowComps);
            SetActionButtonColWidth(Ref_ButtonColWidth.current);
            setGenerateHeader(true);
        }, [TableRowsInputs]);
        /****************************** CALLBACK FUNCTIONS *********************/

        /****************************** EFFECT *********************/
        useEffect(() => {
            if (IsInitialized === true) {
                if (TableRowsInputs && Array.isArray(TableRowsInputs) && TableRowsInputs.length > 0) {
                    GenerateBodyRowsComponent();
                }
            }
        }, [GenerateBodyRowsComponent, TableRowsInputs, IsInitialized]);

        useEffect(() => {
            let TotalRowActionButtons = TableOptions.RowButtons.TotalButtons;
            if (TotalRowActionButtons === 0) {
                setIsInitialized(true);
            } else {
                if (IsInitialized === false) {
                    GenerateFakeButtonRows();
                }
            }
        }, [GenerateFakeButtonRows, TableOptions, IsInitialized]);

        useEffect(() => {
            if (CalcButton === true) {
                CalculateButtonColWidth();
            }
        }, [CalculateButtonColWidth, Ref_FakeButtonCalc, CalcButton]);

        /****************************** EFFECT *********************/

        /****************************** FUNCTIONS *********************/
        const HandleSelectRow = (RowId, value) => {
            SetSelectedRows(RowId, value);
        };

        const HandleModalMessageClose = (action) => {
            if (action.Action === "Delete") {
                setTableAction({action: "DeleteOne", Id: action.Id});

                setShowModalMessage(false);
                setModalMessage(null);
            } else if (action.Action === "DeleteMessageOnly") {
                setShowModalMessage(false);
                setModalMessage(null);
                action.onClick(action.RowData);
            }
        };
        const HandleCloseCard = () => {
            setShowModalMessage(false);
            setModalMessage(null);
        };

        const HandleDeleteRow = (RowData) => {
            let Id = RowData._id;
            if (Id) {
                // console.log("Id", Id);
            }

            setModalMessage({Action: "Delete", Id: Id});
            setShowModalMessage(true);
        };
        const HandleDeleteRowMessageOnly = (RowData, onClick) => {
            let Id = RowData._id;
            if (Id) {
                // console.log("Id", Id);
            }

            setModalMessage({Action: "DeleteMessageOnly", Id: Id, onClick: onClick, RowData: RowData});
            setShowModalMessage(true);
        };

        /****************************** FUNCTIONS *********************/

        /****************************** RENDER *********************/
        return (
            <div className={classes.TableData}>
                <TableMessages ShowCard={ShowModalMessage} Action={ModalMessage} Output={HandleModalMessageClose} HandleCloseCard={HandleCloseCard} />
                {BodyRowsComponent}
            </div>
        );
        /****************************** RENDER *********************/
    },
    (prevProps, nextProps) => {
        if (prevProps === nextProps) {
            return true;
        }
        return false;
    }
);

TableBody.defaultProps = {
    MyProp: null,
};

TableBody.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default TableBody;
