import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import CardModal from "../../../../../../artibulles-cis/CardModal/CardModal";
import ContactEdit from "./ContactEdit";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
	CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
		flex :"0 0 35px"
    },
}));

const ContactNew = React.memo(function ContactNew(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowCard, setShowCard] = useState(true);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseEditCard = () => {
        setShowCard(false);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <CardModal
            CardID="EditInvoiceCard"
            // ParentCompRef={Ref_TabNavContent}
            TopBarContent={<div>New Contact</div>}
            ClassExtendTopToolBar={classes.CardTitle}
            CloseCard={HandleCloseEditCard}
            CloseOnClickOutside={false}
            WithCloseButton={true}
            // CardMaxWidth="800px"
            // CardHeight="700px"
            CardWindowPaddingVertical="10px"
            CardWindowPaddingHorizontal="10px"
            WithPerfectScrollbar={false}
            // CardWindowPaddingVertical="50px"

            ShowCard={ShowCard}
            WindowBackgroundColor="rgba(0,0,0,0.3)"
            CardBackgroundColor="white"
            CardBackground="white"
            ClassExtendCard={classes.ClassExtendCard}
        >
            <ContactEdit Id="new" HandleCloseCard={HandleCloseEditCard} />
        </CardModal>
    );

    /***************** RENDER ******************/
});

export default ContactNew;
