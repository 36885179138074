/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch} from "react-router-dom";
import {ToastContainer, toast, Slide} from "react-toastify";
import {authserverurl} from "../common/util/APIServerAddresses";
import {useLocation, useHistory} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {useSelector, useDispatch} from "react-redux";
import {LOGIN_USER} from "../auth/authConstants";
import {useCookies} from "react-cookie";

import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";

import AppWrapper from "@artibulles-cis/react/AppWrapper";
import AppMainContainer from "@artibulles-cis/react/AppMainContainer";
import NavHorizontalBar from "@artibulles-cis/react/NavHorizontalBar";
import TopNavBarMobile from "./TopNavBar/TopNavBarMobile";
import AppUpdater from "./AppUpdater";
import Login from "../Pages/Desktop/LoginAndProfile/Login";

import HomePage from "../Pages/Mobile/HomePage";
import TasksRouter from "../Pages/Mobile/Tasks/TasksRouter";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    classExtendMainContainer: {
        // display : "flex",
        // flexDirection : "column"
    },
}));
toast.configure();
const AppMobile = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    /***************** CONST ******************/
    const AppConfig = {
        maxWidth: "2500px",
    };
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const AuthServerAddress = authserverurl(DevMode);

    const Dispatch = useDispatch();
    const Location = useLocation();
    const History = useHistory();

    const IntervalAuthCheckMilliseconds = 1000 * 60 * 60 * 24;

    /***************** CONST ******************/

    /***************** STATE ******************/
    //Extracting Desired Redux State Variables
    const {authenticated} = useSelector((state) => ({
        authenticated: state.auth.authenticated,
    }));

    const [UserIsAuthenticated, setUserIsAuthenticated] = useState(false);
    const [Initialized, setInitialized] = useState(false);
    const [ApiRes, setApiRes] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [cookies, setCookie] = useCookies(null);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = React.useCallback(() => {
        //Making sure the Loading is done and the Results are ready
        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("errors", ApiRes);
                setUserIsAuthenticated(false);
            } else {
                if (ApiRes.data) {
                    let data = ApiRes.data.data;
                    if (data) {
                        setUserIsAuthenticated(true);
                        setInitialized(true);
                        Dispatch({type: LOGIN_USER, payload: data});
                    }
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    //Check If authenticated
    useEffect(() => {
        // console.log("authenticated check")
        if (authenticated === true) {
            // console.log("authenticated")
            //No need to do anything
            setUserIsAuthenticated(true);
        } else {
            if (DevMode === "development") {
                //there are no cookies
                const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post", data: null};
                setLoading(true);
                async function APIInternal() {
                    const res = await APICallExternal(ApiCall);
                    setApiRes(res);
                    setLoading(false);
                }
                APIInternal();
            } else {
                if (Object.keys(cookies).length === 0) {
                    //No cookies at all
                    setUserIsAuthenticated(false);
                    setInitialized(true);
                } else {
                    //We check that the right cookie is there (not any stupid cookie)

                    if (cookies["artibulles-bms_Session_P"]) {
                        //we need to call again the server to get the user information in case the user refreshed the page and the store was lost
                        const ApiCall = {url: `${AuthServerAddress}/api/auth/checklogin`, type: "post", data: null};
                        setLoading(true);
                        async function APIInternal() {
                            const res = await APICallExternal(ApiCall);
                            setApiRes(res);
                            setLoading(false);
                        }
                        APIInternal();
                    } else {
                        setInitialized(true);
                        setUserIsAuthenticated(false);
                    }
                }
            }
        }
    }, [authenticated]);

    //Check browser cookies to confirm that the user is loged in
    useEffect(() => {
        setInterval(() => {
            if (Object.keys(cookies).length === 0) {
                //No cookies at all
                setUserIsAuthenticated(false);
            } else {
                //We check that the right cookie is there (not any stupid cookie)

                if (cookies["artibulles-bms_Session_P"]) {
                    setUserIsAuthenticated(true);
                } else {
                    setUserIsAuthenticated(false);
                    Dispatch({type: SIGN_OUT_USER, payload: null});
                }
            }
        }, IntervalAuthCheckMilliseconds);
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleLoginCallBack = (callBack) => {
        if (callBack.offline === true) {
            console.log("offline");
        } else {
            Dispatch({type: LOGIN_USER, payload: callBack.User});
            History.push("/");
        }
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var FinalComponent;
    if (Initialized === false) {
        FinalComponent = <React.Fragment>Loading...</React.Fragment>;
    } else {
        if (UserIsAuthenticated === true) {
            FinalComponent = (
                <React.Fragment>
                    <NavHorizontalBar Show={true} Height="60px" id="TopFixedNavBar" style={{background: "green"}} MaxWidth={AppConfig.maxWidth}>
                        <TopNavBarMobile />
                    </NavHorizontalBar>

                    <AppMainContainer
                        WithPerfectScrollbar={false}
                        WithScrollBar={false}
                        classExtendMainContainer={classes.classExtendMainContainer}
                        DisableBodyScroll={true}
                        TopRefComponentID="TopFixedNavBar"
                        // BottomRefComponentID="BottomFixedNavBar"
                    >
                        <Switch>
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                            <Route path="/tasks">
                                <TasksRouter />
                            </Route>
                            <Route exact path="/">
                                <HomePage />
                            </Route>
                        </Switch>
                    </AppMainContainer>
                </React.Fragment>
            );
        } else {
            FinalComponent = <Login HandleLoginCallBack={HandleLoginCallBack} />;
        }
    }

    return (
        <AppWrapper AppMaxWidth={AppConfig.maxWidth} style={{height: "100%"}}>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                // style={{minWidth : "500px"}}
                transition={Slide}
                closeOnClick={false}
                rtl={false}
                pauseOnVisibilityChange={false}
                draggable={false}
                pauseOnHover={false}
            />
            {FinalComponent}
            <AppUpdater />
        </AppWrapper>
    );
    /***************** RENDER ******************/
};

export default AppMobile;
