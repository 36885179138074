import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import AccountantUploadHistoryTable from "./SendingHistoryTable";
import CardModal from "@artibulles-cis/react/CardModal";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },
    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px ",
    },

    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        width: "620px",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        // margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "3px 10px 3px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        justifyContent: "center",
    },
    ClassExtendButton: {
        margin: "0px 5px",
        padding: "0px 5px",
    },
    FormFlex: {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "space-between",
        boxSizing: "border-box",
    },
    FormLeft: {
        flex: "1 1 auto",
        display: "flex",

        justifyContent: "flex-start",
        // flexDirection : "column"
    },
    FormRight: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        // alignItems : "flex-end",
        // flexDirection : "column"
    },
}));

const Component = React.memo(function Component(props) {
    const {TableData, HandleCloseCardParent, Invoices_File_Path, Customers, PassedFormValues, InvoiceId, HandleSaveRefreshForm} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowCard, setShowCard] = useState(true);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        setShowCard(false);
        HandleCloseCardParent();
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ModalCard;
    if (ShowCard === true) {
        ModalCard = (
            <CardModal
                CardID="SendingToCustomer"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Invoice Sending Email</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1400px"
                CardHeight="700px"
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardWindow}>
                    <div className={classes.CardMain} style={{flex: "1 1 auto"}}>
                        <div className={classes.FormFlex}>Test</div>
                        <TabNav
                            NoLink={true}
                            MenuFullWidth={false}
                            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
                            TabNavMenuSeparation="Line"
                            ActiveIndicator={{
                                WithIndicator: true,
                                Position: "Bottom",
                            }}
                            TabNavMenuHeight="25px"
                            TabMenuContent={[
                                {
                                    TabMenu: (
                                        <div className={classes.TabMenuDiv}>
                                            <div className={classes.TabTitle}>Upload History</div>
                                        </div>
                                    ),

                                    TabContent: <AccountantUploadHistoryTable TableData={TableData} />,
                                },

                                {
                                    TabMenu: (
                                        <div className={classes.TabMenuDiv}>
                                            <div className={classes.TabTitle}>Message History</div>
                                        </div>
                                    ),

                                    TabContent: <div>VAT</div>,
                                },
                            ]}
                        />
                    </div>
                </div>
            </CardModal>
        );
    } else {
        ModalCard = null;
    }

    return <React.Fragment>{ModalCard}</React.Fragment>;

    /***************** RENDER ******************/
});

export default Component;
