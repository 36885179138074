import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import _ from "lodash";

import Button from "../Button/Button"
import TableDataStore from "../TableDataStore/TableDataStore";
import TableMain from "../TableMain/TableMain";
import TableContainer from "../TableContainer/TableContainer";

//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    DevMode: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        border: "2px solid rgb(211, 78, 100)",
        borderRadius: "5px",
        padding: "10px 10px 10px 10px",
        margin: "10px 0px",
        background: "white",
    },
    DevModeButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "0 0 auto",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "0px 10px 0px 10px",
    },
    DevModeData: {
        flex: "0 1 auto",
        maxHeight: "300px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "10px 10px 10px 10px",
        boxSizing: "border-box",
    },
    DevModeDataIndividual: {
        flex: "0 1 auto",
        maxHeight: "150px",
        overflow: "auto",
        margin: "0px",
        fontSize: "14px",
        padding: "0px",

        boxSizing: "border-box",
    },
}));

/**
 * Artibulles-CIS Table component
 * Note : Use a parent container like this :
 * position: "relative",
		boxSizing : "border-box",
		padding : "10px 15px 10px 15px",
        display: "flex",
        maxWidth: "1200px",
        maxHeight: "800px",
        height: "100%",
        margin: "0 auto",
        justifyContent: "center",
 */

const Table = React.memo(function Table(props) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        URLAbsolute,
        URLRelative,
        //eslint-disable-next-line
        LocalData,
        MainDataSource,
        ColomnsInput,
        TableDimensions,
        TableOptions,
        DevMode,
        ShareTableData,
        UpdateDataFromOutisde,
    } = props;

    // DataOutput
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/

    /****************************** REFS *********************/

    /****************************** CONST *********************/
    const TableDimensionsInitial = {
        FitParentContainer: true,
        FitContent: true,
        ContainerScroll: true,
        // Width: "500px",
        // MaxWidth: "500px",
        // Height: "600px",
        // MaxHeight: "700px",
    };
    const TableDimensionsFinal = _.merge(TableDimensionsInitial, TableDimensions);
    const NodeDevMode = process.env.NODE_ENV === "development" ? true : false;
    var FinalDevMode = false;
    if (NodeDevMode === true && DevMode === true) {
        FinalDevMode = true;
    }

    /****************************** CONST *********************/

    /****************************** STATE *********************/
    const [TableData, setTableData] = useState(null);

    const [NoData, setNoData] = useState(false);
    const [CRUDAction, setCRUDAction] = useState(null);
    const [DevCheckValues, setDevCheckValues] = React.useState({content: "", Show: false}); //ONLY USED FOR DEVELOPMENT CHECKS
    // const [MainComponent, setMainComponent] = useState(null);

    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/
    // const GenerateDataStore = useCallback(() => {
    //     var TableComponent;
    //     if (TableData) {
    //         TableComponent = (
    //             <TableContainer TableDimensions={TableDimensionsFinal}>
    //                 <TableMain
    //                     Data={TableData}
    //                     MainDataSource={MainDataSource}
    //                     ColomnsInput={ColomnsInput}
    //                     TableDimensions={TableDimensionsFinal}
    //                     TableOptions={TableOptions}
    //                     CRUDAction={HandleCRUDAction}
    //                 />
    //             </TableContainer>
    //         );
    //     } else {
    //         TableComponent = <TableDataStore URLRelative={URLRelative} URLAbsolute={URLAbsolute} MainDataSource={MainDataSource} DataOutput={DataOutput} CRUD={CRUDAction} />;
    //     }
    //     setMainComponent(TableComponent);
    // }, [TableData, CRUDAction,ColomnsInput,MainDataSource]);

    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/

    // useEffect(() => {
    //     if (LocalData) {
	// 		console.log("Local Data", LocalData.Tasks)
    //         setTableData(LocalData);
    //     }
    // }, [LocalData]);

    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    const DataOutput = (Data) => {
        if (Data === "Deleted") {
            setCRUDAction(null);
            setTableData(null);
        } else {
            if (!Data) {
                setNoData(true);
            } else {
                //We format the data if required by the user

                if (Array.isArray(Data) && Data.length > 0) {
                    setTableData(Data);
                } else {
                    setTableData(Data);
                }
            }
        }
    };

    const HandleCRUDAction = (action) => {
        setCRUDAction(action);
        setTableData(null);
    };
    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var DevModeComponent;
    if (FinalDevMode === true) {
        if (TableData) {
            DevModeComponent = (
                <div className={classes.DevMode}>
                    <div className={classes.DevModeButtonBar}>
                        <Button
                            type="button"
                            Width="200px"
                            Height="50px"
                            Background="rgb(211, 78, 100)"
                            ClassExtendButton={classes.ClassExtendButton}
                            style={{margin: "0px 10px 0px 10px"}}
                            onClick={() => {
                                setDevCheckValues((prev) => {
                                    return {...prev, ...{Show: true}};
                                });
                            }}
                        >
                            DEV MODE API SHOW RESPONSE
                        </Button>
                        <Button
                            type="button"
                            Width="200px"
                            Height="50px"
                            Background="rgb(211, 78, 100)"
                            ClassExtendButton={classes.ClassExtendButton}
                            style={{margin: "0px 10px 0px 10px"}}
                            onClick={() => {
                                setDevCheckValues((prev) => {
                                    return {...prev, ...{Show: false}};
                                });
                            }}
                        >
                            DEV MODE API HIDE RESPONSE
                        </Button>
                        <Button
                            type="button"
                            Width="200px"
                            Height="50px"
                            Background="rgb(211, 78, 100)"
                            ClassExtendButton={classes.ClassExtendButton}
                            style={{margin: "0px 10px 0px 10px"}}
                            onClick={() => {
                                console.log("Table - ApiResponse : ", TableData);
                            }}
                        >
                            LOG DATA IN CONSOLE
                        </Button>
                    </div>
                    <div style={{display: DevCheckValues.Show === true ? "block" : "none"}}>
                        <h2>API SERVER RESPONSE</h2>

                        <pre className={classes.DevModeData}>{JSON.stringify(TableData, 0, 3)}</pre>
                    </div>
                </div>
            );
        } else {
            DevModeComponent = null;
        }
    } else {
        DevModeComponent = null;
    }

    var FinalComponent;
    if (LocalData) {
        FinalComponent = (
            <TableContainer NoData={false} TableDimensions={TableDimensionsFinal} DevModeComponent={DevModeComponent}>
                <TableMain
                    Data={LocalData}
                    MainDataSource={MainDataSource}
                    ColomnsInput={ColomnsInput}
                    TableDimensions={TableDimensionsFinal}
                    TableOptions={TableOptions}
                    CRUDAction={HandleCRUDAction}
                    ShareTableData={ShareTableData}
                    UpdateDataFromOutisde={UpdateDataFromOutisde}
                />
            </TableContainer>
        );
    } else if (TableData) {
        FinalComponent = (
            <TableContainer NoData={NoData} TableDimensions={TableDimensionsFinal} DevModeComponent={DevModeComponent}>
                <TableMain
                    Data={TableData}
                    MainDataSource={MainDataSource}
                    ColomnsInput={ColomnsInput}
                    TableDimensions={TableDimensionsFinal}
                    TableOptions={TableOptions}
                    CRUDAction={HandleCRUDAction}
                    ShareTableData={ShareTableData}
                    UpdateDataFromOutisde={UpdateDataFromOutisde}
                />
            </TableContainer>
        );
    } else {
        FinalComponent = <TableDataStore URLRelative={URLRelative} URLAbsolute={URLAbsolute} MainDataSource={MainDataSource} DataOutput={DataOutput} CRUD={CRUDAction} />;
    }

    return <React.Fragment>{FinalComponent}</React.Fragment>;
    /****************************** RENDER *********************/
});

Table.defaultProps = {
    LocalData: null,
    URLAbsolute: null,
    URLRelative: null,
    MainDataSource: null,
    ColomnsInput: null,
    TableDimensions: null,
    TableOptions: null,
    DevMode: false,
    ShareTableData: null,
    UpdateDataFromOutisde: null,
};

Table.propTypes = {
    /**
     * LocalData ={main : [], additional :[] }
     *
     */
    LocalData: PropTypes.any,

    /**
     * URLAbsolute : {
     * 	MAIN: URLAbsolute.MAIN,
     * 	DELETE: URLAbsolute.DELETE,
     * 	PUT: URLAbsolute.PUT,
     * 	POST: URLAbsolute.POST
     * }
     *
     */
    URLAbsolute: PropTypes.any,

    /**
     * URRelative : {
     * 	MAIN: URLAbsolute.MAIN,
     * 	DELETE: URLAbsolute.DELETE,
     * 	PUT: URLAbsolute.PUT,
     * 	POST: URLAbsolute.POST
     * }
     *
     */
    URLRelative: PropTypes.any,
    /**
     * MainDataSource : The name of the source for the table from the server api response
     * MainDataSource : "main"
     *
     */
    MainDataSource: PropTypes.any,
    /**
     * ColomnsInput : The columns definition to create the table
     * [
     * {width: "150px", caption: "Id", datafield: "_id", fixedCol: true, searchable: true, sortable: false, datatype: "text"},
     * ]
     */
    ColomnsInput: PropTypes.any,
    /**
     * TableDimensions
     */
    TableDimensions: PropTypes.any,
    /**
     * TableOptions
	 * const TableOptions = {
        Searchable: true,
        SearchOptions: {
            OnClickSearch_Only: false,
            MinCharSearch: "2",
        },
        Sortable: true,
        SortOptions: {
            MultiSearch: true,
        },
        Selectable: true,
        RowButtons: {
			IconFormat:{
					IconSize : "10px",
					Color : "red",
					HoverColor : "green",
					DisabledColor : "blue"

				},
            Standard: {
                Edit: {Condition: null, Position: 1, OnClick: HandleEditRow},
                View: {Condition: null, Position: 2, OnClick: HandlePreviewRow},
                Delete: {Condition: deletecondition, Position: 3, OnClick: null},
            },
        },
		CommonStyle: {
            CellVerticalPadding: "0px",
            CellHorizontalPadding: "0px",
        },
        BodyStyle: {
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
            color: "blue",
        },
        FixedCellStyle: {
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
            color: "red",
        },
        HeaderStyle: {
            fontSize: "12px",
            fontFamily: "Roboto",
            fontWeight: "400",
            justifyContent: "centererd",
            color: "green",
        },
    };
     */
    TableOptions: PropTypes.any,
    /**
     * If true -> Show Api Data to help building the table
     */
    DevMode: PropTypes.any,
    /**
     * ShareTableData : Pass the full data table to the parent component to perform operations (filter, sort...)
     */
    ShareTableData: PropTypes.any,
    /**
     * UpdateDataFromOutisde : Update the Table Data from outside to show, hide rows, cols....
     */
    UpdateDataFromOutisde: PropTypes.any,
};

export default Table;
