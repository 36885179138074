import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "@artibulles-cis/react/utils/APICallExternal";
import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import SendinEmailSummary from "./SendinEmailSummary";
import SendinEmailForm from "./SendinEmailForm";
import SendToContactsTabNav from "./SendToContacts/SendToContactsTabNav";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    Button: {
        margin: "0px 5px",
        padding: "0px",
        fontSize: "0.8rem",
    },

    ClassExtendCard: {
        color: "black",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px ",
    },

    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        alignSelf: "center",
        width: "620px",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        // margin: "0px 150px 0px 0px",

        textAlign: "left",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "0px 0px 0px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "3px 10px 3px 10px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        justifyContent: "center",
    },
    ClassExtendButton: {
        margin: "0px 5px",
        padding: "0px 5px",
    },
    FormFlex: {
        display: "flex",
        padding: "10px 0px",
        justifyContent: "space-between",
        boxSizing: "border-box",
    },
    FormLeft: {
        flex: "1 1 auto",
        display: "flex",

        justifyContent: "flex-start",
        // flexDirection : "column"
    },
    FormRight: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        // alignItems : "flex-end",
        // flexDirection : "column"
    },
}));

const Component = React.memo(function Component(props) {
    const {ShowCardUpload, HandleCloseCardParent, Invoices_File_Path, Customers, PassedFormValues, InvoiceId, HandleSaveRefreshForm} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_SendEmailHistoryId = useRef();
    const Ref_FormValuesSendEmail = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ApiRes, setApiRes] = useState(false);
    const [ApiCallType, setApiCallType] = useState(null);
    const [Loading, setLoading] = useState(false);
    const [ShowCard, setShowCard] = useState(false);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
                // toast.error(`Email not sent, ${ApiRes.error}`, {autoClose: true});
            } else {
                if (!ApiCallType) {
                    if (ApiRes && ApiRes.data && ApiRes.data.data) {
                        let Data = ApiRes.data.data;
                        let SaleInvoiceHistory = Data.SaleInvoiceHistory;
                        console.log("Sending Document Card", SaleInvoiceHistory);
                        setShowCard(true);
                        Ref_SendEmailHistoryId.current = SaleInvoiceHistory._id;
                        // let EmailToCustomer = Data.Invoice.EmailToCustomerStatus;
                        // if (EmailToCustomer && EmailToCustomer.Sent === false) {
                        // } else {
                        // }
                    } else {
                        console.log("error");
                    }
                } else if (ApiCallType === "SaveDraft") {
                    let Data = ApiRes.data.data;
                    let SaleInvoiceHistory = Data.SaleInvoiceHistory;
                    console.log("Sending Document Save Draft", SaleInvoiceHistory);
                    setShowCard(true);
                    Ref_SendEmailHistoryId.current = SaleInvoiceHistory._id;
                } else if (ApiCallType === "SendEmail") {
                    let Data = ApiRes.data.data;
                    // let SaleInvoiceHistory = Data.SaleInvoiceHistory;
                    // console.log("Sending Document Save Draft", SaleInvoiceHistory);
                    // setShowCard(true);
                    // Ref_SendEmailHistoryId.current = SaleInvoiceHistory._id;
                }
            }
        }
    }, [ApiRes, Loading]);

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        let DataForm = {
            InvoiceId: InvoiceId,
        };
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoice/email/intialize`, type: "post", data: DataForm};
        setLoading(true);

        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    }, []);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleCloseCard = () => {
        setShowCard(false);
        HandleCloseCardParent();
    };
    const HandleFieldSendEmailChanged = (FormValues) => {
        Ref_FormValuesSendEmail.current = FormValues;
    };

    const HandleSaveForm = () => {
        // console.log("Values", Ref_FormValuesSendEmail.current);
        let DataForm = Ref_FormValuesSendEmail.current;
        setApiCallType("SaveDraft");
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoice/email/history/${Ref_SendEmailHistoryId.current}`, type: "put", data: DataForm};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    const HandleSendEmail = () => {
        // console.log("Values", Ref_FormValuesSendEmail.current);
        let DataForm = Ref_FormValuesSendEmail.current;
        DataForm.SendInvoiceHistoryId = Ref_SendEmailHistoryId.current;
        console.log("DataForm", DataForm);
        setApiCallType("SendEmail");
        const ApiCall = {url: `${ProdURL}/api/saleinvoices/invoice/email/send`, type: "post", data: DataForm};
        setLoading(true);
        async function APIInternal() {
            const res = await APICallExternal(ApiCall);

            setApiRes(res);
            setLoading(false);
        }

        APIInternal();
    };

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    let ButtonBar = (
        <div className={classes.ButtonBar}>
            <Button ClassExtendButton={classes.ClassExtendButton} Width="130px" Height="30px" onClick={HandleSaveForm}>
                Save Draft
            </Button>
            <Button ClassExtendButton={classes.ClassExtendButton} Width="130px" Height="30px" onClick={HandleSendEmail}>
                Send Email
            </Button>
        </div>
    );
    let ModalCard;
    if (ShowCard === true) {
        ModalCard = (
            <CardModal
                CardID="SendingToCustomer"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Send Invoice to Customer</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseCard}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                CardMaxWidth="1400px"
                CardHeight="700px"
                // CardWindowPaddingVertical="10px"
                // CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardWindow}>
                    <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
                    <div className={classes.CardMain} style={{flex: "1 1 auto"}}>
                        {ButtonBar}
                        <div className={classes.FormFlex}>
                            <div className={classes.FormLeft}>
                                <SendinEmailSummary Invoices_File_Path={Invoices_File_Path} Customers={Customers} PassedFormValues={PassedFormValues} />
                            </div>

                            <div className={classes.FormRight}>
                                <SendinEmailForm
                                    InvoiceHistoryId={Ref_SendEmailHistoryId.current}
                                    Invoices_File_Path={Invoices_File_Path}
                                    Customers={Customers}
                                    PassedFormValues={PassedFormValues}
                                    HandleFieldChange={HandleFieldSendEmailChanged}
                                />
                            </div>
                        </div>

                        <SendToContactsTabNav InvoiceId={InvoiceId} InvoiceHistoryId={Ref_SendEmailHistoryId.current} />
                    </div>
                </div>
            </CardModal>
        );
    } else {
        ModalCard = null;
    }

    return (
        <React.Fragment>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {ModalCard}
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
