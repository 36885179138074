import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Switch, Route, Redirect, useParams} from "react-router-dom";
import TaskManagementRouter from "./TaskMananagement/TaskManagementRouter";
import DailyOrganierTabNav from "./DailyOrganizer/DailyOrganierTabNav";
import TestDailyOrganizer from "./DailyOrganizer/TestDailyOrganizer";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const TaskTimeManagementRouter = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <Switch>
            <Route path="/tasktimemanagement/tasks/dailyorganizer">
                <DailyOrganierTabNav />
            </Route>
			<Route path="/tasktimemanagement/tasks/testcalendar">
                <TestDailyOrganizer />
            </Route>
            <Route path="/tasktimemanagement/tasks">
                <TaskManagementRouter />
            </Route>
        </Switch>
    );

    /***************** RENDER ******************/
};

export default TaskTimeManagementRouter;
