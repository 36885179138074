/**
 * ScriptName : vatEUCodeCheck.js
 * Version : 1.0.0
 * Date : 2023/01/21
 * Author: ArnaudJaspard
 * Description : description
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/

/********LOADING DEPENDENCIES************/

/******** CONST ************/

const AT = "^ATU[A-Z0-9]{8,8}$";
const BE = "^BE[0-9]{10,10}$";
const BG = "^BG[0-9]{9,10}$";
const CY = "^CY[0-9]{8,8}[A-Z]{1,1}$";
const CZ = "^CZ[0-9]{8,10}$";
const DE = "^DE[0-9]{9,9}$";
const DK = "^DK[0-9]{8,8}$";
const EE = "^EE[0-9]{9,9}$";
const EL = "^EL[0-9]{9,9}$";
const ES = "^ES[A-Z0-9]{1,1}[0-9]{7,7}[A-Z0-9]{1,1}$";
const FI = "^FI[0-9]{8,8}$";
const FR = "^FR[A-Z0-9]{2,2}[0-9]{9,9}$";
// const FR = "^FR[0-9A-HJ-NP-Z]{2,2}[0-9]{9,9}$";
const GB = "^GB[0-9]{9,9}$" + "|^GB[0-9]{12,12}$" + "|^GBGD[0-4][0-9]{2,2}$" + "|^GBHA[5-9][0-9]{2,2}$";
const HR = "^HR[0-9]{11,11}$";
const HU = "^HU[0-9]{8,8}$";
const IE = "^IE[0-9]{1,1}[A-Z0-9]{1,1}[0-9]{5,5}[A-Z]{1,1}$|^IE[0-9]{7,7}[A-W]{1,1}[A-I]{1,1}$";
const IT = "^IT[0-9]{11,11}$";
const LT = "^LT[0-9]{9,9}$|^LT[0-9]{12,12}$";
const LU = "^LU[0-9]{8,8}$";
const LV = "^LV[0-9]{11,11}$";
const MT = "^MT[0-9]{8,8}$";
const NL = "^NL[A-Z0-9]{9,9}B[A-Z0-9]{2,2}$";
const PL = "^PL[0-9]{10,10}$";
const PT = "^PT[0-9]{9,9}$";
const RO = "^RO[0-9]{2,10}$";
const SE = "^SE[0-9]{10,10}01$";
const SI = "^SI[0-9]{8,8}$";
const SK = "^SK[0-9]{10,10}$";
const CH = "^(CHE-|CHE|CHEs)([0-9]{3,3}(.)[0-9]{3,3}(.)[0-9]{3,3}|[0-9]{9,9})(s|)(MWST|TVA|IVA|)$";

const patterns = {
    GB: {
        prefix: "GB",
        pattern: GB,
    },
    AT: {
        prefix: "AT",
        pattern: AT,
    },
    BG: {
        prefix: "BG",
        pattern: BG,
    },
    BE: {
        prefix: "BE",
        pattern: BE,
    },
    CY: {
        prefix: "CY",
        pattern: CY,
    },
    CZ: {
        prefix: "CZ",
        pattern: CZ,
    },
    DE: {
        prefix: "DE",
        pattern: DE,
    },
    DK: {
        prefix: "DK",
        pattern: DK,
    },
    EE: {
        prefix: "EE",
        pattern: EE,
    },
    ES: {
        prefix: "ES",
        pattern: ES,
    },
    FI: {
        prefix: "FI",
        pattern: FI,
    },
    FR: {
        prefix: "FR",
        pattern: FR,
    },
    HU: {
        prefix: "HU",
        pattern: HU,
    },
    IE: {
        prefix: "IE",
        pattern: IE,
    },
    IT: {
        prefix: "IT",
        pattern: IT,
    },
    LT: {
        prefix: "LT",
        pattern: LT,
    },
    LU: {
        prefix: "LU",
        pattern: LU,
    },
    LV: {
        prefix: "LV",
        pattern: LV,
    },
    MT: {
        prefix: "MT",
        pattern: MT,
    },
    NL: {
        prefix: "NL",
        pattern: NL,
    },
    PL: {
        prefix: "PL",
        pattern: PL,
    },
    PT: {
        prefix: "PT",
        pattern: PT,
    },
    SE: {
        prefix: "SE",
        pattern: SE,
    },
    SI: {
        prefix: "SI",
        pattern: SI,
    },
    SK: {
        prefix: "SK",
        pattern: SK,
    },
    RO: {
        prefix: "RO",
        pattern: RO,
    },
    EL: {
        prefix: "EL",
        pattern: EL,
    },
    HR: {
        prefix: "HR",
        pattern: HR,
    },
    CH: {
        prefix: "CH",
        pattern: CH,
    },
};
/******** CONST ************/

/********CORE FUNCTION************/

exports.CheckEUVatCode = function (VatCode) {
    let vatcode = VatCode.toUpperCase();
    vatcode = vatcode.replace(/\s+/g, "");
    //Select the correct pattern based on the country code
    var countryCode = vatcode.substring(0, 2);
    var countryPattern = getPattern(countryCode, patterns);
    if (typeof countryPattern === "undefined") return false;
    //Validate using the country's regex patterns
    var checker = new RegExp(countryPattern);
    let IsVatCorrect = checker.test(vatcode);
    return IsVatCorrect;
    // return {Error: "No Country Code", Valid: "false"};
};
/********CORE FUNCTION************/

/********GLOBAL FUNCTIONS************/
function getPattern(prefixStr, patterns) {
    for (var p in patterns) {
        if (patterns.hasOwnProperty(p)) {
            if (patterns[p].prefix === prefixStr) return patterns[p].pattern;
        }
    }
}
/********GLOBAL FUNCTIONS************/
