/**
 * ScriptName : APIServerAddress.js
 * Version : 1.0.0
 * Date : 2021/05/05
 * Author: ArnaudJaspard
 * Description : description
 * License : N/A
 * Inputs :
 * Output:
 */

/********LOADING DEPENDENCIES************/

/********LOADING DEPENDENCIES************/

/********CORE FUNCTION************/
export const apiserverurl = () => {
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const URL = DevMode ? "localhost" : "https://artibulles-bms.artibulles.com";
    return URL;
};

export const authserverurl = (type) => {
    var URL;
    if (type === "development") {
        // URL = "http://192.168.1.110:3203";
		URL = "http://localhost:3203";
    } else {
        URL = "https://artibulles-bms.artibulles.com";
		// URL = "https://whateverdomain.com";
    }

    return URL;
};

/********CORE FUNCTION************/

/********GLOBAL FUNCTIONS************/

/********GLOBAL FUNCTIONS************/
