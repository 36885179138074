/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { createUseStyles, useTheme } from 'react-jss';
import APICallExternal from '@artibulles-cis/react/utils/APICallExternal';

//eslint-disable-next-line
const LockedstylesWithProps = props => {
  return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles(theme => ({}));

/**
 * Component Description
 */

const TableDataStore = React.memo(function TableDataStore(props, ref) {
  /****************************** PROPS AND JSS CLASSES *********************/
  const { URLAbsolute, URLRelative, MainDataSource, DataOutput, CRUD } = props;

  const theme = useTheme();
  //eslint-disable-next-line
  const classes = styles({ ...props, theme });
  /****************************** PROPS AND JSS CLASSES *********************/

  /****************************** REFS *********************/
  // const ref = useRef(null)
  /****************************** REFS *********************/

  /****************************** CONST *********************/
  const DevMode = process.env.NODE_ENV === 'development' ? true : false;
  //eslint-disable-next-line

  /****************************** CONST *********************/

  /****************************** STATE *********************/
  const [ApiRes, setApiRes] = useState(null); //Loader and ensuring async is done for API response
  const [Loading, setLoading] = useState(false); //Loader and ensuring async is done for API response
  const [CallType, setCallType] = useState(null);

  /****************************** STATE *********************/

  /****************************** CALLBACK FUNCTIONS *********************/

  const EndofAPI = React.useCallback(() => {
    //Making sure the Loading is done and the Results are ready
    if (!Loading && ApiRes) {
      if (ApiRes.error) {
        console.log('Table Data Store error : ', ApiRes);
        //Something went wrong
        // toast.error(`Error : ${ApiRes.errorMessage.message}`, {autoClose: true});
      } else {
        if (CallType === 'DeleteOne') {
          //Need to call again the API to get the data again
          DataOutput('Deleted');
          setCallType(null);
        } else {
          DataOutput(ApiRes.data.data);
        }
      }
    }
  }, [ApiRes, Loading, CallType, DataOutput]);
  /****************************** CALLBACK FUNCTIONS *********************/

  /****************************** EFFECT *********************/
  useEffect(() => {
    var FinalURL;
    if (URLAbsolute) {
      FinalURL = {
        MAIN: URLAbsolute.MAIN,
        DELETE: URLAbsolute.DELETE,
        PUT: URLAbsolute.PUT,
        POST: URLAbsolute.POST,
      };
    } else {
      FinalURL = {
        MAIN: URLRelative.MAIN,
        DELETE: URLRelative.MAIN + '/' + URLRelative.DELETE + '/',
        PUT: URLRelative.PUT,
        POST: URLRelative.POST,
      };
    }
    var FinalAPICall;

    if (CRUD) {
      if (CRUD.type === 'DeleteOne') {
        FinalAPICall = { url: FinalURL.DELETE + CRUD.Id, type: 'delete' };
        setCallType('DeleteOne');
      }
    } else {
      FinalAPICall = { url: FinalURL.MAIN, type: 'get' };
    }

    setLoading(true);
    async function APIInternal() {
      const res = await APICallExternal(FinalAPICall.url, FinalAPICall.type);

      setApiRes(res);
      setLoading(false);
    }

    APIInternal();
  }, [URLAbsolute, URLRelative, MainDataSource, CRUD]);

  useEffect(() => {
    //Used to prevent executing the core function multiple times
    if (!Loading && ApiRes) {
      EndofAPI();
    }
  }, [Loading, ApiRes]);
  /****************************** EFFECT *********************/

  /****************************** FUNCTIONS *********************/
  //ADD YOUR FUNCTIONS HERE

  /****************************** FUNCTIONS *********************/

  /****************************** RENDER *********************/
  return <div></div>;
  /****************************** RENDER *********************/
});

TableDataStore.defaultProps = {
  MyProp: null,
};

TableDataStore.propTypes = {
  /**
   * MyProp :
   */
  MyProp: PropTypes.string,
};

export default TableDataStore;
