/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
// import FormWithApi from "@artibulles-cis/react/FormWithApi";
import FormWithApi from "../../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";
// import TabNavContent from "@artibulles-cis/react/TabNavContent";
import TabNavContent from "../../../../../../../../artibulles-cis/TabNavContent/TabNavContent";
import CardEditForm from "./CardEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendCard: {
        borderRadius: "0px",
    },
}));

const Component = (props) => {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/financials/commpanyaccount/creditcards/card/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companyaccount/creditcards/card/edit`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            // let Id = args.data.data.Customer._id;
            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/
    const [InitialBankAccount, setInitialBankAccount] = useState(null);

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        //SET THE INITIAL FORM VALUES
        var FormErrorMain;
        if (data && data.Error) {
            let Errors = data.Error;
            if (Errors.Form) {
                FormErrorMain = Errors.Form;
            }
        }
        var CompanyCardTypes;
        if (data.ref_creditCardType && data.ref_creditCardType.length > 0) {
            CompanyCardTypes = data.ref_creditCardType.map((elem) => {
                return {value: elem._id, label: elem.name};
            });
        }
        var BankAccounts;
        if (data.ref_myBankAccount && data.ref_myBankAccount.length > 0) {
            BankAccounts = data.ref_myBankAccount.map((elem) => {
                if (IdFinal === "new") {
                    if (elem.is_default === true) {
                        setInitialBankAccount(elem._id);
                    }
                } else if (data.CreditCard) {
                    if (data.CreditCard.ref_myBankAccount) {
                        if (elem._id === data.CreditCard.ref_myBankAccount) {
                            setInitialBankAccount(elem._id);
                        }
                    }
                }

                return {value: elem._id, label: elem.select_account_owner_bank};
            });
        }

        //SET THE INITIAL FORM VALUES
        var MainData;

        if (data && data.CreditCard) {
            if (data.CreditCard === "No bank account") {
                History.push("/financials/companyaccount/creditcards");
            } else {
                MainData = data.CreditCard ? data.CreditCard : null;
            }
        }

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: MainData ? MainData._id : "new",
                ref_CreditCardType: MainData ? MainData.ref_CreditCardType : null,
                ref_myBankAccount: InitialBankAccount,
                card_number: MainData ? MainData.card_number : null,
                card_holder_Name: MainData ? MainData.card_holder_Name : null,
                is_default: MainData ? MainData.is_default : false,
                expiration_date: MainData ? (MainData.expiration_date ? MainData.expiration_date.toString() : null) : null,
                db_status: MainData ? MainData.db_status : null,
                db_locked: MainData ? MainData.db_locked : null,
                db_locked_by: MainData ? MainData.ref_db_locked_by : null,
                db_locked_at: MainData ? MainData.db_locked_at : null,
                owner: MainData ? MainData.ref_owner : null,
                createdAt: MainData ? (MainData.createdAt ? format(parseISO(MainData.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: MainData ? (MainData.updatedAt ? format(parseISO(MainData.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                ref_CreditCardType: CompanyCardTypes,
                ref_myBankAccount: BankAccounts,
                // parentId: parentId,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
            InitiaFormErrors: {},
            InitialFormErrorMain: FormErrorMain,
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Card: {
                        Updatable: {
                            ref_CreditCardType: values.ref_CreditCardType,
                            ref_myBankAccount: values.ref_myBankAccount ? values.ref_myBankAccount : InitialBankAccount,
                            card_number: values.card_number,
                            card_holder_Name: values.card_holder_Name,
                            expiration_date: values.expiration_date,
                            is_default: values.is_default,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                OpenInViewMode={IdFinal ? (IdFinal === "new" ? false : false) : false}
                SwitchEditMode="FieldClick"
                toast={toast}
                DevMode={false}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={CardEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save Draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save"}}
                ButtonQuitNoSave={{Active: false, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
};

export default Component;
