import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {useHistory, useLocation} from "react-router-dom";
import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
import Page from "../../../../../../artibulles-cis/Page/Page";
import Table from "../../../../../../artibulles-cis/TableNew/Table";

import _ from "lodash";
import {toast} from "react-toastify";
import APICallExternal from "../../../../../../artibulles-cis/utils/APICallExternal";
import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import {isValid, format, parseISO} from "date-fns";
import MilestoneditDetails from "./MilestoneditDetails";

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "10px 15px 10px 15px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "100%",
        maxHeight: "700px",
        margin: "0 auto",
        justifyContent: "center",
    },
    FormTitle_Wrapper: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        boxSizing: "border-box",
    },
    FormTitle_Box: {
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        height: "60px",
        border: "1px solid grey",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "5px 10px",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 10px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "400",
        fontSize: "22px",
        margin: "0px",
    },
    TableToolbar: {
        display: "flex",
    },
    ClassExtendCard: {
        borderRadius: "10px",
        flexDirection: "column",
        alignItems: "center",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "4px 0px 4px 0px",
        // flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {Id, ProgramDetails} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    const Ref_LocaleData = useRef();

    const Location = useLocation();
    const PathName = Location.pathname;
    let ProgramId;
    if (Id) {
        ProgramId = Id;
    } else {
        ProgramId = PathName.replace("/programs/program/", "").replace("/milestones", "");
    }

    const History = useHistory();
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const ColomnsInput = [
        {width: "auto", caption: "Milestone", datafield: "milestone_name", fixedCol: false, searchable: true, sortable: true, datatype: "text", wrapCell: true},
        {width: "350px", caption: "Description", datafield: "description", fixedCol: false, searchable: false, sortable: true, wrapColHeader: true, datatype: "text", wrapCell: true},
        {
            width: "120px",
            caption: "Due Date",
            datafield: "due_date_Iso",
            cellFormatFunction: (value) => {
                let dateFromIso = parseISO(value);
                if (isValid(dateFromIso)) {
                    return format(parseISO(value), "dd-MMM-yyyy");
                } else {
                    return value;
                }
            },

            fixedCol: false,
            searchable: false,
            sortable: true,
            initialSort: {order: 1, sortDirection: "Dsc"},
            datatype: "date",
        },
        {
            width: "350px",
            caption: "Expected Duration",
            datafield: "expected_duration_seconds",
            cellFormatFunction: (value) => {
                if (value) {
                    return parseInt(value) / 3600;
                }
            },

            fixedCol: false,
            searchable: false,
            sortable: true,
            wrapColHeader: true,
            datatype: "number",
        },
    ];

    const deletecondition = (args) => {
        // console.log("args",args.rowData)
        return true;
        // if (args.rowData.company) {
        //     return true;
        // } else {
        //     return false;
        // }
    };
    const HandlePreviewRow = (rowData) => {
        if (rowData && rowData.rowData) {
            let id = rowData.rowData._id;
            console.log("id", id);
            setShowViewCard(true);
            setViewCardId(id);
        }
    };
    const TotalRow = {
        Show: false,
    };

    const HandleEditRow = (arg) => {
        if (arg) {
            let id = arg._id;

            setShowViewCard(true);
            setViewCardId(id);
        }
    };

    const HandleDeleteRow = (arg) => {
        let Id = arg._id;
        if (Id) {
            const ApiCall = {url: `${ProdURL}/api/programs/program/${Id}`, type: "delete"};
            setLoading(true);
            setAPICallType("deleteone");
            setisIntialized(false);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    };

    const TableOptions = {
        PagerOptions: {
            InfiniteScroll: false,
            ShowPager: false,
            DefaultPageSize: 30,
            AllowedPageSizes: [15, 30, 45],
        },
        Searchable: false,
        Selectable: false,
        RowButtons: [
            {
                Standard: true,
                Name: "Edit",
                ActiveCondition: null,
                Position: 1,
                OnClick: HandleEditRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
            {
                Standard: true,
                Name: "Delete",
                ActiveCondition: null,
                Position: 2,
                OnClick: HandleDeleteRow,
                IconSize: "10px",
                ActiveColor: "red",
                HoverColor: "green",
                InactiveColor: "blue",
            },
        ],
        TopToolbar: {
            Disabled: true,
            AlwaysShow: false,
        },
    };
    const TableDimensions = {
        width: "800px",
        height: "400px",
        FitContent: false,
        FitParentContainer: true,
    };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowViewCard, setShowViewCard] = useState(false);
    const [isIntialized, setisIntialized] = useState(false);
    const [ViewCardId, setViewCardId] = useState(null);

    const [TableComponent, setTableComponent] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [APICallType, setAPICallType] = useState("InitSelect");
    const [InitialFormValues, setInitialFormValues] = React.useState({
        is_completed: "All",
    }); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({
        is_completed: "All",
    }); //FINAL FORM VALUES

    const [FieldOptions, setFieldOptions] = useState({
        is_completed: [
            {label: "Pending", value: "Pending"},
            {label: "All", value: "All"},
            {label: "Completed", value: "Completed"},
        ],
    });

    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const GenerateTableComponent = useCallback(() => {
        if (Ref_LocaleData.current && isIntialized === true) {
            let FinalTableComponent = (
                <Table
                    LocalData={Ref_LocaleData.current}
                    // URLAbsolute={Ref_URLAbsolute.current}
                    MainDataSource="Milestones"
                    ColomnsInput={ColomnsInput}
                    TableDimensions={TableDimensions}
                    TableOptions={TableOptions}
                    TotalRow={TotalRow}
                    DevMode={false}
                />
            );
            setTableComponent(FinalTableComponent);
        } else {
            let CreateMLilestoneComponent = (
                <div>
                    There are no Milestones
                    <Button onClick={HandleCreateMilestone}>Create Milestone</Button>
                </div>
            );
            setTableComponent(CreateMLilestoneComponent);
        }
    }, [Ref_LocaleData, isIntialized]);

    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                console.log("error", ApiRes);
            } else {
                console.log("ApiRes", ApiRes);
                if (ApiRes.data && ApiRes.data.data) {
                    let data = ApiRes.data.data;
                    console.log("data", data);

                    if (data.Milestones && Array.isArray(data.Milestones) && data.Milestones.length > 0) {
                        Ref_LocaleData.current = data;
                    } else {
                        Ref_LocaleData.current = null;
                    }

                    setisIntialized(true);
                }

                if (APICallType === "deleteone") {
                    toast.success(`Entry deleteted`, {autoClose: true});
                    setisIntialized(true);
                    setAPICallType(null);
                }
            }
        }
    }, [ApiRes, Loading]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (isIntialized === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, isIntialized]);
    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (isIntialized === false) {
            //Calling the Api whenever the selects are changing to create the query
            console.log("FormValues", FormValues);
            // let CompanyName = FormValues.company_name;

            let ApiCall = {url: `${ProdURL}/api/programs/milestonesqueries/?programid=${ProgramId}`, type: "get"};
            // if (CompanyName !== "All") {
            //     ApiCall = {url: `${ProdURL}/api/programs/milestonesqueries/?programid=${ProgramId}`, type: "get"};
            // }

            setLoading(true);

            setisIntialized(false);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [FormValues, isIntialized]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    const HandleCloseViewCard = () => {
        setShowViewCard(false);
        setViewCardId(null);
        //Reload the table
        setisIntialized(false);
        setTimeout(() => {
            setisIntialized(true);
        }, 150);
    };

    const HandleCreateMilestone = () => {
        setShowViewCard(true);
        setViewCardId("new");
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        let SelectedValues = SelectedDetails.Value;
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    var ModalCardView;
    let FinalTableComponent;
    if (isIntialized === true) {
        FinalTableComponent = TableComponent;
    } else {
        FinalTableComponent = null;
    }
    if (ShowViewCard === true) {
        ModalCardView = (
            <CardModal
                // CardID="HealFit_CreateDailyIntake"
                // ParentCompRef={Ref_TabNavContent}
                // TopBarContent={CardTitle}
                CloseCard={HandleCloseViewCard}
                CloseOnClickOutside={true}
                WithCloseButton={true}
                CardMaxWidth="800px"
                CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"

                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardContent}>
                    <MilestoneditDetails Id={ViewCardId} ProgramId={ProgramDetails._id} HandleCloseViewCard={HandleCloseViewCard} />
                </div>
            </CardModal>
        );
    }
    return (
        <Page WithPerfectScrollBar={true} PagePadding="10px" ClassExtendsPage={classes.PageContentExtends}>
            {ModalCardView}
            <div className={classes.FormTitle_Wrapper}>
                <div className={classes.FormTitle_Box}>
                    <div className={classes.FormTitle_Title_Wrapper}>
                        <h1 className={classes.FormTitle_Title}>{ProgramDetails ? ProgramDetails.program_name : null}</h1>
                    </div>
                </div>
            </div>

            <div className={classes.TableToolbar}>
                <div className={classes.FormMultilineFlex}>
                    <div className={classes.FormField} style={{flex: "0 0 250px"}}>
                        <FormCompontent
                            Name="is_completed"
                            InitialValue={InitialFormValues.is_completed ? InitialFormValues.is_completed : null}
                            FormValue={FormValues.is_completed ? FormValues.is_completed : null}
                            OutputSelectedValues={(values) => handleSelectChange("is_completed", values)}
                            SelectOptions={FieldOptions.is_completed}
                            Component="Select"
                            Variant="OutlinedLabel"
                            Label="Completed"
                            NoMessage={false}
                            // meta={{error: FormErrors.ref_taskPriority, invalid: FormErrors.ref_taskPriority ? true : false}}
                            // OutputError={(error) => HandleUpdateErrors("ref_taskPriority", error)}
                            MultiSelect={false}
                            SortSelectOptions="Asc"
                            // SortField="order"
                            // SortFirstItemName={{Exact: false, Name: "All"}}
                            SortIcons={false}
                            AllowCreateOption={false}
                            LabelMaxWidth="200px"
                            LabelAlign="Left"
                            Required={true}
                        />
                    </div>
                    <div className={classes.FormField} style={{flex: "0 0 500px", marginTop : "15px"}}>
                        <Button Height="30px" style={{margin: "0px"}} onClick={HandleCreateMilestone}>
                            Add Milestone
                        </Button>
                    </div>
                </div>
            </div>
            <div className={classes.TableWrapper}>{FinalTableComponent}</div>
        </Page>
    );

    /***************** RENDER ******************/
});

export default Component;
