import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import APICallExternal from "../../../../../../../../artibulles-cis/utils/APICallExternal";
import LocalCircularLoader from "@artibulles-cis/react/LocalCircularLoader";
import {toast} from "react-toastify";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AGGridTable from "../../../../../../../../artibulles-cis/AGGridTable/AGGridTable";
import {format, parseISO} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    FormField: {
        flex: "0 1 600px",
        margin: "5px 10px 5px 10px",
    },
    TableWrapper: {
        position: "relative",
        boxSizing: "border-box",
        padding: "5px 0px",
        display: "flex",
        width: "100%",
        // maxWidth: "1200px",
        // maxHeight: "800px",
        height: "250px",
        margin: "0 auto",
        justifyContent: "center",
    },
    ClassExtendTabContent: {
        // maxWidth: "1200px",
    },
    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px",
    },
	InvoiceSummary_Line: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "flex-start",
        padding: "2px 0px",
		width : "250px",
		fontSize : "0.9rem"
    },
    InvoiceSummary_Line_Title: {
        flex: "0 0 120px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 10px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
}));

const CustomerEditBankAccountTable = React.memo(function CustomerEditBankAccountTable(props) {
    const {FormViewMode, PurchaseInvoiceID, SubmitParentForm} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/
    const Ref_LocaleData = useRef();
    /***************** REFS ******************/

    /***************** CONST ******************/
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const PaymentCell = {
        display: "flex",
        padding: "0px 5px",
    };

    const AccountNumberRendering = (params) => {
        let RowData = params.data;
        console.log("RowData", RowData);
        let counterparty_bankAccount_number = RowData.CompanyAccountTransactions.counterparty_bankAccount_number;
        let location = RowData.location;
        let Output;
        if (counterparty_bankAccount_number) {
            Output = counterparty_bankAccount_number;
        } else {
            Output = location;
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };
    const AccountOwnerRendering = (params) => {
        let RowData = params.data;
        let counterparty_bankAccount_name = RowData.CompanyAccountTransactions.counterparty_bankAccount_name;
        let merchant = RowData.merchant;
        let Output;
        if (counterparty_bankAccount_name) {
            Output = counterparty_bankAccount_name;
        } else {
            Output = merchant;
        }
        return <React.Fragment>{Output}</React.Fragment>;
    };

    const TableColumns = [
        {
            field: "CompanyAccountTransactions.calc_RefTransaction",
            headerName: "Transaction Id",
            headerClass: "ag-CenterHeader",
            flex: 1,
            minWidth: 170,
            // filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sort: "desc",
            sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            // Type: "Date",
        },
        {
            field: "CompanyAccountTransactions.transaction_date_Iso",
            headerName: "Date",
            headerClass: "ag-CenterHeader",
            width: 130,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            sortingOrder: ["asc", "desc"],
            // sortIndex: 1,
            // sort: "desc",
            Type: "Date",
        },
        {
            field: "CompanyAccountTransactions.ReconciledDateIso",
            headerName: "Reconciliation",
            headerClass: "ag-CenterHeader",
            width: 150,
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            // sort: "desc",
            // sortIndex: 1,
            sortingOrder: ["asc", "desc"],
            Type: "Date",
        },
        {
            field: "CompanyAccountTransactions.banking_amount_transaction",
            headerName: "Amount",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: "agNumberColumnFilter",
            filterParams: {buttons: ["apply", "reset"], closeOnApply: true},
            Type: "Currency",
        },
        {
            field: "CompanyAccountTransactions.communication",
            //
            headerName: "Communication",
            headerClass: "ag-CenterHeader",
            flex: 2,
            filter: true,
            sortingOrder: ["asc", "desc"],
            // cellRenderering: StatusRendering,
            // cellStyle: PaymentCell,
        },

        {
            field: "CompanyAccountTransactions.counterparty_bankAccount_number",
            //
            headerName: "Account Number",
            headerClass: "ag-CenterHeader",
            width: 180,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountNumberRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "CompanyAccountTransactions.counterparty_bankAccount_name",
            //
            headerName: "Account Owner",
            headerClass: "ag-CenterHeader",
            width: 180,
            filter: true,
            sortingOrder: ["asc", "desc"],
            cellRenderering: AccountOwnerRendering,
            cellStyle: PaymentCell,
        },
        {
            field: "CompanyAccountTransactions.transaction_source",
            headerName: "Source",
            headerClass: "ag-CenterHeader",
            width: 150,
            filter: true,
            // Lookup: {LookupField: "ref_BankJournal", Source: "AccountJournals", ReturnField: "JournalName"},
            // sort: "asc",
            // sortIndex: 2,
            sortingOrder: ["asc", "desc"],
        },
    ];

    const TableOptions = {
        // Selection: {
        //     // Multiple: true,
        //     WithCheckBox: true,
        // },
        Resizable: true,
        Sortable: true,
        Filterable: true,
        SupressMenu: true,
        WrapText: true,
        WrapHeader: false,
        HeaderHeight: 30,
        RowHeight: 24,
        RowFontSize: "13px",
        HeaderFontSize: "14px",
        StatusBar: true,
    };
    // const TotalRow = {
    //     Position: "Bottom",
    //     Style: null,
    // };

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [Init, setInit] = useState(false);
    const [APICallType, setAPICallType] = useState(null);
    const [ReconiciliationNote, setReconiciliationNote] = useState(null);
    const [ReconciliationDate, setReconciliationDate] = useState(null);
    const [ApiRes, setApiRes] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [TableComponent, setTableComponent] = useState(null);
    const [ReloadTableData, setReloadTableData] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/
    const EndofAPI = useCallback(() => {
        //Making sure the Loading is done and the Results are ready

        if (!Loading && ApiRes) {
            if (ApiRes.error) {
                // console.log("error", ApiRes);
            } else {
                if (ApiRes && ApiRes.data && ApiRes.data.data) {
                    let Data = ApiRes.data.data;
                    if (Init === false) {
                        let ReconciledTransactions = Data.ReconciledTransactions;
                        // console.log("ReconciledTransactions", ReconciledTransactions);
                        if (ReconciledTransactions && Array.isArray(ReconciledTransactions) && ReconciledTransactions.length > 0) {
                            Ref_LocaleData.current = ReconciledTransactions;
                            if (Data.ReconiciliationNote) {
                                setReconiciliationNote(Data.ReconiciliationNote);
                            }
                            if (Data.ReconciliationDateISo) {
                                let ReconciliationDateIso = format(parseISO(Data.ReconciliationDateISo), "dd-MMM-yyyy");
                                setReconciliationDate(ReconciliationDateIso);
                            }
                            setInit(true);
                        } else {
                            Ref_LocaleData.current = null;
                            setInit(true);
                        }
                    }
                } else {
                    if (APICallType === "deleteone") {
                        toast.success(`Entry deleted`, {autoClose: true});

                        Ref_LocaleData.current = null;
                        setAPICallType(null);
                        setTimeout(() => {
                            setInit(false);
                        }, 50);
                        setTimeout(() => {
                            SubmitParentForm();
                        }, 200);
                    }
                }
            }
        }
    }, [ApiRes, Loading]);

    const GenerateTableComponent = useCallback(() => {
        setTableComponent(null);
        let FinalTableComponent = null;

        if (Ref_LocaleData.current) {
            FinalTableComponent = (
                <AGGridTable
                    TableName="Reconciliation_ViewMode"
                    Data={Ref_LocaleData.current}
                    // URLAbsolute={URLAbsolute}
                    TableColumns={TableColumns}
                    MainDataSource=""
                    // RowActionButtons={TableRowButtons}
                    // ColumnButtonStyle={ColumnButtonStyle}
                    TableOptions={TableOptions}
                    // ReloadData={ReloadTableData}
                    // SyncReloadDataStateFunction={setReloadTableData}
                    // TotalRow={TotalRow}
                />
            );
        }

        setTimeout(() => {
            setTableComponent(FinalTableComponent);
            setReloadTableData(false);
        }, 200);
    }, [Ref_LocaleData.current, Init, FormViewMode]);
    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/
    useEffect(() => {
        if (Init === false || ReloadTableData === true) {
            // console.log("API RELOAD Table");
            const ApiCall = {url: `${ProdURL}/api/purchaseinvoices/reconciliation/byinvoice/${PurchaseInvoiceID}`, type: "get", timeout: 10000};
            setLoading(true);
            setAPICallType(null);

            async function APIInternal() {
                const res = await APICallExternal(ApiCall);
                setApiRes(res);
                setLoading(false);
            }

            APIInternal();
        }
    }, [Init, ReloadTableData]);

    useEffect(() => {
        //Used to prevent executing the core function multiple times
        if (!Loading && ApiRes) {
            EndofAPI();
        }
    }, [Loading, ApiRes]);

    useEffect(() => {
        if (Init === true) {
            GenerateTableComponent();
        }
    }, [GenerateTableComponent, Init, FormViewMode, ReloadTableData]);

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    var FinalTableComponent;

    if (Init === true) {
        if (TableComponent) {
            let ReconciledDateComponent;
            if (ReconciliationDate) {
                ReconciledDateComponent = (
                    <div className={classes.InvoiceSummary_Line}>
                        <div className={classes.InvoiceSummary_Line_Title}>Reconciled on:</div>
                        <div className={classes.InvoiceSummary_Line_Value}>{ReconciliationDate}</div>
                    </div>
                );
            }

            FinalTableComponent = (
                <div>
                    {ReconciledDateComponent}
                    {ReconiciliationNote}

                    <div className={classes.TableWrapper}>{TableComponent}</div>
                </div>
            );
        } else {
            FinalTableComponent = null;
        }
    } else {
        FinalTableComponent = "There is no data";
    }

    return (
        <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
            <LocalCircularLoader Loading={Loading} WithModalCard={true} FullSize={true} />
            {FinalTableComponent}
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default CustomerEditBankAccountTable;
