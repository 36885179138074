import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import TabNav from "@artibulles-cis/react/TabNav";
import CardModal from "@artibulles-cis/react/CardModal";
import Button from "@artibulles-cis/react/Button";
import TabNavContent from "@artibulles-cis/react/TabNavContent";
import AIReconciliationTable from "./AIReconciliationTable";
import SaleInvoicesRecommend from "./SaleInvoicesRecommend";
import SaleInvoicesAll from "./SaleInvoicesAll";
import {format, parseISO} from "date-fns";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ClassExtendTabNavMenu: {
        // width: "375px",
    },
    TabMenuDiv: {
        fontSize: "0.8rem",
    },

    ClassExtendTabContent: {
        // maxWidth: "1200px",
    },

    CardTitle: {
        display: "flex",
        justifyContent: "center",
        color: "white",
        fontWeight: "600",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        background: "rgb(255,97,3)",
        flex: "0 0 35px",
    },
    CardWindow: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        // maxWidth: "1200px",
    },
    CardMain: {
        flex: "1 1 auto",
        color: "black",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        boxSizing: "border-box",
        padding: "10px 10px",
        alignItems: "flex-start",
    },
    CardButtons: {
        flex: "0 0 50px",
        color: "black",
        display: "flex",
        width: "500px",
        alignSelf: "center",
        justifyContent: "center",
        padding: "10px 0px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "0 1 auto",
        display: "flex",
        alignItems: "center",
        // justifyContent: "flex-end",
        // padding: "0px 20px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: " 0 1 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
        margin: "0px 20px 0px 5px",
    },
    TransactionSummary: {
        padding: "5px",
        boxSizing: "border-box",
        border: "1px solid grey",
        borderRadius: "8px",
        fontSize: "0.9rem",
        marginBottom: "10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {TransactionID, HandleTransactionChangedParent, FormValues} = props;

    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/
    const [ShowReconcileTable, setShowReconcileTable] = useState(false);
    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    const HandleShowReconcileTable = () => {
        setShowReconcileTable(true);
    };
    const HandleCloseReconcileTable = () => {
        setShowReconcileTable(false);
    };
    const HandleUpdateReconconcile = () => {
        HandleTransactionChangedParent();
        setShowReconcileTable(false);
    };
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    // transaction_source;

    // counterparty_bankAccount_number;
    // counterparty_bankAccount_name;

    // location;
    // merchant;
    // communication;

    let TransactionSummaryComponent;

    if (FormValues) {
        TransactionSummaryComponent = (
            <div className={classes.TransactionSummary}>
                <div className={classes.InvoiceSummary_Line}>
                    <div className={classes.InvoiceSummary_Line_Title}>Transaction:</div>
                    <div className={classes.InvoiceSummary_Line_Value}>{FormValues.calc_RefTransaction}</div>
                    <div className={classes.InvoiceSummary_Line_Title}>Date:</div>
                    <div className={classes.InvoiceSummary_Line_Value}>{FormValues.transaction_date_Iso ? format(parseISO(FormValues.transaction_date_Iso), "dd-MMM-yyyy") : null}</div>

                    <div className={classes.InvoiceSummary_Line_Title}>Amount:</div>
                    <div className={classes.InvoiceSummary_Line_Value}>
                        {new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR", maximumFractionDigits: 2}).format(FormValues.banking_amount_transaction)}
                    </div>
                    <div className={classes.InvoiceSummary_Line_Title}>Account Number:</div>
                    <div className={classes.InvoiceSummary_Line_Value}>{FormValues.counterparty_bankAccount_number}</div>
                    <div className={classes.InvoiceSummary_Line_Title}>Account Name:</div>
                    <div className={classes.InvoiceSummary_Line_Value}>{FormValues.counterparty_bankAccount_name}</div>
                </div>
                <div className={classes.InvoiceSummary_Line}>
                    <div className={classes.InvoiceSummary_Line_Title}>Communication:</div>
                    <div className={classes.InvoiceSummary_Line_Value}>{FormValues.communication}</div>
                </div>
            </div>
        );
    }

    var ModalCardView;
    if (ShowReconcileTable === true) {
        ModalCardView = (
            <CardModal
                CardID="PurchaseItemCard"
                // ParentCompRef={Ref_TabNavContent}
                TopBarContent={<div>Transaction Reconciliation</div>}
                ClassExtendTopToolBar={classes.CardTitle}
                CloseCard={HandleCloseReconcileTable}
                CloseOnClickOutside={false}
                WithCloseButton={true}
                // CardMaxWidth="1000px"
                // CardHeight="700px"
                CardWindowPaddingVertical="10px"
                CardWindowPaddingHorizontal="10px"
                WithPerfectScrollbar={true}
                // CardWindowPaddingVertical="50px"
                ZIndex="200000"
                ShowCard={true}
                WindowBackgroundColor="rgba(0,0,0,0.3)"
                CardBackgroundColor="white"
                CardBackground="white"
                ClassExtendCard={classes.ClassExtendCard}
            >
                <div className={classes.CardWindow}>
                    <div className={classes.CardMain} style={{flex: "1 1 auto"}}>
                        <div>{TransactionSummaryComponent}</div>
                        <TabNav
                            NoLink={true}
                            MenuFullWidth={false}
                            ClassExtendTabNavMenu={classes.ClassExtendTabNavMenu}
                            TabNavMenuSeparation="Line"
                            ActiveIndicator={{
                                WithIndicator: true,
                                Position: "Bottom",
                            }}
                            TabNavMenuHeight="25px"
                            TabMenuContent={[
                                {
                                    TabMenu: (
                                        <div className={classes.TabMenuDiv}>
                                            <div className={classes.TabTitle}>AI Recommendation</div>
                                        </div>
                                    ),

                                    // TabContent: <InvoiceItemsTable FormViewMode={FormViewMode} PurchaseInvoiceID={PurchaseInvoiceID} SubmitParentForm={HandlePurchaseItemChanged} />,

                                    TabContent: <AIReconciliationTable TransactionID={TransactionID} FormValues={FormValues} HandleUpdateReconconcile={HandleUpdateReconconcile} />,
                                },
                                {
                                    TabMenu: (
                                        <div className={classes.TabMenuDiv}>
                                            <div className={classes.TabTitle}>Sales Invoices Recommended</div>
                                        </div>
                                    ),

                                    TabContent: <SaleInvoicesRecommend TransactionID={TransactionID} FormValues={FormValues} HandleUpdateReconconcile={HandleUpdateReconconcile} />,
                                },
								{
                                    TabMenu: (
                                        <div className={classes.TabMenuDiv}>
                                            <div className={classes.TabTitle}>Sales Invoices All</div>
                                        </div>
                                    ),

                                    TabContent: <SaleInvoicesAll TransactionID={TransactionID} FormValues={FormValues} HandleUpdateReconconcile={HandleUpdateReconconcile} />,
                                },
                               

                               
                            ]}
                        />
                    </div>
                </div>
            </CardModal>
        );
    }

    return (
        <TabNavContent InnerPadding="0px" ClassExtendTabContent={classes.ClassExtendTabContent}>
            {ModalCardView}
            <Button Width="170px" Height="25px" style={{margin: "5px 0px 0px 5px", padding: "0px 5px 0px 5px"}} onClick={HandleShowReconcileTable}>
                Reconcile
            </Button>
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default Component;
