import React from "react";
import {createUseStyles, useTheme} from "react-jss";
import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import CustomerEditAddressForm from "./CustomerEditAddressForm";



//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const Component = React.memo(function Component(props) {
    const {Id, CompanyId, CloseCard} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    
    

    const InitialCall = {type: "Edit", ID: Id};

    

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/mycompany/company/address`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the initial options if needed
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            CloseCard();
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;
        console.log("data", data);

        //SET THE INITIAL FORM VALUES
        let Address = data.CompanyAddress ? data.CompanyAddress : null;
        let countries, addressTypes;
        if (data.Countries && data.Countries.length > 0) {
            countries = data.Countries.map((elem) => {
                return {value: elem._id, label: elem.country_name_EN, code: elem.country_code};
            });
        }
        if (data.CompanyAddressTypes && data.CompanyAddressTypes.length > 0) {
            addressTypes = data.CompanyAddressTypes.map((elem) => {
                return {value: elem._id, label: elem.name_EN};
            });
        }

		
        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Address ? Address._id : "new",
                ref_address_type: Address ? Address.ref_address_type : null,
                region_state: Address ? Address.region_state : null,
                city: Address ? Address.city : null,
                zip_code: Address ? Address.zip_code : null,
                street_line1: Address ? Address.street_line1 : null,
                street_line2: Address ? Address.street_line2 : null,
                is_defaultAddress: Address ? Address.is_defaultAddress : null,
                ref_country: Address ? Address.ref_country : null,
                location: Address ? Address.location : null,
            },
            SelectOptions: {
                ref_country: countries,
                ref_address_type: addressTypes,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Address: {
                        Updatable: {
                            ref_myCompany: CompanyId,
                            ref_address_type: values.ref_address_type,
                            region_state: values.region_state,
                            city: values.city,
                            zip_code: values.zip_code,
                            street_line1: values.street_line1,
                            street_line2: values.street_line2,
                            is_defaultAddress: values.is_defaultAddress,
                            ref_country: values.ref_country,
                            location: values.location,
                        },
                    },
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/

    return (
        <div>
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                // DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={CustomerEditAddressForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={{Active: false, Text: "Save draft"}}
                ButtonSaveAndQuit={{Active: true, Text: "Save And Quit"}}
                ButtonQuitNoSave={{Active: true, Text: "Exit"}}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </div>
    );

    /***************** RENDER ******************/
});

export default Component;
