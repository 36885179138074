import React from "react";
import PropTypes from "prop-types";
//eslint-disable-next-line
import clsx from "clsx";
import {createUseStyles, useTheme} from "react-jss";
import Button from "@artibulles-cis/react/Button";
import ReactTooltip from "react-tooltip";
//eslint-disable-next-line
const LockedstylesWithProps = (props) => {
    return {};
};

//eslint-disable-next-line
const Lockedstyles = {};

//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
}));

/**
 * Component Description
 */

const FormSubmitButtons = React.forwardRef(function FormSubmitButtons(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/
    const {
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        HandleSubmitForm,
        Invalid,
        Pristine,
        DisableToolTip,
        FormViewMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    //const [val, setVal] = useState();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    var ButtonSaveAsDraftComp, ButtonSaveAndQuitComp, ButtonQuitNoSaveComp, ButtonResetComp, ButtonPublishComp, ButtonDeleteComp, ButtonNextComp, ButtonPreviousComp;

    if (ButtonSaveAsDraft.Active) {
        ButtonSaveAsDraftComp = (
            <React.Fragment>
                <Button
                    data-tip="Save Draft"
                    data-for="ButtonSaveDraft"
                    disabled={Invalid || Pristine}
                    Width={ButtonSaveAsDraft.Width ? ButtonSaveAsDraft.Width : "150px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    ClassExtendButton={classes.ClassExtendButton}
                    onClick={() => HandleSubmitForm("SaveDraft")}
                >
                    {ButtonSaveAsDraft.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonSaveDraft" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonSaveAndQuit.Active) {
        ButtonSaveAndQuitComp = (
            <React.Fragment>
                <Button
                    data-tip="Save"
                    data-for="ButtonSaveAndQuit"
                    disabled={Invalid || Pristine}
                    Width={ButtonSaveAndQuit.Width ? ButtonSaveAndQuit.Width : "160px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    onClick={() => HandleSubmitForm("SaveAndQuit")}
                >
                    {ButtonSaveAndQuit.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonSaveAndQuit" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonQuitNoSave.Active) {
        ButtonQuitNoSaveComp = (
            <React.Fragment>
                <Button
                    data-tip="Quit Without Saving"
                    data-for="ButtonQuitNoSave"
                    disabled={Invalid}
                    Width={ButtonQuitNoSave.Width ? ButtonQuitNoSave.Width : "160px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    onClick={() => HandleSubmitForm("QuitNoSave")}
                >
                    {ButtonQuitNoSave.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonQuitNoSave" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonReset.Active) {
        ButtonResetComp = (
            <React.Fragment>
                <Button
                    data-tip="Reset Form"
                    data-for="ButtonReset"
                    disabled={Pristine}
                    Width={ButtonReset.Width ? ButtonReset.Width : "160px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    onClick={() => HandleSubmitForm("reset")}
                >
                    {ButtonReset.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonReset" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonPublish.Active) {
        ButtonPublishComp = (
            <React.Fragment>
                <Button
                    data-tip="Publish"
                    data-for="ButtonPublish"
                    disabled={Invalid || Pristine}
                    Width={ButtonPublish.Width ? ButtonPublish.Width : "150px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    onClick={() => HandleSubmitForm("Publish")}
                >
                    {ButtonPublish.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonPublish" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonDelete.Active) {
        ButtonDeleteComp = (
            <React.Fragment>
                <Button
                    data-tip="Delete"
                    data-for="ButtonDelete"
                    Width={ButtonDelete.Width ? ButtonDelete.Width : "150px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    onClick={() => HandleSubmitForm("Delete")}
                >
                    {ButtonDelete.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonDelete" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonNext.Active) {
        ButtonNextComp = (
            <React.Fragment>
                <Button
                    data-tip="Delete"
                    data-for="ButtonDelete"
                    Width={ButtonNext.Width ? ButtonNext.Width : "150px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    disabled={Invalid}
                    onClick={() => HandleSubmitForm(ButtonNext.Save === true ? "NextSave" : "NextQuit")}
                >
                    {ButtonNext.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonDelete" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    if (ButtonPrevious.Active) {
        ButtonPreviousComp = (
            <React.Fragment>
                <Button
                    data-tip="Delete"
                    data-for="ButtonDelete"
                    Width={ButtonPrevious.Width ? ButtonPrevious.Width : "150px"}
                    Height="30px"
                    Background="rgb(66, 165, 245)"
                    color="black"
                    ClassExtendButton={classes.ClassExtendButton}
                    disabled={Invalid}
                    onClick={() => HandleSubmitForm(ButtonPrevious.Save === true ? "PreviousSave" : "PreviousQuit")}
                >
                    {ButtonPrevious.Text}
                </Button>
                <ReactTooltip disable={DisableToolTip} id="ButtonDelete" place="bottom" type="dark" effect="solid" />
            </React.Fragment>
        );
    }

    return (
        <div className={classes.ButtonBar} style={{display: `${FormViewMode === "Edit" ? "flex" : "none"}`}}>
            {ButtonPreviousComp}
            {ButtonNextComp}
            {ButtonSaveAsDraftComp}
            {ButtonSaveAndQuitComp}
            {ButtonQuitNoSaveComp}
            {ButtonPublishComp}
            {ButtonResetComp}
            {ButtonDeleteComp}
        </div>
    );
    /****************************** RENDER *********************/
});

FormSubmitButtons.defaultProps = {
    MyProp: null,
};

FormSubmitButtons.propTypes = {
    /**
     * MyProp :
     */
    MyProp: PropTypes.string,
};

export default FormSubmitButtons;
