import React, {useEffect, useState, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";

import FormWithApi from "../../../../../../../artibulles-cis/FormWithApi/FormWithApi";
import {useHistory, useLocation} from "react-router-dom";
import {toast} from "react-toastify";

import TabNavContent from "@artibulles-cis/react/TabNavContent";
import CustomerEditForm from "./CustomerEditForm";
import _ from "lodash";
const {format, parseISO} = require("date-fns");
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({}));

const CustomerEdit = React.memo(function CustomerEdit(props) {
    const {Id} = props;
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/
    const Ref_TabNavContent = React.useRef(null);
    const DevMode = process.env.NODE_ENV === "development" ? true : false;
    const ProdURL = DevMode ? "" : "https://artibulles-bms.artibulles.com";

    const Location = useLocation();
    const PathName = Location.pathname;

    var IdFinal;
    if (Id) {
        IdFinal = Id;
    } else {
        IdFinal = PathName.replace("/crm/customers/customer/", "").replace("/view", "");
    }

    const InitialCall = {type: "Edit", ID: IdFinal};

    const History = useHistory();

    const FinalAPIUrlRelative = {
        MAIN: `${ProdURL}/api/companies/company/edit`,
        DELETE: "",
        PUT: "",
        POST: "", //Call when creating a new Entry to send back the options for the select if any
    };

    //Redirect URL when Leaving the Form
    const HandleRedirection = (args) => {
        console.log("args", args);
        if (args.typeOfSubmit === "SaveAndQuit" || args.typeOfSubmit === "QuitNoSave") {
            let Id = args.data.data.Company._id;
            if (IdFinal === "new") {
                //We redirect to edit to reset the form and get the right buttons and editing
                History.push(`/crm/customers/customer/${Id}/edit`);
            }

            // History.push(`/crm/customers/customer/${Id}/view`);
        }
    };
    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/
    const FormatDataServerToForm = (response) => {
        let data = response.data;

        var CompanySiteTypes;
        if (data.CompanySiteTypes && data.CompanySiteTypes.length > 0) {
            CompanySiteTypes = data.CompanySiteTypes.map((elem) => {
                return {value: elem._id, label: elem.name_EN};
            });
        }

        var CompanySiteTypesInitial = data.Company.ref_companySiteType;
        if (!CompanySiteTypesInitial) {
            CompanySiteTypesInitial = _.find(data.CompanySiteTypes, {name_EN: "Headquarters"});
            if (CompanySiteTypesInitial) {
                CompanySiteTypesInitial = CompanySiteTypesInitial._id;
            }
        }

        var parentId = [];
        if (data.Companies && data.Companies.length > 0) {
            parentId = data.Companies.map((elem) => {
                return {value: elem._id, label: elem.company};
            });
        }

        //Handling the Addresses
        var Addresses = [];
        if (data.CompanyAddresses && data.CompanyAddresses.Addresses && data.CompanyAddresses.Addresses.length > 0) {
            Addresses = data.CompanyAddresses.Addresses;
        }
        //We will need to do something with the initial country and address type using the select...

        var countries;
        if (data.CompanyAddresses && data.CompanyAddresses.ref_country && data.CompanyAddresses.ref_country.length > 0) {
            countries = data.CompanyAddresses.ref_country.map((elem) => {
                return {value: elem._id, label: elem.country_name_EN, code: elem.country_code};
            });
        }
        var CompanyAddressTypes;
        if (data.CompanyAddresses && data.CompanyAddresses.ref_address_type && data.CompanyAddresses.ref_address_type.length > 0) {
            CompanyAddressTypes = data.CompanyAddresses.ref_address_type.map((elem) => {
                return {value: elem._id, label: elem.name_EN};
            });
        }

        //Simulating the address
        var CompanyAddresses = {
            Addresses: Addresses,
            ref_country: countries,
            ref_address_type: CompanyAddressTypes,
        };

        //SET THE INITIAL FORM VALUES
        let Company = data.Company ? data.Company : null;

        var InitialFormValues = {
            InitiaFormValues: {
                ApiID: Company ? Company._id : "new",
                company: Company ? Company.company : null,
                companyLogoPath: Company ? Company.companyLogoPath : null,
                description: Company ? Company.description : null,
                ref_companySiteType: CompanySiteTypesInitial ? CompanySiteTypesInitial : null,
                isSubsidiary: Company ? (Company.isSubsidiary && Company.isSubsidiary === true ? true : false) : false,
                is_supplier: Company ? (Company.is_supplier && Company.is_supplier === true ? true : false) : null,
                is_customer: Company ? (Company.is_customer && Company.is_customer === true ? true : false) : null,
                siteName: Company ? Company.siteName : null,
                parentId: Company ? Company.parentId : null,
                CompanyAddresses: CompanyAddresses,
                status: Company ? Company.status : null,
                vat_number: Company ? Company.vat_number : null,
                db_status: Company ? Company.db_status : null,
                db_locked: Company ? Company.db_locked : null,
                db_locked_by: Company ? Company.ref_db_locked_by : null,
                db_locked_at: Company ? Company.db_locked_at : null,
                owner: Company ? Company.ref_owner : null,
                createdAt: Company ? (Company.createdAt ? format(parseISO(Company.createdAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
                updatedAt: Company ? (Company.updatedAt ? format(parseISO(Company.updatedAt), "MMM dd,yyyy HH:mm:ss") : null) : null,
            },
            SelectOptions: {
                ref_companySiteType: CompanySiteTypes,
                parentId: parentId,
            },
            SubOptionsFull: {},
            // FilesUploaders: {
            //     fileuploaderName: data.tutorial.Files,
            // },
        };
        return InitialFormValues;
    };
    /***************** FORMATTING THE DATA COMING FROM THE SERVER TO INITATE THE FORM ****************/

    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    const FormatDataFormToServer = (FullSubmission) => {
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY
        // console.log("FormatDataFormToServer", FullSubmission);
        //USE THIS TO CHECK YOUR DATA FORMAT AND FORMATTING THE DATA CORRECTLY

        if (FullSubmission) {
            //If data were sent back from the form
            let values = FullSubmission.values;

            if (values) {
                let ServerData = {
                    API_id: values.ApiID,
                    Company: {
                        Updatable: {
                            company: values.company,
                            parentId: values.parentId ? values.parentId : null,
                            // isSubsidiary: values.isSubsidiary,
                            ref_companySiteType: values.ref_companySiteType,
                            company_logo_path: values.company_logo_path,
                            description: values.description,
                            status: values.status,
                            is_supplier: values.is_supplier,
                            is_customer: values.is_customer,
                            vat_number: values.vat_number,
                        },
                    },
                    CompanyAddresses: values.CompanyAddresses,
                };
                //this is what will be sent back to the server
                return ServerData;
            }
        }
    };
    /***************** FORMATTING THE DATA WHEN SUBMITTING THE FORM BEFORE SENDING BACK TO THE SERVER ****************/
    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    let ButtonSaveAsDraftComp, ButtonSaveAndQuitComp, ButtonQuitNoSaveComp;

    if (IdFinal === "new") {
        ButtonSaveAsDraftComp = {Active: false, Text: "Create customer", Width: "180px"};
        ButtonSaveAndQuitComp = {Active: true, Text: "Create customer", Width: "180px"};
        ButtonQuitNoSaveComp = {Active: true, Text: "Exit"};
    } else {
        console.log("Final Component editing");
        ButtonSaveAsDraftComp = {Active: true, Text: "Save draft"};
        ButtonSaveAndQuitComp = {Active: true, Text: "Save And Quit"};
        ButtonQuitNoSaveComp = {Active: true, Text: "Exit"};
    }

    return (
        <TabNavContent
            TabRef={Ref_TabNavContent}
            ClassExtendTabContent={classes.ClassExtendTabContent}
            //   Border={ContentBorder}
            // Background="blue"
            //   InnerPadding={ContentPadding}
            //   MaxWidth={ContentMaxWidth}
            // Height="100%"
            // PerfectScrollBar={{Active: true, ScrollX: false, ScrollY: true}}
            // ScrollX="hidden"
            // ScrollY="auto"
        >
            <FormWithApi
                // WithCardModal={Id ? false : true}
                // WithCardModal={ue}
                CardModalOptions={{
                    ParentCompRef: Ref_TabNavContent,
                    // CloseCard={HandleCloseCardCreate}
                    // WithPerfectScrollbar={true}
                    // CardWindowPaddingVertical="10px"
                    // CardWindowPaddingHorizontal="10px"
                    ShowCard: true,
                    WindowBackgroundColor: "rgba(0,0,0,0.3)",
                    CardBackgroundColor: "white",
                    CardBackground: "white",
                    ClassExtendCard: classes.ClassExtendCard,
                }}
                // OpenInViewMode={Id ? (Id === "new" ? false : true) : false}
                // SwitchEditMode="FieldClick"
                toast={toast}
                // DevMode={true}
                InitialCall={InitialCall}
                // RedirectUrlOnQuit={RedirectUrlOnQuit}
                HandleRedirection={HandleRedirection}
                APIUrlRelative={FinalAPIUrlRelative}
                // APIUrlAbsolute = "null"
                FormatDataServerToForm={FormatDataServerToForm}
                FormatDataFormToServer={FormatDataFormToServer}
                FormComponent={CustomerEditForm}
                DisableToolTip={false}
                // LocaleToastMessage = en"
                // ToastDeleteConfirmation = {true}
                // ToastPublishConfirmation = {true}
                // ToastQuitWihtouSaveConfirmation = {true}
                ToastSavedMessage="Saved"
                // ToastDeletedMessage = "Delete me"
                // ToastPublishedMessage = "Publish Me"
                ButtonSaveAsDraft={ButtonSaveAsDraftComp}
                ButtonSaveAndQuit={ButtonSaveAndQuitComp}
                ButtonQuitNoSave={ButtonQuitNoSaveComp}
                ButtonDelete={{Active: false, Text: "Delete"}}
                ButtonPublish={{Active: false, Text: "publier"}}
                ButtonReset={{Active: false, Text: "reseter"}}
                ButtonNext={{Active: false, Text: "Next", Save: false, FormConditions: {invalid: false, pristine: false}}}
                ButtonPrevious={{Active: false, Text: "Previous", Save: false, FormConditions: {invalid: false, pristine: false}}}
            />
        </TabNavContent>
    );

    /***************** RENDER ******************/
});

export default CustomerEdit;
