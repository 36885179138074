/* eslint-disable */
import React, {useEffect, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";
import {Route, Switch, Redirect} from "react-router-dom";

import SalesInvoicesTabNavRouter from "./01SalesInvoicesTabNavRouter";

import InvoiceEdit from "./01InvoiceEdit/InvoiceEdit";
import PaymentTrackingTabNavRouter from "./02PaymentTrackingTabNavRouter";
//eslint-disable-next-line
const styles = createUseStyles((theme) => ({
    TabTitle_WithIcon: {
        display: "flex",
        alignItems: "center",
    },
    TabTitle_WithIcon_Title: {
        marginLeft: "5px",
    },
}));

const Component = React.memo(function Component(props) {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** CONST ******************/

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return (
        <React.Fragment>
            <Switch>
                <Route exact path="/financials/saleordersandinvoices/invoices">
                    <Redirect to="/financials/saleordersandinvoices/invoices/tables" />
                </Route>
                <Route path="/financials/saleordersandinvoices/invoices/tables">
                    <SalesInvoicesTabNavRouter />
                </Route>
                <Route exact path="/financials/saleordersandinvoices/invoices/invoice/new">
                    <InvoiceEdit Id="new" RedirectToOnClosingUrl="/financials/saleordersandinvoices/invoices/tables" />
                </Route>
                <Route path="/financials/saleordersandinvoices/invoices/payments">
                    <PaymentTrackingTabNavRouter />
                </Route>
            </Switch>
        </React.Fragment>
    );

    /***************** RENDER ******************/
});

export default Component;
