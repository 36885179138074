/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import {createUseStyles, useTheme} from "react-jss";
import ReactTooltip from "react-tooltip";
import Button from "@artibulles-cis/react/Button";
import FormDev from "../../../../../../artibulles-cis/FormDev/FormDev";
import _ from "lodash";
import FormSubmitButtons from "../../../../../../artibulles-cis/FormSubmitButtons/FormSubmitButtons";
import ExpandableBox from "../../../../../../artibulles-cis/ExpandableBox/ExpandableBox";
import clsx from "clsx";
/************** NEW COMPONENTS **************/

import {PDF} from "@artibulles-cis/react-icons";
import PerfectScrollBarWrapper from "@artibulles-cis/react/PerfectScrollBarWrapper";

import FormCompontent from "../../../../../../artibulles-cis/FormComponent/FormCompontent";
import CheckBox from "../../../../../../artibulles-cis/CheckBox/CheckBox";
import FileUploader from "../../../../../../artibulles-cis/FileUploader/FileUploader";
import {WarningTriangleRed, Contract, AvatarV2, Login} from "@artibulles-cis/react-icons";
import {formatISO, parseISO, add, lastDayOfMonth, setDate, setYear, setMonth, getDate, getYear, getMonth} from "date-fns";

import ContactEditDetailsTabNav from "./ContactEditFormParts/ContactEditDetailsTabNav";
/************** NEW COMPONENTS **************/

const styles = createUseStyles((theme) => ({
    FormWrapper: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: "1px solid grey",
        padding: "0px 0px",
        height: "100%",
        color: "black",
        overflow: "hidden",
        textAlign: "left",
    },
    FormFlexHorizontal: {
        display: "flex",
        height: "100%",
        overflow: "hidden",
        boxSizing: "border-box",
    },
    FormPDFViewer: {
        flex: "1 1 auto",
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
    },
    FormMainScroll: {
        flex: "1 0 1400px",
        height: "100%",
        // overflowY: "auto",
        boxSizing: "border-box",
        // padding: "0px 10px",
        border: "1px solid grey",
        maxWidth: "1400px",
        display: "flex",
        flexDirection: "column",
    },

    FormTitle_Wrapper: {
        display: "flex",
        // justifyContent: "space-around",
        alignItems: "center",
        height: "35px",
        boxSizing: "border-box",
        borderBottom: "1px solid rgb(200,200,200)",
        // padding: "3px 20px 3px 0px ",
        background: "rgb(255,97,3)",
        color: "white",
    },
    FormTitle_Box: {
        flex: "0 1 auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "650px",
        // height: "30px",
        // border: "1px solid rgb(200,200,200)",
        borderRadius: "3px",
        boxSizing: "border-box",
        padding: "0px 10px",
        height: "100%",
    },
    ActionButton: {
        display: "flex",
        margin: "0px 5px",
    },
    FormTitle_Logo_Wrapper: {
        flex: "0 0 30px",
        boxSizing: "border-box",
        // borderRadius: "3px",
        // padding: "4px",
        // border: "1px solid grey",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    FormTitle_Logo: {
        width: "43px",
        height: "auto",
    },
    FormTitle_Title_Wrapper: {
        flex: "1 1 auto",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 0px 5px",
        alignItems: "center",
    },
    FormTitle_Title: {
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "18px",
        margin: "0px 5px 0px 0px",
    },
    FormTitleMain: {
        flex: "0 0 1400px",
        display: "flex",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightStatus: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
    },
    FormTitleRightInfo: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        boxSizing: "border-box",
        padding: "0px 45px 0px 0px",
    },
    FormTitleRightStatus_Main: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
    },
    FormTitleRightStatus_RecordInfo: {
        flex: "0 1 auto",
        display: "flex",
        flexDirection: "column",
        fontSize: "0.7rem",
        alignItems: "flex-end",
        marginLeft: "20px",
    },
    FormTitleRightStatus_RecordInfo_Line: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Title: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-start",
    },
    FormTitleRightStatus_RecordInfo_Value: {
        flex: "1 1 auto",
        display: "flex",
        justifyContent: "flex-end",
    },

    InvoiceDetailsSection: {
        flex: "1 1 auto",
        // maxHeight: "500px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "5px 0px",
        boxSizing: "border-box",
        // height : "100%"
        // maxWidth: "1400px",
        // padding: "10px 10px 0px 10px",
    },
    FormSection: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        borderBottom: "2px solid grey",
        padding: "10px",
        marginBottom: "20px",
    },
    FormSectionTitle: {
        margin: "0px 0px 10px 0px",
    },
    FormSectionContent: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 0px 0px 5px",
    },
    FormFieldCenteredMaxWidth: {
        flex: "0 1 600px",
    },
    FormMultilineFlex: {
        display: "flex",
        flexDirection: "row",
        margin: "2px 0px",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
    },
    FormFieldMultiLineMaxWidth: {
        flex: "0 1 300px",
        margin: "0px 0px 0px 0px",
    },
    FormFieldMultiLineLeft: {
        flex: "0 1 300px",
    },
    FormField: {
        flex: "0 1 600px",
        margin: "2px 5px 2px 5px",
    },
    FormFieldCheckBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },

    FormFieldSwitchBoxAligned: {
        flex: "0 1 600px",
        margin: "0px 10px 0px 10px",
    },
    ButtonBar: {
        display: "flex",
        flexWrap: "wrap",
        flex: "1 1 700px",
        justifyContent: "flex-start",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        padding: "8px 20px 8px 20px",
    },
    tooltipP: {
        fontSize: "14px",
        padding: "0px",
    },
    FormTopFlex: {
        flex: "1 1 auto",
        display: "flex",
        width: "100%",
        boxSizing: "border-box",
        position: "relative",
        // padding: "0px 10px 0px 10px",
    },
    FormTopLeft: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        borderRight: "1px solid grey",
        borderBottom: "1px solid grey",
        padding: "0px 0px 10px 10px",
    },
    FormTopRight: {
        flex: "0 0 350px",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
        justifyContent: "flex-end",
        borderBottom: "1px solid grey",
        padding: "0px 10px 0px 0px",
    },
    PDFViewerIcon: {
        margin: "0px",
        display: "flex",
        flex: "0 0 30px",
        justifyContent: "flex-end",
    },
    InvoiceSummaryBox: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "flex-end",
        boxSizing: "border-box",
        margin: "8px 0px 8px 10px",
        padding: "5px 5px",
        borderRadius: "5px",
        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    FormLeft: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        position: "relative",
    },
    FormErrorMainMessage: {
        display: "flex",
        padding: "5px",
        boxSizing: "border-box",
        color: "orange",
    },
    ClassExtendsContentOpened: {
        padding: "0px 0px 0px 10px",
    },

    ButtonArrowStart: {
        background: "none",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowStart_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
    },
    ButtonArrowMiddle: {
        background: "none",
        padding: "0px 16px",
        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:after": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            left: "auto",
            bottom: "auto",
            right: "-10px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "16px solid transparent",
            borderBottom: "16px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowMiddle_Active: {
        background: "rgb(66, 165, 245)",
        "&:after": {
            borderLeft: "10px solid rgb(66, 165, 245)",
        },
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },

    ButtonArrowEnd: {
        background: "transparent",
        padding: "0px 16px",

        position: "relative",
        minHeight: "34px",
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        borderWidth: "0",
        "&:before": {
            height: "0",
            boxSizing: "border-box",
            padding: "0",
            content: '""',
            display: "block",
            position: "absolute",
            top: "0",
            right: "auto",
            bottom: "auto",
            left: "0px",
            borderTop: "17px solid transparent",
            borderBottom: "17px solid transparent",
            borderRight: "none",
            borderLeft: "10px solid transparent",
            zIndex: "10",
        },
    },
    ButtonArrowEnd_Active: {
        background: "rgb(66, 165, 245)",
        "&:before": {
            borderLeft: "10px solid rgb(255,97,3)",
        },
    },
    //ARROWS
    SupplierAddress: {
        display: "flex",
        flexDirection: "column",
    },
    SupplierAddressLine: {
        display: "flex",
    },
    InvoiceDetailsTabNavWrapper: {
        // maxWidth: "1400px",
        padding: "10px 0px 2px 0px",
    },
    InvoiceSummary: {
        display: "flex",

        // padding: "0px 0px 0px 5px",
        width: "290px",
        alignSelf: "flex-start",
        flexDirection: "column",
        justifyContent: "flex-start",
        fontSize: "0.8rem",
        padding: "0px 5px 0px 0px",
    },
    InvoiceSummary_Line: {
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "row",
        justifiContent: "space-around",
        padding: "2px 0px",
    },
    InvoiceSummary_Line_Title: {
        flex: "0 0 180px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 15px 0px 0px",
        boxSizing: "border-box",
    },
    InvoiceSummary_Line_Value: {
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
    },
    UploadButton: {
        fontSize: "0.8rem",
        padding: "0px",
        margin: "0px",
    },
    TitleButtons: {
        fontSize: "0.9rem",
        padding: "0px",
        margin: "0px",
    },
    SummaryInvoiceError: {
        color: "orange",
        fontWeight: "600",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px 0px 0px 0px",
        boxSizing: "border-box",
        padding: "10px",
    },
}));

const Component = React.memo(function Component(props) {
    const {
        DevMode = true, //used to check the form content wihtout sending it to the server
        FormData, //Load the Data into the form FormData = {SelectOptions:{option1 : [value :"", label :""]},InitiaFormValues : {}}
        OnSubmitExternal, //A function that hanldes the sumbission const OnSubmitExternal = FullSubmission=>{console.log(FullSubmission)}
        DisableToolTip = false, //To Enable or disable the Tooltip if required
        ButtonSaveAsDraft,
        ButtonSaveAndQuit,
        ButtonQuitNoSave,
        ButtonDelete,
        ButtonPublish,
        ButtonReset,
        ButtonNext,
        ButtonPrevious,
        OpenInViewMode,
        SwitchEditMode,
    } = props;

    const theme = useTheme();
    const classes = styles({...props, theme});
    const Ref_Status = useRef(null);
    const Ref_ManualPaymentConfirmation = useRef(null);
    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    const [FormDataInitReady, setFormDataInitReady] = useState(false); //PREVENT RENDERING THE FORM WHEN DATA IS NOT READY YET
    const [InitialFormValues, setInitialFormValues] = React.useState({}); //INITIAL FORM VALUES
    const [FormValues, setFormValues] = React.useState({}); //FINAL FORM VALUES
    const [FieldOptions, setFieldOptions] = React.useState({}); //FORM OPTIONS FOR SELECT
    const [NewFieldOptions, setNewFieldOptions] = React.useState({}); //Used for CreatableSelect to pass back to the server
    const [FieldSubOptionsFull, setFieldSubOptionsFull] = React.useState({}); //FORM SUBOPTIONS FOR SELECT
    const [FilesUploaders, setFilesUploaders] = React.useState({});
    const [Invalid, setInvalid] = useState(false); //Form has invalid fields
    const [Pristine, setPristine] = useState(true); //Form has been edited by the user -> Pristie === false
    const [PristineDetails, setPristineDetails] = useState({});
    const [FormViewMode, setFormViewMode] = useState();
    const [InitialFormErrors, setInitialFormErrors] = useState({});
    const [FormErrors, setFormErrors] = useState({}); //HNDLING FORM ERRORS
    const [InitialFormErrorMain, setInitialFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [FormErrorMain, setFormErrorMain] = useState(); //HNDLING FORM ERROR GLOBAL MESSAGE
    const [ShowViewPDF, setShowViewPDF] = useState(false); //HNDLING FORM ERROR GLOBAL MESSAGE

    const [ShowPaymentReview, setShowPaymentReview] = useState(false);
    const [ShowInfoPart, setShowInfoPart] = useState(false);
    const [ShowReconcileTransactionCard, setShowReconcileTransactionCard] = useState(false);
    const [PDFFileURL, setPDFFileURL] = useState(null);

    /**************** REQUIRED STATE (DONT NEED TO BE UPDATED NORMALLY) *****************/
    /**************** VIEW OR EDIT MODE CONTROL *****************/
    useEffect(() => {
        if (OpenInViewMode === true) {
            if (SwitchEditMode === "EditButton") {
                setFormViewMode("EditButton");
            }
            if (SwitchEditMode === "FieldClick") {
                setFormViewMode("FieldClick");
            } else {
                setFormViewMode("View");
            }
        } else {
            setFormViewMode("Edit");
        }
    }, [OpenInViewMode, SwitchEditMode]);

    useEffect(() => {
        if (InitialFormValues && InitialFormValues.Status) {
            Ref_Status.current = InitialFormValues.Status;
            if (InitialFormValues.Status === "Draft") {
                setFormViewMode("Edit");
            }
            setShowInfoPart(true);
        }
    }, [InitialFormValues]);

    useEffect(() => {
        if (FilesUploaders && FilesUploaders.Statements_File_Path && FilesUploaders.Statements_File_Path.length > 0) {
            let FileUrl;
            if (FilesUploaders.Statements_File_Path && FilesUploaders.Statements_File_Path.length > 0) {
                FileUrl = FilesUploaders.Statements_File_Path[0].serverPath;
            }
            if (FileUrl) {
                setPDFFileURL(FileUrl);
                //And by Default we show the viewer
                setShowViewPDF(true);
            } else {
                setPDFFileURL(null);
            }
        } else {
            setPDFFileURL(null);
        }
    }, [FilesUploaders]);

    /**************** VIEW OR EDIT MODE CONTROL *****************/
    /**************** INITIAL FORM VALUES AND OPTIONS *****************/
    React.useEffect(() => {
        if (FormData) {
            if (FormData.SelectOptions) {
                //Cloning the object ????Why
                let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
                setFieldOptions(LoadData);
            }
            if (FormData.InitiaFormValues) {
                setInitialFormValues(FormData.InitiaFormValues);
                setFormValues(FormData.InitiaFormValues);
            }
            if (FormData.SubOptionsFull) {
                setFieldSubOptionsFull(FormData.SubOptionsFull);
            }
            if (FormData.FilesUploaders) {
                //Cloning the object
                let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
                setFilesUploaders(filesinit);
            }
            if (FormData.InitiaFormErrors) {
                setInitialFormErrors(FormData.InitiaFormErrors);
                setFormErrors(FormData.InitiaFormErrors);
            }
            if (FormData.InitialFormErrorMain) {
                setInitialFormErrorMain(FormData.InitialFormErrorMain);
                setFormErrorMain(FormData.InitialFormErrorMain);
                //Set the form to invalid
                setInvalid(true);
            }
            setFormDataInitReady(true);
        }
    }, [FormData]);

    /**************** INITIAL FORM VALUES AND OPTIONS *****************/

    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/
    const handleSelectChange = (field, SelectedDetails) => {
        //Hanlding the select change
        //Checking Pristine

        let FieldPristine = SelectedDetails.Pristine;
        let SelectedValues = SelectedDetails.Value;

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        var FinalPristine = true;

        //Checking Pristine using the FieldResponse
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        setPristineDetails(UpdatedPristineDetails);
        setPristine(FinalPristine);
        var UpdatedFormValues = {...FormValues, ...{[field]: SelectedValues}};
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        /************* IF NO CONDITIONAL SELECT USE THIS *****************/
        setFormValues(UpdatedFormValues);
        /************* IF NO CONDITIONAL SELECT USE THIS *****************/

        /************* CONDITIONAL SELECT *****************/
        //To prevent having to deal with different server configuration, the logic to implement here must be tailored to your needs

        /*Option 1 :
         *Updating the options of a subfield based on the select value ->
         *If you use this, don't forget to specify that the conditional field is disabled if the parentField is empty or null
         */

        if (field === "PaymentMethod") {
            if (SelectedValues === "01" || SelectedValues === "02") {
                //BankAccount
            } else {
                //Others
                //We reset the Communication and the BankAccount
                UpdatedFormValues.CounterpartyBankAccount_Id = null;
                UpdatedFormValues.InvoicePaymentExpectedCommunication = null;
            }
        }
        if (field === "ConditionalSelect2") {
            //We update the Options of the ConditionalSelect3
            let SubOptions = [];
            FieldSubOptionsFull.ConditionalSelect2.forEach((elem) => {
                if (elem.ref_ConditionalSelect2 === SelectedValues) {
                    let newsub = {value: elem.id, label: elem.name};
                    SubOptions.push(newsub);
                }
            });
            setFieldOptions({...FieldOptions, ...{ConditionalSelect3: SubOptions}});
            //We also need to reset the Conditional 3;
            setFormValues({...UpdatedFormValues, ...{ConditionalSelect3: null}});
        }

        ValidateForm(field, SelectedValues);
    };
    /**************** HANDLING SELECT CHANGE - INCLUDING SUB-SELECT *****************/

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    const HandleUpdateFormValues = (field, values) => {
        let value = values.Value;
        let formattedValue = values.FormattedValue;
        let FieldPristine = values.Pristine;

        //Checking Pristine

        // Update the pristine based on the Field
        var FinalPristine = true;
        let PristineKeys = _.keys(PristineDetails);
        let UpdatedPristineDetails = PristineDetails;

        if (PristineKeys && PristineKeys.length > 0) {
            //Going through all the pristine fields to check if one of them is false
            var CurrentFieldHandled = false;
            PristineKeys.forEach((key) => {
                if (key === field) {
                    CurrentFieldHandled = true;
                    //Update the pristine value
                    UpdatedPristineDetails[field] = FieldPristine;
                    if (FieldPristine === false) {
                        FinalPristine = false;
                    }
                } else {
                    //just checking if false;
                    if (UpdatedPristineDetails[key] === false) {
                        FinalPristine = false;
                    }
                }
            });
            if (CurrentFieldHandled === false) {
                //The key wasn't there so we need to add it
                if (FieldPristine === false) {
                    UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                    FinalPristine = false;
                }
            }
        } else {
            //First field to be touched
            if (FieldPristine === false) {
                UpdatedPristineDetails = {...UpdatedPristineDetails, ...{[field]: false}};
                FinalPristine = false;
            }
        }
        var UpdateFormValues = {[field]: value};

        if (field === "StatementMonth") {
            let InitialDate = parseISO(value);

            let YearStatement;
            if (FormValues.StatementYear) {
                YearStatement = getYear(parseISO(FormValues.StatementYear));
                let StatementDateISO = lastDayOfMonth(setYear(InitialDate, YearStatement));

                UpdateFormValues.StatementDateIso = formatISO(StatementDateISO);
            }
        }
        if (field === "StatementYear") {
            let InitialDate = parseISO(value);

            let MonthStatement;
            if (FormValues.StatementMonth) {
                MonthStatement = getMonth(parseISO(FormValues.StatementMonth));

                let StatementDateISO = lastDayOfMonth(setMonth(InitialDate, MonthStatement));

                UpdateFormValues.StatementDateIso = formatISO(StatementDateISO);
            }
        }

        if (field === "ManualTempTotalAmount") {
            console.log("Edit Form", field, value, formattedValue);
        }

        setPristineDetails(UpdatedPristineDetails);
        setFormValues({...FormValues, ...UpdateFormValues});
        setPristine(FinalPristine);

        //Manual update the pristine
        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/
        // let FormValuesKeys = _.keys(FormValues);
        // var FinalPristine = true;

        // if (FormValuesKeys && FormValuesKeys.length > 0) {
        //     FormValuesKeys.forEach((key) => {
        //         let InitialValue = InitialFormValues[key];

        //         var Value;
        //         if (key !== field) {
        //             Value = FormValues[key];
        //         } else {
        //             //Check that the modified field is not the same as the InitalValue (The value has not changed yet)
        //             Value = value;
        //         }
        //         if (InitialValue === undefined) {
        //             InitialValue = null; //We set it to null to be able to compare to the Input Value that is set to null
        //             //It means that there was no initialValue passed to the form (that's the case when it is new)
        //         }
        //         if (InitialValue !== Value) {
        //             FinalPristine = false;
        //         }
        //     });
        // }

        /************* DONT MODIFY THIS LOGIC OR THE PRISTINE AND INVALID WILL BE LOST *****************/

        //Confirm Errors
        ValidateForm(field, value);
        HandleFiedsChange(field, value);
    };

    const HandleUpdateErrors = (field, error) => {
        // console.log("HandleUpdateErrors", field, error);
        //We need to check if there is an error or not
        const ErrorsKeys = _.keys(FormErrors);
        var FinalErrors = {};
        var InvalidTemp = false;
        if (ErrorsKeys && ErrorsKeys.length > 0) {
            // If there is an error
            ErrorsKeys.forEach((key) => {
                if (key !== field) {
                    if (FormErrors[key]) {
                        //Add the error in the list
                        FinalErrors = {...FinalErrors, ...{[key]: FormErrors[key]}};
                        InvalidTemp = true;
                    }
                }
            });
        }

        if (error) {
            //Add the error in the list
            FinalErrors = {...FinalErrors, ...{[field]: error}};
            InvalidTemp = true;
        }
        // console.log("InfalErrors", FinalErrors);

        setFormErrors(FinalErrors);

        setInvalid(InvalidTemp);
    };

    /**************** HANDLING ALL INPUT CHANGES EXCEPTED SELECT  *****************/

    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //Update the Select Creatable Options to add the new Options to the menu and transfer the new Options to the parent component
    const UpdateCreatableOptions = (field, UpdatedOptions) => {
        let FullNewOptions = UpdatedOptions.FullOptions;
        let NewOptions = UpdatedOptions.NewOptions;

        FieldOptions[field] = FullNewOptions;
        setNewFieldOptions({...NewFieldOptions, ...{[field]: NewOptions}});
        setFieldOptions(FieldOptions);
    };
    /**************** UPDATE THE OPTIONS WITH THE CREATED ONES IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/
    //UPDATE THE UPLOADED FILES FIELDS IF ANY

    const UpdateFormFiles = (fieldName, filedetails) => {
        if (fieldName && filedetails) {
            // console.log("UpdateFormFiles", filedetails);
            let FullFilesUploader = [];
            for (var i = 0; i < filedetails.length; i++) {
                FullFilesUploader.push({
                    serverPath: filedetails[i].serverPath,
                    originalName: filedetails[i].originalName,
                    fileName: filedetails[i].fileName,
                    fileExtension: filedetails[i].fileExtension,
                    fileType: filedetails[i].fileType,
                    fileSize: filedetails[i].fileSize,
                    keywords: filedetails[i].keywords,
                });
            }

            FilesUploaders[fieldName] = FullFilesUploader;

            setFilesUploaders(FilesUploaders);

            if (FullFilesUploader && FullFilesUploader.length > 0) {
                let FileUrl;
                if (FullFilesUploader && FullFilesUploader.length > 0) {
                    FileUrl = FullFilesUploader[0].serverPath;
                }
                if (FileUrl) {
                    setPDFFileURL(FileUrl);
                } else {
                    setPDFFileURL(null);
                }
            } else {
                setPDFFileURL(null);
            }
        }
    };

    /**************** UPDATE THE FilesUploaders IF ANY (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    const HandleSubmitForm = (submitType) => {
        /**Default SubmitType :
         * SaveDraft,
         * SaveAndQuit
         * QuitNoSave
         * reset //No Caps
         * Publish
         * Delete
         * NextSave
         * NextQuit
         * PreviousSave
         * PreviousQuit
         */
        if (submitType === "reset") {
            //Reinitializing the select options in case some were created
            let LoadData = JSON.parse(JSON.stringify(FormData.SelectOptions));
            setFieldOptions(LoadData);
            //Reinitializing the pristineDetails
            setPristineDetails({});
            //Reinitializing the CreatedOptions
            setNewFieldOptions({});

            //Reinitializing the FilesUplaoders
            let filesinit = JSON.parse(JSON.stringify(FormData.FilesUploaders));
            setFilesUploaders(filesinit);

            //Reinitializing the FormValues;

            setFormValues(InitialFormValues);

            //Reinitializing the Errors;

            setFormErrors(InitialFormErrors);

            //Setting the Form back to pristine
            setPristine(true);
        }
        if (typeof OnSubmitExternal === "function" || submitType === "SaveDraft") {
            //If there are new created options, we need to pass them too with the value

            //WE ONLY PASS THE FORMVALUES THAT ARE DIFFERENT THAN THE INITALVALUES EXCEPTED FOR THE CREATE SELECT MULTI
            var FinalSubmittedValues = {};
            let FormValuesKeys = _.keys(FormValues);
            if (FormValuesKeys && FormValuesKeys.length > 0) {
                FormValuesKeys.forEach((key) => {
                    if (key === "ApiID") {
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                    }
                    if (key === "Gender") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.Gender, {value: FormValues.Gender});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{Gender: Entry}};
                    } else if (key === "Language") {
                        //We need to retrieve the label and not the value
                        let Entry = _.find(FieldOptions.Gender, {value: FormValues.Language});
                        Entry = Entry.label;
                        FinalSubmittedValues = {...FinalSubmittedValues, ...{Language: Entry}};
                    } else {
                        if (Ref_ManualPaymentConfirmation.current) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{ManualPaymentDateIso: Ref_ManualPaymentConfirmation.current.ManualPaymentDateIso}};
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{ManualPaymentDone: Ref_ManualPaymentConfirmation.current.ManualPaymentDone}};
                        }
                        //Get the IntialValue
                        let InitialValue = InitialFormValues[key];
                        let Value = FormValues[key];
                        if (InitialValue !== Value && key) {
                            FinalSubmittedValues = {...FinalSubmittedValues, ...{[key]: FormValues[key]}};
                        }
                    }
                });
            }

            let FullSubmission = {
                typeOfSubmit: submitType, //Just passing the reset in case the parent needs to know
                values: FinalSubmittedValues ? FinalSubmittedValues : null,
            };
            //Prevent passing new created options if there are no new options

            if (_.isEmpty(NewFieldOptions) === false) {
                FullSubmission.NewCreatedOptions = NewFieldOptions;
            }

            if (FilesUploaders) {
                FullSubmission.FilesUploader = FilesUploaders;
            }

            //Submitting
            OnSubmitExternal(FullSubmission);

            //Updating the form state for editing after it has been submitted to define the editing mode
            if (OpenInViewMode === true) {
                if (SwitchEditMode === "EditButton") {
                    setFormViewMode("EditButton");
                }
                if (SwitchEditMode === "FieldClick") {
                    setFormViewMode("FieldClick");
                } else {
                    setFormViewMode("View");
                }
            }
        } else {
            console.log("You didn't pass a function for an external submission");
        }
    };
    /**************** FORM SUBMISSION (DONT NEED TO BE UPDATED NORMALLY) *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    /**************** FORM VALIDATION  *****************/
    const ValidateForm = (field, value) => {
        // Caca;
        var UpdatedFormErrors = {...FormErrors};

        if (FormValues.AccountType === "Credit Card") {
            // if (field === "InvoicePaymentExpectedCommunication") {
            //     if (value) {
            //         delete UpdatedFormErrors.InvoicePaymentExpectedCommunication;
            //     } else {
            //         UpdatedFormErrors.InvoicePaymentExpectedCommunication = "Cannot be empty";
            //     }
            // }
        }
        // if (field === "ref_Supplier") {
        //     if (value) {
        //         delete UpdatedFormErrors.ref_Supplier;
        //     }
        // }

        // if (field === "InvoicePaymentExpectedCommunication") {
        //     if (value) {
        //         delete UpdatedFormErrors.InvoicePaymentExpectedCommunication;
        //     } else {
        //         UpdatedFormErrors.InvoicePaymentExpectedCommunication = "Cannot be empty";
        //     }
        // }
        // if (field === "PaymentMethod") {
        //     if (value) {
        //         let Entry = _.find(FieldOptions.PaymentMethod, {value: value});
        //         Entry = Entry.label;
        //         if (Entry === "Bank Account Manual" || Entry === "Bank Account Automatic")
        //             if (!FormValues.CounterpartyBankAccount_Id) {
        //                 UpdatedFormErrors.CounterpartyBankAccount_Id = "Bank Account Required";
        //             } else {
        //                 delete UpdatedFormErrors.CounterpartyBankAccount_Id;
        //             }
        //     }
        // }
        // if (field === "CounterpartyBankAccount_Id") {
        //     if (FormValues.PaymentMethod) {
        //         let Entry = _.find(FieldOptions.PaymentMethod, {value: FormValues.PaymentMethod});
        //         Entry = Entry.label;
        //         if (Entry === "Bank Account Manual" || Entry === "Bank Account Automatic")
        //             if (!value) {
        //                 UpdatedFormErrors.CounterpartyBankAccount_Id = "Bank Account Required";
        //             } else {
        //                 delete UpdatedFormErrors.CounterpartyBankAccount_Id;
        //             }
        //     }
        // }
        // if (field === "InvoicePaymentDueDateIso") {
        //     if (value) {
        //         delete UpdatedFormErrors.ManualInvoicePaymentDueDateIso;
        //         delete UpdatedFormErrors.ref_PaymentTerms;
        //     }
        // }
        // if (field === "ref_PaymentTerms") {
        //     if (value) {
        //         delete UpdatedFormErrors.ManualInvoicePaymentDueDateIso;
        //         delete UpdatedFormErrors.ref_PaymentTerms;
        //     } else {
        //         if (FormValues.ManualInvoicePaymentDueDateIso) {
        //             delete UpdatedFormErrors.ManualInvoicePaymentDueDateIso;
        //             delete UpdatedFormErrors.ref_PaymentTerms;
        //         } else {
        //             UpdatedFormErrors.ManualInvoicePaymentDueDateIso = "Chose Due Date or Payment Terms";
        //             UpdatedFormErrors.ref_PaymentTerms = "Chose Due Date or Payment Terms";
        //         }
        //     }
        // }

        // if (field === "CounterpartyBankAccount_Id") {
        //     // console.log("CounterpartyBankAccount_Id", value);
        //     if (value) {
        //         delete UpdatedFormErrors.CounterpartyBankAccount_Id;
        //     } else {
        //         UpdatedFormErrors.CounterpartyBankAccount_Id = "Cannot be empty";
        //     }
        // }

        // if (field === "Statements_File_Path") {
        //     console.log("Statements_File_Path", value);
        //     // if (value) {
        //     //     delete UpdatedFormErrors.CounterpartyBankAccount_Id;
        //     // } else {
        //     //     UpdatedFormErrors.CounterpartyBankAccount_Id = "Cannot be empty";
        //     // }
        // }

        if (Object.keys(UpdatedFormErrors).length > 0) {
            setFormErrors(UpdatedFormErrors);
            setInvalid(true);
        } else {
            setFormErrors({});
            setInvalid(false);
        }
    };
    /**************** FORM VALIDATION  *****************/

    /**************** ON CHANGE UPDATES  *****************/

    const HandleFiedsChange = (field, value) => {
        // if (field === "isSubsidiary") {
        //     if (value === false) {
        //         setFormValues({...FormValues, ...{ref_group: null, isSubsidiary: false}});
        //     } else {
        //         setFormValues({...FormValues, ...{[field]: value}});
        //     }
        // }
    };

    /**************** ON CHANGE UPDATES  *****************/

    /**************** CUSTOM FUNCTIONS  *****************/
    const HandleEnableEditMode = () => {
        setFormViewMode("Edit");
    };
    const HandleSaveDraft = () => {
        HandleSubmitForm("SaveDraft");
    };
    const HandlePost = () => {
        console.log("Post");
        //First we check if all fields are correct
        //Cannot have an empty month, year, pdf file, manual amount, not matching total

        let StatementMonth = FormValues.StatementMonth;
        let StatementYear = FormValues.StatementYear;
        let ManualTempTotalAmount = FormValues.ManualTempTotalAmount;
        let Calc_TotalAmount = FormValues.Calc_TotalAmount;

        let FinalErrors = {};

        if (FilesUploaders.Statements_File_Path.length === 0) {
            FinalErrors.Statements_File_Path = "FilesRequired";
        }

        if (!StatementMonth) {
            FinalErrors.StatementMonth = "Month required";
        }
        if (!StatementYear) {
            FinalErrors.StatementYear = "Year required";
        }

        if (!ManualTempTotalAmount) {
            FinalErrors.ManualTempTotalAmount = "Manual amount required";
        } else {
            if (Calc_TotalAmount.toFixed(2) === ManualTempTotalAmount.toFixed(2)) {
            } else {
                FinalErrors.ManualTempTotalAmount = "Totals don't match";
            }
        }

        // if (!InvoicePaymentExpectedCommunication) {
        //     FinalErrors.InvoicePaymentExpectedCommunication = "Communication required";
        // }
        // if (!PaymentCondition) {
        //     FinalErrors.PaymentCondition = "Cannot be empty";
        // }
        // if (!PaymentMethod) {
        //     FinalErrors.PaymentMethod = "Cannot be empty";
        // } else {
        //     let Entry = _.find(FieldOptions.PaymentMethod, {value: PaymentMethod});
        //     Entry = Entry.label;
        //     if (Entry === "Bank Account Manual" || Entry === "Bank Account Automatic")
        //         if (!CounterpartyBankAccount_Id) {
        //             FinalErrors.CounterpartyBankAccount_Id = "Bank Account Required";
        //         }
        // }
        // if (!InvoiceSupplierDateIso) {
        //     FinalErrors.InvoiceSupplierDateIso = "Invoice date required";
        // }

        // if (!InvoicePaymentDueDateIso) {
        //     FinalErrors.ManualInvoicePaymentDueDateIso = "Chose Due Date or Payment Terms";
        //     FinalErrors.ref_PaymentTerms = "Chose Due Date or Payment Terms";
        // }

        // if (!CalcTotalVATIncluded) {
        //     FinalErrors.CalcTotalVATIncluded = "Cannot be empty";
        // }

        let ErrorsKeys = _.keys(FinalErrors);

        if (ErrorsKeys && ErrorsKeys.length > 0) {
            setFormErrors(FinalErrors);
        } else {
            setFormErrors({});
            //We need to change the Invoice status to Invoiced
            let UpdateFormValues = {Status: "Posted"};
            setFormValues({...FormValues, ...UpdateFormValues});
            Ref_Status.current = "Posted";
            HandleSubmitForm("SaveDraft");
        }
        console.log("FinalErrors", FinalErrors);
    };
    const HandleRevertToDraft = () => {
        //We need to change the Invoice status to posted
        setFormViewMode("Edit");
        let UpdateFormValues = {Status: "Draft"};
        setFormValues({...FormValues, ...UpdateFormValues});
        Ref_Status.current = "Draft";
        HandleSubmitForm("SaveDraft");
        //We need to change the view mode to edit
    };

    const HandleSaveRefreshForm = () => {
        HandleSubmitForm("SaveDraft");
    };

    /**************** CUSTOM FUNCTIONS  *****************/

    /****************************************************************** EDITABLE SECTION ******************************************************************/

    let FormTitle, InvoiceSummaryComponent;

    let FinalFormComponent;

    let FileUploaderComponent;
    if (InitialFormValues.ApiID === "new") {
        // , display: `${InitialFormValues.ApiID === "new" ? "none" : null}`
        FileUploaderComponent = null;
    } else {
        FileUploaderComponent = (
            <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                <div className={classes.FormField} style={{flex: "0 0 938px", maxWidth: "1100px"}}>
                    <FileUploader
                        name="Statements_File_Path"
                        label="Statement"
                        APIUploadPath="/api/companyaccount/uploadbankstatement"
                        APIDeletePath="/api/companyaccount/deletebankstatement"
                        UpdateFormFunction={UpdateFormFiles}
                        MaxFiles={10}
                        MaxSizeMB={10}
                        InitialFiles={FilesUploaders ? FilesUploaders.Statements_File_Path : []}
                        UploadButton={{
                            Message: "Upload Statement",
                            ClassExtendButton: classes.UploadButton,
                            Width: "150px",
                        }}
                        // AcceptedFileTypes={["*"]}
                        AcceptedFileTypes={["pdf"]}
                        MessageDragAndDrop={{DragColor: "grey", DragMessage: "Drag your files here", DropMessage: "Drop files"}}
                        BorderStyles={{EmptyColor: "grey", DraggingColor: "blue"}}
                        SelectStyle={{WithIcons: true, BackgroundColor: "grey", Color: "white"}}
                        WithDragAndDrop={true}
                        WithProgressBar={true}
                        ProgressBarStyle={{EmptyColor: "grey", ProgressColor: "blue"}}
                        WithDeleteConfirmation={true}
                        ReadOnly={FormViewMode === "View" ? true : false}
                        ParentErrors={FormErrors.Statements_File_Path}
                    />
                </div>
            </div>
        );
    }

    if (InitialFormValues)
        if (FormDataInitReady === true) {
            let DetailsComponent = (
                <div className={classes.InvoiceDetailsSection}>
                    <div className={classes.InvoiceDetailsTabNavWrapper}>
                        <ContactEditDetailsTabNav ContactID={InitialFormValues.ApiID} HandleContactDetailsChanged={HandleSaveRefreshForm} FormViewMode={FormViewMode} />
                    </div>
                </div>
            );
            FinalFormComponent = (
                <div className={classes.FormWrapper}>
                    {FormTitle}
                    <div className={classes.FormFlexHorizontal}>
                        <div className={classes.FormTopFlex}>
                            <div className={classes.FormTopLeft}>
                                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                    <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                                        <FormCompontent
                                            Name="FirstName"
                                            InitialValue={InitialFormValues.FirstName ? InitialFormValues.FirstName : null}
                                            FormValue={FormValues.FirstName ? FormValues.FirstName : null}
                                            OutputValue={(values) => HandleUpdateFormValues("FirstName", values)}
                                            Component="Input"
                                            Variant="Outlined"
                                            Label="First Name"
                                            ReadOnly={true}
                                            LabelMaxWidth="100px"
                                            FieldMaxWidth="200px"
                                            LabelAlign="Left"
                                        />
                                    </div>
                                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                        <FormCompontent
                                            Name="LastName"
                                            InitialValue={InitialFormValues.LastName ? InitialFormValues.LastName : null}
                                            FormValue={FormValues.LastName ? FormValues.LastName : null}
                                            OutputValue={(values) => HandleUpdateFormValues("LastName", values)}
                                            Component="Input"
                                            Variant="Outlined"
                                            Label="Last Name"
                                            ReadOnly={true}
                                            LabelMaxWidth="100px"
                                            FieldMaxWidth="200px"
                                            LabelAlign="Left"
                                        />
                                    </div>
                                </div>
                                <div className={classes.FormMultilineFlex} style={{justifyContent: "flex-start"}}>
                                    <div className={classes.FormField} style={{flex: "0 0 320px"}}>
                                        <FormCompontent
                                            Name="Gender"
                                            InitialValue={InitialFormValues.Gender ? InitialFormValues.Gender : null}
                                            FormValue={FormValues.Gender ? FormValues.Gender : null}
                                            OutputSelectedValues={(values) => handleSelectChange("Gender", values)}
                                            SelectOptions={FieldOptions.Gender}
                                            Component="Select"
                                            Variant="Outlined"
                                            Label="Gender"
                                            meta={{error: FormErrors.Gender, invalid: FormErrors.Gender ? true : false}}
                                            OutputError={(error) => HandleUpdateErrors("Gender", error)}
                                            NoMessage={false}
                                            MultiSelect={false}
                                            // SortSelectOptions="Asc"

                                            AllowCreateOption={false}
                                            // CreateDeleteIfUnselected={}
                                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                            LabelMaxWidth="100px"
                                            FieldMaxWidth="200px"
                                            LabelAlign="Left"
                                            Clearable={false}
                                            // Required={true}
                                            // Disabled={true}
                                            ReadOnly={FormViewMode === "View" ? true : false}
                                        />
                                    </div>

                                    <div className={classes.FormField} style={{flex: "0 0 300px"}}>
                                        <FormCompontent
                                            Name="Language"
                                            InitialValue={InitialFormValues.Language ? InitialFormValues.Language : null}
                                            FormValue={FormValues.Language ? FormValues.Language : null}
                                            OutputSelectedValues={(values) => handleSelectChange("Language", values)}
                                            SelectOptions={FieldOptions.Language}
                                            Component="Select"
                                            Variant="Outlined"
                                            Label="Language"
                                            meta={{error: FormErrors.Language, invalid: FormErrors.Language ? true : false}}
                                            OutputError={(error) => HandleUpdateErrors("Language", error)}
                                            NoMessage={false}
                                            MultiSelect={false}
                                            AllowCreateOption={false}
                                            // CreateDeleteIfUnselected={}
                                            // UpdateCreatableOptions={(options) => UpdateCreatableOptions("ref_companySiteType", options)}
                                            LabelMaxWidth="100px"
                                            FieldMaxWidth="200px"
                                            LabelAlign="Left"
                                            Clearable={false}
                                            // Required={true}
                                            // Disabled={true}
                                            ReadOnly={FormViewMode === "View" ? true : false}
                                        />
                                    </div>
                                </div>

                                {FileUploaderComponent}
                                {DetailsComponent}
                            </div>
                        </div>

                        <FormDev
                            DevMode={DevMode}
                            FormValues={FormValues}
                            InitialFormValues={InitialFormValues}
                            FieldOptions={FieldOptions}
                            FieldSubOptionsFull={FieldSubOptionsFull}
                            FilesUploaders={FilesUploaders}
                            FormErrors={FormErrors}
                            NewFieldOptions={NewFieldOptions}
                            PristineDetails={PristineDetails}
                        />
                        {/* Button Bar */}
                        <div className={classes.FormErrorMainMessage} style={{display: FormErrorMain ? null : "none"}}>
                            {`${FormErrorMain ? FormErrorMain : null}`}
                        </div>

                        {/* Button Bar */}
                    </div>
                </div>
            );
        } else {
            FinalFormComponent = null;
        }
    return <React.Fragment>{FinalFormComponent}</React.Fragment>;
});
export default Component;
