/* eslint-disable */
import React, {useEffect, useState, useRef, useCallback} from "react";
import {createUseStyles, useTheme} from "react-jss";
import Page from "../../../../../../artibulles-cis/Page/Page";

const styles = createUseStyles((theme) => ({}));

const CustomersDashboard = (props) => {
    //eslint-disable-next-line
    const theme = useTheme();
    //eslint-disable-next-line
    const classes = styles({...props, theme});

    /***************** REFS ******************/

    /***************** REFS ******************/

    /***************** CONST ******************/
    

    /***************** CONST ******************/

    /***************** STATE ******************/

    /***************** STATE ******************/

    /***************** CALLBACK ******************/

    /***************** CALLBACK ******************/

    /***************** EFFECTS ******************/

    /***************** EFFECTS ******************/

    /***************** FUNCTIONS ******************/

    /***************** FUNCTIONS ******************/

    /***************** RENDER ******************/
    return <Page>Dashbard</Page>;

    /***************** RENDER ******************/
};

export default CustomersDashboard;
// <Table URLAbsolute={FinalAPIUrlRelative} Data={Data} MainDataSource="main" ColomnsInput={ColomnsInput} TableOptions={TableOptions} />
