/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import {CookiesProvider} from "react-cookie";

import {WebsiteAppInitContextProvider} from "../utils/context/websiteAppInitContext/websiteAppInitContext";
import WebsiteAppInitComponent from "../WebsiteAppInitComponent/WebsiteAppInitComponent";

/**
 * This component is the first component of your application and is used to initiate the entire application
 * It loads and set the privacy cookies
 * It detects the user langauage and country
 * Manage mobile and desktop apps based on device type or browser width
 * Share width and height using Context
 * Allow to start an animation component before rendering the cookie policy
 * to use the context elsewhere just use :
 * import useWebsiteAppInitContext from '@artibulles-cis/react/utils/context/websiteAppInitContext'
 * const websiteAppInitContextState = useWebsiteAppInitContext();
 */

const WebsiteAppInit = React.forwardRef(function WebsiteAppInit(props, ref) {
    /****************************** PROPS AND JSS CLASSES *********************/

    const {
        appMaxWidth,
        mobileWidth,
        GeoLocation,
        withCookies,
        animationIntro,
        animationTime,
        AppMainComponent,
        MobileComponent,
        DesktopComponent,
        SiteCookiesList,
        CookiesComponent,
        HomePageAnimationComponent,
    } = props;

    /****************************** PROPS AND JSS CLASSES *********************/

    /****************************** REFS *********************/
    // const ref = useRef(null)
    /****************************** REFS *********************/

    /****************************** STATE *********************/
    //const [val, setVal] = useState();
    /****************************** STATE *********************/

    /****************************** CALLBACK FUNCTIONS *********************/

    //DON'T FORGET RULES OF HOOKS, USE FUNCTIONS INSIDE THE EFFECT OR USE CALLBACKS IF THE FUNCTION NEEDS TO BE REUSED
    /****************************** CALLBACK FUNCTIONS *********************/

    /****************************** EFFECT *********************/
    //WHEN COMPONENT LOADS
    // useEffect(() => {
    //     //Execute your code here
    // }, []);
    // //WHEN PROPS ARE CHANGING
    // useEffect(() => {
    //     //Execute your code here
    // }, [condition]);
    /****************************** EFFECT *********************/

    /****************************** FUNCTIONS *********************/
    //ADD YOUR FUNCTIONS HERE

    /****************************** FUNCTIONS *********************/

    /****************************** RENDER *********************/
    return (
		<WebsiteAppInitContextProvider>
		
            <CookiesProvider>
                <WebsiteAppInitComponent {...props} />
            </CookiesProvider>
        </WebsiteAppInitContextProvider>
    );
    /****************************** RENDER *********************/
});

WebsiteAppInit.defaultProps = {
	NoResizeCalc : false,
	appMaxWidth : null,
    mobileWidth : null,
    GeoLocation : null,
    withCookies :false,
    animationIntro: false,
    animationTime : null,
    AppMainComponent: null,
    MobileComponent: null,
    DesktopComponent: null,
    SiteCookiesList: null,
    CookiesComponent: null,
    HomePageAnimationComponent: null,
};

WebsiteAppInit.propTypes = {
	/**
     * NoResizeCalc :
     */
	NoResizeCalc: PropTypes.bool,
    /**
     * appMaxWidth :
     */
	appMaxWidth: PropTypes.string,
	 /**
     * mobileWidth :
     */
	mobileWidth: PropTypes.string,
	 /**
     * GeoLocation :
     */
	GeoLocation: PropTypes.bool,
	 /**
     * withCookies :
     */
	withCookies: PropTypes.bool,
	 /**
     * animationIntro :
     */
	animationIntro: PropTypes.bool,
	 /**
     * animationTime :
     */
	animationTime: PropTypes.any,
	 /**
     * AppMainComponent :
     */
	AppMainComponent: PropTypes.any,
	 /**
     * MobileComponent :
     */
	MobileComponent: PropTypes.any,
	 /**
     * DesktopComponent :
     */
	DesktopComponent: PropTypes.any,
	 /**
     * SiteCookiesList :
     */
	SiteCookiesList: PropTypes.any,
	 /**
     * CookiesComponent :
     */
	CookiesComponent: PropTypes.any,
	 /**
     * HomePageAnimationComponent :
     */
    HomePageAnimationComponent: PropTypes.any,
};

export default WebsiteAppInit;
